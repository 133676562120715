<div class="payment-container" [ngClass]="{'payment-container-desktop': buySimService.isDesktopView,
'delete-space-in-not-full-screen': !buySimService.isPaymentFullScreen}">
  <p class="title" *ngIf="buySimService.isPaymentFullScreen">{{'v10.buySim.itemsList.paymentDataTitle'|translate}}</p>
  <p class="title-ticket-details" *ngIf="!buySimService.hideTicketDetails">{{'v10.buySim.itemsList.orderSummaryTitle.body'|translate}}</p>
  <div class="white-card" [ngClass]="{'white-card-full-screen': buySimService.isPaymentFullScreen,'first-white-card-full-screen':buySimService.isPaymentFullScreen && showDetails}">
    <label class="card-title" [ngClass]="{'card-title-in-full-screen': buySimService.isPaymentFullScreen}">
      {{'v10.buySim.itemsList.paymentFirstCardTitle'|translate}}</label>

    <div class="wrapper mva10-o-layout">


      <div class="mva10-u--2/3">
        <div class="icon-container" [ngClass]="{'icon-container-in-tray':buySimService.isMobileView && !buySimService.isPaymentFullScreen}">
          <img class="icon-size" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-mobile@2x.png">
        </div>
        <div class="tariff-container">
          <label class="tarif-name" [ngClass]="{'not-mobile-con ':!buySimService.isMobileView}">{{buySimService.shoppingCart?.tariffName}}</label>
          <div class='green-border green-border-for-tablet-and-desktop' *ngIf="!buySimService.isMobileView && buySimService.isPaymentFullScreen">
            <label class="green-text">{{numberType}}</label>
          </div>
          <div>
            <label class="sim-cost">{{buySimService.shoppingCart?.price}}</label>
            <label class="sim-cost" *ngIf='buySimService.showPaymentRechargeTime'> / {{'v10.buySim.itemsList.paymentRechargeTime'|translate}}</label>
          </div>
          <div class='green-border green-border-for-mobile' *ngIf="buySimService.isMobileView && buySimService.isPaymentFullScreen">
            <label class="green-text">{{numberType}}</label>
          </div>
        </div>
      </div>
      <div class="mva10-u--1/3 sim-details right-item" (click)="onToggle()">
        <label class="chevron-title">{{chevronTitle}}</label>
        <i class="icon chevron-container"
          [ngClass]="{'icon-chevron-up': showDetails, 'icon-chevron-down': !showDetails}"></i>
      </div>
      <div id="show-payment-details" [ngClass]="{'show-payment-details-animation':showDetails,'show-payment-details-in-tray':buySimService.isMobileView && !buySimService.isPaymentFullScreen}">
        <div [ngClass]="{'inner-details':!buySimService.isMobileView ||(buySimService.isMobileView && !buySimService.isPaymentFullScreen),'inner-details-in-full-screen-mobile':buySimService.isMobileView && buySimService.isPaymentFullScreen }"
          *ngIf="buySimService.HTMLDataFromAPI" [innerHTML]="buySimService.HTMLDataFromAPI">
        </div>
      </div>
    </div>
  </div>

  <div class="white-card" [ngClass]="{'white-card-full-screen': buySimService.isPaymentFullScreen}">
    <label class="card-title" [ngClass]="{'card-title-in-full-screen': buySimService.isPaymentFullScreen,'second-card-title':showDetails}">{{'v10.buySim.itemsList.paymentSecondCardTitle'|translate}}</label>
    <div class="wrapper mva10-o-layout first-item">
      <div class="mva10-o-layout__item mva10-u--2/3 item-container">
        <div class="item-container">
          <img class="icon-size" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-sim@2x.png">
        </div>
        <div class="tariff-container">
          <label class="card-item">{{'v10.buySim.itemsList.paymentSIMSending'|translate}}</label>
        </div>
      </div>
      <div class="mva10-o-layout__item mva10-u--1/3 right-item item-container">
        <label class="card-item">{{'v10.buySim.itemsList.paymentFree'|translate}}</label>
      </div>
    </div>
    <div class="wrapper mva10-o-layout">
      <div class="mva10-o-layout__item mva10-u--2/3 item-container">
        <div class="item-container">
          <img class="icon-size" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/initial_balance.png">
        </div>
        <div class="tariff-container">
          <label class="card-item">{{'v10.buySim.itemsList.paymentInitialBalance'|translate}}</label>
        </div>
      </div>
      <div class="mva10-o-layout__item mva10-u--1/3 right-item item-container">
        <label class="card-item">{{setInitalBalanceAmount()}}</label>
      </div>
    </div>
    <div [ngClass]="{'card-paragraph-in-mob-tray': buySimService.isMobileView}">
      <p class="card-paragragh" [innerHTML]="getDeliveryHint()"></p>
    </div>
        <!--Promotion section-->
        <div class="promotion-section" *ngIf="buySimService.promotionsArray.length > 0">
          <hr />
          <div class="promotion-title wrapper mva10-o-layout">
            <div class="promotion-title-container mva10-u--2/3">
              <label class="text-title">{{'v10.buySim.itemsList.payment.promotionsSectionTitle'|translate}}</label>
            </div>
          <div class="promotion-more-details mva10-u--1/3" (click)="onChevronToggle()">
            <label>{{chevronPromotionTitle}}</label>
            <i class="icon promotion-chevron"
              [ngClass]="{'icon-chevron-up': showPromotionDetails, 'icon-chevron-down': !showPromotionDetails}">
            </i>
          </div>
        </div>
          <div class="promotions-list" id="show-promotion-details-animation" [ngClass]="{'show-promotions-details':showPromotionDetails,'remove-space': !showPromotionDetails}" >
            <ul>
            <li *ngFor="let item of buySimService.promotionsArray">
              <div class="promotions-list__item">
                <span class="dot"></span>
                <span class="short-desc">{{item.shortDesc}}</span>
              </div>
              <span *ngIf="buySimService.isPaymentFullScreen && item.longDesc" class="long-desc" >{{item.longDesc}}</span>
            </li>
            </ul>
        </div>
      </div>
    <hr />
    <div class="wrapper mva10-o-layout">
      <div class="mva10-o-layout__item mva10-u--3/5">
        <label class="total-item"  [ngClass]="{'total-item-in-full-screen': buySimService.isPaymentFullScreen}">{{'v10.buySim.itemsList.paymentTotalCost'|translate}}</label>
      </div>
      <div class="mva10-o-layout__item mva10-u--2/5 right-item">
        <label class="total-item">{{setInitalBalanceAmount()}}</label>
      </div>
    </div>
    <label class="taxes-hint-in-second-card" *ngIf="!buySimService.isPaymentFullScreen">{{'v10.buySim.itemsList.paymentTaxesIncludedHint'|translate}}</label>

  </div>
  <div *ngIf="buySimService.isPaymentFullScreen">
    <label class="taxes-hint">{{'v10.buySim.itemsList.paymentTaxesIncludedHint'|translate}}</label>
    <div class="container-terms">
        <p class="required-error-message" *ngIf="submitted && !generalTerms.checked">
            {{'v10.buySim.itemsList.mandatoryFieldValidation.body'|translate}}
        </p>
      <input type="checkbox" #generalTerms>
      <label (click)="generalTerms.checked = !generalTerms.checked; onConditionClick(generalTerms.checked)" id="PaymentComp_checkbox_0">
        <span [ngClass]="{'icon icon-tick-or-solved': generalTerms.checked}"></span>
      </label>
      <div class="paymentCon">
        <p [innerHtml]="'v10.buySim.itemsList.paymentScreenCheckbox'| translate| safe:'html'"></p>
        </div>
    </div>
    <div class="container-terms conditions-container">
        <span class="WithdrawalConditions-text"
          [innerHtml]="'v10.buySim.itemsList.paymentScreenWithdrawalConditions'| translate | safe:'html'">
        </span>
      <span>
        {{'v10.buySim.itemsList.paymentLegalTermsHint'|translate}}
        <a class="terms" (click)="onTermsClick()" id='paymentlegalHintTermsLinkId'>{{'v10.buySim.itemsList.paymentHere'|translate}}</a>
      </span>
    </div>
    <button type="button" id="PaymentComp_btn_submitPayment" class="button red two" (click)="onSubmit()">
      {{'v10.buySim.itemsList.paymentFinalizeButton'|translate}}
    </button>
  </div>
</div>


<vfac-overlay class="" [overlayData]="termsData" [show]="showTermsOverlay" [loaded]="true"
  (closed)="closeTermsOverlay(false)" [showButtons]="true">
  <div body>
    <div class="container-terms" *ngFor="let legalTerm of buySimService.legalTerms; let i = index">
      <input type="checkbox" #terms name="terms-{{i}}" [checked]="legalTerm.notSavedChecked==true">
      <label (click)="terms.notSavedChecked = !terms.notSavedChecked; onlegalTermClick(terms.notSavedChecked, i)" for="terms-{{i}}"
        class="col-xs-1">
        <span [ngClass]="{'icon icon-tick-or-solved': legalTerm.notSavedChecked}"></span></label>
      <span class="col-xs-11">{{legalTerm.internalDescription}} </span>
      <div class="clear-fix"></div>
    </div>
    <div class="btn-container">
      <button type="button" id="PaymentComp_btn_overlayPayment" class="button red two overlay-button"
        (click)="closeTermsOverlay(true)">
        {{'v10.buySim.itemsList.paymentOverlaySubmitButton'|translate}}
      </button>
    </div>
  </div>
</vfac-overlay>
import { ServiceType } from '../shared/enums/serviceType.enum';
import { AttrModel } from './attr.model';

export class ServiceProductModel {
    id: string;
    code: string;
    type?: ServiceType;
    recoOPMicro: boolean;
    longDescription: string;
    name: string;
    fee: string;
    netFee: string;
    order: string;
    section: string;
    url_image: string;
    comparation: string;
    secureNet: number;
    attrs: AttrModel[];
}


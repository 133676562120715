import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationCounter } from '../../models/notification-counter.model';
import { NotificationType } from '../enums/notification-type.enum';

@Injectable()
export class NotificationBadgeService {
  private notificationsArray: { [notificationType: string]: number } = {};
  private notificationCounter: BehaviorSubject<{ [notificationType: string]: number }> = new BehaviorSubject({});

  getRealTimeCounter(): Observable<{ [notificationType: string]: number }> {
    return this.notificationCounter.asObservable();
  }

  getSpecificCounter(type: NotificationType): number {
    return this.notificationsArray[type] || 0;
  }

  setNotificationCounter(newCounter: NotificationCounter): void {
    // Add new counter to notifications array
    this.notificationsArray[newCounter.type] = newCounter.value || 0;

    // Update global counter based on new value
    this.notificationsArray[NotificationType.TOTAL] = Object.entries(this.notificationsArray)
      .filter((counter: [string, number]) => counter[0] !== NotificationType.TOTAL)
      .reduce((sum: number, counter: [string, number]): number => sum + counter[1], 0);
    this.notificationCounter.next(this.notificationsArray);
  }
}

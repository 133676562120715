<section class='anonymous'>
    <p id='paymentAuthnticationAnonymousSubtitle' class='anonymous__subtitle mva10-responsive'>{{anonymousPaymentWCSData.subtitle}}</p>
    <div class="anonymous__form">
        <p id='paymentAuthnticationAnonymousDescription' class="anonymous__description">{{anonymousPaymentWCSData.description}}</p>
        <p id='paymentAuthnticationAnonymousNote' class="anonymous__note">{{anonymousPaymentWCSData.note}}</p>
        <form #form class="anonymous__data" [formGroup]="infoForm" (ngSubmit)="submit()">
          <div class="anonymous__heading">
              <mva10-c-icon class="anonymous__heading__icon" iconId="icon-admin" title="icon admin"></mva10-c-icon>
              <p id='paymentAuthnticationIdentificationTitle' class="anonymous__heading__headline">{{anonymousPaymentWCSData.identificationTitle}}</p>
          </div>
          <div class="identification"
               [ngClass]="{
                   'bottom-space': !isTabletView ||
                                    ((infoForm.get('nif').valid || infoForm.get('nif').pristine || infoForm.get('nif').hasError('required'))
                                     && (infoForm.get('cif').valid || infoForm.get('cif').pristine || infoForm.get('cif').hasError('required')))}">
            <div>
            <mva10-c-inputs [ngClass]="{'remove-bottom-padding': !isTabletView && cif}" [type]="0" 
            [textLabel]="anonymousPaymentWCSData.identificationNif" [inputId]="'NIF'" [checked]="!cif" 
            (change)="onRadioButtonClick('NIF')" name="identity" value="NIF"></mva10-c-inputs>

            <mva10-c-text-field *ngIf="!cif || isTabletView" [idInput]="'nif'" ngDefaultControl name="nif" id="nif"
                isRequired="true" formControlName="nif" [invalid]="true" [disabled]="cif && isTabletView" [ngClass]="{'disabled-input': cif && isTabletView}"
                (input)="isNifValid = !(infoForm.get('nif').hasError('pattern') && !infoForm.get('nif').pristine); checkFormValidity()"
                (mva10blur)="checkNifValidity(true)"
                (mva10focus)="checkNifValidity(false)"
                [error]="!isNifValid"
                [helperText]="isNifValid ? anonymousPaymentWCSData.identificationHelperText : identificationError"
                [placeholder]="anonymousPaymentWCSData.identificationNif+'*'">
            </mva10-c-text-field>

            </div>
            <div>
            <mva10-c-inputs [ngClass]="{'remove-bottom-padding': !isTabletView && !cif}" [type]="0" [textLabel]="anonymousPaymentWCSData.identificationCif" 
            [inputId]="'CIF'" [checked]="cif" 
            (change)="onRadioButtonClick('CIF')" name="identity" value="CIF"></mva10-c-inputs>

            <mva10-c-text-field *ngIf="cif || isTabletView" [idInput]="'cif'" ngDefaultControl name="cif" id="cif"
                isRequired="true" formControlName="cif" [invalid]="true" [disabled]="!cif && isTabletView" [ngClass]="{'disabled-input': !cif && isTabletView}"
                (input)="isCifValid = !(infoForm.get('cif').hasError('pattern') && !infoForm.get('cif').pristine); checkFormValidity();"
                (mva10blur)="checkCifValidity(true)"
                (mva10focus)="checkCifValidity(false)"
                [error]="!isCifValid"
                [helperText]="isCifValid ? anonymousPaymentWCSData.identificationHelperText : identificationError"
                [placeholder]="anonymousPaymentWCSData.identificationCif+'*'">
            </mva10-c-text-field>

           </div>
           
           </div>
            <hr />
            <div class="anonymous__heading">
                <mva10-c-icon class="anonymous__heading__icon" iconId='icon-bill-or-report' title="icon report"></mva10-c-icon>
                <p id='paymentAuthnticationPaymentNumberTitle' class="anonymous__heading__headline">{{anonymousPaymentWCSData.paymentNumberTitle}}</p>
            </div>
            <div class="payment-number" [ngClass]="{'bottom-space': !isTabletView}">
                <div>
                <mva10-c-inputs class="add-bottom-margin" [ngClass]="{'remove-bottom-padding': !isTabletView && mobileNumber}" [type]="0" 
                [textLabel]="anonymousPaymentWCSData.accountNumber" [inputId]="'account-number'" [checked]="!mobileNumber" 
                (change)="onRadioButtonClick('AccountNumber')" name="number" value="AccountNumber"></mva10-c-inputs>

                <mva10-c-text-field *ngIf="!mobileNumber || isTabletView" [idInput]="'accountNumber'" ngDefaultControl
                    isRequired="true" name="accountNumber" id="accountNumber" formControlName="accountNumber" [invalid]="true"
                    (mva10blur)="checkAccountNumberValidity(true)" 
                    (mva10focus)="checkAccountNumberValidity(false)"
                    [disabled]="mobileNumber && isTabletView" [ngClass]="{'disabled-input': mobileNumber && isTabletView}" [limit]="9"
                    (input)="checkAccountNumberValidity(false)"
                    [error]="!isAccountNumberValid"
                    [helperText]="isAccountNumberValid ? anonymousPaymentWCSData.accountNumberHelperText : paymentNumberError"
                    [placeholder]="anonymousPaymentWCSData.accountNumber+'*'">
                </mva10-c-text-field>

                </div>
                <div>
                <mva10-c-inputs class="add-bottom-margin" [ngClass]="{'remove-bottom-padding': !isTabletView && !mobileNumber}" [type]="0" 
                [textLabel]="anonymousPaymentWCSData.mobileNumber" [inputId]="'mobile-number'" [checked]="mobileNumber" 
                (change)="onRadioButtonClick('MobileNumber')" name="number" value="MobileNumber"></mva10-c-inputs>
                
                <mva10-c-text-field *ngIf="mobileNumber || isTabletView" [idInput]="'mobileNumber'" ngDefaultControl
                    isRequired="true" name="mobileNumber" id="mobileNumber" formControlName="mobileNumber" [invalid]="true"
                    (mva10blur)="checkMobileNumberValidity(true)"  (mva10focus)="checkMobileNumberValidity(false)"
                    [disabled]="!mobileNumber && isTabletView" [ngClass]="{'disabled-input': !mobileNumber && isTabletView ,'red-helpertext': isMobileNumberValid && showMobileNumerHelpertext }"
                    (input)="checkMobileNumberValidity(false)" [limit]="9"
                    [error]="!isMobileNumberValid"
                    [helperText]="isMobileNumberValid ?  showMobileNumerHelpertext ? anonymousPaymentWCSData.missingMobileText: anonymousPaymentWCSData.mobileNumberHelperText : paymentNumberError"
                    [placeholder]="anonymousPaymentWCSData.mobileNumber+'*'">
                </mva10-c-text-field>
                
                </div>
            </div>
            <hr />
            <p id='paymentAuthnticationPrivacyTxt' class="anonymous__privacy" [innerHtml]="anonymousPaymentWCSData.privacy"></p>
            <div class="btn-container"> 
              <button id='paymentAuthnticationSubmitBtn' type="submit" [ngClass]="{'invalid-form':!isFormValid || infoForm.invalid}" [disabled]="!isFormValid || infoForm.invalid">{{anonymousPaymentWCSData.buttonText}}</button>
            </div>
        </form>
        <div class="anonymous__footer">
            <div class="anonymous__footer-images">
                <div class="visa-image">
                    <img id='paymentAuthnticationVisaIcon' [src]="anonymousPaymentWCSData.visaIcon | imgFullPath"  alt="visa" />
                  </div>
                <div class="mastercard-image">
                    <img id='paymentAuthnticationMasterCardIcon' [src]="anonymousPaymentWCSData.masterCardIcon | imgFullPath" alt="mastercard" />
                </div>
            </div>
            <div class="anonymous__footer-securedssl">
                <p id='paymentAuthnticationSecuredSSLTxt'>{{anonymousPaymentWCSData.securedSSL}}</p>
            </div>
        </div>
    </div>
</section>

<div class="mva10-o-layout">
  <div class="mva10-o-layout__item search-box-contanier">
    <form role="form" [formGroup]="autoCompleteForm">
    <mva10-c-autocomplete
    formControlName="autoComplete"
    [data]="data"
    (changeSelect)="changeSelectEvent($event)"
    [textLabel]="label"
    [nameInput]="searchBoxInput"
    (mva10OnClick) = "onClick($event)"
    [ngModelInput]="InputValueText"
    [optionsBeginWith] ="true"
    [attr.id]="id? id + 'AutoComplete': null"
    >
    </mva10-c-autocomplete>
    <!--lens button-->
    <button  class="search_icon_button" [attr.id]="id? id + 'SearchBtn': null"
    *ngIf="!clearBtn"
    [ngClass]="{'btn-selected':itemSelected}" (click)="searchClicked()"></button>
    <mva10-c-icon
    *ngIf="!clearBtn"
    class="icon-search-lens"
    iconId="icon-search"
    (click)="searchClicked()"
    [attr.id]="id? id + 'SearchIcon': null">
  </mva10-c-icon>
   <!--clear button-->
 <button  class="svg-button" *ngIf="clearBtn" [attr.id]="id? id + 'ClearBtn': null" (click)="clearClicked()"></button>
  </form>
  </div>
</div>
export enum ClientTypology {
  notLogin = 'no logado',
  consumer = 'particular',
  postpaid = 'particular contrato',
  prepaid = 'particular prepago',
  authorized = 'empresas microempresas 5-9',
  resegmented = 'empresas microempresas 1-4',
  business = 'empresas corporate', // TODO: to be changed pending on customer reply
  postpaidYuUser = 'yu postpago',
  prepaidYuUser = 'yu prepago'
}

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorInterface } from './payment-error-interface';
import { PaymentErrorPrePaidAnonymous } from './payment-error-pre-paid-anonymous';
import { config } from '../../../../config/pages-config';

export class PaymentErrorPrePaidAnonymousThirdparty extends PaymentErrorPrePaidAnonymous implements PaymentErrorInterface {
    constructor(public paymentNavigationService: PaymentNavigationService,
        public translate: TranslateService,
        public router: Router) {
        super(paymentNavigationService, translate, router);
    }

    getFirstBtnAction(category: string): Function {
        let btnAction: Function
        switch (category) {
            case PaymentErrorCategory.PaymentApiFails:
            case PaymentErrorCategory.general:
            case PaymentErrorCategory.Notactive:
            case PaymentErrorCategory.Notprepaid:
            case PaymentErrorCategory.NotvodafoneNumber:
            case PaymentErrorCategory.TopupInProgress:
            case PaymentErrorCategory.TimeOut:
            case PaymentErrorCategory.mortiroloKO108:
            case PaymentErrorCategory.invalidCreditCard:
            case PaymentErrorCategory.invalidCreditCardInfo:
            case PaymentErrorCategory.CreditCardTypeNotSupported:
                btnAction = this.restartJourney();
                break;
            default:
                btnAction = this.closeBtnAction;
                break;
        }
        return btnAction
    }
    getSecondBtnAction(): () => void {
        return this.paymentNavigationService.closePayment.bind(this.paymentNavigationService);
    }
    restartJourney(): () => void {
        return this.paymentNavigationService.restartPaymentJourney.bind(this.paymentNavigationService);
    }
    /**
     * the default implmentaion of the second btn in error payment screen
     */
    close(): () => {} {
        return this.paymentNavigationService.closePayment.bind(this.paymentNavigationService);
    }

    /**
     * action for x btn click on overlay
     */
    closeBtnAction(): void {
        this.paymentNavigationService.closePayment();
        setTimeout(() => {
            this.router.navigate([config.login.route])
        }, 500);
    }

}

<mva10-c-modal *ngIf="show"
[show]="show"
(closeBtnClicked)="closeModalFun()" class="verify-modal" [ngClass]="{'hide-bg': hideBg, 'sendMail-modal': isOKModal}">
  <div class="registerMail" [ngClass]="{'sendMail': isOKModal}">
    <div>
      <img [src]="img" [id]="'newSuperWifiVerifyEmail' + id + 'Icon'">
    </div>
     <div>
      <p  class="registerMail-title" [id]="'newSuperWifiVerifyEmail' + id + 'Title'">{{title}}</p>
      <p  class="registerMail-sub-title" [id]="'newSuperWifiVerifyEmail' + id + 'subTitle'" [innerHtml]="subTitle"></p>
    </div>
  </div>
  <div class="button-container">
    <mva10-c-button
      [body]="btnText"
      (mva10OnClick)="clickOnBtnFun()" [id]="'newSuperWifiVerifyEmail' + id + 'Btn'">
    </mva10-c-button>
  </div>
</mva10-c-modal>

import { Component, OnInit } from '@angular/core';
import { AnonymousPostPaidPaymentService } from '../../../../payment/services/anonymous-post-paid-payment.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';

@Component({
  selector: 'sp-payment-disclaimer',
  templateUrl: './payment-disclaimer.component.html',
  styleUrls: ['./payment-disclaimer.component.scss']
})
export class PaymentDisclaimerComponent implements OnInit {

  isTabletView: boolean;

  constructor(
    private anonPayService: AnonymousPostPaidPaymentService,
    private newTagging: NewTaggingHelperService
    ) { }

  ngOnInit(): void {
    this.isTabletView = window.innerWidth >= Breakpoints.TABLET;
    this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
      const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.DEBT_CONSULTATION;
      this.newTagging.state(data.page.stateList[constantName]);
    });
  }

  getBills(): void {
    this.anonPayService.getUserDeptNoOTP(this.anonPayService.anonymousPaymentCustomer);
  }
}

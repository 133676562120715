
import {Observable, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { JSON_PATHS, TOBibubbleValues } from './../constants/defines';
import { API_URLS } from './../constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSettingToken } from '../enums/user-setting-token.enum';
import * as JsonQuery from 'jsonpath/jsonpath';
import { StorageService } from '../../core/services/storage.service';
import * as constants from '../constants/defines';
import { CustomerType } from '../enums/customerType.enum';
import { FederationErrorService } from './federation-error.service';
import { ChatBotTokenBodyRequest, ChatBotTokenRequest } from '../../models/chat-bot-token-request.model';
import * as _ from 'lodash';
import { BubbleHelpService } from '../components/bubble-help/bubble-help.service';

@Injectable()
export class UserSettingsService {
  webToken: string;
  chatToken: string;
  fedError: any;
  averia: string = '';
  ticketId: string = '';
  journey: string = '';
  clientIdAveria: string = '';
  orderId: string = '';
  contactEmail: string = '';
  contactPhone: string = '';
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private fedErrorService: FederationErrorService,
    public bubbleHelpService: BubbleHelpService
  ) { }

  getUserSettingsToken(
    type: UserSettingToken,
    siteId?: string,
    serviceId?: string,
    menuItemClicked: boolean = true,
    chatBotToken?: string,
    WCSData?: ChatBotTokenRequest,
    isdigital: boolean = null
  ): Observable<void> {
    const url: string = API_URLS.userSettings.userSettings;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const segment: string = this.setChatSegment(WCSData, isdigital);
    const body: ChatBotTokenBodyRequest = this.setChatData(menuItemClicked, type, serviceId, WCSData, siteId, chatBotToken, segment);
    return this.http.post(url, body, { headers }).pipe(
      map((res) => {
        this.webToken = JsonQuery.value(res, JSON_PATHS.UserSettings.webToken) || null;
        this.chatToken = JsonQuery.value(res, JSON_PATHS.UserSettings.chatToken) || null;
      }),
      catchError((error) => {
        this.fedError = this.fedErrorService.fedError;
        return throwError(error);
      })
    );
  }

  setChatData(
    menuItemClicked: boolean,
    type: UserSettingToken,
    serviceId: string,
    WCSData: ChatBotTokenRequest,
    siteId: string,
    chatBotToken: string,
    segment: string
  ): ChatBotTokenBodyRequest {
    const body: ChatBotTokenBodyRequest = menuItemClicked
      ? { attributes: [{ name: type }] }
      : {
          attributes: [
            {
              name: type,
              params: _.omitBy(
                // Remove empty values
                {
                  selectedSite: (this.averia ? this.clientIdAveria : siteId) || this.storage.userProfile?.sites?.[0]?.id,
                  selectedService: serviceId,
                  chatBotToken: chatBotToken,
                  origin: WCSData?.originWeb,
                  segment,
                  averia: this.averia,
                  ticketId: this.ticketId,
                  journey: this.journey,
                  orderId: this.orderId,
                  email: this.contactEmail,
                  msisdn: this.contactPhone,
                  originSection:
                    this.bubbleHelpService.currentPageBubbleHelpConfig?.tobiBubble?.toLowerCase() ===
                    TOBibubbleValues.directOrigin.toLowerCase()
                      ? this.bubbleHelpService.currentPageBubbleHelpConfig.module
                      : undefined,
                },
                (param) => !param
              ),
            },
          ],
        };
    return body;
  }

  setChatSegment(WCSData: ChatBotTokenRequest, isdigital: boolean): string {
    let segment: string;
    if (
      this.storage.userProfile?.customerType &&
      [CustomerType.Employee.toLocaleLowerCase(), CustomerType.Authorized.toLocaleLowerCase()].includes(
        this.storage.userProfile.customerType.toLowerCase()
      )
    ) {
      segment = WCSData?.microCompanySegment;
    } else {
      segment = isdigital ? WCSData?.rsYUSegment : WCSData?.rsSegment;
    }
    return segment || '';
  }

  getChatBotToken(WCSData) {
    const url: string = API_URLS.userSettings.onBehalOf;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('vf-country-code', 'ES')
    headers.append('Accept', 'application/json')
    const options = {
      headers: headers
    };
    const accessToken: string = this.storage.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
    const body = 'grant_type=' + WCSData.grantType +
      '&client_id=' + WCSData.clientId +
      '&subject_token_type=' + WCSData.subjectTokenType +
      '&actor_token_type=' + WCSData.actorTokenType +
      '&scope=' + WCSData.chatbotScope +
      '&actor_token=' + WCSData.actorToken +
      '&subject_token=' + accessToken
    return this.http.post(url, body, options).pipe(map(res => {
      return res
    }));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel } from '@angular/forms';
import { Backspace } from '../../constants/defines';

@Component({
  selector: 'sp-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss']
})
export class SearchAutocompleteComponent implements OnInit {
  data: any = [];
  @Input() label: string;
  @Input() autoComplateData: Array<string>;
  @Input() showDataAfterChar: number;
  @Input() InputValueText: NgModel;
  @Input() id: string = '';
  @Output() public valueSelectedText: EventEmitter<string> = new EventEmitter<string>();
  @Output() public searchButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  ngModelInput: any;
  searchBoxInput: any;
  inputValue: any;
  itemSelected: boolean;
  clearBtn: boolean;
  element: any;
  public autoCompleteForm: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    /** if the inout has value show the clear button */
    if (this.InputValueText) {
      this.clearBtn = true;
    }
    this.autoCompleteForm = this.formBuilder.group({
      autoComplete: [this.InputValueText],
    });
  }


  changeSelectEvent(event: any): void {
    this.itemSelected = true;
    this.clearBtn = false;
    // selected value is the event ex: EGYPT
    this.valueSelectedText.emit(event)
  }

  onClick(event: any): void {
    /** once click on the input would listen to the Event */
    this.data = [];
    this.element = event.target;
    event.target.addEventListener('keydown', this.onKeyDown.bind(this));
    event.target.addEventListener('focusout', this.focusout.bind(this));
    event.target.addEventListener('focusin', this.focusin.bind(this));

  }

  focusout(): void {
    if (((this.element && this.element.value === '') || this.inputValue === '')) {
      this.clearBtn = false
    }
  }

  focusin(): void {
    this.clearBtn = true
  }
  onKeyDown(event: any): void {
    this.itemSelected = false;
    this.inputValue = event.target.value;
    if (((this.element && this.element.value) || this.inputValue) && event.key === Backspace) {
      this.clearBtn = true;
    } else {
      this.clearBtn = false;
    }
    /** ONLY ACCEPT CHARACTERS NO numbers or special characters */
    if (!this.checkKeyCodeIsNotNumber(event.keyCode)) {
      if (event) {
        event.preventDefault();
      }
    }
    const inputValueLength: any = this.inputValue.length;
    /** showDataAfterChar will controll the number of char that data should show after */
    if (event.key === Backspace) {
      if (inputValueLength - 1 >= this.showDataAfterChar) {
        this.data = this.autoComplateData;
      }
      else {
        /** don't show data if the chars in the input is less than showDataAfterChar  */
        this.data = []
      }
    } else {
      /** in case of earser  */
      if (inputValueLength + 1 >= this.showDataAfterChar) {
        this.data = this.autoComplateData;
      }
      else {
        /** don't show data if the chars in the input is less than showDataAfterChar  */
        this.data = []
      }
    }
  }
  checkKeyCodeIsNotNumber(key: any): boolean {
    /** to take only strings  */
    return ((key >= 65 && key <= 90) || key === 8 || key === 32);
  }

  searchClicked(): void {
    if (this.itemSelected) {
      this.clearBtn = !!this.element.value;
      this.searchButtonClicked.emit();
    }
  }

  clearClicked(): void {
    this.itemSelected = false;
    this.clearBtn = false;
    if (this.element && this.element.value) { this.element.value = ''; }
    this.autoCompleteForm.controls['autoComplete'].markAsPristine();
    this.autoCompleteForm.controls['autoComplete'].reset();
    this.autoCompleteForm.controls['autoComplete'].setValue('');
  }
}


import { Component, Input } from '@angular/core';

@Component({
  selector: 'sp-icon-navigation-card',
  templateUrl: './icon-navigation-card.component.html',
  styleUrls: ['./icon-navigation-card.component.scss']
})
export class IconNavigationCardComponent {

  @Input() cardTitle: string;
  @Input() iconId: string;
  @Input() iconTitle: string;
  @Input() iconSize: number;
  @Input() cardText: string;
  @Input() chevronColorId: string;
  @Input() routerLink: string;

}

import { PaymentCardModel } from '../../../models/payment-card.model';
import { BillingService } from '../../../billing/billing.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { StorageService } from '../../../core/services/storage.service';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
export interface PaymentTaggingInterface {
    // tagging ticket screen
    tagOpenPaymentTicketScreen(): void;
    tagDownLoadTicket(): void;
    ticketXbtnClickInteraction(): void;
    // prepaid entery point
    topupLandingXbtnClickInteraction(): void;
    // wallet screen tagging
    tagOpenPaymentWalletScreen(isSelectedCard: boolean): void;
    tagPaymentWalletConfirmDeleteInteraction(): void;
    tagPaymentWalletCancelDeleteInteraction(): void;
    tagPaymentWalletDeleteCard(): void;
    tagPaymentWalletUpdateAsPrinciple(): void;
    tagPaymentWalletClickOnXInteraction(isSelectedCard?: boolean): void;
    tagPaymentWalletCancelLeavingOverlay(isSelectedCard: boolean): void;
    tagPaymentWalletClickOnBackInteraction(selectedCard?: PaymentCardModel): void;
    // for stand alone managment journey
    tagPaymentSetPruncipaleBtn(): void;
    tagPaymentDeleteBtn(): void;
    // for payment success screen
    successpaymentCerrarBtnClick(): void;
    successpaymentEntendidoBtnClick(): void;
    tagPaymentLeavingSuccessScreen(transactionId: string, isToggleOn: boolean): void;
    tagCloseBtnClick(): void;
    tagCloseBtnSmartPayAfterTopUp(): void;
}
export class PaymentTaggingDefault implements PaymentTaggingInterface {
    constructor(
        public tagging: TaggingHelperService,
        public billService?: BillingService,
        public paymentNavigationService?: PaymentNavigationService,
        public storageService?: StorageService,
        public newTagging?: NewTaggingHelperService
    ) { }

    // no implmentation here
    // implmentation is inside every journey class
    // this is done beacuse not every class have implmentation for
    // all these functions
    // it's done like this to pass kiwan
    tagOpenPaymentTicketScreen(): void {
        return;
    }
    tagDownLoadTicket(): void {
        return;
    }
    ticketXbtnClickInteraction(): void {
        return;
    }
    topupLandingXbtnClickInteraction(): void {
        return;
    }
    tagOpenPaymentWalletScreen(isSelectedCard: boolean): void {
        return;
    }
    tagPaymentWalletConfirmDeleteInteraction(): void {
        return;
    }
    tagPaymentWalletCancelDeleteInteraction(): void {
        return;
    }
    tagPaymentWalletDeleteCard(): void {
        return;
    }
    tagPaymentWalletUpdateAsPrinciple(): void {
        return;
    }
    tagPaymentWalletClickOnXInteraction(isSelectedCard?: boolean): void {
        return;
    }
    tagPaymentWalletCancelLeavingOverlay(isSelectedCard: boolean): void {
        return;
    }
    tagPaymentWalletClickOnBackInteraction(selectedCard?: PaymentCardModel): void {
        return;
    }
    tagPaymentSetPruncipaleBtn(): void {
        return;
    }
    tagPaymentDeleteBtn(): void {
        return;
    }
    successpaymentCerrarBtnClick(): void {
        return;
    }
    successpaymentEntendidoBtnClick(): void {
        return;
    }
    tagPaymentLeavingSuccessScreen(transactionId: string, isToggleOn: boolean): void {
        return;
    }
    tagCloseBtnClick(): void {
        return;
    }

    tagCloseBtnSmartPayAfterTopUp(): void {
        return;
    }
}


<div class="row mini-circle mva10-u--pt1x" [ngClass]="{'mini-circle-extra-details':productsAndServicesService.isExtraDetailsStyle}">
    <div class="col-xs-9 remaining-container">
        <p class="remaining">{{title}}</p>
        <p class="get-remaining-value">
            {{remaining}} {{unit}}
        </p>
    </div>
    <div class="col-xs-3 reset-padding chart-height">
        <div class="percantage-usage">
            <figure class="chart-{{percentage < 0 ? 0 : percentage}} remove-top animate">
                <svg role="img" xmlns="http://www.w3.org/2000/svg">
                    <circle class="circle-background" r="13" cx="50%" cy="50%"/>
                    <circle class="circle-foreground" r="13" cx="50%" cy="50%"/>
                </svg>
            </figure>
        </div>
    </div>
</div>

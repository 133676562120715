import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorInterface } from './payment-error-interface';
import { PaymentErrorPrePaidLogin } from './payment-error-pre-paid-login';

export class PaymentErrorSaveCreditCard extends PaymentErrorPrePaidLogin implements PaymentErrorInterface {
    defaultErrorCategory: PaymentErrorCategory = PaymentErrorCategory.general;
    forceShowBackBtn: boolean;
    constructor(public paymentNavigationService: PaymentNavigationService,
        public translate: TranslateService,
        public router: Router) {
        super(paymentNavigationService, translate, router);
    }


    getFirstBtnAction(category: string): Function {
        let btnAction: Function
        switch (category) {
            case PaymentErrorCategory.general:
            case PaymentErrorCategory.invalidCreditCard:
            case PaymentErrorCategory.invalidCreditCardInfo:
            case PaymentErrorCategory.CreditCardTypeNotSupported:
            case PaymentErrorCategory.TimeOut:
                btnAction = this.restartJourney();
                break;
            case PaymentErrorCategory.SystemNotAvailable:
            case PaymentErrorCategory.refundSuccess:
            case PaymentErrorCategory.refundFailed:
            case PaymentErrorCategory.operationNotExist:
                btnAction = this.close();
                break;
            default:
                btnAction = this.close();
                break;
        }
        return btnAction
    }
    restartJourney(): () => void {
        return this.paymentNavigationService.restartFromIframePaymentJourney.bind(this.paymentNavigationService);
    }
    /**
     * the default implmentaion of the second btn in error payment screen
     */
    close(): () => {} {
        return this.paymentNavigationService.closePayment.bind(this.paymentNavigationService);
    }

}

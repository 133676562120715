<div class="vfac-side-menu  mva10-u--1/1"
  [ngClass]="{'mva10-o-layout__item': expanded || breakpoint == BREAKPOINTS.DESKTOP}">
  <div class="mva10-u--pb5x" [ngClass]="expanded ? 'text-align-left' : 'text-align-center'">

    <div *ngIf="iconError" id="sharedLeftMenuErrorIcon"
        class="vfac-side-menu__logo cursor-pointer"
        [ngClass]="{'vfac-side-menu__logo--desktop': bigSize }"
        id="sharedLeftMenuLogoClick"
        (click)="logoClick()">
      <svg [ngClass]="bigSize ? 'vfac-side-menu__icon-svg--xl' : 'vfac-side-menu__icon-svg'">
        <title>Logo_Vodafone</title>
        <use xlink:href="#icon-vodafone-esp" />
      </svg>
    </div>

    <div *ngIf=!iconError>
      <div *ngIf="!isYuBit" id="sharedLeftMenuDynamicIcon" class="vfac-side-menu__logo cursor-pointer"
        [ngClass]="{'vfac-side-menu__logo--desktop': bigSize }" (click)="logoClick()">
        <img *ngIf="!vfFlagAnimation" [ngClass]="bigSize ? 'vfac-side-menu__icon-svg--xl' : 'vfac-side-menu__icon-svg'"
          src="{{vfIcon}}" />
        <div *ngIf="vfFlagAnimation"
          [ngClass]="bigSize ? 'vfac-side-menu__icon-svg--xl' : 'vfac-side-menu__icon-svg, animationAjust'">
          <div [ngClass]="expanded && !bigSize ? 'animationAjustExpanded': 'dummy'">
            <sp-lottie-loader [lottieImageUrl]="vfIcon"></sp-lottie-loader>
          </div>
        </div>
      </div>

      <div *ngIf="isYuBit" class="vfac-side-menu__logo">
        <img id="sharedLeftMenuImgYu" *ngIf="!yuBitFlagAnimation"
          [ngClass]="bigSize ? 'vfac-side-menu__yuBitIcon--xl' : 'vfac-side-menu__yuBitIcon'" src="{{yuBitIcon}}" />
        <div *ngIf="yuBitFlagAnimation"
          [ngClass]="bigSize ? 'vfac-side-menu__yuBitIcon--xl' : 'vfac-side-menu__yuBitIcon, animationAjust'">
          <div [ngClass]="expanded ? 'animationAjustExpanded': 'dummy'">
            <sp-lottie-loader [lottieImageUrl]="yuBitIcon"></sp-lottie-loader>
          </div>
        </div>
      </div>
    </div>

    <fieldset *ngIf="leftMenuService.leftMenuData">

      <div *ngFor="let item of leftMenuService.leftMenuData.items; let itemIdx = index" class="vfac-side-menu__item"
        [ngStyle]="{'justify-content': !expanded && !bigSize ? 'center' : 'left'}" [ngClass]="{'vfac-side-menu__item--selected': itemSelected === itemIdx,
                      'vfac-side-menu__item--desktop': bigSize}">

        <mva10-c-menu-list
          *ngIf="item.type !== SideMenuItemType.PLACEHOLDER"
          [badgeNum]="item.badgeText"
          [badgePosition]="'left'" [body]="(expanded || bigSize) ? item.text : ''" [iconLeft]="item.iconClassName"
          [styleClass]="(expanded || bigSize) ? '' : 'hideMenuSpan'" (clickEvent)="onMenuClick(item, itemIdx)">
        </mva10-c-menu-list>
        <div class="sidemenu__button-skeleton__container"
          *ngIf="item.type === SideMenuItemType.PLACEHOLDER" id="leftMenuButtonSkeleton">
          <div class="skeleton sidemenu__button-skeleton__icon">
          </div>
          <div class="skeleton sidemenu__button-skeleton__text">
          </div>
        </div>
      </div>

    </fieldset>
  </div>

  <ng-container *ngIf="bigSize">

    <sp-adara-selector (telcoFilterSelected)="telcoFilter = $event" [locationClass]="'left-menu'" *ngIf="customerAccountService.showAdaraSelector"></sp-adara-selector>

    <!-- CIF & SITE SELECTOR -->
    <div *ngIf="bigSize && telcoFilter" class="vfac-side-menu__selector-sede mva10-u--mb6x">
      <div class="vfac-side-menu__selector-sede--subtitle">
        <p id="sharedLeftMenuCurrentCif">{{leftMenuService.currentCif}}</p>
      </div>
      <div class="vfac-side-menu__selector-sede--subtitle mva10-u--pt1x">
        <p id="sharedLeftMenuCurrentAddress">{{leftMenuService.currentAddress}}</p>
      </div>
      <div *ngIf="customerAccountService.selectorCIFData?.length > 1 || leftMenuService.addressSelector?.length > 1" id="sharedLeftMenuOpenOverlay"
        class="vfac-side-menu__selector-sede--changeit mva10-u--pt1x cursor-pointer" (click)="openCifOverlay()">
        <div class="vfac-side-menu__selector-sede--changeit-title">
          <p id="sharedLeftMenuSelectAddress">{{'v10.common.tray.overlay_myproducts.selectcifaddress_button'|translate}}</p>
        </div>
        <svg class="vfac-side-menu__selector__item-icon">
          <use xlink:href="#icon-chevron-right" />
        </svg>
      </div>
    </div>

    <!-- PRODUCTS SELECTOR -->

    <button
      *ngIf="leftMenuService.clientsPQ && !leftMenuService.errorCif && leftMenuService.productSelectorLoad && !leftMenuService.isSitePendingInstallation && telcoFilter"
      id="sharedLeftMenuProductsButton" class="vfac-side-menu__products-button vfes-title--bold mva10-u--mb4x cursor-pointer"
      (click)="productsButtonClick()">
      {{leftMenuService.selectorDataButtonText}}
    </button>
    <div #productsContainer *ngIf="!leftMenuService.errorCif && !leftMenuService.isSitePendingInstallation && telcoFilter"
      class="vfac-side-menu__products__container" [ngClass]="{'vfac-side-menu__products__container--gradientTop': viewMoreClick && showGradientTop,
                  'vfac-side-menu__products__container--gradientBottom': viewMoreClick && showGradientBottom}">

        <div #menuProducts  *ngIf="leftMenuService.productSelectorLoad; else skeleton" (scroll)="scrollProducts()"
          class="vfac-side-menu__products scrollbar-hidden"
          [ngClass]="{'mva10-o-layout__item': !bigSize,
                      'vfac-side-menu__products--clicked vfac-side-menu__products--scroll-open': viewMoreClick,
                      'vfac-side-menu__products--unclicked vfac-side-menu__products--scroll-blocked': !viewMoreClick}">
          <div *ngIf="!productAndService.leftMenuHideService">
            <div *ngFor="let item of customerAccountService.selectorDataWithSubscriptions?.cardImageSelectorList"
                [ngStyle]="{'justify-content': !expanded && !bigSize ? 'center' : 'left'}"
                id="sharedLeftMenuSelectedProduct"
                (click)="selectedProduct(item)">

              <vfac-card-product
                [svg]="item.icon"
                [title]="item.title"
                [description]="item.description"
                [checked]="item.checked"
                [size]="'small'"
                [colorBg]="item.backgroundColor"
                [borderColor]="item.borderColor"
                [modifier]="this.appService.checkYuUser() ? 'border-gradient-yu' : 'line'"
                extraClass="mva10-u--mb2x"
              ></vfac-card-product>

            </div>
          </div>
        </div>
    </div>

    <div #adaraContainer *ngIf="!leftMenuService.errorCif && !telcoFilter"
      class="vfac-side-menu__products__container" [ngClass]="{'vfac-side-menu__products__container--gradientTop': viewMoreClick && showGradientTop,
                  'vfac-side-menu__products__container--gradientBottom': viewMoreClick && showGradientBottom}">
        <div #menuAdara  *ngIf="leftMenuService.productSelectorLoad; else skeleton" (scroll)="scrollProducts()"
          class="vfac-side-menu__products scrollbar-hidden"
          [ngClass]="{'mva10-o-layout__item': !bigSize,
                      'vfac-side-menu__products--clicked vfac-side-menu__products--scroll-open': viewMoreClick,
                      'vfac-side-menu__products--unclicked vfac-side-menu__products--scroll-blocked': !viewMoreClick}">
          <div *ngIf="customerAccountService.hasAdaraSite">
            <div *ngFor="let item of customerAccountService.adaraSelectorData?.cardImageSelectorList"
                [ngStyle]="{'justify-content': !expanded && !bigSize ? 'center' : 'left'}"
                id="sharedLeftMenuSelectedProduct"
                (click)="clickOnAdaraCTATags()">

              <vfac-card-product
                [svg]="'#icon-idea-or-innovation-mid'"
                [title]="item.title"
                [description]="item.description"
                [checked]="item.checked"
                [size]="'small'"
                [colorBg]="item.backgroundColor"
                [borderColor]="'white'"
                extraClass="mva10-u--mb2x"
              ></vfac-card-product>

            </div>
          </div>
        </div>
    </div>

  </ng-container>

  <div [ngClass]="{
        'footer-side-menu--spaced': customerAccountService.selectorDataWithSubscriptions?.cardImageSelectorList?.length > 4
      }" class="footer-side-menu mva10-u--pb6x">
    <div *ngIf="
          !leftMenuService.errorCif &&
          customerAccountService.selectorDataWithSubscriptions?.cardImageSelectorList?.length > 4 &&
          bigSize &&
          showViewMore &&
          !leftMenuService.isSitePendingInstallation &&
          !productAndService.leftMenuHideService
          && telcoFilter
        " class="view-more mva10-u--pt2x mva10-u--pb2x">
      <div class="vfac-side-menu__item cursor-pointer" (click)="onViewMoreClick()" id="sharedLeftMenuOnViewMore"
        [ngStyle]="{'justify-content': !expanded && !bigSize ? 'center' : 'center'}"
        [ngClass]="{'vfac-side-menu__item--desktop': bigSize}">

        <div *ngIf="!viewMoreClick" class="view-more__item"> {{'v10.dashboard.common.products_viewMore'|translate}}
        </div>
        <div *ngIf="viewMoreClick" class="view-more__item"> {{'v10.dashboard.common.products_viewLess'|translate}}
        </div>
        <div class="view-more__icon">
          <svg class="vfac-side-menu__item-icon"
            [ngClass]="{'view-more__icon--active': viewMoreClick, 'view-more__icon--unactive': !viewMoreClick}">
            <use xlink:href="#icon-chevron-down" />
          </svg>
        </div>
      </div>
    </div>
      <!--logout side menu-->
  </div>
</div>

<vfac-overlay [show]="showChangeCifOverlay" [loaded]="customerAccountService.selectorCIFData"
  [overlayData]="overlayData" [showButtons]="false" (closed)="showChangeCifOverlay = false">
  <div body class="mva10-u--mt12x">
    <mva10-c-tabs-simple
      *ngIf="customerAccountService.selectorCIFData && customerAccountService.selectorCIFData.length > 1"
      [dataSelectTab]="leftMenuService.cifTabIdx" [styleInline]="{'margin-bottom': '24px'}"
      (mva10OnClick)="this.leftMenuService.setCifSelectorClicked($event)">
      <mva10-c-tab *ngFor="let tab of customerAccountService.selectorCIFData; let i = index" [title]="tab">
        <ng-container *ngIf="leftMenuService.addressSelectorLoaded;then overlayContent else skeleton2"></ng-container>
      </mva10-c-tab>
    </mva10-c-tabs-simple>

    <ng-container *ngIf="!customerAccountService.selectorCIFData || customerAccountService.selectorCIFData.length <= 1"
      [ngTemplateOutlet]="overlayContent"></ng-container>

    <vfac-selector *ngIf="!leftMenuService.isSitePendingInstallation" [yuAppearance]="this.appService.checkYuUser()"
      [selectorData]="customerAccountService.selectorData" [startAtIndex]="leftMenuService.startIndexSelectorCard"
      (cardClickEvent)="selectedProductModal($event)" [listLoaded]="leftMenuService.productSelectorLoad">
    </vfac-selector>

    <sp-pending-install-warning id="sharedLeftMenuClickMyOrders" *ngIf="leftMenuService.isSitePendingInstallation" (linkClick)="goToMyOrders()"></sp-pending-install-warning>

  </div>
</vfac-overlay>

<ng-template #overlayContent>
  <div class="overlayContent" *ngIf="!leftMenuService.errorCif">
    <mva10-c-select-field
      *ngIf="leftMenuService.addressSelectorLoaded && leftMenuService.addressSelector && leftMenuService.addressSelector.length > 1"
      [label]="leftMenuService.selectorDataDescription" [listItems]="leftMenuService.addressSelector"
      (clickEvent)="leftMenuService.setSiteSelectorClicked()"
      [preselectedValue]="leftMenuService.addressSelectorDefault">
    </mva10-c-select-field>
  </div>

</ng-template>

<ng-template #skeleton>
  <div class="mva10-u--mt6x">
    <div class="skeleton vfac-side-menu__skeleton__button mva10-u--11/12"></div>

    <div>
      <div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
        <div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
        <div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
      </div>
      <div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
        <div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
        <div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
      </div>
      <div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
        <div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
        <div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #skeleton2>
  <div class="vfac-side-menu__skeleton__container">
    <div class="skeleton vfac-side-menu__skeleton__button mva10-u--11/12"></div>
    <div class="skeleton vfac-side-menu__skeleton__item mva10-u--11/12">
      <div class="skeleton vfac-side-menu__skeleton__title mva10-u--1/3"></div>
      <div class="skeleton vfac-side-menu__skeleton__subtitle mva10-u--2/3"></div>
    </div>
  </div>
</ng-template>

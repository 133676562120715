<div class="cancel__container">
  <div class="cancel__cont">
    <div class="cancel__icon">
      <sp-lottie-loader [lottieImageUrl]="payCancelSer.currnetCancel.lottieFile" [loop]="false"></sp-lottie-loader>
    </div>
    <p class="cancel__title" [innerHTML]="payCancelSer.currnetCancel.desc"></p>
  </div>
  <div class="cancel__btns-cont">
    <div class="cancel__first-btn">
      <mva10-c-button [body]="payCancelSer.currnetCancel.firstBtnText" [styleClass]="'mva10-c-button--primary'"
        (click)="payCancelSer.currnetCancel.firstBtnAction()">
      </mva10-c-button>
    </div>
    <div class="cancel__second-btn">
      <mva10-c-button [body]="payCancelSer.currnetCancel.secondBtnText" [styleClass]="'mva10-c-button--tertiary'"
        *ngIf="payCancelSer.currnetCancel.secondBtnText" (click)="payCancelSer.currnetCancel.secondBtnAction()">
      </mva10-c-button>
    </div>
  </div>
</div>
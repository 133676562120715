import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { CommercialJourneys, confirmation, errTagging } from '../../constants/defines';
import { CommercialOffersService } from '../../services/commercial-offers.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { tagging } from '../../../../config/tagging-config';
import { CheckoutCommercialService } from '../../services/checkout-commercial.service';

@Component({
  selector: 'sp-modal-messages',
  templateUrl: './modal-messages.component.html',
  styleUrls: ['./modal-messages.component.scss']
})
export class ModalMessagesComponent implements OnInit {

  @Input() highlightedText;
  @Input() header;
  @Input() image;
  @Input() title;
  @Input() subtitle;
  @Input() functionCross;
  @Input() type;
  @Input() taggingError?;
  /** This variable is used in error tagging. Each time you exit from a screen, you must set the
   * page_name of this screen as previous_pagename value. You´ll find an example in shipping-checkout
  */
  journeyTagg: any;

  constructor(
    private router: Router,
    public appService: AppService,
    private commercialOffersService: CommercialOffersService,
    private tagging: TaggingHelperService,
    private checkoutCommercialComponent: CheckoutCommercialService
  ) { }

  ngOnInit() {
    if (this.type && (this.type.toLowerCase() === confirmation.confirm.toLowerCase())) {
      this.journeyTagg = this.commercialOffersService.getJourneyTagg();
      this.setTaggingLogic(CommercialJourneys.Tagg.type.init);
    } else if ( this.taggingError ) {
      this.setTaggingLogic(CommercialJourneys.Tagg.type.error);
    }

  }

  crossAction() {
    this.functionCross();
  }

  private setTaggingLogic(type: string) {
    const page = Object.assign({}, tagging.CommercialJourneys.additionalLines.page);
    const data = Object.assign({}, tagging.CommercialJourneys.additionalLines.data);

    switch (type) {
      case CommercialJourneys.Tagg.type.init:
        page.page_name = CommercialJourneys.pageNameThankYou;
        page.page_screen = CommercialJourneys.pageScreenThankYou;
        page.page_subcategory_level_2 = CommercialJourneys.checkout;
        data.purchase_migration_type = this.journeyTagg.purchase_migration_type;
        data.purchase_product_classification = this.journeyTagg.purchase_product_classification;
        data['&&products'] = this.journeyTagg.products;
        data.promotion_name = this.journeyTagg.promotion_name;
        data.promotion_duration = this.journeyTagg.promotion_duration;
        data.promotion_code = this.journeyTagg.promotion_code;
        data.transaction_shipping = this.journeyTagg.transaction_shipping;
        data.company_portability = this.journeyTagg.company_portability;
        data.purchase_entrypoint = this.commercialOffersService.origin.replace('/', '');
        this.checkoutCommercialComponent.postLegalTermsSave();
        const purchaseID = this.checkoutCommercialComponent.personalData.termsId;
        data.purchaseID = purchaseID;
        this.commercialOffersService.setKeyJourneyTagg('purchaseID', purchaseID);
        data['&&events'] = CommercialJourneys.purchase;
        this.tagging.view(page, data);
        break;
        case CommercialJourneys.Tagg.type.error:
          const ErrPage = Object.assign({}, tagging.CommercialJourneys.error.page);
          const ErrData = Object.assign({}, tagging.CommercialJourneys.error.data);
          ErrPage.page_name = this.taggingError.page_name;
          let code;
          if (this.taggingError.error?.ecode || this.taggingError.ecode) {
            code = this.taggingError.error?.ecode ? this.taggingError.error.ecode : this.taggingError.ecode;
          } else {
            code = this.taggingError.status;
          }
          if ( this.taggingError.url && this.taggingError.url.indexOf(errTagging.tienda) > -1 ) {
            ErrData.error_category = `${errTagging.tienda}_${code}`;
          } else {
            ErrData.error_category = `${errTagging.dxl}_${code}`;
          }
          ErrData.error_description = this.taggingError.message;
          ErrPage.page_section = this.taggingError.page_section;
          ErrPage.page_subcategory_level_1 = this.taggingError.page_subcategory_level_1;
          ErrData.previous_pagename = this.taggingError.previous_pagename;
          ErrData.error_code = code;
          ErrData.journey_type = this.taggingError.journey_type ? this.taggingError.journey_type : '';
          const error = Object.assign({}, ErrPage, ErrData)
          this.tagging.sendViewState(error);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceCurrencyRoundZero'
})
export class SliceCurrencyRoundZeroPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let isNegative = false;
    if (value) {
      if (value.indexOf('-') !== -1) {
        value = value.replace('-', '');
        isNegative = true;
      }

      const lastValue = value.slice(value.lastIndexOf('.')).replace('.', ',')
      let output = value.toString().slice(1).replace(/\,/g, '.').slice(0, value.lastIndexOf('.') - 1);
      output = output + (lastValue !== ',0' && lastValue !== ',00' ? lastValue : '') ;
      return (isNegative ? '-' : '') + output;
    }
    else {

      return '';
    }

  }

}

<div *ngIf="displayBanner" class="app-from-web">
  <div class="logo-container">
    <img id="vodafoneLogo" class="voda-logo" alt="vodafone-logo" [src]="imagePath" />
  </div>

  <div class="close-container">
    <span id="closeButton" (click)="xButtonClicked()" class="icon-close"></span>
  </div>

  <div class="texts">
    <div id="nativeBannerTitle" class="text-content">
      {{ 'common.messagesList.nativeBanner.title' | translate }}
    </div>
    <div id="nativeBannerDescription" class="description">
      {{ 'common.messagesList.nativeBanner.description' | translate }}
    </div>
  </div>

  <div class="flex btns-container">
    <button id="nativeBannerOpenApp" (click)="openAppOrStore()" class="btn btn-mvl open">
      {{ 'common.messagesList.nativeBanner.confirmButton.text' | translate }}
    </button>
    <button id="nativeBannerRegister" (click)="register()" class="btn btn-mvl create">
      {{ 'login.loginForm.buttonsList.registerMVA10.extLinkText' | translate }}
    </button>
  </div>
</div>

<div *ngIf="(promotions.titlefooter !== '') && (promotions.linkFooter !== '')" class="promotions">
    <div *ngIf="promotions.flagPromotion">
        <div *ngIf="promotions.titlePromo" class="row">
            <div class="col-xs-12">
                <p id="sharedPromotionsComponentTitlePromo" class="titleVodafone" [innerHTML]="promotions.titlePromo"></p>
            </div>
        </div>
        <div *ngIf="(promotions.descriptionPromo || promotions.iconPromo) && (!promotions.align || promotions.align === 'v' || promotions.align === 'V')" class="row vertical__container">
            <div *ngIf="promotions.descriptionPromo" [ngClass]="{'col-xs-8': promotions.iconPromo, 'col-xs-12': !promotions.iconPromo}">
                <h3 id="sharedPromotionsComponentDescriptionPromoVertical" [innerHTML]="promotions.descriptionPromo"></h3>
            </div>
            <div *ngIf="promotions.iconPromo" [ngClass]="{'col-xs-4': promotions.descriptionPromo, 'col-xs-12': !promotions.descriptionPromo}">
                <div class="img">
                    <img id="sharedPromotionsComponentIconPromoVertical" src="{{promotions.iconPromo | imgFullPath}}" [alt]="promotions.descriptionPromo">
                </div>
            </div>
        </div>
        <div *ngIf="(promotions.descriptionPromo || promotions.iconPromo) && (promotions.align === 'h' || promotions.align === 'H')" class="row horizontal__container">
            <div *ngIf="promotions.iconPromo" class="row">
                <div class="col-xs-12">
                    <ul>
                        <li><img id="sharedPromotionsComponentIconPromoHorizontal" src="{{promotions.iconPromo | imgFullPath}}" [alt]="promotions.descriptionPromo"></li>
                    </ul>
                </div>
            </div>
            <div *ngIf="promotions.descriptionPromo" class="col-xs-12">
                <h3 id="sharedPromotionsComponentDescriptionPromoHorizontal" [innerHTML]="promotions.descriptionPromo"></h3>
            </div>
        </div>
    </div>
    <div *ngIf="promotions.titlefooter" class="row mva10-u--mb2x">
        <div class="col-xs-7">
            <h4 id="sharedPromotionsComponentTitlefooter" [innerHTML]="promotions.titlefooter"></h4>
        </div>
        <div class="col-xs-5 moreInfo" (click)="secondaryClick()">
            <a id="sharedPromotionsComponentLinkFooter" class="text text--red" [innerHTML]="promotions.linkFooter"></a>
            <mva10-c-icon class= "iconMoreInfo" [size]="24" [iconId]="'icon-help-circle'"></mva10-c-icon>
        </div>
    </div>
    <div *ngIf="promotions.descriptionCUL" class="row">
        <div class="col-xs-12">
            <p id="sharedPromotionsComponentDiscountCUL" class="text" [innerHTML]="promotions.descriptionCUL"></p>
        </div>
    </div>
    <div *ngIf="promotions.linkPromo" class="row">
        <div class="col-xs-12">
            <span *ngIf="promotions.commitment">
                <a id="sharedPromotionsComponentWithPermanence" (click)="primaryClick()" class="link underlined-link" [innerHTML]="promotions.linkPromo"></a>
            </span>
            <span id="sharedPromotionsComponentWithoutPermanence" *ngIf="!promotions.commitment" class="link" [innerHTML]="promotions.linkPromo"></span>
        </div>
    </div>
    <div *ngIf="promotions.descriptionFooter" class="row mva10-u--mb4x">
        <div class="col-xs-12">
            <p id="sharedPromotionsComponentDescriptionFooter" class="text" [innerHTML]="promotions.descriptionFooter"></p>
        </div>
    </div>
</div>
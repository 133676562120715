<div class='smartpay-payment-container'>
    <div class="paymnet-card-hint" *ngIf='smartPayService.showPaymentCardTitle' id='smartpayPaymentCardHint'>
        {{'v10.productsServices.smartPay.PyS.benefits.method.new' | translate}}</div>
    <mva10-c-card class="smartpay-payment-card">
        <div class='tariff-info-container'>
            <p class='tariff-name' id='smartpayPaymentCardTariffName'>{{tariffService.Tariff.Name}}</p>
            <p class='tariff-Fee' id='smartpayPaymentCardTariffFee'>{{tariffFeeAmount}}</p>
        </div>
        <div class='tariff-renewal-container' *ngIf='isTariffActive'>
            <p class='renewal-title' id='smartpayPaymentCardRenewalTitle'>
                {{'v10.productsServices.smartPay.PyS.easypay_cards.paymentdate' | translate}}</p>
            <p class='renewal-date' id='smartpayPaymentCardRenewalDate'>{{tariffExpiryDate}}</p>
            <p class='renewal-hint' id='smartpayPaymentCardRenewalHint' *ngIf='isSmartPay'>
                {{'v10.productsServices.smartPay.PyS.easypay_cards.tariffRenewalHint' | translate}}</p>
        </div>
        <div class='credit-card-container'>
            <div class='credit-card-title-container'>
                <div class='credit-card-title-with-iIcon-container'>
                    <p class='credit-card-title' id='smartpayPaymentCardCardTitle'>
                        {{'v10.productsServices.smartPay.PyS.easypay_cards.paymentMethod'|translate}}
                    </p>
                </div>
                <button (click)='clickOnChangeButton()' *ngIf='smartPayService.hasCreditCard'  
                    id="smartpayPaymentCardChangeCard" class='change-credit-card-button'>
                    {{'v10.productsServices.smartPay.common.change'|translate}}
                </button>
            </div>
            <div class='credit-card-info-container'>
                <img class='credit-card-icon' *ngIf="smartPayService.selectedCreditCard?.creditCardImage"
                    id="smartpayPaymentCardSelectedCardIcon"
                    [src]="smartPayService.selectedCreditCard?.creditCardImage" />
                <p class='credit-card-number' id="smartpayPaymentCardSelectedCardNumber">
                    {{smartPayService.selectedCreditCard.creditCardNumber}}
                </p>
            </div>
            <button type="button" (click)="navigateToSaveCreditCardJourney()" id="smartpayPaymentCardCTA"
                *ngIf="smartPayService.showAddNewCardButton" class="add-new-credit-card-button">
                {{ 'v10.productsServices.smartPay.PyS.easypay_cards.addCreditCardButton' | translate }}
            </button>
        </div>
    </mva10-c-card>
</div>
import { map, catchError } from 'rxjs/operators';
import { CommercialJourneys, entryPointsMVA10 } from './../constants/defines';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import { TranslateService } from '@ngx-translate/core';
import { EntryPointModel, EntryPointResponseModel } from '../../models/entry-points.model';
import { Observable, throwError } from 'rxjs';
import { CacheEntrypointsService } from './cache-entrypoints.service';
import { CtcOverlayModel } from '../../models/ctc-overlay.model';
import { HeadersDataOptions } from '../models/dxl-header-data-options.model';
import { Router } from '@angular/router';
import { TrayConfigurationService } from '../../mva10/dashboard/services/tray-configuration.service';
import { marketplaceEntryPoints } from '../../marketplace/Interfaces/marketplace-defines';
import { LeftMenuWs10Service } from '../../mva10/shared/services/left-menu-ws10.service';

@Injectable()
export class EntryPointsService {
  public entryCode: string;
  public analyticsName: string;
  public flows: EntryPointModel[];
  public laEntryPoint: EntryPointResponseModel;
  public pegaEntryPoint: EntryPointModel;
  public entryPointDebtOtError: boolean = false;
  public serviceId?: string;
  public hasMktpEntrypoint: boolean;

  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    public cacheEntrypointsService: CacheEntrypointsService,
    public router: Router,
    public trayConfigurationService: TrayConfigurationService,
    public leftMenuWs10Service: LeftMenuWs10Service
  ) { }

  private headersData(): HeadersDataOptions {
    const headers: HttpHeaders = new HttpHeaders();
    const options: HeadersDataOptions = {
      headers: headers,
      withCredentials: true
    };
    return options;
  }

  getEntryPointsLightValidations(
    code: string,
    screenCode: string,
    customerAccountId: string,
    maxNumber: number,
    serviceId: string,
    version: string,
    flow: string
  ): Observable<EntryPointResponseModel> {
    const entrypointFlowTreatments: string[] = this.translate.instant('v10.flows.screen.SHOP.treatment_flows');
    const entrypointHasFlowValidation: boolean = entrypointFlowTreatments.includes(code);
    const hasFlowValidation: boolean = entrypointHasFlowValidation && !!flow;

    let url: string = '';
    const chosenScreenCode: string = hasFlowValidation ? flow : screenCode;
    url = API_URLS.EntryPointManagement.entryPointsByCode
      .replace('{screenCode}', chosenScreenCode)
      .replace('{customerAccountId}', customerAccountId)
      .replace('{maxNumber}', String(maxNumber))
      .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel);
    if (serviceId) {
      url = url.concat('&serviceId=', serviceId);
    }
    if (version) {
      url = url.concat('&version=', version);
    }
    if (!hasFlowValidation) {
      url = url.concat('&code=', code);
    }

    const options: HeadersDataOptions = this.headersData();
    return this.http.get(url, options).pipe(map((res: EntryPointResponseModel) => {
      return res;
    }));
  }

  getS4UEntryPoints (
    screenCode: string,
    customerAccountId: string,
    maxNumber: string,
    serviceId: string,
    version: string,
    group: string
  ): Observable<EntryPointResponseModel> {
    let url: string = '';

    url = API_URLS.EntryPointManagement.entryPointsWithGroup
          .replace('{screenCode}', screenCode)
          .replace('{customerAccountId}', customerAccountId)
          .replace('{maxNumber}', maxNumber)
          .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel)
          .replace('{serviceId}', serviceId)
          .replace('{version}', version)
          .replace('{group}', group);

    const options: HeadersDataOptions = this.headersData();
    return this.http.get(url, options).pipe(map((res: EntryPointResponseModel) => {
      this.cacheEntrypointsService.setScreenCode(screenCode);
      this.cacheEntrypointsService.setEntryPoints(res);
      return res;
    }));
  }

  getEntryPoints(
    screenCode: string,
    customerAccountId: string,
    maxNumber?: number,
    insideJourney?: boolean,
    serviceId?: string,
    version?: string,
    cartId?: string,
  ): Observable<any> {
    let url = '';
    if (maxNumber) {
      url = API_URLS.EntryPointManagement.entryPointsWithMaxNumber.replace('{screenCode}', screenCode)
        .replace('{customerAccountId}', customerAccountId)
        .replace('{maxNumber}', String(maxNumber))
        .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel);
    } else {
      url = API_URLS.EntryPointManagement.entryPointsWithOutMaxNumber.replace('{screenCode}', screenCode)
        .replace('{customerAccountId}', customerAccountId)
        .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel);
    }

    if (serviceId) {
      url = url.concat('&serviceId=', serviceId);
    }
    if (version) {
      url = url.concat('&version=', version);
    }
    if (cartId && cartId !== '') {
      url = url.concat('&cartId=', cartId);
    }

    const options = this.headersData();
    return this.http.get(url, options).pipe(map((res: EntryPointResponseModel) => {
      /** Set Cache for EntryPoints*/
      this.cacheEntrypointsService.setScreenCode(screenCode);
      const _resEntryPoints: any = (insideJourney) ? this.logicEntry(res, screenCode) : res;
      this.cacheEntrypointsService.setEntryPoints(_resEntryPoints);
      this.cacheEntrypointsService.setEntryPointsByServiceID({serviceID: serviceId, entryPoint: _resEntryPoints});
      this.checkHasMktpEntrypoint(res);
      return _resEntryPoints;
    }), catchError(error => {
      this.checkHasMktpEntrypoint();
      return throwError(error);
    }));
  }
  logicEntry(res, screenCode) {
    this.flows = res.entryPoints.filter(function (item: EntryPointModel): boolean {
      return item.flow === screenCode || (item.flow === entryPointsMVA10.LAHI && screenCode === entryPointsMVA10.LAD);
    });
    if (this.flows) {
      if (!this.analyticsName) {
        this.translate.get(`v10.flows.nav.${screenCode}`).subscribe((data) => {
          this.analyticsName = data.analyticsName ? data.analyticsName : entryPointsMVA10.default;
        });
      }
      return this.flows;
    } else {
      return [];
    }
  }

  public identifyOTorDebt(res: EntryPointResponseModel, screenCode: string): void {
    const hasHSR: boolean = res.entryPoints.some(ep => ep.flow === entryPointsMVA10.HSR);
    if (screenCode === entryPointsMVA10.HSR && !hasHSR) {
      this.translate.get(`v10.flows.customization.${res.entryPoints[0].code}`).subscribe(data => {
        const link: string = data.link;
        if (link) {
          this.router.navigate([link], {
            queryParams: {
              scenario: res.entryPoints[0].code,
            }
          });
        } else {
          this.entryPointDebtOtError = true;
        }
      });
    }
  }

  public initCtcWcs(code: string): CtcOverlayModel {
    let ctc: CtcOverlayModel;
    this.translate.get('v10.flows.customization').subscribe(text => {
      if (text[code].ctcActive === 'true' && text[code].CTC) {
        ctc = new CtcOverlayModel();
        ctc.title = text[code].CTC.title;
        ctc.text = text[code].CTC.text;
        ctc.buttonText = text[code].CTC.textCTA;
        ctc.number = text[code].CTC.num;
      }
    });
    return ctc;
  }
  public searchEntryPoint(_listEntryPoints: EntryPointResponseModel): EntryPointModel {
    return _listEntryPoints.entryPoints.find((entryPoint: EntryPointModel) =>
      (entryPoint?.code?.toLowerCase() === this.entryCode?.toLowerCase()));
  }

  public sortEntryPoints(_entryPoints: EntryPointModel[]): EntryPointModel[] {
    return _entryPoints.sort((epA: EntryPointModel, epB: EntryPointModel) => epA.order - epB.order);
  }

  public getEntryPointByCode(entryPointCode: string): EntryPointModel {
    return this.cacheEntrypointsService.getEntryPoints().entryPoints.find((entryPoint: EntryPointModel) =>
      (entryPoint.code.toLowerCase() === entryPointCode.toLowerCase()));
  }

  public getEntryPointByServiceID(serviceID: string): EntryPointResponseModel {
   return this.cacheEntrypointsService.getEntryPointsByServiceID()
    .find(entry => entry?.serviceID === serviceID).entryPoint;
  }

  public checkIfEntryPointIsCachedPerService(serviceID: string): boolean {
   return this.cacheEntrypointsService.getEntryPointsByServiceID()
    .some(entry => entry?.serviceID === serviceID);
  }

  checkHasMktpEntrypoint(entryPointsResponse?: EntryPointResponseModel): void {
    this.hasMktpEntrypoint = entryPointsResponse ?
    entryPointsResponse?.entryPoints.some(ep => marketplaceEntryPoints.includes(ep.code)) : false;
    this.trayConfigurationService.checkMktpEntrypoint(this.hasMktpEntrypoint);
    this.leftMenuWs10Service.setLeftMenuButton(this.hasMktpEntrypoint);
  }

}

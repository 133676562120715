import { DeliveryHours } from './delivery-hours.model';
import { DeliveryDate } from './delivery-date.model';

export class DeliveryModality {
  xCanal: string;
  xTipoCliente: string;
  xPerfil: string;
  cdTipoProceso: string;
  dsTipoProceso: string;
  horasEntrega: DeliveryHours[];
  fechasEntrega: DeliveryDate[];

}

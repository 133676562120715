<div class="mva10-overlay-login" [ngStyle]="{'z-index':_Mva10OverlayService.trayIndex }">
  <div class="overlay-container" (click)="close()" [ngStyle]="{display:_Mva10OverlayService._isOpened?'block':'none'}" *ngIf="!_Mva10OverlayService.fullScreenTray">
  </div>
  <div class="container-of-container" [@openClose]="_Mva10OverlayService.stopTrayAnimation ? 'none' : (_Mva10OverlayService._isOpened ? 'open' : 'closed')" [ngClass]="{'tray-full-screen': _Mva10OverlayService.fullScreenTray, 'close-animation-tray': _Mva10OverlayService.stopTrayAnimation && _Mva10OverlayService.forceCloseTray, 'open-animation-tray': _Mva10OverlayService.stopTrayAnimation && _Mva10OverlayService.forceOpenTray}">
    <div class="swipe-container" (click)="close()" *ngIf="!_Mva10OverlayService.fullScreenTray">
      <div class="swipe-btn"></div>
    </div>
    <div class="overlay-content-container"  [ngClass]="{'dynamicComponent': !_Mva10OverlayService.showErrorOverLay}">
      <div *ngIf="_Mva10OverlayService.showErrorOverLay">
        <div class="title-container" >
            <div class="top-up-title" >
                <p *ngIf="_Mva10OverlayService._config?.over_fixedTitle"
                  [ngClass]="{'txt-not-bold': _Mva10OverlayService._config?.over_fixedTitle_NotBold}">{{_Mva10OverlayService._config?.over_fixedTitle}}</p>
              </div>
              <div class="exit-logo">
                <span  (click)="close()" class="icon icon-close"></span>
              </div>
        </div>
        <div class="img-container image-cont">
          <img [src]="appService.getImgFullPath(_Mva10OverlayService._config?.over_iconUrl)" alt="">
        </div>
        <div class="content-container text-center">
          <div class="title-container">
            <p class="title-txt" [innerHtml]='_Mva10OverlayService._config?.over_tittle'></p>
          </div>
          <div class="txt-container">
            <p class="txt" [ngClass]="{'txt-bold': _Mva10OverlayService._config?.over_boldDesc}" [innerHtml]='_Mva10OverlayService._config?.over_desc'>
            </p>
          </div>
          <div class="txt-container">
            <p class="txt"  [innerHtml]='_Mva10OverlayService._config?.over_desc_secondLine'>
            </p>
          </div>
          <div class="button-container">
            <button class="gray-btn btn" (click)='onfirstBtnClick()' [ngClass]="{'red-btn': _Mva10OverlayService._config?.over_firstBtnRed}"
              *ngIf="_Mva10OverlayService._config?.over_firstBtnWCS"
              id="SpMva10Overlay_btn_primaryClick">{{_Mva10OverlayService._config.over_firstBtnWCS}}</button>
            <button class="white-btn btn" (click)='onSecondBtnClick()'
              *ngIf="_Mva10OverlayService._config?.over_secondBtnWCS"
              id="SpMva10Overlay_btn_secondaryClick">{{_Mva10OverlayService._config.over_secondBtnWCS}}</button>
          </div>
        </div>
      </div>
      <div #dynamicComponentRef></div>
    </div>
  </div>
</div>
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GenereicNavigatorModel } from '../../models/generic-navigator.mode';

@Component({
  selector: 'sp-generic-navigator',
  templateUrl: './generic-navigator.component.html',
  styleUrls: ['./generic-navigator.component.scss']
})
export class GenericNavigatorComponent {
  @Input() navigator: GenereicNavigatorModel;
  @Output() onNavigate = new EventEmitter();

  navigateTo() {
    this.onNavigate.emit();
  }
}

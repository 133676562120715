export enum PaymentErrorCategory {
    // general
    general = 'Genérico',
    invalidCreditCard = 'Tarjeta inválida',
    invalidCreditCardInfo = 'Datos de Tarjeta',
    CreditCardTypeNotSupported = 'Tarjeta no admitida',
    SystemNotAvailable = 'sistema no disponible',
    ReplicaMode = 'replica mode',
    PaymentApiFails = 'tecnico',
    TimeOut = 'timeout',
    // prepaid
    Notprepaid = 'Notprepaid',
    NotvodafoneNumber = 'NotvodafoneNumber',
    Notactive = 'not active',
    TopupInProgress = 'TopupInProgress',
    refundSuccess = 'refundSuccess',
    operationNotExist = 'operationNotExist',
    refundFailed = 'refundFailed',
    mortiroloKO108 = 'mortiroloKO108',
    // anonymous
    billDetailsFailure = 'billDetailsFailure',
    anonymousIdentifierFailure = 'anonymousIdentifierFailure',
    billDetailsFailureNoOTP = 'billDetailsFailureNoOTP',
    noDebtFailure = 'noDebtFailure',
    codeRetrialFailure = 'codeRetrialFailure',
    codeRenewFailure = 'codeRenewFailure',
    codeTechnicalError = 'codeTechnicalError',
    // wallet related
    emptyWallet = 'emptyWallet',
    loadingWalletFailed = 'loadingWalletFailed',
    // promo code
    invalidPromoCode = 'invalidPromoCode',
    validPromoButInvalidRechargeAmount = 'validPromoButInvalidRechargeAmount',
    validPromoCodeButPromotionNotApplied = 'validPromoCodeButPromotionNotApplied'
}

import { Injectable } from '@angular/core';
import { SubscriptionService } from '../../../core/services/subscription.service';
// import shajs from 'sha.js';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { OnBehalOfTypes, CONFIG, MSISDNPrefix } from '../../constants/defines';
import { ISingleSignOnURL } from 'src/app/models/single-sign-on-url.model';
import { HashService } from '../../../core/services/hash.service';
import { ChatBotTokenRequest } from 'src/app/models/chat-bot-token-request.model';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { AuthenticateService } from '../../../core/services/authenticate.service';
import { API_URLS } from '../../constants/routes-config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SingleSignOnDestinationTypes } from './models/single-sign-on-destination-types.enum';
import { Mva10FullModslServiceService } from '../mva10-full-modsl-service.service';
import { TaggingClickDataModel, TaggingViewModel } from 'src/app/models/tagging.model';
import { tagging } from 'src/config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { SingleSignOnTypes } from './models/single-sign-on-types.enum';
import { ISingleSignOnResponseBody } from './models/single-sign-on-response-body.model';
import { DXLError } from 'src/app/models/dxl-error.model';
import { Subscription } from 'rxjs';
import { EnvironmentName } from '../../enums/environment-name.enum';
import * as constants from '../../constants/defines';

@Injectable({
  providedIn: 'root'
})
export class SingleSignOnService {

  public singleSignOnType: string;
  public hKey: string;

  constructor(
    public subscriptionService: SubscriptionService,
    public configurationService: ConfigurationService,
    public hashService: HashService,
    public appService: AppService,
    private auth: AuthenticateService,
    private http: HttpClient,
    private translate: TranslateService,
    public fullModalServiceService: Mva10FullModslServiceService,
    private tagging: TaggingHelperService,
  ) { }

  hasOneNumberSingleSignOn(): boolean {
    return this.configurationService.configuration.showOneNumberSSO;
  }

  hasSecureNetSingleSignOn(): boolean {
    return (this.translate.instant('v10.sso.securenet.shcode')) === constants.stringOfTrue;
  }
  singleSignOnRedirection(singleSignOnType: SingleSignOnTypes): void {
    this.singleSignOnType = singleSignOnType;
    switch (singleSignOnType) {
      case SingleSignOnTypes.OneNumber:
        this.hKey = this.getHkey();
        this.oneNumberSingleSignOnRedirection();
        break;
      case SingleSignOnTypes.SecureNet:
        this.secureNetSingleSignOnRedirection();
        break;
      case SingleSignOnTypes.VodafoneCare:
        this.vodafoneCareSingleSignOnRedirection();
        break;
    }
  }

  async vodafoneCareSingleSignOnRedirection(): Promise<void> {
    const domain: string = this.getCareSingleSignOnDomain();
    const sharedCode: string = await this.getSingleSignOnShareCode(SingleSignOnDestinationTypes.VodafoneCare);
    const hmsisdn: string = this.getHmsisdn(true);
    const url: string = `${domain}?sharing_code=${sharedCode}&OPCO=${CONFIG.COUNTRY_CODE}&source=mva_app&hashed_msisdn=${hmsisdn}`;
    window.open(url);
  }

  async oneNumberSingleSignOnRedirection(): Promise<void> {
    const url: ISingleSignOnURL = {
      domain: this.getOneNumberSingleSignOnDomain(),
      sharecode: await this.getSingleSignOnShareCode(SingleSignOnDestinationTypes.OneNumber),
      market: CONFIG.COUNTRY_CODE,
      source: CONFIG.SOURCE,
      hmsisdn: this.getHmsisdn(),
      hkey: this.hKey
    }
    const stringUrl: string = `${url.domain}?shared_code=${url.sharecode}&market=${url.market}&source=${url.source}&hmsisdn=${url.hmsisdn}&hkey=${url.hkey}`;
    this.tagViewVodafoneOneNumber();
    window.open(stringUrl);
  }

  async secureNetSingleSignOnRedirection(): Promise<void> {
    const url: ISingleSignOnURL = {
      domain: this.getSecureNetSingleSignOnDomain(),
      sharecode: await this.getSingleSignOnShareCode(SingleSignOnDestinationTypes.SecureNet),
      market: CONFIG.COUNTRY_CODE,
    }
    const stringUrl: string = `${url.domain}?sharing_code=${url.sharecode}&opco=${url.market}`;
    this.tagViewVodafoneSecureNet();
    window.open(stringUrl);
  }

  async getSingleSignOnShareCode(destination: string): Promise<string> {
    try {
      this.appService.showVodaFullLoader = true;
      await this.auth.refreshTokens(true).toPromise();
      const WCSData: ChatBotTokenRequest = {
        clientId : environment.CLIENT_ID,
        grantType : OnBehalOfTypes.grantType,
        subjectTokenType : OnBehalOfTypes.subjectTokenType,
        actorToken : environment.actorTokenForGroup,
        actorTokenType : OnBehalOfTypes.actorTokenType,
        chatbotScope : '',
      };
      const subjectToken: any = await this.appService.getChatBotToken(WCSData).toPromise();
      const getShareCodeResponse: ISingleSignOnResponseBody = await this.requestShareCode(subjectToken.access_token, destination);
      return getShareCodeResponse.shared_code;
    } catch (err) {
      throw err;
    } finally {
      this.appService.showVodaFullLoader = false;
    }
  }

  async requestShareCode(token: string, destination: string): Promise<ISingleSignOnResponseBody> {
    try {
      const url: string = API_URLS.sharedCodeSSO;
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
      const options: { headers: HttpHeaders } = {
        headers
      };
      const body: HttpParams = new HttpParams()
        .set('token', token)
        .set('client', CONFIG.CLIENT_SOURCE)
        .set('destination', destination);
      const data: ISingleSignOnResponseBody  = await this.http.post<ISingleSignOnResponseBody>(url, body.toString(), options).toPromise();
      return data;
    } catch (err) {
      this.processShareCodeRequestError(err);
      throw err;
    }
  }

  /** @param noHkey concat or not random number to msisdn */
  getHmsisdn(noHkey?: boolean): string {
    const msisdn: string = this.getMsisdn();
    return noHkey ? this.hashService.getSha256(msisdn) : this.hashService.getSha256(msisdn + this.hKey);
  }

  getHkey(): string {
    return (Math.random() * 1e32).toString(36).substring(2, 12);
  }

  getMsisdn(): string {
    return MSISDNPrefix + this.subscriptionService.customerData.currentService.id;
  }

  getOneNumberSingleSignOnDomain(): string {
    return environment.environmentName === EnvironmentName.PROD
      ? this.translate.instant('v10.sso.oneNumber.urlPro')
      : this.translate.instant('v10.sso.oneNumber.urlPrePro');
  }

  getSecureNetSingleSignOnDomain(): string {
    return environment.environmentName === EnvironmentName.PROD
      ? this.translate.instant('v10.sso.securenet.urlPro')
      : this.translate.instant('v10.sso.securenet.urlPrePro');
  }

  getCareSingleSignOnDomain(): string {
    return environment.environmentName === EnvironmentName.PROD
      ? this.translate.instant('v10.sso.vodafoneCare.urlPro')
      : this.translate.instant('v10.sso.vodafoneCare.urlPrePro');
  }

  processShareCodeRequestError(err: DXLError): void {
    switch (this.singleSignOnType) {
      case SingleSignOnTypes.OneNumber:
        this.instantiateOneNumberErrorModal(err);
        break;
      case SingleSignOnTypes.SecureNet:
        this.instantiateSecureNetErrorModal(err);
        break;
    }
  }

  tagViewVodafoneOneNumber(): void {
    const data: TaggingViewModel = Object.assign({},
      tagging.onePlus.vodafoneOneNumber.view
    );
    this.tagging.view(null, data);
  }

  tagViewVodafoneSecureNet(): void {
    const data: TaggingViewModel = Object.assign({},
      tagging.productAndServices.secureNet.vodafoneSecureNet.view
    );
    this.tagging.view(null, data);
  }

  instantiateOneNumberErrorModal(err: DXLError): void {
    this.taggingOneNumberViewOverlayGenericError(err);
    const wcsErrorData: any = this.translate.instant('v10.common.literals');
    this.fullModalServiceService.img = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png');
    this.fullModalServiceService.errorTitle = wcsErrorData?.msg.error.ups_seems;
    this.fullModalServiceService.errorSubtitle = wcsErrorData?.msg.error.could_not_getdata;
    this.fullModalServiceService.errorButton1 = wcsErrorData?.understood_C;
    const subsCloseOverlay: Subscription = this.fullModalServiceService.closeOverlaySubject.subscribe(() => {
      this.taggingOneNumberEventCloseBtn();
      subsCloseOverlay.unsubscribe();
    });
    const subsButtonErrorClicked: Subscription = this.fullModalServiceService.errorButton1Subject.subscribe(() => {
      this.taggingOneNumberEventPrimaryBtn();
      subsButtonErrorClicked.unsubscribe();
    });
    this.appService.showErrorFullModal = true;
  }

  instantiateSecureNetErrorModal(err: DXLError): void {
    this.taggingSecureNetViewOverlayGenericError(err);
    const wcsErrorData: any = this.translate.instant('v10.common.literals');
    this.fullModalServiceService.img = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png');
    this.fullModalServiceService.errorTitle = wcsErrorData?.msg.error.ups_seems;
    this.fullModalServiceService.errorSubtitle = wcsErrorData?.msg.error.could_not_getdata;
    this.fullModalServiceService.errorButton1 = wcsErrorData?.understood_C;
    const subsCloseOverlay: Subscription = this.fullModalServiceService.closeOverlaySubject.subscribe(() => {
      this.taggingSecureNetEventCloseBtn();
      subsCloseOverlay.unsubscribe();
    });
    const subsButtonErrorClicked: Subscription = this.fullModalServiceService.errorButton1Subject.subscribe(() => {
      this.taggingSecureNetEventPrimaryBtn();
      subsButtonErrorClicked.unsubscribe();
    });
    this.appService.showErrorFullModal = true;
  }

  taggingOneNumberViewOverlayGenericError(serverError: DXLError): void {
    const data: TaggingViewModel = Object.assign({},
      tagging.onePlus.oneNumberSingleSignOnOverlayGenericError.view
    );
    if (serverError) {
      data.error_code = serverError.error?.ecode;
      data.error_category = serverError.error?.error;
      data.error_description = serverError.error?.description;
    }
    this.tagging.view(null, data);
  }

  taggingOneNumberEventCloseBtn(): void {
    const data: TaggingClickDataModel = Object.assign({},
      tagging.onePlus.oneNumberSingleSignOnOverlayGenericError.closebtn
    );
    this.tagging.track(
      tagging.onePlus.oneNumberSingleSignOnOverlayGenericError.closebtn.event_name,
      data
    );
  }

  taggingOneNumberEventPrimaryBtn(): void {
    const data: TaggingClickDataModel = Object.assign({},
      tagging.onePlus.oneNumberSingleSignOnOverlayGenericError.primarybtn
    );
    this.tagging.track(
      tagging.onePlus.oneNumberSingleSignOnOverlayGenericError.primarybtn.event_name,
      data
    );
  }

  taggingSecureNetViewOverlayGenericError(serverError: DXLError): void {
    const data: TaggingViewModel = Object.assign({},
      tagging.productAndServices.secureNet.securenetSingleSignOnOverlayGenericError.view
    );
    if (serverError?.error) {
      data.error_code = String(serverError.error.ecode);
      data.error_category = serverError.error.error;
      data.error_description = serverError.error.message;
    }
    this.tagging.view(null, data);
  }

  taggingSecureNetEventCloseBtn(): void {
    const data: TaggingClickDataModel = Object.assign({},
      tagging.productAndServices.secureNet.securenetSingleSignOnOverlayGenericError.closebtn
    );
    this.tagging.track(
      tagging.productAndServices.secureNet.securenetSingleSignOnOverlayGenericError.closebtn.event_name,
      data
    );
  }

  taggingSecureNetEventPrimaryBtn(): void {
    const data: TaggingClickDataModel = Object.assign({},
      tagging.productAndServices.secureNet.securenetSingleSignOnOverlayGenericError.primarybtn
    );
    this.tagging.track(
      tagging.productAndServices.secureNet.securenetSingleSignOnOverlayGenericError.primarybtn.event_name,
      data
    );
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../config/tagging-config';
import { PaymentTaggingJourney, PaymentTaggingObject, PaymentTaggingObjectArchetype, ThirdPartyURLParam } from '../../models/payment';
import { PaymentJourneyEnvironment } from '../../shared/enums/payment-journey-environment.enum';
import { PaymentJourneyDetail } from '../../shared/enums/payment-journey-detail.enum';
import { PaymentJourneySavedCard } from '../../shared/enums/payment-journey-saved-card.enum';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { PaymentJourny} from '../../shared/enums/payment-journy.enum';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingStateListModel } from 'src/app/models/new-tagging-state-list.model';

@Injectable()
export class PaymentTaggingService {
  constructor(private taggingHelperService: TaggingHelperService,
    private payNavSer: PaymentNavigationService,
    private translate: TranslateService,
    private newTagging: NewTaggingHelperService) { }

  /**
   * @param screenDetail page name
   * @param options optional parameter diff from screen to another
   * function to fill tagging object and fire tagging request
   */
  sendTaggingForPostPaidJourney(screenDetail: PaymentJourneyDetail,
    options?: ThirdPartyURLParam): void {
    const taggingObj: PaymentTaggingObject = new PaymentTaggingObject();
    if (this.payNavSer.paymentJourneyParams.paymentTaggingConfigArchetype?.folder) {
      this.sendTaggingForPostPaidJourneyArchetype(this.payNavSer.paymentJourneyParams.paymentTaggingConfigArchetype,
        screenDetail, options);
    } else {
      if (screenDetail === PaymentJourneyDetail.thirdPartyIframe) {
        taggingObj.pageName = Object.assign({}, this.payNavSer.paymentJourneyParams.paymentTaggingConfig.pageName);
      }
      taggingObj.journey = new PaymentTaggingJourney();
      taggingObj.journey.journey_name = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_name;
      taggingObj.journey.journey_category = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_category;
      taggingObj.journey.journey_subcategory = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_subcategory;
      taggingObj.journey.journey_type = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_type;
      this.checkExtraJourneyParamas(taggingObj);
      taggingObj.journey.journey_detail = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_detail ?? screenDetail;
      taggingObj.journey.journey_step = `${taggingObj.journey.journey_name }:${taggingObj.journey.journey_detail}`;
      taggingObj.journey.journey_card_selected =
        this.payNavSer.paymentJourneyParams.cardMask ? PaymentJourneySavedCard.savedCC : PaymentJourneySavedCard.newCC;
      taggingObj.journey.journey_environment =
        this.payNavSer.paymentJourneyParams.isAnonymous ? PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
      if ( options?.transactionId) {
        taggingObj.journey['payment_id'] = options.transactionId;
      }
      if (screenDetail === PaymentJourneyDetail.ok) {
        this.prepareSuccessTagging(taggingObj);
      }
      if (screenDetail === PaymentJourneyDetail.ko) {
        if (PaymentJourny.financedDevicesCancellation === this.payNavSer?.WCSJourneyKey) {
          this.prepareKoFinancedDevicesTagging(taggingObj, options);
        } else {
          this.prepareKoTagging(taggingObj, options);
        }
      }
      this.taggingHelperService.view(taggingObj.pageName, taggingObj.journey);
    }
  }

  /**
   * tagging with new archetype.
   */
  sendTaggingForPostPaidJourneyArchetype(taggingObj: PaymentTaggingObjectArchetype, screenDetail: PaymentJourneyDetail,
    options?: ThirdPartyURLParam): void {
    this.newTagging.getJsonTagging(taggingObj.folder).subscribe((data: NewTaggingJsonModel) => {
      let state: NewTaggingStateListModel;
      switch (screenDetail) {
        case PaymentJourneyDetail.thirdPartyIframe:
          state = data.page.stateList[taggingObj.pageName.state_id];
          state.custom_map = {...state.custom_map, ...taggingObj.pageName.custom_map};
        break;
        case PaymentJourneyDetail.ok:
          state = data.page.stateList[taggingObj.pageName_ok.state_id];
          state.custom_map = {...state.custom_map, ...taggingObj.pageName_ok.custom_map};
        break;
        case PaymentJourneyDetail.ko:
          state = data.page.stateList[taggingObj.pageName_ko.state_id];
          state.custom_map = {...state.custom_map, ...taggingObj.pageName_ko.custom_map};
        break;
      }
      this.newTagging.state(state);
  });
  }

  /**
   * prepare ok tagging (pagename, events, products).
   * @param taggingObj {PaymentTaggingObject} tagging object that contains pagename and journey.
   */
  prepareSuccessTagging(taggingObj: PaymentTaggingObject): void {
    taggingObj.pageName = Object.assign({}, this.payNavSer.paymentJourneyParams.paymentTaggingConfig.pageName_ok);
    taggingObj.journey['&&events'] = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.event_success;
    if (this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey['&&products']) {
      taggingObj.journey['&&products'] = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey['&&products'];
    }
  }

  /**
   * prepare ko tagging (pagename, events, errorList).
   * @param taggingObj {PaymentTaggingObject} tagging object that contains pagename and journey.
   * @param options {ThirdPartyURLParam} options to get errorCode and errorDesc from iframe if exist
   * or from static values if not exist.
   */
  prepareKoTagging(taggingObj: PaymentTaggingObject, options: ThirdPartyURLParam): void {
    taggingObj.pageName = Object.assign({}, this.payNavSer.paymentJourneyParams.paymentTaggingConfig.pageName_ko);
    taggingObj.journey['&&events'] = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.event_error;
    if (options && options.errorCode && options.errorDesc) {
      taggingObj.journey['error_list'] = tagging.staticVariables.paymentGenericError
        .replace('<error_code>', options.errorCode).replace('<error_desc>', options.errorDesc);
    } else {
      this.translate.get('v10.payment.itemsList.paymentError').subscribe(data => {
        taggingObj.journey['error_list'] = tagging.staticVariables.paymentGenericError
          .replace('<error_code>', data.staticErrorCode).replace('<error_desc>', data.staticErrorDesc);
      });
    }
  }
   /**
   * prepare ko tagging for financed devices (pagename, events, errorList).
   * @param taggingObj {PaymentTaggingObject} tagging object that contains pagename and journey.
   * @param options {ThirdPartyURLParam} options to get errorCode and errorDesc from iframe if exist
   * or from static values if not exist.
   */
  prepareKoFinancedDevicesTagging(taggingObj: PaymentTaggingObject, options: ThirdPartyURLParam): void {
    taggingObj.pageName = Object.assign({}, this.payNavSer.paymentJourneyParams.paymentTaggingConfig.pageName_ko);
    taggingObj.journey['&&events'] = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.event_error;
    if (options && options.errorCode && options.errorDesc) {
      taggingObj.journey['error_list'] = tagging.staticVariables.paymentFinancedDevicesGenericError
        .replace('<error_code>', options.errorCode).replace('<error_desc>', options.errorDesc);
    } else {
      this.translate.get('v10.payment.itemsList.paymentError').subscribe(data => {
        taggingObj.journey['error_list'] = tagging.staticVariables.paymentFinancedDevicesGenericError
          .replace('<error_code>', data.staticErrorCode).replace('<error_desc>', data.staticErrorDesc);
      });
    }
  }

  checkExtraJourneyParamas(taggingObj: PaymentTaggingObject): void {
    if (this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_process) {
      taggingObj.journey.journey_process = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_process;
    }
    if (this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_payment_type) {
      taggingObj.journey.journey_payment_type = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_payment_type;
    }
    if (this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_payment_method) {
      taggingObj.journey.journey_payment_method =
      this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.journey_payment_method;
    }
    if (this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.state_flow) {
      taggingObj.journey.state_flow = this.payNavSer.paymentJourneyParams.paymentTaggingConfig.journey.state_flow;
    }
  }
}

import { map } from 'rxjs/operators';
import { UtilitiesService } from './../shared/utils/utilities.service';
import { PurchasesAndSubscriptions } from './../models/purchases-subscriptions.model';
import { SimDataModel } from './../models/sim-data.model';
import { ServiceSettingsData } from './service-settings.data';
import { Injectable } from '@angular/core';
import * as JsonQuery from 'jsonpath/jsonpath';
import * as constants from '../shared/constants/defines';
import { ThirdPartyLandingModel, ServicesContainerModel } from '../models/third-party-landing.model';
import { PurchasesSubscriptionsDuration } from '../shared/enums/purchases-subscriptios-duration.enum';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { evict } from '../shared/decorators/evict.decorator';
import { ServiceModel } from '../models/service.model';
import { PremiumContent } from '../models/premium-content.model';
import { CancelSubscription } from '../models/product-cancel-subscription.model';
import { Observable } from 'rxjs';


@Injectable()
export class ServiceSettingsService {
  purchasesSubscriptions: Array<PurchasesAndSubscriptions>;
  node: ServicesContainerModel;
  nodesContainer: ServicesContainerModel[];
  ThirdPartyLandingArray: ThirdPartyLandingModel[];
  thirdPartyItem: ThirdPartyLandingModel;
  selectedService: ServiceModel;
  durationObj;
  subscriptionsPartialError: boolean;
  purchasesFullError: boolean;
  subscriptionsFullError: boolean;
  isRestrictionChanged: boolean;
  isFromProductAndService: boolean;
  constructor(public settingData: ServiceSettingsData, private utilitiesService: UtilitiesService,
    private translateService: TranslateService) { }

  getSIMProfileData(siteId: string, serviceId: string, serviceType?: string): Observable<any>  {
    return this.settingData.getSIMProfileData(siteId, serviceId, serviceType).pipe(map(res => {
      const simProfileData: SimDataModel = new SimDataModel();
      simProfileData.sim = JsonQuery.value(res, constants.JSON_PATHS.ServiceSettings.sim) || null;
      simProfileData.pins = JsonQuery.value(res, constants.JSON_PATHS.ServiceSettings.pins) || {};
      simProfileData.puks = JsonQuery.value(res, constants.JSON_PATHS.ServiceSettings.puks) || {};
      simProfileData.expiryDate = JsonQuery.value(res, constants.JSON_PATHS.ServiceSettings.expiryDate) || null;
      return simProfileData;
    }));
  }
  getThirdPartyData(serviceId: string) {
    this.ThirdPartyLandingArray = new Array<ThirdPartyLandingModel>();
    return this.settingData.getThirdPartyData(serviceId).pipe(map((res: any) => {
      if (res.items) {
        res.items.forEach(el => {
          const ThirdPartyLandingData: ThirdPartyLandingModel = new ThirdPartyLandingModel();
          ThirdPartyLandingData.parentId = JsonQuery.value(el, constants.JSON_PATHS.ServiceSettings.parentId) || null;
          ThirdPartyLandingData.isChargeToBill = JsonQuery.value(el, constants.JSON_PATHS.ServiceSettings.isChargeToBill) || false;
          ThirdPartyLandingData.name = JsonQuery.value(el, constants.JSON_PATHS.ServiceSettings.name) || null;
          this.translateService.get('payment.serviceSettings.restrictionCardsList').subscribe(wcs => {
            ThirdPartyLandingData.title = wcs[ThirdPartyLandingData.name].title;
            ThirdPartyLandingData.shortDescription = wcs[ThirdPartyLandingData.name].shortDesc;
            ThirdPartyLandingData.longDescription = wcs[ThirdPartyLandingData.name].longDesc;
            ThirdPartyLandingData.img = wcs[ThirdPartyLandingData.name].img;
            ThirdPartyLandingData.img2 = wcs[ThirdPartyLandingData.name].img2;
          })
          this.ThirdPartyLandingArray.push(ThirdPartyLandingData);
        });
      }
      return this.ThirdPartyLandingArray;
    }));

  }

  postUnlockSIM(siteId: string, msisdn: string, imei: string, email: string) {
    return this.settingData.postUnlockSIM(siteId, msisdn, imei, email).pipe(map(res => res));
  }

  deleteThirdParty(serviceId: string, subscriptionId: string) {
    return this.settingData.deleteThirdParty(serviceId, subscriptionId).pipe(map(res => res));
  }
  getPurchasesAndSubscriptions(serviceId: string) {
    this.subscriptionsFullError = false;
    this.purchasesFullError = false;
    this.subscriptionsPartialError = false;

    this.purchasesSubscriptions = new Array<PurchasesAndSubscriptions>();
    return this.settingData.getPurchasesAndSubscriptions(serviceId).pipe(map((res: any) => {
      if (res.status) {
        if (res.status.includes('-1')) {
          this.subscriptionsFullError = true;
        }
        if (res.status.includes('-2')) {
          this.subscriptionsPartialError = true;
        }
        if (res.status.includes('-3')) {
          this.purchasesFullError = true;
        }
      }

      const items = JsonQuery.value(res, constants.JSON_PATHS.PurchasesAndSubscriptions.Items);
      this.translateService.get('payment.serviceSettings.itemsList').subscribe(data => {
        this.durationObj = data;
      });
      items.map((item) => {
        this.purchasesSubscriptions.push(this.mapPurchasesAndSubscriptions(item))
      });
    }));
  }
  mapPurchasesAndSubscriptions(item) {

    const purchasesAndSubscriptionsData: PurchasesAndSubscriptions = new PurchasesAndSubscriptions();
    purchasesAndSubscriptionsData.type = JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.type) || null;
    purchasesAndSubscriptionsData.name = JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.name) || null;
    purchasesAndSubscriptionsData.price = JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.price) || '0';
    purchasesAndSubscriptionsData.priceUnit = JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.priceUnit) || null;
    purchasesAndSubscriptionsData.companyName = JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.companyName) || null;
    purchasesAndSubscriptionsData.companyTelephone =
      JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.companyTelephone) || null;
    purchasesAndSubscriptionsData.creationDate = this.utilitiesService.getDateFormatted(JsonQuery.value(item,
      constants.JSON_PATHS.PurchasesAndSubscriptions.creationDate)) || null;
    purchasesAndSubscriptionsData.subscriptionDetails =
      JsonQuery.value(item, constants.JSON_PATHS.PurchasesAndSubscriptions.subscriptionDetails) || null;
    if (purchasesAndSubscriptionsData.subscriptionDetails) {
      purchasesAndSubscriptionsData.subscriptionDetails.ableToUnsubscribe =
        JsonQuery.value(purchasesAndSubscriptionsData.subscriptionDetails,
          constants.JSON_PATHS.PurchasesAndSubscriptions.ableToUnsubscribe) || null;
      purchasesAndSubscriptionsData.subscriptionDetails.duration = this.getDurationText(
        JsonQuery.value(purchasesAndSubscriptionsData.subscriptionDetails,
          constants.JSON_PATHS.PurchasesAndSubscriptions.duration)) || null;
      purchasesAndSubscriptionsData.subscriptionDetails.endDate =
        JsonQuery.value(purchasesAndSubscriptionsData.subscriptionDetails, constants.JSON_PATHS.PurchasesAndSubscriptions.endDate) || null;
      purchasesAndSubscriptionsData.subscriptionDetails.subscriptionId =
        JsonQuery.value(purchasesAndSubscriptionsData.subscriptionDetails,
          constants.JSON_PATHS.PurchasesAndSubscriptions.subscriptionId) || null;

    }
    return purchasesAndSubscriptionsData;
  }

  @evict(constants.cachingKeys.Products)
  updatePaymentRestrictions(serviceId: string, code, changeToBill: boolean) {
   return this.settingData.updatePaymentRestrictions(serviceId, code, changeToBill).pipe(map(res => res));
  }

  cancelSubscriptionService(serviceId: string, code: string, originBackend: string,
    productOfferingId: string): Observable<CancelSubscription> {
    return this.settingData.cancelSubscriptionService(serviceId, code, originBackend, productOfferingId).pipe(map(res => res));
  }
  getDurationText(key) {
    if (key) {
      switch (key.toLowerCase()) {
        case PurchasesSubscriptionsDuration.Weekly.toLowerCase():
          return this.durationObj.weekly.body;
        case PurchasesSubscriptionsDuration.Monthly.toLowerCase():
          return this.durationObj.monthly.body;
        case PurchasesSubscriptionsDuration.Yearly.toLowerCase():
          return this.durationObj.yearly.body;
        default:
          return '';
      }
    }
  }

  mapingServices = (data) => {
    this.translateService.get('payment.serviceSettings.restrictionCardsList').subscribe(wcs => {
      this.nodesContainer = [];
      const services = _.groupBy(<ThirdPartyLandingModel[]>(data), (item) => item['parentId'] ? item['parentId'] : '');
      const keys = Object.keys(services);
      keys.filter(key => key).forEach(key => {
        const parent = new ServicesContainerModel();
        // title and desc for parent
        parent.title = wcs[key].title;
        parent.shortDescription = wcs[key].shortDesc;
        parent.longDescription = wcs[key].longDesc;
        parent.childs = services[key];
        parent.childs.map((child) => {
          // title and desc for child with Parent
          child.title = wcs[child.name].title;
          child.shortDescription = wcs[child.name].shortDesc;
          child.longDescription = wcs[child.name].longDesc;
          child.img = wcs[child.name].img;
          child.img2 = wcs[child.name].img2;
        });
        parent.img = wcs[key].img;
        parent.img2 = wcs[key].img2;
        this.nodesContainer.push(parent);
      });

    });
  }

  getPremiumContentSubscription(serviceId: string): Observable<PremiumContent> {
    return this.settingData.getPremiumContentSubscription(serviceId).pipe(map(res => res));
  }

}

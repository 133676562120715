import { Injectable } from '@angular/core';
import { TariffChangeDataService } from './tariff-change-data.service';
import { Mva10FullModslServiceService } from '../../../shared/services/mva10-full-modsl-service.service';
import { AppService } from '../../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../config/pages-config';
import { Router } from '@angular/router';
import { TariffService } from '../../../shared/services/tariff.service';
import { ProductsAndServicesService } from '../../products-and-services.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import * as constants from '../../../shared/constants/defines';
import { ServiceBalanceWs10Service } from '../../../../app/shared/services/service-balance-ws10.service';
import { CustomerBillService } from '../../../../app/shared/services/customer-bill.service';
import { clockErrorIcon, TroubleTicket } from '../../../shared/constants/defines';
import { SmartPayPdfDescription } from '../../../../app/models/smart-pay-get-payment.model';
import { DocumentumService } from '../../../../app/shared/services/documentum.service';
import { StorageService } from '../../../core/services/storage.service';
import { CaptureResponseService } from 'src/app/shared/services/capture-response.service';

@Injectable({
  providedIn: 'root'
})
export class TariffChangeService {
  selectedTariff: string;
  selectedTariffName: string;
  selectedTariffPrice: string;
  showFullLoader: boolean = false;
  smartPayUserWithNoBalance: boolean = false;
  selectedTabNumber: string;
  cacheCurrentTab: boolean = false;
  selectedTariffCode: string;
  isYuTariff: boolean;
  isVodafonePrepagoTariff: boolean;
  isDeeplink: boolean = false;
  isBalancedUserWithNoBalance: boolean = false;
  constructor(
    private tariffChangeDataService: TariffChangeDataService,
    private fullModalServiceService: Mva10FullModslServiceService,
    private appService: AppService,
    private translate: TranslateService,
    private router: Router,
    private tariffService: TariffService,
    private productsAndServicesService: ProductsAndServicesService,
    private subscriptionService: SubscriptionService,
    private serviceBalanceService: ServiceBalanceWs10Service,
    private customerBillService: CustomerBillService,
    private documentumService: DocumentumService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private captureResponseService: CaptureResponseService,
  ) { }

  postTariffChange(tariffCode: string, userServiceNumber: string): void {
    this.showFullLoader = true;
    this.fullModalServiceService.adjustErrorModalUi = true;
    this.fullModalServiceService.isAdjustContent = true;
    this.tariffChangeDataService.PostChangeTariffRequest(tariffCode, userServiceNumber).subscribe(() => {
      this.showFullLoader = false;
      this.showSuccessOverlay();
      this.sendAcceptCaptureResponse();
      if (this.subscriptionService.customerData.currentService.smartPay) {
      this.generateDocument();
      }

   }, (error) => {
     this.showFullLoader = false
     this.showFailureOverlay();
   });
  }

  sendAcceptCaptureResponse(): void {
    if (this.captureResponseService.isPega) {
      this.captureResponseService.acceptedCaptureResponsePega(this.captureResponseService.entryPoint);
    }
  }



  showSuccessOverlay(): void {
    this.fullModalServiceService.greyMode = false;
    this.appService.showErrorFullModal = true;
    if (this.smartPayUserWithNoBalance) {
      this.fullModalServiceService.img = this.appService.getImgFullPath(clockErrorIcon);
      this.translate.get('v10.productsServices.tariffChange.withInSufficientBalance.successScreen').subscribe((data) => {
        this.fullModalServiceService.errorButton1 = data.renewButton.text;
        this.fullModalServiceService.errorButton2 = data.cerrarButton;
        this.fullModalServiceService.errorButton1Action = () => {
          this.navigateToBenefitRenewal();
        }
        this.fullModalServiceService.errorButton2Action = () => {
          this.navigateToProductAndServices();
        }
        this.fullModalServiceService.closeAction = () => {
          this.navigateToProductAndServices();
        }
      });
      this.translate.get('v10.productsServices.tariffChange.sumbitRequest.successScreen.titleForNoBalance').subscribe((data) => {
        this.fullModalServiceService.errorTitle = data;
      });
      this.translate.get('v10.productsServices.tariffChange.withInSufficientBalance.successScreen.desc').subscribe((data) => {
        this.fullModalServiceService.errorSubtitle = data.firstText + '<br>' + data.secondText;
      });
    }
    else if ( this.isBalancedUserWithNoBalance ) {
      this.fullModalServiceService.img = this.appService.getImgFullPath(clockErrorIcon);
      this.translate.get('v10.productsServices.tariffChange.withInSufficientBalance.successScreen.balanceUser').subscribe((data) => {
        this.fullModalServiceService.errorButton1 = data.activateButtonText;
        this.fullModalServiceService.errorButton2 = data.closeButtonText
        this.fullModalServiceService.errorTitle = data.title;
        this.fullModalServiceService.errorSubtitle = data.firstText + '<br>' + data.secondText;
        this.fullModalServiceService.errorButton1Action = () => {
          this.navigateToBenefitRenewal();
        }
        this.fullModalServiceService.errorButton2Action = () => {
          this.navigateToProductAndServices();
        }
        this.fullModalServiceService.closeAction = () => {
          this.navigateToProductAndServices();
        }
      });
    }
    else {
      this.translate.get('v10.productsServices.tariffChange.sumbitRequest.successScreen.title').subscribe((data) => {
        this.fullModalServiceService.errorTitle = data;
      });
      this.fullModalServiceService.img = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-tick-hifi-light-theme@2x.png');
      this.translate.get('v10.productsServices.tariffChange.successScreen.text').subscribe((data) => {
        this.fullModalServiceService.errorSubtitle = data;
      });
      this.translate.get('v10.productsServices.tariffChange.sumbitRequest.successScreen.actionButton').subscribe((data) => {
        this.fullModalServiceService.errorButton1 = data;
        this.fullModalServiceService.errorButton2 = '';

      });
      this.fullModalServiceService.errorButton1Action = () => {
        this.navigateToProductAndServices();
      }
      this.fullModalServiceService.closeAction = () => {
        this.navigateToProductAndServices();
      }
    }
  }
  /**set failure overlay data */
  showFailureOverlay(): void {
    this.translate.get('v10.productsServices.tariffChange.sumbitRequest.failureScreen').subscribe((data) => {
      this.fullModalServiceService.errorTitle = data.title;
      this.fullModalServiceService.errorSubtitle = data.firstText + '<br>' + data.secondText;
      this.fullModalServiceService.errorButton1 = data.actionButton;
      this.fullModalServiceService.img = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/warning-for-cancel-overlay.png');
      this.fullModalServiceService.errorButton1Action = () => {
        this.appService.showErrorFullModal = false;
        this.showFullLoader = true;
        this.postTariffChange(this.selectedTariffCode,
          this.subscriptionService.customerData?.currentService?.id);
      }
      this.fullModalServiceService.closeAction = () => {
        this.appService.showErrorFullModal = false;
        this.fullModalServiceService.adjustErrorModalUi = false;
        this.fullModalServiceService.isAdjustContent = false;
      }
    });
    this.appService.showErrorFullModal = true;
  }

  /**navigate to product and services and hide overlay */
  navigateToProductAndServices(): void {
    this.serviceBalanceService.refreshTarifAndServiceBalnceApi = true;
    this.navigateToAnotherScreen();
    this.router.navigate([config.ProductsAndServices.landing.route]);
  }

  /**navigate to benefit renewal and hide overlay */
  navigateToBenefitRenewal(): void {
    this.navigateToAnotherScreen();
    this.router.navigate([config.ProductsAndServices.BenefitRenewal.route]);
  }

  navigateToAnotherScreen(): void {
    this.captureResponseService.isPega = false;
    this.appService.showErrorFullModal = false;
    this.productsAndServicesService.noPadding = false;
    this.fullModalServiceService.adjustErrorModalUi = false;
    this.fullModalServiceService.isAdjustContent = false;
  }


  getCurrentAmountForDeepLink(): void {
    this.showFullLoader = true;
    this.serviceBalanceService.getServiceBalance(constants.BundleType.inPlan, false).subscribe((result) => {
      this.customerBillService.getAmountPrepaid(result.items);
      this.showFullLoader = false;
    });
  }

  getActDate(): string {
    const dt: Date = new Date();
    const defDet: string = `${
      dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1).toString().padStart(2, '0')}/${
      dt.getFullYear().toString().padStart(4, '0')} ${
      dt.getHours().toString().padStart(2, '0')}:${
      dt.getMinutes().toString().padStart(2, '0')}:${
      dt.getSeconds().toString().padStart(2, '0')}`
    return defDet;
  }

  getTicket(): string {
    const dateToSend: string = this.getActDate();
    const ticketTxt: string = `
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.title')}

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.client')} \n
    ${this.storageService.userProfile.firstName + ' ' + this.storageService.userProfile.lastName} \n

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.doc')} \n
    ${this.storageService.userProfile.document.id}

    ${this.translateService.instant('v10.productsServices.smartPay.PyS.benefits.method.update.title')} \n
    *************\n\n

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.date')}\n
    ${dateToSend}

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.line')}\n
    ${this.subscriptionService.customerData.currentService.id}

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.tariff')}\n
      ${this.selectedTariffName}

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.fee')}\n
    ${this.tariffService.Tariff.Fee + this.tariffService.Tariff.Unit}

    *************\n\n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.cost')}

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.card')} \n

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.days')} \n


    *************\n\n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.signature_legal')}

    ${this.translateService.instant('v10.productsServices.smartPay.pdf.signature')} \n
    ${dateToSend}
    `
    return ticketTxt;
  }

  generateDocument(): void {
    const ticket: SmartPayPdfDescription = {
      description: this.getTicket()
    }
    this.documentumService.GenerateDocument(
      this.subscriptionService.customerData.customerAccountsId,
      this.storageService.userProfile.document.id,
      ticket, TroubleTicket
    ).subscribe((res) => { }
      , error => {
        throw error;
      });
  }

  getSelectedTariffCode(tariffName: string): string {
    let code: string = '';
    this.translate.get('v10.productsServices.tariffChange.tariffItems.items.tabs').subscribe(data => {
     code = data[0]?.items?.find(tariff => tariff?.tariffTitle?.split('Vodafone')[1]?.split(' ')
     .join('').toLowerCase() === tariffName.toLowerCase())?.code;
    });
    return code;
  }
}

import { home5G } from './../constants/defines';

import {catchError, map} from 'rxjs/operators';
import { MyAccountAuthorizedInfo } from './../../models/myAccountAuthorizedInfo.model';
import { Address } from './../../models/Address.model';
import { Injectable } from '@angular/core';
import {
  CustomerAccount,
  CustomerAccountResponseModel,
  CustomerAccountResponseItem,
  CustomerAccountNewModel,
  UpdatedPaymentAddress,
  MyAccountAuthorizedResponseModel
} from '../../models/customer-account.model';
import { API_URLS } from '../constants/routes-config';
import { HttpHeaders } from '@angular/common/http';
import * as constants from '../constants/defines';
import { StorageService } from '../../core/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { SubscriptionService } from '../../core/services/subscription.service';
import * as JsonQuery from 'jsonpath/jsonpath';
import { MyAccountService } from '../../my-account/my-account.service';
import { CompanyService } from './company.service';
import { Subject ,  Observable, of, throwError } from 'rxjs';
import { AddressStatus } from '../enums/addressStatus.enum';
import { PaymentMethodAddress } from '../../models/account-edit-payment-method-address.model';
import { BillingAccounts } from '../../models/billing-accounts.model';
import { BillingAddress } from '../../models/billing-address.model';
import { PaymentMethod } from '../../models/payment-method.model';
import { BillingAccountSubscription } from '../../models/billing-accounts-subscription';
import { PaymentSite } from '../../models/payment-site.model';
import { billQueryString, cachingKeys, adaraValues } from '../constants/defines';
import { UtilitiesService } from '../utils/utilities.service';
import { Debt } from '../../models/debt.model';
import { PreferencesAndPermissions } from '../../models/preferences-and-permissions.model';
import { AppService } from '../../app.service';
import { evict } from '../decorators/evict.decorator';
import { UserProfile } from '../../models/user-profile.model';
import { SiteStatus } from '../enums/siteStatus.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { ProfileType } from './../enums/profileType.enum';
import { IconClassNames } from '../../shared/enums/iconClassNames.enum';
// MVA10
import { TranslateService } from '@ngx-translate/core';
import { SelectorModel, CardImageSelectorModel, IconType } from '@mva10/mva10-angular';
import moment from 'moment';
import { ServiceModel, TypesCardImageSelectorMode } from '../../models/service.model';
import { CardSelectorExtendedModel } from '../../models/card-selector-extended.model';
import { ProductManagementEnum } from '../enums/product-management.enum';
import {
  MyAccountDetailsResponseModel,
  MyAccountCustomerData,
  FullCustomerData,
  OldAccountDetailsResponseModel,
  ContactMedium,
  AddressAccountEditDetailsViewModel,
  MyAccountCredentialData
} from '../../models/account-edit-details.model';
import { MyAccountDetailsType } from '../enums/my-account-details-type.enum';
import { CustomerAccountSignStatus } from '../enums/customer-account-sign-status.enum';
import { BankAccountDataResponse } from '../../models/bankAccountDataResponse.model';
import { BillTicket } from '../../models/bill-details.model';

@Injectable()
export class CustomerAccountService {
  public resendButton: boolean;
  public otpText: string;
  public otpSettimeoutCalled: boolean;
  public customerAccounts: CustomerAccount[];
  public customerAccountsPendingInstall: CustomerAccount[] = [];
  public customerAccountsPaymentMethods: CustomerAccount[];
  public billingAccounts: BillingAccounts[];
  public customerAccount: CustomerAccount;
  public authorizedInfo: MyAccountAuthorizedInfo;
  public selectedBillingAddress: any;
  public newAddress: any;
  public preferencesAndPermissions: PreferencesAndPermissions;
  /**payment method selected site id */
  public selectedPaymentSite: PaymentSite;
  public selectedBillingAccountId: string;
  public updatedAccountNumber: any;
  public updatedBaymentAddress: UpdatedPaymentAddress;
  public hasOneProfesionalItems: boolean = false;
  isError = false;
  selectedSiteIdFromEBill: string;

  selectDefault: string;
  selectorData: SelectorModel;
  primaryList: Array<CardSelectorExtendedModel>;

  onlineSubscriptionsCard: CardImageSelectorModel;
  oneProfesionalCard: CardImageSelectorModel;
  selectorDataWithSubscriptions: SelectorModel;
  hideSubscriptionsEntryPoint: boolean;

  otherCIFs: String = '';
  selectorCIFData: Array<any>;
  responseHistory: string;
  private productsUpdatedSubject = new Subject();
  loaded: Boolean = false;

  public isSitePrepaid = true;
  // boolean to check if user has bank account or not
  public hasBankAccount: boolean = false;
  // boolean to check if come from pagos screen to my account screen or not
  comeFromPagosToMyAccountScreen: boolean = false;

  public newCustomerAccountData: CustomerAccountNewModel = { contact: {} };

  public hasAdaraSite: boolean;
  public adaraSelectorCard: CardImageSelectorModel;
  public showAdaraSelector: boolean;
  public adaraSelectorData: SelectorModel;
  public adaraSelector: CardImageSelectorModel[] = new Array<CardImageSelectorModel>();

  constructor(private http: HttpClient,
    private storageService: StorageService,
    private subscription: SubscriptionService,
    private myAccountService: MyAccountService,
    private utility: UtilitiesService,
    private appService: AppService,
    private companyService: CompanyService,
    private translateService: TranslateService,
  ) {
    this.customerAccounts = new Array<CustomerAccount>();
    this.authorizedInfo = new MyAccountAuthorizedInfo();
  }

  get productsUpdated$(): Observable<any> {
    return this.productsUpdatedSubject.asObservable();
  }

  private separatePendingInstallCustomerAccounts(): void {
    this.customerAccountsPendingInstall = [];
    if (this.customerAccounts?.length > 1) {
      this.customerAccounts = this.customerAccounts.reduce((nonPendingCA: CustomerAccount[], item: CustomerAccount) => {
        if (this.isPendingInstallationSiteStatus(item.status)) {
          this.customerAccountsPendingInstall.push(item);
        } else {
          nonPendingCA.push(item);
        }
        return nonPendingCA;
      }, [] as CustomerAccount[]);
    }
    this.customerAccount = this.customerAccount || this.customerAccounts[0];
    this.storageService.customerAccountsLoaded = true;
  }

  /**
   * Fetch Customer Accounts to get current customer data
   */
  GetCustomerAccounts(holderId?: string, debtAmount?: boolean, pendingInstallSites?: boolean): Observable<CustomerAccountResponseModel> {
    if (!this.customerAccounts) {
      this.customerAccounts = new Array<CustomerAccount>();
    }
    let url: string = API_URLS.CustomerAccount.FetchCustomerAccounts;
    if (holderId) {
      url += `?holderId=${holderId}`;
    }
    if (debtAmount) {
      url += holderId ? '&' : '?';
      url += 'fields=debtAmount';
    }
    if (pendingInstallSites) {
      url += `${url.indexOf('?') > -1 ? '&' : '?'}pendingInstall=true`;
    }
    return this.http.get(url).pipe(
      map((res: CustomerAccountResponseModel) => {
        if (debtAmount) {
          this.customerAccounts = new Array<CustomerAccount>();
        }
        const items: CustomerAccountResponseItem[] = JsonQuery.value(res, constants.JSON_PATHS.UserAccount.Items);
        items.map((item: CustomerAccountResponseItem) => {
          if (
            !this.customerAccounts.find((site: CustomerAccount) => {
              return site.id === this.mapSiteData(item, holderId).id;
            })
          ) {
            this.customerAccounts.push(this.mapSiteData(item, holderId));
          }
        });
        this.separatePendingInstallCustomerAccounts();

        return res;
      })
    );
  }

  /**
  * Fetch Customer Accounts to get current customer data by id
  */
  GetCustomerAccountById(id: string, holderId?: string): Observable<CustomerAccount> {
    let url: string = API_URLS.CustomerAccount.FetchCustomerAccountById.replace('{id}', id);
    if (holderId) {
      url += `&holder-id=${holderId}`;
    }
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));

    return this.http.get(url, { headers }).pipe(map((res: OldAccountDetailsResponseModel) => {
      this.customerAccount = this.mapSiteData(res, holderId);
      if (!this.customerAccount.status) {
        this.customerAccount.status =
          [...this.customerAccounts, ...this.customerAccountsPendingInstall].find(item => item.id === id)?.status;
      }
      this.myAccountService.loadheader(this.customerAccount);
      return this.customerAccount;
    }));
  }
  getCustomerPartiesById(id: string) {
    const url = API_URLS.CustomerAccount.FetchCustomerPartiesById.replace('{id}', id);
    let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      if (JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.company)) {
        this.authorizedInfo.companyId = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.companyId) || null;
        this.authorizedInfo.companyName = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.companyName) || null;
      }
      if (JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.extension)
        && JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.extensionEs)) {

        this.authorizedInfo.documentId = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.documentId) || null;
      }
      if (JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.extension)
        && JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.extensionEs)) {
        this.authorizedInfo.email = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.email) || null;
        this.authorizedInfo.phone = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.phone) || null;
        this.authorizedInfo.vodafonePhone = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.vodafonePhone) || null;
      }
      this.authorizedInfo.permissions = [];
      if (JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.extension)
        && JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.extensionEs)) {
        this.authorizedInfo.permissions = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.permissions) || null;
      }
      if (JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.contactPreferences)) {
        this.authorizedInfo.contactPoint = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.contactPoint) || null;
        this.authorizedInfo.notificationChannel = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.notificationChannel) || null;
        this.authorizedInfo.notificationTime = JsonQuery.value(res, constants.JSON_PATHS.CustomerParties.notificationTime) || null;
      }
      this.myAccountService.loadheader(this.authorizedInfo);
      return this.authorizedInfo;
    }));
  }

  getCustomerPartiesByIdFull(id: string): Observable<MyAccountAuthorizedResponseModel> {
    const url: string = API_URLS.CustomerAccount.FetchCustomerPartiesById.replace('{id}', id);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));

    return this.http.get(url, { headers }).pipe(
      map((res: MyAccountAuthorizedResponseModel) => {
        const parsedRes: MyAccountDetailsResponseModel = {
          customer: {
            account: [{ id: res.company?.id }],
            contactMedium: [
              { type: MyAccountDetailsType.Email, characteristic: { emailAddress: res.contactPoints[0]?.email?.fullAddress } },
              { type: MyAccountDetailsType.Mobile, characteristic: { phoneNumber: res.contactPoints[0]?.mobile?.msisdn } },
              { type: MyAccountDetailsType.Landline, characteristic: { phoneNumber: res.contactPoints[0]?.fixedLine?.phoneNumber } }
            ],
            id: res.extension?.es?.document?.id,
            name: `${res.infoContact?.name}:${res.infoContact?.lastName}:${res.infoContact?.secondLastName}`
          }
        };
        this.populateSingletonWithNewModel(parsedRes);
        return res;
      })
    );
  }

  @evict(cachingKeys.FetchCustomerAccountById)
  updateCustomerAccount(userDetails) {
    const url = API_URLS.CustomerAccount.PatchCustomerAccount.replace('{id}', this.subscription.customerData.customerAccountsId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };

    return this.http.patch(url, userDetails, options).pipe(map((res) => {
      this.customerAccount.msisdn = JsonQuery.value(res, constants.JSON_PATHS.UserAccount.Msisdn) || null;
      this.customerAccount.type = JsonQuery.value(res, constants.JSON_PATHS.UserAccount.Type) || null;
      this.customerAccount.firstName = res[constants.JSON_PATHS.UserAccount.FirstName] || null;
      this.customerAccount.familyName = (JsonQuery.value(res, constants.JSON_PATHS.UserAccount.middleName) || '') + ' ' +
        JsonQuery.value(res, constants.JSON_PATHS.UserAccount.FamilyName) || null;
      this.customerAccount.email = JsonQuery.value(res, constants.JSON_PATHS.UserAccount.Email) || null;
      this.myAccountService.loadheader(this.customerAccount);
      return res;
    }
    ));
  }
  updateCustomerParties(id, userDetails) {
    const url = API_URLS.CustomerAccount.PatchCustomerPartiesById.replace('{id}', id);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };

    return this.http.patch(url, userDetails, options).pipe(map((res) => {
      return res;
    }
    ));

  }
  addAuthorizedUser(id, userDetails) {
    const url = API_URLS.CustomerAccount.PutCustomerPartiesById.replace('{id}', id);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.put(url, userDetails, options).pipe(map((res) => {
      return res;
    }
    ));
  }

  private mapSiteData(item: CustomerAccountResponseItem | OldAccountDetailsResponseModel, holderId?: string): CustomerAccount {
    let customerAccount: CustomerAccount = new CustomerAccount();
    customerAccount.id = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Id);
    customerAccount.msisdn = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Msisdn);
    customerAccount.type = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Type);
    customerAccount.status = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Status);
    customerAccount.segment = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.segment);
    customerAccount.xmasVoucher = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.xmasVoucher);
    customerAccount.debtAmount = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.debtAmount);
    customerAccount.emailStatus = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.emailStatus);
    customerAccount.newEmail = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.newEmail);
    customerAccount.confirmedEmail = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.confirmedEmail);
    if (customerAccount.status) {
      customerAccount.statusSP = this.utility.translateStatusToSpanish(customerAccount.status);
    }
    customerAccount.firstName = item[constants.JSON_PATHS.UserAccount.FirstName];
    customerAccount.familyName = item[constants.JSON_PATHS.UserAccount.FamilyName];
    customerAccount.email = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Email);

    // Use null as default value for empty fields above
    customerAccount = this.utility.parseNullValues(customerAccount, null);

    customerAccount.companyId = holderId || '';
    customerAccount.address = new Address();
    const address: Address = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.address);
    if (address && Object.keys(address)) {
      customerAccount.address.formattedAddress = address[constants.JSON_PATHS.UserAccount.formattedAddress];
      customerAccount.address.buildingNo = address[constants.JSON_PATHS.UserAccount.buildingNumber];
      customerAccount.address.country = JsonQuery.value(address, constants.JSON_PATHS.UserAccount.country);
      customerAccount.address.level = JsonQuery.value(address, constants.JSON_PATHS.UserAccount.level);
      customerAccount.address.postcode = JsonQuery.value(address, constants.JSON_PATHS.UserAccount.postcode);
      customerAccount.address.street = JsonQuery.value(address, constants.JSON_PATHS.UserAccount.street);
      customerAccount.address.town = JsonQuery.value(address, constants.JSON_PATHS.UserAccount.town);
      customerAccount.address.status = JsonQuery.value(address, constants.JSON_PATHS.UserAccount.Status);

      // Set default address values to empty string
      customerAccount.address = this.utility.parseNullValues(customerAccount.address, '');
    } else {
      customerAccount.address = null;
    }
    customerAccount.pendingSign =
      JsonQuery.value(item, constants.JSON_PATHS.UserAccount.pdteFirma) === CustomerAccountSignStatus.pending;

    return customerAccount;
  }

  private mapPaymentMethodsSiteData(item: any, holderId?: string) {
    const customerAccountsPaymentMethods = new CustomerAccount();
    customerAccountsPaymentMethods.id = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Id) || null;
    customerAccountsPaymentMethods.msisdn = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Msisdn) || null;
    customerAccountsPaymentMethods.type = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Type) || null;
    customerAccountsPaymentMethods.status = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Status) || null;
    customerAccountsPaymentMethods.firstName = item[constants.JSON_PATHS.UserAccount.FirstName] || null;
    customerAccountsPaymentMethods.familyName = item[constants.JSON_PATHS.UserAccount.FamilyName] || null;
    customerAccountsPaymentMethods.companyId = holderId ? holderId : ''
    customerAccountsPaymentMethods.email = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.Email) || null;
    customerAccountsPaymentMethods.address = new Address();
    const address = JsonQuery.value(item, constants.JSON_PATHS.UserAccount.address) || null;
    customerAccountsPaymentMethods.address.formattedAddress = address[constants.JSON_PATHS.UserAccount.formattedAddress] || '';
    return customerAccountsPaymentMethods;
  }
  @evict(cachingKeys.FetchCustomerAccountById)
  patchCredential(credential: MyAccountCredentialData): Observable<any> {
    const url: string = API_URLS.CustomerAccount.patchCredential.replace('{id}', this.storageService.userProfile.id);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('accept', 'application/json');
    return this.http.patch(url,
      JSON.stringify(credential), { headers }).pipe(map(response => {
        return response;
      }));
  }

  updateCustomerAddress(addresData) {
    const url = API_URLS.CustomerAccount.PatchCustomerAccount.replace('{id}', this.subscription.customerData.customerAccountsId);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');

    return this.http.patch(url, addresData, { observe: 'response', headers: headers }).pipe(map((res: any) => {
      this.myAccountService.loadheader(this.customerAccount);

      this.customerAccount.address.status = AddressStatus.pending;

      return res;
    }
    ));
  }
  patchPaymentMethodAddress(siteId: string, billingAccountId: string, newAddress: PaymentMethodAddress): Observable<any> {
    const body: Object[] = [
      {
        'op': 'replace',
        'path': '/billingAddress',
        'value': {
          'formattedAddress': newAddress.formattedAddress,
          'doorNumber': newAddress.doorNumber,
          'buildingName': newAddress.buildingName,
          'buildingNumber': newAddress.buildingNumber,
          'level': newAddress.level,
          'street': newAddress.street,
          'town': newAddress.town,
          'county': newAddress.county,
          'postCode': newAddress.postalCode,
          'status': newAddress.status
        }
      }
    ]
    const url: string = API_URLS.CustomerAccount.patchPaymentMethodAddress
      .replace('{id}', siteId).replace('{billingAccountId}', billingAccountId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    return this.http.patch(url, body, { observe: 'response', headers: headers }).pipe(map((res: any) => {
      return res;
    }
    ));
  }
  /**
  */
  GetPaymentCustomerAccounts(
    hierarchyType: string = billQueryString.bill,
    holderId?: string,
    status?: string
  ): Observable<CustomerAccount[]> {
    this.customerAccountsPaymentMethods = new Array<CustomerAccount>();
    let url: string = API_URLS.CustomerAccount.FetchCustomerAccounts;
    if (holderId) {
      url += `?holderId=${holderId}&hierarchyType=${hierarchyType}`;
    }
    else {
      url += `?hierarchyType=${hierarchyType}`;
    }
    if (status) {
      url += `&status=${status}`;
    }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url, { headers }).pipe(map((res) => {
      const items: any[] = JsonQuery.value(res, constants.JSON_PATHS.UserAccount.Items);
      items.filter(item => !this.isPendingInstallationSiteStatus(item.status)).map((item) => {
        if (!this.customerAccountsPaymentMethods.find(el => el.id === this.mapPaymentMethodsSiteData(item, holderId).id)) {
          this.customerAccountsPaymentMethods.push(this.mapPaymentMethodsSiteData(item, holderId));
        }
      });

      return this.customerAccountsPaymentMethods;
    }
    ));
  }
  /**map billing accounts data */
  mapBillingAccountsData(item: any) {
    const billingAccount = new BillingAccounts();
    billingAccount.id = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.id) || null;
    billingAccount.billingAddress = new BillingAddress();
    const address = item[constants.JSON_PATHS.BillingAccount.billingAddress] || null;
    if (address) {
      billingAccount.billingAddress.buildingNumber = address[constants.JSON_PATHS.BillingAccount.buildingNumber] || '';
      billingAccount.billingAddress.buildingName = address[constants.JSON_PATHS.BillingAccount.buildingName] || '';
      billingAccount.billingAddress.doorNumber = address[constants.JSON_PATHS.BillingAccount.doorNumber] || '';
      billingAccount.billingAddress.level = JsonQuery.value(address, constants.JSON_PATHS.BillingAccount.level) || '';
      billingAccount.billingAddress.street = JsonQuery.value(address, constants.JSON_PATHS.BillingAccount.street) || '';
      billingAccount.billingAddress.town = JsonQuery.value(address, constants.JSON_PATHS.BillingAccount.town) || '';
      billingAccount.billingAddress.country = JsonQuery.value(address, constants.JSON_PATHS.BillingAccount.country) || '';
      billingAccount.billingAddress.postcode = address[constants.JSON_PATHS.BillingAccount.postcode] || '';
    }
    billingAccount.paymentMethod = new PaymentMethod();
    const paymentMethod = item[constants.JSON_PATHS.BillingAccount.paymentMethod] || null;
    if (paymentMethod) {
      billingAccount.paymentMethod.bankName = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.bankName) || '';
      billingAccount.paymentMethod.accountNumber = JsonQuery.value(paymentMethod,
        constants.JSON_PATHS.BillingAccount.accountNumber) || '';
      billingAccount.paymentMethod.bic = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.bic) || '';
      billingAccount.paymentMethod.iban = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.iban) || '';
      billingAccount.paymentMethod.type = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.type) || '';
    }
    billingAccount.debt = new Debt();
    const debt = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.debt) || null;
    if (debt) {
      billingAccount.debt.amount = JsonQuery.value(debt, constants.JSON_PATHS.BillingAccount.amount) || '';
      billingAccount.debt.dueDate = JsonQuery.value(debt, constants.JSON_PATHS.BillingAccount.dueDate) || '';
    }
    billingAccount.subscriptions = new Array<BillingAccountSubscription>();
    billingAccount.subscriptions = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.subscriptions) || null;
    return billingAccount;
  }

  GetBillingAccounts() {
    const url = API_URLS.CustomerAccount.FetchBillingAccounts.replace('{id}', this.selectedPaymentSite.id);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };

    this.billingAccounts = new Array<BillingAccounts>();
    return this.http.get(url, options).pipe(map((res) => {
      const items = JsonQuery.value(res, constants.JSON_PATHS.BillingAccount.items);
      items.map((item) => {
        this.billingAccounts.push(this.mapBillingAccountsData(item));
      });
      return res;
    }
    ));
  }
  postGeneratePIN() {
    const body = {
      'part1': 'Este es tu codigo ',
      'part2': ' Disfruta el resto del día.'
    }

    const url = API_URLS.CustomerAccount.generatePIN;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.post(url, body, options).pipe(map(res => res));
  }

  @evict(cachingKeys.getPaymentMethods, cachingKeys.FetchBillingAccounts)
  patchPaymentMethod(value: UpdatedPaymentAddress, path: string, operation: string,
    verificationPIN?: string): Observable<BankAccountDataResponse> {
    const body: Object = {
      'op': operation,
      'path': path,
      'value': value
    };
    const url: string = API_URLS.CustomerAccount.patchPaymentMethodAddress
      .replace('{id}', this.selectedPaymentSite.id).replace('{billing-account-id}', this.selectedBillingAccountId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json-patch+json');
    if (verificationPIN) {
      headers = headers.append('verification-code', verificationPIN);
    }
    return this.http.patch(url, body, { headers }).pipe(map((res: BankAccountDataResponse) => res));
  }

  getPermissionsAndPreferences() {
    this.preferencesAndPermissions = new PreferencesAndPermissions();
    const url = API_URLS.CustomerAccount.permissionsAndPreferences.replace('{siteId}', this.subscription.customerData.currentService.siteId)
    return this.http.get(url).pipe(map((res) => {
      this.preferencesAndPermissions.timeFrame = JsonQuery.value(res, constants.JSON_PATHS.preferencesAndPermissions.timeFrame) || '';
      this.preferencesAndPermissions.notifications = JsonQuery.
        value(res, constants.JSON_PATHS.preferencesAndPermissions.notifications) || [];
      this.preferencesAndPermissions.topics = JsonQuery.value(res, constants.JSON_PATHS.preferencesAndPermissions.topics) || [];
      this.preferencesAndPermissions.contactTopics =
        JsonQuery.value(res, constants.JSON_PATHS.preferencesAndPermissions.contactTopics) || [];
      this.preferencesAndPermissions.permissions = JsonQuery.value(res, constants.JSON_PATHS.preferencesAndPermissions.permissions) || [];
      this.preferencesAndPermissions.gdprDiagnostics =
        JsonQuery.value(res, constants.JSON_PATHS.preferencesAndPermissions.gdprDiagnostics) || false;

    }))
  }
  @evict(cachingKeys.permissionsAndPreferences)
  postPermissionsAndPreferences(prefrencesAndPremissions: any, isGDPR?: boolean): Observable<any> {
    const url: string = API_URLS.CustomerAccount.permissionsAndPreferences
      .replace('{siteId}', isGDPR ? 'all' : this.subscription.customerData.currentService.siteId);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    return this.http.patch(url, prefrencesAndPremissions, { headers }).pipe(map(res => {
      return res;
    }));

  }
  enableGDPRPermission() {
    return this.postPermissionsAndPreferences({
      'permissions': ['DatosTraficoNavegacion']
    }, true);
  }
  isP1Sites(userProfile: UserProfile) {
    const p1Sites = [SiteStatus.Activo.toLowerCase(),
      SiteStatus.Pend_de_Cambio.toLowerCase(), SiteStatus.Pend_de_Desconectar.toLowerCase(),
      SiteStatus.Active_Pending.toLowerCase()];
    const currentSiteStatuses = userProfile.sites.filter(site =>
      p1Sites.includes(site.status.toLowerCase())
    );
    return currentSiteStatuses.length > 1;
  }
  isP1SiteStatus(userProfile: UserProfile) {
    const p1Sites = [SiteStatus.Activo.toLowerCase(),
      SiteStatus.Pend_de_Cambio.toLowerCase(), SiteStatus.Pend_de_Desconectar.toLowerCase(),
      SiteStatus.Active_Pending.toLowerCase()];
    return userProfile && userProfile.sites && userProfile.sites.find(site => p1Sites.includes(site.status.toLowerCase()));
  }

  getCustomerAccountsWS10(id?: string, pendingInstallSites?: boolean): Observable<CustomerAccount[]> {
    this.customerAccounts = new Array<CustomerAccount>();
    let url: string = !id
      ? API_URLS['Dashboard-WS10'].CustomerAccount.FetchCustomerAccounts +
        (pendingInstallSites ? '?pendingInstall=true' : '')
      : API_URLS['Dashboard-WS10'].CustomerAccount.FetchCustomerAccountById.replace('{id}', id);

    if (this.companyService.selectedCompanyId) {
      url += `${url.indexOf('?') > -1 ? '&' : '?'}holderId=${this.companyService.selectedCompanyId}`;
    }
    return this.http.get(url).pipe(
      map((response: CustomerAccountResponseModel): CustomerAccount[] => {
        this.customerAccounts = new Array<CustomerAccount>();
        const items: CustomerAccountResponseItem[] = JsonQuery.value(response, constants.JSON_PATHS.UserAccount.Items);
        if (items) {
          this.customerAccounts = items.map((item: CustomerAccountResponseItem): CustomerAccount => {
            const newItem: CustomerAccount = new CustomerAccount();
            newItem.id = item.id;
            newItem.firstName = item.name;
            if (item.address?.formattedAddress) {
              newItem.address = new Address();
              newItem.address.formattedAddress = item.address?.formattedAddress;
            }
            newItem.type = item.type;
            newItem.parts = item.parts;
            newItem.companyId = item.cif;
            newItem.status = item.status;
            newItem.clientType = item.clientType
            newItem.marketType = item.marketType
            newItem.pendingSign = item.pdteFirma === CustomerAccountSignStatus.pending;
            return newItem;
          });
          this.separatePendingInstallCustomerAccounts();
        }
        if (response.firstName) {
          this.appService.initGretting(response.firstName);
        }
        this.checkForAdaraSites(this.storageService.userProfile.sites)
        this.showAdaraSelector = pendingInstallSites ?
        this.checkSitesToShowAdaraSelector(items) :
        this.storageService.userProfile.sites.some(site => site.marketType === adaraValues.CLIENT_ADARA)
        return this.customerAccounts;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getHistory(): Observable<any> {
    const urlHistory = API_URLS['Dashboard-WS10'].CustomerAccount.History.
      replace('{siteId}', this.subscription.customerData.customerAccountsId).
      replace('{subscription-id}', this.subscription.customerData.currentService.id).
      replace('{start}', moment(new Date()).subtract(6, 'months').format()).
      replace('{end}', moment(new Date()).format())
    return this.http.get(urlHistory).pipe(map((response: any) => {
      this.responseHistory = response.items[0].transactionDate;
    }), catchError((error) => {
      this.responseHistory = error.error.ecode;
      return throwError(error);
    }));
  }

  getSecondarylist(productId?) {
    this.selectorData = new SelectorModel();
    this.selectorData.cardImageSelectorList = [];
    this.isSitePrepaid = true;
    if (this.subscription.serviceListSite) {
      this.selectorData.cardImageSelectorList = this.subscription.serviceListSite.map(item => {
        this.checkSitePrepaid(item.type);
        return this.mapServiceSelector(item, item.type, productId);
      });
      this.selectorDataWithSubscriptions = this.getServicesListWithSubscriptions();
    }
  }

  public generateAdaraSelector(): SelectorModel {
    this.adaraSelectorCard = {
      id: ProductManagementEnum.adara,
      icon: '#idea-or-innovation-mid',
      title: this.translateService.instant('v10.dashboard.gestion.list.adara.desc'),
      image: this.appService.getImgFullPath(
        this.translateService.instant('v10.dashboard.gestion.list.adara.image')
      ),
      borderColor: 'none',
      description: null,
      checked: false,
    }
    return {
      cardImageSelectorList: [this.adaraSelectorCard]
    };

  }

  getServicesListWithSubscriptions(): SelectorModel {
    if (!this.onlineSubscriptionsCard) {
      this.hideSubscriptionsEntryPoint = [true, 'true'].includes(
        this.translateService.instant('v10.dashboard.gestion.list.online_subscriptions.hideEP')
      );
      this.onlineSubscriptionsCard = {
        id: ProductManagementEnum.online_subscriptions,
        icon: '#icon-offers-mid',
        title: this.translateService.instant('v10.dashboard.gestion.list.online_subscriptions.desc'),
        image: this.appService.getImgFullPath(
          this.translateService.instant('v10.dashboard.images.product_selector.onlineSubscriptions_light')
        ),
        backgroundColor: 'white',
        borderColor: '#999',
        description: null,
        checked: false
      };
    }

    this.hasOneProfesionalItems = !!(this.appService.listSubcriptions?.hasOneProfesionalItems)
    let dataWidthOneProfesionalItems: CardImageSelectorModel[];

    if (this.hasOneProfesionalItems) {
      this.translateService.get('v10.productsServices.businessProducts').subscribe(text => {
        this.oneProfesionalCard = {
          id: text.id,
          icon: IconClassNames.ICON_SME_MID,
          title: text.titleText,
          image: this.appService.getImgFullPath(
            this.translateService.instant('v10.dashboard.images.product_selector.businessProducts')
          ),
          description: text.subtitleText,
          checked: false
        }
        dataWidthOneProfesionalItems = this.selectorData?.cardImageSelectorList;
        dataWidthOneProfesionalItems.unshift(this.oneProfesionalCard);
      })
    }

    const originalData: CardImageSelectorModel[] =  this.hasOneProfesionalItems ?
    dataWidthOneProfesionalItems : this.selectorData?.cardImageSelectorList
     || [];

    return {
      cardImageSelectorList: this.hideSubscriptionsEntryPoint ?
      originalData :
      [...originalData, this.onlineSubscriptionsCard]
    };
  }

  checkSitePrepaid(type) {
    this.isSitePrepaid = type.toUpperCase() === ServiceType.Prepaid.toUpperCase() ? this.isSitePrepaid : false;
  }

  initCIFSelector() {
    this.loaded = false;
    this.selectorCIFData = this.storageService.userProfile.companies.map(item => {
      return item.id;
    });

    if (this.storageService.userProfile.hasExternalCompanies) {
      this.translateService.get('common').subscribe(text => {
          this.otherCIFs = text.itemsList.otherCIFs.body;
          this.selectorCIFData.push(this.otherCIFs);
      });
    }

    this.loaded = true;
  }

  initSelector(productId?: string, includePendingSites?: boolean): void {
    this.selectorCIFData = [];
    this.primaryList = [];
    this.selectorData = new SelectorModel();
    this.selectorData.cardImageSelectorList = [];
    if (this.storageService.userProfile.companies) {
      this.initCIFSelector();
    }

    const sitesToInclude: CustomerAccount[] = includePendingSites
      ? [...this.customerAccounts, ...this.customerAccountsPendingInstall]
      : this.customerAccounts;
    if ((sitesToInclude && sitesToInclude.length > 1 ||
      this.storageService.userProfile.customerType === ProfileType.AUTHORIZED) &&
          (this.storageService.userProfile.customerType !== ProfileType.EMPLOYEE &&
            this.storageService.userProfile.customerType !== ProfileType.SME)) {
      this.primaryList = sitesToInclude.filter(item => item.marketType !== adaraValues.CLIENT_ADARA).map(item => {
        const itemCard: CardSelectorExtendedModel = new CardSelectorExtendedModel();
        itemCard.id = item.id;
        itemCard.text = item.address?.formattedAddress?.toString();
        itemCard.checked = false;
        itemCard.value = item.id;
        itemCard.status = item.status;
        itemCard.blueLabel = this.isPendingInstallationSiteStatus(item.status);

        if (item.id === this.subscription.customerData.customerAccountsId) {
          itemCard.checked = true;
          this.selectDefault =  itemCard.value;
        }
        return itemCard;
      });
    }
    this.getSecondarylist(productId);
    this.productsUpdatedSubject.next(this.selectorData);
  }

  isPendingInstallationSiteStatus(siteStatus?: string, siteId?: string): boolean {
    const customerAccountsToFilter: CustomerAccount[] =
      this.customerAccounts?.length === 1 && this.customerAccountsPendingInstall?.length === 0
        ? this.customerAccounts
        : this.customerAccountsPendingInstall;
    const pendingStatuses: string[] = [
      SiteStatus.Pend_de_Instalar,
      SiteStatus.Pend_de_Instalar_Pend,
      SiteStatus.Pending_installation,
      SiteStatus.Install_Pend
    ];
    siteStatus =
      siteStatus ||
      customerAccountsToFilter?.filter(item => item.marketType !== adaraValues.CLIENT_ADARA)
      .find(site => site.id === (siteId || this.selectDefault))?.status ||
      customerAccountsToFilter.filter(item => item.marketType !== adaraValues.CLIENT_ADARA)[0]?.status;
    return pendingStatuses.includes(siteStatus);
  }

  mapServiceSelector(item: ServiceModel, type: string, defaultProductId?: string): CardImageSelectorModel {
    let itemImageCard: CardImageSelectorModel = new CardImageSelectorModel();
    this.translateService.get('v10').subscribe(text => {
      itemImageCard.title = this.setItemImageCardTitle(item);
      const isHome5G: boolean = item.tarrifCode === home5G.tariffCode;
      const types: TypesCardImageSelectorMode = {
        MOBILEPOSTPAID: this.mobile(item, text.dashboard.images.product_selector.mobile),
        MOBILEPREPAID: this.mobile(item, text.dashboard.images.product_selector.mobile),
        MBBPOSTPAID: this.mbb(item, isHome5G,
          (isHome5G ? text.dashboard.images.product_selector.hogar5g : text.dashboard.images.product_selector.mbb)),
        MBBPREPAID: this.mbb(item, isHome5G, text.dashboard.images.product_selector.mbb),
        MBBHOLIDAY: this.mbb(item, isHome5G, text.dashboard.images.product_selector.mbb),
        LANDLINE: this.landline(item, text.dashboard.images.product_selector.landline),
        VODAFONEENTUCASA: this.landline(item, text.dashboard.images.product_selector.landline),
        TV: this.tv(item, text.dashboard.images.product_selector.tv),
        TVONLINE: this.tv(item, text.dashboard.images.product_selector.tv),
        ADSL: this.adsl(item, text.dashboard.images.product_selector.fibre_adsl),
        FIBRE: this.adsl(item, text.dashboard.images.product_selector.fibre_adsl)
      }
      itemImageCard = types[type.replace(/ /g, '').toUpperCase()];

      const checkedProduct: string = defaultProductId || this.subscription.customerData.currentService.id;
      itemImageCard = this.setItemImageCardChecked(itemImageCard, item, checkedProduct)
    });
    return itemImageCard;
  }

  public mobile(item: ServiceModel, image: string): CardImageSelectorModel {
    return {
      title: this.setItemImageCardTitle(item),
      description: item.id,
      image: this.appService.getImgFullPath(image),
      icon: IconType.ICON_SIM_MID,
      id: item.id
    }
  }

  public mbb(item: ServiceModel, isHome5G: boolean, image: string): CardImageSelectorModel {
    return {
      title: this.setItemImageCardTitle(item),
      description: isHome5G ? '' : item.id,
      image: this.appService.getImgFullPath(image),
      icon: isHome5G ? IconType.ICON_ROUTER_MID : IconType.ICON_USB_MODEM_MID,
      id: item.id
    }
  }

  public landline(item: ServiceModel, image: string): CardImageSelectorModel {
    return {
      title: this.setItemImageCardTitle(item),
      description: item.id,
      image: this.appService.getImgFullPath(image),
      icon: IconType.ICON_FIXED_LINE_MID,
      id: item.id
    }
  }

  public tv(item: ServiceModel, image: string): CardImageSelectorModel {
    return {
      title: this.setItemImageCardTitle(item),
      image: this.appService.getImgFullPath(image),
      icon: IconType.ICON_TV_MID,
      id: item.id
    }
  }

  public adsl(item: ServiceModel, image: string): CardImageSelectorModel {
    return {
      title: this.setItemImageCardTitle(item),
      image: this.appService.getImgFullPath(image),
      icon: IconType.ICON_ROUTER_MID,
      id: item.id
    }
  }

  public setItemImageCardTitle(item: ServiceModel): string {
    return item.name ?? '';
  }

  public setItemImageCardChecked(itemImageCard: CardImageSelectorModel, item: ServiceModel, checkedProduct: string):
    CardImageSelectorModel {
      itemImageCard.checked = false;
      if (item.id === checkedProduct) {
        itemImageCard.checked = true;
      }
      return itemImageCard;
  }

  getMyData(id: string, forceUpdate?: boolean): Observable<CustomerAccountNewModel> {
    if (!forceUpdate && this.newCustomerAccountData && this.newCustomerAccountData.accountId === id) {
      return of(this.newCustomerAccountData);
    }

    let url: string = API_URLS.CustomerAccount.getMyData;

    if (id) {
      url += `?account.id=${id}`;
    }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    const options: Object = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: MyAccountDetailsResponseModel) => {
      return this.populateSingletonWithNewModel(res);
    }));
  }

  modifyMyAccountData(
    modifiedData: MyAccountCustomerData,
    siteId: string = this.subscription.customerData.currentService.siteId
  ): Observable<CustomerAccountNewModel> {
    const url: string = API_URLS.CustomerAccount.patchMyData.replace('{accountId}', siteId);
    const rawBody: FullCustomerData = {
      ...modifiedData,
      account: [{ href: '/customerManagement/v3/customer/' + siteId, id: siteId }]
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');

    return this.http.patch(url, rawBody, { headers }).pipe(map((res: MyAccountDetailsResponseModel) => {
      return this.populateSingletonWithNewModel(res);
    }));
  }

  populateSingletonWithNewModel(newData: MyAccountDetailsResponseModel): CustomerAccountNewModel {
    this.newCustomerAccountData = { ...(this.newCustomerAccountData || {}), ...newData.customer.contactMedium.reduce(
      (result: CustomerAccountNewModel, current: ContactMedium) => {
        if (current.type === MyAccountDetailsType.PostalAddress) {
          const instAdd: AddressAccountEditDetailsViewModel = current.characteristic;
          result.contact[current.type.toLowerCase()] = {
            ...instAdd,
            formattedAddress: this.getFormattedAddress(instAdd)
          };
        } else if (current.type) {
          result.contact[current.type.toLowerCase()] = current.characteristic.phoneNumber || current.characteristic.emailAddress;
        } else {
          result.contact.favouritePhone = current.characteristic.phoneNumber;
        }
        return result;
      },
      { contact: {} } as CustomerAccountNewModel
    )};

    if (newData.billingAccount?.contact?.length) {
      const billAdd: AddressAccountEditDetailsViewModel = newData.billingAccount.contact[0].contactMedium[0].characteristic;
      this.newCustomerAccountData.billingAddress = {
        ...billAdd,
        formattedAddress: this.getFormattedAddress(billAdd)
      };
      this.newCustomerAccountData.billingAccountId = newData.billingAccount.id;
    }

    this.newCustomerAccountData.nif = newData.customer.id;
    this.newCustomerAccountData.accountId = newData.customer.account[0].id;
    this.newCustomerAccountData.billingAccountId = this.newCustomerAccountData.billingAccountId || this.newCustomerAccountData.accountId;
    this.newCustomerAccountData.msisdn = this.newCustomerAccountData.contact.mobile;

    const splittedName: string[] = newData.customer.name.split(':');
    this.newCustomerAccountData.name = splittedName[0] || '';
    this.newCustomerAccountData.firstSurname = splittedName[1] || '';
    this.newCustomerAccountData.secondSurname = splittedName[2] || '';
    this.newCustomerAccountData.fullName =
      `${this.newCustomerAccountData.name} ${this.newCustomerAccountData.firstSurname} ${this.newCustomerAccountData.secondSurname}`.trim();

    this.newCustomerAccountData = this.utility.parseNullValues(this.newCustomerAccountData);
    return this.newCustomerAccountData;
  }

  getFormattedAddress(address: AddressAccountEditDetailsViewModel): string {
    return (
      (address.street1 ? address.street1 + ', ' : '') +
      (address.street2 ? address.street2 + ', ' : '') +
      (address.postCode ? address.postCode + ' ' : '') +
      (address.city ? address.city + ', ' : '') +
      (address.stateOrProvince || '')
    ).trim();
  }

  public checkForAdaraSites(items: CustomerAccount[]): boolean | SelectorModel {
    return items.some(item => item.marketType === adaraValues.CLIENT_ADARA) ?
    (this.hasAdaraSite = true, this.adaraSelectorData = this.generateAdaraSelector()) :
    (this.hasAdaraSite = true, this.adaraSelectorData = this.generateAdaraSelector());
  }
  public checkSitesToShowAdaraSelector(sites: CustomerAccountResponseItem[]): boolean {
    const telcoSites: CustomerAccountResponseItem[] = sites.filter(site =>
      site.clientType !== adaraValues.CLIENT_ADARA || site.marketType !== adaraValues.CLIENT_ADARA);
    const adaraSites: CustomerAccountResponseItem[] = sites.filter(site =>
      site.clientType === adaraValues.CLIENT_ADARA || site.marketType === adaraValues.CLIENT_ADARA);
    return telcoSites.length >= 1 && adaraSites.length >= 1
  }

  public getTicketTraslado(relatedPartyId: string): Observable<BillTicket[]> {
    const url: string = API_URLS.Ticket.getTicketTraslado.replace('{relatedPartyId}', relatedPartyId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: Record<string, HttpHeaders> = {
      headers: headers,
    };
    return this.http.get(url, options).pipe(
      map((res: BillTicket[]) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}

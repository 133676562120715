import { Directive, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Observable ,  fromEvent ,  of } from 'rxjs';
import { tap, delay, mergeMap, takeUntil } from 'rxjs/operators';
import { UtilitiesService } from '../utils/utilities.service';

@Directive({
  selector: '[spLongPress]'
})
export class LongPressDirective {

  /**
   * input for sending how much time I need to long press
   * should be used in native element like this --> spLongPress=500
   * and 500 is a default value
   */
  @Input('spLongPress') howMuchMilliSeconds: number = 500;

  /**
   * output to be emitted as an event for the native elemnt
   * to be used when long press like this --> (longPressed)="myFunc()"
   */
  @Output() longPressed = new EventEmitter();

  /**
   * constructor for creating an instance of this directive
   * @param element is the reference of the native element
   */
  constructor(private element: ElementRef, private utility: UtilitiesService) {
    /** catch native element */
    const nativeElement: HTMLElement = this.element.nativeElement;

    /** determine which events to use desktop or mobile view */
    let startEventType = 'mousedown',
      endEventType = 'mouseup';

    /** check if mobile device then mousedown and mouseup must be replaced with touchstart and touchend */
    if (this.utility.detectDevice()) {
      startEventType = 'touchstart';
      endEventType = 'touchend';
    }

    /** give it startEventType and endEventType events for desktop view */
    const startEvent$: Observable<Event> = fromEvent(nativeElement, startEventType);
    const endEvent$: Observable<Event> = fromEvent(nativeElement, endEventType);

    /** long press "howMuchMilliSeconds" milliseconds */
    const longpress$ = startEvent$.pipe(
      mergeMap((e) => {
        return of(e).pipe(
          delay(this.howMuchMilliSeconds),
          takeUntil(endEvent$),
        );
      })
    );

    /** emit output when done */
    longpress$.pipe(
      tap(() => emitOutput()),
      delay(this.howMuchMilliSeconds),
    ).subscribe()

    /** emit longPressed output */
    const emitOutput = () => {
      this.longPressed.emit();
    }
  }

}

<div class="wallet-container-responsive">

  <sp-marketplace-tabs
      *ngIf="marketplaceService.hasMarketPlaceMyOrdersEP"
      class="marketplace__tabs"
      [pageTagData]="pageTagData"
  ></sp-marketplace-tabs>
  <sp-marketplace-iframe
    *ngIf="marketplaceService.hasMarketPlaceMyOrdersEP  && marketplaceService.marketplaceBillingAccountUrl && marketplaceService.tabMarketplace"
    class="marketplace__body"
    [url]="marketplaceService.marketplaceBillingAccountUrl"
  ></sp-marketplace-iframe>

  <div id="mainPaymentErrorTabShop" class="error__container" *ngIf="marketplaceService.tabShop">
    <div class="error__cont" >
      <div class="error__icon">
        <sp-lottie-loader *ngIf="!paymentErrorService.currnetError.imgUrl"
          [lottieImageUrl]="paymentErrorService.currnetError.lottieUrl" [loop]="false"></sp-lottie-loader>
        <img
          id="mainPaymentErrorImg"
          [src]="paymentErrorService.currnetError.imgUrl | imgFullPath"
          alt="error-icon"
          *ngIf="paymentErrorService.currnetError.imgUrl"
        >
      </div>
      <div class="error__desc-cont">
        <p
          id="mainPaymentErrorTitle"
          class="error__title"
          [innerHTML]="paymentErrorService.currnetError.title"
        ></p>
        <p
          id="mainPaymentErrorDesc"
          class="error__desc hide-break-point-tablet-desktop"
          [innerHTML]="paymentErrorService.currnetError.desc"
        ></p>
      </div>
    </div>
    <div class="error__btns-cont">
      <div class="error__first-btn"
        [ngClass]="{'add-btn-width-error': paymentErrorService.currnetError.secondBtnText 
      || paymentErrorService.currnetError.firstBtnText.length > 10, 
      'error__first-btn': paymentErrorService.currnetError.firstBtnText.length < 10 && !paymentErrorService.currnetError.secondBtnText}">
        <mva10-c-button [body]="paymentErrorService.currnetError.firstBtnText"
          [styleClass]=" isDimmed ? 'mva10-c-button--secondary' : 'mva10-c-button--primary'" (click)="onFirstBtnAction()"
          [disabled]="isDimmed">
        </mva10-c-button>
      </div>
      <div id="mainPaymentErrorSecondBtn" class="error__second-btn" *ngIf="paymentErrorService.currnetError.secondBtnText"
        [ngClass]="{'add-btn-width-error': paymentErrorService.currnetError.secondBtnText}">
        <mva10-c-button [body]="paymentErrorService.currnetError.secondBtnText" [styleClass]="'mva10-c-button--tertiary'"
          (click)="paymentErrorService.currnetError.secondBtnAction()">
        </mva10-c-button>
      </div>
    </div>
  </div>

</div>
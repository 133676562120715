
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CallOptionsRequest } from '../models/call-options.model';
import { LineServicesData } from './line-services.data';
import { LineRestrictions } from '../models/line-restrictions.model';
import * as JsonQuery from 'jsonpath/jsonpath';
import * as constants from '../shared/constants/defines';
import { CallRestrictions } from '../models/call-restrictions.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { API_URLS } from '../shared/constants/routes-config';
import { cachingKeys, LINE_SERVICES_CARDS_VISIBILITY_PAGES_MAP } from '../shared/constants/defines';
import { evict } from '../shared/decorators/evict.decorator';
import { LineRestrictionsVisibilityStatusModel, AnswerMachineCardModelVisibility, AutoredialCardModelVisibility,
    CallWaitingCardModelVisibility, DictaSMSCardModelVisibility, HideMyNumberCardModelVisibility, DivertCallsCardModelVisibility,
    LineRestrictionsVisibility, RecieveSMSCardModelVisibility, CallSettingsVisibility } from '../models/line-restrictions-visibilty-status.model';
import { SubscriptionService } from '../core/services/subscription.service';
import { ServiceType } from '../shared/enums/serviceType.enum';

@Injectable()
export class LineServicesService {
    callOptionsData: CallOptionsRequest;
    LineRestrictions: LineRestrictions;
    selectedService: any;
    voiceMailData: any;
    selectedServiceId: string;
    selectedServiceName: string;
    selectedServiceType: string;
    callOptionsSetData: CallOptionsRequest;
    voiceMailSetData: {};
    pageDetailsName: string;
    isStolen = false;
    errorCase = false;
    spinner = false;
    constructor(public settingData: LineServicesData,
        private http: HttpClient, private subscriptionsService: SubscriptionService) {
            this.subscriptionsService.customerSubject.subscribe(service => {
                this.selectedServiceId = service.id;
                this.selectedServiceName = service.name;
                this.selectedServiceType = service.type;
            })
    }

    getCallOptionsData(serviceId: string) {

        return this.settingData.getCallOptions(serviceId).pipe(map((res: any) => {
            this.callOptionsData = new CallOptionsRequest();
            this.callOptionsData.missedCallsNotification =
                JsonQuery.value(res, constants.JSON_PATHS.callOptions.missedCallsNotification) || null;
            this.callOptionsData.autoRedial =
                JsonQuery.value(res, constants.JSON_PATHS.callOptions.autoRedial) || null;
            this.callOptionsData.dictaSms =
                JsonQuery.value(res, constants.JSON_PATHS.callOptions.dictaSms) || null;

            return this.callOptionsData;
        }));
    }
    @evict(cachingKeys.getCallOptions)
    updateCallOptions(serviceId: string, outofcoverage?: string, busy?: string, autoRedialStatus?: string, dictaSmsStatus?: string) {
        const body = JSON.parse(JSON.stringify(this.callOptionsSetData)) as CallOptionsRequest;
        body.missedCallsNotification.outOfCoverage = outofcoverage ? outofcoverage : body.missedCallsNotification.outOfCoverage;
        body.missedCallsNotification.busy = busy ? busy : body.missedCallsNotification.busy;
        body.autoRedial.status = autoRedialStatus ? autoRedialStatus : body.autoRedial.status;
        body.dictaSms.status = dictaSmsStatus ? dictaSmsStatus : body.dictaSms.status;

        return this.settingData.updateCallOptions(serviceId, body).pipe(map(res => {
            return null;
        }));
    }


    updateVoiceMailSettings(serviceId: string, updatedSettings, ) {
        const url = API_URLS.serviceSettings.updateVoiceMailSettings.replace('{serviceId}', serviceId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/merge-patch+json');
        const options = {
            headers: headers
        };
        const body = updatedSettings

        return this.http.patch(url, body, options).pipe(map((res) => {
            return res;
        }));

    }
    getVoiceMailData(serviceId: string) {

        return this.settingData.getVoiceMail(serviceId).pipe(map((res: any) => {
            this.voiceMailData = res;
            return this.voiceMailData;
        }));
    }

    getLineRestrictions(subscriptionId: string, siteId: string, romaing?: boolean, fullStatus?: boolean): any {
        return this.settingData.getLineRestrictions(subscriptionId, siteId, romaing, fullStatus).pipe(map((res: any) => {
            if (res) {
                this.LineRestrictions = !this.LineRestrictions ?  new LineRestrictions() : this.LineRestrictions;
                this.LineRestrictions.callRestrictions = !this.LineRestrictions.callRestrictions ? new CallRestrictions() :
                this.LineRestrictions.callRestrictions;
                this.LineRestrictions.lineStatus = JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineStatus)
                this.LineRestrictions.callRestrictions.all =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.all);
                this.LineRestrictions.callRestrictions.highLevel =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.highLevel);
                this.LineRestrictions.callRestrictions.incoming =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.incoming);
                this.LineRestrictions.callRestrictions.incomingRoaming =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.incomingRoaming);
                this.LineRestrictions.callRestrictions.international =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.international);
                this.LineRestrictions.callRestrictions.data =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.data);
                this.LineRestrictions.callRestrictions.lowLevel =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.lowLevel);
                this.LineRestrictions.callRestrictions.roaming =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.roaming);
                this.LineRestrictions.callRestrictions.specialTariffMobile =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.specialTariffMobile);
                this.LineRestrictions.callRestrictions.specialTariffLandline =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.specialTariffLandline);
                this.getlineRestrictionsVisibilityStatusCards(res);
            }
        }));
    }

    patchLineRestrictions(subscriptionId: any, siteId: any, patchObject: any, roaming?: boolean, fullStatus?: boolean): any {
        return this.settingData.patchLineRestrictions(subscriptionId, siteId, patchObject, roaming, fullStatus).pipe(map(res => {
            if (res) {
                // falling back to the old value, as the patched response only returns updated values not the entire model
                this.LineRestrictions.lineStatus = JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineStatus) ?
                JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineStatus) : this.LineRestrictions.lineStatus;

                this.LineRestrictions.callRestrictions.all =
                JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.all) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.all)
                    : this.LineRestrictions.callRestrictions.all;

                this.LineRestrictions.callRestrictions.highLevel =
                JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.highLevel) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.highLevel)
                    : this.LineRestrictions.callRestrictions.highLevel ;

                this.LineRestrictions.callRestrictions.incoming =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.incoming) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.incoming)
                    : this.LineRestrictions.callRestrictions.incoming;

                this.LineRestrictions.callRestrictions.incomingRoaming =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.incomingRoaming) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.incomingRoaming)
                    : this.LineRestrictions.callRestrictions.incomingRoaming;

                this.LineRestrictions.callRestrictions.international =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.international) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.international)
                    : this.LineRestrictions.callRestrictions.international;

                this.LineRestrictions.callRestrictions.data =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.data) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.data)
                    : this.LineRestrictions.callRestrictions.data;

                this.LineRestrictions.callRestrictions.lowLevel =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.lowLevel) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.lowLevel)
                    : this.LineRestrictions.callRestrictions.lowLevel;

                this.LineRestrictions.callRestrictions.roaming =
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.roaming) ?
                    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.callRestrictions.roaming)
                    : this.LineRestrictions.callRestrictions.roaming;

                this.getlineRestrictionsVisibilityStatusCards(res);
                return this.LineRestrictions;

            }
        }))
    }

getlineRestrictionsVisibilityStatusCards(res) {
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards = new LineRestrictionsVisibilityStatusModel();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine = new AnswerMachineCardModelVisibility();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial = new AutoredialCardModelVisibility();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.callWaiting = new CallWaitingCardModelVisibility();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS = new DictaSMSCardModelVisibility();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.hideMyNumber = new HideMyNumberCardModelVisibility();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.divertCalls = new DivertCallsCardModelVisibility();
  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS = new RecieveSMSCardModelVisibility();

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.show) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.show) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.show) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.always =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.always) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.always) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.always) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.busy =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.busy) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.busy) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.busy) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.unreachable =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.unreachable) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.unreachable)
   !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.unreachable) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.noAnswerIn =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.noAnswerIn) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.noAnswerIn) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.noAnswerIn) : true;

   this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.smsNotification =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.smsNotification)
  !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.smsNotification)
   !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.smsNotification) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.language =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.language) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.language) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.answerMachine.language) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.show) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.show) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.show) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.busy =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.busy) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.busy) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.busy) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.outOfCoverage =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.outOfCoverage) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.outOfCoverage) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.recieveSMS.outOfCoverage) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.callWaiting.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callWaiting.show) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callWaiting.show) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callWaiting.show) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.hideMyNumber.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.hideMyNumber.show) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.hideMyNumber.show) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.hideMyNumber.show) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.divertCalls.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.divertCalls.show);

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.show) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.show) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.show) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.status =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.status) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.status) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.autoredial.status) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.show =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.show) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.show) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.show) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.status =
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.status) !== null &&
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.status) !== undefined ?
  JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.dictaSMS.status) : true;
    this.mapLineRestrictionVisibility(res);
}

mapLineRestrictionVisibility(res) {
    this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions = new LineRestrictionsVisibility();
    this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings = new CallSettingsVisibility();

    this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.lineStatus =
    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineStatus) !== null &&
    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineStatus) !== undefined ?
    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineStatus) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.all =
    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.all) !== null &&
    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.all) !== undefined ?
    JsonQuery.value(res, constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.all) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.highLevel =
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.highLevel) !== null &&
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.highLevel) !== undefined ?
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.highLevel) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.incoming =
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.incoming) !== null &&
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.incoming) !== undefined ?
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.incoming) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.incomingRoaming =
  JsonQuery.value(res,   constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.incomingRoaming)
   !== null &&
  JsonQuery.value(res,   constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.incomingRoaming)
  !== undefined ?
  JsonQuery.value(res,   constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.incomingRoaming) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.international =
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.international) !== null &&
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.international)
  !== undefined ?
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.international) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.lowLevel =
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.lowLevel) !== null &&
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.lowLevel) !== undefined ?
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.lowLevel) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.roaming =
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.roaming) !== null &&
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.roaming) !== undefined ?
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.roaming) : true;

  this.LineRestrictions.lineRestrictionsVisibilityStatusCards.lineRestrictions.callSettings.show =
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.show) !== null &&
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.show) !== undefined ?
  JsonQuery.value(res,  constants.JSON_PATHS.LineRestrictions.lineRestrictionsVisibilityStatusCards.callSettings.show) : true;
}

getLineServicesServiceId(serviceID) {
    const sitePrepaidPostpaidServices = this.subscriptionsService.customerData.services.filter(service => {
        return (service.type.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
        service.type.toLowerCase() === ServiceType.Prepaid.toLowerCase());
    });
    if (sitePrepaidPostpaidServices && sitePrepaidPostpaidServices.length > 0) {
        const service = sitePrepaidPostpaidServices.find(service => service.id === serviceID) || sitePrepaidPostpaidServices[0];
        this.selectedServiceId = service.id;
        this.selectedServiceName = service.name;
        return service.id;
    } else {
        return serviceID;
    }
}

checkPageVisibility(state: any): boolean {
    const urlSegments = state.url.split('/');
    if (urlSegments && urlSegments.length > 0) {
        const pageName = urlSegments[urlSegments.length - 1];
        const visibilityObj = pageName ? LINE_SERVICES_CARDS_VISIBILITY_PAGES_MAP[pageName.toLocaleLowerCase()] : null;
        const pageVisibility: boolean = visibilityObj ?  JsonQuery
            .value(this.LineRestrictions.lineRestrictionsVisibilityStatusCards, visibilityObj) : true;
        return (pageVisibility !== null && pageVisibility !== undefined) ? pageVisibility : true;
    }
    return true;
}










}


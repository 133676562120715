export interface ErrorTaggingModel {
    /**
     * EH: HTTP response status code (mandatory)
     */
    http_status: string | number;
    /**
     * EC: Error code returned within the response but different from http status code, or undefined otherwise (optional)
     */
    error_code?: string | number;
    /**
     * EM: Try to put (if available) some unique identifier of the error to differentiate
     * between users (not EC or EH), or undefined otherwise. (optional)
     */
    error_module?: string;
    /**
     * ET: tecnico/funcional/conectividad/seguridad (mandatory)
     */
    error_type: string;
    /**
     * EL: front/back (mandatory)
     */
    error_location: ErrorLocation;
    /**
     * ED: Description of the error, put it at the end because if it is too long
     * so that it does not cut the values of the rest of the variables. (mandatory)
     */
    error_description: string;
}

export enum ErrorLocation {
    back = 'back',
    front = 'front'
}

import {map} from 'rxjs/operators';
import { API_URLS } from '../constants/routes-config';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SubscriptionService } from '../../core/services/subscription.service';
import { StorageService } from '../../core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { CustomerType } from '../enums/customerType.enum';
import { Serviciability } from '../../models/serviciability.model';
import { Mva10FullModslServiceService } from './mva10-full-modsl-service.service';
import { AppService } from '../../app.service';
import { CONFIG } from '../constants/defines';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from '../../models/new-tagging-json-model';
import { MyAccountService } from '../../my-account/my-account.service';
import { DxlTwoApiModel, ExtendersListItem, ExtenderDetails, EditDeviceResponse } from '../../models/new-superwifi.model';
import { deviceIcons } from '../enums/superwifi-section.enum';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
@Injectable()
export class SuperwifiService {
  wifisName: string;
  wifisPassword: string;
  userEmail: string;
  customerId: string = '';
  locationId: string = '';
  tokenId: string = '';
  navegatedFrom: string = null;
  servie0ApiFailed: boolean = false;
  service6ApiFailed: boolean = false;
  servicedxl2ApiFailed: boolean = false;
  showRetryBtn: boolean = false;
  retryBtnForDXLServiceTwoAction: Function;
  showShimmerbg: boolean = false;
  public isSuperWifiDeepLink: boolean = false;
  private clientType: string;
  private shopType: string;
  public needEmail: boolean = false;
  private isMicroResegmented: boolean;
  public cartId: string;

  // Tagging variables
  public productName: string;
  public productFee: string;

  public productRouterType: string;
  public goToPlumaSubject: Subject<any> = new Subject<any>();


  showShimmerLoader: boolean;
  showSendMailModalOverlay: boolean;
  registerMailDesc: string;
  showOverlaySuperwifiVerifyEmailKO: boolean
  isActiveUser: boolean = false;
  dataSuperwifiTokenStatus: any;
  superWifiTokenData: any;

  constructor(
    private http: HttpClient,
    private subscriptionService: SubscriptionService,
    private storageService: StorageService,
    private translate: TranslateService,
    private cookieService: CookieService,
    public fullModalService: Mva10FullModslServiceService,
    public appService: AppService,
    private newTagging: NewTaggingHelperService,
    private myAccount: MyAccountService,
    private router: Router

  ) { }

  /** Plume service 0: Know if user email is already registed or not */
  getUserEmailVerifiedStatus(userEmail: string): Observable<Object> {
    const url: string = API_URLS.SuperWifi.getUserEmailVerifiedStatus.replace('{emailAddress}', userEmail);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: { headers: HttpHeaders } = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map(response => {
      return response;
    }));
  }

  /** Plume service 1: Obtains a token if the old has expired and sends a confirmation email */
  getUserToken(userData: any) {
    const url = API_URLS.SuperWifi.getUserToken;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.post(url, userData, options).pipe(map(response => {
      return response;
    }));
  }

  /** Plume service 6: register new user with his extensor and return a new token */
  registerUser(extensorId: string, userData: any) {
    const url = API_URLS.SuperWifi.registerUser.replace('{extensorId}', extensorId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.post(url, userData, options).pipe(map(response => {
      return response;
    }));
  }

  /** Plume service 3: Lists all the Extenders/nodes for a Customer’s location */
  getExtendersList(customerId: string, locationId: string , tokenId: string): Observable<{ nodes: Array<ExtendersListItem> }> {
    const url: string = API_URLS.SuperWifi.getExtendersList.replace('{costumerId}', customerId)
    .replace('{locationId}', locationId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', tokenId);
    const options: { headers: HttpHeaders } = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((response: { nodes: Array<ExtendersListItem> }) => {
      return response;
    }));
  }

  /** Get Client Devices from a customer Location */
  getExtenderDevicesDetails(customerId: string, locationId: string , tokenId: string, nodeId: string):
    Observable<ExtenderDetails> {
    const url: string = API_URLS.SuperWifi.getExtenderDetails.replace('{costumerId}', customerId)
      .replace('{locationId}', locationId).replace('{nodeId}', nodeId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', tokenId);
    const options: { headers: HttpHeaders } = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((response: ExtenderDetails) => {
      return response;
    }));
  }

  /** Edit extender name for each extender */
  editExtenderName(customerId: string, locationId: string, tokenId: string,
    extenderNameData: { nickname: string }, nodeId: string): Observable<ExtendersListItem> {
    const url: string = API_URLS.SuperWifi.getExtenderDetails.replace('{costumerId}', customerId)
      .replace('{locationId}', locationId).replace('{nodeId}', nodeId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', tokenId);
    const options: { headers: HttpHeaders } = {
      headers: headers
    };

    return this.http.put(url, extenderNameData, options).pipe(map(response => {
      return response;
    }));
  }

  /** Edit device name for each device */
  editDeviceName(deviceNameData: { nickname: string }, mac: string): Observable<EditDeviceResponse> {
    const url: string = API_URLS.SuperWifi.editDeviceName.replace('{costumerId}', this.customerId)
      .replace('{mac}', mac);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', this.tokenId);
    const options: { headers: HttpHeaders } = {
      headers: headers
    };

    return this.http.put(url, deviceNameData, options).pipe(map(response => {
      return response;
    }));
  }

  /** Plume service 4: gets Wifi Network info by a location */
  getWifiInfo(customerId: string, locationId: string , tokenId: string): Observable<Object> {
    const url = API_URLS.SuperWifi.getWifiInfo.replace('{costumerId}', customerId).replace('{locationId}', locationId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', tokenId);
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map(response => {
      return response;
    }));
  }

  /** Plume service 2: gets user locations for the service 4 */
  getLocationsWifiNetwork(customerId: string, tokenId: string): Observable<Object> {
    const url = API_URLS.SuperWifi.getLocationsWifiNetwork.replace('{costumerId}', customerId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', tokenId);
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map(response => {
      return response;
    }));
  }

  /** Servicio 1 DXL: gets token status, if it's active/pending/expired */
  getTokenUserStatus(siteId: string) {
    const url = API_URLS.SuperWifi.getTokenUserStatus.replace('{siteId}', siteId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers,
      '&scope=': CONFIG.SCOPES.join(' ')
    };

    return this.http.get(url, options).pipe(map(response => {
      return response;
    }));
  }

  /** Service 2 DXL: save user data in dxl  */
  setSuperwifiUserTokenDXL(userData: any) {
    const url = API_URLS.SuperWifi.setSuperwifiUserTokenDXL;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.post(url, userData, options).pipe(map(response => {
      return response;
    }));
  }

  /** Service 2 DXL: updates the user token */
  updateSuperwifiUserTokenDXL(userData: any): Observable<Object> {
    const url: string = API_URLS.SuperWifi.setSuperwifiUserTokenDXL;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    const options: { headers: HttpHeaders } = {
      headers: headers
    };

    return this.http.patch(url, userData, options).pipe(map(response => {
      return response;
    }));
  }

  /** Plume service 5: saves Wifi Network changes */
  saveWifiChanges(customerId: string, locationId: string, tokenId: string,
    wifiData: { encryptionKey: string, ssid: string }): Observable<object> {
    const url = API_URLS.SuperWifi.saveWifiChanges.replace('{costumerId}', customerId).replace('{locationId}', locationId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json').append('Authorization', tokenId);
    const options = {
      headers: headers
    };

    return this.http.put(url, wifiData, options).pipe(map(response => {
      return response;
    }));
  }

  public personalData(_emailReco?: string):  Observable<any> {
    const url: string = API_URLS.SuperWifi.personalData;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true
    };
    const data: any = {
      'siteId': this.subscriptionService.customerData.currentService.siteId,
      'contactPhone': this.subscriptionService.serviceListSite[0].id,
      'clientType': Number(this.clientType),
      'sceneType': 1,
      'shopType': Number(this.shopType),
      'email': _emailReco
    };
    return this.http.post(url, data, options);
  }

  public getClientType(): void {
    const customerType: string = this.storageService.userProfile.customerType.toLowerCase();

    this.translate.get('v10.commercial.request_config').subscribe(config => {
      this.clientType = this.isMicroResegmented
        ? config.clientType_Micro
        : (customerType === CustomerType.Consumer.toLowerCase())
          ? config.clientType_Partis
          : config.clientType_Micro;

      this.shopType = this.isMicroResegmented
        ? config.shopType_Micro
        : (customerType === CustomerType.Consumer.toLowerCase())
          ? config.shopType_Partis
          : config.shopType_Micro;
      this.cookieService.put('clientTypeID', this.clientType);
    });
  }

  public resetCesta(): Observable<any> {
    const customerId: string = this.subscriptionService.customerData.customerAccountsId,
      url: string = API_URLS.SuperWifi.resetCesta.replace('{customerAccountId}', customerId)
        .replace('{clientType}', this.clientType);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true
    };
    const data: any = {};

    return this.http.post(url, data, options);
  }

  public getExtraItem(): Observable<any> {
    const url: string = API_URLS.SuperWifi.extraItem
      .replace('{sceneType}', '1')
      .replace('{siteId}', this.subscriptionService.customerData.currentService.siteId)
      .replace('{registerType}', '8')
      .replace('{shopType}', this.shopType);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map(response => {
      return response;
    }));
  }

  public addServiceCesta(_flagExtraItemIdep: boolean, service: number, _serviceType: number): Observable<any> {
    const url: string = API_URLS.SuperWifi.addServiceCesta;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    let options: any = {
      headers: headers,
      withCredentials: true
    };
    if (this.cartId) {
      options = Object.assign(options, {
        NTOL_TXID: this.cartId
      });
    }
    const data: any = {
      'flagExtraItemIdep': _flagExtraItemIdep,
      'idService': service,
      'serviceType': _serviceType
    };
    return this.http.post(url, data, options).pipe(map(response => {
      return response;
    }));
  }

  public extraItemData(): Observable<any> {
    const customerAccountId: string = this.subscriptionService.customerData.customerAccountsId;
    const url: string = API_URLS.SuperWifi.extraitemData;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true
    };

    const data: any = {
      'siteId' : customerAccountId
    };

    return this.http.post(url, data, options).pipe(map(response => {
      return response;
    }));
  }

  public getLegalTerms(): Observable<any> {
    const url: string = API_URLS.SuperWifi.getLegalTerms
      .replace('{clientType}', this.clientType).replace('{shopType}', this.shopType);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map(response => {
      return response;
    }));
  }

  public setLegalTerms(_legalTerms: any): Observable<any> {
    const url: string = API_URLS.SuperWifi.setLegalTerms;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true,
      responseType: 'text' as 'json',
    };
    return this.http.post(url, _legalTerms, options).pipe(map(response => {
      return response;
    }));
  }

  public save(): Observable<any> {
    const url: string = API_URLS.SuperWifi.save;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: any = {
      headers: headers,
      withCredentials: true
    };
    const data: any = {};
    return this.http.post(url, data, options);
  }

  public getServiciability(): Observable<Serviciability> {
    const customerType: string = this.storageService.userProfile.customerType.toLowerCase();
    let url: string = API_URLS.Serviciability.getServiciability;
    this.translate.get('v10.commercial.request_config').subscribe(config => {
      url = url
        .replace('{siteId}', this.subscriptionService.customerData.customerAccountsId)
        .replace(
          '{shopType}',
          this.isMicroResegmented
            ? config.shopType_Micro
            : customerType === CustomerType.Consumer.toLowerCase()
              ? config.shopType_Partis
              : config.shopType_Micro
        );
    });

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options: any = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      return res;
    }));
  }

  public getAvailableAppointments(): Observable<any> {
    const url: string = API_URLS.SuperWifi.getDisponibilidadCita;
    const headers: HttpHeaders = new HttpHeaders();
    const options: any = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      return res;
    }))
  }

  public setAvailableAppointments(fechaCita: string, franjaHorariaCita: string): Observable<any> {
    const url: string = API_URLS.SuperWifi.postSaveCitaOrig;
    const headers: HttpHeaders = new HttpHeaders();
    const options: any = {
      headers: headers,
      withCredentials: true
    };

    const params: any = {
      fechaCita: fechaCita,
      franjaHorariaCita: franjaHorariaCita,
      flagAgendarCita: true
    }

    return this.http.post(url, params, options).pipe(map((res: any) => {
      return res;
    }))
  }
  /**
   * error overlay
   * general error overlay for super wifi apis fail.
   * @param wcsPath {string} get wcs path
   */
  showSuperWifiGeneralError(wcsPath: string): void {
    this.translate.get(`v10.productsServices.superWifi.${wcsPath}`).subscribe((data) => {
      this.fullModalService.errorTitle = data.title;
      this.fullModalService.errorSubtitle = data.subtitle;
      this.fullModalService.errorButton1 = data.button;
      this.fullModalService.img = data.icon;
      this.fullModalService.adjustErrorModalUi = true;
      if (this.showRetryBtn) {
        this.fullModalService.retryBtn = data.retryButton;
      }
    });
    this.appService.showErrorFullModal = true;
    this.fullModalService.retryBtnAction = () => {
      if (this.showRetryBtn) {
        this.appService.showErrorFullModal = false;
        this.retryBtnForDXLServiceTwoAction();
        if (!this.servicedxl2ApiFailed) {
          this.newTagging.getJsonTagging('superwifi').subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.interaction(data.eventList['extender_registration_error_click_retry'],
              data.page['stateList']['extender_registration_registration_error']);
          });
        }
      }
    }
    this.fullModalService.errorButton1Action = () => {
      this.appService.showErrorFullModal = false;
      this.servie0ApiFailed = false;
      this.fullModalService.retryBtnAction = null;
      this.retryBtnForDXLServiceTwoAction = null;
      this.fullModalService.retryBtn = '';
      if (this.service6ApiFailed) {
        this.newTagging.getJsonTagging('superwifi').subscribe((data: NewTaggingJsonModel) => {
          this.newTagging.interaction(data.eventList['register_extender_error'],
            data.page['stateList']['extenders_registration_error_page']);
        });
        this.service6ApiFailed = false;
      } else if (this.showRetryBtn) {
        if (!this.servicedxl2ApiFailed) {
          this.newTagging.getJsonTagging('superwifi').subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.interaction(data.eventList['extender_registration_error_click_close'],
              data.page['stateList']['extender_registration_registration_error']);
          });
        }
        this.servicedxl2ApiFailed = false;
        this.showRetryBtn = false;
      }
    }
    this.fullModalService.closeOverlaySubject.subscribe(() => {
      this.servie0ApiFailed = false;
      this.service6ApiFailed = false;
      this.servicedxl2ApiFailed = false;
      this.showRetryBtn = false;
      this.fullModalService.retryBtnAction = null;
      this.retryBtnForDXLServiceTwoAction = null;
      this.fullModalService.retryBtn = '';
    });
  }

  /**
   * fun to return dxl service 2 request object
   */
  getDXLServiceTwoRequestObject(email: string, userName: string, siteId: string, userId: string,
    tokenId: string, endDate: string): { 'Userinfo': DxlTwoApiModel[] } {
    const userData: { 'Userinfo': DxlTwoApiModel[] } = {
      'Userinfo': [
        {
          'email': email,
          'email_verified': true,
          'phone_number_verified': false,
          'name': userName,
          'sub': siteId,
          'userAssets': [
            {
              'id': userId,
              'assetType': 'userId',
              'entityType': 'plumeAuthCredential'
            },
            {
              'id': tokenId,
              'assetType': 'token',
              'entityType': 'plumeAuthCredential'
            },
            {
              'id': endDate,
              'assetType': 'endDate',
              'entityType': 'plumeAuthCredential'
            }
          ]
        }
      ]
    };
    return userData;
  }

  /**
   * get user name
   */
  getUserName(): string {
    const userName: string = this.myAccount.username ? this.myAccount.username : this.userEmail;
    return userName;
  }

  /**
   * get different device icons for each device depending on icon variable on each connected device response
   * @param deviceIcon {string} icon of each device connected to each extender
   */
  getDeviceIcon(deviceIcon: string, superwifiWcsObject: any): string {
    if (this.checkMobileIconValues(deviceIcon)) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.mobile;
    } else if (deviceIcon.toLowerCase() === deviceIcons.cam) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.camera;
    } else if (this.checkTVIconValues(deviceIcon)) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.tv;
    } else {
      deviceIcon = this.getDeviceIconFirstFun(deviceIcon, superwifiWcsObject);
    }
    return deviceIcon;
  }

  getDeviceIconFirstFun(deviceIcon: string, superwifiWcsObject: any): string {
    if (this.checkGameIconValues(deviceIcon)) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.game;
    } else if (this.checkDesktopIconValues(deviceIcon)) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.desktop;
    } else if (deviceIcon.toLowerCase() === deviceIcons.ipad || deviceIcon.toLowerCase() === deviceIcons.kindle) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.tablet;
    } else {
      deviceIcon = this.getDeviceIconSecondFun(deviceIcon, superwifiWcsObject);
    }
    return deviceIcon;
  }

  getDeviceIconSecondFun(deviceIcon: string, superwifiWcsObject: any): string {
    if (this.checkRouterIconValues(deviceIcon)) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.router;
    } else if (this.checkLaptopIconValues(deviceIcon)) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.labtop;
    } else if (deviceIcon.toLowerCase() === deviceIcons.printer || deviceIcon.toLowerCase() === deviceIcons.epson) {
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.printer;
    } else {
      deviceIcon = this.getDeviceIconThirdFun(deviceIcon, superwifiWcsObject);
    }
    return deviceIcon;
  }

  getDeviceIconThirdFun(deviceIcon: string, superwifiWcsObject: any): string {
    switch (deviceIcon.toLowerCase()) {
      case deviceIcons.lifx:
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.idea;
      break;
      case deviceIcons.voip:
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.landline;
      break;
      case deviceIcons.sonos:
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.speaker;
      break;
      default:
      deviceIcon = superwifiWcsObject.configurationPage.extensores.deviceList.mobile;
      break;
    }
    return deviceIcon;
  }

  checkGameIconValues(deviceIcon: string): boolean {
    return deviceIcon.toLowerCase() === deviceIcons.xbox || deviceIcon.toLowerCase() === deviceIcons.ps3
    || deviceIcon.toLowerCase() === deviceIcons.ps4;
  }

  checkTVIconValues(deviceIcon: string): boolean {
    return deviceIcon.toLowerCase() === deviceIcons.appletv || deviceIcon.toLowerCase() === deviceIcons.chromecast
    || deviceIcon.toLowerCase() === deviceIcons.tivo || deviceIcon.toLowerCase() === deviceIcons.np
    || deviceIcon.toLowerCase() === deviceIcons.tv || deviceIcon.toLowerCase() === deviceIcons.vizo;
  }

  checkMobileIconValues(deviceIcon: string): boolean {
    return deviceIcon.toLowerCase() === deviceIcons.iphone || deviceIcon.toLowerCase() === deviceIcons.ipod
    || deviceIcon.toLowerCase() === deviceIcons.phone || deviceIcon.toLowerCase() === deviceIcons.android;
  }

  checkDesktopIconValues(deviceIcon: string): boolean {
    return deviceIcon.toLowerCase() === deviceIcons.imac || deviceIcon.toLowerCase() === deviceIcons.desktop
    || deviceIcon.toLowerCase() === deviceIcons.macintosh;
  }

  checkRouterIconValues(deviceIcon: string): boolean {
    return deviceIcon.toLowerCase() === deviceIcons.netgear || deviceIcon.toLowerCase() === deviceIcons.linksys
    || deviceIcon.toLowerCase() === deviceIcons.asus || deviceIcon.toLowerCase() === deviceIcons.belkin
    || deviceIcon.toLowerCase() === deviceIcons.airport;
  }

  checkLaptopIconValues(deviceIcon: string): boolean {
    return deviceIcon.toLowerCase() === deviceIcons.thinkpad || deviceIcon.toLowerCase() === deviceIcons.macbook
    || deviceIcon.toLowerCase() === deviceIcons.air || deviceIcon.toLowerCase() === deviceIcons.pc
    || deviceIcon.toLowerCase() === deviceIcons.mbp;
  }

  confirmSuperwifiEmailVerified(): void {
    this.showShimmerbg = true;
    this.showShimmerLoader = true;
    this.getLocationsWifiNetwork(this.isActiveUser ? this.customerId : this.superWifiTokenData?.userId,
      this.isActiveUser ? this.tokenId : this.superWifiTokenData?.id
      ).subscribe((data: any) => {
      this.newTagging.getJsonTagging('superwifi').subscribe((taggingData: NewTaggingJsonModel) => {
        this.newTagging.interaction(taggingData.eventList['verify_associated_email_Verify_email_OK'],
        taggingData.page['stateList']['verify_associated_email']);
      });
      const locationId: string = data.length > 0 && data[0].id ? data[0].id : '';
      this.isActiveUser ? this.callPlumeServiceFour(locationId) : this.callDXLServiceTwo(locationId);
    }, () => {
      this.showShimmerLoader = false;
      this.appService.showVodaFullLoader = false;
      this.showShimmerbg = false;
      this.showSendMailModalOverlay = false;
      this.showOverlaySuperwifiVerifyEmailKO = true;
      this.newTagging.getJsonTagging('superwifi').subscribe((data: NewTaggingJsonModel) => {
        this.newTagging.state(data.page.stateList['verify_associated_email_error']);
      });
    });
  }

  /**
   * navigate to configuration screen
   * @param locationId {string} location id
   */
  callPlumeServiceFour(locationId: string): void {
    this.showShimmerLoader = false;
    this.appService.showVodaFullLoader = false;
    this.showShimmerbg = false;
    this.showOverlaySuperwifiVerifyEmailKO = false;
    this.showSendMailModalOverlay = false;
    this.locationId = locationId;
    if (!this.isActiveUser) {
      this.customerId = this.superWifiTokenData.userId;
      this.tokenId = this.superWifiTokenData.id;
    }
    this.router.navigate([config.ProductsAndServices.NewSuperWifi.SuperWifiConfiguration.route]);
  }

  /**
   * call of dxl service 2
   * @param locationId location id
   */
  callDXLServiceTwo(locationId: string): void {
    this.showShimmerLoader = true;
    this.updateSuperwifiUserTokenDXL(this.getDXLServiceTwoRequestObject(
      this.dataSuperwifiTokenStatus.email ? this.dataSuperwifiTokenStatus.email : '',
      this.dataSuperwifiTokenStatus.name ? this.dataSuperwifiTokenStatus.name : '',
      this.subscriptionService.customerData.currentService.siteId,
      this.superWifiTokenData.userId ? this.superWifiTokenData.userId : '',
      this.superWifiTokenData.id ? this.superWifiTokenData.id : '',
      this.superWifiTokenData.created ? this.superWifiTokenData.created : '')).subscribe(() => {
        this.callPlumeServiceFour(locationId);
      }, () => {
        this.showShimmerLoader = false;
        this.appService.showVodaFullLoader = false;
        this.showShimmerbg = false;
        this.showOverlaySuperwifiVerifyEmailKO = false;
        this.showRetryBtn = true;
        this.servicedxl2ApiFailed = true;
        this.showSuperWifiGeneralError('configurationPage.registerModalKO');
        this.retryBtnForDXLServiceTwoAction = this.callDXLServiceTwo.bind(this, locationId);
      });
  }
  retrySuperwifiEmailVerifiedBtn(): void {
    this.newTagging.getJsonTagging('superwifi').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(this.isActiveUser ? data.eventList['verify_associated_email_error_error_retry_valid_token']
       : data.eventList['verify_associated_email_error_error_retry'],
        data.page['stateList']['verify_associated_email_error_action']);
    });
    this.showOverlaySuperwifiVerifyEmailKO = false;
    this.registerMailDesc = this.translate.instant('v10.productsServices.superWifi.mailModal.subtitleAssociated')
              .replace('{0}', this.dataSuperwifiTokenStatus.email);
    this.showSendMailModalOverlay = true;
  }
}

import { MonthName } from './../enums/monthName.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myDataFormatePipe'
})
export class MyDataFormatePipe implements PipeTransform {

  transform(date: any, args?: any): any {
    if (date) {
      // This Was Commented As as it changes the Date Object Value based on the Browser (Time Zone Problem)
      // date = new Date(date).toISOString();
      // Removing the time from the date
      const formattedDate = date.split('T')[0]
      const dateArray = formattedDate.split('-');

      if (args === 'long') {
        switch (dateArray[1]) {
          case '1':
          case '01':
            dateArray[1] = MonthName.January;
            break;
          case '2':
          case '02':
            dateArray[1] = MonthName.February;
            break;
          case '3':
          case '03':
            dateArray[1] = MonthName.March;
            break;
          case '4':
          case '04':
            dateArray[1] = MonthName.April;
            break;
          case '5':
          case '05':
            dateArray[1] = MonthName.May;
            break;
          case '6':
          case '06':
            dateArray[1] = MonthName.June;
            break;
          case '7':
          case '07':
            dateArray[1] = MonthName.July;
            break;
          case '8':
          case '08':
            dateArray[1] = MonthName.August;
            break;
          case '9':
          case '09':
            dateArray[1] = MonthName.September;
            break;
          case '10':
            dateArray[1] = MonthName.October;
            break;
          case '11':
            dateArray[1] = MonthName.November;
            break;
          case '12':
            dateArray[1] = MonthName.December;
            break;
          default:
            dateArray[1] = dateArray[1]
            break;
        }
        const newDate = dateArray[2] + ' de ' + dateArray[1] + ' de ' + dateArray[0]
        return newDate;
      } else {
        const year = dateArray[0].slice(-2);
        const newDate = dateArray[2] + '/' + dateArray[1] + '/' + year
        return newDate;
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { opProductsN } from '../shared/enums/op-products.enum';
import { OpDetailProduct, tabTitles, stepArr, dataPegaInit} from '../shared/enums/op-detail-product.enum';
import { TranslateService } from '@ngx-translate/core';
import { OpAddon } from '../models/opAddon.model';
import {  SelectBadgeModel,  } from '@mva10/mva10-angular';
import { StateService, ProductPromotion, Promotion, DataPega } from './../models/oneProfesionalData.model';

import { UntypedFormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})

export class OneProfesionalService {
  public product: any;
  public preRoot: string;
  public tabTitles: string[] = tabTitles;
  public actTabTxt: string;
  public actTab: number = stepArr[0];
  public newDataPega: DataPega = dataPegaInit;
  public formTitle: string;
  public formTitleResumen: string;
  public resumeLicences: string;
  public resumeLicencesTxt: string;
  public selectedLicences: number = 1;
  public selectedProductTab: number = 0;
  public originProductTab: number = 0;
  public priceUnitTaxes: string;
  public priceMonthTotalResume: string;
  public unitLicencesPriceText: string;
  public infoLicensesText: string;
  public unitTaxes: string;
  public totalTaxes: string;
  public hasLicences: boolean = false;
  public titleBar: string;
  public newAdminData: string;
  public newLicencesData: string;
  public newBusinessData: string;
  public nombreAdm: string;
  public apellidosAdm: string;
  public emailAdm: string;
  public licences: string[];
  public formDomain: UntypedFormGroup;
  public actividadEmpresa: string;
  public emailEmpresa: string;
  public telefonoEmpresa: string;
  public urlEmpresa: string;
  public socialEmpresa: string;
  public checkboxEmpresa: boolean = true;
  public codeSelectedProduct: string;
  public addon: OpAddon;
  public pageLegalTermsKey: string;
  public arrayServiceHireable: SelectBadgeModel[] = [];
  public arrayServiceNotHireable: SelectBadgeModel[] = [];
  public arrayServiceList: SelectBadgeModel[] = [];
  public selectedService: string;
  public serviceTxt: string;
  public selectLine: string;
  public instance: string;
  public phoneNumber: string = null;
  public lineDescription: string = null;
  public promotion: ProductPromotion = new ProductPromotion;
  public showBackdropPega: boolean = false;


constructor( private translate: TranslateService, ) {

}

  setFormTitle(): void {
    let actTitleTab: string;
    if (this.preRoot && this.preRoot + 'summary.aceptConditionstitle' && this.product.idCCategoria !== 8) {
      actTitleTab = this.tabTitles[this.actTab];
      if (this.product.idCCategoria === 9) {
        actTitleTab = this.product.name
      }
      this.formTitle = this.translate.instant(this.preRoot + 'summary.aceptConditionstitle').replace('%s', actTitleTab);
      this.formTitleResumen = actTitleTab;
    }
    else if (this.product.idCCategoria === 8) {
      actTitleTab = this.tabTitles[this.actTab];
      this.formTitle = this.translate.instant(this.preRoot + 'yourBusiness.aceptConditionstitle').replace('%s', actTitleTab);
      this.formTitleResumen = actTitleTab;
    }
    else {
      this.formTitleResumen = this.product.name;
    }
  }

  getWcs(): void {

    const actProduct: any = opProductsN.find(productN => productN.value === this.product.idCCategoria);
    if (actProduct) {
      this.preRoot = actProduct.wcs;
    }

  }

  setOpService(): void {
    this.hasLicences = false;
    this.newAdminData = '';
    this.newLicencesData = '';
    this.selectedLicences = 1;
    this.nombreAdm = '';
    this.apellidosAdm = '';
    this.emailAdm = '';
    this.actividadEmpresa = '';
    this.emailEmpresa = '';
    this.telefonoEmpresa = '';
    this.urlEmpresa = '';
    this.socialEmpresa = '';
    this.checkboxEmpresa = true;
    this.formDomain = null;
    this.licences = [];
    this.priceMonthTotalResume = null;
    this.arrayServiceList = [];
    this.arrayServiceHireable = [];
    this.arrayServiceNotHireable = [];
    this.phoneNumber = null;
    this.lineDescription = null;
    if (this.product ) {
      this.formTitleResumen = this.product.name;
      this.titleBar = this.product.name;
    }
    this.newDataPega = dataPegaInit;
    this.actTabTxt = '';
  }

  exitOpService(): void {
    this.newDataPega.isPega = false;
  }

  getSubscriptionServiceList(listStateServiceInstance: StateService[]): void {
    this.arrayServiceList = [];
    listStateServiceInstance.forEach((service, index) => {
      const element: SelectBadgeModel = new SelectBadgeModel();
          element.id = service.msisdn;
          element.checked = index === 0;
          switch (service.rgu) {
            case (OpDetailProduct.rguInternet):
              element.text = OpDetailProduct.internetTxt;
              break;
            default:
              element.text = service.msisdn;
          }
          this.selectedService = service.msisdn;
          if (service.available && service.hireable) {
            this.arrayServiceHireable.push(element);
          }
          else if (!service.hireable) {
            this.arrayServiceNotHireable.push(element);
          }


          this.serviceTxt = element.text;

    });
    this.arrayServiceList = this.arrayServiceHireable.concat(this.arrayServiceNotHireable);
    if (this.arrayServiceList.length) {
      this.arrayServiceList[0].checked = true;
    }

  }

  setPromotions(): void {
    this.promotion = null;
    if (this.product.onepProducts[this.selectedProductTab].promotions?.length) {
      const actPrice: number = this.product.onepProducts[
        this.selectedProductTab].paymentMethodsList[0].fee.toFixed(2);
      const actPriceTaxes: number = this.product.onepProducts[
        this.selectedProductTab].paymentMethodsList[0].feeTaxes.toFixed(2);
      const actPromotion: Promotion = this.product.onepProducts[this.selectedProductTab].promotions[0];
      const newPromotion: ProductPromotion = new ProductPromotion;
      const productCode: string = this.product.onepProducts[this.selectedProductTab].paymentMethodsList[0].cdNameCatalogOffer;
      newPromotion.perct = actPromotion.discountAmount;
      const priceUnitNoTaxesPromotion: string = this.translate.instant('v10.commercial.microCartera.oneProLanding.product.priceUnit')
    .replace('%s', this.decompose(actPrice - this.calculaPorcentajes(actPrice, Number(actPromotion.discountAmount))));
    const actPriceTaxesPromotion: string =
    this.decompose(actPriceTaxes - this.calculaPorcentajes(actPriceTaxes, Number(actPromotion.discountAmount)));
    const priceUnitTaxesPromotion: string =
    this.translate.instant(`v10.commercial.microCartera.onePro.product.OneProf.${productCode}.status.available.monthlyFeeWithTax`)
    .replace('%s', actPriceTaxesPromotion);
     newPromotion.priceWithPromotion = priceUnitNoTaxesPromotion;
     newPromotion.priceWithPromotionOrigin = priceUnitNoTaxesPromotion;
     newPromotion.priceWithPromotionTaxes = priceUnitTaxesPromotion;
      newPromotion.shortTextPromotion = actPromotion.shortText;
      newPromotion.longTextPromotion = actPromotion.longText;
      newPromotion.originalPrice = actPrice;
      newPromotion.imgPromotion = actPromotion.urlMobilePromotionImageFicha;
      newPromotion.ticketText = actPromotion.ticketText;
      newPromotion.promotionsResumeTxt =
      this.translate.instant(`v10.commercial.microCartera.onePro.summary.OneProf.${productCode}.promotions.title`);

      newPromotion.originalPrice = this.translate.instant(
        `v10.commercial.microCartera.onePro.product.OneProf.${productCode}.status.available.promotionTextEnd`)
        .replace('%s', this.decompose(actPrice));
      newPromotion.textEnd = this.translate.instant(`v10.commercial.microCartera.onePro.moreInfo.OneProf.${productCode}.promotionTextEnd`);
      this.promotion = newPromotion;
      newPromotion.ResumeOriginalPrice = this.translate.instant(
        `v10.commercial.microCartera.onePro.summary.OneProf.${productCode}.promotions.descriptionPromotion`)
        .replace('%s', this.decompose(actPrice));
    }


  }

  public calculaPorcentajes(numero: number, perct: number): number {
    return Math.round(numero * perct) / 100;
  }

  public decompose(newNumber: number): string {

    const integer: number = Math.floor(newNumber);

    const decimal: number = newNumber - integer;

    if (decimal === 0) {
      return Math.trunc(integer).toString()
    }

    else {
      newNumber = Number(newNumber);
      return newNumber.toFixed(2)
    }

  }

}

import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { BillingService } from '../../billing/billing.service';
import { StorageService } from '../../core/services/storage.service';
import { Guid } from 'guid-typescript';
import {
  LOCAL_STORAGE_KEYS, paymentPhpUrlOk, paymentPhpUrlKo, TopupPrepaidStages,
  ERRORCODES, Result, VERIFYEMAIL
} from './../../shared/constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { TopUpService } from '../top-up.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IframeStatus, JourneyIdEnum } from '../../shared/enums/iframe-status.enum';
import { BillPaymentProcessRequest } from '../../models/bill-payment-process-request.model';
import { TopupPrepaidError } from '../../shared/enums/top-up-prepaid-error.enum';
import { TopupPrepaidErrorOVerlayDataService } from '../top-up-prepaid-error-overlay-data.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { LanguageEnum } from '../../shared/enums/language.enum';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { Subscription } from 'rxjs';
import { ResizeService } from '../../mva10/shared/services/resize.service';
import { Breakpoints } from '@mva10/mva10-angular';






@Component({
  selector: 'sp-mva10-topup-iframe-enablement',
  templateUrl: './mva10-topup-iframe-enablement.component.html',
  styleUrls: ['./mva10-topup-iframe-enablement.component.scss']
})
export class Mva10TopupIframeEnablementComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  showDefaultIframeText: boolean
  showLottieLoader: boolean
  uuidForNetPlusIframe: Guid;
  visaImage: string;
  masterCardImage: string;
  scrolledView = false;
  iframeUrl: any;
  showFullLoader = false;
  counter = 0;
  timer = 50;
  timeouted = false;
  responseDone = false;
  koStatus = false;
  iframeSuccess = true;
  transactionId = '';
  timerInterval: any;
  globalTimerInterval: any;
  hideContent = false;
  globalTimeout = '300';
  showCancelationOverlay = false;
  showParagraph = false;
  loaderDescription = '';
  cancelTopupSubscription: Subscription;
  @ViewChild('topupContainer', { static: true }) topupContainerDiv: HTMLElement;
  @ViewChild('iframeContainer') iframeContainer: ElementRef;
  @ViewChild('pageContentContainer', { static: true }) pageContentContainer: ElementRef;
  topupServiceId: string;
  uiModel = {
    refills: '',
    numberToRecharge: '',
    quantity: '',
    billPaymentType: '',
    creditCardInfo: '',
    iframeLoadingError_title: '',
    iframeLoadingError_description: '',
    iframeLoadingError_button1: '',
    sslSecured: '',
  }
  iframe: any;
  iframeInitialHeightSetFlag: boolean = false;
  /**for padlock image */
  BREAKPOINTS: typeof Breakpoints = Breakpoints;
  breakpoint: Breakpoints = Breakpoints.MOBILE;
  constructor(public trayService: SpMva10OverlayService,
    public billingService: BillingService,
    private storageService: StorageService,
    private translateService: TranslateService,
    public appService: AppService,
    public topupService: TopUpService,
    private sanitizer: DomSanitizer,
    private topupErrorService: TopupPrepaidErrorOVerlayDataService,
    private translate: TranslateService,
    private tagging: TaggingHelperService,
    private router: Router,
    private utilService: UtilitiesService,
    private dynamicTrayService: DynamicTrayService,
    private resizeService: ResizeService
  ) { }
  ngOnInit() {
    this.breakpoint = this.resizeService.breakpoint;
    this.topupService.dynamicTrayData.preventTrayInnerScrolling = false;
    this.topupService.dynamicTrayData.isGradientBackground = false;
    this.topupService.dynamicTrayData.removeTrayPadding = true;
    // this.topupService.dynamicTrayData.isContentBodyFullHeight = true;
    history.pushState(null, null, location.href);
    window.onpopstate = () => {
      history.go(1);
      this.closeTray();
    };

    this.setUiContent(this.topupService.topupLanguage);
    this.topupServiceId = this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id
      : this.topupService.msisdn;
    this.sendPageTagging();
    this.trayService.fullScreenTray = true;
    this.translateService.get('payment').subscribe(data => {
      if (this.breakpoint === Breakpoints.MOBILE) {
        this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciVisa.url);
        this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciMasterCard.url);
      }
      else {
        this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciFooterVisa.url);
        this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciFooterMasterCard.url);
      }
    })
    this.setAppUuidForTopup();
    this.requestNetplusIframeUrlForMva10Topup(true);
    this.topupService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);

  }
  /**
   *this function is used to load wcs keys for the page
   *according to which journey we are going through now
   * if payment journey this.billingService.startBillingPayment
   * we get wvery thing accordingly and changes creditCardInfo variable
   * according to which journey of payment
   * if we are going through prepaid jourey
   * we check on the language and then fill WCS keys
   *  @param {*} [lang=LanguageEnum.spanish]
   * @memberof Mva10TopupIframeEnablementComponent
   */
  setUiContent(lang = LanguageEnum.spanish) {
    switch (lang) {
      case LanguageEnum.spanish:
        this.translate.get('common.itemsList').subscribe(data => {
          this.uiModel.refills = data.refills.body;
          this.uiModel.numberToRecharge = data.numberToRecharge.body;
          this.uiModel.quantity = data.quantity.body;
          this.uiModel.creditCardInfo = data.creditCardInfo.body;
        });
        this.translate.get('payment').subscribe(data => {
          this.uiModel.iframeLoadingError_title = data.messagesList.iframeLoadingError.iframeLoadingError_title;
          this.uiModel.iframeLoadingError_description = data.messagesList.iframeLoadingError.iframeLoadingError_description;
          this.uiModel.sslSecured = data.mobileRecharge.fieldsList.sslSecured.body;
          this.uiModel.iframeLoadingError_button1 = data.messagesList.iframeLoadingError.iframeLoadingError_button1.text;
        });
        break;
      case LanguageEnum.english:
        this.translate.get('common.itemsList').subscribe(data => {
          this.uiModel.refills = data.refills_en.body;
          this.uiModel.numberToRecharge = data.numberToRecharge_en.body;
          this.uiModel.quantity = data.quantity_en.body;
          this.uiModel.creditCardInfo = data.creditCardInfo_en.body;
        });
        this.translate.get('payment').subscribe(data => {
          this.uiModel.iframeLoadingError_title = data.messagesList.iframeLoadingError_en.iframeLoadingError_en_title;
          this.uiModel.iframeLoadingError_description = data.messagesList.iframeLoadingError_en.iframeLoadingError_en_description;
          this.uiModel.sslSecured = data.mobileRecharge.fieldsList.sslSecured_en.body;
          this.uiModel.iframeLoadingError_button1 = data.messagesList.iframeLoadingError_en.iframeLoadingError_en_button1.text;

        });
        break;
    }

  }

  /** sets content height in case of mobile view
   * to have all available height in case of anonymous topup
   * use dynamic height of dynamic tray in case of dynamic tray
  */
  setContentHeight() {
    if (this.resizeService.breakpoint === Breakpoints.MOBILE) {
      if (this.topupService.useDynamicTray) {
        this.pageContentContainer.nativeElement.style.height = 'initial';
      } else {
        this.pageContentContainer.nativeElement.style.height = window.innerHeight + 'px';
      }
    }
  }
  ngAfterViewInit() {
    this.setContentHeight();
  }
  sendPageTagging() {
    const service: string = this.topupService.msisdn ? this.topupService.msisdn :
      (this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id : '');
    if (service) {
      this.tagging.encryptApi(service).subscribe(data => {
        tagging.pciPrepaid.journys.secondStep.data.mobile_recharge = data;
        tagging.pciPrepaid.journys.secondStep.data.journey_options = this.topupService.curruentSelectedAmountSelectorValue.toString();
        tagging.pciPrepaid.journys.secondStep.data.journey_type = this.topupService.setPciTaggingJourneyType();
        tagging.pciPrepaid.journys.success.data.journey_type = this.topupService.setPciTaggingJourneyType();
        tagging.pciPrepaid.journys.error.data.journey_type = this.topupService.setPciTaggingJourneyType();
        this.tagging.view(tagging.pciPrepaidPages.iframePage);
        tagging.pciPrepaid.journys.secondStep.data.journey_subcategory = this.topupService.setTaggingJournySubCategoryValue();
        this.tagging.track(tagging.pciPrepaid.journys.secondStep.eventName,
          tagging.pciPrepaid.journys.secondStep.data,
          tagging.pciPrepaidPages.iframePage,
          this.storageService.topupPrepaid);
      });
    }
  }

  closeTray() {
    if (this.transactionId) {
      this.hideContent = true;
      this.trayService.showBackwardTransition = false;
      this.trayService.showForwardTransition = true;
      this.configureTopupGeneralErrorOverlay(this.topupService.msisdn);
      setTimeout(() => {
        this.showCancelationOverlay = true;
        this.topupErrorService.activateXbuttonSubject = true;
        this.topupService.dynamicTrayData.closeButtonAction = this.clickXButtonInCancelationOverlay.bind(this);
        this.topupErrorService.xButtonSubject.subscribe(() => {
          this.clickXButtonInCancelationOverlay();
        })
        this.hideContent = false;
      }, 2000);
    }
    else {
      this.trayService.close();
      this.dynamicTrayService.closeTraySubject.next();
      if (this.topupService.isAnonymousProcess) {
        this.router.navigate([config.login.route]);
      }
      this.topupService.msisdn = '';
    }
  }

  sendTaggingClickOnXInteraction() {
    const pageTaggingName: string = tagging.pciPrepaidPages.iframePage.page_name;
    const eventName: string = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName
      .replace('<page_name>', pageTaggingName);
    this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
      tagging.pciPrepaidPages.iframePage);
  }

  closeTrayWithTagging() {
    this.sendTaggingClickOnXInteraction();
    this.closeTray();
  }
  clickXButtonInCancelationOverlay() {
    this.showCancelationOverlay = false;
    this.topupService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);
    this.topupErrorService.activateXbuttonSubject = false;
    this.trayService.showBackwardTransition = false;
    this.trayService.showForwardTransition = false;
    this.topupService.dynamicTrayData.isGradientBackground = false;
  }

  requestNetplusIframeUrlForMva10Topup(isCalledFromInit = false) {
    this.hideContent = false;
    this.timer = 0;
    this.topupService.showIframeError = false;
    this.appService.showLottieLoader = true;
    let requestBody = {
      documentId: !this.topupService.isAnonymousProcess ? this.storageService.userProfile.document.id : '',
      amount: Math.round(+(this.topupService.curruentSelectedAmountSelectorValue * 100)).toString(),
      appUUID: this.uuidForNetPlusIframe,
      'topupSubscriptionID': this.topupService.msisdn || '',
      language: this.topupService.topupLanguage,
      journeyId: this.topupService.selectedCreditCard && !this.topupService.isAnonymousProcess ?
        JourneyIdEnum.prePaidWithCvv : JourneyIdEnum.prePaidWithOutCvv
    };
    if (this.topupService.selectedCreditCard && !this.topupService.isAnonymousProcess) {
      requestBody = this.getSelectedCardDetails(requestBody);
    }
    const service = this.topupService.isAnonymousProcess ? this.topupService.msisdn :
      this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id : '';
    this.billingService.requestNetplusIframeUrlForMva10Topup(requestBody, service,
      this.topupService.isAnonymousProcess)
      .subscribe((res: any) => {
        this.counter = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1 ? 1 : 0;
        this.topupService.showIframeError = false;
        this.iframeUrl = this.sanitizer.
          bypassSecurityTrustResourceUrl(res.iframeURL);
        this.appService.showLottieLoader = false;
        this.timer = res.timer
        this.globalTimeout = res.globalTimeout || '300';
        this.transactionId = res.iframeURL.split('numOpOrigen=')[1].split('&')[0];
        this.topupService.transactionId = this.transactionId;
      },
        (error) => {
          if (error.error) {
            // non prepaid/non vodafone/not active
            if (error.error.ecode === ERRORCODES.NOT_A_PREPAID_SERVICE || error.error.ecode === ERRORCODES.PAYMENT_INACTIVE_MSISDN ||
              error.error.ecode === ERRORCODES.PENDING_MSISDN) {
              this.handelGeneralError(TopupPrepaidError.Notactive);
              return;
            }
            // Top-up in Progress
            else if (error.error.ecode === ERRORCODES.PREPAID_SERVICE_HAS_PENDING_ORDERS) {
              this.handelGeneralError(TopupPrepaidError.TopupInProgress);
              return;
            }
            // Replica error
            else if (error.error.ecode === ERRORCODES.REPLICA_MODE_ERROR) {
              if (this.topupService.isAnonymousProcess) {
                this.topupErrorService.taggingViewObj = tagging.pciPrepaidPages.systemUnderMaintenance;
                this.handelGeneralError(TopupPrepaidError.ReplicaMode, false);
              }
              else {
                this.handelGeneralError(TopupPrepaidError.ReplicaMode);
              }
              return;
            }
            // app unavailability error
            else if (error.error.ecode === VERIFYEMAIL.errorAppUnavilability) {
              if (this.topupService.isAnonymousProcess) {
                this.appService.errorAvilabilityApp = false;
                this.topupErrorService.taggingViewObj = tagging.pciPrepaidPages.systemUnderMaintenance;
                this.handelGeneralError(TopupPrepaidError.ReplicaMode, false);
              } else {
                this.closeTray();
                this.router.navigate([config.dashboard.route])
              }
              return;
            }
          }
          this.handleErrorCategory(TopupPrepaidError.PaymentApiFails)
          this.appService.showLottieLoader = false;
        })
    if (!isCalledFromInit && !this.billingService.startBillingPayment) {
      this.tagging.track(tagging.pciPrepaid.interactions.clickRetryLoadIframe.eventName,
        tagging.pciPrepaid.interactions.clickRetryLoadIframe.data,
        tagging.pciPrepaidPages.iframePage, this.storageService.topupPrepaid)
    }

  }

  getSelectedCardDetails(requestBody) {
    const selectedCardDetails = {
      cardMask: this.topupService.selectedCreditCard.cardMask,
      token: this.topupService.selectedCreditCard.token,
      expiryDate: this.topupService.selectedCreditCard.expiryDate
    }
    return { ...requestBody, ...selectedCardDetails };
  }

  handelGeneralError(errorType: TopupPrepaidError, isKoTaggingJourney = true): void {
    this.topupErrorService.isKoTaggingJourney = isKoTaggingJourney;
    this.topupService.showPlus = false;
    setTimeout(() => {
      this.topupErrorService.setErrorUiData(errorType);
      this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.topupGeneralError });
      if (this.dynamicTrayService.trayData) {
        this.dynamicTrayService.trayData.basicTrayData.showBackButton = false;
      }
    }, 1000);
  }
  setAppUuidForTopup() {
    this.uuidForNetPlusIframe = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.NETPLUS_UUID_FOR_TOPUP);
    if (!this.uuidForNetPlusIframe) {
      this.uuidForNetPlusIframe = (Guid.create())['value'];
      this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.NETPLUS_UUID_FOR_TOPUP, this.uuidForNetPlusIframe);
    }
  }

  onScroll(event) {
    this.scrolledView = event.target.scrollTop !== 0;
    this.topupService.dynamicTrayData.trayInnerContentScrolling = this.scrolledView;
  }

  loadURL(iframe) {
    this.iframe = iframe;
    this.counter++;
    if (this.counter === 2) {
      history.pushState(null, null, location.href);
      this.setTimeOutTimer(iframe, this.globalTimeout, true);
    }
    if (this.counter === 3) {
      history.pushState(null, null, location.href);
      this.setIframeHeight(iframe);
      this.handleOKAndKO(iframe);
    }
    if (this.counter > 3) {
      history.pushState(null, null, location.href);
      this.handleOKAndKO(iframe, true);
    }
  }

  setIframeHeight(iframe: HTMLElement): void {
    if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
      iframe.style.height = Math.max((iframe.offsetHeight + iframe.offsetTop), 550) + 'px';
    } else {
      iframe.style.height = (iframe.offsetHeight + iframe.offsetTop + 130) + 'px';
    }
  }

  setIframeInitialHeight(): void {
    if (this.topupService.selectedCreditCard) {
      document.getElementById('Iframe').style.height = '380px';
    } else {
      document.getElementById('Iframe').style.height = window.innerWidth >= 600 ? '380px' : '350px';
    }
  }

  handleOKAndKO(iframe, setTimeOut = false) {
    let status;
    try {
      this.readNetPlusParams(iframe);
      if (setTimeOut) {
        this.setTimeOutTimer(iframe, this.timer);
      }
      if ((iframe.contentWindow.location.href.indexOf(paymentPhpUrlOk) !== -1) && !this.koStatus) {
        this.responseDone = true;
        status = IframeStatus.complete
        this.updateStatusFromIframe(status, iframe);
      }
      else if ((iframe.contentWindow.location.href.indexOf(paymentPhpUrlKo) !== -1) && !this.koStatus) {
        this.topupErrorService.errorCode =
          decodeURIComponent(iframe.contentWindow.location.href.split('codigo=')[1].split('&')[0]).replace(/\+/g, ' ');
        this.topupErrorService.errorDesc =
          decodeURIComponent(iframe.contentWindow.location.href.split('mensaje=')[1].split('&')[0]).replace(/\+/g, ' ');
        this.responseDone = true
        status = IframeStatus.failed;
        this.updateStatusFromIframe(status, iframe, true);
      }
    } catch (error) {

    }
  }

  readNetPlusParams(iframe) {
    try {
      this.topupService.cardMask =
        decodeURIComponent(iframe.contentWindow.location.href.split('mascara=')[1].split('&')[0]).replace(/\+/g, ' ');
      this.topupService.cardToken =
        decodeURIComponent(iframe.contentWindow.location.href.split('numeroCifrado=')[1].split('&')[0]).replace(/\+/g, ' ');
      const datosMatchingArray = iframe.contentWindow.location.href.split('datosMatching=');
      const datosMatching = datosMatchingArray.length > 1 ?
        iframe.contentWindow.location.href.split('datosMatching=')[1].split('&')[0] : '';
      this.topupService.transactionDate = this.utilService.formatDateWithTimeOffset(Number.parseInt(datosMatching, 10));
      this.topupService.cardExpiry =
        decodeURIComponent(iframe.contentWindow.location.href.split('fechaCaducidad=')[1].split('&')[0]).replace(/\+/g, ' ');
    } catch (error) {
    }
  }

  updateStatusFromIframe(status: string, iframe: any, error: boolean = false, timeOut: boolean = false): void {
    this.showCancelationOverlay = false;
    this.trayService.showForwardTransition = false;
    this.trayService.showBackwardTransition = false;
    this.showFullLoader = true;
    let paramsObj = {};
    const requestBody: BillPaymentProcessRequest = {
      documentId: !this.topupService.isAnonymousProcess ? this.storageService.userProfile.document.id : '',
      transactionId: this.transactionId,
      status: status,
      cardMask: this.topupService.cardMask,
      cardNetplus: this.topupService.cardToken,
      topupSubscriptionID: this.topupService.msisdn ? this.topupService.msisdn : '',
      journeyId: this.topupService.selectedCreditCard && !this.topupService.isAnonymousProcess ?
        JourneyIdEnum.prePaidWithCvv : JourneyIdEnum.prePaidWithOutCvv
    };
    if (error) {
      requestBody.error = {
        errorCode: this.topupErrorService.errorCode,
        errorDescription: this.topupErrorService.errorDesc
      }
    }
    const parametersOfURL = !timeOut ? this.utilService.splitParametersOfURL(iframe.contentWindow.location.href) : {};
    paramsObj = { ...requestBody, ...parametersOfURL };
    this.clearTimer();
    if (this.transactionId) {
      const service = this.topupService.msisdn && this.topupService.isAnonymousProcess ?
        this.topupService.msisdn : this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id : '';
      this.billingService.updateStatus(timeOut ? requestBody : paramsObj,
        service, this.topupService.isAnonymousProcess).subscribe((res) => {
          if (this.topupErrorService.isIframeCanceled) {
            return;
          }
          if (res.status) {
            this.handleUpdateStatusFromIframeSuccessCase(res);
          } else {
            this.handelGeneralError(TopupPrepaidError.general);
          }
        }, error => {
          if (this.topupErrorService.isIframeCanceled) {
            return;
          }
          this.handleErrorCategory(TopupPrepaidError.general);
        });
    }
  }

  handleUpdateStatusFromIframeSuccessCase(res) {
    switch (res.status.toLowerCase()) {
      case IframeStatus.complete:
        if (this.billingService.startBillingPayment) {
          this.navigate(TopupPrepaidStages.topupConfirmation);
        } else {

          this.handleMortoriloCategory(res.backendStatus, res.refundCategory);

        }
        break;
      case IframeStatus.canceled:
        this.handleErrorCategory(res.refundCategory);
        break;
      case IframeStatus.failed:
        this.handleErrorCategory(res.category);
        break;
      case IframeStatus.timeout:
        this.handleErrorCategory(res.refundCategory ? res.refundCategory : TopupPrepaidError.TimeOut);
        break;
      default:
        this.handelGeneralError(TopupPrepaidError.general);
        break;
    }
  }

  setTimeOutTimer(iframe: any, time: string | number, isGlobal: boolean = false): void {
    this.clearTimer(false);
    if (this.timer && this.iframeUrl) {
      isGlobal ? this.globalTimerInterval = setTimeout(() => {
        this.handleTimeOut(iframe);
      }, (+time) * 1000) :
        this.timerInterval = setTimeout(() => {
          this.handleTimeOut(iframe);
        }, (+time) * 1000);
    }
  }

  handleTimeOut(iframe) {
    if (!this.responseDone) {
      this.timeouted = true;
      const status = IframeStatus.timeout
      this.updateStatusFromIframe(status, iframe, false, true)
    }
  }

  clearTimer(clearAll = true) {
    if (this.timerInterval) {
      clearTimeout(this.timerInterval);
    }
    if (this.globalTimerInterval && clearAll) {
      clearTimeout(this.globalTimerInterval);
    }
  }

  navigate(component) {
    this.showFullLoader = false;
    this.hideContent = true;
    setTimeout(() => {
      this.topupService.topupNavigationSubject.next({ page: component });
      if (this.dynamicTrayService.trayData) {
        this.dynamicTrayService.trayData.basicTrayData.showBackButton = false;
      }
    }, 1500);
  }

  handleErrorCategory(category?) {
    this.topupErrorService.isKoTaggingJourney = true;
    switch (category) {
      case TopupPrepaidError.general:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.general);
        break;
      case TopupPrepaidError.invalidCreditCard:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.invalidCreditCard);
        break;
      case TopupPrepaidError.invalidCreditCardInfo:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.invalidCreditCardInfo);
        break;
      case TopupPrepaidError.CreditCardTypeNotSupported:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.CreditCardTypeNotSupported)
        break;
      case TopupPrepaidError.SystemNotAvailable:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.SystemNotAvailable)
        break;
      case TopupPrepaidError.refundSuccess:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.refundSuccess);
        break;
      case TopupPrepaidError.refundFailed:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.refundFailed)
        break;
      case TopupPrepaidError.operationNotExist:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.operationNotExist)
        break;
      case TopupPrepaidError.PaymentApiFails:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.PaymentApiFails);
        break;
      case TopupPrepaidError.TimeOut:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.TimeOut);
        break;
      case TopupPrepaidError.mortiroloKO108:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.mortiroloKO108)
        break;
      default:
        this.topupErrorService.setErrorUiData(TopupPrepaidError.general);
        break;
    }
    this.navigate(TopupPrepaidStages.topupGeneralError);
  }
  configureTopupGeneralErrorOverlay(serviceId?) {
    this.topupErrorService.resetConfigurations();
    this.topupErrorService.setTrayTitle();
    this.topupErrorService.sendTaggingClickOnXInteractionFlag = false;
    this.setCancelationOverlayUicontent(this.topupService.topupLanguage);
    this.topupErrorService.showTwoCtas = true;
    this.tagging.view(tagging.pciPrepaidPages.cancelPage);
    this.topupErrorService.confirmationButtonFn = () => {
      this.topupErrorService.hideCancelOverlay = true;
      setTimeout(() => {
        this.trayService.showBackwardTransition = true;
        this.trayService.showForwardTransition = false;
        this.showCancelationOverlay = false;
        this.topupService.dynamicTrayData.closeButtonAction = this.closeTray.bind(this);
        this.topupService.dynamicTrayData.isGradientBackground = false;
        this.topupErrorService.activateXbuttonSubject = false;
        this.topupErrorService.hideCancelOverlay = false;
      }, 2000);
    }
    this.topupErrorService.cancelationButtonFn = () => {
      switch (this.topupService.topupLanguage) {
        case LanguageEnum.spanish:
          this.translate.get('payment.messagesList.generalRefundMsg.generalRefundMsg_description').subscribe(data => {
            this.loaderDescription = data;
          });
          break;
        case LanguageEnum.english:
          this.translate.get('payment.messagesList.generalRefundMsg_en.generalRefundMsg_en_description').subscribe(data => {
            this.loaderDescription = data;
          });
          break;
      }
      this.updateCancelOverlayStatus(IframeStatus.canceled, serviceId);
      setTimeout(() => {
        this.topupService.dynamicTrayData.isGradientBackground = false;
      }, 1000);
    }
    this.topupErrorService.showXButton = true;
  }
  setCancelationOverlayUicontent(lang = LanguageEnum.spanish) {
    switch (lang) {
      case LanguageEnum.spanish:
        this.translate.get('payment.messagesList').subscribe((data) => {
          this.topupErrorService.iconUrl = this.appService.getImgFullPath(data.cancelTopupMsg.icon.url);
          this.topupErrorService.title = data.cancelTopupMsg.description;
          this.topupErrorService.confirmationButtonText = data.cancelTopupMsg.button1.text;
          this.topupErrorService.cancelationButttonText = data.cancelTopupMsg.button2.text;
        });
        break;
      case LanguageEnum.english:
        this.translate.get('payment.messagesList').subscribe((data) => {
          this.topupErrorService.iconUrl = this.appService.getImgFullPath(data.cancelTopupMsg.icon.url);
          this.topupErrorService.title = data.cancelTopupMsg_en.description;
          this.topupErrorService.confirmationButtonText = data.cancelTopupMsg_en.button1.text;
          this.topupErrorService.cancelationButttonText = data.cancelTopupMsg_en.button2.text;
        });
        break;
    }
  }

  updateCancelOverlayStatus(status: string, serviceId?: string, fireCancelEvent: boolean = false): void {
    this.topupErrorService.isIframeCanceled = true;
    this.iframeContainer?.nativeElement.removeChild(this.iframe);
    this.showCancelationOverlay = false;
    this.trayService.showBackwardTransition = false;
    this.trayService.showForwardTransition = false;
    this.showFullLoader = true;
    this.scrolledView = false;
    this.showParagraph = true;
    this.topupService.dynamicTrayData.closeButtonAction = null;
    const requestBody: BillPaymentProcessRequest = {
      documentId: !this.topupService.isAnonymousProcess ? this.storageService.userProfile.document.id : '',
      transactionId: this.transactionId,
      status: status,
      topupSubscriptionID: this.topupService.msisdn ? this.topupService.msisdn : (serviceId ? serviceId : ''),
      journeyId: this.topupService.selectedCreditCard && !this.topupService.isAnonymousProcess ?
        JourneyIdEnum.prePaidWithCvv : JourneyIdEnum.prePaidWithOutCvv
    };
    const service = serviceId && this.topupService.isAnonymousProcess ?
      serviceId : this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id : '';
    this.clearTimer();

    this.cancelTopupSubscription =
      this.billingService.updateStatus(requestBody, service, this.topupService.isAnonymousProcess).subscribe((res) => {
        this.showCancelationOverlay = true;
        this.showParagraph = false;
        this.showFullLoader = false;
        if (res.refundCategory) {
          this.handleErrorCategory(res.refundCategory);
        }
        if (fireCancelEvent) {
          this.topupService.transactionCanceledSubject.next();
        }
      });
  }
  returnToTopupLanding() {
    this.hideContent = true;
    this.trayService.showBackwardTransition = true;
    this.topupService.backToFirstStep = true;
    if (this.transactionId) {
      this.updateCancelOverlayStatus(IframeStatus.canceled, this.topupService.msisdn, true);
    }
    setTimeout(() => {
      this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.firstStep });
    }, 1500);
  }

  ngOnDestroy(): void {
    this.clearTimer();
    this.topupErrorService.activateXbuttonSubject = false;
    this.appService.openBubbleStopScroll = false;
    this.topupService.dynamicTrayData.closeButtonAction = null;
    if (this.cancelTopupSubscription) {
      this.cancelTopupSubscription.unsubscribe();
    }
  }

  handleMortoriloCategory(backendStatus: string, refundCategory?: string): void {
    if (backendStatus) {
      if (backendStatus.toLowerCase() === Result.OK.toLowerCase()) {
        this.navigate(TopupPrepaidStages.topupConfirmation);
      } else {
        // back end status ko or none
        this.handleErrorCategory(refundCategory ? refundCategory : TopupPrepaidError.mortiroloKO108)
      }
    } else {
      // we are waiting for backend status and it's null
      this.handleErrorCategory(TopupPrepaidError.general);
    }
  }
  /**
  * this function is used to close the tray and send
  * cancel patch request on the backgroud
  * to cancel the current transaction
  * this function if used during the post paid journy
  */
  CancelTransaction() {
    if (this.transactionId) {
      this.topupErrorService.isIframeCanceled = true;
      this.iframeContainer.nativeElement.removeChild(this.iframe);
      this.topupService.dynamicTrayData.closeButtonAction = null;
      const requestBody: BillPaymentProcessRequest = {
        documentId: this.storageService.userProfile.document.id,
        transactionId: this.transactionId,
        status: IframeStatus.canceled,
        journeyId: this.topupService.selectedCreditCard ? JourneyIdEnum.postPaidWithCvv : JourneyIdEnum.postPaid
      };
      this.clearTimer();
      // as required no handling here for success and error
      // just background api call
      this.cancelTopupSubscription =
        this.billingService.updateStatus(requestBody).subscribe((res) => {
        });
    }
  }
  closeAndCancelTransaction() {
    this.CancelTransaction();
    this.dynamicTrayService.closeTraySubject.next();
  }

  ngAfterViewChecked(): void {
    if (!this.topupService.showIframeError && this.iframeUrl && !this.iframeInitialHeightSetFlag) {
      this.iframeInitialHeightSetFlag = true;
      this.setIframeInitialHeight();
    }
  }
}

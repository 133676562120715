import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../models/payment';
import {
    overlayTitlecustomStyle, overlayTitleSelector, removePaddingCustomStyle, removePaddingSelector,
    overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile, increaseHeightCustomStyle
} from '../../../shared/constants/defines';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigDefault } from './payment-tray-config-default';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { BenefitStatus } from '../../../shared/enums/benefit-status.enum';
import { TariffService } from '../../../shared/services/tariff.service';

export class PaymentTrayConfigPrePaid extends PaymentTrayConfigDefault implements PaymentTrayConfigInterface {
    // this component is treated like an ovrelay we remove tem from history after navigating away from them
    // not to show them to the user accidentaly when he navigate back
    compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] =
        [PaymentPages.cancel, PaymentPages.loading, PaymentPages.deleteConfiramtion];
    /** variable to be used for getting a tray config method based on component name */
    trayConfigurations: any = {
        [PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
        [PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
        [PaymentPages.ko]: this.getKoCompTrayConfigPrePaid.bind(this),
        [PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
        [PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
        [PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
        [PaymentPages.ticket]: this.getTicketCompTrayConfigPrePaid.bind(this),
        [PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
        [PaymentPages.topupScreen]: this.getTopUpLandingCompTrayConfig.bind(this),
        [PaymentPages.contactListPermission]: this.getContactListPermissionCompTrayConfig.bind(this),
        [PaymentPages.TopupCountDownScreen]: this.getTopupCountDownAndSpendBalanceCompTrayConfig.bind(this),
        [PaymentPages.HowToSpendNewBalance]: this.getTopupCountDownAndSpendBalanceCompTrayConfig.bind(this),
        [PaymentPages.SmartPayPromoAfterTopUp]: this.getSmartPayAfterTopUp.bind(this),
    };
    constructor(public paymentNavService: PaymentNavigationService,
        public delegateService: DelegateCreationService,
        public translate: TranslateService,
        public paymentWalletService: PaymentWalletService,
        public tariffService?: TariffService
    ) {
        super(paymentNavService, delegateService, translate, paymentWalletService);
    }
    /**
   * @param compName the page we need it's tray configuration
   * function should return PaymentFullTrayModel containing
   * the proper tray configuration
   */
    getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
        let config: PaymentFullTrayModel;
        /** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
        if (this.trayConfigurations[compName]) {
            config = this.trayConfigurations[compName]();
        } else {
            config = this.getIframeCompTrayConfig();
        }
        return config;
    }
    /**
     * function return PaymentFullTrayModel
     * the configuration of the inadvance payment start screen configuration
     */
    getTopUpLandingCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get('common.itemsList').subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.refills.body;
            paymentFullTrayModel.paymentCustomConfig.title_spanish = data.refills.body;
            paymentFullTrayModel.paymentCustomConfig.title_english = data.refills_en.body;
        })
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
            this.delegateService.paymentTaggingConfig.topupLandingXbtnClickInteraction();
            this.paymentNavService.closePayment();
        }
        paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
        paymentFullTrayModel.paymentCustomConfig.customStyles.push(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle));
        return paymentFullTrayModel;
    }
    /**
    * function return PaymentFullTrayModel
    * the configration of the wallet screen configration
    */
    getWalletCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        paymentFullTrayModel.MVA10Config.showBackButton = true;
        paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.wallet)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title;
        });
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = () => {
                /**
                 * Show leaving overlay when click on x in cclist screen with animation in prepaid journey.
                 * Send tagging for leaving overlay in prepaid journey.
                 * Bind x in leaving overlay to clickXButtonInCancelationOverlay function in prepaid journey.
                 */
                this.delegateService.paymentTaggingConfig.tagPaymentWalletClickOnXInteraction();
                this.paymentNavService.closePayment();
            }
        paymentFullTrayModel.paymentCustomConfig.BackBtnAction = () => {
                this.delegateService.paymentTaggingConfig.tagPaymentWalletClickOnBackInteraction(this.paymentWalletService.selectedCard);
            }
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
                new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
                new CustomStyle(removePaddingSelector, removePaddingCustomStyle)
            );
        return paymentFullTrayModel;
    }
    /**
     * function return PaymentFullTrayModel
     * the configration of the ko screen configration
     */
    getKoCompTrayConfigPrePaid(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getKoCompTrayConfig()
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle));
        return paymentFullTrayModel;
    }
    /**
 * function return PaymentFullTrayModel
 * the configration of the ticket screen configration
 */
    getTicketCompTrayConfigPrePaid(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getTicketCompTrayConfig();
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
            this.delegateService.paymentTaggingConfig.ticketXbtnClickInteraction();
            this.checkUserTariffStatus();
        }
        return paymentFullTrayModel;
    }

/**
   * function return PaymentFullTrayModel
   * the configuration of the contact list permission screen configuration
   */
  getContactListPermissionCompTrayConfig(): PaymentFullTrayModel {
    const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
    this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.contactListPermission)).subscribe(data => {
        paymentFullTrayModel.MVA10Config.title = data.title;
    });
    paymentFullTrayModel.paymentCustomConfig.closeBtnAction
        = this.paymentNavService.back.bind(this.paymentNavService);
    paymentFullTrayModel.paymentCustomConfig.customStyles =
        paymentFullTrayModel.paymentCustomConfig.customStyles.concat(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
            , new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile));
    return paymentFullTrayModel;

    }

  getTopupCountDownAndSpendBalanceCompTrayConfig(): PaymentFullTrayModel {
    const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
    paymentFullTrayModel.paymentCustomConfig.customStyles.push(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
        new CustomStyle(removePaddingSelector, removePaddingCustomStyle),
        new CustomStyle(removePaddingSelector, increaseHeightCustomStyle));
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
            this.delegateService.paymentTaggingConfig.tagCloseBtnClick();
            this.paymentNavService.closePayment();
        }
    return paymentFullTrayModel;
  }

  getSmartPayAfterTopUp(): PaymentFullTrayModel {
    const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
    paymentFullTrayModel.paymentCustomConfig.customStyles.push(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle),
        new CustomStyle(removePaddingSelector, removePaddingCustomStyle),
        new CustomStyle(removePaddingSelector, increaseHeightCustomStyle));
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
            this.delegateService.paymentTaggingConfig.tagCloseBtnSmartPayAfterTopUp();
            this.paymentNavService.closePayment();
        }
    return paymentFullTrayModel;
  }

  checkUserTariffStatus(): void {
    if (this.tariffService?.Tariff?.benefitStatus &&
        this.tariffService?.Tariff?.benefitStatus.toLowerCase() === BenefitStatus.active.toLowerCase()) {
            this.paymentNavService.openPaymentComponent(PaymentPages.SmartPayPromoAfterTopUp)
        }
    else {
        this.paymentNavService.closePayment();
    }
  }
}

<div class="accordion-side-menu">
    <accordion>
        <accordion-group #group>

            <div accordion-heading class="item">
                <div class="item-content">
                    <span class="icon-shape icon-item">
                            <span class="icon {{iconClassName}}"></span>

                    </span>
                    <span class="item-element">{{itemText}}</span>
                    <span class="right-text">{{rightText}}</span>
                    <i class=" icon" [ngClass]="{'icon-chevron-up': group?.isOpen, 'icon-chevron-down': !group?.isOpen}"></i>

                </div>
            </div>

            <div class=" side-menu">
                <div class="col-xs-12" *ngFor="let item of subItems" (click)="onClick(item)" [id]="item.itemText ? 'spn_' + item.itemText.split(' ').join('') : ''">
                    <span class="icon-shape icon-item"></span>
                    <span class="item-element subItem" [ngClass]="item.locked?'blocked':''">{{item.itemText || item.id}}</span>
                    <span class="icon-lock">
                     <span class="icon icon-security" *ngIf="item.locked"></span>
                    </span>

                    <span class=""></span>
                </div>
            </div>
        </accordion-group>
    </accordion>
</div>


import {map} from 'rxjs/operators';
import { API_URLS } from './../shared/constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from '../../../node_modules/rxjs';

@Injectable()
export class LineServicesData {
    constructor(private http: HttpClient) { }
    getCallOptions(serviceId: string) {
        const url = API_URLS.serviceSettings.getCallOptions.replace('{serviceId}', serviceId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(url, options).pipe(map((res) => {
            return res;
        }));
    }
    updateCallOptions(serviceId: string, body: any) {
        const url = API_URLS.serviceSettings.updateCallOptions.replace('{serviceId}', serviceId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/merge-patch+json');
        const options = {
            headers: headers
        };

        return this.http.patch(url, body, options).pipe(map((res) => {
            return res;
        }));
    }
    getVoiceMail(serviceId: string) {
        const url = API_URLS.serviceSettings.getVoiceMail.replace('{serviceId}', serviceId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(url, options).pipe(map((res) => {
            return res;
        }));
    }

    getLineRestrictions(serviceId: string, siteId: string, romaing?: boolean, fullStatus?: boolean): Observable<object> {
        let url: string = API_URLS.serviceSettings.getLineRestrictions.replace('{serviceId}', serviceId)
         .replace('{siteId}', siteId);
         if (romaing) {
             url = url + '?type=roaming';
         }
         if (fullStatus) {
             url = url + '?type=full-status';
         }
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
          headers: headers
        };
        return this.http.get(url, options)
      }

      patchLineRestrictions(serviceId: string, siteId: string, patchedObjected: any,
        roaming?: boolean, fullStatus?: boolean): Observable<object> {
        let url: string = API_URLS.serviceSettings.getLineRestrictions.replace('{serviceId}', serviceId)
         .replace('{siteId}', siteId);
         if (roaming) {
             url = url + '?type=roaming';
         }
         if (fullStatus) {
             url = url + '?type=full-status';
         }
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/merge-patch+json');

        const options = {
          headers: headers
        };
        return this.http.patch(url, patchedObjected, options);
      }
}

export class TicketModel {
    public averia?: string;
    public ticketId: string;
    public ticketName?: string;
    public ticketType?: string;
    public status?: string;
    public creationDate?: string;
    public closingDate?: string;
    public title?: string;
    public index?: string;
    public icon?: string;
    public isGeneralBreakDown?: boolean;
    public showTicketDetail?: boolean = false;
    public showCloseOpen?: boolean = false;
    public template: {
        templateId?: string;
        templateCode?: string;
        templateDate?: string;
        visitFrom?: string;
        visitTo?: string;
        outSLA?: boolean;
    };
    public caseBE?: {
        caseId?: string;
        status?: string;
        channelId?: string;
        agentName?: string;
        postId?: string;
        createdAt?: string;
        statusUpdatedAt?: string;
    };
    public journey?: string;
}

export class TicketDetailModel {
    defaultTicketDetailTitleText?: string;
    defaultTicketDetailButtonText?: string;
    ticketDetailContentButtonText?: string;
    ticketDetailTitleText?: string;
    ticketDetailButtonText?: string;
    ticketDetailBackdropTitleText?: string;
    ticketDetailBackdropSubtitleText?: string;
    ticketDetailBackdropButtonPositive?: string;
    ticketDetailBackdropButtonNegative?: string;
}

<vfac-overlay 
[loaded]="showEnglishOverlay"
[show]="showEnglishOverlay"
(closed)="closeOverlay()">
<div body class="EnglishOverlay">
    <img id="EnglishOverlayImg" class="EnglishOverlay__image" [src]="'v10.dashboard.overlays.englishBackdrop.icon'|imgFullPath">
    <p id="EnglishOverlayTitle" class="EnglishOverlay__title">{{'v10.dashboard.overlays.englishBackdrop.title'|translate}}</p>
    <p id="EnglishOverlayDescription" class="EnglishOverlay__description">{{'v10.dashboard.overlays.englishBackdrop.description'|translate}}</p>
    <div class="EnglishOverlay__btns">
      <button id="EnglishOverlayTopUpBtn" (click)="goToToUp()" type="button" class="button white" >
        {{'v10.dashboard.overlays.englishBackdrop.footer.button2.title'|translate}}
       </button>
      <button id="EnglishOverlayWhatsAppBtn" (click)="goToWhatsApp()" type="button" class="button red" >
        {{'v10.dashboard.overlays.englishBackdrop.footer.button1.title'|translate}}
        </button>     
    </div>   
    <p id="EnglishOverlayLink" (click)="backToDashBoard()" class="EnglishOverlay__link">{{'v10.dashboard.overlays.englishBackdrop.footer.button3.title'|translate}}</p> 
</div>
</vfac-overlay>

<div class="full-loader" *ngIf="showFullLoader">
  <div id="EnglishOverlayFullLoader">
    <sp-lottie-loader [lottieImageUrl]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/loader.json'"></sp-lottie-loader>
  </div>
</div>

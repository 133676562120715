import { tagging } from '../../../../config/tagging-config';
import { StorageService } from '../../../core/services/storage.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentTaggingInterface } from './payment-tagging-interface';
import { PaymentTaggingPrePaid } from './payment-tagging-pre-paid';

export class PaymentTaggingPrePaidAnonymous extends PaymentTaggingPrePaid implements PaymentTaggingInterface {
    constructor(public tagging: TaggingHelperService, public storageService: StorageService) {
        super(tagging, storageService);
    }

    ticketXbtnClickInteraction(): void {
        const pageTaggingName: any = tagging.pciPrepaidPages.paymentTicketPage.page_name;
        const eventName: any
            = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName.replace('<page_name>', pageTaggingName);
        this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
            tagging.pciPrepaidPages.paymentTicketPage);
    }
    topupLandingXbtnClickInteraction(): void {
        const pageTaggingName: string = tagging.pciPrepaidPages.serviceSelectorPage.page_name;
        const eventName: string = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName
            .replace('<page_name>', pageTaggingName);
        this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
            tagging.pciPrepaidPages.serviceSelectorPage);
    }
}

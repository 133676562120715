import { ServiceKeepModel } from './service-keep.model';

export class ServiceKeepWrap {
    id: string;
    serviceName: string;
    serviceType: string;
    img: string;
    extras: ServiceKeepModel[];
    others: ServiceKeepModel[];
    order: string;
}

export enum IframeStatus {
    complete = 'complete',
    failed = 'failed',
    timeout = 'timeout',
    canceled = 'canceled'
}

export enum JourneyIdEnum {
    postPaid = '101',
    prePaidWithOutCvv = '102',
    prePaidWithCvv = '103',
    postPaidWithCvv = '104',
}

/**
 * Used by secondsToTime pipe to define the format
 * to show the duration
 *
 * @export
 * @enum {number}
 */
export enum DurationTimeFormat {
  ShowZeroHr = 'ShowZeroHr',
  ShowZeroMin = 'ShowZeroMin',
  ShowZeroSec = 'ShowZeroSec'
}

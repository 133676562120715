import { CombinationModel } from './combination.model';
import { ServiceModel } from './service.model';

export class ServiceKeepModel extends ServiceModel {
    code: string;
    name: string;
    dateExpire: Date;
    combinations: CombinationModel[];
    fee: string;
    netFee: string;
}

import { Component, OnInit } from '@angular/core';
import { LandingComponent } from '../../../products-and-services/landing/landing.component';
import { Product } from '../../../models/product.model';

@Component({
  selector: 'sp-landing-fixed-to-international-bundle',
  templateUrl: './landing-fixed-to-international-bundle.component.html',
  styleUrls: ['./landing-fixed-to-international-bundle.component.scss']
})
export class LandingFixedToInternationalBundleComponent implements OnInit {
  public parent: LandingComponent;
  public Bundle: Product
  public isUnlimited: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}

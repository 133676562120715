import { Component, Input, OnInit } from '@angular/core';
import { stringOfTrue } from '../../constants/defines';

@Component({
  selector: 'sp-price-hike-alert-card',
  templateUrl: './price-hike-alert-card.component.html',
  styleUrls: ['./price-hike-alert-card.component.scss']
})

export class PriceHikeAlertCardComponent implements OnInit {
  @Input() public flag: string;
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public body: string;

  public show: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.checkFlag();
  }

  public checkFlag(): void {
    this.show = this.flag.toLowerCase() === stringOfTrue;
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomerType } from '../enums/customerType.enum';
import { StorageService } from '../../core/services/storage.service';

@Injectable()
export class DestinyService {

  public hasUnlimited: boolean = false;
  public unlimitedObservable: Subject<boolean> = new Subject<boolean>();
  public isDestiny: boolean = false;
  public destinyObservable: Subject<boolean> = new Subject<boolean>();
  public isNewOfferTV: boolean = false;
  public newOfferTVObservable: Subject<boolean> = new Subject<boolean>();
  public showOfferTVDashboard: boolean = false;
  public upgradeNewOfferTV: boolean = false;
  public upgradeTVObservable: Subject<boolean> = new Subject<boolean>();
  public isTVNEG: boolean = false;
  constructor(
    private storageService: StorageService
    ) {}

  setUnlimited(state: boolean) {
    this.hasUnlimited = state;
    this.unlimitedObservable.next(this.hasUnlimited);
  }

  setDestiny(state: boolean) {
    this.isDestiny = state;
    this.destinyObservable.next(this.isDestiny);
  }

  setNewOfferTV(state: boolean) {
    this.isNewOfferTV = state;
    this.newOfferTVObservable.next(this.isNewOfferTV);
  }

  setUpgradeNewOfferTV(state: boolean) {
    this.upgradeNewOfferTV = state;
    this.upgradeTVObservable.next(this.upgradeNewOfferTV);
  }

  // **************
  // DATA UNLIMITED
  // **************
  checkUnlimitedServiceBalance(arrayItems) {
    let foundDestiny: boolean = false;
    if (arrayItems && arrayItems.length > 0) {
      for (let i = 0; i < arrayItems.length; i++) {
        if (arrayItems[i].categories && arrayItems[i].categories[0] && arrayItems[i].categories[0].toUpperCase() === 'BASIC'
          && arrayItems[i].type && arrayItems[i].type.toUpperCase() === 'DATA'
          && arrayItems[i].productType && arrayItems[i].productType.toUpperCase() === 'PRODUCT'
          && arrayItems[i].allowance && arrayItems[i].allowance.unlimited) {
          foundDestiny = true;
          this.setUnlimited(true);
          break;
        }
        if ((arrayItems.length === i + 1) && !foundDestiny) {
          this.setUnlimited(false);
        }
      }
    } else {
      this.setUnlimited(false);
    }
  }

  checkUnlimitedProducts(arrayItems) {
    let foundDestiny: boolean = false;
    if (arrayItems && arrayItems.length > 0) {
      for (let i = 0; i < arrayItems.length; i++) {
        if (arrayItems[i].productCharacteristics && arrayItems[i].productCharacteristics.category
          && arrayItems[i].productCharacteristics.category.toUpperCase() === 'BASIC'
          && arrayItems[i].type && arrayItems[i].type.toUpperCase() === 'DATA'
          && arrayItems[i].productType && arrayItems[i].productType.toUpperCase() === 'PRODUCT'
          && arrayItems[i].status && arrayItems[i].status.current && arrayItems[i]. status.current.toUpperCase() === 'ACTIVE'
          && arrayItems[i].consumption && arrayItems[i].consumption.allowance && arrayItems[i].consumption.allowance.unlimited) {
          foundDestiny = true;
          this.setUnlimited(true);
          break;
        }
        if ((arrayItems.length === i + 1) && !foundDestiny) {
          this.setUnlimited(false);
        }
      }
    } else {
      this.setUnlimited(false);
    }
  }

  // **************
  // NEW OFFER TV
  // **************
  checkNewOfferTV(tariff) {
    if ((tariff.serviceType.toUpperCase() === 'TV') && (tariff.SuperOffer && tariff.SuperOffer === 'ON19') && (tariff.Code !== 'TVNEG')) {
      this.setNewOfferTV(true);
    } else {
      if (tariff.Code === 'TVNEG') {
        this.isTVNEG = true;
      } else {
        this.isTVNEG = false;
      }
      this.setNewOfferTV(false);
    }
  }

  // **************
  // UPGRADE TV
  // **************
  checkUpgradeTV(tariff) {
    // if ((tariff.serviceType.toUpperCase() === 'TV') && (tariff.SuperOffer && tariff.SuperOffer !== 'ON19') &&
    if ((tariff.serviceType.toUpperCase() === 'TV') && (!tariff.SuperOffer || (tariff.SuperOffer && tariff.SuperOffer !== 'ON19')) &&
    (this.storageService.userProfile.customerType.toString().toLowerCase() === CustomerType.Consumer.toLowerCase())) {
      this.setUpgradeNewOfferTV(true);
    } else {
      this.setUpgradeNewOfferTV(false);
    }
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TrayModel, ButtonModel, IconType, OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from '../../../../app/app.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { StorageService } from 'src/app/core/services/storage.service';

import { config } from '../../../../config/pages-config';
import { ServiceType } from '../../../shared/enums/serviceType.enum';

import { FOLDER_TRAY_FOR_YOU, FOLDER_TRAY_TOP_UP, FOLDER_TRAY_KIT_DIGITAL, FOLDER_TRAY_ONE_PROFESSIONAL,
  FOLDER_TRAY_SHOP_SELECTOR, TRAY_CONFIG_TRACKING } from '../../../shared/constants/archetype/trayConfigTracking';
import { NewTaggingJsonModel } from '../../../models/new-tagging-json-model';
import { ShopTabs } from '../../../marketplace/Interfaces/marketplace-enums';
@Injectable({
  providedIn: 'root'
})
export class TrayConfigurationService {

  public dataTray: TrayModel = new TrayModel();
  public useFourButtonTray: boolean = true;
  public hasMktpEntrypoint: boolean;
  public isPrepaidUser: boolean;
  public openTopUpOverlayFromTray: boolean;
  public showTrayOverlay: boolean;
  public showShopSelectorTrayOverlay: boolean = false;
  public showMyproductsOverlay: boolean = false;
  public showMyAccountOverlay: boolean = false;
  public buttonSecondLeftLoaded: boolean;

  constructor(
    public translateService: TranslateService,
    private subscriptionService: SubscriptionService,
    private appService: AppService,
    public router: Router,
    private newTaggingHelperService: NewTaggingHelperService,
    private storageService: StorageService
  ) { }

  public initTray(useFourButtonTray: boolean): void {
    this.showTrayOverlay = false;
    this.showShopSelectorTrayOverlay = false;
    this.showMyproductsOverlay = false;
    this.showMyAccountOverlay = false;
    this.useFourButtonTray = useFourButtonTray;
    this.isPrepaidUser = this.subscriptionService.customerData.currentService.type === ServiceType.Prepaid;
    this.setTrayData();
    if ((typeof this.hasMktpEntrypoint === 'boolean') && this.useFourButtonTray) {
      this.setSecondLeftButtonData(this.isPrepaidUser);
    }
  }

  async checkMktpEntrypoint(hasMktpEntrypoint: boolean): Promise<boolean | void> {
    if (!this.useFourButtonTray) {
      return
    }
    if (typeof this.hasMktpEntrypoint === 'boolean') {
      return
    }
    this.hasMktpEntrypoint = await hasMktpEntrypoint
    this.setSecondLeftButtonData(this.isPrepaidUser);
    return this.hasMktpEntrypoint
  }

  setTrayData(): void {
    this.dataTray = new TrayModel();
    this.setLeftButtonData()
    this.dataTray.buttonRight = new ButtonModel();
    this.dataTray.buttonRight.icon = this.useFourButtonTray ? IconType.ICON_MY_PRODUCTS : IconType.ICON_MY_ACCOUNT;
    this.translateService.get('v10.common.tray').subscribe(text => {
      this.dataTray.buttonRight.text = this.useFourButtonTray ? text.lnk_products : text.lnk_account;
      if (this.useFourButtonTray) {
        this.dataTray.buttonSecondRight = new ButtonModel();
        this.dataTray.buttonSecondRight.icon = IconType.ICON_MY_ACCOUNT;
        this.dataTray.buttonSecondRight.text = text.lnk_account;
        this.setSecondLeftButtonData(this.isPrepaidUser);
      }
    });
    this.translateService.get('v10.dashboard.images.tray_images').subscribe(img => {
      this.dataTray.imgFixedLeft = this.appService.getImgFullPath(img.corner_left_pm);
      this.dataTray.imgLeft = this.appService.getImgFullPath(img.container_left_pm);
      this.dataTray.imgCenter = this.appService.getImgFullPath(img.gap_tray_pm);
      this.dataTray.imgRight = this.appService.getImgFullPath(img.container_right_pm);
      this.dataTray.imgFixedRight = this.appService.getImgFullPath(img.corner_right_pm);
    });
  }

  setLeftButtonData(): void {
    this.dataTray.buttonLeft = new ButtonModel();
    if (this.storageService.getIsMicroResegmented() || this.storageService.getIsME()) {
      this.dataTray.buttonLeft.icon = IconType.ICON_PREPAID_ADD;
      this.dataTray.buttonLeft.text = this.translateService.instant(`v10.common.tray.${this.storageService.getIsME() ? 'lnk_kitdigital' : 'lnk_sp_mobile'}`);
    } else {
      this.dataTray.buttonLeft.icon = this.useFourButtonTray ? IconType.ICON_REWARDS : IconType.ICON_MY_PRODUCTS;
      this.dataTray.buttonLeft.text = this.translateService.instant(`v10.common.tray.${this.useFourButtonTray ? 'lnk_for_you' : 'lnk_products'}`);
    }
  }

  setSecondLeftButtonData(isPrepaid: boolean): void {
    this.dataTray.buttonSecondLeft = new ButtonModel();
    if (isPrepaid) {
      this.dataTray.buttonSecondLeft.icon = IconType.ICON_PREPAID;
      this.dataTray.buttonSecondLeft.text = this.translateService.instant('v10.common.tray.lnk_top_up');
    } else {
      this.dataTray.buttonSecondLeft.icon = IconType.ICON_SHOPPING;
      this.dataTray.buttonSecondLeft.text = this.translateService.instant('v10.common.tray.lnk_shop');
    }
  }

  public trayEventButtonOne(): void {
    this.hideTray();
    if (this.storageService.getIsMicroResegmented()) {
      this.trayInteractionTagging(FOLDER_TRAY_ONE_PROFESSIONAL, TRAY_CONFIG_TRACKING.EVENTS.CLICK_ONE_PROFESSIONAL);
      this.router.navigate([config.opProducts.route]);
    } else if (this.storageService.getIsME()) {
      this.trayInteractionTagging(FOLDER_TRAY_KIT_DIGITAL, TRAY_CONFIG_TRACKING.EVENTS.CLICK_KIT_DIGITAL);
      this.router.navigate([config.europeanFundsStep2.route]);
    } else {
      this.trayInteractionTagging(FOLDER_TRAY_FOR_YOU, TRAY_CONFIG_TRACKING.EVENTS.CLICK_FOR_YOU);
      this.router.navigate([config.specialForYou.route]);
    }
  }

  public trayEventButtonTwo(): void {
    this.showMyproductsOverlay = false;
    this.showMyAccountOverlay = false;
    if (this.isPrepaidUser) {
      this.hideTray();
      this.router.navigate([config.TopUp.topUpPrepaidservice.route]);
      this.trayInteractionTagging(FOLDER_TRAY_TOP_UP, TRAY_CONFIG_TRACKING.EVENTS.CLICK_TOP_UP);
      setTimeout(() => {
        this.openTopUpOverlayFromTray = !this.openTopUpOverlayFromTray;
      }, 2000);
    } else if (this.hasMktpEntrypoint) {
      this.setShopOverlayData();
      this.showTrayOverlay = true;
      this.showShopSelectorTrayOverlay = true;
      this.trayInteractionTagging(FOLDER_TRAY_SHOP_SELECTOR, TRAY_CONFIG_TRACKING.EVENTS.SHOW_SHOP_SELECTOR);
      setTimeout(() => {
        this.trayInteractionTagging(FOLDER_TRAY_SHOP_SELECTOR);
      }, 2000);
    } else if (!this.hasMktpEntrypoint) {
      this.navigateToShop();
    }
  }

  setShopOverlayData(): void {
    this.translateService.get('v10.common.tray.overlay_shop').subscribe(text => {
      this.dataTray.overlayData = new OverlayModel();
      this.dataTray.overlayData.title = text.title;
      this.dataTray.overlayData.subTitle = text.subTitle;
    });
  }

  navigateToShop(): void {
    this.trayInteractionTagging(FOLDER_TRAY_SHOP_SELECTOR, TRAY_CONFIG_TRACKING.EVENTS.GO_TO_SHOP);
    this.router.navigate([config.TiendaV10.route]);
  }

  navigateToMktp(): void {
    this.trayInteractionTagging(FOLDER_TRAY_SHOP_SELECTOR, TRAY_CONFIG_TRACKING.EVENTS.GO_TO_MKTP);
    this.router.navigate([config.TiendaV10.route], { queryParams: { tab: ShopTabs.Mktplace } });
  }

  destroy(): void {
    this.showTrayOverlay = false;
    this.showShopSelectorTrayOverlay = false;
    this.showMyproductsOverlay = false;
    this.showMyAccountOverlay = false;
    this.useFourButtonTray = false;
    this.isPrepaidUser = false;
  }

  trayInteractionTagging(taggingEvent: string, event_name?: string): void {
    this.newTaggingHelperService.getJsonTagging(taggingEvent).subscribe((data: NewTaggingJsonModel) => {
      if (event_name) {
        data.eventList[TRAY_CONFIG_TRACKING.EVENTS.TRAY_INTERACTION].event.event_label = event_name;
        this.newTaggingHelperService.interaction(data.eventList[TRAY_CONFIG_TRACKING.EVENTS.TRAY_INTERACTION], data.page);
      } else {
        this.newTaggingHelperService.state(data.page);
      }
    })
  }

  hideTray(): void {
    this.showTrayOverlay = false;
    this.showShopSelectorTrayOverlay = false;
    this.showMyproductsOverlay = false;
    this.showMyAccountOverlay = false;
  }
}


import { Injectable } from '@angular/core';
import { MiddlePageModel } from '../../models/middle-page.model';


@Injectable()
export class MiddlePageService {
    public data: MiddlePageModel ;
    constructor() {}
}

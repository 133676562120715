export enum BundleStatus {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending',
    PendingActivation = 'PENDING_ACTIVATION',
    PendingDeactivation = 'PENDING_DEACTIVATION',
    PendingMail = 'PENDING_MAIL',
    PendingConfirm = 'PENDING_CONFIRM',
    ChangeLimit = 'PENDING_CHANGE_LIMIT',
    Activate = 'ACTIVATE',
    Upgrade = 'UPGRADE',
    DownGrade = 'DOWNGRADE',
    Failed = 'FAILED',
    Success = 'SUCCESS',
    Available = 'AVAILABLE',
    Finished = 'FINISHED',
    DeactivationLockedCodeDiscounts = 'bloqueado_por_descuentos',
    DeactivationLockedCodeConditions = 'bloqueado_por_no_cumplir_condiciones_minimas'
}

export enum AssetType {
    token = 'token',
    userId = 'userId',
    status = 'status'
}

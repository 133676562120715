<div>
  <div class="cintillo pega" [ngClass]="{'public': !showCintillo, 'pega': showCintillo}" [ngStyle]="showCintillo && {'background': cintilloColor}">
    <p class="cintillo-text" [ngStyle]="{'color': textColor}">{{cardResp.headBandText}}</p>
  </div>
  <div [ngClass]="{'cintilloPega': showCintillo, 'card': !showCintillo}" [ngStyle]="showCintillo && {'border': cintilloBorderColor}">
    <div class="card-superior" [ngClass]="{'gradientVf': !cardResp.isLimitedTariff, 'gradientYu': cardResp.isLimitedTariff, 'card-superior-public': !showCintillo}">
      <p id="PLPRevampCardSuperiorHeader" class="card-superior-header fontText">{{cardResp.titleText}}</p>
      <p id="PLPRevampCardSuperiorDescription" *ngIf="existsDescription1" class="card-superior-description fontText">{{cardResp.description1}}</p>
      <p id="PLPRevampCardSuperiorDescriptionSpace" *ngIf="!existsDescription1" class="card-superior-description"></p>
      <h5 id="PLPRevampCardSuperiorDescription2" *ngIf="existsDescription2" class="card-superior-description2 fontText">{{cardResp.description2}}</h5>
      <p id="PLPRevampCardSuperiorDescriptionSpace2" *ngIf="!existsDescription2" class="card-superior-description2"></p>
      <div class="card-superior-velocityInfo" *ngIf="existsTopIconText">
        <img alt="cardResp.topIconText" id="PLPRevampCardSuperiorVelocityInfoIcon" class="card-superior-velocityInfo-icon" src="{{cardResp.topIcon}}" *ngIf="existsTopIcon">
        <p id="PLPRevampCardSuperiorVelocityInfoText" class="card-superior-velocityInfo-text fontText">{{cardResp.topIconText}}</p>
      </div>
    </div>
    <div class="card-inferior">
        <div class="card-inferior-price">
          <p id="PLPRevampCardInferiorPriceDiscount" class="card-inferior-price-discount">{{cardResp.textPriceWithDiscount}}</p>
          <p *ngIf="!controlPrice" id="PLPRevampCardInferiorPriceNormal" class="card-inferior-price-normal">{{cardResp.textPrice}}</p>
        </div>
        <div class="card-inferior-info">
          <p id="PLPRevampCardInferiorBlueText" [ngClass]="{'pointer': cardResp.iconAttachedText}" (click)="clickBlueText(cardResp.iconAttachedText)"
           class="card-inferior-info-textBelow">{{textBlue[0]}}<strong>{{textBlue[1]}}</strong>{{textBlue[2]}}<mva10-c-icon *ngIf="cardResp.iconAttachedText"
            iconId="icon-info-circle" size="18" class="card-inferior-info-icon"></mva10-c-icon>
          </p>
        </div>
      <div class="card-inferior-next">
        <mva10-c-button (mva10OnClick)="clickContinue()" [width]="'full'">{{cardResp.textButton}}</mva10-c-button>
        <p id="PLPRevampCardInferiorNextText" (click)="clickInfo()" class="mva10-u--mt2 card-inferior-next-text pointer">{{cardResp.textBelowButton}}</p>
      </div>
    </div>
  </div>
</div>


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { config } from '../../config/pages-config';

import { AdaraMainComponent } from './adara-main/adara-main.component';

const routes: Routes = [
  {
    path: '',
    component: AdaraMainComponent,
  },
  {
    path: config.energy.name,
    component: AdaraMainComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdaraRoutingModule { }

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { JSON_PATHS, CONFIG, GRANT_TYPE } from '../constants/defines';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Impersonation } from '../../models/impersonation.model';
import { AuthenticateService } from '../../core/services/authenticate.service';
import * as JsonQuery from 'jsonpath/jsonpath';

@Injectable()
export class ImpersonationService {
  impersonatedUser: Impersonation;

  constructor(
    private http: HttpClient,
    private authenticateService: AuthenticateService
  ) {
    this.impersonatedUser = new Impersonation();
  }

  PostImpersonate(jwe: string) {
    const url = API_URLS.Impersonation.token;

    let headers = new HttpHeaders();
    headers = headers.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
    const options = {
      headers: headers
    };

    const body =
      'client_id=' +
      CONFIG.CLIENT_ID +
      '&client_secret=' +
      jwe +
      '&scope=' +
      CONFIG.SCOPES.join(' ') +
      '&grant_type=' +
      GRANT_TYPE.CLIENT_CREDENRIALS;
    const saveToken = true;
    return this.authenticateService
      .createRequest(options, body, url, saveToken).pipe(
      map(res => {
        const jwt = JsonQuery.query(res, JSON_PATHS.Impersonation.SUCCESS.JWT);
        const jwtParts = jwt.toString().split('.');

        // convert jwt into string
        const decoded = atob(jwtParts[1]);
        const jwtData = JSON.parse(decoded);

        const impersonatedBy =
          JsonQuery.value(
            jwtData,
            JSON_PATHS.Impersonation.ImpersonatedBy.impersonatedBy
          ) || null;
        if (impersonatedBy) {
          this.impersonatedUser.Subject =
            JsonQuery.value(
              impersonatedBy,
              JSON_PATHS.Impersonation.ImpersonatedBy.Subject
            ) || null;
          this.impersonatedUser.Group =
            JsonQuery.value(
              impersonatedBy,
              JSON_PATHS.Impersonation.ImpersonatedBy.Group
            ) || null;
          this.impersonatedUser.Issuer =
            JsonQuery.value(
              impersonatedBy,
              JSON_PATHS.Impersonation.ImpersonatedBy.Issuer
            ) || null;
          this.impersonatedUser.IssueTime =
            JsonQuery.value(
              impersonatedBy,
              JSON_PATHS.Impersonation.ImpersonatedBy.IssueTime
            ) || null;
          this.impersonatedUser.JwtID =
            JsonQuery.value(
              impersonatedBy,
              JSON_PATHS.Impersonation.ImpersonatedBy.JwtID
            ) || null;
        }
        return res;
      }));
  }
}

<div class="sp-more-info-tray">
  <div class="info-container">
    <div class="info-desc">
      <p>{{dynamicTrayService.trayData?.guestComponentData?.info}}</p>
    </div>
  </div>
  <div class="info-buttons-container">
    <button
      type="button"
      class="continue button red two"
      (click)="dynamicTrayService.trayData?.guestComponentData?.primaryButtonAction()"
    >
      {{dynamicTrayService.trayData?.guestComponentData?.primaryButtonText}}
    </button>
    <button
      type="button"
      class="cancel button white two"
      (click)="dynamicTrayService.trayData?.guestComponentData?.secondaryButtonAction()"
    >
      {{dynamicTrayService.trayData?.guestComponentData?.secondaryButtonText}}
    </button>
  </div>
</div>

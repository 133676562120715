export enum MonthName {
  January = 'Enero',
  February = 'Febrero',
  March = 'Marzo',
  April = 'Abril',
  May = 'Mayo',
  June = 'Junio',
  July = 'Julio',
  August = 'Agosto',
  September = 'Septiembre',
  October = 'Octubre',
  November = 'Noviembre',
  December = 'Diciembre'
}
export const Months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'];

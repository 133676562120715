import { AddressStatus } from './../shared/enums/addressStatus.enum';
export class Address {
    buildingNo: string;
    level: string;
    street: string;
    town: string;
    country: string;
    postcode: string;
    streetType: string;
    door: string;
    formattedAddress: string;
    line1?: string;
    line2?: string;
    status?: AddressStatus
}

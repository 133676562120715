/**
 * Used in WCS 'v10.dashboard.gestion.list' key to list Dashboard management links
 */
export enum ProductManagementEnum {
  fault_management = 'averias',
  notifications = 'notificaciones',
  my_refills = 'mis_recargas',
  pinpuk = 'pinpuk',
  payments = 'pagos',
  refill_prepaid = 'recarga_prepago',
  orders = 'mis_pedidos',
  shop_location = 'loc_tienda',
  one_professional = 'one_profesional',
  online_subscriptions = 'online_subscriptions',
  adara = 'adara',
  business = 'business'
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { PaymentCardModel, PaymentCardPatchModel } from '../../models/payment-card.model';
import { TopUpService } from '../../top-up/top-up.service';
import { PaymentWalletService } from '../../shared/services/payment-wallet.service';
import { PaymentNavigationService } from '../services/payment-navigation.service';
import { PaymentCompoentInputData_Keys } from '../../shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { PaymentErrorCategory } from '../../shared/enums/payment-error-category.enum';
import { DelegateCreationService } from '../services/delegate-creation.service';
import { PaymentErrorService } from '../services/payment-error.service';
import { AppService } from 'src/app/app.service';
import { PaymentAlertModel } from '../../models/payment-alertModel.model';
import { TaggingClickDataModel } from 'src/app/models/tagging.model';
import { marketplaceTagging } from 'src/app/marketplace/tagging/marketplaceTagging';
import { MarketplaceService } from 'src/app/marketplace/services/marketplace.service';
@Component({
  selector: 'sp-payment-wallet',
  templateUrl: './payment-wallet.component.html',
  styleUrls: ['./payment-wallet.component.scss']
})
export class PaymentWalletComponent implements OnInit, OnDestroy, AfterViewInit {

  actions: Array<String>;
  principalTitle: String;
  anotherCreditCardWalletText: string;
  anotherCreditCardWalletImage: string;
  cardsList: Array<PaymentCardModel>;
  @Input() standAloneWallet: boolean = false;
  @Input() alertObject?: PaymentAlertModel;

  pageTagData: TaggingClickDataModel =  marketplaceTagging.marketplaceTabsTab.myBillingPage;

  constructor(
    public trayService: SpMva10OverlayService,
    public topupService: TopUpService,
    public paymentWalletService: PaymentWalletService,
    public paymentNavigationService: PaymentNavigationService,
    public appService: AppService,
    private delegateService: DelegateCreationService,
    private paymentErrorService: PaymentErrorService,
    public marketplaceService: MarketplaceService
  ) { }

  ngOnInit() {
    this.setWCSNeededData();
    this.getSavedCards();
    this.sendPageTagging();

    this.paymentNavigationService.customCancelOverlaySecondBtnAction = () => {
      /** Close tray and return to mis pagos page. */
      const isSelectedCard: boolean = !!this.paymentWalletService.selectedCard;
      this.delegateService.paymentTaggingConfig.tagPaymentWalletCancelLeavingOverlay(isSelectedCard);
      this.paymentNavigationService.closePayment();
    };
  }

  ngAfterViewInit(): void {
    this.marketplaceService.init(true, true);
  }

  /** Method for sending tagging when wallet screen loaded */
  sendPageTagging(): void {
    const isSelectedCard: boolean = !!this.paymentWalletService.selectedCard;
    this.delegateService.paymentTaggingConfig.tagOpenPaymentWalletScreen(isSelectedCard);
  }

  /** Method for getting saved cards and refresh list each time cards list changed ($cardsListChangedSubject) */
  getSavedCards(): void {
    this.refreshList();
    this.paymentWalletService.$cardsListChangedSubject.subscribe(() => this.refreshList());
  }

  /** Method for refresh the cards list from store */
  refreshList(): void {
    this.cardsList = this.paymentWalletService.cardsList;
  }

  /** Method for shwing a loader overlay */
  showLoader(): void {
    this.appService.showVodaFullLoader = true;
  }

  /**
   * Method for make back navigation with/without animation
   * @param withoutAnimation for back without animation (false by default)
   */
  back(withoutAnimation?: boolean): void {
    if (!withoutAnimation) {
      this.paymentNavigationService.back();
    } else {
      this.paymentNavigationService.backWithoutAnimation();
    }
  }

  /**
   * Method for showing a confirmation overlay and send its tagging
   * @param card the card that needed to be deleted
   */
  displayDeleteConfirmationOverlay(card: PaymentCardModel): void {
    const lastFourDigits: string = card.cardMaskData.substr(card.cardMaskData.length - 4)
    this.paymentNavigationService.openPaymentComponent(PaymentPages.deleteConfiramtion, this.paymentNavigationService.WCSJourneyKey,
      [
        {
          key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
          value: this.onSubmitDelete.bind(this, card)
        },
        {
          key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_secondBtnAction,
          value: this.onCancelDelete.bind(this)
        },
        {
          key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_lastFourDigits,
          value: lastFourDigits
        }
      ]);

    this.delegateService.paymentTaggingConfig.tagPaymentWalletDeleteCard();
  }

  /** Method called when cancel delete button is clicked and send a tagging */
  onCancelDelete(): void {
    this.back();
    this.delegateService.paymentTaggingConfig.tagPaymentWalletCancelDeleteInteraction();
  }

  /**
   * Method called when submit delete button is clicked and send its tagging
   * @param card the card that needed to be deleted
   */
  onSubmitDelete(card: PaymentCardModel): void {
    this.showLoader();
    // we need the loader to start it's animation
    this.paymentWalletService.deletePaymentCard(card.uuid).subscribe(() => {
      this.paymentWalletService.$deletedCreditCard.next();
      this.appService.showVodaFullLoader = false;
      this.back()
      if (this.standAloneWallet && (!this.paymentWalletService.cardsList || this.paymentWalletService.cardsList.length === 0)) {
        this.paymentErrorService.handleErrorCategory(PaymentErrorCategory.emptyWallet);
      }
    }, error => {
      this.paymentWalletService.$deletedCreditCard.next();
      this.appService.showVodaFullLoader = false;
    });
    this.delegateService.paymentTaggingConfig.tagPaymentWalletConfirmDeleteInteraction();
  }

  /**
   * Method for makes a card as principal card and send its tagging
   * @param card the card that needed to be updated
   */
  updateCardAsPrinciple(card: PaymentCardModel): void {
    this.showLoader();
    // we need the loader to start it's animation
    this.paymentWalletService.savePaymentCard(this.getCardModelForPatch(card), card.uuid).subscribe(() => {
      this.appService.showVodaFullLoader = false;
    }, error => {
      this.appService.showVodaFullLoader = false;
    });
    this.delegateService.paymentTaggingConfig.tagPaymentWalletUpdateAsPrinciple();
  }

  /** Method for setting needed data from WCS:
   * prncipal title,
   * menu actions (make principle and delete),
   * and other wallet card text and icon
   */
  setWCSNeededData(): void {
    this.principalTitle = this.paymentWalletService.principalTitleText;

    this.anotherCreditCardWalletText = this.paymentWalletService.otherCardText;
    this.anotherCreditCardWalletImage = this.paymentWalletService.otherImageUrl;

    this.actions = this.paymentWalletService.menuActions;
  }

  /**
   * Get a PaymentCardPatchModel from PaymentCardModel
   * @param card used to create a new one with PaymentCardPatchModel type
   */
  getCardModelForPatch(card: PaymentCardModel): PaymentCardPatchModel {
    const cardObj: PaymentCardPatchModel = new PaymentCardPatchModel();
    cardObj.cardMask = card.cardMaskData;
    cardObj.documentID = card.documentID;
    cardObj.documentType = card.documentType;
    cardObj.expiryDate = card.expiryDate;
    cardObj.journey = card.journey;
    cardObj.token = card.token;
    cardObj.principle = true;
    return cardObj;
  }

  /**
   * Method for change selected card object and go back
   * @param card the selected card
   */
  selectAndBack(card: PaymentCardModel): void {
    if (!this.standAloneWallet) {
      this.paymentWalletService.setSelectedCard(card);
      this.back();
    } else {
      return;
    }
  }

  /**
   * Method for updating or deleting a card
   * @param action to deside update or delete?
   * @param card the card that needed to be updated/deleted
   */
  updateOrDelete(action: string, card: PaymentCardModel): void {
    if (action === this.paymentWalletService.deleteAction) {
      this.displayDeleteConfirmationOverlay(card);
      this.delegateService.paymentTaggingConfig.tagPaymentDeleteBtn();
    }
    else {
      this.updateCardAsPrinciple(card);
      this.delegateService.paymentTaggingConfig.tagPaymentSetPruncipaleBtn();
    }
  }

  /** Method for identify each card to increase loop performance in the template */
  cardIdentify(index: number, card: PaymentCardModel): string {
    return card.uuid;
  }

  ngOnDestroy(): void {
    this.paymentNavigationService.customCancelOverlaySecondBtnAction = null;
    this.marketplaceService.destroy();
  }
}


import { Pipe, PipeTransform } from '@angular/core';
import { BusinessProductsListItem } from './../../models/product-detail.model';

@Pipe({
  name: 'filtertabs'
})
export class FilterTabsPipe implements PipeTransform {
  transform(value: BusinessProductsListItem[], args?: string[], long?: number): BusinessProductsListItem[] {
    const filterData: BusinessProductsListItem[] = [];
    if ( long === 0 ) {
      return value;
    }

    value.filter( (tag: BusinessProductsListItem) => {
      args.filter( (arg: string) => {
        if ( arg.includes(tag.code) ) {
          filterData.push(tag);
        }
      })
    });

    return filterData;
  }
}

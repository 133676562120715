export enum TypeUser {
    TypeUser = 0,
    /**
     * General user
     */
    Zero = 0,
    /**
     * Pure Yu Digital user
     */
    One = 1,
    /**
     * Bit user
     */
    Two = 2,
    /**
     * Mixed Yu Digital user
     */
    Three = 3
}

import { AppService } from './../../app.service';
import { PAGES, VERIFYEMAIL } from '../../shared/constants/defines';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GENERAL_ERROR_CODES, generalEcode } from '../../shared/constants/defines';
import { StorageService } from './storage.service';
import { Error } from '../../models/error.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * ErrorHandling Service Used to load application ErrorHandling from middleware
 * middleware URL is defined in the application enviroment settings.
 */
@Injectable()
export class ErrorHandlingService {
  content: any;
  /**
   * constructor inject http service in to it
   */
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private ts: TranslateService,
    private appService: AppService) {
  }
  public lookUpError(page: string, errorObj: any): Error {
    let error: Error = new Error();
    if (errorObj.error && errorObj.error.ecode && errorObj.error.ecode.toString() === VERIFYEMAIL.errorAppUnavilability) {
      this.appService.errorAvilabilityApp = true;
    }
    else {
      if (errorObj.error && errorObj.error.ecode && errorObj.error.ecode.toString() !== generalEcode) {
        this.ts.get(page + '.' + 'errorList.' + errorObj.status.toString()
          + '.' + errorObj.error.ecode.toString()).subscribe(data => {
            if ((page + '.' + 'errorList.' + errorObj.status.toString() + '.' + errorObj.error.ecode.toString()) === data) {
              this.ts.get(page + '.' + 'errorList.' + errorObj.status.toString()
                + '.DEFAULT').subscribe(defaultData => {
                  if (page + '.' + 'errorList.' + errorObj.status.toString() + '.DEFAULT' === defaultData) {
                    this.ts.get(PAGES.COMMON + '.' + 'errorList.' + errorObj.status.toString() + '.' + errorObj.error.ecode.toString())
                      .subscribe(commonData => {
                        if (PAGES.COMMON + '.' + 'errorList.' + errorObj.status.toString() + '.' + errorObj.error.ecode.toString()
                          === commonData) {
                          this.ts.get(PAGES.COMMON + '.' + 'errorList.' + errorObj.status.toString() + '.DEFAULT')
                            .subscribe(errorCodeData => {
                              error = this.getErrorMessage(errorCodeData);

                            })
                        }
                        else {
                          error = this.getErrorMessage(commonData)
                        }
                      })
                  }
                  else {
                    error = this.getErrorMessage(defaultData);
                  }
                })
            }
            else {
              error = this.getErrorMessage(data);
            }
          });
        this.storage.onErrorOccurs.next({error: errorObj, description: error.description});
        return error;
      }
      else {
        if (errorObj.error && errorObj.error.ecode && errorObj.error.ecode.toString() === generalEcode) {
          this.ts.get(PAGES.COMMON + '.' + 'errorList.' + errorObj.status.toString()
            + '.' + generalEcode).subscribe(dataDefault => {
              error = this.getErrorMessage(dataDefault);
              error.retryButton = this.getGeneralErrorMsg(errorObj).retryButton;
            })
        }
        else {

          error = this.getGeneralErrorMsg(errorObj);
        }
        this.storage.onErrorOccurs.next({error: errorObj, description: error.description});
        return error;
      }
    }
  }
  getErrorMessage(data) {
    const error = new Error();
    error.title = data.title;
    error.description = data.description;
    error.errorCode = data.errorCode;
    error.httpStatus = data.httpStatus;
    error.descriptionRichText = data.richDescription ;
    if (data.confirmButton) {
      error.confirmButtonText = data.confirmButton.text;
    }
    if (data.dismissButton) {
      error.dismissButtonText = data.dismissButton.text;
    }
    return error;
  }
  getGeneralErrorMsg(errorObj): Error {
    let error: Error;
    this.ts.get(PAGES.COMMON + '.messagesList').subscribe(data => {
      switch (errorObj.status) {
        // Request Timeout
        case GENERAL_ERROR_CODES.TIMEOUT_OCCURRED:
        case GENERAL_ERROR_CODES.NETWORK_READ_TIMEOUT:
        case GENERAL_ERROR_CODES.REQUEST_TIMEOUT:
        case GENERAL_ERROR_CODES.GATEWAY_TIMEOUT:
          error = this.fillErrorObj(data, 'requestTimeoutError')
          error.retryButton = true;
          break;
        // no response
        case GENERAL_ERROR_CODES.NO_RESPONSE:
        // bade request
        case GENERAL_ERROR_CODES.BAD_REQUEST_ERROR_CODE:
          error = this.fillErrorObj(data, 'otherNetworkErrors')
          error.retryButton = true;
          break;
        // general error 500
        case GENERAL_ERROR_CODES.GENERAL_ERROR:
          error = this.fillErrorObj(data, 'generalApplicationError')
          break;
        // forbidden upgrad
        case GENERAL_ERROR_CODES.FORBIDDEN:
          error = this.fillErrorObj(data, 'otherNetworkErrors')
          break;
        // no connection
        case 0:
          if (!this.appService.onlineFlag) {
            data['noConnectionError'].confirmButton = null;
            error = this.fillErrorObj(data, 'noConnectionError')
            error.retryButton = true;
            error.hideX = true;
            error.dismissButtonText = ''
              ;
          }
          else {
            error = this.fillErrorObj(data, 'somethingWentWrong');
          }
          break;
        default:
          error = this.fillErrorObj(data, 'somethingWentWrong');
          break;
      }
    })
    return error;
  }
  fillErrorObj(data: [any], key: string): Error {
    const _error = new Error();
    _error.title = data[key].title;
    _error.description = data[key].description;
    _error.descriptionRichText = data[key].richDescription ;
    if (data[key].confirmButton && data[key].confirmButton.text) {
      _error.confirmButtonText = data[key].confirmButton.text;
    }
    if (data[key].dismissButton && data[key].dismissButton.text) {
      _error.dismissButtonText = data[key].dismissButton.text;
    }
    if (data[key].dismissButton && data[key].dismissButton.text) {
      _error.dismissButtonText = data[key].dismissButton.text;
    }
    return _error;
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { MyAccountService } from '../../../my-account/my-account.service';
import { BIZTALKERROR, pathEBillConfigPath, paymentMethod, Validation } from '../../constants/defines';
import { CustomerAccountService } from '../../services/customer-account.service';
import { StorageService } from '../../../core/services/storage.service';
import { MyDataInputFieldId } from '../../enums/my-account-sections.enum';
import { MyAccountInputData } from '../../../models/account-edit-details.model';
import { UtilitiesService } from '../../utils/utilities.service';
import { CrossVariables } from '../../../models/tagging.model';
import { PaymentJourneyProcess } from '../../enums/payment-journey-process.enum';
import { PAYMENTS_TRACKING, FOLDER_MY_PAYMENT_METHODS } from '../../constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { NewTaggingStateListModel } from 'src/app/models/new-tagging-state-list.model';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';

@Component({
  selector: 'sp-edit-bank-account-overlay',
  templateUrl: './edit-bank-account-overlay.component.html',
  styleUrls: ['./edit-bank-account-overlay.component.scss']
})
export class EditBankAccountOverlayComponent implements OnInit {
  overlayData: OverlayModel;
  showEditOverlay: boolean;
  showOkOverlay: boolean;
  showKoOverlay: boolean;
  pageOverlay: number = 1;
  /** To load inner content of pages 2 & 3 */
  loadOverlayPages: boolean;
  /** To start validation after user enter at least 24 character */
  isIbanWrittenCompletely: boolean;
  overlayForm: UntypedFormGroup;
  OTPKey: string;
  showOtpError: boolean;
  operation: string;
  userMsisdn: string = '';
  showXButton: boolean = false;
  fieldData: { allFieldsAreValid?: boolean; inputsArray?: MyAccountInputData[]; };
  fieldsIDs: { [key: string]: MyDataInputFieldId } = MyDataInputFieldId;
  pagesTagging: { [key: string]: Function } = {};

  @Output() okayButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  //#region WCS Variables
  addEditBankAccountHeader: string = '';
  addEditBankAccountTitle: string = '';
  addEditBankAccountSubTitle: string = '';
  addEditBankAccountTextFieldName: string = '';
  addEditBankAccountTextFieldSurName: string = '';
  addEditBankAccountTextFieldSecondSurName: string = '';
  addEditBankAccountEditBankTitle: string = '';
  addEditBankAccountTextFieldBank: string = '';
  addEditBankAccountCTA: string = '';
  addEditBankAccountInvalidName: string = '';
  addEditBankAccountInvalidSurName: string = '';
  addEditBankAccountInvalidBank: string = '';
  addEditBankAccountFieldRequired: string = '';
  confirmationHeader: string = '';
  confirmationTitle: string = '';
  confirmationSubTitle: string = '';
  confirmationSaveCTA: string = '';
  confirmationEditAgainCTA: string = '';
  otpHeader: string = '';
  otpTitle: string = '';
  otpSubTitle: string = '';
  otpTextFieldPlaceHolder: string = '';
  otpCTA: string = '';
  otpResendCTA: string = '';
  otpInvalid: string = '';
  koTitle: string = '';
  koSubTitle1: string = '';
  koSubTitle2: string = '';
  koCTA: string = '';
  okTitle: string = '';
  okSubTitle: string = '';
  okTitleCashDesk: string = '';
  okSubTitleCashDesk: string = '';
  okCTA: string = '';
  //#endregion

  constructor(
    private myAccountService: MyAccountService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private utilitiesService: UtilitiesService,
    private customerAccountService: CustomerAccountService,
    public appService: AppService,
    private storageService: StorageService,
    private newTagging: NewTaggingHelperService,
    private taggingHelper: TaggingHelperService
  ) { }

  ngOnInit(): void {
    this.showEditOverlay = true;
    this.overlayData = {};
    this.getUserMsisdn();
    this.setOperation();
    this.loadWCSData();
    this.setOverlayTitle();
    this.setFormControls();
    this.setPagesTagging();
    this.changePage(this.pageOverlay);
    setTimeout(() => this.loadOverlayPages = true, 500);
  }

  /** Method for setting operation either 'add' for new account or 'replace' for editing */
  setOperation(): void {
    if (this.myAccountService.isEditBankInfo) {
      this.operation = paymentMethod.Operations.replace;
    } else {
      this.operation = paymentMethod.Operations.add;
    }
  }

  /** Method for setting tagging function for each page */
  setPagesTagging(): void {
    this.pagesTagging = {
      page1: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA),

      page2: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_VERIFY_ACCOUNT),

      page3: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_SMS_CONFIRMATION),

      okPage: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK),
      okOldAccountPage: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK_OLD_ACCOUNT),
      okNewAccountPage: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK_NEW_ACCOUNT),
      okUnknownAccountPage: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK_UNKNOWN_ACCOUNT),

      koPage: (error?: { code: string, description: string }) => this.getTaggingOfPage(
        PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_KO, error)
    };
  }
  /**
   * Method that return a tagging function to set tagging for each page
   * @param stateName {string} state name
   * @param error error object (ecode, description) to use in error_list variable in error page tagging
   * @returns {Function} return tagging function to set tagging for each page
   */
  getTaggingOfPage(stateName: string, error?: { code: string, description: string }): Function {
    return this.taggingForEditBankAccountScreens.bind(this, stateName, error);
  }

  /** Method for setting the form controls */
  setFormControls(): void {
    this.fieldData = { allFieldsAreValid: false };
    const nameValidators: Validators[] = [
      Validators.maxLength(20),
      Validators.pattern(Validation.spanishName),
      Validators.pattern(/[\S]/), // Do not allow blank spaces only
    ];
    this.fieldData.inputsArray = [
      /** Name Field */
      this.getFieldObject(this.fieldsIDs.name, this.addEditBankAccountTextFieldName,
        [Validators.required, ...nameValidators],
        this.addEditBankAccountFieldRequired, this.addEditBankAccountInvalidName, this.addEditBankAccountInvalidName),

      /** First Name Field */
      this.getFieldObject(this.fieldsIDs.firstName, this.addEditBankAccountTextFieldSurName,
        [Validators.required, ...nameValidators],
        this.addEditBankAccountFieldRequired, this.addEditBankAccountInvalidSurName, this.addEditBankAccountInvalidSurName),

      /** Second Name Field */
      this.getFieldObject(this.fieldsIDs.secondName, this.addEditBankAccountTextFieldSecondSurName, nameValidators,
        '', this.addEditBankAccountInvalidSurName, this.addEditBankAccountInvalidSurName, true),

      /** IBAN Field */
      this.getFieldObject(this.fieldsIDs.IBAN, this.addEditBankAccountTextFieldBank,
        [Validators.required], this.addEditBankAccountFieldRequired, '', this.addEditBankAccountInvalidBank)
    ];

    //#region Set form controls to know what's invalid error should be displayed?
    const formFields: {
      [inputName: string]: [string | number, Validators[]];
    } = {};
    this.fieldData.inputsArray.forEach((input: MyAccountInputData) => {
      formFields[input.inputId] = [input.inputValue, input.validators || []];
    });

    this.overlayForm = this.fb.group(formFields);
    //#endregion

  }

  /**
   * Method for adding space between each 4 digits
   * @param subField should be IBAN field all the time
   */
  formatIBAN(subField: MyAccountInputData): void {
    subField.inputValue = subField.inputValue?.toUpperCase().replace(/\s/g, '')?.match(/.{1,4}/g)?.join(' ');
  }

  /** Method for reset all flags of showing the overlays */
  closeOverlay(): void {
    this.showEditOverlay = false;
    this.showOkOverlay = false;
    this.showKoOverlay = false;
    setTimeout(() => this.closed.emit(), 500);
  }

  /**
   * Method for validate form controls
   * @param subField is a form field
   */
  validateField(subField: MyAccountInputData): void {
    subField.showError = true;
    subField.showRate = false;
    subField.isValid = this.overlayForm.get(subField.inputId).valid;
    // If no subField.validatorErrorTexts key is present, the default error text will be subField.errorText
    if (!subField.isValid && subField.validatorErrorTexts) {
      this.fieldData.allFieldsAreValid = false;
      const currentFieldErrors: string[] = Object.keys(this.overlayForm.get(subField.inputId)?.errors || {});
      const formErrors: string[] = Object.keys(this.overlayForm.errors || {});
      subField.errorText = subField.validatorErrorTexts[formErrors[0]] || subField.validatorErrorTexts[currentFieldErrors[0]];
    }
    this.checkFormValidity();
  }

  /**
   * Method for validating IBAN field
   * @param subField should be IBAN field all the time
   */
  validateIBAN(subField: MyAccountInputData, isFocusOut?: boolean): void {
    subField.showError = false;
    subField.isValid = false;
    subField.showRate = false;
    const iban: string = subField.inputValue?.toUpperCase().replace(/\s/g, '');
    if (!iban) {
      subField.showError = true;
      subField.errorText = subField.validatorErrorTexts.required;
      this.fieldData.allFieldsAreValid = false;
    } else if (isFocusOut || iban.length >= 24) {
      if (iban.length >= 24) {
        this.isIbanWrittenCompletely = true;
      }
      subField.isValid = this.utilitiesService.validateIBAN(iban);
      // If no subField.validatorErrorTexts key is present, the default error text will be subField.errorText
      if (!subField.isValid && subField.validatorErrorTexts) {
        subField.showError = true;
        subField.errorText = subField.validatorErrorTexts.pattern;
      }
    } else if (this.isIbanWrittenCompletely) {
      subField.showError = true;
      subField.errorText = subField.validatorErrorTexts.pattern;
    }
    this.checkFormValidity();
  }

  /**
   * Method for changing overlay page number and change its title
   * @param pageNumber the page number of overlay pages
   */
  changePage(pageNumber: number): void {
    this.pageOverlay = pageNumber;
    this.setOverlayTitle();
    this.sendPageTagging();
    /** make tray full screen if OTP page only (page 3) */
    this.overlayData.isFullScreen = this.pageOverlay === 3;
  }

  /** Method for sending tagging of a page first 3 pages */
  sendPageTagging(): void {
    this.pagesTagging[`page${this.pageOverlay}`]();
  }

  /** Method for setting allFieldsAreValid value for controlling diabling the button of first page */
  checkFormValidity(): boolean {
    this.fieldData.allFieldsAreValid = this.fieldData.inputsArray.every(input => {
      return input.isValid;
    });
    return this.fieldData.allFieldsAreValid;
  }

  /** Method for handling back button behavior */
  back(): void {
    this.changePage(this.pageOverlay - 1);
  }

  /** Set overlay title after each navigation between pages */
  setOverlayTitle(): void {
    switch (this.pageOverlay) {
      case 1: this.overlayData.title = this.addEditBankAccountHeader; break;
      case 2: this.overlayData.title = this.confirmationHeader; break;
      case 3: this.overlayData.title = this.otpHeader; break;
    }
  }

  /** Method for setting updatedAccountNumber data to refresh billing account screen when needed */
  updateBankAccount(): void {
    if (this.checkFormValidity()) {
      this.customerAccountService.updatedAccountNumber = {
        bankName: this.overlayForm.value[paymentMethod.formControls.name]?.trim(),
        firstName: this.overlayForm.value[paymentMethod.formControls.firstName]?.trim(),
        familyName: this.overlayForm.value[paymentMethod.formControls.secondName]?.trim(),
        iban: this.overlayForm.value[paymentMethod.formControls.IBAN]?.toUpperCase().replace(/\s/g, '')
      };

      this.patchNewBankAccountData();
    }
  }

  /** Method for sending a request with new data to edit bank account data */
  patchNewBankAccountData(): void {
    this.toggleVodafoneSpinner(true);
    this.showEditOverlay = false;
    this.customerAccountService.patchPaymentMethod(this.customerAccountService.updatedAccountNumber,
      pathEBillConfigPath.editBankAccount, this.operation, this.OTPKey).
      subscribe(
        (res) => {
          this.okSubTitle = res.paymentMethod.paymentStatus;
          this.toggleVodafoneSpinner(false);
          /** Open OK overlay */
          this.openResultOverlay(true, this.okSubTitle);
        },
        (error) => {
          this.toggleVodafoneSpinner(false);
          if (error.error && error.error.ecode && error.error.ecode.toString() === BIZTALKERROR) {
            this.appService.NudgeBiztalkError();
          } else {
            /** Open KO overlay */
            this.openResultOverlay(false, '', { code: error.error?.ecode, description: error.error?.description });
          }
        });
  }

  /**
   * Method for opening success/failure overlay
   * @param isSuccess if true will open success overlay, false will open failure overlay
   */
  openResultOverlay( isSuccess: boolean, descriptionStatus?: string,  error?: { code: string, description: string }): void {
    this.showOkOverlay = isSuccess;
    this.showKoOverlay = !isSuccess;
    if (isSuccess) {
      if (
        this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.newAccount_description').toLowerCase() ===
        descriptionStatus.toLowerCase()
      ) {
        this.pagesTagging.okNewAccountPage();
      } else if (
        this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.oldAccount_description').toLowerCase() ===
        descriptionStatus.toLowerCase()
      ) {
        this.pagesTagging.okOldAccountPage();
      } else if (
        this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.newAccountNonEnsureBill_description').toLowerCase() ===
        descriptionStatus.toLowerCase()
      ) {
        this.pagesTagging.okUnknownAccountPage();
      } else {
        this.pagesTagging.okPage();
      }
    } else {
      this.pagesTagging.koPage(error)();
    }
  }

  /**
   * Method for show/hide Vodafone spinner
   * @param show if true then show spinner, otherwise hide spinner
   */
  toggleVodafoneSpinner(show: boolean): void {
    this.appService.showVodaFullLoader = show;
  }

  /**
   * Method for generating OTP token
   */
  reSendCode(): void {
    this.customerAccountService.postGeneratePIN().subscribe();
  }

  /** Method for emitting the output and close the tray */
  okayButtonClick(): void {
    this.okayButtonClicked.emit();
    this.closeOverlay();
  }

  /**
   * Load wcs data for mi cuenta add or edit bank account screens
   */
  loadWCSData(): void {
    this.translate.get('v10.myAccount').subscribe(data => {

      //#region start add edit screen wcs
      this.addEditBankAccountHeader = data.bankAccout.addEditBankAccount.headerTitle;
      this.addEditBankAccountTitle = data.bankAccout.addEditBankAccount.title;
      this.addEditBankAccountSubTitle = data.bankAccout.addEditBankAccount.screenHeaderSubtitle;
      this.addEditBankAccountTextFieldName = data.bankAccout.addEditBankAccount.textField.title;
      this.addEditBankAccountTextFieldSurName = data.bankAccout.addEditBankAccount.textField.surname;
      this.addEditBankAccountTextFieldSecondSurName = data.bankAccout.addEditBankAccount.textField.secondSurname;
      this.addEditBankAccountEditBankTitle = data.bankAccout.addEditBankAccount.bankAccountSecionTitle;
      this.addEditBankAccountTextFieldBank = data.bankAccout.addEditBankAccount.textField.bankAccout;
      this.addEditBankAccountCTA = data.bankAccout.dataConfirmationOverlay.saveCTA;
      this.addEditBankAccountInvalidName = data.messagesList.bankAccoutinvalidName;
      this.addEditBankAccountInvalidSurName = data.messagesList.bankAccountInvalidSurname;
      this.addEditBankAccountInvalidBank = data.messagesList.bankAccountInvalidBankAccount;
      this.addEditBankAccountFieldRequired = data.messagesList.bankAccountRequiredField;
      //#endregion

      //#region start confirmation edit screen wcs
      this.confirmationHeader = data.bankAccout.dataConfirmationOverlay.saveCTAheader;
      this.confirmationTitle = data.bankAccout.dataConfirmationOverlay.title;
      this.confirmationSubTitle = data.bankAccout.dataConfirmationOverlay.subTitle;
      this.confirmationSaveCTA = data.bankAccout.dataConfirmationOverlay.saveCTA;
      this.confirmationEditAgainCTA = data.bankAccout.dataConfirmationOverlay.editDataAgainCTA;
      //#endregion

      //#region start otp screen wcs
      this.otpHeader = data.bankAccout.otp.header;
      this.otpTitle = data.bankAccout.otp.title;
      this.otpSubTitle = data.bankAccout.otp.subTitle;
      this.otpTextFieldPlaceHolder = data.bankAccout.otp.placeholder;
      this.otpCTA = data.bankAccout.otp.confirmCTA.title;
      this.otpResendCTA = data.bankAccout.otp.resendOTP.title;
      this.otpInvalid = data.messagesList.bankAccoutoInvalidOTP;
      //#endregion

      //#region start ok screen wcs
      this.okTitle = data.bankAccout.otp.OKConfirmationOverlay.title;
      this.okTitleCashDesk = data.bankAccout.cashUser.otp.OKConfirmationOverlay.title;
      this.okSubTitleCashDesk = data.bankAccout.cashUser.otp.OKConfirmationOverlay.subTitle;
      this.okCTA = data.bankAccout.cashUser.otp.OKConfirmationOverlay.cta;
      //#endregion

      //#region start ko screen wcs
      this.koTitle = data.bankAccout.otp.KOConfirmationOverlay.title;
      this.koSubTitle1 = data.bankAccout.otp.KOConfirmationOverlay.subTitle1;
      this.koSubTitle2 = data.bankAccout.otp.KOConfirmationOverlay.subTitle2;
      this.koCTA = data.bankAccout.otp.KOConfirmationOverlay.cta;
      //#endregion

    });

  }

  /**
   * Method for getting input field object of form control
   * @param name for inputId & formControlName
   * @param label for placeholder and label of input field
   * @param validators for check input field validity
   * @param requiredError should be displayed when field is invalid and required
   * @param maxLengthError should be displayed when field has been exceeded max length
   * @param patternError should be displayed when field has invalid pattern
   * @param isValid to set initial validity of input
   * @returns get an object of type MyAccountInputData
   */
  getFieldObject(name: MyDataInputFieldId, label: string, validators: Validators[],
    requiredError?: string, maxLengthError?: string, patternError?: string, isValid?: boolean): MyAccountInputData {
    return {
      inputId: name,
      inputValue: '',
      inputLabel: label,
      validators,
      validatorErrorTexts: {
        maxlength: maxLengthError,
        pattern: patternError,
        required: requiredError
      },
      isValid
    }
  }

  /**
  * Tagging for edit bank account screens
  * @param stateName {string} state name
  * @param error for KO page
  */
  taggingForEditBankAccountScreens(stateName: string, error?: { code: string, description: string }): void {
    this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
      const state: NewTaggingStateListModel = data.page.stateList[stateName];
      if (this.customerAccountService.hasBankAccount) {
        state.journey.journey_process = PaymentJourneyProcess.bankAccountJourneyProcess;
      } else {
        state.journey.journey_process = PaymentJourneyProcess.cashDeskJourneyProcess;
      }
      if (state.journey.journey_category) { state.journey.journey_category = CrossVariables.client_typology; }
      if (error) {
        this.taggingHelper.error_list = tagging.staticVariables.editBankAccountErrorList
          .replace('<error_code>', error.code).replace('<error_desc>', error.description);
      }
      this.newTagging.state(state);
    });
  }
  /**
   * get user msisdn masked except last two digits
   */
  getUserMsisdn(): void {
    this.userMsisdn = this.storageService.userProfile.msisdn;
    let maskedMsisdn: string = '';
    if (this.userMsisdn) {
      for (let i: number = 0; i < this.userMsisdn.length - 2; i++) {
        maskedMsisdn += '*';
      }
      maskedMsisdn += this.userMsisdn.substring(this.userMsisdn.length - 2);
      this.userMsisdn = maskedMsisdn;
   }
  }
  /**
   * Regex that accept only numbers
   * @return {RegExp} a regex that accept only numbers in text field (text field will be valid when contains numbers only).
   */
  acceptNumbersOnly(): RegExp {
    const regexForNumbersOnly: RegExp = new RegExp(Validation.numberOnly);
    return regexForNumbersOnly;
  }
  /**
   * validate otpkey in text field (contains only numbers or not)
   * @return {boolean} that the otpkey matches the regex or not (contains only numbers or not)
   * @param {string} otpKey value of otpKey in text field
   */
  validOtpKey(otpKey: string): boolean {
    if (otpKey && otpKey.trim().length === 6 && this.acceptNumbersOnly().test(otpKey)) {
      return true;
    } else {
      return false;
    }
  }
}

import { map } from 'rxjs/operators';
import { B5I, COE } from './../constants/defines';
import { RemainingStep } from './../../models/remainingStep.model';
import { WorryFree } from './../../models/worryFree.model';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { HttpHeaders } from '@angular/common/http';
import * as constants from '../constants/defines';
import { Product } from '../../models/product.model';
import { JSON_PATHS, cachingKeys } from '../constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { Promotion } from '../../models/promotion.model';
import { OnlineTv } from '../../models/online-tv.model';
import { UnitCode } from '../enums/unitCode.enum';
import { evict } from '../decorators/evict.decorator';
import { ServiceType } from '../enums/serviceType.enum';
import { UtilitiesService } from '../utils/utilities.service';
import { ProductType } from '../enums/productType.enum';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { tagging } from '../../../config/tagging-config';
import * as _ from 'lodash';
import { BundleType } from '../enums/bundleType.enum';
import { ProductCategory } from '../enums/productCategory.enum';
import { CallType } from '../enums/callType.enum';
import { ProductSubtype } from '../enums/product-subtype.enum';
import { TranslateService } from '@ngx-translate/core';
import { DestinyService } from './destiny.service';
import { ProductRestriction } from '../../models/products-restriction.model';
import { ServiceModel } from '../../models/service.model';
import { ShippingModesTv } from '../enums/shipping-modes-tv.enum';
import { DeliveryOffice } from '../../models/delivery-office.model';
import { Observable } from 'rxjs';
import { Channels } from '../../models/channels.model';
import { OpAddon } from '../../models/opAddon.model';

@Injectable()
export class ProductService {

  products: Product[] = new Array<Product>();
  staticsProducts: Product[];
  statusError: string;
  worryFreeName: string;
  hasUnlimited: boolean = false;
  accessToNewOffer: boolean = false;
  codesListNewOffer: any[];
  showTVOnline: boolean = false;
  navigateToEnjoyMoreListingFromVFPassLanding: boolean = false;
  public channelDescription: string;
  public channelCode: string;
  public channelStatus: string;
  public channelName: string;

  constructor(private http: HttpClient,
    private storageService: StorageService,
    private subscriptionService: SubscriptionService,
    private utilities: UtilitiesService,
    private translateService: TranslateService,
    private destinyService: DestinyService) {
      this.destinyService.unlimitedObservable.subscribe(state => {
        this.hasUnlimited = state;
      });
  }
  /**
   * Fetch All products of the current site and current service
   */
  GetAllProducts(currentServiceId?) {
    const url = API_URLS.Product.Products.replace('{subscription-id}', currentServiceId ? currentServiceId :
      this.subscriptionService.customerData.currentService.id)
      .replace('{customer-account-id}', this.subscriptionService.customerData.customerAccountsId);
    this.storageService.navigateToSecureNetFromBilling = false;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      this.productLogic(res)
      return res;
    }));
  }


  productLogic(product) {
    this.products = new Array<Product>();
    this.statusError = JsonQuery.value(product, JSON_PATHS.Products.statusPartialError) || ''
    const items = JsonQuery.value(product, JSON_PATHS.Products.Items) || null;
    this.destinyService.checkUnlimitedProducts(items);
    items.map(item => {

      const product = new Product();
      product.Name = JsonQuery.value(item, JSON_PATHS.Products.Name) || null;
      product.title = JsonQuery.value(item, JSON_PATHS.Products.title) || null;
      product.serviceType = JsonQuery.value(item, JSON_PATHS.Products.serviceType) || null;
      product.defaultSVACode = JsonQuery.value(item, JSON_PATHS.Products.defaultSVACode) || null;
      product.additionalInfo = JsonQuery.value(item, JSON_PATHS.Products.additionalInfo) || null;
      product.deviceName = JsonQuery.value(item, JSON_PATHS.Products.deviceName) || null;
      // DXL will return true/false values , so can't set initial value true as the button
      // in the enjoy more details initially must been shown
      // so if it returns false it will be true also
      if (JsonQuery.value(item, JSON_PATHS.Products.canBeDeactivated) === false) {
        product.canBeDeactivated = false;
      } else {
        product.canBeDeactivated = true;
      }
      product.titleLimit = JsonQuery.value(item, JSON_PATHS.Products.titleLimit) || null;
      product.titleRate = JsonQuery.value(item, JSON_PATHS.Products.titleRate) || null;
      product.Type = JsonQuery.value(item, JSON_PATHS.Products.Type) || null;
      product.Code = JsonQuery.value(item, JSON_PATHS.Products.Code) || null;
      product.offeringCode = JsonQuery.value(item, JSON_PATHS.Products.offeringCode) || null;
      product.TypeName = JsonQuery.value(item, JSON_PATHS.Products.TypeName) || null;
      product.serviceID = this.subscriptionService.customerData.currentService.id;
      const dataSharing = JsonQuery.value(item, JSON_PATHS.Products.dataSharing) || null;
      product.ProductType = JsonQuery.value(item, JSON_PATHS.Products.ProductType) || null;
      product.benefitsFlag = JsonQuery.value(item, JSON_PATHS.Products.benefitsFlag) || null;
      product.productExpirationDate = JsonQuery.value(item, JSON_PATHS.Products.productExpirationDate) || null;
      product.mobility = JsonQuery.value(item, JSON_PATHS.Products.mobility) || null;
      product.ableToBook = JsonQuery.value(item, JSON_PATHS.Products.onlineTvAblToBook) || false;

      product.channelsTV = (JsonQuery.value(item, JSON_PATHS.Products.channelsTV) || []).map(item => {
        const chn: Channels = new Channels();
        chn.code = JsonQuery.value(item, JSON_PATHS.Products.Code) || null;
        chn.currentStatus = JsonQuery.value(item, JSON_PATHS.Products.currentStatus) || null;
        chn.descriptionLong = JsonQuery.value(item, JSON_PATHS.Products.channelsTVDescriptionLong) || null;
        chn.fee = JsonQuery.value(item, JSON_PATHS.Products.channelsTVFee) || null;
        chn.monthly = JsonQuery.value(item, JSON_PATHS.Products.fee) || null;
        chn.name = JsonQuery.value(item, JSON_PATHS.Products.Name) || null;
        chn.monthlyCurrency = JsonQuery.value(item, JSON_PATHS.Products.feeUnit) || null;
        return chn;
      });

      product.addons = (JsonQuery.value(item, JSON_PATHS.Products.addons) || []).map(item => {
        const addon: OpAddon = new OpAddon();
        addon.id = JsonQuery.value(item, JSON_PATHS.Products.id) || null;
        addon.name = JsonQuery.value(item, JSON_PATHS.Products.Name) || null;
        addon.code = JsonQuery.value(item, JSON_PATHS.Products.Code) || null;
        addon.serviceType = JsonQuery.value(item, JSON_PATHS.Products.serviceType) || null;
        addon.type = JsonQuery.value(item, JSON_PATHS.Products.Type) || null;
        addon.description = JsonQuery.value(item, JSON_PATHS.Products.description) || null;
        addon.groupAddon = JsonQuery.value(item, JSON_PATHS.Products.grupoAddon) || null;
        addon.costMonthly = JsonQuery.value(item, JSON_PATHS.Products.fee) || null;
        addon.costMonthlyCurrency = JsonQuery.value(item, JSON_PATHS.Products.feeUnit) || null;
        addon.costMonthlyWithoutIVA = JsonQuery.value(item, JSON_PATHS.Products.feeWIva) || null;
        addon.costMonthlyCurrencyWithoutIVA = JsonQuery.value(item, JSON_PATHS.Products.feeUnitWIva) || null;

        return addon;
      });

      if (product.mobility) {
        const consumptionAllownce = JsonQuery.value(product.mobility, JSON_PATHS.Products.ConsumptionAllowance) || null;
        if (consumptionAllownce) {
          product.mobility.IsUnLimited = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.IsUnLimited) || false;
          if (!product.mobility.IsUnLimited) {
            product.mobility.TotalConsumption = parseFloat(JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.TotalConsumption)) || 0;
            product.mobility.TotalConsumptionUnit = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.RemainingConsumptionUnit) ?
            JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.RemainingConsumptionUnit).toUpperCase() : '';
            product.mobility.ConsumedConsumption =
            JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumption) !== undefined ?
            parseFloat(JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumption)) : null;
            product.mobility.ConsumedConsumptionUnit = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumptionUnit) ?
            JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumptionUnit).toUpperCase() : '';
          }
          product.mobility.RemainingConsumptionPercentage = product.mobility.TotalConsumption ?
          Math.trunc(((product.mobility.TotalConsumption - product.mobility.ConsumedConsumption) * 100) /
          (product.mobility.TotalConsumption)) : 0;
        }
      }

      product.OrderCreationDate = JsonQuery.value(item, JSON_PATHS.Products.orderCreationDate) || null;
      const ProductCharacteristics = JsonQuery.value(item, JSON_PATHS.Products.ProductCharacteristics) || null;
      if (ProductCharacteristics) {
        product.CallType = JsonQuery.value(item, JSON_PATHS.Products.CallType) || null;
        product.ProductCategory = JsonQuery.value(item, JSON_PATHS.Products.ProductCategory) || null;
      }
      if (JsonQuery.value(item, JSON_PATHS.Products.status)) {

        product.lastStatus = JsonQuery.value(item, JSON_PATHS.Products.lastStatus) || '';
        product.currentStatus = JsonQuery.value(item, JSON_PATHS.Products.currentStatus) || null;
        product.transactionStatus = JsonQuery.value(item, JSON_PATHS.Products.transactionStatus) || null;
        product.ChannelDeactivationLocked = JsonQuery.value(item, JSON_PATHS.Products.ChannelDeactivationLocked) || null;
        product.ChannelDeactivationLockedCode = JsonQuery.value(item, JSON_PATHS.Products.ChannelDeactivationLockedCode) || null;
      }
      const extensions = JsonQuery.value(item, JSON_PATHS.Products.Extensions) || null;
      if (extensions) {
        product.Special = JsonQuery.value(item, JSON_PATHS.Products.Special) || false;
        product.Restrictions = JsonQuery.value(item, JSON_PATHS.Products.Restrictions) || null;
        if (product.Restrictions) {
          product.Restrictions = new ProductRestriction();
          product.Restrictions.id = JsonQuery.value(item, JSON_PATHS.Products.RestrictionsId) || null;
          product.Restrictions.isChargeToBill = JsonQuery.value(item, JSON_PATHS.Products.RestrictionsIsChargeToBill) || null;
        }
      }
      const consumption = JsonQuery.value(item, JSON_PATHS.Products.Consumption) || null;
      if (consumption) {
        const consumptionAllownce = JsonQuery.value(consumption, JSON_PATHS.Products.ConsumptionAllowance) || null;
        if (consumptionAllownce) {
          product.TotalConsumption = parseFloat(JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.TotalConsumption)) || 0;
          product.TotalConsumptionUnit = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.RemainingConsumptionUnit) ?
            JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.RemainingConsumptionUnit).toUpperCase() : '';
          product.ConsumedConsumption = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumption) !== undefined ?
            parseFloat(JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumption)) : null;
          product.ConsumedConsumptionUnit = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumptionUnit) ?
            JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.ConsumedConsumptionUnit).toUpperCase() : '';
          product.IsUnLimited = JsonQuery.value(consumptionAllownce, JSON_PATHS.Products.IsUnLimited) || false;
        }
        product.ConsumptionExpiryDate = JsonQuery.value(item, JSON_PATHS.Products.ConsumptionExpiryDate) || null;
      }
      product.activationDate = JsonQuery.value(item, JSON_PATHS.Products.activationDate) || null;
      product.RemainingConsumptionPercentage = product.TotalConsumption ?
        Math.trunc(((product.TotalConsumption - product.ConsumedConsumption) * 100) /
          (product.TotalConsumption)) : 0;
      product.RemainingConsumptionDataShareing = JsonQuery.value(item, JSON_PATHS.Products.RemainingConsumptionDataShareing) || null;

      if (product.RemainingConsumptionDataShareing) {
        if (product.RemainingConsumptionDataShareing >= 0) {

          product.RemainConsumptionData = product.RemainingConsumptionDataShareing >= 1000 ?
            product.RemainingConsumptionDataShareing / 1024 : product.RemainingConsumptionDataShareing;
        }
        else {
          product.RemainConsumptionData = 0;
        }
        product.RemainConsumptionUnit = product.RemainingConsumptionDataShareing >= 1000 ?
          this.utilities.getUnitCodeFromWCS(UnitCode.GB) : this.utilities.getUnitCodeFromWCS(UnitCode.MB);
      } else if (product.Type &&
        product.Type.toLocaleLowerCase() === BundleType.Data && (product.TotalConsumption - product.ConsumedConsumption) < 1000) {
        product.RemainConsumptionData = (product.TotalConsumption - product.ConsumedConsumption) >= 0 ?
          (product.TotalConsumption - product.ConsumedConsumption) : 0;
        product.RemainConsumptionUnit = this.utilities.getUnitCodeFromWCS(UnitCode.MB);
      } else if (product.Type && product.Type.toLocaleLowerCase() === BundleType.Data) {
        product.RemainConsumptionData = (product.TotalConsumption - product.ConsumedConsumption) / 1024;
        product.RemainConsumptionUnit = this.utilities.getUnitCodeFromWCS(UnitCode.GB);
      }

      if (product.Type && product.Type.toLocaleLowerCase() === BundleType.SMS) {
        product.RemainConsumptionUnit = this.utilities.getUnitCodeFromWCS(UnitCode.SMS);
      }

      if (product.RemainConsumptionData && product.RemainConsumptionData.toString().indexOf('.') > -1) {
        product.RemainConsumptionData = + product.RemainConsumptionData.toString().substring(0,
          product.RemainConsumptionData.toString().indexOf('.') + 3)
      }
      product.RemainConsumptionExtraVoice = Math.trunc((product.TotalConsumption - product.ConsumedConsumption) / 60)
      if (product.RemainConsumptionExtraVoice) {
        product.RemainConsumptionExtraVoice = product.RemainConsumptionExtraVoice < 0 ? 0 : Math.trunc(product.RemainConsumptionExtraVoice);
      }

      product.RemainConsumptionMinutes = this.subscriptionService.customerData.currentService.type.toLowerCase() ===
        ServiceType.Fixed.toLowerCase() ||
        this.subscriptionService.customerData.currentService.type.toLowerCase() ===
        ServiceType.Landline.toLowerCase() ?
        Math.trunc((product.TotalConsumption - product.ConsumedConsumption) / 60) :
        this.utilities.roundDecimals((product.TotalConsumption - product.ConsumedConsumption) / 60, 2)
      if (product.RemainConsumptionMinutes) {
        product.RemainConsumptionMinutes = product.RemainConsumptionMinutes < 0 ? 0 : Math.trunc(product.RemainConsumptionMinutes);
      }
      product.ShortDescription = JsonQuery.value(item, JSON_PATHS.Products.ShortDescription) || null;
      product.LongDescription = JsonQuery.value(item, JSON_PATHS.Products.LongDescription) || null;

      const payment = JsonQuery.value(item, JSON_PATHS.Products.Payment) || null;
      if (payment) {
        product.PayPerUse = JsonQuery.value(item, JSON_PATHS.Products.PayPerUse) || false;
      }
      const cost = JsonQuery.value(item, JSON_PATHS.Products.Cost) || null;

      if (cost) {
        product.Interval = JsonQuery.value(item, JSON_PATHS.Products.Interval) || null;
        product.CallSetup = JsonQuery.value(item, JSON_PATHS.Products.CallSetup) || null;
        product.CallSetupCurrerncy = JsonQuery.value(item, JSON_PATHS.Products.CallSetupCurrency) || null;
        product.fee = JsonQuery.value(item, JSON_PATHS.Products.fee) !== undefined &&
          JsonQuery.value(item, JSON_PATHS.Products.fee) !== '' ?
          (JsonQuery.value(item, JSON_PATHS.Products.fee)).toString().replace('.', ',') :
          null;
        product.feeUnit = JsonQuery.value(item, JSON_PATHS.Products.feeUnit) || null;
        if (product.feeUnit) {
          product.feeUnit = product.feeUnit.replace('|', '/');
        }
      }
      const enjoyMore = JsonQuery.value(item, JSON_PATHS.Products.EnjoyMore) || null;

      if (enjoyMore && Object.keys(enjoyMore).length) {
        product.EnjoyMoreRenewalDate = JsonQuery.value(item, JSON_PATHS.Products.EnjoyMoreRenewalDate) || null;
        product.EnjoyMoreIncludedApplicationsNames = new Array<string>();
        JsonQuery.value(item, JSON_PATHS.Products.EnjoyMoreIncludedApplications).map(el => {
          product.EnjoyMoreIncludedApplicationsNames.push(el.name);
        })
      }
      const worryFree = JsonQuery.value(item, JSON_PATHS.Products.WorryFree) || null;
      product.monthlyCurrency = product.feeUnit || null;
      if (worryFree) {
        product.worryFree = new WorryFree()
        product.worryFree.isCurrentlyUsed = JsonQuery.value(item, JSON_PATHS.Products.isCurrentlyUsed);
        product.worryFree.currentStep = JsonQuery.value(item, JSON_PATHS.Products.currentStep) || 0;
        product.worryFree.limit = JsonQuery.value(item, JSON_PATHS.Products.limit) || null;
        product.worryFree.maximumSteps = JsonQuery.value(item, JSON_PATHS.Products.maximumSteps) || 0;
        product.worryFree.totalPricePaid = JsonQuery.value(item, JSON_PATHS.Products.totalPricePaid) || null;
        const remainingStep = JsonQuery.value(item, JSON_PATHS.Products.remainingStep) || null;
        if (remainingStep) {
          product.worryFree.remainingStep = new RemainingStep();
          product.worryFree.remainingStep.name = JsonQuery.value(item, JSON_PATHS.Products.remainingStepName) || null;
          product.worryFree.remainingStep.availableAllowance = JsonQuery.value(item, JSON_PATHS.Products.availableAllowance) || null;
          product.worryFree.remainingStep.availableAllowanceUnitCode =
            JsonQuery.value(item, JSON_PATHS.Products.availableAllowanceUnitCode) ?
              JsonQuery.value(item, JSON_PATHS.Products.availableAllowanceUnitCode).toUpperCase() :
              null;
          if (JSON_PATHS.Products.remainingStepCost) {
            product.worryFree.remainingStep.costOneTime =
              JsonQuery.value(item, JSON_PATHS.Products.remainingStepCostOneTime) || null;
            product.worryFree.remainingStep.costOneTimeCurrency =
              JsonQuery.value(item, JSON_PATHS.Products.remainingStepCostOneTimeCurrency) || null;
          }
          product.worryFree.remainingStep.totalLimit = JsonQuery.value(item, JSON_PATHS.Products.remainingStepTotalLimit) || null;
          product.worryFree.remainingStep.usedAllowance = JsonQuery.value(item, JSON_PATHS.Products.usedAllowance) || null;
          product.worryFree.remainingStep.usedAllowanceUnitCode =
            JsonQuery.value(item, JSON_PATHS.Products.usedAllowanceUnitCode) ?
              JsonQuery.value(item, JSON_PATHS.Products.usedAllowanceUnitCode).toUpperCase() : null;
          product.worryFree.remainingStep.unlimited =
            JsonQuery.value(item, JSON_PATHS.Products.allowanceUnlimited) || false;
          product.worryFree.remainingStep.totalallowance =
            JsonQuery.value(item, JSON_PATHS.Products.remainingStepTotalallowance) || null;
        }
      }

      if (JsonQuery.value(item, JSON_PATHS.Products.onlineTv)) {
        product.onlineTv = new OnlineTv();
        product.onlineTv.hasPendingChannel = JsonQuery.value(item, JSON_PATHS.Products.hasPendingChannel) || null;
        product.onlineTv.group = JsonQuery.value(item, JSON_PATHS.Products.group) || null;
        product.onlineTv.email = JsonQuery.value(item, JSON_PATHS.Products.onlineTvEmail) || null
        let promotions: Promotion[] = new Array<Promotion>();
        if (JsonQuery.value(item, JSON_PATHS.Products.onlineTVPromotions)) {
          promotions = (JsonQuery.value(item, JSON_PATHS.Products.onlineTVPromotions) as any[]).map((el) => {
            const obj = new Promotion();
            obj.fee = JsonQuery.value(el, JSON_PATHS.Products.promotionFee) || null
            obj.feeUnit = JsonQuery.value(el, JSON_PATHS.Products.promotionFeeUnit) || null
            obj.feeNumber = obj.fee ? Number(obj.fee) : 0;
            obj.serviceIds = JsonQuery.value(el, JSON_PATHS.Products.promotionServiceIds) as string[] || null
            obj.status = JsonQuery.value(el, JSON_PATHS.Products.promotionStatus) || null
            obj.code = JsonQuery.value(el, JSON_PATHS.Products.promotionCode) || null
            obj.activationDate = JsonQuery.value(el, JSON_PATHS.Products.promotionActivationDate) || null
            obj.channelCode = JsonQuery.value(el, JSON_PATHS.Products.promotionChannelCode) || null
            obj.name = JsonQuery.value(el, JSON_PATHS.Products.Name) || null
            return obj;
          });
        }
        product.onlineTv.promotions = promotions;
      }
      if (dataSharing) {
        product.isShared = JsonQuery.value(dataSharing, JSON_PATHS.Products.isShared) || false;
      }
      if (JsonQuery.value(item, JSON_PATHS.Products.subscription)) {
        product.sharingDataType = JsonQuery.value(item, JSON_PATHS.Products.dataSharingType) || null;
      }
      product.subType = this.getBundleSubtype(product);
      if ([ProductSubtype.EnjoyMore, ProductSubtype.EnjoyMoreExtra].includes(product.subType)) {
        this.setEnjoyMoreIcons(product);
      }
      const enjoyMorePromotions = JsonQuery.value(item, JSON_PATHS.Products.oneMonthPromotions) || null;
      if (enjoyMorePromotions && _.isArray(enjoyMorePromotions)) {
        product.oneMonthPromotionCode = JsonQuery.value(item, JSON_PATHS.Products.oneMonthPromotionCode) || null;
        product.oneMonthPromotionStatus = JsonQuery.value(item, JSON_PATHS.Products.oneMonthPromotionStatus) || null;
      }
      product.productAlert = JsonQuery.value(item, JSON_PATHS.Products.productAlert);
      product.incompatibilities = JsonQuery.value(item, JSON_PATHS.Products.incompatibilities) || [];

      product.tvDiscounts = JsonQuery.value(item, JSON_PATHS.Products.discount) || null;
      product.discountedFee = product.tvDiscounts ?
        Number(product.tvDiscounts.fee?.replace(',', '.')) : Number(product.fee?.replace(',', '.'));
      product.billinDiscount = JsonQuery.value(item, JSON_PATHS.Products.discount) || null;
      product.billinCustomerAcount = JsonQuery.value(item, JSON_PATHS.Products.customerAccount) || null;
      product.voucherCode = JsonQuery.value(item, JSON_PATHS.Products.voucherCode) || null;
      product.availableShipping = JsonQuery.value(item, JSON_PATHS.Products.availableShipping) || ShippingModesTv.Tecnico;

      this.products.push(product);
    });
    this.setProductTaggingCrossVariables();

  }

  setProductTaggingCrossVariables() {

    const standAloneTvProducts = this.products.filter((item) => {
      return item.ProductType &&
        item.ProductType.toLowerCase() === ProductType.OnlineTvPackage &&
        item.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase();
    });
    if (this.subscriptionService.customerData.currentService.type.toLowerCase() !== ServiceType.Tv.toLowerCase()) {
      if (standAloneTvProducts && standAloneTvProducts.length > 0) {
        this.storageService.productAndServiceVariables.tv_online_status = tagging.staticVariables.tvOnlineStatus.standAlone;
        this.storageService.productAndServiceVariables.tv_online_bundle =
          tagging.staticVariables.onlineTvBundle.standalone.
            replace('{0}', _.join(_.map(standAloneTvProducts, 'Name'), ','));
      } else {
        this.storageService.productAndServiceVariables.tv_online_bundle = tagging.staticVariables.onlineTvBundle.sin;
      }
    }
    const entertainmentProducts = this.products.filter((item) => {
      return item.Type &&
        item.Type.toLowerCase() === BundleType.Entertainment.toLowerCase();
    });
    if (entertainmentProducts && entertainmentProducts.length > 0) {
      this.storageService.productAndServiceVariables.entertaiment_type = _.join(_.map(entertainmentProducts, 'Name'), ',');
    } else {
      this.storageService.productAndServiceVariables.entertaiment_type = '';
    }

    const extraProducts = this.products.filter((item) => {
      return item.ProductType && ((item.ProductType.toLowerCase() === ProductType.Extras.toLowerCase() ||
        item.ProductType.toLowerCase() === ProductType.WorryFree.toLowerCase() ||
        item.ProductType.toLowerCase() === ProductType.OnlineTvPackage.toLowerCase() ||
        item.ProductType.toLowerCase() === ProductType.Content.toLowerCase()) &&
        (item.currentStatus.toLowerCase() !== BundleStatus.Inactive.toLowerCase()))
      });
    if (extraProducts && extraProducts.length > 0) {
      this.storageService.productAndServiceVariables.extra_status = extraProducts.map((item) => {
        this.translateService.get('productsServices').subscribe(data => {
          this.worryFreeName = data.worryFree.vesSectionText;
        });
        let mappedValue = '';
        if (item.ProductType.toLowerCase() === ProductType.WorryFree.toLowerCase()) {
           mappedValue = this.worryFreeName;
        }
        else {
         mappedValue = item.Name ? item.Name : '';

        }
        switch (item.currentStatus.toLowerCase()) {
          case BundleStatus.Active.toLowerCase():
            mappedValue += ':' + tagging.staticVariables.extraStatus.active;
            break;
          case BundleStatus.PendingActivation.toLowerCase():
          case BundleStatus.PendingDeactivation.toLowerCase():
            mappedValue += ':' + tagging.staticVariables.extraStatus.pending;
            break;
        }
        return mappedValue;
      }).join(',')
    } else {
      this.storageService.productAndServiceVariables.extra_status = '';
    }
     const enjoyMoreDataProducts = this.products.filter((item) => {
      return item.ProductType && item.ProductType.toLowerCase() === ProductType.EnjoyMore.toLowerCase() &&
        item.Type && item.Type.toLowerCase() === BundleType.Data && item.ProductCategory &&
         item.ProductCategory.toLowerCase() !== ProductCategory.Adhoc.toLowerCase() && item.currentStatus &&
        (item.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase())
    });

    if (enjoyMoreDataProducts && enjoyMoreDataProducts.length > 0) {
      this.storageService.productAndServiceVariables.special_data_bundles_status = enjoyMoreDataProducts.map((item) => {
        let mappedValue = item.Name ? item.Name : item.Code;
        if (item.oneMonthPromotionStatus && item.oneMonthPromotionStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ) {
          return mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.promotion;
        }
        // Basic
        if (item.ProductCategory.toLowerCase() === ProductCategory.Basic.toLowerCase()) {
          return mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.basic;
        }
        else if (item.ProductCategory.toLowerCase() === ProductCategory.Monthly.toLowerCase() &&
        item.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ) {
          // postpaid
          switch (this.subscriptionService.customerData.currentService.type.toLowerCase()) {
            case ServiceType.Postpaid.toLowerCase() || ServiceType.MbbPostpaid.toLowerCase():
              mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.prepaidWithBenefitOrPostpaid;
              break;
              case (ServiceType.Prepaid.toLowerCase() || ServiceType.MbbPrepaid.toLowerCase()):
              // prepaid with benefit
            if (item.withBenefit === true) {
              mappedValue += ':'  + tagging.staticVariables.specialDataBundlesStatus.prepaidWithBenefitOrPostpaid;
            }
            // prepaid without benefit
            else {
              mappedValue += ':'  + tagging.staticVariables.specialDataBundlesStatus.prepaidWithoutBenefit;
            }
              break;
          }
        }
        return mappedValue;
      }).join(',')
    }
     else {
      this.storageService.productAndServiceVariables.special_data_bundles_status = '';
    }

    if (this.subscriptionService.customerData.currentService.type.toLowerCase() ===
      ServiceType.Tv.toLowerCase()) {
      if (this.storageService.isHoreca) {
        this.storageService.productAndServiceVariables.tv_online_status = tagging.staticVariables.tvOnlineStatus.notv;
        this.storageService.productAndServiceVariables.tv_online_bundle = tagging.staticVariables.onlineTvBundle.sin;
      }
    }
  }
  getProductByProductType(productType) {
    const serviceId = this.storageService.navigateToSecureNetFromBilling ?
    this.storageService.secureNetAlertServiceId :  this.subscriptionService.customerData.currentService.id;
    const customerAccountsId = this.storageService.navigateToSecureNetFromBilling ?
    this.storageService.secureNetAccountId : this.subscriptionService.customerData.customerAccountsId;
    const url = API_URLS.Product.ProductByType.replace('{subscription-id}', serviceId)
      .replace('{customer-account-id}', customerAccountsId).replace('{product-type}', productType);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      this.productLogic(res)
      return res;
    }));
  }
  @evict(cachingKeys.Products, cachingKeys.Consumption, cachingKeys.getCurrentConsumption)
  BuyOrRemoveBundle(id: string, status?: string , promotionCode?: string, email?: string|boolean, withBenefit?: boolean, products?: any[],
     office?: DeliveryOffice, autoinstallment?: boolean): Observable<string> {
    const url = API_URLS.Product.BuyOrRemoveBundle.replace('{id}', id);
    let body = {};
    // in case renew benfits will send in the body benefitsFlag true only
    if (withBenefit) {
      body['benefitsFlag'] = withBenefit
    } else {
      body = {
        'status': {
          'current': status
        }
      };
      if (promotionCode) {
        body['promotion'] = promotionCode
      }
      if (email) {
        body['email'] = email
      }
      if (products) {
        body['products'] = products
      }
      if (autoinstallment) {
        if (office) {
          body['deliveryAddress'] = {'codired' : office.codired, 'officeHours' : office.horario, 'officePhone' : office.telefono, 'deliveryOptions': {deliveryType: COE }};
        }
        else {
          body['deliveryAddress'] = {'deliveryOptions': {deliveryType: B5I }}
        }
      }
    }

    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers,
      responseType: 'text' as 'text'
    };

    return this.http.patch(url, body, options).pipe(map(res => res));
  }
  updateCpe(id) {
    const url = API_URLS.cpe.update.replace('{id}', id);
    let body = {};

    body = {
      'status': {
        'current': 'active'
      }
    };

    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers,
      responseType: 'text' as 'text'
    };

    return this.http.patch(url, body, options).pipe(map(res => res));
  }

  getBundleSubtype(bundle: Product): ProductSubtype {
    if (bundle.ProductType) {
      if (
        bundle.ProductCategory && bundle.ProductCategory.toLocaleLowerCase() ===
        ProductCategory.Basic.toLocaleLowerCase()
      ) {
        switch (bundle.Type.toLocaleLowerCase()) {
          case BundleType.Data.toLocaleLowerCase():
            if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Product.toLocaleLowerCase()
            ) {
              // {{Inclusive data bundle}} Product-type = product (and) type = data (and) product-characteristics.category =	basic
              return ProductSubtype.Data
            } else if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.EnjoyMore.toLocaleLowerCase()
            ) {
              // {{Inclusive data bundle}} Product-type = enjoy-more (and) type = data (and) product-characteristics.category =	basicle);
              return ProductSubtype.EnjoyMore
            }
            break;
          case BundleType.Voice.toLocaleLowerCase():
            if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Product &&
              bundle.CallType &&
              bundle.CallType.toLocaleLowerCase() ===
              CallType.National.toLocaleLowerCase()
            ) {
              // {{Inclusive fix to mobile bundle}} Product-type = product (and) type = Voice
              // (and) product-characteristics.category =	basic  (and) call-type = National
              return ProductSubtype.FixedToMobile;
            } else if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Product &&
              bundle.CallType &&
              bundle.CallType.toLocaleLowerCase() ===
              CallType.International.toLocaleLowerCase()
            ) {
              // {{Inclusive fix to international bundle}} Product-type = product (and) type = Voice
              // (and) product-characteristics.category =	basic (and) call-type = International
              return ProductSubtype.FixedToInternational;
            } else if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Product.toLocaleLowerCase()
            ) {
              // {{Inclusive voice bundle}} Product-type = product (and) type = Voice
              // (and) product-characteristics.category =	basic
              return ProductSubtype.Minutes;
            }
            break;
          case BundleType.SMS.toLocaleLowerCase():
            if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Product.toLocaleLowerCase()
            ) {
              // {{Inclusive sms bundle}} Product-type = product (and) type = sms (and) product-characteristics.category =	basic
              return ProductSubtype.Sms
            }
            break;
          case BundleType.Fixed.toLocaleLowerCase():
            if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Product &&
              bundle.CallType &&
              bundle.CallType.toLocaleLowerCase() ===
              CallType.National.toLocaleLowerCase()
            ) {
              // {{Inclusive fix to fix bundle}} Product-type = product (and) type = fixed
              // (and) product-characteristics.category =	basic  (and) call-type = National
              return ProductSubtype.FixedToFixed;
            }
            break;
          default:
            break;
        }
      } else if (
        bundle.ProductCategory && bundle.ProductCategory.toLocaleLowerCase() ===
        ProductCategory.Monthly.toLocaleLowerCase()
      ) {
        // product-characteristics.category = Monthly
        switch (bundle.Type.toLocaleLowerCase()) {
          case BundleType.Data.toLocaleLowerCase():
            if (
              bundle.ProductType && bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Extras.toLocaleLowerCase() &&
              (bundle.currentStatus.toLowerCase() ===
                BundleStatus.Active.toLowerCase() ||
                bundle.currentStatus.toLowerCase() ===
                BundleStatus.PendingDeactivation.toLowerCase())
            ) {
              // {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
              return ProductSubtype.Extra;
            } else if (
              bundle.ProductType && bundle.ProductType.toLocaleLowerCase() ===
              ProductType.EnjoyMore.toLocaleLowerCase()
            ) {
              // {{Inclusive Extra enjoy more}} Product-type = enjoy-more (and) type = data
              // (and) product-characteristics.category =	Monthly
              return ProductSubtype.EnjoyMoreExtra;
            }
            break;
          case BundleType.Voice.toLocaleLowerCase():
            if (
              bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Extras.toLocaleLowerCase() &&
              (bundle.currentStatus.toLowerCase() ===
                BundleStatus.Active.toLowerCase() ||
                bundle.currentStatus.toLowerCase() ===
                BundleStatus.PendingDeactivation.toLowerCase())
            ) {
              // {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
              return ProductSubtype.Extra;
            }
            break;
          case BundleType.Entertainment.toLocaleLowerCase():
            if (
              (bundle.currentStatus && bundle.currentStatus.toLowerCase() ===
                BundleStatus.Active.toLowerCase() ||
                bundle.currentStatus.toLowerCase() ===
                BundleStatus.PendingDeactivation.toLowerCase())
            ) {
              return ProductSubtype.Extra;
            }

            break;
          case BundleType.SMS.toLocaleLowerCase():
            if (
              bundle.ProductType && bundle.ProductType.toLocaleLowerCase() ===
              ProductType.Extras.toLocaleLowerCase() &&
              (bundle.currentStatus.toLowerCase() ===
                BundleStatus.Active.toLowerCase() ||
                bundle.currentStatus.toLowerCase() ===
                BundleStatus.PendingDeactivation.toLowerCase())
            ) {
              // {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
              return ProductSubtype.Extra;
            }
            break;
          default:
            break;
        }
      } else if (
        (bundle.ProductCategory && bundle.ProductCategory.toLocaleLowerCase() ===
        ProductCategory.Adhoc.toLocaleLowerCase())
        || (bundle.ProductCategory && bundle.ProductCategory.toLocaleLowerCase() ===
        ProductCategory.Accumulate.toLocaleLowerCase())
      ) {
        // product-characteristics.category = Adhoc
        if (
          (bundle.Type.toLocaleLowerCase() ===
            BundleType.Data.toLocaleLowerCase() || bundle.Type.toLocaleLowerCase() ===
            BundleType.Voice.toLocaleLowerCase()) &&
          bundle.ProductType.toLocaleLowerCase() ===
          ProductType.Extras.toLocaleLowerCase() &&
          (bundle.currentStatus.toLowerCase() ===
            BundleStatus.Active.toLowerCase() ||
            bundle.currentStatus.toLowerCase() ===
            BundleStatus.PendingDeactivation.toLowerCase())
        ) {
          // {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
          return ProductSubtype.Extra;
        }
      }
    }
  }
  resetBundleSubtypeForMbbHoliday(bundle: Product) {
    if (
      bundle.ProductCategory && bundle.ProductCategory.toLocaleLowerCase() ===
      ProductCategory.Monthly.toLocaleLowerCase()
    ) {
      // product-characteristics.category = Monthly
      switch (bundle.Type.toLocaleLowerCase()) {
        case BundleType.Data.toLocaleLowerCase():
          if (
            bundle.ProductType && bundle.ProductType.toLocaleLowerCase() ===
            ProductType.Extras.toLocaleLowerCase() &&
            (bundle.currentStatus.toLowerCase() ===
              BundleStatus.Active.toLowerCase() ||
              bundle.currentStatus.toLowerCase() ===
              BundleStatus.PendingDeactivation.toLowerCase())
          ) {
            // {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
            bundle.subType = ProductSubtype.Extra
          } else if (
            bundle.ProductType && bundle.ProductType.toLocaleLowerCase() ===
            ProductType.EnjoyMore.toLocaleLowerCase()
          ) {
            // {{Inclusive Extra enjoy more}} Product-type = enjoy-more (and) type = data
            // (and) product-characteristics.category =	Monthly
            bundle.subType = ProductSubtype.EnjoyMoreExtra;
          }
          break;
        case BundleType.Voice.toLocaleLowerCase():
          if (
            bundle.ProductType.toLocaleLowerCase() ===
            ProductType.Extras.toLocaleLowerCase() &&
            (bundle.currentStatus.toLowerCase() ===
              BundleStatus.Active.toLowerCase() ||
              bundle.currentStatus.toLowerCase() ===
              BundleStatus.PendingDeactivation.toLowerCase())
          ) {
            // {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
            bundle.subType = ProductSubtype.Extra;
          }
          break;
        case BundleType.Entertainment.toLocaleLowerCase():
          if (
            (bundle.currentStatus && bundle.currentStatus.toLowerCase() ===
              BundleStatus.Active.toLowerCase() ||
              bundle.currentStatus.toLowerCase() ===
              BundleStatus.PendingDeactivation.toLowerCase())
          ) {
            bundle.subType = ProductSubtype.Extra;
          }

          break;
        case BundleType.SMS.toLocaleLowerCase():
          if (
            bundle.ProductType && bundle.ProductType.toLocaleLowerCase() ===
            ProductType.Extras.toLocaleLowerCase() &&
            (bundle.currentStatus.toLowerCase() ===
              BundleStatus.Active.toLowerCase() ||
              bundle.currentStatus.toLowerCase() ===
              BundleStatus.PendingDeactivation.toLowerCase())
          ) {
            // {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
            bundle.subType = ProductSubtype.Extra
          }
          break;
        default:
          break;
      }
    } else if (
      bundle.ProductCategory && bundle.ProductCategory.toLocaleLowerCase() ===
      ProductCategory.Adhoc.toLocaleLowerCase()
    ) {
      // product-characteristics.category = Adhoc
      if (
        bundle.Type.toLocaleLowerCase() ===
        BundleType.Data.toLocaleLowerCase() &&
        bundle.ProductType.toLocaleLowerCase() ===
        ProductType.Extras.toLocaleLowerCase() &&
        (bundle.currentStatus.toLowerCase() ===
          BundleStatus.Active.toLowerCase() ||
          bundle.currentStatus.toLowerCase() ===
          BundleStatus.PendingDeactivation.toLowerCase())
      ) {
        // {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
        bundle.subType = ProductSubtype.Extra;
      }
    }
  }

  setEnjoyMoreIcons(product: Product) {
    this.translateService.get('productsServices').subscribe(data => {
      const enjoyMoreId = product.Code.split('_')[0];
      if (this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()) {
        product.enjoyMoreActiveIcon = data.enjoyMore.prepaidPasses[enjoyMoreId] ?
          data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_active_icon`] ?
            data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_active_icon`]['url'] : constants.defaultEnjoyMoreIcon
          : constants.defaultEnjoyMoreIcon
        product.enjoyMoreInactiveIcon = data.enjoyMore.prepaidPasses[enjoyMoreId] ?
          data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_inactive_icon`] ?
            data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_inactive_icon`]['url'] : constants.defaultEnjoyMoreIcon
          : constants.defaultEnjoyMoreIcon
        product.enjoyMoreInactiveIcon = data.enjoyMore.prepaidPasses[enjoyMoreId] ?
          data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_black_icon`] ?
            data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_black_icon`]['url'] : constants.defaultEnjoyMoreIcon
          : constants.defaultEnjoyMoreIcon
      }
      else {
        product.enjoyMoreActiveIcon = data.enjoyMore.passes[enjoyMoreId] ?
          data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_active_icon`] ?
            data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_active_icon`]['url'] : constants.defaultEnjoyMoreIcon
          : constants.defaultEnjoyMoreIcon
        product.enjoyMoreInactiveIcon = data.enjoyMore.passes[enjoyMoreId] ?
          data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_inactive_icon`] ?
            data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_inactive_icon`]['url'] : constants.defaultEnjoyMoreIcon
          : constants.defaultEnjoyMoreIcon
        product.enjoyMoreInactiveIcon = data.enjoyMore.passes[enjoyMoreId] ?
          data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_black_icon`] ?
            data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_black_icon`]['url'] : constants.defaultEnjoyMoreIcon
          : constants.defaultEnjoyMoreIcon
      }
    })
  }

  GetAllProductsOnlyPostPaid() {
    // tslint:disable-next-line: max-line-length
    const mobilePostPaid: ServiceModel[] = this.subscriptionService.serviceListSite.filter((el) => el.type.toUpperCase() === ServiceType.Postpaid.toUpperCase());
    if (!mobilePostPaid || mobilePostPaid.length === 0) {
      mobilePostPaid[0] = this.subscriptionService.customerData.currentService;
    }

    const url = API_URLS.Product.Products.replace('{subscription-id}', mobilePostPaid[0].id)
    .replace('{customer-account-id}', this.subscriptionService.customerData.customerAccountsId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      this.productLogic(res)
      return res;
    }));
  }

    /**get currency unit from API */
  getCurrencyCode(monthlyRecurringCurrency: string): string {
    if (monthlyRecurringCurrency && monthlyRecurringCurrency.indexOf('/') !== -1) {
      return monthlyRecurringCurrency.trim().substr(0, monthlyRecurringCurrency.indexOf('/'));
    }
    else {
      return monthlyRecurringCurrency;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '../../../app.service';
import { AppThemes, journeyTags, marketNames } from '../../constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { CrossVariables, TaggingViewModel } from '../../../models/tagging.model';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { config } from '../../../../../src/config/pages-config';
import * as constants from '../../../shared/constants/defines';
import { Router } from '@angular/router';

@Component({
  selector: 'sp-xr-contents',
  templateUrl: './xr-contents.component.html',
  styleUrls: ['./xr-contents.component.scss']
})
export class XrContentsComponent implements OnInit {

  private previousTheme: string;
  public cardOfferFlag: boolean = false;
  public characteristicsList: { text: string, icon: string }[] = [];
  public MarketNames: typeof marketNames = marketNames;

  constructor(
    private appService: AppService,
    private location: Location,
    private translate: TranslateService,
    private tagging: TaggingHelperService,
    private utility: UtilitiesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.appService.hiddenHeader = true;
    this.previousTheme = this.appService.theme;
    this.appService.theme = AppThemes.ThemeBkgWhite;
    this.loadXrContents();
    this.loadCardOffer();
    this.tagView();
  }

  loadXrContents(): void {
    this.translate.get('v10.productsServices.xrContents').subscribe( data => {
      this.characteristicsList = data.characteristics;
    });
  }

  public navigateToMobileStore(typeOfStore: string): void {
    const tagObject: TaggingViewModel = Object.assign({}, tagging.dashboard.xrContents.detailClick);
    const isPrepaid: string = this.utility.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
    tagObject.journey_category = CrossVariables.client_typology;
    tagObject.journey_subcategory = isPrepaid;
    tagObject.event_name = `click en ${typeOfStore}`;

    // Apple store
    if (typeOfStore === marketNames.appStore) {
      window.open(this.translate.instant('v10.productsServices.xrContents.appStoreUrl'));
    }
    // Google play store
    else {
      window.open(this.translate.instant('v10.productsServices.xrContents.playStoreUrl'));
    }
    const eventName: string = `${tagObject.page_name}:${tagObject.event_name}`;
    this.tagging.trackReplace(eventName, tagObject, true);
  }

  private tagView(): void {
    const tagObject: TaggingViewModel = Object.assign({}, tagging.dashboard.xrContents.detailView);
    const isPrepaid: string = this.utility.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
    tagObject.journey_category = CrossVariables.client_typology;
    tagObject.journey_subcategory = isPrepaid;
    this.tagging.viewReplace(true, {}, tagObject);
  }

  loadCardOffer(): void {
    const data: string = this.translate.instant('v10.productsServices.xrContents.banner.flag');
    this.cardOfferFlag = data.toLowerCase() === constants.stringOfTrue ? true : false;
    if (this.cardOfferFlag) {
      this.bannerTagView();
    }
  }

  private bannerTagView(): void {
    const tagObject: TaggingViewModel = Object.assign({}, tagging.dashboard.xrContents.bannerView);
    const epListBanner: TaggingViewModel = tagging.dashboard.xrContents.bannerClick;
    tagObject.entrypoint_list = tagObject.entrypoint_list
      .replace('{es}', epListBanner.entrypoint_section)
      .replace('{el}', epListBanner.entrypoint_location)
      .replace('{jn}', epListBanner.journey_name)
      .replace('{et}', epListBanner.entrypoint_title);
    this.tagging.viewReplace(true, {}, tagObject);
  }

  navigateToCheckout(): void {
    const tagObject: TaggingViewModel = Object.assign({}, tagging.dashboard.xrContents.bannerClick);
    const isPrepaid: string = this.utility.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
    tagObject.event_label = this.translate.instant('v10.productsServices.xrContents.banner.button');
    tagObject.journey_category = CrossVariables.client_typology;
    tagObject.journey_subcategory = isPrepaid;
    const eventName: string = `${tagObject.page_name}:${tagObject.event_name}`;
    this.tagging.trackReplace(eventName, tagObject, true);

    const sapId: string = this.translate.instant('v10.productsServices.xrContents.banner.IDSAP');
    this.router.navigate([config.CommercialMobile.landing.route], { queryParams: { sap: sapId } });
  }

  public goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.appService.theme = this.previousTheme;
    this.appService.hiddenHeader = false;
  }
}


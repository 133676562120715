import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpAccordionRadioButtonComponent } from './sp-accordion-radio-button/sp-accordion-radio-button.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SpServiceSelectorModalComponent } from './sp-service-selector-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SpRadioButtonAdvancedModule } from '../sp-radio-button-advanced/sp-radio-button-advanced.module';



@NgModule({
  declarations: [SpServiceSelectorModalComponent, SpAccordionRadioButtonComponent],
  exports: [SpServiceSelectorModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    TabsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    SpRadioButtonAdvancedModule
  ]
})
export class SpServiceSelectorModalModule { }

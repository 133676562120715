import { Component, OnInit, ViewChild } from '@angular/core';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { TopUpService } from '../top-up.service';
import { vodafoneEsRecarga } from '../../shared/constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '../../shared/enums/language.enum';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { StorageService } from '../../core/services/storage.service';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { Breakpoints } from '@mva10/mva10-angular';

@Component({
  selector: 'sp-top-up-payment-ticket',
  templateUrl: './top-up-payment-ticket.component.html',
  styleUrls: ['./top-up-payment-ticket.component.scss']
})
export class TopUpPaymentTicketComponent implements OnInit {
  @ViewChild('ticketCanvas', { static: true }) canvas: any;
  @ViewChild('tickCanvas', { static: true }) tickCanvas: any;
  ticketDetails: { title: string; text: string; leftSpaceInDownloadedImage: number }[]
    = new Array<{ title: string, text: string, leftSpaceInDownloadedImage: number }>();
  topSpaceInDownoadedImage = 115;
  canvasX = 16;
  ticketTitle = '';
  downloadTicketBtnText = '';
  closeBtnText = '';
  /** isDesktopView should be true in case of desktop view */
  isDesktopView = false;
  /** showDownloadedToast to show a toast after download ticket for 3 seconds */
  showDownloadedToast = false;
  /** hideDownloadedToast to hide a toast by default, once it shown then css classes will handle it */
  hideDownloadedToast = true;
  /** source of tick img in downloadtoast */
  tickImgSrc: string = '';

  constructor(public trayService: SpMva10OverlayService,
    public topupService: TopUpService,
    private translate: TranslateService,
    private tagging: TaggingHelperService,
    private storageService: StorageService,
    private dynamicTrayService: DynamicTrayService) { }

  ngOnInit() {
    this.setTrayValues();
    this.sendTagging();
    this.drawTicketCanvas();
    this.drawTickImageInDownloadToast();
    this.getWCSAndFillScreenValues();
  }

  /**
   * Get WCS data and fill screen values
   */
  getWCSAndFillScreenValues(): void {
    this.translate.get('payment').subscribe(data => {
      const itemsList: any = data.itemsList;
      this.ticketTitle = this.isSpanish() ? itemsList.paymentTicketTitle.body : itemsList.paymentTicketTitle_en.body;
      this.downloadTicketBtnText = this.isSpanish() ?
        data.buttonList.downloadAsImageButton.text : data.buttonList.downloadAsImageButton_en.text;
      this.closeBtnText = this.isSpanish() ? data.buttonList.closeButton.text : data.buttonList.closeButton_en.text
      this.setPrepaidTicketValues(itemsList);

    });
  }
  /**
   * set tray values for mobile and desktop views
   */
  setTrayValues(): void {
    if (this.dynamicTrayService.trayData) {
      this.dynamicTrayService.trayData.isContentBodyFullHeight = true;
      /** set flag to change download button text */
      this.isDesktopView = window.innerWidth >= Breakpoints.DESKTOP;
      /** isMobileView should be true in case of mobile view */
      const isMobileView: boolean = window.innerWidth < Breakpoints.TABLET;
      /** open the tray with bold title if not mobile view */
      this.dynamicTrayService.trayData.isBoldTitle = !isMobileView;
      this.dynamicTrayService.trayData.preventTrayInnerScrolling = false;
      this.dynamicTrayService.trayData.removeTrayPadding = true;
      this.dynamicTrayService.trayData.closeButtonAction = this.closeTray.bind(this);
    }
  }
  /**
   * Send state tagging
   */
  sendTagging(): void {
    this.tagging.view(tagging.pciPrepaidPages.paymentTicketPage, this.storageService.topupPrepaid);
  }
  /**
   * Return true if language is Spanish, otherwise false
   */
  isSpanish(): boolean {
    return this.topupService.topupLanguage === LanguageEnum.spanish;
  }
  /**
   * Push item of ticket for prepaid payment
   * @param spanishTitle title on left
   * @param text the value of title
   * @param leftSpaceInDownloadedImage space for downloaded image
   */
  pushPrepaidItem(spanishTitle: string, englishTitle: string, text: string, leftSpaceInDownloadedImage: number): void {
    this.ticketDetails.push({ title: this.isSpanish() ? spanishTitle : englishTitle, text, leftSpaceInDownloadedImage });
  }
  /**
   * Set all prepaid ticket values
   * @param itemsList WCS data
   */
  setPrepaidTicketValues(itemsList: any): void {
    this.pushPrepaidItem(itemsList.companyTitle.body, itemsList.companyTitle_en.body, itemsList.companyDesc.body, 90);

    this.pushPrepaidItem(itemsList.paymentTypeTitle.body, itemsList.paymentTypeTitle_en.body,
      this.isSpanish() ? itemsList.paymentTypeDesc.body : itemsList.paymentTypeDesc_en.body, 87);

    this.pushPrepaidItem(itemsList.paymentIdTitle.body, itemsList.paymentIdTitle_en.body, this.topupService.transactionId, 92);

    this.pushPrepaidItem(itemsList.dateAndTimeTitle.body, itemsList.dateAndTimeTitle_en.body, this.topupService.transactionDate, 107);

    this.pushPrepaidItem(itemsList.cardNumberTitle.body, itemsList.cardNumberTitle_en.body,
      '******' + this.topupService.cardMask.slice(6), 145);

    this.pushPrepaidItem(itemsList.rechargeAmountTitle.body, itemsList.rechargeAmountTitle_en.body,
      this.topupService.curruentSelectedAmountSelectorValue + ' €', 75);

    if (this.topupService.isOpenedFromLoginTabs || !this.topupService.isAnonymousProcess) {
      this.topupService.showSecondCTA = true;
    }
    this.pushPrepaidItem(itemsList.rechargenumber.body, itemsList.rechargenumber_en.body,
      this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id : this.topupService.msisdn, 148);
  }

  drawTicketCanvas() {
    const image = new Image();
    const canvas = this.canvas.nativeElement as HTMLCanvasElement;
    canvas.width = 328;
    canvas.height = 332;
    const context = canvas.getContext('2d');
    image.addEventListener('load', () => {
      /** Draw ticket image */
      context.drawImage(image, 0, 0, 328, 332);
      this.drawCanvasTitleText(this.ticketTitle, context)
      this.drawCanvasDetailsList(context);

      /** Draw ☑ image */
      const paymentImage: HTMLImageElement = new Image();
      paymentImage.addEventListener('load', () => {
        context.drawImage(paymentImage, 12, 12, 48, 48);
      });
      paymentImage.src = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-tick-hifi-light-theme@2x.png';
    });
    image.src = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ticket@2x.png'
  }
  /** Draw tick icon in green download toast */
  drawTickImageInDownloadToast(): void {
    const image: HTMLImageElement = new Image();
    image.src = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/small@3x.png';
    const canvas: HTMLCanvasElement = this.tickCanvas.nativeElement as HTMLCanvasElement;
    canvas.width = 28 * window.devicePixelRatio;
    canvas.height = 28 * window.devicePixelRatio;
    canvas.style.width = '28px';
    canvas.style.height = '28px';
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    image.addEventListener('load', () => {
      context.drawImage(image, 0, 0, 28 * window.devicePixelRatio, 28 * window.devicePixelRatio);
    });
  }

  sendTaggingClickOnXInteraction() {
    const pageTaggingName = tagging.pciPrepaidPages.paymentTicketPage.page_name;
    const eventName = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName.replace('<page_name>', pageTaggingName);
    this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
      tagging.pciPrepaidPages.paymentTicketPage);
  }

  closeTrayWithTagging() {
    this.sendTaggingClickOnXInteraction();
    this.closeTray();
  }

  drawCanvasDetailsList(context) {
    this.ticketDetails.forEach(item => {
      context.font = '16px VodafoneRgBd'
      context.fillText(item.title, this.canvasX, this.topSpaceInDownoadedImage);
      context.font = '16px vodafone-rg';
      context.fillText(item.text, item.leftSpaceInDownloadedImage, this.topSpaceInDownoadedImage);
      this.topSpaceInDownoadedImage += 28;

    })
  }

  downLoadTicket() {
    this.tickImgSrc = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/small@3x.png';
    const link = document.createElement('a');
    link.download = 'payment-ticket.png';
    link.href = this.canvas.nativeElement.toDataURL()
    link.click();
    this.showToastAndHide();
    this.tagging.track(tagging.pciPrepaid.interactions.clickSavePhoto.eventName,
      tagging.pciPrepaid.interactions.clickSavePhoto.data,
      tagging.pciPrepaidPages.paymentTicketPage, this.storageService.topupPrepaid)
  }

  /**
   * show the toast for 3 seconds
   */
  showToastAndHide(): void {
    this.hideDownloadedToast = false;
    this.showDownloadedToast = true;
    setTimeout(() => {
      this.showDownloadedToast = false;
      setTimeout(() => {
        this.hideDownloadedToast = true;
      }, 500);
    }, 3000);
  }
  closeTray() {
    this.trayService.close();
    this.dynamicTrayService.closeTraySubject.next();
    if (this.topupService.isAnonymousProcess && this.topupService.isRedirected) {
      window.open(vodafoneEsRecarga, '_self');
    }
  }

  drawCanvasTitleText(text: string, context) {
    const wordList = text.split(' ');
    let line = '';
    let y = 40;
    context.font = '18px vodafone-rg';
    context.fillStyle = '#3a3a3a';
    wordList.forEach((word, index) => {
      if (line.length <= 22) {
        line = index === 0 ? line + word : line + ' ' + word;
      } else {
        const newLineWords = line.split(' ');
        const lastWord = newLineWords.splice(newLineWords.length - 1, 1);
        line = newLineWords.join(' ');
        context.fillText(line, 70, y);
        line = lastWord + ' ' + word;
        y += 22;
      }

      if (index === wordList.length - 1) {
        context.fillText(line, 70, y);
      }

    })
  }
}

export class BillingAddress {
    public doorNumber?: string;
    public buildingNumber?: string;
    public buildingName?: string;
    public level?: string;
    public street?: string;
    public town?: string;
    public country?: string;
    public postcode?: string;
    public formattedAddress?: string;
}


<div class='card-contanier'>
  <div class="left-side-card">
    <span class="line-one">{{firstLine}}</span>
    <span class="line-two">{{secondLine}}</span>
    <span class="status" [ngClass]="
    {'active':green, 'desactive':red, pending:yellow}">{{stautsText}}</span>
  </div>
  <div class="right-side-card">
    <sp-toggle-button  *ngIf="showToggle && !ispendingSBA" [check]='checkToggle'
      (option)="changeStatus($event)"></sp-toggle-button>
  </div>
</div>


<sp-modal-full [imgSrc]='fullModal.imgSrc' [spinner]='showConfigurationSpinner' (xButtonClicked)='drawComponent()'
  [hideX]="showConfigurationSpinner" #fullModal>
  <div *ngIf="showConfigurationSpinner" class="billing-spinnerContainer">
    <div class="loader"></div>
    <p>{{'common.messagesList.overlayLoadingMessage.overlayLoadingMessage_description'|translate}}</p>
  </div>
</sp-modal-full>
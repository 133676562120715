
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../../shared/constants/routes-config';
import { PersonalModel, SavePersonalDataReponse } from '../../models/buy-sim-personal-data.model';
import { LegalTermsItemPostModel, LegalTermsSaveResponse } from '../../models/buy-sim-legal-terms-item-post.model';
import { SuggestedAddressModel } from '../../models/buy-sim-suggested-address.model';
import { saveOfferReqBody, legalTermsPageName } from '../../shared/constants/defines';
import { Observable } from 'rxjs';
import { OperatorListResponse } from '../../models/buy-sim-operator-model';
import { LegalTermsListResponse } from '../../models/buy-sim-legal-terms-item.model';

@Injectable({
    providedIn: 'root'
  })
export class BuySimDataService {
    pageName: string;
    constructor(private http: HttpClient) { }

    /** api call for create shopping cart */
    createShoppingCart(tariffName: string, registerType: string): Observable<any> {
        const url: string = API_URLS.Ntol.createShoppingCart.replace('{0}', tariffName).replace('{1}', registerType);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.get(url, options).pipe(map(res => res));
    }

    /** gets shopping cart data */
    getShoppingCart(): Observable<any> {
        const url: string = API_URLS.Ntol.getShoppingCart;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options: any = {
            headers: headers,
            withCredentials: true
        };
        return this.http.get(url, options).pipe(map(res => res));
    }

    /** api call for save offer data */
    saveOffer(offerReqBody: typeof saveOfferReqBody): Observable<Object> {
        const url = API_URLS.Ntol.saveOffer;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.post(url, offerReqBody, options).pipe(map(res => res));
    }

    /** api call for savepersonal data */
    savePersonalData(personalData: PersonalModel): Observable<SavePersonalDataReponse> {
        const url = API_URLS.Ntol.savePersonalData;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.post(url, personalData, options).pipe(map((res: SavePersonalDataReponse) => res));
    }

    /** api call for getting legal terms data */
    getLegalTerms(pageName: string): Observable<LegalTermsListResponse> {
        this.pageName = pageName;
        const url: string = API_URLS.Ntol.legalTerms.replace('{0}', pageName);
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, {
            headers: headers,
            withCredentials: true
          }).pipe(map((res: LegalTermsListResponse) => res));
    }

    /** api call for saving user entry of legal terms */
    saveLegalTerms(legalTermsList: LegalTermsItemPostModel[], orderId: string): Observable<LegalTermsSaveResponse> {
        const url = API_URLS.Ntol.saveLegalTerms;
        let body: any;
        body = {
            idPedido: (this.pageName === legalTermsPageName.payment ? Number(orderId) : undefined),
            legalterms: legalTermsList
        };
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.post(url, body, options).pipe(map((res: LegalTermsSaveResponse) => res));
    }

    /** api call for validating id  */
    validateId(id: string, idType: string) {
        const url = API_URLS.Ntol.validateId;
        const body = {
            identificationType: idType,
            numberDoc: id
        };
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.post(url, body, options).pipe(map(res => res));
    }

    /** api call for get nationalities */
    getNationalityList() {
        const url: string = API_URLS.Ntol.getNationalities;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options: any = {
            headers: headers,
            withCredentials: true
        };
       return this.http.get(url, options).pipe(map(res => res));
    }

    getProvincList() {
        const url = API_URLS.Ntol.getProvinces;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.get(url, options).pipe(map(res => res));
    }

    getNewSuggestGeocoder(address: string) {
        const url = API_URLS.Ntol.getNewSuggestGeocoder.replace('{0}', address);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.get(url, options).pipe(map(res => res));
    }

    getNewGeocoder(streetNumber: string, selectedAddress: SuggestedAddressModel) {
        const url = API_URLS.Ntol.getNewGeocoder.replace('{0}', selectedAddress.provinceId)
            .replace('{1}', selectedAddress.cityId)
            .replace('{2}', selectedAddress.streetId)
            .replace('{3}', streetNumber || 'S')
            .replace('{4}', selectedAddress.postalCode);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.get(url, options).pipe(map(res => res));
    }

    getDeliveryDates(postalCode: string) {
        const url = API_URLS.Ntol.getDeliveryDates.replace('{0}', postalCode);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.get(url, options).pipe(map(res => res));
    }

    submitAddressInfo(requestBody) {
        const url = API_URLS.Ntol.submitDeliveryInfo;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.post(url, requestBody, options).pipe(map(res => res));
    }

    submitOrder(requestBody) {
        const url = API_URLS.Ntol.submitOrder;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            withCredentials: true
        };
        return this.http.post(url, requestBody, options).pipe(map(res => res));
    }

    /** api to get transaction id */
    findByToken() {
            const url = API_URLS.Ntol.findByToken;
            let headers = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            const options = {
                headers: headers,
                withCredentials: true
            };
            return this.http.get(url, options).pipe(map(res => res));
        }
    /**get operators list */
    getOperatorsList(): Observable<OperatorListResponse> {
        const url: string = API_URLS.Ntol.getOperators;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(url, {
            headers: headers,
            withCredentials: true
          }).pipe(map((res: OperatorListResponse) => res));
    }
}

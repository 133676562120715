<div class="time-line-container">
  <div class="list-container">
    <div class="list-item" *ngFor="let item of itemList; let i = index" [ngClass]="{'red-border': item.redBorderToNextPoint}">
        <div  class="item-title-container" [ngClass]="{'description-red-border': item.redBorderToNextDescription}">
          <div  [ngClass]="item.bulletType"></div>
          <p [ngClass]="item.fontType || ''">{{item.title}}</p>
        </div>
        <div class="descriptions-container" [ngClass]="{'hide-content': i === itemList.length -1, 'description-red-border': description.redBorderToNextDescription}"  *ngFor="let description of item.descriptions; let j = index">
          <div *ngIf="description.hasBullet" [ngClass]="description.bulletType"></div>
          <p [ngClass]="description.fontType || ''" *ngIf="i !== itemList.length -1" class="description-text">{{description.text}}</p>
        </div>
      </div>
  </div>
  <div class="last-description">
    <p class="description-text" *ngIf="itemList[itemList.length - 1]?.descriptions.length > 0">{{itemList[itemList.length - 1]?.descriptions[0].text}}</p>
  </div>
</div>
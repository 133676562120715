import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sp-tile-image-cta',
  templateUrl: './tile-image-cta.component.html',
  styleUrls: ['./tile-image-cta.component.scss']
})
export class TileImageCtaComponent  {
  @Input() image: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() ctaText: string;

  @Output() ctaClick: EventEmitter<void>;

  constructor(public translate: TranslateService) {
    this.ctaClick = new EventEmitter();
  }

  onCtaClick(): void {
    this.ctaClick.emit();
  }
}

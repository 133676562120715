import {
  SharedServicesPegaReplacerError,
  SharedServicesPegaReplacerReplaceFromSourceOptions,
  SharedServicesPegaReplacerTokensMap,
} from '../../entity/shared-services-pega-replacer.entity';

import { sharedServicesPegaReplacerCreateToken } from '../create-replacer-token/shared-services-pega-replacer-create-token.util';
import { sharedServicesPegaReplacerCreatePatternFromToken } from '../pattern-from-token/shared-services-pega-replacer-pattern-from-token.util';

export function sharedServicesPegaReplacerReplaceFromSource(
  options: SharedServicesPegaReplacerReplaceFromSourceOptions
): string {
  if (sharedServicesPegaReplacerSourceIsMap(options.source)) {
    return sharedServicesPegaReplacerReplaceFromSourceMap(options);
  }

  if (sharedServicesPegaReplacerSourceIsObject(options.source)) {
    return sharedServicesPegaReplacerReplaceFromSourceObject(options);
  }

  throw new Error(SharedServicesPegaReplacerError.ReplacerInvalidSource);
}

export function sharedServicesPegaReplacerReplaceFromSourceMap(
  options: SharedServicesPegaReplacerReplaceFromSourceOptions
): string {
  if (!options.template) {
    throw new Error(SharedServicesPegaReplacerError.ReplacerInvalidTemplate);
  }

  if (!options.source) {
    throw new Error(SharedServicesPegaReplacerError.ReplacerInvalidSource);
  }

  let fromTemplate: string = options.template;

  const tokens: SharedServicesPegaReplacerTokensMap = sharedServicesPegaReplacerCreateToken(options.tokens || '{+}');

  (options.source as Map<string, string>).forEach((value, key) => {
    const pattern: RegExp = sharedServicesPegaReplacerCreatePatternFromToken(tokens, key);

    fromTemplate = fromTemplate.replace(pattern, value);
  });

  return fromTemplate;
}

export function sharedServicesPegaReplacerReplaceFromSourceObject(
  options: SharedServicesPegaReplacerReplaceFromSourceOptions
): string {
  if (!options.template) {
    throw new Error(SharedServicesPegaReplacerError.ReplacerInvalidTemplate);
  }

  if (!options.source) {
    throw new Error(SharedServicesPegaReplacerError.ReplacerInvalidSource);
  }

  let fromTemplate: string = options.template;

  const tokens: SharedServicesPegaReplacerTokensMap = sharedServicesPegaReplacerCreateToken(options.tokens || '{+}');

  for (const key of Object.keys(options.source as Record<string, string>)) {
    const pattern: RegExp = sharedServicesPegaReplacerCreatePatternFromToken(tokens, key);

    fromTemplate = fromTemplate.replace(pattern, options.source[key]);
  }

  return fromTemplate;
}

export function sharedServicesPegaReplacerSourceIsMap<T>(v: unknown): v is T {
  return v instanceof Map;
}

export function sharedServicesPegaReplacerSourceIsObject<T>(v: unknown): v is T {
  return typeof v === 'object' && !(v instanceof Map);
}

<mva10-c-modal class="total-amount-overlay-parent" [show]="true" (closeBtnClicked)="hideTotalAmountOverlay()" >
    <div class="total-amount-overlay">
      <div >
        <div>
          <img class="total-amount-overlay__img" [src]="totalAmountOverlayImagePath || 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/group14@3x.jpg'" />
        </div>
        <p class="total-amount-overlay__message" [innerHtml]="totalAmountOverlayDescription"></p>
      </div>
      <div>
        <div class="btn-container">
          <mva10-c-button (click)="openPaymentPage()" [body]="'v10.payment.messagesList.showDebtTotalOverlay.button1' | translate" [styleClass]="'mva10-c-button--primary'">
          </mva10-c-button>
        </div>
      </div>
    </div>
  </mva10-c-modal>
<div class="generic-accordion">
  <div *ngIf="accordion.mainTitle" class="title">
    {{accordion.mainTitle}}
  </div>
  <accordion>
    <accordion-group #group>

      <div accordion-heading>
        <div *ngIf="!accordion.url" class="item">
          <mva10-c-icon *ngIf="accordion.icon" iconId="{{accordion.icon}}" size="32" [ngClass]="'mva10-u--mr2x mva10-u-color--anthracite'"></mva10-c-icon>
          <span *ngIf="!accordion.url" class="item-text" [ngClass]="{'item-text--f-70': accordion.icon}">{{accordion.accordionTitle}}</span>
          <i *ngIf="!accordion.url" class=" icon"
            [ngClass]="{'icon-chevron-up': group?.isOpen, 'icon-chevron-down': !group?.isOpen}"></i>
        </div>
        <div *ngIf="!lastItem" class="separator">
          <hr *ngIf="!group?.isOpen">
        </div>
      </div>
      <div *ngIf="accordion.htmlContent" class="description" [innerHtml]="accordion.htmlContent | safe: 'html'"></div>
      <hr class="group-open-hr" *ngIf="group?.isOpen">
    </accordion-group>
  </accordion>
  <div *ngIf="accordion.url" class="item" (click)="openLink(accordion.url)">
    <span class="item-text">{{accordion.accordionTitle}}</span>
    <i class='icon icon-chevron-right'></i>
  </div>
</div>

import {of as observableOf,  Observable ,  Subject, throwError, Subscription } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { ConfigurationService } from '../../core/services/configuration.service';
import { config } from './../../../config/pages-config';
import { UserSettingToken } from './../enums/user-setting-token.enum';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ErrorHadlingUsingNotificationService } from '../../core/services/error-hadling-using-notification.service';
import { Router } from '@angular/router';
import { AppService } from './../../app.service';
import { LoginService } from './../../login/login.service';
import { UserSettingsService } from './user-settings.service';
import { CustomerSegment } from './../enums/customer-segment.enum';
import { NavigationType } from './../enums/navigation-type.enum';
import { PlatformType } from './../enums/platform-type.enum';
import { SidemenuCategory } from './../enums/sidemenu-category.enum';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { SpSideMenuItem, SpSideMenuSubItem } from './../components/sp-sidemenu/sp-sidemenu.interface';
import { ServiceModel } from './../../models/service.model';
import { SubscriptionService } from '../../core/services/subscription.service';
import { StorageService } from '../../core/services/storage.service';
import {
  JSON_PATHS, SidemenuQuery, MENU_ACTION_TYPE,
  p2SiteStatuses, HttpRequestMethods, PAGES, UNAVAILABILITY_OVERLAY, menuItemsNames, topUpSubMenuItems, TOBibubbleValues,
} from './../constants/defines';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Routes from '../constants/routes-config';
import * as jsonQuery from 'jsonpath/jsonpath';
import * as _ from 'lodash';
import { ProfileType } from '../enums/profileType.enum';
import { ImpersonationService } from './impersonation.service';
import { ImpersonationGroup } from '../enums/impersonationGroup.enum';
import { BubbleHelpConfiguration } from '../../models/bubbleHelpConfiguration';
import { TranslateService } from '@ngx-translate/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SpModalFullComponent } from '../components/sp-modal-full/sp-modal-full.component';
import { NotificationInboxService } from './notification-inbox.service';
import { NeedBubbleHelpTabs } from '../enums/need-bubble-help-tabs.enum';
import { Location } from '@angular/common';
import { DashboardService } from './dashboard.service';
import { UpgradeProfileService } from '../../upgrade-profile/upgrade-profile.service';
import { tagging } from '../../../config/tagging-config';
import { CustomerAccountService } from './customer-account.service';
import { MenuListResponse, MenuItemResponse, MenuItemApp } from '../../models/side-menu-response.model';
import { CardImageSelectorModel, SelectorModel } from '@mva10/mva10-angular';
import { MyAccountSections } from '../enums/my-account-sections.enum';
import { UtilitiesService } from '../utils/utilities.service';
import { SiteHandlingService } from './site-handling.service';
import { SiteStatus } from '../enums/siteStatus.enum';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from '../../../app/models/new-tagging-json-model';
import { SingleSignOnService } from './single-sign-on/single-sign-on.service';
import { SingleSignOnTypes } from './single-sign-on/models/single-sign-on-types.enum';
import { BubbleHelpResponse } from '../../models/bubble-help-response.model';
import { SpModalComponent } from '../components/sp-modal/sp-modal.component';
import { Configurations } from '../../models/configuration.model';

@Injectable()
export class SidemenuConfigService {
  public OverlyOneModal: SpModalFullComponent;
  allItemsInMenu: SpSideMenuItem[];
  currentSiteStatus: string;
  currentService: ServiceModel = new ServiceModel();
  currentSegment: string;
  customerType: string;
  sideMenuSubject: Subject<void>;
  firstTimeOfLoading: boolean = true;
  menuItemsIsLoadedInResolver: boolean = false;
  showIframe: boolean = false;
  urlIframe: SafeResourceUrl;
  webToken: string;
  originalResponseItems: MenuListResponse;
  bubbleHelpConfigSubject: Subject<void>;
  bubbleHelpConfig: Array<BubbleHelpConfiguration>;
  myAccountCarouselData: SelectorModel;
  myAccountMenuSubItems: SpSideMenuSubItem[];
  code?: string;

  constructor(private http: HttpClient,
    private auth: AuthenticateService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private impersonationService: ImpersonationService,
    private storageService: StorageService,
    private userSettingsService: UserSettingsService,
    private loginService: LoginService,
    private appService: AppService,
    private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
    private tagging: TaggingHelperService,
    private translate: TranslateService,
    private configurationService: ConfigurationService,
    public notificationInboxService: NotificationInboxService,
    private sanitizer: DomSanitizer,
    private location: Location,
    private dashboardService: DashboardService,
    private upgradeService: UpgradeProfileService,
    private customerAccountService: CustomerAccountService,
    private utils: UtilitiesService,
    private newTagging: NewTaggingHelperService,
    private siteHandlingService: SiteHandlingService,
    private singleSignOnService: SingleSignOnService
    ) {
    this.sideMenuSubject = new Subject<void>();
    this.bubbleHelpConfigSubject = new Subject<void>();
  }

  private loadMenuItems(): Observable<SpSideMenuItem[]> {
    this.allItemsInMenu = [];
    if (this.originalResponseItems) {
      return observableOf(this.getFilteredItems(this.originalResponseItems));
    } else {
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('content-type', 'application/json');
      headers = headers.append('accept', 'application/json');
      return this.http.get(Routes.API_URLS.SideMenuConfig.MENU_ITEMS, { headers }).pipe(map((response: MenuListResponse) => {
        this.originalResponseItems = response;
        return this.getFilteredItems(response);
      }), catchError(() => {
        this.allItemsInMenu.push({
          navigationType: NavigationType.Internal,
          itemText: 'Desconectar',
          locked: false,
          iconClassName: 'icon icon-top-up',
          actionType: MENU_ACTION_TYPE.Command,
          command: SidemenuQuery.logout,
          category: SidemenuCategory.General
        });
        return throwError(this.allItemsInMenu);
      }));
    }
  }
  private getCategoryItemsSorted(itemsArray: SpSideMenuItem[], category: string): SpSideMenuItem[] {
    return _.sortBy(_.filter(itemsArray, (item: SpSideMenuItem) => {
      return item.category.toLowerCase() === category.toLowerCase();
    }), SidemenuQuery.order);
  }
  /**
   *   @param item
   *
   * function to fill spMenuItem model from item
   *
   */
  fillMainItem(item: MenuItemResponse): SpSideMenuItem {
    const spMenuItem: SpSideMenuItem = {
      name: jsonQuery.value(item, JSON_PATHS.MenuItem.name),
      locked: jsonQuery.value(item, JSON_PATHS.MenuItem.profile) ?
        (jsonQuery.value(item, JSON_PATHS.MenuItem.profile).toLowerCase()
          !== ProfileType.COMPLETE.toLowerCase()
          && this.storageService.userProfile.profileType.toLowerCase()
          !== ProfileType.COMPLETE.toLowerCase()) : false,
      iconClassName: 'icon ' + item[JSON_PATHS.MenuItem.iconName],
      itemText: jsonQuery.value(item, JSON_PATHS.MenuItem.title),
      redirectTo: jsonQuery.value(item, JSON_PATHS.MenuItem.redirectTo),
      navigationType: jsonQuery.value(item, JSON_PATHS.MenuItem.navigationType),
      id: jsonQuery.value(item, JSON_PATHS.MenuItem.id),
      order: jsonQuery.value(item, JSON_PATHS.MenuItem.order),
      category: jsonQuery.value(item, JSON_PATHS.MenuItem.category),
    };
    spMenuItem[SidemenuQuery.category] = item[SidemenuQuery.category];
    return spMenuItem;
  }
  /**
   *
   * @param item
   * @param subItem
   * generic function to get one or two items
   * if one param will fill main item
   * if two param it will fill child item
   */
  fillItem(item: MenuItemResponse, subItem: MenuItemResponse = null): SpSideMenuItem {
    if (item && subItem) {
      return this.fillSubItem(subItem, item);
    } else {
      return this.fillMainItem(item);
    }

  }
  /**
   *
   * @param item
   * @param subItem
   * function to fill child item from SpSideMenuSubItem model
   */
  fillSubItem(subItem: MenuItemResponse, item?: MenuItemResponse): SpSideMenuSubItem {
    const spSubItem: SpSideMenuSubItem = {
      name: jsonQuery.value(subItem, JSON_PATHS.MenuItem.name),
      parentName: jsonQuery.value(item, JSON_PATHS.MenuItem.name),
      locked: jsonQuery.value(subItem, JSON_PATHS.MenuItem.profile) ?
        (jsonQuery.value(subItem, JSON_PATHS.MenuItem.profile).toLowerCase()
          !== ProfileType.COMPLETE.toLowerCase()
          && this.storageService.userProfile.profileType.toLowerCase()
          !== ProfileType.COMPLETE.toLowerCase()) : false,
      itemText: jsonQuery.value(subItem, JSON_PATHS.MenuItem.title),
      redirectTo: jsonQuery.value(subItem, JSON_PATHS.MenuItem.redirectTo),
      navigationType: jsonQuery.value(subItem, JSON_PATHS.MenuItem.navigationType),
      order: jsonQuery.value(subItem, JSON_PATHS.MenuItem.order)
    };
    if (this.configurationService.configuration.showMovementsHistoryNewScreen &&
      (subItem.name.toLocaleLowerCase() === topUpSubMenuItems.movementsHistory.toLocaleLowerCase()
    || subItem.name.toLocaleLowerCase() === topUpSubMenuItems.topUpHistory.toLocaleLowerCase())) {
      spSubItem.redirectTo = config.TopUp.topUpMovementsHistory.route;
    }
    return spSubItem;
  }
  isSegmentInSegments(): string {
    const allSegments: string[] = Object.keys(CustomerSegment);
    return allSegments.find(segment => segment.toLowerCase() === this.currentSegment.toLowerCase());
  }
  public getUserMenuItems(): Observable<SpSideMenuItem[]> {
    if (this.configurationService.configuration) {
      this.getBubbleHelpConfigHelper();
    }
    return this.loadMenuItems().pipe(map(() => {
      this.initMyAccountCarousel();
      return this.allItemsInMenu;
    }));
  }
  /**
   *
   * @param item
   * method used to filter all item
   * * it went through six mandatory condtion if one fail it will return false
   */
  filterItem(item: MenuItemResponse): boolean {

    /** check on item enabled (1)*/
    if (!item || !item.enabled) {
      return false;
    }
    /** check on platform (2) */
    if (_.findIndex(item.apps, (app: MenuItemApp) => {
      return app.platform.toLowerCase() === PlatformType.Mobile_Web.toLowerCase();
    }) < 0) {
      return false;
    }
    if (this.isImpersonated()) {
      return _.intersection(p2SiteStatuses, item.account[SidemenuQuery.status]).length > 0;
    } else {
      /** check on site status (3) */
      if (_.findIndex(item.account[SidemenuQuery.status], (status: string) => {
        return this.currentSiteStatus && status.toLowerCase() === this.currentSiteStatus.toLowerCase();
      }) < 0) {
        return false;
      }
    }
    /** check on CustomerSegment  (4) */
    if (_.findIndex(item.segments, (CustomerSegment: string) => {
      return CustomerSegment.toLowerCase() === this.currentSegment.toLowerCase();
    }) < 0) {
      return false;
    }
    /** check on customer type (5) */
    const accountTypeIndex: number = _.findIndex(item.account[SidemenuQuery.accountTypes], (obj: { type: string }) => {
      return obj.type.toLowerCase() === this.customerType.toLowerCase();
    });
    if (accountTypeIndex > -1) {
      /** if p2 user || impersonated user pay a dept || site is pending installation then there is no service to use in conditions */
      if (this.isP2SiteStatus(this.currentSiteStatus) || this.isImpersonated() ||
        this.customerAccountService.isPendingInstallationSiteStatus(this.currentSiteStatus)) {
        return true;
      } else {
        /** check on service Type (6) */
        return _.findIndex(item.account[SidemenuQuery.accountTypes][accountTypeIndex].serviceTypes,
          (serviceType: { status: string, type: string }) => {
            return serviceType.type.toLowerCase() === this.subscriptionService.customerData.currentService.type.toLowerCase();
          }) > -1;
      }
    } else {
      return false;
    }
  }

  private isImpersonated(): boolean {
    return this.impersonationService.impersonatedUser?.Group?.toLocaleLowerCase() === ImpersonationGroup.PayADebt.toLocaleLowerCase();
  }

  setFilterVartiables(): void {
    if (this.subscriptionService.customerData && this.subscriptionService.customerData.currentService) {
      if (!this.currentSegment) { this.currentSegment = this.subscriptionService.customerData.currentService.segment; }
      if (!this.currentSiteStatus) { this.currentSiteStatus = this.subscriptionService.customerData.currentService.clientStatus; }
    }
    if (!this.currentSegment || !this.isSegmentInSegments()) {
      this.currentSegment = CustomerSegment.Vodafone;
    }
    if (!this.currentSiteStatus) {
      this.currentSiteStatus =
        this.storageService.userProfile.sites?.find(site => site.id === this.subscriptionService.customerData.customerAccountsId)?.status ||
        '';
    }
    if (this.siteHandlingService.isSiteP2() && !this.currentSiteStatus) {
      this.currentSiteStatus = SiteStatus.Pend_de_Reconectar.toLowerCase();
    }
    if (this.siteHandlingService.isSiteSuspended()) {
      this.currentSiteStatus = SiteStatus.Suspendido;
    }
    if (this.siteHandlingService.isSiteDisconnected()) {
      this.currentSiteStatus = SiteStatus.Desconectado.toLowerCase();
    }
    /** get current service (default service) to make a condition on it */
    this.currentService = this.subscriptionService.customerData.currentService;

    /** get current customer type to make a condition on it */
    this.customerType = this.auth.getUserFromLocalJWT().customerType.toString();
  }

  private getFilteredItems(response: MenuListResponse): SpSideMenuItem[] {
    this.setFilterVartiables();

    let spMenuItems: SpSideMenuItem[] = [];
    /* sort all item with there order  */
    const sortedItems: MenuItemResponse[] = _.sortBy(response[SidemenuQuery.items], SidemenuQuery.order);

    /**
     * group all items by there category and or parent id
     * to get the child items in one group with there parent id in the array keys ex: (account,4) (service,7) or
     * get (account) these means all this group either parent to items in one group like item with id 4 and group (account,4)
     * or all these will be main items  and will be show by it's own
     */
    let myGroupedItems: MenuItemResponse[] = _.groupBy(sortedItems, (item: MenuItemResponse) => {
      if (item[SidemenuQuery.parentId]) {
        return [item[SidemenuQuery.category], item[SidemenuQuery.parentId]];
      } else {
        return item[SidemenuQuery.category];
      }
    });
    const arrKeys: string[] = Object.keys(myGroupedItems);
    /** get all key's of all child items which will be like account,4 */
    const childItems: string[] = _.filter(arrKeys, (key) => { return key.split(',').length > 1; });
    /** get all key's of all main  items which will be like account  service*/
    const mainItems: string[] = _.filter(arrKeys, (key) => { return key.split(',').length === 1; });

    /**
     * Loop through child items in external function to reduce cyclomatic complexity
     */
    [spMenuItems, myGroupedItems] = this.filterChildItems(childItems, spMenuItems, myGroupedItems);

    /**
    * loop through mainItems key's
    * filter them
    * fill  object  push them to all menu items
    */
    for (let index: number = 0; index < mainItems.length; index++) {
      myGroupedItems[mainItems[index]].forEach((item) => {
        if (this.filterItem(item)) {
          const spAloneMenuItem: SpSideMenuItem = this.fillItem(item);
          spMenuItems.push(spAloneMenuItem);
        }
      });
    }
    /**
      * filter all items to get items with category Account alone
      * and then sort them with there order
      */
    const accountItems: SpSideMenuItem[] = this.getCategoryItemsSorted(spMenuItems, SidemenuCategory.Account);
    /**
     * filter all items to get items with category Service alone
     * and then sort them with there order
     */
    const serviceItems: SpSideMenuItem[] = this.getCategoryItemsSorted(spMenuItems, SidemenuCategory.Service);
    /**
   * filter all items to get items with category General alone
   * and then sort them with there order
   */
    const generalItems: SpSideMenuItem[] = this.getCategoryItemsSorted(spMenuItems, SidemenuCategory.General);
    /**
     *concat all these filtered items with the order we need account the service then  general
     */
    this.allItemsInMenu = this.allItemsInMenu.concat(accountItems).concat(serviceItems).concat(generalItems);


    this.sideMenuSubject.next();
    return this.allItemsInMenu;
  }

  private filterChildItems(
    childItems: string[], spMenuItems: SpSideMenuItem[], myGroupedItems: MenuItemResponse[]
  ): [SpSideMenuItem[], MenuItemResponse[]] {
    /**
     * loop through cild item key's and get there parent
     * fill parent object and it's child and  push them to all menu items
     */
    let index: number;
    for (index = 0; index < childItems.length; index++) {
      const keymaps: string[] = childItems[index].split(',');
      /** keymaps[0] --> category's name , keymaps[1] --> child item's parent ID */
      const item: MenuItemResponse  = _.find(myGroupedItems[keymaps[0]], (_item: SpSideMenuItem) => {
        return _item.id === keymaps[1];
      }) as MenuItemResponse;
      /** check if the parent will pass the filtering method */
      if (this.filterItem(item)) {
        /** if parent item  pass fill it into spMenuItem model */
        const spMenuItem: SpSideMenuItem = this.fillItem(item);

        const spSubItems: SpSideMenuSubItem[] = [];
        /** loop through child items filter them and fill them in spSubItem model and push them into spSubItems  */
        myGroupedItems[childItems[index]].forEach(subItem => {
          if (this.filterItem(subItem)) {
            spSubItems.push(this.fillItem(item, subItem));
          }
        });
        /** if spSubItems is not empty push all it's item into
         * spMenuItem.subItems
         * and push that item
         * if not we will ignore that parent item
         */
        if (spSubItems.length > 0) {
          spMenuItem.subItems = spSubItems;
          spMenuItems.push(spMenuItem);
        }
      } /** end of if filter item */
      /** after finsh working with that item remove it from main items group
       * to escape it we loop through the mainItems array
       */
      const itemIndex: number = _.findIndex(myGroupedItems[keymaps[0]], (element: MenuItemResponse) => {
        return element.id === keymaps[1];
      });
      if (itemIndex !== -1) {
        myGroupedItems[keymaps[0]].splice(itemIndex, 1);
      }
    }
    return [spMenuItems, myGroupedItems];
  }


  public isItemExists(navigationURL: string): SpSideMenuItem {
    if (this.allItemsInMenu) {
      const allSubItemns: SpSideMenuItem[] = [];
      this.allItemsInMenu.forEach(parentItem => {
        if (parentItem.subItems) {
          allSubItemns.push(...parentItem.subItems);
        }
      });
      return allSubItemns.find(item => item.redirectTo && item.redirectTo.includes(navigationURL));
    }
    return null;
  }
  openBubble(openBubbleTab?: NeedBubbleHelpTabs): void {
    if (openBubbleTab) {
      this.appService.helpBubbleSelectedTab = openBubbleTab;
    }
    this.appService.helpBubbleItemClicked = true;
    setTimeout(() => this.appService.openBubble.next(), 200);
  }
  sidemenuItemClick(item: SpSideMenuItem | SpSideMenuSubItem): void {
    if (item.name === 'logout') {
      this.callLogout();
      return;
    }
    if (item.name?.toLowerCase() === menuItemsNames.OneNumber?.toLowerCase() && this.singleSignOnService.hasOneNumberSingleSignOn()) {
      this.singleSignOnService.singleSignOnRedirection(SingleSignOnTypes.OneNumber);
      return;
    }
    this.notificationInboxService.endCount();
    if (item.navigationType.toLowerCase() === NavigationType.Internal) {
      if (item.redirectTo === SidemenuQuery.needBubbleHelp) {
        this.openBubble();
      }
      else {
        this.router.navigate([item.redirectTo]);
      }
      if (item.redirectTo === this.router.url) {
        this.tagging.sendViewState();
      }
    } else if (item.navigationType.toLowerCase() === NavigationType.External) {
      window.open(item.redirectTo, '_blank');
    } else if (item.navigationType.toLowerCase() === NavigationType.Contingency.toLowerCase()) {
      this.appService.contingency = item.itemText;
      this.router.navigate([item.redirectTo]);
    } else if (item.navigationType.toLowerCase() === NavigationType.Oldportal.toLowerCase()) {
      this.goToOldApp(item.redirectTo);
    } else if (item.navigationType.toLowerCase() === NavigationType.ECare.toLowerCase()) {
      this.goToECare(item.redirectTo);
    }
  }

  private isP2SiteStatus(siteStatus: string): string {
    return p2SiteStatuses.find((status: string) => status.toLowerCase() === siteStatus.toLowerCase());
  }

  getBubbleHelpConfigHelper(): Subscription {
    this.bubbleHelpConfig = [];
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options: {headers: HttpHeaders} = {
      headers: headers
    };
    if (this.subscriptionService.customerData && this.subscriptionService.customerData.currentService
      && this.subscriptionService.customerData.currentService.id) {
      return this.http.get(Routes.API_URLS.SideMenuConfig.BUBBLE_HELP_CHAT.replace('{selectedService}',
        this.subscriptionService.customerData.currentService.id), options)
        .subscribe((res: BubbleHelpResponse) => {
          if (Array.isArray(res.items)) {
            res.items.forEach(el => {
              this.bubbleHelpConfig.push(this.mapConfig(el));
            });
            this.bubbleHelpConfigSubject.next();
          }
        });
    }
  }

  mapConfig(item: BubbleHelpConfiguration): BubbleHelpConfiguration {
    const result: BubbleHelpConfiguration = new BubbleHelpConfiguration();
    result.name = jsonQuery.value(item, JSON_PATHS.chatConfig.name);
    result.taggingName = jsonQuery.value(item, JSON_PATHS.chatConfig.taggingName);
    result.faqsEnabled = jsonQuery.value(item, JSON_PATHS.chatConfig.faqsEnabled);
    result.chatEnabled = jsonQuery.value(item, JSON_PATHS.chatConfig.chatEnabled);
    result.tobiBubble = jsonQuery.value(item, JSON_PATHS.chatConfig.tobiBubble) || TOBibubbleValues.faqs;
    result.module = jsonQuery.value(item, JSON_PATHS.chatConfig.module);
    return result;
  }
  public goToOldApp(deepLink: string, menuItemClicked = true): void {
    if (deepLink) {
      if (this.isAnyAvailableFlagOff()) {
        // tagging
        this.tagging.taggingUnavailabilityOverlay(UNAVAILABILITY_OVERLAY.ECARE_O_MCARE);
        this.openAPIsUnAvailabilityModal();
      } else {
        this.userSettingsService.getUserSettingsToken(UserSettingToken.WebToken, this.subscriptionService.customerData.customerAccountsId
          , this.subscriptionService.customerData.currentService.id, menuItemClicked).subscribe(() => {
            this.loginService.seibelToken = this.userSettingsService.webToken;
            this.loginService.seibelDeepLink = deepLink;
            this.router.navigate([config.login.loginSeibelRedirection.route]);
          }, (error) => {
            this.appService.showFullAppLoader = false;
            const notificationModal: SpModalComponent = this.errorHadlingUsingNotificationService.
              errorHandlingUsingNotification(error, HttpRequestMethods.post, PAGES.COMMON, true);
            notificationModal.show();
          });
      }
    }
  }

  public goToECare(deepLink: string): void {
    if (this.isAnyAvailableFlagOff()) {
      // tagging
      this.tagging.taggingUnavailabilityOverlay(UNAVAILABILITY_OVERLAY.ECARE_O_MCARE);
      this.openAPIsUnAvailabilityModal();
    } else if (deepLink !== 'gpedidos') {
      this.appService.showFullAppLoader = true;
      this.userSettingsService.getUserSettingsToken(UserSettingToken.WebToken, this.subscriptionService.customerData.customerAccountsId
        , this.subscriptionService.customerData.currentService.id, false).subscribe(() => {
          /** here should redirect to e-care */
          this.openIframe(this.utils.getECareUrl(deepLink || ''), this.userSettingsService.webToken);
        }, (error) => {
          this.appService.showFullAppLoader = false;
          const notificationModal: SpModalComponent = this.errorHadlingUsingNotificationService.
            errorHandlingUsingNotification(error, HttpRequestMethods.post, PAGES.COMMON, true);
          notificationModal.show();
        });
    } else {
      this.router.navigate([config.myorders.route]);
    }
  }
  /**
  * check if Amdocs user and (geneva OR ocs OR clarify) is off
  */
  private isAnyAvailableFlagOff(): boolean {
    const allFlags: Configurations = this.configurationService.configuration;
    return !allFlags.genevaFlag || !allFlags.ocsFlag || !allFlags.clarifyFlag;
  }

  openAPIsUnAvailabilityModal(): void {
    // should create a new overlay 1
    this.OverlyOneModal.show();
    this.translate.get('login.messagesList.sectionNotAvailable').subscribe(data => {
      this.OverlyOneModal.imgSrc = data.icon.url;
      this.OverlyOneModal.htmlContent = data.description;
      this.OverlyOneModal.primaryButtonText = data.confirmButton.text;
      this.OverlyOneModal.primaryButtonClick = () => {
        this.OverlyOneModal.hide();
      };
    });
  }

  openIframe(url: string, webToken: string): void {
    this.showIframe = true;
    this.appService.showFullAppLoader = false;
    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.webToken = webToken;
    this.router.navigate([config.ecareLanding.route]);
  }

  leftMenuItemClick(item: SpSideMenuItem): void {
    if (item.actionType) {
      if (this.auth.isAuthenticated() && !SidemenuQuery.logout.toLowerCase()) {
        this.notificationInboxService.getNotificatoinCount();
      }
      if (item.actionType === MENU_ACTION_TYPE.Command) {
        switch (item.command.toLowerCase()) {
          case SidemenuQuery.logout.toLowerCase():
            this.drawLogoutTray(item);
            this.dashboardService.isVOVBiztalkReplicaTabViewed = false;
            break;
        }
      }
    } else {
      if (item.locked) {
        this.upgradeService.targetRoute = item.redirectTo;
        this.upgradeService.startScenario = true;
        return;
      } else {
        const eventName: string =
          tagging.sidemenu.itemClicked.parentItem.data.event_name.replace('{0}', item.itemText);
        const data: {event_name: string} = {
          event_name: eventName
        };
        this.tagging.track(eventName, data);

        this.sidemenuItemClick(item);
      }

    }
  }

  private initMyAccountCarousel(): void {
    const arrayFromWCS: Array<{ id: MyAccountSections; title: string; image: string }> = this.translate.instant(
      'v10.common.tray.overlay_myaccount.carousel'
    );
    this.myAccountMenuSubItems = this.allItemsInMenu?.find(parentItem => parentItem.name === 'myAccountCarousel')?.subItems || [];
    // to added pushed to the menu Items
    this.myAccountCarouselData = new SelectorModel();
    this.myAccountCarouselData.cardImageSelectorList = this.myAccountMenuSubItems.map(item => {
      return {
        title: item.itemText,
        id: item.name,
        image: this.appService.getImgFullPath(arrayFromWCS.find(wcsItem => wcsItem.id === item.name)?.image),
        description: '',
        checked: false
      } as CardImageSelectorModel;
    });
  }

  onCarouselCardClicked(clickedCardId: string): void {
    // check if clickedCardId is Cerrar Sesión then logout
    if (clickedCardId === 'logout') {
      this.callLogout();
    } else {
      const urlToNavigateTo: string = this.myAccountMenuSubItems.find(subItem => subItem.name === clickedCardId)?.redirectTo;
      if (urlToNavigateTo) {
        this.appService.showFullAppLoader = true;
        this.router.navigate([urlToNavigateTo.split('?')[0], this.utils.getRouteParams(urlToNavigateTo)]);
      }
    }
  }
  callLogout(): void {
    this.drawLogoutTray({
      navigationType: NavigationType.Internal,
      itemText: '',
      locked: false,
      iconClassName: '',
      actionType: MENU_ACTION_TYPE.Command,
      command: SidemenuQuery.logout,
      category: SidemenuCategory.General
    });
    this.newTagging.getJsonTagging('login').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(data.eventList['user_disconnectionn_start'], data.page.stateList['user_disconnection']);
    });
  }

  getCarouselSectionTitle(currentSection: MyAccountSections): string {
    if (!this.myAccountCarouselData) {
      this.initMyAccountCarousel();
    }
    const associatedCard: CardImageSelectorModel = this.myAccountCarouselData.cardImageSelectorList.find(
      card => card.id === currentSection
    );
    return associatedCard ? associatedCard.title : this.translate.instant('myAccount.title');
  }

  drawLogoutTray(item: SpSideMenuItem): void {
    this.appService.showLogoutTray = true;
    this.appService.logoutItem = item;
  }
}


<div id="bundleChart" class="bundle-card-chart" >
    <ng-container *ngIf="!Bundle?.IsUnLimited && isActiveTarrif">
      <mva10-c-icon id="sharedErrorAlertCustomIcon" class="bundle-card-chart__icon" *ngIf="Bundle?.CallType === internationalType"  [size]="25" [iconId]="'icon-international'"></mva10-c-icon>
      <img id="icon" *ngIf="Bundle?.CallType !== internationalType"  class="bundle-card-chart__icon" [alt]="'calls icon'" [src]='Icons.calls | imgFullPath' />      
        <div id="chart" class="chart">
            <mva10-c-data-chart id="datachart" *ngIf="Bundle?.TotalConsumption && Bundle?.RemainConsumptionExtraVoice"
            [valueText]="Bundle?.RemainConsumptionExtraVoice + '/' + Bundle?.TotalConsumption / 60 + defines.Mins "
            [description]="Bundle?.Name" [max]="Bundle?.TotalConsumption / 60" [min]="0"
            [value]="Bundle?.RemainConsumptionExtraVoice">
        </mva10-c-data-chart>
        <p id="bundleTitle" *ngIf="!Bundle?.TotalConsumption && !Bundle?.RemainConsumptionExtraVoice" >{{Bundle.Name}}</p>
        </div>    
    </ng-container>

    <ng-container *ngIf="!isActiveTarrif">
        <mva10-c-icon id="sharedErrorAlertCustomIcon" *ngIf="Bundle?.CallType === internationalType" class="bundle-card-chart__icon"  [size]="25" [iconId]="'icon-international'"  ></mva10-c-icon>
        <img id="icon" *ngIf="Bundle?.CallType !== internationalType"  class="bundle-card-chart__icon" [alt]="'calls icon'" [src]='Icons.calls | imgFullPath' />    
        <div id="chart" class="chart">
            <mva10-c-data-chart id="bundleChart"
            [valueText]=""
            [description]="Bundle?.Name" [max]="0" [min]="0"
            [value]="0">
        </mva10-c-data-chart>
        </div>
  
    </ng-container>

    <ng-container *ngIf="Bundle?.IsUnLimited && isActiveTarrif">
        <mva10-c-icon id="sharedErrorAlertCustomIcon" *ngIf="Bundle?.CallType === internationalType" class="bundle-card-chart__icon"  [size]="25" [iconId]="'icon-international'"></mva10-c-icon>
        <img id="icon" *ngIf="Bundle?.CallType !== internationalType"  class="bundle-card-chart__icon" [alt]="'calls icon'" [src]='Icons.calls | imgFullPath' />   
        <div id="chart" class="chart">
            <mva10-c-data-chart id="bundleChart" [max]="100" [min]="0" [description]="Bundle?.Name"
                valueText="{{'v10.productsServices.extras.unlimited'|translate}}">
            </mva10-c-data-chart>
        </div>   
    </ng-container>
</div>

export class CommercialPlaylist {
  public ecode: number;
  public playlist: PlayListCommercial[];
}

export class PlayListCommercial {
  public name: string;
  public code: number;
  public order: number;
  public type: string;
  public items?: number[] = [];
  public flgAutoComplete?: boolean;
  public checked?: boolean;

  static equal(obj1: PlayListCommercial, obj2: PlayListCommercial) {
    return obj1.name === obj2.name && obj1.code === obj2.code && obj1.order === obj2.order;
  }
}

<mva10-c-modal [styleClass]="''" [ngClass]="{'high-z-index':fullModslServiceService.increase_z_index, 'hide-x-button': fullModslServiceService.hideXButton , 'skeleton-x-button':fullModslServiceService?.showSkeleton,'grey-background':fullModslServiceService?.greyMode,
'error-modal': fullModslServiceService.adjustErrorModalUi, 'has-retry-btn': fullModslServiceService.retryBtn, 'adjust-content': fullModslServiceService.isAdjustContent}"
  [show]="appService.showErrorFullModal" (closeBtnClicked)="close()">
  <div class="confirmation-overlay">
<div *ngIf="!fullModslServiceService?.showSkeleton">
    <div class="confirmation-overlay__message">
      <div class="confirmation-overlay__img" *ngIf="fullModslServiceService.img" id='sharedComponentFullModalErrorIcon'>
        <img [src]="appService.getImgFullPath(fullModslServiceService.img)"  alt="" [ngStyle]="{'width': fullModslServiceService?.imageWidth + 'px','height': fullModslServiceService?.imageHeight + 'px'}" />
      </div>
      <p class="confirmation-overlay__title1" *ngIf="fullModslServiceService.errorTitle"
        [innerHtml]='fullModslServiceService.errorTitle' id='sharedComponentFullModalErrorTitle1'></p>
      <p class="confirmation-overlay__title2" *ngIf="fullModslServiceService.errorSubtitle"
        [innerHtml]='fullModslServiceService.errorSubtitle'  id='sharedComponentFullModalErrorTitle2'></p>
      <button class="confirmation-overlay__retrybtn" *ngIf="fullModslServiceService.retryBtn" (click)="retryButtonAction()" id='sharedComponentFullModalErrorRetryBtn'>
        {{fullModslServiceService.retryBtn}}</button>  
    </div>
  </div>
    <div class="confirmation-overlay__btns">
      <div class="btn-container" [ngClass]="{'btn-container-with-ternary-btn': (fullModslServiceService.errorButton1 && fullModslServiceService.errorButton2)}"
       *ngIf="fullModslServiceService.errorButton1 || fullModslServiceService.errorButton2">
        <mva10-c-button *ngIf="fullModslServiceService.errorButton1" (click)="button1Action()" [body]="fullModslServiceService.errorButton1"
          [styleClass]="'mva10-c-button--primary'" id='sharedComponentFullModalErrorPrimaryButton' [name]="''">
        </mva10-c-button>
        <mva10-c-button class='ternary-btn' *ngIf="fullModslServiceService.errorButton2" (click)="button2Action()" [body]="fullModslServiceService.errorButton2"
          [styleClass]="'mva10-c-button--tertiary'" id='sharedComponentFullModalErrorTernaryButton' [name]="''">
        </mva10-c-button>
      </div>
    </div>


  <!--Skeleton-->
  <div class="skeleton-wrapper-section" *ngIf="fullModslServiceService?.showSkeleton">
    <div class="img-skeleton"></div>
    <section class="content-container-skeleton">
        <div class="content-skeleton first-content"></div>
        <div class="content-skeleton"></div>
    </section>
    <div class="button-skeleton"></div>
</div>

  </div>
</mva10-c-modal>



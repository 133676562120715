<div class="smartpay-management" id="smartpayManagement">
  <div class="smartpay-management__container">
    <mva10-c-card class="smartpay-management__info-banner">
        <img
            *ngIf="isMobileView"
            src="{{appService.getImgFullPath('v10.productsServices.smartPay.PyS.management.image' | translate)}}"
            alt="SmartPay Image"
            class="smartpay-management__image"
            id="smartPayManagementImage"
        />
        <img
            *ngIf="!isMobileView"
            src="{{appService.getImgFullPath('v10.productsServices.smartPay.PyS.management.image_rsp' | translate)}}"
            alt="SmartPay Image"
            class="smartpay-management__image-rsp"
            id="smartPayManagementImage"
        />
      <div class="smartpay-management__info-banner__message-container">
        <p class="smartpay-management__info-banner__message" id="smartPayManagementImage">
          {{smartPayRechargeText}}
        </p>
      </div>
      <button class="smartpay-management__info-banner__button-container" id="smartPayManagementDiscoverButton" (click)="goToSmartPayLanding()">
        <p class="smartpay-management__info-banner__button-text" id="smartPayManagementDiscover">{{smartPayDiscoverText}}</p>
        <svg class="smartpay-management__info-banner__button-icon">
          <title>Go to</title>
          <use xlink:href="#icon-chevron-right"/>
        </svg>
      </button>
    </mva10-c-card>

    <p class="smartpay-management__section-title" id="smartPayManagementMyLinesTitle">{{smartPayMyLinesText}}</p>
    <ng-container *ngIf="linesAvailable">
        <mva10-c-card *ngFor="let msisdn of filteredMsisdnList; let i = index" class="smartpay-management__section-item">
            <svg class="smartpay-management__section-item-icon">
                <title>Phone line</title>
                <use xlink:href="#icon-mobile"/>
            </svg>
            <div class="smartpay-management__section-item__data-container">
                <p class="smartpay-management__section-item__line-number" id="smartPayManagementLine">{{msisdn.id}}</p>
                <p class="smartpay-management__section-item__smartpay-status" id="smartPayManagementLineStatus"
                [ngClass]="{'active': msisdn.smartPay === true}">
                    {{msisdn.smartPay === true ? smartPayActiveText : smartPayInactiveText}}
                </p>
            </div>
            <svg class="smartpay-management__section-item__chevron" (click)="goToSmartPayPage(); setOnClickLinesTaggingLogic()">
                <title>Go to</title>
                <use xlink:href="#icon-chevron-right"/>
            </svg>
        </mva10-c-card>
    </ng-container>


    <p *ngIf="linesAvailable" class="smartpay-management__section-title" id="smartPayManagementMyRenovations">
        {{smartPayMyRenewalsText}}
    </p>

    <mva10-c-card class="smartpay-management__section-item" *ngIf="linesAvailable">
        <svg class="smartpay-management__section-item-icon">
            <title>Top Up List</title>
            <use xlink:href="#icon-list-view"/>
        </svg>
        <div class="smartpay-management__section-item__data-container">
            <p class="smartpay-management__section-item__line-number" id="smartPayManagementTopUpHistory">{{smartPayHistoryText}}</p>
        </div>
        <svg class="smartpay-management__section-item__chevron" (click)="setOnClickHistoryTaggingLogic()">
            <title>Go to</title>
            <use xlink:href="#icon-chevron-right"/>
          </svg>
    </mva10-c-card>

    <mva10-c-card class="smartpay-management__no-lines-card" *ngIf="!linesAvailable">
        <svg class="smartpay-management__no-lines-icon">
            <title>Go to</title>
            <use xlink:href="#icon-bundles-mid"/>
        </svg>
        <p class="smartpay-management__no-lines-title" id="smartPayManagementNoLinesText">{{smartPayNoLinesText}}</p>
        <p class="smartpay-management__no-lines-subtitle" id="smartPayManagementAddLineText">{{smartPayAddLineText}}</p>
    </mva10-c-card>
  </div>
</div>

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorDefault } from './payment-error-default';
import { PaymentErrorInterface } from './payment-error-interface';

export class PaymentErrorPostPaid extends PaymentErrorDefault implements PaymentErrorInterface {
    defaultErrorCategory: PaymentErrorCategory = PaymentErrorCategory.SystemNotAvailable;
    constructor(public paymentNavigationService: PaymentNavigationService,
        public translate: TranslateService,
        public router: Router) {
        super(paymentNavigationService, translate, router);
    }
    /**
     * the default implmentaion of the first btn in error payment screen
     */
    getFirstBtnAction(category: string): Function {
        let btnAction: Function
        switch (category) {
            case PaymentErrorCategory.general:
            case PaymentErrorCategory.PaymentApiFails:
            case PaymentErrorCategory.TimeOut:
            case PaymentErrorCategory.invalidCreditCard:
            case PaymentErrorCategory.CreditCardTypeNotSupported:
            case PaymentErrorCategory.invalidCreditCardInfo:
                btnAction = this.restartJourney();
                break;
            default:
                btnAction = this.close();
                break;
        }
        return btnAction;
    }
    getSecondBtnAction(): () => void {
        return this.paymentNavigationService.openPaymentComponent.bind(this.paymentNavigationService, PaymentPages.otherPaymentMethods);
    }
    restartJourney(): () => void {
        this.paymentNavigationService.partialEnergyDebtPayment = false;
        this.paymentNavigationService.isRestartingPayment = true;
        return this.paymentNavigationService.restartPaymentJourney.bind(this.paymentNavigationService);
    }
    /**
     * the default implmentaion of the second btn in error payment screen
     */
    close(): () => {} {
        this.paymentNavigationService.isRestartingPayment = false;
        this.paymentNavigationService.partialEnergyDebtPayment = false;
        return this.paymentNavigationService.closePayment.bind(this.paymentNavigationService);
    }
    /**
     * action for x btn click on overlay
     */
    closeBtnAction(): void {
        this.paymentNavigationService.isRestartingPayment = false;
        this.paymentNavigationService.partialEnergyDebtPayment = false;
        this.paymentNavigationService.closePayment();
    }
}

import { Component } from '@angular/core';
import { MyAccountService } from '../../../my-account/my-account.service';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { CardImageSelectorModel } from '@mva10/mva10-angular';
import { tagging } from '../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { TaggingActionModel, TaggingViewModel } from '../../../models/tagging.model';
import { SidemenuConfigService } from '../../services/sidemenu-config.service';
import { DashboardService } from '../../services/dashboard.service';
import { MyAccountSections } from '../../enums/my-account-sections.enum';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';

@Component({
  selector: 'sp-submenu-account',
  templateUrl: './submenu-account.component.html',
  styleUrls: ['./submenu-account.component.scss']
})
export class SubmenuAccountComponent {
  constructor(
    public myAccountService: MyAccountService,
    private leftMenuService: LeftMenuWs10Service,
    public menuService: SidemenuConfigService,
    private taggingHelper: TaggingHelperService,
    public dashboardService: DashboardService,
    private newTaggingHelperService: NewTaggingHelperService,
  ) {}

  myAccountSelectorCardClicked(clickedCard: CardImageSelectorModel): void {
    this.leftMenuService.closeSubmenu();
    setTimeout(() => {
      // On menu close
      this.menuService.onCarouselCardClicked(clickedCard.id);
    }, 500);

    if ([MyAccountSections.myData].map((section: MyAccountSections): string => String(section)).includes(clickedCard.id)) {
      this.newTaggingHelperService.getJsonTagging('side-menu/side-menu').subscribe((data: NewTaggingJsonModel) => {
        this.newTaggingHelperService.interaction(data.eventList['tap_menu_my_data'], data.page.stateList['dashboard_menu']);
      });
    } else {
      const page: TaggingViewModel = Object.assign({}, tagging.dashboard.ManageMyAccount.view);
      const clickTaggingData: TaggingActionModel = Object.assign({}, tagging.dashboard.ManageMyAccount.clickCarousel);
      const trackData: TaggingViewModel = Object.assign(page, {
        event_name: clickTaggingData.data.event_name.replace('{title}', clickedCard.title)
      });
      this.taggingHelper.track(clickTaggingData.eventName.replace('{title}', clickedCard.title), trackData);
    }
  }
}

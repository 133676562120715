import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { tagging } from '../../../../../config/tagging-config';
import { AppService } from '../../../../app.service';
import { AnonymousPostPaidPaymentService } from '../../../../payment/services/anonymous-post-paid-payment.service';
import { PaymentNavigationService } from '../../../../payment/services/payment-navigation.service';
import { MSISDNPrefix, nifPrefix } from '../../../../shared/constants/defines';
import { PaymentErrorService } from 'src/app/payment/services/payment-error.service';
import { PaymentCompoentInputData_Keys } from 'src/app/shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';
import { PaymentErrorCategory } from 'src/app/shared/enums/payment-error-category.enum';
import { FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';

@Component({
  selector: 'sp-payment-otp',
  templateUrl: './payment-otp.component.html',
  styleUrls: ['./payment-otp.component.scss']
})
export class PaymentOtpComponent implements OnInit, OnDestroy {
  infoForm: UntypedFormGroup;
  isOtpValidPattern: boolean;
  isOtpValidlength: boolean;
  showOtpError: boolean;
  isOtpValidMaxlength: boolean;
  wrongOtp: boolean;
  inValidPinCodeErrorText: string;
  incorrectPinCodeError: string;
  countDown: Subscription;
  counter: number = 601;
  tick: number = 1000;
  isTechError: boolean = false;
  otpText: string = '';
  constructor(public appService: AppService,
    private translate: TranslateService,
    private payNavSer: PaymentNavigationService,
    private taggingHelperService: TaggingHelperService,
    private anonPayService: AnonymousPostPaidPaymentService,
    private paymentErrorService: PaymentErrorService,
    private newTagging: NewTaggingHelperService,
    private fb: UntypedFormBuilder) {
  }


  ngOnInit(): void {
    this.initForm();
    this.translate.get('v10.payment.itemsList.anonymousPayment.es.otp').subscribe((data) => {
      this.inValidPinCodeErrorText = data.inValidPinCodeError;
      this.incorrectPinCodeError = data.incorrectPinCodeError;
    })
    this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
      const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.SECURITY_CODE;
      this.newTagging.state(data.page.stateList[constantName]);
    });
    this.startCountDown()
  }
  /** Method for initiate the form controls */
  initForm(): void {
    this.infoForm = this.fb.group({
      otp: [ '' , {
        validators: [Validators.required,
        Validators.pattern('^[0-9]+$'), Validators.minLength(6), Validators.maxLength(6)], updateOn: 'change'
      }],
    });
    if (this.payNavSer.otpCode && this.payNavSer.otpTechError) {
      this.otpText = this.payNavSer.otpCode;
      this.infoForm.get('otp').setValue(this.payNavSer.otpCode);
      this.isTechError = true;
      this.wrongOtp = true;
      this.showOtpError = true;
      this.payNavSer.otpCode = null;
    } else {
      this.infoForm.get('otp').setValue('');
    }
  }
  /**
   * Method for check validity of mobile number field
   * @param isBlured to make full validation (numbers only & mobile pattern) on focus out
   */
  checkOtpValidity(isBlured: boolean): void {
    this.isOtpValidPattern = !this.infoForm.get('otp').hasError('pattern');
    this.isOtpValidlength = !this.infoForm.get('otp').hasError('minlength');
    this.isOtpValidMaxlength = !this.infoForm.get('otp').hasError('maxlength');
    this.wrongOtp = false;
    if (isBlured) {
      // show error if we have error in min or max length or pattern
      this.showOtpError = this.infoForm.get('otp')?.value && (!this.isOtpValidPattern || !this.isOtpValidlength ||
        !this.isOtpValidMaxlength)
    } else {
      // show error if we have error in otp pattern only or user enter to many numbers
      this.showOtpError = this.infoForm.get('otp')?.value && (!this.isOtpValidPattern || !this.isOtpValidMaxlength)
    }
  }
  /**
   * @param validForm is the otp form valid
   * function to check the otp
   * and if otp is correct to get user dept
   */
  submitOtp(validForm: boolean = false): void {
    if (validForm) {
      this.payNavSer.showVodafoneLoader();
      // commented as to ba able to test the otp on testing env
      // we be removed when go to release
       const otpcode = MSISDNPrefix + this.anonPayService.anonymousPaymentCustomer.customerMSIDN + ':' + this.infoForm.get('otp')?.value;
      // const otpcode = MSISDNPrefix + this.anonPayService.anonymousPaymentCustomer.customerMSIDN + ':' + 'waotp';
      this.anonPayService.validateAnonymousPaymentOTP(otpcode).subscribe(() => {
        this.anonPayService.getUserDept(this.anonPayService.anonymousPaymentCustomer)
      }, err => {
        this.appService.showVodaFullLoader = false
        this.showOtpError = true;
        this.wrongOtp = true;
        this.infoForm.controls['otp'].setErrors({ 'incorrect': true });
        if (this.isTechError) {
          this.payNavSer.openPaymentComponent(PaymentPages.paymentOtp);
        }
        if (err.status === 401 && err?.error?.code === 1004) {
          this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.codeRetrialFailure,
            [
              {
                key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
                value: () => { this.payNavSer.closePayment() }
              }
            ]);

           const anonyKOVar: { '&&events': string, error_list: string } = { ...tagging.anonymousOtpErrors } ;
           const error_list: string = anonyKOVar.error_list.replace('<error_code>', err.error.code)
              .replace('<error_message>', 'pago anonimo').replace('<error_desc>', tagging.exceedvalidationLimit);
           this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
             this.taggingHelperService.error_list = error_list;
            const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.SECURITY_CODE_MAX_NUMBER_ATTEMPTS_EXCEDED;
            this.newTagging.state(data.page.stateList[constantName]);
          });
        }

        else if (err?.error?.code === 1007) {
          this.payNavSer.otpCode = this.infoForm.get('otp')?.value;
          this.infoForm.controls['otp'].setErrors({ 'incorrect': true });
          this.payNavSer.hideVodafoneLoader();
        }
        else if (err.status !== 401 && err?.error?.code !== 1007) {
          this.payNavSer.otpCode = this.infoForm.get('otp')?.value;
          this.infoForm.controls['otp'].setErrors(null);
          this.isTechError = true;
          this.payNavSer.otpTechError = this.isTechError;
          this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.codeTechnicalError,
            [
              {
                key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
                value: () => { this.submitOtp(!this.infoForm.invalid && this.counter !== 0)  }
              }
            ]);
        }
      })
    }
  }
  /**
   * function to resend otp to user
   */
  reSendOtp(): void {
    this.payNavSer.showVodafoneLoader();
    this.showOtpError = false;
    this.wrongOtp = false;
    this.infoForm.controls['otp'].reset('');
    const loginHint =
      MSISDNPrefix + this.anonPayService.anonymousPaymentCustomer?.customerMSIDN +
      nifPrefix + this.anonPayService.anonymousPaymentCustomer?.documentId;
    this.resendOTPCode(loginHint);
  }
  startCountDown(): void {
    this.counter = 601;
    this.tick = 1000;
    if (this.countDown) {
      this.countDown.unsubscribe()
    }
    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      if (this.counter === 0) {
        this.countDown.unsubscribe();
        this.infoForm.get('otp').setValue('')
        this.infoForm.get('otp').markAsPristine()
        this.infoForm.get('otp').markAsUntouched()
        this.infoForm.get('otp').setErrors(null)
        this.showOtpError = false;
        this.wrongOtp = false;
      }
    });
  }
  resendOTPCode(loginHint: string): void {
    this.anonPayService.validateAnonymousPaymentCustomer(loginHint).subscribe(() => {
      this.payNavSer.hideVodafoneLoader();
      if (this.isTechError) {
        this.payNavSer.openPaymentComponent(PaymentPages.paymentOtp);
      }
      this.startCountDown();
    }, err => {
      this.payNavSer.hideVodafoneLoader();
      if (err.status === 401 || err?.error?.code === 1318) {
        this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.codeRenewFailure,
          [
            {
              key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
              value: () => { this.payNavSer.closePayment() }
            }
          ]);
          const anonyKOVar: { '&&events': string, error_list: string } = { ...tagging.anonymousOtpErrors };
          const error_list: string = anonyKOVar.error_list.replace('<error_code>', err.error.code)
            .replace('<error_message>', 'pago anonimo').replace('<error_desc>', tagging.exceedResendlimit);
          this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
            this.taggingHelperService.error_list = error_list;
            const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.SECURITY_CODE_MAX_NUMBER_CLICKS_EXCEDED;
            this.newTagging.state(data.page.stateList[constantName]);
          });
      }
      else {
        this.isTechError = true;
        this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.codeTechnicalError,
          [
            {
              key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
              value: () => { this.reSendOtp() }
            }
          ]);
      }
    });
  }
  ngOnDestroy(): void {
    if (this.countDown) {
      this.countDown.unsubscribe()
    }
  }
}

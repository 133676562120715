import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpMiddlePageRoutingModule } from './sp-middle-page-routing.module';
import { SpMiddlePageComponent } from './sp-middle-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MiddlePageService } from './services/middle-page.service';



@NgModule({
  declarations: [ SpMiddlePageComponent ],
  imports: [
    CommonModule,
    TranslateModule,
    SpMiddlePageRoutingModule
  ],
  providers: [ MiddlePageService ]
})
export class SpMiddlePageModule { }

export class Template {
  status: string;
  taskId: number;
  form: {
    backButton?: string;
    template: string;
    averia?: string;
    texts: [
      {
        id: string;
        value: string;
      }
    ]
    media: [
      {
        id: string;
        reference: string;
        type: string;
      }
    ]
    inputs: [
      {
        id: string;
        name: string;
        value: string;
        label: string;
        type: string;
        readonly: string;
        required: string;
        minsize: string;
        maxsize: string;
        validator: string;
        errorMessage?: string;
      },
      {
        id: string;
        name: string;
        value: string;
        label: string;
        type: string;
        readonly: string;
        required: string;
        minsize: string;
        maxsize: string;
        validator: string;
        errorMessage?: string;
      }?,
    ];
    actions: [
      {
        id?: string;
        name: string;
        values: [
          {
            label: string;
            value: string;
            color?: string;
            deeplink?: string;
          },
          {
            label: string;
            value: string;
            color?: string;
            deeplink?: string;
          }?,
        ];
      },
      {
        id?: string;
        name: string;
        values: [
          {
            label: string;
            value: string;
            color?: string;
            deeplink?: string;
          }
        ];
      }?
    ],
    slide?: [
      {
        id?: string;
        valueImage1?: [
          {
            id: string;
            reference: string;
            type: string;
          }
        ],
        valueImage2?: [
          {
            id: string;
            reference: string;
            type: string;
          }
        ],
        valuesText?: [
          {
            id: string;
            value: string;
          },
          {
            id: string;
            value: string;
          },
          {
            id: string;
            value: string;
          },
          {
            id: string;
            value: string;
          }
        ]
      }
    ],
    analytics: string;
    noPopup: boolean;
    transformersOK: boolean;
    journey?: string;
    chatParti: boolean;
  };
  backend?: string;
  symptom?: string;
  technology?: string;
  deco?: string;
  ticketId?: string;
}

export class ReducedTemplate {
  status: string;
  taskId: number;
  form: {
    backButton?: string;
    template: string;
    averia?: string;
    texts: Text[];
    media: Media[];
    inputs: [
      {
        id: string;
        name: string;
        value: string;
        label: string;
        type: string;
        readonly: string;
        required: string;
        minsize: string;
        maxsize: string;
        validator: string;
        errorMessage?: string;
      },
      {
        id: string;
        name: string;
        value: string;
        label: string;
        type: string;
        readonly: string;
        required: string;
        minsize: string;
        maxsize: string;
        validator: string;
        errorMessage?: string;
      }?,
    ];
    actions: [
      {
        id: string;
        name: string;
        values: [
          {
            label: string;
            value: string;
            color?: string;
            deeplink?: string;
          },
          {
            label: string;
            value: string;
            color?: string;
            deeplink?: string;
          }?,
        ];
      },
      {
        id: string;
        name: string;
        values: [
          {
            label: string;
            value: string;
            color?: string;
            deeplink?: string;
          }
        ];
      }?
    ],
    slide?: [
      {
        id?: string;
        valueImage1?: [
          {
            id: string;
            reference: string;
            type: string;
          }
        ],
        valueImage2?: [
          {
            id: string;
            reference: string;
            type: string;
          }
        ],
        valuesText?: [
          {
            id: string;
            value: string;
          },
          {
            id: string;
            value: string;
          },
          {
            id: string;
            value: string;
          },
          {
            id: string;
            value: string;
          }
        ]
      }
    ],
    analytics: string;
    noPopup: boolean;
    transformersOK: boolean;
    journey?: string;
    chatParti: boolean;
  };
  backend?: string;
  symptom?: string;
  technology?: string;
  deco?: string;
  ticketId?: string;
}

export class Media {
  id: string;
  reference: string;
  type: string;
}

export class Text {
  id: string;
  value: string;
  size: string;
}

import { ContractType } from '../shared/enums/contract-type.enum';

export class Contract {
    penalty: string;
    type: ContractType;
    startDate: string;
    endDate: string;
    title?: string;
    endDateWithOutFormat?: string;
    subscription: {
        id?: string,
        package?: string
    };
}



export class RelatedContent {
    title: string;
    type: ContractType;
    desc: string;
    descWithDetails?: string;
}

import { tagging } from './../../../../config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { NavigationType } from './../../enums/navigation-type.enum';
import { SidemenuConfigService } from './../../services/sidemenu-config.service';
import { Router } from '@angular/router';
import { CommonService } from '../../../core/services/common.service';
import { SpSideMenuItem } from './sp-sidemenu.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UpgradeProfileService } from '../../../upgrade-profile/upgrade-profile.service';
import * as defines from '../../constants/defines';
import { AuthenticateService } from '../../../core/services/authenticate.service';
import { config } from '../../../../config/pages-config';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { SidemenuQuery } from '../../constants/defines';
import { CrossFunctionalityService } from '../../services/cross-functionality.service';
import { NotificationInboxService } from '../../services/notification-inbox.service';
import { Error } from '../../../models/error.model';
import { AppService } from '../../../app.service';
import { DashboardService } from '../../services/dashboard.service';
import { SpModalFullComponent } from '../sp-modal-full/sp-modal-full.component';
import { IGenericSuccessErrorTray } from '../../../models/generic-success-error-tray.model';
import { DynamicTrayService } from '../../services/dynamic-tray.service';
@Component({
  selector: 'sp-sidemenu',
  templateUrl: './sp-sidemenu.component.html',
  styleUrls: ['./sp-sidemenu.component.scss']
})
export class SpSidemenuComponent implements OnInit {
  @ViewChild('OverlyOneModal', { static: true }) OverlyOneModal: SpModalFullComponent;

  close = false;
  category: string;
  errorMessage: Error;
  public inboxRoute: string = config.Inbox.route.toLowerCase();
  constructor(public commonService: CommonService,
    private router: Router,
    private upgradeService: UpgradeProfileService,
    private authenticate: AuthenticateService,
    private cross: CrossFunctionalityService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    public menuService: SidemenuConfigService,
    private appService: AppService,
    private location: Location,
    public NotificationInboxService: NotificationInboxService,
    private tagging: TaggingHelperService,
    private dashboardService: DashboardService,
    private trayService: DynamicTrayService
  ) { }
  ngOnInit() {
    this.menuService.OverlyOneModal = this.OverlyOneModal;
    this.menuService.sideMenuSubject.subscribe(() => {
      if (this.menuService.allItemsInMenu.length > 0) {
        this.category = this.menuService.allItemsInMenu[0].category;
      }
      this.menuService.allItemsInMenu.forEach(item => {
        if (item.category && this.category && item.category.toLowerCase() !== this.category.toLowerCase()) {
          this.category = item.category;
          item.hasBorder = true;
        }
      });
    })
  }

  onClick(item: SpSideMenuItem) {
    this.hide();
    const menu = this;
    if (item.actionType) {
      if (this.authenticate.isAuthenticated() && !SidemenuQuery.logout.toLowerCase()) {
        this.NotificationInboxService.getNotificatoinCount();
      }
      if (item.actionType === defines.MENU_ACTION_TYPE.Command) {
        switch (item.command.toLowerCase()) {
          case SidemenuQuery.logout.toLowerCase():
            const trayData: IGenericSuccessErrorTray = {};

            this.translate.get('v10.common.logoutOverlay').subscribe(data => {
              /** to let close icon to be on right */
              trayData.title = ' '
              trayData.boldCenterTitle = data.title,
                /** remove padding top from img */
                trayData.imgMarginTop = -20,
                /** no need for padding for this img */
                trayData.staticImageNoPadding = true,
                trayData.staticImagePath = this.appService.getImgFullPath(data.icon);
              trayData.firstDescription = data.subtitle;
              trayData.primaryButton = {
                primaryButtonText: data.button1,
                primaryButtonAction: () => {
                  menu.menuService.allItemsInMenu = null;
                  menu.menuService.originalResponseItems = null;
                  menu.menuService.currentSiteStatus = null;
                  menu.menuService.currentSegment = null;
                  menu.menuService.menuItemsIsLoadedInResolver = false;
                  menu.appService.showGDPROverlay = false;
                  menu.appService.gdprOverlayLoaded = false;
                  menu.authenticate.logout();
                  if (item.navigationType.toLowerCase() === NavigationType.Internal) {
                    menu.router.navigate([config.login.route]);
                  } else if (item.navigationType.toLowerCase() === NavigationType.External) {
                    window.open(menu.location.prepareExternalUrl(menu.location.path()));
                  }
                  menu.router.navigate([config.login.route]);
                  this.trayService.close()
                }
              };

              trayData.secondaryButton = {
                secondaryButtonText: data.button2,
                secondaryButtonAction: () => {
                  this.trayService.close()
                }
              }
              this.trayService.openSuccessErrorTray(trayData);
            });
            this.dashboardService.isVOVBiztalkReplicaTabViewed = false;
            break;
        }
      }
    } else {
      if (item.locked) {
        this.upgradeService.targetRoute = item.redirectTo;
        this.upgradeService.startScenario = true;
        return;
      } else {
        const eventName =
          tagging.sidemenu.itemClicked.parentItem.data.event_name.replace('{0}', item.itemText);
        const data = {
          event_name: eventName
        }
        this.tagging.track(eventName, data);

        this.menuService.sidemenuItemClick(item);
      }

    }
  }
  hide() {
    this.commonService.showMenu = false;
    if (this.cross.hidebyMenu && !this.cross.showNudge) {
      this.cross.hidebyMenu = false;
      this.cross.showNudge = true;

    }
    this.NotificationInboxService.endCount()
  }
}


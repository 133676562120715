<div id="bundleChart" class="bundle-card-chart" >
    <ng-container *ngIf="!Bundle?.IsUnLimited && isActiveTarrif">
        <mva10-c-icon id="sharedErrorAlertCustomIcon" class="bundle-card-chart__icon"  [size]="25" [iconId]="'icon-data'" ></mva10-c-icon>
        <div id="chart" class="chart">
            <mva10-c-data-chart id="bundleChart" *ngIf="Bundle?.TotalConsumption && Bundle?.RemainConsumptionData && Bundle?.RemainConsumptionUnit === defines.GB "
            [valueText]="Bundle?.RemainConsumptionData + '/' + Bundle?.TotalConsumption / 1024 + Bundle?.RemainConsumptionUnit "
            [description]="Bundle?.title" [max]="Bundle?.TotalConsumption / 1024" [min]="0"
            [value]="Bundle?.RemainConsumptionData">
            </mva10-c-data-chart>
            <mva10-c-data-chart id="emptyBundle" *ngIf="Bundle?.TotalConsumption && Bundle?.RemainConsumptionData && Bundle?.RemainConsumptionUnit === defines.MB "
            [valueText]="Bundle?.RemainConsumptionData + '/' + Bundle?.TotalConsumption + Bundle?.RemainConsumptionUnit "
            [description]="Bundle?.title" [max]="Bundle?.TotalConsumption" [min]="0"
            [value]="Bundle?.RemainConsumptionData">
            </mva10-c-data-chart>
            <p *ngIf="!Bundle?.TotalConsumption && !Bundle?.RemainConsumptionData" >{{Bundle.title}}</p>
        </div>
     
    </ng-container>

    <ng-container *ngIf="!isActiveTarrif">
        <mva10-c-icon id="sharedErrorAlertCustomIcon" class="bundle-card-chart__icon"  [size]="25" [iconId]="'icon-data'" ></mva10-c-icon>
        <div id="chartUnlimited" class="chart">
            <mva10-c-data-chart
            [valueText]=""
            [description]="Bundle?.title" [max]="0" [min]="0"
            [value]="0">
        </mva10-c-data-chart>
        </div>
    </ng-container>

    <ng-container *ngIf="Bundle?.IsUnLimited && isActiveTarrif">
        <mva10-c-icon id="sharedErrorAlertCustomIcon" class="bundle-card-chart__icon"  [size]="25" [iconId]="'icon-data'"></mva10-c-icon>
        <div class="chart">
            <mva10-c-data-chart [max]="100" [min]="0" [description]="Bundle?.Name"
                valueText="{{'v10.productsServices.extras.unlimited'|translate}}">
            </mva10-c-data-chart>
        </div>   
    </ng-container>
</div>

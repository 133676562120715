
<div id="enjoymore" class="enjoymore" *ngIf="enjoyMoreBundles?.length > 0">
  <span id="name" class="enjoymore__text" *ngFor="let enjoyMoreBundle of enjoyMoreBundles let i = index">{{enjoyMoreBundle.Name}}
    <span  id="seperator" class="enjoymore__text" *ngIf="i ===  0 && enjoyMoreBundles.length === 2 "> {{'v10.productsServices.miTarifaCard.consumption.includedPasses.items.lastItem.separator' | translate}}
    </span>
    <span id="andseperator" class="enjoymore__text" *ngIf="i ===  0 && enjoyMoreBundles.length === 3 "> {{'v10.productsServices.miTarifaCard.consumption.includedPasses.items.separator' | translate}}
    </span>
    <span id="seperators" class="enjoymore__text" *ngIf="i ===  1 && enjoyMoreBundles.length === 3 "> {{'v10.productsServices.miTarifaCard.consumption.includedPasses.items.lastItem.separator' | translate}}
    </span>
  </span>
  <span id="include" class="enjoymore__text"> {{'v10.productsServices.miTarifaCard.consumption.includedPasses.postfixText' | translate}}
  </span>
</div>
export let loginErrors = {
    '401': {
      '1001': 'C',
      '1004': 'A',
      '1005': 'B',
      '1012': 'B',
      '1025': 'B',
      '1027': 'C',
      '1028': 'B',
      '1029': 'A',
      '1003': 'D',
      '1024': 'D'
    },
    '403': {
      '1013': 'B',
      '1014': 'B',
      '1016': 'B',
      '1030': 'B',
      '1047': 'D',
      '1010': 'D',
      '1019': 'D',
      '1020': 'D',
      '1315': 'D',
      '1316': 'D',
      '1007': 'D',
      '1319': 'D',
      '1201': 'D',

    },
    '400': {
      '1200': 'D',
      '4003': 'E'
    },
    '500': {
      '1043': 'D',
      '4006': 'E'
    },
    '504': {
      '1008': 'D'
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { ServiceBalanceWs10Service } from 'src/app/shared/services/service-balance-ws10.service';
import { BundleType, SuccessScreenTaggingLiterals } from 'src/app/shared/constants/defines';
import { CustomerBillService } from 'src/app/shared/services/customer-bill.service';
import { PaymentNavigationService } from '../../services/payment-navigation.service';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { PaymentCompoentInputData_Keys } from 'src/app/shared/enums/payment-compoent-input-data-keys.enum';
import { TopUpService } from 'src/app/top-up/top-up.service';
import { tagging } from 'src/config/tagging-config';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
import { TaggingViewModel } from 'src/app/models/tagging.model';
@Component({
  selector: 'sp-top-up-count-down',
  templateUrl: './top-up-count-down.component.html',
  styleUrls: ['./top-up-count-down.component.scss']
})
export class TopUpCountDownComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  counter: number = 13;
  tick: number = 1000;
  counterToDisplay: string = '';
  showLoader: boolean = true;
  currentNewBalance: string = '';
  constructor(private serviceBalanceService: ServiceBalanceWs10Service,
    private customerBillService: CustomerBillService,
    private paymentNavigationService: PaymentNavigationService,
    private topUpService: TopUpService,
    private taggingHelperService: TaggingHelperService) { }

  ngOnInit(): void {
    this.sendingTopUpCountDownTagging();
    this.startCountDown();
  }

  sendingTopUpCountDownTagging(): void {
    const page: TaggingViewModel = {...tagging.afterTopUp.countDownPage.state};
    this.topUpService.promoCode  ? page['&&products'] =
    page['&&products'].replace(SuccessScreenTaggingLiterals.promoCodeKey, `;eVar207=${this.topUpService.promoCode}`) :
    page['&&products'] = SuccessScreenTaggingLiterals.productValue
    this.taggingHelperService.view(null, page)
  }

  startCountDown(): void {
    this.counterToDisplay = this.counter.toString();
    if (this.countDown) {
      this.countDown.unsubscribe()
    }
    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      this.counterToDisplay = this.counter.toString();
      if (this.counter === 0) {
        this.getServiceBalance();
        this.countDown?.unsubscribe();
      }
      else if (this.counter < 10) {
        this.counterToDisplay = `0 ${this.counter}`;
      }
    });
  }

  getServiceBalance(): void {
    this.serviceBalanceService.getServiceBalance(BundleType.inPlan, false, this.topUpService.selectedServiceId)
    .subscribe(res => {
      this.showLoader = false;
      this.customerBillService.getAmountPrepaid(res.items);
      this.currentNewBalance = this.customerBillService.valueAmountPrepaid;
      if (this.topUpService.currentBalance !== this.currentNewBalance) {
        this.paymentNavigationService.openPaymentComponent(PaymentPages.HowToSpendNewBalance,
          PaymentJourny.prepaidTopup,
          [{key: PaymentCompoentInputData_Keys.TopUpBalanceChanged, value: true},
          {key: PaymentCompoentInputData_Keys.TopUpnewBalance, value: this.currentNewBalance}]);
      }
      else {
        this.navigateToSpendBalanceComp(false);
      }
    },
    err => {
      this.showLoader = false;
      this.navigateToSpendBalanceComp(false);
    });
  }

  navigateToSpendBalanceComp(isChanged: boolean): void {
    this.paymentNavigationService.openPaymentComponent(PaymentPages.HowToSpendNewBalance,
      PaymentJourny.prepaidTopup,
      [{key: PaymentCompoentInputData_Keys.TopUpBalanceChanged, value: isChanged}]);
  }
  ngOnDestroy(): void {
    if (this.countDown) {
      this.countDown?.unsubscribe();
    }
  }
}

import { FaultManagmentServiceType } from '../shared/enums/fault-managment-service-type.enum';

export class ServiceAvailability {
    public hasMassiveTicket: boolean;
    public massiveTicketId: string;
    public massiveTicketType: FaultManagmentServiceType
    public hasOrderTicket: boolean;
    public isMobile: boolean;
    public isInternet: boolean;
    public isTV: boolean;
    public isFixed: boolean;

    get hasAlerts() {
        return this.hasOrderTicket || this.hasMassiveTicket
    }
}
export class ServiceAvailablityType {
    isMobile: boolean = false;
    isFixed: boolean = false;
    isInternet: boolean = false;
    isTv: boolean = false;
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TicketTemplateCode, TicketStatus, TicketImageName } from '../shared/enums/ticket-status.enum';
import { TimeLineItemModel, BulletTypeEnum, TimeLineItemDescription, FontTypeEnum } from '../shared/models/time-line-item.model';
import { UtilitiesService } from '../shared/utils/utilities.service';
import { AppService } from '../app.service';
import { tagging } from '../../config/tagging-config';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { FaultManagementService } from './fault-management.service';
import { TicketModel } from '../models/ticket.model';
import { lowBarM, lowBarSLA } from '../shared/constants/defines';
import { StorageService } from '../core/services/storage.service';
import moment from 'moment';


@Injectable()
export class TicketsboxService {
  constructor(
    private translate: TranslateService,
    private utilitiesService: UtilitiesService,
    private tagging: TaggingHelperService,
    public appService: AppService,
    private faultManagementService: FaultManagementService,
    private storageService: StorageService,
  ) { }
  mapTicketCodeToBullets(ticket: TicketModel, outSLA: boolean): TimeLineItemModel[] {
    const bulletList = new Array<TimeLineItemModel>();
    const diffTOA: number = moment().diff(
      moment(ticket.template.templateDate + 'T' + ticket.template.visitTo, 'DD-MM-YYYY"T"hh:mm'), 'hour');
    this.translate.get('faultManagement').subscribe(data => {
      const firstBullet = new TimeLineItemModel(data.itemsList.inDiagnosis.body, [], BulletTypeEnum.redCircle, null, true)
      const secondBullet = new TimeLineItemModel(data.itemsList.inSolution.body, [], BulletTypeEnum.redCircle, null, true)
      const thirdBullet = new TimeLineItemModel(data.itemsList.troubleShooting.body, [], BulletTypeEnum.grayCircle, null, false);
      if (ticket.status === TicketStatus.Closed) {
        this.prepareTroubleShootingTemplate(ticket, secondBullet, thirdBullet, data);
      } else if (ticket.status === TicketStatus.Open) {
        switch (ticket.template.templateCode) {
          case TicketTemplateCode.CONTRATA_WITH_APPOINTMENT:
            this.prepareInSolutionTemplateWithAppointment(ticket, secondBullet, thirdBullet, data, diffTOA);
            break;
          case TicketTemplateCode.FRONT_OFFICE:
            this.prepareInDiagnosisTemplate(ticket, firstBullet, secondBullet, thirdBullet, data, outSLA)
            break;
          default:
            this.prepareInSolutionTemplate(ticket, secondBullet, thirdBullet, data, outSLA, diffTOA);
            break;
        }
      }
      bulletList.push(firstBullet, secondBullet, thirdBullet)
    })
    return bulletList;
  }
  prepareInSolutionTemplate(
    ticket: TicketModel,
    secondBullet: TimeLineItemModel,
    thirdBullet: TimeLineItemModel,
    wcs: any,
    outSLA: boolean,
    diffTOA: number
  ): void {
    let url: string
    if (
      ticket.template.templateCode === TicketTemplateCode.MOVIL_SOLUCIONADOYSLA ||
      ticket.template.templateCode === TicketTemplateCode.MOVIL_ILOC_FALTANDATOS
      ) {
      url = this.appService.getImgFullPath(
        wcs.messagesList[TicketTemplateCode.DIAGNOSTICO + lowBarM][TicketTemplateCode.DIAGNOSTICO + lowBarM + '_icon'].url
      );
    } else if (
      ticket.template.templateCode === TicketTemplateCode.GEOLOCALIZACION ||
      ticket.template.templateCode === TicketTemplateCode.GEOLOCALIZACION2
    ) {
      url = this.translate.instant(`v10.faultManagement.images.dynamicLanding.${ticket.template.templateCode.toLocaleLowerCase()}.url`)
    } else {
      url = this.appService.getImgFullPath(
        wcs.messagesList[ticket.template.templateCode + lowBarM][ticket.template.templateCode + lowBarM + '_icon'].url
      );
      secondBullet.descriptions = [
        new TimeLineItemDescription(wcs.messagesList[ticket.template.templateCode + lowBarM]
        [ticket.template.templateCode + lowBarM + '_description']
          , false, null, null, false, url)
      ]
    }
    secondBullet.descriptions = this.secondBulletDescriptionsByTemplateCode(ticket, secondBullet, outSLA, wcs, url);
    secondBullet.bulletType = BulletTypeEnum.shallowCircle;
    secondBullet.fontType = FontTypeEnum.bold;
    secondBullet.redBorderToNextPoint = false;
    if (ticket.template.templateCode === TicketTemplateCode.GENERAL_BREAKDOWN_WITH_DATE) {
      if (diffTOA > 0) {
        const desc: string =
        wcs.messagesList[TicketTemplateCode.CONTRATA_APPOINTMENT_WITHOUT_DATE + lowBarSLA]
        [TicketTemplateCode.CONTRATA_APPOINTMENT_WITHOUT_DATE + lowBarSLA + '_description']
        secondBullet.descriptions[0].text = desc;
      } else {
        let desc: string =
          wcs.messagesList[TicketTemplateCode.GENERAL_BREAKDOWN_WITH_DATE + lowBarM]
          [TicketTemplateCode.GENERAL_BREAKDOWN_WITH_DATE + lowBarM + '_description']
        const hours: string = ticket.template.templateDate ?
          this.utilitiesService.dateFormateWithHours(ticket.template.templateDate).split(',')[0] : '';
        const date: string = ticket.template.templateDate ?
          this.utilitiesService.dateFormateWithHours(ticket.template.templateDate).split(',')[1] : '';
        if (desc.includes('{0}')) {
          desc = desc.replace('{0}', date)
        }
        if (desc.includes('{1}')) {
          desc = desc.replace('{1}', hours)
        }
        secondBullet.descriptions[0].text = desc;
      }
      return;
    }
    if (secondBullet.descriptions[0].text.includes('{0}')) {
      secondBullet.descriptions[0].text = secondBullet.descriptions[0].text.replace(
        '{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName)
      )
    }
  }

  secondBulletDescriptionsByTemplateCode(
    ticket: TicketModel,
    secondBullet: TimeLineItemModel,
    outSLA: boolean,
    wcs: any,
    url: string
  ): TimeLineItemDescription[] {
    switch (ticket.template.templateCode) {
      case TicketTemplateCode.MRI:
      case TicketTemplateCode.GENERAL_BREAKDOWN_WITHOUT_DATE:
      case TicketTemplateCode.DIAGNOSTICO:
      case TicketTemplateCode.CONTRATA_APPOINTMENT_WITHOUT_DATE:
        if (outSLA) {
          secondBullet.descriptions = [
            new TimeLineItemDescription(wcs.messagesList[ticket.template.templateCode + lowBarSLA]
            [ticket.template.templateCode + lowBarSLA + '_description']
              , false, null, null, false, url)
          ]
        }
        break;
      case TicketTemplateCode.MOVIL_SOLUCIONADOYSLA:
        if (outSLA) {
          secondBullet.descriptions = [
            new TimeLineItemDescription(wcs.messagesList[TicketTemplateCode.DIAGNOSTICO + lowBarSLA]
              [TicketTemplateCode.DIAGNOSTICO + lowBarSLA + '_description']
                , false, null, null, false, url)
          ]
        } else {
          secondBullet.descriptions = [
            new TimeLineItemDescription(wcs.messagesList[TicketTemplateCode.DIAGNOSTICO + lowBarM]
            [TicketTemplateCode.DIAGNOSTICO + lowBarM + '_description']
              , false, null, null, false, url)
          ]
        }
        break;
      case TicketTemplateCode.MOVIL_ILOC_FALTANDATOS:
        secondBullet.descriptions = [
          new TimeLineItemDescription(wcs.messagesList[TicketTemplateCode.DEFAULT + lowBarM]
          [TicketTemplateCode.DEFAULT + lowBarM + '_description']
            , false, null, null, false, url)
        ]
        break;
      case TicketTemplateCode.GEOLOCALIZACION:
      case TicketTemplateCode.GEOLOCALIZACION2:
        secondBullet.descriptions = [
          new TimeLineItemDescription(
            this.translate.instant(`v10.faultManagement.contentList.dynamicLanding.${TicketTemplateCode.GEOLOCALIZACION.toLocaleLowerCase()}.body`),
            false, null, null, false, url)
        ]
        break;
    }
    return secondBullet.descriptions;
  }

  prepareInSolutionTemplateWithAppointment(
      ticket: TicketModel,
      secondBullet: TimeLineItemModel,
      thirdBullet: TimeLineItemModel,
      wcs: any,
      diffTOA: number
    ): void {
    const url: string = this.appService.getImgFullPath(
      wcs.messagesList[ticket.template.templateCode][ticket.template.templateCode + '_icon'].url
    );
    if (diffTOA >= 0) {
      secondBullet.descriptions = [
        new TimeLineItemDescription(wcs.messagesList[ticket.template.templateCode + lowBarSLA]
        [ticket.template.templateCode + lowBarSLA + '_description']
          , false, null, null, false, url)
      ]
    } else {
      secondBullet.descriptions = [
        new TimeLineItemDescription(wcs.messagesList[ticket.template.templateCode]
        [ticket.template.templateCode + '_description']
          , false, null, null, false, url)
      ]
      if (ticket.template.templateCode === TicketTemplateCode.CONTRATA_WITH_APPOINTMENT) {
        let desc: string =
          wcs.messagesList[TicketTemplateCode.CONTRATA_WITH_APPOINTMENT]
          [TicketTemplateCode.CONTRATA_WITH_APPOINTMENT + '_description']
        const date: string = ticket.template.templateDate ? ticket.template.templateDate : '';
        const visitFrom: string = ticket.template.visitFrom ? ticket.template.visitFrom : '';
        const visitTo: string = ticket.template.visitTo ? ticket.template.visitTo : '';
        if (desc.includes('{0}')) {
          desc = desc.replace('{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName))
        }
        if (desc.includes('{1}')) {
          desc = desc.replace('{1}', date)
        }
        if (desc.includes('{2}')) {
          desc = desc.replace('{2}', visitFrom)
        }
        if (desc.includes('{3}')) {
          desc = desc.replace('{3}', visitTo)
        }
        secondBullet.descriptions[0].text = desc;
        return;
      }
    }
    secondBullet.bulletType = BulletTypeEnum.shallowCircle;
    secondBullet.fontType = FontTypeEnum.bold;
    secondBullet.redBorderToNextPoint = false;
    if (secondBullet.descriptions[0].text.includes('{0}')) {
      secondBullet.descriptions[0].text = secondBullet.descriptions[0].text.replace(
        '{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName)
      )
    }
  }
  prepareInDiagnosisTemplate(
    ticket: TicketModel,
    firstBullet: TimeLineItemModel,
    secondBullet: TimeLineItemModel,
    thirdBullet: TimeLineItemModel,
    wcs: any,
    outSLA: boolean
  ): void {
    const url: string = this.appService.getImgFullPath(
      wcs.messagesList[ticket.template.templateCode + lowBarM][ticket.template.templateCode + lowBarM + '_icon'].url
    );
    if (outSLA) {
      firstBullet.descriptions = [
        new TimeLineItemDescription(wcs.messagesList[TicketTemplateCode.FRONT_OFFICE + lowBarSLA]
        [TicketTemplateCode.FRONT_OFFICE + lowBarSLA + '_description']
          , false, null, null, false, url)
      ]
    } else {
      firstBullet.descriptions = [
        new TimeLineItemDescription(wcs.messagesList[TicketTemplateCode.FRONT_OFFICE + lowBarM]
        [TicketTemplateCode.FRONT_OFFICE + lowBarM + '_description']
          , false, null, null, false, url)
      ]
    }
    firstBullet.redBorderToNextPoint = false;
    firstBullet.bulletType = BulletTypeEnum.shallowCircle;
    firstBullet.fontType = FontTypeEnum.bold;
    secondBullet.bulletType = BulletTypeEnum.grayCircle;
    secondBullet.redBorderToNextDescription = false;
    secondBullet.redBorderToNextPoint = false;
    secondBullet.fontType = FontTypeEnum.gray;
    thirdBullet.fontType = FontTypeEnum.gray;
    if (firstBullet.descriptions[0].text.includes('{0}')) {
      firstBullet.descriptions[0].text = firstBullet.descriptions[0].text.replace(
        '{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName)
      )
    }
  }
  prepareTroubleShootingTemplate(ticket: TicketModel, secondBullet: TimeLineItemModel, thirdBullet: TimeLineItemModel, wcs: any): void {
    const url: string = this.appService.getImgFullPath(
      wcs.messagesList[ticket.template.templateCode + lowBarM][ticket.template.templateCode + lowBarM + '_icon'].url
    );
    thirdBullet.descriptions = [
      new TimeLineItemDescription(wcs.messagesList[ticket.template.templateCode + lowBarM]
      [ticket.template.templateCode + lowBarM + '_description']
        , false, null, null, false, url)
    ]
    thirdBullet.bulletType = BulletTypeEnum.shallowCircle;
    thirdBullet.fontType = FontTypeEnum.bold;
    secondBullet.redBorderToNextPoint = true;
    secondBullet.redBorderToNextDescription = false;
    if (thirdBullet.descriptions[0].text.includes('{0}')) {
      thirdBullet.descriptions[0].text = thirdBullet.descriptions[0].text.replace(
        '{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName)
      )
    }
  }
  mapImgName(icon, wcsData) {
    let url: string = '';
    switch (icon.toLowerCase()) {
      case TicketImageName.generic.toLowerCase():
        url = this.appService.getImgFullPath(wcsData.icono_engineer.url);
        break;
      case TicketImageName.mobile.toLowerCase():
        url = this.appService.getImgFullPath(wcsData.icono_mobile.url);
        break;
      case TicketImageName.landLine.toLowerCase():
        url = this.appService.getImgFullPath(wcsData.icono_business_phone_or_fixed_line.url);
        break;
      case TicketImageName.musicAndMovies.toLowerCase():
        url = this.appService.getImgFullPath(wcsData.icono_tvmusic.url);
        break;
      case TicketImageName.router.toLowerCase():
        url = this.appService.getImgFullPath(wcsData.icono_router.url);
        break;
      case TicketImageName.tv.toLowerCase():
        url = this.appService.getImgFullPath(wcsData.icono_TV.url);
        break;
    }
    return url;
  }
  reOpenCloseBtnTagging(ticket) {
    if (ticket.status === TicketStatus.Open) {
      tagging.faultManagement.closeOpenFault.start.data.journey_subcategory =
        tagging.staticVariables.journeySubCategory.replace('<ticket-type>', ticket.ticketType);
      tagging.faultManagement.closeOpenFault.start.data.journey_options = ticket.title;
      this.tagging.track(tagging.faultManagement.closeOpenFault.start.eventName,
        tagging.faultManagement.closeOpenFault.start.data);
    } else {
      tagging.faultManagement.reOpenClosedFault.start.data.journey_subcategory =
        tagging.staticVariables.journeySubCategory.replace('<ticket-type>', ticket.ticketType);
      tagging.faultManagement.reOpenClosedFault.start.data.journey_options = ticket.title;
      this.tagging.track(tagging.faultManagement.reOpenClosedFault.start.eventName,
        tagging.faultManagement.reOpenClosedFault.start.data);
    }
  }
  showHideBtnTagging(ticket, showTicketDetail: boolean) {
    let btnName = ''
    this.translate.get('faultManagement').subscribe(data => {
      if (showTicketDetail) {
        btnName = data.itemsList.showLessDetails.body
      } else {
        btnName = data.buttonList.showMoreDetails.text
      }
    })
    const data = Object.assign({}, tagging.faultManagement.showHideTicketDetails.data);
    const eventName = tagging.faultManagement.showHideTicketDetails.eventName.replace('{0}', btnName);
    data.issue_type = this.faultManagementService.specificAveriaVariables.issue_type;
    data.issue_information = this.faultManagementService.specificAveriaVariables.issue_information;
    data.issue_type_description = ticket.title;
    if (ticket.template && ticket.template.templateCode && showTicketDetail) {
      data['issue_wcs_codes'] = ticket.template.templateCode;
    }
    data.event_name = data.event_name.replace('{0}', btnName);
    this.tagging.track(eventName, data);
  }
  retryGetTicketDetailsBtnTagging(ticket) {
    const data = Object.assign({}, tagging.faultManagement.retryGetTicketdetails.data);
    data.issue_type = this.faultManagementService.specificAveriaVariables.issue_type;
    data.issue_information = this.faultManagementService.specificAveriaVariables.issue_information;
    data.issue_type_description = ticket.title;
    this.tagging.track(tagging.faultManagement.retryGetTicketdetails.eventName, data);
  }
}

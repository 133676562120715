import { CrossFunctionalityServiceName } from './../enums/CrossFunctionalityServiceName.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { functions } from '../enums/functions.enums';
import { Product } from '../../models/product.model';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CrossFunctionalityService {
  miWifi: boolean;
  nudgeClosedEmitter = new Subject();
  upgradeTvClass: boolean;
  imgPath: string;
  imgPath2: string;
  show = false;
  isShowActiveForm = false;
  removeData = false;
  isPendingActivation = false;
  isPendingDeActivation = false;
  itemName: string;
  itemDescription: string;
  productID: string;
  showLoading = false;
  status;
  disablefield = false;
  showNudge = false;
  email: string;
  username: string;
  showErrorBackendMessage = false;
  isProductInActive = false
  isProductActive = false
  isDimmedButton = false
  hidebyMenu = false;
  dismissedNudgeProducts: Array<string>;
  prepaidNudgeProducts: Array<object>;
  nudgeDescription: string;
  pending = false;
  extraDescription: string;
  promotionCode: string = undefined;
  serviceName: CrossFunctionalityServiceName = CrossFunctionalityServiceName.Products;
  isWorryFree = false;
  isEditAccount = false;
  iserrorEditAccount = false;
  retryWorryFree: Function;
  // snackbar/toast
  showToast = false;
  toastMessage: string;
  toastBackgroundColor: string;
  toastIconBackgroundColor: string;
  toastIconColor: string;
  toastBigIcon: boolean;
  editToastUi: boolean;
  notResetToast: boolean;

  isErrorAddAuthorized = false;
  changeUsernam: Boolean;
  showErrorActivation: Boolean;
  isErrorDeepLinking: boolean;
  showOneButton: boolean;
  showNudgeButtons: boolean = true;
  buttonText: string;
  changeNudgeEmitter = new BehaviorSubject<any>('');
  firstButtonText: string;
  firstButtonAction;
  secondButtonText: string;
  secondButtonAction;
  customNudge: boolean;
  nudgeTitle: string;
  productName: string;
  navigation: boolean;
  iserrorEditPermissions = false;
  iserrorEditPreferences = false;
  productActivationEmmiter = new Subject<any>();
  withBenefit: boolean;
  renewed: boolean;
  renew: boolean;
  alert: boolean;
  showRetryButton: boolean;
  retryButtonText: string;
  continueBtnText: string;
  // cancel btn for cross functionality ovelay second btn
  cancelBtnText: string;
  serviceCode?;
  changeToBill: boolean;
  subscriptionId?: string;
  functionsType?: functions;
  serviceId?: string;
  productSubject: Subject<any> = new Subject<any>();
  serviceSetting: Subject<any> = new Subject<any>();
  isActiveSuperWifi: boolean = false;
  isPendingActivationSuperWifi: boolean = false;
  isInactiveSuperWifi: boolean = false;
  lineRestrictionsSubject: Subject<any> = new Subject<any>();
  patchPayload: any;
  siteId: string;
  confirmButtonFunc: any;
  /** true=mean that the product status is inactive and it's promotable (promotionStatus=inactive) */
  isPromoProduct: boolean;
  /** true=mean that the product status is active and it's promoted before (promotionStatus=active) */
  isPromotedProduct: boolean;
  openTray: boolean;
  /** falg for show tray */
   showTray: boolean;
  productActivationCancelOrFailedEmmiter = new Subject<any>();
  public isBond5G: boolean;
  public titleBond5G: string;

  constructor(private translate: TranslateService) {
    this.dismissedNudgeProducts = new Array<string>();
    this.prepaidNudgeProducts = new Array<object>();
  }
  hide() {
    this.showLoading = false;
    this.withBenefit = false;
    this.show = false;
    if (this.hidebyMenu) {
      this.showNudge = true;
      this.hidebyMenu = false;

    }
  }
  // this function is to reset cross function modal after use it
  // please use it in ondestroy of your component
  // or before showing the nudge
  resetCrossFunctionalityOverLay() {
    this.cancelBtnText = '';
    this.continueBtnText = '';
    this.serviceName = CrossFunctionalityServiceName.Products;
    this.showErrorBackendMessage = false;
    this.showErrorActivation = false;
    this.pending = false;
  }
  resetNudge() {
    this.showOneButton = false;
    this.showRetryButton = false;
    this.showNudge = false;
    this.nudgeTitle = '';
    this.nudgeDescription = '';
    this.firstButtonText = '';
    this.buttonText = '';
    this.retryButtonText = '';
  }

  // this  function is checking if user try to change status of product
  // try to (active or deactivate) by checking the current and previous status of the product
  // and implment cash of nudge
  // it take 2 params
  // product  we need to check it's status
  // and boolean if we need to show general error and desc on nudge
  // it will return object containg 2 boolean  first (showNudge) true if we should show nudge so the  caller can
  // custom the tittle and or decription on his copmonent
  // second one if true that mean user was activating the product so we can prepare the cross functionality overlay
  // to the user to activate again the component
  checkUserLastProductRequestStatus(product: Product, showGerneralTittle_desc_for_nudge: boolean,
    showOneButton: boolean = false): { showNudge: boolean, buy: boolean } {
    const result = { showNudge: false, buy: false }
    this.showOneButton = showOneButton;
    if (product.currentStatus && product.lastStatus &&
      ((product.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() &&
        product.lastStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase()) ||
        (product.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase() &&
          product.lastStatus.toLowerCase() === BundleStatus.PendingActivation.toLowerCase()))
      && !this.dismissedNudgeProducts.includes(product.Code.toLowerCase())) {
      this.status = product.lastStatus;
      if (product.lastStatus.toLowerCase() === BundleStatus.PendingActivation.toLowerCase()) {
        result.buy = true;
      }
      if (showGerneralTittle_desc_for_nudge) {
        this.translate.get('productsServices.messagesList').subscribe(data => {
          // UAT CR: Remove The Product Name from the nudge as it gets to multiple lines
          this.nudgeTitle = this.nudgeDescription = result.buy ?
            data['psNudgeActErr']['title'] :
            data['psNudgeDeActErr']['title'];
            this.nudgeDescription = result.buy ?
            data['psNudgeActErr']['description'] :
            data['psNudgeDeActErr']['description'];
        });
        this.showNudge = true;
      }
      result.showNudge = true;
    }
    return result;
  }

}

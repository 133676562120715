
import { throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CustomerAccountService } from './../../shared/services/customer-account.service';
import { Error } from './../../models/error.model';
import { ErrorHandlingService } from './../../core/services/errorHandling.service';
import { NotificationService } from '../../shared/services/notification.service';
import { Notification } from './../../models/notification.model';
import { PAGES } from '../../shared/constants/defines';
import { RetryService } from '../../shared/services/retry.service';
@Injectable()
export class PermissionsAndPreferencesResolver implements Resolve<any> {
    permissionError: Error;
    constructor(private customerAccountService: CustomerAccountService,
        private errorHandling: ErrorHandlingService,
        private notificationService: NotificationService,
        private retryService: RetryService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.customerAccountService.getPermissionsAndPreferences().pipe(map(() => {
            return true;
        }), catchError(error => {
            // set tagging error Variables
            const notificationError = new Notification();
            this.permissionError = this.errorHandling.lookUpError(PAGES.MY_ACCOUNT, error);
            if (this.permissionError) {
                notificationError.bodyContent = this.permissionError.description;
                notificationError.bodyTitle = this.permissionError.title;
                notificationError.primaryButtonText = this.permissionError.dismissButtonText ||
                    this.permissionError.confirmButtonText;
                notificationError.showRetryButton = this.permissionError.retryButton;
                this.retryService.url = state.url;
                notificationError.primaryButtonClick = () => {
                    this.notificationService.notificationModal.hide();
                };
                this.notificationService.createNotification(notificationError);
            }

            return throwError(null);

        }));
    }
}

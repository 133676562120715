import { EverythingOkModel } from './../../models/everything-ok.model';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_URLS } from '../../shared/constants/routes-config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { JSON_PATHS } from '../../shared/constants/defines';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class EverythingOkService {

  public everythingOkStatus: EverythingOkModel;

  constructor(
    private http: HttpClient
  ) {}

  public getEverythingOk(customerId: string): Observable<EverythingOkModel> {

    const url = API_URLS.EverythingOk.replace('{0}', customerId);

    const headers = new HttpHeaders();
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((data: any) => {
      return this.mapEverythingOk(data, customerId);
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  private mapEverythingOk(data: any, customerId: string): EverythingOkModel {
    this.everythingOkStatus = new EverythingOkModel();
    this.everythingOkStatus.customerId = customerId;

    this.everythingOkStatus.issues =
      data?.characteristic?.filter(characteristic => characteristic.name === JSON_PATHS.EverythingOkStatus.issuesName)[0]?.value;
    this.everythingOkStatus.debtAmount = data?.bucket?.bucketBalance[0]?.remainingValue.amount;

    return this.everythingOkStatus;
  }

}

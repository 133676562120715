import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../shared/constants/routes-config';
import { Observable } from 'rxjs';
import { GetCoutesResponse } from '../models/coute.model';
@Injectable()
export class PurchaseProductsData {
  constructor(private http: HttpClient) { }

  GetCoutes(customerAccountId: string, isFinancing?: boolean): Observable<GetCoutesResponse> {
    let url = API_URLS.PurchaseProducts.GetCoute;
    if (customerAccountId) {
      url += `?customerAccountId=${customerAccountId}`;
      if (isFinancing) {
        url += '&financing=true';
      }
    }

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }
  GetSVAs(customerAccountId) {
    let url = API_URLS.PurchaseProducts.GetSVAs;
    if (customerAccountId) {
      url += `?customerAccountId=${customerAccountId}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }
  GetSVAsByType(customerAccountId, productType)  {
    const url = `${API_URLS.PurchaseProducts.GetSVAs}?customerAccountId=${customerAccountId}&productType=${productType}`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }
}

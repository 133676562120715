import { Injectable } from '@angular/core';
import { sha256 } from 'js-sha256';

@Injectable({
  providedIn: 'root',
})
export class HashService {
  constructor() {}

  getSha256(stringToHash: string): string {
    return sha256(stringToHash);
  }
}

import { PaymentJourneyDetail } from '../../../shared/enums/payment-journey-detail.enum';
import { PaymentJourneyProcess } from '../../../shared/enums/payment-journey-process.enum';
import { PaymentJourneyUserType } from '../../../shared/enums/payment-journey-user-type.enum';
import { tagging } from '../../../../config/tagging-config';
import { CrossVariables, TaggingViewModel } from '../../../models/tagging.model';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentTaggingDefault, PaymentTaggingInterface } from './payment-tagging-interface';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentJourneySubCategory } from '../../../shared/enums/payment-journey-sub-category.enum';
export class PaymentTaggingCreditCard extends PaymentTaggingDefault implements PaymentTaggingInterface {
    constructor(
        public tagging: TaggingHelperService,
        public paymentNavigationService: PaymentNavigationService
    ) {
        super(tagging, null, paymentNavigationService);
    }
    /**
     * Tagging for ticket screen for payment journey with credit card
     */
    tagOpenPaymentTicketScreen(): void {
        const paymentPageName: TaggingViewModel = Object.assign({}, tagging.paymentCreditCardDetailsPageName);
        const paymentJourney: TaggingViewModel = Object.assign({}, tagging.paymentDetailsJourneyCreditCard);
        paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
            tagging.staticVariables.paymentCreditCardPageName);
        paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
            tagging.staticVariables.paymentCreditCardPageName);
        paymentJourney.journey_category = CrossVariables.client_typology;
        paymentJourney.journey_subcategory = PaymentJourneySubCategory.postpaid;
        paymentJourney.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        paymentJourney.journey_detail = PaymentJourneyDetail.pasarela;
        paymentJourney.state_flow = tagging.genericCheckout.data.state_flow;
        this.tagging.view(paymentPageName, paymentJourney);
    }
    /**
     * Tagging for download button in ticket screen for payment journey with credit card
     */
    tagDownLoadTicket(): void {
        const paymentPageName: TaggingViewModel = Object.assign({}, tagging.paymentCreditCardDetailsPageName);
        const paymentJourney: TaggingViewModel = Object.assign({}, tagging.paymentDetailsJourneyCreditCard);
        let eventName: string = '';
        eventName = tagging.downloadTicket.eventName.replace('<page_name>', tagging.staticVariables.paymentCreditCardPageName);
        paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
            tagging.staticVariables.paymentCreditCardPageName);
        paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
            tagging.staticVariables.paymentCreditCardPageName);
        paymentJourney.journey_category = CrossVariables.client_typology;
        paymentJourney.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        paymentJourney.payment_id = this.paymentNavigationService.thirdPartyURLParams.transactionId;
        paymentJourney.journey_detail = PaymentJourneyDetail.pasarela;
        paymentJourney.state_flow = tagging.genericCheckout.data.state_flow;
        this.tagging.track(eventName, tagging.downloadTicket.data, paymentPageName, paymentJourney);
    }
    /** Tagging for payment leaving success screen */
    tagPaymentLeavingSuccessScreen(transactionId: string, isToggleOn: boolean): void {
        const saveCreditCardJourney: TaggingViewModel = Object.assign({}, tagging.saveCreditCardCommercialJourney);
        const saveCreditCardPageJourney: TaggingViewModel = Object.assign({}, tagging.saveCreditCardCommercialJourney.pageJourney);
        let pageName: string = '';

        pageName = tagging.staticVariables.paymentCreditCardPageName;
        saveCreditCardJourney.data.journey_process = tagging.staticVariables.paymentCreditCardJourneyProcess;
        saveCreditCardPageJourney.journey_detail = PaymentJourneyDetail.pasarela;
        saveCreditCardPageJourney.state_flow = tagging.genericCheckout.data.state_flow;
        saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_subcategory_level_2 = pageName;

        const paymentId: string = transactionId;
        saveCreditCardJourney.data.payment_id = paymentId;
        saveCreditCardJourney.data.journey_category = CrossVariables.client_typology;
        saveCreditCardJourney.data.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;

        this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
    }

    /** Method to be used from Parent class (PaymentTaggingPostPaid) in case payment with credit card */
    sendTaggingHelper(pageName: TaggingViewModel, journey: TaggingViewModel, isSelectedCard: boolean): void {
        const paymentPageName: TaggingViewModel = Object.assign({}, pageName);
        const paymentJourney: TaggingViewModel = Object.assign({}, journey);
        paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
            tagging.staticVariables.paymentCreditCardPageName);
        paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
            tagging.staticVariables.paymentCreditCardPageName);
        paymentJourney.journey_category = CrossVariables.client_typology;
        paymentJourney.journey_process = tagging.staticVariables.paymentCreditCardJourneyProcess;
        paymentJourney.journey_detail = PaymentJourneyDetail.pasarela;
        paymentJourney.state_flow = tagging.genericCheckout.data.state_flow;
        paymentJourney.journey_subcategory = PaymentJourneySubCategory.postpaid;
        paymentJourney.journey_card_selected = isSelectedCard || this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        this.tagging.view(paymentPageName, paymentJourney);
    }

    /** Tagging for payment wallet screen */
    tagOpenPaymentWalletScreen(isSelectedCard: boolean): void {
        this.sendTaggingHelper(
            tagging.walletScreenCreditCardPageName,
            tagging.paymentCardsPages.manageCommercialCreditCards,
            isSelectedCard
        );
    }

    /** Tagging for payment wallet cancel deleting a card */
    tagPaymentWalletCancelDeleteInteraction(): void {
        this.billService.sendPostPaidTaggingTrackActions(tagging.confirmDeleteCardCreditCardPageName, PaymentJourneyUserType.checkout,
            tagging.clickOnCancelDeleteCardCreditCardButton);
    }
    /** Tagging for payment wallet start deleting a card journey */
    tagPaymentWalletDeleteCard(): void {
        this.billService.sendPostPaidTaggingTrackActions(tagging.walletScreenCreditCardPageName, PaymentJourneyUserType.checkout,
            tagging.clickOnDeleteCCCreditCard);
        this.billService.sendViewForDeleteCCScreenPostpaid(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyProcess.pasarela,
            PaymentJourneyDetail.deleteCC, PaymentJourneyUserType.checkout);
    }
    /** Tagging for payment wallet confirm deleting a card */
    tagPaymentWalletConfirmDeleteInteraction(): void {
        this.billService.sendPostPaidTaggingTrackActions(tagging.confirmDeleteCardCreditCardPageName, PaymentJourneyUserType.checkout,
            tagging.clickOnConfirmDeleteCardCreditCardButton);
    }
    /** Tagging cerrar btn Success paymet overlay screen */
    successpaymentCerrarBtnClick(): void {
        const saveCreditCardJourney: TaggingViewModel = Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlayCommercial);
        const saveCreditCardPageJourney: TaggingViewModel =
            Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlayCommercial.pageJourney);
        let pageName: string = '';
        pageName = tagging.staticVariables.paymentCreditCardPageName;
        saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
        saveCreditCardPageJourney.journey_detail = PaymentJourneyDetail.pasarela;
        saveCreditCardPageJourney.state_flow = tagging.genericCheckout.data.state_flow;
        this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
    }
    /** Tagging ok btn Success paymet overlay screen */
    successpaymentEntendidoBtnClick(): void {
        const saveCreditCardJourney: TaggingViewModel = Object.assign({}, tagging.entendidoBtnClickSuccessCreditCardPaymentOverlay);
        const saveCreditCardPageJourney: TaggingViewModel =
            Object.assign({}, tagging.entendidoBtnClickSuccessCreditCardPaymentOverlay.pageJourney);
        let pageName: string = '';
        pageName = tagging.staticVariables.paymentCreditCardPageName;
        saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
        saveCreditCardPageJourney.journey_process = tagging.staticVariables.paymentCreditCardJourneyProcess;
        saveCreditCardPageJourney.journey_detail = PaymentJourneyDetail.pasarela;
        saveCreditCardPageJourney.state_flow = tagging.genericCheckout.data.state_flow;
        this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
    }

    tagCloseBtnClick(): void {
        const pagePaymentClose: TaggingViewModel = Object.assign({}, tagging.genericCheckout.paymentMethod.pageClosePayment);
        const dataPaymentClose: TaggingViewModel = Object.assign({}, tagging.genericCheckout.paymentMethod.dataClosePayment);
        const eventPaymentClose: TaggingViewModel = Object.assign({}, tagging.genericCheckout.paymentMethod.eventClosePayment);
        const trackdata: TaggingViewModel = Object.assign(pagePaymentClose, dataPaymentClose, eventPaymentClose);
        trackdata['&&products'] = this.paymentNavigationService.productTagg;
        this.tagging.trackReplace(trackdata.event_name, trackdata, false);
    }
}

export class CreditCardModel {
    creditCardNumber: string;
    maskCreditCard: string;
    tokenCreditCard: string;
    creditCardExpiryDate: string;
    creditCardImage?: string;
    brand?: string;


}



import { BackdropChooser } from './../../shared/enums/backdropChooser.enum';
import { BackdropOneButton, BackdropDobleButton, KoSuccBackdrop, PermissonBackdrop, DeleteUser } from './backdrop-chooser.model';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';

@Component({
  selector: 'sp-backdrop-chooser',
  templateUrl: './backdrop-chooser.component.html',
  styleUrls: ['./backdrop-chooser.component.scss']
})
export class BackdropChooserComponent implements OnInit, OnChanges {
  @Input() showBackdrop: boolean = false;
  @Input() typeBackdrop: string = '';
  @Input() permisson: string = '';
  @Input() user: string = '';
  @Input() showSpinner: boolean = false;
  @Output() hideBackdrop: EventEmitter<boolean>  = new EventEmitter<boolean>();
  @Output() backdropAction: EventEmitter<boolean>  = new EventEmitter<boolean>();
  public backdropOneButton: BackdropOneButton;
  public backdropDobleButton: BackdropDobleButton;
  public permitActive: boolean = false;
  public dobleButton: boolean = false;
  public spinnerColor: string = BackdropChooser.spinnerColor;
  public spinnerType: string = BackdropChooser.spinnerType;

  constructor(private translate: TranslateService) { }

  ngOnChanges(): void {
    this.getWcsData();
  }

  ngOnInit(): void {
    this.dobleButton = false;
    this.getWcsData();
  }


  public closeBackdrop(): void {
    this.hideBackdrop.emit()
  }

  public actionBackdrop(): void {
    this.backdropAction.emit()
  }

  public getWcsData(): void  {
    switch (this.typeBackdrop) {
      case BackdropChooser.deleteType: {
        this.translate.get('v10.myAccount.manageUsers.confirmationScreen').subscribe((data: DeleteUser) => {
          this.backdropDobleButton = {
            headerTitle: data.deleteUserHeaderTitle,
            title: data.deleteUserTitle.replace('%s', this.user),
            descriptionText: '',
            createButtonText: data.deleteUserButtonText,
            cancelButtonText: data.cancelButtonText
          };
        });
        this.dobleButton = true;
      break;
      }
      case BackdropChooser.succesType:
      case BackdropChooser.errorType:  {
        const resAction: string = this.typeBackdrop === BackdropChooser.errorType
        ? BackdropChooser.typeErrScreen : BackdropChooser.typeSuccScreen;
        this.translate.get(`v10.myAccount.manageUsers.${resAction}`).subscribe((data: KoSuccBackdrop) => {
          this.backdropOneButton = {
            image: data.image,
            image_rsp: data.image,
            title: data.title,
            descriptionText: data.descriptionText,
            backButtonText: data.buttonText
          };
        });
        this.dobleButton = false;
        break;
      }
      case BackdropChooser.grtType: {
        this.translate.get(`v10.myAccount.manageUsers.permissions.${this.permisson}.confirmationScreen`)
        .subscribe((data: PermissonBackdrop) => {
          this.backdropDobleButton = {
            headerTitle: data.permissionHeaderTitle,
            title: data.grantPermissionTitle.replace('%s', this.user),
            descriptionText: data.grantPermissionSubtitle,
            createButtonText: data.grantPermissionButtonText,
            cancelButtonText: this.translate.instant('v10.myAccount.manageUsers.confirmationScreen.cancelButtonText')
          };
        });
        this.dobleButton = true;
      break;
      }
      case BackdropChooser.dnyType: {
        this.translate.get(`v10.myAccount.manageUsers.permissions.${this.permisson}.confirmationScreen`)
        .subscribe((data: PermissonBackdrop) => {
          this.backdropDobleButton = {
            headerTitle: data.permissionHeaderTitle,
            title: data.revokePermissionTitle.replace('%s', this.user),
            descriptionText: data.revokePermissionSubtitle,
            createButtonText: data.revokePermissionButtonText,
            cancelButtonText: this.translate.instant('v10.myAccount.manageUsers.confirmationScreen.cancelButtonText')
          };
        });
        this.dobleButton = true;
      break;
      }
      default: {
          this.translate.get('v10.commercial.permissionRequest.manualRequest').subscribe((data: BackdropOneButton) => {
            this.backdropOneButton = data;
            this.dobleButton = false;
          });
        break;
      }
   }
  }
}

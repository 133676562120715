import { DiscountType } from '../shared/enums/discount-type.enum';

export class CommitmentModel {
    type?: DiscountType;
    commIniDate: string;
    commEndDate: string;
    commDuration: string;
    penaltyAmount: string;
    inService: string;
}

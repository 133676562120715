import { CtcOverlayModel } from './ctc-overlay.model';
export class DmpModel {
  animation?: string;
  bgColor: string;
  buttonType: string;
  controlGroup: boolean;
  CTAbgColor: string;
  CTAtextColor?: string;
  ctc: CtcOverlayModel;
  deeplink?: string;
  design: string;
  endDate?: string;
  entryPointTitle?: string;
  entryPointLocation?: string;
  experienceName: string;
  gradient: string;
  image?: string;
  image_rsp?: string;
  imageTitle?: string;
  imageTitle_rsp?: string;
  journeyName?: string;
  nameCTA: string;
  padding?: boolean;
  promo: string;
  text: string;
  textCTA: string;
  title: string;
  webviewlink?: string;
  imgRight: string;
  imgRightRsp: string;
  paddingImg: boolean;
  darkTextFlag?: boolean;
}

export class NexusDmpModel {
  cardTitle?: string;
  cardDesc?: string;
  cardImgDesktop?: string;
  cardImgMobile?: string;
  cardBackgroundImgDesktop?: string;
  cardBackgroundImgMobile?: string;
  cardBackgroundColour?: string;
  cardBtnText?: string;
  cardBtnColour?: string;
  deeplink?: string;
  order?: number;
  controlGroup?: boolean;
  code?: string;
  cardBtnBackground?: string;
  journeyName?: string;
  experienceName?: string;
}

export class BannerModel {
  image: string;
  image_rsp: string;
  title: string;
  text: string;
  deeplink: string;
  experienceName: string;
  entryPointTitle: string;
  journeyName: string;
  entryPointLocation: string;
  controlGroup: string;
}

export interface NexusRecoDmpModel {
  code: string;
  recomendations: NexusDmpModel[];
}

export interface TestCROBanner {
  isVersionAlternativa: boolean,
  codigoEP: string,
  claves: string[],
  experienceName: string
}

export interface TestOpenAndCloseCards {
  isVersionAlternativa: boolean,
  experienceName: string
}

export interface TestCROBannerConst {
  title: string,
  subtitle: string,
  button: string,
  link: string,
  image: string,
}

export interface DMPReingeeneeringModel {
  image?: string,
  image_rsp?: string,
  title?: string,
  text?: string,
  textCTA?: string,
  deeplink?: string,
  experienceName?: string,
  journeyName?: string,
  entryPointLocation?: string,
  mode?: string,
  code?: string,
  openWebView?: string
}

export const DMPReingeeneeringModelDefaultState: DMPReingeeneeringModel = {
  image: '',
  image_rsp: '',
  title: '',
  text: '',
  textCTA: '',
  deeplink: '',
  experienceName: '',
  journeyName: '',
  entryPointLocation: '',
  mode: '',
  code: ''
}

export interface CarouselDMP {
  clickable_bullets: boolean,
  bullets: boolean,
  arrows: boolean,
  breakpooint: number,
  elements: number
}

import { SideMenuItemModel } from './side-menu-item.model';
import { CardImageSelectorModel } from '@mva10/mva10-angular';


export class SideMenuModel {
  public items: Array<SideMenuItemModel>;
  public productsButtonText?: string;
  public products: Array<CardImageSelectorModel>;
  public sede;
}

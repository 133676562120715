import { Component, Input, EventEmitter, Output, AfterViewChecked } from '@angular/core';
@Component({
  selector: 'sp-mva10-amount-selector',
  templateUrl: './mva10-amount-selector.component.html',
})
export class Mva10AmountSelectorComponent implements AfterViewChecked {
  @Output() slidChangeFn: EventEmitter<any> = new EventEmitter<any>();
  @Input() index: number ;
  @Input() items: Array<any>;

  setInitialPositionFlag = true;

  currentIndex(index: number): void {
    this.slidChangeFn.emit(index);
  }

  ngAfterViewChecked(): void {
    const selectedItem: HTMLElement = document.querySelectorAll('[role=radio]')[this.index] as HTMLElement;
    if (selectedItem && this.setInitialPositionFlag && selectedItem.offsetLeft) {
      const selectedItemGap = selectedItem.offsetLeft + selectedItem.clientWidth / 2;
      document.getElementById('amounts-group').style.transform = `translate(${-selectedItemGap}px, -50%)`;
      this.setInitialPositionFlag = false;
    }
  }
}

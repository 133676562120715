export enum ChevronTypeWhatHappened {
    /**
     * Alarms for Out Of Bundle consumption
     */
    Obb = 'obb',
    /**
     * Alarms for new products in bill
     */
    New = 'new',
    /**
     * Alarms for bill discounts
     */
    Discounts = 'discounts',
    /**
     * Alarms for refunds payments in current bill
     */
    Refunds = 'payment',
    /**
     * Alarms for devices financing
     */
    Financing = 'financing',
    /**
     * Alarms for payments to third parties
     */
    ThirdParties = 'third_parties',
    /**
     * Alarms for premium services
     */
    Premium = 'premium',
    /**
     * Alarms for m4m, prices change
     */
    M4M = 'new_price',
    /**
     * Alarms automatic migrations
     */
    AutoMigration = 'auto_migration',
}

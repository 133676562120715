import { Guid } from 'guid-typescript';
import { ThirdPartyIframePayload, ThirdPartyIframeRequestBodyHeader,
    ThirdPartyIframeUrlRequest, ThirdPartyPatchRequestBodyHeader
} from '../../../models/payment';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentDxlparamInterface } from './payment-dxlparam-interface';
import { PaymentDxlparamPrePaidPostPaid } from './payment-dxlparam-prepaid-postPaid';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';

export class PaymentDxlparamPostPaidFinancedDevices
extends PaymentDxlparamPrePaidPostPaid implements PaymentDxlparamInterface {

    constructor(public paymentNavigationService: PaymentNavigationService) {
        super(paymentNavigationService);
    }
    /**
     * @param appUUID the appUUID uinque id for the user
     * function to construct the request body needed by the dxl
     * by the post request (get net plus url)
     */
    getNetPlusUrlRequestBody(appUUID: Guid): ThirdPartyIframeRequestBodyHeader {
        // // related to financed devices journey
        const requestBodyHeader: ThirdPartyIframeRequestBodyHeader = UtilitiesService.deepCopy(super.getNetPlusUrlRequestBody(appUUID));
        const headerRequestBody: ThirdPartyIframeUrlRequest = {...UtilitiesService.deepCopy(requestBodyHeader.requestBody)
            , imei: this.paymentNavigationService.paymentJourneyParams.imei,
            installmentQuantity: this.paymentNavigationService.paymentJourneyParams.installmentQuantity };
        requestBodyHeader.requestBody = headerRequestBody;
        return requestBodyHeader;
    }
    /**
     * @param payload object contains payload needed to fill the patch request body
     * method to construct the patch request (update the dxl with the netplus status)
     */
    getPatchNetplusStatusRequestBody(payload: ThirdPartyIframePayload): ThirdPartyPatchRequestBodyHeader {
        const requestBodyHeader: ThirdPartyPatchRequestBodyHeader =
        UtilitiesService.deepCopy(super.getPatchNetplusStatusRequestBody(payload));
        // related to financed devices journey
        const headerRequestBody: ThirdPartyPatchRequestBodyHeader = {...UtilitiesService.deepCopy(requestBodyHeader.requestBody)
            , imei: this.paymentNavigationService.paymentJourneyParams.imei,
            installmentQuantity: this.paymentNavigationService.paymentJourneyParams.installmentQuantity };
        requestBodyHeader.requestBody = headerRequestBody;
        return requestBodyHeader;
    }

}

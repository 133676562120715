import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { greenSmallTickIcon } from 'src/app/shared/constants/defines';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { TopUpService } from '../../top-up/top-up.service';
import { DelegateCreationService } from '../services/delegate-creation.service';
import { PaymentSuccessService } from '../services/payment-success.service';

// import { PaymentTicketService } from '../services/payment-ticket.service';

@Component({
  selector: 'sp-payment-ticket',
  templateUrl: './payment-ticket.component.html',
  styleUrls: ['./payment-ticket.component.scss']
})
export class PaymentTicketComponent implements OnInit {
  @ViewChild('ticketCanvas', { static: true }) canvas: ElementRef;
  @ViewChild('tickCanvas', { static: true }) tickCanvas: ElementRef;
  topSpaceInDownoadedImage = 115;
  canvasX = 16;
  /** showDownloadedToast to show a toast after download ticket for 3 seconds */
  showDownloadedToast = false;
  /** hideDownloadedToast to hide a toast by default, once it shown then css classes will handle it */
  hideDownloadedToast = true;
  constructor(public trayService: SpMva10OverlayService,
    public topupService: TopUpService,
    public paySuccessSer: PaymentSuccessService,
    private delegateService: DelegateCreationService
  ) { }

  ngOnInit(): void {
    this.sendTagging();
    this.getWCSAndFillScreenValues();
    this.drawTicketCanvas();
    this.drawTickImageInDownloadToast();
    if (!this.topupService.paymetTicketIcon) {
      this.topupService.paymetTicketIcon = greenSmallTickIcon;
    }
  }

  /**
   * Get WCS data and fill screen values
   */
  getWCSAndFillScreenValues(): void {
    this.paySuccessSer.fillPaymentTicketComponent();

  }
  /**
   * Send state tagging
   */
  sendTagging(): void {
    this.delegateService.paymentTaggingConfig.tagOpenPaymentTicketScreen();
  }
  /**
   * Draw ticket and ☑ images
   */
  drawTicketCanvas(): void {
    const image: HTMLImageElement = new Image();
    const canvas: HTMLCanvasElement = this.canvas.nativeElement as HTMLCanvasElement;
    image.crossOrigin = '*';
    canvas.width = 328;
    canvas.height = 332;
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    image.addEventListener('load', () => {
      /** Draw ticket image */
      context.drawImage(image, 0, 0, 328, 332);
      this.drawCanvasTitleText(this.paySuccessSer.paymentTicket.subtitle, context);
      this.drawCanvasDetailsList(context);

      /** Draw ☑ image */
      const paymentImage: HTMLImageElement = new Image();
      paymentImage.crossOrigin = '*';
      paymentImage.addEventListener('load', () => {
        context.drawImage(paymentImage, 12, 12, 48, 48);
      });
      paymentImage.src = this.topupService.paymetTicketIcon ?? greenSmallTickIcon
    });
    image.src = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ticket@2x.png'
  }

  /** Draw tick icon in green download toast */
  drawTickImageInDownloadToast(): void {
    const image: HTMLImageElement = new Image();
    image.crossOrigin = '*';
    image.src = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/small@3x.png';
    const canvas: HTMLCanvasElement = this.tickCanvas.nativeElement as HTMLCanvasElement;
    canvas.width = 28 * window.devicePixelRatio;
    canvas.height = 28 * window.devicePixelRatio;
    canvas.style.width = '28px';
    canvas.style.height = '28px';
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    image.addEventListener('load', () => {
      context.drawImage(image, 0, 0, 28 * window.devicePixelRatio, 28 * window.devicePixelRatio);
    });
  }

  /**
   * Draw ticket details in ticket canvas
   * @param context to fill
   */
  drawCanvasDetailsList(context: CanvasRenderingContext2D): void {
    this.paySuccessSer.paymentTicket.ticketDetails.forEach(item => {
      context.font = '16px VodafoneRgBd'
      context.fillText(item.key, this.canvasX, this.topSpaceInDownoadedImage);
      context.font = '16px vodafone-rg';
      context.fillText(item.value, item.spaceFromLeft, this.topSpaceInDownoadedImage);
      this.topSpaceInDownoadedImage += 28;
    });
    if ( this.paySuccessSer.paymentTicket.ticketNote && this.paySuccessSer.paymentTicket.ticketNote !== '' ) {
      this.topSpaceInDownoadedImage += 16;
      context.font = '14px vodafone-rg';
      context.fillText(this.paySuccessSer.paymentTicket.ticketNote,
        this.canvasX, this.topSpaceInDownoadedImage, 296);
    }
  }
  /**
   * Executed when click on download ticket button in ticket screen
   * Make tagging of clicking button
   * show green toast for 3 seconds that confirm ticket image download
   */
  downLoadTicket(): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.download = 'payment-ticket.png';
    link.href = this.canvas.nativeElement.toDataURL('image/png')
    link.click();
    this.showToastAndHide();
    // tagging for download btn
    this.delegateService.paymentTaggingConfig.tagDownLoadTicket();
  }

  /**
   * show the toast for 3 seconds
   */
  showToastAndHide(): void {
    this.hideDownloadedToast = false;
    this.showDownloadedToast = true;
    setTimeout(() => {
      this.showDownloadedToast = false;
      setTimeout(() => {
        this.hideDownloadedToast = true;
      }, 500);
    }, 3000);
  }
  /**
   * Draw Canvas title text
   * @param text of ticket title
   * @param context to fill
   */
  drawCanvasTitleText(text: string, context: CanvasRenderingContext2D): void {
    const wordList: string[] = text ? text.split(' ') : [];
    let line: string = '';
    let y: number = 40;
    context.font = '18px vodafone-rg';
    context.fillStyle = '#3a3a3a';
    wordList.forEach((word, index) => {
      if (line.length <= 22) {
        line = index === 0 ? line + word : line + ' ' + word;
      } else {
        const newLineWords: string[] = line.split(' ');
        const lastWord: string[] = newLineWords.splice(newLineWords.length - 1, 1);
        line = newLineWords.join(' ');
        context.fillText(line, 70, y);
        line = lastWord + ' ' + word;
        y += 22;
      }

      if (index === wordList.length - 1) {
        context.fillText(line, 70, y);
      }

    })
  }
}

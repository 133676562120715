<sp-full-width-slider #onBoarding (indexChangeFn)="changeSlide($event)">
  <div class="swiper-slide" *ngFor="let item of img; let i = index">
      <div class="swiper-slide-content">
          <div class="slider-icon">
              <img [src]="img[i]">
          </div>
          <div class="swiper-slide-content__text">
              <h3 class="font-styling">{{ titles[i] }}</h3>
              <p class="font-styling">{{ description[i] }}</p>
              <button class="button red toDestiny" (click)="clickPrimaryButton()" >{{ buttonText[i] }}</button>
          </div>
      </div>
  </div>
</sp-full-width-slider>

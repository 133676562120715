<div class="tile-image-cta">
  <div class="tile-image-cta__left-column">
    <div class="tile-image-cta__text">
      <label *ngIf="title" id="tileImageCtaTitle" class="tile-image-cta__title mva10-u--body regular-bold-font">
        {{ title | translate }}
      </label>
      <label *ngIf="subtitle" id="tileImageCtaSubtitle" class="tile-image-cta__subtitle mva10-u--body">
        {{ subtitle | translate }}
      </label>
    </div>
    <div class="tile-image-cta__cta">
      <mva10-c-button
        *ngIf="ctaText"
        id="tileImageCtaButton"
        (mva10OnClick)="onCtaClick()"
        [name]="ctaText | translate"
        styleClass="mva10-c-button--secondary mva10-c-button--small regular-bold-font"
      >
        {{ ctaText | translate }}
      </mva10-c-button>
    </div>
  </div>
  <div class="tile-image-cta__right-column">
    <img *ngIf="image" id="tileImageCtaImage" src="{{ image | imgFullPath }}" class="tile-image-cta__image" alt="Tile image" />
  </div>
</div>

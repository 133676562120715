import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ThirdPartyIframePayload, ThirdPartyIframeRequestBodyHeader, ThirdPartyPatchRequestBodyHeader } from '../../models/payment';
import { LOCAL_STORAGE_KEYS } from '../../shared/constants/defines';
import { PaymentDataService } from '../../shared/services/payment-data.service';
import { StorageService } from '../../core/services/storage.service';
import { DelegateCreationService } from './delegate-creation.service';

@Injectable()
export class PaymentIframeService {
  constructor(private storageService: StorageService,
    private delegateService: DelegateCreationService,
    private paymentDataService: PaymentDataService) { }
  // post request to get thirs party  url
  getPayment3rdPartyUrl(isPayingEnergyDebt?: boolean): Observable<any> {
    return this.paymentDataService.requestNetplusIframeUrlForMva10Topup(this.getPayment3rdPartyUrlRequestBody(this.getAppUuidForTopup()),
    isPayingEnergyDebt);
  }
  // patch request to update dxl with the current third party status
  patchThirdPartyPaymentStatus(payload: ThirdPartyIframePayload, isPayingEnergyDebt?: boolean): Observable<any> {
    return this.paymentDataService.updatePaymentStatus(this.getPaymentPatchThirdPartyBody(payload), isPayingEnergyDebt).pipe(tap((resp) => {
      this.delegateService.DXLParamatersConfig.mapPaymentOutputParams(resp);
    }))
  }
  // helper method to create the post request body
  getPayment3rdPartyUrlRequestBody(appUUID: Guid): ThirdPartyIframeRequestBodyHeader {
    return this.delegateService.DXLParamatersConfig.getNetPlusUrlRequestBody(appUUID);
  }
  // helper method to create unique appuuid for every customer
  getAppUuidForTopup(): Guid {
    let appUUIDForNetPlusIframe: Guid = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.NETPLUS_UUID_FOR_TOPUP);
    if (!appUUIDForNetPlusIframe) {
      appUUIDForNetPlusIframe = (Guid.create())['value'];
      this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.NETPLUS_UUID_FOR_TOPUP, appUUIDForNetPlusIframe);
    }
    return appUUIDForNetPlusIframe
  }
  // helper method to create the paych request body
  getPaymentPatchThirdPartyBody(payload: ThirdPartyIframePayload): ThirdPartyPatchRequestBodyHeader {
    return this.delegateService.DXLParamatersConfig.getPatchNetplusStatusRequestBody(payload);
  }

}

import { Injectable } from '@angular/core';
import { CanjeModel } from '../../models/canje.model';
import { StorageService } from '../../core/services/storage.service';
import { LOCAL_STORAGE_KEYS, JSON_PATHS } from '../constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../../app.service';
@Injectable()
export class CanjeService {

  private window: any = window;
  private currentCanje: CanjeModel;

  private activedExchange: boolean = true;
  private activedExchangeSubject = new BehaviorSubject({});
  public activedExchange$ = this.activedExchangeSubject.asObservable();

  constructor(
    private storageService: StorageService,
    private appService: AppService
  ) {
    this.window._canje = {};
    this.window._canje = {
      canjes: {
        resolve: null,
        reject: null,
        promise: null
      }
    };
    this.initPromiseCanje();
  }

  get(json: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (json) {
          resolve(json);
        }
      } catch (error) {
        reject(`Some error getting json ${json}`);
      }
    });
  }

  get canje(): { canjes: { resolve: Function, reject: Function, promise: Promise<any> } } {
    return this.window._canje;
  }

  get windowRef(): any {
    return this.window;
  }

  initPromiseCanje() {
    this.window._canje.canjes.promise = new Promise((resolve, reject) => {
      this.window._canje.canjes.resolve = resolve;
      this.window._canje.canjes.reject = reject;
    });
  }

  public canjeContent(): CanjeModel {
    if (!this.currentCanje) {
      this.currentCanje = this.jsonToCanjeModel(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.CANJE));
    }

    return this.currentCanje;
  }

  private jsonToCanjeModel(canje: string) {
    const model = new CanjeModel();
    model.banner_title = JsonQuery.value(canje, JSON_PATHS.Canje.banner_title) || '';
    model.banner_description = JsonQuery.value(canje, JSON_PATHS.Canje.banner_description) || '';
    model.banner_img = JsonQuery.value(canje, JSON_PATHS.Canje.banner_img) || '';
    model.entryPoint_img = JsonQuery.value(canje, JSON_PATHS.Canje.entryPoint_img) || '';
    model.experienceName = JsonQuery.value(canje, JSON_PATHS.Canje.experienceName) || '';
    model.link = JsonQuery.value(canje, JSON_PATHS.Canje.link) || '';
    model.link_description = JsonQuery.value(canje, JSON_PATHS.Canje.link_description) || '';
    model.reco_title = JsonQuery.value(canje, JSON_PATHS.Canje.reco_title) || '';
    model.reco_promo = JsonQuery.value(canje, JSON_PATHS.Canje.reco_promo) || '';
    model.reco_mobile_1 = JsonQuery.value(canje, JSON_PATHS.Canje.reco_mobile_1) || '';
    model.reco_mobile_2 = JsonQuery.value(canje, JSON_PATHS.Canje.reco_mobile_2) || '';
    model.reco_description = JsonQuery.value(canje, JSON_PATHS.Canje.reco_description) || '';
    model.reco_CTA = JsonQuery.value(canje, JSON_PATHS.Canje.reco_CTA) || '';
    model.reco_CTA_2 = JsonQuery.value(canje, JSON_PATHS.Canje.reco_CTA_2) || '';
    model.ticket_title = JsonQuery.value(canje, JSON_PATHS.Canje.ticket_title) || '';
    model.ticket_description = JsonQuery.value(canje, JSON_PATHS.Canje.ticket_description) || '';
    model.reco_mobile_3 = JsonQuery.value(canje, JSON_PATHS.Canje.reco_mobile_3) || '';
    return model;
  }

  /** Change CANJE WCS */
  public getCanjeModelWCS(_objCanjeCode: any): CanjeModel {
      let _modelWCS: CanjeModel  = new CanjeModel();
      const _objCanjeBanner: any = this.getCanjeBanner(_objCanjeCode);
      const _objCanjeEntry: any = this.getCanjeEntry(_objCanjeCode);
      const _objCanjeReco: any = this.getCanjeReco(_objCanjeCode);
      _modelWCS = Object.assign(_objCanjeBanner, _objCanjeEntry, _objCanjeReco);
      return _modelWCS;
  }

  private getCanjeBanner(_objCanjeCode: any): any {
    const _objCanje: any = {
        banner_title: (_objCanjeCode.banner_title) ? _objCanjeCode.banner_title : '',
        banner_img: (_objCanjeCode.banner_img) ? this.appService.getImgFullPath(_objCanjeCode.banner_img) : '',
        banner_description: (_objCanjeCode.banner_description) ? _objCanjeCode.banner_description : '',
    };
    return _objCanje;
  }

  private getCanjeEntry(_objCanjeCode: any): any {
    const _objCanje: any = {
        entryPoint_img: (_objCanjeCode.entryPoint_img) ? _objCanjeCode.entryPoint_img : '',
        experienceName: (_objCanjeCode.experience_name) ? _objCanjeCode.experience_name : '',
        link: (_objCanjeCode.link) ? _objCanjeCode.link : '',
        link_description: (_objCanjeCode.link_description) ? _objCanjeCode.link_description : '',
    };
    return _objCanje;
  }

  private getCanjeReco(_objCanjeCode: any): any {
    const _objCanje: any = {
        reco_title: (_objCanjeCode.reco_title) ? _objCanjeCode.reco_title : '',
        reco_promo: (_objCanjeCode.reco_promo) ? _objCanjeCode.reco_promo : '',
        reco_mobile_1: (_objCanjeCode.reco_mobile_1) ? _objCanjeCode.reco_mobile_1 : '',
        reco_mobile_2: (_objCanjeCode.reco_mobile_2) ? _objCanjeCode.reco_mobile_2 : '',
        reco_description: (_objCanjeCode.reco_description) ? _objCanjeCode.reco_description : '',
        reco_CTA: (_objCanjeCode.reco_CTA) ? _objCanjeCode.reco_CTA : '',
        reco_CTA_2: (_objCanjeCode.reco_CTA_2) ? _objCanjeCode.reco_CTA_2 : '',
        reco_mobile_3: (_objCanjeCode.reco_mobile_3) ? _objCanjeCode.reco_mobile_3 : '',
        ticket_title: (_objCanjeCode.ticket_title) ? _objCanjeCode.ticket_title : '',
        ticket_description: (_objCanjeCode.ticket_description) ? _objCanjeCode.ticket_description : '',
    }
    return _objCanje;
  }

  public activateExchange(): void {
    this.activedExchange = true;
    this.refresObservable();
  }

  public desactivateExchange(): void {
    this.activedExchange = false;
    this.refresObservable();
  }

  private refresObservable(): void {
    this.activedExchangeSubject.next(this.activedExchange);
  }

}

<div class="vfac-submenu-menu__modal-content container-form">
  <div *ngFor="let item of subMenuDisplayData; let idx = index;"  class="vfac-submenu__item"
      [ngClass]="{'vfac-submenu__item--border':  item.hasBorder, 'vfac-submenu__item--selected': selectedItemIndex === idx}">
    <mva10-c-menu-list
      [badgeNum]="item.redirectTo && item.redirectTo.toLowerCase().includes(inboxRoute) ? NotificationInboxService.notificationInboxCount : ''"
      [badgePosition]="'left'"
      [body]="item.itemText"
      [iconRight]="item.subItems ? 'icon-chevron-down' : ''"
      [iconLeft]="item.iconClassName"
      (clickEvent)="itemClicked(item, idx)">
      <mva10-c-child-item *ngFor="let subitem of item.subItems">
        <span (click)="itemClicked(subitem)" class="vfac-submenu-menu__subItem">{{subitem.itemText}}</span>
      </mva10-c-child-item>
    </mva10-c-menu-list>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ResizeService } from 'src/app/mva10/shared/services/resize.service';
import { iban, VFExternalBuisnessWeb } from 'src/app/shared/constants/defines';
import { CrossFunctionalityService } from 'src/app/shared/services/cross-functionality.service';
import { PaymentNavigationService } from '../services/payment-navigation.service';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { InteractionTaggingModel } from 'src/app/models/new-tagging-Interaction.model';

@Component({
  selector: 'sp-payment-clarify-user',
  templateUrl: './payment-clarify-user.component.html',
  styleUrls: ['./payment-clarify-user.component.scss']
})
export class PaymentClarifyUserComponent implements OnInit, OnDestroy {
  breakpoint: number;
  subscrition: Subscription;
  BREAKPOINTS: typeof Breakpoints = Breakpoints;
  constructor(
    private resizeService: ResizeService,
    private cross: CrossFunctionalityService,
    private paymentNavigationService: PaymentNavigationService,
    public translate: TranslateService,
    private newTagging: NewTaggingHelperService) { }

  ngOnInit(): void {
    this.breakpoint = this.resizeService.breakpoint;
    this.subscrition = this.resizeService.onResize$.subscribe((size: Breakpoints) => {
      this.breakpoint = size;
    });
  }
  onCopy(): void {
    const selBox: HTMLTextAreaElement = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = iban;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.cross.toastMessage = this.translate.instant('v10.payment.itemsList.anonymousPayment.es.anonymousClairfyCustomerAuthentication.iBANCopiedLabel.title');
    this.cross.editToastUi = true;
    this.cross.showToast = true;
    setTimeout(() => {
      this.cross.showToast = false;
    }, 10000);
    this.sendCopyIbanTagging()
  }
  firstBtnAction(): void {
    this.paymentNavigationService.openExternalVFWebLink(VFExternalBuisnessWeb)
  }


  sendCopyIbanTagging(): void {
    this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
      const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.INTERACTIONS.CLICK_ON_COPIAR_IBAN;
      const event: InteractionTaggingModel = data.eventList[constantName];
      this.newTagging.interaction(event, data.page.stateList[PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.CLARIFY_USERS]);
    });
  }
  ngOnDestroy(): void {
    this.subscrition?.unsubscribe();
  }
}

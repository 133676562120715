<div class="politica-zona-one-contanier">
<p [innerHTML]="description" class="precios-description" id="roamingPoliticaZonaOneDescription"></p>
<div *ngFor="let item of itemsList" class="row col-md-6">
    <div class="precios-contanier">
        <img class="precios-img" id="roamingPoliticaZonaOnePreciosImg" [src]="appService.getImgFullPath(item.icon)"/>
        <p class="precios-name" id="roamingPoliticaZonaOnePreciosName">{{item.name}}</p>
        <p class="precios-price" id="roamingPoliticaZonaOnePreciosPrice">{{item.price}}</p>
    </div>
    <hr class="divider">
</div>
<p [innerHTML]="description1" class="precios-description1" id="roamingPoliticaZonaOnePreciosDescription1"></p>
<p [innerHTML]="description2" class="precios-description2" id="roamingPoliticaZonaOnePreciosDescription2"></p>
</div>
<button class="precios-button" id="roamingPoliticaZonaOneCloseBtn" (click)=closeTray()>{{buttonText}}</button>
export enum IconClassNames {
  icon_landline = 'icon-landline-or-call',
  icon_text = 'icon-text',
  icon_mobile = 'icon-mobile',
  icon_country = 'icon-international',
  icon_tv = 'icon-tv',
  icon_mbb = 'icon-mbb',
  icon_mobile_prepaid = 'icon-prepaid',
  icon_mobile_postpaid = 'icon-mobile',
  icon_internet = 'icon-wifi',
  icon_busines_phone = 'icon-landline-with-buttons',
  icon_broadband_or_wifi = 'icon-wifi',
  icon_info = 'icon-info-circle',
  icon_chat = 'icon-chat',
  icon_video = 'icon-video',
  icon_music = 'icon-music',
  icon_social = ' icon-customers-or-users',
  icon_data = 'icon-data',
  icon_mail = 'icon-mail-new',
  icon_location = 'icon-location',
  icon_entertainment = 'icon-entertainment',
  icon_clock = 'icon-clock-or-timed',
  icon_map = 'icon-location',
  icon_top_up_1 = 'icon-top-up-1',
  icon_top_up_download = 'icon-top-up-download',
  icon_roaming = 'icon-roaming',
  icon_bill = 'icon-bill-or-report',
  icon_vodafone_one = 'icon-convergente-1',
  icon_warning = 'icon-warning',
  icon_open = 'icon-info-circle',
  // to do to be changed back to adsl
  icon_adsl = 'icon-router',
  /** this icon in APP only not exists in Admin portal */
  icon_top_up_2 = 'icon-top-up-right-arrow',
  icon_mobile_offered = 'icon-mobile-offered',
  icon_info_icon = 'icon-info-icon',
  icon_my_vodafone_profile = 'icon-my-vodafone-or-profile',
  icon_device_guide = 'icon-device-guide',
  icon_admin = 'icon-admin',
  icon_connectivity = 'icon-connectivity',
  icon_security = 'icon-security',
  icon_info_esp = '#icon-info-circle-esp',
  icon_sms = 'icon-sms',
  icon_bussines_phone = 'icon-business-phone',
  icon_usb_modem = 'icon-usb_modem_esp',
  icon_tv_tv = 'icon-tv_tv',
  icon_channel_variety = 'icon-channel_variety',
  icon_careers = 'icon-careers',
  icon_calendar = 'icon-calendar',
  icon_acceleration = 'icon-acceleration',
  icon_offer_badge_percent = 'icon-offer-badge-percent',
  icon_wifi = 'icon-broadband-or-wifi',
  ICON_SME_MID = '#icon-sme-mid',
  icon_super_wifi = 'icon-superwifi',
  icon_add_ons_boosts = 'icon-add-ons-boosts',
  icon_router_mid = 'icon-router-mid',
  icon_mobile_mid = 'icon-mobile-mid',
  icon_mobiles_mid = 'icon-mobiles-mid',
  icon_landline_mid = 'icon-landline-mid',
  icon_one_number_mid = 'icon-one-number-mid',
  icon_virus_protection_mid = 'icon-virus-protection-mid',
  icon_calendar_month_mid = 'icon-calendar-month-mid',
  icon_list_view_mid = 'icon-list-view-mid',
}

import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { FOLDER_MY_PAYMENT_METHODS, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentTaggingDefault, PaymentTaggingInterface } from './payment-tagging-interface';

export class PaymentTaggingStandAloneWalletManagment extends PaymentTaggingDefault implements PaymentTaggingInterface {
    constructor(
        public tagging: TaggingHelperService,
        public newTagging: NewTaggingHelperService
    ) {
        super(tagging, null, null, null, newTagging );
    }
    /** Tagging for payment wallet screen */
    tagOpenPaymentWalletScreen(): void {
        this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.state(data.page.stateList[
              PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_SAVED_CARDS]);
        });
    }
    tagPaymentSetPruncipaleBtn(): void {
        this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.interaction(data.eventList[
              PAYMENTS_TRACKING.MY_PAYMENT_METHODS.INTERACTIONS.SAVED_CARDS_CLICK_ON_USE_AS_MAIN],
               data.page.stateList[PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_SAVED_CARDS]);
        });
    }
    tagPaymentDeleteBtn(): void {
        this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.interaction(data.eventList[
              PAYMENTS_TRACKING.MY_PAYMENT_METHODS.INTERACTIONS.SAVED_CARDS_CLICK_ON_DELETE],
               data.page.stateList[PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_SAVED_CARDS]);
        });
    }

    tagPaymentWalletDeleteCard(): void {
        this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.state(data.page.stateList[
                PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.SAVED_CARDS_CONFIRM_DELETE_CARD])
        });
    }

    tagPaymentWalletConfirmDeleteInteraction(): void {
        this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.interaction(data.eventList[
              PAYMENTS_TRACKING.MY_PAYMENT_METHODS.INTERACTIONS.SAVED_CARDS_CONFIRM_DELETE_CARD_CLICK_ON_DELETE],
               data.page.stateList[PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.SAVED_CARDS_CONFIRM_DELETE_CARD]);
        });
    }

    tagPaymentWalletCancelDeleteInteraction(): void {
        this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.interaction(data.eventList[
              PAYMENTS_TRACKING.MY_PAYMENT_METHODS.INTERACTIONS.SAVED_CARDS_CONFIRM_DELETE_CARD_CLICK_ON_CANCEL],
               data.page.stateList[PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.SAVED_CARDS_CONFIRM_DELETE_CARD]);
        });
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UpgradeProfileService } from '../upgrade-profile.service';
import { UpgradeProfile } from '../UpgradeProfile.enum';
import { SpModalComponent } from '../../shared/components/sp-modal/sp-modal.component';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { ProfileType } from '../../shared/enums/profileType.enum';
import { StorageService } from '../../core/services/storage.service';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { LOCAL_STORAGE_KEYS } from '../../shared/constants/defines';
import { UserProfile } from '../../models/user-profile.model';

@Component({
  selector: 'sp-step-zero',
  templateUrl: './step-zero.component.html'
})
export class StepZeroComponent implements OnInit {
  public userProfile: UserProfile;

  /**
   * modal to make a pointer to modal selector
   */
  @ViewChild('modal', { static: true }) modal: SpModalComponent;

  constructor(public upgrade: UpgradeProfileService,
    private auth: AuthenticateService,
    private router: Router,
     private storageService: StorageService) {
    this.accept = this.accept.bind(this);
    this.destroy = this.destroy.bind(this);
    this.userProfile = this.auth.getUserProfile(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0]);
  }
  /**
   * on init of step zero starting confirmation modal if not a compelete profile else will redirect user target url
   */
  ngOnInit() {
    const form = this;
    if (this.storageService.userProfile.profileType.toString().toLowerCase() === ProfileType.COMPLETE.toLowerCase()) {
      this.upgrade.startScenario = false;
      this.upgrade.step = UpgradeProfile.stepZero;
      this.router.navigate([this.upgrade.targetRoute]);
    } else if (this.storageService.userProfile.profileType.toString().toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
      this.upgrade.step = UpgradeProfile.stepTwo;
    } else {
      setTimeout(function () {
        form.modal.show();
      });
    }
  }
  /**
   * accept function delegated to accept button in the modal and check to which step shall redirect the user
   */
  public accept = function () {

    if (this.auth.isAuthenticated() && this.userProfile.customerType.toString().toLowerCase() === CustomerType.Employee.toLowerCase()) {
      this.upgrade.step = UpgradeProfile.stepOne;
    } else if (this.auth.isAuthenticated() && this.storageService.userProfile.profileType.toString() === ProfileType.LIGHT) {
      this.upgrade.step = UpgradeProfile.stepTwo;
    } else {
      this.upgrade.step = UpgradeProfile.stepOne;
    }
  };
  /**
   * destroy function delegated to close button in the modal
   */
  destroy = function () {
    this.upgrade.startScenario = false;
  };

}

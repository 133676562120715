export enum UnitCode {
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  Min = 'Min',
  Mins = 'Mins',
  SMS = 'SMS',
  SECONDS = 'seconds',
  Megabytes= 'Mb'
}

<div id="dashboardDashboardAdaraSelector"
[ngClass]="{'adara-selector-dashboard': locationClass === 'dashboard' && adaraFilter,
            'adara-selector-submenu': locationClass === 'submenu-products',
            'adara-selector-left-menu': locationClass === 'left-menu'}">
    <mva10-c-filter
            [idInput]="telcoTitle"
            [icon]="locationClass === 'dashboard'|| locationClass === 'submenu-products' ? telcoIcon : null"
            [checked]="telcoFilter"
            [noWrap]="true"
            (mva10Change)="changeAdaraFilter()"
            id="dashboardDashboardAdaraSelectorFilterTabTelco"
            [ngClass]="{'adara-selector-dashboard-tlf-filter': locationClass === 'dashboard'}">
                    <span [ngClass]="{'adara-selector-submenu-text': locationClass === 'submenu-products',
                    'adara-selector-left-menu-text': locationClass === 'left-menu'}">
                    {{telcoTitle}}</span>
    </mva10-c-filter>
    <mva10-c-filter
            [idInput]="adaraTitle"
            [icon]="locationClass === 'dashboard' || locationClass === 'submenu-products' ? adaraIcon : null"
            [checked]="adaraFilter"
            [noWrap]="true"
            (mva10Change)="changeAdaraFilter()"
            id="dashboardDashboardAdaraSelectorFilterTabAdara"
            [ngClass]="{'adara-selector-dashboard-adara-filter': locationClass === 'dashboard',
            'adara-selector-submenu-adara-filter': locationClass === 'submenu-products',
            'adara-selector-left-menu-adara-filter': locationClass === 'left-menu'}">
                    <span [ngClass]="{'adara-selector-submenu-text': locationClass === 'submenu-products',
                    'adara-selector-left-menu-text': locationClass === 'left-menu'}">
                    {{adaraTitle}}</span>
    </mva10-c-filter>
</div>
<div *ngIf="locationClass === 'left-menu'" class="adara-selector-narrow-desktop" id="dashboardDashboardAdaraSelectorFilterNarrow">
    <mva10-c-badge-text [text]="telcoTitle" [colorBg]="telcoFilter ? 'turquoise' : 'aluminium'" [size]="'small'" (click)="changeAdaraFilter()"></mva10-c-badge-text>
    <mva10-c-badge-text [text]="adaraTitle" [colorBg]="!telcoFilter ? 'turquoise' : 'aluminium'" [size]="'small'" (click)="changeAdaraFilter()"></mva10-c-badge-text>
</div>

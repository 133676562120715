<div class="disclaimer-conatiner">
  <div class="disclaimer-conatiner__content">
    <div class="disclaimer-conatiner__icon">
        <sp-lottie-loader id="paymentDisclaimerIcon" [lottieImageUrl]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/tick.json'" [loop]="false"></sp-lottie-loader>
    </div>
    <p id="paymentDisclaimerSubtitle" class="disclaimer-conatiner__subtitle">{{'v10.payment.messagesList.anonymousPayment.es.accountNumberDisclaimer.subtitle' |translate}}</p>
    <p id="paymentDisclaimerDescription" class="disclaimer-conatiner__description">{{'v10.payment.messagesList.anonymousPayment.es.accountNumberDisclaimer.description' |translate}}</p>
    <div class="disclaimer-conatiner__btn">
        <mva10-c-button id="paymentDisclaimerBtn" [body]="'v10.payment.messagesList.anonymousPayment.es.accountNumberDisclaimer.button1' |translate"
        [name]="'v10.payment.messagesList.anonymousPayment.es.accountNumberDisclaimer.button1' |translate"
        [title]="'v10.payment.messagesList.anonymousPayment.es.accountNumberDisclaimer.button1' |translate"
        (click)="getBills()">
        </mva10-c-button>
    </div>
</div>
    <div *ngIf="isTabletView" class="disclaimer-conatiner__footer">
        <div class="disclaimer-conatiner__footer-images">
            <div class="visa-image">
                <img id='paymentAuthnticationVisaIcon' [src]="'v10.payment.itemsList.anonymousPayment.es.anonymousAuthntication.visaIcon' |imgFullPath"  alt="visa" />
              </div>
            <div class="mastercard-image">
                <img id='paymentAuthnticationMasterCardIcon' [src]="'v10.payment.itemsList.anonymousPayment.es.anonymousAuthntication.masterCardIcon' |imgFullPath" alt="mastercard" />
            </div>
        </div>
        <div class="disclaimer-conatiner__footer-securedssl">
            <p id='paymentAuthnticationSecuredSSLTxt'>{{'v10.payment.itemsList.anonymousPayment.es.anonymousAuthntication.securedSSL' |translate}}</p>
        </div>
    </div>
</div>

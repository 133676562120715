import { Injectable } from '@angular/core';
import { RoamingBundleDeatils, romaingBundleDeailType } from '../../models/romaingBundleDeatils.model';
import { RoamingBundle } from '../../models/roamingBundle.model';
import { RoamingBundleTypes } from '../../shared/enums/roamingBundleTypes';
import { RoamingDataService } from './roaming-data.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProductOfferingPostObject } from '../models/roaming-productOrderItem';
import { RestrictionsStatus } from '../enums/restrictions-status.enum';
import { Roaming, roamingTarrifTypes } from '../constants/defines';

@Injectable({
  providedIn: 'root'
})
export class RoamingService {
  roamingBundle: RoamingBundle[];
  bundleDeatilsList: RoamingBundleDeatils[];
  /** moundoBundleProductOffering proprtey from response which we need it in activation */
  public moundoBundleProductOffering: any[];
  constructor(private roamingDataService: RoamingDataService) { }
  /** method to get data from api */
  getRoamingEligibleProductOffering(customerAccountId: string,
     nationalTariff: string ,
     zoneId: string , serviceId: string): Observable<any> {
    return this.roamingDataService.getRoamingEligibleProductOffering(customerAccountId,
       nationalTariff, zoneId, serviceId).pipe(map(res => {
       const ProductItemslist: any[] = [...res.productOfferingQualificationItem];
    //  inialize roaming bundle array with length of ProductItemslist
     this.roamingBundle = new Array<RoamingBundle>(ProductItemslist.length);
      this.getRoamingbundleType(ProductItemslist);
      this.getRoamingbundleStatus(ProductItemslist);
      this.getLimitedTarrif(zoneId, ProductItemslist);
      this.getBundleDeatils(ProductItemslist);
      this.roamingBundle = this.sortRoamingBundlelist(this.roamingBundle);

      return this.roamingBundle;
     }));
  }
  /** helper method to get romaing bundles status */
 private getRoamingbundleStatus(ProductItemslist: any[]): void {
    ProductItemslist.forEach((bundle: any , index: number) => {
      this.roamingBundle[index].status = bundle.state;
    });
  }
   /** helper method to sort array in which active or pending inactive bundle come first */
   sortRoamingBundlelist(roamingBundles: RoamingBundle[]): RoamingBundle[] {
    return roamingBundles.sort(function(item: RoamingBundle): any {
      if (item.status === RestrictionsStatus.active || item.status === RestrictionsStatus.PendingInActive  ) {
        return -1
      }
      else {
        return 1;
      }
    });
  }

  /** helper method to get bundles deatils */
 private getBundleDeatils(itemList: any[]): void {
    itemList.forEach((item: any, index: number) => {
      this.getDeatils(item.productOffering.productOfferingPrice);
      this.roamingBundle[index].deatils = this.bundleDeatilsList;
    });
  }
  /** helper method to get bundles deatils */
 private getDeatils(list: any[]): void {
     this.bundleDeatilsList = new Array<RoamingBundleDeatils>();

     const bundleDeatilsCallSent: RoamingBundleDeatils = new RoamingBundleDeatils();
      bundleDeatilsCallSent.type = romaingBundleDeailType.callssent;
      bundleDeatilsCallSent.price = list.find(element => element.name === romaingBundleDeailType.callssent)?.price.value;
      bundleDeatilsCallSent.priceUnit = list.find(element => element.name === romaingBundleDeailType.callssent)?.price.unit;
      bundleDeatilsCallSent.establishmentPrice =
       list.find(element => element.name === romaingBundleDeailType.callssentestablishment)?.price.value;
      bundleDeatilsCallSent.establishmentUnit =
       list.find(element => element.name === romaingBundleDeailType.callssentestablishment)?.price.unit;
      this.bundleDeatilsList.push(bundleDeatilsCallSent);

      const bundleDeatilsCallsReceived: RoamingBundleDeatils = new RoamingBundleDeatils();
      bundleDeatilsCallsReceived.type = romaingBundleDeailType.callsreceived;
      bundleDeatilsCallsReceived.price = list.find(element => element.name === romaingBundleDeailType.callsreceived)?.price.value;
      bundleDeatilsCallsReceived.priceUnit = list.find(element => element.name === romaingBundleDeailType.callsreceived)?.price.unit;
      bundleDeatilsCallsReceived.establishmentPrice =
       list.find(element => element.name === romaingBundleDeailType.callsreceivedestablishment)?.price.value;
      bundleDeatilsCallsReceived.establishmentUnit =
       list.find(element => element.name === romaingBundleDeailType.callsreceivedestablishment)?.price.unit;
      this.bundleDeatilsList.push(bundleDeatilsCallsReceived);

      const bundleDeatilsSmssent: RoamingBundleDeatils  = new RoamingBundleDeatils();
      bundleDeatilsSmssent.type = romaingBundleDeailType.smssent;
      bundleDeatilsSmssent.price = list.find(element => element.name === romaingBundleDeailType.smssent)?.price.value;
      bundleDeatilsSmssent.priceUnit = list.find(element => element.name === romaingBundleDeailType.smssent)?.price.unit;
      bundleDeatilsSmssent.description = list.find(element => element.name === romaingBundleDeailType.smssent)?.description
      this.bundleDeatilsList.push(bundleDeatilsSmssent);

      const bundleDeatilsData: RoamingBundleDeatils = new RoamingBundleDeatils();
      bundleDeatilsData.type = romaingBundleDeailType.data;
      bundleDeatilsData.price = list.find(element => element.name === romaingBundleDeailType.data)?.price.value;
      bundleDeatilsData.priceUnit = list.find(element => element.name === romaingBundleDeailType.data)?.price.unit;
      bundleDeatilsData.description = list.find(element => element.name === romaingBundleDeailType.data)?.description
      this.bundleDeatilsList.push(bundleDeatilsData);
  }
      /** method to get bundle type => bundle can be : Moundo or World */
  private getRoamingbundleType (itemsList: any[]): void {
    if ( itemsList.length === 1 ) {
      this.roamingBundle[0] = new RoamingBundle();
     this.roamingBundle[0].type = RoamingBundleTypes.World;
    }
    else {
    itemsList.forEach((item: any, index: number) => {
      const subitems: any[] =   item.productOffering.category;
      const type = subitems.find(item =>
        item.hasOwnProperty('@type') && (item['@type'] === 'extrasType' || item['@type'] === 'serviciosType'));
        const subType = subitems.find(item =>
        item.hasOwnProperty('@type') && (item['@type'] === 'extrasSubtype' || item['@type'] === 'serviciosSubtype'));
          if ( type && subType) {
                this.roamingBundle[index] = new RoamingBundle();
               this.roamingBundle[index].type = (subType['name'] === RoamingBundleTypes.World) ?
               RoamingBundleTypes.World : RoamingBundleTypes.Mundo;
               if (this.roamingBundle[index].type === RoamingBundleTypes.Mundo) {
                  this.moundoBundleProductOffering = item.productOffering.bundledProductOffering;
               }
          }
    });
  }
  }


  toggleproductOrderingManagement(reqBody: ProductOfferingPostObject): Observable<any> {
    return this.roamingDataService.toggleproductOrderingManagement(reqBody).pipe(map(res => res));
  }
  /** method to get bunlde type in terms of limited or unLimited tarrif
   * @param zoneId : zone id of country
   * ProductItemslist : ProductItemslist
  */

   getLimitedTarrif(zoneId: string, ProductItemslist: any[]): void {
     // zone zero when both the first and second countries is zone one ex: Francia - espana
    if (zoneId === Roaming.ZoneZero || zoneId === Roaming.ZoneOne) {
      // in case of zone 0 or zone 1 product item list always have one item (world card)
        const prodSpecCharValueUse: any[] = ProductItemslist[0]?.productOffering?.prodSpecCharValueUse?.find(item =>
           item?.name === 'limit' && item?.productSpecCharacteristicValue?.length > 0 &&
        item?.productSpecCharacteristicValue[0]?.value);
        if ( prodSpecCharValueUse) {
          // addding isLimitedTarrif dynamically in only this case to avoid any problems as it return back
          // from backend in case of origin country is zone 1
        this.roamingBundle[0]['isLimitedTarrif'] =
         prodSpecCharValueUse['productSpecCharacteristicValue'][0]?.['value'] === roamingTarrifTypes.limited;
        }
    }
  }
}

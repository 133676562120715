import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AppThemes } from '../shared/constants/defines';
import { CommonService } from '../core/services/common.service';
import { MiddlePageModel } from '../models/middle-page.model';
import { MiddlePageService } from './services/middle-page.service';


@Component({
  selector: 'sp-sp-middle-page',
  templateUrl: './sp-middle-page.component.html',
  styleUrls: ['./sp-middle-page.component.scss']
})
export class SpMiddlePageComponent implements OnInit {
 public data: MiddlePageModel;
  constructor(
    public middlePageService: MiddlePageService,
    public appService: AppService,
    public commonService: CommonService) { }

  ngOnInit() {
    this.data = this.middlePageService.data
    this.appService.showFullAppLoader = false;
    this.appService.theme = AppThemes.Theme2;
    this.commonService.showMenuButton = true;
    this.appService.appHeaderTitle = this.data.headerTitle;
  }


  actionClick(item: any): void {
    item.clickAction()
  }

}

import { StorageService } from '../../core/services/storage.service';
import { Company } from './../../models/company.model';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyService {
  selectedCompanyId: string;
  CompanyList: Company[];
  selectedCompanyGestionadoIkki: boolean;
  constructor(private storageService: StorageService) {
    this.CompanyList = [];
  }
}

import { CharacteristicsPerTerminalElement, CommercialTerminalList } from './commercial-terminal-list.model';

export class CommercialTerminal {
  public id: number;
  public type: string;
  public nombre: string;
  public orden: number;
  public ordenCuotaMensual: number;
  public ordenPagoInicial: number;
  public commercialTerminalList: CommercialTerminalList[];
  public characteristicsPerTerminalElement: CharacteristicsPerTerminalElement;
  public isPrioritary?: boolean;
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaymentJourneyParams, ThirdPartyURLParam } from '../../models/payment';
import { PaymentCardModel, PaymentCardPatchModel } from '../../models/payment-card.model';
import { PaymentWalletService } from '../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../services/delegate-creation.service';
import { PaymentNavigationService } from '../services/payment-navigation.service';
import { PaymentSuccessService } from '../services/payment-success.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { TopUpService } from 'src/app/top-up/top-up.service';
import { CaptureResponseService } from 'src/app/shared/services/capture-response.service';
import { EntryPointsService } from 'src/app/shared/services/entry-points.service';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';
import { tagging } from 'src/config/tagging-config';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
@Component({
  selector: 'sp-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  /* show Save Card Toggle  */
  showSaveCardToggle: boolean;
  /** to be unsubscribed to prevent memory leaks */
  subscriber: Subscription;
  /** To keep a copy of selected card here before set it null in forceClose case in the service */
  selectedCard: PaymentCardModel;

  constructor(
    public paySuccessSer: PaymentSuccessService,
    private paymentNavigationService: PaymentNavigationService,
    private paymentWalletService: PaymentWalletService,
    private delegateCreationService: DelegateCreationService,
    private topUpService: TopUpService,
    private captureResponse: CaptureResponseService,
    private entryPointService: EntryPointsService,
    private taggingHelperService: TaggingHelperService
  ) { }

  ngOnInit() {
    if (this.isNewCard() && this.paySuccessSer.currnetSuccess.isWalletEligible &&
    !this.paymentNavigationService.isPayingEnergyDebt) {
      this.showSaveCardToggle = true;
    } else {
      this.showSaveCardToggle = false;
      /** Clone selected to be used in saveCreditCardLogic method before setting it null in forceClose case in the service */
      this.selectedCard = this.paymentWalletService.selectedCard ?
      UtilitiesService.deepCopy(this.paymentWalletService.selectedCard) : {}; }
    this.subscriber = this.paymentNavigationService.changePaymentCompSubject.subscribe(() => {
      this.saveCreditCardLogic();
      /** Unsubscribe to save the card only one time */
      this.subscriber.unsubscribe();
    });
  }

  /** Method to crate a new card, save it and send its tagging */
  saveCreditCardLogic(): void {
    const isWalletEligible: boolean = this.paySuccessSer.currnetSuccess.isWalletEligible;
    const isSuccessToggleON: boolean = this.paySuccessSer.currnetSuccess.isSuccessToggleON;
    const thirdPartyObj: ThirdPartyURLParam = this.paymentNavigationService.thirdPartyURLParams;
    if (isWalletEligible && isSuccessToggleON && this.showSaveCardToggle) {
      const newCard: PaymentCardPatchModel = new PaymentCardPatchModel();
      const journeyObj: PaymentJourneyParams = this.paymentNavigationService.paymentJourneyParams;
      newCard.cardMask = thirdPartyObj.cardMask;
      newCard.documentID = journeyObj.payer.documentId;
      newCard.documentType = journeyObj.payer.documentType;
      newCard.expiryDate = thirdPartyObj.expiryDate;
      newCard.journey = journeyObj.journeyId;
      newCard.token = thirdPartyObj.cardToken;
      this.paymentWalletService.savePaymentCard(newCard).subscribe( () => {
        this.paymentWalletService.$savedCreditCardStatus.next(true);
      },
      error => {
        this.paymentWalletService.$savedCreditCardStatus.next(false);
      });
    } else {
      /** Check if selected card is not recurrent then make it recurrent */
      if (this.selectedCard && !this.selectedCard.pagoRecurrente
        && !this.paymentNavigationService.isAnonymousPayment) {
        /** Save credit card again to make it recurrent */
        const savedCard: PaymentCardPatchModel = this.getCardModelForPatch(this.selectedCard);
        this.paymentWalletService.savePaymentCard(savedCard, null, true).subscribe();
      }
    }

    /** Send tagging when leaving this confirmation screen and toggle is appeared */
    if (this.showSaveCardToggle) {
      this.delegateCreationService.paymentTaggingConfig.tagPaymentLeavingSuccessScreen(thirdPartyObj.transactionId, isSuccessToggleON);
    }
  }

  /**
   * Method for getting mapped card model to patch model
   * @param card selected card
   * @returns new instance of PaymentCardPatchModel model
   */
   getCardModelForPatch(card: PaymentCardModel): PaymentCardPatchModel {
    const cardObj: PaymentCardPatchModel = new PaymentCardPatchModel();
    cardObj.uuid = card.uuid;
    cardObj.cardMask = card.cardMaskData;
    cardObj.documentID = card.documentID;
    cardObj.documentType = card.documentType;
    cardObj.expiryDate = card.expiryDate;
    cardObj.journey = card.journey;
    cardObj.token = card.token;
    cardObj.principle = card.principle;
    return cardObj;
  }

  /**
   * Method to check that there is no selected card (no selection from wallet screen)
   * and the card's token is not exists in the current cards list
   * @param token of card
   */
  isNewCard(): boolean {
    return !this.paymentWalletService.cardsList || (!this.paymentWalletService.selectedCard &&
      !this.paymentWalletService.cardsList.find(card => card.token === this.paymentNavigationService.thirdPartyURLParams.cardToken));
  }
  onToggleBtnChange(checked: boolean): void {
    this.paySuccessSer.currnetSuccess.isSuccessToggleON = checked;

  }
  onSecondBtnclick(): void {
    if (this.paymentNavigationService.paymentJourneyParams.isSuccessTarrifFeeOverlayOpened) {
      this.paySuccessSer.currnetSuccess.firstBtnAction()
      this.taggingHelperService.track(tagging.afterTopUp.successPageWithNewOptions.interactions.VerTicketDePago.eventName,
        tagging.afterTopUp.successPageWithNewOptions.interactions.VerTicketDePago.data, tagging.afterTopUp.successPageWithNewOptions.state)
    }
    else {
      this.paySuccessSer.currnetSuccess.secondBtnAction();
      this.delegateCreationService.paymentTaggingConfig.successpaymentEntendidoBtnClick();
    }
  }

  onFirstBtnClick(): void {
    if (this.paymentNavigationService.paymentJourneyParams.isSuccessTarrifFeeOverlayOpened) {
        this.taggingHelperService.track(tagging.afterTopUp.successPageWithNewOptions.interactions.renovarTarifaOComprarBono.eventName,
        tagging.afterTopUp.successPageWithNewOptions.interactions.renovarTarifaOComprarBono.data,
        tagging.afterTopUp.successPageWithNewOptions.state)
        this.paymentNavigationService.openPaymentComponent(PaymentPages.TopupCountDownScreen);
    }
    else {
    this.paySuccessSer.currnetSuccess.firstBtnAction()
    this.taggingHelperService.track(tagging.afterTopUp.successPageWithNewOptions.interactions.VerTicketDePago.eventName,
      tagging.afterTopUp.successPageWithNewOptions.interactions.VerTicketDePago.data, tagging.afterTopUp.successPageWithNewOptions.state)
    }
  }

  sendCaptureResponse(): void {
    if (this.topUpService.isPega) {
      this.captureResponse.acceptedCaptureResponsePega(this.entryPointService.getEntryPointByCode(this.entryPointService.entryCode));
    }
    this.topUpService.isSuccessPegaOverlayOpened = false;
    this.topUpService.isPega = false;
  }

}

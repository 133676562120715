import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentSuccessDefault } from './payment-success-default';
import { PaymentSuccessInterface } from './payment-success-interface';

export class PaymentSuccessSaveCreditCard extends PaymentSuccessDefault implements PaymentSuccessInterface {
    constructor(
        public payNavSer: PaymentNavigationService,
        public translate: TranslateService,
        public router: Router
    ) {
        super(payNavSer, translate, router);
    }
    /**
     * the default implmentaion of the first btn in success payment screen
     */
    firstBtnAction(): void {
        this.payNavSer.closePayment();
    }

    /**
      * action for x btn click on overlay
      */
    closeBtnAction(): void {
        this.payNavSer.closePayment();
    }
}

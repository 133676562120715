
import {map} from 'rxjs/operators';
import { HttpRequestMethods, PAGES, expenseContractTypes, dummyID } from './../shared/constants/defines';
import { ErrorHadlingUsingNotificationService } from './../core/services/error-hadling-using-notification.service';
import { UtilitiesService } from './../shared/utils/utilities.service';
import { Router } from '@angular/router';
import { SubscriptionService } from './../core/services/subscription.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyService } from './../shared/services/company.service';
import { Injectable, EventEmitter } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { CustomerAccount } from '../models/customer-account.model';
import { ServiceDTO } from './service.model';
import { Contract } from './contract.model';
import { JSON_PATHS, commitmentContractQuery } from '../shared/constants/defines';
import * as Routes from '../shared/constants/routes-config';
import * as JsonQuery from 'jsonpath/jsonpath';
import { config } from '../../config/pages-config';
import { ServiceType } from '../shared/enums/serviceType.enum';
import * as _ from 'lodash';
import { UserProfile } from '../models/user-profile.model';
import { AccountOverview, AccountOverviewData } from '../models/account-overview.model';
import { ProfileType } from '../shared/enums/profileType.enum';
import { SiteType } from '../shared/enums/siteType.enum';
import { CustomerType } from '../shared/enums/customerType.enum';
import { TranslateService } from '@ngx-translate/core';
import { MyAccountSections } from '../shared/enums/my-account-sections.enum';
import { ContractType } from '../shared/enums/contract-type.enum';

@Injectable()
export class MyAccountService {
  /**
   * MyAccountService that share the title username and dni across all my account routes so they
   * be able to change it
   */
  public title: string;
  public username: string;
  public isPermissionView = false;
  public dni: string;
  public addressTwo: string;
  public addressOne: string;
  public showPaymentAddress: boolean;
  public billingClass: string;
  public isEditBankInfo: boolean;
  public editAccountNumber: boolean;
  public pendingEditEmail: boolean;
  public cameFromGDPR: boolean;

  public text1: string;
  public text2: string;
  public siteId: string;
  public skip: boolean;
  public site: CustomerAccount;
  public service: ServiceDTO;
  public allContracts: Contract[] = [];
  public siteServices: ServiceDTO[];
  private loadedSites: { siteId: string, allContracts: Contract[] }[] = [];
  public isONO = false;
  public hasStyleBelowTitle: boolean;
  public cameFromSiteSelector: boolean;
  showSecondTextChanged = new EventEmitter<{ show: boolean, text1: string, text2: string }>();

  isCommitmentContract: boolean;
  isGdprView: boolean;
  hideMyAcountHeader: boolean;
  public AccountOverview: AccountOverview = new AccountOverview();
  isUserAuthorized: boolean;

  /**
   * List of contracts that are not associated to a specific subscription id
   * and therefore are expenses related to a siteId instead of commitment contracts
   */
  public siteExpenses: Contract[];
  /**
   * Clicked expense contract to show its details in /myAccount/commitmentContracts
   */
  public expense: Contract;

  constructor(private storageService: StorageService, private companyService: CompanyService,
    private http: HttpClient, private subscriptionService: SubscriptionService,
    private router: Router,
    private utility: UtilitiesService,
    private translateService: TranslateService,
    private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService) { }
  loadheader(customerAccount) {
    this.pendingEditEmail = false;
    if (this.companyService.selectedCompanyId) {
      this.username = customerAccount.companyName;
      if (customerAccount.companyId.match(/^[xyzXYZ]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}/g) ||
        customerAccount.companyId.match(/^[0-9]{8}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}/g)) {
        const dniPart1 = (customerAccount.companyId.toString()).
          substring(0, customerAccount.companyId.length - 1);
        const dniPart2 = (customerAccount.companyId.toString()).
          substring(customerAccount.companyId.length - 1);
        this.dni = dniPart1 + '-' + dniPart2;
      }
      else {

        this.dni = customerAccount.companyId
      }
    }
    else {
      this.username = (customerAccount.firstName ? customerAccount.firstName : ' ') + ' ' +
        (customerAccount.familyName ? customerAccount.familyName : ' ');
      const dinPart1 = (this.storageService.userProfile.document.id.toString()).
        substring(0, this.storageService.userProfile.document.id.length - 1);
      const dinPart2 = (this.storageService.userProfile.document.id.toString()).
        substring(this.storageService.userProfile.document.id.length - 1);
      this.dni = dinPart1 + '-' + dinPart2;
    }
  }

  hideAndShowSecondText(show) {
    const textToShow = { show: show, text1: show ? this.text1 : '', text2: show ? this.text2 : '' };
    this.showSecondTextChanged.emit(textToShow);
  }
  getContracts() {
    const url = Routes.API_URLS.Contracts.getContracts.replace('{id}', this.siteId);
    let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((response: any) => {
      this.isONO = false;
      const items = JsonQuery.value(response, JSON_PATHS.CommitmentContracts.items) || null;
      this.allContracts = [];
      items.map(item => {
        const contract = new Contract();
        contract.penalty = JsonQuery.value(item, JSON_PATHS.CommitmentContracts.penalty) || null;
        contract.type = JsonQuery.value(item, JSON_PATHS.CommitmentContracts.type) || null;
        contract.startDate = item[JSON_PATHS.CommitmentContracts.startDate] || null;
        if (contract.startDate) {
          contract.startDate = this.utility.getDateWithMonth(contract.startDate);
        }
        contract.endDate = item[JSON_PATHS.CommitmentContracts.endDate] || null;
        contract.endDateWithOutFormat = item[JSON_PATHS.CommitmentContracts.endDate] || null;
        if (contract.endDate) {
          contract.endDate = this.utility.getDateWithMonth(contract.endDate);
        }
        contract.subscription = item[JSON_PATHS.CommitmentContracts.subscription] || null;
        this.allContracts.push(contract);
      });
      this.loadedSites.push({
        allContracts: this.allContracts,
        siteId: this.siteId
      })
      return this.allContracts;
    }));
  }

  getServiceContracts(isPackage?: boolean) {
    const serviceContracts = this.allContracts.filter(contract => {
      if (isPackage) {
        return contract.subscription.package && contract.subscription.package.toLowerCase() === this.service.package.type.toLowerCase();
      } else {
        return contract.subscription.id === this.service.id && !contract.subscription.package;
      }
    });
    return serviceContracts;
  }

  getSiteExpenses(): Contract[] {
    const wcsContracts: {
      [key: string]: {
        title: string;
        type: ContractType;
        desc: string;
      };
    } = this.translateService.instant('myAccount.commitmentContracts.relatedContentList');
    const serviceExpenses: Contract[] = this.allContracts
      .filter(contract => {
        if ((contract.subscription.id === dummyID || contract.subscription.id === null) ||
          expenseContractTypes.includes(contract.type)) {
          return contract
        } else {
          return !contract.subscription?.id && expenseContractTypes.includes(contract.type);
        }
      })
      .map(expense => {
        expense.title = Object.values(wcsContracts)?.find(conctractType => conctractType.type === expense.type)?.title;
        return expense;
      });
    return serviceExpenses;
  }

  setPlanCommitmentforMyaccount(contracts: Contract[]) {
    if (contracts) {
      if (contracts.length === 1) {
        this.storageService.myaccountVariables.plan_commitment = contracts[0].endDateWithOutFormat ?
          this.formatDate(new Date(contracts[0].endDateWithOutFormat)) : '';
      }
      else if (contracts.length > 1) {
        this.storageService.myaccountVariables.plan_commitment = this.getClosetDateToToday(contracts.map((item) => {
          return item.endDateWithOutFormat;
        }))
      } else {
        this.storageService.myaccountVariables.plan_commitment = '';
      }
    }
  }
  formatDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();
    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;
    return [year, month, day].join('-');
  }
  getClosetDateToToday(dates: string[]) {
    const today = new Date();
    const newerDates = [];
    dates.forEach((dateString) => {
      const date = new Date(dateString);
      if (date > today) {
        newerDates.push(date);
      }
    })
    if (newerDates.length === 1) {
      if (newerDates[0]) {
        return this.formatDate(newerDates[0]);
      }
    }
    else if (newerDates.length > 1) {
      newerDates.sort(function (date1, date2) {
        if (date1 > date2) { return 1 };
        if (date1 < date2) { return -1 };
        return 0;
      });
      if (newerDates[0]) {
        return this.formatDate(newerDates[0]);
      }
    } else {
      return '';
    }

  }
  areSiteContractsLoaded(): boolean {
    const site: {
      siteId: string;
      allContracts: Contract[];
    } = this.loadedSites.find(loadedSite => loadedSite.siteId === this.siteId);
    if (!site) {
      return false;
    } else {
      this.allContracts = site.allContracts;
      return true;
    }
  }

  private getSiteServices() {
    const siteServices = this.subscriptionService.customerData.services.filter((service) => {
      return service.siteId === this.siteId;
    });
    this.filterMyServices(siteServices);
    return this.siteServices;
  }
  goToServicesOrContracts(site: CustomerAccount): void {
    this.cameFromSiteSelector = true;
    this.siteId = site.id;
    this.site = site;
      this.getContracts().subscribe(contract => {
        /** get selected site's services (if loaded before) */
        let siteServices = this.getSiteServices();
        /** if the services didn't load then we must load it */
        if (siteServices.length < 1) {
          this.subscriptionService.GetCustomerServices(this.siteId, false , false)
            .subscribe(res => {
              siteServices = this.getSiteServices();
              this.goTo(siteServices);
            });
        } else {
          this.goTo(siteServices);
        }
      }, error => {
        // here we must check the site ONO or not based on the error code
        // if ono site navigate to contracts with ono flag
        if (error.error && error.error.ecode && error.error.ecode === commitmentContractQuery.onoErrorCode) {
          this.isONO = true;
          this.router.navigate([config.myAccount.commitmentContracts.route]);
        } else {
          this.isONO = false;
          this.commitmentContractErrorHandling(error, HttpRequestMethods.get);
        }
      });
  }

  private goTo(siteServices: ServiceDTO[]): void {
    if (siteServices) {
      const numberOfSiteExpenses: number = this.siteExpenses?.length || 0;
      if (siteServices.length === 1 && numberOfSiteExpenses === 0) {
        this.cameFromSiteSelector = false;
        /** get contracts for this service */
        const service: ServiceDTO = siteServices[0];
        if (!service.serviceContracts) {/** load the service contracts first time only */
          this.service = service;
          service.serviceContracts = this.getServiceContracts();
        }
        const serviceDto: ServiceDTO = new ServiceDTO();
        serviceDto.id = service.id;
        serviceDto.name = service.name;
        serviceDto.serviceContracts = service.serviceContracts;
        this.service = serviceDto;
        this.text1 = this.service.name;
        this.text2 = this.service.id;
        /** go to contracts directly */
        this.router.navigate([config.myAccount.commitmentContracts.route]);
      }
      else if (siteServices.length === 0 && numberOfSiteExpenses === 1) {
        this.service = null;
        this.expense = this.siteExpenses[0];
        /** go to contracts directly */
        this.router.navigate([config.myAccount.commitmentContracts.route]);
      }
      else if (siteServices.length + numberOfSiteExpenses > 1) {
        this.siteServices = siteServices;
        this.router.navigate([config.myAccount.serviceSelectorCommitmentContract.route]);
      } else {
        /** go to contracts directly */
        this.router.navigate([config.myAccount.commitmentContracts.route]);
      }
    }
  }

  commitmentContractErrorHandling(error, HttpRequestMethod: string) {
    const notificationModal =
      this.errorHadlingUsingNotificationService.errorHandlingUsingNotification(error,
        HttpRequestMethod, PAGES.MY_ACCOUNT, true);
    notificationModal.show();
  }

  private getIconName(serviceType: ServiceType) {
    return this.utility.setServiceIcon(serviceType);
  }

  private filterMyServices(services: ServiceDTO[]) {
    this.siteServices = [];
    services.forEach(service => {
      this.setServiceIconAndContractsAndPush(service);
    });

    this.siteExpenses = this.getSiteExpenses();

    const groupedServicesByPackage = _.groupBy(services, commitmentContractQuery.packageCode);
    let service: ServiceDTO;
    for (const index in groupedServicesByPackage) {
      if (groupedServicesByPackage[index][0].package && groupedServicesByPackage[index][0].package !== 'undefined') {
        service = new ServiceDTO();
        service.package = { type: groupedServicesByPackage[index][0].package.type };
        service.name = groupedServicesByPackage[index][0].package.name;
        service.type = ServiceType.VodafoneOne;
        this.setServiceIconAndContractsAndPush(service, true);
      }
    }
  }

  private setServiceIconAndContractsAndPush(service: ServiceDTO, isPackage?: boolean): void {
    service.iconName = this.getIconName(service.type);
    if (!service.serviceContracts) {/** load the service contracts first time only */
      this.service = service;
      service.serviceContracts = this.getServiceContracts(isPackage);
    }
    if (service.serviceContracts.length) {
      this.siteServices.push(service);
    }
  }

  initAccountOverviewData(): AccountOverviewData[] {
    return [
      {
        title: this.translateService.instant('myAccount.itemsList.tusDatos.body'),
        description: this.translateService.instant('myAccount.tusDatos.desc'),
        display: true,
        url: config.myAccount.details.route,
        section: MyAccountSections.myData
      },
      {
        title: this.translateService.instant('myAccount.itemsList.metodosDePago.body'),
        description: this.translateService.instant('myAccount.metodosDePago.desc'),
        display: true,
        url: config.myAccount.paymentMethods.route,
        section: MyAccountSections.payments
      },
      {
        title: this.translateService.instant('myAccount.datosAcceso.title'),
        description: this.translateService.instant('myAccount.datosAcceso.desc'),
        display: true,
        url: config.myAccount.accessInfo.route,
        section: MyAccountSections.myData
      },
      {
        title: this.translateService.instant('myAccount.misContratos.title'),
        description: this.translateService.instant('myAccount.misContratos.desc'),
        display: true,
        url: config.myAccount.contracts.route,
        section: MyAccountSections.contracts
      },
      {
        title: this.translateService.instant('myAccount.misPermanencias.title'),
        description: this.translateService.instant('myAccount.misPermanencias.desc'),
        display: true,
        url: config.myAccount.siteSelector.route,
        section: MyAccountSections.contracts
      },
      {
        title: this.translateService.instant('myAccount.permissionsPreferences.fieldsList.pPerm.title'),
        description: this.translateService.instant('myAccount.permissionsPreferences.fieldsList.pPerm.body'),
        display: true,
        url: config.myAccount.permissions.route,
        section: MyAccountSections.privacy
      },
      {
        title: this.translateService.instant('myAccount.permissionsPreferences.fieldsList.pPref.title'),
        description: this.translateService.instant('myAccount.permissionsPreferences.fieldsList.pPref.body'),
        display: true,
        url: config.myAccount.prefrences.route,
        section: MyAccountSections.none
      },
      {
        title: this.translateService.instant('myAccount.diagnostico.title'),
        description: this.translateService.instant('myAccount.diagnostico.desc'),
        display: true,
        url: config.myAccount.PermissionsAndPreferences.DiagnosticoDeMiVodafone.route,
        section: MyAccountSections.privacy
      },
      {
        title: this.translateService.instant('myAccount.itemsList.pagoFacturas.body'),
        display: true,
        url: config.billing.billPaymentDetails.route, // missing accountStatus queryParam
        section: MyAccountSections.payments
      }
    ];
  }

  checkMyAccountSectionsPrivileges(
    userProfile: UserProfile,
    currentSiteTipe: SiteType,
    data: AccountOverviewData[] = this.AccountOverview.accountOverviewData
  ): void {
    /**
     * checks to filter account Overview Data based on profile type & customer type & service type
     */
    if (userProfile && userProfile.profileType) {
      if (userProfile.profileType.toLowerCase() === ProfileType.COMPLETE.toLowerCase()) {
        this.checkPrivilegesCompleteUser(userProfile, currentSiteTipe, data);
      } else if (userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
        this.checkPrivilegesLightUser(userProfile, currentSiteTipe, data);
      } else if (userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()) {
        this.checkPrivilegesNetworkUser(userProfile, currentSiteTipe, data);
      }
    }

    // For MyAccountLandingTempComponent
    if (data.length > 6) {
      data[6].display = data[5].display;
      data[6].locked = data[5].locked;
      data[7].display = data[5].display;
      data[7].locked = data[5].locked;
      data[8].display = data[1].display;
      data[8].locked = data[1].locked;
    }
  }

  private checkPrivilegesCompleteUser(userProfile: UserProfile, currentSiteTipe: SiteType, data: AccountOverviewData[]): void {
    if (currentSiteTipe.toLocaleLowerCase() === SiteType.Prepaid.toLocaleLowerCase()) {
      /**
       *my vodafone service only  & your details with
       */
      data[0].display = true;
      data[1].display = false;
      data[2].display = true;
      data[3].display = false;
      data[4].display = false;
    }
    if (userProfile.customerType && userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
      data[5].display = true;
    }
    if (userProfile.customerType && userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase()) {
      data[5].display = true;
    }
  }

  private checkPrivilegesLightUser(userProfile: UserProfile, currentSiteTipe: SiteType, data: AccountOverviewData[]): void {
    data[0].locked = true;
    data[1].locked = true;
    data[2].locked = false;
    data[3].locked = true;
    data[4].locked = true;
    data[5].locked = true;
    if (
      userProfile.customerType &&
      (userProfile.customerType.toLowerCase() === CustomerType.Employee.toLowerCase() ||
        userProfile.customerType.toLowerCase() === CustomerType.SME.toLowerCase())
    ) {
      /**
       * my vodafone service only
       */
      data[0].display = false;
      data[1].display = false;
      data[2].display = true;
      data[3].display = false;
      data[4].display = false;
      data[5].display = false;
    }
    if (currentSiteTipe.toLocaleLowerCase() === SiteType.Prepaid.toLocaleLowerCase()) {
      /**
       * my vodafone service only  & your details
       */
      data[0].display = true;
      data[1].display = false;
      data[2].display = true;
      data[3].display = false;
      data[4].display = false;
    } else if (currentSiteTipe.toLocaleLowerCase() === SiteType.Postpaid.toLocaleLowerCase()) {
      data[4].locked = true;
    }
  }

  private checkPrivilegesNetworkUser(userProfile: UserProfile, currentSiteTipe: SiteType, data: AccountOverviewData[]): void {
    /**
     * all locked
     */
    data[0].locked = true;
    data[1].locked = true;
    data[2].locked = true;
    data[3].locked = true;
    data[4].locked = true;
    data[5].locked = true;
    if (userProfile.customerType && userProfile.customerType.toLowerCase() === CustomerType.Employee.toLowerCase()) {
      /**
       *my vodafone service only
       */
      data[0].display = false;
      data[1].display = false;
      data[2].display = true;
      data[3].display = false;
      data[4].display = false;
    }
    if (currentSiteTipe.toLocaleLowerCase() === SiteType.Prepaid.toLocaleLowerCase()) {
      /**
       *my vodafone service only  & your details with
       */
      data[0].display = true;
      data[1].display = false;
      data[2].display = true;
      data[3].display = false;
      data[4].display = false;
    }
  }

}

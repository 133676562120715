import {map, catchError} from 'rxjs/operators';
import { AditionalLines } from './../../models/aditional-lines.model';
import { AditionalLinesService } from './aditional-lines.service';
import { ServiceGroup } from './../enums/service-group.enum';
import { ServiceKeepWrap } from './../../models/service-keep-wrap.model';
import { OfferModel } from './../../models/offer.model';
import { ProductGroup } from './../enums/product-group.enum';
import { DiscountModel } from './../../models/discount.model';
import { ServiceProductModel } from '../../models/service-product.model';
import {
  JSON_PATHS, LOCAL_STORAGE_KEYS, isUnlimited, Commons, codesOutComparator, ERRORCODES, CRMCases, customerTypes, CLIENT_RS, SEGMENT_MICRO
} from './../constants/defines';
import { CombinationModel } from './../../models/combination.model';
import { ServiceKeepModel } from './../../models/service-keep.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { API_URLS } from '../constants/routes-config';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { HttpHeaders } from '@angular/common/http';
import * as JsonQuery from 'jsonpath/jsonpath';
import { PackageServiceModel } from '../../models/package-service.model';
import { ProductModel } from '../../models/product-composition.model';
import { ServiceType } from '../enums/serviceType.enum';
import { AttrModel } from '../../models/attr.model';
import { TranslateService } from '@ngx-translate/core';
import { CommitmentModel } from '../../models/commitments.model';
import { tagging } from '../../../config/tagging-config';
import { BubbleStatus } from '../../shared/constants/defines';
import { UtilitiesService } from '../utils/utilities.service';
import { CrossVariables } from '../../models/tagging.model';
import { CustomerType } from '../enums/customerType.enum';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { BundleType } from '../enums/bundleType.enum';
import { EntryPointModel } from '../../models/entry-points.model';
import { RecomendationModel } from '../../models/recomendation.model';

@Injectable()
export class RecommendationService {
  public keepsViewModel: ServiceKeepWrap[];

  public compareModel = {};

  public offers: OfferModel[];
  public currentProduct: ProductModel;

  // Recommender observable
  private recoSubject: BehaviorSubject<null> = new BehaviorSubject(null);
  public reco$: Observable<any> = this.recoSubject.asObservable();

  // misisdn selector
  public identifierOptions: any[] = [];

  // Offers observable
  private offerSubject = new BehaviorSubject(null);
  public offers$ = this.offerSubject.asObservable();

  public isCTC: boolean = false;

  // CurrentProduct observable
  private currentProductSubject = new BehaviorSubject(null);
  public currentProduct$ = this.currentProductSubject.asObservable();

  // comparator model observalbe
  private comparationSubject = new BehaviorSubject(null);
  public comparationModel$ = this.comparationSubject.asObservable();

  // keeps model observable
  private keeps: any[];
  private keepsSubject = new BehaviorSubject(null);
  public keepsModel$ = this.keepsSubject.asObservable();
  public keepTotalAmount = {'fee': 0, 'netFee': 0};
  public keepsOPL: ServiceKeepModel[];

  public unlimitedLit: string;
  public selectedLines: AditionalLines[] = [];

  private clientType: string;
  private shopType: string;
  public onePlusRecoData: any;

  public velocity: string = '';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private utilitiesService: UtilitiesService,
    private aditionalLinesService: AditionalLinesService,
    private cookieService: CookieService,
    private route: Router
  ) {}

  /**
   * Fetch All products of the current site and current service
   */
  GetProductAndRecommendations(screenCode?: string, offerVFDescriptor?: string, fields?: string): Observable<any> {
    let url: string = API_URLS.Recommendation.getRecommendation.replace('{customerAccountId}',
      this.subscriptionService.customerData.customerAccountsId);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers
    };

    if (fields) { url = url.concat('&fields=', fields); }
    if (screenCode) { url = url.concat('&screenCode=', screenCode); }
    if (offerVFDescriptor) { url = url.concat('&offerVFDescriptor=', encodeURIComponent(offerVFDescriptor)); }

    return this.http.get(url, options).pipe(map((res: any) => {
      // Json to Model
      this.recommendedOffersLogic(res.recommendedOffers);
      this.currentProductLogic(res.product);

      // Comparator model bussines logic
      this.comparatorModelLogic();

      // Keeps json to model and model for view
      this.keeps = res.keep;
      this.keepsLogic(res.keep);
      this.setRecoSubject(res);
      return res;
    }), catchError((err) => {
      this.isCTC = Number(err.status) === Number(ERRORCODES.AVAILABLENBAS) ||
      (Number(err.status) === Number(ERRORCODES.NEWAVAILABLENBAS) &&
      Number(err.error.ecode) === Number(ERRORCODES.AVAILABLENBAS)) ? true : false;
      return throwError(err);
    }));
  }

  /**
   * Get OnePlus Package Recommendation
   */
  GetProductAndRecommendationOPL(screenCode?: string, offerVFDescriptor?: string): Observable<RecomendationModel> {
    let url: string = API_URLS.Recommendation.getOPLRecommendation.replace('{customerAccountId}',
    this.subscriptionService.customerData.customerAccountsId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };

    if (screenCode) { url = url.concat('&screenCode=', screenCode); }
    if (offerVFDescriptor) { url = url.concat('&offerVFDescriptor=', encodeURIComponent(offerVFDescriptor)); }
    return this.http.get(url, options).pipe(map((res: RecomendationModel) => {
      this.recommendedOffersLogic(res.recommendedOffers);
      this.currentProductLogic(res.product);
      this.keepsOPL = res.keep;
      return res;
    }), catchError((err) => {
      this.isCTC = Number(err.status) === Number(ERRORCODES.AVAILABLENBAS) ||
      (Number(err.status) === Number(ERRORCODES.NEWAVAILABLENBAS) &&
      Number(err.error.ecode) === Number(ERRORCODES.AVAILABLENBAS)) ? true : false;
      this.route.navigate([config.dashboard.route]);
      return throwError(err);
    }));
  }

  private getIsMicroResegmented(): boolean {
    return this.storageService?.userProfile?.customerType === customerTypes.CONSUMER
      ? this.storageService.userProfile?.sites?.some((site: any) => site.clientType === CLIENT_RS && site.marketSegment === SEGMENT_MICRO)
      : false;
  }

  public setServiceId(currentValue, newValue) {
    Object.keys(this.compareModel).forEach(key => {
      if (this.isMobile(this.compareModel[key].serviceType)) {
        this.compareModel[key].offered.forEach(service => {
          if (service.id === currentValue) {
            service.id = newValue;
          } else if (service.id === newValue) {
            service.id = currentValue;
          }
        })
      }
    });

    this.offers.forEach(offer => {
      if (offer.product.packages.services && offer.product.packages.services.length > 0) {
        offer.product.packages.services.forEach(service => {
          this.updateServiceId(service, currentValue, newValue);
        });
      }
      if (offer.product.services && offer.product.services.length > 0) {
        offer.product.services.forEach(service => {
          this.updateServiceId(service, currentValue, newValue);
        })
      }
    });

    // call keeps login to know if extras or others has been excluded
    this.keepsLogic(this.keeps);

    this.offerSubject.next(this.offers);
    this.keepsSubject.next(this.keepsViewModel);
  }

  private updateServiceId(service: ServiceProductModel, currentValue: string, newValue: string): void {
    if (this.isMobile(service.type)) {
      if (service.id === currentValue) {
        service.id = newValue;
      } else if (service.id === newValue) {
        service.id = currentValue;
      }
    }
  }

  private comparatorModelLogic(): void {
    // Comparator model bussines logic
    this.compareModel = {};
    this.comparatorLogic('current', this.currentProduct);
    if (this.offers && this.offers.length > 0) {
      this.comparatorLogic('offered', this.offers[0].product);
    }
    this.comparationSubject.next(this.compareModel);
  }

  /**
   * Method for model current product and offered product for comparisson
   * @param entity {string} current products or offered product
   * @param model {ProductModel} to extract services
   */
  private comparatorLogic(entity, model) {
    if (model.packages && model.packages.services) {
      model.packages.services.map(service => {
        if (!codesOutComparator.includes(service.code)) {
          this.serviceComparatorLogic(service, entity, ProductGroup.PACKAGE);
        }
      });
    }

    if (model.services) {
      model.services.map(service => {
        if (!codesOutComparator.includes(service.code)) {
          this.serviceComparatorLogic(service, entity, ProductGroup.SERVICE);
        }
      });
    }

  }

  /**
   * Method for evaluate te service type and set as current or offered product
   * @param service {string} service to evaluate attrs
   * @param entity {ServiceProductModel} to evaluate type a set in hashset
   * @param type {string} current or offered product string, indicate the object to set
   */
  private serviceComparatorLogic(service: ServiceProductModel, entity, type) {
    // Template variables
    const serviceType: string = service.type.toUpperCase();
    const id = service.id;

    // Key value composition
    const keyValue: string = serviceType !== ServiceType.Tv.toUpperCase() ? `${serviceType}_${id}_${type}` : `${serviceType}_${type}`;

    // Check if pair key-object<current, offered> exists
    if (!this.compareModel[keyValue]) {
      this.compareModel[keyValue] = {};
    }

    // Check if attrs array exists and create if not
    if (!this.compareModel[keyValue][entity]) {
      this.compareModel[keyValue].serviceType = serviceType;
      this.compareModel[keyValue].group = type;
      this.compareModel[keyValue][entity] = [];
      if (serviceType && serviceType.toLowerCase() === ServiceType.Tv.toLowerCase()) {
        this.translate.get('dashboard.contentList.serviceSelector.servicesList.tv.tv_title').subscribe(tvMessage => {
          this.compareModel[keyValue]['name' + entity] = tvMessage;
        })
      } else {
        this.compareModel[keyValue]['name' + entity] = service.name;
      }
    }

    // Set attrs on current or offered pendind entity strind by keyValue
    service.attrs.forEach(attr => {
      if (this.isMobile(service.type) && attr.type !== 'data') {
          // TODO
      } else {
        const attrView: any = {};
        attrView.type = attr.type;
        attrView.img = this.calculateIconFromType(service.type);
        attrView.id = (this.isMobile(service.type) || this.isMBB(service.type)) ? service.id : null;
        attrView.value = attr.value;
        attrView.units = attr.units;
        attrView.label = this.calculateLabel(service, attr);
        attrView.code = service.code;
        attrView.subtitle = this.calculateSubtitle(service, attr);
        attrView.comparation = service.comparation;

        this.compareModel[keyValue][entity].push(attrView);

        if (this.isMobile(service.type)) {
          const opt = { text: service.id, value: service.id };

          let matchItem = false;
          this.identifierOptions.forEach(item => {
            if (item.value === service.id) {
              matchItem = true;
            }
          });

          if (!matchItem) {
            this.identifierOptions.push(opt);
          }
        }
      }

    });
    // TODO: Improve using diccionary
    this.compareModel[keyValue][entity].sort();
  }

  private calculateSubtitle(service: ServiceProductModel, attr: AttrModel): string {
    let label: string = '';
    if (service.type.toLowerCase() === ServiceType.Tv.toLowerCase()) {
      label += attr.value;
      label += (attr.units) ? ' ' + attr.units : '';
    }
    return label;
  }

  private currentProductLogic(product) {
    this.currentProduct = new ProductModel();

    if (product) {
      this.currentProduct.subscriptionsAmount = JsonQuery.value(product, JSON_PATHS.Product.subscriptionsAmount) || null;
      this.currentProduct.subscriptionsNetAmount = JsonQuery.value(product, JSON_PATHS.Product.subscriptionsNetAmount) || null;

      if (product.services) {
        const services = new Array<ServiceProductModel>();

        product.services.map(item => {
          services.push(this.jsonToServiceModel(item));
        })

        this.currentProduct.services = services;
      }

      if (product.package) {
        this.currentProduct.packages = this.jsonToPackageModel(product.package);
      }
    }

    // Emit observable valu
    this.currentProductSubject.next(this.currentProduct);
  }

  private recommendedOffersLogic(offers) {
    this.offers = new Array<OfferModel>();

    if (offers) {
      offers.map(item => {
        const offer = this.jsonToOfferModel(item);
        this.offers.push(offer);
      });
      this.offerSubject.next(this.offers);
    }

  }

  /**
   * Method to parse keeps json to view model
   */
  private keepsLogic(keeps) {
    this.keepsViewModel = new Array<ServiceKeepWrap>();

    // Group keeps by serviceId
    const keepsDiccionary = [];
    if (keeps) {
      keeps.map(item => {
        const idetify = JsonQuery.value(item, JSON_PATHS.Service.id) || null;
        if (!keepsDiccionary[idetify]) {
          keepsDiccionary[idetify] = [];
        }
        keepsDiccionary[idetify].push(this.jsonToServiceKeepModel(item));
      });

      // Model as ServiceKeepWrap[]
      Object.keys(keepsDiccionary).forEach(key => {
        const keepServiceWrap = new ServiceKeepWrap();

        keepServiceWrap.id = key;
        const pckOrder = this.getPriorityAsPackage(key);

        keepServiceWrap.serviceType = this.getServiceType(key);
        keepServiceWrap.serviceName = this.getServiceName(keepServiceWrap.serviceType);
        keepServiceWrap.img = this.utilitiesService.setServiceIcon(keepServiceWrap.serviceType);
        keepServiceWrap.extras = this.calculateServiceToKeep(keepsDiccionary[key], ServiceGroup.Extras);
        keepServiceWrap.others = this.calculateServiceToKeep(keepsDiccionary[key], ServiceGroup.Otros);
        keepServiceWrap.order = `${pckOrder}_${this.getOrderByType(keepServiceWrap.serviceType, key)}`;

        this.keepsViewModel.push(keepServiceWrap);
      });

      this.keepTotalAmount.fee = this.keepTotalAmount.netFee = 0;
      this.keepsViewModel.forEach(service => {
        service.extras.forEach(extra => {
          this.keepTotalAmount.fee += extra.fee ? parseFloat(extra.fee) : 0;
          this.keepTotalAmount.netFee += extra.netFee ? parseFloat(extra.netFee) : 0;
        });
        service.others.forEach(other => {
          if (!codesOutComparator.includes(other.code)) {
            this.keepTotalAmount.fee += other.fee ? parseFloat(other.fee) : 0;
              this.keepTotalAmount.netFee += other.netFee ? parseFloat(other.netFee) : 0;
          }
        });
      });

      // Order keeps
      this.keepsViewModel.sort(this.sortByAttr.bind({ sortField: 'order' }));

      this.keepsSubject.next(this.keepsViewModel);
    }
  }

  /**
   * Method that return order index for service type
   * @return {string} order key
   */
  private getOrderByType(serviceType: string, id: string): string {
    let order: number;
    switch (serviceType.toLowerCase()) {
      case ServiceType.Mobile.toLowerCase():
      case ServiceType.Postpaid.toLowerCase():
      case ServiceType.Prepaid.toLowerCase():
        order = 1;
        break;
      case ServiceType.Internet.toLowerCase():
      case ServiceType.Fibre.toLowerCase():
        order = 2;
        break;
      case ServiceType.Landline.toLowerCase():
        order = 3;
        break;
      case ServiceType.MbbPostpaid.toLowerCase():
      case ServiceType.MbbPrepaid.toLowerCase():
        order = 4;
        break;
      case ServiceType.Tv.toLowerCase():
        order = 5;
        break;
     }
    return `${order}_${id}`;
  }

/**
  * Method to get the serviceType from an id
  * @param id
  */
  public getServiceType(id: string): string {
    let idPack;
    let idServ;
    if (this.currentProduct.packages && this.currentProduct.packages.services && this.currentProduct.packages.services.length > 0) {
      idPack = this.currentProduct.packages.services.filter(num => num.id === id)[0];
    }
    if (this.currentProduct.services && this.currentProduct.services.length > 0) {
      idServ = this.currentProduct.services.filter(num => num.id === id)[0];
    }
    return idPack ? idPack.type.toLowerCase() : idServ ? idServ.type.toLowerCase() : '';
  }

/**
  * Method to get the serviceName from serviceType
  * @param serviceType
  */
  public getServiceName(serviceType: string): string {
    let serviceName: string;
    switch (serviceType) {
      case ServiceType.Tv.toLowerCase():
        this.translate.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_tele_titulo.body').subscribe(data => {
          serviceName = data;
        });
        break;
      case ServiceType.Mobile.toLowerCase():
      case ServiceType.Postpaid.toLowerCase():
      case ServiceType.Prepaid.toLowerCase():
        this.translate.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_movil_titulo.body').subscribe(data => {
          serviceName = data;
        });
        break;
      case ServiceType.Landline.toLowerCase():
        this.translate.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_fijo_titulo.body').subscribe(data => {
          serviceName = data;
        });
        break;
      case ServiceType.Internet.toLowerCase():
      case ServiceType.Fibre.toLowerCase():
        this.translate.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_fibra_titulo.body').subscribe(data => {
          serviceName = data;
        });
        break;
      case ServiceType.MbbPostpaid.toLowerCase():
      case ServiceType.MbbPrepaid.toLowerCase():
        this.translate.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_mbb_titulo.body').subscribe(data => {
          serviceName = data;
        });
        break;
      default:
        serviceName = '';
        break;
     }
    return serviceName;
  }

  private jsonToOfferModel(item) {
    const offer = new OfferModel();

    offer.id = JsonQuery.value(item, JSON_PATHS.Offer.id) || null;
    offer.typeReco = JsonQuery.value(item, JSON_PATHS.Offer.typeReco) || null;
    offer.codReco = JsonQuery.value(item, JSON_PATHS.Offer.codReco) || null;
    offer.codTax = JsonQuery.value(item, JSON_PATHS.Offer.codTax) || null;
    offer.offerTotalAmount = JsonQuery.value(item, JSON_PATHS.Offer.offerTotalAmount) || null;
    offer.offerNetAmount = JsonQuery.value(item, JSON_PATHS.Offer.offerNetAmount) || null;
    offer.hasAgent = JsonQuery.value(item, JSON_PATHS.Offer.hasAgent) || null;

    if (!item.evaluatedOffers.package) {
      item.evaluatedOffers.package = {};
    }

    // Package bussines logic
    if (item.evaluatedOffers.package) {
      offer.product = new ProductModel();
      offer.product.packages = this.jsonToPackageModel(item.evaluatedOffers.package);
    }

    if (item.discounts) {
      offer.discounts = new Array<DiscountModel>();
      item.discounts.map(discount => {
        offer.discounts.push(this.jsonToDiscount(discount));
      });
    }

    if (item.commitments) {
      offer.commitments = new Array<CommitmentModel>();
      item.commitments.map(commitment => {
        offer.commitments.push(this.jsonToCommitmentModel(commitment));
      })
    }

    // Services bussines logic
    if (item.evaluatedOffers.services) {
      offer.product.services = new Array<ServiceProductModel>();
      item.evaluatedOffers.services.map(serv => {
        offer.product.services.push(this.jsonToServiceModel(serv));
      });

      offer.product.services.sort(this.sortByAttr.bind({ sortField: 'order' }));
    }

    if (item.evaluatedOffers.subscriptionsAmount && item.evaluatedOffers.subscriptionsNetAmount) {
      offer.product.subscriptionsAmount = item.evaluatedOffers.subscriptionsAmount;
      offer.product.subscriptionsNetAmount = item.evaluatedOffers.subscriptionsNetAmount;
    }

    return offer;
  }

  private jsonToCommitmentModel(commitment: any) {
    const model = new CommitmentModel();
    model.type = JsonQuery.value(commitment, JSON_PATHS.Commitment.type) || null;
    model.commIniDate = JsonQuery.value(commitment, JSON_PATHS.Commitment.commIniDate) || null;
    model.commEndDate = JsonQuery.value(commitment, JSON_PATHS.Commitment.commEndDate) || null;
    model.commDuration = JsonQuery.value(commitment, JSON_PATHS.Commitment.commDuration) || null;
    model.penaltyAmount = JsonQuery.value(commitment, JSON_PATHS.Commitment.penaltyAmount) || null;
    model.inService = JsonQuery.value(commitment, JSON_PATHS.Commitment.inService) || null;
    return  model;
  }

  private jsonToPackageModel(packageObj: any) {
    const model = new PackageServiceModel();
    model.code = JsonQuery.value(packageObj, JSON_PATHS.Service.code) || null;
    model.fee = JsonQuery.value(packageObj, JSON_PATHS.Service.fee) || null;
    model.name = JsonQuery.value(packageObj, JSON_PATHS.Service.name) || null;
    model.longDescription = JsonQuery.value(packageObj, JSON_PATHS.Service.longDescription) || null;
    model.netFee = JsonQuery.value(packageObj, JSON_PATHS.Service.netFee) || null;
    model.type = JsonQuery.value(packageObj, JSON_PATHS.Service.type) || null;
    model.recoOPMicro = JsonQuery.value(packageObj, JSON_PATHS.Service.recoOPMicro) || null;
    if (packageObj.services) {
      model.services = new Array<ServiceProductModel>();
      packageObj.services.map(serv => {
        model.services.push(this.jsonToServiceModel(serv));
      });

      model.services.sort(this.sortByAttr.bind({ sortField: 'order' }));
    }

    return model;
  }

  /**
   * Method to transform service to ServiceRecommendationModel
   * @param service any service from json response
   */
  private jsonToServiceModel(service: any) {
    const model = new ServiceProductModel();
    model.code = JsonQuery.value(service, JSON_PATHS.Service.code) || null;
    model.fee = JsonQuery.value(service, JSON_PATHS.Service.fee) || null;
    model.id = JsonQuery.value(service, JSON_PATHS.Service.id) || null;
    model.name = JsonQuery.value(service, JSON_PATHS.Service.name) || null;
    model.longDescription = JsonQuery.value(service, JSON_PATHS.Service.longDescription) || null;
    model.netFee = JsonQuery.value(service, JSON_PATHS.Service.netFee) || null;
    model.type = JsonQuery.value(service, JSON_PATHS.Service.type) || null;
    model.order = this.getOrderByType(model.type, model.id);
    model.section = service.section || null;
    model.comparation = service.comparation || null;
    model.url_image = service.url_image || null;
    model.secureNet = service.secureNet || null;
    model.recoOPMicro = JsonQuery.value(service, JSON_PATHS.Service.recoOPMicro) || null;
    if (service.attrs) {
      model.attrs = new Array<AttrModel>();
      service.attrs.map(attr => {
        model.attrs.push(this.jsonToAttrModel(attr));
      })
    }

    return model;
  }

  private jsonToAttrModel(attr: any) {
    const model = new AttrModel();
    model.value = JsonQuery.value(attr, JSON_PATHS.Attr.value) || null;
    model.type = JsonQuery.value(attr, JSON_PATHS.Attr.type) || null;
    model.units = JsonQuery.value(attr, JSON_PATHS.Attr.units) || null;
    return model;
  }

  private jsonToDiscount(discount: any) {
    const model = new DiscountModel();
    model.code = JsonQuery.value(discount, JSON_PATHS.Discount.code) || null;
    model.type = JsonQuery.value(discount, JSON_PATHS.Discount.type) || null;
    model.dtoExpire = JsonQuery.value(discount, JSON_PATHS.Discount.dtoExpire) || null;
    model.dtoDuration = JsonQuery.value(discount, JSON_PATHS.Discount.dtoDuration) || null;
    model.amount = JsonQuery.value(discount, JSON_PATHS.Discount.amount) || null;
    model.netAmount = JsonQuery.value(discount, JSON_PATHS.Discount.netAmount) || null;
    return model;
  }

  /**
   * Bussines logic that transforms response keeps attribute to objects model
   */
  private jsonToServiceKeepModel(item) {
    const keep = new ServiceKeepModel();
    keep.code = JsonQuery.value(item, JSON_PATHS.Service.code) || null;
    keep.dateExpire = JsonQuery.value(item, JSON_PATHS.Service.dateExpire) || null;
    keep.id = JsonQuery.value(item, JSON_PATHS.Service.id) || null;
    keep.name = JsonQuery.value(item, JSON_PATHS.Service.name) || null;
    keep.type = JsonQuery.value(item, JSON_PATHS.Service.type) || null;

    if (item.combinations) {
      const combinations = new Array<CombinationModel>();
      item.combinations.map(comb => {
        const combination = new CombinationModel();
        combination.excludeAddon = JsonQuery.value(comb, JSON_PATHS.Combination.excludeAddon);
        combination.tariffId = JsonQuery.value(comb, JSON_PATHS.Combination.tariffId) || null;
        combinations.push(combination);
      });

      keep.combinations = combinations;
    }

    keep.fee = JsonQuery.value(item, JSON_PATHS.Service.fee) || null;
    keep.netFee = JsonQuery.value(item, JSON_PATHS.Service.netFee) || null;

    return keep;
  }

  private calculateLabel(service: ServiceProductModel, attr: AttrModel): string {
    if (service.type.toLowerCase() === ServiceType.Tv.toLowerCase()) {
      return service.name;
    } else {
      const unitText: string = (attr.units) ? attr.units : '',
            unLimitedVoice: string = this.translate.instant('migration.tarifa.tarifa_info_new.itemList.lit_mins_ilimitados.body'),
            unLimitedOthers: string = this.translate.instant('migration.tarifa.tarifa_info.itemList.lit_ilimitados.body');
      this.unlimitedLit = (attr.type.toLowerCase() === BundleType.Voice.toLowerCase()) ? unLimitedVoice : unLimitedOthers;
      return (attr.value.toLowerCase() === isUnlimited.toLowerCase()) ? `${unitText} ${this.unlimitedLit}` : `${attr.value} ${unitText}`;
    }
  }

  private isMobile(serviceType: string): boolean {
    return (serviceType.toLowerCase() === ServiceType.Mobile.toLowerCase()
        || serviceType.toLowerCase() === ServiceType.Postpaid.toLowerCase()
        || serviceType.toLowerCase() === ServiceType.Prepaid.toLowerCase());
  }

  private isMBB(serviceType: string): boolean {
    return (serviceType.toLowerCase() === ServiceType.MbbPostpaid.toLowerCase()
        || serviceType.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase());
  }

  private calculateIconFromType(type: ServiceType): string {
    if (type.toLowerCase() === ServiceType.ADSL.toLowerCase()
      || type.toLowerCase() === ServiceType.Fibre.toLowerCase()
      || type.toLowerCase() === ServiceType.Internet.toLowerCase()) {
      return 'icon-wifi';
    } else if (type.toLowerCase() === ServiceType.Landline.toLowerCase()) {
      return 'icon-landline-or-call';
    } else if (type.toLowerCase() === ServiceType.Tv.toLowerCase()) {
      return 'icon-tv';
    } else if (type.toLowerCase() === ServiceType.MbbPostpaid.toLowerCase()
      || type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()) {
      return 'icon-mbb';
    } else {
      return 'icon-mobile';
    }
  }

  calculateServiceToKeep(services: ServiceKeepModel[], serviceGruop: ServiceGroup) {
    return services.filter((item: ServiceKeepModel) => {
      let hasExtras = false;

      if (!item.combinations || item.combinations.length === 0) {
        hasExtras = true;
      } else {
        Object.keys(this.compareModel).forEach(key => {
          if (this.isMobile(this.compareModel[key].serviceType) || this.isMBB(this.compareModel[key].serviceType)) {
            this.compareModel[key].offered.map(service => {
              if (service.id === item.id) {
                item.combinations.map((comb: CombinationModel) => {
                  if (comb.tariffId === service.code && !comb.excludeAddon) {
                    hasExtras = true;
                  }
                })
              }
            })
          }
        });
      }

      return serviceGruop.toLowerCase() === item.type.toLowerCase() && hasExtras;
    });
  }

  public evaluateAdditionalLines(): boolean {
    const packageSrv = this.currentProduct.packages ?
      this.currentProduct.packages.services : null;
    const lineServices = this.currentProduct.services ?
      this.currentProduct.services.filter(srv =>
      (srv.type.toLowerCase() === ServiceType.Postpaid.toLowerCase()) || (srv.type.toLowerCase() === ServiceType.Prepaid.toLowerCase())
      ) : null;
    let maxPartis;
    let maxMicro;
    this.translate.get('migration.tarifasCommon.itemsList.reco').subscribe(data => {
      maxPartis = data.maxLineasPartis.body;
      maxMicro = data.maxLineasMicro.body;
    })

    if (packageSrv && lineServices && packageSrv.find(srv => srv.type === ServiceType.Postpaid) && (
      (packageSrv.length > 1 && lineServices.length < parseInt(maxPartis, 10) &&
      this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase())
      ||
      (packageSrv.length > 1 && lineServices.length < parseInt(maxMicro, 10) &&
      this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase())
    )) {
      return true;
    } else {
      return false;
    }
  }

  public setTaggingNbas(available: boolean) {
    CrossVariables.nba_availability = available ? Commons.yes : Commons.no;
    CrossVariables.nba_show = available ? Commons.yes : Commons.no;
  }

  public taggingLogic(hasNbas: boolean) {

    this.aditionalLinesService.selectedLines$.subscribe((lines: AditionalLines[]) => {
      this.selectedLines = lines;
    })
    const currentProduct = this.currentProduct;
    const offer = (this.offers && this.offers.length > 0) ?
      this.offers[0] : null;
    const currentAmount = currentProduct ? currentProduct.subscriptionsAmount : 0;
    const offeredAmount = offer ? offer.offerNetAmount : 0;
    const additionalLines = this.selectedLines[0] ? this.selectedLines[0].totalFee : 0;
    this.setTaggingNbas(hasNbas);
    tagging.migration.data.result_fee = Number(currentAmount) - Number(offeredAmount) + this.keepTotalAmount.fee
    + Number(additionalLines);

    const data = Object.assign({}, tagging.migration.data);
    data.event_name = tagging.migration.nba_show.event_name;
    data.help_bubble_status = BubbleStatus.available;
    data.lifecycle_timestamp = new Date().getTime() / 1000;
  }

  public getPriorityAsPackage(id: string): number {
    let idPack;
    let idServ;
    if (this.currentProduct.packages && this.currentProduct.packages.services && this.currentProduct.packages.services.length > 0) {
      idPack = this.currentProduct.packages.services.filter(num => num.id === id)[0];
    }
    if (this.currentProduct.services && this.currentProduct.services.length > 0) {
      idServ = this.currentProduct.services.filter(num => num.id === id)[0];
    }
    return idPack ? 1 : idServ ? 2 : 0;
  }

  /**
   * Method with sort criteria
   */
  private sortByAttr(a, b) {
    const sortKey = this['sortField'];
    return (a[sortKey] > b[sortKey]) ? 1 : ((b[sortKey] > a[sortKey]) ? -1 : 0)
  }

  public clean(_removeCookie: boolean = true): void {
    this.currentProduct = new ProductModel();
    this.keepsViewModel = [];
    this.offers = new Array<OfferModel>();
    this.compareModel = {};
    this.identifierOptions = [];
    this.isCTC = false;
    if (_removeCookie) {
        /** Revome cookie flujo_migracion CPP */
        this.utilitiesService.deleteCookie('flujo_migracion');
    }
  }

  public getExperiencieVersion() {
    /** Collect the saved values ​​from the localStorage, they can come from WCS or Cookies. */
    const _expVersion = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.FLOWMIGRATION);
    return  _expVersion;
  }

  public getRoutes() {
    if (this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.FLOWMIGRATION) === null) {
        let _values: any = {};
        /** Collect the WCS Values. */
        this.translate.get('migration.itemsList').subscribe(response => {
          _values.experienceVersion = response.experience_version.body;
          _values.experienceName = response.experience_name.body;
          _values.stickyCta = response.experience_value.experience_value.sticky_cta.body;
          _values.tableColour = response.experience_value.experience_value.tables_colour.body;
          _values.warmLimitedTime = (response.experience_value.experience_value.warm_limitedtime.body === 'true');
          _values.onlyChange = (response.experience_value.experience_value.only_change.body === 'true');
          _values.priceEndPromotion = (response.experience_value.experience_value.price_end_promotion.body === 'true');
          _values.stickyPrice = (response.experience_value.experience_value.sticky_price.body === 'true');
          _values.onlyDestiny = (response.experience_value.experience_value.only_destiny.body === 'true');
          _values.speedMobile = (response.experience_value.experience_value.speed_mobile.body === 'true');
        });
        this.translate.get('migration.itemsList.disable_Adobe_Target').subscribe(data => {
          if (data.body === 'false') {
            /** Collect the Cookie Values. */
            const _getExpVersion: string = document.cookie.replace(/(?:(?:^|.*;\s*)flujo_migracion\s*\=\s*([^;]*).*$)|^.*$/, '$1');
            if (_getExpVersion !== '') {
              let _decodeExpVersion: string = decodeURI(_getExpVersion);
              _decodeExpVersion = _decodeExpVersion.replace(/%3A/g, ':').replace(/%2C/g, ',');

              const _parseExpVersion: { [key: string]: any }  = JSON.parse(_decodeExpVersion);
              _values.experienceVersion = (_parseExpVersion.experienceVersion) ?
                      _parseExpVersion.experienceVersion : _values.experienceVersion;
              _values.experienceName = (_parseExpVersion.experienceName) ?
                      _parseExpVersion.experienceName : _values.experienceName;
              _values = this.setExperiencieValue(_parseExpVersion.experienceValues, _values);
            }
          }
          this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.FLOWMIGRATION, _values);
        });
    }
  }

  private setExperiencieValue(_experienceValues: {[key: string]: any}, _values: {[key: string]: any}): {[key: string]: any} {
    if (_experienceValues) {
      _values.stickyCta = (_experienceValues.stickyCta) ? _experienceValues.stickyCta : _values.stickyCta;
      _values.tableColour = (_experienceValues.tableColour) ? _experienceValues.tableColour.replace('%23', '#') : _values.tableColour;
      _values.warmLimitedTime = (_experienceValues.warmLimitedTime) ?
            (_experienceValues.warmLimitedTime === 'true') : _values.warmLimitedTime;
      _values.onlyChange = (_experienceValues.onlyChange) ? (_experienceValues.onlyChange === 'true') : _values.onlyChange;
      _values.priceEndPromotion = (_experienceValues.priceEndPromotion) ?
            (_experienceValues.priceEndPromotion === 'true') : _values.priceEndPromotion;
      _values.stickyPrice = (_experienceValues.stickyPrice) ?
            (_experienceValues.stickyPrice === 'true') : _values.stickyPrice;
      _values.onlyDestiny = (_experienceValues.onlyDestiny) ?
            (_experienceValues.onlyDestiny === 'true') : _values.onlyDestiny;
      _values.speedMobile = (_experienceValues.speedMobile) ?
            (_experienceValues.speedMobile === 'true') : _values.speedMobile;
    }
    return _values;
  };

  public getClientType() {
    const customerType = this.storageService.userProfile.customerType.toLowerCase();
    const isMicroResegmented: boolean = this.getIsMicroResegmented();

    this.translate.get('v10.commercial.request_config').subscribe(config => {
      this.clientType = isMicroResegmented
        ? config.clientType_Micro
        : (customerType === CustomerType.Consumer.toLowerCase())
          ? config.clientType_Partis
          : config.clientType_Micro;

      this.shopType = isMicroResegmented
        ? config.shopType_Micro
        : (customerType === CustomerType.Consumer.toLowerCase())
          ? config.shopType_Partis
          : config.shopType_Micro;
      this.cookieService.put('clientTypeID', this.clientType);
    });
  }

  public resetCesta(_data: EntryPointModel): Observable<any> {
    const customerId = this.subscriptionService.customerData.customerAccountsId,
          url = API_URLS.CPP.resetCesta.replace('{customerAccountId}', customerId).replace('{clientType}', this.clientType);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
       headers: headers,
       withCredentials: true
    };

    const data: EntryPointModel = (_data.offerVFDescriptor) ? _data : null;
    return this.http.post(url, data, options);
  }

  public changePricePlan(_recommendedOffers) {
    const url = API_URLS.CPP.changePricePlan;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
       headers: headers,
       withCredentials: true
    };
    const data: any = _recommendedOffers;
    return this.http.post(url, data, options);
  }


  public personalData(_emailReco?: string) {
    const url = API_URLS.CPP.personalData;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
       headers: headers,
       withCredentials: true
    };
    const data: any = {
      'siteId': this.subscriptionService.customerData.currentService.siteId,
      'contactPhone': this.subscriptionService.serviceListSite[0].id,
      'clientType': Number(this.clientType),
      'sceneType': 1,
      'shopType': Number(this.shopType),
      'email': _emailReco
    };
    return this.http.post(url, data, options);
  }

  public getLegalTerms() {
    const url = API_URLS.CPP.getlegalterms.replace('{clientType}', this.clientType).replace('{shopType}', this.shopType);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
       headers: headers,
       withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    }));
  }

  public postLegalTerms(_legalTerms: any) {
    const url = API_URLS.CPP.setlegalterms,
          headers = new HttpHeaders(),
          options = {
              headers: headers,
              withCredentials: true,
              responseType: 'text' as 'json',
          };

    return this.http.post(url, _legalTerms, options).pipe(map((res: any) => {
      return res;
    }));
  }

  public save() {
    const url = API_URLS.CPP.save;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
       headers: headers,
       withCredentials: true
    };
    const data: any = {};
    return this.http.post(url, data, options);
  }

  private setRecoSubject(_res: any): void {
    this.recoSubject.next(_res);
  }

  public getServiceCode(code: string): string {
    code = code.replace(/ /g, '_');
    if (code.indexOf('.') !== -1) {
        const _AuxCode: string[] = code.split('.');
        code = `${_AuxCode[0]}${_AuxCode[1]}`;
    }
    return code;
  }

  private viewTariffs(_typeTariff: string, _foundMMb: {[key: string]: any}): EntryPointModel {
    return _foundMMb.offered.find(valueCurrent => {
      valueCurrent.code = this.getServiceCode(valueCurrent.code);
      return _typeTariff.includes(valueCurrent.code);
    });
  }

  /** Check tariff Mobile postpaid */
  private tarriffIsUnlimited(_foundMMb: {[key: string]: any}): number {
    let _mobiletariff: number = CRMCases.cases0;

    /** Tariff TOTAL */
    const mobileTariffTOTAL: string = this.translate.instant('migration.tarifa.tarifa_info_new.itemList.lista_tarifas_total.body');
    const _tariffTOTAL: EntryPointModel = this.viewTariffs(mobileTariffTOTAL, _foundMMb);

    /** Tariff SUPER */
    const mobileTariffSUPER: string = this.translate.instant('migration.tarifa.tarifa_info_new.itemList.lista_tarifas_super.body');
    const _tariffSUPER: EntryPointModel = this.viewTariffs(mobileTariffSUPER, _foundMMb);

    /** Tariff ILIMITADA */
    const mobileUnlimitedTariff: string = this.translate.instant('migration.tarifa.tarifa_info_new.itemList.lista_tarifas_ilimitada.body');
    const _unlimitedTariff: EntryPointModel = this.viewTariffs(mobileUnlimitedTariff, _foundMMb);

    _mobiletariff = (_tariffTOTAL) ? CRMCases.cases1 : _mobiletariff;
    _mobiletariff = (_tariffSUPER) ? CRMCases.cases2 : _mobiletariff;
    _mobiletariff = (_unlimitedTariff) ? CRMCases.cases3 : _mobiletariff;

    return _mobiletariff;
  }

  public getComparation(_service: any, _comparation: any): boolean {
    let _found = 0;
    if (_comparation !== undefined) {
      if (_comparation.serviceType.toLowerCase() !== ServiceType.OneProfessional.toLowerCase()) {
        _found = _comparation.current.findIndex(element => element.code === _service.code);
      } else {
        _found = -1;
      }
    }
    return (_found === -1);
  }

  public checkComparation(_listComparation: any []): number {
    let restCases: number = -1,
        _foundItem: boolean = false;
    _listComparation.forEach(itemBlock => {
      if (itemBlock.offered) {
        itemBlock.offered.forEach(service => {
            const _changeService: boolean = this.getComparation(service, itemBlock);
            if (_changeService && !_foundItem) {
              /** When the serviceType is Mobile postpaid: Cases 1, 2 and 3 */
              if (itemBlock.serviceType.toLowerCase() === ServiceType.Postpaid.toLowerCase()) {
                      restCases = this.tarriffIsUnlimited(itemBlock);
                      _foundItem = true;
              }
              /** When the serviceType is Internet: Case 5 */
              if ((itemBlock.serviceType.toLowerCase() === ServiceType.Landline.toLowerCase()) ||
                  (itemBlock.serviceType.toLowerCase() === ServiceType.Fibre.toLowerCase()) ||
                  (itemBlock.serviceType.toLowerCase() === ServiceType.ADSL.toLowerCase())) {
                      restCases = CRMCases.cases5;
                      this.velocity = (service) ? `${service.value}${service.units}` : this.velocity;
                      _foundItem = true;
              }
              /** When the serviceType is TV: Case 4 */
              if ((itemBlock.serviceType.toLowerCase() === ServiceType.Tv.toLowerCase()) ||
                  (itemBlock.serviceType.toLowerCase() === ServiceType.TvOnline.toLowerCase())) {
                      restCases = CRMCases.cases4;
                      _foundItem = true;
              }
            }
        });
      }
    });
    return restCases;
  }

}

import { ServiceSelector, ERRORCODES, PAGES, HttpRequestMethods } from './../../../constants/defines';
import { UserProfile } from './../../../../models/user-profile.model';
import { AuthenticateService } from '../../../../core/services/authenticate.service';
import { Accordion } from './../../../../models/accordion.model';
import { CustomerAccount } from './../../../../models/customer-account.model';
import { CustomerAccountService } from './../../../services/customer-account.service';
import { SubscriptionService } from '../../../../core/services/subscription.service';
import { DataSource } from './../../../../models/radio-button-data-source.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterContentChecked, OnDestroy } from '@angular/core';
import { ProfileType } from '../../../enums/profileType.enum';
import { TranslateService } from '@ngx-translate/core';
import { IconClassNames } from '../../../enums/iconClassNames.enum';
import { ServiceType } from '../../../enums/serviceType.enum';
import { ErrorHandlingService } from '../../../../core/services/errorHandling.service';
import { Error } from '../../../../models/error.model';
import { TariffService } from '../../../services/tariff.service';
import { tagging } from './../../../../../config/tagging-config';
import { TaggingHelperService } from '../../../../core/services/tagging.helper.service';
import { ErrorHadlingUsingNotificationService } from '../../../../core/services/error-hadling-using-notification.service';
import { LoginService } from '../../../../login/login.service';


@Component({
  selector: 'sp-accordion-radio-button',
  templateUrl: './sp-accordion-radio-button.component.html',
  styleUrls: ['./sp-accordion-radio-button.component.scss']
})
export class SpAccordionRadioButtonComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {


  form: UntypedFormGroup;
  @Input() companyId: string;
  showError: boolean;
  // statusInactiveError: boolean = false;
  companyError: boolean;
  errorMessage: string;
  @Input() customerAccounts: CustomerAccount[];
  items: DataSource[];
  serviceIcon: string;
  vodafoneOne: DataSource[];
  sites: Accordion[];
  spinner: boolean;
  spinnerCompany: boolean;
  companyErrorMsg: string;
  largCompanyUrl: string;
  loadingCIFText: string;
  loadingSiteText: string;
  warningSiteMessage: string;
  warningButtonText: string;
  CIFIcon: string;
  vodafoneOneName: string;
  companyChooserError: Error;
  currentSiteId: string;
  isErrorConnection = false;
  @Input() userProfile: UserProfile;
  @Output() radioEvent: EventEmitter<DataSource> = new EventEmitter();
  @Output() showStatusInactiveError: EventEmitter<any> = new EventEmitter();
  @Input() hasExternalServices: boolean;
  curentSiteId: string;
  openVodafoneOne: boolean;
  ngAfterContentChecked(): void {
    if (this.customerAccounts.length && !this.sites.length && this.userProfile && !this.items.length && !this.vodafoneOne.length) {
      this.items = new Array<DataSource>();
      this.vodafoneOne = new Array<DataSource>();
      this.sites = [];
      this.openVodafoneOne = false
      if (this.authenticationService.hasRole('employee')) {
        this.showServiceIcon(this.subscriptionService.customerData.currentService)
        this.items = [{
          icon: this.serviceIcon, id: this.subscriptionService.customerData.currentService.id,
          label: this.subscriptionService.customerData.currentService.name, text: this.subscriptionService.customerData.currentService.id,
          value: this.subscriptionService.customerData.currentService.id,
          siteId: this.subscriptionService.customerData.customerAccountsId
        }]
      }
      else if (this.userProfile.profileType.toLowerCase() === ProfileType.NETWORK.toLowerCase()) {
        this.showServiceIcon(this.subscriptionService.customerData.currentService)
        this.items = [{
          icon: this.serviceIcon,
          id: this.subscriptionService.customerData.currentService.id,
          label: this.subscriptionService.customerData.currentService.name,
          text: this.subscriptionService.customerData.currentService.id,
          value: this.subscriptionService.customerData.currentService.id,
          siteId: this.subscriptionService.customerData.customerAccountsId

        }]
      }
      else if (this.userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {

        this.showServiceIcon(this.subscriptionService.customerData.currentService)
        this.items = [{
          icon: this.serviceIcon,
          id: this.subscriptionService.customerData.currentService.id,
          label: this.subscriptionService.customerData.currentService.name,
          text: this.subscriptionService.customerData.currentService.id,
          value: this.subscriptionService.customerData.currentService.id,
          siteId: this.subscriptionService.customerData.customerAccountsId
        }]
      }
      else {
        if (this.authenticationService.hasRole('authorized')) {
          this.sites = [];
          this.customerAccounts.map((el) => {

            if (this.companyId === el.companyId) {
              this.sites.push({
                id: el.id,
                companyId: el.companyId,
                status: el.status,
                type: el.type, email: el.email,
                familyName: el.familyName,
                firstName: el.familyName,
                formattedAddress: el.address.formattedAddress ? el.address.formattedAddress : el.address.toString(),
                addressLineOne: el.address.street + ' ' +
                  el.address.buildingNo + ', ' +
                  el.address.level,
                addressLineTwo: el.address.postcode + ', ' +
                  el.address.town,
                msisdn: el.msisdn,
                open: this.currentSiteId ? this.curentSiteId === el.id :
                  this.subscriptionService.customerData.currentService.siteId === el.id,
                openVodafoneOne: this.subscriptionService.customerData.currentService.package &&
                  (this.currentSiteId ? this.curentSiteId === el.id :
                    this.subscriptionService.customerData.currentService.siteId === el.id)
              })
            }
          })

        } else {

          this.customerAccounts.map((el) => {
            this.sites.push({
              id: el.id,
              companyId: el.companyId,
              status: el.status,
              type: el.type, email: el.email,
              familyName: el.familyName,
              firstName: el.familyName,
              formattedAddress: el.address.formattedAddress ? el.address.formattedAddress : el.address.toString(),
              addressLineOne: el.address.street + ' ' +
                el.address.buildingNo + ', ' +
                el.address.level,
              addressLineTwo: el.address.postcode + ', ' +
                el.address.town,
              msisdn: el.msisdn,
              open: this.currentSiteId ? this.curentSiteId === el.id :
                this.subscriptionService.customerData.currentService.siteId === el.id,
              openVodafoneOne: this.subscriptionService.customerData.currentService.package &&
                (this.currentSiteId ? this.curentSiteId === el.id :
                  this.subscriptionService.customerData.currentService.siteId === el.id)
            });
          })
        }
        if (this.sites.length === 1) {
          this.subscriptionService.customerData.services.map((el) => {
            this.showServiceIcon(el)
            if (!el.package && el.siteId === this.sites[0].id) {

              if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {


                this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
              }
              else {
                this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
              }
            }
            else if (el.package && el.siteId === this.sites[0].id && (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase())) {
              this.vodafoneOneName = el.package.name;
              if (this.subscriptionService.customerData.currentService.package &&
                this.subscriptionService.customerData.currentService.siteId === el.siteId) {
                this.openVodafoneOne = true
              }
              else {
                this.openVodafoneOne = false
              }
              if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
                this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
              } else {

                this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
              }

            }

          })
        }
        else {

          this.subscriptionService.customerData.services.map((el) => {
            this.showServiceIcon(el)
            if (!el.package && el.siteId === this.subscriptionService.customerData.customerAccountsId) {
              if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {

                this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
              }
              else {
                this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
              }
            }
            else if (el.package && (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase()) &&
              el.siteId === this.subscriptionService.customerData.customerAccountsId) {
              this.vodafoneOneName = el.package.name;
              if (this.subscriptionService.customerData.currentService.package &&
                this.subscriptionService.customerData.currentService.siteId === el.siteId) {
                this.openVodafoneOne = true
              }
              else {
                this.openVodafoneOne = false
              }
              if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
                this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
              } else {

                this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
              }
            }

          })
        }


      }
    }

  }
  showServiceIcon(el) {
    switch (el.type.toLowerCase()) {
      case ServiceType.Tv.toLowerCase():
        this.serviceIcon = IconClassNames.icon_tv;
        break;
      case ServiceType.MbbPostpaid.toLowerCase():
      case ServiceType.MbbPrepaid.toLowerCase():
        this.serviceIcon = IconClassNames.icon_mbb;
        break;
      case ServiceType.Prepaid.toLowerCase():
        this.serviceIcon = IconClassNames.icon_mobile_prepaid;
        break;
      case ServiceType.Postpaid.toLowerCase():
        this.serviceIcon = IconClassNames.icon_mobile_postpaid;
        break;
      case ServiceType.Internet.toLowerCase():
      case ServiceType.Fibre.toLowerCase():
        this.serviceIcon = IconClassNames.icon_internet;
        break
      case ServiceType.Landline.toLowerCase():
        this.serviceIcon = IconClassNames.icon_busines_phone;
        break;
      case ServiceType.ADSL.toLowerCase():
        this.serviceIcon = IconClassNames.icon_adsl;
        break;
      case ServiceType.VodafoneEnTuCasa.toLowerCase():
        this.serviceIcon = IconClassNames.icon_busines_phone;
        break;
      default:
        this.serviceIcon = IconClassNames.icon_mobile;
    };
  }
  radioClick(data: DataSource) {
    event.stopImmediatePropagation()
    if (data.siteId !== this.subscriptionService.customerData.currentService.siteId) {
      if (this.companyId) {
        this.customerAccountService.GetCustomerAccountById(data.siteId, this.companyId).subscribe();
      } else {
        this.customerAccountService.GetCustomerAccountById(data.siteId).subscribe();
      }
      this.subscriptionService.customerData.customerAccountsId = data.siteId;
    }
    const tempId = this.subscriptionService.customerData.currentService.id
    this.tariffService.getSelectedServicesStatus(data.id, () => {
      this.tagging.track(tagging.serviceSelector.serviceButtonClicked.eventName, tagging.serviceSelector.serviceButtonClicked.data);
      this.subscriptionService.customerData.currentService = this.subscriptionService.customerData.services.find((el) => el.id === data.id)
      this.subscriptionService.customerSubject.next(this.subscriptionService.customerData.currentService)
      this.subscriptionService.changeService.next();
      this.radioEvent.emit(data);
    }, (error) => {
      // handle error message from WCS
      this.subscriptionService.customerData.currentService = this.subscriptionService.customerData.services.find((el) => el.id === tempId)
      this.showStatusInactiveError.emit([true, error])
    }, PAGES.DASHBORD)

  }
  onClick(siteId: string) {
    this.currentSiteId = siteId;
    this.openVodafoneOne = false;
    this.items = [];
    this.vodafoneOne = [];
    this.curentSiteId = siteId
    this.spinner = false;
    if (this.subscriptionService.customerData.services.find((el) => {
      return el.siteId === siteId
    })) {
      this.showError = false;
      this.subscriptionService.customerData.services.map((el) => {
        this.showServiceIcon(el)
        if (siteId === el.siteId) {

          this.subscriptionService.serviceListSite.push(el)
          if (!el.package) {

            if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {

              this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
            }
            else {
              this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
            }
          }
          else if (el.package && (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
            el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
            el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
            el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase())) {
            this.vodafoneOneName = el.package.name;
            if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
              this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
            } else {

              this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
            }
            if (this.subscriptionService.customerData.currentService.package &&
              this.subscriptionService.customerData.currentService.siteId === el.siteId) {
              this.openVodafoneOne = true
            }
            else {
              this.openVodafoneOne = false
            }

          }
        }
        this.subscriptionService.checkShowOfferTVDashboard();
      })
    } else {
      this.spinner = true
      this.subscriptionService.GetCustomerServices(siteId).subscribe((res) => {
        this.isErrorConnection = false;
        this.showError = false;
        this.subscriptionService.customerData.services.map((el) => {
          this.showServiceIcon(el)
          if (!el.package && el.siteId === siteId) {

            if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {

              this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
            }
            else {
              this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
            }
          }
          else if (el.package && (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
            el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
            el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
             el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase()) && el.siteId === siteId) {
            this.vodafoneOneName = el.package.name;
            if (this.subscriptionService.customerData.currentService.package &&
              this.subscriptionService.customerData.currentService.siteId === el.siteId) {
              this.openVodafoneOne = true
            }
            else {
              this.openVodafoneOne = false
            }

            if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
              el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
              this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
            } else {

              this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
            }

          }

        })
        this.spinner = false
        this.subscriptionService.checkShowOfferTVDashboard();
      }, error => {
        if (error.status === 0 && (!navigator.onLine)) {
          this.isErrorConnection = true;
          const notificationModal =
            this.errorHadlingUsingNotificationService.noConnectionHandlingNotification(error, HttpRequestMethods.get, false);
          notificationModal.retry = () => {
            this.retryButton(this.curentSiteId)
            notificationModal.hide()
          }
          notificationModal.show();
        }
        else {
          this.showError = true
        }

      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerAccounts']) {
      this.openVodafoneOne = false
      this.items = [];
      this.sites = [];
      this.vodafoneOne = [];
      this.customerAccounts = changes['customerAccounts'].currentValue

    }
    if (changes['userProfile']) {
      this.openVodafoneOne = false
      this.items = [];
      this.sites = []
      this.vodafoneOne = [];
      this.userProfile = changes['userProfile'].currentValue

    }
    if (changes['companyId'] && this.companyId) {
      if (changes['companyId'].previousValue !== this.companyId) {
        this.openVodafoneOne = false
        this.companyId = changes['companyId'].currentValue;
        this.items = [];
        this.sites = [];
        this.vodafoneOne = [];
        this.getCompanySite(this.companyId);
        if (this.sites.length > 1) {
          this.onClick(this.subscriptionService.customerData.customerAccountsId)
        }
      }

    }
  }

  getCompanySite(id: string) {
    this.openVodafoneOne = false
    this.items = [];
    this.vodafoneOne = [];
    if (!this.customerAccountService.customerAccounts.find((el) => {
      return el.companyId === id
    })) {
      this.sites = [];
      this.spinnerCompany = true

      this.customerAccountService.GetCustomerAccounts(id).subscribe(() => {
        this.companyError = false;
        this.sites = [];
        this.customerAccounts.map((el) => {
          if (this.companyId === el.companyId) {
            this.sites.push({
              id: el.id,
              companyId: el.companyId,
              status: el.status,
              type: el.type, email: el.email,
              familyName: el.familyName,
              firstName: el.familyName,
              formattedAddress: el.address.formattedAddress ? el.address.formattedAddress : el.address.toString(),
              addressLineOne: el.address.street + ' ' +
                el.address.buildingNo + ', ' +
                el.address.level,
              addressLineTwo: el.address.postcode + ', ' +
                el.address.town,
              msisdn: el.msisdn,
              open: this.currentSiteId ? this.curentSiteId === el.id :
                this.subscriptionService.customerData.currentService.siteId === el.id,
              openVodafoneOne: this.subscriptionService.customerData.currentService.package &&
                (this.currentSiteId ? this.curentSiteId === el.id :
                  this.subscriptionService.customerData.currentService.siteId === el.id)
            })
          }
        })
        if (this.sites.length === 1) {
          this.items = [];
          this.vodafoneOne = []
          this.subscriptionService.GetCustomerServices(this.sites[0].id).subscribe((res) => {
            this.subscriptionService.customerData.services.map((el) => {
              this.showServiceIcon(el)
              if (!el.package && el.siteId === this.sites[0].id) {

                if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {

                  this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
                }
                else {
                  this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
                }
              }
              else if (el.package && el.siteId === this.sites[0].id &&
                (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
                  el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
                  el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
                  el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase())) {
                this.vodafoneOneName = el.package.name;
                if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
                  this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
                } else {

                  this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
                }
                if (this.subscriptionService.customerData.currentService.package &&
                  this.subscriptionService.customerData.currentService.siteId === el.siteId) {
                  this.openVodafoneOne = true
                }
                else {
                  this.openVodafoneOne = false
                }
              }

            })
          })
        }
        if (this.sites.length > 1) {
          this.onClick(this.subscriptionService.customerData.customerAccountsId)
        }
        this.spinnerCompany = false
      }, error => {
        this.companyError = true;
        if (error && error.error && error.error.ecode) {
          if (error.error && error.error.ecode === ERRORCODES.NOMEERRORCODE) {
            this.translate.get('dashboard.contentList.serviceSelector.messagesList.ssNotSohoErr.ssNotSohoErr_description').
              subscribe(data => {
                this.companyErrorMsg = data;
              })
          }
          else {
            this.companyChooserError = this.errorHandling.lookUpError(PAGES.DASHBORD, error);
            this.companyErrorMsg = this.companyChooserError.description
          }
        }
      });
    } else {
      this.companyError = false;
      this.sites = [];
      this.customerAccounts.map((el) => {
        if (this.companyId === el.companyId) {
          this.sites.push({
            id: el.id,
            companyId: el.companyId,
            status: el.status,
            type: el.type, email: el.email,
            familyName: el.familyName,
            firstName: el.familyName,
            formattedAddress: el.address.formattedAddress ? el.address.formattedAddress : el.address.toString(),
            addressLineOne: el.address.street + ' ' +
              el.address.buildingNo + ', ' +
              el.address.level,
            addressLineTwo: el.address.postcode + ', ' +
              el.address.town,
            msisdn: el.msisdn,
            open: this.currentSiteId ? this.curentSiteId === el.id :
              this.subscriptionService.customerData.currentService.siteId === el.id,
            openVodafoneOne: this.subscriptionService.customerData.currentService.package &&
              (this.currentSiteId ? this.curentSiteId === el.id :
                this.subscriptionService.customerData.currentService.siteId === el.id)
          })
        }
      })
      if (this.sites.length === 1) {
        this.items = [];
        this.vodafoneOne = []
        if (this.subscriptionService.customerData.services.find((el) => {
          return el.siteId === this.sites[0].id
        })) {
          this.subscriptionService.customerData.services.map((el) => {
            this.showServiceIcon(el)
            if (!el.package && el.siteId === this.sites[0].id) {
              if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {

                this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
              }
              else {
                this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
              }
            }
            else if (el.package && (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
              el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase()) && el.siteId === this.sites[0].id) {
              this.vodafoneOneName = el.package.name;
              if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
                this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
              } else {

                this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
              }
              if (this.subscriptionService.customerData.currentService.package &&
                this.subscriptionService.customerData.currentService.siteId === el.siteId) {
                this.openVodafoneOne = true
              }
              else {
                this.openVodafoneOne = false
              }
            }

          })
        } else {
          this.spinnerCompany = true
          this.subscriptionService.GetCustomerServices(this.sites[0].id).subscribe((res) => {
            this.companyError = false;
            this.subscriptionService.customerData.services.map((el) => {
              this.showServiceIcon(el)
              if (!el.package && el.siteId === this.sites[0].id) {

                if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {

                  this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: '', value: el.id, siteId: el.siteId })
                }
                else {
                  this.items.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
                }
              }
              else if (el.package && (el.package.code.toLowerCase() === ServiceSelector.one.toLowerCase() ||
                el.package.code.toLowerCase() === ServiceSelector.paquete.toLowerCase() ||
                el.package.code.toLowerCase() === ServiceSelector.Familia.toLowerCase() ||
                el.package.code.toLowerCase() === ServiceSelector.Unitario.toLowerCase())
                && el.siteId === this.sites[0].id) {
                this.vodafoneOneName = el.package.name;
                if (el.type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
                  el.type.toLowerCase() === ServiceType.ADSL.toLowerCase()) {
                  this.vodafoneOne.push({
                    icon: this.serviceIcon, id: el.id,
                    label: el.name, text: '', value: el.id, siteId: el.siteId
                  })
                } else {

                  this.vodafoneOne.push({ icon: this.serviceIcon, id: el.id, label: el.name, text: el.id, value: el.id, siteId: el.siteId })
                }
                if (this.subscriptionService.customerData.currentService.package &&
                  this.subscriptionService.customerData.currentService.siteId === el.siteId) {
                  this.openVodafoneOne = true
                }
                else {
                  this.openVodafoneOne = false
                }
              }

            })
            this.spinnerCompany = false
          }, error => {
            this.companyError = true;

          })
        }
      }

    }

  }
  constructor(
    private readonly bulider: UntypedFormBuilder,
    public subscriptionService: SubscriptionService,
    public customerAccountService: CustomerAccountService,
    private authenticationService: AuthenticateService,
    private translate: TranslateService,
    public tariffService: TariffService,
    private tagging: TaggingHelperService,
    private errorHandling: ErrorHandlingService,
    private loginService: LoginService,
    private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService
  ) {
    if (!this.items) {
      this.items = new Array<DataSource>();
    }
    this.sites = new Array<Accordion>();
    this.openVodafoneOne = false
    this.vodafoneOne = new Array<DataSource>();
    this.form = this.bulider.group({
      companyNameField: ['', []]
    });
  }

  ngOnInit() {
    this.openVodafoneOne = false;
    this.translate.get('dashboard').subscribe(data => {
      this.loadingSiteText = data.contentList.serviceSelector.messagesList.ssLoadingMessage.ssLoadingMessage_description;

      this.loadingCIFText = data.contentList.serviceSelector.messagesList.ssCifLoadingMessage.ssCifLoadingMessage_description

      this.warningSiteMessage = data.contentList.serviceSelector.messagesList.ssCifLoadingErr.ssCifLoadingErr_description
      this.warningButtonText = data.contentList.serviceSelector.messagesList.ssCifLoadingErr.ssCifLoadingErr_button1.text
    });

  }
  siebleRedirect() {
    this.loginService.siebleRedirection(this.subscriptionService.customerData.customerAccountsId
      , this.subscriptionService.customerData.currentService.id)
  }
  retryButton(siteId) {
    this.showError = false
    this.onClick(siteId)
  }
  ngOnDestroy(): void {
    this.vodafoneOne = [];
    this.companyError = false;
    this.spinner = false;
    this.spinnerCompany = false;
    this.openVodafoneOne = false
    this.tariffService.isError = false;
    this.customerAccountService.isError = false;
  }
}

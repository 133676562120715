import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AnonymousPaymentCustomer } from '../../../../models/payment';
import { AnonymousPostPaidPaymentService } from '../../../../payment/services/anonymous-post-paid-payment.service';
import { PaymentErrorService } from '../../../../payment/services/payment-error.service';
import { PaymentNavigationService } from '../../../../payment/services/payment-navigation.service';
import {
  MSISDNPrefix,
  netPlusErrormessage,
  netPlusErrorName,
  nifPrefix,
  anonymousIdentificationVFAccount,
  anonymousIdentificationMsisdn,
} from '../../../../shared/constants/defines';
import { PaymentCompoentInputData_Keys } from '../../../../shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from '../../../../shared/enums/payment-pages.enum';
import { PaymentErrorCategory } from '../../../../shared/enums/payment-error-category.enum';
import { tagging } from '../../../../../config/tagging-config';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { ANONYMOUS_IDENTIFICATION_EVENT_CONTEXT, FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { InteractionTaggingModel } from 'src/app/models/new-tagging-Interaction.model';

@Component({
  selector: 'sp-payment-authentication',
  templateUrl: './payment-authentication.component.html',
  styleUrls: ['./payment-authentication.component.scss']
})
export class PaymentAuthenticationComponent implements OnInit {
  //#region Declarations
  @ViewChild('form') form: ElementRef;
  infoForm: UntypedFormGroup;
  cif: boolean;
  mobileNumber: boolean;
  accountNumberBlured: boolean;
  isMobileNumberValid: boolean = true;
  isAccountNumberValid: boolean = true;
  isCifValid: boolean = true;
  isNifValid: boolean = true;
  isFormValid: boolean = true;
  isTabletView: boolean;
  isSubmitted: boolean;
  identificationError: string = '';
  paymentNumberError: string = '';
  identificationType: string = anonymousIdentificationVFAccount;
  anonymousPaymentWCSData: {
    subtitle: string, description: string, note: string,
    identificationTitle: string, identificationNif: string, identificationCif: string,
    identificationHelperText: string, identificationError: string
    paymentNumberTitle: string, accountNumber: string, mobileNumber: string,
    accountNumberHelperText: string, mobileNumberHelperText: string,
    paymentNumberError: string
    privacy: string, buttonText: string, securedSSL: string,
    masterCardIcon: string, visaIcon: string,
    focusOutErrorText: string, missingMobileText: string
  } = {
      subtitle: '', description: '', note: '', identificationTitle: '',
      identificationNif: '', identificationCif: '', identificationHelperText: '', identificationError: '',
      paymentNumberTitle: '', accountNumber: '', mobileNumber: '', accountNumberHelperText: '',
      mobileNumberHelperText: '', paymentNumberError: '', privacy: '', buttonText: '',
      securedSSL: '', masterCardIcon: '', visaIcon: '', focusOutErrorText: '', missingMobileText: ''
    };
  //#endregion
  showMobileNumerHelpertext: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private paymentNavigationService: PaymentNavigationService,
    private paymentErrorService: PaymentErrorService,
    private anonPayService: AnonymousPostPaidPaymentService,
    private newTagging: NewTaggingHelperService,
    private taggingHelper: TaggingHelperService
  ) { }

  ngOnInit(): void {
    this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.state(data.page);
    });
    this.getWCSData();
    this.initForm();
    this.isTabletView = window.innerWidth >= Breakpoints.TABLET;
  }

  /** Method for setting all needed texts from WCS */
  getWCSData(): void {
    this.translateService.get('v10.payment.itemsList.anonymousPayment.es.anonymousAuthntication')
      .subscribe((data) => {
        this.anonymousPaymentWCSData.subtitle = data.subtitle;
        this.anonymousPaymentWCSData.description = data.description;
        this.anonymousPaymentWCSData.note = data.note;
        this.anonymousPaymentWCSData.identificationTitle = data.identificationTitle;
        this.anonymousPaymentWCSData.identificationNif = data.identificationNif;
        this.anonymousPaymentWCSData.identificationCif = data.identificationCif;
        this.anonymousPaymentWCSData.identificationHelperText = data.identificationHelperText;
        this.anonymousPaymentWCSData.identificationError = data.identificationError;
        this.anonymousPaymentWCSData.paymentNumberTitle = data.paymentNumberTitle;
        this.anonymousPaymentWCSData.accountNumber = data.accountNumber;
        this.anonymousPaymentWCSData.mobileNumber = data.mobileNumber;
        this.anonymousPaymentWCSData.accountNumberHelperText = data.accountNumberHelperText;
        this.anonymousPaymentWCSData.mobileNumberHelperText = data.mobileNumberHelperText;
        this.anonymousPaymentWCSData.paymentNumberError = data.paymentNumberError;
        this.anonymousPaymentWCSData.privacy = data.privacy;
        this.anonymousPaymentWCSData.buttonText = data.button1;
        this.anonymousPaymentWCSData.securedSSL = data.securedSSL;
        this.anonymousPaymentWCSData.masterCardIcon = data.masterCardIcon;
        this.anonymousPaymentWCSData.visaIcon = data.visaIcon;
        this.anonymousPaymentWCSData.focusOutErrorText = data.error;
        this.anonymousPaymentWCSData.missingMobileText = data.mobileNumberMissingHelperText;
      });
  }

  /** Method for initiate the form controls */
  initForm(): void {
    this.infoForm = this.fb.group({
      nif: ['', {
        validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'),
        Validators.minLength(9)], updateOn: 'change'
      }],
      cif: ['', { updateOn: 'change' }],
      accountNumber: ['', {
        validators: [Validators.required, Validators.pattern('^[0-9]+$'),
        Validators.minLength(9)], updateOn: 'change'
      }],
      mobileNumber: ['', { updateOn: 'change' }],
    });
  }

  /**
   * Method for handling each radio button logic
   * @param RadioType should be NIF, CIF, AccountNumber or MobileNumber
   */
  onRadioButtonClick(RadioType: string): void {
    if (RadioType === 'NIF') {
      this.cif = false;
      this.infoForm.get('nif').setValidators([Validators.required,
      Validators.pattern('^(?![A-Za-z]+$)[0-9A-Za-z]+$'), Validators.minLength(9)]);
      this.infoForm.get('cif').clearValidators();
      this.infoForm.get('cif').setValue('');
      this.isCifValid = true;
      this.checkValueAndFocus('nif', true);
    }
    else if (RadioType === 'CIF') {
      this.cif = true;
      this.infoForm.get('cif').setValidators([Validators.required,
      Validators.pattern('^(?![A-Za-z]+$)[0-9A-Za-z]+$'), Validators.minLength(9)]);
      this.infoForm.get('nif').clearValidators();
      this.infoForm.get('nif').setValue('');
      this.isNifValid = true;
      this.checkValueAndFocus('cif', true);
    }
    else if (RadioType === 'AccountNumber') {
      this.mobileNumber = false;
      this.infoForm.get('accountNumber').setValidators([Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(9)]);
      this.infoForm.get('mobileNumber').clearValidators();
      this.infoForm.get('mobileNumber').setValue('');
      this.isMobileNumberValid = true;
      this.checkValueAndFocus('accountNumber', false);
      this.showMobileNumerHelpertext = false;
      this.identificationType = anonymousIdentificationVFAccount;
    }
    else if (RadioType === 'MobileNumber') {
      this.mobileNumber = true;
      /** Set validation for accepting numbers only to be checked while typing */
      this.infoForm.get('mobileNumber').setValidators([Validators.required, Validators.pattern('^(6|7)[0-9]*$')]);
      this.infoForm.get('accountNumber').clearValidators();
      this.infoForm.get('accountNumber').setValue('');
      this.isAccountNumberValid = true;
      this.checkValueAndFocus('mobileNumber', false);
      this.identificationType = anonymousIdentificationMsisdn;
    }
    this.infoForm.get('nif').updateValueAndValidity();
    this.infoForm.get('cif').updateValueAndValidity();
    this.infoForm.get('mobileNumber').updateValueAndValidity();
    this.infoForm.get('accountNumber').updateValueAndValidity();

    this.checkFormValidity();
  }

  /** Method for submitting the form */
  submit(): void {
    this.paymentNavigationService.showVodafoneLoader();
    this.isSubmitted = true;
    const mobileNumber: string = this.infoForm.get('mobileNumber').value;
    const accountNumber: string = this.infoForm.get('accountNumber').value;
    this.anonPayService.anonymousPaymentCustomer = new AnonymousPaymentCustomer();
    const idNumber: string = this.cif ? this.infoForm.get('cif').value : this.infoForm.get('nif').value;
    this.anonPayService.anonymousPaymentCustomer.documentId = idNumber;
    if ( accountNumber ) {
      this.paymentNavigationService.accountNumberValue = accountNumber;
      this.anonPayService.anonymousPaymentCustomer.customerAccountNumber = accountNumber;
      this.paymentNavigationService.paymentJourneyParams.payer.siteId = accountNumber;
      this.paymentNavigationService.paymentJourneyParams.buyer.siteId = accountNumber;
      this.paymentNavigationService.openPaymentComponent(PaymentPages.paymentDisclaimer);
    }
    else {
    const loginHint: string = MSISDNPrefix + mobileNumber + nifPrefix + idNumber;
    this.anonPayService.validateAnonymousPaymentCustomer(loginHint).subscribe(() => {
      this.anonPayService.anonymousPaymentCustomer.customerMSIDN = mobileNumber;
      this.paymentNavigationService.openPaymentComponent(PaymentPages.paymentOtp);
    }, (err) => {
      if (err.status === 401 && err?.error?.code === 1318) {
        this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.codeRenewFailure,
          [
            {
              key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
              value: () => { this.paymentNavigationService.closePayment() }
            }
          ]);
        const anonyKOVar: { '&&events': string, error_list: string } = { ...tagging.anonymousOtpErrors };
        const error_list: string = anonyKOVar.error_list.replace('<error_code>', err.error.code)
          .replace('<error_message>', 'pago anonimo').replace('<error_desc>', tagging.exceedResendlimit);
        this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
          this.taggingHelper.error_list = error_list;
          const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.SECURITY_CODE_MAX_NUMBER_CLICKS_EXCEDED;
          this.newTagging.state(data.page.stateList[constantName]);
        });
      }
      else {
        this.paymentErrorService.handleEnteryPonitsErrorsCategory(PaymentErrorCategory.anonymousIdentifierFailure,
          [
            {
              key: PaymentCompoentInputData_Keys.paymentDeleteCardConfiramtionComponent_firstBtnAction,
              value: () => { this.paymentNavigationService.back() }
            }
          ]);

        const anonymousIdentificationError: { error_list: string } = { ...tagging.anonymousIdentificationErrors };
        const error_list: string = anonymousIdentificationError.error_list.replace(netPlusErrorName, err.error.code)
          .replace(netPlusErrormessage, err.error.message);
        this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
          this.taggingHelper.error_list = error_list;
          const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.STATES.WRONG_DATA;
          this.newTagging.state(data.page.stateList[constantName]);
        });
      }
    });
    }
    this.newTagging.getJsonTagging(FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION).subscribe((data: NewTaggingJsonModel) => {
      const constantName: string = PAYMENTS_TRACKING.ANONYMOUS_IDENTIFICATION.INTERACTIONS.CLICK_ON_CONTINUE;
      const event: InteractionTaggingModel = data.eventList[constantName];
      event.event.event_context = `${ANONYMOUS_IDENTIFICATION_EVENT_CONTEXT} ${this.identificationType}`;
      this.newTagging.interaction(event, data.page);
    });
  }
  /**
     * Method for check validity of NIF field
     * @param isBlured to make full validation on focus out
     */
  checkNifValidity(isBlured: boolean): void {
    this.isNifValid = !((this.infoForm.get('nif').hasError('pattern')) ||
      (isBlured && this.infoForm.get('nif').hasError('minlength')) ||
      (isBlured && this.infoForm.get('nif').value === ''));
    this.identificationError = (isBlured && this.infoForm.get('nif').value === '') ?
      this.anonymousPaymentWCSData.focusOutErrorText
      : this.anonymousPaymentWCSData.identificationError;
    this.checkFormValidity();
  }

  /**
     * Method for check validity of CIF field
     * @param isBlured to make full validation on focus out
     */
  checkCifValidity(isBlured: boolean): void {
    this.isCifValid = !((this.infoForm.get('cif').hasError('pattern')) ||
      (isBlured && this.infoForm.get('cif').hasError('minlength')) ||
      (isBlured && this.infoForm.get('cif').value === ''));
    this.identificationError = (isBlured && this.infoForm.get('cif').value === '') ?
      this.anonymousPaymentWCSData.focusOutErrorText
      : this.anonymousPaymentWCSData.identificationError;
    this.checkFormValidity();
  }

  /**
   * Method for check validity of account number field
   * @param isBlured to make full validation (numbers only & min length) on focus out
   */
  checkAccountNumberValidity(isBlured: boolean): void {
    this.accountNumberBlured = isBlured;
    this.isAccountNumberValid = !((this.infoForm.get('accountNumber').hasError('pattern')) ||
      (this.accountNumberBlured && this.infoForm.get('accountNumber').hasError('minlength')) ||
      isBlured && this.infoForm.get('accountNumber').value === '');
    this.paymentNumberError = (isBlured && this.infoForm.get('accountNumber').value === '') ?
      this.anonymousPaymentWCSData.focusOutErrorText
      : this.anonymousPaymentWCSData.paymentNumberError;
    this.checkFormValidity();
  }

  /**
   * Method for check validity of mobile number field
   * @param isBlured to make full validation (numbers only & mobile pattern) on focus out
   */
  checkMobileNumberValidity(isBlured: boolean): void {
    this.isMobileNumberValid = !this.infoForm.get('mobileNumber').hasError('pattern');

    if (isBlured) {
      this.isMobileNumberValid = this.isMobileNumberValid && this.isFullMobileNumberValid()
        && this.infoForm.get('mobileNumber').value !== '';
    } else {
      this.showMobileNumerHelpertext = true;
    }
    this.paymentNumberError = (isBlured && this.infoForm.get('mobileNumber').value === '') ?
    this.anonymousPaymentWCSData.focusOutErrorText
    : this.anonymousPaymentWCSData.paymentNumberError;
    this.checkFormValidity();
  }

  /** Method for making a full validity of mobile number pattern */
  isFullMobileNumberValid(): boolean {
    const mobileNumber: string = this.infoForm.get('mobileNumber').value;
    return !mobileNumber || /^(6|7)([0-9]{8})$/.test(mobileNumber);
  }

  /** Method for checking if the whole form id valid or not to enable/disable submit button */
  checkFormValidity(): void {
    this.isFormValid = this.infoForm.valid &&
      (this.cif ? this.isCifValid : this.isNifValid) && (this.mobileNumber ?
        (this.isMobileNumberValid && this.isFullMobileNumberValid()) : this.isAccountNumberValid);
  }


  /**
   * Method for checking if there is a value in the target input after change radio button check
   * then should focus on the input to show its value instead of its placeholder (IN MOBILE VIEW ONLY)
   * @param controlName control name in reqctive form
   * @param isNifOrCif to take first/second input based on this parameter
   */
  checkValueAndFocus(controlName: string, isNifOrCif: boolean): void {
    if (!this.isTabletView && this.infoForm.get(controlName).value) {
      /** using timeout to make sure that text field is visible (appended to DOM) in mobile view */
      setTimeout(() => this.form.nativeElement.getElementsByClassName('mva10-c-text-field__input')[isNifOrCif ? 0 : 1].focus());
    }
  }
}

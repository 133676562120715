export class Notification {
    public bodyTitle: String = '';
    public bodyContent: String = '';
    public primaryButton = false;
    public secondaryButton = false;
    public primaryButtonText: String = '';
    public secondaryButtonText: String = '';
    public primaryButtonStyle: String = '';
    public secondaryButtonStyle: String = '';
    public bodyIcon: String = '';
    public primaryButtonClick: Function;
    public secondaryButtonClick: Function;
    public closeButtonClick: Function;
    public hidex: boolean;
    public showRetryButton?: boolean;
    public xFunctionSameAsPrimaryButton?: boolean;
}

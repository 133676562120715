import { CrossFunctionalityService } from './../../services/cross-functionality.service';
import { MdlSnackbarService } from '@angular-mdl/core';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'sp-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() backgroundColor: string;
  @Input() iconBackgroundColor: string;
  @Input() iconColor: string;
  @Input() bigIcon: boolean;

  constructor(private mdlSnackbarService: MdlSnackbarService,
    private translate: TranslateService,
    private cross: CrossFunctionalityService) { }

  ngOnInit() {
    if (!this.cross.toastMessage) {

      this.translate.get('productsServices.contentList.actAndDeAct.messagesList.cfReqSent.cfReqSent_description').subscribe(data => {
        this.cross.toastMessage = data;

      })
    }
    this.mdlSnackbarService.showSnackbar({
          message: this.cross.toastMessage,
          timeout: 5000
        });
      const div = document.getElementsByClassName('mdl-snackbar');
      if (this.cross.editToastUi) {
        document.getElementById('demo-toast-example').classList.add('edit-toast-ui');
      }
      for (let elementIndex = 0; elementIndex < div.length; elementIndex++) {
        if (this.backgroundColor) {
          div[elementIndex].setAttribute('style', `background-color: ${this.backgroundColor}`);
        }
        const innerDiv = document.createElement('div');
        innerDiv.className = 'block-2';
        if (this.iconBackgroundColor) {
          innerDiv.setAttribute('style', `background-color: ${this.iconBackgroundColor}`);
        }
        div[elementIndex].appendChild(innerDiv);
        const icon = document.createElement('span');
        icon.className = 'icon icon-tick-or-solved' + (this.bigIcon ? ' big-icon' : '');
        if (this.iconColor) {
          icon.setAttribute('style', `color: ${this.iconColor}`);
        }
        innerDiv.appendChild(icon);
      }
     }

     ngOnDestroy(): void {
       this.cross.toastBackgroundColor = null;
       this.cross.toastIconBackgroundColor = null;
       this.cross.toastIconColor = null;
       this.cross.toastBigIcon = false;
       this.cross.showToast = false;
       this.cross.editToastUi = false;
     }
  }

// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class LocationService {
  constructor(private location: Location) {}

  /**
   * [IMPORTANT]: It'a a hack that simulates Angular +12 Location.historyGo().
   * See: https://angular.io/api/common/Location#historygo
   * See: https://github.com/angular/angular/blob/261b060fa168754db00248d1c5c9574bb19a72b4/packages/common/src/location/platform_location.ts#L128
   *
   * Navigate to a specific page from session history, identified by its relative position to the current page.
   *
   * @param relativePosition Position of the target page in the history relative to the current page.
   */
  public historyGo(relativePosition: number = 0): void {
    window.history.go(relativePosition);
  }

  public historyLength(): number {
    return window.history.length;
  }

  /**
   * Navigates back in the platform's history.
   */
  public back(): void {
    this.location.back();
  }

  /**
   * Navigates forward in the platform's history.
   */
  public forward(): void {
    this.location.forward();
  }
}


import { of as observableOf,  Observable, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { AppService } from './../../app.service';
import { NotificationService } from '../../shared/services/notification.service';
import { RetryService } from '../../shared/services/retry.service';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { SidemenuConfigService } from '../../shared/services/sidemenu-config.service';
import { CompanyService } from '../../shared/services/company.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerAccountService } from '../../shared/services/customer-account.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { SiteHandlingService } from '../../shared/services/site-handling.service';
import { PAGES } from '../../shared/constants/defines';
import { ErrorHandlingService } from '../../core/services/errorHandling.service';
import { Error } from './../../models/error.model';
import { Notification } from './../../models/notification.model';
import {
  subscriptionsAndTariffError
} from '../../shared/constants/defines';
import { ImpersonationService } from '../../shared/services/impersonation.service';
import { ImpersonationGroup } from '../../shared/enums/impersonationGroup.enum';
import { mergeMap } from '../../../../node_modules/rxjs/operators';
import { ConfigurationService } from '../../core/services/configuration.service';
import { SiteStatus } from '../../shared/enums/siteStatus.enum';

@Injectable()
export class PreloadGuard implements CanActivate {
  errorObj: Error;
  constructor(public customerAccount: CustomerAccountService,
    private auth: AuthenticateService,
    private subscription: SubscriptionService,
    private company: CompanyService,
    private impersonationService: ImpersonationService,
    private menuService: SidemenuConfigService,
    private siteHandlingService: SiteHandlingService,
    private router: Router, private retryService: RetryService,
    private appService: AppService, private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService,
    private configService: ConfigurationService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isRefreshingToken || !this.configService.isConfigLoaded) {
      return this.auth.refreshTokenEmmiter.pipe(mergeMap(res => {
        if (!res) {
          return observableOf(false);
        }
        const result = this.loadData(state);
        if (result instanceof Observable) {
          return result
        }
        else {
          return observableOf(result);
        }

      }))
    }
    return this.loadData(state);
  }
  loadMenu() {
    this.menuService.getUserMenuItems().subscribe();
  }
  getAuthorizedData() {
    this.menuService.menuItemsIsLoadedInResolver = true;
    this.loadMenu();
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
  errorHandling(state, error) {
    this.appService.showFullAppLoader = false;
    const notificationError = new Notification();
    this.errorObj = this.errorHandlingService.lookUpError(PAGES.COMMON, error)
    if (this.errorObj) {
      notificationError.bodyContent = this.errorObj.description;
      notificationError.bodyTitle = this.errorObj.title;
      this.retryService.url = state.url;
      notificationError.showRetryButton = this.errorObj.retryButton;
      notificationError.primaryButtonText = this.errorObj.dismissButtonText || this.errorObj.confirmButtonText;
      notificationError.primaryButtonClick = () => {
        this.notificationService.notificationModal.hide();
      };
      this.notificationService.createNotification(notificationError);
    }
  }

  loadData(state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (!this.company.selectedCompanyId && this.auth.hasRole(CustomerType.Authorized)) {
      this.router.navigate([config.login.companyChooser.route], {
        queryParams: {
          targetUrl: state.url
        }
      });
      return false;
    }
    if (this.siteHandlingService.isSitePendingInstall()) {
      this.menuService.currentSiteStatus = SiteStatus.Pend_de_Instalar;
      this.loadMenu();
    }
    else if (!this.subscription.customerData.currentService && !this.siteHandlingService.isSiteP2()) {
      return this.loadCurrentServices(state);
    }
    else if ((this.siteHandlingService.isSiteP2() ||
      (this.impersonationService.impersonatedUser?.Group?.toLocaleLowerCase() ===
        ImpersonationGroup.PayADebt.toLocaleLowerCase())) && !this.menuService.allItemsInMenu) {
      this.loadMenu()
    }
    this.appService.showAndHideGDPROverlay();
    return true;
  }

  private loadCurrentServices(state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.company.selectedCompanyId) {
      if (this.company.CompanyList.length === 1) {
        this.appService.waitUntilSubscription = true;
        return this.subscription.GetCompanyServices(this.company.selectedCompanyId).pipe(map(() => {
          this.appService.showAndHideGDPROverlay();
          this.appService.subscriptinAPILoadEmmiter.next();
          this.appService.waitUntilSubscription = false;
          this.menuService.menuItemsIsLoadedInResolver = true;
          this.loadMenu();
          this.subscription.isCurrentServiceLoaded.next(true);
          return true;
        }), catchError((error) => {
          if (error?.status !== subscriptionsAndTariffError.status && error?.error?.ecode !== subscriptionsAndTariffError.ecode) {
            this.errorHandling(state, error);
          }
          this.auth.logout();
          this.router.navigate([config.login.route])
          return throwError([false]);
        }))
      }
      else {
        return this.getAuthorizedData()
      }
    } else {
      this.appService.waitUntilSubscription = true;
      return this.subscription.GetDefaultServices().pipe(map(() => {
        this.appService.showAndHideGDPROverlay();
        this.appService.subscriptinAPILoadEmmiter.next();
        this.appService.waitUntilSubscription = false;
        this.menuService.menuItemsIsLoadedInResolver = true;
        this.loadMenu();
        this.subscription.isCurrentServiceLoaded.next(true);
        return true;
      }), catchError((error) => {
        if (error?.status !== subscriptionsAndTariffError.status && error?.error?.ecode !== subscriptionsAndTariffError.ecode) {
          this.errorHandling(state, error);
        }
        this.auth.logout();
        this.router.navigate([config.login.route])
        return throwError(false);
      }));
    }
  }
}

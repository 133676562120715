
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as Routes from '../constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FTE } from '../../models/fte.model';
import { SubscriptionService } from '../../core/services/subscription.service';

@Injectable()
export class FteServiceService {
  constructor(private http: HttpClient,
    private subscriptionService: SubscriptionService) { }
  getFTE() {
    const url = Routes.API_URLS.Dashboard.FTE.replace('{customerAccountId}',
      this.subscriptionService.customerData.customerAccountsId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: FTE) => {
      return res;
    }));
  }
  postFTE(idFTE: string) {
    const url = Routes.API_URLS.Dashboard.FTE.replace('{customerAccountId}',
      this.subscriptionService.customerData.customerAccountsId);
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');

    const body = {
      'fteId': idFTE
    };

    const options = {
      headers: headers
    };
    return this.http.post(url, body, options).subscribe();
  }

}

import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../models/payment';
import {
    overlayMainsectionGrayBackGroundOnMobile, overlayMainsectionSelector,
    overlayTitlecustomStyle, overlayTitleSelector, removePaddingCustomStyle, removePaddingSelector,
    overlayBoldTitleStyle
} from '../../../shared/constants/defines';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';

export class PaymentTrayConfigDefault implements PaymentTrayConfigInterface {
    // this component is treated like an ovrelay we remove tem from history after navigating away from them
    // not to show them to the user accidentaly when he navigate back
    compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] =
        [PaymentPages.cancel, PaymentPages.loading, PaymentPages.deleteConfiramtion];
    /** variable to be used for getting a tray config method based on component name */
    trayConfigurations: any = {
        [PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
        [PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
        [PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
        [PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
        [PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
        [PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
        [PaymentPages.ticket]: this.getTicketCompTrayConfig.bind(this),
        [PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
    };
    constructor(public paymentNavService: PaymentNavigationService,
        public delegateService: DelegateCreationService,
        public translate: TranslateService,
        public paymentWalletService: PaymentWalletService
    ) {
    }
    /**
     * helper function to set general configration for the
     * tray
     */
    getInitalConfigModel(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = new PaymentFullTrayModel();
        paymentFullTrayModel.MVA10Config.isCenterTitle = true;
        paymentFullTrayModel.MVA10Config.isFullScreen = true;
        paymentFullTrayModel.MVA10Config.showBackButton = false;
        paymentFullTrayModel.MVA10Config.isScrollableTray = false
        paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = true;
        return paymentFullTrayModel;
    }
    /**
     * @param compName the page we need it's tray configration
     * function should return PaymentFullTrayModel containing
     * the proper tray configration
     */
    getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
        let config: PaymentFullTrayModel;
        /** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
        if (this.trayConfigurations[compName]) {
            config = this.trayConfigurations[compName]();
        } else {
            config = this.getIframeCompTrayConfig();
        }
        return config;
    }
    /**
     * function return PaymentFullTrayModel
     * the configration of the iframe screen configration
     */
    getIframeCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.thirdPartyContainer)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title
        })
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = () => {
                this.paymentNavService.startCancelPayment();
            }
        paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
        paymentFullTrayModel.paymentCustomConfig.customStyles.push(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle));
        return paymentFullTrayModel;
    }
    /**
     * function return PaymentFullTrayModel
     * the configration of the ok screen configration
     */
    getOkCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.ok)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title
        })
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = () => {
                this.delegateService.paymentTaggingConfig.tagCloseBtnClick();
                this.delegateService.paymentSuccessConfig.closeBtnAction()
            }
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
                , new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile));
        return paymentFullTrayModel;
    }
    /**
     * function return PaymentFullTrayModel
     * the configration of the ko screen configration
     */
    getKoCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        paymentFullTrayModel.MVA10Config.title = this.delegateService.paymentErrorConfig.currentErrorTitle;
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction =
            this.delegateService.paymentErrorConfig.closeBtnAction.bind(this.delegateService.paymentErrorConfig)
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
                , new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile));
        return paymentFullTrayModel;
    }

    /**
     * function return PaymentFullTrayModel
     * the configration of the cancel screen configration
     */
    getCancelCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.cancel)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title
        });
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = this.paymentNavService.backWithoutAnimation.bind(this.paymentNavService);
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
                , new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile));
        return paymentFullTrayModel;
    }
    /**
    * function return PaymentFullTrayModel
    * the configration of the wallet screen configration
    */
    getWalletCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        paymentFullTrayModel.MVA10Config.showBackButton = true;
        paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.wallet)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title;
        });
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = () => {
                /**
                 * Show leaving overlay when click on x in cclist screen with animation in postpaid journey.
                 * Send tagging for leaving overlay in postpaid journey.
                 * Bind x in leaving overlay to clickXButtonInCancelationOverlay function in postpaid journey.
                 */
                const isSelectedCard: boolean = !!this.paymentWalletService.selectedCard;
                this.delegateService.paymentTaggingConfig.tagPaymentWalletClickOnXInteraction(isSelectedCard);
                this.paymentNavService.showCancelPayment();
            }
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
                new CustomStyle(overlayTitleSelector, overlayBoldTitleStyle),
                new CustomStyle(removePaddingSelector, removePaddingCustomStyle)
            );
        return paymentFullTrayModel;
    }
    /**
     * function return PaymentFullTrayModel
     * the configration of the loading screen configration
     */
    getLoadingCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        paymentFullTrayModel.paymentCustomConfig.customStyles.push(new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle));
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.thirdPartyContainer)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title
        });
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = () => {
                this.paymentNavService.defaultKoClose();
            }
        return paymentFullTrayModel;
    }
    /**
     * function return PaymentFullTrayModel
     * the configration of the ticket screen configration
     */
    getTicketCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.ticket)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title;
        })
        return paymentFullTrayModel;
    }
    /**
   * function return PaymentFullTrayModel
   * the configuration of the delete card screen configuration
   */
    getDeleteCardCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.deleteConfiramtion)).subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title;
        });
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = this.paymentNavService.backWithoutAnimation.bind(this.paymentNavService);
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(new CustomStyle(overlayTitleSelector, overlayBoldTitleStyle)
                , new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile));
        return paymentFullTrayModel;

    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any[], field: string, direction?: 'asc' | 'desc'): any[] {
    const Direction = direction || 'asc'
    const SortedArr = array.sort((firstItem: any, secondItem: any) => {
      // if the number is not defined throw it in the end of the array either descending or ascending
      const first = firstItem[field] ? +(firstItem[field].toString().replace(',', '.')) :
        Direction === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
      const second = secondItem[field] ? +(secondItem[field].toString().replace(',', '.')) :
        Direction === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
      if (first < second) {
        return Direction === 'asc' ? -1 : 1;
      } else if (first > second) {
        return Direction === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
    return SortedArr;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../app.service';
import { PLPCardRevamp } from '../../../models/PLP-card-revamp.model';
import { laCintilloColor } from '../../constants/defines';

@Component({
  selector: 'sp-plp-revamp-card',
  templateUrl: './plp-revamp-card.component.html',
  styleUrls: ['./plp-revamp-card.component.scss']
})
export class PLPRevampCardComponent implements OnInit {
  @Input() cardResp: PLPCardRevamp;
  @Output() blueClick: EventEmitter<{openBackdrop: boolean, text: string, code: string}> =
    new EventEmitter<{openBackdrop: boolean, text: string, code: string}>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() info: EventEmitter<boolean> = new EventEmitter<boolean>();
  public existsDescription1: boolean;
  public existsDescription2: boolean;
  public existsTopIcon: boolean;
  public existsTopIconText: boolean;
  public coinMonth: string;
  public textBlue: string[] = [];
  public controlPrice: boolean;
  public isPega: boolean;
  public showCintillo: boolean;
  public cintilloColor: string;
  public cintilloBorderColor: string = laCintilloColor.colorStyle;
  public textColor: string;

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.showCintillo = !!this.cardResp?.headBandText;
    this.cintilloColor = this.cardResp?.bgColor;
    this.textColor = this.cardResp?.textColor;
    this.cintilloBorderColor = this.cintilloBorderColor.replace('<color>', this.cintilloColor);
    this.cardResp.topIcon = this.appService.getImgFullPath(this.cardResp.topIcon);
    this.existsDescription1 = !!(this.cardResp.description1 !== '' || undefined);
    this.existsDescription2 = !!(this.cardResp.description2 !== '' || undefined);
    this.existsTopIcon = !!(this.cardResp.topIcon !== '' || undefined);
    this.existsTopIconText = !!(this.cardResp.topIconText !== '' || undefined);
    this.isPega = this.cardResp.isPega;
    this.controlPrice = (parseInt(this.cardResp.textPrice, 10) <= parseInt(this.cardResp.textPriceWithDiscount , 10));
    this.textBlue = this.cardResp.textDescriptionBelow.split('strong>');
    this.textBlue[0] = this.textBlue[0].replace('<', '');
    this.textBlue[1] = this.textBlue[1].replace('</', '');
  }

  clickBlueText(openBackdrop: boolean): void {
    this.blueClick.emit({openBackdrop: openBackdrop, text: this.cardResp?.textDescriptionBelow, code: this.cardResp?.code});
  }

  clickContinue(): void {
    this.continue.emit();
  }

  clickInfo(): void {
    this.info.emit(this.isPega);
  }
}

import { DataSource } from './../../../models/radio-button-data-source.model';
import { Component, OnInit, Input, forwardRef, AfterContentChecked, EventEmitter, Output, SimpleChanges,
  ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS } from '@angular/forms';
import * as constants from '../../constants/defines'
/**Just empty definition for a function */
const noop = () => {
};
/**custom validation function call in every change */
export function createRadioButtonValidator(required: boolean) {
  return (c: UntypedFormControl) => {
    if (required) {
      return c.value ? null : {};
    }
    else {
      return null;
    }
  };
}
declare var $: any;

@Component({
  selector: 'sp-radio-button-advanced',
  templateUrl: './sp-radio-button-advanced.component.html',
  styleUrls: ['./sp-radio-button-advanced.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SpRadioButtonAdvancedComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SpRadioButtonAdvancedComponent),
    multi: true
  }]
})

export class SpRadioButtonAdvancedComponent implements OnInit, ControlValueAccessor, AfterContentChecked {
  /**Placeholders for the callbacks which are later providesd by the Control Value Accessor */
  onTouchedCallback: () => void = noop;
  onChangeCallback: (_: any) => void = noop;
  /**array of choices that will bind in radio buttons */
  @Input() dataSource: DataSource[];
  /**group name of radio button group */
  @Input() groupName: string;
  /**flag to identify whether we should choose radio button or not */
  @Input() required: boolean;
  /**flag to identify whether there is a default value checked or not */
  @Input() defaultCheckedValue: string;
  /**error message */
  @Input() message: string;
  /**empty function that will hold the validation function */
  validateFn: any;
  /**the inner value of radio button */
  innerValue: any;
  @Input() one: boolean
  @Input() newClass: boolean
  @Input() onlineTvBox: boolean;
  @Input() isChangeSecurity: boolean;
  @Output() radioClick: EventEmitter<DataSource> = new EventEmitter();
  @Input() disabled: boolean;
  @Input() companyTab: boolean;
  @Input() title: string;
  noPromotionName: string;
  infoSlide: boolean;
  openedInfoSlides = [];
  @ViewChild('infoIconClose') infoIconClose: ElementRef;
  @ViewChild('infoToggle') infoToggle: ElementRef;
  @ViewChild('checkCircle') checkCircle: ElementRef;
  @ViewChild('infoText') infoText: ElementRef;
  @ViewChild('infoContainer') infoContainer: ElementRef;
  @ViewChild('iconContainer') iconContainer: ElementRef;



  /**function that will call only when initialize to updat
   * refresh the inner value in case of it is not like the old inner value.
   * @param  {any} The text value.
   */
  writeValue(value: any): void {
    if (value) {
      this.innerValue = value;
    }
  }
  /**function that will be called with every change in form*/
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  /**function that will be called with every touch of form control*/
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  /**function that will call onChangeCallback to validat the form after any change*/
  ngAfterContentChecked() {
    this.onChangeCallback(this.innerValue);
  }

  /**
   * function that will update the inner value with the current choice
   * @param choice
   */
  updateForm(choice: any) {
    this.innerValue = choice;
  }
  checked(choice: any) {
    return this.innerValue === choice;
  }
  /**this function will be called after any change to validate the form */
  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
  /**function that will run when initialize the component */
  ngOnInit() {
    if (this.defaultCheckedValue) {
      if (this.dataSource.length > 0) {
        this.innerValue = this.defaultCheckedValue;
      }
    }
    this.validateFn = createRadioButtonValidator(this.required);
    this.noPromotionName = constants.noPromotionName;
  }
  onClick(value, event) {
    if (this.isChangeSecurity && (event.target.id === this.infoIconClose.nativeElement.id
      || event.target.id === this.infoToggle.nativeElement.id)) {
      return;
    }
    if (this.disabled) {
      return;
    }
    if (this.isChangeSecurity && event.target.id !== this.checkCircle.nativeElement.id) {
      return;
    }
    if (this.onlineTvBox) {
      this.innerValue = value;
    } else {
      this.innerValue = value.value
    }
    this.radioClick.emit(value);
  }
  ngOnDestroy() {
    this.innerValue = null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultCheckedValue']) {
      this.innerValue = changes['defaultCheckedValue'].currentValue;
    }
    if (changes['dataSource'] && this.isChangeSecurity) {
      if (changes['dataSource'].currentValue.length > 0) {
        this.openedInfoSlides = [];
        changes['dataSource'].currentValue.forEach((data, index) => {
          this.openedInfoSlides.push({id: '#landing-info-container-' + index , isOpen: false});
        })
      }
    }
  }

  toggleSlideInfo = (event, index, isClosed?) => {
    this.closeOpenedSlidesInfo(index);
    const slide = this.openedInfoSlides.find(item => {
      return item.id ===  '#landing-info-container-' + index;
    })
    if (slide) {
      $(slide.id).slideToggle(500);
      slide.isOpen = isClosed === undefined ? !slide.isOpen : !isClosed;
    }
    event.stopPropagation();

  }
  closeOpenedSlidesInfo(index?) {
    const openedSlides = this.openedInfoSlides.filter(item => {
      return item.isOpen;
    });
    if (openedSlides.length > 0) {
      openedSlides.forEach(slide => {
        if ((index !== undefined && slide.id !== '#landing-info-container-' + index) || index === undefined) {
          slide.isOpen = false;
          $(slide.id).slideToggle(500);
        }

      });
    }
  }

  closeInfoOpenedInfoToggles(event) {
    if ( this.isChangeSecurity && event.target.id !== this.infoIconClose.nativeElement.id
      && event.target.id !== this.infoToggle.nativeElement.id &&
      event.target.id !== this.infoText.nativeElement.id &&
      event.target.id !== this.infoContainer.nativeElement.id &&
      event.target.id !== this.iconContainer.nativeElement.id) {
      this.closeOpenedSlidesInfo();
      return;
    }
  }
}

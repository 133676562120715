<div *ngIf="!showSafariErrorOverlay" [ngClass]="{'fade-out-tray':hideContent, 'backward-transition-tray':trayService?.showBackwardTransition, 'top-up-rows-anonymous': topupService.isAnonymousProcess, 'change-language': changingLanguage, 'top-rows-responsive': topupService.useDynamicTray, 'show-contact-list-icon':topupService.contactListSupported }" class="top-up-rows" tabindex="2">
  <div class="top-up-title-with-exit-icon-container" [ngClass]="{'title-shadow': scrolledView, 'hide-title': topupService.useDynamicTray}">
  <img class="header-img" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/red-small@2x.png">
  <div class="top-up-title">
    <p>{{uiModel.refills}}</p>
  </div>
  <span (click)="closeTrayWithTagging()" class="icon icon-close" [ngClass]="{'visibility-hidden': (showFullLoader || !topupService.isMobileView)}"></span>
</div>
<div class='topup-content-container'>
<div class="languages-and-Banner-container" *ngIf="topupService.isAnonymousProcess" id='anonymousTopupLanguageBannerBoxId'>
  <div class="languages-container" id='anonymousTopupLanguageId'>
    <span (click)="changeLanguage()">
        <p class='language-text' id='anonymousTopupLanguageTextId'>{{languageText}}</p>
        <img class='language-icon'  id='anonymousTopupLanguageIconId' [src]="languageIcon" alt="">
       </span>
      </div>
    <div class="box-container" *ngIf= "showTobiBanner"  id='anonymousTopupBannerTextId'>
       <sp-banner-card [bannerCard]='languageBannerCard' (clickOnCTA)='clickOnBannerCTA()'></sp-banner-card>
   </div>
</div>
<div class="landing-container" [ngClass]="{'visibility-hidden': showFullLoader, 'landing-container-small-margin' : topupService.isAnonymousProcess, 'landing-container-anonymous': topupService.isAnonymousProcess, 
'flex-container': topupService.contactListSupported}" (scroll)="onScroll($event)" #landingContainer>
    <div class = "service-selector-with-content-container">
        <div class = "service-selector-title">
          <p>{{uiModel.rechargeNumber}}</p>
          <sp-mva10-service-selector  *ngIf ="selections" [showPlus]="true && !topupService.isAnonymousProcess" #serviceSelector [selections]="selections" (onSelectionChange)="onServiceChange($event)" (plusClicked)="clickPlus()" [plusSelected]="topupService.showPlus" [selectedItem]="topupService.selectedItemIndex"></sp-mva10-service-selector>
        </div>
      </div>
      <form [formGroup]="form" *ngIf="form && (topupService.showPlus || topupService.isAnonymousProcess)" [ngClass]="{'more-margin': !selections}">
      <sp-text id="NewTopUpPrepaidServiceComp_txt_msisdn" max=9 textType="number" [preventSpace]="true" (onInputEventFn)="onInputFn($event)" (onFocusFn)="onFocus()" formControlName="msisdn" [pattern]="MsisdnValidation" [enforceValidation]="showeMsisdnErrorMessage" [patternErrorMessage]="errorMessage" 
      placeholder="{{uiModel.introduceNewMobile}}" (onBlurFn)="onBlur()" [mva10Textbox]="true" [isRequired]="true" required tabindex="1" [focused]="isFocus" [dirtyValidationCheck]="true" [showSuccessIcon]="showMsisdnFieldSuccessIcon && topupService.contactListSupported"></sp-text>
      <div class="contact-list-icon" *ngIf="topupService.contactListSupported">
          <img src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-calls-contacts@2x.png" (click)="navigateToContactListPermission()">
      </div>
    </form>
      <div class="amount-selector-with-content-container" *ngIf="!showFullLoader">
        <div class="swipper">
          <p>{{uiModel.rechargeAmount}}</p>
          <sp-mva10-amount-selector [index]="selectedAmountSelectorValue"
            (slidChangeFn)="selectedAmountSelectorValues($event)" [items]="amountSelectorValues"></sp-mva10-amount-selector>
        </div>
      </div>
      <div class="card-selection-container" *ngIf="topupService.showCardSelectionSection && !topupService.isAnonymousProcess">
        <p class="card-selection-title">{{'payment.itemsList.rechargeSelector.body'|translate}}</p>
        <div class="card-selection-chevron" (click)='navigateToWalletScreen()'>
          <img [src]='selectedCardImage' alt="" class="card-img">
          <p class="card-mask">{{selectedCardNumer}}</p>
          <img [src]="appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-chevron-right-red@2x.png')" alt="" class="chevron-img">
        </div>
      </div>
</div>
<div class="footer-container" [ngClass]="{'footer-container-small-margin': (topupService.showPlus && selections), 'footer-shadow': (!reachScrollerEnd && pageHasScroller && !topupService.isAnonymousProcess), 'visibility-hidden': showFullLoader, 'footer-container-anonymous': topupService.isAnonymousProcess, 'multi-lang-small-marign' : topupService.isAnonymousProcess}">
  <div class="button-container-with-payment-buttons">
    <div class="jumbotron-button top-margin">
      <button (click)="navigateToIframeEnablement()" id="LandingComp_btn_routeToExtrasList" type="button" class="button red two"
      [disabled] = "(topupService?.newPciSelectedService?.hasPendingPciTransactions) || (form?.invalid && (topupService.showPlus || topupService.isAnonymousProcess) || (isFocus && !showMsisdnFieldSuccessIcon))"
      [ngClass]="{'btn-disabled': (topupService?.newPciSelectedService?.hasPendingPciTransactions) || (form?.invalid && (topupService.showPlus || topupService.isAnonymousProcess) || (isFocus && !showMsisdnFieldSuccessIcon) )}"
        >{{uiModel.continueBtn}}</button>
    </div>
    <div class="icons-with-security-text">
      <div class="visa-icon">
          <img [src]='visaImage' alt="" class="img-responsive">
      </div>
      <div class="master-card-icon">
          <img [src]='masterCardImage' alt="" class="img-responsive">

      </div>
      <div class="security-text-container-having-text">
          <img src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-padlock-close.png" *ngIf = "breakpoint !== BREAKPOINTS.MOBILE">
            <p>{{uiModel.sslSecured}}</p>
      </div>
    </div>
  </div>
</div>
</div>
  <div class="full-loader" *ngIf="showFullLoader">
    <div class="">
      <sp-lottie-loader [lottieImageUrl]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/loader.json'"></sp-lottie-loader>
    </div>
  </div>
</div>


<sp-top-up-general-error-overlay *ngIf="showSafariErrorOverlay"></sp-top-up-general-error-overlay>
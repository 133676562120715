import { FamilySort } from 'src/app/models/commercial-terminal-detail.model';
import { config } from '../../../config/pages-config';
import { environment } from '../../../environments/environment';
import { ChevronTypeWhatHappened } from '../enums/chevron-type-what-happened.enum';
import { ContractType } from '../enums/contract-type.enum';
import { iconSvgPath } from '../enums/IconSvgPath.enum';
import { NewServiceTypeWhatHappened } from '../enums/new-service-type-what-happened.enum';
import { ProductSubtype } from '../enums/product-subtype.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { TypeUser } from '../enums/typeUser';
import { BundleType as BillingBundleType } from './../enums/bundleType.enum';
import { ProductType } from './../enums/productType.enum';
import { LogInRedirectionModel } from 'src/app/models/log-in-redirection.model';
import { TestCROBannerConst } from './../../models/dmp.model';
import { MicroFlowsType } from 'src/app/models/microFlowsData.model';
import { ConfigDefineModel, InboxMessageDefineModel, ValidationDefineModel, BillQueryDefineModel, VerifyEmailDefinesModel,
  ErrorCodesDefinesModel, PinPukDefinesModel, InboxMessagesDetailsModel, SlideConfigModel, FeeSeperatorsModel,
  PreferencesAndPermissionsModel, OtpTrialStatusModel, MigrationModel, TicketTemplatesModel, CommercialJourneysModel,
  CommercialMobileModel, OnePlusProductsModel, TicketDefinesModel, OnePlusRecommenderModel, DiscountRenewalDefinesModel,
  SecureNetFamilyModel, CheckoutModel, ProvinciasListModel, MVA10CarouselConfigObjModel, LegoPackModel, SubmitOrderReqBodyModel,
  SmartPayLandingImageModel, BundleupsellDefinesModel, RoamingProductmangmentDefinesModel, JsonPathsDefinesModel,
  SaveOfferReqBodyDefinesModel,
  BuySimJourneyDefinesModel} from '../models/defines.model';

export const CONFIG: ConfigDefineModel = {
  Email: 'email',
  Pdf: 'pdf',
  CLIENT_ID: environment.CLIENT_ID,
  SCOPES: [
    environment.MSISDNScope,
    'CUSTOMER_CUSTOMER_ACCOUNT_ALL',
    'CUSTOMER_CUSTOMER_AGREEMENT_ALL',
    'CUSTOMER_CUSTOMER_PARTY_ALL',
    'DOWNLOAD_ALL',
    'ES_ADMIN_CONFIG_MENU_ITEMS_GET',
    'ES_ADMIN_CONFIG_MENU_ITEM_GET',
    'ES_APP_CONFIGURATION_URL_GET',
    'ES_AUTHENTICATION_CREDENTIAL_ALL',
    'ES_CUSTOMER_ACCOUNTS_ALL',
    'ES_CUSTOMER_BILL_ALL',
    'ES_TOPUP_ALL',
    'ES_USER_NOTIFICATION_ALL',
    'PAYMENT_CUSTOMER_BILL_ALL',
    'PRODUCT_PRODUCT_ALL',
    'PRODUCT_TARIFFS_ALL',
    'SERVICE_SERVICE_BALANCE_ALL',
    'ES_SERVICE_ACCOUNT_INFO_ALL',
    'SUBSCRIPTION_SUBSCRIPTION_ALL',
    'SUBSCRIPTION_SUBSCRIPTION_PROFILE_ALL',
    'ES_EXTERNAL_USER_SETTINGS_ALL',
    'ES_MIWIFI_ALL',
    'ES_TICKET_ALL',
    'ES_OTP_ALL',
    'ES_NEXT_BEST_ACTION_RECOMMENDATION_ALL',
    'TMF621_TROUBLE_TICKET_API_ALL',
    'TMF667_DOCUMENT_MANAGEMENT_API_ALL',
    'ES_PRODUCTS_SERVICES_ALL',
    'TMF640_SERVICE_ACTIVATION_AND_CONFIGURATION_ALL',
    'ES_MYDATA_ALL',
    'TMF666_ACCOUNT_MANAGEMENT_ALL',
    'TMF622_PRODUCT_ORDERING_MANAGEMENT_ALL',
    'EVERYTHING_IS_OKAY_STATUS_CHECK_ALL',
    'ES_PRODUCT_SPECIFICATION_OFFERING_JOURNEY_ALL',
    'TMF637_PRODUCT_INVENTORY_MANAGEMENT_ALL',
    'TMF691_FEDERATED_ID_API_ALL',
    'TMF670_PAYMENT_METHODS_API_ALL',
    'TMF635_USAGE_MANAGEMENT_API_ALL',
    'ES_PREMIUM_CONTENT_MANAGEMENT_ALL',
    'ES_DEVICE_FINANCING_ALL',
    'ES_OIDC_IMPLICIT_SHARED_CODE',
    'ES_BILL_REVIEW_ALL',
    'ES_STORIES_ALL',
    'TMF701_PROCESS_FLOW_ALL',
    'CSM019_CUSTOMER_BILL_ALL',
    'ES_CONTRACT_SUMMARY_ALL'
  ],
  COUNTRY_CODE: 'ES',
  CLIENT_LOGIN_TYPE: {
    MANUAL: 'manual',
    PASSWORD_SAVED: 'clave guardada'
  },
  OTP_VERSION: '2.0',
  SOURCE: 'MVA',
  CLIENT_SOURCE: 'MVA-Web'
};
export const GRANT_TYPE: { [key: string]: string } = {
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
  CLIENT_CREDENRIALS: 'client_credentials',
  authorization_code: 'authorization_code'
};
export const AnonymousPaymentScope: { [key: string]: string } = {
  PAYMENT_CUSTOMER_BILL_ALL: 'TMF666_ACCOUNT_MANAGEMENT_ALL',
  ES_CUSTOMER_DEBT_ALL: 'ES_CUSTOMER_DEBT_ALL',
};
export const AGREED: string = 'Entendido';
export const PACKSTV_TXTV: string = 'PACKSTV_TXTV';
export const TXNEWCHANNELLIST: string = 'txnewChannelList';
export const OTVConfirmation: string = 'OTVConfirmation';
export const OTVConfirmationNoDeco: string = 'OTVConfirmationNoDeco';
export const OTVConfirmationAutoInstall: string = 'OTVConfirmationAutoInstall';
export const OTVConfirmationTechnicianInstall: string = 'OTVConfirmationTechnicianInstall';
export const Inbox_Message: InboxMessageDefineModel = {
  NOTIFICATION_DEFUALT_LENGTH: 20,
  DATE_ASC: 'asc',
  DATE_DESC: '',
  TRAIL: '...',
  Zero: '0',
  User_ID: '12',
  sideMenuId: 200,
  REFRESH_TIME: 60000, // one min in millisecond equal == 60000
  oldPortalFlag: 'vfesmcareapp/Smartphones',
  READED: 'leido',
  NOT_READED: 'no leido'
};
export const LOCAL_STORAGE_PREFIX: string = 'mcare-LocalStore.key';
export const LOCAL_STORAGE_KEYS: { [key: string]: string }  = {
  ACCESS_TOKEN: 'accessToken',
  ACCESS_TOKEN_EXP: 'accessTokenExpirationTime',
  USER_CSRF_TOEKN: 'userCsrfToken',
  ACCESS_TOKEN_ISSUED: 'accessTokenIssuedTime',
  JWT: 'jwt',
  REFRESH_TOKEN: 'refreshToken',
  REFRESH_TOKEN_EXP: 'refreshTokenExpirationTime',
  SETTINGS: 'settings',
  HIDE_BILL_ALARMS_HINT: 'hideBillAlarmsHint',
  OMIT_DESTINY_ONBOARDING: 'omitDestinyOnBoarding',
  CANJE: 'canje',
  TAGGING_PAGE_ORIGINAL_REFERING: 'pageOriginalRefering',
  NETPLUS_UUID_FOR_TOPUP: 'netplusUuidForTopup',
  SPECIALPROMOTION: 'specialPromotion',
  SERVICEAES256: 'serviceAES256',
  MSISDN: 'msisdn',
  EXPERIENCE_VERSION: 'experienceVersion',
  FLOWMIGRATION: 'flowMigration',
  FLOWDISCOUNTRENEWAL: 'flowDiscountRenewal',
  ableToOpenContactList: 'ableToOpenContactList',
  ENTRYPOINTCODE: 'entryPointCode',
  ENTRYPOINTBUNDLE: 'entryPointBundle',
  RECOMMENDATIOS: 'recommendations',
  RELOADCROBUNDLE: 'reloadcrobundle',
  EXPERIENCENAME: 'experienceName',
  SHOWLINKPERMANENCE: 'showLinkPermanence',
  SHOWCTAADDCART: 'showCTAddtocart',
  SHOWCTAMOREINFOPDP: 'showCTAMoreInfoPDP'
};
export const JSON_PATHS: JsonPathsDefinesModel = {
  USERPROFILE: {
    CUSTOMER_TYPE: '$.userProfile.customerType',
    PROFILE_TYPE: '$.userProfile.profileType',
    USERNAME: '$.userProfile.userName',
    MSISDN: '$.userProfile.msisdn',
    FIRSTNAME: '$.userProfile.firstName',
    SECONDNAME: '$.userProfile.secondName',
    LASTNAME: '$.userProfile.lastName',
    EMAIL: '$.userProfile.email',
    PERMISSIONS: '$.userProfile.customerType',
    USSERPERMISSIONS: '$.userProfile.permissions',
    COMPANIES: '$.userProfile.companies',
    SERVICES: '$.userProfile.services',
    DEFAULT_SITE_ID: '$.userProfile.defaultsiteId',
    DOUCMENT_ID: '$.userProfile.document.id',
    DOUCMENT_TYPE: '$.userProfile.document.type',
    ID: '$.userProfile.id',
    LAST_PASSWORD_CHANGE: '$.userProfile.lastPassChangeDate',
    SITES: '$.userProfile.sites',
    hasExternalServices: '$.userProfile.hasExternalServices',
    hasExternalCompanies: '$.userProfile.hasExternalCompanies',
    gdprDiagnostics: '$.userProfile.gdprDiagnostics',
    showGDPROverlay: '$.userProfile.gdprReconfirmation',
    category: '$.userProfile.category',
    selectedCif: '$.userProfile.selectedCif',
    ROLES: '$.roles',
  },
  IMPERSONATEDBY: {
    SUB: '$.impersonatedBy.sub',
    GROUP: '$.impersonatedBy.group',
    ISS: '$.impersonatedBy.iss',
    IAT: '$.impersonatedBy.iat',
    JTI: '$.impersonatedBy.jti'
  },
  LOGIN: {
    SUCCESS: {
      JWT: '$.userInfo.jws',
      JWS: '$.jws',
      ACCESS_TOKEN: '$.access_token',
      REFRESH_TOKEN: '$.refresh_token',
      REFRESH_TOKEN_EXPIRES: '$.refresh_token_expires',
      ACCESS_TOKEN_EXPIRES: '$.refresh_token_expires',
      TOKEN_TYPE: '$.token_type',
      EXPIRES_AT: '$.expires_at',
      ISSUES_AT: '$.issued_at',
      firmaDigital: '$.firmaDigital',
      EXPIRES_IN: '$.expires_in'
    }
  },
  Configuration: {
    networkLogin: 'networkLogin',
    newAnonymousJourney: 'newAnonymousJourney',
    availabilityOfRMCA: 'availabilityOfRMCA',
    showBillsLink: 'showBillsLink',
    showOneNumberSSO: 'showOneNumberSSO',
    showSecureNetSSO: 'showSecureNetSSO',
    chatEnabled: 'chatEnabled',
    tobiBubble: 'tobiBubble',
    hideBillConfigurationToggles: 'hideBillConfigurationToggles',
    billClarifications: 'billClarifications',
    billingFlag: 'billingFlag',
    biztalkAvailabilityFlag: 'biztalkAvailabilityFlag',
    paymentUnavailability: 'paymentUnavailability',
    clarifyFlag: 'clarifyFlag',
    genevaFlag: 'genevaFlag',
    ocsFlag: 'ocsFlag',
    productConfiguration: 'productConfiguration',
    pciFlag: 'topupNewPCI',
    pciPattern: 'topupNewPCIMsisdnPattern',
    disablemiwifi: 'disableMiwifi',
    showBillingEta: 'showBillingEta',
    tobiChatUnAvailability: 'tobiChatUnAvailability',
    tobiUserConfiguration: 'tobiUserConfiguration',
    showEverythingIsOk: 'showEverythingIsOk',
    smartPayMSIDNPattern: 'smartPayMSIDNPattern',
    purchaseDefaultJourney: 'newPrepagoLinesPurchaseDefaultJourney',
    billreviewStatus: '$.reviewBillingJourney.status',
    billreviewpattern: '$.reviewBillingJourney.pattern',
    billreviewValidUsers: '$.reviewBillingJourney.validUsers',
    showSuperWifi: 'showSuperWifi',
    storiesPosition: 'storiesPosition',
    rsmAvailabilityForBillReview  : 'rsmAvailabilityForBillReview',
    showMovementsHistoryNewScreen: 'showMovementsHistoryNewScreen',
    showBillstatus: 'showBillstatus'
  },
  UpgradeProfile: {
    profileType: '$.profile.profileType'
  },
  tvChannels: {
    url: '$..url',
    channel: 'ch',
    code: '_code'
  },
  PaymentCustomerDept: {
    id : '$.id',
    accountBalanceAmount: '$.accountBalance[?(@.balanceType=="total")].amount.value',
    accountBalanceAmountUnit: '$.accountBalance[?(@.balanceType=="total")].amount.unit',
    state: '$.state',
  },
  Dashboard: {
    ServiceBalance: {
      id: '$.id',
      code: '$.code',
      type: '$.type',
      category: '$.categories[0]',
      group: '$.group',
      uploadSpeed: 'uploadSpeed',
      downloadSpeed: 'downloadSpeed',
      name: '$.name',
      shortDescription: '$.description.short',
      amount: '$.amount.amount',
      currency: '$.amount.currency',
      validity_period: 'validityPeriod',
      fromDate: 'fromDate',
      activationDate: 'activationDate',
      expirationDate: 'toDate',
      services: '$.subscriptions',
      allowance: '$.allowance',
      available_allowance: 'availableAllowance',
      available_allowance_unit_code: 'availableAllowanceUnitCode',
      used_allowance: 'usedAllowance',
      used_allowance_unit_code: 'usedAllowanceUnitCode',
      remaining_allawonce: 'remainingAllowance',
      remaining_allowance_unit_code: 'remainingAllowanceUnitCode',
      isLimited: 'unlimited',
      dataSharingType: '$.subscriptions[0].dataSharingType',
      dataSharing: '$.datasharing',
      isShared: '$.isShared',
      limitStatus: '$.limitStatus',
      targetLimit: '$.datasharing.targetLimit',
      basicEnjoyMore: '$.basicEnjoyMore',
      productType: '$.productType',
      sharingType: '$.datasharing.type',
      isEligibleForSharing: '$.datasharing.isEligibleForSharing'
    }
  },
  Subscription: {
    customerAccount: '$.items[0].parts[0]',
    parts: '$.parts[0]',
    defaultService: '$.items[0]',
    services: '$.items',
    seviceId: '$.id',
    serviceName: '$.name',
    serviceDescription: '$.description',
    serviceType: '$.type',
    msisdn: '$.msisdn',
    siteDigital: '$.parts[0].customerAccounts[0].digital',
    serviceDigital: '$.digital',
    serviceStatus: '$.status',
    Package: '$.parts[0].productOffers[0]',
    PackageCode: '$.parts[0].productOffers[0].code',
    PackageType: '$.parts[0].productOffers[0].type',
    PackageName: '$.parts[0].productOffers[0].name',
    PackageTitle: '$.parts[0].productOffers[0].title',
    PackageSubtitle: '$.parts[0].productOffers[0].subtitle',
    PackageAdditionalLinesDesc: '$.parts[0].productOffers[0].additionalLinesDesc',
    PackageStartDate: '$.parts[0].productOffers[0].startDate',
    customerAccountId: 'customerAccounts',
    tarrifCode: '$.code',
    subscriptionDecimalAmount: '$.subscriptionDecimalAmount',
    subscriptionWithoutDiscountAmount: '$.subscriptionWithoutDiscountAmount',
    subscriptionDecimalAmountNoTax: '$.subscriptionDecimalAmountNoTax',
    subscriptionWithoutDiscountAmountNoTax: '$.subscriptionWithoutDiscountAmountNoTax',
    extras: '$.extras',
    discounts: '$.discounts',
    superOffer: '$.items[0].parts[0].customerAccounts[0].superOffer',
    subscriptionsAmount: '$.subscriptionsAmount',
    digital: '$.items[0].parts[0].customerAccounts[0].digital',
    internetSpeed: '$.internetSpeed',
    inactiveItems: '$.inactiveItems',
    instance: '$.instance',
    smartPay: '$.items[0].parts[0].customerAccounts[0].smartPay'
  },
  MenuItem: {
    enabled: '$.enabled',
    iconName: 'iconName',
    category: 'category',
    title: '$.title',
    redirectTo: '$.navigation.path',
    profile: '$.navigation.profile',
    navigationType: '$.navigation.type',
    id: '$.id',
    name: '$.name',
    order: '$.order'
  },
  UserAccount: {
    Id: '$.id',
    FirstName: 'firstName',
    middleName: 'middleName',
    FamilyName: 'familyName',
    Msisdn: '$.msisdn',
    Email: '$.email',
    Items: '$.items',
    Type: '$.type',
    Status: '$.status',
    buildingNo: '$.buildingNumber',
    buildingNumber: 'buildingNumber',
    address: '$.address',
    level: '$.level',
    levelNr: '$.levelNr',
    street: '$.street',
    town: '$.town',
    country: '$.country',
    postcode: '$.postcode',
    postcodeTariff: '$.postCode',
    door: '$.portalDoor',
    streetType: '$.streetType',
    formattedAddress: 'formattedAddress',
    segment: '$.segment',
    marketSegment: '$.marketSegment',
    xmasVoucher: '$.xmasVoucher',
    debtAmount: '$.debtAmount',
    chatSegment: '$.chatSegment',
    emailStatus: '$.emailStatus',
    newEmail: '$.newEmail',
    pdteFirma: '$.pdteFirma',
    confirmedEmail: '$.confirmedEmail'
  },
  preferencesAndPermissions: {
    timeFrame: '$.preferences.timeFrame',
    notifications: '$.preferences.notifications',
    topics: '$.preferences.topics',
    contactTopics: '$.preferences.contactTopics',
    permissions: '$.permissions',
    gdprDiagnostics: '$.gdprDiagnostics'
  },
  BillingAccount: {
    items: '$.items',
    id: '$.id',
    billingAddress: 'billingAddress',
    doorNumber: 'doorNumber',
    buildingNumber: 'buildingNumber',
    buildingName: 'buildingName',
    level: '$.level',
    street: '$.street',
    town: '$.town',
    country: '$.county',
    postcode: 'postCode',
    paymentMethod: 'paymentMethod',
    type: '$.type',
    iban: '$.iban',
    bic: '$.bic',
    accountNumber: '$.accountNumber',
    bankName: '$.bankName',
    subscriptions: '$.subscriptions',
    serviceId: '$.id',
    debt: '$.debt',
    amount: '$.amount',
    dueDate: '$.dueDate',
    notReflectedPayments: '$.notReflectedPayments',
    notReflectedCachDesk: '$.notReflectedCachDesk',
    reflectedPaymentsType: '$.reflectedPaymentsType'
  },
  BillDetails: {
    total: '$.billOverview.amounts[0].grossAmountDue',
    subTotal: '$.billOverview.amounts[0].netAmountDue',
    taxes: '$.billOverview.amounts[0].taxes',
    taxesDue: '$.billOverview.amounts[0].taxesDue',
    allDetailesItems: '$.billDetails',
    billStatus: '$.status',
    numberOfAlarms: '$.billOverview.numberOfAlarms',
    subscriptions: '$.subscriptions',
    subscriptionId: '$.id',
    subscriptionName: '$.name',
    subscriptiontype: '$.type',
    /** from bill details */
    amount: '$.amounts[0].grossAmountDue',
    reminingAmount: '$.amounts[0].remainingAmountDue',
    name: '$.name',
    serviceNumber: '$.subscriptionId',
    type: '$.type',
    notification: '$.extension.es.notification',
    notificationId: '$.notificationId',
    notificationType: '$.notificationType',
    numberOfNotifications: '$.extension.es.numberOfNotifications',
    discounts: '$.discount',
    newOffer: '$.newOffer',
    startDate: '$.startDate',
    endDate: '$.endDate',
    typeAlarm: '$.typeAlarm'
  },
  Bill: {
    billing_cycle: 'billingCycle',
    billing_date: 'billingDate',
    bill_overview: 'billOverview',
    billing_period: 'billingPeriod',
    start_date: '$.items.0.parts.0.billingCycle.billingPeriod.startDate',
    end_date: '$.items.0.parts.0.billingCycle.billingPeriod.endDate'
  },
  Inbox: {
    id: '$.id',
    title: '$.title',
    type: '$.type',
    creationDate: '$.creationDate',
    section: '$.section',
    description: '$.description',
    staus: '$.status',
    displayInPopup: '$.displayInPopup',
    destination: '$.destination',
    destinationType: '$.destination.type',
    destinatinValue: '$.destination.value',
    templete: '$.template',
    imageUrl: '$.imageURL',
    content: '$.content',
    contentActionId: '$.actionId',
    contentUrl: '$.url',
    contentAltImage: '$.altImageURL',
    contentDescription: '$.description',
    navigation: '$.navigation',
    contentNavigationLabel: '$.navigation.label',
    contentNavigationchildBrowser: '$.navigation.childBrowser',
    contentNavigationchildUrl: '$.navigation.URL',
    contentNavigationActionId: '$.navigation.actionId',
    count: '$.count.delivered',
    category: '$.category',
    notificationTypeId: '$.notificationTypeId'
  },
  Products: {
    Items: '$.items',
    status: '$.status',
    serviceType: '$.serviceType',
    additionalInfo: '$.additionalInfo',
    deviceName: '$.deviceName',
    additionalInfoTitle: '$.additionalInfoTitle',
    isOneProfesional: '$.isOneProfesional',
    defaultSVACode: '$.defaultSVACode',
    Name: '$.name',
    title: '$.title',
    titleLimit: '$.titleLimit',
    titleRate: '$.titleRate',
    Type: '$.type',
    Code: '$.code',
    TypeName: '$.typeName',
    ProductType: '$.productType',
    Email: '$.email',
    productExpirationDate: '$.productExpirationDate',
    ProductCharacteristics: '$.productCharacteristics',
    CallType: '$.productCharacteristics.callType',
    ProductCategory: '$.productCharacteristics.category',
    statusPartialError: '$.status',
    lastStatus: '$.status.previous',
    currentStatus: '$.status.current',
    ChannelDeactivationLocked: '$.status.deactivationlocked',
    ChannelDeactivationLockedCode: '$.status.messageCode',
    transactionStatus: '$.status.transaction',
    Extensions: '$.extension.es',
    Special: '$.extension.es.isSpecialBundle',
    Restrictions: '$.extension.es.restrictions',
    RestrictionsId: '$.extension.es.restrictions[0].id',
    RestrictionsIsChargeToBill: '$.extension.es.restrictions[0].isChargeToBill',
    Consumption: '$.consumption',
    ConsumptionAllowance: '$.allowance',
    TotalConsumption: '$.availableAllowance',
    RemainingConsumptionDataShareing: '$.consumption.allowance.remainingAllowance',
    RemainingConsumptionUnit:
      '$.availableAllowanceUnitCode',
    ConsumedConsumption: '$.usedAllowance',
    ConsumedConsumptionUnit: '$.usedAllowanceUnitCode',
    IsUnLimited: '$.unlimited',
    ConsumptionExpiryDate: '$.consumption.expiryDate',
    ShortDescription: '$.description.short',
    LongDescription: '$.description.long',
    Payment: '$.payment',
    PayPerUse: '$.payment.perUse',
    CallSetup: '$.cost.setup',
    Interval: '$.cost.interval',
    CallSetupCurrency: '$.cost.setupCurrency',
    CallSetupUnit: '',
    Cost: '$.cost',
    fee: '$.cost.monthly',
    feeUnit: '$.cost.monthlyCurrency',
    feeWIva: '$.cost.monthlyWithoutIVA',
    feeUnitWIva: '$.cost.monthlyCurrencyWithoutIVA',
    EnjoyMore: '$.enjoyMore',
    EnjoyMoreRenewalDate: '$.enjoyMore.validUntil',
    EnjoyMoreIncludedApplications: '$.enjoyMore.includedApplications',
    WorryFree: '$.worryFree',
    isCurrentlyUsed: '$.worryFree.isCurrentlyUsed',
    currentStep: '$.worryFree.currentStep',
    maximumSteps: '$.worryFree.maximumSteps',
    remainingStep: '$.worryFree.remainingStep',
    remainingStepName: '$.worryFree.remainingStep.name',
    remainingStepTotalLimit: '$.worryFree.remainingStep.totalLimit',
    remainingStepTotalallowance: '$.worryFree.remainingStep.allowance',
    availableAllowance:
      '$.worryFree.remainingStep.allowance.availableAllowance',
    availableAllowanceUnitCode:
      '$.worryFree.remainingStep.allowance.availableAllowanceUnitCode',
    usedAllowance: '$.worryFree.remainingStep.allowance.usedAllowance',
    usedAllowanceUnitCode:
      '$.worryFree.remainingStep.allowance.usedAllowanceUnitCode',
    allowanceUnlimited: '$.worryFree.remainingStep.allowance.unlimited',
    remainingStepCost: '$.worryFree.remainingStep.cost',
    remainingStepCostOneTime: '$.worryFree.remainingStep.cost.oneTime',
    remainingStepCostOneTimeCurrency:
      '$.worryFree.remainingStep.cost.oneTimeCurrency',
    totalPricePaid: '$.worryFree.totalPricePaid',
    limit: '$.worryFree.limit',
    onlineTv: '$.onlineTv',
    hasPendingChannel: '$.onlineTv.hasPendingChannel',
    group: '$.onlineTv.group',
    channels: '$.onlineTv.channels',
    onlineTVPromotions: '$.onlineTv.promotions',
    promotionServiceIds: '$.serviceIds',
    promotionFee: '$.fee',
    promotionFeeUnit: '$.feeunit',
    promotionCode: '$.code',
    promotionStatus: '$.status',
    promotionName: '$.name',
    promotionActivationDate: '$.activationDate',
    promotionChannelCode: '$.channelCode',
    onlineTvEmail: '$.onlineTv.email',
    onlineTvAblToBook: '$.ableToBook',
    serviceIds: '$.serviceIds',
    benefitsFlag: '$.benefitsFlag',
    activationDate: '$.activationDate',
    dataSharing: '$.datasharing',
    isShared: '$.isShared',
    subscription: '$.subscription',
    dataSharingType: '$.subscription.dataSharingType',
    mobility: '$.mobility',
    oneMonthPromotions: '$.promotions',
    oneMonthPromotionCode: '$.promotions[0].code',
    oneMonthPromotionStatus: '$.promotions[0].status',
    canBeDeactivated: '$.canBeDeactivated',
    orderCreationDate: '$.order.creationDate',
    productAlert: '$.alert',
    discount: '$.discount',
    discountsDesc: '$.discount[0].desc',
    discountsMonthlyCurrency: '$.discount[0].monthlyCurrency',
    discountsDiscountAmount: '$.discount[0].discountAmount',
    discountsFee: '$.discount[0].fee',
    discountsStartDate: '$.discount[0].startDate',
    discountsEndDate: '$.discount[0].endDate',
    discountsDiscountDuration: '$.discount[0].discountDuration',
    incompatibilities: '$.incompatibilities',
    customerAccount: '$.customerAccount',
    voucherCode: '$.voucher.voucher',
    availableShipping: '$.availableShipping',
    offeringCode: '$.offeringCode',
    channelsTV: '$.channels',
    channelsTVDescriptionLong: '$.description.long',
    channelsTVFee: '$.cost.fee',
    addons: '$.addons',
    description: '$.description',
    id: '$.id',
    grupoAddon: '$.grupoAddon',

  },
  Agreement: {
    links: '$.links',
    streaming: '$.links.download',
    href: '$.links.download.href',
    agreementId: '$.id',
    agreementName: '$.name'
  },
  CustomerParties: {
    id: '$.id',
    type: '$.type',
    currentMsisdn: '$.currentMsisdn',
    username: '$.username',
    individual: '$.individual',
    salutation: '$.individual.salutation',
    firstName: '$.individual.firstName',
    familyName: '$.individual.familyName',
    birthDate: '$.individual.birthDate',
    company: '$.company',
    companyName: '$.company.name',
    companyId: '$.company.id',
    contactPoints: '$.contactPoints',
    phone: '$.contactPoints[0].fixedLine.phoneNumber',
    vodafonePhone: '$.contactPoints[0].mobile.msisdn',
    email: '$.contactPoints[0].email.fullAddress',
    postal: '$.contactPoints[0].postal',
    country: '$.contactPoints[0].postal.country',
    street: '$.contactPoints[0].postal.street',
    town: '$.contactPoints[0].postal.town',
    extension: '$.extension',
    extensionEs: '$.extension.es',
    permissions: '$.extension.es.permissions',
    contactPreferences: '$.contactPreferences',
    contactPoint: '$.contactPreferences.contactPoint',
    notificationChannel: '$.contactPreferences.notificationChannel',
    notificationTime: '$.contactPreferences.notificationTime',
    documentId: '$.extension.es.document.id'
  },
  Tariff: {
    item: '$.items',
    Name: '$.name',
    Code: '$.code',
    Id: '$.id',
    statusSBA: '$.statusSBA',
    serviceType: '$.serviceType',
    decoEquipment: '$.decoEquipment',
    devices: '$.devices',
    equipment: '$.equipment',
    Status: '$.status',
    Cost: '$.cost',
    Fee: '$.cost.monthlyRecurring',
    Unit: '$.cost.monthlyRecurringCurrency',
    RoamingMessage: '$.roamingMessage',
    RoamingMessageType: '$.roamingMessageType',
    Yu: '$.segments[0]',
    Segments: '$.segments',
    Address: '$.address',
    ShortDescription: '$.description.short',
    LongDescription: '$.description.long',
    Fibre: '$.fibre',
    DownloadSpeed: '$.fibre.downloadSpeed',
    DownloadSpeedUnit: '$.fibre.downloadSpeedUnit',
    UploadSpeed: '$.fibre.uploadSpeed',
    UploadSpeedUnit: '$.fibre.uploadSpeedUnit',
    RouterName: '$.fibre.routerName',
    RouterType: '$.fibre.type',
    RouterSerialNumber: '$.fibre.routerSerialNumber',
    Television: '$.onlineTv',
    HasPendingChannel: '$.onlineTv.hasPendingChange',
    NumberOfChannels: '$.onlineTv.numberOfChannels',
    group: '$.onlineTv.group',
    Email: '$.onlineTv.email',
    OnlineStatus: '$.onlineTv.status.current',
    lastStatus: '$.onlineTv.status.previous',
    Channels: '$.onlineTv.channels',
    benefitStatus: '$.extension.es.benefits.status',
    benefitDescription: '$.extension.es.benefits.description',
    topupPeriodicity: '$.topUpPeriodicity',
    benefitExpiryDate: '$.extension.es.benefits.expiryDate',
    benefitRenewalDate: '$.extension.es.benefits.renewalDate',
    hasBenefits: '$.extension.es.benefits.isEligible',
    isTotalMobility: '$.mobility.isTotalMobility',
    superWifi: '$.extension.es.superWifi',
    secureNet: '$.extension.es.secureNet',
    secureNetFamily: '$.extension.es.secureNetFamily',
    SuperOffer: '$.items[0].superofer',
    mercury: '$.is5g',
    credit: '$.credit',
    creditValue: '$.credit.creditValue',
    creditCurrency: '$.credit.currency',
    subscription: '$.subscription',
    msisdn: '$.subscription.id',
    vouchers: '$.vouchers',
    isPrepaidCommunity: '$.isPrepaidCommunity',
    promotions: '$.promotions',
    smartPay: '$.customerAccount.smartPay',
  },
  Voucher: {
    type: '$.type',
    status: '$.status',
    serviceId: '$.serviceId',
    voucher: '$.voucher',
    endDate: '$.endDate'
  },
  CommitmentContracts: {
    items: '$.items',
    penalty: '$.penalty',
    type: '$.type',
    startDate: 'startDate',
    endDate: 'endDate',
    subscription: 'subscription'
  },
  Equipment: {
    id: '$.subscription.id',
    type: '$.type',
    hasAppointment: '$.hasAppointment',
    routerPrice: '$.routerPrice',
    router6Price: '$.router6Price',
    routerDecoPrice: '$.routerDecoPrice',
    routerDeco2Price: '$.routerDeco2Price',
    decoPrice: '$.decoPrice',
    decoAutoPrice: '$.decoAutoPrice',
    deco2Price: '$.deco2Price',
    deco2AutoPrice: '$.deco2AutoPrice',
    changeRouterID: '$.changeRouterID',
    changeRouter6ID: '$.changeRouter6ID',
    changeRouterDecoID: '$.changeRouterDecoID',
    changeRouterDeco2ID: '$.changeRouterDeco2ID',
    changeDecoID: '$.changeDecoID',
    changeDecoAutoID: '$.changeDecoAutoID',
    changeDeco2ID: '$.changeDeco2ID',
    changeDeco2AutoID: '$.changeDeco2AutoID',
    decoQuoteAmount: '$.decoQuoteAmount'
  },
  Normalizador: {
    results: '$.results',
    addressComponenets: '$.address_components',
    type: '$.types[0]',
    longName: '$.long_name',
    routeType: 'route_type',
    route: 'route',
    routeName: 'route_name',
    streetNumber: 'street_number',
    provincial: 'administrative_area_level_2',
    city: 'locality',
    postalCode: 'postal_code',
    formattedAddress: 'formatted_address'
  },
  Billing: {
    items: '$.items',
    billId: '$.id',
    billNo: '$.billNo',
    creationDate: '$.creationDate',
    resolutionDate: '$.resolutionDate',
    billStatus: '$.status',
    links: '$.documents[0].links',
    billingAccount: '$.billingAccount',
    billingAddress: '$.billingAddress',
    billingAccountId: '$.id',
    billingAccountAlias: '$.alias',
    billingAccountFormattedAddress: '$.formattedAddress',
    billingCycle: '$.billingCycle',
    billingMonth: '$.billingMonth',
    billingYear: '$.billingYear',
    startDate: '$.startDate',
    endDate: '$.endDate',
    billOverview: '$.billOverview',
    numberOfAlarms: '$.numberOfAlarms',
    billAmount: '$.amounts[0]',
    grossAmountDue: '$.grossAmountDue',
    remainingAmountDue: '$.remainingAmountDue',
    billCurrency: '$.currency',
    billDocumentLink: '$.download.href',
    subscriptions: '$.subscriptions',
    serviceId: '$.id',
    serviceType: '$.type',
    Notification: {
      id: '$.id',
      type: '$.type',
      alertType: '$.alertType',
      description: '$.description',
      alerts: '$.alerts'
    },
    configuration: {
      items: '$.items',
      id: '$.id',
      alias: '$.alias',
      billLanguage: '$.language',
      eBilling: '$.eBilling',
      status: '$.status',
      attachPdf: '$.attachPdf',
      sms: '$.sms',
      msisdn: '$.msisdn',
      email: '$.email',
      emailAddress: '$.emailAddress'
    },
    currentConsumption: {
      items: '$.items',
      amount: '$.amount',
      description: '$.description',
      type: '$.type',
      quantity: '$.quantity',
      destination: '$.destination',
      unit: '$.unit',
      timePeriod: '$.timePeriod',
      /** will returned inside timePeriod object */
      startDate: '$.startDate',
      endDate: '$.endDate',
      duration: '$.duration',
      code: '$.code'
    }
  },
  URLItems: {
    items: '$.items',
    id: '$.id',
    name: '$.name',
    sourceLink: '$.sourceLink',
    destination: '$.destination',
    urlType: '$.urlType',
    messageCode: '$..messageCode'
  },
  ServiceSettings: {
    sim: '$.sim',
    pins: '$.pins',
    puks: '$.puks',
    expiryDate: '$.expiryDate',
    name: '$.name',
    isChargeToBill: '$.isChargeToBill',
    parentId: '$.parentId'
  },
  callOptions: {
    missedCallsNotification: '$.missedCallsNotification',
    autoRedial: '$.autoRedial',
    dictaSms: '$.dictaSms'
  },
  TopUpHistory: {
    items: '$.items',
    topupDate: '$.transactionDate',
    topupType: '$.type',
    topupAmount: '$.amount',
    offset: '$.offset',
    serviceId: '$.serviceId',
    hasPendingOrders: '$.hasPendingOrders'
  },
  TopUpCurrentSpendings: {
    items: '$.items',
    type: '$.type',
    description: '$.description',
    amount: '$.amount',
    quantity: '$.quantity',
    destination: '$.destination',
    unit: '$.unit',
    timePeriod: {
      startDateTime: '$.timePeriod.startDate',
      endDateTime: '$.timePeriod.endDate',
      duration: '$.timePeriod.duration'
    },
    offset: '$.offset'
  },
  Impersonation: {
    SUCCESS: {
      JWT: '$.jws',
      ACCESS_TOKEN: '$.access_token',
      TOKEN_TYPE: '$.token_type',
      EXPIRES_AT: '$.expires_at',
      EXPIRES_IN: '$.expires_in',
      ISSUED_AT: '$.issued_at'
    },
    ImpersonatedBy: {
      impersonatedBy: '$.impersonatedBy',
      Subject: '$.sub',
      Issuer: '$.iss',
      Group: '$.group',
      IssueTime: '$.iat',
      JwtID: '$.jti'
    }
  },
  UserSettings: {
    webToken: '$.webToken',
    chatToken: '$.chatToken'
  },
  chatConfig: {
    name: '$.name',
    taggingName: '$.taggingName',
    faqsEnabled: '$.faqsEnabled',
    chatEnabled: '$.chatEnabled',
    module: '$.module',
    tobiBubble: '$.tobiBubble'
  },
  PurchasesAndSubscriptions: {
    Items: '$.items',
    type: '$.type',
    name: '$.name',
    price: '$.price',
    priceUnit: '$.priceUnit',
    creationDate: '$.creationDate',
    companyName: '$.companyName',
    companyTelephone: '$.companyTelephone',
    subscriptionDetails: '$.subscriptionDetails',
    duration: '$.duration',
    ableToUnsubscribe: '$.ableToUnsubscribe',
    subscriptionId: '$.subscriptionId',
    endDate: '$.endDate',
    typeOfSubscription: '$.typeOfSubscription'
  },
  LineRestrictions: {
    lineStatus: '$.lineStatus',
    callRestrictions: {
      all: '$.callSettings.all',
      incoming: '$.callSettings.incoming',
      international: '$.callSettings.international',
      data: '$.callSettings.data',
      roaming: '$.callSettings.roaming',
      incomingRoaming: '$.callSettings.incomingRoaming',
      highLevel: '$.callSettings.highLevel',
      lowLevel: '$.callSettings.lowLevel',
      show: '$.callSettings.show',
      specialTariffMobile: '$.callSettings.specialTariffMobile',
      specialTariffLandline: '$.callSettings.specialTariffLandline'
    },
    lineRestrictionsVisibilityStatusCards: {
      answerMachine: {
        show: '$.lineServicesCard.answerMachine.show',
        always: '$.lineServicesCard.answerMachine.always',
        busy: '$.lineServicesCard.answerMachine.busy',
        unreachable: '$.lineServicesCard.answerMachine.unreachable',
        noAnswerIn: '$.lineServicesCard.answerMachine.noAnswerIn',
        smsNotification: '$.lineServicesCard.answerMachine.smsNotification',
        language: '$.lineServicesCard.answerMachine.language'
      },
      recieveSMS: {
        show: '$.lineServicesCard.recieveSMS.show',
        busy: '$.lineServicesCard.recieveSMS.busy',
        outOfCoverage: '$.lineServicesCard.recieveSMS.outOfCoverage'
      },
      callWaiting: {
        show: '$.lineServicesCard.callWaiting.show'
      },
      hideMyNumber: {
        show: '$.lineServicesCard.hideMyNumber.show'
      },
      divertCalls: {
        show: '$.lineServicesCard.divertCalls.show'
      },
      autoredial: {
        show: '$.lineServicesCard.autoredial.show',
        status: '$.lineServicesCard.autoredial.status'
      },
      dictaSMS: {
        show: '$.lineServicesCard.dictaSMS.show',
        status: '$.lineServicesCard.dictaSMS.status'
      },
      callSettings: {
        all: '$.lineServicesCard.lineRestrictions.callSettings.all',
        incoming: '$.lineServicesCard.lineRestrictions.callSettings.incoming',
        international: '$.lineServicesCard.lineRestrictions.callSettings.international',
        roaming: '$.lineServicesCard.lineRestrictions.callSettings.roaming',
        incomingRoaming: '$.lineServicesCard.lineRestrictions.callSettings.incomingRoaming',
        highLevel: '$.lineServicesCard.lineRestrictions.callSettings.highLevel',
        lowLevel: '$.lineServicesCard.lineRestrictions.callSettings.lowLevel',
        show: '$.lineServicesCard.lineRestrictions.callSettings.show'
      },
      lineStatus: '$.lineServicesCard.lineRestrictions.lineStatus'
    },
  },
  FaultManagment: {
    ServiceAvailability: {
      hasMassiveTicket: '$.hasMassiveTicket',
      hasOrderTicket: '$.hasOrderTicket',
      massiveTicketId: '$.massiveTicketId',
      massiveTicketType: '$.massiveTicketType',
      isMobile: '$.isMobile',
      isInternet: '$.isInternet',
      isTV: '$.isTV',
      isFixed: '$.isFixed'
    },
    OpenNewTicket: {
      status: '$.status',
      taskId: '$.taskId',
      form: '$.form',
      technology: '$.technology',
      backend: '$.backend',
      symptom: '$.symptom',
      deco: '$.deco',
      ticketId: '$.ticketId',
      journey: '$.journey',
    },
    TicketDetails: {
      ticketId: '$.ticketId',
      ticketName: '$.ticketName',
      ticketType: '$.ticketType',
      status: '$.status',
      creationDate: '$.creationDate',
      title: '$.title',
      icon: '$.icon',
      isGeneralBreakDown: '$.isGeneralBreakDown',
      closingDate: '$.closingDate',
      template: {
        templateId: '$.template.templateId',
        templateCode: '$.template.templateCode',
        templateDate: '$.template.templateDate',
        visitFrom: '$.template.visitFrom',
        visitTo: '$.template.visitTo',
        outSLA: '$.template.outSLA'
      },
      averia: '$.averia',
      caseBE: {
        caseId: '$.caseBE.caseId',
        status: '$.caseBE.status',
        channelId: '$.caseBE.channelId',
        agentName: '$.caseBE.agentName',
        postId: '$.caseBE.postId',
        createdAt: '$.caseBE.createdAt',
        statusUpdatedAt: '$.caseBE.statusUpdatedAt',
      },
      journey: '$.journey',
    }
  },
  MiwifiConfig: {
    wificonfig: '$.wificonfig',
    ssids: '$.ssids',
    currentPassword: '$.currentPassword',
    currentSecType: '$.currentSecType',
    currentChannel: '$.currentChannel',
    currentBandwidth: '$.currentBandwidth',
    currentAutoChannel: '$.currentAutochannel',
    currentName: '$.currentName',
    enabled: '$.enabled',
    actions: '$.actions',
    actionId: '$.actionId',
    role: '$.role',
    type: '$.type',
    uuid: '$.uuid',
    inputs: '$.inputs',
    inputId: '$.inputId',
    default: '$.default',
    valueList: '$.valueList',
    label: '$.label',
    value: '$.value'
  },
  Attr: {
    type: '$.type',
    value: '$.value',
    units: '$.units'
  },
  Product: {
    subscriptionsAmount: '$.subscriptionsAmount',
    subscriptionsNetAmount: '$.subscriptionsNetAmount'
  },
  Service: {
    id: '$.id',
    code: '$.code',
    dateExpire: '$.dateExpire',
    name: '$.name',
    longDescription: '$.longDescription',
    type: '$.type',
    recoOPMicro: '$.recoOPMicro',
    fee: '$.fee',
    netFee: '$.netFee',
    attrs: '$.attrs'
  },
  Combination: {
    excludeAddon: '$.excludeAddon',
    tariffId: '$.tariffId'
  },
  Discount: {
    code: '$.code',
    type: '$.type',
    dtoExpire: '$.dtoExpire',
    dtoDuration: '$.dtoDuration',
    amount: '$.amount',
    netAmount: '$.netAmount',
  },
  TiendaV10: {
    code: '$.code',
    group: '$.group',
    order: '$.order'
  },
  Offer: {
    id: '$.id',
    typeReco: '$.typeReco',
    codReco: '$.codReco',
    codTax: '$.codTax',
    offerTotalAmount: '$.offerTotalAmount',
    offerNetAmount: '$.offerNetAmount',
    hasAgent: '$.hasAgent'
  },
  Commitment: {
    type: '$.type',
    commIniDate: '$.commIniDate',
    commEndDate: '$.commEndDate',
    commDuration: '$.commDuration',
    penaltyAmount: '$.penaltyAmount',
    inService: '$.inService'
  },
  CommercialOffers: {
    configurable: '$.rate.configurable',
    device: '$.rate.device',
    offs: '$.offer',
    offerId: '$.offerId',
    packageId: '$.packageId',
    code: '$.rate.code',
    rate: '$.rate',
    name: '$.rate.name',
    installment: '$.rate.price.installment',
    installmentPromo: '$.rate.price.installmentPromo',
    installmentTaxes: '$.rate.price.installmentTaxes',
    installmentPromoTaxes: '$.rate.price.installmentPromoTaxes',
    dataIncludedList: '$.rate.text.dataIncludedList',
    dataList: '$.rate.text.dataList',
    dataIncludedDetailList: '$.rate.text.dataIncludedDetailList',
    deviceUpfrontTaxesPromo: '$.rate.price.deviceUpfrontTaxesPromo',
    callsIncludedList: '$.rate.text.callsIncludedList',
    callsIncludedDetailList: '$.rate.text.callsIncludedDetailList',
    callsIncludedSecondList: '$.rate.text.callsIncludedSecondList',
    dataIncludedSecondList: '$.rate.text.dataIncludedSecondList',
    callsList: '$.rate.text.callsList',
    roamingList: '$.rate.text.roamingList',
    roamingDetailList: '$.rate.text.roamingDetailList',
    otherList: '$.rate.text.otherList',
    otherDetailList: '$.rate.text.otherDetailList',
    dataSpeedList: '$.rate.text.dataSpeedList',
    dataDetailList: '$.rate.text.dataDetailList',
    image: '$.rate.image.rateImageIcon',
    permanenceDuration: '$.rate.permanenceDuration',
    promotions: '$.rate.promotions',
    showDetailList: '$.rate.showDetailList',
    showDetailFicha: '$.rate.showDetailFicha',
    showOpenList: '$.rate.showOpenList',
    nameList: '$.rate.nameList',
    nameFicha: '$.rate.nameFicha',
    additionalLine: '$.rate.additionalLine',
    price: '$.rate.price.installmentTaxes',
    dataInclude: '$.rate.text.dataIncludedList',
    callsIndlude: '$.rate.text.callsIncludedList',
    recommendedList: '$.rate.recommendedUses',
    registerType: '$.registerType',
    internetIncludedFicha: '$.rate.text.internetIncludedFicha',
    internetIncludedSecondFicha: '$.rate.text.internetIncludedSecondFicha',
    internetIncludedList: '$.rate.text.internetIncludedList',
    internetIncludedSecondList: '$.rate.text.internetIncludedSecondList',
    dataAddLineDefNewRegi: '$.rate.text.dataAddLineDefNewRegi',
    dataAddLineDefNewRegiOne: '$.rate.text.dataAddLineDefNewRegiOne',
    disabled: '$.disabled',
    transaccional: '$.rate.transaccional',
    unsubscribeDiscounts: '$.rate.unsubscribeDiscounts',
  },
  PromoCommercial: {
    proms: '$.promos',
    endDate: '$.endDate',
    keep: '$.keep',
    id: '$.id',
    applyTo: '$.applyTo',
    descriptionCRM: '$.descriptionCrm',
    discountAmount: '$.discountAmount',
    discountAmountTaxes: '$.discountAmountTaxes',
    discountClassType: '$.discountClassType',
    discountDuration: '$.discountDuration',
    discountDurationUndefined: '$.discountDurationUndefined',
    discountType: '$.discountType',
    evaluationOrder: '$.evaluationOrder',
    flagIgnoreCRM: '$.flagIgnoreCRM',
    flagRegional: '$.flagRegional',
    headbandOpenText: '$.headbandOpenText',
    internalName: '$.internalName',
    linkedToCatalog: '$.linkedToCatalog',
    longText: '$.longText',
    publicName: '$.publicName',
    detailTextList: '$.detailTextList',
    urlMobilePromotionImageFicha: '$.urlMobilePromotionImageFicha',
    urlMobilePromotionImage: '$.urlMobilePromotionImage',
    urlTabletPromotionImageFicha: '$.urlTabletPromotionImageFicha',
    shortText: '$.shortText',
    llbbText: '$.llbbText',
    llbbUrl: '$.llbbUrl',
    detailTextFicha: '$.detailTextFicha',
    stayPromotionText: '$.stayPromotionText',
    starText: '$.starText',
    longTextFicha: '$.longTextFicha',
    listIcon: '$.listIcon',
    detailIcon: '$.detailIcon',
    cardColor: '$.cardColor',
    cardText: '$.cardText',
    detailPromotionText: '$.detailPromotionText',
  },
  CommercialOperators: {
    operadoresMovil: '$.operadoresMovil',
    operadoresFijo: '$.operadoresFijo',
    id: '$.id',
    objId: '$.objId',
    name: '$.name',
    visible: '$.visible',
    xDesCortaOper: '$.xDesCortaOper',
    xNomIdentificacion: '$.xNomIdentificacion',
    xNomOperadora: '$.xNomOperadora'
  },
  Reset: {
    ntol_txid: '$.NTOL_TXID'
  },
  PersonalData: {
    completed: '$.completed',
    order_id: '$.order_id',
    ecode: '$.ecode'
  },
  LegalTermJson: {
    listAceptos: '$.listAceptos',
    checked: '$.checked',
    id: '$.id',
    internalDescription: '$.internalDescription',
    legalTermDetails: '$.legalTermDetails',
    shortText: '$.shortText',
    linkShortText: '$.linkShortText',
    htmlText: '$.htmlText',
    orden: '$.orden'
  },
  CommercialShoppingCart: {
    deleteItems: '$.deleteItems',
    overMaxInternetLines: '$.overMaxInternetLines',
    overMaxLines: '$.overMaxLines',
    remainingInternetLines: '$.remainingInternetLines',
    remainingMobileLines: '$.remainingMobileLines',
    taxType: '$.taxType',
    taxValue: '$.taxValue',
    price: '$.price',
    upfront: '$.price.upfront',
    upfrontTaxes: '$.price.upfrontTaxes',
    installment: '$.price.installment',
    installmentTaxes: '$.price.installmentTaxes',
    installmentPromo: '$.price.installmentPromo',
    installmentPromoTaxes: '$.price.installmentPromoTaxes',
    upfrontPromo: '$.price.upfrontPromo',
    upfrontTaxesPromo: '$.price.upfrontTaxesPromo',
    devicesUpfrontPromo: '$.price.devicesUpfrontPromo',
    devicesUpfrontTaxesPromo: '$.price.devicesUpfrontTaxesPromo',
    devicesUpfront: '$.price.devicesUpfront',
    devicesUpfrontTaxes: '$.price.devicesUpfrontTaxes',
    singlePayment: '$.price.singlePayment',
    singlePaymentTaxes: '$.price.singlePaymentTaxes',
    singlePaymentPromo: '$.price.singlePaymentPromo',
    singlePaymentTaxesPromo: '$.price.singlePaymentTaxesPromo',
    deviceFinalPayment: '$.price.deviceFinalPayment',
    deviceFinalPaymentTaxes: '$.price.deviceFinalPaymentTaxes',
    finalPaymentMonthlyFee: '$.price.finalPaymentMonthlyFee',
    finalPaymentMonthlyFeeTaxes: '$.price.finalPaymentMonthlyFeeTaxes',
    shippingCosts: '$.shippingCosts',
    fromActivated: '$.shippingCosts.fromActivated',
    postalCode: '$.shippingCosts.postalCode',
    upfrontShippingCosts: '$.shippingCosts.upfront',
    upfrontPromoShippingCosts: '$.shippingCosts.upfrontPromo',
    upfrontTaxesShippingCosts: '$.shippingCosts.upfrontTaxes',
    upfrontTaxesPromoShippingCosts: '$.shippingCosts.upfrontTaxesPromo',
    ecode: '$.ecode'
  },
  CriteriaDto: {
    checkoutCriteriaDto: '$.checkoutCriteriaDto',
    deliveryType: '$.deliveryType',
    postalCode: '$.postalCode',
    bankAccount: '$.bankAccount',
    paymentTypeId: '$.paymenttypeid'
  },
  CartItemsCommercial: {
    cartItems: '$.cartItems',
    cartItemId: '$.cartItemId',
    price: '$.price'
  },
  ServicesItemsCommercial: {
    services: '$.services',
    itemId: '$.itemId',
    catalogElementType: '$.catalogElementType',
    productHierarchyType: '$.productHierarchyType',
    code: '$.code',
    tax: '$.tax',
    price: '$.price',
    detail: '$.detail',
    siteId: '$.siteId',
    additionalLine: '$.detail.additionalLine',
    agentOffer: '$.detail.agentOffer',
    catalogOffer: '$.detail.catalogOffer',
    clientType: '$.detail.clientType',
    contractType: '$.detail.contractType',
    coverageTechnology: '$.detail.coverageTechnology',
    crossedOfferId: '$.detail.crossedOfferId',
    description: '$.detail.description',
    idesc: '$.detail.idesc',
    manualProvision: '$.detail.manualProvision',
    name: '$.detail.name',
    nameList: '$.detail.nameList',
    numberLine: '$.detail.numberLine',
    offerId: '$.detail.offerId',
    os: '$.detail.os',
    permanenceDuration: '$.detail.permanenceDuration',
    plusLine: '$.detail.plusLine',
    rateType: '$.detail.rateType',
    rateTypeMapeo: '$.detail.rateTypeMapeo',
    registerType: '$.detail.registerType',
    registerTypeName: '$.detail.registerTypeName',
    sap: '$.detail.sap',
    sceneType: '$.detail.sceneType',
    serviceItemType: '$.detail.serviceItemType',
    serviceItemTypeName: '$.detail.serviceItemTypeName',
    shopType: '$.detail.shopType',
    symmetricSpeed: '$.detail.symmetricSpeed',
    promotions: '$.detail.promotions',
    brand: '$.detail.brand',
    canon: '$.detail.canon',
    canonTaxes: '$.detail.canonTaxes',
    capacity: '$.detail.capacity',
    cash: '$.detail.cash',
    cashTaxes: '$.detail.cashTaxes',
    color: '$.detail.color',
    manufacturedType: '$.detail.manufacturedType',
    months: '$.detail.months',
    paymentType: '$.detail.paymentType',
    permanencePenalty: '$.detail.permanencePenalty',
    permanencePenaltyTaxes: '$.detail.permanencePenaltyTaxes',
    terminalType: '$.detail.terminalType',
    promotedCanon: '$.detail.promotedCanon',
    finalPaymentNumberMonths: '$.detail.finalPaymentNumberMonths',
    packageText: '$.detail.packageText',
    packageImage: '$.detail.packageImage',
    asociatedTariff: '$.detail.asociatedTariff',
    associatedLine: '$.detail.associatedLine',
  },
  CommercialListTerminals: {
    ecode: '$.ecode',
    playlist: '$.plalylist',
    taxType: '$.taxType',
    taxValue: '$.taxValue',
    msisdn: '$.msisdn',
    cartItemId: '$.cartItemId'
  },
  CommercialTerminal: {
    terminal: '$.items',
    id: '$.id',
    type: '$.type',
    nombre: '$.nombre',
    orden: '$.orden',
    ordenCuotaMensual: '$.ordenCuotaMensual',
    ordenPagoInicial: '$.ordenPagoInicial',
    commercialTerminalList: '$.listTerminals',
    isPrioritary: '$.isPrioritary',
  },
  CommercialTerminalList: {
    listTerminals: '$.items.listTerminals',
    idGrupo: '$.idGrupo',
    ordenGrupo: '$.ordenGrupo',
    sap: '$.sap',
    color: '$.color',
    codigoColor: '$.codigoColor',
    capacidad: '$.capacidad',
    valor: '$.capacidadValorUnidad.valor',
    unidad: '$.capacidadValorUnidad.unidad',
    imagenListado: '$.imagenListado',
    textoImagenListado: '$.textoImagenListado',
    modelo: '$.modelo',
    so: '$.so',
    altImagenSo: '$.altImagenSo',
    analyticsCategoryType: '$.analyticsCategoryType',
    marca: '$.marca',
    idMarca: '$.idMarca',
    catalogOffer: '$.catalogOffer',
    nombreTarifa: '$.nombreTarifa',
    exclusivoVodafone: '$.exclusivoVodafone',
    listaPromos: '$.listaPromos',
    idPromo: '$.listaPromos.id',
    textoNoCobroCanon: '$.textoNoCobroCanon',
    idTerminal: '$.idTerminal',
    idTipoTerminal: '$.idTipoTerminal',
    limitado: '$.limitado',
    crossedOfferOriginal: '$.crossedOfferOriginal',
    semaphore: '$.semaphore',
    colorSemaphore: '$.semaphore.color',
    textSemaphore: '$.semaphore.text',
    financingOptions: '$.multiFinancingDevice.financingOptions',
    mainCharacteristics: '$.mainCharacteristics',
    isPrioritary: '$.isPrioritary',
    techDeviceType: '$.techDeviceType',
    accelerator: '$.itemStock.accelerator',
    presale: '$.itemStock.presale',
    unavailable: '$.itemStock.unavailable',
  },
  CommercialFinancingOption: {
    numMeses: '$.numMeses',
    flagDefaultMonthly: '$.flagDefaultMonthly',
    payments: '$.payments'
  },
  CommercialPayment: {
    canonConImpuesto: '$.canonConImpuesto',
    canonSinImpuesto: '$.canonSinImpuesto',
    crossedOfferOriginal: '$.crossedOfferOriginal',
    offerType: '$.offerType',
    offerOrigin: '$.offerOrigin',
    cuotaMensualConImpuesto: '$.cuotaMensualConImpuesto',
    cuotaMensualSinImpuesto: '$.cuotaMensualSinImpuesto',
    idPrecio: '$.idPrecio',
    pagoAlContadoConImpuesto: '$.pagoAlContadoConImpuesto',
    pagoAlContadoSinImpuesto: '$.pagoAlContadoSinImpuesto',
    pagoInicialConImpuesto: '$.pagoInicialConImpuesto',
    pagoInicialSinImpuesto: '$.pagoInicialSinImpuesto',
    paymentType: '$.paymentType',
    flagDefaultPayment: '$.flagDefaultPayment',
    flagPaymentOptionFp: '$.flagPaymentOptionFp',
    flagFinalPayment: '$.finalPayment.flagFinalPayment',
    flagOptionsRefinancingFp: '$.finalPayment.flagOptionsRefinancingFp',
    deviceFinalPaymentTaxes: '$.finalPayment.deviceFinalPaymentTaxes',
    deviceFinalPayment: '$.finalPayment.deviceFinalPayment',
    permanenceDuration: '$.permanenceDuration',
    permanencePenalty: '$.permanencePenalty',
    permanencePenaltyTaxes: '$.permanencePenaltyTaxes',
    optionsRefinancingFp: '$.finalPayment.optionsRefinancingFp',
    pvpTotal: '$.pvpTotal',
    pvpTotalTaxes: '$.pvpTotalTaxes'
  },
  CommercialRefinancingOption: {
    numberMonths: '$.finalPaymentNumberMonths',
    monthlyFeeTaxes: '$.finalPaymentMonthlyFeeTaxes',
    monthlyFee: '$.finalPaymentMonthlyFee'
  },
  CommercialTerminalDetail: {
    nombre: '$.nombre',
    id: '$.id',
    type: '$.type',
    taxType: '$.taxType',
    taxValue: '$.taxValue',
    listTerminals: '$.listTerminals'
  },
  CommercialTerminalDetailList: {
    terminales: '$.listTerminals',
    listaIco: '$.listaIconos',
    listaPromos: '$.listaPromos',
    ordenGrupo: '$.ordenGrupo',
    idTerminal: '$.idTerminal',
    idTipoTerminal: '$.idTipoTerminal',
    idGrupo: '$.idGrupo',
    sap: '$.sap',
    color: '$.color',
    codigoColor: '$.codigoColor',
    capacidad: '$.capacidad',
    unidad: '$.capacidadValorUnidad.unidad',
    valor: '$.capacidadValorUnidad.valor',
    characteristics: '$.characteristics',
    modelo: '$.modelo',
    imagenFrontal: '$.imagenFrontalMovil',
    textoImagenFrontal: '$.textoImagenFrontalMovil',
    imagenTrasera: '$.imagenTraseraMovil',
    textoImagenTrasera: '$.textoImagenTraseraMovil',
    imagenLateral: '$.imagenLateralMovil',
    textoImagenLateral: '$.textoImagenLateralMovil',
    imagenMedidas: '$.imagenMedidasMovil',
    textoImagenMedidas: '$.textoImagenMedidasMovil',
    imagenFrontalTablet: '$.imagenFrontalTablet',
    textoImagenFrontalTablet: '$.textoImagenFrontalTablet',
    imagenTraseraTablet: '$.imagenTraseraTablet',
    textoImagenTraseraTablet: '$.textoImagenTraseraTablet',
    imagenLateralTablet: '$.imagenLateralTablet',
    textoImagenLateralTablet: '$.textoImagenLateralTablet',
    imagenMedidasTablet: '$.imagenMedidasTablet',
    textoImagenMedidasTablet: '$.textoImagenMedidasTablet',
    textoIcono: '$.listaIconos.textoIcono',
    imagenIcono: '$.listaIconos.imagenIcono',
    so: '$.so',
    imagenSo: '$.imagenSo',
    marca: '$.marca',
    idMarca: '$.idMarca',
    limitado: '$.limitado',
    selected: '$.selected',
    section: '$.section',
    icon: '$.icon',
    items: '$.items',
    name: '$.name',
    value: '$.value',
    description: '$.descripcion',
    itemStock: '$.itemStock',
    visible: '$.itemStock.visible',
    presale: '$.itemStock.presale',
    unavailable: '$.itemStock.unavailable',
    stock: '$.itemStock.stock',
    ventaSinStock: '$.itemStock.ventaSinStock',
    presaleFrontText: '$.itemStock.presaleFrontText',
    stockFicticio: '$.itemStock.stockFicticio',
    superaUmbralFicticio: '$.itemStock.superaUmbralFicticio',
    unavailableFicticio: '$.itemStock.unavailableFicticio',
    financingOptions: '$.multiFinancingDevice.financingOptions',
    mainCharacteristics: '$.mainCharacteristics',
    code: '$.code',
    desc: '$.desc',
    order: '$.order',
    sortMethod: '$.sortMethod',
    sortType: '$.sortType',
    extendedValue: '$.extendedValue',
    standardValue: '$.standardValue',
    unit: '$.unit',
    categoryType: '$.categoryType',
    ecoRatingImage: '$.ecoRatingImage',
    ecoRatingText: '$.ecoRatingText',
    promotedCanon: '$.promotedCanon',
    analyticsCategoryType: '$.analyticsCategoryType',
    isPrioritary: '$.isPrioritary',
    techDeviceType: '$.techDeviceType',
    insuranceByDefault: '$.insuranceByDefault'
  },
  CommercialTerminalDetailPromosList: {
    promoAdded: '$.added',
    promoType: '$.type',
    promoId: '$.id',
    promoInternalName: '$.internalName',
    promoPublicName: '$.publicName',
    promoStartDate: '$.startDate',
    promoEndDate: '$.endDate',
    promoStateType: '$.stateType',
    promoFlagRegional: '$.flagRegional',
    promoManualProvision: '$.manualprovision',
    promoDiscountType: '$.discountType',
    promoCodigoOferta: '$.codigoOfertaCruzada',
    promoEvuluationOrder: '$.evaluationOrder',
    promoShortText: '$.shortText',
    promoImage: '$.urlFileImage',
    promoLongText: '$.longText',
    promoLlbbText: '$.llbbText',
    promoLlbbUrl: '$.llbbUrl',
    listIcon: '$.listIcon',
    detailIcon: '$.detailIcon',
    urlEmbeddedVideo : '$.urlEmbeddedVideo',
    urlImagePreviewVideo : '$.urlImagePreviewVideo',
    promotionTitle : '$.promotionTitle',
    detailPromotion : '$.detailPromotionText',
    cardColor : '$.cardColor',
  },
  CommercialPackageText: {
    callsIncludedDetailList: '$.callsIncludedDetailList',
    callsIncludedFicha: '$.callsIncludedFicha',
    callsIncludedList: '$.callsIncludedList',
    callsIncludedSecondFicha: '$.callsIncludedSecondFicha',
    callsIncludedSecondList: '$.callsIncludedSecondList',
    callsList: '$.callsList',
    dataDetailFicha: '$.dataDetailFicha',
    dataDetailList: '$.dataDetailList',
    dataIncludedDetailList: '$.dataIncludedDetailList',
    dataIncludedFicha: '$.dataIncludedFicha',
    dataIncludedList: '$.dataIncludedList',
    dataIncludedSecondFicha: '$.dataIncludedSecondFicha',
    dataIncludedSecondList: '$.dataIncludedSecondList',
    dataList: '$.dataList',
    dataSpeedFicha: '$.dataSpeedFicha',
    dataSpeedList: '$.dataSpeedList',
    dataSpeedCard: '$.dataSpeedCard',
    htmlTextInfo: '$.htmlTextInfo',
    htmlTextRateTable: '$.htmlTextRateTable',
    htmlTextTicketMobile: '$.htmlTextTicketMobile',
    otherDetailList: '$.otherDetailList',
    otherList: '$.otherList',
    roamingList: '$.roamingList',
  },
  CommercialPackageImage: {
    altCallsIncludedIcon: '$.altCallsIncludedIcon',
    altDataIncludedIcon: '$.altDataIncludedIcon',
    altImageIcon: '$.altImageIcon',
    altOtherIcon: '$.altOtherIcon',
    altRoamingIcon: '$.altRoamingIcon',
    desktopCallsIncludedIcon: '$.desktopCallsIncludedIcon',
    desktopDataIncludedIcon: '$.desktopDataIncludedIcon',
    desktopOtherIcon: '$.desktopOtherIcon',
    desktopRoamingIcon: '$.desktopRoamingIcon',
    mobileCallsIncludedIcon: '$.mobileCallsIncludedIcon',
    mobileDataIncludedIcon: '$.mobileDataIncludedIcon',
    mobileOtherIcon: '$.mobileOtherIcon',
    mobileRoamingIcon: '$.mobileRoamingIcon',
    rateImageIcon: '$.rateImageIcon',
    rateImageIconFicha: '$.rateImageIconFicha',
    tabletCallsIncludedIcon: '$.tabletCallsIncludedIcon',
    tabletDataIncludedIcon: '$.tabletDataIncludedIcon',
    tabletOtherIcon: '$.tabletOtherIcon',
    tabletRoamingIcon: '$.tabletRoamingIcon',
  },
  CommercialPlayList: {
    ecode: '$.ecode',
    playlists: '$.playlists',
    name: '$.name',
    code: '$.code',
    order: '$.order',
    type: '$.type',
    items: '$.items',
    flgAutoComplete: '$.flgAutoComplete'
  },
  AllowedServices: {
    allowedServices: '$.allowedServices',
    serviceId: '$.serviceId',
    value: '$.value',
    code: '$.code',
    productType: '$.productType',
    serviceType: '$.serviceType',
    serviceName: '$.serviceName',
    packageName: '$.packageName',
    allowed: '$.allowed',
    origin: '$.origin',
    registerType: '$.registerType',
    itemId: '$.itemId'
  },
  EverythingOkStatus: {
    issuesName: 'OpenIssues'
  },
  AditionalLine: {
    name: '$.name',
    code: '$.coderate',
    fee: '$.price',
    netFee: '$.pricenet',
    discount: '$.discount',
    netDiscount: '$.discountnet',
    totalFee: '$.totalFee',
    totalNetFee: '$.totalNetFee',
    mainDescription: '$.maindescription',
    discountDescription: '$.discountdescription',
    tariffDescription: '$.maindescription',
    summaryFeatures: '$.summaryfeatures',
    detailFeatures: '$.detailFeatures',
    speedIcon: '$.speedicon',
    summaryfeaturesData: '$.summaryfeaturesData',
    summaryfeaturesVoice: '$.summaryfeaturesVoice',
    secundaryDescription: '$.secundarydescription'
  },
  Canje: {
    banner_title: '$.banner_title',
    banner_description: '$.banner_description',
    banner_img: '$.banner_img',
    entryPoint_img: '$.entryPoint_img',
    link_description: '$.link_description',
    experienceName: '$.experienceName',
    link: '$.link',
    reco_title: '$.reco_title',
    reco_promo: '$.reco_promo',
    reco_mobile_1: '$.reco_mobile_1',
    reco_mobile_2: '$.reco_mobile_2',
    reco_description: '$.reco_description',
    reco_CTA: '$.reco_CTA',
    reco_CTA_2: '$.reco_CTA_2',
    ticket_title: '$.ticket_title',
    ticket_description: '$.ticket_description',
    reco_mobile_3: '$.reco_mobile_3'
  },
  SpecialPromotion: {
    modal_title: '$.modal_title',
    modal_subtitle: '$.modal_subtitle',
    modal_img: '$.modal_img',
    modal_link_text: '$.modal_link_text',
    path: '$.path',
    segment: '$.segment',
    promoName: '$.promoName',
    promoSeg: '$.promoSeg',
    promoPlan: '$.promoPlan',
    colorAspa: '$.colorAspa',
    buttonType: '$.buttonType',
    buttonInverse: '$.buttonInverse',
    modal_link_text2: '$.modal_link_text2',
    numCTC2: '$.numCTC2',
    buttonType2: '$.buttonType2',
    buttonInverse2: '$.buttonInverse2',
    alignContentTop: '$.alignContentTop',
    entryPointTitle: '$.entryPointTitle',
    journeyName: '$.journeyName',
    numCTC: '$.numCTC',
    path2: '$.path2',
    webview: '$.webview',
    webview2: '$.webview2',
    modal_img_rsp: '$.modal_img_rsp',
    experience_name: '$.promoName',
    controlGroup: '$.controlGroup'
  },
  Dmp: {
    animation: '$.animation',
    bgColor: '$.bgColor',
    buttonType: '$.buttonType',
    controlGroup: '$.controlGroup',
    CTAbgColor: '$.CTAbgColor',
    CTAtextColor: '$.CTAtextColor',
    ctcNum: '$.ctc.num',
    ctcText: '$.ctc.text',
    ctcTextCTA: '$.ctc.textCTA',
    ctcTitle: '$.ctc.title',
    deeplink: '$.deeplink',
    design: '$.design',
    entryPointTitle: '$.entryPointTitle',
    entryPointLocation: '$.entryPointLocation',
    experienceName: '$.experienceName',
    gradient: '$.gradient',
    image: '$.image',
    image_rsp: '$.image_rsp',
    imageTitle: '$.imageTitle',
    imageTitle_rsp: '$.imageTitle_rsp',
    journeyName: '$.journeyName',
    nameCTA: '$.nameCTA',
    padding: '$.padding',
    promo: '$.promo',
    text: '$.text',
    textCTA: '$.textCTA',
    title: '$.title',
    webviewlink: '$.webviewlink',
    image_right: '$.image_right',
    image_right_rsp: '$.image_right_rsp',
    padding_image_right: '$.padding_image_right',
    mode: '$.mode'
  },
  PurchaseProductsCoutes: {
    items: '$.items',
    type: '$.type',
    name: '$.name',
    desc: '$.desc',
    terminationDate: '$.terminationDate',
    isBundle: '$.isBundle',
    amount: '$.amount',
    currency: '$.currency',
    taxesCode: '$.taxesCode',
    netAmountDue: '$.netAmountDue',
    grossAmountDue: '$.grossAmountDue',
    additionalCharges: '$.additionalCharges',
    aggregatedAmount: '$.aggregatedAmount'
  },
  CampaignPromotion: {
    billsAttr: '$.billsAttr',
    attrs: '$.attrs',
    moreInfoAttrs: '$.moreInfoAttrs',
    alertInfoAttr: '$.alertInfoAttr',
    serialNumber: '$.serialNumber',
    brandName: '$.brandName',
    name: '$.name',
    promoCondition: '$.promoCondition',
    promoConditionHighlight: '$.promoConditionHighlight',
    shortDescription: '$.shortDescription',
    legalConditionsText: '$.legalConditionsText',
    legalConditionsLinkText: '$.legalConditionsLinkText',
    imagenPrp: '$.imagenPrp',
    imagenDetll: '$.imagenDetll',
    color: '$.color',
    buttonText: '$.buttonText',
    moreInfoTitle: '$.moreInfoTitle',
    ticketDescription: '$.ticketDescription'
  },
  DeliveryDates: {
    xCanal: '$.xCanal',
    xTipoCliente: '$.xTipoCliente',
    xPerfil: '$.xPerfil',
    cdTipoProceso: '$.xTipoProceso.cdTipoProceso',
    dsTipoProceso: '$.xTipoProceso.dsTipoProceso',
    horasEntrega: '$.horasEntrega',
    idModalidadEntrega: '$.idModalidadEntrega',
    idTipoEntrega: '$.idTipoEntrega',
    cdTipoDeliveryType: '$.cdTipoDeliveryType',
    xOpcEntrega: '$.xOpcEntrega',
    nombreModalidadEntrega: '$.nombreModalidadEntrega',
    dsHoraEntrega: '$.dsHoraEntrega',
    dsOpcEntrega: '$.dsOpcEntrega',
    xIdentificacion: '$.xIdentificacion',
    xPrioridadEntrega: '$.xPrioridadEntrega',
    xCosteOpcEntrega: '$.xCosteOpcEntrega',
    eurPrecio: '$.xCosteOpcEntrega.eurPrecio',
    eurPrecioPromocion: '$.xCosteOpcEntrega.eurPrecioPromocion',
    eurPrecioTaxes: '$.xCosteOpcEntrega.eurPrecioTaxes',
    eurPrecioPromocionTaxes: '$.xCosteOpcEntrega.eurPrecioPromocionTaxes',
    cdCatalogOfferCharge: '$.xCosteOpcEntrega.cdCatalogOfferCharge',
    dsCatalogOfferChargeName: '$.xCosteOpcEntrega.dsCatalogOfferChargeName',
    euCatalogOfferCharge: '$.xCosteOpcEntrega.euCatalogOfferCharge',
    deliveryDates: '$.fechasEntrega',
    deliveryDate: '$.fechaEntregaPedido',
    portabilityDate: '$.fechasPortabilidad',
    fechaPortabilidad: '$.fechaPortabilidad',
    xDiaEntrega: '$.xDiaEntrega',
    xHoraLiberacion: '$.xHoraLiberacion',
    xSlaFraude: '$.xSlaFraude',
  },
  DeliveryTypes: {
    deliveryTipes: '$.destinosCostesEntrega',
    idType: '$.idTipoEntrega',
    cdType: '$.cdTipoDeliveryType',
    price: '$.xCoste.eurPrecio',
    priceTax: '$.xCoste.eurPrecioTaxes',
    itMarcadoDefault: '$.itMarcadoDefault',
    nmOrden: '$.nmOrden',
    title: '$.nombrePubDestEntrega',
    description: '$.descrDestinoEntrega',
    xOpcEntrega: '$.xOpcEntrega[0]'
  },
  PurchaseProductsSVAs: {
    items: '$.items',
    code: '$.code',
    category: '$.category',
    name: '$.name',
    amount: '$.amount',
    currency: '$.currency',
    taxesCode: '$.taxesCode',
    netAmountDue: '$.netAmountDue',
    grossAmountDue: '$.grossAmountDue',
    taxesDue: '$.taxesDue',
    amountCredited: '$.amountCredited',
    chargedFor: '$.chargedFor',
    type: '$.type',
    discount: '$.discount',
    terminationDate: '$.terminationDate',
    sba: '$.sba',
    subscription: '$.subscription',
    id: '$.id',
    resumeIcon: '$.resumeIcon',
    info: '$.info',
    longDesc: '$.desc.long',
    shortDesc: '$.desc.short',
    additionalInfo: '$.additionalInfo',
    deviceName: '$.deviceName',
    packagesDesc: '$.packagesDesc',
    detailIcon: '$.detailIcon',
    solucionProfesional: '$.solucionProfesional',
    status: '$.status',
    typeOnePlus: '$.typeOnePlus'
  },
  PostalAddress: {
    type: '$.type',
    name: '$.name',
    addressNumber: '$.addressNumber',
    floor: '$.floor',
    door: '$.door',
    zipCode: '$.zipCode',
    province: '$.province',
    town: '$.town',
    verticalId: '$.verticalId',
    notNormalizedAllowed: '$.notNormalizedAllowed'
  },
 ChangeAddress: {
    provinceDD: '$.ProvinceDD',
    id: '$.id',
    name: '$.name',
    code: '$.code',
    prefixes: '$.prefixes',
  },
  StoreCoordinates: {
    city: '$.city',
    region: '$.region',
    province: '$.province',
    country: '$.country',
    latitude: '$.latitude',
    longitude: '$.longitude',
  },
  DeliveryStore: {
    tiendas: '$.tiendas',
    sfid: '$.sfid',
    nombreTienda: '$.nombreTienda',
    distanciaPuntoRef: '$.distanciaPuntoRef',
    direccion: '$.direccion',
    horaInicio: '$.horaInicio',
    horaFin: '$.horaFin',
    diaInicio: '$.diaInicio',
    diaFin: '$.diaFin',
    horaInicioJornadaPartida: '$.horaInicioJornadaPartida',
    horaFinJornadaPartida: '$.horaFinJornadaPartida',
    codigoPostal: '$.codigoPostal',
    latitud: '$.latitud',
    longitud: '$.longitud',
    localidad: '$.localidad',
    cdAlmacen: '$.cdAlmacen',
    provincia: '$.provincia',
    telefono: '$.telefono'
  },
  DeliveryOffice: {
    oficinasCorreos: '$.oficinasCorreos',
    acceso: '$.acceso',
    ccaa: '$.ccaa',
    codHorario: '$.codHorario',
    codProvincia: '$.codProvincia',
    codigoPostal: '$.codigoPostal',
    codired: '$.codired',
    coordXETRS89: '$.coordXETRS89',
    coordXWGS84: '$.coordXWGS84',
    coordYETRS89: '$.coordYETRS89',
    coordYWGS84: '$.coordYWGS84',
    direccion: '$.direccion',
    distanciaPuntoRef: '$.distanciaPuntoRef',
    horario: '$.horario',
    id: '$.id',
    latitud: '$.latitud',
    latitudETRS89: '$.latitudETRS89',
    latitudWGS84: '$.latitudWGS84',
    localidad: '$.localidad',
    longitud: '$.longitud',
    longitudETRS89: '$.longitudETRS89',
    longitudWGS84: '$.longitudWGS84',
    nombreUnidad: '$.nombreUnidad',
    provincia: '$.provincia',
    provinciaEs: '$.provinciaEs',
    telefono: '$.telefono'
  },
  entryPoints: {
    code: '$.code',
    group: '$.group',
    order: '$.order',
    entryPoints: '$.entryPoints'
  },
  paymentCard: {
    uuid: '$.uuid',
    documentId: '$.documentID',
    documentType: '$.documentType',
    journey: '$.journey',
    cardMask: '$.cardMask',
    token: '$.token',
    expiryDate: '$.expiryDate',
    savedDate: '$.savedDate',
    lastUsedDate: '$.lastUsedDate',
    principle: '$.principle',
    pagoRecurrente: '$.pagoRecurrente'
  },
  buySim: {
    status: '$.result.status',
    errorList: '$.result.listMessages',
    errorCode: '$.code',
    errorMessage: '$.description',
    shoppingCart: {
      cartItems: '$.data.shoppingcartResponse.cartItems',
      cartItemType: '$.catalogElementType',
      tariffName: '$.detail.name',
      tariffPrice: '$.price.installmentPromoTaxes',
      initialBalance: '$.price.devicesUpfrontTaxesPromo',
      cartItemSerives: '$.services',
      rateCode: '$.code',
      NameSEO: '$.data.dataRateInit.nameSEO',
      htmlPaymentCardData: '$.detail.packageText.htmlTextInfo',
      rechargeamounttaxes: '$.data.shoppingcartResponse.cartItems[0].services[0].price.devicesRechargeAmountTaxes'
    },
    orderId: '$.data.order_id',
    legalTermsListPersonelInfo: '$.data.listAceptos',
    legalTermsListPayment: '$.data.listCdus',
    promotionsList: '$.detail.promotions',
    legalTerm: {
      id: '$.id',
      name: '$.name',
      type: '$.type',
      order: '$.orden',
      internalDescription: '$.internalDescription',
      shortText: '$.shortText',
      htmlText: '$.htmlText'
    },
    promotion: {
      shortDescription: '$.longTextFicha',
      longDescription: '$.detailTextFicha'
    },
    idValidation: {
      message: '$.data.message',
      resultValidation: '$.data.resultValidation'
    },
    address: {
      addressList: '$.results',
      formattedAddress: '$.formatted_address',
      addressComponents: '$.address_components',
      addressComponentType: '$.types[0]',
      addressComponentShortName: '$.short_name',
      horizontalDataList: '$.coverageAddressList',
      horizontalDataItem: {
        number: '$.descriptionNumber',
        stair: '$.descriptionStair',
        floor: '$.descriptionFloor',
        door: '$.descriptionDoor'
      }
    },
    coverageAddressList: {
      type: '$.viaType',
      name: '$.street',
      number: '$.number',
      stairway: '$.stair',
      floor: '$.floorNumber',
      door: '$.gate',
      descriptionNumber: '$.descriptionNumber',
      descriptionStair: '$.descriptionStair',
      descriptionFloor: '$.descriptionFloor',
      descriptionDoor: '$.descriptionDoor',
      postalcode: '$.cp',
      province: '$.provinceName',
      town: '$.city',
      verticalId: '$.verticalId',
      postCodeDelivery: '$.cp',
      hand1: '$.hand1',
      hand2: '$.hand2',
      identificador: '$.identificador',
      block: '$.block',
      duplicate: '$.duplicate'
    },
    deliveryDates: {
      xCosteEur: '$.horasEntrega[0].xCosteOpcEntrega.eurPrecio',
      cdTipoDeliveryType: '$.horasEntrega[0].cdTipoDeliveryType',
      fcEntregaPedido: '$.fechasEntrega[0].fechaEntregaPedido',
      xPerfil: '$.xPerfil',
      xCanal: '$.xCanal',
      xTipoCliente: '$.xTipoCliente',
      xTipoProceso: '$.xTipoProceso.dsTipoProceso',
      cdTipoProceso: '$.xTipoProceso.cdTipoProceso',
      xDestEntrega: '$.horasEntrega[0].cdTipoDeliveryType',
      xCostePromoEur: '$.horasEntrega[0].xCosteOpcEntrega.eurPrecioPromocion',
      xDsOpcEntrega: '$.horasEntrega[0].dsOpcEntrega',
      dsOpcEntrega: '$.horasEntrega[0].dsOpcEntrega',
      xOpcEntrega: '$.horasEntrega[0].xOpcEntrega',
      xIdentificacion: '$.horasEntrega[0].xIdentificacion',
      xPrioridadEntrega: '$.horasEntrega[0].xPrioridadEntrega',
      idModalidadEntrega: '$.horasEntrega[0].idModalidadEntrega',
      idTipoEntrega: '$.horasEntrega[0].idTipoEntrega',
      fcPortabilidad: '$.fechasEntrega[0].fechasPortabilidad[0].fechaPortabilidad',
      xDiaEntrega: '$.fechasEntrega[0].fechasPortabilidad[0].xDiaEntrega',
      xHoraLiberacion: '$.fechasEntrega[0].fechasPortabilidad[0].xHoraLiberacion',
      xSlaFraude: '$.fechasEntrega[0].fechasPortabilidad[0].xSlaFraude'
    },
    idNtolRequest: '$.data.idNtolRequest',
  },
  genericCheckout: {
    steps: '$.steps',
    name: '$.name',
    order: '$.order',
    code: '$.code',
    tipi: '$.tipi'
  },
  fundedDevices: {
    id: '$.financialPlan[0].product[0].id',
    productCharacteristic: '$.financialPlan[0].product[0].productCharacteristic',
    sap: '$.financialPlan[0].product[0].productSpecification.id',
    accountBalance: '$.financialPlan[0].accountBalance',
    paymentPlan: '$.financialPlan[0].paymentPlan',
    relatedParty: '$.relatedParty',
    state: '$.state',
    paymentStatus: '$.paymentStatus',
  },
  nexusProducts: {
    products: '$.products',
    name: '$.name',
    code: '$.code',
    nmOrden: '$.nmOrden',
    category: '$.category',
    productId: '$.productId',
    serviceInstanceList: '$.serviceInstanceList'
  },
  PaymentResponse: {
    transactionId: '$.transactionId',
    codigo: '$.codigo',
    NumOperacionOrigen: '$.NumOperacionOrigen',
    fechaOpOrigen: '$.fechaOpOrigen',
    mensaje: '$.mensaje',
    importe: '$.importe',
    refundCodigo: '$.refundCodigo',
    refundId: '$.refundId',
    refundFecha: '$.refundFecha',
    refundMensaje: '$.refundMensaje',
  },
  GetPayments: {
    paymentMethod: '$.paymentMethod',
    idTypePayment: '$.idTypePayment',
    nameMethodPayment: '$.nameMethodPayment',
    descriptionMethodPayment: '$.descriptionMethodPayment'
  },
  nexusDmp: {
    cardTitle: '$.cardTitle',
    cardDesc: '$.cardDesc',
    cardImgDesktop: '$.cardImgDesktop',
    cardImgMobile: '$.cardImgMobile',
    cardBackgroundImgDesktop: '$.cardBackgroundImgDesktop',
    cardBackgroundImgMobile: '$.cardBackgroundImgMobile',
    cardBackgroundColour: '$.cardBackgroundColour',
    cardBtnText: '$.cardBtnText',
    cardBtnColour: '$.cardBtnColour',
    deeplink: '$.deeplink',
    order: '$.order',
    controlGroup: '$.controlGroup',
    cardBtnBackground: '$.cardBtnBackground',
    journeyName: '$.journeyName'
  },
  bundellfibra: {
    infoPromoPega: '$.infoPromoPega',
    clientInfo: '$.clientInfo'
  },
  newSuggest: {
    status: '$.status',
    results: '$.results',
    addressComponents: '$.address_components',
    formattedAddress: '$.formatted_address',
    geometry: '$.geometry',
    partialMatch: '$.partial_match',
    types: '$.types',
    shortName: '$.short_name',
    longName: '$.long_name'
  },
  newGeocoder: {
    idLead: '$.idLead',
    orderDateCreation: '$.orderDateCreation',
    coverageAddressList: '$.coverageAddressList',
    error: '$.error',
    code: '$.code',
    message: '$.message',
    horizontalId: '$.horizontalId',
    verticalId: '$.verticalId',
    street: '$.street',
    number: '$.number',
    stair: '$.stair',
    floorNumber: '$.floorNumber',
    gate: '$.gate',
    cp: '$.cp',
    city: '$.city',
    viaType: '$.viaType',
    viaTypeDescription: '$.viaTypeDescription',
    provinceName: '$.provinceName',
    aliasNumber: '$.aliasNumber',
    descriptionNumber: '$.descriptionNumber',
    sinValorNumber: '$.sinValorNumber',
    ordenNumber: '$.ordenNumber',
    aliasStair: '$.aliasStair',
    descriptionStair: '$.descriptionStair',
    sinValorStair: '$.sinValorStair',
    aliasFloor: '$.aliasFloor',
    ordenStair: '$.ordenStair',
    descriptionFloor: '$.descriptionFloor',
    sinValorFloor: '$.sinValorFloor',
    ordenFloor: '$.ordenFloor',
    aliasDoor: '$.aliasDoor',
    descriptionDoor: '$.descriptionDoor',
    sinValorDoor: '$.sinValorDoor',
    ordenDoor: '$.ordenDoor',
    hand1: '$.hand1',
    hand2: '$.hand2',
  }
};
export const PAGES: { [key: string]: string }  = {
  LOGIN: 'login',
  COMMON: 'common',
  PAYMENT: 'payment',
  BILLING_PAGE: 'billing',
  PRODUCT_SERVICE: 'productsServices',
  MY_ACCOUNT: 'myAccount',
  DASHBORD: 'dashboard',
  USAGE: 'usage',
  SERVICESETTINGS: 'serviceSettings',
  MIGRATION: 'migration',
  CANJE: 'canjeTerminales',
  TIENDAV10: '/tienda',
  DEEPLINK: 'deepLink'
};
export const DOCUMENT_TYPES: Record<string, string>[] = [
  { text: 'NIE', value: '1' },
  { text: 'N.I.F.', value: '2' },
  { text: 'Passport', value: '3' }
];

export const MENU_ACTION_TYPE: { [key: string]: string }  = {
  Redirect: 'redirect',
  Command: 'command'
};

export const Validation: ValidationDefineModel = {
  amountNumbersFrom1to9digit: /^([1-9]{1}[0-9]{0,8})$/,
  NIE:
    '^[xyzXYZ]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}$',
  NIF: '^[0-9]{8}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}$',
  Passport: '',
  Numbers: /\d/gi,
  SpecialChars: /[$&+,;=?@#¿\\"\|'_\/<>^.*:)(%!¡-]/gi,
  Char: /[a-zA-Z]/gi,
  CharNoSpace: '^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$',
  AuthorizedPassword: '^[0-9]{6,6}$',
  ConsumerPassword: `^[a-zA-Z0-9$&+,;=?@#¿\\"\|'_\/<>^.*:)(%!¡-]{6,18}$`,
  currentPasswordPattern: '^[a-zA-Z0-9\\s!@#$%^&*)(+=._-]{8,18}$',
  licencesPattern: '^[a-zA-Z0-9)(+=._-]{2,18}$',
  MobileNumber: '^(6|7|9)([0-9]{8})$',
  Email:
    '^[_A-Za-z0-9-+]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(\.{1}[A-Za-z]{2,})$',
  emailCommercial: '^(([^<>()\\[\\]\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\.,;:\\s@"]+)*))@((([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))+$',
  emailPoint: '/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i',
  emailSimple: /^[a-z0-9._\-]+@[a-z0-9._\-]+\.([a-z])+$/,
  emailCheckChar: /^[a-z0-9.@_\-]+$/,
  emailCheckValidation: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
  emailBuySimPersonalInfo: /^(?!.*[À-ž])(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  unlockImei: '^[0-9]{15}$',
  euroCurrency: '^[0-9]*((.|,)[0-9]{1,2})?',
  CreditCardNumber: '^(4|5)([0-9]{15})$',
  HolderName: '[^0-9\n]{1,100}',
  DayAndMonth: '^[0-9]*$',
  CCV: '^[0-9]{3}',
  numberOnly: '^[0-9]*$',
  msisdn: '^(6|7)([0-9]{8})$',
  msisdnAndFixed: '^(6|7)([0-9]{8})$',
  ICCIDValidator: '^(8934)([0-9]{15})$',
  phone: '^([6-7])([0-9]{8})$',
  phoneNumber: /^[6789]{1}[0-9]{8}$/,
  landLine: '^([8-9])([0-9]{8})$',
  LettersAndNumbersOnly: '^[A-Za-z0-9]*$',
  lettersAndSpaceOnly: '^[A-Za-z ]+$',
  userNameValidation: '(^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+(\\.[A-Za-z]{2,})$)|(^[a-zA-Z0-9]*$)',
  otpPattern: '^[0-9]{6,14}$',
  bonitaTemp2phoneValidation: '^([6-9])([0-9]{8})$',
  Dni: '^([0-9]{8}[A-Za-z])|[XYZxyz][0-9]{7}[A-Za-z]$',
  DNISimple: /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/,
  AlphaValues: '^[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð \- \']+$',
  lettersAndHyphens: '^[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð \-]+$',
  zipCode: '^(?:0[1-9]|[1-4]\\d|5[0-2])\\d{3}$',
  NIFNIE: '^([xyzXYZ]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}$)' +
    '|([0-9]{8}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}$)',
  numbersAndCommaOnly: '^[0-9][0-9]*([.,][0-9]*)?$',
  NIESimple: /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/,
  dniOrNie: '^([xyzXYZ]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlcketTRWAGMYFPDXBNJZSQVHLCKET]{1}$)' +
    '|(([0-9]{8}[A-Za-z])|[XYZxyz][0-9]{7}[A-Za-z]$)',
  date: '/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/',
  dateRexp: /^([0-2][0-9]|3[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
  fiveDigits: '^[0-9]{5}',
  Domain: '^((?!-)[A-Za-z0-9-]'
    + '{1,63}(?<!-)\\.)'
    + '+[A-Za-z]{2,6}',
  Url: '^(https?|ftp)://((?!-)[A-Za-z0-9-]'
    + '{1,63}(?<!-)\\.)'
    + '+[A-Za-z]{2,6}',
  validChars: 'TRWAGMYFPDXBNJZSQVHLCKET',
  nifRexp: /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i,
  nieRexp: /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i,
  buySimPassport: '^[A-Za-z0-9]{6,}$',
  emptyString: '^\\s+$|^$',
  spanishName: '^[A-Za-zÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýžÅÆÉØåæéøÉËÏÓÖÜéëïóöüÄÅÖäåöÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸàâæçéèêëïîôœùûüÿÄÖÜẞäöüß' +
    'ÁÉÍÖÓŐÜÚŰáéíöóőüúűÁÆÐÉÍÓÖÞÚÝáæðéíóöþúýÀÈÉÌÒÓÙàèéìòóùÅÆÂÉÈÊØÓÒÔåæâéèêøóòôĄĆĘŁŃÓŚŹŻąćęłńóśźżÃÁÀÂÇÉÊÍÕÓÔÚÜãáàâçéêíõóôúü' +
    'ĂÂÎŞȘŢȚăâîşșţțÁÉÍÑÓÚÜáéíñóúüÄÅÉÖäåéöÂÇĞIİÎÖŞÜÛâçğıİîöşüû .:\'-]{1,20}$',
  strongPassword: /(?!.*\s)(?=.{6,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$&+,;=?@#¿\\|'_"/<>^.*:()%!¡-])/g,
  mediumPassword: /^(?!.*\s)(?=.{6,})(((?=.*[a-zA-Z])(?=.*[0-9]))|((?=.*[a-zA-Z])(?=.*[$&+,;=?@#¿\\|'_"/<>^.*:()%!¡-]))|((?=.*[0-9])(?=.*[$&+,;=?@#¿\\|'_"/<>^.*:()%!¡-])))/g,
  auxTagging1: /[\u0300-\u036f]/g,
  auxTagging2: 'NFD',
  comma: /,/g,
  streetAvenueNumber: '([A-Za-záéíóúÁÉÍÓÚñÑ/º, 0-9])*'
};

export enum MyAccountDataValidations {
  name = '^[A-Za-zÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýžÅÆÉØåæéøÉËÏÓÖÜéëïóöüÄÅÖäåöÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸàâæçéèêëïîôœùûüÿÄÖÜẞäöüß' +
  'ÁÉÍÖÓŐÜÚŰáéíöóőüúűÁÆÐÉÍÓÖÞÚÝáæðéíóöþúýÀÈÉÌÒÓÙàèéìòóùÅÆÂÉÈÊØÓÒÔåæâéèêøóòôĄĆĘŁŃÓŚŹŻąćęłńóśźżÃÁÀÂÇÉÊÍÕÓÔÚÜãáàâçéêíõóôúü' +
  'ĂÂÎŞȘŢȚăâîşșţțÁÉÍÑÓÚÜáéíñóúüÄÅÉÖäåéöÂÇĞIİÎÖŞÜÛâçğıİîöşüû .:\'-]{1,20}$',
  email = '^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}',
  postalCode = '^[0-9]{3,5}$',
  phone = '^(6|7)([0-9]{8})$',
  phoneFixed = '^(8|9)([0-9]{8})$'
}

export const YouKnowConst: { youKnow: string } = {
  youKnow: '¿Sabías que...?'
};

export const MyAccountOverViewStatics: { [key: string]: string }  = {
  MyDetails: 'Tus datos',
  MyServices: 'Datos acceso a Mi Vodafone',
  MyContracts: 'Mis contratos',
  PaymentMethods: 'Métodos de pago'
};
export const AppThemes: { [key: string]: string }  = {
  Theme1: 'theme1',
  Theme2: 'theme2',
  Theme3: 'theme3',
  ThemeVf10: 'theme-vf10',
  ThemeBkgWhite: 'theme-background-white',
  ThemeNewLogin: 'new-login-theme',
  // TODO: Delete when squad vf10 upload prod
  ThemeVf10Provisional: 'theme-vf10-provisional',
  ThemeCommercial: 'theme-commercial',
  ThemeGrayBG: 'theme-gray'
};
export const AccesInfo: { [key: string]: string }  = {
  PasswordWeak: 'pass-weak',
  PasswordMedium: 'pass-medium',
  PasswordStrong: 'pass-strong'
};
export const AgreementName: { [key: string]: string }  = {
  parent: 'contrato',
  child: 'sepa'
};
export const ServiceSelector: { [key: string]: string }  = {
  one: 'one',
  paquete: 'paquete',
  Familia: 'Familia',
  Unitario: 'Unitario'
};

export const TariffSegment: { [key: string]: string }  = {
  yu: 'yu',
  xs: 'xs',
  roaming: 'Roaming',
  simple: 'simple',
  topup: 'topup',
  similarYu: 'similarYu'
};

export const PaymentCardJournies: { [key: string]: string }  = {
  topup: 'TOPUP',
  bill: 'BILL'
};

export const specialTypes: { [key: string]: string }  = {
  voice: 'minutes',
  sms: 'sms'
};

export const SSNDB: { [key: string]: string }  = {
  selected: 'selected'
};
export const STORE_URL: { [key: string]: string }  = {
  IOS: 'https://itunes.apple.com/es/app/mi-vodafone/id455655421?mt=8',
  ANDROID:
    'https://play.google.com/store/apps/details?id=es.vodafone.mobile.mivodafone',
  OTHERS: 'http://www.vodafone.es/c/particulares/es/'
};
export const billClass: { [key: string]: string }  = {
  oneServiceClass: 'one-service',
  titleOnly: 'title-only'
};
export const appSetting: { [key: string]: string }  = {
  settings: 'settings'
};
export const menuItemsUrls: { [key: string]: string }  = {
  billing: '/billing'
};
export const billQueryString: BillQueryDefineModel = {
  bill: 'bill',
  deviceInvoice: 'device invoice',
  creditNotes: 'credit note',
  amount: 'amount',
  type: 'type',
  status: {
    ready: 'ready'
  },
  aggregationLevel: 'aggregationLevel',
  billingYear: 'billingCycle.billingYear',
  billingMonth: 'billingCycle.billingMonth',
  billShock: {
    mobile_number: 'mobile_number',
    email: 'email',
    description: 'description'
  },
  settings: 'settings',
  billClarification: 'billClarifications',
  alias: 'alias',
  e_bill: {
    controlName: 'emailOrSMS'
  }
};
export const vovAnimation: { [key: string]: string }  = {
  show: 'show',
  hide: 'hide'
};
export const graphAnimation: { [key: string]: string }  = {
  show: 'show',
  hide: 'hide'
};
export const TV: string = 'Televisión';
export const SidemenuQuery: { [key: string]: string }  = {
  category: 'category',
  parentId: 'parentId',
  status: 'status',
  accountTypes: 'accountTypes',
  serviceTypes: 'serviceTypes',
  items: 'items',
  order: 'order',
  logout: 'logout',
  needBubbleHelp: 'needHelpBubble'
};
export const PINPrPrefix: { [key: string]: string }  = {
  msisdnPrefix: 'tel:+34'
};

export const PromotionStatus: { [key: string]: string }  = {
  inactive: 'inactive'
};
export const hintMessage: string = 'hintMessage';
export const noPromotionName: string = 'DEFAULT';

export const tvPlans: { [key: string]: string }  = {
  Essential: 'Essential',
  Extra: 'Extra',
  Total: 'Total',
  Horeca: 'HORECA'
};
export const EBillKeys: { [key: string]: string }  = {
  activeEBill: 'activeEBill',
  inactiveEBill: 'inactiveEBill',
  activeEmail: 'activeEmail',
  inactiveEmail: 'inactiveEmail',
  activeSMS: 'activeSMS',
  inactiveSMS: 'inactiveSMS',
  activePDF: 'activePDF',
  inactivePDF: 'inactivePDF'
};
export const MyAccountAuthorizedInfo: { [key: string]: string }  = {
  addAuthoriedPermission: '5'
};
export const MyAccountDetails: { [key: string]: string }  = {
  nifUrl: '../nif',
  addressUrl: '../editAddress',
  editUrl: '../editDetails',
  tele: 'tele',
  teleVodafone: 'teleVodafone',
  fixed: 'fixed'
};
export const pathEBillConfigPath: { [key: string]: string }  = {
  alias: '/billingAlias',
  eBill: '/ebillConfigurations',
  editBankAccount: '/paymentMethod/directDebitPayment',
  editBankAddress: '/billingAddress'
};
export const paymentMethod:
  {
    Operations: { replace: string, add: string },
    formControls: { name: string, firstName: string, secondName: string, IBAN: string }
  } = {
  Operations: {
    replace: 'replace',
    add: 'add'
  },
  formControls: {
    name: 'name',
    firstName: 'firstName',
    secondName: 'secondName',
    IBAN: 'IBAN'
  }
};
export const OPERATION_NOT_ALLOWED_STATUS: string = '405';
export const VERIFYEMAIL: VerifyEmailDefinesModel = {
  QUERYPARAMS: 'token',
  OPENMAILIMAGE: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/mail-read-hi-dark.png',
  EXPIEREDERRORSTATUS: 403,
  EXPIEREDERRORECODE: '1301',
  INVALIDERRORECODE: '1300',
  ErrorUserNameExist: '3003',
  ErrorUpdateUsername: '3004',
  ErrorResetPassword: '3005',
  ErrorUserHasTvService: '3006',
  ErrorUserHasPendingOrder: '3007',
  ErrorCanNotDectivateInitialPackage: '3008',
  ErrorUnkownOrder: '3009',
  ErrorRecentOrderFailed: '3012',
  errorInvalidPassword: '1001',
  errorUserBlocked: '1004',
  errorPassword: '1005',
  ErrorActivateExtra: '3010',
  errorRegistration: '1012',
  errorPasswordNotSynced: '1003',
  errorAppUnavilability: '6666',
  siebleErrorInvalidCredentials: '1027',
  INVALIDCREDENTIAL: '1028',
  OLD_PASSWORD_INVALID: '1023',
  TOKEN: 'token',
};
export const BIZTALKERROR: string = '1006';
export const FLAGDEFAULT: string = 'flagDefault';
export const DECO: string = 'deco';
export const ERRORCODES: ErrorCodesDefinesModel = {
  INVALID_TOKEN: 1300,
  CIFP3: '1316',
  AUTHORIZEDP3: '1320',
  P3ERRORCODE: '1315',
  P2ERRORCODE: '1205',
  NOMEERRORCODE: '1201',
  CORPORATENOTALLOWEDMCARE: '1020',
  INVALIDEMAIL: '6011',
  INVALIDMISDIN: '6010',
  NOCONSUMPTIONDETAILS: '6008',
  NOTOPUP: '7002',
  DIGITALSERVICESERROR: '1031',
  OPERATION_NOT_ALLOWED: '5157',
  SEIBELUSERANDFLAGUNAVAILABILITY: '1074',
  CLARIFYUSER: '1073',
  AVAILABLENBAS: 400,
  NEWAVAILABLENBAS: 402,
  EPERROR: 422,
  MIWIFICONGIG: {
    ERROR101: '101',
    ERROR102: '102',
    ERROR103: '103',
    ERROR106: '106',
    ERROR105: '105',
    ERROR104: '104',
    ERROR107: '107',
    ERROR108: '-108'
  },
  VFPORTABILITY: {
    ERROR137: '137'
  },
  PCI_MORTIROLO_ERRORS: {
    ERROR108: '-108'
  },
  NOT_A_PREPAID_SERVICE: '7032',
  PREPAID_SERVICE_HAS_PENDING_ORDERS: '7033',
  PAYMENT_INACTIVE_MSISDN: '7000',
  GENERAL_ERROR_OCCURED: '1007',
  PENDING_MSISDN: '1315',
  REPLICA_MODE_ERROR: '1006',
  ERRORVULCP: '1300', // ERROR VULNERABILIDAD CAPTURE-RESPONSE
  ERRORVULRC: '1300', // ERROR VULNERABILIDAD RESET-CESTA
  WRONG_OTP_ERROR: 1007,
  INVALID_PROMO_CODE: '7037',
  INVALID_RECHARGE_AMOUNT: '7038',
  PROMOTION_NOT_APPLIED: '7039'
};
export const NoBILLS: { [key: string]: string }  = {
  noBillsOnboardingError: '6005',
  billsError: '6006'
};


export const CALENDAR: Record<string, Record<string, string>> = {
  ClassName: {
    disable: 'disabled',
    current: 'current',
    startPoint: 'startpoint',
    startRange: 'startRange',
    startDate: 'startdate',
    endPoint: 'endpoint',
    endRange: 'endRange',
    endDate: 'enddate',
    initial: 'initial',
    red: 'red',
    emptyCell: 'emptycell'
  },
  Operations: {
    remove: 'remove',
    toggle: 'toggle',
    addclass: 'className'
  }
};

export const BundleType: { [key: string]: string }  = {
  inPlan: 'inPlan',
  outOfPlan: 'outOfPlan'
};

export const serviceBalanceType: { [key: string]: string }  = {
  inPlan: 'inPlan',
  outOfPlan: 'outOfPlan'
};

export const notEligable: string = 'noteligible';
export const EnjoyMore: { [key: string]: string }  = {
  wordPass: 'pass'
};
export const PinPuk: PinPukDefinesModel = {
  queryParam: 'subscriptionId',
  noDataErrorStatus: 400,
  expiryDateUnavailableErrorCode: 1316,
  dataUnavailableErrorCode: 1317
};

export const WORDING_FILES: { [key: string]: string }  = {
  defaultWording: 'defaultWording',
  wcs: 'sp'
};

export const AuthorizationList: string[] = [
  'v1/verification/email/',
  'v1/apixoauth2password/oauth2/token',
  'OTPLogin/v1/authorize',
  'OTPLogin/v1/token',
  'es/v1/appSettings/settings',
  'es/v1/impersonation/token',
  'es/v1/customerAccounts/ANONYMOUS/subscriptions',
  'es/v1/customerAccounts/[0-9]+/subscriptions/ANONYMOUS',
  'tmf-api/accountManagement/v4/billingAccount\\?id=',
  'es/customerdebt/v1/customerDebtState\\?id=[0-9]+&relatedParty\\[\\?\\(@.referredType%3D%3D%27Individual%27\\)].id=NIF-[0-9a-zA-Z]+$',
];

export const authorizedNifError: { [key: string]: string }  = {
  status: '409',
  ecode: '1310'
};

export const SEIBEL_USER: { [key: string]: string }  = {
  seibelUserCode: '1026',
  queryString: '?tku',
  deepLink: 'c'
};

export const subscriptionsAndTariffError: { [key: string]: string }  = {
  status: '403',
  ecode: '1319'
};
export const dataConsumptionQuery: { [key: string]: string }  = {
  type: 'type',
  amount: 'amount',
  noItem: 'noItem',
  dataAggrText: 'Datos de',
  basic: 'basic'
};
export const durations: Array<number> = [
  1, // 1 month
  3, // 3 months
  6 // 6 months
];
export const messageTemplateNumber: { [key: string]: string }  = {
  temp1: '1',
  temp2: '2',
  temp3: '3',
  temp4: '4',
  temp5: '5'
};
export const InboxMessagesDetails: InboxMessagesDetailsModel = {
  dateTimeSpliter: 'T',
  tmp2MaxLength: 2,
  tmp5MaxLength: 4
};
export const SwiperSelectedSlide: { [key: string]: string }  = {
  Selected: 'selected'
};
export const TopupPrepaidServiceValidation: Record<string, string> = {
  Msisdn: '^(6|7|9)([0-9]{8,8})$',
  CreditCardNumber: '^(4|5)([0-9]{15})$',
  HolderName: '[^0-9\n]{1,100}',
  DayAndMonth: '^([0-9]{2,2})$',
  CCV: '^[0-9]{3}',
  numberOnly: '^[0-9]*$'
};
export const slideConfig: SlideConfigModel = {
  speed: 200,
  dots: false,
  arrows: false,
  width: '220pt',
  infinite: false,
  slidesToShow: 1,
  centerMode: true,
  slidesToScroll: 1,
  centerPadding: '12%',
  responsive: {
    breakpoint: 500,
    settings: {
      slidesToShow: 1,
      inerrWidth: 100,
      respondTo: 'min'
    }
  },
  mobileFirst: true,
  variableWidth: true
};
export const ConsumptionBoundleType: { [key: string]: string }  = {
  tv: 'tv',
  voice: 'voice',
  data: 'data',
  sms: 'sms',
  dataAggregate: 'dataaggr',
  noItem: 'noItem',
  internetSpeed: 'internetSpeed'
};
export const ConsumptionSpNavigator: { [key: string]: string }  = {
  backColor: '#ececec'
};
export const GetLine: Record<string, string> = {
  getLIneReturn: '——',
  billingServiceLine: '───',
  getOneLine: '▔'
};
export const ESCENARIOS: { [key: string]: string }  = {
  FLAGAUTOINSTALABLE: 'FLAGAUTOINSTALABLE',
  INSTALACIOFECHATECNICO: 'INSTALACIOFECHATECNICO',
  PAUTASACCESOINDIRECTO: 'PAUTASACCESOINDIRECTO',
  SINPUNTOSTOA: 'SINPUNTOSTOA',
  NEW: 'NEW',
  PORTA: 'PORTA'
};
export const selectors: { [key: string]: string }  = {
  dateFilter: 'date-filter',
  typeFilter: 'type-filter'
};
export const AlertId: { [key: string]: string }  = {
  ALRT_1: 'ALRT_1'
};
export const onlineTvPackageCode: { [key: string]: string }  = {
  onlineTvPackageCode: 'TVOBA'
};
export const feeSeperators: FeeSeperatorsModel = {
  TariffCost: {
    placeHolder: ['|', '/'],
    replacement: 'Cada'
  },
  ProductCost: {
    placeHolder: '|',
    replacement: '/'
  },
  LandingSeperators: {
    data: {
      placeHolder: '|',
      replacement: 'por'
    },
    minutes: {
      placeHolder: '|',
      replacement: '/'
    },
    messages: {
      placeHolder: '|',
      replacement: '/'
    }
  }
};

export const staticWords: Record<string, string> = {
  de: 'de',
  en: 'en',
  more: 'más',
  less: 'menos',
  and: 'y'
};
export const portalHTML5: Record<string, string> = {
  portalHTML5: 'portalHTML5/'
};

export const billingAccountQueryParamTypes: Record<string, string> = {
  billConfig: 'billConfig',
  customerDebt: 'customerDebt',
  active: 'active'
};

export const billListQueryParamNames: Record<string, string> = {
  status: 'status'
};

export const billPaymentQueryParamsNames: Record<string, string> = {
  accountStatus: 'accountStatus',
  debtAmount: 'debtAmount',
  readyAmount: 'readyAmount',
  readyDate: 'readyDate',
  readyLink: 'readyLink',
  billAccountId: 'billAccountId'
};

export const BillAccountType: Record<string, string> = {
  directDebitPayment: 'direct-debit-payment',
  bankPayment: 'bank-payment'
};
export const planCodeTypeLimits: Record<string, string[]> = {
  basicLimit: ['TISET', 'PMV14'],
  highLimit: ['DATSH', 'PMSE3']
};

export const rangeSlider: Record<string, string> = {
  unlimited: 'SIN LIMITE'
};
export const upselltv: string = 'UPSELLTV';

export const deepLinkingIdentifiers: Record<string, Record<string, Record<string, string>>> = {
  productsAndServices: {
    specialTariff: {
      specialCallPrices: 'Specialcallprices',
      specialSmsPrices: 'Specialsmsprices'
    },
    specialExtraCategoryList: {
      data: 'Data',
      minutes: 'Minutes',
      sms: 'SMS',
      voiceInternational: 'VoiceInternational',
      entertainment: 'Entertainment',
      listing: 'Listing',
      extraDetails: 'ExtraDetails',
      touristExtraDetails: 'TouristExtraDetails'
    },
    enjoyMorePass: {
      chatPass: 'Chatpass',
      socialPass: 'Socialpass',
      socialPassVol: 'SocialPassVol',
      videoPass: 'Videopass',
      videoPassHD: 'VideoPassHD',
      musicPass: 'Musicpass',
      mapPass: 'MapPass',
      enjoyMore: 'enjoymore'
    },
    prepaidEnjoyMorePass: {
      // value is from the last part of deeplinking URL
      chatPass: 'Chatpass',
      socialPass: 'Socialpass',
      socialPassVol: 'SocialpassVol',
      videoPass: 'Videopass',
      videoHDPass: 'VideoHDpass',
      musicPass: 'Musicpass',
      mapPass: 'Mappass',
      prepaidEnjoyMore: 'Prepaidenjoymore'
    },
    channelDetails: {
      babyTV: 'BabyTV',
      vodafoneLiga: 'VodafoneLiga',
      casaVision: 'CasaVision',
      adults: 'Adults',
      dark: 'Dark',
      football: 'Football',
      extraTVChannelDetails: 'ExtraTVChannelDetails'
    },
    planOnlineTv: {
      onlineTV: 'OnlineTV',
      changeUsername: 'changeusername',
      changePassword: 'changepassword',
      planDetails: 'PlanDetails'
    }
  }
};
export const baseURL: string = '/mves';
export const googleEnterprise: string = 'https://www.google.com/recaptcha/enterprise.js';
export const preferencesAndPermissions: PreferencesAndPermissionsModel = {
  taostMessage: 'Los cambios en tus permisos se han realizado correctamente',
  totalPrefrences: [
    { id: 1, name: 'CV_H9_H20', checked: false, type: 'timeFrame' },
    { id: 2, name: 'CV_H9_H14', checked: false, type: 'timeFrame' },
    { id: 3, name: 'CV_H16_H20', checked: false, type: 'timeFrame' },
    { id: 4, name: 'CV_SMS', checked: false, type: 'notifications' },
    { id: 5, name: 'CV_EMAIL', checked: false, type: 'notifications' },
    { id: 6, name: 'CV_CALL', checked: false, type: 'notifications' },
    { id: 7, name: 'CV_MVFMF', checked: false, type: 'notifications' },
    { id: 8, name: 'CV_OC', checked: false, type: 'topics' },
    { id: 9, name: 'CV_MSVC', checked: false, type: 'topics' },
    { id: 10, name: 'CV_ND', checked: false, type: 'topics' },
    { id: 11, name: 'CV_APP_SVC', checked: false, type: 'topics' },
    { id: 12, name: 'CV_MUS', checked: false, type: 'contactTopics' },
    { id: 13, name: 'CV_CNTV', checked: false, type: 'contactTopics' },
    { id: 14, name: 'CV_DP', checked: false, type: 'contactTopics' },
    { id: 15, name: 'CV_GAST', checked: false, type: 'contactTopics' },
    { id: 16, name: 'CV_VJ', checked: false, type: 'contactTopics' }
  ]
};
export const MyAccountPermissions: string[] = [
  'DatosLocalizacion',
  'DatosTraficoNavegacion',
  'OfertasOtrasEmpresas',
  'OfertasVodafone',
  'DatosEntidades'
];

export const dataSharingStopPoints: Record<string, number[]> = {
  basic: [500, 1024, 1536, 2048],
  higher: [200, 500, 1024, 1536, 2048, 2560, 3072, 3598]
};

export const basketsIds: Record<string, number> = {
  basket_2000: 2000,
  basket_2501: 2501,
  basket_2502: 2502,
  basket_2500: 2500,
  basket_2503: 2503,
  basket_2504: 2504,
  basket_2505: 2505,
  basket_2506: 2506
};

export const commitmentContractQuery: Record<string, string> = {
  packageCode: 'package.code',
  onoErrorCode: '3000'
};
export const GENERAL_ERROR_CODES: Record<string, number> = {
  REQUEST_TIMEOUT: 408,
  GATEWAY_TIMEOUT: 504,
  NETWORK_READ_TIMEOUT: 598,
  TIMEOUT_OCCURRED: 524,
  GENERAL_ERROR: 500,
  NO_RESPONSE: 444,
  BAD_REQUEST_ERROR_CODE: 400,
  //////////////////////
  NOT_AUTHORIZED: 401, // login
  FORBIDDEN: 403 // upgrad
};
export const iban: string = 'ES10 0049 1500 0229 1008 0785';
export const VFExternalBuisnessWeb: string = 'https://www.vodafone.es/c/empresas/pymes/es/';
export const journeyStatuseEnum: Record<string, string> = {
  start: 'start',
  ok: 'ok',
  ko: 'ko'
};
export const AccountManagementHeader: { name: string, value: string } = {
  name: 'X-VF-API-Process',
  value: 'Multifinancing'
};
export const getUserDeptHeader: { name: string, value: string } = {
  name: 'X-VF-API-Process',
  value: 'GetCustomerDebt'
};
export const getPaymentCommitmentHeader: { name: string, value: string } = {
  name: 'X-VF-API-Process',
  value: 'ConsultaPromesasPago'
};
export const DocumentumHeader: Record<string, string> = {
  name: 'X-DocumentProcess',
  value: 'CreateFileDocumentum'
};
export const ChangePinParentalHeader: Record<string, string> = {
  name: 'ServiceConfigurationProcess',
  value: 'SetUserDynamicData'
};
export const SMAPIheaders: object = {
  'x-vf-trace-source': 'js:com.tsse.spain.myvodafone',    // your assigned trace source
  'x-vf-trace-source-version': '6'   // and assigned version
};
export const SMAPIops: object = {
  configureNetworkMonitoring: true, // set false to disable
  spa: {
    replaceState: true // whether or not to hook history.replaceState
  }
};
export const cachingRegex: string = '{[^}]*}';
export const cachingKeys: Record<string, string> = {
  Products: 'Products',
  Subscriptions: 'Subscriptions',
  GetCustomerAgreements: 'GetCustomerAgreements',
  FetchCustomerAccountById: 'FetchCustomerAccountById',
  FetchBillingAccounts: 'FetchBillingAccounts',
  getPaymentMethods: 'getPaymentMethods',
  Consumption: 'Consumption',
  CurrentSpending: 'CurrentSpending',
  Tariff: 'Tariff',
  GetBills: 'GetBills',
  GetBillDetails: 'GetBillDetails',
  GetServiceUsages: 'GetServiceUsages',
  getCurrentConsumption: 'getCurrentConsumption',
  permissionsAndPreferences: 'permissionsAndPreferences',
  getSIMProfileData: 'getSIMProfileData',
  ServiceSettings: 'ServiceSettings',
  getCallOptions: 'getCallOptions',
  Offers: 'Offers',
  PriceCustomerAccount: 'PriceCustomerAccount'
};

export const HttpRequestMethods: Record<string, string> = {
  get: 'get',
  post: 'post',
  put: 'put',
  patch: 'patch',
  delete: 'delete'
};
export const transactionKey: string = 'vf-trace-transaction-id';

export const sessionTimeout: number = 15;
export const generalEcode: string = '1007';

export const CSRFToken: string = 'vf-csrf-token';
export const ChatPassDetails: Record<string, string[]> = {
  includedApps: [
    'Whatsapp',
    'Telegram',
    'Line',
    'WeChat',
    'Blackberry',
    'Message+'
  ]
};

export enum EditLimitStatus {
  New = 'NEW',
  Pending = 'PENDING',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export const CookieWhiteList: string[] = ['refresh-token', 'bearer-token', 'csrf-token'];

export enum expiredTokenMessages {
  accessToken = 'The access token expired',
  refreshToken = 'The refresh token expired'
}

export const seperatorRegex: RegExp = /\|/g;

export const p2SiteStatuses: string[] = [
  'Suspendido',
  'Pend de Reconectar',
  'Desconectado',
  'Desconectado No Pago'
];

export const appUrlsConfiguration: string =
  'es/v1/appUrlsConfiguration/urls?sourceLink=';
export const logOut: string = 'v1/tokenRevocation/revoke';
export const JWE: string = 'jwe';
export const chatBotToken: string = 'oauth2OnBehalfOf/v1/token';

export const code: string = 'Code';
export const voiceIntCat: string = 'intCat';
// renewal margin is the limit after the renewal date passed after this margin the user has to buy the product again
export const renewalMargin: number = 16; // in days
export const statusSbaPending: string = 'pending';
export const SearchedKeys: Record<string, string> = {
  benefitsFlag: 'benefitsFlag'
};
export const EntertainmentCodes: Record<string, string> = {
  FILMN: 'FILMN',
  HBO: 'HBOST'
};
export const EntertainmentNames: Record<string, string> = {
  FILMN: 'filmin',
  HBO: 'hbo'
};
export const siebleLinks: string = 'LinkExternal';
export const FAQURLSchema: string =
  `https://ayudacliente.vodafone.es/app-{client_type}/{partialURL}` +
  `?perfil=OWparticulares&ikki={ikki}&client_debt={client_debt}&client_status={client_status}&` +
  `page_platform={page_platform}&client_id_crm={client_id_crm}&client_typology={client_typology}&` +
  `service_type={service_type}&client_privileges={client_privileges}`;
export const FootballTvExtraCodes: string[] = ['CLIGA', 'GGOLR'];
export const homePageUrl: string = 'home/';
export const porqueNosImportasUrl: string = 'https://porquenosimportas.es/';
export const alphabeticalKey: Record<string, string> = {
  productCategory: 'ProductCategory'
};
export const updateCpe: Record<string, string> = {
  obsolete: 'OBSOLETE',
  updating: 'UPDATING',
  high: 'HIGH',
  wifi5: 'WIFI5',
  wifi6: 'WIFI6',
  router: 'Router',
  decodificador: 'Decodificador',
  combi: 'Router + Decodificador',
  auto: 'Autoinstalación',
  equipo: 'Equipo + Instalación'
};

export const defaultEnjoyMoreIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/group.png';
export const clockErrorIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-clock-hi-light-theme@3x.png';

// Refactoring Landing Component, foreach service type add the bundle types in the order you wish to be displayed
// NOTE: Please Adjust them according to service type as most of them were added with all service subtypes
export const LandingSortingConfig: {serviceType: ServiceType, bundleSubtypes: ProductSubtype[]}[] = [
  {
    serviceType: ServiceType.Postpaid,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra,
      ProductSubtype.EnjoyMoreExtra
    ]
  },
  {
    serviceType: ServiceType.Prepaid,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra
    ]
  },
  {
    serviceType: ServiceType.MbbPostpaid,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.Extra
    ]
  },
  {
    serviceType: ServiceType.Landline,
    bundleSubtypes: [
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Extra
    ]
  },
  {
    serviceType: ServiceType.Tv,
    bundleSubtypes: []
  },
  {
    serviceType: ServiceType.Internet,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra,
      ProductSubtype.EnjoyMoreExtra
    ]
  },
  {
    serviceType: ServiceType.ADSL,
    bundleSubtypes: []
  },
  {
    serviceType: ServiceType.Fibre,
    bundleSubtypes: []
  },
  {
    serviceType: ServiceType.Mobile,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra,
      ProductSubtype.EnjoyMoreExtra
    ]
  },
  {
    serviceType: ServiceType.Fixed,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra,
      ProductSubtype.EnjoyMoreExtra
    ]
  },
  {
    serviceType: ServiceType.VodafoneOne,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra,
      ProductSubtype.EnjoyMoreExtra
    ]
  },
  {
    serviceType: ServiceType.firstAdd,
    bundleSubtypes: [
      ProductSubtype.Data,
      ProductSubtype.EnjoyMore,
      ProductSubtype.FixedToFixed,
      ProductSubtype.FixedToMobile,
      ProductSubtype.FixedToInternational,
      ProductSubtype.Minutes,
      ProductSubtype.Sms,
      ProductSubtype.Extra,
      ProductSubtype.EnjoyMoreExtra
    ]
  },
  {
    serviceType: ServiceType.VodafoneEnTuCasa,
    bundleSubtypes: [ProductSubtype.FixedToFixed, ProductSubtype.FixedToMobile,
    ProductSubtype.FixedToInternational, ProductSubtype.Data,
    ProductSubtype.EnjoyMore, ProductSubtype.Sms, ProductSubtype.Extra, ProductSubtype.EnjoyMoreExtra]
  },
];
export const chatBaseUrl: string = `${environment.imageBaseUrl}/need-help-bubble-chat.html?token={0}&chatUrl={1}`;
export const paymentPhpUrlKo: string = '/payment-ko.html';
export const paymentPhpUrlOk: string = '/payment-ok.html';
export const urlDashboard: string = 'mves/dashboard';
export const voiceMailSecondsLimites: number[] = [5, 10, 15, 20, 25, 30];

export const downloadAppNudgeKey: string = 'downloadAppNudge';

export const capmaignsStoredKey: string = 'campaigns';

export const nativeAppVariables: { [key: string]: string } = {
  nativeAppTestBundleId: 'com.tsse.spain.myvodafone',
  nativeAppProductionBundleId: 'es.vodafone.mobile.mivodafone',
  androidAppStore: 'https://play.google.com/store/apps/details?id=es.vodafone.mobile.mivodafone',
  iosAppleStore: 'https://itunes.apple.com/es/app/mi-vodafone/id455655421?mt=8',
  webAppLink: 'https://m.vodafone.es/mves/dashboard',
  domainTest: 'http://w9u6f.app.goo.gl',
  domainProduction: 'https://vfes.page.link'
};
/**
 * apn: andriod bundle id,
 * ibi: iOS bundle id,
 * afl: android if not installed (app store),
 * ifl: iOS if not installed (apple store),
 * ofl: other plat form (back to web)
 */
export const nativeAppURL: string = '{domain}/?link=https://Dashboard&apn={apn}&afl={afl}&ibi={ibi}&ifl={ifl}&ofl={ofl}&efr=1';
export const adobeNativeAppURL: string = '{adobelink}&apn={apn}&afl={afl}&ibi={ibi}&ifl={ifl}&ofl={ofl}&efr=1';

export const deepLinkParams: { [key: string]: string } = {
  extraId: 'extraId',
  enjoyId: 'enjoyId',
  tvChannelId: 'tvChannelId',
  onlineChannelId: 'onlineChannelId',
  entId: 'entId',
  restId: 'restId',
  playlist: 'playlist',
  playlistId: 'playlistId',
  codes: 'codes',
  terminalSap: 'sap',
  cartid: 'cartid',
  msisdn: 'msisdn',
  cartItemId: 'cartItemId',
  group: 'group',
  offerId: 'offerId',
  category: 'category'
};

export const ChannelsCode: { [key: string]: string } = {
  extraCode: 'gextr',
  totalCode: 'gtotn'
};
export const BreakDownStatus: string = 'GENERAL_BREAKDOWN_WITH_DATE';

export const voiceMailToggleStatus: { [key: string]: string } = {
  active: 'active',
  inactive: 'inactive'
};

export const SecurityType: { [key: string]: string } = {
  open: 'open',
  wep64: 'wep64',
  wep128: 'wep128',
  wpapsk: 'wpapsk',
  wpa2psk: 'wpa2psk',
  wpa12psk: 'wpa12psk'
};
export const miWiFiOpenSecurityType: { [key: string]: string } = {
  open: 'open',
  setPasswordAction: 'act_wifi_ssid_setpassword'
};

export const ticketStatus: { [key: string]: string } = {
  open: 'open',
  closed: 'closed'
};

export const TemplateImage: Record<string, string> = {
  tools: 'tools.png',
};
export const HOURS_LIMIT_FOR_ACTIVATION: number = 48;

export const otpTrialStatus: OtpTrialStatusModel = {
  /**
   * No Msisdn provided on Pin generation
   */
  ecodeNoMsisdn: '3002',
  statusNoMsisdn: 400,
  /**
   * Pin generation limit exceeded
   */
  ecode: '1318',
  status: 406,
  /**
   * No Pin provided on Pin validation
   */
  ecodeNoPin: '1306',
  statusNoPin: 406,
  /**
   * Invalid Pin on Pin validation
   */
  ecodeWrongPin: '1313',
  statusWrongPin: 406
};

export const UNAVAILABILITY_OVERLAY: { [key: string]: string } = {
  TOTAL: 'total',
  ECARE_O_MCARE: 'ecare o mcare',
  BILLING: 'billing',
  SIEBEL_O_CLARIFY: 'siebel o clarify'
};

export const coin: string = '€';
export const perMonth: string = '/mes';
export const perMonths: string = ' /meses';

export const AutomaticChannelText: { [key: string]: string } = {
  automaticChannel: 'Automatico (Recomendado)'
};

export const legacyCodes: string[] = ['ON15'];

export const superOffers: string[] = ['ON19', 'DESTINY'];

export const ecareRoutes: string[] = ['gpedidos'];

export const isUnlimited: string = 'Unlimited';

export const Migration: MigrationModel = {
  Ticket: {
    ticketType: {
      Peticion: 'Peticion'
    },
    discountCode: {
      key: 'Código de descuento:',
      keyWithoutDiscount: 'Sin descuento'
    },
    discountUnInstall: {
      key: 'Descuentos a desinstalar:',
      keyWithoutDiscount: 'Sin descuentos'
    },
    severity: {
      Major: '1'
    },
    ContactMethod: {
      key: 'ContactMethod',
      Email: '1'
    },
    Triplet: {
      key: 'Triplet',
      value: '{pega}{typeSupperOffer} Portal Móvil: Mi Vodafone PM|{0}|'
    },
    FaultyCode: {
      key: 'FaultyCode'
    },
    AditionalLine: {
      title: 'Líneas adicionales',
      id: 'Nueva línea móvil adicional {0}',
      ticket: '\nNueva línea móvil\nSBA:',
      url: 'aditionalLines',
      pushState: 'mves/recommender'
    },
    Canje: {
      title: 'Canje Terminal'
    },
    CanjeAditional: {
      title: 'Canje terminal y línea adicional'
    },
    PEGA: {
      title: 'INGENIO: '
    }
  },
  Documentun: {
    characteristic: {
      siteId: 'site_id',
      documentum_object_name: 'object_name',
      extension_of_doc: 'Extension_of_doc',
      fiscalNum: 'fiscalnum',
      subtipe: 'subtipo'
    }
  },
  TVServices: {
    TVSER: 'TVSER',
    TVSLO: 'TVSLO'
  },
  badges: {
    new: 'new',
    modified: 'modified'
  }
};
export const SpMVA10TextIcon: Record<string, string> = {
  profileIcon: 'icon-profile',
  showPassword: 'icon-show-password',
  hidePassword: 'icon-hide-password',
  key: 'icon-key',
  infoCircle: 'icon-info-circle',
  redInfoCircle: 'icon-info-circle red'
};
export const SpMVA10TextboxType: Record<string, string> = {
  text: 'text',
  password: 'password',
  number: 'number'
};
export const SpMVA10Variable: Record<string, string> = {
  icon_ok: 'icon-tick-or-solved_esp',
  green: '#009900',
  icon: 'icon-warning-notification',
  red: '#ff0000',
  vodafoneRed: '#e60000'
};

export const UPGRAD_SCINARIO_ERROR_4006: string = '4006';

export const BubbleStatus: { [key: string]: string } = {
  available: 'disponible',
  unavailable: 'no disponible'
};
export const prepaidFormatedAddress: Record<string, string> = {
  address: 'CL. FICTICIA TVTA',
};

export const Commons: { [key: string]: string } = {
  yes: 'si',
  no: 'no',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY',
  wcs: 'wcs',
  dxl: 'dxl',
  tagYes: 'yes'
};

export const Language: { [key: string]: string } = {
  spanish: 'es-ES',
  english: 'en-US'
};

export const AditionalLineType: { [key: string]: string } = {
  basic: 'basic',
  unlimited: 'unlimited'
};

export const TicketTemplates: TicketTemplatesModel = {
  Migration: {
    siteId: 'SiteID: {0}',
    service: ' {name} ({code})',
    serviceMBB: ' {name} ({code}: {mbbNetPrice} sin IVA) {idRelatedTo} (padre)',
    serviceNetFee: ' {name} ({code}: {netFee} sin IVA)',
    sbas: 'SBA: {0}',
    svas: 'SVA: {0}',
    discounts: {
      migration: 'Descuento Migración: {discountMigrationAmount} sin IVA |',
      duration: 'Duración Descuento Migración: {discountMigrationDuration} meses',
      ontariff: 'Duración Descuento Migración: En tu tarifa',
      additionalLines: 'Descuento Lineas adicionales: {discountVolumeAmount} sin IVA',
      totalWithDiscount: 'Total con Descuento: {totalAmount} sin IVA |',
      totalWithoutDiscount: ' Total sin Descuento: {totalAmount} sin IVA'
    },
    commitments: {
      withDuration: 'Duración permanencia: {commitmentMigrationDuration} meses | ',
      withoutDuration: 'Duración permanencia: Sin permanencia | ',
      penalty: 'Penalización maxima: {commitmentInMonths} con IVA'
    },
    sign: 'Registo de firma:',
    signedDate: 'Fecha y hora:',
    canje: 'Canje terminal sobre MSISDN {0} '
  },
  CampaignPromotion: {
    siteId: 'ID CLIENTE',
    msisdn: 'MSISDN',
    address: 'DIRECCION DE ENVIO',
    tariffCode: 'CODIGO DE TARIFA',
    mail: 'MAIL',
    nif: 'NIF/DNI',
    date: 'FECHA CONTRATACION',
  },
  bundleupsell: {
    titleIdPega: 'Oferta PEGA:',
    idPEGA: 'Id PEGA: {0}',
    movement: 'Movimiento: {0}',
    destinationPack: 'Pack destino: {0}',
    destinationPackTV: {
      destinationTV: 'Pack destino: {0} ({1})',
      packTV: 'Pack TV: {0}',
      promotionTV: 'Promoción TV: {0}',
      promotionAD: 'Promoción Adicional: {0}. {1}. {2}'
    },
    ticket: {
      triplet: {
        key: 'Triplet',
        value: '{0} | PM | {1}'
      }
    },
    ticketM2P: {
      triplet: {
        key: 'Triplet',
        value: '{1} | PM'
      }
    },
    ticketType: {
      Peticion: 'Peticion'
    },
    severity: {
      Major: '1'
    },
    faultyCode: {
      key: 'FaultyCode'
    },
    contactMethod: {
      key: 'ContactMethod',
      Email: '1'
    },
    sign: 'Registo de firma:',
    titleContractedProducts: 'Productos contratados:',
    titleDiscounts: 'Descuento a cargar:',
    discounts: {
      key: 'Descuento código:',
      keyM2P: 'Descuento código: {0}',
      migration: 'Descuento precio: {0} sin IVA',
      duration: 'Descuento Duración: {0} meses',
      ontariff: 'Descuento Duración: Sin Permanencia (ilimitado)',
      withoutDiscount: 'Oferta sin descuento'
    },
    commitments: {
      key: 'Permanencia:',
      withDuration: 'Permanencia: {0} meses',
      withoutDuration: 'Permanencia: Sin Permanencia (ilimitado)',
      penalty: 'Penalización maxima: {0} con IVA',
      penaltyM2P: 'Penalización maxima: {0} sin IVA',
      maxPenaltyM2P: 330.58
    },
    titleDiscountUninstall: 'Descuentos a desinstalar',
    titleNODiscountUninstall: 'No hay descuentos a desinstalar',
    titleFinalFee: 'Cuota final:',
    finalFee: {
      withDiscount: 'Promocionada {0} meses: {1} sin IVA',
      withDiscountM2P: 'Promocionada: {0} sin IVA',
      endDiscount: 'Tras fin promoción: {0} sin IVA',
      withoutDiscount: 'Cuota final: {0} sin IVA'
    },
    devices: {
      key: 'Dispositivos:',
      sap: 'sap',
      finalFee: 'Cuota: {0}€ durante {1} meses',
      financingType: {
        key: 'Método de pago',
        cash: 'al contado',
        financing: 'financiado'
      }
    },
    tv:  {
      key: 'Television:',
      email: 'no aplica',
      modalityTV: {
        modality: 'modalidad:',
        email: 'email:',
        fee: 'cuota:'
      }
    },
    portability:  {
      key: 'Tipo de alta:',
      isPortability: 'Portabilidad',
      notPortability: 'Alta nueva',
      portability: {
        operator: 'Operador:',
        code: 'Cod. operador:',
        phone: 'Numero a portar:'
      }
    }
  }
};

export const codesOutComparator: string[] = ['TVSBA'];

export const tariffTypes: { [key: string]: string } = {
  basic: 'basic',
  unlimited: 'unlimited'
};

export const callToTel: string = 'tel:';

export const OnBehalOfTypes: { [key: string]: string } = {
  grantType: 'urn:ietf:params:oauth:grant-type:token-exchange',
  subjectTokenType: 'urn:ietf:params:oauth:token-type:access_token',
  actorTokenType: 'urn:ietf:params:oauth:token-type:client_id'
};
export const LINE_SERVICES_CARDS_VISIBILITY_PAGES_MAP: Record<string, string> = {
  'smsalert': '$.recieveSMS.show',
  'waiting': '$.callWaiting.show',
  'hidemynumber': '$.hideMyNumber.show',
  'divertCalls': '$.divertCalls.show',
  'autoredial': '$.autoredial.show',
  'dictasms': '$.dictaSMS.show',
  'voicemail': '$.answerMachine.show'
};
export const contactUsDefaultMapping: { [key: string]: string } = {
  key: 'contactUsDefaultMapping'
};
export const CommercialJourneys: CommercialJourneysModel = {
  ntol: 'NTOL_TXID',
  disCountClassTypeFare: 'FARE',
  discountTypeSubstract: 'SUBSTRACT',
  discountTypePresent: 'PRESENT',
  discountClassTypeClient: 'CLIENT',
  discountTypePercent: 'PERCENT',
  clientType: '0',
  shopType: '4',
  sceneType: '1',
  keepTypeFrontService: 'service',
  mobileToPack: 'mobileToPack',
  oneConect: 'oneConect',
  equals: '=',
  false: false,
  true: true,
  contractType: '0',
  sap: '11111',
  sapME: '22222',
  rateType: '2',
  rateType_One: '1',
  registerTypeMTP: '5',
  registerType: '0',
  registerTypeRenewal: '100',
  onlyAdditionalLine: 'true',
  typeOptionSelected: 'PORTABILIDAD',
  typeOptionSelected2: 'Nuevo número',
  page: 'CHECKOUT',
  pageAddons: 'CHECKOUT_OP_START',
  pageMtp: 'CHECKOUT_M2P',
  clientType_Partis: '2',
  shoppingcartitem: 'shoppingcartitem',
  registerTypePortability: 2,
  registerTypeNew: 0,
  registerTypeNewUpsell: 5,
  serviceItemTypeUpsell: 8,
  OneProfessionalService: 17,
  OneProfPurchasePoint: 4,
  serviceItemTypeUpsellTV: 9,
  serviceItemTypeTV: 6,
  serviceItemTypeDeco: 22,
  serviceItemTypeOnePlus: 14,
  serviceItemTypeConecta: 15,
  serviceItemTypeConectaTelevision: 16,
  euros: ' €',
  coinMonth: '/mes',
  percent: '%',
  portability: 'Portabilidad: ',
  new_line: 'Alta Nueva',
  altaNueva: 'nuevo',
  portabilidad: 'portabilidad',
  seleccionadoAltaNueva: 'seleccionado alta nueva',
  seleccionadoPorta: 'seleccionado portabilidad',
  free: 'Gratis',
  catalogElementTypePackage: 'Package',
  catalogElementTypeDevice: 'Device',
  pageNameHome: 'purchase:lineas adicionales:home',
  pageNameHomeM2P: 'home',
  pageScreenHome: 'home',
  conCita: 'con cita',
  sinCita: 'sin cita',
  autoinstalable: 'autoinstalable',
  pageNameThankYou: 'purchase:lineas adicionales:checkout:thank you',
  pageScreenThankYou: 'thank you',
  dashboardPath: '/dashboard',
  dashboardPathWithOutBar: 'dashboard',
  checkout: 'checkout',
  purchase: 'purchase',
  securePath: '/api/ikki/secure',
  pathOfM2POferts: 'api/ikki/catalog/frontend/offers',
  productsAndServicesPath: '/ProductsAndServices/MainScreen',
  imgHome: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/home-sim.svg',
  imgStore: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/store-home.svg',
  imgOffice: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-work.svg',
  imgClearFilters: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-refresh-red.svg',
  m2pTitle: 'M2P',
  m2pSubtitle: 'Entry Point Mobile To Pack',
  spacer: ' ',
  emailLabel: 'Email',
  bigNumber: 99999999999999999,
  ecode136: '136',
  servicesFail: {
    servicesOfCheckout: 'servicesOfCheckout',
    postShoppingCartItem: 'postShoppingCartItem',
    postPersonalData: 'postPersonalData',
    postMobileData: 'postMobileData',
    postPersonalDataSaveNewData: 'postPersonalDataSaveNewData'
  },
  dniNieValidation: {
    letters: 'TRWAGMYFPDXBNJZSQVHLCKET',
    DNI: 'DNI',
    NIE: 'NIE',
  },
  serviciabilityRespons: {
    FIBRA: 'FIBRA',
    ADSL: 'ADSL',
    SIN_COBERTURA: 'SIN COBERTURA'
  },
  journey: {
    AL: 'additional_lines',
    M2P: 'mobileToPack',
    journey: 'journey',
    sndResidences: 'secondResidences',
    OPUPG: 'onePlusUpgrade',
    OPADD: 'onePlusAddLines',
    BUNDLELUPSELL: 'bundleupsell'
  },
  entryPointsRequest: {
    params: '?'
      + 'screenCode={screenCode}&'
      + 'customerAccountId={customerAccountId}&'
      + 'sceneType={sceneType}&'
      + 'contractType={contractType}&'
      + 'rateType={rateType}',
    channel: '0'
  },
  offersRequest: {
    common: {
      params: '?'
        + 'clientType={clientType}&'
        + 'shopType={shopType}&'
        + 'sceneType={sceneType}&'
        + 'contractType={contractType}&'
        + 'rateType={rateType}'
    },
    AL: {
      params: '&'
        + 'sap={sap}&'
        + 'registerType&'
        + 'onlyAdditionalLine={onlyAdditionalLine}&'
        + 'siteId={siteId}'
    },
    M2P: {
      params: '&'
        + 'coverage={coverage}&'
        + 'registerType={registerType}&'
        + 'internet={internet}&'
        + 'siteId={siteId}',
      checkout: {
        flagCheckItemsCart: '?flagCheckItemsCart=true',
        requestActive: '?requestActive=true'
      },
      installation: {
        nebalClient: ['NEBAF', 'NEBAL', 'VDSL', 'ADSL'],
        noToa: ['cable', 'FTTH']
      }
    },
    sndResidences: {
      params: '&'
        + 'sap={sap}&'
        + 'registerType={registerType}&'
        + 'siteId={siteId}&'
        + 'rateCode={rateCode}',
    },
    OPADD: {
      params: '&'
        + 'sap={sap}&'
        + 'registerType&'
        + 'siteId={siteId}&'
        + 'oneplus=true',
    },
    OPUPG: {
      params: '&'
        + 'sap={sap}&'
        + 'registerType&'
        + 'siteId={siteId}&'
        + 'oneplusPackage={oneplusPackage}',
    }
  },
  mobileToPack_validation: {
    ok: '000',
    ko_postpago: '001',
    ko_paquete: '002',
    ko_descuentos: '003',
    ko_general: '999',
    confirmation: {
      installationTipe: {
        datesAppointmentInstall: 0,
        autoinstalableResponse: 1,
        noToaResponse: 2,
        nebalResponse: 3
      }
    }
  },
  retryPages: {
    offers: 'offers',
    checkout: 'checkout',
    shipping: 'shipping',
    summary: 'summary'
  },
  journeyTaggObj: {
    purchase_entrypoint: '',
    purchase_migration_type: '',
    purchase_product_classification: '',
    products: '',
    promotion_name: '',
    promotion_duration: '',
    promotion_code: '',
    transaction_shipping: '',
    purchaseID: '',
  },
  errorALPrescoring: {
    error_type: 'funcional',
    error_category: 'prescoring ko',
    deeplink: 'deeplink'
  },
  shopV10: {
    params: {
      TV: 'TV'
    },
    page: 'SHOP',
    codes: {
      MIGON15: 'MIGON15',
      LAON15: 'LAON15',
      TVON15: 'TVON15',
      CTON19: 'CTON19',
      LAON19: 'LAON19',
      TVON19: 'TVON19',
      TV: 'TV',
      SAON1X: 'SAON1X',
      PECON1X: 'PECON1X',
      CDON1X: 'CDON1X',
      ONENUM: 'ONENUM',
      BONOEX: 'BONOEX',
      MIGON19: 'MIGON19',
      ENTRET: 'ENTRET',
      OFEPT: 'OFEPT',
      NEGME19: 'NEGME19'
    },
    path: '/ShopV10'
  },
  getBannersParams: {
    params: '?'
      + 'screenCode={screenCode}&'
      + 'customerAccountId={customerAccountId}&'
      + 'serviceId={serviceId}&'
      + 'channel.id={channel.id}'
  },
  journeyTaggs: {
    installation_type: 'installation_type',
    purchase_entrypoint: 'purchase_entrypoint',
    purchase_migration_type: 'purchase_migration_type',
    purchase_product_classification: 'purchase_product_classification',
    products: 'products',
    promotion_name: 'promotion_name',
    promotion_duration: 'promotion_duration',
    promotion_code: 'promotion_code',
    transaction_shipping: 'transaction_shipping',
    purchaseID: 'purchaseID',
  },
  Tagg: {
    type: {
      init: 'init',
      type: 'type',
      tarif: 'tarif',
      classification: 'classification',
      shipping: 'shipping',
      moreInfo: 'moreInfo',
      info: 'info',
      error: 'error',
      click: 'click',
      back: 'back',
    },
    entrypoint: {
      dashboard: 'dashboard',
      product_service: 'productos y servicios',
      shop_v10: 'tienda'
    },
    tarif: {
      basic: 'basica',
      unlimited: 'ilimitable'
    },
    tarifRate: {
      basic: 'basica',
      unlimited: 'ilimitada'
    },
    purchaseChoice: {
      port: 'portabilidad',
      new: 'nuevo'
    },
    choice: {
      port: 'portabilidad',
      new: 'nueva linea'
    },
    shipping: {
      store: 'recoger en tienda',
      home: 'envio a casa',
      office: 'recoger en correos'
    },
    product: 'producto',
    prodView: 'prodView',
    offersStatus: {
      init: 'init',
      default: 'default',
      change: 'change',
      boxless: 'boxless'
    },
    paymentType: {
      contado: 'pago al contado',
      plazos: 'financiacion'
    },
    eVar: {
      product_category: 'movil;',
      product_category_m2p: 'movil+fibra;',
      product_quantity: ';1;;;',
      promo: ',promo;',
      eVar187_principal: ';1;;;eVar187=producto principal:fide',
      eVar184: '|eVar184=',
      eVar208: '|eVar208=',
      eVar207: '|eVar207=',
      eVar182: '|eVar182=',
      product_promo: ',movil;',
      eVar184_promo: ';1;;;eVar184=',
      evar187_addon: '|eVar187=addons:fide',
      evar187: '|eVar187=producto principal',
      evar187_promo: '|eVar187=producto principal:fide',
      evar188: '|eVar188=primario',
      evar188_secundario: '|eVar188=secundario',
      evar180: 'eVar180={0}',
      evar181: '|eVar181={0}',
      evar186: '|eVar186={0}',
      evar200: '|eVar200={0}',
      evar208: '|eVar208={0}',
      evar214: '|eVar214={0}',
      evar68: '|eVar68=pads:{0}-{1}',
      evar181_promo : 'eVar181={0}',
      evar_addons : 'addons;'
    },
    errorTerminalList: {
      error_category: 'oferta no habilitada',
      error_type: 'funcional'
    },
    genericCheckout: {
      checkoutLoaded: 'checkoutLoaded',
      selectShipping: 'selectShipping',
      error: 'error',
      leaveCheckout: 'abandonar',
      continueCheckout: 'continuar',
      deliveryDetails: {
        deliveryDataOk: 'deliveryDataOk',
        clickChangeAddress: 'clickChangeAddress',
        clickSaveNewAddress: 'clickSaveNewAddress',
        closeNewAddress: 'closeNewAddress',
      }
    },
    nexusCommercial: {
      nexusLoaded: 'nexusLoaded',
      directTransition: 'directTransition',
      deleteProduct: 'deleteProduct',
      error: 'error',
      dropDown: 'dropDown',
      goToShipping: 'goToShipping'
    },
    nexusDMP: {
      eventType: {
        impresion: 'impresion',
        click: 'click'
      }
    }
  },
  nexusCommercial: {
    directTransition: 'transaccional:microflujos:nexo:transicion directa',
    page_screen_transition: 'transicion directa',
    leaveEvents: {
      boton: 'boton',
      leave: {
        event_name: 'abandonar',
        '&&events': 'event105'
      },
      continue: {
        event_name: 'continuar',
        '&&events': 'event106'
      }
    },
    evar: {
      terminal: 'terminal',
      linea: 'movil',
      fibra: 'movil+fibra',
      portabilidad: 'portabilidad',
      alta_nueva: 'alta nueva',
      semicolon: ';',
      separator: ';1;;;',
      principal: 'producto principal:fide',
      addons: 'addons:fide',
      p: 'primario',
      s: 'secundario',
      evar180: 'eVar180=',
      evar181: '|eVar181=',
      evar182: '|eVar182=',
      evar184: '|eVar184=',
      evar186: '|eVar186=',
      evar187: '|eVar187=',
      evar188: '|eVar188=',
      evar190: '|eVar190=',
      evar207: '|eVar207=',
      evar213: '|eVar213=',
      evar214: '|eVar214=',
      evar215: '|eVar215=',
      evar138: '|eVar138=',
      evar239: '|eVar239=',
      evar200: '|eVar200=',
      evar68: '|eVar68=',
      pco: 'pco:',
      pto: '_pto:',
      space: ' ',
      underscore: '_',
      coma: ',',
      paymentCash: 'pago al contado',
      financedPayment: 'financiacion {0} meses',
    },
    evar239_states: {
      available: 'disponible',
      presale_stock: 'preventa:con stock',
      accelerator: 'acelerador',
      case_1: '1'
    }
  },
  genericCheckout: {
    homeShipping: 'envio a casa',
    shopShipping: 'recoger en tienda',
    pickupShipping: 'recoger en correos',
    changenumber: 'cambiar numero movil',
    addnumber: 'anadir numero movil',
    changenumberEvent: 'click en cambiar numero movil',
    addnumberEvent: 'click en anadir numero movil',
    leaveEvents: {
      boton: 'boton',
      leave: {
        event_name: 'abandonar',
        '&&events': 'event105'
      },
      continue: {
        event_name: 'continuar',
        '&&events': 'event106'
      }
    }
  },
  EntryPointErrorCase: {
    notAvailableOffer: {
      redirection: 'dashboard'
    },
    oldTariffs: {
      redirection: 'recommender'
    },
    orderInProgress: {
      redirection: 'gpedidos'
    },
    clientDebt: {
      redirection: 'billing/billPayment'
    },
    errorPage: {
      redirection: 'dashboard'
    }
  },
  allowedRequest: {
    params: '?'
      + 'cartid={cartid}'
  },
  config_filter: 'según tu configuración',
  journey_name: '<journey_name>',
  lad: {
    page_name_denegacion: 'purchase:lineas adicionales:error de entryPoint',
  },
  paymentCard: {
    initPayment: {
      event_name: 'click en pagar',
      event_category: 'boton',
      event_context: 'pasarela',
      event_label: 'cerrar'
    }
  }
};

export const optionsComparators: Record<string, string> = {
  optionA: 'A',
  optionB: 'B',
  optionC: 'C'
};

export const Result: Record<string, string> = {
  OK: 'OK',
  KO: 'KO',
  NONE: 'NONE'
};
export const codiogo: Record<string, string> = {
  cancel: 'cancel',
  timeout: 'timeout',
  complete: 'OK',
  fail: 'KO',
};
export const mensaj: Record<string, string> = {
  cancelMessage: 'cancelado por usuario',
  timeoutMessage: 'tiempo de espera excedido',
  OKMessage: 'Finalización sin Incidencias',
  KoMessage: 'Denegada por diversos motivos',
};

export const BundleAction: Record<string, string> = {
  Activate: 'activate',
  Deactivate: 'deactivate'
};

export const SmartTvParams: Record<string, string> = {
  action: 'action',
  contractType: 'contractType',
  delightTv: 'delightTv'
};

export const tvContractType: Record<string, string> = {
  deco: 'DECO',
  nodeco: 'SMRTV',
  gen3: 'GEN3',
  migraGen3: 'MIGRAGEN3',
  migra: 'MIGRA',
  gmap5: 'GMAP5',
  gmap6: 'GMAP6'
};

export const tvContractText: Record<string, string> = {
  deco: 'contratar ahora con decodificador',
  nodeco: 'contratar ahora sin decodificador',
  migra: 'cambiar a con decodificador'
};

export const SmartTvAction: Record<string, string> = {
  CheckoutSummary: 'summary',
  CheckoutPreconfirmation: 'preconfirmation',
  Change: 'change',
};
export const commercialMobile: CommercialMobileModel = {
  clientType: 0,
  clientTypeMicro: 2,
  shopType: '4',
  shopTypeMicro: '5',
  shopTypeYu: '9',
  lineType: 0,
  terminalType: 3,
  registerType: 4,
  sceneType: 1,
  contractType: 0,
  group: '&group={group}',
  offerId: '&offerId={offerId}',
  paymentType: '&paymentType={paymentType}',
  promoCode: '&codigoPromo={promoCode}',
  page: 'CHECKOUT_CANJE',
  profile: 'GESTION ONLINE',
  null: 'null',
  type: {
    T: 'T',
    G: 'G'
  },

  ecodePersonalData: {
    emailError: '100'
  },

  errorCodes: {
    preScoring: '101',
    preScoring2: '100',
    preScoring3: '102',
    preScoring4: '108',
    preScoring5: '103',
    terminalDetailSap: '111',
    promoNotValid: '404'
  },

  plus: '+',
  coinMonth: '/mes',
  PRESENT: 'PRESENT',
  privatePlaylist: 'private',
  privatePlTagging: 'privada_',

  Tagg: {
    type: {
      init: 'initCorrect',
      error: 'initError',
      selectPlaylist: 'selectPlaylist',
      ecodePrescoring: 'ecodePrescoring',
      applyFilter: 'applyFilter',
      emptyResponse: 'emptyResponse',
      errorPrescoring: 'prescoring',
      financActiva: 'financActiva',
      entryError: 'entryError',
      continue: 'continuar',
      leave: 'abandonar',
      openProducts: 'openProducts',
      paymentCreditCard: 'paymentCreditCard',
      privateOffer: 'privateOffer',
      clickPrivateOffer: 'clickPrivateOffer',
      clickButtonBdp: 'clickButtonBdp',
      bdpChangeLine: 'bdpChangeLine',
      clickChangeLine: 'clickChangeLine',
    },
    financing: {
      cash: 'pago al contado',
      financing: 'financiacion',
    },
    event: {
      event_color: 'seleccion de color de terminal',
      event_size: 'seleccion de capacidad de terminal',
      event_financing: 'seleccionado {0}',
      event_finalRefinancing: 'seleccion meses pago final',
      event_detailFinancing: 'detalle financiacion'
    },
    evar: {
      terminal: 'terminal;',
      linea: 'movil',
      portabilidad: 'portabilidad',
      alta_nueva: 'alta nueva',
      semicolon: ';',
      separator: ';1;;;',
      principal: 'producto principal:fide',
      addons: 'addons:fide',
      p: 'primario',
      s: 'secundario',
      evar180: 'eVar180=',
      evar181withoutSeparator: 'eVar181=',
      evar181: '|eVar181=',
      evar182: '|eVar182=',
      evar184: '|eVar184=',
      evar186: '|eVar186=',
      evar187: '|eVar187=',
      evar188: '|eVar188=',
      evar207: '|eVar207=',
      evar213: '|eVar213=',
      evar214: '|eVar214=',
      evar215: '|eVar215=',
      evar138: '|eVar138=',
      evar190: '|eVar190=',
      evar200: '|eVar200=',
      evar239: '|eVar239=',
      evar244: '|eVar244=',
      evar68: '|eVar68=',
      pco: 'pco:',
      pto: '_pto:',
      ptc: '_ptc:',
      space: ' ',
      underscore: '_',
      coma: ',',
      paymentCash: 'pago al contado',
      financedPayment: 'financiacion {0} meses',
      evar239_states: {
        presale_stock: 'preventa:con stock',
        available: 'disponible',
        stock: 'acelerador',
        unavailable: 'no disponible'
      },
      pvp_inactive: 'precio normal',
      pvp_active: 'precio recomendado'
    },
    filter: {
      color: 'color:',
      brand: 'brand:',
      capacity: 'capacity:',
      end: ';'
    },
    finalPayType: {
      no: 'no',
      total: 'total',
      parcial: 'parcial {0} meses',
    }
  },
  icon: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/{name}.svg',
  assets: 'assets',
  icon_tick_ok: 'icon-tick-or-solved_esp',
  icon_info: 'icon-info-circle-esp',
  color_ok: '#009900',
  color_info: '#eb9700',
  device: 'device',
  package: 'package',
  mobile: 'mobile',
  fibre: 'fibre',
  tv: 'tv',
  deco: 'deco',
  unsubscribeservices: 'unsubscribeservices',
  sim: 'SIM',
  mobilepackage: 'mobilepackage',
  onepackage: 'onepackage',
  onetelevisionpackage: 'onetelevisionpackage',
  onepluspackage: 'onepluspackage',
  conectapackage: 'conectapackage',
  conectatelevisionpackage: 'conectatelevisionpackage',
  landlineservice: 'landlineservice',
  monthly: 'monthly',
  cash: 'cash',
  productHierarchyType: 'p',
  prescoring: {
    notAllowed: 'notAllowed',
    prescoring: 'prescoring',
    permitida: 'consulta no permitida',
    limitada: 'oferta limitada'
  },
  semaphore: {
    criticalHex: '#E60000',
    critical: 'critical',
    neutralHex: '#EB9700',
    neutral: 'neutral',
    positiveHex: '#009900',
    positive: 'positive'
  }
};

export const SPMVA10FullOverlayClasses: Record<string, string> = {
  flush: 'flush',
  slideLeft: 'slide-left',
  SlideRight: 'slide-right',
  leftRight: 'left-right',
  rightLeft: 'right-left',
};

export const ACTIVACION: string = 'activacion';

export const DESACTIVACION: string = 'desactivacion';
export const FIJO: string = 'fijo';
export const CALLID: string = 'CallId';
export const CALLOPTIONS: Record<string, string> = {
  callID: 'callID',
  privateNumber: 'privateNumber',
  outgoingCall: 'outgoingCall',
  whileAnswering: 'whileAnswering',
  whenNotAnswered: 'whenNotAnswered'
};
export const callidAction: Record<string, string> = {
  desactivar: 'desactivar',
  activar: 'activar'
};
export const callOptionAction: Record<string, string> = {
  desactivar: 'desactivar',
  activar: 'activar',
  confirmar: 'confirmar'
};
export const UserDocumentType: Record<string, string> = {
  NIF: 'NIF'
};
export const SVADetailsNavigation: Record<string, string> = {
  pass: 'pass',
  secure: 'secure',
  hbo: 'hbo',
  TDLLA: 'TDLLA',
};

export const deliveryParams: Record<string, string> = {
  homeCd: 'DOMICILIO',
  storeCd: 'TIENDA',
  officeCd: 'CORREOS'
};

export const confirmation: Record<string, string> = {
  confirm: 'Confirmation'
};

export const impositiveError: Record<string, string> = {
  errorImpositivoNum: '032',
  errorNotFoundNum: '123'
};

export enum TopupPrepaidStages {
  firstStep = 'firstStep',
  secondStep = 'secondStep',
  topupConfirmation = 'topupConfirmation',
  topupGeneralError = 'topupGeneralError',
  paymentTicket = 'topupPaymentTicket',
  TopUpCardWalletScreen = 'TopUpCardWalletScreen',
  billingDeptPayment = 'deptPayment',
  cashDeskPayment = 'cashDeskPayment',
  inAdvancePayment = 'inAdvancePayment',
  payDebtOtherPaymentMethods = 'payDebtOtherPaymentMethods',
  topUpContactListPermissionComponent = 'topUpContactListPermissionComponent'
}
export const vodafoneEsRecarga: string = 'https://www.vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/';
export const TDLLA: string = 'TDLLA';
export const ChangePin: Record<string, string> = {
  purchasePin: 'PurchasePIN',
  firstPin: 'firstPin',
  confirmationPin: 'confirmationPin'
};

export const FTEMapping: Record<string, string> = {
  idFTE: 'idFTE:',
  fteShown: 'fteShown'
};
export const canceledMva10Status: Record<string, string> = {
  overlayStatus: 'canceled'
};
export const no: string = 'no ';
export const Safari: string = 'safari';

export const netFlixProductId: string = '31';

export const ON15: Record<string, string> = {
  on15: 'ON15'
};
export const ON19: Record<string, string> = {
  on19: 'ON19'
};

export const payDeptTab: string = 'paydebt';
export const publicPayment: string = 'publicpayment';

export const netFlixCode: string = 'SV_NetflixSA';

export const errTagging: Record<string, string> = {
  minLength: 'minLength',
  pattern: 'pattern',
  tienda: 'tienda',
  trastienda: 'trastienda',
  dxl: 'dxl',
  otro: 'otro',
  error: 'error'
};

export const Journey: Record<string, string> = {
  HRS: 'HRS:'
};

export const platformWeb: string = 'web';
export const ANONYMOUS: string = 'ANONYMOUS';
export const overlayTitleSelector: string = '.vfac-c-overlay__title';
export const overlayTitlecustomStyle: string = 'vfac-c-overlay__title-payment';
export const overlayBoldTitleStyle: string = 'vfac-c-overlay__bold-title';
export const overlayRemoveBoldTitleStyle: string = 'vfac-c-overlay__remove-bold-title';
export const overlayCenterTitleStyle: string = 'vfac-c-overlay__center-title';
export const overlayMainsectionSelector: string = '.vfac-c-overlay';
export const overlayMainsectionGrayBackGround: string = 'vfac-c-overlay-gray-back-ground';
export const overlayMainsectionGrayBackGroundOnMobile: string = 'vfac-c-overlay-gray-back-ground-mobile-secreen';
export const removePaddingSelector: string = '.vfac-c-overlay__scroll-content';
export const removePaddingCustomStyle: string = 'vfac-c-overlay__remove-padding';
export const increaseHeightCustomStyle: string = 'vfac-c-overlay__icrease-height';
export const overlayHeaderSelector: string = '.vfac-c-overlay__header';
export const overlayHeadercustomStyle: string = 'vfac-c-overlay__header-payment';
export const removeTopPaddingCustomStyle: string = 'vfac-c-overlay__remove-top-padding';
export const stringOfTrue: string = 'true';
export const stringOfFalse: string = 'false';

export const paymentWCSKey: Record<string, string> = {
  mainSection: 'v10.payment.',
  itemList: 'itemsList.',
  messsageList: 'messagesList.',
  thirdPartyPageKey: '.paymentThirdParty',
  paymentSuccessful: '.paymentSuccessful',
  paymentCancel: '.paymentCancelation',
  paymentTicket: '.ticket',
  paymentWallet: '.wallet',
  deleteWalletConfirmation: '.deleteWalletConfirmation',
  openContactListPermission: '.openContactListPermission',
  eShopMiVF: '.preorder'
};
export const paymentOutPutParams: Record<string, string> = {
  codigo: 'codigo=',
  numOperacionOrigen: '&numOperacionOrigen=',
  fechaOpOrigen: '&FechaOpOrigen=',
  importe: '&importe=',
  mensaje: '&mensaje=',
  systemReferenceId: '&systemReferenceId=',
  refundCodigo: '&refundCodigo=',
  refundId: '&refundId=',
  refundFecha: '&refundFecha=',
  refundMensaje: '&refundMensaje=',
};

export const _3dsErrors: Record<string, string> = {
  general: 'Genérico',
  invalidCreditCard: 'Tarjeta inválida',
  invalidCreditCardData: 'Datos de Tarjeta',
};

export const tvStatus: Record<string, string> = {
  tvUnordered: 'tvUnordered',
  tvOrdered: 'tvOrdered',
  noActive: 'noActive'
};
export const legalKeys: Record<string, string> = {
  termsconditions: 'termsconditions',
  privacypolicy: 'privacypolicy',
  libraries: 'libraries'
};

export const SocialMediaLink: Record<string, string> = {
  twitter: 'http://twitter.com/share?text={text}&url={url}',
  facebook: 'https://www.facebook.com/sharer/sharer.php?u={url}',
  mail: 'mailto:?subject={subject}&body={body}',
  clipboard: '',
  whatsapp: 'whatsapp://send?text={text}'
};
export const TopNavgationBarIconsName: Record<string, string> = {
  left_arrow: 'icon-arrow-left',
  vodafone_Logo: 'icon-vodafone-esp',
  MenuIcon: 'icon-menu',
  CloseIcon: 'icon-close'
};

export const TYPEUSER: Record<string, TypeUser> = {
  zero: TypeUser.Zero,
  one: TypeUser.One,
  two: TypeUser.Two,
  three: TypeUser.Three
};

export const defines: Record<string, string> = {
  on19: 'ON19',
  onep: 'ONEP',
  destiny: 'DESTINY',
  ecode7002: '7002',
  euro: '€',
  strEuro: ' euros',
  inactive: 'INACTIVE',
  active: 'ACTIVE',
  ecode6006: '6006',
  credit: 'credit',
  entreYuser: 'entre Yuser',
  one: '1',
  two: '2',
  nine: '9',
  seven: '7',
  thirtyOne: '31',
  amountZero: '0',
  spacer: ' ',
  spacerMB: ' MB',
  spacerGB: ' GB',
  GB: 'GB',
  MB: 'MB',
  Mins: 'Mins',
  textDoublePoint: ':',
  unlimited: 'Sin límites',
  unlimitedUpper: 'SIN LIMITES',
  guin: '-',
  cif: '&cif=',
  comma: ',',
  ingenio: 'INGENIO',
  primary: 'primary',
  rigth: 'rigth',
};

export const CODE_ENTRY_POINTS: Record<string, string> = {
  MIGON15: 'MIGON15',
  MIGON19: 'MIGON19',
  LAON15: 'LAON15',
  LAON19: 'LAON19',
  TVON15: 'TVON15',
  TVON19: 'TVON19',
  TVTXON19: 'TVTXON19',
  TVSTXON19: 'TVSTXON19',
  TVSON19: 'TVSON19',
  CTON15: 'CTON15',
  CTON19: 'CTON19',
  PQNI: 'PQNI',
  MPON19: 'MPON19',
  CTONM19: 'CTONM19',
  TRYANDBUY: 'TRYANDBUY',
  RENON19: 'RENON19',
  DLGHTV: 'DLGHTV',
  OPV001: 'OPV001',
  OPONBR: 'OPONBR',
  OPONBM: 'OPONBM',
  TRYANDPAY: 'TRYANDPAY',
  SOCIALDISCOUNT: 'SOCIALDISCOUNT',
  TRY: 'TRY',
  TRYPAY: 'TRYPAY',
  TRYBUY: 'TRYBUY',
  TVUPSELLPEGA: 'TVUPSELLPEGA',
  BUPSELLPEGA: 'BUPSELLPEGA',
  TVPACKSPEGA: 'TVPACKSPEGA'
};

export const ecodeEmail: Record<string, string> = {
  badRequest: '105',
  goodRequest: '100',
  changeRequest: '104',
  prescoring: '107'
};

export const allowedServicesDef: Record<string, string> = {
  postpaid: 'Mobile Postpaid',
  prepaid: 'Mobile Prepaid',
  cambiar: 'Cambiar',
  journey: 'CTON19',
  journeyM: 'CTONM19',
  contract: 'contract',
  basket: 'basket',
  green: 'ui-green',
  grey: 'aluminium',
  size: 'medium',
  new: 'Alta Nueva',
  query: '&cartId={cartid}'
};
export const recaptchaAction: string = 'web_authentication';

export const LAD: string = 'LAD';
export const DSR: string = 'DSR';
export const M2P: string = 'M2P';

export const typeTabsComsuption: Record<string, string> = {
  incluido: 'incluido',
  fuera: 'fuera',
  todo: 'todo'
};
export const entryPointsMVA10: Record<string, string> = {
  addLine: 'lineas adicionales',
  tv: 'television',
  migra: 'migracion',
  canje: 'canje terminal',
  movil: 'movil to pack',
  step1: 'step1',
  dashboard: 'DSH',
  HSR: 'HSR',
  MGN: 'MGN',
  renewDiscounts: 'DSR',
  billing: 'BILL',
  billingDetail: 'BILLDETAIL',
  newBilling: 'NEWBILL',
  default: 'undefined',
  NXS: 'NXS',
  LAD: 'LAD',
  LADP: 'LADP',
  LAHI: 'LAHI',
  checkout: 'CHK',
  PACKSTV: 'PACKSTV',
  backdrop: 'BDP',
  DELIGHTTV: 'DELIGHTTV',
  UPSELL: 'UPSELL',
  UPSELLFIBRA: 'UPSELLFIBRA',
  VFCARE: 'VFCARE',
  secondResidences: '2NDHOME',
  LA_PEGA: 'SLUPSELLPEGA',
  TV_LA_PEGA: 'TVUPSELLPEGA',
  digitalKits: 'DIGITALKITS',
  F2PACK: 'F2PACK',
  energy: 'ENERGY'
};

export const pegaOffersIDs: { [key: string]: string } = {
  Offer30PCT: 'EB_P_30PCT_APP',
  Offer40PCT: 'EB_P_40PCT_APP',
  Offer50PCT: 'EB_P_50PCT_APP',
  Offer15PCT: 'EB_D_15PCT_BENEFIT_R_APP',
  ALMOUSTIN:  'LOY_HVC_INF_ALMOST_IN_APP',
  ALMOUSTOUT: 'LOY_HVC_INF_ALMOST_OUT_APP'
};

export const pegaOfferFields: { [key: string]: string } = {
  extraBalancePCT: 'EXTRA_BALANCE_GRANTED_PORC',
  pricePlanRate: 'TU_EFFORT_MIN',
  acceptanceDate: 'DATE_END_ACCEPTANCE_REMAIN',
  pricePlanRateFiftenPCT: 'PRICE_PLAN_RATE',
  platinumMinRecharge: 'TU_EFFORT_PEND'
};

export const serviciabilityErrorMTP: Record<string, number> = {
  Zero: 0,
  One: 1,
  Two: 2,
  Three: 3
};
export const VisaFirstDigit: string = '4';
export const MasterCardFirstDigit: string = '5';

export const onePlusProducts: OnePlusProductsModel = {
  connectivity: [ServiceType.Fibre.toUpperCase(), ServiceType.Landline.toUpperCase(),
    ServiceType.Postpaid.toUpperCase()],
  security: [ProductType.secureNet],
  content: []
};

export const assessorRateusParams: Record<string, string> = {
  clientId: 'clientId=%s',
  timed: 'timemed=%s'
};


export const manageWifi: Record<string, string> = {
  name: 'nombre',
  channel: 'canal',
  password: 'contraseña',
  securitySettings: 'tipo de seguridad'
};

export const networkState: Record<string, string> = {
  active: 'activar red',
  desactive: 'desactivar red',
};
export const prepaidTypes: Record<string, string> = {
  data: 'datos',
  voice: 'llamadas',
  sms: 'sms'
};
export const deExpiryDate: string = 'de';

export const TABLET_BREAKPOINT: number = 768;

export const billingTileChart: Record<string, number> = {
  maxBills: 7
};

export const MessageCode0: number = 0;
export const MessageCode2: number = 2;

export enum iotProductType {
  connectedCar = 'vauto',
  vHomeMini = 'vhome'
}

export enum iotCodes {
  connectedCar = 'VCARC',
  vHomeMini = 'VHOMI'
}

export const oneNumber: Record<string, string[]> = {
  codes: ['CMUF1', 'CMUF2', 'CMUFA', 'CMM03', 'CMUFG'],
  validServices: ['TPVHI', 'TPHIG']
};

export const movistarEstrenos: Record<string, string[]> = {
  codes: ['TVMES']
};

export const digitalSecurity: TicketDefinesModel = {
  codes: ['CBSM1'],
  ticket: {
    faultyCode: 'FaultyCode',
    triplet: 'Triplet',
    contactMethod: 'ContactMethod'
  },
  wcsBase: 'digitalSecurity',
};

export const digitalBusiness: TicketDefinesModel = {
  codes: ['PSTM1', 'NEGODIG'],
  ticket: {
    faultyCode: 'FaultyCode',
    triplet: 'Triplet',
    contactMethod: 'ContactMethod'
  },
  wcsBase: 'digitalBusiness'
};

export const genericTicket: TicketDefinesModel = {
  ticket: {
    faultyCode: 'FaultyCode',
    triplet: 'Triplet',
    contactMethod: 'ContactMethod'
  }
};

export enum onePlusSvaStates {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  request = 'REQUEST',
  inProgress = 'IN PROGRESS',
  ticket = 'TICKET',
  configuration = 'CONFIGURATION',
  ticketInProgress = 'TICKET IN PROGRESS'
}

export const superWifiCode: string = 'SUPWI';

export const SuperWifiCodes: Record<string, {ecode105: number}> = {
  ecode: {
    ecode105: 105
  }
};

export const CRMCases: Record<string, number> = {
  cases0: 0,  // Default value
  cases1: 1,  // Mobile and tariff TOTAL
  cases2: 2,  // Mobile and tariff SUPER
  cases3: 3,  // Mobile and tariff ILIMITADA
  cases4: 4,  // TV
  cases5: 5   // INTERNET
};

export const MigrationTypeSpeed: { [key: string]: string } = {
  total: 'total',
  super: 'super'
};

export const ABONOS: Record<string, string> = {
  withOutCreditNotes: 'sin notas de abono',
  withCreditNotes: 'con notas de abono'
};

export const entryAnalytics: Record<string, string> = {
  canje: 'canje terminales',
  lineas: 'lineas adicionales',
  fromEntry: ' de entrypoint',
  movilToPack: 'movil to pack'
};
export const LeftMenuIconsName: Record<string, string> = {
  menu: 'icon-menu',
  products: 'icon-connected-devices',
  myAccount: 'icon-admin',
  forYou: 'icon-all-rewards',
  shop: 'icon-shopping-trolley',
  topUps: 'icon-top-up',
  oneProfessional: 'icon-add-ons-boosts',
  kitDigital: 'icon-add-ons-boosts',
};
export const isSafariScreen: string = 'true';

export const tagEventValues: Record<string, string> = {
  event10: 'event10',
  event11: 'event11',
  event12: 'event12',
  event16: 'event16',
  event70: 'event70',
  event75: 'event75',
  event100: 'event100',
  event101: 'event101',
  event102: 'event102',
  event105: 'event105',
  event106: 'event106',
  scAdd: 'scAdd',
};

export const onePlusRecommender: OnePlusRecommenderModel = {
  section: {
    connectivity: 'connectivity',
    entertainment: 'entertainment',
    security: 'security'
  },
  unlimited: 'TPILT',
  exceptions: ['SUPWI', 'COPAP', 'VHOMI', 'VCARC', 'TVSBA', 'CMUF1', 'CMUF2', 'CMUFA', 'CMM03', ' CMUFG', 'SNMOP', 'SNFIP'],
  activationRecommenderFilter: ['TVSBA'],
  comparation: {
    new: 'new',
    same: 'same',
    improved: 'improved'
  },
  tvPackage: 'TVPLU',
  liteTvPackages: ['TVPL1', 'TVPL2'],
  keepList: {
    entertainment: ['N6H14', 'FILMN'],
    oneNumber: ['CMUF1', 'CMUF2', 'CMUFA', 'CMM03', 'CMUFG']
  }
};

export const DiscountRenewal: DiscountRenewalDefinesModel = {
  discountRenewalType: {
    migration: 'Migration',
    origin: 'Origin',
    totalStartIncompatible: 'TotalStartIncompatible',
    totalIncompatible: 'TotalIncompatible',
    tecnical: 'tecnico'
  },
  ecode: {
    ecode105: 105
  },
  fields: 'RENEWAL',
  experience: 'experiencia por defecto',
  trastienda: 'trastienda',
  dxl: 'dxl',
  otros: 'otros',
  error: 'error',
  euroPerMonth: '€/mes'
};

export const tagPageTypology: Record<string, string> = {
  screen: 'screen',
  overlay: 'overlay',
  nudge: 'nudge',
  backdrop: 'backdrop'
};

export const entrypointListTemplate: string = 'es=<section>|el=<location>|ep=<position>|jn=<journey>|en=<experience>|et=<title>|ety=<type>';
export const entrypointPegaTemplate: string = 'enm=<name>|elb=<label>|eis=<issue>|egn=<groupnba>|jn=<journey_name>|el=<location>|ec=<entrypoint_code>';

export const secureNetFamily: SecureNetFamilyModel = {
  codes: ['COPAP'],
  genericCode: 'SECURE-NET'
};

export const secureNet: Record<string, string[]> = {
  codesExceptionON15: ['SNFIP']
};

export const securenetTag: string = 'securenet';

export const securenetFamilyTag: string = 'securenet family';


export enum typeOnePlus {
  connectivity = 'connectivity',
  security = 'security',
  content = 'content',
  extras = 'extras'
}
export const whatHappenedCardsOrder: Record<string, number> = {
  [ChevronTypeWhatHappened.AutoMigration]: 0,
  [ChevronTypeWhatHappened.M4M]: 1,
  [ChevronTypeWhatHappened.New]: 2,
  [ChevronTypeWhatHappened.Obb]: 3,
  [ChevronTypeWhatHappened.Discounts]: 4,
  [ChevronTypeWhatHappened.Refunds]: 5,
  [ChevronTypeWhatHappened.Financing]: 6,
  [ChevronTypeWhatHappened.ThirdParties]: 7,
  [ChevronTypeWhatHappened.Premium]: 8,
};

export const billChangesConceptsOrder: Record<string, number> = {
  [NewServiceTypeWhatHappened.TariffChange]: 0,
  [NewServiceTypeWhatHappened.NewProduct]: 1,
  [NewServiceTypeWhatHappened.Other]: 2
};

export const leftMenuProductsContainer: Record<string, number> = {
  maxHeight: 250
};

export const emailStatus: Record<string, string> = {
  canceled: 'canceled',
  pending: 'pending',
  finished: 'finished',
  expired: 'expired'
};

/**
 * Different values to be used in FormatDatePipe
 */
export enum DatePipeFormat {
  defaultInputFormat = 'DD/MM/YYYY',
  defaultWithShortYear = 'DD/MM/YY',
  dayAndMonth = 'D/MM',
  dayMonthWithFullYear = 'D/MM/YYYY',
  alternativeDayMonthYear = 'dd/MM/yyyy',
  dayMonthWithShortYear = 'D/MM/YY',
  time = 'H:mm',
  timeFullHour = 'HH:mm',
  dayLongMonthAndYear = 'D MMMM YYYY',
  dayShortMonthAndYear = 'D MMM YYYY',
  dayLongMonth = 'D MMMM',
  dayShortMonth = 'D MMM',
  shortMonthAndYear = 'MMM YYYY',
  legibleFullDate = 'dddd, D/MM/YYYY  H:mm',
  timeAndFullDate = 'H:mm, D/MM/YYYY',
  dayName = 'dddd',
  dayNumber = 'D',
  monthName = 'MMMM',
  fullDayAndMonth = 'dddd, D [de] MMMM',
  timezoneDate = 'YYYY-MM-DDTHH:mm',
  dateHyphens = 'YYYY-MM-DD',
  fullDateAndTime = 'DD/MM/YYYY HH:mm:ss',
}

export const datePipeErrors: { [errorType: string]: string } = {
  missingDate: 'MISSING DATE',
  missingOutputFormat: 'MISSING OUTPUT FORMAT',
  invalidInputFormat: 'INVALID INPUT FORMAT',
  invalidOutputFormat: 'INVALID OUTPUT FORMAT'
};

export const dfnsExperiencieVersion: Record<string, string> = {
  experienceVersionA: 'A',
  experienceVersionB: 'B',
  experienceVersionC: 'C',
  experienceNameA: 'Version A',
  experienceNameB: 'Version B',
  tableColourA: '#e60000',
};

export const CPPTransational: Record<string, string> = {
  eservice: 'eservice',
  transaccional: 'transaccional',
  deepLink: 'cn',
  funtionError: 'funtionError',
  tecnicalError: 'tecnicalError',
  emailError: 'emailError',
  serviciabilityError: 'serviciabilityError',
  MVBAM: 'MVBAM'
};
export const maxYearToConsiderPermanentDiscount: number = 2030;
export const minYearToConsiderPermanentDiscount: number = 1753;

export const checkout: CheckoutModel = {
  steps: {
    envio: 'Datos de envío',
    resumen: 'Resumen',
    payments: 'pago',
    installation: 'Instalación'
  },
  back: 'back',
  quit: 'quit',
  icons: {
    home: 'icon-home',
    store: 'icon-vodafone-store',
    office: 'icon-sme-dev'
  },
  boxes: {
    mobile: 'icon-mobile-mid',
    details: 'Detalles del móvil',
    info: 'Más info',
    monthly: '/mes ({0} meses)',
    document: 'icon-document',
    asociated: 'icon-link_insert_edit',
    discount: 'icon-offer-badge-percent',
    sim: 'icon-sim',
    iconMid: 'icon-sim-mid',
    iconFiber: 'icon-bundles-mid',
    iconHomeMid: 'icon-home-mid',
    iconSimSwapMid: 'icon-sim-swap-mid',
    iconTVMid: 'icon-tv-mid',
    div: ' - ',
    genericIconDevices: 'icon-connected-devices-mid'
  },
  promotions: {
    portability: '<p>Asociado a {0}</p><p>{1}</p>',
    porta: 'Portabilidad',
    alta: 'Alta nueva',
    line: 'Línea'
  },
  summary: {
    paymentType: {
      monthly: 'monthly',
      initial: 'initial',
      final: 'final',
      tv: 'tv',
      deco: 'deco'
    },
    shoppingCartNameVar: 'shoppingCart',
    orderTypeParameterName: 'orderType',
    finalPaymentMonthly: {
      unit: '/mes'
    },
    ampersandAddParameter: '&',
    questionMarkParameter: '?',
    step_legalAcceptances: 'step=RESUMEN_MIVF',
    step_legalAcceptances_tvtx: 'step=RESUMEN_MIVF_TV',
    legalTermsVarName: 'legalTerms',
    legalAcceptances_DescriptionLinkSeparator: '. ',
    legalAcceptances_HTMLLink: '<a href="{0}" target="_blank">{1}</a>',
    legalAcceptancesForFinalizeOrderButtonMarginPx: '{0}px',
    legalAcceptancesOffsetForFinalizeOrderButtonMargin: 0,
    downloadPdfButtonMarginPx: '{0}px',
    downloadPdfOffsetForFinalizeOrderButtonMargin: 0,
    orderUnsubscribeServices: 'Z',
    iconAppChannelVariety: 'icon-app-channel-variety',
    legalAcceptances_param: 'CHECKOUT_RENO'
  }
};

export const errorList: Record<string, string> = {
  et: 'et',
  ec: 'ec',
  em: 'em',
  ed: 'ed',
  server: 'tecnico:servidor',
  funcional: 'funcional',
  total: 'total',
  parcial: 'parcial'
};

export const dmpButtonTypes: Record<string, string> = {
  primary: 'primary',
  secondary: 'secondary',
  secondaryDark: 'secondary dark',
  tertiary: 'tertiary',
  tertiaryDark: 'tertiary dark'
};

export const cardOfferBgColors: Record<string, string> = {
  grey: 'grey',
  white: 'white'
};

export const delightTv: string = 'DELIGHTTV';

export const dmpBannerStyles: Record<string, string> = {
  ds1: 'ds1',
  ds2: 'ds2',
  ds3: 'ds3'
};

export const NTOL_BASE_URL: string = 'https://yu.vodafone.es/srv/vf-back-digital/api/';

export const vodafonees_url: string = 'https://www.vodafone.es/c/particulares/es/';

export const login_seibel_url: string = 'https://m.vodafone.es/portalHTML5';


export enum IdentificationType {
  NIF = 1,
  NIE = 2,
  PASSPORT = 4
}
export enum osName {
  mac = 'mac',
  android = 'android',
  windows = 'windows'
}
export enum deviceName {
  ipad = 'ipad'
}
export const macPlatform: string = 'MacIntel';
export const ontouchstart: string = 'ontouchstart';

export const BUY_SIM_JOURNEY: BuySimJourneyDefinesModel = {
  spainNationalityId: 36,
  IdentificationTypeNIF: 'NIF',
  IdentificationTypeNIE: 'NIE',
  portability: 'portability',
  newNumber: 'newNumber',
  statusOk: 0,
  defaultTariffName: 'tarifa-prepago-s',
  saveDeliveryDataRequestObj: {
    'xCosteEur': 0,
    'flagPrepaid': true,
    'cdTipoDeliveryType': 'DOMICILIO',
    'isinstallationaddress': '',
    'fcEntregaPedido': '2020-07-29T22:00:00Z',
    'address': {
      'type': '',
      'name': '',
      'number': '',
      'stairway': '',
      'floor': '',
      'door': '',
      'num': null,
      'duplicate': null,
      'block': null,
      'identificador': null,
      'letter': null,
      'hand1': null,
      'hand2': null,
      'descriptionNumber': '',
      'descriptionStair': '',
      'descriptionFloor': '',
      'descriptionDoor': '',
      'otherdoor': null,
      'postcode': '',
      'province': 0,
      'town': '',
      'verticalId': '',
      'postCodeDelivery': '',
      'rateType': 2
    },
    'xPerfil': 'FRONT WEB',
    'xCanal': 'ONLINE',
    'xTipoCliente': 'PARTICULAR',
    'xTipoProceso': 'ALTA NUEVA SIM/ONLY',
    'cdTipoProceso': '16',
    'xDestEntrega': 'DOMICILIO',
    'xCostePromoEur': 0,
    'dsCosteOperador': null,
    'xDsOpcEntrega': 'Normal 9-19h',
    'dsOpcEntrega': 'Normal 9-19h',
    'xOpcEntrega': 'B5I',
    'xIdentificacion': 'Identificado',
    'xPrioridadEntrega': '3',
    'idModalidadEntrega': '322300',
    'idTipoEntrega': '11006',
    'cdCatalogOfferCharge': null,
    'cdCatalogOfferDiscount': null,
    'dsCatalogOfferChargeName': null,
    'dsCatalogOfferDiscountName': null,
    'euCatalogOfferCharge': null,
    'euCatalogOfferDiscount': null,
    'textHtmlDescuentoTicket': null,
    'textHtmlDescuentoEmailConfirmacion': null,
    'textHtmlDescuentoPaginaConfirmacion': null,
    'textHtmlUrlModalidadesEmailConfirmacion': null,
    'textHtmlUrlModalidadesPaginaConfirmacion': null,
    'itDeliveryModalityNoNext': false,
    'flagTieneError': false,
    'fcPortabilidad': null,
    'itPortabilidad': false,
    'xDiaEntrega': null,
    'xHoraLiberacion': null,
    'xSlaFraude': null
  },
  addressDropDownLists: {
    number: [{
      'value': '1',
      'name': 'Seleciona una opción',
      'checked': true
    }],
    stair: [{
      'value': '1',
      'name': 'Seleciona una opción',
      'checked': true
    }],
    floor: [{
      'value': '1',
      'name': 'Seleciona una opción',
      'checked': true
    }],
    door: [{
      'value': '1',
      'name': 'Seleciona una opción',
      'checked': true
    }]
  },
  addressComponentsType: {
    provinceId: 'administrative_area_level_2',
    cityId: 'administrative_area_level_3',
    postalCode: 'postal_code',
    streetId: 'route'
  },
  addressTextElementsKeys: ['postalCode', 'door', 'floor', 'stair', 'number'],
  documentIdTypes: [
    {
      'value': '1',
      'name': 'DNI',
      'checked': true
    },
    {
      'value': '2',
      'name': 'NIE',
      'checked': false
    },
    {
      'value': '3',
      'name': 'Pasaporte',
      'checked': false
    }
  ],
  portabilityInitialAmount: '0',
  paymentPendingAmount: '?',
  passportBlackListValues: [
    '999999999',
    '99999999R',
    '000000000'
  ],
  buySimInitialBalance: '0 €',
};
export const ICCIDLength: number = 19;
export const ActualNumberLength: number = 9;
export const NIEOrNIFLength: number = 9;

export const legalTermsPageName: Record<string, string> = {
  personelInfo: 'DATOS_PERSONALES_PREPAGO',
  payment: 'CHECKOUT_PREPAGO'
};

export const saveOfferReqBody: SaveOfferReqBodyDefinesModel = {
  rateType: 2,
  rateCode: '',
  contractType: 1,
  registerTypeMobile: 0,
  registerTypeInternet: 0,
  operatorLandLine: null,
  operatorMobile: null,
  portaTitularLineaFijo: false,
  portaTitularLineaMovil: false,
  portaNombreLineaMovil: null,
  portaPrimerApellidoLineaMovil: null,
  portaSegundoApellidoLineaMovil: null,
  portaNumeroMovil: null,
  identificationTypeLineaMovil: null,
  identificationNumberLineaMovil: null,
  portaNumeroICCID: null
};

export const submitOrderReqBody: SubmitOrderReqBodyModel = {
  tipificacion: 'CHECKOUT_TERM_LEGAL_DIGITAL',
  idPedido: 0
};
export const ExitButtonText: Record<string, string> = {
  exit: 'salir',
};

export const ChatText: { chat: string } = {
  chat: 'chat',
};

export const provincias_LIST: ProvinciasListModel = {
  'provincias': [
    {
      'value': '1',
      'name': 'Araba/Álava',
      'checked': false
    },
    {
      'value': '2',
      'name': 'Albacete',
      'checked': false

    },
    {
      'value': '3',
      'name': 'Alicante/Alacant',
      'checked': false

    },
    {
      'value': '4',
      'name': 'Almería',
      'checked': false
    },
    {
      'value': '5',
      'name': 'Ávila',
      'checked': false
    },
    {
      'value': '6',
      'name': 'Badajoz',
      'checked': false
    },
    {
      'value': '7',
      'name': 'Balears, Illes',
      'checked': false
    },
    {
      'value': '8',
      'name': 'Barcelona',
      'checked': false
    },
    {
      'value': '9',
      'name': 'Burgos',
      'checked': false
    },
    {
      'value': '10',
      'name': 'Cáceres',
      'checked': false
    },
    {
      'value': '11',
      'name': 'Cádiz',
      'checked': false
    },
    {
      'value': '12',
      'name': 'Castellón/Castelló',
      'checked': false
    },
    {
      'value': '13',
      'name': 'Ciudad Real',
      'checked': false
    },
    {
      'value': '14',
      'name': 'Córdoba',
      'checked': false
    },
    {
      'value': '15',
      'name': 'Coruña, A Código Literal',
      'checked': false
    },
    {
      'value': '16',
      'name': 'Cuenca',
      'checked': false
    },
    {
      'value': '17',
      'name': 'Girona',
      'checked': false
    },
    {
      'value': '18',
      'name': 'Granada',
      'checked': false
    },
    {
      'value': '19',
      'name': 'Castellón/Guadalajara',
      'checked': false
    },
    {
      'value': '20',
      'name': 'Gipuzkoa',
      'checked': false
    },
    {
      'value': '21',
      'name': 'Huelva',
      'checked': false
    },
    {
      'value': '22',
      'name': 'Huesca',
      'checked': false
    },
    {
      'value': '23',
      'name': 'Jaén',
      'checked': false
    },
    {
      'value': '24',
      'name': 'León',
      'checked': false
    },
    {
      'value': '25',
      'name': 'Lleida',
      'checked': false
    },
    {
      'value': '26',
      'name': 'Rioja, La',
      'checked': false
    },
    {
      'value': '27',
      'name': 'Lugo',
      'checked': false
    },
    {
      'value': '28',
      'name': 'Madrid',
      'checked': false
    },
    {
      'value': '29',
      'name': 'Málaga',
      'checked': false
    },
    {
      'value': '30',
      'name': 'Murcia',
      'checked': false
    },
    {
      'value': '30',
      'name': 'Gipuzkoa',
      'checked': false
    },
    {
      'value': '31',
      'name': 'Navarra',
      'checked': false
    },
    {
      'value': '32',
      'name': 'Ourense',
      'checked': false
    },
    {
      'value': '33',
      'name': 'Asturias',
      'checked': false
    },
    {
      'value': '34',
      'name': 'Palencia Código	Literal',
      'checked': false
    },
    {
      'value': '35',
      'name': 'Palmas, Las',
      'checked': false
    },
    {
      'value': '36',
      'name': 'Pontevedra',
      'checked': false
    },
    {
      'value': '37',
      'name': 'Salamanca',
      'checked': false
    },
    {
      'value': '38',
      'name': 'Santa Cruz de Tenerife',
      'checked': false
    },
    {
      'value': '39',
      'name': 'Cantabria',
      'checked': false
    },
    {
      'value': '40',
      'name': 'Segovia',
      'checked': false
    },
    {
      'value': '41',
      'name': 'Sevilla',
      'checked': false
    },
    {
      'value': '42',
      'name': 'Soria',
      'checked': false
    },
    {
      'value': '43',
      'name': 'Tarragona',
      'checked': false
    },
    {
      'value': '44',
      'name': 'Teruel',
      'checked': false
    },
    {
      'value': '45',
      'name': 'Toledo',
      'checked': false
    },
    {
      'value': '46',
      'name': 'Valencia/València',
      'checked': false
    },
    {
      'value': '47',
      'name': 'Valladolid',
      'checked': false
    },
    {
      'value': '48',
      'name': 'Bizkaia',
      'checked': false
    },
    {
      'value': '49',
      'name': 'Zamora',
      'checked': false
    },
    {
      'value': '50',
      'name': 'Zaragoza',
      'checked': false
    },
    {
      'value': '51',
      'name': 'Ceuta',
      'checked': false
    },
    {
      'value': '52',
      'name': 'Melilla',
      'checked': false
    }
  ]
};

export enum surveyAnswer {
  affirmative = 'ENCUESTASI',
  negative = 'ENCUESTANO'
}

export const returnFormOvelay: Record<string, string> = {
  rewards: 'rewards',
  trayLeft: 'trayLeft',
  billing: 'billing',
  discoverTile: 'discoverTile',
  onBoarding: 'onBoarding',
  navigateTo: 'navigateTo',
  dmpClicked: 'dmpClicked'
};

export const modeCarrouselDsl: { [key: string]: string } = {
  tiny: 'tiny'
};

export const pageNameVerifyAccount: Record<string, string> = {
  factura: 'factura:',
  mis_productos: 'mis_productos:',
  tienda: 'tienda:',
  entrypoint: 'entrypoint:',
  entrypoint_personalizacion: 'entrypoint personalizacion:'
};

export const taggingLiterals: { [key: string]: string } = {
  error_list: 'et={error_type}|el={error_location}|ec={error_code}|em={error_module}|eh={http_status}|ed={error_description}',
  cancel: 'cancelar',
  call: 'llamar',
  change: 'cambiar',
  clickMoreInfo: 'click en mas informacion',
  nifPageName: 'mi cuenta:mis datos:cambiar documento de identidad',
  noPhone: 'sin telefono de contacto',
  logout: 'cerrar sesion',
  emailSent: 'email confirmacion enviado',
  clickShowPermits: 'click en ver detalle permisos y autorizaciones',
  verifyAddress: 'verificar direccion',
  clickInstallAddress: 'click en ver informacion direccion de instalacion',
  technical: 'tecnico',
  functional: 'funcional',
  serviciability: 'serviciabilidad'
};

export const benefitRenewelSmartPayTagging: Record<string, string> = {
  journey_type_plan: 'smartpay si',
  journey_topup_amount_With_Extras: 'saldo suficiente renovar extras si',
  journey_topup_amount_With_NoExtras: 'saldo suficiente renovar extras no'
};

export const benefitRenewelNonSmartPayTagging: Record<string, string> = {
  journey_topup_amount_With_NoExtras: 'saldo suficiente renovar tarifa si',
  journey_topup_amount_With_Extras: 'saldo suficiente renovar tarifa si_saldo suficiente renovar extras si',
  journey_topup_amount_With_tarrif_balance: 'saldo suficiente renovar tarifa no_saldo suficiente renovar extras no' ,
  journey_topup_amount_With_extras_balance: 'saldo suficiente renovar tarifa no',
  journey_process_Nobalance: 'renovacion no disponible',
  journey_process_balance: 'renovacion disponible sin extras',
};

export const benefitRenewelSuccessScreenTagging: Record<string, string> = {
  event_context: 'renovar beneficios ok',
  journey_bundle: 'chat Pass_bono extra 5 GB'
};

export const autoInstallCode: string = 'AUTOI';

export const multifinancingKeyNames: { marcaterminal: string, modeloterminal: string, subscriber: string } = {
  marcaterminal: 'marcaterminal',
  modeloterminal: 'modeloterminal',
  subscriber: 'subscriber',
};

export enum amortizeTypes {
  total = 'delete.total',
  parcial = 'delete.parcial',
  refin = 'modify'
}

export const Backspace: string = 'Backspace';
export const Espana: string = 'España';
export const NoDISPONIBLE: string = 'NO DISPONIBLE';
export const migrationTariffCode: Record<string, string> = {
  TPMMD: 'TPMMD',
  TPMID: 'TPMID',
  Super_on19: 'Movil Ilim Super_on19'
};

export const screenCode: { [key: string]: string } = {
  DSR: 'DSR',
  HIU: 'HIU',
  PX2: 'PX2'
};

export const tvMultidispositivoAppsUrls: Record<string, string> = {
  android: 'https://play.google.com/store/apps/details?id=es.vodafone.tvonline',
  ios: 'https://apps.apple.com/es/app/vodafone-tv/id1043023087',
};

export const PREFIJO: string = 'Prefijo';
export const MVA10CarouselConfigObj: MVA10CarouselConfigObjModel = {
  'responsive': {
    '0': {
      'breakpoint': 0,
      'elements': 1
    },
    '1': {
      'breakpoint': 769,
      'elements': 1
    },
    '2': {
      'breakpoint': 1025,
      'elements': 1,
      'arrows': true
    }
  },
  'build': {
    'arrows': false,
    'bullets': true,
    'clickable_bullets': true
  }
};

export const size290: number = 290;
export const size420: number = 420;

export const ContractsTabsTaggingName: { [key: number]: string } = {
  0: 'contratos',
  1: 'documentos'
};

export const ContractsDownloadTaggingName: { [key: string]: string } = {
  contracts: 'contrato',
  documents: 'documento'
};

export const HttpOk: number = 200;

export const CaptureResponseActions: { [key: string]: string } = {
  click: 'Click',
  accepted: 'Accepted',
  rejected: 'Rejected'
};

export const entrypointType: { [key: string]: string } = {
  reco: 'reco',
  target: 'target',
  pega: 'pega',
  manual: 'manual'
};

export const billingServiceApiVersion: string = '2.0';

export const LegoPack: LegoPackModel = {
  codes: ['VEME1', 'VEME2', 'VEME3', 'VEME4'],
  serviceType: 'OneProfesional'
};

export const maxNumberOperator: number = 999999;

export const newLineOverlay: Record<string, string> = {
  overlays: 'overlays',
  overlaysMicro: 'overlaysMicro'
};

export const dataToMF: { [key: string]: string } = {
  allowedServices: 'allowedServices',
  tokens: 'tokens',
  entryPoints: 'entryPoints',
  screenCode: 'screenCode',
  reset: 'reset'
};

export const carouselConfig: { [key: string]: string } = {
  firstConfig: '{"responsive":{"0":{"breakpoint":0,"elements":2,"arrows":false}, "1":{"breakpoint":769,"elements":4,"arrows":true},' +
    '"2":{"breakpoint":1025,"elements":4.5, "arrows":true}}, "build":{"arrows":true, "bullets":false, "clickable_bullets":true}}',
  secondConfig: '{"responsive":{"0":{"breakpoint":0,"elements":2,"arrows":false}, "1":{"breakpoint":769,"elements":5.4,"arrows":true},' +
    '"2":{"breakpoint":1025,"elements":5.4, "arrows":true}}, "build":{"arrows":true, "bullets":false, "clickable_bullets":true}}',
  thirdConfig: '{"responsive":{"0":{"breakpoint":0,"elements":1}, "1":{"breakpoint":769,"elements":2},' +
    '"2":{"breakpoint":1025,"elements":2, "arrows":true}}, "build":{"arrows":true, "bullets":false, "clickable_bullets":true}}'
};

/**
 * Maps ServiceType enum (in lowercase) into values used in WCS key 'v10.dashboard.gestion.config'
 */
export const serviceTypeWCSMap: { [serviceType: string]: string } = {
  [ServiceType.Postpaid.toLowerCase().trim()]: 'mobile_postpaid',
  [ServiceType.Prepaid.toLowerCase().trim()]: 'mobile_prepaid',
  [ServiceType.MbbPostpaid.toLowerCase().trim()]: 'mbb_postpaid',
  [ServiceType.MbbPrepaid.toLowerCase().trim()]: 'mbb_prepaid',
  [ServiceType.Landline.toLowerCase().trim()]: 'fijo',
  [ServiceType.Tv.toLowerCase().trim()]: 'tv',
  [ServiceType.ADSL.toLowerCase().trim()]: 'adsl',
  [ServiceType.Fibre.toLowerCase().trim()]: 'fibra'
};

/**
 * Contract types to be considered as expenses to show them in CcServiceSelectorComponent
 */
export const expenseContractTypes: ContractType[] = [ContractType.cargo_inst];

/**
 * Maps ServiceType enum (in lowercase) into iconSvgPath values
 */
export const serviceTypeIconSvgMap: { [serviceType: string]: string } = {
  [ServiceType.Postpaid.toLocaleLowerCase()]: iconSvgPath.icon_mobile_postpaid,
  [ServiceType.Prepaid.toLocaleLowerCase()]: iconSvgPath.icon_mobile_prepaid,
  [ServiceType.MbbPostpaid.toLocaleLowerCase()]: iconSvgPath.icon_mbb,
  [ServiceType.MbbPrepaid.toLocaleLowerCase()]: iconSvgPath.icon_mbb,
  [ServiceType.Tv.toLocaleLowerCase()]: iconSvgPath.icon_tv,
  [ServiceType.Landline.toLocaleLowerCase()]: iconSvgPath.icon_busines_phone,
  [ServiceType.ADSL.toLocaleLowerCase()]: iconSvgPath.icon_adsl,
  [ServiceType.Fibre.toLocaleLowerCase()]: iconSvgPath.icon_internet,
  [ServiceType.Internet.toLocaleLowerCase()]: iconSvgPath.icon_broadband_or_wifi,
  [ServiceType.VodafoneEnTuCasa.toLocaleLowerCase()]: iconSvgPath.icon_busines_phone
};

export const subscriptionsOnline: { purchases: string, subscriptions: string } = {
  purchases: 'compras',
  subscriptions: 'suscripciones'
};

/**
 * Pages which are allowed to be accessed when a pending install site is selected
 */
export const allowedPendingInstallRoutes: string[] = [
  config.dashboard.route,
  config.myorders.route,
  config.myAccount.route,
  config.licenses.route,
  config.myAccount.details.route,
  config.myAccount.contracts.route
];

export const Necesito: string = 'Necesito';
export const Estoy: string = 'Estoy';
export const Nacional: string = 'nacional';
export const Linea: string = 'línea';
export const Extranjero: string = 'extranjero';


export const Roaming: { [key: string]: string } = {
  ZoneOne: '1',
  ZoneTwo: '2',
  // zone 0 when origin and destination countries are from zone 1
  ZoneZero: '0'
};
export const roamingProductmangment: RoamingProductmangmentDefinesModel = {
  role: 'Customer',
  referredType: 'Individual',
  RoamingCategory: {
    id_value: 'Value',
    id_CatalogElement: 'CatalogElement',
    name: 'Mobile'
  },
  productOrderItemType: 'ProductOderItemExtended',
  instance: 'Instance'
};
export const roamingTarrifTypes: Record<string, string> = {
  unlimited: 'unlimited',
  limited: 'limited'
};

export const tryAndPayModals: Record<string, string> = {
  keep: 'keep',
  change: 'change'
};
export const variableDmpEPlist: string = 'es={es}|el={el}|ep={ep}|jn={jn}|en={en}|et={et}';

export const billedConsumptionServiceTagging: {
  [serviceType: string]: string;
} = {
  [ServiceType.Mobile.toLowerCase()]: 'movil pospago',
  [ServiceType.Postpaid.toLowerCase()]: 'movil pospago',
  [ServiceType.Landline.toLowerCase()]: 'fijo tradicional',
  [ServiceType.Tv.toLowerCase()]: 'television'
};

export const billedConsumptionCardTagging: {
  [bundleType: string]: string;
} = {
  [BillingBundleType.Voice.toLowerCase()]: 'llamadas',
  [BillingBundleType.Data.toLowerCase()]: 'datos',
  [BillingBundleType.SMS.toLowerCase()]: 'mensajes',
  [BillingBundleType.TV.toLowerCase()]: 'television'
};

export const TaggingVarsValues: Record<string, string> = {
  yes: 'yes',
  no: 'no',
  primario: 'primario',
  secundario: 'secundario'
};
export enum marketNames {
  googlePlay = 'google play',
  appStore = 'app store'
}

export enum routerTypes {
  routerType5 = '5',
  routerType6 = '6'
}

export enum superWifiTypes {
  superWifi5 = '5',
  superWifi6 = '6'
}

export const clienteExcepcionadoVoucher: string = 'clienteExcepcionado';
export const dummyID: string = '000000000'

export const contacts: string = 'contacts';
export const tel: string = 'tel';

export const extras: Record<string, string> = {
  monthly: 'monthly',
  active: 'active',
  emptyOfferCode: 'undefined',
  fee: 'fee'
};

export const sortFamilies: FamilySort = {
  asc: 'asc',
  desc: 'desc',
  alphabetical: 'alphabetical',
  number: 'number',
  value: 'value',
  standardValue: 'standardValue',
  color: 'color',
  defaultIcon: 'icon-mobile'
};
export const smartpayProductsAndServicesTagging: { [key: string]: string } = {
  activo: 'activo',
  noActivo: 'no activo',
  origen: 'PyS'
};

export const SmartPay: { [key: string]: string } = {
  positive: 'positive',
  small: 'small',
  neutral: 'neutral',
};
export const NAVIGATION_ERROR_CANT_MATCH_ROUTE: string[] = ['NG04002', 'Cannot match any routes'];
export const fault: string = 'Averías';

export const lowBarM: string = '_M';

export const lowBarSLA: string = '_SLA';

export const inDiagnostic: string = 'En diagnóstico';
export const cardTerminalColor: string = 'transparent';

export const inSolution: string = 'En solución';

export const faultDone: string = 'Avería solucionada';
export const nifPrefix: string = ':NIF-';
export const MSISDNPrefix: string = '34';
export const NIFPREFIX: string = 'NIF-';

export const backdropKey: string = 'backdrop';

export const packageUpgrade: { [key: string]: string } = {
  linesOP: 'lineas',
  packageOP: 'paquete'
};
export const terminalListCharacteristics: Record<string, Record<string, string>> = {
  sortMethod: {
    number: 'number',
    alphabetical: 'alphabetical'
  },
  sortType: {
    asc: 'asc',
    desc: 'desc'
  },
  characteristicCode: {
    color: 'color'
  }
};
export const backButton: string = 'backButton';

export const idInTariffChangeRequest: string = 'prepaid-price-plan-change-{0}-{1}';
export const add: string = 'add';
export const targetUrl: string = 'targetUrl';
export const bundleupsell: BundleupsellDefinesModel = {
  fields: 'UPSELLPEGA',
  exitProccess: {
    init: 'init',
    continue: 'continue',
    exit: 'exit'
  },
  badgeType: {
    new: 'new',
    same: 'same',
    improved: 'improved'
  },
  journeyType: {
    new: 'nuevo',
    portability: 'portabilidad'
  },
  offerType: {
    public: 'publica',
    pega : 'pega'
  }
};

export const upsell: {[key: string]: string} = {
  catalogServiceType: '6',
  comparation: 'upsell'
};

export const tariffCodes: Record<string, Record<string, string>[]> = {
  vodafonePrepagoS: [{
    code: 'PPMIN',
    name: 'Vodafone Prepago S'
  }],
  vodafonePrepagoL: [{
    code: 'PPTRV',
    name: 'Vodafone Prepago L'

  }],
  vodafonePrepagoXL: [{
    code: 'PPVXL',
    name: 'Vodafone Prepago XL'

  }],
  vodafonePrepagoXXL: [{
    code: 'PPXXL',
    name: 'Vodafone Prepago XXL'

  }],
  vodafonePrepagoM: [
  {
     code: 'PPVIS',
    name: 'Vodafone Prepago M'
  },
  {
    code: 'PPVSP',
    name: 'Vodafone Prepago M'
   }
  ],
  tarifaBigYuser: [{
    code: 'PPTDY'
  }],
  tarifaHeavyYuser: [{
    code: 'PPY25',
    name: 'Heavy Yuser'
  }],
  YUSER: [
    { code: 'PPIB1' },
    { code: 'PPIB4' },
    { code: 'PPIB7' },
  ],
  SUPERYUSER: [
    { code: 'PPIB2' },
    { code: 'PPIB5' },
    { code: 'PPIB8' },
  ],
  MEGAYUSER: [
    { code: 'PPIB3' },
    { code: 'PPIB6' },
    { code: 'PPIB9' },
  ],

};
export const COE: string = 'COE';
export const B5I: string = 'B5I';
export enum delightChecklist {
  checkVf = 'CHECKVF',
  checkThirds = 'CHECKTHIRDS'
}
export const upgradePackageEntrypoint: { [key: string]: string } = {
  upop2: 'UPOP2',
  upop3: 'UPOP3',
  laop4: 'LAOP4',
  hi2: 'hi 2.0',
  hi3: 'hi 3.0',
  hi4: 'hi 4.0',
  package2: '2.0',
  package3: '3.0',
  package4: '4.0'
};

export const asociatedUserPatch: { [key: string]: string } = {
  op: 'replace',
  path: '/status',
  value: 'DELETED'
};

export const smsPremiumName: string = 'SMS PREMIUM';

export const inApp: string = 'inApp';
export const externalUrl: string = 'externalUrl';

export const journeyTags: { [key: string]: string } = {
  start: 'start',
  confirmation: 'confirmacion',
  ok: 'ok',
  koPartial: 'ko parcial',
  home: 'home',
  prepaid: 'prepago',
  postpaid: 'postpago',
  pospaid: 'pospago',
  bussiness: 'empresas',
  particular: 'particulares',
  er: 'er',
  restricciones: 'restricciones',
  end: 'end'
};

export const lineTypes: { [key: string]: string } = {
  TPILB: 'TPILB',
  TPIL4: 'TPIL4',
  TPILT: 'TPILT',
};

export const smartPayOrigin: string = 'MoreInfo';
export const TroubleTicket: string = 'TroubleTicket';
export const vfesButtonPrimary: string = 'vfes-button--primary';

export const getPaymentMethods: { [key: string]: string} = {
  idTypePayment: 'idTypePayment',
  refound: 'Contra reembolso',
  card: 'Tarjeta de crédito',
  iconRefound: 'icon-note-euro',
  iconCard: 'icon-payment',
  tagView: 'tagView',
  tagBackDrop: 'tagBackDrop',
  tagContinue: 'tagContinue',
  tagRefound: 'contra reembolso',
  tagCard: 'tarjeta',
  infoRefound: 'icon-confidential',
  infoCard: 'icon-withdraw-cash'
};

export const secondResidences: { [key: string]: string} = {
  tariffCode: 'TMBB2',
  title: 'Línea de datos',
  categoryType: 'MODEM',
  socialRateType: '2'
};

export const smartPayErrors: { [key: string]: string} = {
  unexpected: '7051',
  retry: '1007',
  interventions: '6666',
  max: '7052',
  debt: '7033',
  advance: '7053'
};

export const dmpChanges: { [key: string]: string} = {
    products: 'products',
    DMproducts: 'DMproducts',
    entryPointsList: 'es=nexo|el=recomendador|ep=<entrypoint_position>|et=<entrypoint_title>'
};

export const registerTypeMobileList: {value: string; name: string; checked: boolean}[] =
  [
    {
      'value': '3',
      'name': 'Prepago',
      'checked': true
    },
    {
      'value': '2',
      'name': 'Contrato',
      'checked': false
    }
  ];

export const SEGMENT_MICRO: string = 'MICRO';
export const SEGMENT_PARTICULAR: string = 'PARTICULAR';
export const CLIENT_RS: string = 'RS';
export const CLIENT_ME: string = 'ME';
export const customerTypes: { [key: string]: string} = {
  AUTHORIZED: 'AUTHORIZED',
  CONSUMER: 'CONSUMER'
};
export const colors: Record<string, string> = {
  brownishGrey: 'anthracite',
  green: 'ui-green',
  white: 'blanco',
  blue: 'azul',
  MidGrey: 'mid-grey',
  Maroon: 'maroon'
};
export const centInEuro: string = '0.01' ;
export const newLinesPurchase: string = 'NewLinesPurchase';
export const undefinedType: string = 'undefined';
export const indefinidedType: string = 'Indefinida';
export const netPlusErrorName: string = '<netplus error code/dxl error code>';
export const netPlusErrormessage: string = '<error "mensaje">';

export const defaultSearchConstList: Record<string, number> = {
  PRO: 222095623,
  PRE: 135075742
};

export const htmlIdDefines: { [key: string]: string } = {
  comercialMobileTerminalListsearchbutton: 'searchbutton',
  comercialMobileTerminalListfinderID: 'finderID',
  newLinesTypeOfferSelectorHide: 'newLinesTypeOfferSelectorHide',
};

export const operatorConst: { [key: string]: string} = {
  vodafone: 'vodafone',
  VODAFONE: 'VODAFONE'
};

export const logInRedirectionData: LogInRedirectionModel = {
  formElement: 'form',
  inputElement: 'input',
  mapFormTarget: '_self',
  mapFormMethod: 'POST',
  textType: 'text',
  jwsName: 'jws',
  selectedCifName: 'selectedCif',
  authenticationFederateName: 'authenticationFederate',
  authenticationFederateValue: 'true',
  accessToken: 'access_token',
  refreshToken: 'refresh_token'

};

export const logInQueryErrorType: { [key: string]: string} = {
  logInQueryErrorNotAvailableCommon: 'common',
  logInQueryErrorNotAvailable111: '111',
  logInQueryErrorNoCompany112: '112',
  logInQueryErrorNoCompany113: '113',
  logInQueryErrorCifManagement116: '116',
  logInQueryErrorNoSites117: '117',
  logInQueryErrorNoCompany141: '141',
  logInQueryErrorDesktopNeeded: 'login.errorList.403.1201.richDescription',
  desktopNeeded: 'desktopNeeded',
};

export const attrCardOffers: { [key: string]: string} = {
  line: 'line',
  turquoise: 'turquoise',
  redViolet: 'red-violet',
  shadow: 'shadow'
};

export const statusOP: { [key: string]: string} = {
  active: 'activo',
  hireable: 'contratable',
  noHireable: 'no contratable'
};

export const logInQueryErrorMessage: { [key: string]: string} = {
  logInQueryErrorMessageNotAvailableCommon: 'v10.login.companyLoginErrors.common',
  logInQueryErrorMessageNotAvailable111: 'v10.login.companyLoginErrors.111',
  logInQueryErrorMessageNoCompany112: 'v10.login.companyLoginErrors.112',
  logInQueryErrorMessageNoCompany113: 'v10.login.companyLoginErrors.113',
  logInQueryErrorMessageCifManagement116: 'v10.login.companyLoginErrors.116',
  logInQueryErrorMessageNoSites117: 'v10.login.companyLoginErrors.117',
  logInQueryErrorMessageNoCompany141: 'v10.login.companyLoginErrors.141',
};
export const smartPayCreditCardDateCommonValues: { [key: string]: string} = {
  yearPrefix: '20',
  dayOne: '-01T00:00:00'
};

export const pagoRecurrenteF: string = 'F';

export const adaraValues: { [key: string]: string} = {
  CLIENT_ADARA: 'EN',
  adara: 'ADARA',
  adaraIcon: 'idea-or-innovation',
  telcoIcon: 'network-signal',
  serviceTab: 'pestaña servicios',
  event70: 'event70',
  originEP: 'EP',
  originShop: 'Shop',
  originLogin: 'Login',
  categoryAdara: 'Energy',
  adaraEventCloseAction: 'close',
  adaraEventCloseMessage: 'adara',
  originBills: 'Bills'
};
export const adaraEntrypointRequestMaxNumber: number = 99;

export const adaraParams: { [key: string]: string} = {
  ENCODING: 'UTF-8',
  ADARATOKENSCOPE: 'OPENID',
  AUTHORIZERESPONSETYPEVALUE: 'code',
  AUTHORIZECLIENTIDVALUE: 'adara',
  AUTHORIZESCOPEVALUE: 'OAUTH2_ON_BEHALF_OF ON_BEHALF_OF_ACTOR ON_BEHALF_OF_SUBJECT_JWT_ENABLED ES_USERINFO_ALL',
  AUTHORIZEURL: 'http://localhost:8080/openIDConnectAuthorizationCode/v1/authorize?response_type=code&client_id=',
  REDUCEDTOKENSCOPE: '',
  ACTORTOKEN: 'gDfTo1LIP9T6myLyigKGgZl24uGJdQMq'
};

export const adaraUrlParams: { [key: string]: string} = {
  redirectUri: '&redirect_uri=',
  redirectDestination: '3',
  redirectUriPurchase: 'purchase',
  redirectUriSelfcare: 'selfcare',
  redirectUriBilling: 'billing',
  redirectScope: '&scope=OPENID&id_token_hint=',
  redirectPrefix: '/authorize?response_type=code&client_id='
};

export const customerBillCharateristic: { [key: string]: string} = {
  category: 'Category',
  contentName: 'ContentName',
  eventStartDate: 'EventStartDate',
  deductAmount: 'DeductAmount',
  shopName: 'ShopName'
};

export const promotionsAligment: { [key: string]: string} = {
  horizontal: 'h',
  vertical: 'v'
};

export const typeTryPromo: { [key: string]: string} = {
  trybuyKeep: 'VOUCHER_TRYBUYKEEP',
  trybuyUpgrade: 'VOUCHER_TRYBUYDOWNGRADE',
  trypayKeep: 'VOUCHER_TRYPAYKEEP',
  trypayUpgrade: 'VOUCHER_TRYPAYDOWNGRADE'
};

export const yuAppearance: string = 'yu';

export const ContratsTyps: { [key: string]: string} = {
  OrderConfirm : 'OrderConfirm',
  Aceptos: 'Aceptos',
  COMUNICACION_CONTRATO: 'COMUNICACION-CONTRATO',
  SEPA: 'SEPA',
  AnexoRC_Contrato: 'AnexoRC_Contrato',
  Contrato: 'Contrato'
};
export const linkChevronAttr: string = 'normal';
export const pathOpProducts: string = '/opProducts/';
export const textBelowFullLoaderWidth: number = 184;
export const overlayPermanenceText: string = 'overlayPermanence';
export const textHogarIlimitable: string = 'Hogar Ilimitable';
export const AddCreditCardKeys: { [key: string]: string} = {
  inActiveCardInfo: 'inActiveCardInfo',
  addAnotherCreditCard: 'addAnotherCreditCard'
};

export const smartPayIdTypes: { [key: string]: string}  = {
  Passport: 'Passport',
  NIF: 'NIF',
  NIE: 'NIE'
};

export const documentTypeTranslated: { [key: string]: string}  = {
  Passport: 'Pasaporte',
  NIF: 'NIF',
  NIE: 'Tarjeta Residente',
  other: 'Otros'
};
export const packageType: string = 'package';

export const menuItemsNames: { [key: string]: string} = {
  OneNumber: 'onenumber'
};

export const mapHogarIlimitableVersions: { [key: string]: string} = {
  '2.0': '20',
  '3.0': '30',
  '4.0': '40'
};

export const homeZone: string = 'HomeZone';
export const movil: string = 'movil';
export const fibra: string = 'fibra';
export const tv: string = 'tv';
export const fibre: string = 'fibre';
export const mobilePostpaid: string = 'mobile postpaid';

export const al: string = ' al ';

export const upgrade: string = 'upgrade';

export const clickBackdropLA: string = 'click en ';

export const catalogServiceType: { [key: string]: string}  = {
  movil: '0',     // Mobile
  tv: '6',        // TV
  fibra: '5',     // Fibre
  movilInternet: '2',  // Home5G
  fijo: '4',      // Fijo
  mmb: '2',       // 5G y Movil
  sl: '999',        // SecondLine
};

export const errorFunction: string = 'error funcional';

export const billReviewTicketCreation: {[key: string]: string} = {
  Description: 'Peticion revision factura',
  TicketType: 'Peticion',
  Type: 'TroubleTicketExtended',
  ChannelID: 'ECARE',
  NoteID: 'Comment',
  Factura: 'Factura ',
  clientComment: 'Comentario del cliente: '
};
export const smartphoneRaffleCTAWidth: string = '145px';

export const HintLabelBackground: { [key: string]: string} = {
  whiteBackground: 'white-background',
  orangeBackground: 'orange-background'
};
export const NBAOverlayImage: { [key: string]: string } = {
 mobile: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/small-three-boys-at-skatepark-on-phones@2x.jpg',
 tabletsAndDesktop: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/group@2x.webp'
};
export const httpsConst: { [key: string]: string } = {
  http: 'http://',
  https: 'https://'
};
export const TariffErrorMessage: {[key: string]: string} = {
  siteOrServiceNotFound: 'Error in getting subscription-id or customer-account-id',
};
export const storiesDurationValues: { [key: string]: number} = {
  storyDuration: 150,
  intervalValue: 100,
};
export const storiesStringValues: { [key: string]: string} = {
  dashboardButtonClickEventName: 'dashboardButtonClick',
  dashboardStoryClickEventName: 'dashboardStoryClick',
  imageFormat: 'image',
  gifFormat: 'gif',
  videoFormat: 'video',
  undefinedFormat: 'undefined',
  countdownBg: 'white',
  countdownSize: 'small',
  btnUrl: 'url',
  btnLink: 'link',
  carouselConfig: '{"responsive":{"0":{"breakpoint":0,"elements":1},"1": {"breakpoint":769,"elements":1},"2": {"breakpoint":1025,"elements":1,"arrows":true}},"build": {"arrows":false,"bullets":true,"clickable_bullets":true}}',
  storyViewed : 'true'
};
export const smartPayLandingImage: SmartPayLandingImageModel = {
  contentImages: [
    'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/icons-indicators-system-icons-smart-pay-333333-copy-2.svg',
    'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-cost-control.svg',
    'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/icon-all-rewards.svg',
    'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/icons-indicators-system-icons-add-ons-333333.svg',
  ],
  mobileCoverImage : 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/small-three-boys-at-skatepark-on-phones@2x.png',
  desktopCoverImage: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/group-copy@2x.png',
  indicatorIconPath: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/icons-indicators-system-icons.png'
};

export const sanitizerURL: string = 'https://www.youtube.com/embed/';
export const codeTestacelera: string = 'TESTACELERA';
export const regexEuropeanFundsYoutube: string = '[\\?&]v=([^&#]*)';
export const sanitizeURLYoutube: string = 'https://youtu.be/';

export const pay: { [key: string]: string} = {
  postpaid_check: 'Postpaid',
  postpaid: 'pospago',
  prepaid: 'prepago'
};
export const anonymousIdentificationVFAccount: string = 'vf account';
export const anonymousIdentificationMsisdn: string = 'msisdn';

export const testCRO: TestCROBannerConst = {
  title: 'title',
  subtitle: 'subtitle',
  button: 'button',
  link: 'link',
  image: 'image',
};
export const discountPromotionCode: { [key: string]: string} = {
  withoutDiscount: 'sin descuento',
  sameDiscount: 'descuento mismo valor',
  differentDiscount: 'descuento distinto valor'
};
export const discountPromoName: { [key: string]: string} = {
  newDiscount: 'nuevo descuento',
  replaceDiscount: 'sustituir descuento',
  sameDiscount: 'mismo descuento'
};
export const discountErrorsCategory: { [key: string]: string} = {
  entrypoint: 'entrypoint',
  recommendation: 'recommendation',
  deepLink: 'error deeplink'
};

export const NLUP:  { [key: string]: string} = {
  OPL2: 'OPL2',
  OPL3: 'OPL3',
  OPL4: 'OPL4',
  OPLG: 'OPLGF',
  currency: '€',
  quit: 'quit',
  back: 'back',
  porta: '2',
  true: 'true',
  EP: 'LAOP5',
  free: '0€',
  portaText: 'portabilidad',
  newNumberText: 'nuevo numero'
};

export const event27: string = 'event27';
export const ctaRejectReno: string = 'prefiero no obtener el descuento';

export const epBanner: string = 's4y banner 1';
export const epCarrusel: string = 's4y c1';
export const epListado: string = 's4y l1';
export const epBeneficios: string = 's4y lb';
export const etReco: string = 'reco';
export const etPega: string = 'pega';
export const etTarget: string = 'target';
export const ecCard: string = 'card';
export const goToShop: string = 'ir a tienda';
export const back: string = 'volver';
export const noOffers: string = 'sin ofertas';
export const noBenefits: string = 'sin beneficios';
export const ofertsSoldOut: string = 'ofertas agotadas';
export const noCustomizedOffers: string = 'sin ofertas personalizadas';

export const codeSL: string = 'sl';
export const debtPaymentType: { [key: string]: string} = {
  totalPayment: 'Total',
  partialPayment: 'Partial'
};

export const languageKey: { [key: string]: string } = {
  en: 'en',
  es: 'es',
};
export const darkMode: string = 'darkmode';
export const lightMode: string = 'lightmode';

export const smartPayTagging: { [key: string]: string } = {
  products_service_pago_facil_active_mas_info_click: 'products_service_pago_facil_active_mas_info_click',
  products_service_pago_facil_inactive_mas_info_click: 'products_service_pago_facil_inactive_mas_info_click',
  pago_facil_active: 'pago_facil_active',
  pago_facil_inactive: 'pago_facil_inactive',
  pago_facil_active_change_payment_method_click: 'pago_facil_active_change_payment_method_click',
  pago_facil_inactive_change_payment_method_click: 'pago_facil_inactive_change_payment_method_click',
  pago_facil_active_mas_info_click: 'pago_facil_active_mas_info_click',
  pago_facil_inactive_mas_info_click: 'pago_facil_inactive_mas_info_click',
  pago_facil_inactive_activate_pago_facil_click: 'pago_facil_inactive_activate_pago_facil_click',
  pago_facil_active_guardar_click: 'pago_facil_active_guardar_click',
  pago_facil_active_mas_Info_screen_X_click: 'pago_facil_active_mas_Info_screen_X_click',
  pago_facil_inactive_mas_info_screen_X_click: 'pago_facil_inactive_mas_info_screen_X_click',
  pago_facil_active_more_info_screen: 'pago_facil_active_more_info_screen',
  pago_facil_inactive_more_info_screen: 'pago_facil_inactive_more_info_screen',
  pago_facil_active_mas_Info_screen_entendido_click: 'pago_facil_active_mas_Info_screen_entendido_click',
  pago_facil_inactive_mas_info_screen_entendido_click: 'pago_facil_inactive_mas_info_screen_entendido_click',
  pago_facil_tariff_inActive_renovar_tarifa_click: 'pago_facil_tariff_inActive_renovar_tarifa_click',
  pago_facil_facil_user_cambiar_tarifa_click: 'pago_facil_facil_user_cambiar_tarifa_click',
  highlight_benefit_screen: 'highlight_benefit_screen',
  maintain_benefit_screen: 'maintain_benefit_screen',
  confirm_smart_pay_deactivation_normal_days: 'confirm_smart_pay_deactivation_normal_days',
  confirm_smart_pay_deactivation_twenty_eight_day: 'confirm_smart_pay_deactivation_twenty_eight_day',
  highlight_benefit_screen_X_click: 'highlight_benefit_screen_X_click',
  maintain_benefit_entendido_button_click: 'maintain_benefit_entendido_button_click',
  confirm_deactivation_normal_days_maintain_click: 'confirm_deactivation_normal_days_maintain_click',
  confirm_deactivation_normal_days_deactivate_click: 'confirm_deactivation_normal_days_deactivate_click',
  confirm_deactivation_twenty_eight_day_maintain_click: 'confirm_deactivation_twenty_eight_day_maintain_click',
  confirm_deactivation_twenty_eight_day_deactivate_click: 'confirm_deactivation_twenty_eight_day_deactivate_click',
  deactivate_pago_facil: 'desactivar pago facil',
  maintain_pago_facil: 'mantener pago facil',
  renewal_processing_pago_facil: 'renovacion procesando pago facil',
  confrim_deactivation: 'estas seguro pago facil',
  retention_smart_pay_screen: 'retention_smart_pay_screen',
  after_top_up_mas_info: 'click_on_mas_info',
  after_top_up_click_cerrar: 'click_on_cerrar'
};

export const smartPayPrepaidTagging: { [key: string]: string } = {
  active_tariff_active_smartpay_smartpay_card_more_info_click: 'active_tariff_active_smartpay_smartpay_card_more_info_click',
  active_tariff_inactive_smartpay_smartpay_card_more_info_click: 'active_tariff_inactive_smartpay_smartpay_card_more_info_click',
  inactive_tariff_recharge_click: 'inactive_tariff_recharge_click',
  active_tariff_inactive_smartpay_renew_tariff_click: 'active_tariff_inactive_smartpay_renew_tariff_click',
  active_tariff_active_smartpay_renew_tariff_click: 'active_tariff_active_smartpay_renew_tariff_click',
  active_tariff_see_details_click: 'active_tariff_see_details_click',
  inactive_tariff_see_details_click: 'inactive_tariff_see_details_click'
};
export const faultManagementImages: string = 'faultManagement.images';
export const v10FaultManagementImages: string = 'v10.faultManagement.images';
export const templateWithOutImagesInV10: string = '0';
export const templateWithImagesInV10: string = '5';

export const typeBlob: string = 'application/pdf';
export const digitalKits: { [key: string]: string } = {
  oneOrTwoEmployees: '1 o 2 empleados',
  threeToNineEmployees: '3 a 9 empleados',
  oneToTwo: '1to2',
  threeToNine: '3to9',
  zero: '0',
  two: '2',
  nine: '9',
  true: 'true',
  false: 'false',
  active: 'active',
  roleOffer: 'offer',
  roleKit: 'kit',
  roleIdTicket: 'idticket',
  roleCustomer: 'Customer',
  debtError: '1102',
  unauthorizedError: '1100',
  error401: '401',
  processFlowSpecification: 'Digitalkits',
  processFlowSpecificationStatus: 'digitalkits',
  completed: 'completed',
  request: 'Peticion',
  grantOk: 'Aprobado',
  grantKo: 'Rechazado',
  grantPendingTagging: 'subvencion en curso',
  grantOkTagging: 'solicitud aprobada',
  grantKoTagging: 'solicitud rechazada',
  imgModalExit: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/warning-for-cancel-overlay.png',
  selectedKit: ' Kit digital seleccionado',
  backend: 'backend',
  security: 'seguridad',
  technical: 'tecnico',
  roleDoctype: 'doctype',
  cif: 'cif',
  nif: 'nif',
  platform: 'platform',
  web: 'js',
  nif2: 'nif2'
};

export const digitalKitsSteps: { [key: string]: string } = {
  one: '0',
  two: '1',
  three: '2'
};

export const europeanFundsTasks: { [key: string]: string } = {
  step1: '010_ONBOARDING',
  step2: '020_TESTREALIZATION',
  step3: '030_KITSSELECTION',
  step4: '040_GRANTAPPLICATION',
  step5: '050_GRANTMANAGEMENT'
};
export const roamingToggles: { [key: string]: string } = {
  outgoing: 'outgoing',
  data: 'data'
};
export const sinOfertasOne: string = 'sin ofertas one';
export const general: string = 'general';
export const PM: string = 'PM';
export const titleDistributive: string = 'Seleccionado para ti';
export const titleCarousel: string = 'También te puede interesar';
export const sectionDelight: string = 'banner_dmp_deligth';
export const sectionCarousel: string = 'carrusel_pega';
export const overlayQuit: string = 'Cerrar';
export const overlayPrimaryStyle: string = 'vfes-button--primary';
export const s4uScreenCode: string = 'S4U';
export const distributiveMenu: string = 'menu_distrib';
export const phonesAndDevices: string = 'Móviles y Dispositivos';
export const benefitsNoActivated: string = 'Beneficios no activados';
export const eventsViewMoreOffers: { [key: string]: string } = {
  eventLabelNoOffers: 'no hay mas ofertas',
  eventLabelOffers: 'mas ofertas one',
};
export const creaditNotesStatus: { [key: string]: string } = {
  Rechazado: 'Rechazado',
  Aprobado: 'Aprobado',
  PdteFacturacion: 'Pdte Facturacion',
  PdteAprobacion: 'Pdte Aprobacion'
};

export const autoinstallGuide: { [key: string]: string } = {
  modem5GCode: '314222',
  pin: '1234',
  errorImage: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/warning-for-cancel-overlay.png'
};
export const analyticCard: string = 'myData';
export const analyticSubmenu: string = 'Mis Datos';
export const menuItemsUrl: string = 'es/v2/adminConfig/menu/items';

export const warningImg: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json';
export const redTickIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/redTick.json';
export const greenTickIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/tick.json';
export const giftIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/giftIcon.json';
export const redGift: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/gift.json';

export const redSmallTickIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/redSmallTick.png';
export const greenSmallTickIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-tick-hifi-light-theme@2x.png';

export const chatCopyImg: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/chat-copy@2x.png';
export const darkTheme: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/dark-theme@2x.png';
export const changeTariffNoPromoBtnWidths: Record<string, string> = {
  mobile: '100%',
  desktop: '105px',
  desktopPromo: '22%'
};


export const home5G: Record<string, string> = {
  tariffCode: 'MBB5G',
  name: 'Hogar 5G'
};

export const passwordAnalytics: string = 'contraseña';

export const superwifi: Record<string, string> = {
  zero: '0',
  free: 'gratis'
};
export const typeConnection: { [key: string]: string } = {
  wifi: 'wifi',
  data: 'data'
};

export const taggingInitialStates: { [key: string]: string } = {
  chat: 'chat',
  chatTicket: 'chatTicket',
  call: 'call',
  diagnostic: 'diagnostic',
  state: 'state',
  solved: 'solved',
  ticket: 'ticket',
  steps: 'steps',
  error: 'error',
  exit: 'exit',
  yes: 'yes',
  no: 'no',
  backButton: 'backButton'
};
export const keepWaitingKeys: { [key: string]: string} = {
  rebootSolved_progressbar_text: 'rebootSolved_progressbar_text',
  rebootSolved_text: 'rebootSolved_text',
  hfc_progressbar_text: 'hfc_progressbar_text',
  hfc_text: 'hfc_text',
  startTime: 'startTime',
  responseTime: 'responseTime',
};

export const typeConnectionKeys: { [key: string]: string} = {
  connection: 'connection',
  mozConnection: 'mozConnection',
  webkitConnection: 'webkitConnection',
  safari: 'safari',
  type: 'type',
  wifi: 'wifi',
  data: 'data',
};
export const stringZero: string = '0';

export const button: string = 'boton';

export const tobiImageFiles: { [key: string]: string } = {
  hover: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/wink.png',
  hoverAdara: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/WinkAdara.png',
  default: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/Default.png',
  defaultAdara: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/DefaultAdara.png',
  focus: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/Focus.png',
  minimize: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/minimize-icon.svg'
};
export const carouselPEGA: Record<string, string> = {
  impression: 'Impression',
  NoImpression: 'NoImpression',
  section: 'carrusel_pega'
};
export const groupsS4U: Record<string, string> = {
  S4U1: 'S4U1',
  S4U2: 'S4U2',
  S4U3: 'S4U3'
};

export const warningImagePath: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png';
export const HBOIN: string = 'HBOIN';
export const HBOCODE: string = '69';
export const typeActionsDecoders: Record<string, string> = {
  viewdetail: 'viewdetail',
  click: 'click',
};

export const eventTaggsDecoders: Record<string, string> = {
  viewWhyAskThis: 'porque escoger',
  viewWhyAskThisInfo: 'info alta bundle upsell',
  viewWhatDecoder: 'info deco',
  viewWhatDecoderInfo: 'info deco bundle upsell'
};

export const bubbleHelpImages: Record<string, string> = {
  minimize_icon_top_tobi: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/minimize-icon-top-tobi.svg',
  asesores_low_rojo: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/asesores_low_rojo.svg',
  asesores_low_gris: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/asesores_low_gris.svg',
  tobi: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/tobi.svg',
  help_icon_active: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/help-icon-active.svg',
  help_icon_inactive: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/help-icon-inactive.svg',
};
export const geolocation: string = 'geolocalizacion';

export const microflowsTagg: MicroFlowsType = {
  access: 'access',
  statusep: 'statusep'
};

export const responsiveSize: Record<string, number> = {
  mobile: 0,
  tablet: 769,
  desktop: 1025
};
export const tagCloseButtons: Record<string, string> = {
  boton: 'boton',
  cross: 'aspa'
};

export const tagJourneyStates: Record<string, string> = {
  active: 'consulta promocion',
  inactive: 'inactivo',
  endOfPromo: 'fin de stock',
  success: 'confirmacion',
  pending: 'canjear codigo'
};
export const superWifi6Code: string = 'SUPW6';

export const newTaggingClicks: Record<string, string> = {
  click_share: 'click_share',
  click_continue: 'click_continue',
  click_close: 'click_close',
  click_link1: 'click_link1',
  click_link2: 'click_link2',
  share: 'compartir',
  legal: 'ver condiciones',
  copy: 'copiar'
};
export const SuccessScreenTaggingLiterals: Record<string, string> = {
  productValue: 'recarga;recarga prepago;1;;',
  promoCodeKey: 'eVar207=<promotion code>'
};

export const iuaValidation: RegExp = /^[0-9]{12}$/;

export const datePrefix: string = 'Hasta el';
export const giftImg: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/Vector.png';
export const tariffCardInfo: Record<string, string> = {
  warningIcon: 'icon-warning',
  redBgGradientColor: 'red',
  mainButtonTxtColor: 'black',
  activeSmartPayBgColor: 'ui-green',
  activeSmartPayIcon: 'icon-tick-circle',
  notActiveSmartPayBgColor: 'turquoise',
  notActiveSmartPayIcon: 'icon-top-up'
};
export const miTarrifaIcons: Record<string, string> = {
  calls: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/calls.png',
};

export const DomiciledReceipt: string = 'Recibo domiciliado';
export const Size: Record<string, string> = {
  Small: 'small'
};
export const ErrorText: string = 'error';
export const iua: Record<string, string> = {
  control: 'iua',
  portability: 'mantener nuevo actual',
  notPortability: 'solicitar nuevo numero',
  empty: 'vacio',
  notValid: 'iua no valido',
  valid: 'iua valido'
};
export const imgConfirmationEmail: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/rrss-mail.png';
export const whatsAppLink: string = 'https://api.whatsapp.com/send/?phone=34607100800&text&app_absent=0';
export const tab: string = 'tab';
export const highlightBenefitsIcons: string[] = ['icon-top-up', 'icon-all-rewards', 'icon-add-ons'];

export const smartPayRetentionImages: Record<string, string> = {
  charity_giving: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/charity-giving.png',
  warning: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-warning-notification-uired-active@2x.png',
  celebrate: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/celebrate.png',
  errorImg: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png'
};
export const customeDate: string = 'Personalizado';
export const actionIconTitle: Record<string, string> = {
  close: 'Close',
  back: 'Back'
};
export const topUpSubMenuItems: Record<string, string> = {
  topUpHistory: 'TopupHistory',
  movementsHistory: 'CurrentSpendingHistory'
};
export const movementsHistoryIcons: Record<string, string> = {
   euroCurrency: '€'
};

export const TOBibubbleValues: Record<string, string> = {
  direct: 'direct',
  directOrigin: 'directOrigin',
  faqs: 'faqs'
};

export const billingNavigationLinksIcons: Record<string, string> = {
  billConfig: 'icon-settings',
  deviceInvoice: 'icon-mobile',
  payments: 'icon-payment',
  abonos: 'icon-document'
};

export const coverage: Record<string, string> = {
  coverage4G: '4g',
  coverage5G: '5g'
};

export const removeSpaces: RegExp = /\s+/g;

export const serviceabilityListResult: Record<string, string> = {
  param: '&'
    + 'speed5g={speed5g}&'
    + 'coverage5g={coverage5g}'
};

export const rateTypePriority: Record<string, string> = {
  one: '1',
  thirteen: '13'
};

export const speed5gValue: number = 50;

export const auxAdress: Record<string, string> = {
  whiteSpace: ' ',
  notDefine: ''
};

export const months: string = 'meses';

export const configurationNoTx: Record<string, string> = {
  free: 'gratis',
  month: '€/mes',
  speedUp: '100',
  speedMiddle: '50',
};

export const widowOpenBlank: string = '_blank';
export const longNameTitle: number = 30;
export const EnAuDate: string = 'en-au';

export const thirdPartyModal: Record<string, string> = {
  imgSrc: 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/restrictions/tutorials-hi-dark@3x.png',
  buttonStyle: 'btn-gray'
};

export const hereDownload: string = 'aqui';

export const tertiaryStyle: string = 'mva10-c-button--tertiary';

export const whiteColor: string = '#fff';

export const repackSubscription: string = 'REPACK';

export const pegaPlatinumOfferCodes: string[] = ['LOY_HVC_INF_ALMOST_IN_APP', 'LOY_HVC_INF_ALMOST_OUT_APP'];

export const insurance: Record<string, string> = {
  protected: 'protegido',
  notProtected: 'no protegido'
};

export const pegaExtraBalanceOfferIcon: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/redeemed_unlocked_rewards.svg';
export const info: string = 'Información';

export const promoBanner: Record<string, string> = {
  style: 'linear-gradient(to right, bg1 , bg2)',
  bg1: 'bg1',
  bg2: 'bg2'
}

export const laBackdropInfoAnalitic: Record<string, string> = {
  moreInformation: 'backdropInfo',
  click: 'click en ',
  button: 'boton info',
  tooltip: 'click en tooltip'
}

export const laCintilloColor: Record<string, string> = {
  colorStyle: '3px solid <color>'
}

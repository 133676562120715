import { RetryService } from './../../services/retry.service';
import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpRequestMethods } from '../../constants/defines';

@Component({
  selector: 'sp-modal',
  templateUrl: './sp-modal.component.html',
  styleUrls: ['./sp-modal.component.scss']
})
export class SpModalComponent {
  @Input() hideX: boolean;
  @Input() xFunctionSameAsPrimaryButton: boolean;
  @Input() requestMethod: string;
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  /**
     *  modal body Title (defult empty string)
     */
  @Input()
  public bodyTitle: String = '';
  /**
  *  modal body Content  (defult empty string)
  */
  @Input()
  public bodyContent: String = '';

  /**
   * Indicate whether modal has primaryButton or not (defult not)
   */
  @Input()
  public primaryButton = false;
  /**
   * Indicate whether modal has secondaryButton or not (defult not)
   */
  @Input()
  public secondaryButton = false;
  /**
   *  modal primaryButton text (defult empty string)
   */
  @Input()
  public primaryButtonText: String = '';
  /**
   *  modal secondaryButton text (defult empty string)
   */
  @Input()
  public secondaryButtonText: String = '';
  /**
   *  modal primaryButton style (defult empty string)
   */
  @Input()
  public primaryButtonStyle: String = '';
  /**
   *  modal secondaryButton style (defult empty string)
   */
  @Input()
  public secondaryButtonStyle: String = '';
  /**
   *  modal body Icon url (defult empty string)
   */
  @Input()
  public bodyIcon: String = '';
  /**
   *  modal primaryButtonClick event will equal to function and invoked on button click
   */
  @Input()
  public primaryButtonClick: Function;
  /**
   *  modal secondaryButtonClick event will equal to function and invoked on button click
   */
  @Input()
  public secondaryButtonClick: Function;
  /**
 *
 */
  @Input()
  public closeButtonClick: Function;
  /**
   * Indicate whether modal has secondaryButton or not (defult not)
   */
  @Input()
  public showRetryButton = false;
  // indicated class of retry button
  isRedClass: boolean = false;
  /**
   * Pointer to Button openModal (ElementRef)
   */
  public show(): void {
    this.isRetryRed();
    this.modal.show();
  }
  public hide(): void {
    this.modal.hide();
    if (typeof this.closeButtonClick === 'function') {
      this.closeButtonClick();
    }
  }

  public primaryBtnFunc() {
    this.modal.hide();
    if (typeof this.primaryButtonClick === 'function') {
      this.primaryButtonClick();
    }
  }

  public retry() {
    if (this.requestMethod === HttpRequestMethods.get) {
      this.modal.hide();
      this.retryService.retry();
    } else {
      this.modal.hide();
      if (!navigator.onLine) {
        setTimeout(() => {
          this.show();
        }, 100);

      }
    }

  }
  isRetryRed() {
    if (this.primaryButtonText || this.secondaryButtonText) {
      this.isRedClass = false;
    } else {
      this.isRedClass = true;
    }
  }
  constructor(private retryService: RetryService) {
  }

}

export enum BundlesGroups {
    data = 'CARD_DATA',
    voiceNational = 'CARD_VOICE_NACIONAL_MOBILE_POSTPAID',
    voiceInternational = 'CARD_VOICE_INTERNACIONAL_MOBILE_POSTPAID',
    sms = 'CARD_SMS',
    fiber = 'CARD_FIBRE',
    landlineMobile = 'CARD_VOICE_NACIONAL_LANDLINE',
    landlineMobileInternational = 'CARD_VOICE_INTERNACIONAL_LANDLINE',
    landlineFixed = 'CARD_FIXED_NACIONAL',
    landline = 'LANDLINE',
    dataPrepaid = 'CARD_DATA_PREPAID',
    adsl = 'CARD_ADSL',
    voiceNationalPrepaid = 'CARD_VOICE_NACIONAL_MOBILE_POSTPAID_PREPAID',
    smsPrepaid = 'CARD_SMS_PREPAID'
}

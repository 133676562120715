export enum PaymentJourneyIds {
    postPaid = '101',
    prePaidWithOutCvv = '102',
    prePaidWithCvv = '103',
    postPaidWithCvv = '104',
    prepaidNewCreditCard = '106',
    creditCard = '109',
    creditCardWithCvv = '110',
    postPaidFinancedDevicesWithouCC = '107',
    energyDebt = '120'
}

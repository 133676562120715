import { Injectable } from '@angular/core';
import { Mva10TopupLandingWithPciComponent } from './mva10-topup-landing-with-pci/mva10-topup-landing-with-pci.component';
import { Mva10TopupIframeEnablementComponent } from './mva10-topup-iframe-enablement/mva10-topup-iframe-enablement.component';
import { TopUpConfirmationOverlayComponent } from './top-up-confirmation-overlay/top-up-confirmation-overlay.component';
import { TopUpGeneralErrorOverlayComponent } from './top-up-general-error-overlay/top-up-general-error-overlay.component';
import { TopUpService } from './top-up.service';
import { AppService } from '../app.service';
import { TopupPrepaidStages } from '../shared/constants/defines';
import { TopUpPaymentTicketComponent } from './top-up-payment-ticket/top-up-payment-ticket.component';
import { TopUpCardWalletScreenComponent } from './top-up-card-wallet-screen/top-up-card-wallet-screen.component';
import { DynamicTrayService } from '../shared/services/dynamic-tray.service';
import { Router } from '@angular/router';
import { SpMva10OverlayService } from '../shared/services/sp-mva10-overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../config/pages-config';
import { BillingService } from '../billing/billing.service';
import { PayDebtOtherPaymentMethodsComponent } from '../billing/pay-debt-other-payment-methods/pay-debt-other-payment-methods.component';
import { TopUpContactListPermissionComponent } from './top-up-contact-list-permission/top-up-contact-list-permission.component';

@Injectable()
export class TopUpNavigationService {
    topupPages = {
        'firstStep': Mva10TopupLandingWithPciComponent,
        'secondStep': Mva10TopupIframeEnablementComponent,
        'topupConfirmation': TopUpConfirmationOverlayComponent,
        'topupGeneralError': TopUpGeneralErrorOverlayComponent,
        'topupPaymentTicket': TopUpPaymentTicketComponent,
        'TopUpCardWalletScreen': TopUpCardWalletScreenComponent,
        'payDebtOtherPaymentMethods': PayDebtOtherPaymentMethodsComponent,
        'topUpContactListPermissionComponent': TopUpContactListPermissionComponent
    }


    constructor(private topupService: TopUpService,
        private appService: AppService,
        private dynamicTrayService: DynamicTrayService,
        private trayService: SpMva10OverlayService,
        private translateService: TranslateService,
        public billService: BillingService,
        private router: Router) {
    }

    init() {
        this.translateService.get('common.itemsList.refills.body').subscribe(data => {
            this.topupService.dynamicTrayData.basicTrayData.title = data;
        })

        this.topupService.topupNavigationSubject.subscribe(data => {
            this.topupNavigate(data.page, data.isFirstTimeOpen);
            this.setDynamicTrayTitle(data.page);
        });
        this.topupService.isAnonymousProcess = this.router.url.split('?')[0] === config.publicTopup.route;

    }

    topupNavigate(page: TopupPrepaidStages, isFirstTimeOpen = false) {
        this.topupService.useDynamicTray = isFirstTimeOpen ?
            (!this.topupService.isAnonymousProcess && !this.topupService.isOpenedFromLoginTabs) : this.topupService.useDynamicTray;

        if (this.topupService.useDynamicTray) {
            if (this.billService.startBillingPayment && isFirstTimeOpen) {
                this.dynamicTrayService.open(this.billService.dynamicTrayData, this.topupPages[page]);
            } else if (isFirstTimeOpen) {
                this.dynamicTrayService.open(this.topupService.dynamicTrayData, this.topupPages[page]);
            } else {
                this.dynamicTrayService.openTraySubject.next(this.topupPages[page]);
            }
        } else {
            if (isFirstTimeOpen) {
                this.trayService.open(100);
            }
            this.appService.mva10OverlaySubject.next(this.topupPages[page]);
        }

    }

    /**
     * set title value of dynamic tray when navigate between components.
     * @param {TopupPrepaidStages} page - containig the name of page tray is navigation to.
     */

    setDynamicTrayTitle(page: TopupPrepaidStages) {
        switch (page) {
            case TopupPrepaidStages.TopUpCardWalletScreen:
                this.translateService.get('payment.itemsList.wallet.body').subscribe(data => {
                    this.topupService.dynamicTrayData.basicTrayData.title = data;
                });
                break;
            default:
                this.translateService.get('common.itemsList.refills.body').subscribe(data => {
                    this.topupService.dynamicTrayData.basicTrayData.title = data;
                });
                break;
        }
    }
}

import { LegalTermDetail } from './legalTermDetail.model';

export class LegalTerms {
    public checked: boolean;
    public id: number;
    public internalDescription: string;
    public legalTermDetail: LegalTermDetail[];
    public orden: number;

}



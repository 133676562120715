import { LoggerService } from './LoggerService';
import { ErrorHandler } from '@angular/core';

export class GeneralErrorHandler implements ErrorHandler {
    constructor(private logger: LoggerService) { }

    handleError(error: Error) {
        if (error && error.stack) {
            this.logger.error(error.stack);
        }
        else if (error) {
            this.logger.error(error);
        }
    }
}

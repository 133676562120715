<div class="current-bonos">

    <div *ngIf="loadComsuption; else skeletonComsuption">

        <div *ngIf="!errorComsuption; else errorComsuptionTemplate">

            <label class="mva10-u-heading--3 current-bonos__title" id="currentBonosLabelTitle">{{title}}</label>

            <div #cardDataBonos class="current-bonos__card" *ngIf="arrayBonosData.length > 0">
                <mva10-c-card [styleClass]="'current-bonos__expandable'" [responsiveSize]="20">

                    <div class="current-bonos__desc-item">
                        <span>
                            <svg class="icon">
                                <title>Wifi</title>
                                <use xlink:href="#icon-broadband-or-wifi" />
                            </svg>
                            <label class="titleCaseCapitalize mva10-u--ml4x" id="currentBonosLabelData">
                                {{labelData}}
                            </label>
                        </span>
                    </div>

                    <div class="current-bonos--hidden" id="cardExpandBonosInternetId">
                        <hr>
                        <vfac-progress-list [progressListData]="progressListDataData"
                            [yuAppearance]="this.appTheme.checkYuUser() ? 'yu' : ''">
                        </vfac-progress-list>
                    </div>

                    <hr>
                    <div class="current-bonos__expandable-label cursor-pointer"
                        (click)="clickExpandCardConsumption(!cardExpandableValueInternet, bundleTypeEnum.Data)" id="currentBonosExpandableDataBtn">
                        <label id="currentBonosExpandableDataText">{{labelcardExpandableValueInternet}}</label>
                    </div>
                </mva10-c-card>
            </div>

            <div #cardVoiceBonos class="current-bonos__card" *ngIf="arrayBonosVoice.length > 0">
                <mva10-c-card [styleClass]="'current-bonos__expandable'" [responsiveSize]="20">

                    <div class="current-bonos__desc-item">
                        <span>
                            <svg class="icon">
                                <title>Calls</title>
                                <use xlink:href="#icon-call-log" />
                            </svg>
                            <label class="titleCaseCapitalize mva10-u--ml4x" id="currentBonosLabelCall">
                                {{labelCalls}}
                            </label>
                        </span>
                    </div>

                    <div class="current-bonos--hidden" id="cardExpandBonosCallsId">
                        <hr>
                        <vfac-progress-list [progressListData]="progressListDataVoice"
                            [yuAppearance]="this.appTheme.checkYuUser() ? 'yu' : ''">
                        </vfac-progress-list>
                    </div>

                    <hr>
                    <div class="current-bonos__expandable-label cursor-pointer"
                        (click)="clickExpandCardConsumption(!cardExpandableValueCalls, bundleTypeEnum.Voice)" id="currentBonosExpandableCallBtn">
                        <label id="currentBonosExpandableCallText">{{labelcardExpandableValueCalls}}</label>
                    </div>

                </mva10-c-card>
            </div>

            <div #cardSMSBonos class="current-bonos__card" *ngIf="arrayBonosSMS.length > 0">
                <mva10-c-card [styleClass]="'current-bonos__expandable'" [responsiveSize]="20">

                    <div class="current-bonos__desc-item">
                        <span>
                            <svg class="icon">
                                <title>SMS</title>
                                <use xlink:href="#icon-sms" />
                            </svg>
                            <label class="titleCaseCapitalize mva10-u--ml4x" id="currentBonosLabelMessage">
                                {{labelMessages}}
                            </label>
                        </span>
                    </div>

                    <div class="current-bonos--hidden" id="cardExpandBonosMessagesId">
                        <hr>
                        <vfac-progress-list [progressListData]="progressListDataSMS"
                            [yuAppearance]="this.appTheme.checkYuUser() ? 'yu' : ''">
                        </vfac-progress-list>
                    </div>

                    <hr>
                    <div class="current-bonos__expandable-label cursor-pointer"
                        (click)="clickExpandCardConsumption(!cardExpandableValueMessages, bundleTypeEnum.SMS)" id="currentBonosExpandableMessageBtn">
                        <label id="currentBonosExpandableMessageText">{{labelcardExpandableValueMessages}}</label>
                    </div>

                </mva10-c-card>
            </div>

        </div>

        <ng-template #errorComsuptionTemplate>
            <div class="current-bonos__error">
                <div class="current-bonos__error-lottie">
                    <ng-lottie [options]="lottieConfig" (animationCreated)="handleAnimation($event)">
                    </ng-lottie>
                </div>
                <span id="currentBonosErrorComsuptionTitleError">{{'v10.billing.comsuption.titleError'|translate}}</span>
                <span id="currentBonosErrorComsuptionErrorDesc">{{'v10.dashboard.overlay_consumption.datasharing.ko_desc'|translate}}</span>
                <div class="current-bonos__error-button">
                    <mva10-c-button [body]="btnRetry"
                        (click)="retryComsuptionBonos()"
                        [styleClass]="'mva10-c-button--primary'"
                        [name]="btnRetry"
                        [title]="btnRetry"
                        id="currentBonosErrorComsuptionBtnErro">
                    </mva10-c-button>
                </div>
            </div>
        </ng-template>

    </div>

    <ng-template #skeletonComsuption>
        <ng-container [ngTemplateOutlet]="comsuptionSkeleton"></ng-container>
    </ng-template>

</div>

<ng-template #comsuptionSkeleton>
    <div class="skeleton-current-bonos" *ngFor="let repeat of [1,2,3]">
        <div class="skeleton-wrapper-section">
            <div class="skeleton">
            </div>
        </div>
        <div class="skeleton-wrapper-section">

            <div class="skeleton">
            </div>
            <div class="skeleton">
            </div>
        </div>
    </div>
</ng-template>
import { PaymentTaggingPostPaid } from './payment-tagging-post-paid';

import { tagging } from '../../../../config/tagging-config';
import { BillingService } from '../../../billing/billing.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { CrossVariables } from '../../../models/tagging.model';
import { PaymentJourneyUserType } from '../../../shared/enums/payment-journey-user-type.enum';
import { PaymentJourneyProcess } from '../../../shared/enums/payment-journey-process.enum';
import { PaymentJourneyPaymentType } from '../../../shared/enums/payment-journey-payment-type.enum';
import { PaymentJourneyDetail } from '../../../shared/enums/payment-journey-detail.enum';
import { PaymentJourneyEnvironment } from '../../../shared/enums/payment-journey-environment.enum';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';


export class PaymentTaggingPostPaidCashDesk extends PaymentTaggingPostPaid {
  constructor(
    public billService: BillingService,
    public paymentNavigationService: PaymentNavigationService,
    public tagging: TaggingHelperService
  ) {
    super(billService, paymentNavigationService, tagging);
  }
  /**
   * Tagging for ticket screen for postpaid journey cash desk
   */
  tagOpenPaymentTicketScreen(): void {
    const paymentPageName: any = Object.assign({}, tagging.paymentDetailsPageName);
    const paymentJourney: any = Object.assign({}, tagging.paymentDetailsJourney);
    paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
      tagging.staticVariables.paymentCashDeskPageName);
    paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
      tagging.staticVariables.paymentCashDeskPageName);
    paymentJourney['journey_payment_type'] =
      this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postcashDeskPartial ?
        PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
    paymentJourney.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;
    paymentJourney.journey_category = CrossVariables.client_typology;
    paymentJourney.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
      tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
    paymentJourney.payment_id = this.paymentNavigationService.thirdPartyURLParams.transactionId;
    paymentJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
                PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
    this.tagging.view(paymentPageName, paymentJourney);
  }
  /**
   * Tagging for download button in ticket screen for postpaid journey cash desk
   */
  tagDownLoadTicket(): void {
    const paymentPageName: any = Object.assign({}, tagging.paymentDetailsPageName);
    const paymentJourney: any = Object.assign({}, tagging.paymentDetailsJourney);
    let eventName: string = '';
    eventName = tagging.downloadTicket.eventName.replace('<page_name>', tagging.staticVariables.paymentCashDeskPageName);
    paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
      tagging.staticVariables.paymentCashDeskPageName);
    paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
      tagging.staticVariables.paymentCashDeskPageName);
    paymentJourney['journey_payment_type'] =
      this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postcashDeskPartial ?
        PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
    paymentJourney.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;
    paymentJourney.journey_category = CrossVariables.client_typology;
    paymentJourney.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
      tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
    paymentJourney.payment_id = this.paymentNavigationService.thirdPartyURLParams.transactionId;
    this.tagging.track(eventName, tagging.downloadTicket.data, paymentPageName, paymentJourney);
  }
  /** Tagging for payment leaving success screen */
  tagPaymentLeavingSuccessScreen(transactionId: string, isToggleOn: boolean): void {
    const saveCreditCardJourney: any = Object.assign({}, tagging.saveCreditCardJourney);
    const saveCreditCardPageJourney: any = Object.assign({}, tagging.saveCreditCardJourney.pageJourney);
    let pageName: string = '';

    pageName = tagging.staticVariables.paymentCashDeskPageName;
    saveCreditCardJourney['journey_payment_type'] =
      this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postcashDeskPartial ?
        PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
    saveCreditCardJourney.data.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;

    saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
    saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
    saveCreditCardPageJourney.page_subcategory_level_2 = pageName;

    const paymentId: string = transactionId;
    saveCreditCardJourney.data.payment_id = paymentId;
    const eventNumber: any = isToggleOn ? saveCreditCardJourney.toggle_active : saveCreditCardJourney.toggle_not_active;
    saveCreditCardJourney.data['&&events'] = saveCreditCardJourney.data['&&events']
      .replace('<event_number>', eventNumber.toString())
      .replace('<payment_id>', paymentId);
    saveCreditCardJourney.data.journey_category = CrossVariables.client_typology;
    saveCreditCardJourney.data.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
      tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
    saveCreditCardJourney.data.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
      PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;

    this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
  }

  /** Method to be used from Parent class (PaymentTaggingPostPaid) in case postpaid inadvance */
  sendTaggingHelper(pageName: any, journey: any, isSelectedCard: boolean): void {
    const paymentPageName: any = Object.assign({}, pageName);
    const paymentJourney: any = Object.assign({}, journey);
    paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
      tagging.staticVariables.paymentCashDeskPageName);
    paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
      tagging.staticVariables.paymentCashDeskPageName);
    paymentJourney['journey_payment_type'] =
      this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postcashDeskPartial ?
        PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
    paymentJourney.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;
    paymentJourney.journey_category = CrossVariables.client_typology;
    paymentJourney.journey_card_selected = isSelectedCard || this.paymentNavigationService.paymentJourneyParams.token ?
      tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
      paymentJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
                            PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
    this.tagging.view(paymentPageName, paymentJourney);
  }

  /** Tagging for payment wallet cancel deleting a card */
  tagPaymentWalletCancelDeleteInteraction(): void {
    this.billService.sendPostPaidTaggingTrackActions(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyUserType.cashDesk,
      tagging.clickOnCancelDeleteCardPostPaidButton);
  }
  /** Tagging for payment wallet start deleting a card journey */
  tagPaymentWalletDeleteCard(): void {
    this.billService.sendPostPaidTaggingTrackActions(tagging.walletScreenPostpaidPageName, PaymentJourneyUserType.cashDesk,
      tagging.clickOnDeleteCCPostPaid);
    this.billService.sendViewForDeleteCCScreenPostpaid(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyProcess.cashdesk,
      PaymentJourneyDetail.deleteCC, PaymentJourneyUserType.cashDesk);
  }
  /** Tagging for payment wallet confirm deleting a card */
  tagPaymentWalletConfirmDeleteInteraction(): void {
    this.billService.sendPostPaidTaggingTrackActions(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyUserType.cashDesk,
      tagging.clickOnConfirmDeleteCardPostPaidButton);
  }
  /** Tagging cerrar btn Success paymet overlay screen */
  successpaymentCerrarBtnClick(): void {
    const saveCreditCardJourney: any = Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlay);
    const saveCreditCardPageJourney: any = Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlay.pageJourney);
    let pageName: string = '';
    pageName = tagging.staticVariables.paymentCashDeskPageName;
    saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
    saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
    saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
    this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
  }
  /** Tagging ok btn Success paymet overlay screen */
  successpaymentEntendidoBtnClick(): void {
    const saveCreditCardJourney: any = Object.assign({}, tagging.entendidoBtnClickSuccessPaymentOverlay);
    const saveCreditCardPageJourney: any = Object.assign({}, tagging.entendidoBtnClickSuccessPaymentOverlay.pageJourney);
    let pageName: string = '';
    pageName = tagging.staticVariables.paymentCashDeskPageName;
    saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
    saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
    saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
    this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
  }
}

import { PagesConfig } from '../app/models/pages-config.model';

export let config: PagesConfig = {
  'roaming': {
    'name': 'roaming',
    'route': '/roaming',
    'deeplink': true,
    'permissionList': ['Authorized'],
    'loadChildren': () => import('../app/Roaming/roaming.module').then(m => m.RoamingModule),
    'landing': {
      'name': 'landing',
      'route': '/roaming/landing',
      'page_name': 'roaming:gestiona el roaming de tus lineas',
      'page_section': 'roaming',
      'page_subcategory_level_1': 'gestiona el roaming de tus lineas',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal',
      'deeplink': true,
    },
    'consumptionLimits': {
      'name': 'consumptionLimits',
      'route': '/roaming/consumptionLimits',
      'page_name': 'roaming:control de consumo de datos',
      'page_section': 'roaming',
      'page_subcategory_level_1': 'control de consumo de datos',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'control de consumo de datos',
      'page_typology': 'principal',
      'deeplink': true,
    },
     'airAndSea': {
      'name': 'airAndSea',
      'route': '/roaming/airAndSea',
      'page_name': 'roaming:cobertura aerea y maritima',
      'page_section': 'roaming',
      'page_subcategory_level_1': 'cobertura aerea y maritima',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'cobertura aerea y maritima',
      'page_typology': 'principal',
      'deeplink': true,
    },
    'faq': {
      'name': 'faq',
      'route': '/roaming/faq',
      'page_name': 'roaming:dudas cuando viajes al extranjero',
      'page_section': 'roaming',
      'page_subcategory_level_1': 'dudas cuando viajes al extranjero',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'dudas cuando viajes al extranjero',
      'page_typology': 'principal',
      'deeplink': true,
    },
    'zonasRoaming': {
      'name': 'zonasRoaming',
      'route': '/roaming/zonasRoaming',
      'page_name': 'roaming:zonas roaming',
      'page_section': 'roaming',
      'page_subcategory_level_1': 'zonas roaming',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'zonas roaming',
      'page_typology': 'principal',
    },
    'zoansDeatilsRoaming': {
      'name': 'zoansDeatilsRoaming',
      'route': '/roaming/zoansDeatilsRoaming',
      'page_name': 'roaming:zonas roaming',
      'page_section': 'roaming',
      'page_subcategory_level_1': 'zonas roaming',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'zonas roaming',
      'page_typology': 'principal',
    },
    'usageFair': {
      'name': 'usageFair',
      'route': '/roaming/usageFair',
      'page_name': 'roaming',
      'page_section': 'roaming',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal',
      'deeplink': true
    }
  },
  'splash': {
    'name': 'splash',
    'route': '/splash',
    'page_name': 'prelogin:splashpage',
    'page_section': 'prelogin',
    'page_subcategory_level_1': 'splashpage',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'splashpage',
    'page_typology': 'principal',
  },
  'publicTopup': {
    'name': 'publicTopup',
    'route': '/publicTopup'
  },
  'publicPaymentTopup': {
    'name': 'publicPaymentTopup',
    'route': '/publicPaymentTopup'
  },
  'payment': {
    'name': 'publicPayment',
    'route': '/payment'
  },
  /** this is capital to match routes for tagging  */
  'Inbox': {
    'name': 'Inbox',
    'route': '/Inbox',
    'permissionList': ['Authorized'],
    /** this is capital to match routes for tagging  */
    'InboxListing': {
      'name': 'InboxListing',
      'route': '/Inbox/InboxListing',
      'deeplink': true,
      'page_name': 'mensajes:bandeja de entrada',
      'page_section': 'mensajes',
      'page_subcategory_level_1': 'bandeja de entrada',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'bandeja de entrada',
      'page_typology': 'principal'
    },
    'details': {
      'name': 'details',
      'route': '/Inbox/details',
      'page_name': 'mensajes:bandeja de entrada:detalle de mensaje',
      'page_section': 'mensajes',
      'page_subcategory_level_1': 'bandeja de entrada',
      'page_subcategory_level_2': 'detalle de mensaje',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de mensaje',
      'page_typology': 'detalle'
    },
    'messageDetails': {
      'name': 'InboxListing/MessageDetails',
      'route': '/Inbox/InboxListing/MessageDetails',
      'page_name': 'mensajes:bandeja de entrada:detalle de mensaje',
      'page_section': 'mensajes',
      'page_subcategory_level_1': 'bandeja de entrada',
      'page_subcategory_level_2': 'detalle de mensaje',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de mensaje',
      'page_typology': 'detalle',
      'deeplink': true
    },
    'notificationConfigration': {
      'name': 'InboxListing/NotificationConfigration',
      'route': '/Inbox/InboxListing/NotificationConfigration',
      'page_name': 'mensajes'
    },
    'configration': {

      'name': 'configration',
      'route': '/Inbox/configration',
    },
  },
  'login': {
    'name': 'login',
    'route': '/login',
    'page_name': 'login:login',
    'loginSeibelRedirection': {
      'name': 'loginSeibelRedirection',
      'route': '/login/loginSeibelRedirection',
    },
    'companyChooser': {
      'name': 'companyChooser',
      'route': '/login/companyChooser',
      'page_name': 'login:selecciona empresa',
      'page_section': 'login',
      'page_subcategory_level_1': 'selecciona empresa',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'selecciona empresa',
      'page_typology': 'overlay',
      'permissionList': ['Authorized']
    },
    'orderMangment': {
      'name': 'orderMangment',
      'route': '/login/orderMangment',
      'page_name': 'login:servicios no activados',
      'page_section': 'login',
      'page_subcategory_level_1': 'servicios no activados',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'servicios no activados',
      'page_typology': 'overlay',
      'deeplink': true
    },
    'otp': {
      'name': 'otp',
      'route': '/login/otp'
    }
  },
  'communications': {
    'name': 'comunicaciones',
    'route': '/comunicaciones',
    'deeplink': true,
    'permissionList': ['Authorized']
  },
  'securitas': {
    'name': 'alarmaentucasa',
    'route': '/alarmaentucasa',
    'deeplink': true
  },
  'tradeIn': {
    'name': 'reciclatumovil',
    'route': '/reciclatumovil',
    'deeplink': true
  },
  'ecareLanding': {
    'name': 'ecareLanding',
    'route': '/ecareLanding'
  },
  'childBrowser': {
    'name': 'childBrowser',
    'route': '/childBrowser'
  },
  'myorders': {
    'name' : 'myorders',
    'route': '/myorders',
    'deeplink': true
  },
  'pqni': {
    'name': 'pqni',
    'route': '/pqni',
    'deeplink': true,
    'permissionList': ['Authorized']
  },
  'tryAndBuy': {
    'name': 'tryAndBuy',
    'route': '/tryAndBuy',
    'deeplink': true
  },
  'tvmicro': {
    'name': 'tvmicro',
    'route': '/tvmicro',
    'deeplink': true
  },
  'memberGetMember': {
    'name': 'planNegocio',
    'route': '/planNegocio',
    'deeplink': true
  },
  'beeDigital': {
    'name': 'presenciadigital',
    'route': '/presenciadigital',
    'deeplink': true
  },
  'beeDigitalIframe': {
    'name': 'presenciadigitalIframe',
    'route': '/presenciadigitalIframe',
    'deeplink': true
  },
  'headerPrincipal': {
    'name': 'headerPrincipal',
    'route': '/headerPrincipal',
    'deeplink': true
  },
  'formTryAndBuy': {
    'name': 'formTryAndBuy',
    'route': '/formTryAndBuy',
    'deeplink': true
  },
  'labelSup': {
    'name': 'labelSup',
    'route': '/labelSup',
    'deeplink': true
  },
  'whatIs': {
    'name': 'whatIs',
    'route': '/whatIs',
    'deeplink': true
  },
  'productDescription': {
    'name': 'productDescription',
    'route': '/productDescription',
    'deeplink': true
  },
  'whatInclude': {
    'name': 'whatInclude',
    'route': '/whatInclude',
    'deeplink': true
  },
  'advantages': {
    'name': 'advantages',
    'route': '/advantages',
    'deeplink': true
  },
  'productFaqs': {
    'name': 'productFaqs',
    'route': '/productFaqs',
    'deeplink': true
  },
  'promosMicro': {
    'name': 'promosMicro',
    'route': '/promosMicro',
    'deeplink': true
  },
  'specialForYou': {
    'name': 'specialForYou',
    'route': '/specialForYou',
    'deeplink': true,
    'offers' : {
      'name': 'offers',
      'route': '/specialForYou/offers',
    }
  },
  'engagement': {
    'name': 'landingAE',
    'route': '/landingAE',
    'deeplink': true,

  },
  'opProducts': {
    'name': 'opProducts',
    'route': '/opProducts',
    'deeplink': true,
    'opProductsList': {
      'name': 'opProductsList',
      'route': '/opProducts/opProductsList',
      'deeplink': true,
    },
    'opProductsDetail': {
      'name': 'opProductsDetail',
      'route': '/opProducts/opProductsDetail',
    },
    'opProfesionalSolution': {
      'name': 'opProfesionalSolution',
      'route': '/opProducts/opProfesionalSolution',
    },
    'opGSuite': {
      'name': 'opProductsDetailbc',
      'route': '/opProducts/opProductsDetailbc',
    },
    'opAddons': {
      'name': 'oneprofessionaladdons',
      'route': '/opProducts/oneprofessionaladdons'
    },
    'opAddon': {
      'name': 'oneprofessionaladdon',
      'route': '/opProducts/oneprofessionaladdon',
    },
    'OpSummaryAddon': {
      'name': 'oneprofessionalsummaryaddon',
      'route': '/opProducts/oneprofessionalsummaryaddon',
    }
  },
  'opProduct': {
    'name': 'opProduct',
    'route': '/opProduct',
    'deeplink': true,
    'opProductsList': {
      'name': 'opProductsList',
      'route': '/opProduct/opProductsList',
      'deeplink': true,
    },
    'opProductsDetail': {
      'name': 'opProductsDetail',
      'route': '/opProduct/opProductsDetail',
    },
    'opProfesionalSolution': {
      'name': 'opProfesionalSolution',
      'route': '/opProduct/opProfesionalSolution',
    },
    'opGSuite': {
      'name': 'opProductsDetailbc',
      'route': '/opProduct/opProductsDetailbc',
    }
  },
  'europeanFunds': {
    'name': 'testacelera',
    'route': '/testacelera',
    'deeplink': true,
    'digitalDiagnosis': {
      'name': 'digitalDiagnosis',
      'route': '/testacelera/digitalDiagnosis'
    }
  },
  'europeanFundsStep2': {
    'name': 'digitalkit',
    'route': '/digitalkit',
    'deeplink': true,
    'landing': {
      'name': 'landing',
      'route': '/digitalkit/landing'
    },
    'selectKit': {
      'name': 'selectKit',
      'route': '/digitalkit/selectKit'
    },
    'summary': {
      'name': 'summary',
      'route': '/digitalkit/summary'
    }
  },
  'installationGuide': {
    'name': 'autoinstallGuides',
    'route': '/autoinstallGuides',
    'deeplink': true
  },
  'asessorDigital': {
    'name': 'asessorDigital',
    'route': '/asessorDigital',
    'deeplink': true,
    'permissionList': ['Authorized']
  },
  'dashboard': {
    'name': 'dashboard',
    'route': '/dashboard',
    'page_name': 'dashboard:home',
    'page_section': 'dashboard',
    'page_subcategory_level_1': 'home',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'home',
    'page_typology': 'principal',
    'permissionList': ['Authorized'],
    'deeplink': true,
    'Ayuda': {
      'page_name': 'dashboard:home',
      'page_section': 'dashboard',
      'page_subcategory_level_1': 'home',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'home',
      'page_typology': 'principal',
      'deeplink': true
    },
  },
  'VfpassLanding': {
    'name': 'VfpassLanding',
    'route': '/VfpassLanding',
    'deeplink': true,
    'permissionList': ['Authorized']
  },

  'Dashboard': {
    'name': 'Dashboard',
    'route': '/Dashboard',
    'page_name': 'dashboard:home',
    'page_section': 'dashboard',
    'page_subcategory_level_1': 'home',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'home',
    'page_typology': 'principal',
    'permissionList': ['Authorized'],
    'deeplink': true,
    'Ayuda': {
      'page_name': 'dashboard:home',
      'page_section': 'dashboard',
      'page_subcategory_level_1': 'home',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'home',
      'page_typology': 'principal',
      'deeplink': true
    },
  },
  'bubble': {
    'name': 'Ayuda',
    'route': '/dashboard/Ayuda',
    'page_name': 'dashboard:home',
    'page_section': 'dashboard',
    'page_subcategory_level_1': 'home',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'home',
    'page_typology': 'principal',
    'permissionList': ['Authorized'],
    'deeplink': true
  },
  'Bubble': {
    'name': 'Ayuda',
    'route': '/Dashboard/Ayuda',
    'page_name': 'dashboard:home',
    'page_section': 'dashboard',
    'page_subcategory_level_1': 'home',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'home',
    'page_typology': 'principal',
    'permissionList': ['Authorized'],
    'deeplink': true
  },
  'myAccount': {
    'name': 'myAccount',
    'route': '/myAccount',
    'page_name': 'myAccount:myAccount',
    'page_section': 'myAccount',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'myAccount',
    'page_typology': 'principal',
    'permissionList': ['Authorized'],
    'OverviewScreen': {
      'name': 'OverviewScreen',
      'route': '/myAccount/OverviewScreen',
      'page_name': 'mi cuenta:resumen de mi cuenta',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'resumen de mi cuenta',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de mi cuenta',
      'page_typology': 'principal',
      'deeplink': true
    },
    'landing': {
      'name': 'landing/:section',
      'route': '/myAccount/landing',
      'page_name': 'mi cuenta:landing',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'landing',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'landing',
      'page_typology': 'principal',
      'deeplink': true
    },
    'permissionAndPrefrence': {
      'name': 'permissionAndPrefrence',
      'route': '/myAccount/permissionAndPrefrence',
      'page_name': 'mi cuenta:permisos y preferencias:principal',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'permisos y preferencias',
      'page_subcategory_level_2': 'principal',
      'page_subcategory_level_3': '',
      'page_screen': 'principal',
      'page_typology': 'principal',
      'deeplink': true,
    },
    'PermissionsAndPreferences': {
      'name': 'PermissionsAndPreferences',
      'route': '/myAccount/PermissionsAndPreferences',
      'deeplink': true,
      'DiagnosticoDeMiVodafone': {
        'name': 'PermissionsAndPreferences/DiagnosticoDeMiVodafone',
        'route': '/myAccount/PermissionsAndPreferences/DiagnosticoDeMiVodafone',
        'deeplink': true,
        'page_name': 'mi cuenta:permisos y preferencias:diagnostico de mi vodafone',
        'page_section': 'mi cuenta',
        'page_subcategory_level_1': 'permisos y preferencias',
        'page_subcategory_level_2': 'diagnostico de mi vodafone',
        'page_subcategory_level_3': '',
        'page_screen': 'diagnostico de mi vodafone',
        'page_typology': 'principal',
      },
    },
    'permissions': {
      'name': 'permissions',
      'route': '/myAccount/permissions',
      'page_name': 'mi cuenta:permisos y preferencias:permisos',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'permisos y preferencias',
      'page_subcategory_level_2': 'permisos',
      'page_subcategory_level_3': '',
      'page_screen': 'permisos',
      'page_typology': 'principal',
      'deeplink': true,
    },
    'myPermissions': {
      'name': 'PermissionsAndPreferences/Permissions',
      'route': '/myAccount/PermissionsAndPreferences/Permissions',
      'deeplink': true,
    },
    'prefrences': {
      'name': 'prefrences',
      'route': '/myAccount/prefrences',
      'page_name': 'mi cuenta:permisos y preferencias:preferencias',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'permisos y preferencias',
      'page_subcategory_level_2': 'preferencias',
      'page_subcategory_level_3': '',
      'page_screen': 'preferencias',
      'page_typology': 'principal',
    },
    'myPrefrences': {
      'name': 'PermissionsAndPreferences/Preferences',
      'route': '/myAccount/PermissionsAndPreferences/Preferences',
      'deeplink': true,
    },
    'editUserDetails': {
      'name': 'YourDetails/EdituserDetails',
      'route': '/myAccount/YourDetails/EdituserDetails',
      'deeplink': true
    },
    'editDetails': {
      'name': 'editDetails',
      'route': '/myAccount/editDetails',
      'deeplink': true
    },
    'userDetails': {
      'name': 'YourDetails/MainPage',
      'route': '/myAccount/YourDetails/MainPage',
      'page_name': 'mi cuenta:tus datos:resumen de tus datos',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'resumen de tus datos',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de tus datos',
      'page_typology': 'principal',
      'deeplink': true
    },
    'details': {
      'name': 'details',
      'route': '/myAccount/details',
      'deeplink': true
    },
    'managerUsers': {
      'name': 'managerUsers',
      'route': '/myAccount/managerUsers',
    },
    'managerUsersDetails': {
      'name': 'managerUsersDetails',
      'route': '/myAccount/managerUsersDetails',
    },
    'managerUsersChoosers': {
      'name': 'managerUsersChoosers',
      'route': '/myAccount/managerUsersChoosers',
    },
    'managerUsersChoosersDetail': {
      'name': 'managerUsersChoosersDetail',
      'route': '/myAccount/managerUsersChoosersDetail',
    },
    'nif': {
      'name': 'nif',
      'route': '/myAccount/nif',
      'page_name': 'mi cuenta:tus datos:editar datos personales:editar dni',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'editar datos personales',
      'page_subcategory_level_3': 'editar dni',
      'page_screen': 'editar dni',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editNif': {
      'name': 'YourDetails/EdituserDetails/EditDNI',
      'route': '/myAccount/YourDetails/EdituserDetails/EditDNI',
      'page_name': 'mi cuenta:tus datos:editar datos personales:editar dni',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'editar datos personales',
      'page_subcategory_level_3': 'editar dni',
      'page_screen': 'editar dni',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editAddress': {
      'name': 'editAddress',
      'route': '/myAccount/editAddress',
      'page_name': 'mi cuenta:tus datos:editar direccion',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'editar direccion',
      'page_subcategory_level_3': '',
      'page_screen': 'editar direccion',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editUserAddress': {
      'name': 'YourDetails/EditAddress',
      'route': '/myAccount/YourDetails/EditAddress',
      'page_name': 'mi cuenta:tus datos:editar direccion',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'editar direccion',
      'page_subcategory_level_3': '',
      'page_screen': 'editar direccion',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editAddressPayment': {
      'name': 'editAddressPayment',
      'route': '/myAccount/editAddressPayment',
      'page_name': 'mi cuenta:metodos de pago:editar dirección de facturación',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'metodos de pago',
      'page_subcategory_level_2': 'editar dirección de facturación',
      'page_subcategory_level_3': '',
      'page_screen': 'editar dirección de facturación',
      'page_typology': 'principal',
    },
    'suggestedAddress': {
      'name': 'suggestedAddress',
      'route': '/myAccount/suggestedAddress',
      'page_name': 'mi cuenta:metodos de pago:editar dirección de facturación:normalizar datos',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'metodos de pago',
      'page_subcategory_level_2': 'editar dirección de facturación',
      'page_subcategory_level_3': 'normalizar datos',
      'page_screen': 'normalizar datos',
      'page_typology': 'principal',
    },
    'accessInfo': {
      'name': 'accessInfo',
      'route': '/myAccount/accessInfo',
      'page_name': 'mi cuenta:acceso a mi vodafone:resumen de acceso a mi vodafone',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'acceso a mi vodafone',
      'page_subcategory_level_2': 'resumen de acceso a mi vodafone',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de acceso a mi vodafone',
      'page_typology': 'principal',
      'deeplink': true
    },
    'serviceAccessInfo': {
      'name': 'Serviceaccess/MainPage',
      'route': '/myAccount/Serviceaccess/MainPage',
      'page_name': 'mi cuenta:acceso a mi vodafone:resumen de acceso a mi vodafone',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'acceso a mi vodafone',
      'page_subcategory_level_2': 'resumen de acceso a mi vodafone',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de acceso a mi vodafone',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editPassword': {
      'name': 'editPassword',
      'route': '/myAccount/editPassword',
      'page_name': 'mi cuenta:acceso a mi vodafone:editar clave',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'acceso a mi vodafone',
      'page_subcategory_level_2': 'editar clave',
      'page_subcategory_level_3': '',
      'page_screen': 'editar clave',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editUserPassword': {
      'name': 'Serviceaccess/Edituserpassword',
      'route': '/myAccount/Serviceaccess/Edituserpassword',
      'page_name': 'mi cuenta:acceso a mi vodafone:editar clave',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'acceso a mi vodafone',
      'page_subcategory_level_2': 'editar clave',
      'page_subcategory_level_3': '',
      'page_screen': 'editar clave',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editEmail': {
      'name': 'editEmail',
      'route': '/myAccount/editEmail',
      'page_name': 'mi cuenta:acceso a mi vodafone:editar usuario',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'acceso a mi vodafone',
      'page_subcategory_level_2': 'editar usuario',
      'page_subcategory_level_3': '',
      'page_screen': 'editar usuario',
      'page_typology': 'principal',
      'deeplink': true
    },
    'editUserName': {
      'name': 'Serviceaccess/Editusername',
      'route': '/myAccount/Serviceaccess/Editusername',
      'page_name': 'mi cuenta:acceso a mi vodafone:editar usuario',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'acceso a mi vodafone',
      'page_subcategory_level_2': 'editar usuario',
      'page_subcategory_level_3': '',
      'page_screen': 'editar usuario',
      'page_typology': 'principal',
      'deeplink': true
    },
    'bankInfo': {
      'name': 'bankInfo',
      'route': '/myAccount/bankInfo',
      'page_name': 'mi cuenta:metodos de pago:editar datos bancarios',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'metodos de pago',
      'page_subcategory_level_2': 'editar datos bancarios',
      'page_subcategory_level_3': '',
      'page_screen': 'editar datos bancarios',
      'page_typology': 'principal',
    },
    'otp': {
      'name': 'otp',
      'route': '/myAccount/otp',
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'contracts': {
      'name': 'contracts',
      'route': '/myAccount/contracts',
      'page_name': 'mi cuenta:mis contratos:resumen de mis contratos',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'mis contratos',
      'page_subcategory_level_2': 'resumen de mis contratos',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de mis contratos',
      'page_typology': 'principal',
      'deeplink': true
    },
    'myContracts': {
      'name': 'Mycontracts/MainPage',
      'route': '/myAccount/Mycontracts/MainPage',
      'page_name': 'mi cuenta:mis contratos:resumen de mis contratos',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'mis contratos',
      'page_subcategory_level_2': 'resumen de mis contratos',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de mis contratos',
      'page_typology': 'principal',
      'deeplink': true
    },
    'paymentMethods': {
      'name': 'paymentMethods',
      'route': '/myAccount/paymentMethods',
      'page_name': 'mi cuenta:metodos de pago:resumen de metodos de pago',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'metodos de pago',
      'page_subcategory_level_2': 'resumen de metodos de pago',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de metodos de pago',
      'page_typology': 'principal'
    },
    'userPaymentMethods': {
      'name': 'Paymethods/MainPage',
      'route': '/myAccount/Paymethods/MainPage',
      'page_name': 'mi cuenta:metodos de pago:resumen de metodos de pago',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'metodos de pago',
      'page_subcategory_level_2': 'resumen de metodos de pago',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de metodos de pago',
      'page_typology': 'principal',
      'deeplink': true
    },
    'billingAccounts': {
      'name': 'billingAccounts',
      'route': '/myAccount/billingAccounts',
      'page_name': 'pagos:mis pagos:metodos de pago',
      'site_section': 'pagos',
      'page_detail': 'metodos de pago',
      'page_subcategory_level_1': 'mis pagos',
      'page_subcategory_level_2': 'metodos de pago',
      'page_subcategory_level_3': '',
      'page_screen': 'metodos de pago',
      'page_typology': 'principal'
    },
    'allContracts': {
      'name': 'allContracts',
      'route': '/myAccount/allContracts',
      'page_name': 'mi cuenta'
    },
    'PermissionsAndAuthorizations': {
      'name': 'PermissionsAndAuthorizations',
      'route': '/myAccount/PermissionsAndAuthorizations',
      'deeplink': true,
      'page_name': 'mi cuenta:tus datos:autorizaciones asignadas',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'autorizaciones asignadas',
      'page_subcategory_level_3': '',
      'page_screen': 'autorizaciones asignadas',
      'page_typology': 'detalle'
    },
    'addNewAuthorized': {
      'name': 'addNewAuthorized',
      'route': '/myAccount/addNewAuthorized',
      'page_name': 'mi cuenta:tus datos:nueva autorizacion',
      'page_section': 'mi cuenta',
      'page_subcategory_level_1': 'tus datos',
      'page_subcategory_level_2': 'nueva autorizacion',
      'page_subcategory_level_3': '',
      'page_screen': 'nueva autorizacion',
      'page_typology': 'principal'
    },
    'createAuthorizedUser': {
      'name': 'CreateAuthorizedUser',
      'route': '/myAccount/CreateAuthorizedUser',
      'page_name': 'myAccount:OverviewScreen:addNewAuthorized',
      'page_section': 'myAccount',
      'page_subcategory_level_1': 'myAccount',
      'page_subcategory_level_2': 'OverviewScreen',
      'page_subcategory_level_3': 'addNewAuthorized',
      'page_screen': 'addNewAuthorized',
      'page_typology': 'principal',
      'deeplink': true
    },
    /** cc stands for Commitment Contract */
    'siteSelector': {
      'name': 'siteSelector',
      'route': '/myAccount/siteSelector',
    },
    'serviceSelectorCommitmentContract': {
      'name': 'serviceSelectorCommitmentContract',
      'route': '/myAccount/serviceSelectorCommitmentContract',
      'page_name': 'mivoweb:mi cuenta:mis permanencias',
      'asset_name': 'mivoweb',
      'navigation_level_1': 'mi cuenta',
      'navigation_level_2': 'mis permanencias',
      'navigation_level_3': '',
      'navigation_level_4': '',
      'page_screen': 'mis permanencias',
      'page_typology': 'principal',
      'deeplink': true
    },
    'MyServiceSelectorCommitmentContract': {
      'name': 'Mycommitments/MainScreen',
      'route': '/myAccount/Mycommitments/MainScreen',
      'deeplink': true
    },
    'commitmentContracts': {
      'name': 'commitmentContracts',
      'route': '/myAccount/commitmentContracts'
    },
    'gdprConfirmPermission': {
      'name': 'gdprConfirmPermission',
      'route': '/myAccount/gdprConfirmPermission',
      'page_name': 'permisos:gdpr:mas informacion',
      'page_section': 'permisos',
      'page_subcategory_level_1': 'gdpr',
      'page_subcategory_level_2': 'mas informacion',
      'page_subcategory_level_3': '',
      'page_screen': 'mas informacion',
      'page_typology': 'principal'
    }
  },
  'Promotions': {
    'name': 'Promotions',
    'route': '/Promotions',
    'permissionList': ['Authorized'],
    'page_name': 'Promotions:Promotions',
    'page_section': 'Promotions',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'Promotions',
    'page_typology': 'principal',
    'deeplink': true,
    'BlackFriday': {
      'name': 'BlackFriday',
      'route': '/Promotions/BlackFriday',
      'deeplink': false,
      'page_name': 'promotions:resumen de promotions',
      'page_section': 'promotions',
      'page_subcategory_level_1': 'resumen de promotions',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de promotions',
      'page_typology': 'principal'
    },
    'details': {
      'name': 'details',
      'route': '/Promotions/details',
      'page_name': 'promotions:detalle de movil',
      'page_section': 'promotions',
      'page_subcategory_level_1': 'detalle de movil',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de movil',
      'page_typology': 'detalle',
      'deeplink': false
    },
    'Delight': {
      'name': 'Delight',
      'route': '/Promotions/Delight',
      'deeplink': true
    },
    'DelightTV': {
      'name': 'DelightTV',
      'route': '/Promotions/DelightTV',
      'deeplink': false
    },
    'DelightTVSurvey': {
      'name': 'DelightTVSurvey',
      'route': '/Promotions/DelightTVSurvey',
      'deeplink': false
    },
    'TryAndPayPromo': {
      'name': 'TryAndPayPromo',
      'route': '/Promotions/TryAndPayPromo',
      'deeplink': true
    },
    'SocialTariff': {
      'name': 'SocialTariff',
      'route': '/Promotions/SocialTariff',
      'deeplink': true
    },
    'formularioTryFibra': {
      'name': 'formularioTryFibra',
      'route': '/Promotions/formularioTryFibra',
      'deeplink': true
    },
    'SimplePromoDelight': {
      'name': 'DelightSimplePromo',
      'route': '/Promotions/DelightSimplePromo'
    },
    'DelightVoucher': {
      'name': 'delightvoucher',
      'route': '/Promotions/delightvoucher',
      'deeplink': false
    },
    'DelightMulticard': {
      'name': 'DelightMulticard',
      'route': '/Promotions/DelightMulticard',
      'deeplink': false
    }
  },
  'ProductsAndServices': {
    'name': 'ProductsAndServices',
    'route': '/ProductsAndServices',
    'permissionList': ['Authorized'],
    'page_name': 'ProductsAndServices:ProductsAndServices',
    'page_section': 'ProductsAndServices',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'ProductsAndServices',
    'page_typology': 'principal',
    'deeplink': true,
    'landing': {
      'name': 'MainScreen',
      'route': '/ProductsAndServices/MainScreen',
      'deeplink': true,
      'page_name': 'productos y servicios:resumen de productos y servicios',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'resumen de productos y servicios',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de productos y servicios',
      'page_typology': 'principal'
    },
    'MainScreen': {
      'name': 'MainScreen',
      'route': '/ProductsAndServices/MainScreen',
      'deeplink': true,
      'page_name': 'productos y servicios:resumen de productos y servicios',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'resumen de productos y servicios',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de productos y servicios',
      'page_typology': 'principal'
    },
    'superWifiDetails': {
      'name': 'superWifiDetails',
      'route': '/ProductsAndServices/superWifiDetails',
      'deeplink': true
    },
    'onlineTvlanding': {
      'name': 'PlanDetails/OnlineTV',
      'route': '/ProductsAndServices/PlanDetails/OnlineTV',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'MainScreen',
      'page_typology': 'principal'
    },
    'businessProductDetail': {
      'name': 'businessProductDetail',
      'route': '/ProductsAndServices/businessProductDetail',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'MainScreen',
      'page_typology': 'principal'
    },
    'enjoyMoreDetails': {
      'name': 'enjoyMoreDetails',
      'route': '/ProductsAndServices/enjoyMoreDetails',
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'securenet': {
      'name': 'securenet',
      'route': '/ProductsAndServices/securenet',
      'deeplink': true,
      'page_name': 'productos y servicios:securenet',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'securenet',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'securenet',
      'page_typology': 'detalle'
    },
    'SecurenetConvergent': {
      'name': 'SecurenetConvergent',
      'route': '/ProductsAndServices/SecurenetConvergent',
      'page_name': 'productos y servicios:securenetConvergent',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'securenetConvergent',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'securenetConvergent',
      'page_typology': 'detalle',
      'deeplink': true,
      'SecurenetPostpaid': {
        'name': 'SecurenetPostpaid',
        'route': '/ProductsAndServices/SecurenetConvergent/SecurenetPostpaid',
        'deeplink': true,
      },
      'SecurenetFamily': {
        'name': 'SecurenetFamily',
        'route': '/ProductsAndServices/SecurenetConvergent/SecurenetFamily'
      },
      'SecurenetFamilyLanding': {
        'name': 'SecurenetFamilyLanding',
        'route': '/ProductsAndServices/SecurenetConvergent/SecurenetFamilyLanding',
        'deeplink': true
      }
    },
    'digitalProductsActivation': {
      'name': 'digitalProductsActivation',
      'route': '/ProductsAndServices/digitalProductsActivation',
      'signUp': {
        'name': 'digitalProductsActivation/signUp',
        'route': '/ProductsAndServices/digitalProductsActivation/signUp'
      }
    },
    'enjoyMoreDetailsChat': {
      'name': 'enjoymore/Chatpass',
      'route': '/ProductsAndServices/enjoymore/Chatpass',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'enjoyMoreDetailsSocial': {
      'name': 'enjoymore/Socialpass',
      'route': '/ProductsAndServices/enjoymore/Socialpass',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'socialPassVol': {
      'name': 'enjoymore/SocialPassVol',
      'route': '/ProductsAndServices/enjoymore/SocialPassVol',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'enjoyMoreDetailsVideo': {
      'name': 'enjoymore/Videopass',
      'route': '/ProductsAndServices/enjoymore/Videopass',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'videoPassHD': {
      'name': 'enjoymore/VideoPassHD',
      'route': '/ProductsAndServices/enjoymore/VideoHDPass',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'mapPass': {
      'name': 'enjoymore/MapPass',
      'route': '/ProductsAndServices/enjoymore/MapPass',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'enjoyMoreDetailsPass': {
      'name': 'EnjoyMore/PassDetails',
      'route': '/ProductsAndServices/EnjoyMore/PassDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'enjoyMoreDetailsMusic': {
      'name': 'enjoymore/Musicpass',
      'route': '/ProductsAndServices/enjoymore/Musicpass',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'enjoyMoreDetails',
      'page_typology': 'detalle'
    },
    'PrepaidenjoymoreDetailsPass': {
      'name': 'Prepaidenjoymore',
      'route': '/ProductsAndServices/Prepaidenjoymore',
      'deeplink': true
    },
    'PrepaidenjoymoreDetailsChatpass': {
      'name': 'Prepaidenjoymore/Chatpass',
      'route': '/ProductsAndServices/Prepaidenjoymore/Chatpass',
      'deeplink': true
    },
    'PrepaidenjoymoreDetailsSocialpass': {
      'name': 'Prepaidenjoymore/Socialpass',
      'route': '/ProductsAndServices/Prepaidenjoymore/Socialpass',
      'deeplink': true,
    },
    'PrepaidenjoymoreDetailsMusicpass': {
      'name': 'Prepaidenjoymore/Musicpass',
      'route': '/ProductsAndServices/Prepaidenjoymore/Musicpass',
      'deeplink': true
    },
    'PrepaidenjoymoreDetailsSocialpassVol': {
      'name': 'Prepaidenjoymore/SocialpassVol',
      'route': '/ProductsAndServices/Prepaidenjoymore/SocialpassVol',
      'deeplink': true
    },
    'PrepaidenjoymoreDetailsVideopass': {
      'name': 'Prepaidenjoymore/Videopass',
      'route': '/ProductsAndServices/Prepaidenjoymore/Videopass',
      'deeplink': true
    },
    'PrepaidenjoymoreDetailsVideoHDpass': {
      'name': 'Prepaidenjoymore/VideoHDpass',
      'route': '/ProductsAndServices/Prepaidenjoymore/VideoHDpass',
      'deeplink': true
    },
    'PrepaidenjoymoreDetailsMappass': {
      'name': 'Prepaidenjoymore/Mappass',
      'route': '/ProductsAndServices/Prepaidenjoymore/Mappass',
      'deeplink': true
    },
    'extraListDetails': {
      'name': 'extraListDetails',
      'route': '/ProductsAndServices/extraListDetails',
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList:extrasList:extraListDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'extrasCategoryList',
      'page_subcategory_level_2': 'extrasList',
      'page_subcategory_level_3': 'extraListDetails',
      'page_screen': 'extraListDetails',
      'page_typology': 'detalle'
    },
    'extrasCategoryList': {
      'name': 'extrasCategoryList',
      /** this link handled as dynamic deep link for entertainment in /ProductsAndServices/Extras/Entertainment/ExtraDetails */
      'route': '/ProductsAndServices/extrasCategoryList',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasCategoryList',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'extrasDataDetails': {
      'name': 'Extras/Data/ExtraDetails',
      'route': '/ProductsAndServices/Extras/Data/ExtraDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'extrasDataCategoryList': {
      'name': 'Extras/Data/Listing',
      'route': '/ProductsAndServices/Extras/Data/Listing',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasCategoryList',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'TouristExtraDetails': {
      'name': 'Extras/Data/TouristExtraDetails',
      'route': '/ProductsAndServices/Extras/Data/TouristExtraDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasCategoryList',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'extrasMinutesCategoryList': {
      'name': 'Extras/Minutes/Listing',
      'route': '/ProductsAndServices/Extras/Minutes/Listing',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasDetails',
      'page_typology': 'principal'
    },
    'extrasSMSDetails': {
      'name': 'Extras/SMS/ExtraDetails',
      'route': '/ProductsAndServices/Extras/SMS/ExtraDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasDetails',
      'page_typology': 'principal'
    },
    'extrasSMSCategoryList': {
      'name': 'Extras/SMS/Listing',
      'route': '/ProductsAndServices/Extras/SMS/Listing',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasList',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'extrasVoiceInternationalDetails': {
      'name': 'Extras/VoiceInternational/ExtraDetails',
      'route': '/ProductsAndServices/Extras/VoiceInternational/ExtraDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasDetails',
      'page_typology': 'principal'
    },
    'extrasVoiceNationalDetails': {
      'name': 'Extras/VoiceNational/ExtraDetails',
      'route': '/ProductsAndServices/Extras/VoiceNational/ExtraDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasDetails',
      'page_typology': 'principal'
    },
    'extrasVoiceInternationalCategoryListing': {
      'name': 'VoiceInternational/Listing', // this is change from all list URL but it is as per the deeplink page
      'route': '/ProductsAndServices/VoiceInternational/Listing',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasCategoryList',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'extrasVoiceNationalCategoryListing': {
      'name': 'Extras/VoiceNational/Listing',
      'route': '/ProductsAndServices/Extras/VoiceNational/Listing',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasCategoryList',
      'page_subcategory_level_3': '',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'termsAndConditions': {
      'name': 'termsAndConditions',
      'route': '/ProductsAndServices/termsAndConditions',
      'page_name': 'ProductsAndServices:MainScreen:enjoyMoreDetails:termsAndConditions',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'enjoyMoreDetails',
      'page_subcategory_level_3': 'termsAndConditions',
      'page_screen': 'termsAndConditions',
      'page_typology': 'principal'
    },
    'extrasList': {
      'name': 'extrasList',
      'route': '/ProductsAndServices/extrasList',
      'deeplink': true,
      'page_name': 'productos y servicios:extras:resumen de extras',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'extras',
      'page_subcategory_level_2': 'resumen de extras',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de extras',
      'page_typology': 'principal'
    },
    'extrasListDeep': {
      'name': 'Extras/MainScreen',
      'route': '/ProductsAndServices/Extras/MainScreen',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:extrasCategoryList:extrasList',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'extrasCategoryList',
      'page_subcategory_level_3': 'extrasList',
      'page_screen': 'extrasList',
      'page_typology': 'principal'
    },
    'details': {
      'name': 'details',
      'route': '/ProductsAndServices/details',
      'page_name': 'productos y servicios:detalle de tarifa',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de tarifa',
      'page_typology': 'detalle',
      'deeplink': true
    },
    'detailsDeep': {
      'name': 'PlanDetails',
      'route': '/ProductsAndServices/PlanDetails',
      'page_name': 'ProductsAndServices:details',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'details',
      'page_subcategory_level_3': '',
      'page_screen': 'details',
      'page_typology': 'detalle',
      'deeplink': true
    },
    'update': {
      'name': 'update',
      'route': '/ProductsAndServices/update',
      'page_name': 'productos y servicios:actualizar equipo',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'Update Cpe',
      'page_subcategory_level_2': 'productos y servicios:actualizar equipo:listado equipos',
      'page_subcategory_level_3': '',
      'page_screen': 'Update Cpe',
      'page_typology': 'detalle',
      'tvDeeplink': {
        'name': 'updateDeco',
        'route': '/ProductsAndServices/updateDeco',
        'deeplink': true
      },
      'fibreDeeplink': {
        'name': 'updateRouter',
        'route': '/ProductsAndServices/updateRouter',
        'deeplink': true
      },
      'deeplink': false
    },
    'combi': {
      'name': 'combi',
      'route': '/ProductsAndServices/combi',
      'page_name': 'productos y servicios:actualizar equipo',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'Update Cpe',
      'page_subcategory_level_2': 'productos y servicios:actualizar equipo:listado equipos',
      'page_subcategory_level_3': '',
      'page_screen': 'Update Cpe',
      'page_typology': 'detalle',
      'deeplink': false
    },
    'superWifiUpdate': {
      'name': 'superWifiActivation',
      'route': '/ProductsAndServices/superWifiActivation',
      'deeplink': true
    },
    'superWifiMainPage': {
      'name': 'superWifiMainPage',
      'route': '/ProductsAndServices/superWifiMainPage',
      'page_name': 'superwifi'
    },
    'tvDetailsDeep': {
      'name': 'PlanDetails/TV',
      'route': '/ProductsAndServices/PlanDetails/TV',
      'deeplink': true,
      'page_name': 'ProductsAndServices:details',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'details',
      'page_subcategory_level_3': '',
      'page_screen': 'details',
      'page_typology': 'detalle',
    },
    'special': {
      'name': 'special',
      'route': '/ProductsAndServices/special',
      'page_name': 'productos y servicios:detalle de tarifa:llamadas de tarificacion especial',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': 'llamadas de tarificacion especial',
      'page_subcategory_level_3': '',
      'page_screen': 'details',
      'page_typology': 'detalle'
    },
    'specialCall': {
      'name': 'PlanDetails/Specialcallprices',
      'route': '/ProductsAndServices/PlanDetails/Specialcallprices',
      'deeplink': true,
      'page_name': 'productos y servicios:detalle de tarifa:llamadas de tarificacion especial',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': 'llamadas de tarificacion especial',
      'page_subcategory_level_3': '',
      'page_screen': 'details',
      'page_typology': 'detalle'
    },
    'specialSMS': {
      'name': 'PlanDetails/Specialsmsprices',
      'route': '/ProductsAndServices/PlanDetails/Specialsmsprices',
      'deeplink': true,
      'page_name': 'productos y servicios:detalle de tarifa:sms de tarificacion especial',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': 'sms de tarificacion especial',
      'page_subcategory_level_3': '',
      'page_screen': 'details',
      'page_typology': 'detalle'
    },
    'manageWorryFree': {
      'name': 'manageWorryFree',
      'route': '/ProductsAndServices/manageWorryFree',
      'page_name': 'productos y servicios:mas megas:resumen de mas megas',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'mas megas',
      'page_subcategory_level_2': 'resumen de mas megas',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de mas megas',
      'page_typology': 'principal'
    },
    'worryFreeMain': {
      'name': 'worryfree/MainPage',
      'route': '/ProductsAndServices/worryfree/MainPage',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'MainScreen',
      'page_typology': 'principal'
    },
    'channelDetailsPlan': {
      'name': 'PlanDetails/ExtraTVChannelDetails',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelDetails': {
      'name': 'channelDetails',
      'route': '/ProductsAndServices/channelDetails',
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'newChannelDetails': {
      'name': 'detailChannelPack',
      'route': '/ProductsAndServices/detailChannelPack',
      'deeplink': true
    },
    'checkoutChannelDetails': {
      'name': 'checkoutChannelDetails',
      'route': '/ProductsAndServices/checkoutChannelDetails',
      'deeplink': false
    },
    'txCheckoutChannelDetails': {
      'name': 'txCheckoutChannelDetails',
      'route': '/ProductsAndServices/txCheckoutChannelDetails',
      'deeplink': false
    },
    'ContractDecoNoDeco': {
      'name': 'ContractDecoNoDeco',
      'route': '/ProductsAndServices/ContractDecoNoDeco',
      'deeplink': false
    },
    'txContractDecoNoDeco': {
      'name': 'txContractDecoNoDeco',
      'route': '/ProductsAndServices/txContractDecoNoDeco',
      'deeplink': false
    },
    'channelDetailsBaby': {
      'name': 'PlanDetails/ExtraTVChannelDetails/BabyTV',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails/BabyTV',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelDetailsVodafoneLiga': {
      'name': 'PlanDetails/ExtraTVChannelDetails/VodafoneLiga',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails/VodafoneLiga',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelDetailsCasaVision': {
      'name': 'PlanDetails/ExtraTVChannelDetails/CasaVision',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails/CasaVision',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelDetailsAdults': {
      'name': 'PlanDetails/ExtraTVChannelDetails/Adults',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails/Adults',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelDetailsDark': {
      'name': 'PlanDetails/ExtraTVChannelDetails/Dark',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails/Dark',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelDetailsFootball': {
      'name': 'PlanDetails/ExtraTVChannelDetails/Football',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelDetails/Football',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelList:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelList',
      'page_subcategory_level_3': 'channelDetails',
      'page_screen': 'channelDetails',
      'page_typology': 'detalle'
    },
    'channelList': {
      'name': 'channelList',
      'route': '/ProductsAndServices/channelList',
      'page_name': 'productos y servicios:detalle de tarifa:canales extra',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': 'canales extra',
      'page_subcategory_level_3': '',
      'page_screen': 'canales extra',
      'page_typology': 'principal'
    },
    'newChannelList': {
      'name': 'newChannelList',
      'route': '/ProductsAndServices/newChannelList',
      'deeplink': true,
    },
    'txnewChannelList': {
      'name': 'txnewChannelList',
      'route': '/ProductsAndServices/txnewChannelList',
      'deeplink': true,
    },
    'newChannelListMicro': {
      'name': 'newChannelListMicro',
      'route': '/ProductsAndServices/newChannelListMicro',
      'page_name': 'productos y servicios:ofertas de tv',
      'page_section': 'dashboard',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'ofertas de tv',
      'page_typology': 'principal'
    },
    'channelListDeep': {
      'name': 'PlanDetails/ExtraTVChannelListing',
      'route': '/ProductsAndServices/PlanDetails/ExtraTVChannelListing',
      'deeplink': true,
      'page_name': 'ProductsAndServices:MainScreen:channelDetails',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'channelDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'channelList',
      'page_typology': 'principal'
    },
    'onlineTvDetails': {
      'name': 'onlineTvDetails',
      'route': '/ProductsAndServices/onlineTvDetails',
      'page_section': 'ProductsAndServices',
      'page_name': 'ProductsAndServices:MainScreen:onlineTvDetails',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'onlineTvDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'channelList',
      'page_typology': 'detalle'
    },
    'decoEquipment': {
      'name': 'decoEquipment',
      'route': '/ProductsAndServices/decoEquipment',
      'page_section': 'ProductsAndServices',
      'page_name': 'ProductsAndServices:MainScreen:decoEquipmentDetails',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'decoEquipmentDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'decoEquipmentList',
      'page_typology': 'detalle'
    },
    'onlineTvDetailsUsername': {
      'name': 'PlanDetails/OnlineTV/changeusername',
      'route': '/ProductsAndServices/PlanDetails/OnlineTV/changeusername',
      'deeplink': true,
      'page_section': 'ProductsAndServices',
      'page_name': 'ProductsAndServices:MainScreen:onlineTvDetails',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'onlineTvDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'channelList',
      'page_typology': 'detalle'
    },
    'onlineTvDetailsPassword': {
      'name': 'PlanDetails/OnlineTV/changepassword',
      'route': '/ProductsAndServices/PlanDetails/OnlineTV/changepassword',
      'deeplink': true,
      'page_section': 'ProductsAndServices',
      'page_name': 'ProductsAndServices:MainScreen:onlineTvDetails',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'onlineTvDetails',
      'page_subcategory_level_3': '',
      'page_screen': 'channelList',
      'page_typology': 'detalle'
    },
    'changeUsername': {
      'name': 'changeUsername',
      'route': '/ProductsAndServices/changeUsername',
      'page_name': 'ProductsAndServices:MainScreen:onlineTvDetail:changeUsername',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'onlineTvDetail',
      'page_subcategory_level_3': 'changeUsername',
      'page_screen': 'changeUsername',
      'page_typology': 'detalle'
    },
    'changePin': {
      'name': 'changePin',
      'route': '/ProductsAndServices/changePin',
      'page_name': 'ProductsAndServices:MainScreen:onlineTvDetail:changePin',
      'page_section': 'ProductsAndServices',
      'page_subcategory_level_1': 'MainScreen',
      'page_subcategory_level_2': 'onlineTvDetail',
      'page_subcategory_level_3': 'changePin',
      'page_screen': 'changePin',
      'page_typology': 'detalle'
    },
    'onlineTvDetail': {
      'name': 'onlineTvDetail',
      'route': '/ProductsAndServices/onlineTvDetail',
      'page_name': 'productos y servicios:detalle de tarifa:vodafone tv online',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': 'vodafone tv online',
      'page_subcategory_level_3': '',
      'page_screen': 'vodafone tv online',
      'page_typology': 'detalle',
      'deeplink': true
    },
    'entertainmentDetails': {
      'name': 'entertainmentDetails',
      'route': '/ProductsAndServices/entertainmentDetails',
      'deeplink': true
    },
    'entertainmentCategoryList': {
      'name': 'Extras/Entertainment/Listing',
      'route': '/ProductsAndServices/Extras/Entertainment/Listing',
      'deeplink': true
    },
    'extrasEntertainmentDetails': {
      'name': 'Extras/Entertainment/ExtraDetails',
      'route': '/ProductsAndServices/Extras/Entertainment/ExtraDetails',
      'deeplink': true
    },
    'oneProfesionalDetails': {
      'name': 'oneProfesionalDetails',
      /** this link handled as dynamic deep link in /ProductsAndServices/Extras/Entertainment/ExtraDetails */
      'route': '/ProductsAndServices/oneProfesionalDetails',
    },
    'editDataSharing': {
      'name': 'editDataSharing',
      'route': '/ProductsAndServices/editDataSharing',
      'deeplink': true,
      'page_name': 'consumo:mi consumo:compartir datos',
      'page_section': 'consumo',
      'page_subcategory_level_1': 'mi consumo',
      'page_subcategory_level_2': 'compartir datos',
      'page_subcategory_level_3': '',
      'page_screen': 'compartir datos',
      'page_typology': 'principal'
    },
    'miniXsDetails': {
      'name': 'miniXsDetails',
      'route': '/ProductsAndServices/miniXsDetails',
      'page_name': 'productos y servicios:detalle de tarifa:promocion',
      'page_section': 'productos y servicios',
      'page_subcategory_level_1': 'detalle de tarifa',
      'page_subcategory_level_2': 'promocion',
      'page_subcategory_level_3': '',
      'page_screen': 'promocion',
      'page_typology': 'principal'
    },
    'buyMiniXs': {
      'name': 'buyMiniXs',
      'route': '/ProductsAndServices/buyMiniXs'
    },
    'planDetailsStatic': {
      'name': 'planDetailsStatic',
      'route': '/ProductsAndServices/planDetailsStatic'
    },
    'chatPassDetailsStatic': {
      'name': 'chatPassDetailsStatic',
      'route': '/ProductsAndServices/chatPassDetailsStatic'
    },
    'landingStatic': {
      'name': 'landingStatic',
      'route': '/ProductsAndServices/landingStatic'
    },
    'enjoyMoreListing': {
      'name': 'Prepaidenjoymore/Listing',
      'route': '/ProductsAndServices/Prepaidenjoymore/Listing',
      'deeplink': true
    },
    'newPackageDetails': {
      'name': 'newPackageDetails',
      'route': '/ProductsAndServices/newPackageDetails',
      'deeplink': true,
      'page_section': 'ProductsAndServices',
      'page_name': 'ProductsAndServices:newPackageDetails',
      'page_subcategory_level_1': 'newPackageDetails',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'newPackageDetails',
      'page_typology': 'detalle'
    },
    'PlanDetails': {
      'UpgradePackageSelector': {
        'name': 'PlanDetails/UpgradePackageSelector',
        'route': '/ProductsAndServices/PlanDetails/UpgradePackageSelector',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'ProductsAndServices:PlanDetails:UpgradePackageSelector',
        'page_subcategory_level_1': 'PlanDetails',
        'page_subcategory_level_2': 'UpgradePackageSelector',
        'page_subcategory_level_3': '',
        'page_screen': 'UpgradePackageSelector',
        'page_typology': 'principal'
      },
      'Total': {
        'name': 'PlanDetails/NewPackageDetails/Total',
        'route': '/ProductsAndServices/PlanDetails/NewPackageDetails/Total',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'ProductsAndServices:PlanDetails:NewPackageDetails:Total',
        'page_subcategory_level_1': 'PlanDetails',
        'page_subcategory_level_2': 'NewPackageDetails',
        'page_subcategory_level_3': 'Total',
        'page_screen': 'Total',
        'page_typology': 'detalle'
      },
      'Extra': {
        'name': 'PlanDetails/NewPackageDetails/Extra',
        'route': '/ProductsAndServices/PlanDetails/NewPackageDetails/Extra',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'ProductsAndServices:PlanDetails:NewPackageDetails:Extra',
        'page_subcategory_level_1': 'PlanDetails',
        'page_subcategory_level_2': 'NewPackageDetails',
        'page_subcategory_level_3': 'Extra',
        'page_screen': 'Extra',
        'page_typology': 'detalle'
      }
    },
    'ThirdPartySummaryLanding': {
      'name': 'ThirdPartySummary',
      'route': '/ProductsAndServices/ThirdPartySummary',
      'deeplink': true
    },
    'channelContent': {
      'name': 'channelContent',
      'route': '/ProductsAndServices/channelContent',
      'deeplink': true
    },
    'MiWifi': {
      'name': 'ManageMiWifi',
      'route': '/ProductsAndServices/ManageMiWifi',
      'deeplink': true,
      'MiWifiLanding': {
        'name': 'MiWifiLanding',
        'route': '/ProductsAndServices/ManageMiWifi/MiWifiLanding',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'productos y servicios:gestionar wifi',
        'page_subcategory_level_1': 'gestionar wifi',
        'page_subcategory_level_2': '',
        'page_subcategory_level_3': '',
        'page_screen': 'gestionar wifi',
        'page_typology': 'detalle'
      },
      'ChangeMiWifiChannel': {
        'name': 'ChangeMiWifiChannel',
        'route': '/ProductsAndServices/ManageMiWifi/ChangeMiWifiChannel',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'productos y servicios:gestionar wifi:canal',
        'page_subcategory_level_1': 'gestionar wifi',
        'page_subcategory_level_2': 'canal',
        'page_subcategory_level_3': '',
        'page_screen': 'canal',
        'page_typology': 'detalle'
      },
      'changeMiWifiName': {
        'name': 'changeMiWifiName',
        'route': '/ProductsAndServices/ManageMiWifi/changeMiWifiName',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'productos y servicios:gestionar wifi:nombre',
        'page_subcategory_level_1': 'gestionar wifi',
        'page_subcategory_level_2': 'nombre',
        'page_subcategory_level_3': '',
        'page_screen': 'nombre',
        'page_typology': 'detalle'
      },
      'changeMiWifiPassword': {
        'name': 'changeMiWifiPassword',
        'route': '/ProductsAndServices/ManageMiWifi/changeMiWifiPassword',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'productos y servicios:gestionar wifi:contraseña',
        'page_subcategory_level_1': 'gestionar wifi',
        'page_subcategory_level_2': 'contraseña',
        'page_subcategory_level_3': '',
        'page_screen': 'contraseña',
        'page_typology': 'detalle'
      },
      'ChangeSecuritySettings': {
        'name': 'ChangeSecuritySettings',
        'route': '/ProductsAndServices/ManageMiWifi/ChangeSecuritySettings',
        'deeplink': true,
        'page_section': 'ProductsAndServices',
        'page_name': 'productos y servicios:gestionar wifi:tipo de seguridad',
        'page_subcategory_level_1': 'gestionar wifi',
        'page_subcategory_level_2': 'tipo de seguridad',
        'page_subcategory_level_3': '',
        'page_screen': 'tipo de seguridad',
        'page_typology': 'detalle'
      }
    },
    'OneNumberActivation': {
      'name': 'OneNumberActivation',
      'route': '/ProductsAndServices/OneNumberActivation',
      'deeplink': true
    },
    'OneNumberLanding': {
      'name': 'OneNumberLanding',
      'route': '/ProductsAndServices/OneNumberLanding',
      'deeplink': true
    },
    'VProductsPurchase': {
      'name': 'VProductsPurchase',
      'route': '/ProductsAndServices/VProductsPurchase'
    },
    'VProductsActivation': {
      'name': 'VProductsActivation',
      'route': '/ProductsAndServices/VProductsActivation'
    },
    'IotPage': {
      'name': 'IotPage',
      'route': '/ProductsAndServices/IotPage'
    },
    'NewSuperWifi': {
      'name': 'NewSuperWifi',
      'route': '/ProductsAndServices/NewSuperWifi',
      'SuperWifiLanding': {
        'name': 'SuperWifiLanding',
        'route': '/ProductsAndServices/NewSuperWifi/SuperWifiLanding',
        'page_name': 'superwifi:onboarding'
      },
      'SuperWifiActivation': {
        'name': 'SuperWifiActivation',
        'route': '/ProductsAndServices/NewSuperWifi/SuperWifiActivation',
      },
      'SuperWifiInstallation': {
        'name': 'SuperWifiInstallation',
        'route': '/ProductsAndServices/NewSuperWifi/SuperWifiInstallation',
      },
      'SuperWifiRegisterEmail': {
        'name': 'SuperWifiRegisterEmail',
        'route': '/ProductsAndServices/NewSuperWifi/SuperWifiRegisterEmail',
        'page_name': 'superwifi:identificacion'
      },
      'SuperWifiConfiguration': {
        'name': 'SuperWifiConfiguration',
        'route': '/ProductsAndServices/NewSuperWifi/SuperWifiConfiguration',
        'page_name': 'superwifi:gestion red y extensores:red'
      }
    },
    'CommercialCareInfo': {
      'name': 'CommercialCareInfo',
      'route': '/ProductsAndServices/CommercialCareInfo',
      'deeplink': true
    },
    'OnePlusTv': {
      'name': 'OnePlusTv',
      'route': '/ProductsAndServices/OnePlusTv'
    },
    'ProfesionalPackageDetail': {
      'name': 'ProfesionalPackageDetail',
      'route': '/ProductsAndServices/ProfesionalPackageDetail'
    },
    'BonusesSecondResidences': {
      'name': 'BonusesSecondResidences',
      'route': '/ProductsAndServices/BonusesSecondResidences',
      'deeplink': true,
      'Detail': {
        'name': 'BonusesSecondResidences/Detail',
        'route': '/ProductsAndServices/BonusesSecondResidences/Detail',
      }
    },
    'CommercialCareLanding': {
      'name': 'CommercialCareLanding',
      'route': '/ProductsAndServices/CommercialCareLanding',
      'deeplink': false
    },
    'BenefitRenewal': {
      'name': 'BenefitRenewal',
      'route': '/ProductsAndServices/BenefitRenewal',
      'deeplink':  true
    },
    'Smartpay': {
      'name': 'Smartpay',
      'route': '/ProductsAndServices/Smartpay',
      'deeplink':  true
    },
    'SaldoTab': {
      'name': 'saldoTab',
      'route': '/ProductsAndServices/saldoTab',
      'deeplink':  true
    },
  },
  'TariffChange': {
    'name': 'TariffChange',
    'route': '/TariffChange',
    'TariffList': {
      'name': 'TariffList',
      'route': '/TariffChange/TariffList'
    },
    'TariffDetailsPage': {
      'name': 'TariffDetailsPage',
      'route': '/TariffChange/TariffDetailsPage'
    }
  },
  'TariffInfo': {
    'name': 'tariffInfo',
    'route': '/tariffInfo',
    'deeplink': true
  },
  'NewLines': {
    'name': 'NewLinesPurchase',
    'route': '/NewLinesPurchase',
    'permissionList': ['Authorized'],
    'page_name': 'NewLines:NewLines',
    'page_section': 'NewLines',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'NewLines',
    'page_typology': 'principal',
    'deeplink': true,
    'landing': {
      'name': 'MainScreen',
      'route': '/NewLinesPurchase/MainScreen',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'MainScreen': {
      'name': 'MainScreen',
      'route': '/NewLinesPurchase/MainScreen',
      'deeplink': true,
      'page_name': '',
      'page_section': 'líneas adicionales',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'typeOfferNewLines': {
      'name': 'newlinescheckout',
      'route': '/NewLinesPurchase/newlinescheckout'
    }
  },
  'migrahogar': {
    'name': 'migrahogar',
    'route': '/migrahogar',
    'permissionList': ['Authorized'],
    'deeplink': true,
    'mainscreen': {
      'name': 'mainscreen',
      'route': '/migrahogar/mainscreen',
      'deeplink': true
    },
    'migratypeoffer': {
      'name': 'migratypeoffer',
      'route': '/migrahogar/migratypeoffer'
    },
    'migrasummary': {
      'name': 'migrasummary',
      'route': '/migrahogar/migrasummary'
    }
  },
  'CommercialCare': {
    'name': 'commercialcare',
    'route': '/commercialcare',
    'permissionList': ['Authorized'],
    'deeplink': false,
    'landing': {
      'name': 'mainscreen',
      'route': '/commercialcare/mainscreen',
      'deeplink': false
    },
  },
  'CommercialMobile': {
    'name': 'commercialhandsetrenewal',
    'route': '/commercialhandsetrenewal',
    'permissionList': ['Authorized'],
    'page_name': 'CommercialMobile:CommercialMobile',
    'page_section': 'CommercialMobile',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'CommercialMobile',
    'page_typology': 'principal',
    'deeplink': true,
    'landing': {
      'name': 'mainscreen',
      'route': '/commercialhandsetrenewal/mainscreen',
      'deeplink': true
    },
    'MainScreen': {
      'name': 'mainscreen',
      'route': '/commercialhandsetrenewal/mainscreen',
      'deeplink': true
    },
    'terminalDetail': {
      'name': 'Detail',
      'route': '/commercialhandsetrenewal/Detail',
      'deeplink': true
    },
    'mobileCheckout': {
      'name': 'mobileCheckout',
      'route': '/commercialhandsetrenewal/mobileCheckout',
      'deeplink': true,
    },
    'commercialSummary': {
      'name': 'Summary',
      'route': '/commercialhandsetrenewal/Summary',
      'deeplink': true
    },
  },
  'PurchasedProducts': {
    'name': 'PurchasedProducts',
    'route': '/PurchasedProducts',
    'permissionList': ['Authorized'],
    'page_name': 'PurchaseProducts:PurchaseProducts',
    'page_section': 'PurchaseProducts',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'PurchaseProducts',
    'page_typology': 'principal',
    'MainScreen': {
      'name': 'MainScreen',
      'route': '/PurchasedProducts/MainScreen',
      'deeplink': true,
      'page_name': 'que tengo contratado:resumen que tengo contratado',
      'page_section': 'que tengo contratado',
      'page_subcategory_level_1': 'resumen que tengo contratado',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen que tengo contratado',
      'page_typology': 'principal'
    },
    'OnePlus': {
      'name': 'OnePlus',
      'route': '/PurchasedProducts/OnePlus',
      'deeplink': true,
      'page_name': 'que tengo contratado:resumen que tengo contratado',
      'page_section': 'que tengo contratado',
      'page_subcategory_level_1': 'resumen que tengo contratado',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen que tengo contratado',
      'page_typology': 'principal',
      'onBoarding': {
        'name': 'OnePlus/onBoarding',
        'route': '/PurchasedProducts/OnePlus/onBoarding'
      }
    },
    'oobDetails': {
      'name': 'oobDetails',
      'route': '/PurchasedProducts/oobDetails',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'couteDetails': {
      'name': 'couteDetails',
      'route': '/PurchasedProducts/couteDetails',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    }
  },
  'TopUp': {
    'name': 'TopUp',
    'route': '/TopUp',
    'deeplink': true,
    'permissionList': ['Authorized'],
    'topUpHistory': {
      'name': 'topUpHistory',
      'route': '/TopUp/topUpHistory',
      'deeplink': true,
      'page_name': 'recargas:historico de recargas',
      'page_section': 'recargas',
      'page_subcategory_level_1': 'historico de recargas',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'historico de recargas',
      'page_typology': 'principal'
    },
    'TopUpHistory': {
      'name': 'TopUpHistory',
      'route': '/TopUp/TopUpHistory',
      'deeplink': true,
      'page_name': 'recargas:historico de recargas',
      'page_section': 'recargas',
      'page_subcategory_level_1': 'historico de recargas',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'historico de recargas',
      'page_typology': 'principal'
    },
    'topUpCurrentSpendings': {
      'name': 'currentSpendingHistory',
      'route': '/TopUp/currentSpendingHistory',
      'deeplink': true
    },
    'topUpPrepaidservice': {
      'name': 'topUpPrepaidservice',
      'route': '/TopUp/topUpPrepaidservice',
      'deeplink': true,
      'page_name': 'recargas:realizar recarga:inicio de recarga',
      'page_section': 'recargas',
      'page_subcategory_level_1': 'realizar recarga',
      'page_subcategory_level_2': 'inicio de recarga',
      'page_subcategory_level_3': '',
      'page_screen': 'inicio de recarga',
      'page_typology': 'principal'
    },
    'mva10TopupLandingWithPci': {
      'name': 'mva10TopupLanding',
      'route': '/TopUp/mva10TopupLanding'
    },
    'extraBalancePegaOffer': {
      'name': 'extraBalancePegaOffer',
      'route': '/TopUp/extraBalancePegaOffer',
      'deeplink': true
    },
    'privateTopUp': {
      'name': 'privateTopUp',
      'route': '/TopUp/privateTopUp',
      'deeplink': true
    },
    'topUpMovementsHistory': {
      'name': 'topUpMovementsHistory',
      'route' : '/TopUp/topUpMovementsHistory',
      'deeplink': true
    }
  },
  'billing': {
    'name': 'billing',
    'route': '/billing',
    'permissionList': ['Authorized'],
    'billingOverview': {
      'name': 'billingOverview',
      'route': '/billing/billingOverview',
      'page_name': 'facturas:mis facturas:resumen de cuentas',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'resumen de cuentas',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de cuentas',
      'page_typology': 'principal',

      'deeplink': true
    },
    'billOverview': {
      'name': 'BillOverview',
      'route': '/billing/BillOverview',
      'page_name': 'facturas:mis facturas:resumen de cuentas',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'resumen de cuentas',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen de cuentas',
      'page_typology': 'principal',

      'deeplink': true
    },
    'billingDetails': {
      'name': 'billingDetails',
      'route': '/billing/billingDetails',
      'page_name': 'facturas:mis facturas:detalle de factura',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'detalle de factura',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de factura',
      'page_typology': 'principal',
      'deeplink': true
    },
    'billDetails': {
      'name': 'BillDetails',
      'route': '/billing/BillDetails',
      'page_name': 'facturas:mis facturas:resumen facturas:detalle de factura',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'resumen facturas',
      'page_subcategory_level_3': 'detalle factura',
      'page_screen': 'detalle de factura',
      'page_typology': 'principal',
      'deeplink': true
    },
    'billingDetailsServiceUsages': {
      'name': 'billingDetailsServiceUsages',
      'route': '/billing/billingDetailsServiceUsages',
      'page_name': 'facturas:mis facturas:detalle de factura:detalle de consumo',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'detalle de factura',
      'page_subcategory_level_3': 'detalle de consumo',
      'page_screen': 'detalle de consumo',
      'page_typology': 'principal',
      'deeplink': true
    },
    'billDetailsConsumptionDetails': {
      'name': 'BillDetails/ConsumptionDetails',
      'route': '/billing/BillDetails/ConsumptionDetails',
      'page_name': 'facturas:mis facturas:detalle de factura:detalle de consumo',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'detalle de factura',
      'page_subcategory_level_3': 'detalle de consumo',
      'page_screen': 'detalle de consumo',
      'page_typology': 'principal',
      'deeplink': true
    },
    'creditNotes': {
      'name': 'creditNotes',
      'route': '/billing/creditNotes',
      'deeplink': true
    },
    'abonos': {
      'name': 'Abonos',
      'route': '/billing/Abonos',
      'deeplink': true
    },
    'deviceInvoice': {
      'name': 'deviceInvoice',
      'route': '/billing/deviceInvoice',
      'deeplink': true,
      'page_name': 'facturas:factura dispositivos:resumen facturas dispositivo',
      'site_section': 'facturas',
      'page_subcategory_level_1': 'factura dispositivos',
      'page_subcategory_level_2': 'resumen facturas dispositivo',
      'page_subcategory_level_3': '',
      'page_screen': 'resumen facturas dispositivo',
      'page_typology': 'principal'
    },
    'billDetailsDeviceInvoice': {
      'name': 'BillDetails/DevicesInvoices',
      'route': '/billing/BillDetails/DevicesInvoices',
      'deeplink': true
    },
    'billingDetailsBillShock': {
      'name': 'billingDetailsBillShock',
      'route': '/billing/billingDetailsBillShock',
      'deeplink': true
    },
    'billDetailsBillShock': {
      'name': 'BillDetails/BillShock',
      'route': '/billing/BillDetails/BillShock',
      'deeplink': true
    },
    'billingDetailsBillShockSummery': {
      'name': 'billingDetailsBillShockSummery',
      'route': 'billing/billingDetailsBillShockSummery'
    },
    'billConfig': {
      'name': 'billConfig',
      'route': '/billing/billConfig',
      'deeplink': true,
      'page_name': 'facturas:configuracion factura:seleccionar cuenta',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'configuracion factura',
      'page_subcategory_level_2': 'seleccionar cuenta',
      'page_subcategory_level_3': '',
      'page_screen': 'seleccionar cuenta',
      'page_typology': 'principal'
    },
    'billConfiguration': {
      'name': 'BillConfigurations',
      'route': '/billing/BillConfigurations',
      'deeplink': true,
      'page_name': 'facturas:configuracion factura:seleccionar cuenta',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'configuracion factura',
      'page_subcategory_level_2': 'seleccionar cuenta',
      'page_subcategory_level_3': '',
      'page_screen': 'seleccionar cuenta',
      'page_typology': 'principal'
    },
    'eBill': {
      'name': 'eBill',
      'route': '/billing/eBill',
      'deeplink': true,
      'page_name': 'facturas:configuracion factura',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'configuracion factura',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'facturas',
      'page_typology': 'principal',
      'site_section': 'facturas'
    },
    'currentConsumption': {
      'name': 'currentConsumption',
      'route': '/billing/currentConsumption',
      'deeplink': true,
      'page_name': 'consumo:mi consumo:detalle de consumo fuera de tarifa',
      'page_section': 'consumo',
      'page_subcategory_level_1': 'mi consumo',
      'page_subcategory_level_2': 'detalle de consumo fuera de tarifa',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de consumo fuera de tarifa',
      'page_typology': 'principal'
    },
    'currentSpendingConsumptionDetails': {
      'name': 'CurrentSpendingConsumptionDetails',
      'route': '/billing/CurrentSpendingConsumptionDetails',
      'deeplink': true
    },
    'billPayment': {
      'name': 'billPayment',
      'route': '/billing/billPayment',
      'deeplink': true,
      'page_name': 'facturas:pago de facturas:estado de mis facturas',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'pago de facturas',
      'page_subcategory_level_2': 'estado de mis facturas',
      'page_subcategory_level_3': '',
      'page_screen': 'estado de mis facturas',
      'page_typology': 'principal'
    },
    'BillPayment': {
      'name': 'BillPayment',
      'route': '/billing/BillPayment',
      'deeplink': true,
      'page_name': 'facturas:pago de facturas:estado de mis facturas',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'pago de facturas',
      'page_subcategory_level_2': 'estado de mis facturas',
      'page_subcategory_level_3': '',
      'page_screen': 'estado de mis facturas',
      'page_typology': 'principal'
    },
    'billPaymentDetails': {
      'name': 'billPaymentDetails',
      'route': '/billing/billPaymentDetails',
      'deeplink': true,
      'page_name': 'pagos:mis pagos',
      'page_section': 'pagos',
      'page_subcategory_level_1': 'mis pagos',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'mis pagos',
      'page_typology': 'principal'
    },
    'paymentCommitments': {
      'name': 'paymentCommitments',
      'route': '/billing/paymentCommitments',
      'deeplink': false,
    },
    'noBills': {
      'name': 'noBills',
      'route': '/billing/noBills',
      'page_name': 'facturas:mis facturas:no hay facturas disponibles',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'no hay facturas disponibles',
      'page_subcategory_level_3': '',
      'page_screen': 'no hay facturas disponibles',
      'page_typology': 'principal',
      'deeplink': true

    },
    'billPaymentReady': {
      'name': 'billPaymentReady',
      'route': '/billing/billPaymentReady',
      'page_name': 'facturas:pago de facturas:detalle de pago de factura',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'pago de facturas',
      'page_subcategory_level_2': 'detalle de pago de factura',
      'page_subcategory_level_3': '',
      'page_screen': 'detalle de pago de factura',
      'page_typology': 'principal'
    },
    'billPaymentProcess': {
      'name': 'billPaymentProcess',
      'route': '/billing/billPaymentProcess',
      'page_name': 'facturas:pago de facturas:formulario de pago',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'pago de facturas',
      'page_subcategory_level_2': 'formulario de pago',
      'page_subcategory_level_3': '',
      'page_screen': 'formulario de pago',
      'page_typology': 'principal'
    },
    'otherPaymentMethod': {
      'name': 'otherPaymentMethod',
      'route': '/billing/otherPaymentMethod',
      'deeplink': true,
      'page_name': 'facturas:pago de facturas:otros metodos de pago',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'pago de facturas',
      'page_subcategory_level_2': 'otros metodos de pago',
      'page_subcategory_level_3': '',
      'page_screen': 'estado de mis facturas',
      'page_typology': 'principal'
    },
    'fundedDeviceDetails': {
      'name': 'fundedDeviceDetails',
      'route': '/billing/fundedDeviceDetails',
      'deeplink': false,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': ''
    }
  },
  'verifyEmail': {
    'name': 'verifyEmail',
    'route': '/verifyEmail',
    'verifyPdf': {
      'name': 'verifyPdf',
      'route': '/verifyEmail/verifyPdf',
      'deeplink': true
    }
  },
  'ServiceSettings': {
    'name': 'ServiceSettings',
    'route': '/ServiceSettings',
    'deeplink': true,
    'permissionList': ['Authorized'],
    'navigators': {
      'name': 'navigators',
      'route': '/ServiceSettings/navigators',
      'deeplink': true,
      'page_name': 'ajustes:pin puk',
      'page_section': 'ajustes',
      'page_subcategory_level_1': 'pin puk',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'pin puk',
      'page_typology': 'principal'
    },
    'pinpuk': {
      'name': 'pinpuk',
      'route': '/ServiceSettings/pinpuk',
      'deeplink': true,
      'page_name': 'ajustes:pin puk:informacion',
      'page_section': 'ajustes',
      'page_subcategory_level_1': 'pin puk',
      'page_subcategory_level_2': 'informacion',
      'page_subcategory_level_3': '',
      'page_screen': 'informacion',
      'page_typology': 'principal'
    },
    'PINPUKManagement': {
      'name': 'PINPUKManagement',
      'route': '/ServiceSettings/PINPUKManagement',
      'deeplink': true
    },
    'unlock': {
      'name': 'unlock',
      'route': '/ServiceSettings/unlock',
      'deeplink': true
    },
    'UnlockSIM': {
      'name': 'UnlockSIM',
      'route': '/ServiceSettings/UnlockSIM',
      'deeplink': true
    },
    'purchasesAndSubscriptions': {
      'name': 'purchasesAndSubscriptions',
      'route': '/ServiceSettings/purchasesAndSubscriptions',
      'deeplink': true,
      'page_name': 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:compras',
      'page_section': 'ajustes de linea',
      'page_subcategory_level_1': 'pagos a vodafone y terceros',
      'page_subcategory_level_2': 'compras y suscripciones',
      'page_subcategory_level_3': 'compras',
      'page_screen': 'compras ',
      'page_typology': 'principal'
    },
    'thirdPartyLanding': {
      'name': 'thirdPartyLanding',
      'route': '/ServiceSettings/thirdPartyLanding',
      'deeplink': true,
      'page_name': 'ajustes de linea:pagos a vodafone y terceros'
    },
    'RestrictionsThirdPartyLandingPage': {
      'name': 'RestrictionsThirdPartyLandingPage',
      'route': '/ServiceSettings/RestrictionsThirdPartyLandingPage',
      'deeplink': true
    },
    'thirdPartyChild': {
      'name': 'thirdPartyChild',
      'route': '/ServiceSettings/thirdPartyChild',
      'deeplink': true
    },
    'PagosAVodafone': {
      'name': 'RestrictionsThirdPartyLandingPage/Categories/PagosAVodafone',
      'route': '/ServiceSettings/RestrictionsThirdPartyLandingPage/Categories/PagosAVodafone',
      'deeplink': true
    },
    'ThirdPartyDetails': {
      'name': 'thirdPartyDetails',
      'route': '/ServiceSettings/thirdPartyDetails',
      'deeplink': false
    },
    'ThirdPartyRestrictionsDetails': {
      'name': 'RestrictionsThirdParty/Details',
      'route': '/ServiceSettings/RestrictionsThirdParty/Details',
      'deeplink': true
    },
    'secureNet': {
      'name': 'Securenet',
      'route': '/ServiceSettings/Securenet',
      'deeplink': true
    }

  },
  'serviceSettings': {
    'name': 'serviceSettings',
    'route': '/serviceSettings',
    'deeplink': true,
    'permissionList': ['Authorized'],
    'navigators': {
      'name': 'navigators',
      'route': '/serviceSettings/navigators',
      'deeplink': true,
      'page_name': 'ajustes:pin puk',
      'page_section': 'ajustes',
      'page_subcategory_level_1': 'pin puk',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'pin puk',
      'page_typology': 'principal'
    },
    'pinpuk': {
      'name': 'pinpuk',
      'route': '/serviceSettings/pinpuk',
      'deeplink': true,
      'page_name': 'ajustes:pin puk:informacion',
      'page_section': 'ajustes',
      'page_subcategory_level_1': 'pin puk',
      'page_subcategory_level_2': 'informacion',
      'page_subcategory_level_3': '',
      'page_screen': 'informacion',
      'page_typology': 'principal'
    },
    'PINPUKManagement': {
      'name': 'PINPUKManagement',
      'route': '/serviceSettings/PINPUKManagement',
      'deeplink': true
    },
    'unlock': {
      'name': 'unlock',
      'route': '/serviceSettings/unlock',
      'deeplink': true
    },
    'UnlockSIM': {
      'name': 'UnlockSIM',
      'route': '/serviceSettings/UnlockSIM',
      'deeplink': true
    },
    'purchasesAndSubscriptions': {
      'name': 'purchasesAndSubscriptions',
      'route': '/serviceSettings/purchasesAndSubscriptions',
      'deeplink': true,
      'page_name': 'ajustes de linea:pagos a vodafone y terceros:compras y suscripciones:compras',
      'page_section': 'ajustes de linea',
      'page_subcategory_level_1': 'pagos a vodafone y terceros',
      'page_subcategory_level_2': 'compras y suscripciones',
      'page_subcategory_level_3': 'compras',
      'page_screen': 'compras',
      'page_typology': 'principal'
    },
    'thirdPartyLanding': {
      'name': 'thirdPartyLanding',
      'route': '/serviceSettings/thirdPartyLanding',
      'deeplink': true,
      'page_name': 'ajustes de linea:pagos a vodafone y terceros'
    },
    'RestrictionsThirdPartyLandingPage': {
      'name': 'RestrictionsThirdPartyLandingPage',
      'route': '/serviceSettings/RestrictionsThirdPartyLandingPage',
      'deeplink': true
    },
    'thirdPartyChild': {
      'name': 'thirdPartyChild',
      'route': '/serviceSettings/thirdPartyChild',
      'deeplink': true
    },
    'PagosAVodafone': {
      'name': 'RestrictionsThirdPartyLandingPage/Categories/PagosAVodafone',
      'route': '/serviceSettings/RestrictionsThirdPartyLandingPage/Categories/PagosAVodafone',
      'deeplink': true
    },
    'ThirdPartyDetails': {
      'name': 'thirdPartyDetails',
      'route': '/serviceSettings/thirdPartyDetails',
      'deeplink': false
    },
    'secureNet': {
      'name': 'Securenet',
      'route': '/ServiceSettings/Securenet',
      'deeplink': true
    }

  },
  'retry': {
    'name': 'retry',
    'route': '/retry',
    'deeplink': false
  },
  'impersonate': {
    'name': 'impersonate',
    'route': '/impersonate'
  },
  'contingency': {
    'name': 'contingency',
    'route': '/contingency',
    'deeplink': false
  },
  'billIsDown': {
    'name': 'billIsDown',
    'route': '/billIsDown',
    'deeplink': false
  },
  'licenses': {
    'name': 'licenses',
    'route': '/licenses',
    'deeplink': true,
    'page_name': 'aviso legal'
  },
  'LegalText': {
    'name': 'LegalText/LandingPage',
    'route': '/LegalText/LandingPage',
    'deeplink': true,
    'page_name': 'aviso legal'
  },
  'LineServices': {
    'name': 'LineServices',
    'route': '/LineServices',
    'deeplink': true,
    'vbyVodafoneChild': {
      'name': 'VbyVodafone',
      'route': '/LineServices/VbyVodafone',
      'deeplink': true
    },
    'mobileContent': {
      'name': 'MobileConnect',
      'route': '/LineServices/MobileConnect',
      'deeplink': true

    }
    ,
    'Restrictions': {
      'MainPage': {
        'name': 'Restrictions/MainPage',
        'route': '/LineServices/Restrictions/MainPage',
        'deeplink': true,
        'page_name': 'ajustes de linea:restricciones de llamada',
      }
    }
    ,
    'RestrictionsDetails': {
      'name': 'Restrictions/Details',
      'route': '/LineServices/Restrictions/Details',
      'deeplink': true
    },
    'CallOptions': {
      'MainPage': {
        'name': 'CallOptions/MainPage',
        'route': '/LineServices/CallOptions/MainPage',
        'deeplink': true,
        'page_name': 'ajustes de linea:opciones de llamada',
        'page_section': 'Ajustes de Linea',
        'page_subcategory_level_1': 'lista de Ajustes de Linea',
        'page_subcategory_level_2': '',
        'page_subcategory_level_3': '',
        'page_screen': 'lista de Ajustes de Linea',
        'page_typology': 'principal',
      }
    }
    , 'SMSAlert': {
      'name': 'CallOptions/SMSAlert',
      'route': '/LineServices/CallOptions/SMSAlert',
      'deeplink': true,
      'page_name': 'Ajustes de Linea:aviso por sms de llamadas perdidas',
      'page_section': 'Ajustes de Linea',
      'page_subcategory_level_1': 'aviso por sms de llamadas perdidas',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'aviso por sms de llamadas perdidas',
      'page_typology': 'principal'
    },
    'Waiting': {
      'name': 'CallOptions/Waiting',
      'route': '/LineServices/CallOptions/Waiting',
      'deeplink': true,
      'page_name': 'Ajustes de Linea:llamadas en espera',
      'page_section': 'Ajustes de Linea',
      'page_subcategory_level_1': 'llamadas en espera',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'llamadas en espera',
      'page_typology': 'principal'
    },
    'HidemyNumber': {
      'name': 'CallOptions/HidemyNumber',
      'route': '/LineServices/CallOptions/HidemyNumber',
      'deeplink': true,
      'page_name': 'Ajustes de Linea:ocultar tu número',
      'page_section': 'Ajustes de Linea',
      'page_subcategory_level_1': 'ocultar tu número',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'ocultar tu número',
      'page_typology': 'principal'
    },
    'DivertCalls': {
      'name': 'CallOptions/DivertCalls',
      'route': '/LineServices/CallOptions/DivertCalls',
      'deeplink': true
    },
    'Autoredial': {
      'name': 'CallOptions/Autoredial',
      'route': '/LineServices/CallOptions/Autoredial',
      'deeplink': true,
      'page_name': 'Ajustes de Linea:aviso para rellamadas',
      'page_section': 'Ajustes de Linea',
      'page_subcategory_level_1': 'aviso para rellamadas',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'aviso para rellamadas',
      'page_typology': 'principal'
    },
    'DictaSMS': {
      'name': 'CallOptions/DictaSMS',
      'route': '/LineServices/CallOptions/DictaSMS',
      'deeplink': true,
      'page_name': 'Ajustes de Linea:dicta sms',
      'page_section': 'Ajustes de Linea',
      'page_subcategory_level_1': 'dicta sms',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'dicta sms',
      'page_typology': 'principal'
    }
    ,
    'Voicemail': {
      'name': 'CallOptions/Voicemail',
      'route': '/LineServices/CallOptions/Voicemail',
      'deeplink': true
    }
  },
  'FaultManagement': {
    'name': 'FaultManagement',
    'route': '/FaultManagement',
    'deeplink': true,
    'OpenNewTicket': {
      'name': 'OpenNewTicket',
      'route': '/FaultManagement/OpenNewTicket',
      'deeplink': false,
    },
    'LandingPage': {
      'name': 'LandingPage',
      'route': '/FaultManagement/LandingPage',
      'deeplink': true,
      'page_name': 'averias',
      'page_section': 'averias',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': 'averias',
      'page_typology': 'principal'
    }
  },
  'Recommender': {
    'name': 'recommender',
    'route': '/recommender',
    'permissionList': ['Authorized'],
    'deeplink': true,
    'Comparator': {
      'name': 'comparatorMigraC',
      'route': '/recommender/comparatorMigraC',
      'deeplink': true
    },
    'CTC': {
      'name': 'CTC',
      'route': '/recommender/CTC',
      'deeplink': true
    },
    'OnePlusComparator': {
      'name': 'onePlusComparator',
      'route': '/recommender/onePlusComparator',
      'deeplink': true,
      'NewLine': {
        'name': 'onePlusComparator/newLine',
        'route': '/recommender/onePlusComparator/newLine',
        'deeplink': false
      },
      'Activation': {
        'name': 'onePlusComparator/activation',
        'route': '/recommender/onePlusComparator/activation',
        'deeplink': false
      }
    }
  },
  'FedError': {
      'name': 'FedError',
      'route': '/FedError',
      'deeplink': true
  },
  'OnlineTvDetailInfo': {
    'name': 'OnlineTvDetailInfo',
    'route': '/OnlineTvDetailInfo',
    'deeplink': false
  },
  'BillingVf10': {
    'name': 'BillingVf10',
    'route': '/BillingVf10',
    'permissionList': ['Authorized'],
    'page_name': 'BillingVf10:BillingVf10',
    'page_section': 'BillingVf10',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'BillingVf10',
    'page_typology': 'principal',
    'MainScreen': {
        'name': 'MainScreen',
        'route': '/BillingVf10/MainScreen',
        'deeplink': true,
        'page_name': 'facturas:mis facturas:resumen facturas',
        'page_section': '',
        'page_subcategory_level_1': '',
        'page_subcategory_level_2': '',
        'page_subcategory_level_3': '',
        'page_screen': '',
        'page_typology': 'principal'
    },
    'BillDetails': {
      'name': 'BillDetails',
      'route': '/BillingVf10/BillDetails',
      'deeplink': true,
      'page_name': 'facturas:mis facturas:resumen facturas:detalle de factura',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis facturas',
      'page_subcategory_level_2': 'resumen facturas',
      'page_subcategory_level_3': 'detalle de facturas',
      'page_screen': 'detalle de facturas',
      'page_typology': 'principal'
  },
    'reviewBill': {
      'name': 'reviewBill',
      'route': '/BillingVf10/reviewBill',
      'deeplink': false,
      'page_name': 'facturas:mis:resumen facts:detalle de fac',
      'page_section': 'facturas',
      'page_subcategory_level_1': 'mis',
      'page_subcategory_level_2': 'resumen facts',
      'page_subcategory_level_3': 'detalle de fac',
      'page_screen': 'detalle de fac',
      'page_typology': 'principal'
  },
    'billTickets': {
      'name': 'billReviewTickets',
      'route': '/BillingVf10/billReviewTickets',
      'deeplink': false
    }
  },
  'TiendaV10': {
    'name': 'tienda',
    'route': '/tienda',
    'deeplink': true
  },
  'Marketplace': {
    'name': 'marketplace',
    'route': '/marketplace',
    'deeplink': true
  },
  TiendaV10PegaIntermediate: {
    name: 'ofertas',
    route: '/tienda/ofertas',
    deeplink: true,
  },
  'tienda': {
    'name': 'tienda',
    'route': '/tienda',
    'deeplink': true,
    'page_name': 'tienda:home',
    'page_section': 'tienda',
    'page_subcategory_level_1': 'home',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'home',
    'page_typology': 'overlay'
  },
  'Checkout': {
    'name': 'commercialcheckout',
    'route': '/commercialcheckout',
    'permissionList': ['Authorized'],
    'page_name': 'commercialcheckout:commercialcheckout',
    'page_section': 'commercialcheckout',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'commercialcheckout',
    'page_typology': 'principal',
    'deeplink': true,
    'landing': {
      'name': 'mainscreen',
      'route': '/commercialcheckout/mainscreen',
      'deeplink': true,
    },
    'MainScreen': {
      'name': 'mainscreen',
      'route': '/commercialcheckout/mainscreen',
      'deeplink': true
    },
    'summary': {
      'name': 'summary',
      'route': '/commercialcheckout/summary',
      'deeplink': true
    },
    'Delivery': {
      'name': 'delivery',
      'route': '/commercialcheckout/delivery',
      'deeplink': true
    },
    'payments': {
      'name': 'payments',
      'route': '/commercialcheckout/payments',
      'deeplink': true
    },
    'installation': {
      'name': 'installation',
      'route': '/commercialcheckout/installation',
      'deeplink': true
    }
  },
  'Nexus': {
    'name': 'commercialnexus',
    'route': '/commercialnexus',
    'permissionList': ['Authorized'],
    'page_name': 'commercialnexus:commercialnexus',
    'page_section': 'commercialnexus',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'commercialnexus',
    'page_typology': 'principal',
    'deeplink': true,
    loadChildren: () => import('../app/nexus/nexus.module').then(m => m.NexusModule),
      'landing': {
      'name': 'mainscreen',
      'route': '/commercialnexus/mainscreen',
      'deeplink': true,
    },
    'MainScreen': {
      'name': 'mainscreen',
      'route': '/commercialnexus/mainscreen',
      'deeplink': true
    }
  },
  'DiscountRenewal': {
      'name': 'DiscountRenewal',
      'route': '/DiscountRenewal',
      'permissionList': ['Authorized'],
      'deeplink': true,
      'MainScreen': {
        'name': 'MainScreen',
        'route': '/DiscountRenewal/MainScreen',
        'deeplink': true,
      }
  },
  'buySim': {
    'name': 'buySim',
    'route': '',
    'deeplink': true,
    'personelInfo': {
      'name': 'personelInfo',
      'route': '/buySim/personelInfo',
      'deeplink': true
    },
    'deliveryAddress': {
      'name': 'deliveryAddress',
      'route': '/buySim/deliveryAddress',
      'deeplink': true
    },
    'payment': {
      'name': 'payment',
      'route': '/buySim/payment',
      'deeplink': true
    },
    'deliveryAddressVerticalInfo': {
      'name': 'deliveryAddressVerticalInfo',
      'route': '/buySim/deliveryAddressVerticalInfo',
      'deeplink': true
    },
    'orderConfirmation': {
      'name': 'orderConfirmation',
      'route': '/buySim/orderConfirmation',
      'deeplink': true
    },
    'generalError': {
      'name': 'generalError',
      'route': '/buySim/generalError',
      'deeplink': true
    },
    'portability': {
      'name': 'portability',
      'route': '/buySim/portability',
      'deeplink': true
    },
    'Delivery': {
      'name': 'delivery',
      'route': '/commercialcheckout/delivery',
      'deeplink': true
    }
  },
  'StoreFinder': {
    'name': 'StoreFinder',
    'route': '/StoreFinder'
  },
  'MobileToPackPurchase': {

    'name': 'MobileToPackPurchase',
    'route': '/MobileToPackPurchase',
    'permissionList': ['Authorized'],
    'page_name': 'NewLines:NewLines',
    'page_section': 'NewLines',
    'page_subcategory_level_1': '',
    'page_subcategory_level_2': '',
    'page_subcategory_level_3': '',
    'page_screen': 'NewLines',
    'page_typology': 'principal',
    'deeplink': true,
    'landing': {
      'name': 'MainScreen',
      'route': '/MobileToPackPurchase/MainScreen',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'MainScreen': {
      'name': 'MainScreen',
      'route': '/MobileToPackPurchase/MainScreen',
      'deeplink': true,
      'page_name': '',
      'page_section': 'líneas adicionales',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'OfferHandler': {
      'name': 'Offers',
      'route': '/MobileToPackPurchase/Offers',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'checkoutPorta': {
      'name': 'Checkout',
      'route': '/MobileToPackPurchase/Checkout',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'shippingCheckout': {
      'name': 'Shipping',
      'route': '/MobileToPackPurchase/Shipping',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'summaryCheckout': {
      'name': 'Summary',
      'route': '/MobileToPackPurchase/Summary',
      'deeplink': true,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    },
    'summaryCheckoutM2P': {
      'name': 'SummaryM2P',
      'route': '/MobileToPackPurchase/SummaryM2P',
      'deeplink': false,
      'page_name': '',
      'page_section': '',
      'page_subcategory_level_1': '',
      'page_subcategory_level_2': '',
      'page_subcategory_level_3': '',
      'page_screen': '',
      'page_typology': 'principal'
    }
  },
  'SecondResidences': {
    'name': 'SecondResidences',
    'route': '/SecondResidences',
    'selection': {
      'name': 'MainScreen',
      'route': '/SecondResidences/MainScreen',
      'deeplink': true
    },
    'detail': {
      'name': 'Detail',
      'route': '/SecondResidences/Detail'
    }
  },
  'OnePlusUpgrade': {
    'name': 'newlineonepluspurchase',
    'route': '/newlineonepluspurchase',
    'deeplink': true,
    'landing': {
      'name': 'mainscreen',
      'route': '/newlineonepluspurchase/mainscreen',
      'deeplink': true
    },
    'typeSelector': {
      'name': 'checkout',
      'route': '/newlineonepluspurchase/checkout'
    }
  },
  'MFError': {
    'name': 'MFError',
    'route': '/MFError'
  },
  'DebtOtError': {
    'name': 'commercial/message/mainscreen',
    'route': '/commercial/message/mainscreen'
  },
  'Bundleupsell': {
    'name': 'bundleupsell',
    'route': '/bundleupsell',
    'permissionList': ['Authorized'],
    'deeplink': true,
    'Mainscreen': {
      'name': 'mainscreen',
      'route': '/bundleupsell/mainscreen',
      'deeplink': true
    }
  },
  'MobileToPack': {
    'name': 'bundletx',
    'route': '/bundletx',
    'permissionList': ['Authorized'],
    'deeplink': true,
    'Mainscreen': {
      'name': 'mainscreen',
      'route': '/bundletx/mainscreen',
      'deeplink': true
    }
  },
  'SmartPay': {
    'name': 'smartpay',
    'route': '/smartpay',
    'deeplink': false,
    'smartPayManagement': {
      'name': 'smartPayManagement',
      'route': '/smartPayManagement',
      'deeplink': false
    }
  },
  'smartpayLanding': {
    'name': 'SmartpayLanding',
    'route': '/SmartpayLanding',
    'deeplink': false
  },
  'newSmartpayLanding': {
    'name': 'newSmartpayLanding',
    'route': '/newSmartpayLanding',
    'deeplink': false
  },
  'smartPayTopUpHistory': {
    'name': 'smartPayTopUpHistory',
    'route': '/smartPayTopUpHistory',
    'deeplink': false
  },
  'MiddlePage': {
      'name': 'middlePage',
      'route': '/middlePage',
      'page_name': 'middle page'
  },
  'xrContents': {
    'name': 'xrcontents',
    'route': '/xrcontents',
    'deeplink': true
  },
  'adara': {
    'name': 'adara',
    'route': '/adara',
    'deeplink': false
  },
  'smartphoneRaffle': {
    'name': 'smartphoneRaffle',
    'route': '/smartphoneRaffle'
  },
    'tradeUpTarifa': {
    'name': 'tradeUp/infoOverlay',
    'route': '/tradeUp/infoOverlay',
    'deeplink': true
  },
  'adaralogin': {
    'name': 'adaralogin',
    'path': '/adaralogin',
    'deeplink': true
  },
  'energy': {
    'name': 'energy',
    'route': '/energy',
    'deeplink': true
  },
  'FibreToPack': {
    'name': 'fibreToPack',
    'route': '/fibreToPack',
    'permissionList': ['Authorized'],
    'deeplink': true,
  },
  'disfrutaOneProfesional': {
    'name': 'disfrutaOneProfesional',
    'route': '/disfrutaOneProfesional',
    'deeplink': true,
  },
  'pegaPlatinumOffer': {
    'name': 'prepaidPlatinumOffer/landing',
    'route': '/prepaidPlatinumOffer/landing',
    'deeplink': true
  }
}

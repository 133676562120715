import { AppService } from './../../../app.service';
import { SpModalFullComponent } from './../../components/sp-modal-full/sp-modal-full.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UNAVAILABILITY_OVERLAY } from '../../constants/defines';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';

@Component({
  selector: 'sp-unavilability-modal',
  templateUrl: './unavilability-modal.component.html',
  styleUrls: ['./unavilability-modal.component.scss']
})
export class UnavilabilityModalComponent implements OnInit {
  @ViewChild('fullModal', { static: true }) fullModal: SpModalFullComponent;

  public imgSrc: string | undefined;

  constructor(private appService: AppService, private tagging: TaggingHelperService) {}

  ngOnInit() {
    this.tagging.taggingUnavailabilityOverlay(UNAVAILABILITY_OVERLAY.TOTAL);
    this.imgSrc = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png');
  }

  ngAfterViewInit() {
    this.fullModal.show();
  }

  close() {
    this.fullModal.hide();
    this.appService.errorAvilabilityApp = false
  }
}

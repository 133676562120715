import { Injectable } from '@angular/core';

import { SharedServicesPegaParserService } from '../feature/parser';
import { SharedServicesPegaReplacerService } from '../feature/replacer';

import { SharedServicesPegaParseEntrypoint } from '../entity/shared-services-pega.entity';
import { SharedServicesPegaParserZippedHashMap } from '../feature/parser/entity/shared-services-parser.entity';

@Injectable({ providedIn: 'root' })
export class SharedServicesPegaService {
  isPegaPlatinumOffer: boolean;
  constructor(
    private sharedServicesPegaParserService: SharedServicesPegaParserService,
    private sharedServicesPegaReplacerService: SharedServicesPegaReplacerService
  ) {}

  public parseEntrypoint(options: SharedServicesPegaParseEntrypoint): string {
    const source: Record<string, string> | SharedServicesPegaParserZippedHashMap =
      this.sharedServicesPegaParserService.parse(options.nameVar, options.valueVar, {
        transformToObject: false,
        isPegaPlatinum: this.isPegaPlatinumOffer
      });

    return this.sharedServicesPegaReplacerService.replace({
      source,
      tokens: '{+}',
      template: options.template,
    });
  }
}

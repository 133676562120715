import { Guid } from 'guid-typescript';
import {
    PaymentPatchRequestBody, ThirdPartyIframePayload, ThirdPartyIframeRequestBodyHeader,
    ThirdPartyIframeUrlRequest, ThirdPartyPatchRequestBodyHeader
} from '../../../models/payment';
import { ANONYMOUS, platformWeb } from '../../../shared/constants/defines';
import { IframeStatus } from '../../../shared/enums/iframe-status.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentDxlparamInterface } from './payment-dxlparam-interface';

export class PaymentDxlparamPrePaidPostPaid implements PaymentDxlparamInterface {
    constructor(public paymentNavigationService: PaymentNavigationService) { }
    /**
     * @param appUUID the appUUID uinque id for the user
     * function to construct the request body needed by the dxl
     * by the post request (get net plus url)
     */
    getNetPlusUrlRequestBody(appUUID: Guid): ThirdPartyIframeRequestBodyHeader {
        const thirdPartyIframeUrlInfo: ThirdPartyIframeRequestBodyHeader = new ThirdPartyIframeRequestBodyHeader();
        const requestBody: ThirdPartyIframeUrlRequest = new ThirdPartyIframeUrlRequest();
        requestBody.payer.documentId = this.paymentNavigationService.paymentJourneyParams.payer.documentId;
        requestBody.payer.siteId = this.paymentNavigationService.paymentJourneyParams.payer.siteId;
        requestBody.payer.serviceId = this.paymentNavigationService.paymentJourneyParams.payer.serviceId;
        requestBody.buyer.documentId = this.paymentNavigationService.paymentJourneyParams.buyer.documentId;
        requestBody.buyer.siteId = this.paymentNavigationService.paymentJourneyParams.buyer.siteId;
        requestBody.buyer.serviceId = this.paymentNavigationService.paymentJourneyParams.buyer.serviceId;
        requestBody.amount = this.paymentNavigationService.convertAmountToCent(this.paymentNavigationService.paymentJourneyParams.amount)
        requestBody.language = this.paymentNavigationService.paymentJourneyParams.language;
        requestBody.journeyId = this.paymentNavigationService.paymentJourneyParams.journeyId;
        requestBody.platform = platformWeb;
        requestBody.appUUID = appUUID;
        requestBody.systemID = this.paymentNavigationService.paymentJourneyParams.systemID;
        requestBody.systemReferenceId = this.paymentNavigationService.paymentJourneyParams.systemReferenceId;
        requestBody.promoCode = this.paymentNavigationService.paymentJourneyParams.promoCode ?? undefined;
        // this if we have saved credit card
        if (this.paymentNavigationService.paymentJourneyParams.token) {
            requestBody.token = this.paymentNavigationService.paymentJourneyParams.token
            requestBody.cardMask = this.paymentNavigationService.paymentJourneyParams.cardMask
            requestBody.expiryDate = this.paymentNavigationService.paymentJourneyParams.expiryDate
        }
        thirdPartyIframeUrlInfo.requestBody = requestBody;
        thirdPartyIframeUrlInfo.isAnonymous = this.paymentNavigationService.paymentJourneyParams.isAnonymous;
        thirdPartyIframeUrlInfo.urlSiteId = thirdPartyIframeUrlInfo.isAnonymous && !thirdPartyIframeUrlInfo.requestBody.payer.siteId
        ? ANONYMOUS : thirdPartyIframeUrlInfo.requestBody.payer.siteId
        thirdPartyIframeUrlInfo.urlServiceId = thirdPartyIframeUrlInfo.requestBody.payer.serviceId
        ? thirdPartyIframeUrlInfo.requestBody.payer.serviceId : '0';
        return thirdPartyIframeUrlInfo;
    }
    /**
     * @param payload object contains payload needed to fill the patch request body
     * method to construct the patch request (update the dxl with the netplus status)
     */
    getPatchNetplusStatusRequestBody(payload: ThirdPartyIframePayload): ThirdPartyPatchRequestBodyHeader {

        const thirdPartyPatchRequestBodyHeader: ThirdPartyPatchRequestBodyHeader = new ThirdPartyPatchRequestBodyHeader()
        let requestBody: PaymentPatchRequestBody = new PaymentPatchRequestBody();
        // fill payer data
        requestBody.payer.documentId = this.paymentNavigationService.paymentJourneyParams.payer.documentId;
        requestBody.payer.siteId = this.paymentNavigationService.paymentJourneyParams.payer.siteId;
        requestBody.payer.serviceId = this.paymentNavigationService.paymentJourneyParams.payer.serviceId;
        // fill buyer data
        requestBody.buyer.documentId = this.paymentNavigationService.paymentJourneyParams.buyer.documentId;
        requestBody.buyer.siteId = this.paymentNavigationService.paymentJourneyParams.buyer.siteId;
        requestBody.buyer.serviceId = this.paymentNavigationService.paymentJourneyParams.buyer.serviceId;

        requestBody.journeyId = this.paymentNavigationService.paymentJourneyParams.journeyId;
        requestBody.platform = platformWeb;
        requestBody.transactionId = payload.transactionId;
        requestBody.status = payload.status;
        requestBody.systemReferenceId = this.paymentNavigationService.paymentJourneyParams.systemReferenceId;
        if (payload.netPlusParams && payload.netPlusParams.cardMask) {
            requestBody.cardMask = payload.netPlusParams.cardMask;
            requestBody.cardNetplus = payload.netPlusParams.cardToken;
        }
        if (payload.status === IframeStatus.failed) {
            requestBody.error = payload.error;
        }
        if (payload.status === IframeStatus.complete || payload.status === IframeStatus.failed) {
            requestBody = { ...requestBody, ...payload.iframUrlParam }
        }
        thirdPartyPatchRequestBodyHeader.requestBody = requestBody;
        thirdPartyPatchRequestBodyHeader.isAnonymous = this.paymentNavigationService.paymentJourneyParams.isAnonymous;
        thirdPartyPatchRequestBodyHeader.urlSiteId =
        thirdPartyPatchRequestBodyHeader.isAnonymous && !thirdPartyPatchRequestBodyHeader.requestBody.payer.siteId
            ? ANONYMOUS : thirdPartyPatchRequestBodyHeader.requestBody.payer.siteId
        thirdPartyPatchRequestBodyHeader.urlServiceId = thirdPartyPatchRequestBodyHeader.requestBody.payer.serviceId
        ? thirdPartyPatchRequestBodyHeader.requestBody.payer.serviceId : '0';
        return thirdPartyPatchRequestBodyHeader;
    }
    mapPaymentOutputParams(res: any): void {
        // no implmentation here
        return;
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { PaymentCardModel } from '../../models/payment-card.model';
import { MasterCardFirstDigit, TopupPrepaidStages, VisaFirstDigit } from '../../shared/constants/defines';
import { PaymentCardsService } from '../../shared/services/payment-cards.service';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { StorageService } from '../../core/services/storage.service';
import { TopUpService } from '../../top-up/top-up.service';

@Component({
  selector: 'sp-credit-card-chevron',
  templateUrl: './credit-card-chevron.component.html',
  styleUrls: ['./credit-card-chevron.component.scss']
})
export class CreditCardChevronComponent implements OnInit {

  /** This input for adding margin top because we can't add margin top to custom this component's directive in its parent */
  @Input() marginTop: number;
  /** selected card from wallet saved cards */
  selectedCardNumber: string;
  /** selected type of card from wallet saved cards (master, visa, ...) */
  selectedCardImage: string;
  /** This flag for show/hide credit card chevron */
  showChevron: boolean = false;

  constructor(
    private translate: TranslateService,
    public appService: AppService,
    public topupService: TopUpService,
    private paymentCardsService: PaymentCardsService,
    private storageService: StorageService,
    public trayService: SpMva10OverlayService
  ) { }

  ngOnInit(): void {
    this.checkForSavedCreditCards();
  }

  /** Open saved credit cards list */
  navigateToWalletScreen(): void {
    this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.TopUpCardWalletScreen });
    this.trayService.showForwardTransition = true;
    this.trayService.showBackwardTransition = false;
  }

  /** Get saved credit cards list */
  checkForSavedCreditCards(): void {
    if ((!this.topupService.returnFromWalletScreen && !this.topupService.selectedCreditCard) || this.topupService.selectedCardDeleted) {
      this.getSavedCreditCards();
    } else {
      /** Show chevron when came back from wallet screen even if no selected card */
      this.showChevron = true;
    }
    if (!this.topupService.selectedCreditCard) {
      this.translate.get('payment.buttonList').subscribe(data => {
        this.selectedCardNumber = data.anotherCreditCardWalletButton.text;
      })
      this.selectedCardImage = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/walletOther.png';
    }
    else {
      this.selectedCardNumber = this.topupService.selectedCreditCard.cardMask;
      this.setCardSelectionVisaImage();
    }
    this.topupService.showCardSelectionSection = true;
  }
  getSavedCreditCards(): void {
    this.showChevron = false;
    this.paymentCardsService.getPaymentCardsByDocumentId(this.storageService.userProfile.document.id).subscribe(() => {
      this.topupService.selectedCreditCard = this.paymentCardsService.cardsList.length > 0 ?
        this.paymentCardsService.cardsList.find(card => card.principle) : null;
      if (this.topupService.selectedCreditCard) {
        this.selectedCardNumber = this.topupService.selectedCreditCard.cardMask;
        this.setCardSelectionVisaImage();
        this.showChevron = true;
      } else {
        this.showChevron = false;
      }
      this.topupService.showCardSelectionSection = this.topupService.selectedCreditCard && !this.topupService.isAnonymousProcess;
    }, error => {
      this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
      this.topupService.showCardSelectionSection = false;
      this.topupService.selectedCreditCard = null;
    });
  }
  /** Set card type image */
  setCardSelectionVisaImage(): void {
    if (this.topupService.selectedCreditCard.cardMaskData && this.topupService.selectedCreditCard.cardMaskData[0] === VisaFirstDigit) {
      this.selectedCardImage = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/walletVisa.png';
    } else if (this.topupService.selectedCreditCard.cardMaskData &&
      this.topupService.selectedCreditCard.cardMaskData[0] === MasterCardFirstDigit) {
      this.selectedCardImage = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/walletMasterCard.png';
    } else {
      this.selectedCardImage = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/walletOther.png';
    }
  }

}

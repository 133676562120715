
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BuySimDataService } from './buy-sim-data.service';
import { ShoppingCartModel } from './../../models/shopping-cart.model';
import * as JsonQuery from 'jsonpath/jsonpath';
import {
    JSON_PATHS, CommercialJourneys, Validation, BUY_SIM_JOURNEY, IdentificationType,
    legalTermsPageName, submitOrderReqBody, macPlatform, ontouchstart, saveOfferReqBody, NIEOrNIFLength
} from '../../shared/constants/defines';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { PersonalModel } from '../../models/buy-sim-personal-data.model';
import { LegalTermsItemModel } from '../../models/buy-sim-legal-terms-item.model';
import { LegalTermsItemPostModel } from '../../models/buy-sim-legal-terms-item-post.model';
import { IdValidationModel } from '../../models/buy-sim-id-validation.model';
import { config } from '../../../config/pages-config';
import { Router } from '@angular/router';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { OperatorModel } from '../../models/buy-sim-operator-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { BuySimPromotion } from '../../models/buy-sim-promotion-model';
import { BuySimSteps } from '../../shared/enums/buy-sim-steps.enum';
import { throwError } from 'rxjs';
import { BuySimErrorModel } from '../../models/buy-sim-error-model';
import { HttpErrorResponse } from '@angular/common/http';
import { PortabilityOwnerModel } from '../../models/buy-sim-portability-owner-model';
import { SaveOfferResponse, Service } from '../../models/buy-sim-saveOffer.model';

@Injectable({
    providedIn: 'root'
  })
export class BuySimService {
    /** shows module full loader */
    showFullLoader = false;

    /** buy sim steps data */
    steps = [];

    /** tariff to buy
     * sets from query param
     */
    tariffName = '';

    /** mark current step in steps component */
    currentStep: BuySimSteps = BuySimSteps.stepZeroPersonalInfo;

    /** prevent router outlet div from scorlling */
    preventScroll = false;

    /** shopping cart obj contains tariff name , tariff price , order id */
    shoppingCart: ShoppingCartModel;

    /** legal terms for personal info page from ntol system */
    legalTerms = new Array<LegalTermsItemModel>();

    /** list of nationalities from ntol */
    nationalityDataList = [];
    /**response mapping object for legal terms */
    responseDetails: string;
    /**this list handled to send in saveLigalTerms request in paymentTicket case; */
    AcceptListInResponse: LegalTermsItemModel[];
    AcceptListInRequest: LegalTermsItemPostModel[] = new Array <LegalTermsItemPostModel>();
    /**this is the list in save legal terms request */
    legalTermsPostList:  LegalTermsItemPostModel[];
    provinceDataList = [];
    /**Flag for order confirmation page */
    hideStepAndOrderSectionsFlag = false;
    /**this parameter for saveLegalTerms in payment */
    requestOrderId: string;
    /**email in personel info form */
    personelInfoEmail: string;

    /**detects device is desktop to show ticke summary  */
    isDesktopView = false;
    /**detects device is mobile to handle ticket summary */
    isMobileView = false;
    /** shows and hide ticket summary */
    hideTicketDetails = false;
    /**tariff name for tagging */
    tariffNameForTagging: string;
    /**check if we open payment screen in tray */
    isPaymentFullScreen = false;
    /** html data from create shopping api in payment section */
    HTMLDataFromAPI: any;
    /**id ntol request which will display as order id */
    idNtolRequest: string;
    /**List of operators */
    operatorsDataList: any;
    operatorsList: any[];
    /**radio boxes in portability screen */
    newNumberChecked: boolean = true;
    portabilityChecked: boolean = false;

    /**flag to know if the user of buy sim the same of portability owner or not */
    changePortabilityOwner: boolean = false;
    /**promotions Array */
    promotionsArray: BuySimPromotion[] = new Array<BuySimPromotion>();
    documentIdType: number
    personalInfo: PersonalModel = new PersonalModel();
    portabilityFullName: string;
    /**portability owner object to sending it from edit portability tray to portability screen */
    portabilityOwner: PortabilityOwnerModel = new PortabilityOwnerModel();
    /**list of document type */
    documentIdTypesList: Array<any> = BUY_SIM_JOURNEY.documentIdTypes;
    idFieldPlaceholder: string;
    documentIdMaxLength: number = 9;
    idValidationPattern: string = Validation.NIF;
    showIdBackEndError: boolean = false;
    /**fir prevent background scrolling when overlay is opened */
    preventBackgroundScrolling: boolean = false
    /**Title and hint for each step*/
    screenTitle: string;
    screenHint: string;
    /**Flag for hideHintText in the last 2 steps in the journey*/
    hideHintText: boolean = false;

    /** payment terms and conditions checkbox value */
    paymentTermsAndConditionFlag: boolean;

    /** saldo initial amount in caseof portability displayed in ticket details */
    portabilityInitialAmount: string = BUY_SIM_JOURNEY.portabilityInitialAmount + ' ' + CommercialJourneys.euros;

    /**flag for selecting new number or portability radio button used in safe offer to update tarif price*/
    newNumberOrPortabilityChecked: boolean = false

    /**true when submit order at order confirmation screen and order cookies expires */
    journeyEndsFlag: boolean;
  /** flag to show payment time text boolean */
  showPaymentRechargeTime: boolean;

    constructor(private translate: TranslateService, private buySimDataService: BuySimDataService,
        private router: Router,
        private tagging: TaggingHelperService,
        private deviceService: DeviceDetectorService,
        private readonly sanitizer: DomSanitizer,
        ) {
        this.translate.get('v10.buySim.itemsList').subscribe(data => {
            this.steps = [data.setpOneLabel.body, data.portabilityStepLabel.body, data.setpTwoLabel.body, data.setpThreeLabel.body];
            if (data.passportBlacklistValues && data.passportBlacklistValues.length > 0) {
                BUY_SIM_JOURNEY.passportBlackListValues = data.passportBlacklistValues;
            }
        });
        this.getDeviceInfo();
    }

    /**check if device is desktop or mobile */
    getDeviceInfo(): void {
        if (!this.deviceService.isMobile()) {
            this.isMobileView = false;
            if (navigator.platform.toLowerCase() === macPlatform.toLowerCase() &&
                (navigator.maxTouchPoints >= 1) && (ontouchstart in window)) {
                this.isDesktopView = false;
            }
            else {
                this.isDesktopView = this.deviceService.isDesktop();
            }
        }
        else {
            this.isDesktopView = false;
            this.isMobileView = true;
        }
    }

    /** creates a shopping cart in ntol system
     */
    createShoppingCart(registerType: string): void {
        this.showFullLoader = true;
        this.buySimDataService.createShoppingCart(this.tariffName, registerType).subscribe(data => {
        const status: number = JsonQuery.value(data, JSON_PATHS.buySim.status);
        this.showPaymentRechargeTime = (JsonQuery.value(data, JSON_PATHS.buySim.shoppingCart.rechargeamounttaxes) === 0) ? false : true;
            if (status === BUY_SIM_JOURNEY.statusOk) {
                if (!data) {
                    this.handleGeneralError(data);
                    return;
                }
                this.shoppingCart = this.mapShoppingCartResponse(data);
                this.getLegalTerms(legalTermsPageName.personelInfo);
                this.HTMLDataFromAPI = this.sanitizer.bypassSecurityTrustHtml(this.shoppingCart.PaymentCardData);
                this.journeyEndsFlag = false;
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });
    }

    /** gets shopping cart data */
     getShoppingCart(): void {
        this.showFullLoader = true;
        this.buySimDataService.getShoppingCart().subscribe(data => {
            const status: number = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                if (!data) {
                    this.handleGeneralError(data);
                    return;
                }
                this.updateShoppingCartPrices(data);
                this.showFullLoader = false;
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });
    }
    /**Save offer */
    saveOffer(offerReqBody: typeof saveOfferReqBody, navigatetodeliveryAddress: boolean = true): void {
        this.buySimDataService.saveOffer(offerReqBody).subscribe((data: SaveOfferResponse) => {
            const status: number = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.updateShoppingCartPrices(data);
                this.updatePromotions(data);
                this.showFullLoader = false;
                if ( navigatetodeliveryAddress) {
                    this.router.navigate([config.buySim.deliveryAddress.route]);
                }
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });

    }
    /** updates tariff price and initial balance amount after saveoffer call and create shopping cart */
    updateShoppingCartPrices(data: any): void {
        this.shoppingCart.price = JsonQuery.value(data.data, JSON_PATHS.buySim.shoppingCart.tariffPrice) +
            ' ' + CommercialJourneys.euros;
        this.shoppingCart.initialBalance = JsonQuery.value(data.data, JSON_PATHS.buySim.shoppingCart.initialBalance) +
            ' ' + CommercialJourneys.euros;
    }

    /** updates promotions in ticket */
    updatePromotions(data: SaveOfferResponse): void {
        if (!data.data?.cartItems || data.data?.cartItems?.length === 0) {
            return;
        }
        const tariffService: Service = data.data.cartItems[0].services.find(item => {
            return item.catalogElementType?.toLowerCase() === CommercialJourneys.catalogElementTypePackage.toLowerCase();
        });
        this.getPromotions(tariffService);
    }

    /** map shopping cart data to model object
     *  @param data shopping cart data from api
     */
    mapShoppingCartResponse(data) {
        const shoppingCart = new ShoppingCartModel();
        const cartItmes = JsonQuery.value(data, JSON_PATHS.buySim.shoppingCart.cartItems);
        if (!cartItmes || cartItmes.length === 0) {
            return null;
        }
        const tariffItemServices = JsonQuery.value(cartItmes[0], JSON_PATHS.buySim.shoppingCart.cartItemSerives);
        const tariffService = tariffItemServices.find(item => {
            const type = JsonQuery.value(item, JSON_PATHS.buySim.shoppingCart.cartItemType);
            return type && type.toLowerCase() === CommercialJourneys.catalogElementTypePackage.toLowerCase();
        });
        const deviceService: any = tariffItemServices.find((item: any) => {
            const type: string = JsonQuery.value(item, JSON_PATHS.buySim.shoppingCart.cartItemType);
            return type && type.toLowerCase() === CommercialJourneys.catalogElementTypeDevice.toLowerCase();
        });
        this.getPromotions(tariffService);
        shoppingCart.tariffName = JsonQuery.value(tariffService, JSON_PATHS.buySim.shoppingCart.tariffName);
        shoppingCart.price = JsonQuery.value(tariffService, JSON_PATHS.buySim.shoppingCart.tariffPrice) + ' ' + CommercialJourneys.euros;
        shoppingCart.initialBalance = JsonQuery.value(deviceService, JSON_PATHS.buySim.shoppingCart.initialBalance) +
            ' ' + CommercialJourneys.euros;
        shoppingCart.rateCode = JsonQuery.value(tariffService, JSON_PATHS.buySim.shoppingCart.rateCode);
        shoppingCart.PaymentCardData = JsonQuery.value(tariffService, JSON_PATHS.buySim.shoppingCart.htmlPaymentCardData);
        shoppingCart.nameSEO = JsonQuery.value(data, JSON_PATHS.buySim.shoppingCart.NameSEO);
        return shoppingCart;

    }

    /** submits form data to ntol system
     * @param infoForm {FormGroup}
     */
    submitPersonalData(infoForm?: UntypedFormGroup): void {
        this.personalInfo = new PersonalModel();
        /**this value for order confirmation screen*/
        this.personelInfoEmail = infoForm.controls['email'].value;
        this.mapPersonalInfoToRequestObj(this.personalInfo, infoForm);
        if (!this.portabilityFullName) {
            this.getUserFullName(infoForm);
        }
        this.buySimDataService.savePersonalData(this.personalInfo).subscribe(data => {
            const status: number = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.shoppingCart.orderId = JsonQuery.value(data, JSON_PATHS.buySim.orderId);
                this.saveLegalTems();
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });
    }

    /** submits edit personal data from portability page */
    submitEditPersonalData(): void {
        /** as required in PREP-620  flagTitularMovil wil always false*/
        this.personalInfo.flagTitularMovil = false;
        if (this.newNumberOrPortabilityChecked) {
            this.showFullLoader = false;
            this.newNumberOrPortabilityChecked = false;
        } else {
            this.buySimDataService.savePersonalData(this.personalInfo)?.subscribe(data => {
                const status: number = JsonQuery.value(data, JSON_PATHS.buySim.status);
                if (status === BUY_SIM_JOURNEY.statusOk) {
                    this.router.navigate([config.buySim.deliveryAddress.route]);
                } else {
                    this.handleGeneralError(data);
                }
            }, error => {
                this.handleGeneralError(error);
            });
        }
    }

    /**Get user full name to be displayed in portability screen */
    getUserFullName(infoForm?: UntypedFormGroup): void {
        this.portabilityFullName = infoForm.value.name + ' ' + infoForm.value.surName +
            (infoForm.value.secondSurName ? ' ' + infoForm.value.secondSurName : '');
    }

    /**handle accept list in payment screen to send it in save Legal terms request  */
    handleAcceptListonPaymentTicketScreen(): LegalTermsItemPostModel[] {
        this.AcceptListInRequest = [];
        this.AcceptListInResponse?.forEach(term => {
            const legalTerm: LegalTermsItemPostModel = new LegalTermsItemPostModel();
            legalTerm.checked = true;
            legalTerm.id_legalterms = JsonQuery.value(term, JSON_PATHS.buySim.legalTerm.id);
            legalTerm.internalDescription = JsonQuery.value(term, JSON_PATHS.buySim.legalTerm.internalDescription);
            legalTerm.name = JsonQuery.value(term, JSON_PATHS.buySim.legalTerm.name);
            legalTerm.type = JsonQuery.value(term, JSON_PATHS.buySim.legalTerm.type);
            this.AcceptListInRequest.push(legalTerm);
        });
        return this.AcceptListInRequest;
    }

    /** save user selection of legal terms */
    saveLegalTems(): void {
        this.legalTermsPostList = [];
        this.legalTerms.forEach(term => {
            const legalTerm = new LegalTermsItemPostModel();
            legalTerm.checked = term.checked ? true : false;
            legalTerm.id_legalterms = term.id;
            legalTerm.internalDescription = term.internalDescription;
            legalTerm.name = term.name;
            legalTerm.type = term.type;
            this.legalTermsPostList.push(legalTerm);
        });
        if (this.buySimDataService.pageName === legalTermsPageName.payment) {
            this.legalTermsPostList =  [...this.legalTermsPostList, ...this.handleAcceptListonPaymentTicketScreen()]
        }

        this.buySimDataService.saveLegalTerms(this.legalTermsPostList, this.requestOrderId).subscribe(data => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                if (this.buySimDataService.pageName === legalTermsPageName.personelInfo) {
                    this.requestOrderId = JsonQuery.value(data, JSON_PATHS.buySim.orderId);
                    this.router.navigate([config.buySim.portability.route]);
                    return;
                }
                else {
                    this.submitOrder();
                }
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });
    }

    /** maps personal data to api model  */
    mapPersonalInfoToRequestObj(personalInfo: PersonalModel, infoForm: UntypedFormGroup): void {
        personalInfo.name = infoForm.controls['name'].value.trim();
        personalInfo.firstsurname = infoForm.controls['surName'].value.trim();
        personalInfo.secondsurname = infoForm.controls['secondSurName'].value ? infoForm.controls['secondSurName'].value.trim() : '';
        personalInfo.email = infoForm.controls['email'].value;
        personalInfo.phonenumber = infoForm.controls['contactNumber'].value;
        personalInfo.birthdateday = infoForm.controls['dateOfBirth'].value.getDate();
        personalInfo.birthdatemonth = infoForm.controls['dateOfBirth'].value.getMonth() + 1;
        personalInfo.birthdateyear = infoForm.controls['dateOfBirth'].value.getFullYear();
        personalInfo.flagTitularFijo = false;
        personalInfo.flagTitularMovil = false;
        personalInfo.identificationNumber = infoForm.controls['id'].value;
        personalInfo.identificationType = this.documentIdType;
        personalInfo.nationality = this.setNationality(infoForm.controls['nationality'].value);
    }

    /** returns nationality id for selected nationality */
    setNationality(nationality) {
        if (!nationality) {
            return BUY_SIM_JOURNEY.spainNationalityId;
        }
        const selectedNationality = this.nationalityDataList.find(item => {
            return item.name.toLowerCase() === nationality.toLowerCase();
        })
        return selectedNationality?.id;
    }

    /**get Promotions for payment screen*/
    getPromotions(cartItems: any): void {
        this.promotionsArray = new Array<BuySimPromotion>();
        const promotionsListData: any = JsonQuery.value(cartItems, JSON_PATHS.buySim.promotionsList) || [];
        promotionsListData.forEach(promotionItem => {
            const newPromotion: BuySimPromotion = new BuySimPromotion();
            newPromotion.shortDesc = JsonQuery.value(promotionItem, JSON_PATHS.buySim.promotion.shortDescription);
            newPromotion.longDesc = JsonQuery.value(promotionItem, JSON_PATHS.buySim.promotion.longDescription) || '';
            this.promotionsArray.push(newPromotion);
        });
    }

    /** get lis of legal terms to be displayed on personal data page and payment from ntol api */
    getLegalTerms(pageName: string): void {
        this.legalTerms = new Array<LegalTermsItemModel>();
        this.buySimDataService.getLegalTerms(pageName).subscribe(data => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                if (pageName === legalTermsPageName.personelInfo) {
                    this.responseDetails = JSON_PATHS.buySim.legalTermsListPersonelInfo;
                    this.sendPageTagging(tagging.buySIMJourney.personelInfoLoadedjourney);
                }
                else {
                    this.responseDetails = JSON_PATHS.buySim.legalTermsListPayment;
                    /**ACEPTOS List handled to send it in save legal Terms request */
                    this.AcceptListInResponse = JsonQuery.value(data, JSON_PATHS.buySim.legalTermsListPersonelInfo) || [];
                }
                const legalDataArray = JsonQuery.value(data, this.responseDetails) || [];
                legalDataArray.forEach(legalItem => {
                    const newLegalTerm = new LegalTermsItemModel();
                    newLegalTerm.id = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.id);
                    newLegalTerm.name = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.name);
                    newLegalTerm.type = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.type);
                    newLegalTerm.order = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.order);
                    newLegalTerm.internalDescription = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.internalDescription);
                    newLegalTerm.shortText = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.shortText);
                    newLegalTerm.htmlText = JsonQuery.value(legalItem, JSON_PATHS.buySim.legalTerm.htmlText);


                    this.legalTerms.push(newLegalTerm);
                });
                this.legalTerms.sort((item1, item2) => item1.order - item2.order);
                this.showFullLoader = false;
            } else {
                this.handleGeneralError(data);
            }

        }, error => {
            this.handleGeneralError(error);
        });
    }

    /** validates id from ntol api
     * @param id {string} entered id
     * @param identificationType {string} NIF OR NIE
     */
    validateId(id: string, identificationType: string) {
        return this.buySimDataService.validateId(id, identificationType).pipe(map(data => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                const result = new IdValidationModel();
                result.message = JsonQuery.value(data, JSON_PATHS.buySim.idValidation.message);
                result.resultValidation = JsonQuery.value(data, JSON_PATHS.buySim.idValidation.resultValidation);
                return result;
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        }));
    }

    /** gets list of nationalities form ntol api */
    getNationalityList() {
        return this.buySimDataService.getNationalityList().pipe(map((data: any) => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.nationalityDataList = data.data.Country;
                return this.nationalityDataList.map((nationality) => nationality.name);
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        }))
    }

    getProvinceList() {
        return this.buySimDataService.getProvincList().pipe(map((data: any) => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.provinceDataList = data.data.ProvinceDD;
                return this.provinceDataList.map((province) => province.name);
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        }))
    }

    /**submit the buysim order */
    submitOrder(): void {
        const orderReqBody = submitOrderReqBody;
        orderReqBody.idPedido = this.requestOrderId ? Number(this.requestOrderId) : undefined;
        this.buySimDataService.submitOrder(orderReqBody).subscribe(data => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.sendOKTagging();
                this.showFullLoader = false;
                this.journeyEndsFlag = true;
            } else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });

    }
    /** incase of any api failure */
    handleGeneralError(errorResponse?: any): void {
        const errorsList: BuySimErrorModel[] = this.mapErrorData(errorResponse);
        errorsList.forEach(error => {
            this.tagging.setKOJourneyErrorListVariable(tagging.buySIMJourney.kojourney.journey_name,
                error.errorCode, error.errorMessage);
        });
        this.sendKOTagging();
        this.showFullLoader = false
        this.router.navigate([config.buySim.generalError.route]);
    }

    /** maps api error response */
    mapErrorData(errorData: any): BuySimErrorModel[] {
        if (!errorData) {
            return [];
        }
        const errorResultList: BuySimErrorModel[] = new Array<BuySimErrorModel>();
        const errorList: [] = JsonQuery.value(errorData.error, JSON_PATHS.buySim.errorList);
        if (errorList && errorList.length > 0) {
            errorList.forEach(error => {
                const errorItem: BuySimErrorModel = new BuySimErrorModel();
                errorItem.errorCode = JsonQuery.value(error, JSON_PATHS.buySim.errorCode);
                errorItem.errorMessage = JsonQuery.value(error, JSON_PATHS.buySim.errorMessage);
                errorResultList.push(errorItem);
            });
        } else {
            const httpError: HttpErrorResponse = errorData as HttpErrorResponse;
            const errorItem: BuySimErrorModel = new BuySimErrorModel();
            if (httpError) {
                errorItem.errorCode = httpError.status.toString();
                errorItem.errorMessage = httpError.message;
                errorResultList.push(errorItem);
            }
        }
        return errorResultList;
    }

    /**send tagging when the api success*/
    sendOKTagging(): void {
        this.tariffNameForTagging = this.shoppingCart.nameSEO.split('-').join(' ');
        tagging.buySIMJourney.okjourney.journey_options = this.tariffNameForTagging;
        tagging.buySIMJourney.okjourney.product_process_type = this.newNumberChecked ?
            tagging.staticVariables.buySimJourneyProcessType.newNumber : tagging.staticVariables.buySimJourneyProcessType.portability;
        tagging.buySIMJourney.okjourney['&&products'] =
            tagging.buySIMJourney.okjourney['&&products'].replace('<product name>', this.tariffNameForTagging);
        tagging.buySIMJourney.okjourney.purchaseID = this.requestOrderId;
        tagging.buySIMJourney.okjourney.journey_prepaid_tariff = this.tariffNameForTagging;
        this.tagging.viewReplace(false, {}, tagging.buySIMJourney.okjourney)
    }
    /**send tagging when the api fail*/
    sendKOTagging(): void {
        this.tariffNameForTagging = this.tariffName ?
            this.tariffName.split('-').join(' ') : this.shoppingCart.nameSEO.split('-').join(' ');
        tagging.buySIMJourney.kojourney.journey_options = this.tariffNameForTagging
        tagging.buySIMJourney.kojourney.product_process_type = this.newNumberChecked ?
            tagging.staticVariables.buySimJourneyProcessType.newNumber : tagging.staticVariables.buySimJourneyProcessType.portability;
        tagging.buySIMJourney.kojourney['&&products'] =
            tagging.buySIMJourney.kojourney['&&products'].replace('<product name>', this.tariffNameForTagging);
        tagging.buySIMJourney.kojourney.error_list =
            this.tagging.getKOJourneyErrorListVariable(tagging.buySIMJourney.kojourney.journey_name);
        tagging.buySIMJourney.kojourney.journey_prepaid_tariff = this.tariffNameForTagging;
        this.tagging.viewReplace(false, {}, tagging.buySIMJourney.kojourney)
    }

    /** this api to get NtolRequest id to be displayed as order id in order confirmation screen*/
    findByToken(): void {
        this.buySimDataService.findByToken().subscribe(data => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.idNtolRequest = JsonQuery.value(data, JSON_PATHS.buySim.idNtolRequest);
                this.getLegalTerms(legalTermsPageName.payment);
            }
            else {
                this.handleGeneralError(data);
            }
        }, error => {
            this.handleGeneralError(error);
        });
    }
    /**sends page view for each page in the buy sim journey
     * @param taggingObj tagging obj for each page
     */
    sendPageTagging(taggingObj: any): void {
        this.tariffNameForTagging = this.shoppingCart.nameSEO.split('-').join(' ');
        if (taggingObj.journey_options !== undefined) {
            taggingObj.journey_options = this.tariffNameForTagging;
        }
        taggingObj['&&products'] =
            taggingObj['&&products'].replace('<product name>', this.tariffNameForTagging);
        if (taggingObj.journey_process_type !== undefined) {
            taggingObj.journey_process_type = this.newNumberChecked ?
                tagging.staticVariables.buySimJourneyProcessType.newNumber : tagging.staticVariables.buySimJourneyProcessType.portability;
        }
        if (!taggingObj.journey_prepaid_tariff) {
            taggingObj.journey_prepaid_tariff = this.tariffName ?
            this.tariffName.split('-').join(' ') : this.shoppingCart.nameSEO.split('-').join(' ');
        }
        if (!taggingObj.product_process_type) {
            taggingObj.product_process_type = this.newNumberChecked ? tagging.staticVariables.buySimJourneyProcessType.newNumber
            : tagging.staticVariables.buySimJourneyProcessType.portability;
        }
        this.tagging.viewReplace(false, {}, taggingObj)
    }

    /** send tagging interactions of continuar button click
     * @param pageObj tagging page object of page contains continuar button
     */
    sendClickOnContinuarTaggingInteraction(pageObj: any): void {
        const taggingObj: any = Object.assign({}, tagging.buySIMInteractions.clickOnContinuar);
        taggingObj.eventName = taggingObj.eventName.replace('<page_name>', pageObj.page_name);
        this.tagging.track(taggingObj.eventName, taggingObj.data, pageObj);
    }

    /**send field error interaction
     * @param fieldName name of field that has the error
     * @param pageObj tagging page obj for page that contains field
     */
    sendErrorFieldInteraction(fieldName: string, pageObj: any): void {
        const taggingObj: any = Object.assign({}, tagging.buySIMInteractions.errorInField);
        const eventName: string = taggingObj.eventName.replace('<page_name>', pageObj.page_name).replace('<field name>', fieldName);
        const data: any = {
            event_name: taggingObj.data.event_name.replace('<field name>', fieldName)
        };
        this.tagging.track(eventName, data, pageObj);
    }

    /**send field error interaction after a form submition
     * @param form data form
     * @param disableFields contains disable fields if any to neglect them from tagging
     */
    sendErrorFieldTaggingInteractionAfterSubmit(form: UntypedFormGroup, disableFields?: string[]): void {
        Object.keys(form.controls).forEach((key) => {
            const fieldTaggingName: string = tagging.staticVariables.buySimFieldsName[key];
            if (fieldTaggingName && (!disableFields || disableFields.indexOf(fieldTaggingName) !== -1)) {
                this.sendErrorFieldTaggingInteraction(key, form);
            }
        });
    }

    /**sends tagging error interaction for each form field
     * @param fieldName name of field that has the error
     * @param form data form
    */
    sendErrorFieldTaggingInteraction(fieldName: string, form: UntypedFormGroup): void {
        const control: AbstractControl = form.get(fieldName);
        if (control.invalid && !control.disabled) {
            this.sendErrorFieldInteraction(tagging.staticVariables.buySimFieldsName[fieldName], tagging.buySimPages.personalData);
        }
    }


    /**get operators list to portability screen */
    getOperatorsList(): any {
        return this.buySimDataService.getOperatorsList().pipe(map((data: any) => {
            const status: any = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.operatorsList = new Array<OperatorModel>();
                this.operatorsDataList = data.data.OperatorMobile;
                return this.operatorsDataList.map((item) => {
                    this.operatorsList.push(item.name);
                });
            }
            else {
                this.handleGeneralError(data);
            }
        }), catchError((error) => {
            this.handleGeneralError(error);
            return throwError(null);
        }))
    }

    /**sets id field validation when new document type selected
    * @param {documentType} string selected document type [DNI, NIE, passport]
    */
    onSelectDocumentIdType(documentType: string): void {
        switch (documentType) {
            case this.documentIdTypesList[0].name:
                this.setIdFieldValidations(NIEOrNIFLength, Validation.NIF, IdentificationType.NIF,
                    'v10.buySim.itemsList.idFieldDniLabel');
                break;
            case this.documentIdTypesList[1].name:
                this.setIdFieldValidations(NIEOrNIFLength, Validation.NIE, IdentificationType.NIE,
                    'v10.buySim.itemsList.idFieldNieLabel');
                break;
            case this.documentIdTypesList[2].name:
                this.setIdFieldValidations(12, Validation.buySimPassport, IdentificationType.PASSPORT,
                    'v10.buySim.itemsList.idFieldPassportLabel');
                break;
        }
    }
    /** sets id field validations and label when a new document type is selected
   * @param {maxLength} number field max number
   * @param {validationPattern} string filed validation regex
   * @param {placeHolderKey} string wcs key for field place holder
   * @param {erroMessageKey} string wcs key for validation error message
   */
    setIdFieldValidations(maxLength: number,
        validationPattern: string,
        documentIdType: IdentificationType,
        placeHolderKey: string): void {
        this.documentIdMaxLength = maxLength;
        this.idValidationPattern = validationPattern;
        this.documentIdType = documentIdType;
        this.translate.get(placeHolderKey).subscribe(data => {
            this.idFieldPlaceholder = data;
        });
    }

    /** sets default label for id field and identification type */
    setIdFieldDefaultValues(): void {
        this.documentIdType = IdentificationType.NIF;
        this.translate.get('v10.buySim.itemsList.idFieldDniLabel').subscribe(data => {
            this.idFieldPlaceholder = data;
        });
    }

    /** backend validation of id  */
    idBackendValidation(id: string): void {
        const nieRegex: RegExp = new RegExp(Validation.NIE);
        const nifRegex: RegExp = new RegExp(Validation.NIF);
        const identificationTypeValue: string = nieRegex.test(id) ? BUY_SIM_JOURNEY.IdentificationTypeNIE :
            (nifRegex.test(id) ? BUY_SIM_JOURNEY.IdentificationTypeNIF : '');
        if (identificationTypeValue) {
            this.showFullLoader = true;
            this.validateId(id.toUpperCase(), identificationTypeValue).subscribe(data => {
                this.showIdBackEndError = !data.resultValidation;
                this.showFullLoader = false;
            }, error => {
                this.handleGeneralError(error);
            });
        }
    }
}


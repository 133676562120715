export class CampaignPromotion {
    billsAttr: any[];
    attrs: any[];
    moreInfoAttrs: any[];
    alertInfoAttr: any[];
    serialNumber: string;
    brandName: string;
    name: string;
    promoCondition: string;
    promoConditionHighlight: string;
    shortDescription: string;
    legalConditionsText: string;
    legalConditionsLinkText: string;
    imagenPrp: string;
    imagenDetll: string;
    color: string;
    buttonText: string;
    moreInfoTitle: string;
    ticketDescription: string;
}

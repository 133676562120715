import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IDynamicTray } from '../../models/dynamic-tray.model';
import { IGenericSuccessErrorTray } from '../../models/generic-success-error-tray.model';
import { TrayLoadingModeComponent } from '../components/tray-loading-mode/tray-loading-mode.component';

@Injectable()
export class DynamicTrayService {
  /** trayData: this property for making a runtime changes on existing opened tray */
  public trayData: IDynamicTray;
  /** openTraySubject: should be emitted every time of opening dynamic tray */
  public openTraySubject = new Subject<any>();
  /** closeTraySubject: should be emitted every time of closing dynamic tray */
  public closeTraySubject = new Subject<boolean>();
  /** clears component ref to trigger component ondestory hook */
  public componentRefClearSubject: Subject<any> = new Subject();

  /** backButtonTraySubject: should be emitted every time of clicking on back button in dynamic tray */
  public backButtonTraySubject = new Subject();

  /** genericSuccessErrorTrayData: this property for success/error trays only */
  public genericSuccessErrorTrayData: IGenericSuccessErrorTray;
  /** openSuccessErrorTraySubject: should be emitted every time of opening generic success/error tray */
  public openSuccessErrorTraySubject = new Subject<IGenericSuccessErrorTray>();

  /** isSuccessErrorTrayOpenedRightNow: to know the current status of success error tray is it opened or not? */
  public isSuccessErrorTrayOpenedRightNow: boolean = false;
    /** flag for vodafone loader */
    showVodaSpinner?: boolean;

  constructor() { }

  /**
   * This method for opening the dynamic tray
   * @param trayData has all values of the tray
   * @param component should be in EntryComponents array in the module
   */
  open(trayData: IDynamicTray, component: any) {
    this.trayData = trayData;
    this.openTraySubject.next(component);
  }

  close() {
    this.closeTraySubject.next();
    this.isSuccessErrorTrayOpenedRightNow = false;
  }

  /**
   * this function should open a tray like this one https://app.zeplin.io/project/5981aacf48775f3834c1e7f6/screen/5e048332eb55711ae307e8ec
   * @param trayData to pass your data based on zeplin screen
   */
  openSuccessErrorTray(trayData: IGenericSuccessErrorTray) {
    this.openSuccessErrorTraySubject.next(trayData);
    this.isSuccessErrorTrayOpenedRightNow = true;
  }

  /**
   * this method for opening tray with one or two buttons only without any other content, header or notch
   * @param primaryButtonText red button text
   * @param primaryButtonAction red button action
   * @param secondaryButtonText optional white button text
   * @param secondaryButtonAction optional white button action
   */
  openButtonsOnlyTray(primaryButtonText: string, primaryButtonAction: any, secondaryButtonText?: string, secondaryButtonAction?: any) {
    const trayData: IGenericSuccessErrorTray = {
      buttonsOnly: true,
      hideTopNotch: true,
      hideHeader: true,
      hideDarkPlace: true,
      primaryButton: { primaryButtonText, primaryButtonAction },
      secondaryButton: { secondaryButtonText, secondaryButtonAction }
    };
    this.openSuccessErrorTray(trayData);
  }

  /**
   * this function for disabling / enabling the tray close
   * @param status true = will disable closing the tray
   */
  disableClosing(status: boolean) {
    this.trayData.basicTrayData.disableClosing = status;
  }
  /**show skelton inside a tray  */
  openSkeltonTray(disableClosing: boolean, showCustomHeader: boolean): void {
    let sekelatonTray: IDynamicTray;
    sekelatonTray = {
      basicTrayData: {
        disableClosing: true,
        hideXButton: true,
      },
     showCustomTrayHeader: showCustomHeader,
    }
    this.open(sekelatonTray, TrayLoadingModeComponent);
    this.disableClosing(disableClosing);
  }

}

import { MaxLengthDirective } from './../../directives/max-length.directive';
import { SPTextAreaComponent } from './sp-text-area.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SPTextAreaComponent, MaxLengthDirective],
  exports: [SPTextAreaComponent, MaxLengthDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class SpTextAreaModule {}

export enum TopupPrepaidError {
    general = 'Genérico',
    invalidCreditCard = 'Tarjeta inválida',
    invalidCreditCardInfo = 'Datos de Tarjeta',
    Notprepaid = 'Notprepaid',
    NotvodafoneNumber = 'NotvodafoneNumber',
    Notactive= 'not active',
    TopupInProgress = 'TopupInProgress',
    CreditCardTypeNotSupported = 'Tarjeta no admitida',
    SystemNotAvailable = 'sistema no disponible',
    ReplicaMode= 'replica mode',
    refundSuccess = 'refundSuccess',
    operationNotExist = 'operationNotExist',
    refundFailed = 'refundFailed',
    PaymentApiFails = 'tecnico',
    TimeOut= 'timeout',
    mortiroloKO108= 'mortiroloKO108'
}

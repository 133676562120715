import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NavigationType } from 'src/app/shared/enums/navigation-type.enum';
import { NewTaggingHelperService } from '../../../../core/services/new-tagging.helper.service';
import { StorageService } from '../../../../core/services/storage.service';
import { config } from 'src/config/pages-config';
import { SpSideMenuItem } from '../../sp-sidemenu/sp-sidemenu.interface';
import { Location } from '@angular/common';
import { AuthenticateService } from 'src/app/core/services/authenticate.service';
import { OverlayModel } from '@mva10/mva10-angular';

@Component({
  selector: 'sp-logout-overlay',
  templateUrl: './logout-overlay.component.html',
})
export class LogoutOverlayComponent implements OnInit {
  overlayData: OverlayModel = new OverlayModel();
  name: string;

  constructor(
    private nglocation: Location,
    private newTagging: NewTaggingHelperService,
    private translateService: TranslateService,
    public storageService: StorageService,
    public appService: AppService,
    private router: Router,
    private authenticateService: AuthenticateService,
  ) {}

  ngOnInit(): void {
    this.translateService.get('v10.common.logoutTray').subscribe(data => {
      const firstName: string = this.storageService.userProfile.firstName?.toLowerCase() || '' ;
      this.name = firstName.charAt(0).toUpperCase() + firstName.slice(1) || '';
      this.overlayData.title = data.title;
      this.overlayData.horizontalButtons = true;
      this.overlayData.primaryButton = data.button1;
      this.overlayData.secondaryButton = data.button2;
    });
  }

  logoutClicked(): void {
    this.newTagging.getJsonTagging('login').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(data.eventList['user_disconnectionn_ok'], data.page.stateList['user_disconnection']);
    });
    const item: SpSideMenuItem = this.appService.logoutItem;
    this.router.navigate([config.login.route]);
    this.appService.showLogoutTray = false;
    this.appService.showGDPROverlay = false;
    this.appService.gdprOverlayLoaded = false;
    this.authenticateService.logout();
    if (item.navigationType.toLowerCase() === NavigationType.Internal) {
      this.router.navigate([config.login.route]);
    } else if (item.navigationType.toLowerCase() === NavigationType.External) {
      window.open(this.nglocation.prepareExternalUrl(this.nglocation.path()));
    }
    this.router.navigate([config.login.route]);
  }

  logoutCancel(): void {
    this.newTagging.getJsonTagging('login').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(data.eventList['user_disconnectionn_ko'], data.page.stateList['user_disconnection']);
    });
    this.appService.showLogoutTray = false;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ANONYMOUS, Validation } from '../../../shared/constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';
import { PaymentJourneyEnvironment } from '../../../shared/enums/payment-journey-environment.enum';
import { BillingService } from '../../../billing/billing.service';
import { tagging } from '../../../../config/tagging-config';
import { CrossVariables } from '../../../models/tagging.model';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { AnonymousPostPaidPaymentService } from '../../services/anonymous-post-paid-payment.service';
import { PaymentCustomer } from '../../../models/payment';
import { PaymentNavigationService } from '../../services/payment-navigation.service';
import { StorageService } from '../../../core/services/storage.service';
@Component({
  selector: 'sp-payment-inadvance-user',
  templateUrl: './payment-inadvance-user.component.html',
  styleUrls: ['./payment-inadvance-user.component.scss']
})
export class PaymentInadvanceUserComponent implements OnInit {
  /** declare the form */
  paymentForm: UntypedFormGroup = this.fb.group({
    amountField: ['', [this.amountValidator()]]
  });
  /** images variables */
  visaImage: string;
  masterCardImage: string;
  winkImage: string;
  /** bottom right text in the tray */
  securedSslText: string;
  /** WCS variable */
  wcsData: {
    title: string, description: string, confirmButtonText: string,
    textboxPlaceholder: string, paidAmountErrorMsg: string
  } =
    { title: '', description: '', confirmButtonText: '', textboxPlaceholder: '', paidAmountErrorMsg: '' };
  /** should include the error message that should be appeared once user enters a value more than maximum */
  amountMoreThanMaxMsg: string = '';
  /** should include the error message that should be appeared once user enters an invalid value */
  invalidValueMsg: string = '';
  /** Show and hide validation error of the entered value in partial payment */
  showErrorMsg: boolean = false;
  /** Show and hide X button in the text field */
  showXIcon: boolean = false;
  /** to handle the height of tray when keyboard is opening */
  isFieldFocused: boolean = false;
  /** to change UI in case of desktop view */
  isDesktopView: boolean;
  /** to get different image in case of mobile view */
  isMobileView: boolean;
  @Input() isAnonymous: boolean = false;
  constructor(
    public billingService: BillingService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private appService: AppService,
    public paymentWalletService: PaymentWalletService,
    public anonPayService: AnonymousPostPaidPaymentService,
    private taggingHelperService: TaggingHelperService,
    private paymentNavigationService: PaymentNavigationService,
    private storageService: StorageService,
  ) { }

  public get isConsultant(): boolean {
    return this.storageService?.userProfile?.isConsultant || false;
  }

  ngOnInit() {
    /** check if the device is desktop */
    this.isDesktopView = window.innerWidth >= Breakpoints.DESKTOP;
    this.isMobileView = window.innerWidth < Breakpoints.TABLET;
    this.translate.get('payment').subscribe((data) => {
      /** get Data from wcs for in advance payment */
      this.wcsData.description = data.messagesList.paymentInAdvance.description;
      this.wcsData.confirmButtonText = data.messagesList.pendingAdvancedPayment.confirmButton.text;
      this.wcsData.textboxPlaceholder = data.itemsList.advancedAmount.body;
      this.wcsData.paidAmountErrorMsg = data.messagesList.paidAmountErrMsg.description;
      this.wcsData.title = data.messagesList.pendingAdvancedPayment.title;
      this.invalidValueMsg = data.messagesList.paidAmountErrMsg.description;
      this.amountMoreThanMaxMsg = data.itemsList.payInAdvanceNewErrorMsg.body;
      /** get Data from wcs for total, partial footer images and text */
      this.securedSslText = data.itemsList.securedSsl.body;
      this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciVisa.url);
      this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciMasterCard.url);
      /** get data from wcs for mobile and responsive wink image */
      if (this.isMobileView) {
        this.winkImage = this.appService.getImgFullPath(data.messagesList.cashDeskPayment.icon.url);
      } else {
        this.winkImage = this.appService.getImgFullPath(data.images.cashDeskResponsiveIcon.url);
      }
    });
    // make tagging for in advance payment start screen.
    tagging.payInAdvancePaymentStartJourney.journey_category = CrossVariables.client_typology;
    tagging.payInAdvancePaymentStartJourney.journey_environment =
      this.isAnonymous ? PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
    this.taggingHelperService.view(tagging.payInAdvancePaymentStartPageName, tagging.payInAdvancePaymentStartJourney);
  }
  /**
   * Excuted when click on continuar Button to make a payment.
   * Remove € sign from text field when submit value.
   * Check if value contains comma replace it with dot.
   */
  onSubmit(): void {
    let inAdvanceAmount: number;
    if (this.paymentForm.controls['amountField'].value.includes(',')) {
      const removeCommaFromAmount: any = this.paymentForm.controls['amountField'].value.replace(',', '.');
      inAdvanceAmount = +removeCommaFromAmount.replace('€', '');
    } else {
      inAdvanceAmount = +this.paymentForm.controls['amountField'].value.replace('€', '');
    }
    if (this.isAnonymous) {
      this.paymentNavigationService.isAnonymousPayment = this.isAnonymous;
      const payer: PaymentCustomer = new PaymentCustomer()
      payer.documentId = this.anonPayService.anonymousPaymentCustomer?.documentId; // user input
      payer.siteId = this.anonPayService.anonymousPaymentCustomer?.selectedsiteId; // site id
      payer.serviceId = ANONYMOUS;
      this.translate.get('payment.itemsList.payInAdvaneType.body').subscribe((concepto) => {
        this.billingService.openPaymentModulePostPaidAnonymous(inAdvanceAmount.toString(),
          PaymentJourny.anonymousPayInAdvance, concepto, payer, payer)
      });
    } else {
      this.translate.get('payment.itemsList.payInAdvaneType.body').subscribe((concepto) => {
        this.billingService.openPaymentModule(inAdvanceAmount.toString(), PaymentJourny.postInadvance, concepto)
      });
    }
  }
  /**
   * @return a regex that accept only numbers and single comma in text field.
   */
  numbersAndSingleCommaOnly(): RegExp {
    const regexForNumbersAndSingleComaOnly: RegExp = new RegExp(Validation.numbersAndCommaOnly);
    return regexForNumbersAndSingleComaOnly;
  }
  /**
   * Validation Fn to validate form against it.
   * Make text field must have a value , accept only numbers , single comma , and value smaller than or equal dept amount.
   */
  amountValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        this.showErrorMsg = false;
        return { invalid: true };
      } else if (control.value && this.numbersAndSingleCommaOnly().test(control.value)
        /** in advance amount should be between 1 and 500 € */
        && (+control.value.replace(',', '.') >= 1 && (+control.value.replace(',', '.') <= 500))) {
        if (control.value.includes('.')) {
          setTimeout(() => {
            control.setValue(control.value.replace('.', ','));
          }, 300);
        }
        this.showErrorMsg = false;
        return null;
      } else {
        /** Check if amount more than maximum value or it's invalid value */
        if ((+control.value.replace(',', '.') > 500)) {
          this.wcsData.paidAmountErrorMsg = this.amountMoreThanMaxMsg;
        } else {
          this.wcsData.paidAmountErrorMsg = this.invalidValueMsg;
        }
        this.showErrorMsg = true;
        return { invalid: true };
      }
    }
  }
  /**
   * Add € sign to valid text field value when blur or focus out from it.
   * Reset validators to null to make submit button not disabled when value is valid and € added to text field.
   * Hide x button inside text field when blur.
   * Revert isFieldFocused to make tray height as before
   * Make setTimeout so when click on x button inside text field click event applied first then blur.
   */
  addEurSignToValidInputOnBlur(): void {
    setTimeout(() => {
      this.isFieldFocused = false;
      this.showXIcon = false;
      if (this.paymentForm.valid && this.paymentForm.controls['amountField'].value) {
        this.paymentForm.controls['amountField'].setValidators(null);
        this.paymentForm.controls['amountField'].setValue(this.paymentForm.controls['amountField'].value + '€');
      }
    }, 1);
  }
  /**
   * Check if text field value contains € sign which added on blur and then reomove it on focus in text field.
   * Reset Validators again to validator fn.
   * Reset showXIcon boolean to true again after blur.
   * Set isFieldFocused boolean to true on focus.
   */
  onFocus(): void {
    this.showXIcon = true;
    this.isFieldFocused = true;
    if (this.paymentForm.controls['amountField'].value
      && this.paymentForm.controls['amountField'].value.includes('€')) {
      this.paymentForm.controls['amountField'].
        setValue(this.paymentForm.controls['amountField'].value.replace('€', ''));
      this.paymentForm.controls['amountField'].setValidators([this.amountValidator()]);
    }
  }
  /**
   * Executed when click on x button inside text field.
   * Reset showXIcon boolean to true again after blur.
   * Reset Validators again to validator fn.
   */
  clickXButtonInTextField(): void {
    this.showXIcon = true;
    this.paymentForm.controls['amountField'].setValidators([this.amountValidator()]);
  }
}


import { Component, OnInit } from '@angular/core';
import { TopUpService } from '../top-up.service';
import { LanguageEnum } from '../../../app/shared/enums/language.enum';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app/app.service';
import { TopupPrepaidErrorOVerlayDataService } from '../top-up-prepaid-error-overlay-data.service';
import { TopupPrepaidStages, LOCAL_STORAGE_KEYS } from '../../../app/shared/constants/defines';
import { SpMva10OverlayService } from '../../../app/shared/services/sp-mva10-overlay.service';
import { StorageService } from '../../core/services/storage.service';


@Component({
  selector: 'sp-top-up-contact-list-permission',
  templateUrl: './top-up-contact-list-permission.component.html',
  styleUrls: ['./top-up-contact-list-permission.component.scss']
})
export class TopUpContactListPermissionComponent implements OnInit {
  hideContent: boolean = false;
  permissionData: any = {
    title: '',
    subtitle: '',
    button1: '',
    button2: '',
    icon: '',
    description: ''
  }

  constructor(public topupService: TopUpService,
    private translate: TranslateService,
    private appService: AppService,
    public topupErrorService: TopupPrepaidErrorOVerlayDataService,
    public trayService: SpMva10OverlayService,
    private storage: StorageService) { }

  ngOnInit(): void {
    this.topupService.dynamicTrayData.closeButtonAction = this.returnToFirstScreens.bind(this);
    this.setUiContent();
  }
  setUiContent(): void {
    if (this.topupService.topupLanguage === LanguageEnum.spanish) {
      this.translate.get('v10.payment.messagesList.prepaidTopup.es.openContactListPermission').subscribe(data => {
        this.permissionData.subtitle = data.subtitle;
        this.permissionData.description = data.description
        this.permissionData.icon = this.appService.getImgFullPath(data.icon)
        this.permissionData.button1 = data.button1;
        this.permissionData.button2 = data.button2;
      });
    }
    else {
      this.translate.get('v10.payment.messagesList.prepaidTopup.en.openContactListPermission').subscribe(data => {
        this.permissionData.subtitle = data.subtitle;
        this.permissionData.description = data.description
        this.permissionData.icon = this.appService.getImgFullPath(data.icon)
        this.permissionData.button1 = data.button1;
        this.permissionData.button2 = data.button2;
      });

    }
  }

  returnToFirstScreens(): void {
    this.topupService.backToFirstStep = true;
    this.trayService.showBackwardTransition = true;
    this.trayService.showForwardTransition = false;
    this.topupService.isReturnFromPermissionScreen = true;
    this.hideContent = true;
    this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.firstStep });
  }

  ngOnDestroy(): void {
    this.topupService.dynamicTrayData.closeButtonAction = null;
  }

  openContactList(): void {
    this.topupService.backToFirstStep = true;
    this.topupService.isReturnFromPermissionScreen = true;
    this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.firstStep });
    this.storage.setLocalStorage(LOCAL_STORAGE_KEYS.ableToOpenContactList, true);
    this.topupService.openContactList().then(() => {
      this.topupService.returnDataFromContactList.next();
    });
  }
}


<div class="sidemenu" [ngClass]="{'hide-menu':!commonService.showMenu}" >
    <div class="side-menu-dialog">
        <div class="modal-header">
            <div class="close-icon">
                <span class="icon icon-close" id="X-button-Side-Menu" (click)="hide()"></span>
            </div>
        </div>
        <div class="modal-body">
            <div class="modal-content container-form">
                <div class="login-form">
                    <form role="form" method="POST" action="#">

                        <fieldset>
                            <div class="item" *ngFor="let item of menuService.allItemsInMenu ; let i = index" [ngClass]="{'category-group-top-border':  item.hasBorder}">
                                <div [ngClass]="{'item-contentLocked': item.locked, 'item-content': !item.locked}" *ngIf="!item.subItems || item.subItems.length == 0"
                                    (click)="onClick(item)" [id]="item.itemText ? 'spn_' + item.itemText.split(' ').join('') : ''">
                                    <span *ngIf="NotificationInboxService.notificationInboxCount > 0 && (item.redirectTo ? item.redirectTo.toLowerCase().includes(inboxRoute) : false)"
                                        class="notification-bg side-menu-pos">{{NotificationInboxService.notificationInboxCount }}</span>
                                    <span class="icon-shape icon-item">
                                        <span [ngClass]="item.iconClassName">
                                        </span>
                                    </span>


                                    <span class="item-name" [ngClass]="item.locked?'blocked':''" [innerHTML]="item.itemText">
                                      </span>

                                    <span class="icon-lock">
                                        <span class="icon icon-security" *ngIf="item.locked"></span>
                                    </span>
                                </div>
                                <div class="side-menu-accordion-padding">

                                    <span *ngIf="item.subItems && item.subItems.length > 0 && NotificationInboxService.notificationInboxCount > 0 && (item.redirectTo ? item.redirectTo.toLowerCase().includes(inboxRoute) : false)"
                                        class="notification-bg side-menu-pos">{{NotificationInboxService.notificationInboxCount }}</span>
                                    <sp-accordion (childClicked)="hide()" *ngIf="item.subItems && item.subItems.length > 0" [itemText]="item.itemText" [iconClassName]="item.iconClassName"
                                        [subItems]="item.subItems"></sp-accordion>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<sp-modal-full #OverlyOneModal></sp-modal-full>

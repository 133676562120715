export class GetCoutesResponse {
  public aggregatedAmount?: AggregatedAmount;
  public items?: Coute[];
}

export class Coute {
  public type?: string;
  public name?: string;
  public desc?: string;
  public isBundle?: boolean;
  public amount?: Amount;
  public additionalCharges?: Array<AdditionalCharges>;
  /**
   * NOT FROM SERVICE: Sorted amounts
   */
  public amounts?: ParsedAmount[];
}

export class Amount {
  public currency?: string;
  public taxesCode?: string;
  public netAmountDue?: number;
  public grossAmountDue?: number;
}

export class ParsedAmount {
  public desc?: string;
  public netAmountDue?: number;
  public grossAmountDue?: number;
  public currency?: string;
  public endDate?: string;
  public endDateDesc?: string;
}

export class AdditionalCharges {
  public type?: string;
  public name?: string;
  public desc?: string;
  public amount?: Amount;
  /**
   * Date in seconds until the concept is active
   */
  public terminationDate?: number;
}

export class AggregatedAmount {
  public currency?: string;
  public taxesCode?: string;
  public type?: string;
  public netAmountDue?: number;
  public grossAmountDue?: number;
}

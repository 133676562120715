export class JourneyTaggingModel {
    journey_name: string;
    journey_type: string;
    journey_detail: string;
    journey_status: string;
    journey_process: string;
    journey_step?: string;
    journey_category?: string;
    journey_subcategory?: string;
    constructor() {
        return {
            journey_name: '',
            journey_type: '',
            journey_detail: '',
            journey_status: '',
            journey_process: '',
            journey_step: '',
            journey_category: '',
            journey_subcategory: '',
        }
    }
}

export enum OpDetailProduct {
  volver = 'Volver',
  cerrar = 'Cerrar',
  labClose = 'labClose',
  labReturn = 'labReturn',
  siteId = 'siteId',
  admin = ' Admin: ',
  msisdn = ' MSISDN: ',
  servicio = 'Servicio',
  codigoServicio = '\n Código Servicio: ',
  rguInternet = 'I',
  internetTxt = 'Internet',
  licenciaTxt = 'licencia',
  licenciasTxt = 'licencias',
  Licencia = 'Licencia',
  maxLenghtPhone = 9,
  firstSteep = 0,
  secondSteep = 1,
  thirdSteep = 2,
  fourthSteep = 3,
  oneLicence = '1 licencia',
  currency = '€'
  }

  export enum DomainText {
    domain = 'tengo dominio propio',
    noDomain = 'no tengo dominio propio',
    licences = '{licencias} licencias'
  }

  export enum OpDetailProductFormTxt {
    nameAdmin = 'Nombre Admin: ',
    surNameAdmin = 'Apellidos Admin: ',
    emailAdmin = 'Email Admin: ',
    phoneAdmin = 'Teléfono de contacto Admin: ',
    documentAdmin = 'NIF/NIE Admin: ',
    domain1 = 'Dominio 1: ',
    domain2 = 'Dominio 2:  ',
    domain3 = 'Dominio 3: ',
    ActivityE = 'Actividad Empresa: ',
    EmailE = 'Email Empresa: ',
    phoneE = 'Teléfono Empresa: ',
    urlE = 'URL Empresa: ',
    socialE = 'Social Empresa: ',
    checkboxE = 'Direccion Empresa: '
    }

    export enum OpDetailControls {
      Nombre = 'Nombre',
      Apellidos = 'Apellidos',
      Email = 'Email',
      Telefono = 'Telefono',
      NIFNIE = 'NIFNIE',
      Domain0 = 'Domain0',
      Domain1 = 'Domain1',
      Domain2 = 'Domain2',
      Actividad = 'Actividad',
      Url = 'Url',
      Social = 'Social'
    }

  export const licencesArra = [{ name: '1', value: 1, checked: true },
  { name: '2', value: 2, checked: false }, { name: '3', value: 3, checked: false }];
  export const tabTitles = ['', ''];
  export const stepArr = [0, 1, 2, 3];

  export enum NtolInfo {
    clientType = '2',
    serviceType = '17',
    purchasePoint = '3',
    sceneType = '1',
    shopType = '5',
    cartItemID = '0',
    itemId = '0-0',
    registerType = '12'
  }

  export const dataPegaInit = {
    isPega: false,
    productId: 0,
    productname: '',
    istab: false,
    modalityid: 0
  }


export class SpMVA10OverlayConfigModel {
    over_tittle: String = '';
    over_desc: String = '';
    over_iconUrl: string = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/warning-overlay.png';
    over_firstBtnWCS: string = '';
    over_secondBtnWCS: string = '';
    over_useDafaultFirstBtnAction?: boolean = true;
    over_useDafaultSecondBtnAction?: boolean = true;
    over_fixedTitle: string = '';
    over_boldDesc: boolean = false;
    over_firstBtnRed: boolean = false;
    over_desc_secondLine: String = '';
    over_fixedTitle_NotBold: Boolean = false;
}

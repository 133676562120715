import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpTextComponent } from './sp-text.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SpTextComponent],
  exports: [SpTextComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class SpTextModule {}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sp-super-wifi-verify-email',
  templateUrl: './super-wifi-verify-email.component.html',
  styleUrls: ['./super-wifi-verify-email.component.scss']
})
export class SuperWifiVerifyEmailComponent {
  @Input() show: boolean = false;
  @Input() hideBg: boolean = false;
  @Input() img: string = '';
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() btnText: string = '';
  @Input() id: string = '';
  @Input() isOKModal: string = '';
  @Output() closeFun: EventEmitter<any> = new EventEmitter();
  @Output() btnFun: EventEmitter<any> = new EventEmitter();

  closeModalFun(): void {
    this.closeFun.emit();
  }

  clickOnBtnFun(): void {
    this.btnFun.emit();
  }
}

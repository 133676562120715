<div class="chat-pass row change-padding cont-height">
  <div class="col-xs-12">
    <div class="reamin-usage row change-padding">
      <div class="remain" [ngClass]="isUnlimited || Bundle.IsUnLimited || Bundle.TotalConsumption&&Bundle.ConsumedConsumption!=null?'col-xs-7':'col-xs-12'">
        <ng-container *ngIf="!isUnlimited">
          <span class="bundle-name-lines">
            <span *ngIf="!Bundle.title">{{Bundle.Name}}&nbsp;</span>
            <span *ngIf="Bundle.title">{{Bundle.title}}&nbsp;</span>
            <span *ngIf="Bundle.title">{{Bundle.titleLimit}} {{Bundle.titleRate}}</span>
          </span>
          <p class="call-price active-unlimated">
            <span *ngIf="parent.tariffService.Tariff.Yu && parent.isBenifitsActive && parent.isPrePaid" class="active-package">{{'productsServices.itemsList.psYuBenefitsSms.body'|translate}}</span>
          </p>
        </ng-container>
        <ng-container *ngIf="isUnlimited">
            <img src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/sms_icon.png"/>
            <span>{{'productsServices.rgk.item.sms.title'|translate}}</span> 
        </ng-container>
      </div>
      <ng-container *ngIf="!isUnlimited">
        <div class="col-xs-5 usage" *ngIf="!Bundle.IsUnLimited && Bundle.TotalConsumption&&Bundle.ConsumedConsumption!=null ">
          <sp-landing-percentage title="{{'productsServices.itemsList.psRemianing.body'|translate}}" [remaining]="(Bundle.TotalConsumption - Bundle.ConsumedConsumption) | dot"
            [percentage]="Bundle.RemainingConsumptionPercentage"></sp-landing-percentage>
        </div>
        <div class="col-xs-5 usage" *ngIf="Bundle.IsUnLimited">
          <sp-landing-percentage [percentage]="100"></sp-landing-percentage>
        </div>
      </ng-container>
      <ng-container *ngIf="isUnlimited">
        <div class="col-xs-5 usageDestiny">
          <span>{{'productsServices.rgk.item.sms.description'|translate}}</span>                                                
        </div>
      </ng-container>
    </div>
    <div class="call-info" *ngIf="(Bundle.PayPerUse  && Bundle.CallSetup) && !parent.isPrePaid">
      <!-- pay per use b true w mintues -->
      <p class="call-price">
        <span class="package">
          + {{'productsServices.prepaidPlan.itemsList.planEstablish.body'|translate}}
        </span>
      </p>
    </div>
  </div>
</div>
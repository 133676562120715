import { tagging } from '../../../../config/tagging-config';
import { BillingService } from '../../../billing/billing.service';
import { CrossVariables } from '../../../models/tagging.model';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTaggingDefault, PaymentTaggingInterface } from './payment-tagging-interface';
import { PaymentJourneyUserType } from '../../../shared/enums/payment-journey-user-type.enum';
import { PaymentJourneyEnvironment } from '../../../shared/enums/payment-journey-environment.enum';
import { PaymentJourneyProcess } from '../../../shared/enums/payment-journey-process.enum';
import { PaymentJourneyPaymentType } from '../../../shared/enums/payment-journey-payment-type.enum';
import { PaymentJourneyDetail } from '../../../shared/enums/payment-journey-detail.enum';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';

/** Use PaymentTaggingPostPaid as a default journey for dept payment */
export class PaymentTaggingPostPaid extends PaymentTaggingDefault implements PaymentTaggingInterface {
    constructor(
        public billService: BillingService,
        public paymentNavigationService: PaymentNavigationService,
        public tagging: TaggingHelperService
    ) {
        super(tagging, billService, paymentNavigationService);
    }
    /**
     * Tagging for download button in ticket screen for postpaid journey dept
     */
    tagDownLoadTicket(): void {
        const paymentPageName: any = Object.assign({}, tagging.paymentDetailsPageName);
        const paymentJourney: any = Object.assign({}, tagging.paymentDetailsJourney);
        let eventName: string = '';
        eventName = tagging.downloadTicket.eventName.replace('<page_name>', tagging.staticVariables.paymentDeptPageName);
        paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
            tagging.staticVariables.paymentDeptPageName);
        paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
            tagging.staticVariables.paymentDeptPageName);
        paymentJourney['journey_payment_type'] =
            this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postDeptPartial ?
                PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
        paymentJourney.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;
        paymentJourney.journey_category = CrossVariables.client_typology;
        paymentJourney.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        paymentJourney.payment_id = this.paymentNavigationService.thirdPartyURLParams.transactionId;
        this.tagging.track(eventName, tagging.downloadTicket.data, paymentPageName, paymentJourney);
    }
    /**
     * Tagging for ticket screen for postpaid journey dept
     */
    tagOpenPaymentTicketScreen(): void {
        const paymentPageName: any = Object.assign({}, tagging.paymentDetailsPageName);
        const paymentJourney: any = Object.assign({}, tagging.paymentDetailsJourney);
        paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
            tagging.staticVariables.paymentDeptPageName);
        paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
            tagging.staticVariables.paymentDeptPageName);
        paymentJourney['journey_payment_type'] =
            this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postDeptPartial ?
                PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
        paymentJourney.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;
        paymentJourney.journey_category = CrossVariables.client_typology;
        paymentJourney.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        paymentJourney.payment_id = this.paymentNavigationService.thirdPartyURLParams.transactionId;
        paymentJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
            PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
        this.tagging.view(paymentPageName, paymentJourney);
    }
    /** Tagging for payment wallet screen */
    tagOpenPaymentWalletScreen(isSelectedCard: boolean): void {
        this.sendTaggingHelper(
            tagging.paymentCardsPages.managePostpaidCreditCardsPageName,
            tagging.paymentCardsPages.managePostpaidCreditCards,
            isSelectedCard
        );
    }
    /** Tagging for payment wallet confirm deleting a card */
    tagPaymentWalletConfirmDeleteInteraction(): void {
        this.billService.sendPostPaidTaggingTrackActions(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyUserType.dept,
            tagging.clickOnConfirmDeleteCardPostPaidButton);
    }
    /** Tagging for payment wallet cancel deleting a card */
    tagPaymentWalletCancelDeleteInteraction(): void {
        this.billService.sendPostPaidTaggingTrackActions(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyUserType.dept,
            tagging.clickOnCancelDeleteCardPostPaidButton);
    }
    /** Tagging for payment wallet start deleting a card journey */
    tagPaymentWalletDeleteCard(): void {
        this.billService.sendPostPaidTaggingTrackActions(tagging.walletScreenPostpaidPageName, PaymentJourneyUserType.dept,
            tagging.clickOnDeleteCCPostPaid);
        this.billService.sendViewForDeleteCCScreenPostpaid(tagging.confirmDeleteCardPostpaidPageName, PaymentJourneyProcess.dept,
            PaymentJourneyDetail.deleteCC, PaymentJourneyUserType.dept);

    }
    /** Tagging for payment wallet update a card to be principal */
    tagPaymentWalletUpdateAsPrinciple(): void {
        this.tagging.track(tagging.paymentCards.interactions.clickOnUseAsMainCard.eventName,
            tagging.paymentCards.interactions.clickOnUseAsMainCard.data, tagging.paymentCardsPages.manageCreditCards);
    }
    /** Tagging for payment wallet closing overlay */
    tagPaymentWalletClickOnXInteraction(isSelectedCard: boolean): void {
        this.sendTaggingHelper(tagging.cancelPaymentInCClistPageName, tagging.cancelPaymentJourney, isSelectedCard);
    }
    /** Tagging for payment wallet cancel leaving overlay */
    tagPaymentWalletCancelLeavingOverlay(isSelectedCard: boolean): void {
        this.sendTaggingHelper(tagging.cancelPaymentBtnClickedInCClistPageName, tagging.cancelPaymentBtnClickedJourney, isSelectedCard);
    }
    /** Tagging for payment leaving success screen */
    tagPaymentLeavingSuccessScreen(transactionId: string, isToggleOn: boolean): void {
        const saveCreditCardJourney: any = Object.assign({}, tagging.saveCreditCardJourney);
        const saveCreditCardPageJourney: any = Object.assign({}, tagging.saveCreditCardJourney.pageJourney);
        let pageName: string = '';

        pageName = tagging.staticVariables.paymentDeptPageName;
        saveCreditCardJourney['journey_payment_type'] =
            this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postDeptPartial ?
                PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
        saveCreditCardJourney.data.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;

        saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_subcategory_level_2 = pageName;

        const paymentId: string = transactionId;
        saveCreditCardJourney.data.payment_id = paymentId;
        const eventNumber: any = isToggleOn ? saveCreditCardJourney.toggle_active : saveCreditCardJourney.toggle_not_active;
        saveCreditCardJourney.data['&&events'] = saveCreditCardJourney.data['&&events']
            .replace('<event_number>', eventNumber.toString())
            .replace('<payment_id>', paymentId);
        saveCreditCardJourney.data.journey_category = CrossVariables.client_typology;
        saveCreditCardJourney.data.journey_card_selected = this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        saveCreditCardJourney.data.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
            PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;

        this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
    }

    sendTaggingHelper(pageName: any, journey: any, isSelectedCard: boolean): void {
        const paymentPageName: any = Object.assign({}, pageName);
        const paymentJourney: any = Object.assign({}, journey);
        paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
            tagging.staticVariables.paymentDeptPageName);
        paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
            tagging.staticVariables.paymentDeptPageName);
        paymentJourney['journey_payment_type'] =
            this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postDeptPartial ?
                PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
        paymentJourney.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;
        paymentJourney.journey_category = CrossVariables.client_typology;
        paymentJourney.journey_card_selected = isSelectedCard || this.paymentNavigationService.paymentJourneyParams.token ?
            tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
        paymentJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
            PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
        this.tagging.view(paymentPageName, paymentJourney);
    }
    /** Tagging cerrar btn Success paymet overlay screen */
    successpaymentCerrarBtnClick(): void {
        const saveCreditCardJourney: any = Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlay);
        const saveCreditCardPageJourney: any = Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlay.pageJourney);
        let pageName: string = '';
        pageName = tagging.staticVariables.paymentDeptPageName;
        saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
        this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
    }
    /** Tagging ok btn Success paymet overlay screen */
    successpaymentEntendidoBtnClick(): void {
        const saveCreditCardJourney: any = Object.assign({}, tagging.entendidoBtnClickSuccessPaymentOverlay);
        const saveCreditCardPageJourney: any = Object.assign({}, tagging.entendidoBtnClickSuccessPaymentOverlay.pageJourney);
        let pageName: string = '';
        pageName = tagging.staticVariables.paymentDeptPageName;
        saveCreditCardJourney.event_name = saveCreditCardJourney.event_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
        saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
        this.tagging.track(saveCreditCardJourney.event_name, saveCreditCardJourney.data, saveCreditCardPageJourney);
    }
}

import { tagging } from './../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';

import { BundleType } from '../../../shared/enums/bundleType.enum';

declare var $: any;

import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { ServiceBalanceWs10Service } from '../../services/service-balance-ws10.service';
import { serviceBalanceType, defines, DatePipeFormat } from '../../../shared/constants/defines';
import { ServiceBalanceModel, ItemsServiceBalanceModel } from '../../../models/serviceBalance.model';
import { ProgressListModel, ItemProgressListModel } from '@mva10/mva10-angular';
import moment from 'moment';
import { ServiceType } from '../../enums/serviceType.enum';

@Component({
  selector: 'sp-current-bonos',
  templateUrl: './current-bonos.component.html',
  styleUrls: ['./current-bonos.component.scss']
})
export class CurrentBonosComponent implements OnInit {

  @Output() loadBonosWithoutError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() taggingOverlay: Object;
  @Input() title: string;

  labelButtonCollapse: string;
  labelButtonExpanded: string;
  cardExpandableValueCalls: boolean = true;
  cardExpandableValueInternet: boolean = true;
  cardExpandableValueMessages: boolean = true;

  loadComsuption: boolean = false;
  errorComsuption: boolean = false;
  labelCalls: string;
  labelData: string;
  labelMessages: string;
  labelTv: string;
  labelInternet: string;
  labelDescEmptyComsuption: string;
  labelcardExpandableValueCalls: string;
  labelcardExpandableValueInternet: string;
  labelcardExpandableValueMessages: string;
  labelcardExpandableValueTv: string;
  multipleSites: boolean;

  lottieConfig: AnimationOptions;
  anim: AnimationItem;

  @ViewChild('cardVoiceBonos') cardVoiceBonos: ElementRef;
  @ViewChild('cardDataBonos') cardDataBonos: ElementRef;
  @ViewChild('cardSMSBonos') cardSMSBonos: ElementRef;
  labelCycle: string;
  labelMonth: string;
  arrayBonosVoice: ItemsServiceBalanceModel[] = [];
  arrayBonosData: ItemsServiceBalanceModel[] = [];
  arrayBonosSMS: ItemsServiceBalanceModel[] = [];
  progressListDataVoice: ProgressListModel;
  progressListDataSMS: ProgressListModel;
  progressListDataData: ProgressListModel;

  btnRetry: string;

  get bundleTypeEnum(): typeof BundleType { return BundleType; }

  constructor(
    public appTheme: AppService,
    private translate: TranslateService,
    private taggingHelper: TaggingHelperService,
    public utilitiesService: UtilitiesService,
    private serviceBalanceService: ServiceBalanceWs10Service,
  ) {
    this.appTheme.showFullAppLoader = false;
  }

  ngOnInit() {
    this.resetTextExpand();
    this.getTranslateTextComsuption();
    this.getComsuptionBonos();
    this.sendView();
  }

  sendView(): void {
    this.taggingHelper.view(JSON.parse(JSON.stringify(this.taggingOverlay)).view);
  }

  getComsuptionBonos(): void {
    this.loadComsuption = false;
    this.errorComsuption = false;
    this.serviceBalanceService.getServiceBalance(serviceBalanceType.inPlan, false).subscribe((responseBonos: ServiceBalanceModel) => {
      this.loadBonosWithoutError.emit(true);
      this.loadComsuption = true;
      this.checkTypeBonos(responseBonos.items);
      this.mapItemVoice();
      this.mapItemSMS();
      this.mapItemData();

    }, (error) => {
      this.loadComsuption = true;
      this.errorComsuption = true;
      this.loadBonosWithoutError.emit(false);
    });
  }

  checkTypeBonos(arrayBonos: ItemsServiceBalanceModel[]): void {
    this.arrayBonosVoice = arrayBonos.filter(item => item.type.toUpperCase() === BundleType.Voice.toUpperCase());
    this.arrayBonosData = arrayBonos.filter(item => item.type.toUpperCase() === BundleType.Data.toUpperCase());
    this.arrayBonosSMS = arrayBonos.filter(item => item.type.toUpperCase() === BundleType.SMS.toUpperCase());
  }

  mapItemVoice(): void {

    this.progressListDataVoice = new ProgressListModel();
    this.progressListDataVoice.items = [];

    const text_dashboard_overlay: { [key: string]: string } = this.translate.instant('v10.dashboard.overlay_consumption');

    const text_common_literal: { [key: string]: string } = this.translate.instant('v10.common.literals');

    this.arrayBonosVoice.forEach((item: ItemsServiceBalanceModel) => {

      const itemProgress: ItemProgressListModel = new ItemProgressListModel();
      itemProgress.title = item.name;
      if (item.validityPeriod && item.allowance && !item.allowance.unlimited &&
        item.subscriptions[0].type.toLowerCase() === ServiceType.Postpaid.toLowerCase()) {
        itemProgress.description = text_dashboard_overlay.to + ' ' +
          moment(new Date(item.validityPeriod.toDate)).format(DatePipeFormat.defaultInputFormat);
      }
      if (item.allowance.unlimited) {
        itemProgress.descriptionRight = (text_common_literal.unlimited_FP ? text_common_literal.unlimited_FP : '');
        itemProgress.total = 1;
        itemProgress.value = 1;
      } else {
        const availableAllowance: number =
          Number.parseInt(item.allowance.availableAllowance, 10) - Number.parseInt(item.allowance.usedAllowance, 10);
        itemProgress.total = Number.parseInt(item.allowance.availableAllowance, 10);
        itemProgress.value = Number.parseInt(availableAllowance.toString(), 10);
        if (item.allowance.usedAllowance) {
          if (availableAllowance < 60) {
            itemProgress.descriptionRight = text_dashboard_overlay.data_left + ' 0 min';
          } else {
            itemProgress.descriptionRight = text_dashboard_overlay.data_left + ' ' +
              (Math.trunc(availableAllowance / 60)) + ' min';
          }
        }
      }
      this.progressListDataVoice.items.push(itemProgress);
    });
  }

  mapItemSMS(): void {
    this.progressListDataSMS = new ProgressListModel();
    this.progressListDataSMS.items = [];

    const text_dashboard_overlay: { [key: string]: string } = this.translate.instant('v10.dashboard.overlay_consumption');

    const text_common_literal: { [key: string]: string } = this.translate.instant('v10.common.literals');

    this.arrayBonosSMS.forEach((item: ItemsServiceBalanceModel) => {
      const itemProgress: ItemProgressListModel = new ItemProgressListModel();
      itemProgress.title = item.name;
      if (item.validityPeriod && item.allowance && !item.allowance.unlimited &&
        item.subscriptions[0].type.toLowerCase() === ServiceType.Postpaid.toLowerCase()) {
        itemProgress.description = text_dashboard_overlay.to + ' ' +
          moment(new Date(item.validityPeriod.toDate)).format(DatePipeFormat.defaultInputFormat);
      }
      if (item.allowance.unlimited) {
        itemProgress.descriptionRight = (text_common_literal.unlimited_P ? text_common_literal.unlimited_P : '');
        itemProgress.total = 1;
        itemProgress.value = 1;
      } else {
        const availableAllowance: number =
          Number.parseInt(item.allowance.availableAllowance, 10) - Number.parseInt(item.allowance.usedAllowance, 10);
        itemProgress.total = Number.parseInt(item.allowance.availableAllowance, 10);
        itemProgress.value = Number.parseInt(availableAllowance.toString(), 10);
        if (item.allowance.usedAllowance) {
          itemProgress.descriptionRight = text_dashboard_overlay.data_left + ' ' + availableAllowance + ' SMS';
        }
      }
      this.progressListDataSMS.items.push(itemProgress);
    });
  }

  mapItemData(): void {
    this.progressListDataData = new ProgressListModel();
    this.progressListDataData.items = [];

    const text_dashboard_overlay: { [key: string]: string } = this.translate.instant('v10.dashboard.overlay_consumption');

    const text_common_literal: { [key: string]: string } = this.translate.instant('v10.common.literals');

    this.arrayBonosData.forEach((item: ItemsServiceBalanceModel) => {
      const itemProgress: ItemProgressListModel = new ItemProgressListModel();
      itemProgress.title = item.name;
      if (item.validityPeriod && item.allowance && !item.allowance.unlimited &&
        item.subscriptions[0].type.toLowerCase() === ServiceType.Postpaid.toLowerCase()) {
        itemProgress.description = text_dashboard_overlay.to + ' ' +
          moment(new Date(item.validityPeriod.toDate)).format(DatePipeFormat.defaultInputFormat);
      }
      if (item.allowance && item.allowance.unlimited) {
        itemProgress.descriptionRight = (text_common_literal.unlimited_P ? text_common_literal.unlimited_P : '');
        itemProgress.total = 1;
        itemProgress.value = 1;
      } else if (item.allowance) {
        const availableAllowance: number =
          Number.parseFloat(item.allowance.availableAllowance) - Number.parseFloat(item.allowance.usedAllowance);
        itemProgress.total = Number.parseInt(item.allowance.availableAllowance, 10);
        itemProgress.value = Number.parseInt(availableAllowance.toString(), 10);
        if (item.allowance.usedAllowance && availableAllowance < 1024) {
          itemProgress.descriptionRight = text_dashboard_overlay.data_left + ' ' +
            Number.parseFloat(availableAllowance.toString()).toFixed(2).replace('.', ',') + ' MB';
        } else if (item.allowance.usedAllowance && availableAllowance >= 1024) {
          const valueAllowance: string = this.utilitiesService.thousandNumber((Number.parseFloat(availableAllowance.toString()) / 1024)
            .toFixed(2).replace('.', ','));
          itemProgress.descriptionRight = text_dashboard_overlay.data_left + ' ' +
            valueAllowance + defines.spacerGB;
        }
      }
      this.progressListDataData.items.push(itemProgress);
    });
  }

  clickExpandCardConsumption(isClosingCard: boolean, type: BundleType): void {
    const animationDelay = 300;

    let expandCardId = '';
    let cardElement: ElementRef;
    let taggingProductViewDetails: string;
    switch (type) {
      case BundleType.Voice:
        expandCardId = 'cardExpandBonosCallsId';
        cardElement = this.cardVoiceBonos;
        taggingProductViewDetails = tagging.staticVariables.specialCall.toLowerCase();
        this.cardExpandableValueCalls = isClosingCard;
        this.labelcardExpandableValueCalls = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;

      case BundleType.Data:
        expandCardId = 'cardExpandBonosInternetId';
        cardElement = this.cardDataBonos;
        taggingProductViewDetails = tagging.staticVariables.specialData.toLowerCase();
        this.cardExpandableValueInternet = isClosingCard;
        this.labelcardExpandableValueInternet = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;

      case BundleType.SMS:
        expandCardId = 'cardExpandBonosMessagesId';
        cardElement = this.cardSMSBonos;
        taggingProductViewDetails = tagging.staticVariables.specialSms.toLowerCase();
        this.cardExpandableValueMessages = isClosingCard;
        this.labelcardExpandableValueMessages = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;
    }

    // Perform actions
    if (isClosingCard) {
      $('#' + expandCardId).slideUp(animationDelay);
    } else {
      $('#' + expandCardId).slideDown(animationDelay);
      setTimeout(() => cardElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' }), animationDelay);

      const eventName: string = JSON.parse(JSON.stringify(this.taggingOverlay))
        .clickOnViewDetails.eventName.replace('{product}', taggingProductViewDetails);
      const data: { [key: string]: string } = JSON.parse(JSON.stringify(this.taggingOverlay)).clickOnViewDetails.data;
      data.event_name = data.event_name.replace('{product}', taggingProductViewDetails)
      this.taggingHelper.track(eventName, data);
    }
  }


  getTranslateTextComsuption(): void {
    this.translate.get('v10.billing').subscribe(data => {
      this.labelButtonCollapse = data.comsuption.openExpand;
      this.labelButtonExpanded = data.comsuption.closeExpand;
      this.labelCalls = data.comsuption.labelCalls;
      this.labelData = data.comsuption.labelData;
      this.labelMessages = data.comsuption.labelSms;
      this.labelInternet = data.comsuption.labelInternet;
      this.labelDescEmptyComsuption = data.comsuption.descEmpty;
      this.labelcardExpandableValueCalls = this.labelButtonCollapse;
      this.labelcardExpandableValueInternet = this.labelButtonCollapse;
      this.labelcardExpandableValueMessages = this.labelButtonCollapse;
      this.lottieConfig = {
        path: this.appTheme.getImgFullPath(data.comsuption.iconError),
        autoplay: true,
        loop: true
      };
    });
    this.btnRetry = this.translate.instant('v10.common.literals.retry_C');
  }

  retryComsuptionBonos(): void {
    this.getComsuptionBonos();
    const eventName: string = JSON.parse(JSON.stringify(this.taggingOverlay)).clickOnRetry.eventName;
    const data: { [key: string]: string } = JSON.parse(JSON.stringify(this.taggingOverlay)).clickOnRetry.data;
    this.taggingHelper.track(eventName, data);
  }


  resetTextExpand(): void {
    this.cardExpandableValueCalls = true;
    this.cardExpandableValueInternet = true;
    this.cardExpandableValueMessages = true;
    this.labelcardExpandableValueCalls = this.labelButtonCollapse;
    this.labelcardExpandableValueInternet = this.labelButtonCollapse;
    this.labelcardExpandableValueMessages = this.labelButtonCollapse;
  }

  handleAnimation(anim: AnimationItem): void {
    this.anim = anim;
  }
}



<div id="bundle" class="bundle">
  <div id="bundleRoaming" class="bundle__roaming">
    <mva10-c-icon id="sharedErrorAlertCustomIcon" class="icon"   [size]="25" [iconId]="'icon-roaming'"  style="margin-bottom: 14px;"></mva10-c-icon>
    <p id="LandingComp_txt_psRoamingMsg1_title" class=col-xs-12 >
      {{'v10.productsServices.miTarifaCard.consumption.roamingText'|translate}}</p>
  </div>
 
      <div id="bundleLines" class="bundle__lines">
        <mva10-c-icon id="sharedErrorAlertCustomIcon" class="bundle__lines-icons"  [size]="25" [iconId]="'icon-sms'"  style="margin-bottom: 14px;"></mva10-c-icon>
        <p id="bundleName" *ngIf="!Bundle?.title">{{Bundle?.Name}}</p>
        <p id="bundleTitle" *ngIf="Bundle?.title">{{Bundle?.title}} {{Bundle?.titleLimit}} {{Bundle?.titleRate}}</p>
      </div>
</div>

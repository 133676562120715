import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { AuthenticateService } from '../services/authenticate.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { config } from 'src/config/pages-config';

@Injectable()
export class AuthorizedUserGuard implements CanActivate {
  constructor(private auth: AuthenticateService, private router: Router, private deepLinkingService: DeepLinkingService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.hasRole(CustomerType.Authorized.toLowerCase())) {
      this.deepLinkingService.isError = false;
      return true;
    }
    else {
      this.deepLinkingService.isError = true;
      this.router.navigate([config.dashboard.route]);
      return false;
    }
  }
}

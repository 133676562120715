import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msisdnToShow'
})
export class MsisdnToShowPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value && value.length === 9) {
      return value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 9);
    } else {
      return value;
    }
  }

}

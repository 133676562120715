<div class="radio">
    <ul>
        <li *ngFor="let choice of choices">
            <div class="item-content">
                <input type="radio" id="{{groupName+choice}}" [name]="groupName" [value]="choice" (change)="updateForm(choice)" [checked]="checked(choice)">
                <label for="{{groupName+choice}}"> {{isCompanyChooser? 'CIF:' : ''}} {{choice}} </label>
                <div class="check"></div>
            </div>
        </li>
        <li *ngIf="hasExternalCompanies">
            <div class="item-content">
                <input type="radio" id="radio" [value]="LinkExternal" (change)="updateForm(LinkExternal)" [checked]="checked(LinkExternal)" >
                <label for="radio"> {{'common.itemsList.manageOthers.body'|translate}} </label>
                <div class="check"></div>
            </div>
        </li>
    </ul>
</div>
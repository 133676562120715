
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../../shared/constants/routes-config';
import { CompanyService } from '../../shared/services/company.service';
import * as constants from '../../shared/constants/defines';
import { SubscriptionService } from '../../core/services/subscription.service';
import { Observable } from 'rxjs';
import { BillTicket } from 'src/app/models/bill-details.model';
@Injectable()
export class BillingVf10Data {
  constructor(private http: HttpClient,
    private companyService: CompanyService,
    private subscriptionService: SubscriptionService) { }


  public getCustomerAccount(siteId: string) {
    let url;
    if (this.companyService.selectedCompanyId) {
      url = `${API_URLS.Subscriptions.Subscription}?holderId=${this.companyService.selectedCompanyId}&customerAccountId=${siteId}`;
    } else {

      url = `${API_URLS.Subscriptions.Subscription}?customerAccountId=${siteId}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  public getBalanceForService(siteId: string, bundleType = constants.BundleType.outOfPlan) {
    return this.http.get(API_URLS.Dashboard.ServiceBalance.
      replace('{siteId}', siteId).
      replace('{serviceId}', this.subscriptionService.customerData.currentService.id).
      replace('{bundleType}', bundleType)).pipe(map((response: any) => {
        return response;
      }))
  }
  public getTicket(customerId: string): Observable<BillTicket[]> {
    let url: string = API_URLS.Ticket.get;
    url = url.replace('{customerID}', customerId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: { [key: string]: HttpHeaders } = {
      headers: headers
    };
    return this.http.get<BillTicket[]>(url, options);
  }
}

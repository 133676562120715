
import { SpModalComponent } from '../components/sp-modal/sp-modal.component';
import { Notification } from '../../models/notification.model';
import { Error } from '../../models/error.model';
import { HttpRequestMethods } from '../constants/defines';
import { SiteHandlingService } from './site-handling.service';
import { Injectable } from '@angular/core';
@Injectable()
export class NotificationService {
    public notificationModal: SpModalComponent;
    public sp_modal_functions = {
        back: this.backImpl.bind(this),
        dismiss: this.dismissImpl.bind(this),
    }
    constructor(private siteHandlingService: SiteHandlingService) { }
    public createNotification(notification: Notification) {
        this.notificationModal.bodyContent = notification.bodyContent;
        this.notificationModal.bodyIcon = notification.bodyIcon;
        this.notificationModal.bodyTitle = notification.bodyTitle;
        this.notificationModal.closeButtonClick = notification.closeButtonClick;
        this.notificationModal.primaryButtonClick = notification.primaryButtonClick;
        this.notificationModal.primaryButtonStyle = notification.primaryButtonStyle;
        this.notificationModal.primaryButtonText = notification.primaryButtonText;
        this.notificationModal.secondaryButtonClick = notification.secondaryButtonClick;
        this.notificationModal.secondaryButtonStyle = notification.secondaryButtonStyle;
        this.notificationModal.secondaryButtonText = notification.secondaryButtonText;
        this.notificationModal.hideX = notification.hidex;
        this.notificationModal.showRetryButton = notification.showRetryButton;
        this.notificationModal.xFunctionSameAsPrimaryButton = notification.xFunctionSameAsPrimaryButton;
        this.notificationModal.show();
    }
    public createErrorNotification(backEndError, customError: Error, requestMethod: string, isPageLoaded?: boolean,
        hideFunction?)
        : SpModalComponent {
        this.resetModalObj();
        this.notificationModal.bodyContent = customError.description;
        this.notificationModal.bodyTitle = customError.title;
        this.notificationModal.showRetryButton = customError.retryButton;
        if (!isPageLoaded && requestMethod === HttpRequestMethods.get) {
            if (hideFunction) {
                this.notificationModal.primaryButtonClick = hideFunction;
            }
            else {

                this.notificationModal.primaryButtonClick = this.sp_modal_functions.back;
            }
            this.notificationModal.hideX = true;
            this.notificationModal.primaryButtonText = customError.confirmButtonText || customError.dismissButtonText;
        }
        else {
            this.notificationModal.primaryButtonClick = this.notificationModal.primaryButtonClick || this.sp_modal_functions.dismiss;
            this.notificationModal.hideX = customError.hideX;
            this.notificationModal.primaryButtonText = customError.dismissButtonText || customError.confirmButtonText;

        }
        this.notificationModal.requestMethod = requestMethod;
        return this.notificationModal;
    }

    backImpl() {
        if (this.siteHandlingService.isSiteP2()) {
            this.notificationModal.hide();
        }
        else {
            this.notificationModal.hide()
            history.go(-1);
        }

    }
    dismissImpl() {
        this.notificationModal.hide();
    }


    resetModalObj() {
        this.notificationModal.bodyIcon = undefined;
        this.notificationModal.closeButtonClick = undefined;
        this.notificationModal.primaryButtonClick = undefined;
        this.notificationModal.primaryButtonStyle = undefined;
        this.notificationModal.secondaryButtonClick = undefined;
        this.notificationModal.secondaryButtonStyle = undefined;
        this.notificationModal.secondaryButtonText = undefined;
        this.notificationModal.showRetryButton = false;

    }

}

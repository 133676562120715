import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { PromotionsIngenio } from '../../../models/promotions-ingenio.model';

@Component({
  selector: 'sp-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {
  @Input() promotions: PromotionsIngenio;
  @Output() showPrimaryClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showSecondaryClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.promotions.flagPromotion = (this.promotions.flagPromotion !== undefined) ? this.promotions.flagPromotion : true;
  }

  /** Click PRIMARY buttom */
  public primaryClick(): void {
    this.showPrimaryClick.emit(true);
  }
  /** Click SECONDARY buttom */
  public secondaryClick(): void {
    this.showSecondaryClick.emit(true);
  }

}

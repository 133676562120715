export enum JourneyCategory {
  consumer = 'particulares',
  freelance = 'autonomos',
  enterprise = 'empresas'
}

export enum JourneySubCategory {
  postpaid = 'postpago',
  prepaid = 'prepago'
}

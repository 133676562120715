<div class="vfac-submenu"
    [ngClass]="{'vfac-submenu__transition-init': !starting && !closing,
                'vfac-submenu__transition-start': starting && !bigsubmenu,
                'vfac-submenu__transition-bigstart': starting && bigsubmenu,
                'vfac-submenu__transition-end': !starting && closing}">
  <div *ngIf="openText">
    <div class="vfac-submenu__sidemenu" [ngClass]="{'vfac-submenu--hidden':!openText}">
      <div class="vfac-submenu__side-menu-dialog">
        <div class="vfac-submenu__header">
          <svg class="vfac-submenu__close-icon" (click)="close()">
            <title>Close</title>
            <use xlink:href="#icon-close"/>
          </svg>
        </div>
        <div class="vfac-submenu__body scrollbar-hidden">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{'vfac-submenu': open}" (click)="close()">
</div>

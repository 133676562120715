import { Component, OnInit } from '@angular/core';
import { LandingComponent } from '../../../products-and-services/landing/landing.component';
import { Product } from '../../../models/product.model';

@Component({
  selector: 'sp-landing-fixed-to-fixed--bundle',
  templateUrl: './landing-fixed-to-fixed--bundle.component.html',
  styleUrls: ['./landing-fixed-to-fixed--bundle.component.scss']
})
export class LandingFixedToFixedBundleComponent implements OnInit {
  public parent: LandingComponent
  public Bundle: Product
  public isUnlimited: boolean = false;

  constructor()  { }

  ngOnInit() {
  }

}

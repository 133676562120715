export class AdaraReducedTokenDataModel {
  public grantType: string
  public clientId: string
  public subjectTokenType: string
  public actorTokenType: string
  public chatbotScope: string
  public actorToken: string
}

export class AdaraOverlayDataModel {
  public adaraTitle: string;
  public adaraCloseOverlayTitle: string;
  public adaraCloseOverlaySubtitle: string;
  public adaraCloseOverlayButtonYes: string;
  public adaraCloseOverlayButtonNo: string;
  public adaraCloseOverlayButtonContinue: string
  public adaraCloseOverlayButtonExit: string
  public doYouWantToLeaveTitle: string
  public adaraIframeUrlBase: string;
}

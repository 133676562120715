export enum shortMonthName {
  January = 'Ene',
  February = 'Feb',
  March = 'Mar',
  April = 'Abr',
  May = 'May',
  June = 'Jun',
  July = 'Jul',
  August = 'Ago',
  September = 'Sep',
  October = 'Oct',
  November = 'Nov',
  December = 'Dic'
}


export class ServicePackageModel {
    name?: string;
    code?: string;
    type?: string;
    title?: string;
    subtitle?: string;
    additionalLinesDesc?: string;
    startDate?: string;
}


import { Injectable } from '@angular/core';
import { AllowedServices } from '../../models/commercial-allowed.model';
import { allowedServicesDef, JSON_PATHS, packageType, SSNDB } from '../constants/defines';
import { UtilitiesService } from '../utils/utilities.service';
import { SubscriptionService } from '../../core/services/subscription.service';

@Injectable()
export class AllowedServicesService {
public allowedServices: AllowedServices[];
public allowedLines: AllowedServices[];
public unAllowedLines: AllowedServices[];
public contracAllowedLines: AllowedServices[];
public contracunAllowedLines: AllowedServices[];
public newAllowedLines: AllowedServices[];
public newUnAllowedLines: AllowedServices[];
public selectedLine: AllowedServices;
public selectedTemporalLine: AllowedServices;
public packageAllowedLines: AllowedServices[];
public packageLine: boolean = false;
public listAllowedMsisdn: string[];
public listAllowedCartItemId: string[];
public returnFromPrescoring: boolean = false;

  constructor(
    private utilities: UtilitiesService,
    public subscriptionData: SubscriptionService
  ) {}

  public allowedLogic(resServices: AllowedServices[]): AllowedServices[] {
    this.allowedServices = new Array<AllowedServices>();
    this.allowedServices = resServices;
    this.setListAllowed(resServices);
    return resServices;
  }

  public separateLines(): void {
    this.allowedLines = this.allowedServices.filter(line => line.allowed);
    this.unAllowedLines = this.allowedServices.filter(line => {
      return (line.serviceType.toLowerCase() === allowedServicesDef.postpaid.toLowerCase()) && !line.allowed
    });
    this.separateAllowed();
    this.separateUnallowed();
    this.setSelectedLine();
    this.sortContractLines();
    this.sortNewLines();
  }

  private separateAllowed(): void {
    this.contracAllowedLines = new Array();
    this.newAllowedLines = new Array();
    this.allowedLines.map( res => {
      res.origin.toLocaleLowerCase() === allowedServicesDef.contract.toLocaleLowerCase() ?
      this.contracAllowedLines.push(res) : this.newAllowedLines.push(res);
    })
  }

  private separateUnallowed(): void {
    this.contracunAllowedLines = new Array();
    this.newUnAllowedLines = new Array();
    this.unAllowedLines.map( res => {
      res.origin.toLocaleLowerCase() === allowedServicesDef.contract.toLocaleLowerCase() ?
      this.contracunAllowedLines.push(res) : this.newUnAllowedLines.push(res);
    })
  }

  private setSelectedLine(): void {
    const phoneNumber: string = this.subscriptionData.customerData.currentService.id;
    this.packageLine = this.contracAllowedLines.some(line => line.productType === packageType);
    let selected: boolean = false;
    this.packageAllowedLines = [];
      this.contracAllowedLines.forEach(res => {
        if (res.productType === packageType) {
          res.selected = !selected;
          selected = true;
          this.packageAllowedLines.push(res);
        } else if (res.serviceId === phoneNumber && !this.packageLine) {
          res.selected = !selected;
          selected = true;
        }
      });

    if ( !selected && this.contracAllowedLines.length > 0 ) {
      this.sortContractLines();
      this.contracAllowedLines[0].selected = true;
    } else if ( !selected ) {
      this.sortNewLines();
      this.newAllowedLines[0].selected = true;
    }
  }

  private sortContractLines(): void {
    const usedString: string = JSON_PATHS.AllowedServices.serviceId.replace('$.', '');
    this.utilities.sortByKeyReverse(this.contracAllowedLines, usedString);
    this.utilities.sortByKey(this.contracAllowedLines, SSNDB.selected);
    this.utilities.sortByKeyReverse(this.contracunAllowedLines, usedString);
  }

  private sortNewLines(): void {
    const usedString: string = JSON_PATHS.AllowedServices.itemId.replace('$.', '');
    const porta: AllowedServices[] = new Array();
    const newLines: AllowedServices[] = new Array();
    this.newAllowedLines.map(res => {
      Number(res.registerType) === 2 ? porta.push(res) : newLines.push(res);
    });
    this.utilities.sortByKeyReverse(porta, usedString);
    this.utilities.sortByKeyReverse(newLines, usedString);
    const portaUnallowed: AllowedServices[] = new Array();
    const newLinesUnallowed: AllowedServices[] = new Array();
    this.newUnAllowedLines.map(res => {
      Number(res.registerType) === 2 ? portaUnallowed.push(res) : newLinesUnallowed.push(res);
    });
    this.utilities.sortByKeyReverse(portaUnallowed, usedString);
    this.utilities.sortByKeyReverse(newLinesUnallowed, usedString);
    this.newAllowedLines = new Array();
    this.newUnAllowedLines = new Array();
    this.newAllowedLines.push(...porta, ...newLines);
    this.newUnAllowedLines.push(...portaUnallowed, ...newLinesUnallowed);
    this.utilities.sortByKey(this.newAllowedLines, SSNDB.selected);
  }

  public destroyAllLines(): void {
    this.allowedLines?.map( res => {
      res.selected = false;
    })
    this.selectedLine = new AllowedServices();
  }

  public setListAllowed(allowedServices: AllowedServices[]): void {
    this.listAllowedMsisdn = new Array<string>();
    this.listAllowedCartItemId = new Array<string>();
    allowedServices.filter(service =>
      (service.allowed && service.origin?.toLowerCase() === allowedServicesDef.contract)
    ).forEach(serv => {
      this.listAllowedMsisdn.push(serv.serviceId)
    });
    allowedServices.filter(service =>
      (service.allowed && service.origin?.toLowerCase() === allowedServicesDef.basket)
    ).forEach(serv => {
      this.listAllowedCartItemId.push(serv.itemId.toString());
    });
  }
}

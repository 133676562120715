import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CrossFunctionalityService } from './cross-functionality.service';
import { StorageService } from '../../core/services/storage.service';
import { CustomerType } from '../enums/customerType.enum';
import { SiteStatus } from '../enums/siteStatus.enum';
import * as pagesConfig from './../../../config/pages-config';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AppService } from '../../app.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { ProfileType } from '../enums/profileType.enum';
import { config } from '../../../config/pages-config';
import { UpgradeProfileService } from '../../upgrade-profile/upgrade-profile.service';
import { UtilitiesService } from '../utils/utilities.service';
import { UserProfile } from '../../models/user-profile.model';
import { allowedPendingInstallRoutes } from '../constants/defines';

@Injectable({
  providedIn: 'root',
})
export class SiteHandlingService {
  currentSiteStatus: string;
  currentStatus: string;
  errorDescrption: string;


  constructor(private translate: TranslateService,
    private crossFunctionality: CrossFunctionalityService,
    private router: Router,
    private appService: AppService,
    private storageService: StorageService,
    private subscriptionService: SubscriptionService,
    private utilities: UtilitiesService,
    private upgradeProfileService: UpgradeProfileService,
    ) { }
  nudgeSelector() {
    const userProfile = this.storageService.userProfile
    let currentSiteStatus;
    if (this.currentSiteStatus) {
      currentSiteStatus = this.currentSiteStatus.toLowerCase()
    }
    else {
      if (userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
        currentSiteStatus = this.storageService.userProfile.companies[0].sites[0].status.toLowerCase()
      }
      else {
        currentSiteStatus = this.storageService.userProfile.sites[0].status.toLowerCase()
      }
    }

    this.translate.get('billing').subscribe((data) => {
      this.siteStatusDescription(currentSiteStatus, data)
      if (currentSiteStatus === SiteStatus.Desconectado_No_Pago.toLowerCase()) {
        this.crossFunctionality.secondButtonText = data.messagesList.nudge2.button2.text
        this.crossFunctionality.firstButtonText = data.messagesList.nudge2.button1.text
        this.crossFunctionality.secondButtonAction = () => {
          this.crossFunctionality.showNudge = false;
        }
        this.crossFunctionality.firstButtonAction = () => {
          this.crossFunctionality.showNudge = false;
          this.crossFunctionality.firstButtonAction = null;
          this.router.navigate([pagesConfig.config.billing.billPayment.route]);
        }
      }
      else {
        this.crossFunctionality.showOneButton = true;
        this.crossFunctionality.buttonText = data.messagesList.nudge2.button1.text
      }
    })

  }
  /** Nudge description according to its status */
  siteStatusDescription(currentSiteStatus, data) {
    switch (currentSiteStatus.toLowerCase()) {
      case SiteStatus.Suspendido.toLowerCase():
        this.crossFunctionality.nudgeTitle = data.messagesList.nudge1Suspended.title
        this.crossFunctionality.nudgeDescription = data.messagesList.nudge1Suspended.description
        break;
      case SiteStatus.Pend_de_Reconectar.toLowerCase():
        this.crossFunctionality.nudgeTitle = data.messagesList.nudge1PendingReconnection.title
        this.crossFunctionality.nudgeDescription = data.messagesList.nudge1PendingReconnection.description
        break;
      case SiteStatus.Desconectado.toLowerCase():
        // nudge1
        this.crossFunctionality.nudgeTitle = data.messagesList.nudge16month.title
        this.crossFunctionality.nudgeDescription = data.messagesList.nudge16month.description

        break;
      case SiteStatus.Desconectado_No_Pago.toLowerCase():
        // nudge2
        this.crossFunctionality.nudgeTitle = data.messagesList.nudge2.title
        this.crossFunctionality.nudgeDescription = data.messagesList.nudge2.description
        break;
      default:
        break;
    }

  }
  /** function return true if site status one of priority 2 status */
  isSiteP2() {
    let currentStatus;
    if (this.currentSiteStatus) {
      currentStatus = this.currentSiteStatus.toLowerCase();
    }
    else {
      const userProfile = this.storageService.userProfile
      if (userProfile && userProfile.customerType && userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
        if (userProfile.companies.length === 1 && userProfile.companies[0].sites
          && userProfile.companies[0].sites[0].status) {
          currentStatus = userProfile.companies[0].sites[0].status.toLowerCase()
        }
      }
      else {
        if (userProfile && userProfile.sites && userProfile.sites[0].status) {
          currentStatus = userProfile.sites[0].status.toLowerCase()
        }
      }
    }
    if (currentStatus && (currentStatus === SiteStatus.Suspendido.toLowerCase() ||
      currentStatus === SiteStatus.Pend_de_Reconectar.toLowerCase() ||
      currentStatus === SiteStatus.Desconectado.toLowerCase() ||
      currentStatus === SiteStatus.Desconectado_No_Pago.toLowerCase())) {
      this.currentStatus = currentStatus
      return true
    }
  }

  isSitePendingInstall(): boolean {
    let currentStatus: string;
    const userProfile: UserProfile = this.storageService.userProfile;
    if (userProfile?.customerType?.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
      if (userProfile.companies.length === 1 && userProfile.companies[0].sites?.length > 0) {
        currentStatus = userProfile.companies[0].sites
          .find(site => site.id === (this.subscriptionService.customerData?.customerAccountsId || userProfile.companies[0].sites[0].id))
          ?.status?.toLowerCase();
      }
    } else if (userProfile?.sites?.length > 0) {
      currentStatus = userProfile.sites
        .find(site => site.id === (this.subscriptionService.customerData?.customerAccountsId || userProfile.sites[0].id))
        ?.status?.toLowerCase();
    }
    return (
      currentStatus === SiteStatus.Install_Pend.toLowerCase() ||
      currentStatus === SiteStatus.Pend_de_Instalar.toLowerCase() ||
      currentStatus === SiteStatus.Pend_de_Instalar_Pend.toLowerCase() ||
      currentStatus === SiteStatus.Pending_installation.toLowerCase()
    );
  }
  isSiteSuspended(currentSiteStatus?: string): boolean {
    let currentStatus: string;
    const userProfile: UserProfile = this.storageService.userProfile;
    if (currentSiteStatus) {
      currentStatus = currentSiteStatus;
    }
    else if (userProfile?.customerType?.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
      if (userProfile.companies.length === 1 && userProfile.companies[0].sites?.length > 0) {
        currentStatus = userProfile.companies[0].sites
          .find(site => site.id === (this.subscriptionService.customerData?.customerAccountsId || userProfile.companies[0].sites[0].id))
          ?.status?.toLowerCase();
      }
    } else if (userProfile?.sites?.length > 0) {
      currentStatus = userProfile.sites
        .find(site => site.id === (this.subscriptionService.customerData?.customerAccountsId || userProfile.sites[0].id))
        ?.status?.toLowerCase();
    }
    return (
      currentStatus === SiteStatus.Suspendido.toLowerCase() ||
      currentStatus === SiteStatus.Suspended.toLowerCase() ||
      currentStatus === SiteStatus.Suspending.toLowerCase() );
  }

  isSiteDisconnected(): boolean {
    let currentStatus: string;
    const userProfile: UserProfile = this.storageService.userProfile;
    if (userProfile?.customerType?.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
      if (userProfile.companies.length === 1 && userProfile.companies[0].sites?.length > 0) {
        currentStatus = userProfile.companies[0].sites
          .find(site => site.id === (this.subscriptionService.customerData?.customerAccountsId || userProfile.companies[0].sites[0].id))
          ?.status?.toLowerCase();
      }
    } else if (userProfile?.sites?.length > 0) {
      currentStatus = userProfile.sites
        .find(site => site.id === (this.subscriptionService.customerData?.customerAccountsId || userProfile.sites[0].id))
        ?.status?.toLowerCase();
    }
    return (
      currentStatus === SiteStatus.Desconectado.toLowerCase() ||
      currentStatus === SiteStatus.Disconnected.toLowerCase() ||
      currentStatus === SiteStatus.Disconnect_Pend.toLowerCase() );
  }
  showErrorNudge() {
    if (this.isSiteP2()) {
      this.appService.showBackButton = false;
      this.crossFunctionality.showNudge = true;
      this.nudgeSelector();

    }
  }
  clearData() {
    this.crossFunctionality.showNudge = false;
    this.crossFunctionality.showOneButton = false;
    this.appService.showBackButton = true;
  }
  getCurrentSiteStatus(): SiteStatus {
    if (this.storageService.userProfile) {
      if (this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
        return this.getCurrentSiteStatusForAuthorizedUser();
      } else {
        return this.getCurrentSiteStatusForRegularUser() as SiteStatus;
      }
    }
    return SiteStatus.None;
  }

  private getCurrentSiteStatusForRegularUser() {
    if (this.storageService.userProfile.sites
      && this.storageService.userProfile.sites.length > 0) {
      if (this.subscriptionService.customerData && this.subscriptionService.customerData.currentService) {
        const currentSite = this.storageService.userProfile.sites.find(site => {
          return site.id === this.subscriptionService.customerData.currentService.siteId;
        });
        if (currentSite) {
          return currentSite ? currentSite.status : '';
        }
        return '';
      } else {
        return this.storageService.userProfile.sites[0] ? this.storageService.userProfile.sites[0].status : '';
      }
    }
  }

  private getCurrentSiteStatusForAuthorizedUser() {
    if (this.storageService.userProfile.companies
      && this.storageService.userProfile.companies.length > 0) {
      if (this.subscriptionService.customerData && this.subscriptionService.customerData.currentService) {
        let site;
        this.storageService.userProfile.companies.forEach(company => {
          let currentSite;
          if (company.sites) {
            currentSite = company.sites.find(site => {
              return site.id === this.subscriptionService.customerData.currentService.siteId;
            });
          }
          if (currentSite) {
            site = currentSite;
          }
        });
        if (site) {
          return site.status;
        }
        return '';
      } else {

        return this.storageService.userProfile.companies[0] &&
          this.storageService.userProfile.companies[0].sites &&
          this.storageService.userProfile.companies[0].sites[0] ? this.storageService.userProfile.companies[0].sites[0].status : '';
      }
    }
    return '';
  }

  public handelPendingInstallationCase(nextPage?: ActivatedRouteSnapshot): boolean {
    if (this.storageService.userProfile?.profileType?.toLowerCase() === ProfileType.LIGHT.toLowerCase()) {
      this.upgradeProfileService.startScenario = true;
      this.upgradeProfileService.targetRoute = config.login.orderMangment.route;
      this.appService.showFullAppLoader = false;
      return false;
    }
    else if (allowedPendingInstallRoutes.includes(this.utilities.getResolvedUrlFromRoute(nextPage))) {
      if (this.router.url === '/' && !this.storageService.customerAccountsLoaded) {
        // If it's first navigation, redirect to splash to correctly load data
        this.router.navigate([config.splash.route]);
      }
      return true;
    }
    else {
      this.router.navigate([config.dashboard.route]);
      this.appService.showFullAppLoader = false;
      return false;
    }
  }

}


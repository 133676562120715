/* Constants to obtain payments JSONs information */
export const FOLDER_PENDING_PAYMENTS = 'payments/pending-payments';
export const FOLDER_MY_PAYMENT_METHODS = 'payments/my-payment-methods';
export const FOLDER_FINANCED_DEVICES = 'payments/financed-devices';
export const FOLDER_ANONYMOUS_PAYMENT_IDENTIFICATION = 'payments/anonymous-payment-identification';
export const FOLDER_ANONYMOUS_PAYMENT_MY_PAYMENTS = 'payments/anonymous-payment-my-payments';
export const ANONYMOUS_IDENTIFICATION_EVENT_CONTEXT = 'pago anonimo';
export const FOLDER_MY_PAYMENTS: string = 'payments/my-payment';

export const PAYMENTS_TRACKING = {
    PENDING_PAYMENTS: {
        /* STATES */
        STATES: {},
        /* INTERACTIONS */
        INTERACTIONS: {
            CLICK_ON_CHECK_PAYMENTS_BY_SITE: 'click_on_check_payments_by_site',
            CLICK_ON_CLOSE: 'click_on_close',
            CLICK_ON_TO_PAY: 'click_on_to_pay'
        }
    },
    MY_PAYMENT_METHODS: {
        /* STATES */
        STATES: {
            EDIT_BANK_DATA: 'edit_bank_data',
            EDIT_BANK_DATA_VERIFY_ACCOUNT: 'edit_bank_data_verify_account',
            EDIT_BANK_DATA_SMS_CONFIRMATION: 'edit_bank_data_sms_confirmation',
            EDIT_BANK_DATA_OK: 'edit_bank_data_ok',
            EDIT_BANK_DATA_OK_NEW_ACCOUNT: 'edit_bank_data_ok_new_account',
            EDIT_BANK_DATA_OK_OLD_ACCOUNT: 'edit_bank_data_ok_old_account',
            EDIT_BANK_DATA_OK_UNKNOWN_ACCOUNT: 'edit_bank_data_ok_unknown_account',
            EDIT_BANK_DATA_KO: 'edit_bank_data_ko',
            EDIT_SAVED_CARDS: 'edit_saved_cards',
            SAVED_CARDS_CONFIRM_DELETE_CARD: 'saved_cards_confirm_delete_card'
        },
        /* INTERACTIONS */
        INTERACTIONS: {
            CLICK_ON_SAVED_CARDS: 'click_on_saved_cards',
            SAVED_CARDS_CLICK_ON_USE_AS_MAIN: 'saved_cards_click_on_use_as_main',
            SAVED_CARDS_CLICK_ON_DELETE: 'saved_cards_click_on_delete',
            SAVED_CARDS_CONFIRM_DELETE_CARD_CLICK_ON_DELETE: 'saved_cards_confirm_delete_card_click_on_delete',
            SAVED_CARDS_CONFIRM_DELETE_CARD_CLICK_ON_CANCEL: 'saved_cards_confirm_delete_card_click_on_cancel'
        }
    },
    FINANCED_DEVICES: {
        /* STATES */
        STATES: {
            CANCELLED: 'cancelled',
            ACTIVE_FINANCING: 'active_financing',
            ACTIVE_FINANCING_PAYMENT_METHOD: 'active_financing_payment_method',
            ACTIVE_FINANCING_PAYMENT_PRECONFIRMATION: 'active_financing_payment_preconfirmation',
            ACTIVE_FINANCING_PAYMENT_QUANTITY: 'active_financing_payment_quantity',
            ACTIVE_FINANCING_CARD_SELECTION: 'active_financing_card_selection',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD: 'active_financing_payment_with_credit_card',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_OK: 'active_financing_payment_with_credit_card_ok',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO: 'active_financing_payment_with_credit_card_ko',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO_REFUND_SUCCESS: 'active_financing_payment_with_credit_card_ko_refund_sucess',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO_REFUND_FAILED: 'active_financing_payment_with_credit_card_ko_refund_failed',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO_REFUND_OPERATION_NOT_EXIST: 'active_financing_payment_with_credit_card_ko_refund_operation_not_exist',
            ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_PAYMENT_TICKET: 'active_financing_payment_with_credit_card_payment_ticket'
        },
        /* INTERACTIONS */
        INTERACTIONS: {
            CLICK_ON_SEE_DETAILS: 'click_on_see_details',
            CLICK_ON_CLOSE_DETAILS: 'click_on_close_details',
            CLICK_ON_MODIFY_FINANCIATION: 'click_on_modify_financiation',
        }
    },
    ANONYMOUS_IDENTIFICATION: {
        /* STATES */
        STATES: {
            WRONG_DATA: 'wrong_data',
            CLARIFY_USERS: 'clarify_users',
            SECURITY_CODE: 'security_code',
            SECURITY_CODE_MAX_NUMBER_ATTEMPTS_EXCEDED: 'security_code_max_number_attempts_exceded',
            SECURITY_CODE_MAX_NUMBER_CLICKS_EXCEDED: 'security_code_max_number_clicks_exceded',
            SECURITY_CODE_KO: 'security_code_ko',
            DEBT_CONSULTATION: 'debt_consultation',
            DEBT_CONSULTATION_KO: 'debt_consultation_ko',
            DEBT_CONSULTATION_NOT_EXIST_DEBT: 'debt_consultation_not_exist_debt'
        },
        /* INTERACTIONS */
        INTERACTIONS: {
            CLICK_ON_CONTINUE: 'click_on_continue',
            CLICK_ON_COPIAR_IBAN: 'click_on_copiar_iban'
        }
    },
    MY_PAYMENT: {
        /* INTERACTIONS */
        INTERACTIONS: {
            CLICK_ON_FINANCED_COMPLETED_FILTER: 'click_on_financed_completed_filter',
        }
    },
}

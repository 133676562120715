import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { Build, CarrouselDslModel, ConfigResponsive, Responsive } from '../../../models/carrousel-dsl.model';
import { CarouselDMP, DMPReingeeneeringModel, DMPReingeeneeringModelDefaultState } from '../../../models/dmp.model';
import { S4UEPinfo } from '../../../models/S4U-EP-info.model';
import { lightMode, stringOfTrue, widowOpenBlank } from '../../constants/defines';
import { DmpCardTagging } from '../../models/dmpCardTagging.model';

@Component({
  selector: 'sp-dmp-mapper',
  templateUrl: './dmp-mapper.component.html'
})
export class DmpMapperComponent implements OnInit {
  @Input() dmpObject: DMPReingeeneeringModel;
  @Input() dmpArray: DMPReingeeneeringModel[] = [];
  @Input() configCarousel: CarouselDMP;
  @Output() taggingClick: EventEmitter<DmpCardTagging> = new EventEmitter<DmpCardTagging>();
  @Output() taggingSwipe: EventEmitter<DMPReingeeneeringModel> = new EventEmitter<DMPReingeeneeringModel>();
  @Output() mapperRespArray: EventEmitter<DMPReingeeneeringModel[]> = new EventEmitter<DMPReingeeneeringModel[]>();
  @Output() mapperResp: EventEmitter<DMPReingeeneeringModel> = new EventEmitter<DMPReingeeneeringModel>();

  public carouselConfig: string;
  public selectedCarouselDMPItems: DMPReingeeneeringModel[] = [];
  public isCarousel: boolean;
  public isUniqueCard: boolean;
  public darkMode: boolean[] = [];
  public darkModeUnique: boolean;
  public showUniqueCard: boolean;
  constructor (
    private router: Router,
    public translate: TranslateService,
    public appService: AppService,
    ) {}

  ngOnInit(): void {
    const isReingeeneeringArray: boolean = !!this.dmpArray?.find( element => (element.code !== undefined));
    const isReingeeneeringUnique: boolean = (this.dmpObject?.code !== undefined);
    if (isReingeeneeringArray) {
      this.dmpArray = this.dmpArray?.map((element: DMPReingeeneeringModel) => this.mapWCSKeys(element));
      this.isUniqueCard = false;
      this.dmpArray = this.dmpArray?.filter(res => res.title !== '');
      this.mapperRespArray.emit(this.dmpArray);
    } else if (isReingeeneeringUnique) {
      this.dmpObject = this.mapWCSKeys(this.dmpObject);
      this.isUniqueCard = true;
      this.showUniqueCard = (this.dmpObject.title !== '');
      this.mapperResp.emit(this.dmpObject);
    }
    else if (!isReingeeneeringArray) {
      this.isUniqueCard = false;
      this.mapperRespArray.emit(this.dmpArray);
    }
    else if (!isReingeeneeringUnique) {
      this.isUniqueCard = true;
      this.mapperResp.emit(this.dmpObject);
    }
    this.carouselConfig = JSON.stringify(this.carouselConfiguration());
    this.isCarousel = (!!this.configCarousel);
    this.darkMode = this.dmpArray.map(elem => (elem.mode !== lightMode) || (!elem.mode));
    this.darkModeUnique = (this.dmpObject?.mode !== lightMode) || (!this.dmpObject.mode);
  }

  public carouselConfiguration(): CarrouselDslModel {
    const carrouselDslModel: CarrouselDslModel = new CarrouselDslModel();

    carrouselDslModel.build = new Build();

    carrouselDslModel.build.clickable_bullets = this.configCarousel?.clickable_bullets;
    carrouselDslModel.build.bullets = this.configCarousel?.bullets;
    carrouselDslModel.build.arrows = this.configCarousel?.arrows;

    carrouselDslModel.responsive = new Responsive();

    carrouselDslModel.responsive[0] = new ConfigResponsive();
    carrouselDslModel.responsive[0].breakpoint = this.configCarousel?.breakpooint;
    carrouselDslModel.responsive[0].elements = this.configCarousel?.elements;

    return carrouselDslModel;
  }

  public carrouselCardChange(e: { position: number }, dmp: DMPReingeeneeringModel[]): void {
    this.taggingSwipe.emit(dmp[e.position]);
  }

  buildDeeplinkNavigate(dmp: DMPReingeeneeringModel , index?: number): void {
    if (dmp.openWebView === stringOfTrue) {
      window.open(dmp.deeplink, widowOpenBlank);
    }
    else {
      if (dmp.deeplink && dmp.deeplink.split('?')[1]) {
        const params: [] = [];
        params[dmp.deeplink.split('?')[1].split('=')[0]] = dmp.deeplink.split('?')[1].split('=')[1];
        this.router.navigate([dmp.deeplink.split('?')[0]], { queryParams: params });
      } else if (dmp.deeplink) {
        const url: string = dmp.deeplink.replace('https:/', '').trim();
        this.router.navigate([url]);
      }
    }
    const paramsTagging: DmpCardTagging = {
      dmpInfo: dmp,
      position: index
    }
    this.taggingClick.emit(paramsTagging);
  }

  public mapWCSKeys(dmp: DMPReingeeneeringModel): DMPReingeeneeringModel {
    const codeWCS: string = dmp?.code;
    const DMPReingeeneeringResp: DMPReingeeneeringModel = {
      ...DMPReingeeneeringModelDefaultState,
      code: codeWCS
    }
    this.translate.get('v10').subscribe({
      next: ({ flows }) => {
        const wcsData: S4UEPinfo = flows.customization[codeWCS];
        DMPReingeeneeringResp.image = this.appService.getImgFullPath(wcsData.S4U.DS1.image);
        DMPReingeeneeringResp.image_rsp = this.appService.getImgFullPath(wcsData.S4U.DS1.image_rsp);
        DMPReingeeneeringResp.mode = wcsData.S4U.DS1.mode;
        DMPReingeeneeringResp.title = wcsData.S4U.DS1.title;
        DMPReingeeneeringResp.text = wcsData.S4U.DS1.text;
        DMPReingeeneeringResp.textCTA = wcsData.S4U.DS1.textCTA;
        DMPReingeeneeringResp.deeplink = wcsData.S4U.DS1.deeplink;
        DMPReingeeneeringResp.experienceName = wcsData.S4U.DS1.experienceName;
        DMPReingeeneeringResp.journeyName = wcsData.S4U.DS1.journeyName;
        DMPReingeeneeringResp.entryPointLocation = wcsData.S4U.DS1.entryPointLocation;
        DMPReingeeneeringResp.openWebView = wcsData.S4U.DS1.openWebView;
      }});
      return DMPReingeeneeringResp;
  }
}

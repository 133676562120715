import { CampaignOverlayComponent } from './components/campaign-overlay/campaign-overlay.component';
import { BannerCardComponent } from './components/banner-card/banner-card.component';
import { WhatIncludeComponent } from './components/product-description/what-include/what-include.component';
import { PriceToShowModule } from './pipes/price-to-show/price-to-show.module';
import { SpToggleButtonModule } from './components/sp-toggle-button/sp-toggle-button.module';
import { SliceCurrencyModule } from './pipes/slice-currency/slice-currency.module';
import { ImgFullPathModule } from './pipes/img-full-path/img-full-path.module';
import { NexusOpComponent } from './../nexus/nexus-op/nexus-op.component';
import { SpMva10CtcOverlayComponent } from './components/sp-mva10-ctc-overlay/sp-mva10-ctc-overlay.component';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { SpJumbotronNavigatorComponent } from './components/sp-jumbotron-navigator/sp-jumbotron-navigator.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SpDropdownComponent } from './components/sp-dropdown/sp-dropdown.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TranslateModule } from '@ngx-translate/core';
import { SpModalComponent } from './components/sp-modal/sp-modal.component';
import { SpModalFullComponent } from './components/sp-modal-full/sp-modal-full.component';
import { SpRadioButtonComponent } from './components/sp-radio-button/sp-radio-button.component';
import { SpSidemenuComponent } from './components/sp-sidemenu/sp-sidemenu.component';
import { SpAccordionComponent } from './components/sp-accordion/sp-accordion.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';
import { SpPasswordValidationService } from './components/sp-password/sp-password-validation.service';
import { ConvertToAsterisksPipe } from './pipes/convert-to-asterisks.pipe';
import { LandingPercentageComponent } from './components/landing-percentage/landing-percentage.component';
import { SpEnjoyMoreNavigatorComponent } from './components/sp-enjoy-more-navigator/sp-enjoy-more-navigator.component';
import { MyDataFormatePipe } from './pipes/my-data-formate.pipe';
import { UtilitiesService } from './utils/utilities.service';
import { AlertInfoComponent } from './components/alert-info/alert-info.component';
import { SliceCurrencyRoundZeroPipe } from './pipes/slice-currency-round-zero.pipe';
import { ToastComponent } from './widget/toast/toast.component';
import { SpToggleDropdownComponent } from './components/sp-toggle-dropdown/sp-toggle-dropdown.component';
import { TruncatePipe } from '../shared/pipes/truncate-string.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { SpDoubleToggleDropdownComponent } from './components/sp-double-toggle-dropdown/sp-double-toggle-dropdown.component';
import { SecondsToTimePipe } from './pipes/secondsToTime.pipe';
import 'hammerjs';
import 'hammer-timejs';
import moment from 'moment';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { FullWidthSliderComponent } from './components/full-width-slider/full-width-slider.component';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { UnavilabilityModalComponent } from './widget/unavilability-modal/unavilability-modal.component';
import { ArraySortPipe } from './pipes/sort.pipe';
import { LandingDataBundleComponent } from './widget/landing-data-bundle/landing-data-bundle.component';
import { LandingBundleContainerDirective } from './directives/landing-bundle-container.directive';
import { LandingEnjoymoreBundleComponent } from './widget/landing-enjoymore-bundle/landing-enjoymore-bundle.component';
import { LandingFixedToFixedBundleComponent } from './widget/landing-fixed-to-fixed--bundle/landing-fixed-to-fixed--bundle.component';
import { LandingFixedToMobileBundleComponent } from './widget/landing-fixed-to-mobile-bundle/landing-fixed-to-mobile-bundle.component';
import { LandingFixedToInternationalBundleComponent } from './widget/landing-fixed-to-international-bundle/landing-fixed-to-international-bundle.component';
import { LandingMinutesBundleComponent } from './widget/landing-minutes-bundle/landing-minutes-bundle.component';
import { LandingSmsBundleComponent } from './widget/landing-sms-bundle/landing-sms-bundle.component';
import { DotPipe } from './pipes/dot.pipe';
import { DestinyService } from './services/destiny.service';
import { SafePipe } from './pipes/safe-sanitizer.pipe';
import { GdprModalComponent } from './widget/gdpr-modal/gdpr-modal.component';
import { CategorizeByPipe } from './pipes/categorize-by.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterTabsPipe } from './pipes/filter-tabs.pipe';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';
import { SpOverlayComponent } from './components/sp-overlay/sp-overlay.component';

import { SpMva10OverlayComponent } from './components/sp-mva10-overlay/sp-mva10-overlay.component';
import { ModalMessagesComponent } from './components/modal-messages/modal-messages.component';
import { SpMva10ServiceSelectorComponent } from './sp-mva10-service-selector/sp-mva10-service-selector.component';
import { Mva10FullOverlayComponent } from './components/mva10-full-overlay/mva10-full-overlay.component';
import { SpActivationToggleCardComponent } from './widget/sp-activation-toggle-card/sp-activation-toggle-card.component';
import { BannerAppFromWebComponent } from './widget/banner-app-from-web/banner-app-from-web.component';

import { Mva10AmountSelectorComponent } from './components/mva10-amount-selector/mva10-amount-selector.component';
import { Mva10TopupLandingWithPciComponent } from '../top-up/mva10-topup-landing-with-pci/mva10-topup-landing-with-pci.component';
import { Mva10TopupIframeEnablementComponent } from '../top-up/mva10-topup-iframe-enablement/mva10-topup-iframe-enablement.component';
import { LottieLoaderComponent } from './components/lottie-loader/lottie-loader.component';
import { TopUpConfirmationOverlayComponent } from '../top-up/top-up-confirmation-overlay/top-up-confirmation-overlay.component';
import { TopUpGeneralErrorOverlayComponent } from '../top-up/top-up-general-error-overlay/top-up-general-error-overlay.component';
import { GenericAccordionComponent } from './components/generic-accordion/generic-accordion.component';
import { GenericNavigatorComponent } from './components/generic-navigator/generic-navigator.component';

import { MsisdnToShowPipe } from './pipes/msisdn-to-show.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MyDateAdapter } from '../new-lines/my-date-adapter';
import { SpActivationToggleCardMva10Component } from './widget/sp-activation-toggle-card-mva10/sp-activation-toggle-card-mva10.component';
import { SpToggleButtonMva10Component } from './components/sp-toggle-button-mva10/sp-toggle-button-mva10.component';
import { RrssComponent } from './widget/rrss/rrss.component';
import { TooltipComponent } from './widget/tooltip/tooltip.component';
import { TopUpPaymentTicketComponent } from '../top-up/top-up-payment-ticket/top-up-payment-ticket.component';
import { ResizeListenerComponent } from '../mva10/shared/components/resize-listener/resize-listener.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EntryPointsManagementComponent } from './components/entry-points-management/entry-points-management.component';
import { SpDeliveryTypesV2Component } from './components/sp-delivery-types-v2/sp-delivery-types-v2.component';
import { PaymentCardsService } from './services/payment-cards.service';
import { TopUpCardWalletScreenComponent } from '../top-up/top-up-card-wallet-screen/top-up-card-wallet-screen.component';
import { SpWalletCardComponent } from './components/sp-wallet-card/sp-wallet-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { GeneralErrorTrayComponent } from './widget/general-error-tray/general-error-tray.component';
import { Mva10TimeLineComponent } from './components/mva10-time-line/mva10-time-linecomponent';
import { CurrentComsuptionComponent } from './components/current-comsuption/current-comsuption.component';
import {
  SelectorModule,
  OverlayModule,
  GenericModule,
  CardProductModule,
  OverlayFullScreenModule,
  GreetingModule,
  DataUsageModule,
  ProgressListModule,
  SelectorBadgeModule
} from '@mva10/mva10-angular';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { SubmenuMenuComponent } from './components/submenu-menu/submenu-menu.component';
import { SubmenuProductsComponent } from './components/submenu-products/submenu-products.component';
import { CommercialPromotionsComponent } from './widget/commercial-promotions/commercial-promotions.component';
import { Mva10FullModalErrorComponent } from './components/mva10-full-modal-error/mva10-full-modal-error.component';
import { DynamicTrayComponent } from './components/dynamic-tray/dynamic-tray.component';
import { CommercialConfirmationClose } from './services/commercial-confirmation-close.service';
import { ToggleCardComponent } from './components/toggle-card/toggle-card.component';
import { Mva10SubHeaderComponent } from './components/mva10-sub-header/mva10-sub-header.component';
import { BackgroundHeaderAndIconComponent } from './components/background-header-and-icon/background-header-and-icon.component';
import { SuccessErrorTrayComponent } from './components/success-error-tray/success-error-tray.component';
import { LabelNbaComponent } from './components/label-nba/label-nba.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { PayDebtOtherPaymentMethodsComponent } from '../billing/pay-debt-other-payment-methods/pay-debt-other-payment-methods.component';
import { ExpandableChevronComponent } from './components/expandable-chevron/expandable-chevron.component';
import { SpFixedBottomButtonComponent } from './components/sp-fixed-bottom-button/sp-fixed-bottom-button.component';
import { SpPurchasingComponent } from './components/sp-purchasing/sp-purchasing.component';
import { LongPressDirective } from './directives/long-press.directive';
import { TrayLoadingModeComponent } from './components/tray-loading-mode/tray-loading-mode.component';
import { SubmenuAccountComponent } from './components/submenu-account/submenu-account.component';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { SpCalenderComponent } from './components/sp-mat-calender/sp-mat-calendercomponent';
import { FailureComponent } from './components/failure/failure.component';
import { SearchAutocompleteComponent } from './components/search-autocomplete/search-autocomplete.component';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { PaymentDeptComponent } from '../payment/payment-entry-points/payment-dept/payment-dept.component';
import { PaymentClarifyUserComponent } from '../payment/payment-clarify-user/payment-clarify-user.component';
import { PaymentCashDeskUserComponent } from '../payment/payment-entry-points/payment-cash-desk-user/payment-cash-desk-user.component';
import { PaymentInadvanceUserComponent } from '../payment/payment-entry-points/payment-inadvance-user/payment-inadvance-user.component';
import { PaymentTicketComponent } from '../buy-sim/payment-ticket/payment-ticket.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CreditCardChevronComponent } from '../billing/credit-card-chevron/credit-card-chevron.component';
import { CancelPaymentComponent } from '../billing/cancel-payment/cancel-payment.component';
import { CurrentBonosComponent } from './components/current-bonos/current-bonos.component';
import { NewSiteSelectorComponent } from '../billing/new-site-selector/new-site-selector.component';
import { SpGenericTicketComponent } from './components/sp-generic-ticket/sp-generic-ticket.component';
import { PendingInstallWarningComponent } from './components/pending-install-warning/pending-install-warning.component';
import { PayWithOtherPaymentMethodsComponent } from '../payment/pay-with-other-payment-methods/pay-with-other-payment-methods.component';
import { PaymentDeleteCardConfiramtionComponent } from '../payment/payment-delete-card-confiramtion/payment-delete-card-confiramtion.component';

import { PaymentWalletChevronComponent } from '../payment/payment-wallet-chevron/payment-wallet-chevron.component';
import { MonthlyFeePipe } from './pipes/monthly-fee.pipe';
import { TotalDebtPaymentModalComponent } from './widget/total-debt-payment-modal/total-debt-payment-modal.component';
import { TooltipModalComponent } from './widget/tooltip-modal/tooltip-modal.component';
import { GenericFlipCardComponent } from './components/generic-flip-card/generic-flip-card.component';
import { EditBankAccountOverlayComponent } from './widget/edit-bank-account-overlay/edit-bank-account-overlay.component';
import { ExclusiveOfferStampComponent } from './components/exclusive-offer-stamp/exclusive-offer-stamp.component';
import { PaymentFullDynamicTrayComponent } from './../payment/payment-full-dynamic-tray/payment-full-dynamic-tray.component';
import { FormatNumberPricePipe } from './pipes/format-number-price.pipe';
import { TopTileComponent } from './components/top-tile/top-tile.component';
import { CountDownFormatPipe } from './pipes/count-down-format.pipe';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { MoreInfoTrayComponent } from './components/sp-more-info-tray/sp-more-info-tray.component';
import { SharedServicesPegaModule } from '@shared/services/pega';
import { TileImageCtaComponent } from './components/tile-image-cta/tile-image-cta.component';

import { LogoutOverlayComponent } from './components/logout-overlay/logout-overlay/logout-overlay.component';

import { TariffChangeHeaderComponent } from '../products-and-services/tariff-change/tariff-change-header/tariff-change-header.component';
import { TarrifCardInfoComponent } from '../products-and-services/tariff-change/tarrif-card-info/tarrif-card-info.component';
import { SuperWifiVerifyEmailComponent } from '../products-and-services/new-super-wifi/super-wifi-verify-email/super-wifi-verify-email.component';
import { ShimmerLoaderComponent } from '../products-and-services/new-super-wifi/shimmer-loader/shimmer-loader.component';
import { XrContentsComponent } from './components/xr-contents/xr-contents.component';
import { TradeinDeeplinkComponent } from './components/tradein-deeplink/tradein-deeplink.component';

import { PaymentCardComponent } from '../payment/payment-entry-points/payment-card/payment-card.component';
import { SavingsComponent } from './components/savings/savings.component';
import { FullOverlayComponent } from './components/full-overlay/full-overlay.component';
import { SmartphoneRaffleComponent } from './components/smartphone-raffle/smartphone-raffle.component';
import { NbaTradeUpTarifaComponent } from './components/nba-trade-up-tarifa/nba-trade-up-tarifa.component';
import { FullOverLayPageComponent } from './components/full-over-lay-page/full-over-lay-page.component';
import { NewSmartPayLandingComponent } from '../smart-pay/new-smart-pay-landing/new-smart-pay-landing.component';
import { IconNavigationCardComponent } from './components/icon-navigation-card/icon-navigation-card.component';
import { EliminateZerosPipe } from './pipes/eliminate-zeros.pipe';
import { HeaderWithClosebuttonComponent } from './components/header-with-closebutton/header-with-closebutton.component';
import { SpTextModule } from './components/sp-text/sp-text.module';
import { SpTextAreaModule } from './components/sp-text-area/sp-text-area.module';
import { AdaraSelectorModule } from './components/adara-selector/adara-selector.module';
import { FullOverlayIframeComponent } from './components/full-overlay-iframe/full-overlay-iframe.component';
import { EnglishModalComponent } from './widget/english-modal/english-modal.component';
import { BackdropChooserComponent } from '../products-and-services/backdrop-chooser/backdrop-chooser.component'
import { PriceHikeAlertCardComponent } from './components/price-hike-alert-card/price-hike-alert-card.component';
import { SpErrorMessageModule } from './components/sp-error-message/sp-error-message.module';
import { SpSwiperModule } from './components/sp-swiper/sp-swiper.module';
import { LandingDataBundleForPrepaidComponent } from './widget/landing-data-bundle-for-prepaid/landing-data-bundle-for-prepaid.component';
import { LandingPrepaidFixedToMobileBundleComponent } from './widget/landing-prepaid-fixed-to-mobile-bundle/landing-prepaid-fixed-to-mobile-bundle.component';
import { LandingPrepaidSmsBundleComponent } from './widget/landing-prepaid-sms-bundle/landing-prepaid-sms-bundle.component';
import { LandingPrepaidEnjoymoreBundleComponent } from './widget/landing-prepaid-enjoymore-bundle/landing-prepaid-enjoymore-bundle.component';
import { DmpMapperComponent } from './components/dmp-mapper/dmp-mapper.component';
import { PickerItemComponent } from './components/picker-item/picker-item.component';
import { SubmenuShopSelectorComponent } from './components/submenu-shop-selector/submenu-shop-selector.component';
import { NbaPegaPlatinumOfferComponent } from './components/nba-pega-platinum-offer/nba-pega-platinum-offer.component';
import { PLPRevampCardComponent } from './components/plp-revamp-card/plp-revamp-card.component';
import { PromoBannerLayoutComponent } from './components/promo-banner-layout/promo-banner-layout.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory(): LottiePlayer {
  return player;
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): HammerManager {
    const mc: HammerManager = new Hammer(element, {
      touchAction: 'none'
    });
    return mc;
  }
}

@NgModule({
  imports: [
    MatProgressBarModule,
    HammerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    TranslateModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    RouterModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    SwiperModule,
    TooltipModule.forRoot(),
    AngularSvgIconModule,
    MatDatepickerModule,
    MatRadioModule,
    MatIconModule,
    MatProgressSpinnerModule,
    GenericModule,
    MatNativeDateModule,
    OverlayModule,
    MatSlideToggleModule,
    MatMenuModule,
    GreetingModule,
    SelectorBadgeModule,
    SelectorModule,
    CardProductModule,
    OverlayFullScreenModule,
    LottieModule.forRoot({ player: playerFactory }),
    CardProductModule,
    DataUsageModule,
    ProgressListModule,
    SharedServicesPegaModule,
    MatProgressBarModule,
    SpTextModule,
    SpTextAreaModule,
    ImgFullPathModule,
    SliceCurrencyModule,
    SpToggleButtonModule,
    PriceToShowModule,
    AdaraSelectorModule
  ],
  declarations: [
    SpModalComponent,
    SpModalFullComponent,
    SpRadioButtonComponent,
    SpDropdownComponent,
    SpSidemenuComponent,
    SpAccordionComponent,
    ConvertToAsterisksPipe,
    SpJumbotronNavigatorComponent,
    LandingPercentageComponent,
    SpEnjoyMoreNavigatorComponent,
    SpJumbotronNavigatorComponent,
    AlertInfoComponent,
    TruncatePipe,
    EliminateZerosPipe,
    MyDataFormatePipe,
    ToastComponent,
    SpToggleDropdownComponent,
    OrderByDatePipe,
    FullWidthSliderComponent,
    SpDoubleToggleDropdownComponent,
    SecondsToTimePipe,
    HtmlToTextPipe,
    UnavilabilityModalComponent,
    ArraySortPipe,
    LandingDataBundleComponent,
    LandingBundleContainerDirective,
    LandingEnjoymoreBundleComponent,
    LandingFixedToFixedBundleComponent,
    LandingFixedToMobileBundleComponent,
    LandingFixedToInternationalBundleComponent,
    LandingMinutesBundleComponent,
    LandingSmsBundleComponent,
    DotPipe,
    GdprModalComponent,
    CategorizeByPipe,
    FilterPipe,
    FilterTabsPipe,
    SafePipe,
    BlockCopyPasteDirective,
    SpOverlayComponent,
    SpMva10OverlayComponent,
    SliceCurrencyRoundZeroPipe,
    ModalMessagesComponent,
    NexusOpComponent,
    Mva10FullOverlayComponent,
    FormatNumberPricePipe,
    ResizeListenerComponent,
    SpActivationToggleCardComponent,
    BannerAppFromWebComponent,
    Mva10AmountSelectorComponent,
    SpMva10ServiceSelectorComponent,
    Mva10TopupLandingWithPciComponent,
    Mva10TopupIframeEnablementComponent,
    LottieLoaderComponent,
    TopUpConfirmationOverlayComponent,
    TopUpGeneralErrorOverlayComponent,
    TopUpCardWalletScreenComponent,
    GenericAccordionComponent,
    GenericNavigatorComponent,
    TopUpPaymentTicketComponent,
    SpActivationToggleCardMva10Component,
    SpToggleButtonMva10Component,
    RrssComponent,
    TooltipComponent,
    TooltipModalComponent,
    CurrentComsuptionComponent,
    CurrentBonosComponent,
    LeftMenuComponent,
    SubmenuComponent,
    SubmenuMenuComponent,
    SubmenuProductsComponent,
    SubmenuAccountComponent,
    MsisdnToShowPipe,
    CommercialPromotionsComponent,
    EntryPointsManagementComponent,
    Mva10FullModalErrorComponent,
    DynamicTrayComponent,
    SpDeliveryTypesV2Component,
    Mva10TimeLineComponent,
    SpCalenderComponent,
    ToggleCardComponent,
    Mva10SubHeaderComponent,
    PaymentDeptComponent,
    PaymentClarifyUserComponent,
    PaymentCashDeskUserComponent,
    PaymentInadvanceUserComponent,
    PayWithOtherPaymentMethodsComponent,
    PaymentDeleteCardConfiramtionComponent,
    BackgroundHeaderAndIconComponent,
    SuccessErrorTrayComponent,
    CreditCardChevronComponent,
    PaymentWalletChevronComponent,
    PayDebtOtherPaymentMethodsComponent,
    SpWalletCardComponent,
    LabelNbaComponent,
    FormatDatePipe,
    SpinnerLoaderComponent,
    ReplacePipe,
    ExpandableChevronComponent,
    SpFixedBottomButtonComponent,
    SpPurchasingComponent,
    LongPressDirective,
    TrayLoadingModeComponent,
    SpMva10CtcOverlayComponent,
    FailureComponent,
    SearchAutocompleteComponent,
    PaymentTicketComponent,
    CancelPaymentComponent,
    PendingInstallWarningComponent,
    NewSiteSelectorComponent,
    SpGenericTicketComponent,
    MonthlyFeePipe,
    TotalDebtPaymentModalComponent,
    GenericFlipCardComponent,
    EditBankAccountOverlayComponent,
    ExclusiveOfferStampComponent,
    PaymentFullDynamicTrayComponent,
    TopTileComponent,
    PromotionsComponent,
    CountDownFormatPipe,
    MoreInfoTrayComponent,
    TileImageCtaComponent,
    LogoutOverlayComponent,
    TariffChangeHeaderComponent,
    TarrifCardInfoComponent,
    SuperWifiVerifyEmailComponent,
    BackdropChooserComponent,
    ShimmerLoaderComponent,
    PaymentCardComponent,
    XrContentsComponent,
    GeneralErrorTrayComponent,
    TradeinDeeplinkComponent,
    SavingsComponent,
    FullOverlayComponent,
    SmartphoneRaffleComponent,
    NbaTradeUpTarifaComponent,
    FullOverLayPageComponent,
    NewSmartPayLandingComponent,
    IconNavigationCardComponent,
    HeaderWithClosebuttonComponent,
    FullOverlayIframeComponent,
    WhatIncludeComponent,
    BannerCardComponent,
    CampaignOverlayComponent,
    LandingDataBundleForPrepaidComponent,
    LandingPrepaidFixedToMobileBundleComponent,
    LandingPrepaidSmsBundleComponent,
    LandingPrepaidEnjoymoreBundleComponent,
    OnboardingComponent,
    EnglishModalComponent,
    NbaPegaPlatinumOfferComponent,
    PriceHikeAlertCardComponent,
    DmpMapperComponent,
    PickerItemComponent,
    SubmenuShopSelectorComponent,
    NbaPegaPlatinumOfferComponent,
    PLPRevampCardComponent,
    PromoBannerLayoutComponent
  ],
  exports: [
    SpToggleButtonModule,
    SpDropdownComponent,
    TranslateModule,
    SpModalComponent,
    SpModalFullComponent,
    SpRadioButtonComponent,
    TruncatePipe,
    SpSidemenuComponent,
    SpEnjoyMoreNavigatorComponent,
    SpAccordionComponent,
    ConvertToAsterisksPipe,
    AlertInfoComponent,
    SpJumbotronNavigatorComponent,
    SpJumbotronNavigatorComponent,
    LandingPercentageComponent,
    SpEnjoyMoreNavigatorComponent,
    SliceCurrencyModule,
    EliminateZerosPipe,
    MyDataFormatePipe,
    AlertInfoComponent,
    HtmlToTextPipe,
    ToastComponent,
    OrderByDatePipe,
    SpToggleDropdownComponent,
    SpDoubleToggleDropdownComponent,
    SecondsToTimePipe,
    AccordionModule,
    FullWidthSliderComponent,
    UnavilabilityModalComponent,
    ArraySortPipe,
    LandingDataBundleComponent,
    Mva10TopupLandingWithPciComponent,
    Mva10TopupIframeEnablementComponent,
    LandingBundleContainerDirective,
    LandingEnjoymoreBundleComponent,
    LandingFixedToFixedBundleComponent,
    LandingFixedToMobileBundleComponent,
    LandingFixedToInternationalBundleComponent,
    LandingMinutesBundleComponent,
    LandingSmsBundleComponent,
    DotPipe,
    GdprModalComponent,
    CategorizeByPipe,
    SpMva10OverlayComponent,
    SpOverlayComponent,
    SliceCurrencyRoundZeroPipe,
    SafePipe,
    BlockCopyPasteDirective,
    SpOverlayComponent,
    SpMva10OverlayComponent,
    FormatNumberPricePipe,
    ModalMessagesComponent,
    Mva10FullOverlayComponent,
    SpActivationToggleCardComponent,
    BannerAppFromWebComponent,
    MsisdnToShowPipe,
    RrssComponent,
    EntryPointsManagementComponent,
    BannerAppFromWebComponent,
    Mva10FullModalErrorComponent,
    Mva10TimeLineComponent,
    NexusOpComponent,
    CurrentComsuptionComponent,
    CurrentBonosComponent,
    ResizeListenerComponent,
    LeftMenuComponent,
    SubmenuComponent,
    SubmenuMenuComponent,
    SubmenuProductsComponent,
    SubmenuAccountComponent,
    Mva10FullModalErrorComponent,
    GenericNavigatorComponent,
    GenericAccordionComponent,
    DynamicTrayComponent,
    CommercialPromotionsComponent,
    LottieLoaderComponent,
    ToggleCardComponent,
    Mva10SubHeaderComponent,
    SpDeliveryTypesV2Component,
    BackgroundHeaderAndIconComponent,
    SpActivationToggleCardMva10Component,
    SpToggleButtonMva10Component,
    SuccessErrorTrayComponent,
    LabelNbaComponent,
    FormatDatePipe,
    ImgFullPathModule,
    ReplacePipe,
    SpinnerLoaderComponent,
    ExpandableChevronComponent,
    PendingInstallWarningComponent,
    SpWalletCardComponent,
    TopUpCardWalletScreenComponent,
    EntryPointsManagementComponent,
    TrayLoadingModeComponent,
    LongPressDirective,
    SpFixedBottomButtonComponent,
    SpPurchasingComponent,
    SpMva10CtcOverlayComponent,
    SpCalenderComponent,
    FailureComponent,
    SearchAutocompleteComponent,
    PaymentTicketComponent,
    PaymentClarifyUserComponent,
    LottieModule,
    NewSiteSelectorComponent,
    SpGenericTicketComponent,
    TooltipModalComponent,
    SpMva10ServiceSelectorComponent,
    Mva10AmountSelectorComponent,
    PaymentWalletChevronComponent,
    MonthlyFeePipe,
    TotalDebtPaymentModalComponent,
    GenericFlipCardComponent,
    EditBankAccountOverlayComponent,
    ExclusiveOfferStampComponent,
    PaymentFullDynamicTrayComponent,
    TopTileComponent,
    PromotionsComponent,
    MoreInfoTrayComponent,
    TileImageCtaComponent,
    LogoutOverlayComponent,
    TariffChangeHeaderComponent,
    TarrifCardInfoComponent,
    SuperWifiVerifyEmailComponent,
    BackdropChooserComponent,
    ShimmerLoaderComponent,
    XrContentsComponent,
    CountDownFormatPipe,
    SavingsComponent,
    FullOverlayComponent,
    GeneralErrorTrayComponent,
    SmartphoneRaffleComponent,
    FullOverLayPageComponent,
    NewSmartPayLandingComponent,
    IconNavigationCardComponent,
    HeaderWithClosebuttonComponent,
    SpTextModule,
    SpTextAreaModule,
    ModalModule,
    SwiperModule,
    TooltipModule,
    PriceToShowModule,
    FullOverlayIframeComponent,
    FilterPipe,
    FilterTabsPipe,
    WhatIncludeComponent,
    BannerCardComponent,
    CampaignOverlayComponent,
    OnboardingComponent,
    EnglishModalComponent,
    PriceHikeAlertCardComponent,
    SpErrorMessageModule,
    SpSwiperModule,
    DmpMapperComponent,
    PickerItemComponent,
    SubmenuShopSelectorComponent,
    PLPRevampCardComponent,
    PromoBannerLayoutComponent
  ],
  providers: [
    BsModalService,
    SpPasswordValidationService,
    UtilitiesService,
    PaymentCardsService,
    {
      // hammer instantion with custom config
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    { provide: 'moment', useValue: moment },
    DestinyService,
    { provide: DateAdapter, useClass: MyDateAdapter },
    FormatDatePipe,
    CommercialConfirmationClose,
    MsisdnToShowPipe,
    DecimalPipe
  ],
  entryComponents: [
    TooltipComponent,
    TooltipModalComponent,
    SuccessErrorTrayComponent,
    PayDebtOtherPaymentMethodsComponent,
    TrayLoadingModeComponent,
    PaymentTicketComponent,
    NewSiteSelectorComponent,
    MoreInfoTrayComponent,
    FullOverlayComponent,
    FullOverLayPageComponent
  ]
})
export class SharedModule {}

import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { SmartPayService } from './services/smart-pay.service';
import { ProductsAndServicesService } from 'src/app/products-and-services/products-and-services.service';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { config } from 'src/config/pages-config';
import { Router } from '@angular/router';
import { ServiceBalanceWs10Service } from 'src/app/shared/services/service-balance-ws10.service';
import { SmartPayPaymentService } from 'src/app/shared/services/smart-pay-payment.service';
import { PaymentWalletService } from 'src/app/shared/services/payment-wallet.service';
import { TariffStatus } from 'src/app/shared/enums/tariffStatus.enum';
import { NewTaggingHelperService } from '../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from '../models/new-tagging-json-model';
import { smartPayTagging, highlightBenefitsIcons, redSmallTickIcon } from '../shared/constants/defines';
import { OverlayFullScreenModel } from '@mva10/mva10-angular';

@Component({
  selector: 'sp-smart-pay',
  templateUrl: './smart-pay.component.html',
  styleUrls: ['./smart-pay.component.scss']
})
export class SmartPayComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild('cardsContainer') cardsContainer: ElementRef;
  isSmartPay: boolean;
  remainingHeight: number;
  pageHeight: string = '0px';
  isDay28: boolean;
  paymentDate: string;
  msisdn: string;
  tariffCode: string;
  isTariffActive: boolean;
  isDeactivateOverlay: boolean;
  maintainBenefitOverlay: OverlayFullScreenModel = new OverlayFullScreenModel();
  confirmDeactivationOverlay: OverlayFullScreenModel = new OverlayFullScreenModel();
  highlightBenefitsWCS: string[] = [];
  constructor(public translateService: TranslateService,
    private newTagging: NewTaggingHelperService,
    private appService: AppService,
    private subscriptionService: SubscriptionService,
    public smartPayService: SmartPayService,
    private productAndServicesService: ProductsAndServicesService,
    private tariffService: TariffService,
    private router: Router,
    private serviceBalanceService: ServiceBalanceWs10Service,
    private smartPayPaymentService: SmartPayPaymentService,
    private paymentWalletService: PaymentWalletService) { }

  ngOnInit(): void {
    this.setUIData();
    this.setOverlayUIData();
    this.paymentWalletService.$deletedCreditCard ?.subscribe(() => {
      this.smartPayService.updateCardToBeDefault(this.isSmartPay);
    });
    this.smartPayPageTagging()
  }

  smartPayPageTagging(): void {
    const stateKey: string =  this.isSmartPay ? smartPayTagging.pago_facil_active : smartPayTagging.pago_facil_inactive;
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.state(
        data.page['stateList'][stateKey]);
    });
 }

  handleScrollingUI(): void {
    if (!this.smartPayService.showCheckboxAndFooter) {
      this.smartPayService.footerHeight = 0
    }
    this.remainingHeight = 0;
    const topPaddingOfBodyContainer: number = parseFloat(this.productAndServicesService.tariffMainContainerPaddingTop);
    this.remainingHeight = window.innerHeight - this.appService.fixedHeaderHeight - this.appService.bannerHeight - topPaddingOfBodyContainer
      - this.cardsContainer.nativeElement.offsetHeight - this.smartPayService.footerHeight;
    this.appService.isMobileScrollable = this.remainingHeight < 0 ? true : false;
    this.pageHeight = (this.cardsContainer ?.nativeElement ?.offsetHeight + (+this.smartPayService.footerHeight)) + 'px';
  }

  ngAfterViewChecked(): void {
    this.handleScrollingUI();
  }

  setUIData(): void {
    this.productAndServicesService.hideServicesHeaderAndTabs = true;
    this.isSmartPay = this.subscriptionService.customerData?.currentService?.smartPay;
    this.msisdn = this.subscriptionService.customerData?.currentService?.id;
    this.isDay28 = this.tariffService.isDay28();
    this.tariffCode = this.tariffService.Tariff?.Code;
    this.isTariffActive = this.tariffService.Tariff?.benefitStatus?.toLowerCase() === TariffStatus.Active.toLowerCase();
    this.translateService.get('v10.productsServices.smartPay').subscribe((data) => {
      this.appService.appHeaderTitle = data?.common?.easypay;
      this.highlightBenefitsWCS.push(data?.PyS?.benefits?.deactivation?.benefits_reminder?.first);
      this.highlightBenefitsWCS.push(data?.PyS?.benefits?.deactivation?.benefits_reminder?.second);
      this.highlightBenefitsWCS.push(data?.PyS?.benefits?.deactivation?.benefits_reminder?.third);
    });
  }

  setOverlayUIData(): void {
    this.smartPayService.overlayData.closeBtn = true;
    this.smartPayService.overlayData.stylesSecundaryBtn = 'mva10-c-button--tertiary';
  }

  ngOnDestroy(): void {
    this.smartPayService.overlayData.closeBtn = false;
    this.smartPayService.isCTASubmitted = false;
    this.smartPayService.showPaymentCardTitle = false;
    this.smartPayService.showCheckboxAndFooter = false;
    this.paymentWalletService.$deletedCreditCard ?.unsubscribe();
    this.smartPayService.showOverlay = false;
    this.smartPayService.showMaintainSmartPayOverlay = false;
    this.smartPayService.showConfirmDeactivationOverlay = false;
    this.smartPayService.showSuccessOrErrOverlay = false;
  }

  checkOverlayCloseButtonTagging(isCTA: boolean, CTATagging: string, XButtonTagging: string,
    viewTagging: string, eventContext?: string): void {
    const interactionKey: string =  isCTA ? CTATagging : XButtonTagging;
     this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
       if (eventContext) {
         data.eventList[interactionKey].event.event_context = eventContext;
        }
       this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList'][viewTagging]);
     });
  }

  closeOverLayActivateTagging(isCTA?: boolean): void {
    if (this.smartPayService.isActivateOverlay && this.smartPayService.activateSmartPaySuccessfully) {
      this.checkOverlayCloseButtonTagging(isCTA, 'pago_facil_activated_screen_entendido_click', 'pago_facil_activated_screen_X_click', 'pago_facil_activated_screen');
    }
    else if (this.smartPayService.isActivateOverlay && !this.smartPayService.activateSmartPaySuccessfully)  {
      this.checkOverlayCloseButtonTagging(isCTA, 'pago_facil_activate_failure_screen_retry_click', 'pago_facil_activate_failure_screen_X_click', 'pago_facil_activate_failure_screen');
    }
  }

  closeOverLayDeactivatedTagging(isCTA?: boolean): void {
    if (this.isDeactivateOverlay && this.smartPayService.deactivateSmartPaySuccessfully) {
      this.checkOverlayCloseButtonTagging(isCTA, 'pago_facil_active_deactivated_screen_entendido_click', 'pago_facil_active_deactivated_screen_X_click', 'pago_facil_deactivated');
    }
    else if (this.isDeactivateOverlay && !this.smartPayService.deactivateSmartPaySuccessfully) {
      this.checkOverlayCloseButtonTagging(isCTA, 'pago_facil_active_deactivate_failure_screen_retry_click', 'pago_facil_active_deactivate_failure_screen_X_click', 'pago_facil_deactivate_failure_screen');
    }
  }

  closeDeactivationOverlaysTagging(): void {
    let eventContext: string = '';
    if (this.smartPayService.deactivationScreens) {
      if (this.smartPayService.showOverlay) {
        eventContext = smartPayTagging.deactivate_pago_facil;
      }
      else if (this.smartPayService.showMaintainSmartPayOverlay) {
        eventContext = smartPayTagging.maintain_pago_facil;
      }
      else if (this.smartPayService.showConfirmDeactivationOverlay && this.isDay28) {
        eventContext = smartPayTagging.renewal_processing_pago_facil;
      }
      else if (this.smartPayService.showConfirmDeactivationOverlay && !this.isDay28) {
        eventContext = smartPayTagging.confrim_deactivation;
      }
      this.checkOverlayCloseButtonTagging(false, smartPayTagging.highlight_benefit_screen_X_click,
        smartPayTagging.highlight_benefit_screen_X_click, smartPayTagging.highlight_benefit_screen, eventContext);
    }
  }

  closeOverlayButton(): void {
    this.closeOverLayActivateTagging(true);
    this.closeOverLayDeactivatedTagging(true);
    this.smartPayService.activateSmartPaySuccessfully = false;
    this.smartPayService.deactivateSmartPaySuccessfully = false;
    this.setMaintainSmartPayBenefitOverlay();
  }

  closeOverlayButtonX(): void {
    this.closeOverLayActivateTagging(false);
    this.closeOverLayDeactivatedTagging(false);
    this.closeDeactivationOverlaysTagging();
    this.closeOverlay();
  }
  closeOverlay(): void {
    this.smartPayService.activateSmartPaySuccessfully = false;
    this.smartPayService.deactivateSmartPaySuccessfully = false;
    this.smartPayService.showOverlay = false;
    this.smartPayService.deactivationScreens = false;
    this.navigateToProductsAndServices();
  }

  closeMaintainBenefitOverlay(): void {
    this.smartPayService.activateSmartPaySuccessfully = false;
    this.smartPayService.deactivateSmartPaySuccessfully = false;
    this.smartPayService.showMaintainSmartPayOverlay = false;
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(data.eventList[smartPayTagging.maintain_benefit_entendido_button_click],
        data.page['stateList'][smartPayTagging.maintain_benefit_screen]);
    });
    this.navigateToProductsAndServices();
  }


  navigateToProductsAndServices(): void {
    this.smartPayService.resetFlags();
    this.serviceBalanceService.refreshSubscriptionAndTarifAndServiceBalanceAPIs = true;
    this.router.navigate([config.ProductsAndServices.route]);
  }

  deactivateSmartPayTagging(): void {
    const interactionKey: string = this.isDay28 ? smartPayTagging.confirm_deactivation_twenty_eight_day_deactivate_click :
    smartPayTagging.confirm_deactivation_normal_days_deactivate_click;
    const stateKey: string = this.isDay28 ? smartPayTagging.confirm_smart_pay_deactivation_twenty_eight_day :
    smartPayTagging.confirm_smart_pay_deactivation_normal_days;
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(data.eventList[interactionKey],
      data.page['stateList'][stateKey]);
    });
  }

  deactivateSmartPayScreenTagging(): void {
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.state(
        data.page['stateList']['pago_facil_deactivated']);
    });
  }

  deactivateSmartPayFailureScreenTagging(): void {
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.state(
        data.page['stateList']['pago_facil_deactivate_failure_screen']);
    });
  }
  deactivateSmartPay(): void {
    this.deactivateSmartPayTagging();
    this.smartPayService.showOverlay = false;
    this.smartPayService.showConfirmDeactivationOverlay = false;
    this.smartPayService.showFullLoader = true;
    this.smartPayPaymentService.deleteSmartPayPayment(this.subscriptionService.customerData.currentService.id).subscribe(
      () => {
        this.smartPayService.deactivateSmartPaySuccessfully = true;
        this.stopLoadingAfterSec();
        this.setSuccessDeactivationOverlay();
        this.isDeactivateOverlay = true;
        this.deactivateSmartPayScreenTagging();
      }, err => {
        this.stopLoadingAfterSec();
        this.smartPayService.setFailureOverlay(err.error);
        this.isDeactivateOverlay = true;
        this.deactivateSmartPayFailureScreenTagging();
      });
  }

  stopLoadingAfterSec(): void {
    setTimeout(() => {
      this.smartPayService.showFullLoader = false
    }, 1000);
  }

  setSuccessDeactivationOverlay(): void {
    this.smartPayService.showSuccessOrErrOverlay = true;
    this.translateService.get('v10.productsServices.smartPay.PyS.benefits.deactivation').subscribe((data) => {
      this.smartPayService.deactivationSuccOrErrOverlay.titleOverlay = this.isDay28 ? data.smartpay_deactivated_day_minus_one_overlay?.title
      : data.smartpay_deactivated_overlay?.title;
      this.smartPayService.deactivationSuccOrErrOverlay.textOverlay = this.isDay28 ?
        data.smartpay_deactivated_day_minus_one_overlay?.message : data.smartpay_deactivated_overlay?.message;
      this.smartPayService.deactivationSuccOrErrOverlay.primaryBtnText =
      this.isDay28 ? data.smartpay_deactivated_overlay?.button_title : data.smartpay_deactivated_day_minus_one_overlay?.button_title;
    });
    this.smartPayService.deactivationSuccOrErrOverlay.closeBtn = true;
    this.smartPayService.deactivationSuccOrErrOverlay.secundaryBtnText = '';
    this.smartPayService.overlayIcon = redSmallTickIcon;
  }

  setMaintainSmartPayBenefitOverlay(): void {
    this.smartPayService.showOverlay = false;
    this.smartPayService.showConfirmDeactivationOverlay = false;
    this.smartPayService.showMaintainSmartPayOverlay = true;
    this.smartPayService.overlayIcon = '';
    this.maintainBenefitOverlay.primaryBtnText = this.translateService.instant('v10.productsServices.smartPay.PyS.benefits.deactivation.smartpay_kept_overlay.button_title');
    this.maintainBenefitOverlay.secundaryBtnText = '';
    this.maintainBenefitOverlay.closeBtn = true;
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.state(
        data.page['stateList'][smartPayTagging.maintain_benefit_screen]);
    });
    this.smartPayService.deactivationScreens = true;
  }

  dismissBenefitDeactivation(): void {
    const interactionKey: string = this.isDay28 ? smartPayTagging.confirm_deactivation_twenty_eight_day_maintain_click :
    smartPayTagging.confirm_deactivation_normal_days_maintain_click;
    const stateKey: string = this.isDay28 ? smartPayTagging.confirm_smart_pay_deactivation_twenty_eight_day :
    smartPayTagging.confirm_smart_pay_deactivation_normal_days;
    this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
      this.newTagging.interaction(data.eventList[interactionKey],
      data.page['stateList'][stateKey]);
    });
    this.setMaintainSmartPayBenefitOverlay();
  }

  setConfirmationDeactivationOverlay(): void {
    this.smartPayService.showOverlay = false;
    this.smartPayService.showConfirmDeactivationOverlay = true;
    this.confirmDeactivationOverlay.closeBtn = true;
    this.translateService.get('v10.productsServices.smartPay.PyS.benefits.deactivation').subscribe((data) => {
      if (this.isDay28) {
        this.confirmDeactivationOverlay.titleOverlay = data.day_minus_one_user.confirmation.title;
        this.confirmDeactivationOverlay.textOverlay = data.day_minus_one_user.confirmation.message;
        this.confirmDeactivationOverlay.primaryBtnText = data.day_minus_one_user.confirmation.keep_button_title;
        this.confirmDeactivationOverlay.secundaryBtnText = data.day_minus_one_user.confirmation.deactivate_button_title;
        this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
        this.newTagging.state(
          data.page['stateList'][smartPayTagging.confirm_smart_pay_deactivation_twenty_eight_day]);
        });
      }
      else {
        this.confirmDeactivationOverlay.primaryBtnText = data?.regular_user?.confirmation?.keep_button_title;
        this.confirmDeactivationOverlay.secundaryBtnText = data?.regular_user?.confirmation?.deactivate_button_title;
        this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
        this.newTagging.state(
          data.page['stateList'][smartPayTagging.confirm_smart_pay_deactivation_normal_days]);
        });
         }
      this.smartPayService.overlayIcon = data?.regular_user?.confirmation?.overlay_icon;
    })
    this.confirmDeactivationOverlay.stylesSecundaryBtn = 'mva10-c-button--tertiary';
 }

 public get highlightBenefitsIcons (): typeof highlightBenefitsIcons {
  return highlightBenefitsIcons;
}
}

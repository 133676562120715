import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardSection } from '../../../models/card-section.model';
import { BundleStatus } from '../../enums/bundleStatus.enum';
import { commercialMobile } from '../../../shared/constants/defines';

@Component({
  selector: 'sp-toggle-card',
  templateUrl: './toggle-card.component.html',
  styleUrls: ['./toggle-card.component.scss'],
})
export class ToggleCardComponent implements OnInit {
  @Input() item: CardSection;
  @Input() isOnePlus: boolean;
  @Input() bottomSectionDate: boolean = false;
  @Output() clickButton = new EventEmitter();
  @Output() clickchevron = new EventEmitter();
  @Output() clickToggle = new EventEmitter();

  public bundleStatus = BundleStatus;
  public monthlyText = commercialMobile.coinMonth;
  constructor() {}

  ngOnInit() {
  }
  buttonAction() {
    this.clickButton.emit();
  }
  chevronAction() {
    this.clickchevron.emit();
  }
}


import {map} from 'rxjs/operators';
import { Product } from './../../models/product.model';
import { BenefitStatus } from '../../shared/enums/benefit-status.enum';
import { ProductType } from '../../shared/enums/productType.enum';
import { ProductService } from '../../shared/services/product.service';
import { TariffService } from '../../shared/services/tariff.service';
import { config } from './../../../config/pages-config';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../../core/services/subscription.service';
import { SiteType } from '../../shared/enums/siteType.enum';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { mergeMap } from '../../../../node_modules/rxjs/operators';
import { AppService } from '../../app.service';

@Injectable()
export class PrepaidGuard implements CanActivate, CanActivateChild {

    constructor(private subscriptionData: SubscriptionService,
        private router: Router,
        private deepLinkingService: DeepLinkingService,
        private tariffService: TariffService,
        private productService: ProductService,
        private appService: AppService) {

    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.deepLinkingService.isDeepLink || this.productService.navigateToEnjoyMoreListingFromVFPassLanding) {
            if ((this.subscriptionData.customerData && this.subscriptionData.customerData.SiteType
                && this.subscriptionData.customerData.SiteType.toLowerCase() === SiteType.Prepaid.toLowerCase())
                || this.productService.navigateToEnjoyMoreListingFromVFPassLanding) {
                this.productService.navigateToEnjoyMoreListingFromVFPassLanding = false;
                return this.tariffService.GetAllTariffs().pipe(
                    mergeMap(() => {
                        return this.productService.GetAllProducts().pipe(map(() => {

                            if (this.productService.products.find((product: Product) => {
                                    return this.isEnjoyMore(product) && this.isInactiveStatus(product)
                                })) {

                                return true;

                            }
                            else {
                                this.rejectDeepLinking();
                                return false;
                            }
                        }))
                    })
                )
            } else {
                this.productService.navigateToEnjoyMoreListingFromVFPassLanding = false;
                this.rejectDeepLinking();
                return false;
            }
        } else if (this.subscriptionData.customerData && this.subscriptionData.customerData.SiteType
            && this.subscriptionData.customerData.SiteType.toLowerCase() === SiteType.Prepaid.toLowerCase()) {
            if (this.productService.products.find((product: Product) => {
                    return this.isEnjoyMore(product) && this.isInactiveStatus(product)
                })) {
                return true;
            } else {
                this.router.navigate([config.ProductsAndServices.landing.route]);
                return false;
            }
        }
        else {
            return true;
        }


    }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
    private rejectDeepLinking() {
        this.deepLinkingService.isDeepLink = false;
        this.deepLinkingService.isError = true;
        this.appService.showFullAppLoader = false;
        this.router.navigate([config.dashboard.route]);
    }
    private isEnjoyMore(product: Product) {
        return product.ProductType && product.ProductType.toLowerCase() === ProductType.EnjoyMore.toLowerCase();
    }
    private isInactiveStatus(product: Product) {
        return product.currentStatus && product.currentStatus.toLowerCase() === BenefitStatus.inactive.toLowerCase();
    }
}

import { tagging } from '../../../../config/tagging-config';
import { StorageService } from '../../../core/services/storage.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentTaggingDefault, PaymentTaggingInterface } from './payment-tagging-interface';
import { PaymentCardModel } from '../../../models/payment-card.model';

export class PaymentTaggingPrePaid extends PaymentTaggingDefault implements PaymentTaggingInterface {
    constructor(public tagging: TaggingHelperService, public storageService: StorageService) {
        super(tagging, null, null, storageService);
    }
    /**
     * Tagging for ticket screen for prepaid journey
     */
    tagOpenPaymentTicketScreen(): void {
        this.tagging.view(tagging.pciPrepaidPages.paymentTicketPage, this.storageService.topupPrepaid);
    }
    /**
     * Tagging for download button in ticket screen for prepaid journey
     */
    tagDownLoadTicket(): void {
        this.tagging.track(tagging.pciPrepaid.interactions.clickSavePhoto.eventName,
            tagging.pciPrepaid.interactions.clickSavePhoto.data,
            tagging.pciPrepaidPages.paymentTicketPage, this.storageService.topupPrepaid)
    }
    /** Tagging for payment wallet screen */
    tagOpenPaymentWalletScreen(): void {
        const pageObj: any = tagging.paymentCardsPages.manageCreditCards;
        this.tagging.view(pageObj);
    }
    /** Tagging for payment wallet confirm deleting a card */
    tagPaymentWalletConfirmDeleteInteraction(): void {
        this.tagging.track(tagging.paymentCards.interactions.clickOnConfirmDeleteCard.eventName,
            tagging.paymentCards.interactions.clickOnConfirmDeleteCard.data, tagging.paymentCardsPages.confirmDeleteCard);
    }
    /** Tagging for payment wallet cancel deleting a card */
    tagPaymentWalletCancelDeleteInteraction(): void {
        this.tagging.track(tagging.paymentCards.interactions.clickOnCancelDeleteCard.eventName,
            tagging.paymentCards.interactions.clickOnCancelDeleteCard.data, tagging.paymentCardsPages.confirmDeleteCard);
    }
    /** Tagging for payment wallet start deleting a card journey */
    tagPaymentWalletDeleteCard(): void {
        this.tagging.track(tagging.paymentCards.interactions.clickOnDeleteCard.eventName,
            tagging.paymentCards.interactions.clickOnDeleteCard.data, tagging.paymentCardsPages.manageCreditCards);
        this.tagging.view(tagging.paymentCardsPages.confirmDeleteCard);
    }
    /** Tagging for payment wallet update a card to be principal */
    tagPaymentWalletUpdateAsPrinciple(): void {
        this.tagging.track(tagging.paymentCards.interactions.clickOnUseAsMainCard.eventName,
            tagging.paymentCards.interactions.clickOnUseAsMainCard.data, tagging.paymentCardsPages.manageCreditCards);
    }
    /** Tagging for payment wallet closing overlay */
    tagPaymentWalletClickOnXInteraction(): void {
        const pageTaggingName: string = tagging.paymentCardsPages.manageCreditCards.page_name;
        const eventName: string = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName
            .replace('<page_name>', pageTaggingName);
        this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
            tagging.paymentCardsPages.manageCreditCards);
    }
    /** Tagging for payment wallet back button overlay */
    tagPaymentWalletClickOnBackInteraction(selectedCard?: PaymentCardModel): void {
        if (!selectedCard) {
            tagging.pciPrepaid.newTopupJourney.journey_card_selected = tagging.staticVariables.newCreditCard;
        }
        else {
            tagging.pciPrepaid.newTopupJourney.journey_card_selected = tagging.staticVariables.savedCreditCard;
        }
        this.tagging.viewReplace(false, {}, tagging.pciPrepaid.newTopupJourney);
    }
     /** Tagging for payment x button overlay */
     tagCloseBtnClick(): void {
        this.tagging.track(tagging.cerrarBtnClickSuccessPaymentOverlay.event_name,
            tagging.cerrarBtnClickSuccessPaymentOverlay.data, tagging.afterTopUp.successPageWithNewOptions.state)
    }
    /**Tagging for close button for smartpay retention screen */
    tagCloseBtnSmartPayAfterTopUp(): void {
        this.tagging.track(tagging.smartPayAfterTopUp.interaction.ClickOnCerrar.data.event_name,
            tagging.smartPayAfterTopUp.interaction.ClickOnCerrar.data, tagging.smartPayAfterTopUp.state);
    }
}

export const marketplaceTagging = {
  marketplaceTabsTab: {
    shopPage: {
      page_name: 'tienda:home',
      site_section: 'tienda',
      page_subcategory_level_1: 'home',
      page_subcategory_level_2: '',
      page_subcategory_level_3: '',
      page_subcategory_level_4: '',
      page_detail: 'home',
      event_context: 'home tienda {0}',
    },
    myOrdersPage: {
      page_name: 'seguimiento de pedidos:home',
      site_section: 'seguimiento de pedidos',
      page_subcategory_level_1: 'home',
      page_subcategory_level_2: '',
      page_subcategory_level_3: '',
      page_subcategory_level_4: '',
      page_detail: 'home',
      event_context: 'home seguimiento de pedidos {0}',
    },
    myBillingPage: {
      page_name: 'pagos:mis pagos:metodos de pago:tarjetas guardadas',
      site_section: 'pagos',
      page_subcategory_level_1: 'mis pagos',
      page_subcategory_level_2: 'metodos de pago',
      page_subcategory_level_3: 'tarjetas guardadas',
      page_subcategory_level_4: '',
      page_detail: 'tarjetas guardadas',
      event_context: 'tarjetas guardadas {0}',
    },
    event: {
      event_category: 'tab',
      event_label: 'click en {0}'
    }
  },
  marketplaceAlertModal: {
    page: {
      page_name: 'marketplace:abandono carrito',
      site_section: 'marketplace',
      page_subcategory_level_1: 'abandono carrito',
      page_subcategory_level_2: '',
      page_subcategory_level_3: '',
      page_subcategory_level_4: '',
      page_detail: 'overlay',
    },
    event: {
      event_context: 'abandono carrito marketplace',
      event_category: 'button',
      event_label: 'click en {0}'
    }
  },
}

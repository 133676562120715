
import { catchError, map } from 'rxjs/operators';
import {
  CommercialTerminalDetail, CommercialTerminalDetailList, Capacity,
  Characteristics, Items, Color, MainCharacteristics
} from './../../models/commercial-terminal-detail.model';
import {
  CommercialFinancingOption, CommercialPayment, CommercialRefinancingOption
} from '../../models/commercial-terminal-financing.model';
import { ListaIconos, Promotion } from './../../models/commercial-terminal-detail.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import { StorageService } from '../../core/services/storage.service';
import { LOCAL_STORAGE_KEYS, JSON_PATHS, commercialMobile, insurance, pay, newTaggingClicks } from '../constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { BehaviorSubject, throwError } from 'rxjs';
import { ProductConfigurationService } from './product-configuration.service';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { CommercialMobileService } from './commercial-mobile.service';
import { MicroFlowsService } from './micro-flows.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CommercialDetail } from '../../models/commercial-detail.model';
import { UtilitiesService } from '../utils/utilities.service';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { ClientTypology } from '../enums/clientTopology.enum';

@Injectable()
export class CommercialMobileDetailService {
  private terminalDetail: CommercialTerminalDetail = null;

  private listaIconos: ListaIconos[];
  private listaIconosSubject = new BehaviorSubject([]);
  public listaIconos$ = this.listaIconosSubject.asObservable();

  public productTagg: string;
  public isME: boolean;
  public isYU: boolean;

  private showFinalPayment: boolean = false;
  public availableCharacteristics: Array<string>;
  public selectedCharacteristics: Array<object>;
  public clientType: string;
  public nameDeviceDetail: string;
  private isMicroResegmented: boolean;
  public analyticsCategoryType: string;

  public category: string = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology).toLowerCase();
  public subcategory: string = this.subscriptionService.customerData.SiteType === pay.postpaid_check ? pay.postpaid : pay.prepaid;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private productConfiguration: ProductConfigurationService,
    private commercialMobileService: CommercialMobileService,
    private translate: TranslateService,
    private microFlowService: MicroFlowsService,
    private readonly utilities: UtilitiesService,
    private newTaggingHelper: NewTaggingHelperService,
    private tagging: TaggingHelperService,
    private subscriptionService: SubscriptionService
  ) {
    this.isMicroResegmented = this.utilities.isConsumerAndHasMicro();
  }

  public getTerminalsDetail(
      clientId: string,
      sap: string,
      msisdn: string,
      cartItemId: string,
      group: string,
      offerId: string,
      paymentType: string,
      terminalType?: string,
      promoCode?: string
    ): Observable<CommercialDetail | CommercialTerminalDetail> {
    let url = API_URLS.CommercialMobiles.getTerminaldetail;
    const isMeOrMicroResegmented: boolean = this.isME  || this.isMicroResegmented;
    if (group) {
      url += commercialMobile.group.replace('{group}', group);
    }
    if (offerId) {
      url += commercialMobile.offerId.replace('{offerId}', offerId);
    }
    if (paymentType) {
      url += commercialMobile.paymentType.replace('{paymentType}', paymentType);
    }
    if (promoCode) {
      url += commercialMobile.promoCode.replace('{promoCode}', promoCode);
    }
    if (terminalType) {
      url = url.replace('{terminalType}', terminalType);
    } else {
      url = url.replace('{terminalType}', '3');
    }
    url = url
      .replace(
        '{clientType}',
        isMeOrMicroResegmented
          ? commercialMobile.clientTypeMicro.toString()
          : commercialMobile.clientType.toString()
      )
      .replace(
        '{shopType}',
        isMeOrMicroResegmented
          ? commercialMobile.shopTypeMicro.toString()
          : this.isYU
            ? commercialMobile.shopTypeYu.toString()
            : commercialMobile.shopType.toString()
      )
      .replace('{clientId}', clientId)
      .replace('{sap}', sap)
      .replace('{msisdn}', msisdn)
      .replace('{profile}', commercialMobile.profile)
      .replace('{cartItemId}', cartItemId)
      .replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options = {
      headers: headers,
      auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
      NTOL_TXID: this.microFlowService.cartId,
      withCredentials: true
    };

    return this.http.get(url, options).pipe(map((res: any) => {
      this.commercialMobileService.taxType = res.taxType;
      this.terminalDetail = res;
      if (!res.error) {
        this.terminalDetailLogic(res);
        this.clientType = res.tipoCliente ? res.tipoCliente.toLocaleLowerCase() : '';
      }
      return this.terminalDetail;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  private terminalDetailLogic(terminal) {
    this.showFinalPayment = this.productConfiguration.shouldShowFeature(ProductConfigurationID.showFinalPayment);
    this.terminalDetail = new CommercialTerminalDetail();
    this.terminalDetail.terminalDetailList = new Array<CommercialTerminalDetailList>();
    this.listaIconos = new Array<ListaIconos>();
    this.terminalDetail.nombre = JsonQuery.value(terminal, JSON_PATHS.CommercialTerminalDetail.nombre) || null;
    this.terminalDetail.id = JsonQuery.value(terminal, JSON_PATHS.CommercialTerminalDetail.id) || 0;
    this.terminalDetail.type = JsonQuery.value(terminal, JSON_PATHS.CommercialTerminalDetail.type) || null;
    this.terminalDetail.taxType = JsonQuery.value(terminal, JSON_PATHS.CommercialTerminalDetail.taxType) || null;
    this.terminalDetail.taxValue = JsonQuery.value(terminal, JSON_PATHS.CommercialTerminalDetail.taxValue) || null;
    terminal.listTerminals.map(term => {
      const terminalDetailCommercial = new CommercialTerminalDetailList();
      terminalDetailCommercial.capacidad = new Capacity();
      terminalDetailCommercial.color = new Color();
      const iconos = new ListaIconos();

      terminalDetailCommercial.ordenGrupo = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.ordenGrupo) || 0;
      terminalDetailCommercial.idTerminal = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.idTerminal) || 0;
      terminalDetailCommercial.idGrupo = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.idGrupo) || 0;
      terminalDetailCommercial.sap = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.sap) || 0;
      terminalDetailCommercial.color.name = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.color) || null;
      terminalDetailCommercial.color.code = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.codigoColor) || null;
      terminalDetailCommercial.modelo = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.modelo) || null;
      terminalDetailCommercial.capacidad.capacidadValorUnidad =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.capacidad) || null;
      terminalDetailCommercial.capacidad.unidad =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.unidad) || null;
      terminalDetailCommercial.capacidad.valor =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.valor) || null;
      terminalDetailCommercial.categoryType = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.categoryType) || null;
      terminalDetailCommercial.idTipoTerminal = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.idTipoTerminal) || null;
      terminalDetailCommercial.imagenFrontalMovil = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenFrontal) || null;
      terminalDetailCommercial.textoImagenFrontalMovil =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenFrontal) || null;
      terminalDetailCommercial.imagenTraseraMovil = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenTrasera) || null;
      terminalDetailCommercial.textoImagenTraseraMovil =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenTrasera) || null;
      terminalDetailCommercial.imagenLateralMovil = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenLateral) || null;
      terminalDetailCommercial.textoImagenLateralMovil =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenLateral) || null;
      terminalDetailCommercial.imagenMedidasMovil = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenMedidas) || null;
      terminalDetailCommercial.textoImagenMedidasMovil =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenMedidas) || null;
      terminalDetailCommercial.imagenFrontalTablet = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenFrontal) || null;
      terminalDetailCommercial.textoImagenFrontalTablet =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenFrontal) || null;
      terminalDetailCommercial.imagenTraseraTablet = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenTrasera) || null;
      terminalDetailCommercial.textoImagenTraseraTablet =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenTrasera) || null;
      terminalDetailCommercial.imagenLateralTablet = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenLateral) || null;
      terminalDetailCommercial.textoImagenLateralTablet =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenLateral) || null;
      terminalDetailCommercial.imagenMedidasTablet = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenMedidas) || null;
      terminalDetailCommercial.textoImagenMedidasTablet =
        JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoImagenMedidas) || null;
      terminalDetailCommercial.so = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.so) || null;
      terminalDetailCommercial.imagenSo = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenSo) || null;
      terminalDetailCommercial.marca = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.marca) || null;
      terminalDetailCommercial.idMarca = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.idMarca) || null;
      terminalDetailCommercial.limitado = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.limitado) || null;
      terminalDetailCommercial.selected = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.selected) || null;
      terminalDetailCommercial.insuranceByDefault = this.getInsuranceByDefault(term);
      terminalDetailCommercial.descripcion = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.description) || null;
      terminalDetailCommercial.characteristics = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.characteristics) || null;
      terminalDetailCommercial.promotedCanon = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.promotedCanon);
      terminalDetailCommercial.analyticsCategoryType = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.analyticsCategoryType);
      terminalDetailCommercial.isPrioritary = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.isPrioritary);
      terminalDetailCommercial.techDeviceType = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.techDeviceType);
      if (terminalDetailCommercial.characteristics) {
        terminalDetailCommercial.characteristics = this.getCharacteristics(terminalDetailCommercial.characteristics, term);
      }
      terminalDetailCommercial.mainCharacteristics = JsonQuery.value(term,
        JSON_PATHS.CommercialTerminalDetailList.mainCharacteristics) || null;
      if (terminalDetailCommercial.mainCharacteristics) {
        terminalDetailCommercial.mainCharacteristics = this.getMainCharacteristics(terminalDetailCommercial.mainCharacteristics, term);
      }
      const financingOptions = JsonQuery.value(term, JSON_PATHS.CommercialTerminalList.financingOptions) || [];
      if (financingOptions.length) {
        terminalDetailCommercial.financingOptions = [];
        financingOptions.forEach(option => {
          const financingOption = new CommercialFinancingOption();
          financingOption.numMeses = JsonQuery.value(option, JSON_PATHS.CommercialFinancingOption.numMeses) || 0;
          financingOption.flagDefaultMonthly = JsonQuery.value(option, JSON_PATHS.CommercialFinancingOption.flagDefaultMonthly) || false;
          const payments = JsonQuery.value(option, JSON_PATHS.CommercialFinancingOption.payments) || [];
          if (payments.length) {
            financingOption.payments = [];
            payments.forEach(item => {
              const payment = new CommercialPayment();
              payment.idPrecio = JsonQuery.value(item, JSON_PATHS.CommercialPayment.idPrecio) || 0;
              payment.pagoAlContadoConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoAlContadoConImpuesto) || 0;
              payment.pagoAlContadoSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoAlContadoSinImpuesto) || 0;
              payment.cuotaMensualConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.cuotaMensualConImpuesto) || 0;
              payment.cuotaMensualSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.cuotaMensualSinImpuesto) || 0;
              payment.pagoInicialConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoInicialConImpuesto) || 0;
              payment.pagoInicialSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pagoInicialSinImpuesto) || 0;
              payment.crossedOfferOriginal = JsonQuery.value(item, JSON_PATHS.CommercialPayment.crossedOfferOriginal) || '';
              payment.offerType = JsonQuery.value(item, JSON_PATHS.CommercialPayment.offerType) || '';
              payment.offerOrigin = JsonQuery.value(item, JSON_PATHS.CommercialPayment.offerOrigin);
              payment.canonConImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.canonConImpuesto) || 0;
              payment.canonSinImpuesto = JsonQuery.value(item, JSON_PATHS.CommercialPayment.canonSinImpuesto) || 0;
              payment.paymentType = JsonQuery.value(item, JSON_PATHS.CommercialPayment.paymentType) || null;
              payment.flagDefaultPayment = JsonQuery.value(item, JSON_PATHS.CommercialPayment.flagDefaultPayment) || false;
              payment.flagPaymentOptionFp = JsonQuery.value(item, JSON_PATHS.CommercialPayment.flagPaymentOptionFp) || false;
              payment.flagFinalPayment = JsonQuery.value(item, JSON_PATHS.CommercialPayment.flagFinalPayment) || false;
              payment.flagOptionsRefinancingFp = JsonQuery.value(item, JSON_PATHS.CommercialPayment.flagOptionsRefinancingFp) || false;
              payment.deviceFinalPaymentTaxes = JsonQuery.value(item, JSON_PATHS.CommercialPayment.deviceFinalPaymentTaxes) || false;
              payment.deviceFinalPayment = JsonQuery.value(item, JSON_PATHS.CommercialPayment.deviceFinalPayment) || false;
              payment.permanenceDuration = JsonQuery.value(item, JSON_PATHS.CommercialPayment.permanenceDuration) != null
                ? JsonQuery.value(item, JSON_PATHS.CommercialPayment.permanenceDuration) : null;
              payment.permanencePenalty = JsonQuery.value(item, JSON_PATHS.CommercialPayment.permanencePenalty) != null
                ? JsonQuery.value(item, JSON_PATHS.CommercialPayment.permanencePenalty) : null;
              payment.permanencePenaltyTaxes = JsonQuery.value(item, JSON_PATHS.CommercialPayment.permanencePenaltyTaxes) != null
                ? JsonQuery.value(item, JSON_PATHS.CommercialPayment.permanencePenaltyTaxes) : null;
              payment.pvpTotal = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pvpTotal) || null;
              payment.pvpTotalTaxes = JsonQuery.value(item, JSON_PATHS.CommercialPayment.pvpTotalTaxes) || null;
              const optionsRefinancingFp = JsonQuery.value(item, JSON_PATHS.CommercialPayment.optionsRefinancingFp) || [];
              if (optionsRefinancingFp.length) {
                payment.optionsRefinancingFp = [];
                optionsRefinancingFp.forEach(option => {
                  const refinancingOption = new CommercialRefinancingOption();
                  refinancingOption.numberMonths = JsonQuery.value(option, JSON_PATHS.CommercialRefinancingOption.numberMonths) || 0;
                  refinancingOption.monthlyFeeTaxes = JsonQuery.value(option, JSON_PATHS.CommercialRefinancingOption.monthlyFeeTaxes) || 0;
                  refinancingOption.monthlyFee = JsonQuery.value(option, JSON_PATHS.CommercialRefinancingOption.monthlyFee) || 0;
                  payment.optionsRefinancingFp.push(refinancingOption);
                });
              }
              if (!payment.flagFinalPayment || this.showFinalPayment && payment.flagFinalPayment) {
                financingOption.payments.push(payment);
              }
            });
          }
          if (financingOption.payments.length) {
            terminalDetailCommercial.financingOptions.push(financingOption);
          }
        });
      }
      if (terminalDetailCommercial.financingOptions.length) {
        this.terminalDetail.terminalDetailList.push(terminalDetailCommercial);
      }

      if (term.listaIconos) {
        iconos.imagenIcono = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.imagenIcono) || null;
        iconos.textoIcono = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.textoIcono) || null;
        this.listaIconos.push(iconos);
      }

      terminalDetailCommercial.listaPromos = new Array<Promotion>();
      term.listaPromos.forEach(promo => {
        const promos = new Promotion();
        promos.added = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoAdded) || null;
        promos.type = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoAdded) || null;
        promos.id = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoId) || 0;
        promos.internalName = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoInternalName) || null;
        promos.publicName = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoPublicName) || null;
        promos.startDate = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoStartDate) || null;
        promos.endDate = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoEndDate) || null;
        promos.stateType = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoStateType) || null;
        promos.flagRegional = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoFlagRegional) || null;
        promos.manualProvision = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoManualProvision) || null;
        promos.discountType = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoDiscountType) || null;
        promos.codigoOferta = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoCodigoOferta) || null;
        promos.evuluationOrder = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoEvuluationOrder) || 0;
        promos.shortText = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoShortText) || null;
        promos.image = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoImage) || null;
        promos.longText = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoLongText) || null;
        promos.llbbText = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoLlbbText) || null;
        promos.llbbUrl = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promoLlbbUrl) || null;
        promos.listIcon = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.listIcon) || null;
        promos.detailIcon = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.detailIcon) || null;
        promos.urlEmbeddedVideo = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.urlEmbeddedVideo);
        promos.urlImagePreviewVideo = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.urlImagePreviewVideo);
        promos.promotionTitle = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.promotionTitle);
        promos.detailPromotion = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.detailPromotion);
        promos.cardColor = JsonQuery.value(promo, JSON_PATHS.CommercialTerminalDetailPromosList.cardColor);
        terminalDetailCommercial.listaPromos.push(promos);
      }, true);
      terminalDetailCommercial.itemStock = JsonQuery.value(term,
        JSON_PATHS.CommercialTerminalDetailList.itemStock) || null;
      terminalDetailCommercial.ecoRatingImage = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.ecoRatingImage);
      terminalDetailCommercial.ecoRatingText = JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.ecoRatingText);
      return terminalDetailCommercial;
    });
  }

  public getTerminalDetailsLoaded() {
    return this.terminalDetail;
  }

  public setSelected(selected: number, state: boolean) {
    this.terminalDetail.terminalDetailList[selected].selected = state;
  }

  private getCharacteristics(characToStore, characToGet) {
    characToStore = new Array<Characteristics>();
    const charact = JsonQuery.value(characToGet, JSON_PATHS.CommercialTerminalDetailList.characteristics) || null;
    charact.map(ch => {
      const charct = new Characteristics();
      charct.section = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.section) || null;
      charct.icon = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.icon) || null;
      charct.items = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.items) || null;
      if (charct.items) {
        const items = new Array<Items>();
        const item = JsonQuery.value(charct, JSON_PATHS.CommercialTerminalDetailList.items) || null;
        item.map(it => {
          const itm = new Items();
          itm.name = JsonQuery.value(it, JSON_PATHS.CommercialTerminalDetailList.name) || null;
          itm.value = JsonQuery.value(it, JSON_PATHS.CommercialTerminalDetailList.value) || null;
          items.push(itm);
        });
        charct.items = items;
      }
      characToStore.push(charct);
    });
    return characToStore;
  }

  private getMainCharacteristics(characToStore: MainCharacteristics[],
    characToGet: CommercialTerminalDetailList): MainCharacteristics[] {
    characToStore = new Array<MainCharacteristics>();
    const charact: MainCharacteristics[] = JsonQuery.value(characToGet,
      JSON_PATHS.CommercialTerminalDetailList.mainCharacteristics) || null;
    charact.map(ch => {
      const charct: MainCharacteristics = new MainCharacteristics();
      charct.code = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.code) || null;
      charct.desc = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.desc) || null;
      charct.order = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.order) || null;
      charct.sortMethod = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.sortMethod) || null;
      charct.sortType = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.sortType) || null;
      charct.icon = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.icon) || null;
      charct.value = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.value) || null;
      charct.extendedValue = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.extendedValue) || null;
      charct.standardValue = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.standardValue) || null;
      charct.unit = JsonQuery.value(ch, JSON_PATHS.CommercialTerminalDetailList.unit) || null;
      this.translate.get('v10.commercial.mfdevices.mainCharacteristics').subscribe((data) => {
        charct.title = data[charct.code] ? data[charct.code] : data.default;
      });
      characToStore.push(charct);
    });
    return characToStore;
  }

  public getTaxValue() {
    return this.terminalDetail.taxValue;
  }
  public getTaxType() {
    return this.terminalDetail.taxType.toLocaleUpperCase();
  }

  public setMainCharacteristics(charact: MainCharacteristics[]): void {
    this.availableCharacteristics = new Array();
    this.order(charact);
    charact.map(res => {
      if (!this.availableCharacteristics.includes(res.desc)) {
        this.availableCharacteristics.push(res.desc)
      }
    });
  }

  private order(mainChar: MainCharacteristics[]): void {
    mainChar.sort((achar: MainCharacteristics, bchar: MainCharacteristics) => {
      return achar.order - bchar.order;
    });
  }

  private getInsuranceByDefault(term: CommercialTerminalDetailList): boolean {
    return JsonQuery.value(term, JSON_PATHS.CommercialTerminalDetailList.insuranceByDefault) || null;
  }

  public clickToggleInsuranceTag(text: string, protect: boolean): void {
    this.newTaggingHelper.getJsonTagging('insurance/detail-insurance').subscribe(data => {
      data.eventList['click_toggle'].event.event_label = data.eventList['click_toggle'].event.event_label.replace('{0}', text);
      data.eventList['click_toggle'].event.event_context = !protect ? insurance.protected : insurance.notProtected;
      data.eventList['click_toggle'].journey.journey_category = this.category;
      data.eventList['click_toggle'].journey.journey_subcategory = this.subcategory;
      this.newTaggingHelper.interaction(data.eventList['click_toggle'], data.page);
    });
  }

  public clickLinkInsuranceTag(checked: boolean, text: string, insurance: string): void {
    this.newTaggingHelper.getJsonTagging('insurance/detail-insurance').subscribe(data => {
      const link: string = checked ? newTaggingClicks.click_link2 : newTaggingClicks.click_link1;
      if (checked) {
        data.eventList[link].event.event_context = insurance;
      }
      data.eventList[link].event.event_label = data.eventList[link].event.event_label.replace('{0}', text);
      data.eventList[link].custom_map['&&products'] = this.productTagg;
      data.eventList[link].journey.journey_category = this.category;
      data.eventList[link].journey.journey_subcategory = this.subcategory;
      this.newTaggingHelper.interaction(data.eventList[link], data.page);
    });
  }

  public tagStateInsurance(): void {
    this.newTaggingHelper.getJsonTagging('insurance/vodafone-care').subscribe(data => {
      data.page.custom_map['&&products'] = this.productTagg;
      data.page.journey.journey_category = this.category;
      data.page.journey.journey_subcategory = this.subcategory;
      this.newTaggingHelper.state(data.page);
    });
  }

}

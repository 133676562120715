<div class="top-up-rows" [ngClass]="{'forward-transition-tray': trayService?.showForwardTransition,'backward-transition-tray': trayService?.showBackwardTransition, 'fade-out-tray':topupErrorService?.hideCancelOverlay, 'topup-rows-responsive': topupService.useDynamicTray, 'height-with-percentage': topupService.useDynamicTray, 'height-with-vh': !topupService.useDynamicTray}">
  <div class="top-up-header" [ngClass]="{'hide-title': topupService.useDynamicTray}">
      <img class="header-img" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/red-small@2x.png">
      <div class="top-up-title"  *ngIf='topupErrorService.trayTitle'>
          <p>{{topupErrorService.trayTitle}}</p>
      </div>
      <div class="exit-logo" *ngIf="topupErrorService.showXButton">
          <span (click)="closeTray()" class="icon icon-close"></span>
        </div>
  </div>
  <div class="error-container">
    <div class="icon-container">
      <sp-lottie-loader *ngIf="topupErrorService.showLottie" [lottieImageUrl]="topupErrorService.lottieFile" [loop]="false"></sp-lottie-loader>
      <img [src]="appService.getImgFullPath(topupErrorService.iconUrl)" *ngIf="!topupErrorService.showLottie">
    </div>
    <div class="error-title" [innerHTML]="topupErrorService.title">
    </div>
    <div class="error-desc" *ngIf="topupErrorService.descriptionLine1 || topupErrorService.descriptionLine2">
      <p [innerHTML]="topupErrorService.descriptionLine1"></p>
      <p [innerHTML]="topupErrorService.descriptionLine2" *ngIf="topupErrorService.descriptionLine2"></p>
    </div>
  </div>
  <div class="error-buttons-container" [ngClass]="{'error-buttons-container-responsive': topupErrorService.showTwoCtas}">
    <button  type="button" class="continue button red two" (click)="topupErrorService.confirmationButtonFn()">{{topupErrorService.confirmationButtonText}}</button>
    <button  *ngIf="topupErrorService.showTwoCtas" type="button" class="cancel button white two" (click)="topupErrorService.cancelationButtonFn()">{{topupErrorService.cancelationButttonText}}</button>
  </div>
</div>
<div *ngIf="!showLeavingOverlay" [ngClass]="{'fade-out-tray':hideContent, 'backward-transition-tray':trayService?.showBackwardTransition, 'wallet-container-responsive': topupService.useDynamicTray}">
  <div class="top-up-title-with-exit-icon-container-wallet" [ngClass]="{'hide-title': topupService.useDynamicTray}">
    <div class="top-up-exit-logo">
      <img (click)="returnToScreenFunction()()" src='https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-arrow-left.svg'>
    </div>
    <div class="top-up-title-wallet">
      <p class="bold-title">{{screenTitle}}</p>
    </div>
    <div class="exit-logo-wallet" *ngIf="topupService.isMobileView">
      <span *ngIf="topupService.isMobileView" (click)="closeTray()" class="icon icon-close"></span>
    </div>
  </div>

  <div class='top-up-cards-container' [ngClass]="{'no-display': showFullLoader}" >
    <sp-wallet-card [menuItems]="actions" [isDotsShow]='true' [isPrinciple]='card.principle' [label]='principalTitle'
      [cardImg]="card.cardImage" [cardNumber]='card.cardMask' *ngFor='let card of creditCardsList'
      (onMenuActionClick)='updateOrDelete($event,card)' (onCardClick)="returnToScreenFunction()(false ,card)">
    </sp-wallet-card>
    <sp-wallet-card [isDotsShow]='false' [isPrinciple]='false' [cardImg]="otherImage"
      [cardNumber]='anotherCreditCardWallet' (click)="returnToScreenFunction()(true)" class="last-card">
    </sp-wallet-card>
  </div>
  <div class="full-loader" *ngIf="showFullLoader">
    <div class="">
      <sp-lottie-loader [lottieImageUrl]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/loader.json'"></sp-lottie-loader>
    </div>
  </div>
</div>
<sp-cancel-payment *ngIf="showLeavingOverlay"
(continueBtnClickedSubject)="continuePayment()"
(cancelBtnClickedSubject)="cancelPayment()">
</sp-cancel-payment>
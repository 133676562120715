
import {throwError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { VERIFYEMAIL } from '../constants/defines';
import { AppAvailabilityCheckService } from './app-availability-check.service';
import { NewTaggingHelperService } from '../../../app/core/services/new-tagging.helper.service';

@Injectable()
export class AppAvailabilityCheckInterceptor implements HttpInterceptor {
    constructor(private appAvailabilityCheckService: AppAvailabilityCheckService,
        private _TaggingHelperService: NewTaggingHelperService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(() => {}), catchError(
            error => {
                if (error.error && error.error.ecode && error.error.ecode.toString() === VERIFYEMAIL.errorAppUnavilability) {
                    this.appAvailabilityCheckService.onAppUnavailable.emit();
                    this._TaggingHelperService.creatErrorItem(error)
                }
                return throwError(error);
            }
          ))
    }

}

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { AppService } from 'src/app/app.service';
import { SmartPayService } from '../services/smart-pay.service';
import { API_URLS } from 'src/app/shared/constants/routes-config';
import { StorageService } from '../../core/services/storage.service';
import { CreditCardModel } from 'src/app/models/credit-card-model';
import { SmartPayCreatePaymentModel } from 'src/app/models/smart-pay-create-payment.model';
import { SmartPayPaymentService } from 'src/app/shared/services/smart-pay-payment.service';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { smartPayCreditCardDateCommonValues, smartPayIdTypes, documentTypeTranslated, smartPayTagging } from 'src/app/shared/constants/defines';
import { SmartPayPdfDescription } from 'src/app/models/smart-pay-get-payment.model';
import { DocumentumService } from 'src/app/shared/services/documentum.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';

@Component({
    selector: 'sp-smart-pay-footer',
    templateUrl: './smart-pay-footer.component.html',
    styleUrls: ['./smart-pay-footer.component.scss']
})
export class SmartPayFooterComponent implements OnInit, AfterViewChecked {
    @Input() isSmartPay: boolean;
    @Input() isDay28: boolean;
    @Input() msisdn: string;
    @ViewChild('fixedButton') fixedButton: { firstFooter: { nativeElement: { offsetHeight: any; } } };
    @ViewChild('fixedButton') remainingFooterForDesktop: { remaninigFooter: { nativeElement: { offsetHeight: any; } } };
    @ViewChild('checkBoxContainer') checkBoxContainer: ElementRef;
    buttonName: string;
    isMobileView: boolean;
    checkedLegalTerm: boolean;
    checkBoxTitle: string;

    constructor(public translateService: TranslateService,
        public appService: AppService,
        public smartPayService: SmartPayService,
        private storageService: StorageService,
        private smartPayPaymentService: SmartPayPaymentService,
        private tariffService: TariffService,
        private documentumService: DocumentumService,
        private newTagging: NewTaggingHelperService,
        private subscriptionService: SubscriptionService) { }

    ngOnInit(): void {
        this.setUIContent();
    }

    setUIContent(): void {
        this.isMobileView = window.innerWidth < Breakpoints.TABLET;
        this.translateService.get('v10.productsServices.smartPay').subscribe((data) => {
            this.buttonName = this.isSmartPay ? data.PyS.benefits.method.pay : data.purchase.easypay_active;
        });
    }

    ngAfterViewChecked(): void {
        if (this.smartPayService.showCheckboxAndFooter) {
            this.smartPayService.footerHeight = this.checkBoxContainer.nativeElement.offsetHeight +
                this.fixedButton.firstFooter.nativeElement.offsetHeight +
                this.remainingFooterForDesktop.remaninigFooter.nativeElement.offsetHeight;
        }
    }

    onClickActivateSmartPayTagging(): void {
        const interactionKey: string = !this.isSmartPay ? smartPayTagging.pago_facil_inactive_activate_pago_facil_click :
        smartPayTagging.pago_facil_active_guardar_click;
        const stateKey: string =  this.isSmartPay ? smartPayTagging.pago_facil_inactive : smartPayTagging.pago_facil_active;
        this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
          this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList'][stateKey]);
        });
      }

    onClickActivateSmartPay(elem?: HTMLElement): void {
        this.onClickActivateSmartPayTagging();
        this.smartPayService.isCTASubmitted = true;
        if (!this.checkedLegalTerm) {
            this.scrollDown(elem);
            return;
        }
        this.activateSmartPay(this.smartPayService.selectedCreditCard);
    }

    scrollDown(elem?: HTMLElement): void {
        setTimeout(() => {
            elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 100);
    }

    onCheckConditions(checked: boolean): void {
        this.checkedLegalTerm = checked;
    }

    createSmartPayRequestObject(selectedCreditCard: CreditCardModel): SmartPayCreatePaymentModel {
        const createSmartPayData: SmartPayCreatePaymentModel = {
            account: [
                {
                    id: this.subscriptionService.customerData.customerAccountsId
                }
            ],
            relatedParty: [
                {
                    id: this.msisdn,
                    href: API_URLS.SmartPayRelatedParty.subscriber + this.msisdn,
                    role: 'Subscriber'
                },
                {
                    id: this.translateDocumentType(this.storageService.userProfile.document.type) + '-' +
                        this.storageService.userProfile.document.id,
                    href: API_URLS.SmartPayRelatedParty.individual +
                        this.translateDocumentType(this.storageService.userProfile.document.type)
                        + '-' + this.storageService.userProfile.document.id,
                    role: 'Individual'
                },
                {
                    id: 'ONLINE',
                    href: API_URLS.SmartPayRelatedParty.user + 'ONLINE',
                    role: 'User'
                }
            ],
            type: 'tokenizedCardExtended',
            details: {
                brand: selectedCreditCard.brand,
                mask: selectedCreditCard.maskCreditCard,
                expirationDate: this.cardDateFormat(selectedCreditCard.creditCardExpiryDate),
                bank: 'CreditCard',
                token: selectedCreditCard.tokenCreditCard,
                characteristic: [
                    {
                        name: 'amount',
                        value: this.tariffService.Tariff.Fee
                    },
                    {
                        name: 'currency',
                        value: 'EUR'
                    }
                ]
            }
        }
        return createSmartPayData;
    }

    cardDateFormat(date: string): string {
        return smartPayCreditCardDateCommonValues.yearPrefix + date ?.substring(0, 2) +
            '-' + date ?.substring(2) + smartPayCreditCardDateCommonValues.dayOne
    }

    activeSmartPaySuccessOverlayTagging(): void {
        this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.state(
              data.page['stateList']['pago_facil_activated_screen']);
          });
    }
    activeSmartPayFailureOverlayTagging(): void {
        this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
            this.newTagging.state(
              data.page['stateList']['pago_facil_activate_failure_screen']);
          });
    }

    activateSmartPay(selectedCreditCard: CreditCardModel): void {
        this.translateService.get('v10.productsServices.smartPay.loadingMessage_desc').subscribe((data) => {
            this.smartPayService.loadingScreenText = this.isSmartPay ? '' : data;
        });
        this.smartPayService.showFullLoader = true;
        const smartPayObject: SmartPayCreatePaymentModel = this.createSmartPayRequestObject(selectedCreditCard);
        this.smartPayPaymentService.activateSmartPayPayment(smartPayObject, this.isSmartPay).subscribe((res) => {
            setTimeout(() => {
                this.smartPayService.showFullLoader = false
            }, 1000);
            this.smartPayService.showOverlay = false;
            this.smartPayService.showSuccessOrErrOverlay = true;
            this.activeSmartPaySuccessOverlayTagging();
            this.smartPayService.activateSmartPaySuccessfully = true;
            this.smartPayService.isActivateOverlay = true;
            this.setSuccessActivationOverlay(selectedCreditCard.maskCreditCard);
            this.generateDocument(selectedCreditCard.maskCreditCard);
        }, err => {
            setTimeout(() => {
                this.smartPayService.showFullLoader = false
            }, 1000);
            this.smartPayService.showOverlay = false;
            this.smartPayService.showSuccessOrErrOverlay = true;
            this.smartPayService.isActivateOverlay = true;
            this.smartPayService.setFailureOverlay(err);
            this.activeSmartPayFailureOverlayTagging();
        });
    }

    translateDocumentType(idType: string): string {
        switch (idType) {
            case smartPayIdTypes.Passport:
                return documentTypeTranslated.Passport;
            case smartPayIdTypes.NIE:
                return documentTypeTranslated.NIE;
            case smartPayIdTypes.NIF:
                return documentTypeTranslated.NIF;
            default:
                return documentTypeTranslated.other;
        }
    }

    getTicket(cardMask: string): string {
        const dateToSend: string = this.getActDate();
        const ticketTxt: string = `
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.title')}
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.client')}
    ${this.storageService.userProfile.firstName + ' ' + this.storageService.userProfile.lastName} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.doc')}
    ${this.storageService.userProfile.document.id} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.request')}
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.date')}
    ${dateToSend} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.line')}
    ${this.msisdn} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.tariff')}
    ${this.tariffService.Tariff.Name} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.fee')}
    ${this.tariffService.Tariff.Fee + this.tariffService.Tariff.Unit}
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.days')} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.cost')}
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.card')}
    ************ ${cardMask.substring(12, 16)} \n
    ${this.translateService.instant('v10.productsServices.smartPay.legal.accepts')} \n
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.signature_legal')}
    ${this.translateService.instant('v10.productsServices.smartPay.pdf.signature')}
    ${dateToSend}\n
    *************\n\n`
        return ticketTxt;
    }

    getActDate(): string {
        const dt: Date = new Date();
        const defDet: string = `${
            dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1).toString().padStart(2, '0')}/${
            dt.getFullYear().toString().padStart(4, '0')} ${
            dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
            dt.getSeconds().toString().padStart(2, '0')}`
        return defDet;
    }

    generateDocument(cardMask: string): void {
        const ticket: SmartPayPdfDescription = {
            description: this.getTicket(cardMask)
        }
        this.documentumService.GenerateDocument(
            this.subscriptionService.customerData.customerAccountsId,
            this.storageService.userProfile.document.id,
            ticket,
            'smartpay'
        ).subscribe((res) => { }
            , error => {
                throw error;
            });
    }

    setSuccessActivationOverlay(maskCreditCard?: string): void {
        this.translateService.get('v10.productsServices.smartPay').subscribe((data) => {
            if (this.isSmartPay) {
                this.smartPayService.deactivationSuccOrErrOverlay.titleOverlay = data.PyS.benefits.method.update.title;
                this.smartPayService.deactivationSuccOrErrOverlay.textOverlay =
                data.PyS.benefits.method.update.desc + maskCreditCard.substring(12, 16);
            } else {
                this.smartPayService.deactivationSuccOrErrOverlay.titleOverlay = data.activate.titleOK;
                this.smartPayService.deactivationSuccOrErrOverlay.textOverlay = data.activate.renovation;
            }
            this.smartPayService.deactivationSuccOrErrOverlay.primaryBtnText = data.PyS.benefits_spInact.button;
        });
        this.smartPayService.deactivationSuccOrErrOverlay.secundaryBtnText = '';
        this.smartPayService.deactivationSuccOrErrOverlay.closeBtn = true;
        this.smartPayService.overlayIcon = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-tick-hifi-light-theme@2x.png';
    }
}

import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit } from '@angular/core';
import { OverLayContent } from '../../../models/overlayContent.model';
import { AppService } from '../../../app.service';
import { Breakpoints } from '@mva10/mva10-angular';

@Component({
  selector: 'sp-full-over-lay-page',
  templateUrl: './full-over-lay-page.component.html',
  styleUrls: ['./full-over-lay-page.component.scss']
})
export class FullOverLayPageComponent implements OnInit {
  @Input() overLayContent!: OverLayContent;
  @Output() closeAction: EventEmitter<any> =  new EventEmitter<any>();
  @Output() btnAction: EventEmitter<any> =  new EventEmitter<any>();
  @ViewChild('pageContent') pageContent: ElementRef;
  @ViewChild('footer') footer: { firstFooter: { nativeElement: { offsetHeight: any; } } };

  remainingHeight: number;
  pageHeight: string = '0px';
  isMobileScrollable: boolean;
  isMobileView: boolean;
  breakpoint: Breakpoints;
  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.isMobileView = window.innerWidth < Breakpoints.TABLET;
  }

  close(): void {
    this.closeAction.emit();
  }
  btnClick(): void {
    this.btnAction.emit();
  }

  ngAfterViewChecked(): void {
    setTimeout( ()  => {
      this.remainingHeight = 0;
      const pageHeight: number = this.pageContent?.nativeElement?.offsetHeight + this.footer?.firstFooter?.nativeElement?.offsetHeight;
      this.remainingHeight = window.innerHeight - this.appService.bannerHeight - pageHeight;
      this.isMobileScrollable = this.remainingHeight < 0 ? true : false;
      this.pageHeight = pageHeight + 'px';
    }, 0)
}
}

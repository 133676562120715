export enum ServiceType {
  Postpaid = 'Mobile postpaid',
  Prepaid = 'Mobile prepaid',
  MbbPostpaid = 'MBB postpaid',
  MbbPrepaid = 'MBB prepaid',
  MbbHoliday = 'MBB Holiday',
  Landline = 'landline',
  Tv = 'tv',
  TxTv = 'txtv',
  TvOnline = 'TV Online',
  Internet = 'internet',
  ADSL = 'adsl',
  Fibre = 'fibre',
  Mobile = 'Mobile',
  Fixed = 'fixed',
  VodafoneOne = 'one',
  firstAdd = 'firstAdd',
  VodafoneEnTuCasa = 'Vodafone En Tu Casa',
  InternetFibra = 'Internet Fibra',
  MBB_Holiday = 'MBB Holiday',
  TV_online = 'TV Online',
  OneProfessional = 'OneProfesional',
  Adara = 'Vodafone En Tu Casa'
}

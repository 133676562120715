export class OpAddon {
  id: string;
  name: string;
  code: string;
  serviceType: string;
  type: string;
  description: string;
  groupAddon: string;
  costMonthly: string;
  costMonthlyCurrency: string;
  costMonthlyWithoutIVA: string;
  costMonthlyCurrencyWithoutIVA: string;
}

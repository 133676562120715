import { BillStatus } from '../shared/enums/billStatus.enum';
import { BillAlarmType } from '../shared/enums/bill-alarm-type.enum';

export class BillDetailsModel {
    subTotal: string;
    tax: string;
    total: string;
    status: BillStatus
    startDate: string;
    endDate: string;
    numberOfAlarms: number;
    taxes: Taxes[];
    categories: BillDetailsCategory[];
}

export class BillDetailsCategory {
    amount: number;
    isPurple: boolean;
    showIcon: boolean;
    name: string;
    notificationType: string; // alarm or help or another value
    categoryType: string;
    isNavigator: boolean;
    serviceNumber: string;
    notificationId: string;
    subItems?: BillDetailsCategory[];
    billShockChecked?: boolean;
    discounts?: BillDetailsCategory[];
    newOffer: boolean;
    firstIndex: boolean;
    lastIndex: boolean;
    nameQuotaHeader: string;
    typeAlarm: BillAlarmType;
    startDate: string;
    endDate: string;
    discountEndDateDescription?: string;
}
export class Taxes {
    type: string;
    tax: number;
    percentage: string;
    amount: number;

}
export class BillTicket {
    id: string;
    status?: string;
    description?: string;
    hasTicket?: boolean;
    billId?: string;
    billMonth?: string;
    billAmount?: number;
    creationDate?: string;
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class Mva10FullOverlayService {
  public closeSpMVA10FullOverLaySubject: Subject<any> = new Subject<any>();
  public openSpMVA10FullOverLaySubject: Subject<any> = new Subject<any>();
  _isOpened: boolean;
  _isOpenedFromRight: boolean;
  private overLayHistory = [];
  fullScreenScroll: boolean;
  constructor() { }
  open(openFromRight: boolean = false) {
    if (openFromRight) {
      this._isOpenedFromRight = true;
    } else {
      this._isOpened = true;
    }
  }
  close() {
    this.overLayHistory = [];
    this._isOpened = false;
    this._isOpenedFromRight = false;
    this.closeSpMVA10FullOverLaySubject.next();
  }
  // navigation boolean to enter with animation from right to left
  // it takes false if we need the compnent to enter from left to right
  showDynmaicComponent(component, navigation: boolean = true, openFromRight: boolean = false) {
    if (navigation) {
      // not to push new component in case of back navigation
      this.overLayHistory.push(component);
    }
    this.openSpMVA10FullOverLaySubject.next({ comp: component, navigation: navigation });
    this.open(openFromRight);
  }

  back() {
    if (this.overLayHistory.length > 1) {
      this.overLayHistory.pop();
      this.showDynmaicComponent(this.overLayHistory[this.overLayHistory.length - 1], false);
    }
  }
  ShowBackBtn(component): boolean {
    return this.overLayHistory.length > 1 && this.overLayHistory.indexOf(component) > 0;
  }

}

import { Injectable } from '@angular/core';

import {
  sharedServicesPegaParserZipHashMaps,
  sharedServicesPegaParserStringToHashMap,
  sharedServicesPegaParserHashMapToObject,
} from '../util';

import {
  SharedServicesPegaParserParseOptions,
  SharedServicesPegaParserZippedHashMap,
} from '../entity/shared-services-parser.entity';

@Injectable({ providedIn: 'root' })
export class SharedServicesPegaParserService {
  public parse(
    v1: string,
    v2: string,
    options: SharedServicesPegaParserParseOptions
  ): SharedServicesPegaParserZippedHashMap | Record<string, string> {
    const zipped: SharedServicesPegaParserZippedHashMap = sharedServicesPegaParserZipHashMaps(
      sharedServicesPegaParserStringToHashMap(v1, {
        separator: options.separator,
        isPegaPlatinum: options.isPegaPlatinum
      }),
      sharedServicesPegaParserStringToHashMap(v2, {
        separator: options.separator,
        isPegaPlatinum: options.isPegaPlatinum
      })
    );

    if (options.transformToObject) {
      return sharedServicesPegaParserHashMapToObject(zipped) as Record<string, string>;
    }

    return zipped as SharedServicesPegaParserZippedHashMap;
  }
}

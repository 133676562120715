export class Equipment {
    id: string;
    type: string;
    hasAppointment: string;
    routerPrice: string;
    router6Price: string;
    routerDecoPrice: string;
    routerDeco2Price: string;
    decoPrice: string;
    decoAutoPrice: string;
    deco2Price: string;
    deco2AutoPrice: string;
    changeRouterID: string;
    changeRouter6ID: string;
    changeRouterDecoID: string;
    changeDecoID: string;
    changeDecoAutoID: string;
    changeRouterDeco2ID: string;
    changeDeco2ID: string;
    changeDeco2AutoID: string;
    decoQuoteAmount: string;
}

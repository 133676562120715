
import { DmpService } from './shared/services/dmp.service';
import { AditionalLinesService } from './shared/services/aditional-lines.service';

import { LineServicesData } from './line-services/line-services.data';
import { LineServicesService } from './line-services/line-services.service';
import { CookieHelper } from './shared/utils/cookie-helper';
import { LoggerService } from './shared/utils/LoggerService';
import { RetryService } from './shared/services/retry.service';
import { PermissionsAndPreferencesResolver } from './my-account/premission-and-prefrences/permissions-and-perferences.resolver';
import { DeepLinkingService } from './shared/services/deep-linking.service';
import { MyAccountResolve } from './my-account/my-account.resolver';
import { ServiceSettingsData } from './service-settings/service-settings.data';
import { ServiceSettingsService } from './service-settings/service-settings.service';
import { SidemenuConfigService } from './shared/services/sidemenu-config.service';
import { UtilitiesService } from './shared/utils/utilities.service';
import { CustomerBillService } from './shared/services/customer-bill.service';
import { DashboardService } from './shared/services/dashboard.service';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { CustomHttpInterceptor } from './core/interceptors/custom-http.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { SpAccordionService } from './shared/components/sp-accordion/sp-accordion.service';
import { UpgradeProfileModule } from './upgrade-profile/upgrade-profile.module';
import { NotificationService } from './shared/services/notification.service';
import { CustomerAccountService } from './shared/services/customer-account.service';
import { CompanyService } from './shared/services/company.service';
import { DashboardResolve } from './dashboard/dashboard.resolver';
import { AppService } from './app.service';
import { MyAccountService } from './my-account/my-account.service';
import { ProductService } from './shared/services/product.service';
import { TariffService } from './shared/services/tariff.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { CrossFunctionalityService } from './shared/services/cross-functionality.service';
import { MdlSnackbarService, MdlDialogOutletService, MdlModule } from '@angular-mdl/core';

import { TealiumUtagService } from './tealium/utag.service';
import { ProductsAndServicesService } from './products-and-services/products-and-services.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CustomTranslateLoader } from './shared/utils/CustomTranslateLoader';
import { GeneralErrorHandler } from './shared/utils/GeneralErrorHandler';

export function createTranslateLoader(http: HttpClient) {
  return new CustomTranslateLoader(http, 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/i18n/', '.json');
}
import { NotificationInboxService } from './shared/services/notification-inbox.service';

import { CreditBalanceService } from './shared/services/credit-balance.service';
import { GrifoTimeService } from './shared/services/grifo-time.service';
import { TopUpService } from './top-up/top-up.service';
import { TopUpDataService } from './top-up/top-up-data.service';
import { APP_BASE_HREF, DecimalPipe } from '@angular/common';

import { baseURL, googleEnterprise } from './shared/constants/defines';
import { CacheInterceptor } from './shared/utils/cache-interceptor.service';
import { ErrorHandler } from '@angular/core';
import { SiteHandlingService } from './shared/services/site-handling.service';
import { ProductsAndServicesResolve } from './products-and-services/products-and-services.resolver';

import { TaggingInterceptor } from './shared/utils/tagging-interceptor.service';
import { ImpersonationService } from './shared/services/impersonation.service';
import { UserSettingsService } from './shared/services/user-settings.service';
import { LoginService } from './login/login.service';

import { CampaignService } from './shared/components/campaign-overlay/campaign-overlay.service';
import { ContactUsService } from './shared/services/contact-us.service';
import { FaultManagementService } from './fault-management/fault-management.service';
import { FaultManagementData } from './fault-management/fault-management.data';
import { RecommendationService } from './shared/services/recommendation.service';
import { TryAndBuyService } from './shared/services/try-and-buy.service';
import { OpProductsListService } from './shared/services/op-products-list.service';
import { DocumentumService } from './shared/services/documentum.service';

import { CommercialOffersService } from './shared/services/commercial-offers.service';
import { ProductConfigurationService } from './shared/services/product-configuration.service';
import { SpMva10OverlayService } from './shared/services/sp-mva10-overlay.service';
import { CheckoutCommercialService } from './shared/services/checkout-commercial.service';
import { CanjeService } from './shared/services/canje.service';
import { PurchaseProductsService } from './purchase-products/purchase-products.service';
import { PurchaseProductsData } from './purchase-products/purchase-products-data';
import { Mva10TopupLandingWithPciComponent } from './top-up/mva10-topup-landing-with-pci/mva10-topup-landing-with-pci.component';
import { BillingVf10Service } from './mva10/billing-vf10/billing-vf10.service';
import { BillingVf10Data } from './mva10/billing-vf10/billing-vf10-data';
import { Mva10FullOverlayService } from './shared/services/mva10-full-overlay.service';
import { BubbleHelpTaggingService } from './shared/components/bubble-help/bubble-help-tagging.service';
import { SuperwifiService } from './shared/services/superwifi.service';
import { FteServiceService } from './shared/services/fte-service.service';
import { CommercialMobileService } from './shared/services/commercial-mobile.service';
import { CommercialMobileDetailService } from './shared/services/commercial-mobile-detail.service';
import { TopUpNavigationService } from './top-up/top-up-navigation.service';
import { TopupPrepaidErrorOVerlayDataService } from './top-up/top-up-prepaid-error-overlay-data.service';
import { TryAndBuySetTaggingService } from './try-and-buy/try-and-buy-set-tagging.service';
import { OpSetTaggingService } from './op-products/op-set-tagging.service';
import { LegalModule } from './legal/legal.module';
import { GenericModule , CoreModule, OverlayFullScreenModule, OverlayModule,  SelectorBadgeModule} from '@mva10/mva10-angular';
import { AllowedServicesService } from './shared/services/allowed-services.service';
import { DelightPromotionService } from './shared/services/delight-promotion.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { EntryPointsService } from './shared/services/entry-points.service';
import { TiendaV10Service } from './shared/services/tienda-v10.service';
import { LeftMenuWs10Service } from './mva10/shared/services/left-menu-ws10.service';
import { ResizeService } from './mva10/shared/services/resize.service';
import { FederationErrorService } from './shared/services/federation-error.service';
import { DynamicTrayService } from './shared/services/dynamic-tray.service';
import { ServiceBalanceWs10Service } from './shared/services/service-balance-ws10.service';
import { SmartPayPaymentService } from './shared/services/smart-pay-payment.service';

// LIBRARY
import { Mva10FullModslServiceService } from './shared/services/mva10-full-modsl-service.service';
import { VProductsPurchaseService } from './products-and-services/v-products-purchase/v-products-purchase.service';
import { AppAvailabilityCheckInterceptor } from './shared/utils/app-availability-check-interceptor.service';
import { AppAvailabilityCheckService } from './shared/utils/app-availability-check.service';
import { PurchasingService } from './shared/services/purchasing.service';
import { VerifyEmailService } from './shared/services/verify-email.service';
import { Mva10SubHeaderService } from './shared/services/mva10-sub-header.service';
import { TicketsboxService } from './fault-management/ticketsbox.service';
import { BuySimService } from './buy-sim/services/buy-sim.service';
import { BuySimDataService } from './buy-sim/services/buy-sim-data.service';
import { PaymentTrayConfigService } from './payment/services/payment-tray-config.service';
import { PaymentNavigationService } from './payment/services/payment-navigation.service';
import { PaymentErrorService } from './payment/services/payment-error.service';
import { PaymentSuccessService } from './payment/services/payment-success.service';
import { PaymentIframeService } from './payment/services/payment-iframe.service';
import { PaymentDataService } from './shared/services/payment-data.service';
import { DelegateCreationService } from './payment/services/delegate-creation.service';
import { PaymentCancelService } from './payment/services/payment-cancel.service';
import { PaymentTaggingService } from './payment/services/payment-tagging.service';
import { MyAccountDataResolver } from './my-account/my-account-data.resolver';
import { NexusService } from './shared/services/nexus.service';
import { PaymentTopupLandingComponent } from './payment/payment-entry-points/payment-topup-landing/payment-topup-landing.component';
import { NormalizeAddressService } from './buy-sim/services/normalize-address.service';
import { RoamingHelper } from './Roaming/roaming-helper.service';
import { MicroFlowsService } from './shared/services/micro-flows.service';
import { BuySimDataHistoryService } from './buy-sim/services/buy-sim-data-history.service';
import { PaymentAuthenticationComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-authentication/payment-authentication.component';
import { PaymentBillingComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-billing/payment-billing.component';
import { PaymentOtpComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-otp/payment-otp.component';
import { PaymentWalletService } from './shared/services/payment-wallet.service';
import { CampaignPromotionsService } from './shared/services/campaign-promotions.service';
import { PaymentDisclaimerComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-disclaimer/payment-disclaimer.component';
import { CommercialPaymentService } from './shared/services/commercial-payment.service';
import { FinancedDevicesCancellationComponent } from './payment/payment-entry-points/financed-devices-cancellation/financed-devices-cancellation.component';

import { SmartPayModule } from './smart-pay/smart-pay.module';
import { SmartpayLandingModule } from './shared/components/smartpay-landing/smartpay-landing.module';
import { SpMiddlePageModule } from './sp-middle-page/sp-middle-page.module';
import { NotificationBadgeService } from './shared/services/notification-badge.service';
import { SmartPayService } from './smart-pay/services/smart-pay.service';
import { AdaraModule } from './adara/adara.module';
import { CoreMVAModule } from './core/core-mva.module';
import { TopUpCountDownComponent } from './payment/payment-entry-points/top-up-count-down/top-up-count-down.component';
import { SpendNewBalanceComponent } from './payment/payment-entry-points/spend-new-balance/spend-new-balance.component';
@NgModule({
  declarations: [
    AppComponent,
    PaymentAuthenticationComponent,
    PaymentBillingComponent,
    PaymentOtpComponent,
    PaymentDisclaimerComponent,
    FinancedDevicesCancellationComponent,
    TopUpCountDownComponent,
    SpendNewBalanceComponent,
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MdlModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreMVAModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    UpgradeProfileModule,
    CookieModule.forRoot(),
    AppRoutingModule,
    LegalModule,
    CoreModule,
    HttpClientJsonpModule,
    GenericModule,
    RecaptchaV3Module,
    OverlayFullScreenModule,
    OverlayModule,
    SelectorBadgeModule,
    RouterModule,
    SmartPayModule,
    SpMiddlePageModule,
    SmartpayLandingModule,
    AdaraModule
  ],
  providers: [
    DeviceDetectorService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.googleToken },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: googleEnterprise,
    },
    CookieHelper,
    LoggerService,
    { provide: ErrorHandler, useClass: GeneralErrorHandler, deps: [LoggerService] },
    HttpClient,
    {
      provide: APP_BASE_HREF,
      useValue: baseURL
    },
    CustomerBillService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TaggingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppAvailabilityCheckInterceptor,
      multi: true
    },
    SiteHandlingService,
    TealiumUtagService,
    NotificationService,
    CustomerAccountService,
    SidemenuConfigService,

    DashboardResolve,
    CompanyService,
    SpAccordionService,
    ProductsAndServicesService,
    AppService,
    MyAccountService,
    ProductService,
    RecommendationService,
    TryAndBuyService,
    OpProductsListService,
    DocumentumService,
    CommercialOffersService,
    CheckoutCommercialService,
    TariffService,
    CrossFunctionalityService,
    MdlSnackbarService,
    MdlDialogOutletService,
    UtilitiesService,
    CookieService,
    ServiceSettingsService,
    ServiceSettingsData,
    LineServicesService,
    LineServicesData,
    FaultManagementService,
    FaultManagementData,
    NotificationInboxService,

    MyAccountResolve,
    MyAccountDataResolver,
    DeepLinkingService,

    DashboardService,
    CreditBalanceService,
    GrifoTimeService,
    TopUpService,
    TopUpDataService,
    PermissionsAndPreferencesResolver,

    RetryService,
    ProductsAndServicesResolve,

    ImpersonationService,
    LoginService,
    UserSettingsService,
    ContactUsService,

    CampaignService,



    ProductConfigurationService,
    CanjeService,
    DmpService,

    SpMva10OverlayService,

    PurchaseProductsService,
    PurchaseProductsData,
    BillingVf10Service,
    BillingVf10Data,

    Mva10FullOverlayService,
    ResizeService,
    BubbleHelpTaggingService,
    AditionalLinesService,
    TopUpNavigationService,



    SuperwifiService,
    BubbleHelpTaggingService,

    FteServiceService,
    CommercialMobileService,
    CommercialMobileDetailService,
    TopupPrepaidErrorOVerlayDataService,
    TryAndBuySetTaggingService,
    OpSetTaggingService,
    Mva10TopupLandingWithPciComponent,

    AllowedServicesService,
    DelightPromotionService,
    EntryPointsService,
    TiendaV10Service,
    LeftMenuWs10Service,
    DynamicTrayService,
    Mva10SubHeaderService,
    DecimalPipe,
    ServiceBalanceWs10Service,
    FederationErrorService,
    VProductsPurchaseService,
    Mva10FullModslServiceService,

    AppAvailabilityCheckService,
    TicketsboxService,

    PurchasingService,
    BuySimService,
    BuySimDataService,
    NormalizeAddressService,
    VerifyEmailService,
    PaymentTrayConfigService,
    PaymentNavigationService,
    PaymentIframeService,
    PaymentTaggingService,
    PaymentDataService,
    PaymentErrorService,
    PaymentSuccessService,
    DelegateCreationService,
    PaymentCancelService,
    PaymentTopupLandingComponent,
    NexusService,
    RoamingHelper,
    MicroFlowsService,

    BuySimDataHistoryService,
    PaymentWalletService,
    CampaignPromotionsService,
    SmartPayPaymentService,
    NotificationBadgeService,

    CommercialPaymentService,
    SmartPayService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { SubscriptionService } from '../core/services/subscription.service';
import { CustomerAccountService } from '../shared/services/customer-account.service';
import { AppService } from '../app.service';
import { CustomerAccountNewModel } from '../models/customer-account.model';

@Injectable()
export class MyAccountDataResolver implements Resolve<any> {
  constructor(private customerAccount: CustomerAccountService, private subscription: SubscriptionService, private appService: AppService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CustomerAccountNewModel> {
    if (this.subscription.customerData.currentService) {
      return this.customerAccount
        .getMyData(this.subscription.customerData.currentService.siteId)
        .toPromise()
        .then(newAccountData => {
          if (newAccountData && newAccountData.name) {
            this.appService.initGretting(newAccountData.name);
            sessionStorage.setItem('mva10-LocalStore.key.firstName', newAccountData.name);
          } else {
            return newAccountData;
          }
        })
        .catch(() => null);
    } else {
      return null;
    }
  }
}

import {
  SharedServicesPegaParserHashMap,
  SharedServicesPegaParserStringToHashMapOptions,
  SharedServicesPegaParserError,
} from '../../entity/shared-services-parser.entity';

import { sharedServicesPegaAssertRegExp } from '../assert-reg-exp/shared-services-pega-assert-reg-exp.util';

export function sharedServicesPegaParserStringToHashMapOptionValidator(
  options?: SharedServicesPegaParserStringToHashMapOptions
): RegExp {
  const defaultSeparator: RegExp = /\|/g;

  if (options) {
    if ('separator' in options && sharedServicesPegaAssertRegExp(options.separator)) {
      return options.separator;
    }
  }

  return defaultSeparator;
}

export function sharedServicesPegaParserStringToHashMap(
  value: string,
  options?: SharedServicesPegaParserStringToHashMapOptions
): SharedServicesPegaParserHashMap {
  if (typeof value !== 'string') {
    throw new Error(SharedServicesPegaParserError.InvalidStringArgument);
  }

  const separator: RegExp = sharedServicesPegaParserStringToHashMapOptionValidator(options);
  const fragments: string[] = value.trim().split(separator);

  if (fragments.length === 1 && !options?.isPegaPlatinum) {
    throw new Error(SharedServicesPegaParserError.InvalidStringToParse);
  }

  const hashMap: SharedServicesPegaParserHashMap = new Map();

  for (let i = 0; i < fragments.length; i++) {
    hashMap.set(i, fragments[i]);
  }

  hashMap.set('separator', separator);

  return hashMap;
}

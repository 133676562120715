
import {map} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BundleType } from '../constants/defines';
import * as Routes from '../constants/routes-config';


@Injectable()
export class CreditBalanceService {

  constructor(private http: HttpClient) {}
  getServiceBalance(siteId, serviceId, bundleType = BundleType.inPlan) {
    const url = Routes.API_URLS.Dashboard.ServiceBalance.
    replace('{siteId}', siteId).
    replace('{serviceId}', serviceId).
    replace('{bundleType}', bundleType);

    let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((response: any) => {
        return response;
    }));


  }
}


export enum ProductType {
    Product = 'product',
    EnjoyMore = 'enjoy-more',
    WorryFree = 'worry-free',
    Channel = 'channel',
    OnlineTv = 'online-tv',
    Extras = 'extras',
    Content = 'content',
    OnlineTvPackage = 'online-tv-package',
    Promotion = 'promotion',
    secureNet = 'secure-net',
    TV = 'TV',
    netFlix = 'wcs-product',
    voucher = 'voucher_type'
}


export class AditionalLines {
    name: string;
    code: string;
    fee: string;
    netFee: string;
    discount: string;
    netDiscount: string;
    totalFee: string;
    totalNetFee: string;
    mainDescription: string;
    discountDescription: string;
    tariffDescription: string;
    summaryFeatures: {
        data: string,
        voice: string
    }
    detailFeatures: string[];
    speedIcon: string;
    summaryfeaturesData: string;
    summaryfeaturesVoice: string;
    secundaryDescription: string;
}

import { Injectable } from '@angular/core';
import { PaymentCancel, PaymentDeleteConfirmation } from '../../models/payment';
import { DelegateCreationService } from './delegate-creation.service';

@Injectable()
export class PaymentCancelService {

  currnetCancel: PaymentCancel = new PaymentCancel();
  deleteConfirmation: PaymentDeleteConfirmation = new PaymentDeleteConfirmation();
  constructor(private delegateService: DelegateCreationService) { }
  /**
   * function to fill cancel overlay with it's proper
   * wcs values and btn actions
   */
  fillCurrentCancelOverlay(): void {
    this.currnetCancel = this.delegateService.paymentCancelConfig.fillCancelOverlay();
  }

  /**
   * function to fill cancel overlay with it's proper
   * wcs values and btn actions
   */
  fillDeleteCredietCardConfirmationOverlay(): void {
    this.deleteConfirmation = this.delegateService.paymentCancelConfig.fillDeleteCredietCardConfirmationOverlay();
  }

  /**
   * function to fill contact list permission overlay with it's proper
   * wcs values and btn actions
   */
  fillContactListPermissionOverlay(): void {
    this.deleteConfirmation = this.delegateService.paymentCancelConfig.fillContactListPermissionOverlay();
  }
}

<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" [config]="{keyboard: false,backdrop : 'static'}">
    <div class="full modal-dialog row service" [ngClass]="{show:show,'white':(customerType||(customerAccounts?.length<=1&&!companies.length)),'nondashboard':nonDashoard}">
        <div class="modal-header">
            <span class="title">{{bodyTitle}}</span>
            <button id="SpServiceSelectorModalComp_btn_hide" type="button" class="close-container pull-right"
                aria-label="Close" (click)="hide()">
                <span class='icon icon-close modal-full-close'></span>
            </button>
        </div>
        <div class="modal-body" [ngClass]="statusInactiveError ?   'white-bg': '' ">
            <div class="head">

            </div>
            <div class="desc" *ngIf="!statusInactiveError" [ngClass]="{'only-radio':(customerAccounts?.length<=1)||customerType}">
                <div class="edit-cifs-width"*ngIf="companies.length>1||storage.userProfile.hasExternalCompanies">
                    <tabset type="pills">
                        <tab *ngFor="let company of companies" [active]="company.id===companyId" heading="CIF: {{company.id}}"
                            (select)="onClick(company)" #tab class="tab-content">
                            <sp-accordion-radio-button *ngIf="company.id===companyId" [userProfile]="userProfile"
                                [hasExternalServices]="storage.userProfile.hasExternalServices" [companyId]="companyId"
                                [customerAccounts]="customerAccounts" (radioEvent)="radioEvent($event)"
                                (showStatusInactiveError)="showStatusInactiveError($event)"></sp-accordion-radio-button>
                        </tab>
                        <tab heading="{{'common.itemsList.otherCIFs.body'|translate}}" *ngIf="storage.userProfile?.hasExternalCompanies" class="tab-content">
                            <accordion class="panel-group">
                                <div accordion-heading>
                                    <div class="item-content dropdown" (click)="siebleRedirect()">
                                        <span class="icon icon-location col-xs-2"></span>
                                        <span class="accordion-item col-xs-8">
                                            <p>{{'common.itemsList.manageOthers.body'|translate}}</p>
                                        </span>
                                        <span class="icon icon-chevron-right col-xs-1"></span>
                                    </div>
                                </div>
                            </accordion>
                        </tab>
                    </tabset>
                </div>

                <sp-accordion-radio-button *ngIf="companies.length<=1&&!storage.userProfile?.hasExternalCompanies"
                    [userProfile]="userProfile" [companyId]="companyId" [customerAccounts]="customerAccounts"
                    [hasExternalServices]="storage.userProfile.hasExternalServices" (radioEvent)="radioEvent($event)"
                    (showStatusInactiveError)="showStatusInactiveError($event)">
                </sp-accordion-radio-button>

                <div *ngIf="customerType" class="internal-description">

                    <p class="second-desc" [innerHTML]="">{{warningMessage}}</p>
                    <div class="form-group col-xs-10 col-xs-offset-1">
                        <button id="SpServiceSelectorModalComp_btn_primaryClick" type="button" class="button gray"
                            (click)="primaryButtonClick()">{{warningButtonMessage}}
                            <span *ngIf="light" class="icon icon-security"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="statusInactiveError" class="has-error">
                <div class="desc row" [ngClass]="">
                    <div class="warningMessage">
                        <div class="iconText">
                            <span class='icon icon-warning position-style'></span>
                            <div class="textWarning margin-bottom">
                                <p class="textLine1">{{ inactiveErrorMessage?.bodyContent }}</p>
                            </div>
                            <button id="SpServiceSelectorModalComp_btn_showStatusInactiveError_false" class="button red"
                                (click)="showStatusInactiveError([false])">{{ inactiveErrorMessage?.primaryButtonText }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of Error for inactive Error -->


        </div>

    </div>
</div>

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AditionalLines } from '../../models/aditional-lines.model';
import { BehaviorSubject } from 'rxjs';
import * as JsonQuery from 'jsonpath/jsonpath';
import { JSON_PATHS, AditionalLineType } from '../constants/defines';
@Injectable()
export class AditionalLinesService {

    public basicLines: AditionalLines[] = [];
    public unlimitedLines: AditionalLines[] = [];

    private selectedLines: AditionalLines[];
    private selectedLinesSubject = new BehaviorSubject([]);
    public selectedLines$ = this.selectedLinesSubject.asObservable();

    private totalFee: number;
    private totalFeeSubject = new BehaviorSubject(0);
    public totalFee$ = this.totalFeeSubject.asObservable();

    private totalNetFee: number;
    private totalNetFeeSubject = new BehaviorSubject(0);
    public totalNetFee$ = this.totalNetFeeSubject.asObservable();

    private totalDiscount: number;
    private totalDiscountSubject = new BehaviorSubject(0);
    public totalDiscount$ = this.totalDiscountSubject.asObservable();

    private totalNetDiscount: number;
    private totalNetDiscountSubject = new BehaviorSubject(0);
    public totalNetDiscount$ = this.totalNetDiscountSubject.asObservable();

    constructor(private translate: TranslateService) { }
    /**
    * Loads tariffs from WCS
    */
    loadAditionalLines() {
        if (this.basicLines.length === 0 && this.unlimitedLines.length === 0) {
            this.translate.get('migration.tarifas.itemlist').subscribe(msg => {
                msg.forEach(tariff => {
                    switch (tariff.ratetype) {
                        case AditionalLineType.basic:
                            this.basicLines.push(this.jsonToAditionalLinesModel(tariff));
                            break;
                        case AditionalLineType.unlimited:
                            this.unlimitedLines.push(this.jsonToAditionalLinesModel(tariff));
                            break;
                    }
                });
            });
            this.selectedLines = [];
            this.resetAmount();
        }
    }
    /**
    * Transforms a tariff to AditionalLines model
    * @param tariff any tariff from WCS json response
    */
    private jsonToAditionalLinesModel(tariff: any) {
        const model = new AditionalLines();
        model.name = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.name) || null;
        model.code = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.code) || null;
        model.fee = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.fee) || null;
        model.netFee = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.netFee) || null;
        model.discount = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.discount) || null;
        model.netDiscount = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.netDiscount) || null;
        model.totalFee = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.totalFee) || null;
        model.totalNetFee = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.totalNetFee) || null;
        model.summaryFeatures = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.summaryFeatures) || null;
        model.mainDescription = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.mainDescription) || null;
        model.discountDescription = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.discountDescription) || null;
        model.discountDescription = model.discountDescription.replace('$descuento', model.discount + '€');
        model.tariffDescription = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.tariffDescription) || null;
        model.detailFeatures = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.detailFeatures) || null;
        model.speedIcon = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.speedIcon) || null;
        model.summaryfeaturesData = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.summaryfeaturesData) || null;
        model.summaryfeaturesVoice = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.summaryfeaturesVoice) || null;
        model.secundaryDescription = JsonQuery.value(tariff, JSON_PATHS.AditionalLine.secundaryDescription) || null;
        return model;
    }
    /**
    * Method to add a selected line
    * @param code {string} tariff code
    */
    public addLine(code: string): void {
        const selectedLine = this.findLine(code);
        if (selectedLine) {
            this.selectedLines.push(selectedLine);
            this.calculateFees();
            this.refreshObservables();
        }
    }
    /**
    * Method to replace a line previously added
    * @param currentCode {string} tariff code
    * @param newCode {string} tariff code
    */
    public editLine(currentCode: string, newCode: string): void {
        const currentLine = this.findLine(currentCode);
        const newLine = this.findLine(newCode);
        if (currentLine && newLine) {
            this.selectedLines.splice(this.selectedLines.indexOf(currentLine), 1, newLine);
            this.calculateFees();
            this.refreshObservables();
        }
    }
    /**
    * Method to remove a line previously added
    * @param code {string} tariff code
    */
    public removeLine(code: string): void {
        const selectedLine = this.findLine(code);
        if (selectedLine) {
            this.selectedLines.splice(this.selectedLines.indexOf(selectedLine), 1);
            this.calculateFees();
            this.refreshObservables();
        }
    }
    /**
    * Returns a line
    * @param code {string} tariff code
    */
    private findLine(code: string): AditionalLines {
        const lines = [...this.basicLines, ...this.unlimitedLines];
        return lines.find(line => line.code === code) || null;
    }
    /**
    * Keeps observables updated
    */
    private refreshObservables() {
        this.selectedLinesSubject.next(this.selectedLines);
        this.totalFeeSubject.next(this.totalFee);
        this.totalNetFeeSubject.next(this.totalNetFee);
        this.totalDiscountSubject.next(this.totalDiscount);
        this.totalNetDiscountSubject.next(this.totalNetDiscount);
    }
    /**
    * Keeps the total amount updated
    */
    private calculateFees(): void {
        this.resetAmount();
        this.selectedLines.forEach(line => {
            this.totalFee += parseFloat(line.fee);
            this.totalNetFee += parseFloat(line.netFee);
            this.totalDiscount += parseFloat(line.discount);
            this.totalNetDiscount += parseFloat(line.netDiscount);
        });
    }
    private resetAmount(): void {
        this.totalFee = 0;
        this.totalNetFee = 0;
        this.totalDiscount = 0;
        this.totalNetDiscount = 0;
    }

}

export class ProductsNexusModel {
  public name: string;
  public code: string;
  public nmOrden?: number;
  public category?: string;
  public productId?: number;
  public serviceInstanceList?: Array<ServiceInstanceList>;
}

export interface ServiceInstanceList {
  instance: string;
  msisdn: string;
  parentItemId: string;
}

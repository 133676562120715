import { Injectable } from '@angular/core';
import { timer, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AppService } from '../../app.service';
import {
  ForceStringType, PaymentCompHistory,
  PaymentCompoentInputData, PaymentFullTrayModel, PaymentJourneyParams, PaymentNavigation, PaymentTrayNavigation
} from '../../models/payment';
import { PaymentLanguages } from '../../shared/enums/payment-languages.enum';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { PaymentTrayAction } from '../../shared/enums/payment-tray-action.enum';
import { PaymentJourny } from '../../shared/enums/payment-journy.enum';
import { PayWithOtherPaymentMethodsComponent } from '../pay-with-other-payment-methods/pay-with-other-payment-methods.component';
import { PaymentCancelComponent } from '../payment-cancel/payment-cancel.component';
import { PaymentDeleteCardConfiramtionComponent } from '../payment-delete-card-confiramtion/payment-delete-card-confiramtion.component';
import { PaymentCashDeskUserComponent } from '../payment-entry-points/payment-cash-desk-user/payment-cash-desk-user.component';
import { PaymentDeptComponent } from '../payment-entry-points/payment-dept/payment-dept.component';
import { PaymentInadvanceUserComponent } from '../payment-entry-points/payment-inadvance-user/payment-inadvance-user.component';
import { PaymentErrorComponent } from '../payment-error/payment-error.component';
import { PaymentFullLoaderComponent } from '../payment-full-loader/payment-full-loader.component';
import { PaymentIframeContainerComponent } from '../payment-iframe-container/payment-iframe-container.component';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';
import { PaymentWalletComponent } from '../payment-wallet/payment-wallet.component';
import { PaymentTicketComponent } from '../payment-ticket/payment-ticket.component';
import { DelegateCreationService } from './delegate-creation.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { PaymentTopupLandingComponent } from '../payment-entry-points/payment-topup-landing/payment-topup-landing.component';
import { PaymentAuthenticationComponent } from '../payment-entry-points/anonymous-entry-points/payment-authentication/payment-authentication.component';
import { PaymentBillingComponent } from '../payment-entry-points/anonymous-entry-points/payment-billing/payment-billing.component';
import { PaymentOtpComponent } from '../payment-entry-points/anonymous-entry-points/payment-otp/payment-otp.component';
import { PaymentDisclaimerComponent } from '../payment-entry-points/anonymous-entry-points/payment-disclaimer/payment-disclaimer.component';
import { PaymentSaveCreditCardComponent } from '../payment-entry-points/payment-save-credit-card/payment-save-credit-card.component';
import { PaymentCardComponent } from '../payment-entry-points/payment-card/payment-card.component';
import { FinancedDevicesCancellationComponent } from '../payment-entry-points/financed-devices-cancellation/financed-devices-cancellation.component';
import { PaymentClarifyUserComponent } from '../payment-clarify-user/payment-clarify-user.component';
import { TopUpCountDownComponent } from '../payment-entry-points/top-up-count-down/top-up-count-down.component';
import { SpendNewBalanceComponent } from '../payment-entry-points/spend-new-balance/spend-new-balance.component';
import { SmartPayAfterTopUpComponent } from '../../top-up/smart-pay-after-top-up/smart-pay-after-top-up.component';
@Injectable()
export class PaymentTrayConfigService {
  /* subject to fire when we need  to change the dynamic payment component */
  public paymentNavigationSubject: Subject<PaymentTrayNavigation> = new Subject<PaymentTrayNavigation>();
  /* array to keep history of the pushed component inside the payment tray */
  public paymentCompHistory: Array<PaymentCompHistory> = new Array<PaymentCompHistory>();
  /* show dynamic payment tray  */
  public showTray: boolean;
  /*  current Tray Configration */
  public currentTrayConfig: PaymentFullTrayModel = new PaymentFullTrayModel();
  /*  next  comp to push in the Tray Configration */
  public nextTrayConfig: PaymentFullTrayModel;
  /* show back btn inside the tray or not */
  public showBackBtn: boolean;
  debounceTime: number;
  /* mapping component name to the component it self */
  paymentPagesMapping: ForceStringType = {
    [PaymentPages.thirdPartyContainer as string]: PaymentIframeContainerComponent,
    [PaymentPages.ok as string]: PaymentSuccessComponent,
    [PaymentPages.ko as string]: PaymentErrorComponent,
    [PaymentPages.cancel as string]: PaymentCancelComponent,
    [PaymentPages.loading as string]: PaymentFullLoaderComponent,
    [PaymentPages.wallet as string]: PaymentWalletComponent,
    [PaymentPages.ticket as string]: PaymentTicketComponent,
    [PaymentPages.dept as string]: PaymentDeptComponent,
    [PaymentPages.inadvance as string]: PaymentInadvanceUserComponent,
    [PaymentPages.cashDesk as string]: PaymentCashDeskUserComponent,
    [PaymentPages.partialCashDesk as string]: PaymentCashDeskUserComponent,
    [PaymentPages.partialDept as string]: PaymentDeptComponent,
    [PaymentPages.otherPaymentMethods as string]: PayWithOtherPaymentMethodsComponent,
    [PaymentPages.deleteConfiramtion as string]: PaymentDeleteCardConfiramtionComponent,
    [PaymentPages.contactListPermission as string]: PaymentDeleteCardConfiramtionComponent,
    [PaymentPages.topupScreen as string]: PaymentTopupLandingComponent,
    [PaymentPages.financedDevicesTotal as string]: FinancedDevicesCancellationComponent,
    [PaymentPages.paymentAuthntication as string]: PaymentAuthenticationComponent,
    [PaymentPages.paymentAnonymousBilling as string]: PaymentBillingComponent,
    [PaymentPages.paymentOtp as string]: PaymentOtpComponent,
    [PaymentPages.paymentDisclaimer as string]: PaymentDisclaimerComponent,
    [PaymentPages.saveCreditCard as string]: PaymentSaveCreditCardComponent,
    [PaymentPages.cardPaymentCommercial as string]: PaymentCardComponent,
    [PaymentPages.PaymentClarifyUser as string]: PaymentClarifyUserComponent,
    [PaymentPages.TopupCountDownScreen as string]: TopUpCountDownComponent,
    [PaymentPages.HowToSpendNewBalance as string]: SpendNewBalanceComponent,
    [PaymentPages.SmartPayPromoAfterTopUp as string]: SmartPayAfterTopUpComponent,
  }
  /* the component which not destroyed and we can go back to them which reinitialize again */
  currentNotDestroyedComp: any = [];
  paymentCompData: PaymentCompoentInputData[];
  animationStartingDate: Date;
  constructor(private paymentNavService: PaymentNavigationService,
    public appService: AppService,
    private delegateService: DelegateCreationService) {
    this.paymentNavService.changePaymentCompSubject.pipe(
      debounce((data) => {
        return timer(this.getTimeToDebounce(data))
      })
    ).subscribe(({ compname, actionNeeded, compData, backSteps }) => {
      this.paymentCompData = compData;
      this.navigationActionHandler(compname, actionNeeded, backSteps);
    })

  }

  /**
   * function to set number to debounce if needed
   * used to wait for any navigation if the navigation manager here is animating component
   */
  getTimeToDebounce(paymentNavigation: PaymentNavigation): number {
    const diff: number =
      this.animationStartingDate ? Math.abs(new Date().getTime() - this.animationStartingDate.getTime() + this.debounceTime) : 1600;
    let debounceTime: number;
    if (!this.animationStartingDate || diff > 2000) {
      debounceTime = 0;
    } else if (diff > 0 && diff < 2000) {
      debounceTime = 2000 - diff;
    }
    if (this.isNaviagtionActionNeedTranstionTime(paymentNavigation)) {
      this.animationStartingDate = new Date(new Date().getTime() + debounceTime)
    }
    this.debounceTime = debounceTime;
    return debounceTime;
  }
  /**
   * @param _compName compnent name needed to be pushed
   * it show the tray and push it inside compHistory and
   * change the comp
   */
  push(_compName: PaymentPages): void {
    this.showTray = true;
    this.appService.openBubbleStopScroll = true;
    // here we need to pop the last component when push any new one component after
    // to treat it like an overlay
    if (this.paymentCompHistory[this.paymentCompHistory.length - 1]
      && this.delegateService.paymentTrayConfig.compnentToBeRemovedFromHistoryAfternavigation
        .indexOf(this.paymentCompHistory[this.paymentCompHistory.length - 1].compName) > -1) {
      this.paymentCompHistory.pop();
    }
    this.paymentCompHistory.push({ compName: _compName, comp: this.paymentPagesMapping[_compName], compData: this.paymentCompData });
    this.changeDynamicComponent(this.paymentPagesMapping[_compName], _compName, this.paymentCompHistory.length - 1, false);
  }
  /**
   * method to handle to go back for the previous component
   * if the back btn is not overrided
   */
  pop(step: number = null, animationOnBack = true): void {
    this.showTray = true;
    this.appService.openBubbleStopScroll = true;
    if (this.currentTrayConfig.paymentCustomConfig.BackBtnAction) {
      this.currentTrayConfig.paymentCustomConfig.BackBtnAction();
    }
    if (step && Math.abs(step) > 0) {
      step = Math.abs(step);
      this.paymentCompHistory.splice(this.paymentCompHistory.length - step, step)
    } else {
      this.paymentCompHistory.pop();
    }
    if (this.paymentCompHistory.length > 0) {
      const { comp, compName }: PaymentCompHistory = this.paymentCompHistory[this.paymentCompHistory.length - 1];
      this.changeDynamicComponent(comp, compName, this.paymentCompHistory.length - 1, true, animationOnBack);
    }
    else {
      this.close();
    }
  }

  /**
   * @param component the component need tp be shown
   * @param compName string have the comp name
   * @param goBack is to go back or forward
   */
  private changeDynamicComponent(component: any, compName: PaymentPages,
    compIndex: number, goBack = false, animationOnTranstion = true): void {
    let initComponantOnBack = true;
    this.nextTrayConfig = this.delegateService.paymentTrayConfig.getCurrentTrayConfig(compName);
    if (goBack && this.currentNotDestroyedComp && this.currentNotDestroyedComp.indexOf(compName) > -1) {
      initComponantOnBack = this.nextTrayConfig.paymentCustomConfig.initComponantOnBack
    }
    this.paymentNavigationSubject.next({ comp: component, animationOnTranstion, goBack, initComponantOnBack, compIndex });
  }

  /**
   * restart the payment tray
   * open the first opened component
   */
  restartJourney(compName?: PaymentPages): void {
    let comp: PaymentCompHistory
    if (compName) {
      comp = this.paymentCompHistory.find((comp) => {
        return comp.compName === compName;
      })
    } else {
      comp = this.paymentCompHistory[0];
    }
    const wcsJourneyKey: PaymentJourny = this.paymentNavService.WCSJourneyKey;
    setTimeout(() => {
      if (comp) {
        this.paymentNavService.openPaymentComponent(comp.compName, wcsJourneyKey, comp.compData);
      }
    }, 1000);
    this.paymentNavService.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.forceClose });
  }
  /**
   * restart the payment tray
   * open the first opened component
   */
  restartJourneyFromIframe(): void {
    const wcsJourneyKey: PaymentJourny = this.paymentNavService.WCSJourneyKey;
    const paymentJourneyParams: PaymentJourneyParams = this.paymentNavService.paymentJourneyParams;
    setTimeout(() => {
      this.paymentNavService.WCSJourneyKey = wcsJourneyKey;
      this.paymentNavService.openPaymentThirdParty(paymentJourneyParams);
    }, 1000);
    this.paymentNavService.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.forceClose });
  }
  /**
   * close tray if close action is overrided
   */
  close(): void {
    if (this.currentTrayConfig.paymentCustomConfig.closeBtnAction) {
      this.currentTrayConfig.paymentCustomConfig.closeBtnAction();
    } else {
      this.paymentNavService.closePayment();
    }
  }

  /**
   * mandatory close the payment tray
   */
  forceClose(): void {
    this.showTray = false;
    this.appService.openBubbleStopScroll = false;
    this.paymentCompHistory = new Array();
    this.currentNotDestroyedComp = new Array();
    this.animationStartingDate = new Date();
  }
  /**
   * change Language the payment tray
   */
  changeLanguage(): void {
    if (this.paymentNavService.paymentJourneyParams.language === PaymentLanguages.english) {
      this.currentTrayConfig.MVA10Config.title = this.currentTrayConfig.paymentCustomConfig.title_english;
    } else {
      this.currentTrayConfig.MVA10Config.title = this.currentTrayConfig.paymentCustomConfig.title_spanish;
    }

  }
  /**
   * @param compname comp name
   * @param actionNeeded PaymentTrayAction needed to be done
   */
  navigationActionHandler(compname: PaymentPages, actionNeeded: PaymentTrayAction, backSteps: number): void {
    switch (actionNeeded) {
      case PaymentTrayAction.changeComp:
        this.push(compname);
        break;
      case PaymentTrayAction.close:
        this.close();
        break;
      case PaymentTrayAction.forceClose:
        this.forceClose();
        break;
      case PaymentTrayAction.pop:
        this.pop(backSteps);
        break;
      case PaymentTrayAction.popWithoutAnimation:
        this.pop(0, false);
        break;
      case PaymentTrayAction.restart:
        this.restartJourney(compname);
        break;
      case PaymentTrayAction.restartFromIframe:
        this.restartJourneyFromIframe();
        break;
      case PaymentTrayAction.changeLanguage:
        this.changeLanguage();
        break;
    }
  }
  isNaviagtionActionNeedTranstionTime(paymentNavigation: PaymentNavigation): boolean {
    return (
      paymentNavigation.actionNeeded === PaymentTrayAction.changeComp ||
      paymentNavigation.actionNeeded === PaymentTrayAction.pop ||
      paymentNavigation.actionNeeded === PaymentTrayAction.popWithoutAnimation ||
      paymentNavigation.actionNeeded === PaymentTrayAction.close ||
      paymentNavigation.actionNeeded === PaymentTrayAction.forceClose
    )
  }

}

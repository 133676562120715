import { trigger, transition, style, animate, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Animation used for expandable content. Must be use algonside with an ngIf directive.
 *
 * Example: <div *ngIf="showContent" [@expandAnimation]>Hidden content</div>
 */
export const expandAnimation: AnimationTriggerMetadata = trigger('expandAnimation', [
  transition('void => *', [
    style({ height: 0, 'padding-top': 0, 'padding-bottom': 0, overflow: 'hidden' }),
    animate('0.3s ease', style({ height: '*', 'padding-top': '*', 'padding-bottom': '*' }))
  ]),
  transition('* => void', [
    style({ height: '*', 'padding-top': '*', 'padding-bottom': '*', overflow: 'hidden' }),
    animate('0.3s ease', style({ height: 0, 'padding-top': 0, 'padding-bottom': 0 }))
  ])
]);

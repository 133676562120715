import { Pipe, PipeTransform } from '@angular/core';
import { BusinessProductsListItem } from './../../models/product-detail.model';
import { UsersAccount } from '../enums/try-and-buy.enum';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

   transform(value: BusinessProductsListItem[], args?: string, usersAccount?: string): BusinessProductsListItem[] {
    let filterData: BusinessProductsListItem[];
    filterData = (usersAccount && usersAccount === UsersAccount.usersAccount) ?
     value.filter((data: BusinessProductsListItem) => data.email.toLowerCase().includes(args.toLowerCase())) :
     value.filter((data: BusinessProductsListItem) => data.title.toLowerCase().includes(args.toLowerCase()))

    return filterData;
  }

}

import { map } from 'rxjs/operators';
import { StorageService } from './core/services/storage.service';
import { BackNavigation } from './shared/enums/back-navigation.enum';
import { Injectable } from '@angular/core';
import { Subject } from '../../node_modules/rxjs';
import { chatBaseUrl, GENERAL_ERROR_CODES } from './shared/constants/defines';
import { UserSettingsService } from './shared/services/user-settings.service';
import { UserSettingToken } from './shared/enums/user-setting-token.enum';
import { TranslateService } from '@ngx-translate/core';
import { CrossFunctionalityService } from './shared/services/cross-functionality.service';
import { environment } from '../environments/environment';
import { tagging } from '../config/tagging-config';
import { NeedBubbleHelpTabs } from './shared/enums/need-bubble-help-tabs.enum';
import { CustomerType } from './shared/enums/customerType.enum';
import { GreetingModel, GreetingYouBitModel } from '@mva10/mva10-angular';
import { NotificationInboxService } from './shared/services/notification-inbox.service';
import { TypeUser } from './shared/enums/typeUser';
import { ServiceModel } from './models/service.model';
import { AppAvailabilityCheckService } from './shared/utils/app-availability-check.service';
import { SpSideMenuItem } from './shared/components/sp-sidemenu/sp-sidemenu.interface';
import { IMVA10TopBarIcon } from './models/mva10-top-bar.model';
import { ChatBotTokenRequest } from './models/chat-bot-token-request.model';


@Injectable()
export class AppService {
  public showHeaderwithCloseBtn: boolean;
  public iconHeaderAction: Function;
  public rightIconData: Array<IMVA10TopBarIcon>;
  public showLogoutTray: boolean;
  public logoutItem: SpSideMenuItem;
  public stepToNavigate: BackNavigation;
  public theme: string;
  public showBackButton;
  errorAvilabilityApp: boolean
  private _showFullAppLoader = false;
  onlineFlag: boolean = true;
  public openBubbleStopScroll: boolean;
  contingency: string;
  settingsSubject: Subject<any> = new Subject<any>();
  public chatUrl: string = null;
  public showLoadingIframe: boolean;
  public chatUrlShowError: boolean = false;
  public showGDPROverlay: boolean;
  public gdprOverlayLoaded: boolean;
  public subscriptinAPILoadEmmiter = new Subject();
  public waitUntilSubscription: boolean;
  openBubble = new Subject();
  closeNeedHelpBubble = new Subject();
  helpBubbleItemClicked: boolean;
  helpBubbleSelectedTab: NeedBubbleHelpTabs;
  timeOutError: boolean;
  imageBaseUrl = environment.imageBaseUrl;
  currentUrl: string;
  previousUrl: string;
  showLottieLoader: boolean = false;
  isAuthorizedAndBiztalkReplicaMode: boolean;
  public isAppFromWebBannerShown: boolean;
  public curentPageChangedEmitter = new Subject();
  mva10OverlaySubject: Subject<any> = new Subject<any>();
  showNewDashboard: boolean = false;
  typeUser: number;
  listSubcriptions: any;
  greetingData: GreetingModel;
  greetingDataYouBit: GreetingYouBitModel;
  notification: boolean = false;
  showErrorFullModal: boolean;
  public appHeaderTitle: string = ''
  public hiddenHeader = false;
  showTopBar: boolean = true;
  showAdviseApp: boolean;
  pageObject: object;
  public isMobileScrollable: boolean;
  private iconWhiteYu: string
  private iconWhite: string

  public childBrowserOn: boolean = true;
  public bannerCategory: string;

  frequency: number;
  loadBanner: boolean;

  navigatefromMessages: boolean = false;
  showEnglishOverlay: boolean;

  /** Flag to show/hide total amount of all sites overlay */
  showDebtTotalAmountOverlay: boolean;
  /** showVodaFullLoader: is a flag for show or hide the vodafone lottie full loader */
  showVodaFullLoader: boolean;
  /** Flag for letting app knows that back button is clicked, and we should reset it once navigation ends */
  backButtonClicked: boolean;

  isResponsiveView: boolean = false;

  confirmCrossFunction: Subject<any> = new Subject<any>();

  showLogo = false; // boolean To Show Logo while full Loading indicator
 spanishLanguage: string;
  // this flag to show or hide bubble chat
  showBubbleChat: boolean = true;
  /** varibale for setting taryloading height  */
  public trayLoadingMinmimHegiht?: number ;
  public trayLoadingEditUi?: boolean;
  fixedHeaderHeight: number = 0;
  bannerHeight: number = 0;
  applogingOut: Subject<any> = new Subject<any>();
  get showFullAppLoader(): boolean {
    return this._showFullAppLoader;
  }

  set showFullAppLoader(sFullLoader: boolean) {
    this._showFullAppLoader = sFullLoader;
  }

  constructor(private userSettingsService: UserSettingsService,
    private ts: TranslateService,
    private cross: CrossFunctionalityService,
    private storageService: StorageService,
    private notificationInboxService: NotificationInboxService,
    private appUnavailabilityInterceptor: AppAvailabilityCheckService) {
      this.appUnavailabilityInterceptor.onAppUnavailable.subscribe(() => {
        this.errorAvilabilityApp = true;
      })
    }
  getChatBotToken(wcsData) {
    return this.userSettingsService.getChatBotToken(wcsData).pipe(map((res: any) => {
      return res
    }))
  }
  getChatToken(
    siteId: string,
    serviceId: ServiceModel['id'],
    chatBotToken: string,
    WCSData: ChatBotTokenRequest,
    isDigital: boolean,
    chatType: UserSettingToken = UserSettingToken.ChatToken
  ): void {
    this.timeOutError = false;
    this.showLoadingIframe = true;
    this.chatUrl = null;
    this.chatUrlShowError = false;
    this.userSettingsService.chatToken = null;
    this.userSettingsService
      .getUserSettingsToken(chatType, siteId, serviceId, false, chatBotToken, WCSData, isDigital)
      .subscribe(() => {
        this.showLoadingIframe = false;
        if (this.userSettingsService.chatToken) {
          this.chatUrl = chatBaseUrl.replace('{0}', this.userSettingsService.chatToken).replace('{1}', WCSData.chatUrl);
        }
      }, (error) => {
        this.setTaggingChatAvailiability();
        this.chatUrlShowError = true;
        this.showLoadingIframe = false;
        if (error.error.ecode && error.error.ecode.toString() === GENERAL_ERROR_CODES.GATEWAY_TIMEOUT.toString()) {
          this.timeOutError = true;
        }
      })
  }

  setTaggingChatAvailiability() {
    this.storageService.needHelpBubbleVariables.asistente_availability = tagging.staticVariables.faqAvailability.unavailabe;
    this.storageService.needHelpBubbleVariables.faqs_availability = '';
  }

  showAndHideGDPROverlay(): void {
    if (this.storageService.userProfile) {
      if (!this.storageService.userProfile.showGDPROverlay) {
        this.gdprOverlayLoaded = true;
        this.showOrHideEnglishOverlay();
      } else {
        this.showGDPROverlay = !this.gdprOverlayLoaded;
      }
    } else {
      this.gdprOverlayLoaded = true;
      this.showOrHideEnglishOverlay();
    }
  }

  showOrHideEnglishOverlay(): void {
    let englishOverlayUsers: string[] = this.storageService.getLocalStorage('englishOverlayUsers');
    const userName: any = this.storageService.getLocalStorage('username');
    const showEnglishOverlay: boolean = englishOverlayUsers?.includes(userName);
    const userChoosedLanguage: string = window.navigator.language;
    this.ts.get('v10.dashboard.overlays.englishBackdrop').subscribe((data) => {
      const disallowedLanguageArray: string[] = data.disallowedLanguages;
      this.spanishLanguage = disallowedLanguageArray.find(a => a === userChoosedLanguage)
    })
    if (!showEnglishOverlay && !this.spanishLanguage) {
      if (!englishOverlayUsers) {
        englishOverlayUsers = [];
      }
      englishOverlayUsers.push(userName);
      this.storageService.setLocalStorage('englishOverlayUsers', englishOverlayUsers)
      this.showEnglishOverlay = true;
    }
    else {
      this.showEnglishOverlay = false;
    }
  }


  getImgFullPath(src: string) {
    if (src && (src.includes(this.imageBaseUrl) || src.includes('assets/') || src.includes('https://') || src.includes('http://'))) {
      return src;
    }
    return src ? this.imageBaseUrl + src : '';
  }

  NudgeBiztalkError(title?: string, description?: string, btnText?: string) {
    this.cross.hide();
    this.ts.get('login.messagesList.biztalkNudge').subscribe(data => {
      this.cross.nudgeTitle = title || data.title;
      this.cross.nudgeDescription = description || data.description;
      this.cross.showOneButton = true;
      this.cross.buttonText = btnText || data.confirmButton.text;
      this.cross.showRetryButton = false;
      this.cross.showNudge = true;
      this.cross.firstButtonAction = () => this.cross.showNudge = false;
    })
  }

  authorizedBiztalkLogic() {
    if (this.isAuthorizedAndBiztalkReplicaMode) {
      this.ts.get('myAccount.messagesList.biztalkNudgeME').subscribe(data => {
        this.NudgeBiztalkError(data.title, data.description, data.confirmButton.text);
      })
    }
  }
  isSMEUser() {
    return this.storageService.userProfile && this.storageService.userProfile.customerType &&
      this.storageService.userProfile.customerType.toLowerCase() === CustomerType.SME.toLowerCase();
  }

  initGretting(firstName) {
    this.notificationInboxService.notificationInboxCount = null;
    this.notificationInboxService.getNotificatoinCount();
    this.greetingData = null;
    this.greetingData = new GreetingModel();
    this.ts.get('v10.dashboard.images.logos').subscribe(result => {
      this.greetingData.icon = this.getImgFullPath(result.logo_vf_img);
      this.iconWhiteYu = this.getImgFullPath(result.logo_white_vf_img);
      this.greetingData.flag = JSON.parse(result.flagAnimation);
  });
    this.ts.get('v10.dashboard.common.greeting').subscribe(result => {
      this.greetingData.message1 = result.gmorning;
      this.greetingData.message2 = result.gafternoon;
      this.greetingData.message3 = result.gnights;
      this.greetingData.userName = this.capitalizeText(firstName);

      setTimeout(() => {
          const _notifications = this.notificationInboxService.cachedVersion();

          if (_notifications !== null) {
                this.greetingData.notification = _notifications.toString();
                this.notification = (_notifications > 0) ? true : false;
          }
      }, 1000);

    });

    this.greetingDataYouBit = null;
    this.greetingDataYouBit = new GreetingYouBitModel();
    this.ts.get('v10.dashboard.images.yu_bit').subscribe(result => {
      this.greetingDataYouBit.icon = this.getImgFullPath(result.yu_bit_img);
      this.iconWhite = this.getImgFullPath(result.yu_bit_white_img);
      this.greetingDataYouBit.flag = JSON.parse(result.yu_bit_flagAnimation);
    });

    this.ts.get('v10.dashboard.common.yu_bit').subscribe(result => {
      this.greetingDataYouBit.message = result.hey;
      this.greetingDataYouBit.lastMessage = result.exclamation_mark;
    });
    this.checkFormats()

  }

  initGetUserInit(subcriptions) {
    this.typeUser = TypeUser.Zero;
    this.listSubcriptions = subcriptions;
    const itemFirst = this.listSubcriptions.items[0];

    if (itemFirst) {
      if (itemFirst.digital && itemFirst.parts[0].customerAccounts[0].digital) {
          this.typeUser = TypeUser.One;
      } else if (!itemFirst.digital && itemFirst.parts[0].customerAccounts[0].digital) {
          this.typeUser = TypeUser.Two;
      } else if (itemFirst.digital) {
          this.typeUser = TypeUser.Three;
      }
    }
  }

  initGetUser(subcriptions) {
    this.typeUser = TypeUser.Zero;
    this.listSubcriptions = subcriptions;
    const auxDigital = [];
    const auxIsDigital = [];

    this.listSubcriptions.items.map( (item: any) => {
      const digital =
            (item.digital && item.digital === true) ? true : false;
      auxDigital.push(digital);

      const isDigital =
            (item.parts[0].customerAccounts[0].digital && item.parts[0].customerAccounts[0].digital === true) ? true : false;
      auxIsDigital.push(isDigital);
    });

    const typeDigital = auxDigital.findIndex( (element: any) => element === false);
    const typeIsDigital = auxIsDigital.findIndex( (element: any) => element === false);

    if (typeDigital === -1 && typeIsDigital === -1) {
            this.typeUser = TypeUser.One;
    } else if (typeDigital !== -1 && typeIsDigital === -1) {
            this.typeUser = TypeUser.Two;
      } else {
        const getUserMixed = auxDigital.findIndex( (element: any) => element === true);
        if (getUserMixed !== -1) {
              this.typeUser = TypeUser.Three;
        }
    }
  }

  /**
   * check that all digital values in items array in subscriptions api are true to be 100% yu user
   */
  checkDigitalValueToGetYuUser(): boolean {
    const allDigital: Boolean[] = [];
    this.listSubcriptions.items.map((item) => {
      const digital: boolean = (item.digital && item.digital === true) ? true : false;
      allDigital.push(digital);
    });
    const notAllDigital: number = allDigital.findIndex((element) => element === false);
    return notAllDigital === -1 ? true : false;
  }

  private capitalizeText(text: string): string {
    return (text || '')
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  checkYuUser(): boolean {
    if (this.typeUser === 1 || this.typeUser === 3 ) {
      return true;
    } else  {
      return false;
    }
  }
  // function to collect all overlays need to be closed if we are loging user out
  emitApplogoutSubject(): void {
    this.applogingOut.next();
  }

  checkFormats(): void {
    if (this.greetingData.flag) {
      this.greetingData.flag =  this.greetingData.icon.includes('.json') ? this.greetingData.flag : undefined
    }
    else {
      this.greetingData.flag = this.greetingData.icon.includes('.png')  ? this.greetingData.flag : undefined
    }
    if (this.greetingDataYouBit.flag) {
      this.greetingDataYouBit.flag =  this.greetingDataYouBit.icon.includes('.json') ? this.greetingDataYouBit.flag : undefined
    }
    else {
      this.greetingDataYouBit.flag = this.greetingDataYouBit.icon.includes('.png')  ? this.greetingDataYouBit.flag : undefined
    }
  }

  changeIconColor(): void {
      this.greetingData.icon = this.iconWhite;
      this.greetingDataYouBit.icon = this.iconWhiteYu ;
    }
}

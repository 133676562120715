import { DecoEquipment } from './decoEquipment.model';
import { PlanType } from './../shared/enums/plan-type.enum';
import { BenefitStatus } from './../shared/enums/benefit-status.enum';
import { ServiceType } from '../shared/enums/serviceType.enum';
import { TariffStatus } from '../shared/enums/tariffStatus.enum';
import { RoamingMessageType } from '../shared/enums/roamingMessageType.enum';
import { Address } from './Address.model';
import { SuperWifi } from './super-wifi.model';
import { Equipment } from './equipment.model';
import { SecureNet, SecureNetFamily } from './secure-net.model';


export class Tariff {
  public Name: string;
  public Email: string;
  public Id: string;
  public Code: string; // The primary key of the tariff
  public serviceType: ServiceType;
  public Status: TariffStatus;
  public statusSBA: string;
  public Fee: string; // oop sprint 3
  public Unit: string; // oop sprint 3
  public RoamingMessage: string; // The roaming message if the tariff plan is compatible with roaming
  public RoamingMessageType: RoamingMessageType;
  public Yu: boolean; // A flag that indicates whether the tariff plan is youth compatible or not to display message depending on this
  public Xs: boolean; // A flag that indicates whether the tariff plan is Xs
  public Address: Address;
  public ShortDescription: string;
  public LongDescription: string;
  public DownloadSpeed: string;
  public DownloadSpeedUnit: string;
  public UploadSpeed: string;
  public UploadSpeedUnit: string;
  public RouterName: string;
  public RouterSerialNumber: string;
  public RouterType?: string;
  public HasPendingChannel: string; // The flag indicates if the TV has a pending channel to be added
  public NumberOfChannels: string; // Number of the channels this TV service has
  public OnlineStatus: string; // The online TV status of the TV service
  public Channels: string; // List of channels TV Service has
  public group: string;
  public lastStatus: String;
  public segments: string[];
  public benefitStatus?: BenefitStatus;
  public planType?: PlanType;
  public topupPeriodicity: string;
  public benefitDescription: string;
  public benefitExpiryDate?: string;
  public benefitRenewalDate?: string;
  public hasBenifits?: boolean;
  public SuperOffer?: string;
  public isTotalMobility?: boolean;
  public superWifi?: SuperWifi;
  public secureNet?: SecureNet;
  public secureNetFamily?: SecureNetFamily;
  public mercury?: boolean;
  public equipment?: Equipment;
  public decoEquipment?: DecoEquipment[];
  public vouchers?: Voucher[];
  public isPrepaidCommunity?: boolean;
  public promotions?: TariffPromotion[];
  public isElegible?: boolean;
  public smartPay?: boolean;
  public devices?: Devices;
}

export class Devices {
  public id: string;
  public autoInstalable?: boolean;
  public name?: string;
  public sap?: string
}

export class Voucher {
  public type: string;
  public status: string;
  public serviceId?: string;
  public voucher?: string;
  public endDate?: string;
}

export class TariffPromotion {
  public activationDate: Date;
  public deactivationDate: Date;
  public code: string;
  public fee: number;
  public name: string;
  public status: string;
}

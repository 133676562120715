export enum BillType {
  bundle = 'bundle',
  sba = 'SBA',
  other = 'Other'
}

export enum BillChargesType {
  // Some types may be missing
  bundle = 'bundle',
  sva = 'SVA',
  discount = 'Discount'
}

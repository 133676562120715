

<div class="skeleton-wrapper-section" [ngStyle]="{'min-height': appservice?.trayLoadingMinmimHegiht + 'vh'}"
[ngClass]="{'edit-loading-ui': appservice?.trayLoadingEditUi}">
  <div class="img-skeleton"></div>
  <section class="content-container-skeleton">
    <div class="content-skeleton first-content"></div>
    <div class="content-skeleton  second-content"></div>
  </section>
  <div class="btn button-skeleton-primary"></div>
  <div class="btn btn-position"></div>
  <div class="btn button-skeleton-primary mobile-only" *ngIf="appservice.trayLoadingMinmimHegiht"></div>
  <div class="btn mobile-only" *ngIf="appservice.trayLoadingMinmimHegiht"></div>
</div>


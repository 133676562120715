
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BillingVf10Data } from './billing-vf10-data';
import { JSON_PATHS } from '../../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import * as constants from '../../shared/constants/defines';
import { OutOfBundle } from '../../models/out-of-bundle.model';
import { OutOfBundleModel } from '../../models/out-of-bundle-model.model';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { DiscountTypeByTime } from '../../shared/enums/discounts-type-by-time.enum';
import { BillDetailsCategoryType } from '../../shared/enums/bill-details-cat-type.enum';
import { BillAlarmType } from '../../shared/enums/bill-alarm-type.enum';
import { Billing, Subscription } from '../../models/billing.model';
import { BillDetailsCategory, BillDetailsModel, BillTicket } from '../../models/bill-details.model';
import { ALARMS_TYPE_CONFIG } from '../shared/constants/alarms-config-ws10';
import { CustomerAccount } from '../../models/customer-account.model';
import { SiteStatus } from '../../shared/enums/siteStatus.enum';
import { ConfigurationService } from '../../core/services/configuration.service';
import { StorageService } from '../../core/services/storage.service';
import { BillingService } from '../../billing/billing.service';
import { UtilityService } from '../../core/services/utility.service';
import { ReviewBillingJourney } from '../../models/configuration.model';
import { Observable } from 'rxjs';
import { CustomerAccountService } from '../../shared/services/customer-account.service';
import { BillingTicketStatus } from '../../shared/enums/billing-ticket-status.enum';

@Injectable()
export class BillingVf10Service {
    public customerCoute: number;
    serviceBundles: OutOfBundle;
    downloadBill: boolean = false;
    isBillReviewEligable: boolean;
    openedTicket: BillTicket;
    showTicketsCardInLanding: boolean;
    billingTickets: BillTicket[];
    constructor(
        private billingVf10Data: BillingVf10Data,
        public configurationService: ConfigurationService,
        public storageService: StorageService,
        public billingService: BillingService,
        public utilsService: UtilityService,
        private customerAccountService: CustomerAccountService
    ) { }

    GetCustomerCoute(siteId: string): Observable<number> {
        return this.billingVf10Data.getCustomerAccount(siteId).pipe(map((res) => {
            this.customerCoute = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionDecimalAmount);
            return this.customerCoute ? this.customerCoute : 0;
        }
        ));
    }

    public getBalanceForService(siteId: string, bundleType: string = constants.BundleType.outOfPlan): Observable<void> {
        this.serviceBundles = new OutOfBundle();
        this.serviceBundles.bundles = new Array<OutOfBundleModel>();
        return this.billingVf10Data.getBalanceForService(siteId, bundleType).pipe(map((response) => {
            this.serviceBundles.totalAmount = 0;
            this.serviceBundles = new OutOfBundle();
            this.serviceBundles.bundles = new Array<OutOfBundleModel>();
            response.items.forEach(el => {
                if (el.subscriptions && (el.subscriptions[0].type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
                    el.subscriptions[0].type.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
                    el.subscriptions[0].type.toLowerCase() === ServiceType.MbbPostpaid.toLowerCase() ||
                    el.subscriptions[0].type.toLowerCase() === ServiceType.Landline.toLowerCase() ||
                    el.subscriptions[0].type.toLowerCase() === ServiceType.Prepaid.toLowerCase())) {
                    if (this.serviceBundles.bundles.length > 0) {

                        let service: OutOfBundleModel = new OutOfBundleModel();
                        service = this.serviceBundles.bundles.find((item) => item.id === el.subscriptions[0].id);

                        if (service) {
                            if (service.id === el.subscriptions[0].id) {

                                service.amount += this.getNumber(el.amount.amount.toString());
                            }
                        } else {
                            service = new OutOfBundleModel();
                            service.amount = this.getNumber(el.amount.amount.toString());
                            service.name = el.subscriptions[0].name;
                            service.id = el.subscriptions[0].id;
                            service.type = el.subscriptions[0].type;

                            this.serviceBundles.bundles.push(service)
                        }
                    }
                    else {
                        const service: OutOfBundleModel = new OutOfBundleModel();
                        service.amount = this.getNumber(el.amount.amount.toString());
                        service.name = el.subscriptions ? el.subscriptions[0].name : '';
                        service.id = el.subscriptions ? el.subscriptions[0].id : '';
                        service.type = el.subscriptions ? el.subscriptions[0].type : '';
                        this.serviceBundles.bundles.push(service)
                    }
                }
            })
        }))
    }

    public getNumber(amount: string): number {
        return +amount.replace(',', '.');
    }

    public getAliasBill(bill: Billing): string {
        let alias: string = '';

        const subcriptionMobile: Subscription = bill.subscriptions.find(subscription =>
            subscription.type.trim().toLowerCase() === ServiceType.Mobile.trim().toLowerCase());
        const subcriptionLandLine: Subscription = bill.subscriptions.find(subscription =>
            subscription.type.trim().toLowerCase() === ServiceType.Landline.trim().toLowerCase());
        const subcriptionInternet: Subscription = bill.subscriptions.find(subscription =>
            subscription.type.trim().toLowerCase() === ServiceType.Internet.trim().toLowerCase());
        const subcriptionTv: Subscription = bill.subscriptions.find(subscription =>
            subscription.type.trim().toLowerCase() === ServiceType.Tv.trim().toLowerCase());

        if (bill.billingAccount?.alias) {
            alias = bill.billingAccount.alias;
        } else if (subcriptionMobile) {
            alias = subcriptionMobile.id;
        } else if (subcriptionLandLine) {
            alias = subcriptionLandLine.id;
        } else if (subcriptionInternet) {
            alias = subcriptionInternet.id;
        } else if (subcriptionTv) {
            alias = subcriptionTv.id;
        } else {
            alias = bill.billingAccount.id;
        }

        return alias;
    }

    /**
     * Returns true if there is a tariff change in the specified bill
     * @param selectedBill Bill to check
     */
    isTariffChangeForDicounts(selectedBill: Billing): boolean {
        const subItems: BillDetailsCategory[] = [];
        selectedBill.details.categories.forEach(cat => subItems.push(...cat.subItems));
        return subItems.filter(discount => ALARMS_TYPE_CONFIG.tariffChange.includes(discount.typeAlarm)).length > 0;
    }

    /**
     * Returns true if there are new bought products in the specified bill
     * @param selectedBill Bill to check
     */
    checkBillHasNewProducts(selectedBill: Billing): boolean {
        const subItems: BillDetailsCategory[] = [];
        selectedBill.details.categories.forEach(cat => subItems.push(...cat.subItems));
        return subItems.filter(discount => ALARMS_TYPE_CONFIG.newProduct.includes(discount.typeAlarm)).length > 0;
    }

    /**
     * Returns true if there is any tariff update in the specified bill
     * @param selectedBill Bill to check
     */
     checkBillHasTariffUpdates(selectedBill: Billing, typeAlarm?: string): boolean {
        const subItems: BillDetailsCategory[] = [];
        selectedBill.details.categories.forEach(cat => subItems.push(...cat.subItems));
        if (!typeAlarm) {
            return subItems.some(item => ALARMS_TYPE_CONFIG.tariffUpdate.includes(item.typeAlarm));
        } else {
            return subItems.some(item => typeAlarm === item.typeAlarm);
        }
    }

    /**
     * Extracts and filters the disounts from a bill based on the specified type of discount.
     * Returns an array with the filtered discounts or an empty array if no match found.
     * @param selectedBill Bill with the desired discounts to filter
     * @param getTypeDiscounts Type of discount we want the function to return
     */
    public getTimeTypeDiscounts(selectedBill: Billing, getTypeDiscounts: DiscountTypeByTime): BillDetailsCategory[] {
        const discountsArray: BillDetailsCategory[] = [];

        // filtering for discountsSubItems
        selectedBill.details.categories.forEach(cat => {
            if (cat.categoryType.toLowerCase() === BillDetailsCategoryType.discount && cat.subItems.length > 0) {
                discountsArray.push(...cat.subItems);
            }
        });

        const discountsArraySorted: BillDetailsCategory[] = this.sortDiscounts(discountsArray);

        // filter discounts with endDate between startDate and endDate of the selectedBill
        return discountsArraySorted.filter(discount => {
            const isFinishedThisMonth: boolean =
                discount.typeAlarm === BillAlarmType.lastMonthDiscount ||
                discount.typeAlarm === BillAlarmType.lastMonthDiscountPaymentInAdvance;
            switch (getTypeDiscounts) {
                case DiscountTypeByTime.finishedThisMonth:
                    return isFinishedThisMonth;
                case DiscountTypeByTime.currentsDiscounts:
                    return !isFinishedThisMonth;
                default:
                    return false;
            }
        }
        );
    }

    /**
     * Sort discounts array takingthe first ones with the close date and in case of same date alphabetically
     * @param discountsArray Array with all the discounts
     */
    sortDiscounts(discountsArray: BillDetailsCategory[]): BillDetailsCategory[] {
        let discountsSorted: BillDetailsCategory[] = [];
        discountsSorted = [...discountsArray].sort((discountA, discountB) => {
            if (discountA.endDate && !discountB.endDate) {
                return -1;
            }
            if (!discountA.endDate && discountB.endDate) {
                return 1;
            }
            if (discountA.endDate && discountB.endDate) {
                const dateA: string[] = discountA.endDate.split('/');
                const dateB: string[] = discountB.endDate.split('/');
                const formattedA: string = `${dateA[1]}/${dateA[0]}/${dateA[2]}`;
                const formattedB: string = `${dateA[1]}/${dateB[0]}/${dateB[2]}`;
                if (Date.parse(formattedA) < Date.parse(formattedB)) {
                    return -1;
                }
                if (Date.parse(formattedA) > Date.parse(formattedB)) {
                    return 1;
                }
                else {
                    if (discountA.name < discountB.name) {
                        return -1;
                    }
                    if (discountA.name > discountB.name) {
                        return 1;
                    }
                    return 0;
                }
            }
            else {
                if (discountA.name < discountB.name) {
                    return -1;
                }
                if (discountA.name > discountB.name) {
                    return 1;
                }
                return 0;
            }
        });
        return discountsSorted;
    }

    /**
     * Returns the sum of all extra consumption from an array of bill details categories
     * @param categoriesArray Array with all the categories from a bill
     */
    getTotalBillChanges(categoriesArray: BillDetailsCategory[], ignoreTariffChanges?: boolean, ignoreNewProducts?: boolean): number {
        let result: number = 0;
        categoriesArray.forEach((cat: BillDetailsCategory) => {
            if (cat.categoryType.toLowerCase() === BillDetailsCategoryType.basic) {
                cat.subItems.forEach((billBasicDetail: BillDetailsCategory) => {
                    if (
                        (!ignoreNewProducts && ALARMS_TYPE_CONFIG.newProduct.includes(billBasicDetail.typeAlarm)) ||
                        (!ignoreTariffChanges && ALARMS_TYPE_CONFIG.tariffChange.includes(billBasicDetail.typeAlarm))
                    ) {
                        result +=
                            billBasicDetail.amount +
                            (billBasicDetail.discounts || []).reduce((res: number, disc: BillDetailsCategory) => res + disc.amount, 0);
                    }
                });
            } else if (cat.categoryType.toLowerCase() === BillDetailsCategoryType.other) {
                cat.subItems.forEach((billOtherDetail: BillDetailsCategory) => {
                    if (
                        billOtherDetail.typeAlarm === BillAlarmType.refund ||
                        billOtherDetail.typeAlarm === BillAlarmType.thirdPartiesPayments ||
                        billOtherDetail.typeAlarm === BillAlarmType.premiumPayments
                    ) {
                        result += billOtherDetail.amount;
                    }
                });
            } else if (cat.amount && cat.categoryType.toLowerCase() === BillDetailsCategoryType.outofbundle) {
                result += cat.amount;
            }
        });
        return result;
    }

    /**
     * Returns an array with all the out of bundle consumption from an array of bill details categories
     * @param categoriesArray Array with all the categories from a bill
     */
    getOutOfBundle(categoriesArray: BillDetailsCategory[]): BillDetailsCategory[] {
        const outOfBundleArray: BillDetailsCategory[] = [];
        categoriesArray.forEach(cat => {
            if (cat.categoryType.toLowerCase() === BillDetailsCategoryType.outofbundle && cat.subItems.length > 0) {
                outOfBundleArray.push(...cat.subItems);
            }
        });
        return outOfBundleArray;
    }

    /**
     * Returns an array with all the refunds/creditNotes from an array of bill details categories
     * @param categoriesArray Array with all the categories from a bill
     */
    getRefunds(categoriesArray: BillDetailsCategory[]): BillDetailsCategory[] {
        const refundsArray: BillDetailsCategory[] = [];
        categoriesArray
            .filter(cat => cat.categoryType.toLowerCase() === BillDetailsCategoryType.other)
            .forEach(otherCat => {
                otherCat.subItems.forEach(otherCatDetail => {
                    if (otherCatDetail.typeAlarm === BillAlarmType.refund) {
                        refundsArray.push(otherCatDetail);
                    }
                });
            });
        return refundsArray;
    }

    /**
     * Checks if the array of billDetails has alarms
     * @param categoriesArray Array with all the categories from a bill
     */
    detailsHasAlarms(categoriesArray: BillDetailsModel): boolean {
        const billDetails: BillDetailsCategory[] = [];
        categoriesArray.categories.forEach((category: BillDetailsCategory) => billDetails.push(...category.subItems));
        const hasAlarms: BillDetailsCategory = billDetails.find((categoryItem: BillDetailsCategory) => !!categoryItem.typeAlarm);
        return !!hasAlarms;
    }

    /**
     * Returns the sum of all categories amounts inside the parameter array.
     * By default, it will sum both the gross amounts and the discounts.
     * @param categoriesArray Array with all BillDetailsCategory we want to sum
     * @param skipDiscounts Whether to skip the discounts during the sum
     */
    getTotalPriceFromCategoryArray(categoriesArray: BillDetailsCategory[], skipDiscounts?: boolean): number {
        return categoriesArray.reduce((result: number, category) => {
            result += this.getPriceFromCategoryDetail(category, skipDiscounts);
            return result;
        }, 0);
    }

    /**
     * Returns the price of a specific category. By default, it will sum both the gross amount and the discounts.
     * @param category BillDetailsCategory we want to extract the amount from
     * @param skipDiscounts Whether to skip the discounts or not
     */
    getPriceFromCategoryDetail(category: BillDetailsCategory, skipDiscounts?: boolean): number {
        return category.amount + (skipDiscounts ? 0 : category.discounts.reduce((res, disc) => res = res + disc.amount, 0));
    }

    getNewOfferSubItems(subItems: BillDetailsCategory[]): BillDetailsCategory[] {
        return subItems.filter(category => category.newOffer === true);
    }

    getOldOfferSubItems(subItems: BillDetailsCategory[]): BillDetailsCategory[] {
        return subItems.filter(category => category.newOffer === false);
    }

    getNonChangingOfferSubItems(subItems: BillDetailsCategory[]): BillDetailsCategory[] {
        return subItems.filter(category => (category.newOffer !== true && category.newOffer !== false));
    }

    getThirdPartiesData(categoriesArray: BillDetailsCategory[]): BillDetailsCategory[] {
        const thirdPartiesArray: BillDetailsCategory[] = [];
        categoriesArray
            .forEach(cat => {
                cat.subItems.forEach(catDetail => {
                    if (catDetail.typeAlarm === BillAlarmType.thirdPartiesPayments && catDetail.amount > 0) {
                        thirdPartiesArray.push(catDetail);
                    }
                });
            });
        return thirdPartiesArray;
    }

    getPremiumData(categoriesArray: BillDetailsCategory[]): BillDetailsCategory[] {
        const premiumArray: BillDetailsCategory[] = [];
        categoriesArray
            .forEach(cat => {
                cat.subItems.forEach(catDetail => {
                    if (catDetail.typeAlarm === BillAlarmType.premiumPayments && catDetail.amount > 0) {
                        premiumArray.push(catDetail);
                    }
                });
            });
        return premiumArray;
    }

    /**
     * Checks if a site is allowed to see the estimated invoice section
     * @param site Site to check
     * @returns true if site is allowed to see the estimated invoice
     */
    isInvoiceEstimate(site: CustomerAccount): boolean {
      const siteStatus: string = site?.status?.toLowerCase();
      const allowedStatuses: SiteStatus[] = [
        SiteStatus.Active,
        SiteStatus.Activo,
        SiteStatus.Pending_Change,
        SiteStatus.Pend_de_Cambio,
      ];
      return allowedStatuses.map((status: SiteStatus): string => status.toLowerCase()).includes(siteStatus);
    }

      /**
       * function to check that the last two digit in nif
       * is in range we get from setting api
       * @returns boolean
       */
    isNifValidForBillReview(): boolean {
        let valid: boolean = false;
        const billReviewJourneyConfig: ReviewBillingJourney = this.configurationService.configuration.reviewBillingJourney
        const nif: string = this.storageService.userProfile.document.id;
        const last2digit: number = parseInt(nif.slice(nif.length - 3, nif.length - 1), 10);

        if (billReviewJourneyConfig.status === 'active') {
            if ((!billReviewJourneyConfig.pattern && billReviewJourneyConfig.validUsers?.length === 0) ||
                billReviewJourneyConfig.pattern === '*') {
                valid = true;
            } else {
                const singleValidUsersValues: string[] = [];
                const rangeValidUsersValues: string [] = [
                    ...(billReviewJourneyConfig.pattern ? [billReviewJourneyConfig.pattern] : [])
                  ];
                billReviewJourneyConfig.validUsers.map(item => {
                    item.includes('-') ? rangeValidUsersValues.push(item) : singleValidUsersValues.push(item);
                });
                valid = !!rangeValidUsersValues.find(item => this.checkIfNifInRange(item, last2digit));
                valid = valid || singleValidUsersValues.includes( last2digit.toString());
            }
        }
        return valid
    }

    checkIfNifInRange(range: string, nifLastDigits: number): boolean {
        let isInRange: boolean = false;
        const [minRang, maxRang]: string[] = range.split('-');
        const _minRang: number = parseInt(minRang, 10);
        const _maxRang: number = parseInt(maxRang, 10);
        if (!isNaN(_minRang) && !isNaN(_maxRang) && !isNaN(nifLastDigits)) {
            isInRange = this.isInRange(_minRang, _maxRang, nifLastDigits);
        }
        return isInRange;
    }
    /**
     * function to check that nmber is in the range between two geiven number
     * @param minNum the smallest number in the range
     * @param maxNum the largest number in the range
     * @param num the number we need to check
     * @returns  boolean
     */
    isInRange(minNum: number, maxNum: number, num: number): boolean {
        return num >= minNum && num <= maxNum;
    }
    public getTicket(billAccountId: string): void {
        this.showTicketsCardInLanding = false;
        this.openedTicket = undefined;
        this.billingVf10Data.getTicket(billAccountId).subscribe((resp: BillTicket[]) => {
            const hasNoTickets: BillTicket[] = resp.filter(ticket => !ticket.hasTicket);
            this.isBillReviewEligable = hasNoTickets.length > 0;
            if (this.isBillReviewEligable) {
                this.billingService.selectedBillReviewAccID = hasNoTickets[hasNoTickets.length - 1].id;
            }
            else {
                if (this.checkIfMultipleSite()) {
                    const filteredTickets: BillTicket[] = resp.filter(ticket =>
                        ticket.description.substring(ticket.description.indexOf(' ') + 1)
                            .includes(this.billingService.selectedBill.billNo));
                    this.openedTicket = filteredTickets[filteredTickets.length - 1];
                } else {
                    this.showTicketsCardInLanding = true;
                    this.billingTickets = resp;
                    this.checkOpenedBillingTickets();
                }
            }
        }, err => {
            this.isBillReviewEligable = false;
        })
    }
    checkBillReviewEligablity(billAccountId: string): void {
        const ismircorOpenTicket: boolean =
            this.configurationService.configuration.rsmAvailabilityForBillReview && this.storageService.getIsMicroResegmented();
        const isPARTICULARUSer: boolean = this.storageService.getIsParticlurResegmented();
        if (isPARTICULARUSer || ismircorOpenTicket) {
            if (this.isNifValidForBillReview()) {
                // open ticket if no tickts
                this.getTicket(billAccountId) // go to create ticket
            }
            else {
                this.isBillReviewEligable = false;
            }
        }
        else {
            this.isBillReviewEligable = false;
        }
    }

    checkOpenedBillingTickets(): void {
        const openedOrReopenedTickets: BillTicket[] = this.billingTickets.filter(ticket =>
            ticket.status.toLowerCase() === BillingTicketStatus.opened.toLowerCase() ||
            ticket.status.toLowerCase() === BillingTicketStatus.reopened.toLowerCase());
        if (openedOrReopenedTickets.length === 0) {
            this.isBillReviewEligable = true;
        }
    }

    getBillDateCycle(bill: Billing): string {
        const startDate: Date = new Date(bill.billingCycle.startDate);
        const endDate: Date = new Date(bill.billingCycle.endDate);
        return `${startDate.getDate()} ${this.utilsService.getMonthName((startDate.getMonth() + 1).toString(), true)} -
            ${endDate.getDate()} ${this.utilsService.getMonthName((endDate.getMonth() + 1).toString(), true)}`;
    }

    checkIfMultipleSite(): boolean {
        return (
            this.storageService.userProfile.sites?.length > 1 &&
            this.customerAccountService.isP1Sites(this.storageService.userProfile) &&
            this.customerAccountService.customerAccounts.length > 1 ) ||
            (this.storageService.userProfile.companies?.length > 1)
    }
}

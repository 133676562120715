<div class="background-opacity"></div>
<div class="overlay-container" [ngClass]="{'visible': this, 'hidden': !this, 'slideUp': this}">
    <div class="pull-line"></div>
    <div class="header" [ngClass]="{'shadow-header': actionText, 'info': info}">
        <span class="sub-header">
          <span *ngIf="breadcrumb" class="breadcrumb">{{ breadcrumb }}</span>
          <span *ngIf="title" class="title">{{ title }}</span>
          <span *ngIf="subtitle" class="subtitle" [innerHtml]="subtitle"></span>
          <span *ngIf="actionText || actionImage" class="action" (click)="onClick($event)">
            <div *ngIf="actionImage" class="image"><img src="{{appService.getImgFullPath(actionImage)}}"></div>
            <div *ngIf="actionText" class="text">{{ actionText }}</div>
          </span>
        </span>
        <span (click)="closeOverlay()" class="icon icon-close delete-alise white-top-border"></span>
    </div>
    <div class="scroll-content" [ngClass]="{'handSetRenewal': handSetRenewal, 'filters': filters, 'info': info}">
        <div class="body row">
          <ng-content></ng-content>
        </div>
        <div class="footer row">
          <button *ngIf="redBtn" class="button red" (click)="redBtnClick()">{{ redBtn }}</button>
          <button *ngIf="greyBtn" class="button grey" (click)="greyBtnClick()">{{ greyBtn }}</button>
          <button *ngIf="whiteBtn" class="button white" (click)="whiteBtnClick()">{{ whiteBtn }}</button>
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import { Inbox_Message } from '../constants/defines';

@Pipe({
  name: 'limitTo'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args?: string): string {
    const limit = parseInt(args, 10);
    const trail = Inbox_Message.TRAIL;

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}




<sp-fixed-bottom-button
  [btnName]="btnName"
  [disabled]="btnDisabled"
   (btnAction)="showConfirmationTile()" 
>
</sp-fixed-bottom-button>

<vfac-overlay 
  [show]="showTile"
  [overlayData]="overLayData"
  [loaded]="true"
  [showButtons]="showButtons"
  (secondaryBtnClicked)="  closeTopUpTray()"
  (closed)="closeTopUpTray()" 
  (primaryBtnClicked)="buyProduct()"
>
<div body >
    <p *ngIf="!trayLoadedMode" class="description" [innerHtml]="desctiptionSecondLine"></p>

    <div *ngIf="trayLoadedMode" class="skeleton-wrapper-section">
      <section class="content-container-skeleton">
        <div class="first-content"></div>
        <div class="first-content"></div>
        <div class="content-skeleton"></div>
        
      </section>

    </div>
  
</div>
  </vfac-overlay>

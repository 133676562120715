<!-- show shimmer loading for plume service 2 api-->
<mva10-c-modal *ngIf="show" [show]="show" class="show-shimmer-loader"
[ngClass]="{'hide-bg-dark': !superWifiservice.showRetryBtn && !superWifiservice.showShimmerbg}">
  <div class="skeleton skeleton__title0-ser2"></div>
  <div class="skeleton skeleton__title1-ser2"></div>
  <div class="skeleton skeleton__title2-ser2"></div>
  <div class="skeleton skeleton__title3-ser2"></div>
  <div class="skeleton skeleton__title4-ser2"></div>
  <div class="skeleton skeleton__title5-ser2"></div>
  <div class="skeleton skeleton__title6-ser2"></div>
  <div class="skeleton skeleton__title7-ser2"></div>
</mva10-c-modal>
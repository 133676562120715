<div class="title" id="commercialPromotionsTitle">{{ 'v10.commercial.checkout.terminalSetup.promos.title' | translate }}
</div>
<div *ngFor="let promo of terminalViewModel.promotions; let i = index">
  <div *ngIf="i < numberPromotions" class="promo">
    <div class="promo-title" (click)="showDetailPromo(promo)" id="commercialPromotionsPromoTitle-{{ i }}">
      <mva10-c-icon *ngIf="promo.img" class="icon" iconId="{{ promo.img }}" size="32" title="{{ promo.img }}">
      </mva10-c-icon>
      <div class="text" id="commercialPromotionsPromoText-{{ i }}">{{ promo.promotionTitle }}</div>
      <div class="icon-chevron">
        <span class="icon-chevron-right"></span>
      </div>
    </div>
  </div>
</div>
<div class="viewMore" *ngIf="terminalViewModel.promotions.length > 2" (click)="changeView()">
  <a id="commercialPromotionsClickViewMore" class="viewMore_text">{{ text }}</a>
</div>

<mva10-c-backdrop [show]="showBackdrop" (mva10Close)="closeBackdrop()" [fullScreenOnScroll]="false"
  class="backdrop-promotion">
  <div bd-header class="backdrop-promotion_header" id="commercialPromotionsBackdropHeader">
    <mva10-c-icon *ngIf="promotion?.img" class="icon" iconId="{{ promotion?.img }}" size="32"
      title="{{ promotion?.img }}">
    </mva10-c-icon>
    <p class="text" id="commercialPromotionsBackdropText">{{ promotion?.promotionTitle }}</p>
  </div>
  <div bd-subtitle class="backdrop-promotion_subtitle" id="commercialPromotionsBackdropSubtitle">
    {{ 'v10.commercial.checkout.terminalSetup.promos.title' | translate }}
  </div>
  <div bd-content>
    <div class="backdrop-promotion_contentText" [innerHtml]="promotion?.longText | safe: 'html'"
      id="commercialPromotionsBackdropContent"></div>
    <div *ngIf="promotion?.urlEmbeddedVideo">
      <div class="backdrop-promotion_iframe" id="commercialPromotionsBackdropImagePreview">
        <img class="backdrop-promotion_iframe-img" id="commercialPromotionsBackdropImagePreviewImg"
          [src]="promotion?.urlImagePreviewVideo" />
        <div class="backdrop-promotion_play" (click)="openModalIframe()" id="commercialPromotionsBackdropPlayImage">
          <mva10-c-icon class="icon" [size]="32" [iconId]="'icon-play-arrow'"></mva10-c-icon>
        </div>
      </div>
    </div>
  </div>
</mva10-c-backdrop>

<vfac-overlay-full-screen class="modal-video" [show]="showModalIframe" [overlayFullScreenData]="overlayDataIframe"
  (closeBtnClicked)="closeModalIframe()">
  <div class="content-iframe">
    <iframe id="commercialPromotionsBackdropIframeVideo" class="modal-video_player"
      [src]="promotion?.urlEmbeddedVideo | safe: 'resourceUrl'" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</vfac-overlay-full-screen>


import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './shared/utils/LoggerService';
import { StorageService } from './core/services/storage.service';
import { RoutesConfigService } from './core/services/routes-config.service';
import { config } from './../config/pages-config';
import { DeepLinkingService } from './shared/services/deep-linking.service';
import { AuthenticateService } from './core/services/authenticate.service';
import { Component, OnInit, Renderer2, AfterContentChecked, OnDestroy } from '@angular/core';
import { CommonService } from './core/services/common.service';
import { Router, NavigationEnd, NavigationStart, NavigationError, NavigationCancel } from '@angular/router';
import { Notification } from './models/notification.model';
import * as PagesConfig from '../config/pages-config';
import { NotificationService } from './shared/services/notification.service';
import { AppService } from './app.service';
import { UtilitiesService } from './shared/utils/utilities.service';
import { CrossFunctionalityService } from './shared/services/cross-functionality.service';
import { TaggingHelperService } from './core/services/tagging.helper.service';
import {
  AppThemes, portalHTML5, LOCAL_STORAGE_KEYS,
  MessageCode0, MessageCode2, STORE_URL, NAVIGATION_ERROR_CANT_MATCH_ROUTE
} from './shared/constants/defines';
import { Route } from './models/Route.model';
import { tagging } from '../config/tagging-config';
import { taggingConfig } from './models/tagging.model';
import { ProductsAndServicesService } from './products-and-services/products-and-services.service';
import { BillingService } from './billing/billing.service';
import { SidemenuConfigService } from './shared/services/sidemenu-config.service';
import { ConfigurationService } from './core/services/configuration.service';
import { CampaignService } from './shared/components/campaign-overlay/campaign-overlay.service';
import { CustomerType } from './shared/enums/customerType.enum';
import { OperatingSystem } from './shared/enums/operating-systems.enum';
import { SpMva10OverlayService } from './shared/services/sp-mva10-overlay.service';
import { Mva10FullOverlayService } from './shared/services/mva10-full-overlay.service';
import { LeftMenuWs10Service } from './mva10/shared/services/left-menu-ws10.service';
import { TopUpNavigationService } from './top-up/top-up-navigation.service';
import { NewTaggingHelperService } from './core/services/new-tagging.helper.service';
import { URLConfig } from './models/url.model';
import { BubbleHelpConfiguration } from './models/bubbleHelpConfiguration';

@Component({
  selector: 'sp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  title = 'Mi Vodafone';
  siteContent = {};
  firstNudgeButtonText: string;
  secondNudgeButtonText: string;
  showAdviseApp: boolean;
  OPERETINGSYSTEM_MAC = OperatingSystem.mac;

  public AppThemes = AppThemes;
  constructor(
    public storage: StorageService,
    public authenticate: AuthenticateService,
    public appTheme: AppService,
    public commonService: CommonService,
    private router: Router,
    private notificationService: NotificationService,
    public cross: CrossFunctionalityService,
    public utilitiesService: UtilitiesService,
    private tealium: TaggingHelperService,
    private deepLinkingService: DeepLinkingService,
    private rendrer: Renderer2,
    private routeConfigService: RoutesConfigService,
    private logger: LoggerService,
    private translateService: TranslateService,
    private productAndService: ProductsAndServicesService,
    private billService: BillingService,
    public menuService: SidemenuConfigService,
    public campaignService: CampaignService,
    public configurationService: ConfigurationService,
    private topupNavigationService: TopUpNavigationService,
    public mva10FullOverlayService: Mva10FullOverlayService,
    public leftMenuService: LeftMenuWs10Service,
    /** Load app from web banner at first time */
    public _Mva10OverlayService: SpMva10OverlayService,
    public _newTaggingService: NewTaggingHelperService
    ) {  }

  loadFrequencyFromWCS() {
    if (!this.appTheme.frequency && this.appTheme.frequency !== 0 && this.router.url !== '/') {
      this.translateService.get('dashboard.itemsList.frequencyToShowNudge.frequencyToShowNudge').subscribe(data => {
        this.appTheme.frequency = +data || 0;
      });
    }
  }

  /**
   * To seperate the queryparam to object key and value
   * @param queryString string of query param
   */
  parseQuery(queryString = '') {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let index = 0; index < pairs.length; index++) {
      const pair = pairs[index].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  getPageOriginalReferingFromDeepLink(url: URLConfig): string {
    try {
      return decodeURIComponent(tagging.staticVariables.pageOriginalRefering.replace('{0}', url.destination));
    } catch (error) {
      return '';
    }
  }
  configureBubbleHelp() {
    this.commonService.showChatInBubbleHelp = false;
    this.commonService.showFAQsInBubbleHelp = false;
    if (this.menuService.bubbleHelpConfig) {
      const currentPage = this.tealium.getCurrentPage(this.router.url);
      if (currentPage) {
        const textmonia: string = currentPage.page_name ? currentPage.page_name.split(':')[0] : null;
        let bubbleHelpConfiguration: BubbleHelpConfiguration;
        if (textmonia) {
          bubbleHelpConfiguration = this.menuService.bubbleHelpConfig.find(
            item => item.module.toLowerCase() === textmonia.toLowerCase());
        }
        if (bubbleHelpConfiguration) {
          this.commonService.currentPageBubbleHelpConfig = bubbleHelpConfiguration;
          this.commonService.showChatInBubbleHelp = bubbleHelpConfiguration.chatEnabled;
          this.commonService.showFAQsInBubbleHelp = bubbleHelpConfiguration.faqsEnabled;
          this.commonService.showContactUS =
            this.storage.userProfile && this.storage.userProfile.customerType &&
            this.storage.userProfile.customerType.toString().toLowerCase() === CustomerType.Authorized.toLowerCase();
        }
      }
    }
  }
  public getRoute(url: string): Route {
    let route: Route = null;
    const conf = config;
    const linkUrl = url.split('?')[0];
    Object.keys(conf).forEach(moduleKey => {
      if (conf[moduleKey].route === linkUrl) {
        route = conf[moduleKey];
      } else {
        Object.keys(conf[moduleKey]).forEach(stepKey => {
          if (conf[moduleKey][stepKey].route === linkUrl) {
            route = conf[moduleKey][stepKey];
          }
        });
      }
    });
    return route;
  }

  ngOnInit(): void {
    this.logger.info('Starting app. component');
    this.initRouteListener();
    this.menuService.bubbleHelpConfigSubject.subscribe(() => {
      this.configureBubbleHelp();
    });
    this.utilitiesService.getDeviceInfo();
    // check if browser window is offline, the popup will appears
    this.rendrer.listen('window', 'offline', (evt) => {
      this.appTheme.onlineFlag = false;
    });
    this.rendrer.listen('window', 'online', (evt) => {
      this.appTheme.onlineFlag = true;
    });
  }

  ngOnDestroy(): void {
    this.leftMenuService.clearMenu();
  }

  ngAfterContentChecked(): void {
    // Adding Try catch to stop tagging from breaking the application if it caused an error
    try {
      const exceptionCheck = this.tealium.checkFinishLoadOfExceptionRouts();
      if (taggingConfig.navigationEnd && taggingConfig.pendingRequests.length === 0 && taggingConfig.componentFinishInit &&
        !this.appTheme.showFullAppLoader && !this.productAndService.spinner && !this.billService.showSpinner
        && exceptionCheck) {
        this.tealium.sendViewState();
      }
    } catch (error) {
      // Do nothing
    }
  }

  hideBackBtnMenuAyoda() {
    this.commonService.showMenuButton = false;
    this.appTheme.showBackButton = false;
    this.commonService.showChatInBubbleHelp = false;
    this.commonService.showFAQsInBubbleHelp = false;
    this.commonService.showContactUS = false;
  }

  checkAndAppendExtraQueryStrings(extraQueryString: string, url: URLConfig): void {
    if (extraQueryString) {
      const parsedURL: URL = new URL(url.destination);

      const nativeLink = parsedURL.searchParams.get('link');
      const webLink = parsedURL.searchParams.get('afl');

      /** Append query string to (link param for native, afl and ifl for web) by replacing them with new links includes extra params */
      this.changeDestinationURL(nativeLink, extraQueryString, url);
      this.changeDestinationURL(webLink, extraQueryString, url);
    }
  }
  changeDestinationURL(partialURL: string, extraQueryString: string, url: URLConfig): void {
    if (partialURL) {
      partialURL = encodeURIComponent(partialURL);
      /** Check if it already has a query param then we have to append on it using `&` otherwise using `?` */
      const separator = encodeURIComponent(partialURL.includes('?') ? '&' : '?');
      /** using split because of web has two URLs, so we need to replace them together */
      url.destination = url.destination
        .split(partialURL).join(`${partialURL}${separator}${encodeURIComponent(extraQueryString)}`);
    }
  }


  routeToValidUrl(url: string, queryString: string): void {
    if (this.utilitiesService.validateUrl(url)) {
      this.routeConfigService.getUrl(url).subscribe((resUrl: URLConfig) => {
        const messageCode: number = resUrl.messageCode;
        this.deepLinkingService.deeplinkStartFlag = messageCode === MessageCode0;
        switch (messageCode) {
          case MessageCode0: {
            const queryUrl: string[] = resUrl.destination.split('?');
            if (queryUrl.length > 1) {
              const params: {} = this.parseQuery(queryUrl[1]);
              this.router.navigate([queryUrl[0]], { queryParams: params });
            } else {
              this.router.navigate([resUrl.destination]);
            }
            break;
          }
          case MessageCode2: {
            // in case of message code = 2 we have to check on the device info
            // in case of android we will navigate to play store if mac or ios we will navigate to apple store
            // in case of desktop we will navigate to dashboard which is the default case
            this.utilitiesService.getDeviceInfo();
            if (this.utilitiesService.deviceInfo && this.utilitiesService.deviceInfo.os) {
              switch (this.utilitiesService.deviceInfo.os.toLowerCase()) {
                case OperatingSystem.android.toLowerCase():
                  window.open(STORE_URL.ANDROID, '_self');
                  break;
                case OperatingSystem.mac.toLowerCase():
                case OperatingSystem.ios.toLowerCase():
                  window.open(STORE_URL.IOS, '_self');
                  break;
                default:
                  this.router.navigate([config.dashboard.route]);
              }
            } else {
              this.router.navigate([config.dashboard.route]);
            }
            break;
          }
          default:
            this.router.navigate([config.dashboard.route]);
            break;
        }
        const pageOriginalRefering: string = this.getPageOriginalReferingFromDeepLink(resUrl);
        this.storage.setStorage(LOCAL_STORAGE_KEYS.TAGGING_PAGE_ORIGINAL_REFERING, pageOriginalRefering);

        this.checkAndAppendExtraQueryStrings(queryString, resUrl);

      }, error => {
        this.router.navigate([config.dashboard.route]);
      });
    } else {
      this.router.navigate([config.dashboard.route]);
    }

  }

  initRouteListener(): void {
    this.router.events.subscribe(event => {
      this.topupNavigationService.init();
      if (event instanceof NavigationStart) {
       this.navigationStartHandling(event);
      }
      if (event instanceof NavigationError) {
        this.navigationErrorHandling(event);
      }
      if (event instanceof NavigationEnd) {
        this.navigationEndHandling(event);
      }
      if (event instanceof NavigationCancel) {
        this.navigationCancelHandling(event);
      }
    });
  }

  navigationStartHandling(event: NavigationStart): void {
    if (this.commonService.overrideBackHandling) {
      this.commonService.backBtnSubject.next();
    }
    const contentRoot: HTMLCollectionOf<Element> = document.getElementsByClassName('main-content');
    if (event['id']) {
      this.deepLinkingService.navigationId = event['id'];
    }
    if (contentRoot.length > 0) {
      contentRoot[0].scrollTop = 0;
    }
    this.tealium.resetErrorVariables();
    taggingConfig.navigationEnd = false;
    if (!this.appTheme.onlineFlag) {
      this.translateService.get('common.messagesList.noConnectionError').subscribe(data => {
        if (data) {
          const errorObj: Notification = new Notification();
          errorObj.bodyTitle = data.title;
          errorObj.bodyContent = data.description;
          errorObj.secondaryButtonText = data.confirmButtonText;
          errorObj.secondaryButtonClick = () => {
            if (this.appTheme.onlineFlag) {
              this.notificationService.notificationModal.hide();
            }
          };
          this.notificationService.createNotification(errorObj);
        }
      });
    }
    this.loadFrequencyFromWCS();
  }

  navigationErrorHandling(event: NavigationError): void {
    taggingConfig.navigationEnd = true;
        /** Reset this flag once navigation is done */
        this.appTheme.backButtonClicked = false;
        if (
          event?.error?.message &&
          NAVIGATION_ERROR_CANT_MATCH_ROUTE?.some((noMatchStr) => event.error.message.indexOf(noMatchStr) !== -1)
        ) {
          this.deepLinkingService.deeplinkStartFlag = false;
          let url = event.url;
          if (url.split(portalHTML5.portalHTML5).length > 1) {
            url = url.split(portalHTML5.portalHTML5)[1];
          }
          if (url && url.length > 0 && !this.getRoute(url)) {
            let queryString;
            if (window.location.href.includes('?')) {
              /** Get appended query string on friendly url */
              queryString = `${window.location.href.split('?')[1]}`;
            }
            /** Get url only without appended query string
           *  url is source link will sent relative not full path
          */
            const urlOnly = (queryString ? url.split('?')[0] : url).slice(1);
            this.routeToValidUrl(urlOnly, queryString);
          } else {
            location.reload();
          }
        }
  }

  navigationEndHandling(event: NavigationEnd): void {
     /** Reset this flag once navigation is done */
     this.appTheme.backButtonClicked = false;
     taggingConfig.navigationEnd = true;
     this.commonService.showMenu = false;
     this.appTheme.showBackButton = true;
     this.tealium.previous_page = event.url;
     this.appTheme.previousUrl = this.appTheme.currentUrl;
     this.appTheme.currentUrl = event.url;
     this.deepLinkingService.deeplinkStartFlag = false;
     if (this.router.url.split('?')[0] === PagesConfig.config.login.route ||
       this.router.url.split('?')[0] === PagesConfig.config.login.otp.route ||
       this.router.url === PagesConfig.config.splash.route ||
       this.router.url.includes(PagesConfig.config.buySim.name)) {
       this.hideBackBtnMenuAyoda();
       /** Load app from web banner after logout */
       this.appTheme.loadBanner = true;
     }
     else {
       this.commonService.showMenuButton = true;
       this.configureBubbleHelp();
       if (this.authenticate.isAccessTokenExist() && !this.authenticate.isAccessTokenExpired()
         && this.router.url.split('?')[0] !== PagesConfig.config.login.companyChooser.route) {
         this.leftMenuService.initLeftMenu();
       }
     }
     this.appTheme.curentPageChangedEmitter.next();
     this.loadFrequencyFromWCS();

  }

  navigationCancelHandling(event: NavigationCancel): void {
    if (this.router.url.split('?')[0] === PagesConfig.config.login.route ||
          this.router.url === PagesConfig.config.splash.route) {
          this.hideBackBtnMenuAyoda();
        }
        taggingConfig.navigationEnd = true;
        /** Reset this flag once navigation is done */
        this.appTheme.backButtonClicked = false;
  }
}

export const marketplaceEntryPoints: string[] = ['MKTP', 'MKTPP'];

export const marketplaceGrifoEntryPoint: string = 'MKTPO';

export const marketplaceMyOrdersScreenCode: string = 'MYORDERS';

export const marketplaceParams: { [key: string]: string } = {
    authorizeClientIdValue: 'marketplace',
    redirectPrefix: '/authorize/marketplace?response_type=code&client_id=',
    redirectScope: '&scope=OPENID&id_token_hint=',
    redirectUri: '&redirect_uri=',
    REDUCEDTOKENSCOPE: '',
    wcsActivatedFlag: 'v10.productsServices.marketplace.myOrdersActivated',
    wcsUriHome: 'v10.productsServices.marketplace.uriHome',
    wcsUriMyOrders: 'v10.productsServices.marketplace.uriMyOrders',
    wcsUriBillingAccount: 'marketplace_wallet',
    wcsUrlBase: 'v10.productsServices.marketplace.urlBase',
};


import {catchError, map} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject, throwError } from 'rxjs';
import { API_URLS } from '../constants/routes-config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { JSON_PATHS, CommercialJourneys, maxNumberOperator, packageUpgrade, commercialMobile } from '../constants/defines';
import { CommercialOperator } from '../../models/commercial-operator.model';
import * as JsonQuery from 'jsonpath/jsonpath';
import { CommercialData } from '../../models/commercial-data.model';
import { LegalTerms } from '../../models/legalTerms.model';
import { LegalTermDetail } from '../../models/legalTermDetail.model';
import { PostTermsLegal } from '../../models/postTermsLegal.model';
import { CommercialShoppingCart } from '../../models/commercial-shopping-cart.model';
import { CommercialPrice } from '../../models/commercial-price.model';
import { CommercialCartItem } from '../../models/commercial-cart-item.model';
import { CommercialService } from '../../models/commercial-service.model';
import { CommercialDetail } from '../../models/commercial-detail.model';
import { PromoCommercial } from '../../models/promo-commercial.model';
import { DeliveryType } from '../../models/delivery-type.model';
import { DeliveryDate } from '../../models/delivery-date.model';
import moment from 'moment';
import { config } from '../../../config/pages-config';
import { CartItems } from '../../models/cartItems.model';
import { PostalAddress } from '../../models/postal-address.model';
import { DeliveryModality } from '../../models/delivery-modality.model';
import { DeliveryHours } from '../../models/delivery-hours.model';
import { StoreCoordinates } from '../../models/store-coordinates.model';
import { DeliveryStore } from '../../models/delivery-store.model';
import { DeliverySelectedStore } from '../../models/delivery-selected-store.model';
import { CommercialShippingCosts } from '../../models/commercial-shipping-costs.model';
import { StorageService } from '../../core/services/storage.service';
import { CustomerType } from '../enums/customerType.enum';
import { DeliveryOffice } from '../../models/delivery-office.model';
import { InstallationDataModel } from '../../models/installationData.model';
import { Router } from '@angular/router';
import { FederationErrorService } from './federation-error.service';
import { CommercialPersonalData } from '../../models/commercial-personal-data.model';
import { ParamsMobileData } from '../../models/params-mobile-data.model';
import { ParamsLandData, ParamsCheckoutComercial } from '../../models/params-land-data.model';
import { UtilitiesService } from '../utils/utilities.service';
import { MobileToPackExpediteService } from '../../mobile-to-pack/services/mobile-to-pack-expedite.service';
import { HeadersDataOptions } from '../models/dxl-header-data-options.model';

@Injectable()
export class CheckoutCommercialService {
  commercialOperators?: CommercialOperator[];
  personalData: CommercialData;
  commercialshoppingCartItems: CommercialShoppingCart;
  cartItemId: string;
  postTermsLegal: PostTermsLegal;
  legalTermsArray: LegalTerms[];
  legalDetailsArray: LegalTermDetail[];
  cart: CartItems[];
  public ecodeMail: string;
  public installationCode: number;

  federror: any;

  public ntolString: string;

  ntolMtp: string;
  private installationData: InstallationDataModel;
  private installationDataSubject = new BehaviorSubject({});
  public installationData$ = this.installationDataSubject.asObservable();

  public mtpPorta: boolean;

  public selectedDeliveryOffice: DeliveryOffice;
  public priceShippinCosts: number;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private cookieService: CookieService,
    private router: Router,
    private fedErrorService: FederationErrorService,
    private utilitiesService: UtilitiesService,
    public mobileToPackExpediteService: MobileToPackExpediteService,
  ) { }

  private headersData() {
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    return options;
  }


  public deleteReset(idClient: string, clientTypeID: string, isMTP?) {
    const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

    let url: string = API_URLS.Checkout.deteleReset
      .replace('{idClient}', idClient)
      .replace('{clientTypeID}', isMicroResegmented
        ? commercialMobile.clientTypeMicro.toString()
        : clientTypeID
      );

    if (isMTP) {
      url += `?idTypeJourney=${CommercialJourneys.mobileToPack}`;
    }
    const options = this.headersData();

    const params = {};
    return this.http.post(url, params, options).pipe(map((res: any) => {
    }), catchError((err) => {
       return throwError(err);
    }));
  }

  public getDropDowns(_isMTP: boolean): Observable<any> {
    const url = API_URLS.Checkout.getDropdowns;
    const options = this.headersData();

    return this.http.get(url, options).pipe(map((res: any) => {
      this.dropdownsLogic(res, _isMTP);
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public postPersonalData(param) {
    const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

    const url = API_URLS.Checkout.postPersonalData;
    const params = Object.assign(param, {
      sceneType: param.sceneType,
      shopType: isMicroResegmented
        ? Number(commercialMobile.shopTypeMicro)
        : this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase()
          ? Number(commercialMobile.shopType)
          : Number(commercialMobile.shopTypeMicro),
      clientType: isMicroResegmented
        ? commercialMobile.clientTypeMicro
        : param.clientType
    });

    const options = this.headersData();

    return this.http.post(url, params, options).pipe(map((res: any) => {
      this.personalData = new CommercialData;
      this.personalData.completed = JsonQuery.value(res, JSON_PATHS.PersonalData.completed) || false;
      this.personalData.order_id = JsonQuery.value(res, JSON_PATHS.PersonalData.order_id) || null;
      if (param.email) {
        this.personalData.email = param.email;
      }
      this.ecodeMail = res.ecode;
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public postShoppingCartItem(offerId?: number, packageId?: number, isMtp?: boolean, opUpgrade?: string): Observable<any> {
    const url = API_URLS.Checkout.postShoppingCartItem;
    let params;
    if (!isMtp && !opUpgrade) {
      params = { 'idPrecio': offerId, 'paymentType': 0 }
    } else if (opUpgrade) {
      params = {
        'idTypeJourney': 'packToPack',
        'idPrecio': offerId,
        'paymentType': 1,
        'oneplus': opUpgrade === packageUpgrade.linesOP ? true : false
      }
    } else {
      params = { 'idPackage': packageId, 'idTypeJourney': CommercialJourneys.mobileToPack }
    }

    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.http.post(url, params, options).pipe(map((res: any) => {
      this.shoppingCartLogic(res);
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getShoppingCartItem() {
    const url = API_URLS.Checkout.getShoppingCartItem;
    const options = this.headersData();

    return this.http.get(url, options).pipe(map((res: any) => {
      this.shoppingCartLogic(res);
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public postMobileData(clientType: string, shopType: string, sceneType: string, registerType: string,
    idOperator: number, phoneNumber: string, userPersonalData: CommercialPersonalData): Observable<any> {
    const url = API_URLS.Checkout.postMobileData;
    let docType;
    if ((userPersonalData.dni.charAt(-1) === 'X') || (userPersonalData.dni.charAt(-1) === 'Y')
      || (userPersonalData.dni.charAt(-1) === 'Z')) {
      docType = 2;
    } else {
      docType = 1;
    }
    let params;
    // Body params
    if (!userPersonalData.change) {
      params = {
        'clientType': clientType,
        'mobileData': [
          {
            'lineHolder': true,
            'operator': idOperator,
            'phoneNumber': phoneNumber,
            'registerType': registerType,
            'email': userPersonalData.email
          }
        ],
        'sceneType': sceneType,
        'shopType': shopType
      };
    } else {
      params = {
        'clientType': clientType,
        'mobileData': [
          {
            'docNumber': userPersonalData.dni,
            'docType': docType,
            'lineHolder': false,
            'name': userPersonalData.firstName,
            'operator': idOperator,
            'phoneNumber': phoneNumber,
            'registerType': registerType,
            'surname1': userPersonalData.middleName,
            'surname2': userPersonalData.familyName,
            'email': userPersonalData.email
          }
        ],
        'sceneType': sceneType,
        'shopType': shopType
      };
    }
    this.personalData.email = userPersonalData.email;
    this.personalData.phoneNumber = phoneNumber;

    const options = this.headersData();

    return this.http.post(url, params, options).pipe(map((res: any) => {
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getDeliveryTypes() {
    const url = API_URLS.Checkout.getDeliveryTypes;

    const options = this.headersData();

    return this.http.get(url, options).pipe(map((res: any) => {
      return this.deliveryTypesLogic(res);
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getPostalAddress() {
    const url = API_URLS.Checkout.getPostalAddress;

    const options = this.headersData();

    return this.http.get(url, options).pipe(map((res: any) => {
      return this.postalAddressLogic(res);
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getDeliveryDates(address) {
    const url = API_URLS.Checkout.getDeliveryDates;

    // Body params
    const params = {
      'cpDireccionEntrega': address.cp,
      'idTipoEntrega': address.idType,
      'cdTipoDeliveryType': address.cdType
    };

    const options = this.headersData();

    return this.http.post(url, params, options).pipe(map((res: any) => {
      return this.deliveryDatesLogic(res);
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public postDeliveryData(itPortabilidad, clientType, shopType, sceneType, address: PostalAddress,
    deliveryInfo: DeliveryModality, horasEntrega: DeliveryHours, deliveryDate: DeliveryDate,
    selectedStore: DeliverySelectedStore, selectedOffice?: DeliveryOffice) {
    const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

    const url = API_URLS.Checkout.postDeliveryData;

    let params = {
      flagTieneError: false,
      itPortabilidad: Number(itPortabilidad),
      clientType: isMicroResegmented ? commercialMobile.clientTypeMicro : Number(clientType),
      shopType: isMicroResegmented ? commercialMobile.shopTypeMicro : Number(shopType),
      sceneType: Number(sceneType),
      address: {
        door: address.door,
        floor: address.floor,
        name: address.name,
        number: address.addressNumber,
        postcode: address.zipCode,
        province: Number(address.province),
        town: address.town,
        type: address.type,
        verticalId: address.verticalId,
        longitude: address.longitud,
        latitude: address.latitud
      },
      cdTipoProceso: deliveryInfo.cdTipoProceso,
      xTipoProceso: deliveryInfo.dsTipoProceso,
      xCanal: deliveryInfo.xCanal,
      xPerfil: deliveryInfo.xPerfil,
      xTipoCliente: deliveryInfo.xTipoCliente,
      idModalidadEntrega: horasEntrega.idModalidadEntrega,
      idTipoEntrega: horasEntrega.idTipoEntrega,
      cdTipoDeliveryType: horasEntrega.cdTipoDeliveryType,
      xOpcEntrega: horasEntrega.xOpcEntrega,
      nombreModalidadEntrega: horasEntrega.nombreModalidadEntrega,
      dsHoraEntrega: horasEntrega.dsHoraEntrega,
      dsOpcEntrega: horasEntrega.dsOpcEntrega,
      xIdentificacion: horasEntrega.xIdentificacion,
      xPrioridadEntrega: horasEntrega.xPrioridadEntrega,
      xCosteOpcEntrega: {
        eurPrecio: horasEntrega.eurPrecio,
        eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
        eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
        eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
        cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
        dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
        euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
      },
      xDestEntrega: horasEntrega.cdTipoDeliveryType,
      xCosteEur: horasEntrega.eurPrecioTaxes,
      xCostePromoEur: horasEntrega.eurPrecioPromocionTaxes,
      xDsOpcEntrega: horasEntrega.dsOpcEntrega,
      eurPrecio: horasEntrega.eurPrecio,
      eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
      eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
      eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
      cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
      dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
      euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
      fcEntregaPedido: deliveryDate?.fechaEntregaPedido,
      fechaPortabilidad: deliveryDate?.fechaPortabilidad,
      xDiaEntrega: deliveryDate?.xDiaEntrega,
      xHoraLiberacion: deliveryDate?.xHoraLiberacion,
      xSlaFraude: deliveryDate?.xSlaFraude,
      fcPortabilidad: deliveryDate?.fechaPortabilidad,
    };

    if (selectedStore) {
      params = Object.assign(params, {
        tiendaSfid: selectedStore.tiendaSfid,
        tiendaNombre: selectedStore.tiendaNombre,
        tiendaProvincia: selectedStore.tiendaProvincia,
        tiendaCdAlmacen: selectedStore.tiendaCdAlmacen,
        tiendaTelefono: selectedStore.tiendaTelefono,
      });
    }

    if (selectedOffice) {
      params = Object.assign(params, {
        correosId: selectedOffice.id,
        correosCodired: selectedOffice.codired
      });
      this.selectedDeliveryOffice = selectedOffice;
    }

    const options = this.headersData();

    return this.http.post(url, params, options).pipe(map((res: any) => {
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  saveParamsMtp(params?: InstallationDataModel) {
    this.installationData = params;
    this.installationDataSubject.next(this.installationData);
  }


  public postSaveCitaOrig(fechaCita: string, franjaHorariaCita: string) {
    const url = API_URLS.Checkout.postSaveCitaOrig;
    const options = this.headersData();

    const params = {
      fechaCita: fechaCita,
      franjaHorariaCita: franjaHorariaCita,
      flagAgendarCita: true
    }

    return this.http.post(url, params, options).pipe(map((res: any) => {
      return res;
    }))};

  public getCoordinates(zipCode) {
    const url = API_URLS.Checkout.getCoordinates.replace('{zipCode}', zipCode);
    const options = this.headersData();

    return this.http.get(url, options).pipe(map((res: any) => {
      return this.getCoordinatesLogic(res);
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getTiendasEntrega(province, latitude, longitude, shopType) {
    const url = API_URLS.Checkout.getTiendasEntrega.replace('{province}', province)
      .replace('{latitude}', latitude).replace('{longitude}', longitude)
      .replace('{shopType}', shopType).replace('{checkStock}', 'false');
    const options = this.headersData();
    return this.http.get(url, options).pipe(map((res: any) => {
      return this.getTiendasEntregaLogic(res);
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  private deliveryTypesLogic(response): DeliveryType[] {
    const resTypes = JsonQuery.value(response, JSON_PATHS.DeliveryTypes.deliveryTipes) || null;
    return resTypes.map(type => {
      const deliveryType = new DeliveryType();
      deliveryType.idType = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.idType) || null;
      deliveryType.cdType = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.cdType) || null;
      deliveryType.price = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.price) || 0;
      deliveryType.priceTax = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.priceTax) || 0;
      deliveryType.itMarcadoDefault = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.itMarcadoDefault) || false;
      deliveryType.nmOrden = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.nmOrden) || 0;
      deliveryType.title = JsonQuery.value(type, JSON_PATHS.DeliveryTypes.title) || null;
      return deliveryType;
    });
  }

  private postalAddressLogic(response): PostalAddress {
    const postalAddress = new PostalAddress();
    postalAddress.addressNumber = JsonQuery.value(response, JSON_PATHS.PostalAddress.addressNumber) || null;
    postalAddress.door = JsonQuery.value(response, JSON_PATHS.PostalAddress.door) || null;
    postalAddress.floor = JsonQuery.value(response, JSON_PATHS.PostalAddress.floor) || null;
    postalAddress.name = JsonQuery.value(response, JSON_PATHS.PostalAddress.name) || null;
    postalAddress.province = JsonQuery.value(response, JSON_PATHS.PostalAddress.province) || null;
    postalAddress.town = JsonQuery.value(response, JSON_PATHS.PostalAddress.town) || null;
    postalAddress.type = JsonQuery.value(response, JSON_PATHS.PostalAddress.type) || null;
    postalAddress.verticalId = JsonQuery.value(response, JSON_PATHS.PostalAddress.verticalId) || null;
    postalAddress.zipCode = JsonQuery.value(response, JSON_PATHS.PostalAddress.zipCode) || null;
    return postalAddress;
  }

  private deliveryDatesLogic(response): DeliveryModality {
    const deliveryModality = new DeliveryModality();
    deliveryModality.cdTipoProceso = JsonQuery.value(response, JSON_PATHS.DeliveryDates.cdTipoProceso) || null;
    deliveryModality.dsTipoProceso = JsonQuery.value(response, JSON_PATHS.DeliveryDates.dsTipoProceso) || null;

    deliveryModality.xCanal = JsonQuery.value(response, JSON_PATHS.DeliveryDates.xCanal) || null;
    deliveryModality.xPerfil = JsonQuery.value(response, JSON_PATHS.DeliveryDates.xPerfil) || null;
    deliveryModality.xTipoCliente = JsonQuery.value(response, JSON_PATHS.DeliveryDates.xTipoCliente) || null;

    const responseDates = JsonQuery.value(response, JSON_PATHS.DeliveryDates.deliveryDates) || null;
    deliveryModality.fechasEntrega = responseDates.map(date => {
      const deliveryDate = new DeliveryDate();
      deliveryDate.fechaEntregaPedido = JsonQuery.value(date, JSON_PATHS.DeliveryDates.deliveryDate) || null;
      const portabilityDates = JsonQuery.value(date, JSON_PATHS.DeliveryDates.portabilityDate) || null;
      deliveryDate.fechaEntregaPedido = deliveryDate.fechaEntregaPedido ? moment(deliveryDate.fechaEntregaPedido).toDate() : null;

      if (portabilityDates) {
        deliveryDate.fechaPortabilidad = moment(portabilityDates[0].fechaPortabilidad).toDate() || null;
        deliveryDate.xDiaEntrega = JsonQuery.value(portabilityDates[0], JSON_PATHS.DeliveryDates.xDiaEntrega) || null;
        deliveryDate.xHoraLiberacion = JsonQuery.value(portabilityDates[0], JSON_PATHS.DeliveryDates.xHoraLiberacion) || null;
        deliveryDate.xSlaFraude = JsonQuery.value(portabilityDates[0], JSON_PATHS.DeliveryDates.xSlaFraude) || null;
      }

      return deliveryDate;
    });

    const responseHours = JsonQuery.value(response, JSON_PATHS.DeliveryDates.horasEntrega) || null;
    deliveryModality.horasEntrega = responseHours.map(data => {
      const deliveryHour = new DeliveryHours();
      deliveryHour.cdTipoDeliveryType = JsonQuery.value(data, JSON_PATHS.DeliveryDates.cdTipoDeliveryType) || null;
      deliveryHour.dsHoraEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.dsHoraEntrega) || null;
      deliveryHour.dsOpcEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.dsOpcEntrega) || null;
      deliveryHour.idTipoEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.idTipoEntrega) || null;
      deliveryHour.nombreModalidadEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.nombreModalidadEntrega) || null;
      deliveryHour.cdCatalogOfferCharge = JsonQuery.value(data, JSON_PATHS.DeliveryDates.cdCatalogOfferCharge) || null;
      deliveryHour.dsCatalogOfferChargeName = JsonQuery.value(data, JSON_PATHS.DeliveryDates.dsCatalogOfferChargeName) || null;
      deliveryHour.euCatalogOfferCharge = JsonQuery.value(data, JSON_PATHS.DeliveryDates.euCatalogOfferCharge) || 0;
      deliveryHour.eurPrecio = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecio) || 0;
      deliveryHour.eurPrecioPromocion = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecioPromocion) || 0;
      deliveryHour.eurPrecioPromocionTaxes = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecioPromocionTaxes) || 0;
      deliveryHour.eurPrecioTaxes = JsonQuery.value(data, JSON_PATHS.DeliveryDates.eurPrecioTaxes) || 0;
      deliveryHour.idModalidadEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.idModalidadEntrega) || null;
      deliveryHour.xIdentificacion = JsonQuery.value(data, JSON_PATHS.DeliveryDates.xIdentificacion) || null;
      deliveryHour.xOpcEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.xOpcEntrega) || null;
      deliveryHour.xPrioridadEntrega = JsonQuery.value(data, JSON_PATHS.DeliveryDates.xPrioridadEntrega) || null;
      return deliveryHour;
    });

    return deliveryModality;

  }

  getCoordinatesLogic(response): StoreCoordinates {
    const coords = new StoreCoordinates();
    coords.city = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.city) || null;
    coords.country = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.country) || null;
    coords.latitude = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.latitude) || 0;
    coords.longitude = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.longitude) || 0;
    coords.province = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.province) || null;
    coords.region = JsonQuery.value(response, JSON_PATHS.StoreCoordinates.region) || null;
    return coords;
  }

  getTiendasEntregaLogic(response): DeliveryStore[] {
    const tiendas = JsonQuery.value(response, JSON_PATHS.DeliveryStore.tiendas) || null;
    return tiendas.map(store => {
      const deliveryStore = new DeliveryStore();
      deliveryStore.diaFin = JsonQuery.value(store, JSON_PATHS.DeliveryStore.diaFin) || null;
      deliveryStore.diaInicio = JsonQuery.value(store, JSON_PATHS.DeliveryStore.diaInicio) || null;
      deliveryStore.distanciaPuntoRef = JsonQuery.value(store, JSON_PATHS.DeliveryStore.distanciaPuntoRef) || null;
      deliveryStore.horaFin = JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaFin) || null;
      deliveryStore.horaFinJornadaPartida = JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaFinJornadaPartida) || null;
      deliveryStore.horaInicio = JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaInicio) || null;
      deliveryStore.horaInicioJornadaPartida = JsonQuery.value(store, JSON_PATHS.DeliveryStore.horaInicioJornadaPartida) || null;
      deliveryStore.nombreTienda = JsonQuery.value(store, JSON_PATHS.DeliveryStore.nombreTienda) || null;
      deliveryStore.sfid = JsonQuery.value(store, JSON_PATHS.DeliveryStore.sfid) || null;
      deliveryStore.codigoPostal = JsonQuery.value(store, JSON_PATHS.DeliveryStore.codigoPostal) || null;
      deliveryStore.latitud = JsonQuery.value(store, JSON_PATHS.DeliveryStore.latitud) || 0;
      deliveryStore.longitud = JsonQuery.value(store, JSON_PATHS.DeliveryStore.longitud) || 0;
      deliveryStore.localidad = JsonQuery.value(store, JSON_PATHS.DeliveryStore.localidad) || 0;
      deliveryStore.cdAlmacen = JsonQuery.value(store, JSON_PATHS.DeliveryStore.cdAlmacen) || null;
      deliveryStore.provincia = JsonQuery.value(store, JSON_PATHS.DeliveryStore.provincia) || null;
      deliveryStore.telefono = JsonQuery.value(store, JSON_PATHS.DeliveryStore.telefono) || null;
      return deliveryStore;
    });
  }

  postLegalTerms(id) {
    const url = API_URLS.Checkout.postLegalTerms;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      responseType: 'text' as 'json',
      withCredentials: true
    };
    const params = {
      'listAceptos': [
        {
          'id': id,
          'checked': true
        }]
    }
    return this.http.post(url, params, options).pipe(map((res: any) => {
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  postLegalTermsM2P(legalList) {
    const url = API_URLS.Checkout.postLegalTerms;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      responseType: 'text' as 'json',
      withCredentials: true
    };
    const legalChecksList = [];
    legalList.forEach(res => {
      const plantilla = { id: res, checked: true }
      legalChecksList.push(plantilla)
    })
    const params = {
      legalChecksList
    }
    return this.http.post(url, params, options).pipe(map((res: any) => {
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  getLegalterms(clientType, shopType, isMtp?) {
    const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

    const url: string = API_URLS.Checkout.getLegalTerms
      .replace('{clientType}', isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientType)
      .replace('{shopType}', isMicroResegmented ? commercialMobile.shopTypeMicro : shopType)
      .replace('{page}', isMtp ? CommercialJourneys.pageMtp : CommercialJourneys.page);

    const options = this.headersData();

    return this.http.get(url, options).pipe(map((res: any) => {
      this.legalTermsLogic(res);
      return res;
    }), catchError((err) => {
      return throwError(err);
    }))
  }

  postLegalTermsSave() {
    const url = API_URLS.Checkout.postLegalTermsSave;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    const params = {};
    return this.http.post(url, params, options).pipe(map((res: any) => {
      this.personalData.termsId = res.idIkkiRequest;
      this.personalData.email = res.email;
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public callLineData(clientype: string, selectedPorta: boolean, registerTypeMTP: number,
          sceneType: number, shopType: string, email: string, number: string,
          operator?: number, linePhone?: string, dataUserLine?: CommercialPersonalData): Observable<any> {
    const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

    this.cookieService.put('clientTypeID', isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientype);
    const headers: HttpHeaders = new HttpHeaders();
    const options: HeadersDataOptions = {
      headers: headers,
      withCredentials: true
    };
    const url: string  = API_URLS.Checkout.getLineData;
    const docType: number = ((dataUserLine.dni.charAt(-1) === 'X') ||
                     (dataUserLine.dni.charAt(-1) === 'Y') || (dataUserLine.dni.charAt(-1) === 'Z')) ? 2 : 1;
    let paramsLandData: ParamsLandData, paramsMobileData: ParamsMobileData;
    paramsLandData = this.setParamsLandData(clientype, selectedPorta, registerTypeMTP,
      sceneType, shopType, email, number, docType, operator, linePhone, dataUserLine);
    paramsMobileData = {
      'mobileData': [
        {
          'cartItemID': 0,
          'email': email,
          'lineHolder': true,
          'phoneNumber': number,
          'registerType': 5
        }
      ],
      'clienteType' : Number(isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientype),
      'sceneType': sceneType,
      'shopType':  isMicroResegmented ? commercialMobile.shopTypeMicro : shopType
    }

    const params: ParamsCheckoutComercial = { ...paramsLandData, paramsMobileData };
    return this.http.post(url, params, options).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  public setParamsLandData(clientype: string, selectedPorta: boolean, registerTypeMTP: number,
    sceneType: number, shopType: string, email: string, number: string, docType: number,
    operator?: number, linePhone?: string, dataUserLine?: CommercialPersonalData): ParamsLandData {
    let paramsLandData: ParamsLandData;
    if (dataUserLine?.change) {
      paramsLandData = {
        'landData': [
          {
            'cartItemID': 0,
            'email': email,
            'lineHolder': false,
            'registerType': registerTypeMTP,
            'operator': operator,
            'phoneNumber': linePhone,
            'name': dataUserLine.firstName,
            'surname1': dataUserLine.middleName,
            'surname2': dataUserLine.familyName,
            'docNumber': dataUserLine.dni,
            'docType': docType,
          }
        ]
      }
    } else {
      paramsLandData = {
        'landData': [
          {
            'cartItemID': 0,
            'email': email,
            'lineHolder': selectedPorta,
            'registerType': registerTypeMTP
          }
        ]
      }
      if (operator !== maxNumberOperator) {
        paramsLandData.landData[0].operator = operator;
      }
      if (linePhone !== '') {
        paramsLandData.landData[0].phoneNumber = linePhone;
      }
    }
    if (this.mobileToPackExpediteService.iua) {
      paramsLandData.landData[0].iua = this.mobileToPackExpediteService.iua;
    }

    return paramsLandData;
  }

  public getContainBoxless() {
    const url = API_URLS.Checkout.containBoxless;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    }))
  }

  public postEmailBoxless(mailBoxless) {
    const url = API_URLS.Checkout.updateMailBoxless.replace('{mailBoxless}', mailBoxless);
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    const params = {
      'mailBoxless': mailBoxless
    }
    return this.http.post(url, params, options).pipe(map(() => {
    }))
  }

  private fedError(error) {
    this.federror = error;
    this.fedErrorService.fedError = this.fedError;
    this.router.navigate([config.FedError.route]);
  }


  getCheckOrderCorrepondAppointment() {
    const url = API_URLS.Checkout.comprobarPedidoIkkiCorrespondeCita + CommercialJourneys.offersRequest.M2P.checkout.flagCheckItemsCart;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  getServiceGetAvailabilityAppointment () {
    const url = API_URLS.Checkout.getDisponibilidadCita + CommercialJourneys.offersRequest.M2P.checkout.requestActive;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      return res;
    }))
  }

  SetSave () {
    const url = API_URLS.Checkout.postLegalTermsSave + CommercialJourneys.offersRequest.M2P.checkout.flagCheckItemsCart;
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  legalTermsLogic(arrayLegal: LegalTermDetail) {
    this.legalTermsArray = new Array<LegalTerms>();
    const listAceptos = JsonQuery.value(arrayLegal, JSON_PATHS.LegalTermJson.listAceptos) || null;
    listAceptos.map(op => {
      const legalBasics = new LegalTerms();

      legalBasics.checked = JsonQuery.value(op, JSON_PATHS.LegalTermJson.checked) || false;
      legalBasics.id = JsonQuery.value(op, JSON_PATHS.LegalTermJson.id) || null;
      legalBasics.internalDescription = JsonQuery.value(op, JSON_PATHS.LegalTermJson.internalDescription) || null;
      legalBasics.orden = JsonQuery.value(op, JSON_PATHS.LegalTermJson.orden) || null;

      legalBasics.legalTermDetail = new Array<LegalTermDetail>();
      const legalDetail = JsonQuery.value(op, JSON_PATHS.LegalTermJson.legalTermDetails) || null;
      legalDetail.map(ap => {
        const legalDetailes = new LegalTermDetail();
        legalDetailes.htmlText = JsonQuery.value(ap, JSON_PATHS.LegalTermJson.htmlText) || null;
        legalDetailes.linkShortText = JsonQuery.value(ap, JSON_PATHS.LegalTermJson.linkShortText) || null;
        legalDetailes.shortText = JsonQuery.value(ap, JSON_PATHS.LegalTermJson.shortText) || null;

        legalBasics.legalTermDetail.push(legalDetailes);
        return legalDetailes;
      });
      this.legalTermsArray.push(legalBasics);
      return legalBasics;
    })
  }

  private dropdownsLogic(operator: any, _isMTP: boolean): void {
    this.commercialOperators = new Array<CommercialOperator>();

    const oper: any = (_isMTP) ?
          (JsonQuery.value(operator, JSON_PATHS.CommercialOperators.operadoresFijo) || null) :
          (JsonQuery.value(operator, JSON_PATHS.CommercialOperators.operadoresMovil) || null);

    oper.map(op => {
      const operatorCommercial = new CommercialOperator();
      operatorCommercial.id = JsonQuery.value(op, JSON_PATHS.CommercialOperators.id) || null;
      operatorCommercial.name = JsonQuery.value(op, JSON_PATHS.CommercialOperators.name) || null;
      operatorCommercial.objId = JsonQuery.value(op, JSON_PATHS.CommercialOperators.objId) || null;
      operatorCommercial.visible = JsonQuery.value(op, JSON_PATHS.CommercialOperators.visible) || false;
      operatorCommercial.xDesCortaOper = JsonQuery.value(op, JSON_PATHS.CommercialOperators.xDesCortaOper) || null;
      operatorCommercial.xNomIdentificacion = JsonQuery.value(op, JSON_PATHS.CommercialOperators.xNomIdentificacion) || null;
      operatorCommercial.xNomOperadora = JsonQuery.value(op, JSON_PATHS.CommercialOperators.xNomOperadora) || null;
      this.commercialOperators.push(operatorCommercial);
      return operatorCommercial;
    });
  }

  private shoppingCartLogic(items: CommercialShoppingCart) {
    const shoppingCart = new CommercialShoppingCart();
    shoppingCart.deleteItems = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.deleteItems) || false;
    shoppingCart.overMaxInternetLines =
      JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.overMaxInternetLines) || false;
    shoppingCart.overMaxLines = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.overMaxLines) || false;
    shoppingCart.remainingInternetLines =
      JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.remainingInternetLines) || null;
    shoppingCart.remainingMobileLines =
      JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.remainingMobileLines) || null;
    shoppingCart.taxType = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.taxType) || null;
    shoppingCart.taxValue = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.taxValue) || null;
    shoppingCart.siteId = JsonQuery.value(items, JSON_PATHS.ServicesItemsCommercial.siteId) || null;
    const price = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.price) || null;
    if (price) {
      shoppingCart.price = this.getPrices(shoppingCart.price, items);
    }
    const itemsCart = new Array<CommercialCartItem>();
    const item = JsonQuery.value(items, JSON_PATHS.CartItemsCommercial.cartItems) || null;
    item.map(it => {
      const itemCart = new CommercialCartItem();
      itemCart.cartItemId = JsonQuery.value(it, JSON_PATHS.CartItemsCommercial.cartItemId) || null;
      this.cartItemId = itemCart.cartItemId;
      itemCart.price = JsonQuery.value(it, JSON_PATHS.CartItemsCommercial.price) || null;
      if (itemCart.price) {
        itemCart.price = this.getPrices(itemCart.price, it);
      }
      const services = new Array<CommercialService>();
      const service = JsonQuery.value(it, JSON_PATHS.ServicesItemsCommercial.services) || null;
      service.map(sv => {
        const serv = new CommercialService();
        serv.catalogElementType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.catalogElementType) || null;
        serv.code = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.code) || null;
        serv.itemId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.itemId) || null;
        serv.tax = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.tax) || 0;
        serv.price = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.price) || null;
        if (serv.price) {
          serv.price = this.getPrices(serv.price, sv);
        }

        serv.detail = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.detail) || null;
        if (serv.detail) {
          serv.detail = new CommercialDetail();
          serv.detail.additionalLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.additionalLine) || false;
          serv.detail.agentOffer = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.agentOffer) || false;
          serv.detail.catalogOffer = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.catalogOffer) || null;
          serv.detail.clientType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.clientType) || 0;
          serv.detail.contractType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.contractType) || 0;
          serv.detail.coverageTechnology = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.coverageTechnology) || null;
          serv.detail.crossedOfferId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.crossedOfferId) || null;
          serv.detail.description = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.description) || null;
          serv.detail.idesc = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.idesc) || null;
          serv.detail.manualProvision = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.manualProvision) || false;
          serv.detail.name = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.name) || null;
          serv.detail.nameList = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.nameList) || null;
          serv.detail.numberLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.numberLine) || 0;
          serv.detail.offerId = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.offerId) || 0;
          serv.detail.os = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.os) || null;
          serv.detail.permanenceDuration = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.permanenceDuration) || null;
          serv.detail.plusLine = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.plusLine) || false;
          serv.detail.rateType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.rateType) || 0;
          serv.detail.rateTypeMapeo = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.rateTypeMapeo) || 0;
          serv.detail.registerType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.registerType) || 0;
          serv.detail.registerTypeName = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.sap) || null;
          serv.detail.sap = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.sap) || null;
          serv.detail.sceneType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.sceneType) || 0;
          serv.detail.serviceItemType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.serviceItemType) || null;
          serv.detail.serviceItemTypeName = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.serviceItemTypeName) || null;
          serv.detail.shopType = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.shopType) || 0;
          serv.detail.symmetricSpeed = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.symmetricSpeed) || false;
          serv.detail.promotions = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.promotions) || null;
          if (serv.detail.promotions) {
            const promotions = new Array<PromoCommercial>();
            const promotion = JsonQuery.value(sv, JSON_PATHS.ServicesItemsCommercial.promotions) || null;
            promotion.map(prom => {
              const promo = new PromoCommercial();
              promo.id = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.id) || null;
              promo.applyTo = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.applyTo) || null;
              promo.discountAmount = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountAmount) || null;
              promo.discountAmountTaxes = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountAmountTaxes) || null;
              promo.discountClassType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountClassType) || null;
              promo.discountDuration = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountDuration) || null;
              promo.discountType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountType) || null;
              promo.evaluationOrder = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.evaluationOrder) || null;
              promo.flagIgnoreCRM = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagIgnoreCRM) || false;
              promo.flagRegional = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagRegional) || false;
              promo.headbandOpenText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.headbandOpenText) || null;
              promo.internalName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.internalName) || null;
              promo.linkedToCatalog = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.linkedToCatalog) || null;
              promo.longText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.longText) || null;
              promo.publicName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.publicName) || null;
              promo.detailTextList = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailTextList) || null;
              promo.urlMobilePromotionImage = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImage) || null;
              promo.urlMobilePromotionImageFicha = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImageFicha) || null;
              promotions.push(promo);
            });
            serv.detail.promotions = promotions;
          }
        }
        services.push(serv);
      });
      itemCart.services = services;
      itemsCart.push(itemCart);
    });
    const shippingCosts = JsonQuery.value(items, JSON_PATHS.CommercialShoppingCart.shippingCosts) || null;
    if (shippingCosts) {
      shoppingCart.shippingCosts = this.getShippingCosts(shoppingCart.shippingCosts, items);
    }
    shoppingCart.cartItems = itemsCart;
    this.commercialshoppingCartItems = shoppingCart;
    return this.commercialshoppingCartItems;
  }

  private getPrices(itemToStore, itemToGet) {
    itemToStore = new CommercialPrice();
    itemToStore.devicesUpfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfront) || 0;
    itemToStore.devicesUpfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfront) || 0;
    itemToStore.devicesUpfrontPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfrontPromo) || 0;
    itemToStore.devicesUpfrontTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfrontTaxes) || 0;
    itemToStore.devicesUpfrontTaxesPromo =
      JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.devicesUpfrontTaxesPromo) || 0;
    itemToStore.installment = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installment) || 0;
    itemToStore.installmentPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installmentPromo) || 0;
    itemToStore.installmentPromoTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installmentPromoTaxes) || 0;
    itemToStore.installmentTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.installmentTaxes) || 0;
    itemToStore.singlePayment = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePayment) || 0;
    itemToStore.singlePaymentPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePaymentPromo) || 0;
    itemToStore.singlePaymentTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePaymentTaxes) || 0;
    itemToStore.singlePaymentTaxesPromo =
      JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.singlePaymentTaxesPromo) || 0;
    itemToStore.upfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfront) || 0;
    itemToStore.upfrontPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontPromo) || 0;
    itemToStore.upfrontTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxes) || 0;
    itemToStore.upfrontTaxesPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxesPromo) || 0;
    return itemToStore;
  }

  private getShippingCosts(itemToStore, itemToGet) {
    itemToStore = new CommercialShippingCosts();
    itemToStore.fromActivated = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.fromActivated) || false;
    itemToStore.postalCode = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.postalCode) || null;
    itemToStore.upfront = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontShippingCosts) || 0;
    itemToStore.upfrontPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontPromoShippingCosts) || 0;
    itemToStore.upfrontTaxes = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxesShippingCosts) || 0;
    itemToStore.upfrontTaxesPromo = JsonQuery.value(itemToGet, JSON_PATHS.CommercialShoppingCart.upfrontTaxesPromoShippingCosts) || 0;
    return itemToStore;
  }

  public formatSchedule(store: DeliveryStore, text: string): string {
    const horarioFijo: string = store.horaInicio && store.horaFin && store.diaInicio && store.diaFin;
    const horarioPartido: string = horarioFijo && store.horaInicioJornadaPartida && store.horaFinJornadaPartida;
    let shippingScheduleText: string;

    if (horarioFijo) {
      shippingScheduleText = store.diaInicio[0]
        + '-' + store.diaFin[0]
        + ' ' + store.horaInicio
        + ' a ' + store.horaFin
      if (horarioPartido) {
        shippingScheduleText
          += ' y de ' + store.horaInicioJornadaPartida
          + ' a ' + store.horaFinJornadaPartida;
      }
    } else {
      shippingScheduleText = text;
    }
    return shippingScheduleText;
  }

  public checkModeSelected(selected: boolean, isMtp: boolean, autoinstalableResponse: boolean ): boolean {
    return !selected && (!isMtp || autoinstalableResponse);
  }
}

import { AlertTypeEnum } from '../shared/enums/alertType.enum';
import { AlertlayoutEnum } from '../shared/enums/AlertTypeEnum.enum';

export class PaymentAlertModel {
    showModel: boolean = false;
    modelType?: AlertTypeEnum;
    message?: string;
    layout?: AlertlayoutEnum;

}

import { AppService } from './../../../app.service';
import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { config } from '../../../../config/pages-config';
import { OverlayModel } from '@mva10/mva10-angular';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
import { tagging } from 'src/config/tagging-config';
import { whatsAppLink } from '../../constants/defines';



@Component({
  selector: 'sp-english-modal',
  templateUrl: './english-modal.component.html',
  styleUrls: ['./english-modal.component.scss']
})

export class EnglishModalComponent implements OnInit {
  overlayData: OverlayModel;
  showEnglishOverlay: boolean = true;
  showFullLoader: boolean;

  constructor(public appService: AppService,
    private router: Router,
    private tagging: TaggingHelperService
) { }

  ngOnInit(): void {
    this.tagging.view(null, tagging.englishOverlayJourney.state);
  }


  goToWhatsApp(): void {
    window.open(whatsAppLink);
    this.tagging.track(tagging.englishOverlayJourney.interactions.openWhatsApp.eventName,
      tagging.englishOverlayJourney.interactions.openWhatsApp.data, tagging.englishOverlayJourney.state)
    this.showEnglishOverlay = false
  }

  goToToUp(): void {
    this.router.navigate([config.TopUp.topUpPrepaidservice.route]);
    this.tagging.track(tagging.englishOverlayJourney.interactions.openTopUp.eventName,
      tagging.englishOverlayJourney.interactions.openTopUp.data, tagging.englishOverlayJourney.state)
      this.showEnglishOverlay = false;
  }

  backToDashBoard(): void {
    this.tagging.track(tagging.englishOverlayJourney.interactions.continueExperienceInSpanish.eventName,
      tagging.englishOverlayJourney.interactions.continueExperienceInSpanish.data, tagging.englishOverlayJourney.state)
    this.showEnglishOverlay = false;
  }

  closeOverlay(): void {
    this.tagging.track(tagging.englishOverlayJourney.interactions.closeButton.eventName,
      tagging.englishOverlayJourney.interactions.closeButton.data, tagging.englishOverlayJourney.state)
    this.showEnglishOverlay = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { PaymentNavigationService } from '../../payment/services/payment-navigation.service';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from '../../models/new-tagging-json-model';
import { smartPayTagging } from '../../shared/constants/defines';

@Component({
  selector: 'sp-smart-pay-after-top-up',
  templateUrl: './smart-pay-after-top-up.component.html',
  styleUrls: ['./smart-pay-after-top-up.component.scss']
})
export class SmartPayAfterTopUpComponent implements OnInit {

  constructor(private router: Router,
    private paymentNavService: PaymentNavigationService,
    private newTaggingHelperService: NewTaggingHelperService) { }

  ngOnInit(): void {
    this.newTaggingHelperService.getJsonTagging('smart-pay-after-top-up').subscribe((data: NewTaggingJsonModel) => {
      this.newTaggingHelperService.state(data.page['stateList'][smartPayTagging.retention_smart_pay_screen]);
    });
  }
  navigateToSmartPay(): void {
    this.newTaggingHelperService.getJsonTagging('smart-pay-after-top-up').subscribe((data: NewTaggingJsonModel) => {
      this.newTaggingHelperService.interaction(data.eventList[smartPayTagging.after_top_up_mas_info],
        data.page['stateList'][smartPayTagging.retention_smart_pay_screen]);
    });
    this.paymentNavService.closePayment();
    setTimeout(() => {
      this.router.navigate([config.ProductsAndServices.Smartpay.route]);
    }, 300);
  }
}

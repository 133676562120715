import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorInterface } from './payment-error-interface';
import { PaymentErrorCategory } from 'src/app/shared/enums/payment-error-category.enum';
import { PaymentErrorPostPaid } from './payment-error-post-paid';

export class PaymentErrorFinancedDevicesCancellation extends PaymentErrorPostPaid implements PaymentErrorInterface {
    defaultErrorCategory: PaymentErrorCategory = PaymentErrorCategory.SystemNotAvailable;
    constructor(public paymentNavigationService: PaymentNavigationService,
        public translate: TranslateService,
        public router: Router) {
        super(paymentNavigationService, translate, router);
    }
    /**
     * the default implmentaion of the first btn in error payment screen
     */
    getFirstBtnAction(category: string): Function {
        let btnAction: Function
        switch (category) {
            case PaymentErrorCategory.TimeOut:
            case PaymentErrorCategory.invalidCreditCard:
            case PaymentErrorCategory.CreditCardTypeNotSupported:
            case PaymentErrorCategory.invalidCreditCardInfo:
                btnAction = this.restartJourney();
                break;
            default:
                btnAction = this.close();
                break;
        }
        return btnAction;
    }
}

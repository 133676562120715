import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalComponent } from './legal.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SharedModule } from '../shared/shared.module';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { OpenSourceLibrariesComponent } from './open-source-libraries/open-source-libraries.component';
import { LegalRoutingModule } from './legal-routing.module';

@NgModule({
  declarations: [LegalComponent, PrivacyPolicyComponent, TermsAndConditionComponent, OpenSourceLibrariesComponent],
  imports: [
    LegalRoutingModule,
    CommonModule,
    SharedModule,
  ],
  exports: [LegalComponent, PrivacyPolicyComponent, TermsAndConditionComponent, OpenSourceLibrariesComponent]
})
export class LegalModule { }

import { Component, Input } from '@angular/core';
import { GenereicAccordionModel } from '../../models/generic-accordion.model';

@Component({
  selector: 'sp-generic-accordion',
  templateUrl: './generic-accordion.component.html',
  styleUrls: ['./generic-accordion.component.scss']
})
export class GenericAccordionComponent {
  @Input() accordion: GenereicAccordionModel;
  @Input() lastItem: any;

  openLink(url: string) {
    window.open(url, '_blank');
  }

}

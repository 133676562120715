<div class="payment-dept">
  <form class="payment-dept__form-container" [formGroup]="partialPaymentForm" (ngSubmit)="onSubmit()">
    <div class="payment-dept__total-pay" *ngIf="!showPayPartial">
      <p class="payment-dept__desc">
        {{totalPaymentWcsData.description}}
      </p>
      <p class="payment-dept__amount">
        {{billingService.roundAmount(deptAmount)}}<span class="euro-sign">€</span>
      </p>
      <div *ngIf="hasPayPartialSection" class="debt-partial">
        <div>
          <p class="debt-partial__desc">{{totalPaymentWcsData.partialOption}}</p>
          <a class="debt-partial__link"(click)="payPartial()">{{totalPaymentWcsData.payPartialButtonText}}</a>
        </div>
        <img class="debt-partial__icon" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-info-circle-mid.png" />
      </div>
      <sp-payment-wallet-chevron [marginTop]="32" *ngIf="!isAnonymous"></sp-payment-wallet-chevron>
    </div>
    <div class="payment-dept__partial-pay" *ngIf="showPayPartial">
      <p class="payment-dept__desc">
        {{partialPaymentWcsData.subtitle}}
      </p>
      <p class="payment-dept__amount sm-margin">
        {{billingService.roundAmount(deptAmount)}}<span class="euro-sign">€</span>
      </p>
      <p class="payment-dept__desc desc-margin">{{partialPaymentWcsData.description}}</p>
      <div class="text-box">
        <sp-text ngDefaultControl formControlName="payPartialField" (onBlurFn)="addEurSignToValidInputOnBlur()"
          [mva10Textbox]="true" [placeholder]="partialPaymentWcsData.textboxPlaceholder" (onFocusFn)="onFocus()"
          [showRightIconSuccess]="false" [showErrorMessage]="showErrorMsg"
          [patternErrorMessage]="partialPaymentWcsData.paidAmountErrorMsg" [redHelperText]="true"
          [resetButton]="partialPaymentForm.controls['payPartialField'].value && !showErrorMsg && showXIcon"
          (xButtonClicked)="clickXButtonInTextField()">
        </sp-text>
      </div>
      <sp-payment-wallet-chevron [marginTop]="48" *ngIf="!isAnonymous"></sp-payment-wallet-chevron>
    </div>
    <div class="btn-container" [ngClass]="{'payment-dept__increase-top-margin': isFieldFocused && !paymentWalletService.selectedCard, 'payment-in-advance__decrease-top-margin': isFieldFocused && paymentWalletService.selectedCard}">
      <button type="submit" [disabled]="!partialPaymentForm.valid&&showPayPartial"
        [ngClass]="{'invalid-form': !partialPaymentForm.valid&&showPayPartial,
        'debt-partial__button':!billingService.showPayPartial}">
        {{!showPayPartial? totalPaymentWcsData.confirmButtonText +" "+ billingService.roundAmount(deptAmount)+"€":partialPaymentWcsData.confirmButtonText}}</button>
    </div>
  </form>
  <div class="payment-dept__footer">
    <div class="payment-dept__footer-images">
      <div class="visa-image">
        <img [src]="visaImage" alt="visa" />
      </div>
      <div class="mastercard-image">
        <img [src]="masterCardImage" alt="mastercard" />
      </div>
    </div>
    <div class="payment-dept__footer-securedssl">
      <p>{{securedSslText}}</p>
    </div>
  </div>
</div>

import {throwError,  Observable } from 'rxjs';

import {finalize, catchError, tap} from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { taggingConfig } from '../../models/tagging.model';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';



@Injectable()
export class TaggingInterceptor implements HttpInterceptor {
    constructor(private _TaggingHelperService: NewTaggingHelperService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      taggingConfig.pendingRequests.push(req.method + req.urlWithParams);
      return next.handle(req).pipe(tap(event  => {
        if (event instanceof HttpResponse) {
          const reqIndex = taggingConfig.pendingRequests.indexOf(req.method + req.urlWithParams);
          taggingConfig.pendingRequests.splice(reqIndex, 1);
        }
      }), catchError(
        error => {
          if (error instanceof HttpErrorResponse) {
            /* this function will send the catched error to be grouped to a list **/
           this._TaggingHelperService.creatErrorItem(error)
            const reqIndex = taggingConfig.pendingRequests.indexOf(req.method + req.urlWithParams);
            taggingConfig.pendingRequests.splice(reqIndex, 1);
          }
          return throwError(error);
        }
      ), finalize(() => {
        const reqIndex = taggingConfig.pendingRequests.indexOf(req.method + req.urlWithParams);
        taggingConfig.pendingRequests.splice(reqIndex, 1);
      }));

    }


}

import {catchError, map} from 'rxjs/operators';
import {
  LOCAL_STORAGE_KEYS,
  ServiceSelector,
  cachingKeys,
  JSON_PATHS,
  secondResidences,
  adaraValues,
  AppThemes,
  home5G,
} from '../../shared/constants/defines';
import { CompanyService } from '../../shared/services/company.service';
import { Subject, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../../core/services/storage.service';
import { API_URLS } from '../../shared/constants/routes-config';
import { Subscription } from '../../models/subscription.model';
import { ServiceModel, ServiceResponse } from '../../models/service.model';
import * as JsonQuery from 'jsonpath/jsonpath';
import { SiteType } from '../../shared/enums/siteType.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { BundleType, RoamersBundleType } from '../../shared/enums/bundleType.enum';
import { ServicePackageModel } from '../../models/service-package.model';
import { ProductInventoryModel } from '../../models/product-inventory.model';
import { environment } from '../../../environments/environment';
import { config } from '../../../config/pages-config';
import { Router } from '@angular/router';
import { CustomerType } from '../../shared/enums/customerType.enum';

import { AppService } from '../../app.service';
import { UserProfile } from 'src/app/models/user-profile.model';
import { JWTHelper } from '../../core/services/jwt.helper';
import { evict } from '../../shared/decorators/evict.decorator';

@Injectable()
export class SubscriptionService {
  postpaid = 0;
  transactional: boolean = true;
  prepaid = 0;
  memberAllDataUpdated: boolean;
  serviceSubjectEmitter: Subject<any> = new Subject<any>();
  public customerData: Subscription = new Subscription();
  public changeService: Subject<any> = new Subject<any>();
  public customerSubject: Subject<ServiceModel>;
  public serviceListSite: ServiceModel[];
  showSpinner: boolean;
  showOfferTVDashboard: boolean = false;
  hasOfferTVDashboard: boolean;
  showTVOnline: boolean;
  isCurrentServiceLoaded: Subject<boolean> = new Subject();
  public subscriptionAPICalled: boolean;
  public loadedCompaneis: string[] = [];
  public superOffer: string;
  public customerCoute;
  public customerCouteWithoutDiscount;
  public customerCouteNoTax: string;
  public customerCouteWithoutDiscountNoTax: string;
  public customerExtras: boolean;
  public customerDiscounts;
  public yuUser: boolean = false;
  public inactiveItems: number;
  private cachedServices: {
    [siteId: string]: ServiceModel[];
  } = {};

  public cachedRoamers: { [siteId: string]: { [serviceId: string]: BundleType[] } } = {};

  public smartPay: boolean;

  constructor(private http: HttpClient,
    private appService: AppService,
    private storageService: StorageService,
    private companyService: CompanyService,
    private jwtHelper: JWTHelper,
    private router: Router) {
    this.customerSubject = new Subject<ServiceModel>();
  }

  /**
   * returns all services of the current cutomer and the first service is the
   * default service
   */
  GetDefaultServices(tagetMsisdn?) {
    this.customerData.services = new Array<ServiceModel>();
    let url;
    if (tagetMsisdn) {
      url = API_URLS.Subscriptions.Subscription + `?targetMsisdn=${tagetMsisdn}`;
    }
    else {
      url = API_URLS.Subscriptions.Subscription;
    }
    this.serviceListSite = [];
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (environment.stubsFlag) {
      // headers = headers.append('stubs-id', '1');
    }
    const options = {
      headers: headers,
      observe: 'response' as 'body'
    };
    return this.http.get(url, options).pipe(map((response: HttpResponse<any>) => {
      const res: any = response.body;
      const parts: string[] = response.headers.get('Authorization').split('.');
      // ensure the jwt is correct
      if (parts.length === 3) {
        this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.JWT, [response.headers.get('Authorization')]);
        this.storageService.userProfile = this.getUserProfile(response.headers.get('Authorization'));
        this.storageService.gdprUpdatedLocaly = false;
      }
      this.superOffer = JsonQuery.value(res, JSON_PATHS.Subscription.superOffer);
      this.yuUser = JsonQuery.value(res, JSON_PATHS.Subscription.digital);
      this.customerData.customerAccountsId = JsonQuery.value(res,
        JSON_PATHS.Subscription.customerAccount)[JSON_PATHS.Subscription.customerAccountId][0].id;
      const services = JsonQuery.value(res, JSON_PATHS.Subscription.services);
      this.tarnsformServiceData(services);
      this.customerCoute = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionDecimalAmount);
      this.inactiveItems = JsonQuery.value(res, JSON_PATHS.Subscription.inactiveItems);
      this.customerCouteWithoutDiscount = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionWithoutDiscountAmount);
      this.customerCouteNoTax = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionDecimalAmountNoTax);
      this.customerCouteWithoutDiscountNoTax = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionWithoutDiscountAmountNoTax);
      this.customerExtras = JsonQuery.value(res, JSON_PATHS.Subscription.extras);
      this.customerDiscounts = JsonQuery.value(res, JSON_PATHS.Subscription.discounts);
      this.customerData.currentService = this.customerData.services[0] || null;
      this.smartPay = JsonQuery.value(res, JSON_PATHS.Subscription.smartPay);
      this.customerSubject.next(this.customerData.services[0]);
      this.refreshRoamers(this.customerData.customerAccountsId);
      this.customerData.services.map((el, index) => {
        if (el.siteId === this.customerData.customerAccountsId) {
          const subscriptionMbb: ServiceModel =
            this.customerData.services.find(item =>
              item.tarrifCode === secondResidences.tariffCode || item.tarrifCode === home5G.tariffCode);
          if (subscriptionMbb) {
            if (el.name === secondResidences.title || el.tarrifCode === home5G.tariffCode) {
              el.name = el.desc;
            }
          }
          this.serviceListSite.push(el)
        }
        if ((index + 1) === this.customerData.services.length) {
          this.checkShowOfferTVDashboard();
        }
      })
      this.appService.initGetUserInit(res);
      return res;
    }), catchError(error => {
      this.serviceSubjectEmitter.next(error)
      if (!tagetMsisdn) {
        this.storageService.empty();
        this.router.navigate([config.login.route]);
      }
      return throwError(error);
    }));
  }
  getUserProfile(jwt: string): UserProfile {
    const userProfile: UserProfile = this.jwtHelper.getUserProfileFromJWT(jwt);
    this.storageService.userProfile = userProfile;
    this.storageService.setLocalStorage('username', userProfile.username);
    this.redirectToAdara(userProfile)
    return userProfile;
  }
  /**
 * Fetch Customer Accounts to get all sites of the current customer using cif comes from company chooser
 */
  GetCompanyServices(cif: string, isAutoSelected?: boolean) {
    if (isAutoSelected) {
      /* Clear currentService to load from new CIF */
      this.customerData.services = new Array<ServiceModel>();
      this.customerData.currentService = null;
    }
    if (!this.customerData.services) {

      this.customerData.services = new Array<ServiceModel>();
    }
    this.serviceListSite = [];
    const url = API_URLS.Subscriptions.Subscription + `?holderId=${cif}`;
    let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      this.loadedCompaneis.push(cif);
      this.superOffer = JsonQuery.value(res, JSON_PATHS.Subscription.superOffer);
      this.customerData.customerAccountsId = JsonQuery.value(res,
        JSON_PATHS.Subscription.customerAccount)[JSON_PATHS.Subscription.customerAccountId][0].id;
      const services = JsonQuery.value(res, JSON_PATHS.Subscription.services);
      this.tarnsformServiceData(services);
      this.customerCoute = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionDecimalAmount);
      this.customerCouteWithoutDiscount = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionWithoutDiscountAmount);
      this.customerCouteNoTax = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionDecimalAmountNoTax);
      this.customerCouteWithoutDiscountNoTax = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionWithoutDiscountAmountNoTax);
      this.customerExtras = JsonQuery.value(res, JSON_PATHS.Subscription.extras);
      this.customerDiscounts = JsonQuery.value(res, JSON_PATHS.Subscription.discounts);
      this.customerData.currentService = this.customerData.services[0] || null;
      this.inactiveItems = JsonQuery.value(res, JSON_PATHS.Subscription.inactiveItems);
      this.customerSubject.next(this.customerData.services[0])
      this.refreshRoamers(this.customerData.customerAccountsId);
      this.customerData.services.map((el, index) => {
        if (el.siteId === this.customerData.customerAccountsId) {
          this.serviceListSite.push(el)
        }
        if ((index + 1) === this.customerData.services.length) {
          this.checkShowOfferTVDashboard();
        }
      })
      this.appService.initGetUserInit(res);
      return res;
    }), catchError(error => {
      this.serviceSubjectEmitter.next(error)
      return throwError(error);
    }));
  }
  /**
* Fetch Customer Accounts to get all sites of the current customer using service id
*/
  GetCustomerServices(siteId: string, keepOldServices: boolean = false, changeCurrentSite: boolean = true): Observable<Object> {
    let url;
    if (this.companyService.selectedCompanyId) {
      url = API_URLS.Subscriptions.Subscription + `?holderId=${this.companyService.selectedCompanyId}&customerAccountId=${siteId}`;
    } else {

      url = API_URLS.Subscriptions.Subscription + `?customerAccountId=${siteId}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      this.subscriptionAPICalled = true;
      this.customerData.customerAccountsId = JsonQuery.value(res,
        JSON_PATHS.Subscription.customerAccount)[JSON_PATHS.Subscription.customerAccountId][0].id;
      const services = JsonQuery.value(res, JSON_PATHS.Subscription.services);
      const mappedServices = this.tarnsformServiceData(services, keepOldServices, changeCurrentSite);
      this.customerCoute = JsonQuery.value(res, JSON_PATHS.Subscription.subscriptionDecimalAmount);
      this.inactiveItems = JsonQuery.value(res, JSON_PATHS.Subscription.inactiveItems);
      if (changeCurrentSite) {
        this.changeCurrentSite(
          JsonQuery.value(res, JSON_PATHS.Subscription.customerAccount)[JSON_PATHS.Subscription.customerAccountId][0].id
        );
        this.superOffer = JsonQuery.value(res, JSON_PATHS.Subscription.superOffer);
      }
      this.appService.initGetUserInit(res);
      this.cachedServices[siteId] = mappedServices;
      return mappedServices || res;
    }
    ));
  }

  changeCurrentSite(siteId: string): void {
    this.serviceListSite = [];
    this.customerData.customerAccountsId = siteId;
    this.customerData.services.map((el, index) => {
      if (el.siteId === siteId) {
        this.serviceListSite.push(el);
      }
      if (index + 1 === this.customerData.services.length) {
        this.checkShowOfferTVDashboard();
      }
    });
    this.refreshRoamers(siteId);
  }

  getCachedServicesForSite(siteId: string): Observable<ServiceModel[]> {
    if (!this.cachedServices[siteId] || (this.cachedServices[siteId] && this.cachedServices[siteId].length === 0)) {
      let url = API_URLS.Subscriptions.Subscription + `?customerAccountId=${siteId}`;
      if (this.companyService.selectedCompanyId) {
        url += `&holderId=${this.companyService.selectedCompanyId}`;
      }
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('accept', 'application/json');
      return this.http.get(url, { headers }).pipe(map(res => {
        const services: any = JsonQuery.value(res, JSON_PATHS.Subscription.services);
        this.cachedServices[siteId] = this.tarnsformServiceData(services);
        return this.cachedServices[siteId];
      }));
    } else {
      return of(this.cachedServices[siteId]);
    }
  }

  /*
  Get Specific service details
  */
  GetService(serviceId: string) {
    const url = API_URLS.Subscriptions.SubscriptionById.replace('{id}', serviceId);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      this.customerSubject.next(this.mapServiceData(res))
      return this.mapServiceData(res);
    }));
  }

  private tarnsformServiceData(data: any, keepOldServices?: boolean, changeCurrentSite: boolean = true) {
    this.postpaid = 0;
    this.prepaid = 0;
    const Services: ServiceModel[] = []
    data.map(el => {
      const service = this.mapServiceData(el);
      if (changeCurrentSite) {
        if (service.type.toLocaleLowerCase() === ServiceType.Postpaid.toLocaleLowerCase() ||
          service.type.toLocaleLowerCase() === ServiceType.MbbPostpaid.toLocaleLowerCase()) {
          this.postpaid++;
        } else if (service.type.toLocaleLowerCase() === ServiceType.Prepaid.toLocaleLowerCase() ||
          service.type.toLocaleLowerCase() === ServiceType.MbbPrepaid.toLocaleLowerCase()) {
          this.prepaid++;
        } else {
          this.postpaid++;
        }
      }
      service.msisdn = JsonQuery.value(el, JSON_PATHS.Subscription.msisdn);

      if ((this.customerData.services && !this.customerData.services.find((el) => { return el.id === service.id })) || keepOldServices) {

        Services.push(service);
      }
    });
    this.customerData.services = keepOldServices || Services.length === 0 ? this.customerData.services : Services

    if (this.postpaid) {
      this.customerData.SiteType = SiteType.Postpaid;
    } else if (this.prepaid > this.postpaid && this.postpaid === 0) {
      this.customerData.SiteType = SiteType.Prepaid;
    }
    return Services;
  }
  private mapServiceData(resp: ServiceResponse): ServiceModel {
    let service: ServiceModel = new ServiceModel();
    service.id = JsonQuery.value(resp, JSON_PATHS.Subscription.seviceId);
    service.name = JsonQuery.value(resp, JSON_PATHS.Subscription.serviceName);
    service.internetSpeed = JsonQuery.value(resp, JSON_PATHS.Subscription.internetSpeed);
    service.desc = JsonQuery.value(resp, JSON_PATHS.Subscription.serviceDescription);
    service.status = JsonQuery.value(resp, JSON_PATHS.Subscription.serviceStatus);
    service.siteDigital = JsonQuery.value(resp, JSON_PATHS.Subscription.siteDigital);
    service.serviceDigital = JsonQuery.value(resp, JSON_PATHS.Subscription.serviceDigital) || false;
    service.type = JsonQuery.value(resp, JSON_PATHS.Subscription.serviceType);
    service.siteDigital = JsonQuery.value(resp, JSON_PATHS.Subscription.siteDigital);
    service.serviceDigital = JsonQuery.value(resp, JSON_PATHS.Subscription.serviceDigital) || false;
    service.onePlus = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].onePlus;
    service.onePlusMicro = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].onePlusMicro;
    service.siteId = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].id;
    service.segment = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].segment;
    service.siteType = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].type;
    service.clientStatus = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].status;
    service.overdue = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].overdue;
    service.smartPay = JsonQuery.value(resp,
      JSON_PATHS.Subscription.parts)[JSON_PATHS.Subscription.customerAccountId][0].smartPay;
    const allPackagesTypes = Object.keys(ServiceSelector);
    const packageType: string = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageType) || '';

    if (JsonQuery.value(resp, JSON_PATHS.Subscription.Package) &&
      Object.keys(JsonQuery.value(resp, JSON_PATHS.Subscription.Package)).length > 0 && (
        packageType && allPackagesTypes.map(pack => pack.toLowerCase()).includes(packageType.toLowerCase()))) {

      service.package = this.mapPackage(resp)
      if (packageType.toLowerCase() === ServiceSelector.Familia.toLowerCase()) {
        service.familia = this.mapPackage(resp)
      }
    }
    service.tarrifCode = JsonQuery.value(resp, JSON_PATHS.Subscription.tarrifCode) || null;
    service.instance = JsonQuery.value(resp, JSON_PATHS.Subscription.instance) || null;

    service = this.transformNameHome5G(service);
    return service;
  }

  public transformNameHome5G(service: ServiceModel): ServiceModel {
    if (service.tarrifCode === home5G.tariffCode) {
      service.name = service.desc;
    }
    return service;
  }

  checkShowOfferTVDashboard() {
    this.hasOfferTVDashboard = false;
    this.showOfferTVDashboard = false;
    this.showTVOnline = false;
    for (let i = 0; i < this.serviceListSite.length; i++) {
      if (this.serviceListSite[i].type.toUpperCase() === ServiceType.Tv.toUpperCase()) {
        this.hasOfferTVDashboard = false;
        break;
      } else if ((this.storageService.userProfile.customerType.toString().toLowerCase() !== CustomerType.Employee.toLowerCase())) {
        this.hasOfferTVDashboard = true;
      }
      if ((this.serviceListSite[i].type.toUpperCase() === ServiceType.Postpaid.toUpperCase())) {
        this.showTVOnline = true;
      }
    }
  }

  mapPackage(resp) {
    const servicePackage = new ServicePackageModel();
    servicePackage.code = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageCode) || '';
    servicePackage.name = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageName) || '';
    servicePackage.type = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageType) || '';
    servicePackage.title = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageTitle) || '';
    servicePackage.subtitle = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageSubtitle) || '';
    servicePackage.additionalLinesDesc = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageAdditionalLinesDesc) || '';
    servicePackage.startDate = JsonQuery.value(resp, JSON_PATHS.Subscription.PackageStartDate) || '';
    return servicePackage;
  }

  /**
  * Recover a list with the "grifos" for a user
  *
  * @param siteId
  * @param keepOldServices
  * @param changeCurrentSite
  *
  * @returns Array
  */

  GetGrifoService(siteId: string, keepOldServices: boolean = false, changeCurrentSite: boolean = true): Observable<any[]> {
    let url;

    if (this.companyService.selectedCompanyId) {
      url = API_URLS.Subscriptions.Subscription + `?holderId=${this.companyService.selectedCompanyId}`;
    } else {

      url = API_URLS.Subscriptions.Subscription + `?customerAccountId=${siteId}`;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((response: any) => {
      const grifos: any[] = [];
      if (response.properties) {
        response.properties.forEach(element => {
          grifos.push(element);
        });
      }
      return grifos;
    }))
  }

  /**
   * Refresh roamers Observable while has not been cached before
   * @param siteId: string
   */
  private refreshRoamers(siteId: string): void {
    if (!this.cachedRoamers[siteId]) {
      this.GetProductInventory(siteId).subscribe((res: ProductInventoryModel[]) => {
        this.cachedRoamers[siteId] = {}
        res.forEach(serviceInventory => {
          this.cachedRoamers[siteId][serviceInventory.productSerialNumber] = !serviceInventory.product ? [] :
            serviceInventory.product
              .filter(sva => RoamersBundleType[sva.productOffering?.id?.toUpperCase()])
              .map(roamerSVA => RoamersBundleType[roamerSVA.productOffering?.id?.toUpperCase()])
        });
      });
    }
  }

  /**
   * Get SVA inventory by customer account id
   * @param siteId: string
   * @returns Observable<ProductInventoryModel[]>
   */
  GetProductInventory(siteId: string): Observable<ProductInventoryModel[]> {
    const url: string = API_URLS.ProductSVAInventory.replace('{siteId}', siteId);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('X-VF-API-Process', 'ConsultarServicios');

    return this.http.get(url, { headers: headers }).pipe(map((res: ProductInventoryModel[]) => {
      return res;
    }));
  }
  /**
  * Get customer account ID from Customer-Data or User-Profile
  */
  public GetCustomerAccountsId(): string {
    let siteID: string = this.customerData?.customerAccountsId;
    if (!siteID && this.storageService.userProfile?.sites?.length > 0) {
      siteID = this.storageService.userProfile.sites[0].id;
    }
    return siteID;
  }

  public DownloadFile(url: string, options: any): Observable<any> {
    return this.http.get(url, options);
  }

  @evict(cachingKeys.Subscriptions)
  GetDefaultServicesWithoutCache(targetMsisdn: string): Observable<any> {
      return this.GetDefaultServices(targetMsisdn).pipe(map(res => res));
  }

  private redirectToAdara(userProfile: UserProfile): void {
    if (userProfile.category === adaraValues.categoryAdara) {
      this.appService.theme = AppThemes.ThemeBkgWhite;
      this.appService.showFullAppLoader = true;
      this.router.navigate([config.adara.name], { queryParams: { origen: adaraValues.originLogin }})
    }
  }
}

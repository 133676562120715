import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'sp-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModalComponent implements OnChanges, OnInit {
  /**
   * Iframe url
   */
  @Input() url: string = '';
  /**
 * retry function
 */
  @Input() retryFunc;
  /**
 * show error message
 */
  @Input() showError: boolean = false;
  /**
 * to know which tab of Iframe is clicked
 */
  @Input() tabClickedDetector: string = '';
  /**
 * boolean to show loading
 */
  showLoading: boolean = true;
  /**
 * boolean to show loading message
 */
  @Input() showLoadingMessage: boolean = true;
  /**
 * boolean to show button in error case
 */
  @Input() showErrorButton: boolean;

  @Output() onIframeLoad = new EventEmitter<any>();
  @Output() onIframeError = new EventEmitter<any>();

  errorTitle: string;
  @ViewChild('iframe') iframe: HTMLIFrameElement;
  iframeURL: any;
  close: () => void;
  @Input() headerText: string = '';
  constructor(private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService) {
  }
  ngOnInit() {
    this.translate.get('common.messagesList').subscribe((data) => {
      if (this.showErrorButton) {
        this.errorTitle = data.chatApiTimeOut.chatApiTimeOut_description;
      }
      else {
        this.errorTitle = data.chatApiFailure.chatApiFailure_description;
      }
    })
  }
  retry() {
    this.showLoading = true;
    this.retryFunc();
  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes['url'] && changes['url'].currentValue ) {
        this.iframeURL = changes['url'] ? this.sanitizer.bypassSecurityTrustResourceUrl(changes['url'].currentValue) :
        this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      if (this.iframeURL) {
        this.changeDetector.detectChanges();
        this.drawIframe();
       }
    }
    if (changes['url'] && ! changes['url'].currentValue ) {
      this.showLoading = true;
      this.showError = false;
    }
  }

  drawIframe() {
    this.iframe = document.getElementById('Iframe') as  HTMLIFrameElement;
    if (this.headerText) {
      this.iframe.style.minHeight = '62vh';
      this.iframe.scrollTop = 0;
    }
    this.iframe.onload = () => {
      this.onIframeLoad.emit(this.iframe);
        this.afterIframeLoad();
    }
    this.iframe.onerror = (event) => {
      this.onIframeError.emit(event);
    }
  }
  afterIframeLoad() {
      this.showLoading = false;
      this.showError = false;
      this.iframe.allowFullscreen = true;
  }
  setIframeUrl() {
    if (this.url) {
      this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      if (this.iframeURL) {
        this.changeDetector.detectChanges();
        this.drawIframe();
      }
    }
  }
}

<div class="alert-info" [style.borderColor]="alertColor">
    <div class="content" [ngClass]="{'commercial': commercial}">
        <div class="col-xs-2 iconSVG-container"
            [ngClass]="{'top-icon': !centerIcon, 'center-icon': centerIcon, 'commercial': commercial}"
            [style.background-color]="alertColor">
            <i *ngIf="!iconSVG && !iconDSL" class="icon" [ngClass]="icon"></i>
            <svg-icon *ngIf="iconSVG && !iconDSL" src="{{iconSVG}}" class="iconSVG"></svg-icon>
            <svg id="alertInfoIconDSL" *ngIf="iconDSL && !iconSVG" class="iconDSL" [ngClass]="iconDSL">
                <use [attr.xlink:href]="'#' + iconDSL" />
            </svg>
        </div>

        <div class="col-xs-10 reduce-description-padding" [ngClass]="{'remove-bottom-padding': removeBottomPadding}">
            <span class="bold-title" *ngIf="boldTitle">{{boldTitle}}</span>
            <div *ngIf="showDescription" class="desc-position">
                <span class="desc-style" *ngIf="description">{{description}}</span> <span class="bold-number"
                    *ngIf="boldText">{{boldText}}</span>
            </div>
            <p *ngIf="message" [innerHTML]="message"></p>
            <p *ngIf="messageSecondary" [innerHTML]="messageSecondary" class="messageSecondary"></p>
            <!--MVES 2295 Alert start -->
            <div *ngIf="choices?.length>1">
                <p class="history-dropdown-title">{{'productsServices.tv.itemsList.selectMsisdn.body'|translate}}</p>
                <sp-toggle-dropdown (dropdownInitValue)="getInitValue($event)" (clickevent)="selectedValue($event)"
                    (expanded)="changeLineStatus($event)" [selectPpeDdStatus]="true" [dataSet]="choices">
                </sp-toggle-dropdown>
            </div>
            <!--MVES 2295 Alert end-->
            <div *ngIf="hasInputField">
                <form [formGroup]="alertForm">
                    <span
                        class="input-title">{{'myAccount.personalInfo.fieldsList.mobileNumber.placeHolder'|translate}}:</span>
                    <sp-text [placeholder]="placeHolder?placeHolder :' '"
                        (input)="msisdnValueChanged($event.target.value)"
                        patternErrorMessage="{{'faultManagement.itemsList.validationMessageForPhone.body'|translate}}"
                        [pattern]="Validation.msisdn" [textType]="'text'" [max]="9" [enforceValidation]="true"
                        formControlName="msisdn" ngDefaultControl></sp-text>
                </form>
            </div>
            <!--hr *ngIf="showLine && !generalBreakDownSecondAlert" -->
            <div *ngIf="generalBreakDownSecondAlert">
                <span
                    class="input-title">{{'faultManagement.messagesList.generalBreakdownAlerts.subtitle'|translate}}</span>

                <div class="repair-img-wrapper">
                    <img src="{{'faultManagement.messagesList.generalBreakdownAlerts.icon.url'|translate}}" alt="">
                    <span>{{'faultManagement.messagesList.generalBreakdownAlerts.title'|translate}}</span>
                </div>
            </div>
            <button [disabled]="hasInputField && (alertForm.invalid || !msisdn)"
                id="AlertInfoComp_btn_executeButtonAction" *ngIf="showButton" type="button"
                class="button gray-new two btn-position col-xs-12"
                [ngClass]="{'adjust-design': adjustButtonDesign}"
                (click)="executeButtonAction()">{{buttonText||'productsServices.prepaidPlan.buttonsList.planReload.text'
                | translate}} </button>
            <div class="navigator-option" *ngIf="showNavigator">
                <sp-jumbotron-navigator [description]="navigateDescription" (navigateTo)="naigateTo(navigateUrl)">
                </sp-jumbotron-navigator>
            </div>
            <!--DAHAND 493 Presale text notify me-->
            <div class="linkTextAction" *ngIf="linkTextAction" id="appModuleAlertInfoLinkTextAction">
                <span id="appModuleAlertInfoLinkTextActionSpan" class="linkTextActionSpan" (click)="linkTextActionClickedFn()" [innerHtml]="linkTextAction"></span>
            </div>
        </div>
    </div>

</div>
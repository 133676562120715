export class PurchasesAndSubscriptions {
    public type: string;
    public name: string;
    public price: string;
    public priceUnit: string;
    public creationDate: string;
    public companyName: string;
    public companyTelephone: string;
    public subscriptionDetails?: SubscriptionDetails ;
}
export class SubscriptionDetails {
    duration: string;
    ableToUnsubscribe: string;
    subscriptionId: string;
    endDate: string;
    typeOfSubscription: string;
}

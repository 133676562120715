<div *ngIf="enjoyMoreBundles.length == 1">
    <div *ngFor="let enjoyMoreBundle of enjoyMoreBundles; let index=index">
        <div class="chat-pass row change-padding cont-height">
            <div class="row-fix">
                <p class="chat-pass-title">{{'productsServices.messagesList.psPassMsg.psPassMsg_description'|translate}}
                </p>
                <sp-enjoy-more-navigator [title1]="parent.splitName(enjoyMoreBundle.Name)" [title2]="parent.splitAfterPass(enjoyMoreBundle.Name)" (navigateByCode)="parent.routeTo(enjoyMoreBundle.Code)" title2="{{'productsServices.itemsList.psPass.body'|translate}}" [iconName]="enjoyMoreBundle.enjoyMoreActiveIcon"></sp-enjoy-more-navigator>
            </div>
        </div>
        <hr/>
    </div>

</div>
<section *ngIf="enjoyMoreBundles.length > 1">
    <div class="chat-pass row change-padding">
        <div class="row-fix">
            <p class="chat-pass-title square-pass-color">{{'productsServices.messagesList.psPassMsg.psPassMsg_description'|translate}}</p>
            <div class="pass-square-container">
                <div class="col-xs-6 chat-pass-wrapper" *ngFor="let enjoyMoreBundle of enjoyMoreBundles">
                    <div class="pass-square" (click)="parent.routeTo(enjoyMoreBundle.Code)"> {{parent.splitName(enjoyMoreBundle.Name)}} <b>{{parent.splitAfterPass(enjoyMoreBundle.Name)}}</b></div>
                </div>
            </div>

        </div>

    </div>
</section>
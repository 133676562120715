import { Component, OnInit,  Input, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { adaraValues } from '../../constants/defines';
import { FOLDER_ADARA_MY_PRODUCTS, ADARA_TRACKING } from 'src/app/shared/constants/archetype/adaraTracking';
import { NewTaggingJsonModel } from 'src/app/models/new-tagging-json-model';
import { NewTaggingHelperService } from './../../../core/services/new-tagging.helper.service';

@Component({
  selector: 'sp-adara-selector',
  templateUrl: './adara-selector.component.html',
  styleUrls: ['./adara-selector.component.scss']
})
export class AdaraSelectorComponent implements OnInit {

  public telcoIcon: string
  public adaraIcon: string
  public telcoTitle: string
  public adaraTitle: string
  public telcoFilter: boolean = true;
  public adaraFilter: boolean

  @Input() locationClass: string = '';
  @Output() telcoFilterSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private newTaggingHelperService: NewTaggingHelperService
  ) {}

  ngOnInit(): void {
    this.setAdaraSelector()
  }

  public setAdaraSelector(): void {
    this.translateService.get('v10.dashboard.gestion.section').subscribe(data => {
      this.telcoTitle = data.telco
      this.adaraTitle = data.adara
      this.telcoIcon = adaraValues.telcoIcon
      this.adaraIcon = adaraValues.adaraIcon
    });
  }

  public changeAdaraFilter(): void {
    this.telcoFilter = !this.telcoFilter;
    this.adaraFilter = !this.adaraFilter;
    this.telcoFilterSelected.emit(this.telcoFilter)
    this.adaraFilterInteractionTags(this.telcoFilter ? this.telcoTitle : this.adaraTitle)
  }

  public adaraFilterInteractionTags(productType: string): void {
    this.newTaggingHelperService.getJsonTagging(FOLDER_ADARA_MY_PRODUCTS).subscribe((data: NewTaggingJsonModel) => {
      data.eventList[ADARA_TRACKING.INTERACTIONS.FILTER_INTERACTION][ADARA_TRACKING.EVENT][ADARA_TRACKING.EVENT_LABEL]
      = productType;
      this.newTaggingHelperService.interaction(data.eventList[ADARA_TRACKING.INTERACTIONS.FILTER_INTERACTION],
      data.page);
    })
  }

}

<div class="form-group-toggle col-xs-12" [ngClass]="{ 'bigSize': size > 1,  'mediumSize': size === 1, 'smallSize': size < 1 }">
  <label class="switch">
    <input type="checkbox" [disabled]="disable" [id]="id" type="checkbox" [checked]="check" [name]="name" (click)="toggle()">
    <span
      class="slider round"
      [ngClass]="{ 'animate-after-load': stopToggleAnimation, 'disabled': disable, 'uncolored': changeColorIfDisabled }"
    >
      {{ label }}
    </span>
  </label>
</div>
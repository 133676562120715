import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MicroFlowsService } from '../../shared/services/micro-flows.service';
import { dataToMF, entryPointsMVA10, journeyStatuseEnum, journeyTags, microflowsTagg } from '../../shared/constants/defines';
import { UserSettingToken } from '../../shared/enums/user-setting-token.enum';
import { EntryPointsService } from '../../shared/services/entry-points.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { TaggingViewModel } from '../../models/tagging.model';
import { Error } from '../../models/error.model';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { AuthenticateService } from '../services/authenticate.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class MicroFlowsGuard implements CanActivate {
  public screenCode: string;
  public dataToFork: string[];
  private route: ActivatedRouteSnapshot;
  public cartid: string;
  public isMainFlow: boolean;
  private hasTokens: boolean = false;
  private hasAllowed: boolean = false;
  private hasEntryPoints: boolean = false;
  private allowedCode: string;

  constructor(
    public microFlowsService: MicroFlowsService,
    public entryPointService: EntryPointsService,
    public deepLinkingService: DeepLinkingService,
    public subscriptionData: SubscriptionService,
    private router: Router,
    private tagging: TaggingHelperService,
    private authenticateService: AuthenticateService,
    private translate: TranslateService,
    private storageService: StorageService,
    private utilitiesService: UtilitiesService,
  ) { }

  /*******
  To ensure that the calls are made correctly,
  implement in the routing module
  canDeactivate: [MicroFlowDeactivateGuard]
  in parent component
  ******/
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.route = route;
    this.screenCode = this.microFlowsService.screenCode;
    if (this.route.data['screenCode'] === this.screenCode) {
      this.microFlowsService.retry = 0;
      return this.checkExpiredTokens()
        ? this.refreshCall() : true;
    } else {
      this.cartid = '';
      this.microFlowsService.cartId = '';
      if (this.route.queryParams.cartid) {
        this.cartid = this.route.queryParams.cartid;
        this.microFlowsService.cartId = this.cartid;
      }
      this.resetMF();
      this.dataToFork = new Array();
      this.setData();
      this.microFlowsService.setClientType();
      this.dataToFork.forEach(res => {
        this.setServices(res);
      })
      return this.checkServices();

    }
  }

  private setData(): void {
    for (const prop in this.route.data) {
      if (prop === dataToMF.screenCode) {
        this.screenCode = this.route.data[prop];
        this.microFlowsService.screenCode = this.screenCode;
      } else if (this.microFlowsService.dataInMf.indexOf(prop) < 0) {
        this.dataToFork.push(prop);
        this.microFlowsService.dataPush(prop);
      }
    }
  }

  private setServices(service: string): void {
    switch (service) {
      case dataToMF.entryPoints:
        this.hasEntryPoints = true;
        break;
      case dataToMF.allowedServices:
        this.hasAllowed = true;
        break;
      case dataToMF.tokens:
        this.hasTokens = true;
        break;
    }
  }

  private setTaggingLogic(isMain: boolean): void {
    this.microFlowsService.isMainFlow = isMain ? true : false;
  }

  private caseAllowed(): Observable<any> {
    return this.microFlowsService.getAllowedServices(this.allowedCode, this.subscriptionData.customerData.customerAccountsId,
      true).pipe(
        mergeMap(res => {
          return this.caseTokens();
        }), catchError(error => {
          this.errorNavigation(error);
          return error;
        }))
  }

  private caseTokens(): Observable<any> {
    return this.checkExpiredTokens() ? this.refreshCall() : this.tokensCall();
  }

  private resetMF(): void {
    this.microFlowsService.reset();
    this.hasTokens = false;
    this.hasEntryPoints = false;
    this.hasAllowed = false;
  }

  private checkServices(): Observable<any> {
    if (this.hasEntryPoints) {
      this.sendTaggingAccess(microflowsTagg.access);
      return this.microFlowsService.getEntryPoints(this.screenCode, this.subscriptionData.customerData.customerAccountsId, 1, true,
        this.subscriptionData.customerData.currentService.id, null, this.cartid).pipe(
          mergeMap((res) => {
            this.sendTaggingAccess(microflowsTagg.statusep, journeyStatuseEnum.ok);
            if (res && !this.entryPointService.entryPointDebtOtError) {
              if (this.isOldHI()) {
                return of(this.router.parseUrl(config.migrahogar.route));
              } else if (this.hasAllowed) {
                this.allowedCode = this.entryPointService.flows[0]['code'];
                return this.caseAllowed();
              }
              return this.caseTokens();
            } else {
              this.sendTaggingAccess(microflowsTagg.statusep, journeyStatuseEnum.ko);
              this.errorNavigation();
            }
          }), catchError(error => {
            this.sendTaggingAccess(microflowsTagg.statusep, journeyStatuseEnum.ko);
            this.errorNavigation(error);
            return error;
          })
        )
    } else {
      return this.caseTokens();
    }
  }

  /** In case the user needs to be redirected to migrahogar */
  public isOldHI(): boolean {
    return (this.entryPointService.flows[0]['flow'] === entryPointsMVA10.LAHI && this.screenCode === entryPointsMVA10.LAD);
  }

  private errorNavigation(error?: HttpErrorResponse): void {
    if (!!error) {
      this.microFlowsService.navigateError = error;
    }
    this.router.navigate([config.MFError.route], {
      queryParams: {
        origin: this.screenCode,
        cartid: this.cartid
      }
    });
    this.screenCode = '';
  }

  private checkCartID(): void {
    if (!this.cartid && this.route.data.reset) {
      this.microFlowsService.resetCookie(false).subscribe(res => {
        this.microFlowsService.cartId = res?.NTOL_TXID;
        this.cartid = res?.NTOL_TXID;

      }, error => {
        if (error.ecode?.toString() === '1300' || error.error.ecode?.toString() === '1300') {
          this.setTrackErrorTaggingLogic(tagging.newLinesPurchase.strings.error_trastienda, error);
          this.router.navigate([config.dashboard.route]);
        }
      });
      this.setTaggingLogic(true);
    } else if (!this.cartid && !this.route.data.reset) {
      this.errorNavigation();
    } else {
      this.setTaggingLogic(false);
    }
  }

  private setTrackErrorTaggingLogic(errorCategory: string, error: Error): void {
    const page: TaggingViewModel = tagging.newLinesPurchase.page;
    const data: TaggingViewModel = tagging.newLinesPurchase.data;
    const errorData: TaggingViewModel = tagging.newLinesPurchase.error;
    const strings: { [key: string]: string } = tagging.newLinesPurchase.strings;

    page.page_name = page.page_name = page.page_section + ':' +
                                      page.page_subcategory_level_1 + ':' +
                                      strings.error;
    page.page_screen = strings.error;

    data.page_subcategory_level_3 = '';
    data.page_subcategory_level_4 = '';
    data.navigation_level_3 = strings.error;
    data['&&events'] = strings.event100;

    errorData.error_category = errorData.error_category.replace('{error_category}', errorCategory + '_' + error.error?.ecode);
    errorData.error_description = errorData.error_description.replace('{error_description}', error.error?.message);
    errorData.error_type = errorData.error_type.replace('{error_type}', strings.error_tecnico);
    errorData.error_code = errorData.error_code.replace('{error_code}', error.error?.ecode);

    const state: string = page.page_name;
    const context_data: TaggingViewModel = Object.assign(page, data, errorData);
    this.tagging.track(state, context_data);
  }

  private tokensCall(): Observable<any> {
    return this.microFlowsService.getTokens(UserSettingToken.DxlAuth, this.subscriptionData.customerData.customerAccountsId,
      this.subscriptionData.customerData.currentService.id, false).pipe(map(res => {
        this.checkCartID();
        return true;
      }), catchError(error => {
        this.errorNavigation();
        return error;
      }))
  }

  private refreshCall(): Observable<any> {
    return this.authenticateService.refreshTokens().pipe(mergeMap(() => {
      return this.tokensCall();
    }))
  }

  private checkExpiredTokens(): boolean {
    return (this.authenticateService.isAccessTokenAlmostExpired(5) && !this.authenticateService.isRefreshingToken);
  }

  public sendTaggingAccess(type: string, status?: string): void {
      const analitycsName: string = this.setAnalyticName(status);
      const page: TaggingViewModel = { ...tagging.microflowsAccess.page };
      page.page_name = page.page_name.replace('<microflujo>', analitycsName);
      page.page_subcategory_level_2 = analitycsName;
      page.navigation_level_3 = analitycsName;
      page.journey_name = analitycsName;
      page.journey_principal = analitycsName;
      page.journey_step = page.journey_step.replace('<microflujo>', analitycsName);
      page.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
      page.journey_subcategory = this.utilitiesService.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
      page.state_flow = analitycsName;
      if (type === microflowsTagg.access) {
        setTimeout(() => {
          this.tagging.view(false, page);
        }, 500);
      } else if (type === microflowsTagg.statusep && !this.cartid && this.deepLinkingService.isDeepLink) {
        const data: TaggingViewModel = { ...page, ...tagging.microflowsAccess.data };
        if (this.microFlowsService.responseEntrypoints?.entryPoints) {
          data.entrypoint_type = this.microFlowsService.responseEntrypoints.entryPoints[0].statePEGA;
          data.entrypoint_code = this.microFlowsService.responseEntrypoints.entryPoints[0].code;
          data.entrypoint_name = this.microFlowsService.responseEntrypoints.entryPoints[0].name;
        }
        data.event_context = data.event_context.replace('<state>', status);
        setTimeout(() => {
          this.tagging.trackReplace(data.event_name, data, false);
        }, 500);
      }
  }

  public setAnalyticName(response?: string): string {
    let analitycsName: string;
    if (response === journeyStatuseEnum.ok && this.microFlowsService.responseEntrypoints?.entryPoints) {
      const code: string = this.microFlowsService.responseEntrypoints.entryPoints[0].code;
      analitycsName = this.translate.instant(`v10.flows.customization.${code}.analyticsName`);
    }
    return analitycsName ?? this.translate.instant(`v10.flows.nav.${this.screenCode}.analyticsName`);
  }
}

<div class='smartpay-footer-container' *ngIf='smartPayService.showCheckboxAndFooter' #footerContainer>
    <div #checkBoxContainer>
        <div *ngIf='smartPayService.showCheckBox' class='check-box-container' id='smartpayFooterCheckbox'>
            <div class='chechbox-container' id='smartpayFooterCheckboxText'>
                <mva10-c-inputs [type]="1" [inputId]="'checkBoxAction'" [checked]="checkedLegalTerm" [textLabel]=""
                    (change)="onCheckConditions($event)">
                </mva10-c-inputs>
                <p [ngClass]="{'mandatory-error-message': smartPayService.isCTASubmitted && !checkedLegalTerm}" [innerHTML]="'v10.productsServices.smartPay.smartpayPaymentCondition' | translate | safe: 'html'">
                </p>
            </div>
        </div>

    </div>
    <div class='fixed-button-container'>
        <sp-fixed-bottom-button #fixedButton [btnText]='smartPayService.hintCTA' id='smartpayFooterButton'
            [ngClass]="{'dark-gray-button': isSmartPay && smartPayService.isCTAEnabled}" [shadowFooter]='true'
            [btnName]="buttonName" [disabled]="!smartPayService.isCTAEnabled"
            (btnAction)="onClickActivateSmartPay(checkBoxContainer)" [grayBackgroundBelowFooter]='true'
            [isMobileScrollable]="!appService.isMobileScrollable && isMobileView">
        </sp-fixed-bottom-button>
    </div>
</div>
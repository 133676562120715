import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdaraSelectorComponent } from './adara-selector.component';
import { GenericModule } from '@mva10/mva10-angular';



@NgModule({
  declarations: [
    AdaraSelectorComponent
  ],
  imports: [
    CommonModule,
    GenericModule,
  ],
  exports: [
    AdaraSelectorComponent
  ]
})
export class AdaraSelectorModule { }

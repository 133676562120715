import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BillingService } from '../../billing/billing.service';
import { PaymentJourny } from '../../shared/enums/payment-journy.enum';
import { PaymentTrayAction } from '../../shared/enums/payment-tray-action.enum';
import { PaymentWalletService } from '../../shared/services/payment-wallet.service';
import { StorageService } from '../../core/services/storage.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { PaymentCancelDefault } from './paymentCancelDelegate/payment-cancel-default';
import { PaymentCancelInterface } from './paymentCancelDelegate/payment-cancel-interface';
import { PaymentDxlparamDefault } from './paymentDxlParamsDelegate/payment-dxlparam-default';
import { PaymentDxlparamInterface } from './paymentDxlParamsDelegate/payment-dxlparam-interface';
import { PaymentDxlparamPrePaidPostPaid } from './paymentDxlParamsDelegate/payment-dxlparam-prepaid-postPaid';
import { PaymentErrorDefault } from './paymentErrorDelegate/payment-error-default';
import { PaymentErrorInterface } from './paymentErrorDelegate/payment-error-interface';
import { PaymentErrorPostPaid } from './paymentErrorDelegate/payment-error-post-paid';
import { PaymentErrorPostPaidAnonymous } from './paymentErrorDelegate/payment-error-post-paid-anonymous';
import { PaymentErrorPrePaidAnonymous } from './paymentErrorDelegate/payment-error-pre-paid-anonymous';
import { PaymentErrorPrePaidAnonymousThirdparty } from './paymentErrorDelegate/payment-error-pre-paid-anonymous-thirdparty';
import { PaymentErrorPrePaidLogin } from './paymentErrorDelegate/payment-error-pre-paid-login';
import { PaymentErrorStandAloneWalletManagment } from './paymentErrorDelegate/payment-error-stand-alone-wallet-manegment';
import { PaymentSuccessDefault } from './paymentSuccessDelegate/payment-success-default';
import { PaymentSuccessInterface } from './paymentSuccessDelegate/payment-success-interface';
import { PaymentSuccessPostPaid } from './paymentSuccessDelegate/payment-success-post-paid';
import { PaymentSuccessPrePaid } from './paymentSuccessDelegate/payment-success-pre-paid';
import { PaymentSuccessSaveCreditCard } from './paymentSuccessDelegate/payment-success-save-credit-card';
import { PaymentTaggingInterface, PaymentTaggingDefault } from './paymentTaggingDelegate/payment-tagging-interface';
import { PaymentTaggingPostPaid } from './paymentTaggingDelegate/payment-tagging-post-paid';
import { PaymentTaggingPostPaidCashDesk } from './paymentTaggingDelegate/payment-tagging-post-paid-cash-desk';
import { PaymentTaggingPostPaidInAdvance } from './paymentTaggingDelegate/payment-tagging-post-paid-in-advance';
import { PaymentTaggingPrePaid } from './paymentTaggingDelegate/payment-tagging-pre-paid';
import { PaymentTaggingPrePaidAnonymous } from './paymentTaggingDelegate/payment-tagging-pre-paid-anonymous';
import { PaymentTrayConfigAnonymousPostPaid } from './paymentTrayConfigDelegate/payment-tray-config-anonymous-postPaid';
import { PaymentTaggingStandAloneWalletManagment } from './paymentTaggingDelegate/payment-tagging-stand-alone-wallet-managment';
import { PaymentTrayConfigDefault } from './paymentTrayConfigDelegate/payment-tray-config-default';
import { PaymentTrayConfigInterface } from './paymentTrayConfigDelegate/payment-tray-config-interface';
import { PaymentTrayConfigPostPaid } from './paymentTrayConfigDelegate/payment-tray-config-postPaid';
import { PaymentTrayConfigPrePaid } from './paymentTrayConfigDelegate/payment-tray-config-prePaid';
import { PaymentTrayConfigPrePaidAnonymous } from './paymentTrayConfigDelegate/payment-tray-config-prePaid-anonymous';
import { PaymentTrayConfigPrePaidAnonymousThirdParty } from './paymentTrayConfigDelegate/payment-tray-config-prePaid-anonymous-third-party';
import { PaymentTrayConfigStandAloneWalletManagement } from './paymentTrayConfigDelegate/payment-tray-config-stand-alone-wallet-management';
import { PaymentTrayConfigSaveCreditCard } from './paymentTrayConfigDelegate/payment-tray-config-save-credit-card';
import { PaymentErrorSaveCreditCard } from './paymentErrorDelegate/payment-error-save-credit-card';
import { PaymentTrayConfigCardPayment } from './paymentTrayConfigDelegate/payment-tray-config-card-payment';
import { PaymentTaggingCreditCard } from './paymentTaggingDelegate/payment-tagging-credit-card';
import {
  PaymentTrayConfigFinancedDevicesCancellation
} from './paymentTrayConfigDelegate/payment-tray-config-financed-devices-cancellation';
import { PaymentErrorFinancedDevicesCancellation } from './paymentErrorDelegate/payment-error-financed-devices-cancellation';
import { PaymentTaggingFinancedDevicesCancellation } from './paymentTaggingDelegate/payment-tagging-financed-devices-cancellation';
import { PaymentDxlparamPostPaidFinancedDevices } from './paymentDxlParamsDelegate/payment-dxlparam-postPaid-financedDevices';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { PaymentDxlparamPostPaidDebt } from './paymentDxlParamsDelegate/payment-dxlparam-postpaid-debt';
import { PaymentTrayConfigPaymentCommitment } from './paymentTrayConfigDelegate/payment-tray-config-payment-commitment';
import { TariffService } from '../../shared/services/tariff.service';

/**
* this delegate service contains in the builder of the delegate object based
* on the wcs journy key we implment here the factory design pattern to init
* object based on the wcs journy
* every object is implment interface which contain some buissness  is diffrent from journy to
* another
*/
@Injectable()
export class DelegateCreationService {
  /* tray configration  */
  private _paymentTrayConfig: PaymentTrayConfigInterface;
  /* payment error buissness   */
  private _paymentErrorConfig: PaymentErrorInterface;
  /* payment cancel buissness   */
  private _paymentCancelConfig: PaymentCancelInterface;
  /* payment success buissness   */
  private _paymentSuccessConfig: PaymentSuccessInterface;
  /* payment dxl params related to how we handle paramerter send to dxl   */
  private _DXLParamatersConfig: PaymentDxlparamInterface;
  /* payment Tagging Config configration  */
  private _paymentTaggingConfig: PaymentTaggingInterface;
  constructor(private paymentNavService: PaymentNavigationService,
    private translate: TranslateService,
    public billService: BillingService,
    public tagging: TaggingHelperService,
    public storageService: StorageService,
    public paymentWalletService: PaymentWalletService,
    public newTagging: NewTaggingHelperService,
    private router: Router,
    public tariffService: TariffService) {
    // subcribe here for the tray close to clean all objects
    this.paymentNavService.changePaymentCompSubject.subscribe(({ actionNeeded }) => {
      if (actionNeeded === PaymentTrayAction.startPayment) {
        this.clearDelegateObj();
      }
    });
  }

  /**
   * function to clean up the delegate objects after every journey
   */
  clearDelegateObj(): void {
    this._paymentCancelConfig = null;
    this._paymentErrorConfig = null;
    this._paymentSuccessConfig = null;
    this._paymentTrayConfig = null;
    this._DXLParamatersConfig = null;
    this._paymentTaggingConfig = null;
  }

  /**
   * @param id wcs journy id
   * return back tray configration object
   */
  private createTrayConfig(id: PaymentJourny): PaymentTrayConfigInterface {
    switch (id) {
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.postInadvance:
      case PaymentJourny.postcashDeskTotal:
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.postcashDeskPartial:
      case PaymentJourny.suspendedDeptTotal:
        this._paymentTrayConfig = new PaymentTrayConfigPostPaid(this.paymentNavService, this, this.translate, this.paymentWalletService);
        break;
      case PaymentJourny.manageSavedCredietCard:
      case PaymentJourny.smartPaySavedCard:
        this._paymentTrayConfig = new PaymentTrayConfigStandAloneWalletManagement(
          this.paymentNavService, this, this.translate, this.paymentWalletService);
        break;
      case PaymentJourny.postCardPaymentCommercial:
        this._paymentTrayConfig = new PaymentTrayConfigCardPayment(
          this.paymentNavService, this, this.translate, this.paymentWalletService);
        break;
      case PaymentJourny.financedDevicesCancellation:
       this._paymentTrayConfig = new PaymentTrayConfigFinancedDevicesCancellation(
        this.paymentNavService, this, this.translate, this.paymentWalletService);
       break;
      case PaymentJourny.paymentCommitments:
        this._paymentTrayConfig = new PaymentTrayConfigPaymentCommitment(
            this.paymentNavService, this, this.translate, this.paymentWalletService);
      break;
      default:
        this.createPrepaidTrayConfig(id);
    }
    return this._paymentTrayConfig;
  }

  private createPrepaidTrayConfig(id: PaymentJourny): PaymentTrayConfigInterface {
    switch (id) {
      case PaymentJourny.prepaidTopup:
        this._paymentTrayConfig = new PaymentTrayConfigPrePaid(this.paymentNavService, this, this.translate,
          this.paymentWalletService, this.tariffService);
        break;
      case PaymentJourny.prePaidAnonymus:
        this._paymentTrayConfig = new PaymentTrayConfigPrePaidAnonymous(
          this.paymentNavService, this, this.translate, this.paymentWalletService, this.router);
        break;
      case PaymentJourny.paymentAnonymus:
      case PaymentJourny.anonymousPayInAdvance:
      case PaymentJourny.anonymousDebtPartial:
      case PaymentJourny.anonymousDebtTotal:
        this._paymentTrayConfig = new PaymentTrayConfigAnonymousPostPaid(
          this.paymentNavService, this, this.translate, this.paymentWalletService);
        break;
      case PaymentJourny.prePaidAnonymusThirdParty:
        this._paymentTrayConfig = new PaymentTrayConfigPrePaidAnonymousThirdParty(
          this.paymentNavService, this, this.translate, this.paymentWalletService, this.router);
        break;
      case PaymentJourny.saveCreditCard:
        this._paymentTrayConfig = new PaymentTrayConfigSaveCreditCard(
          this.paymentNavService, this, this.translate, this.paymentWalletService);
        break;
      default:
        this._paymentTrayConfig = new PaymentTrayConfigDefault(this.paymentNavService, this, this.translate, this.paymentWalletService);
        break;
    }
    return this._paymentTrayConfig;
  }

  public get paymentTrayConfig(): PaymentTrayConfigInterface {
    return this._paymentTrayConfig ? this._paymentTrayConfig : this.createTrayConfig(this.paymentNavService.WCSJourneyKey);
  }

  /**
   * @param id wcs journy id
   * return  payment error buissness  object
   */
  private createErrorDelegate(id: PaymentJourny): PaymentErrorInterface {
    id = this.getJourneyType(id);
    switch (id) {
      case PaymentJourny.postPaidGeneric:
        this._paymentErrorConfig = new PaymentErrorPostPaid(this.paymentNavService, this.translate, this.router);
        break;
      case PaymentJourny.paymentAnonymus:
      case PaymentJourny.anonymousPayInAdvance:
      case PaymentJourny.anonymousDebtPartial:
      case PaymentJourny.anonymousDebtTotal:
        this._paymentErrorConfig = new PaymentErrorPostPaidAnonymous(this.paymentNavService, this.translate, this.router);
        break;
      case PaymentJourny.manageSavedCredietCard:
      case PaymentJourny.smartPaySavedCard:
        this._paymentErrorConfig = new PaymentErrorStandAloneWalletManagment(this.paymentNavService, this.translate, this.router);
        break;
      case PaymentJourny.financedDevicesCancellation:
        this._paymentErrorConfig = new PaymentErrorFinancedDevicesCancellation(this.paymentNavService, this.translate, this.router);
        break;
      default:
        this.createPrepaidErrorDelegate(id);
    }
    return this._paymentErrorConfig;
  }

  public getJourneyType (id: PaymentJourny): PaymentJourny {
    switch (id) {
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.postInadvance:
      case PaymentJourny.postcashDeskTotal:
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.postcashDeskPartial:
      case PaymentJourny.postCardPaymentCommercial:
      case PaymentJourny.suspendedDeptTotal:
      case PaymentJourny.paymentCommitments:
        id = PaymentJourny.postPaidGeneric;
      break;
      default:
      break;
      }
      return id;
  }
  private createPrepaidErrorDelegate(id: PaymentJourny): PaymentErrorInterface {
    switch (id) {
      case PaymentJourny.prepaidTopup:
        this._paymentErrorConfig = new PaymentErrorPrePaidLogin(this.paymentNavService, this.translate, this.router);
        break;
      case PaymentJourny.prePaidAnonymus:
        this._paymentErrorConfig = new PaymentErrorPrePaidAnonymous(this.paymentNavService, this.translate, this.router);
        break;
      case PaymentJourny.prePaidAnonymusThirdParty:
        this._paymentErrorConfig = new PaymentErrorPrePaidAnonymousThirdparty(this.paymentNavService, this.translate, this.router);
        break;
      case PaymentJourny.saveCreditCard:
        this._paymentErrorConfig = new PaymentErrorSaveCreditCard(this.paymentNavService, this.translate, this.router);
        break;
      default:
        this._paymentErrorConfig = new PaymentErrorDefault(this.paymentNavService, this.translate, this.router);
        break;
    }
    return this._paymentErrorConfig;
  }
  public get paymentErrorConfig(): PaymentErrorInterface {
    return this._paymentErrorConfig ? this._paymentErrorConfig : this.createErrorDelegate(this.paymentNavService.WCSJourneyKey);
  }

  /**
   * @param id wcs journy id
   * return  payment cancel buissness  object
   */
  private createCancelDelegate(id: PaymentJourny): PaymentCancelInterface {
    this._paymentCancelConfig = new PaymentCancelDefault(this.paymentNavService, this.translate);
    return this._paymentCancelConfig;
  }
  public get paymentCancelConfig(): PaymentCancelInterface {
    return this._paymentCancelConfig ? this._paymentCancelConfig : this.createCancelDelegate(this.paymentNavService.WCSJourneyKey);
  }

  /**
   * @param id wcs journy id
   * return  payment success buissness  object
   */
  private createSuccesDelegate(id: PaymentJourny): PaymentSuccessInterface {
    switch (id) {
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.postInadvance:
      case PaymentJourny.postcashDeskTotal:
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.postcashDeskPartial:
      case PaymentJourny.financedDevicesCancellation:
      case PaymentJourny.paymentAnonymus:
      case PaymentJourny.anonymousPayInAdvance:
      case PaymentJourny.anonymousDebtPartial:
      case PaymentJourny.anonymousDebtTotal:
      case PaymentJourny.postCardPaymentCommercial:
      case PaymentJourny.suspendedDeptTotal:
      case PaymentJourny.paymentCommitments:
        this._paymentSuccessConfig = new PaymentSuccessPostPaid(this.paymentNavService, this.translate, this.router);
        break;
      default:
        this.createPrepaidSuccessDelegate(id);
    }
    return this._paymentSuccessConfig;
  }

  private createPrepaidSuccessDelegate(id: PaymentJourny): PaymentSuccessInterface {
    switch (id) {
      case PaymentJourny.prepaidTopup:
      case PaymentJourny.prePaidAnonymus:
      case PaymentJourny.prePaidAnonymusThirdParty:
        this._paymentSuccessConfig = new PaymentSuccessPrePaid(this.paymentNavService, this.translate, this.router, this.tariffService);
        break;
      case PaymentJourny.saveCreditCard:
        this._paymentSuccessConfig = new PaymentSuccessSaveCreditCard(this.paymentNavService, this.translate, this.router);
        break;
      default:
        this._paymentSuccessConfig = new PaymentSuccessDefault(this.paymentNavService, this.translate, this.router);
        break;
    }
    return this._paymentSuccessConfig;
  }

  public get paymentSuccessConfig(): PaymentSuccessInterface {
    return this._paymentSuccessConfig ? this._paymentSuccessConfig : this.createSuccesDelegate(this.paymentNavService.WCSJourneyKey);
  }

  /**
   *  @param id wcs journy id
   * return  dxl params config  object
  */
  private createDXLParamHandler(id: PaymentJourny): PaymentDxlparamInterface {
    id = this.getDeptJourneyType(id);
    switch (id) {
      case PaymentJourny.postInadvance:
      case PaymentJourny.postcashDeskTotal:
      case PaymentJourny.postcashDeskPartial:
      case PaymentJourny.paymentAnonymus:
      case PaymentJourny.anonymousPayInAdvance:
      case PaymentJourny.saveCreditCard:
      case PaymentJourny.postCardPaymentCommercial:
        this._DXLParamatersConfig = new PaymentDxlparamPrePaidPostPaid(this.paymentNavService);
        break;
      case PaymentJourny.debtJourneysGeneric:
        this._DXLParamatersConfig = new PaymentDxlparamPostPaidDebt(this.paymentNavService);
        break;
      case PaymentJourny.financedDevicesCancellation:
        this._DXLParamatersConfig = new PaymentDxlparamPostPaidFinancedDevices(this.paymentNavService);
        break;
      default:
        this.createPrepaidDXLParamHandler(id);
    }
    return this._DXLParamatersConfig
  }

  getDeptJourneyType (id: PaymentJourny): PaymentJourny {
    switch (id) {
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.anonymousDebtPartial:
      case PaymentJourny.anonymousDebtTotal:
      case PaymentJourny.suspendedDeptTotal:
      case PaymentJourny.paymentCommitments:
        id = PaymentJourny.debtJourneysGeneric;
      break;
      default:
      break;
    }
    return id;
  }

  private createPrepaidDXLParamHandler(id: PaymentJourny): PaymentDxlparamInterface {
    switch (id) {
      case PaymentJourny.prepaidTopup:
      case PaymentJourny.prePaidAnonymus:
      case PaymentJourny.prePaidAnonymusThirdParty:
        this._DXLParamatersConfig = new PaymentDxlparamPrePaidPostPaid(this.paymentNavService);
        break;
      default:
        this._DXLParamatersConfig = new PaymentDxlparamDefault(this.paymentNavService);
        break;
    }
    return this._DXLParamatersConfig
  }

  public get DXLParamatersConfig(): PaymentDxlparamInterface {
    return this._DXLParamatersConfig ? this._DXLParamatersConfig : this.createDXLParamHandler(this.paymentNavService.WCSJourneyKey)
  }
  /**
   *  @param id wcs journy id
   * return  dxl params config  object
  */
  private createpaymentTaggingConfig(id: PaymentJourny): PaymentTaggingInterface {
    switch (id) {
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.paymentAnonymus:
      case PaymentJourny.anonymousDebtPartial:
      case PaymentJourny.anonymousDebtTotal:
      case PaymentJourny.suspendedDeptTotal:
      case PaymentJourny.paymentCommitments:
        /** Use PaymentTaggingPostPaid as a default journey for dept payment */
        this._paymentTaggingConfig = new PaymentTaggingPostPaid
          (this.billService, this.paymentNavService, this.tagging);
        break;
      case PaymentJourny.postcashDeskPartial:
      case PaymentJourny.postcashDeskTotal:
        this._paymentTaggingConfig = new PaymentTaggingPostPaidCashDesk
          (this.billService, this.paymentNavService, this.tagging);
        break;
      case PaymentJourny.postInadvance:
      case PaymentJourny.anonymousPayInAdvance:
        this._paymentTaggingConfig = new PaymentTaggingPostPaidInAdvance
          (this.billService, this.paymentNavService, this.tagging);
        break;
      case PaymentJourny.postCardPaymentCommercial:
        this._paymentTaggingConfig = new PaymentTaggingCreditCard(this.tagging, this.paymentNavService);
        break;
      default:
        this.createPrepaidPaymentTaggingConfig(id);
    }
    return this._paymentTaggingConfig
  }

  private createPrepaidPaymentTaggingConfig(id: PaymentJourny): PaymentTaggingInterface {
    switch (id) {
      case PaymentJourny.prepaidTopup:
        this._paymentTaggingConfig = new PaymentTaggingPrePaid(this.tagging, this.storageService);
        break;
      case PaymentJourny.manageSavedCredietCard:
        this._paymentTaggingConfig = new PaymentTaggingStandAloneWalletManagment(this.tagging, this.newTagging);
        break;
      case PaymentJourny.smartPaySavedCard:
        this._paymentTaggingConfig = new PaymentTaggingStandAloneWalletManagment(this.tagging, this.newTagging);
        break;
      case PaymentJourny.prePaidAnonymusThirdParty:
      case PaymentJourny.prePaidAnonymus:
        this._paymentTaggingConfig = new PaymentTaggingPrePaidAnonymous(this.tagging, this.storageService);
        break;
      case PaymentJourny.saveCreditCard:
        this._paymentTaggingConfig = new PaymentTaggingDefault(this.tagging);
        break;
      case PaymentJourny.financedDevicesCancellation:
        this._paymentTaggingConfig = new PaymentTaggingFinancedDevicesCancellation(this.billService,
          this.paymentNavService, this.tagging, this.newTagging);
        break;
    }
    return this._paymentTaggingConfig
  }

  public get paymentTaggingConfig(): PaymentTaggingInterface {
    return this._paymentTaggingConfig ? this._paymentTaggingConfig : this.createpaymentTaggingConfig(this.paymentNavService.WCSJourneyKey)
  }
}

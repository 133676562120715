import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BillingData } from '../../billing/billing-data';
import { IDynamicTray } from '../../models/dynamic-tray.model';
import { PaymentJourneyParams, PaymentTaggingJourney, PaymentTaggingObject, PaymentTaggingPageName } from '../../models/payment';
import { PaymentCardModel } from '../../models/payment-card.model';
import { CrossVariables } from '../../models/tagging.model';
import { PaymentNavigationService } from '../../payment/services/payment-navigation.service';
import { TopUpService } from '../../top-up/top-up.service';
import { tagging } from '../../../config/tagging-config'; import { ANONYMOUS, defines } from '../constants/defines';
import { DynamicTrayService } from './dynamic-tray.service';
import { PaymentWalletService } from './payment-wallet.service';
import { SiteHandlingService } from './site-handling.service';
import { SpMva10OverlayService } from './sp-mva10-overlay.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { NexusService } from './nexus.service';
import { PaymentJourneyIds } from '../enums/payment-journey-ids.enum';
import { PaymentLanguages } from '../enums/payment-languages.enum';
import { PaymentJourny } from '../enums/payment-journy.enum';
import { PaymentJourneyUserType } from '../enums/payment-journey-user-type.enum';
import { PaymentJourneyName } from '../enums/payment-journey-name.enum';
import { PaymentJourneySubCategory } from '../enums/payment-journey-sub-category.enum';
import { PaymentJourneyType } from '../enums/payment-journey-type.enum';
import { PaymentJourneyEvents } from '../enums/payment-journey-events.enum';
import { PaymentJourneyDetail } from '../enums/payment-journey-detail.enum';
import { PaymentSystemIds } from '../enums/payment-system-ids.enum';


@Injectable()
export class CommercialPaymentService {
  public selectedSiteId: string;
  dynamicTrayBackBtnClicked: Subject<any> = new Subject<any>();
  forwardTransition: boolean = false;
  backwardTransition: boolean = false;
  fadeOutTransition: boolean = false;
  dynamicTrayData: IDynamicTray;
  // boolean to show leaving payment overlay in start screens.
  showLeavingOverlay: boolean = false;
  // subject to fire when click on x and continuar button in leaving overlay in start screen.
  resetFormSubject: Subject<any> = new Subject<any>();
  showPayPartial: boolean;

  constructor(public billingData: BillingData,
    public translate: TranslateService,
    private subscriptionService: SubscriptionService,
    public tagging: TaggingHelperService,
    private storage: StorageService,
    private topupService: TopUpService,
    private dynamicTrayService: DynamicTrayService,
    public trayService: SpMva10OverlayService,
    private paymentWalletService: PaymentWalletService,
    public paymentNavigationService: PaymentNavigationService,
    private siteHandlingService: SiteHandlingService,
    public nexusService: NexusService
  ) {
  }
  /**
    * Execute Animation Fadeout , Forward and Backward when dynamic tray backbutton clicked.
    */
  backButtonTransition(): void {
    this.fadeOutTransition = true;
    setTimeout(() => {
      this.dynamicTrayBackBtnClicked.next();
      this.dynamicTrayData.basicTrayData.showBackButton = false;
      this.dynamicTrayService.trayData.basicTrayData.showBackButton = false;
      this.forwardTransition = false;
      this.showPayPartial = false;
      this.fadeOutTransition = false;
      this.backwardTransition = true;
    }, 1000);
  }
  /**
   *the starting point for opening the dynamic tray
   * for the dept payment journey
   * we set prerequisite data for the tray configuration
   * and get subject to start it
   * backWordTranstion is used when we are navigation back to dept Payment
   * tagging for dept payment start screen
   * @memberof CommercialPaymentService
   */
  /**
  * Show leaving overlay when click on x in start screen with animation.
  * Send tagging for leaving overlay.
  * Bind x in leaving overlay to clickXButtonInCancelationOverlay function.
  */
  closeDynamicTray(): void {
    this.sendPostpaidPageTagging(tagging.cancelPaymentInStartScreenPageName, tagging.cancelPaymentJourney);
    this.fadeOutTransition = true;
    this.backwardTransition = false;
    setTimeout(() => {
      this.fadeOutTransition = false;
      this.forwardTransition = true;
      this.showLeavingOverlay = true;
      this.dynamicTrayData.isGradientBackground = true;
      this.dynamicTrayData.basicTrayData.showBackButton = false;
      this.dynamicTrayData.closeButtonAction = this.clickXButtonInCancelationOverlay.bind(this);
    }, 1000);
  }
  /**
   * Executed when click on x in leaving overlay.
   * Return to start screen again without animation.
   * Make tagging of start screen again.
   */
  clickXButtonInCancelationOverlay(): void {
    this.showLeavingOverlay = false;
    this.dynamicTrayData.isGradientBackground = false;
    this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
    this.forwardTransition = false;
    this.resetFormSubject.next();
    if (this.showPayPartial) {
      this.dynamicTrayData.basicTrayData.showBackButton = true;
    }
  }
  /**
   * Tagging fun for leaving overlay and cancel button in leaving overlay.
   */
  sendPostpaidPageTagging(pageName: any, journey: any, has_journey_card_selected: boolean = true): void {
    const paymentPageName: any = Object.assign({}, pageName);
    const paymentJourney: any = Object.assign({}, journey);

    paymentJourney.journey_category = CrossVariables.client_typology;
    if (has_journey_card_selected) {
      paymentJourney.journey_card_selected = this.topupService.selectedCreditCard ?
        tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
    }
    this.tagging.view(paymentPageName, paymentJourney);
  }
  // tagging for in advance payment start screen.
  startInAdvanceTagging(): void {
    tagging.payInAdvancePaymentStartJourney.journey_category = CrossVariables.client_typology;
    this.tagging.view(tagging.payInAdvancePaymentStartPageName, tagging.payInAdvancePaymentStartJourney);
  }
  // make tagging for in cash desk payment start screen.
  startCashDeskTagging(): void {
    if (this.showPayPartial) {
      tagging.cashDeskPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
      this.tagging.view(tagging.cashDeskPaymentStartPartialPageName, tagging.cashDeskPaymentStartPartialJourney);
    } else {
      tagging.cashDeskPaymentStartJourney.journey_category = CrossVariables.client_typology;
      this.tagging.view(tagging.cashDeskPaymentStartPageName, tagging.cashDeskPaymentStartJourney);
    }
  }
  /**
   * Executed when click on continuar button in leaving overlay.
   * Return to start screen again with animation.
   * Make tagging of previous start screen again.
   */
  continuePayment(): void {
    this.fadeOutTransition = true;
    setTimeout(() => {
      this.fadeOutTransition = false;
      this.forwardTransition = false;
      this.backwardTransition = true;
      this.showLeavingOverlay = false;
      this.dynamicTrayData.isGradientBackground = false;
      this.resetFormSubject.next();
      if (this.showPayPartial) {
        this.dynamicTrayData.basicTrayData.showBackButton = true;
      }
      this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
      this.dynamicTrayService.closeTraySubject.subscribe(() => {
        this.closeDynamicTray.bind(this);
      });
    }, 1000);
  }
  /**
   * Executed when click on cancel button in leaving overlay.
   * Close tray and return to mis pagos page.
   * Make tagging for cancel button.
   */
  cancelPayment(): void {
    this.sendPostpaidPageTagging(tagging.cancelPaymentBtnClickedInStartScreenPageName,
      tagging.cancelPaymentBtnClickedJourney);
    this.topupService.returnFromWalletScreen = false;
    this.dynamicTrayService.closeTraySubject.next();
    setTimeout(() => {
      this.forwardTransition = false;
      this.showLeavingOverlay = false;
      this.dynamicTrayData.isGradientBackground = false;
    }, 1000);
  }
  roundAmount(amount: string): string {
    return isNaN(parseFloat(amount)) ? '' : parseFloat(amount).toFixed(2).toString().replace('.', ',')
  }
  /*
   * Open iframe and set payment joureny params object needed to open it
   * @param amount of the money user need to pay
   * @param WCSJournyKey of the current journey
   * @param concepto wcs for concepto value in iframe screen
   */
  openPaymentModule(amount: string, WCSJournyKey: PaymentJourny, concepto: string): void {
    const paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
    // payer data
    paymentJourneyParams.payer.documentId = this.storage.userProfile.document.id;
    paymentJourneyParams.payer.siteId = this.selectedSiteId ? this.selectedSiteId
      : this.subscriptionService.customerData.customerAccountsId;
    paymentJourneyParams.payer.serviceId = this.siteHandlingService.isSiteSuspended() ? ANONYMOUS :
      this.subscriptionService.customerData.currentService.id;
    paymentJourneyParams.payer.documentType = this.storage.userProfile.document.type
    // buyer data
    paymentJourneyParams.buyer.documentId = this.storage.userProfile.document.id;
    paymentJourneyParams.buyer.siteId = this.selectedSiteId ? this.selectedSiteId
      : this.subscriptionService.customerData.customerAccountsId;
    paymentJourneyParams.buyer.serviceId = this.siteHandlingService.isSiteSuspended() ? ANONYMOUS :
      this.subscriptionService.customerData.currentService.id;

    paymentJourneyParams.amount = amount.replace('.', '');
    paymentJourneyParams.WCSJournyKey = WCSJournyKey;
    paymentJourneyParams.isAnonymous = this.siteHandlingService.isSiteSuspended() ? true : false;
    // params related to iframe screen
    paymentJourneyParams.concepto = concepto;
    paymentJourneyParams.journeyId = PaymentJourneyIds.creditCardWithCvv;
    paymentJourneyParams.systemID = PaymentSystemIds.eshop;
    paymentJourneyParams.systemReferenceId = this.nexusService.systemReferenceID;
    // credit card data
    const selectedCard: PaymentCardModel = this.paymentWalletService.selectedCard;
    if (selectedCard) {
      paymentJourneyParams.token = selectedCard.token;
      paymentJourneyParams.expiryDate = selectedCard.expiryDate;
      paymentJourneyParams.cardMask = selectedCard.cardMask;
      paymentJourneyParams.journeyId = PaymentJourneyIds.creditCard;
    }
    // for handling journey

    paymentJourneyParams.isHandleWaiting = true;
    paymentJourneyParams.language = PaymentLanguages.es;
    paymentJourneyParams.paymentTaggingConfig = this.getPaymentModuleTagging(amount);
    paymentJourneyParams.placeHolders.okScreeen.subtitle = this.getSuccessScreenPlaceHolders(amount);
    this.paymentNavigationService.WCSJourneyKey = WCSJournyKey;
    this.paymentNavigationService.openPaymentThirdParty(paymentJourneyParams);
  }
  /**
   *
   * @param amount of the money user need to pay
   * @param WCSJournyKey of the current journey
   */
  getSuccessScreenPlaceHolders(amount: string): string[] {
    const placeHolders: any[] = [];
    placeHolders.push(this.roundAmount(amount + defines.euro));
    return placeHolders;
  }
  /**
  * Return tagging object (pagename, journey) for postpaid tagging
  * @param WCSJournyKey of the current journey
  * @param amount {string} amount to pay
  */
  getPaymentModuleTagging(amount: string): PaymentTaggingObject {
    const paymentTaggingObject: PaymentTaggingObject = new PaymentTaggingObject();
    paymentTaggingObject.pageName = this.getPageNameForPostPaidTagging(tagging.payCreditCardCommercialPageName);
    paymentTaggingObject.pageName_ok = this.getPageNameForPostPaidTagging(tagging.paymentDoneCommercialPageName);
    paymentTaggingObject.pageName_ko = this.getPageNameForPostPaidTagging(tagging.paymentKOCommercialPageName);
    paymentTaggingObject.journey = this.getJourneyForPostPaidTagging(amount);
    return paymentTaggingObject;
  }
  /**
  * Return pagename object for postpaid tagging
  * @param pageNameObject the pagename object
  */
  getPageNameForPostPaidTagging(pageNameObject: PaymentTaggingPageName): PaymentTaggingPageName {
    const pageName: PaymentTaggingPageName = Object.assign({}, pageNameObject);
    pageName.page_name = pageName.page_name.replace('<payment_page_name>', PaymentJourneyUserType.checkout);
    pageName.page_subcategory_level_2 = pageName.page_subcategory_level_2.replace('<payment_page_name>',
      PaymentJourneyUserType.checkout);
    return pageName;
  }
  /**
   * Return journey object for postpaid tagging
   * @param WCSJournyKey of the current journey
   * @param amount {string} amount to pay
   */
  getJourneyForPostPaidTagging(amount: string): PaymentTaggingJourney {
    let amountToPay: number = +this.roundAmount(amount).replace(',', '.') * 100;
    amountToPay = Math.round(amountToPay);
    const journey: PaymentTaggingJourney = new PaymentTaggingJourney();
    journey.journey_name = PaymentJourneyName.checkout;
    journey.journey_category = CrossVariables.client_typology;
    journey.journey_subcategory = PaymentJourneySubCategory.postpaid;
    journey.journey_type = PaymentJourneyType.transaccional;
    journey.event_success = PaymentJourneyEvents.ok.replace('<amount>', amountToPay.toString());
    journey.event_error = PaymentJourneyEvents.koEvent3;
    journey.journey_detail = PaymentJourneyDetail.pasarela;
    journey.journey_step = PaymentJourneyName.checkout + ':' + PaymentJourneyDetail.pasarela;
    journey.state_flow = tagging.genericCheckout.data.state_flow;
    journey['&&products'] = this.nexusService.productTagg;
    return journey;
  }

}

<div class="smartpay-landing" id="smartpayLanding" *ngIf="loaded">
  <div class="smartpay-landing__img-container">
    <p class="smartpay-landing__header" id="smartPayLandingHeaderText">{{smartPayLandingHeaderText}}</p>
    <span class="smartpay-landing__close-icon-container">
      <svg id="smartPayLandingCloseIcon" class="smartpay-landing__close-icon" (click)="goBack()">
        <title>Close</title>
        <use xlink:href="#icon-close"/>
      </svg>
    </span>
    <div  class="smartpay-landing__coming-soon-label-main-container" *ngIf="smartPayComingSoon">
    <div class="smartpay-landing__coming-soon-label-container" >
      <label class="smartpay-landing__coming-soon-label-text"  id="smartPayLandingComingSoonLabel">
        {{"v10.productsServices.smartPay.PyS.benefits.comingSoon.hintLebel" | translate}}
      </label>
    </div>
  </div>
    <img
      [src]="smartPayLandingImage "
      alt=""
      class="smartpay-landing__header-image"
      id="smartPayLandingHeaderImage"
    />
  </div>

  <div class="smartpay-landing__content">
    <mva10-c-card class="smartpay-landing__card"
    [ngStyle]="isOverflowing && !isMobileView? {'padding-bottom': '32px'} : {}">
      <p class="smartpay-landing__title"
      id="smartPayLandingHeaderText">
        {{ smartPayLandingTitle }}
      </p>
      <div class="smartpay-landing__features-container">
        <ul class="smartpay-landing__features-list">
          <li class="smartpay-landing__feature">
            <div class="smartpay-landing__icon-container">
              <svg class="smartpay-landing__icon">
                <title>Payment</title>
                <use xlink:href="#icon-auto-top-up" />
              </svg>
            </div>
            <p class="smartpay-landing__feature-text" id="smartPayLandingPaymentText">
              {{ smartPayLandingPaymentText }}
            </p>
          </li>
          <li class="smartpay-landing__feature">
            <div class="smartpay-landing__icon-container">              
              <svg-icon class="smartpay-landing__icon" [src]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-cost-control.svg'| imgFullPath"></svg-icon>
            </div>
            <p class="smartpay-landing__feature-text" id="smartPayLandingSavingText">
              {{ smartPayLandingSavingText }}
            </p>
          </li>
          <li class="smartpay-landing__feature">
            <div class="smartpay-landing__icon-container">
              <svg-icon class="smartpay-landing__icon" [src]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/icons-svg/icon-all-rewards.svg'| imgFullPath "></svg-icon>
            </div>
            <p class="smartpay-landing__feature-text" id="smartPayLandingAllRewardsText">
              {{ smartPayLandingAllRewardsText }}
            </p>
          </li>
          <li class="smartpay-landing__feature" *ngIf="smartPayMoreInfo">
            <div class="smartpay-landing__icon-container">
              <svg class="smartpay-landing__icon">
                <title>add-ons-boosts</title>
                <use xlink:href="#icon-add-ons-boosts" />
              </svg>
            </div>
            <p class="smartpay-landing__feature-text" id="smartPayLandingExtrasText">
              {{ smartPayLandingExtrasText }}
            </p>
          </li>
        </ul>
      </div>
      <ng-container *ngIf="!isMobileView && !isOverflowing">
          <div class="smartpay-landing__button-card relative">
            <div [ngClass] ="{'comingSoonContainer': smartPayComingSoon}">
              <div *ngIf="smartPayComingSoon" class="smartpay-landing__notification-icon-container">
                <svg id="smartPayLandingNotificationIcon" class="smartpay-landing__notification-icon" >
                  <title>Notification Icon</title>
                  <use xlink:href="#icon-notification" />
                </svg>
              </div>
              <p id="smartPayLandingDescription" class="smartpay-landing__description"  [ngClass] ="{'comingSoonHintInFooter': smartPayComingSoon}" *ngIf="smartPayLandingDescription">{{ smartPayLandingDescription }}</p>
            </div>
            <mva10-c-button id="smartPayLandingButton" class="smartpay-landing__button" (click)="smartPayButtonOnClick();" *ngIf="smartPayLandingButton">
              {{ smartPayLandingButton }}
            </mva10-c-button>
          </div>
        </ng-container>
        <ng-container *ngIf="isMobileView">
          <div class="smartpay-landing__dummy"
          [ngStyle] = "{ 'height': dummyHeight + 'px' }">
          </div>
        </ng-container>
    </mva10-c-card>
    <ng-container *ngIf="!isMobileView && isOverflowing">
      <div class="smartpay-landing__dummy smartpay-landing__dummy-out"
      [ngStyle] = "{ 'height': dummyHeight + 'px' }">
      </div>
    </ng-container>
    <ng-container *ngIf="isMobileView || isOverflowing"
    class="smartpay-landing__button-card fixed"
    >
      <mva10-c-card
        class="smartpay-landing__button-card fixed"
        id="smartPayLandingFloatingCard"
        [ngStyle]="!isOverflowing ? {'box-shadow': 'none'} : {'box-shadow': '0 -5px 10px 0 rgba(0, 0, 0, 0.15)'}"
      >
      <div [ngClass] ="{'comingSoonContainer': smartPayComingSoon}">
        <div *ngIf="smartPayComingSoon" class="smartpay-landing__notification-icon-container">
          <svg id="smartPayLandingNotificationIcon" class="smartpay-landing__notification-icon" >
            <title>Notification Icon</title>
            <use xlink:href="#icon-notification" />
          </svg>
        </div>
        <p id="smartPayLandingDescription" class="smartpay-landing__description"  [ngClass] ="{'comingSoonHintInFooter': smartPayComingSoon}" *ngIf="smartPayLandingDescription">{{ smartPayLandingDescription }}</p>
      </div>
        <mva10-c-button id="smartPayLandingButton" class="smartpay-landing__button" (click)="smartPayButtonOnClick();"  *ngIf="smartPayLandingButton">
          {{ smartPayLandingButton }}
        </mva10-c-button>
      </mva10-c-card>
    </ng-container>
  </div>
</div>


import { Component, OnInit, Input } from '@angular/core';
import { ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ComponentFactory } from '@angular/core';
import { SocialMediaLink } from '../../constants/defines';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'sp-rrss',
  templateUrl: './rrss.component.html',
  styleUrls: ['./rrss.component.scss']
})
export class RrssComponent implements OnInit {

  @Input() customIcon: boolean;
  @Input() twitterOrder: number;
  @Input() facebookOrder: number;
  @Input() mailOrder: number;
  @Input() clipboardOrder: number;
  @Input() contentToShare: string;
  @Input() linkToShare: string;
  @Input() copyLinkToClipboard: boolean = false;

 @ViewChild('tooltip', { read: ViewContainerRef, static: true }) tooltipRef: ViewContainerRef;

  public rrss: SocialMedia[];

  constructor(
    private resolver: ComponentFactoryResolver,
    private appService: AppService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (!this.contentToShare) {
      this.contentToShare = window.document.body.innerText;
    }
    if (!this.linkToShare) {
      this.linkToShare = window.location.href;
    }
    this.loadSocialMedia();
  }

  private loadSocialMedia() {
    this.rrss = [];

    if (this.twitterOrder) {
      this.createMedia(MediaNames.twitter, this.twitterOrder, SocialMediaLink.twitter
        .replace('{text}', this.contentToShare)
        .replace('{url}', encodeURIComponent(this.linkToShare))
      );
    }
    if (this.facebookOrder) {
      this.createMedia(
        MediaNames.facebook,
        this.facebookOrder,
        SocialMediaLink.facebook.replace('{url}', encodeURIComponent(this.linkToShare))
      );
    }
    if (this.mailOrder) {
      this.createMedia(MediaNames.mail, this.mailOrder, SocialMediaLink.mail
        .replace('{subject}', this.contentToShare).replace('{body}', encodeURIComponent(this.linkToShare))
      );
    }
    if (this.clipboardOrder) {
      this.createMedia(MediaNames.clipboard, this.clipboardOrder, null, this.copyToClipboard.bind(this));
    }

    this.rrss.sort((current, next) => { return current.order - next.order });
  }

  private createMedia(socialname: MediaNames, socialOrder: number, socialLink?: string, socialAction?: any): void {
    const media: SocialMedia = {
      name: socialname,
      img: this.appService.getImgFullPath(this.translate.instant(`v10.delight.shareImages.${socialname}`)),
      order: socialOrder,
      link: socialLink,
      action: socialAction
    }
    this.rrss.push(media);
  }

  private copyToClipboard(): void {
    const el = document.createElement('textarea');
    el.value = this.copyLinkToClipboard ? this.linkToShare : this.contentToShare;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.createClipboardUI();
  }

  private createClipboardUI(): void {
    this.tooltipRef.clear();
    const factory: ComponentFactory<TooltipComponent> = this.resolver.resolveComponentFactory(TooltipComponent);
    const componentRef: ComponentRef<TooltipComponent> = this.tooltipRef.createComponent(factory);
    this.translate.get('v10.dashboard.promoXmas.sharePromotion.copiedMessage').subscribe(text => componentRef.instance.text = text);
    setTimeout(() => componentRef.destroy(), 2000);
  }
}

export interface SocialMedia {
  name: MediaNames;
  img: string;
  order: number;
  link?: string;
  action?: Function;
}

export enum MediaNames {
  twitter = 'twitter',
  facebook = 'facebook',
  mail = 'mail',
  clipboard = 'clipboard',
}

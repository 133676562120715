import { Injectable } from '@angular/core';
import { TryAndBuy } from '../shared/enums/try-and-buy.enum';
import { TryAndBuyModel } from '../models/tryAndBuy.model';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { tagging } from '../../config/tagging-config';
import { TaggingViewModel, TaggingClickDataModel } from '../models/tagging.model';
import { CrossVariables } from '../models/tagging.model';

@Injectable()

export class TryAndBuySetTaggingService {
  isForm: boolean = false;
  activeButton: boolean = false;
  activeConditions: boolean = false;
  showError: boolean = false;
  public itmName: string;
  public typeReco: string;
  public step: number = 0;
  public isNewForm: boolean = false;
  public isResume: boolean = false;
constructor(
  private tagging: TaggingHelperService
  ) { }


notItmName (trackData: TaggingViewModel): TaggingViewModel {
  this.itmName = tagging.tryAndBuy.newExtraData.listadoServicios;
  trackData.page_name = tagging.tryAndBuy.newExtraData.page_name_list;
  trackData.journey_step = `${trackData.journey_name}:${tagging.tryAndBuy.newExtraData.inicial}`;
  return trackData;
}

isItmName (trackData: TaggingViewModel): TaggingViewModel {

  trackData.page_subcategory_level_2 = this.itmName.toLowerCase();
  trackData.navigation_level_3 = this.itmName.toLowerCase();
  let typeForm: string;
  if (this.isNewForm) {
    typeForm = tagging.tryAndBuy.newExtraData.formAcceso;
    this.setExtraTagging(trackData);
  }
  else if (this.isResume) {
    typeForm = tagging.tryAndBuy.newExtraData.formResumen;
    this.setExtraTagging(trackData);
  }
  else {
    trackData.page_name = trackData.page_name.replace('{product}', this.itmName);
    typeForm = tagging.tryAndBuy.newExtraData.detail;
  }

  trackData.journey_detail = `${this.itmName}:${typeForm}`;
  trackData.journey_step = `${trackData.journey_name}:${this.itmName}:${typeForm}`;

  return trackData;
}

setTaggingOP( typeButton?: string, txtButton?: string ): void {
  const trackData: TaggingViewModel = Object.assign( {}, tagging.oneProfesional.newView);
  let newTrackData: TaggingViewModel;

    newTrackData = trackData;
    newTrackData.page_name = tagging.oneProfesional.newView.page_name.replace('{product}',
    tagging.oneProfesional.newExtraData.listadoServicios.toLowerCase());
    newTrackData.page_typology = tagging.oneProfesional.newExtraData.principal;
    newTrackData.navigation_level_3 = tagging.oneProfesional.newExtraData.listadoServicios;
    newTrackData.page_screen = tagging.oneProfesional.newExtraData.listadoServiciosOP;
    newTrackData.page_detail = tagging.oneProfesional.newExtraData.listadoServiciosOP;


  txtButton ? this.setClickOP(newTrackData, typeButton, txtButton) : this.tagging.view(null, newTrackData);

  if (this.step <= 0) {
    newTrackData.journey_status = tagging.tryAndBuy.newExtraData.start;
    newTrackData.journey_detail = tagging.tryAndBuy.newExtraData.inicial;
  }
}

setClickOP(trackData: TaggingViewModel, typeButton: string, txtButton: string): TaggingViewModel {
  const newTrack: TaggingViewModel = trackData;
  return newTrack;

}



setTagging(typeButton?: string, txtButton?: string, isOP?: boolean ): void {
  let trackData: TaggingViewModel = Object.assign( {}, tagging.tryAndBuy.newView);
  isOP ?  trackData = Object.assign( {}, tagging.oneProfesional.newView) :  trackData = Object.assign( {}, tagging.tryAndBuy.newView);
  this.tagging.fillJourneyCategoryAndSubcategory(trackData);
  trackData.event_name = '';
  let newTrackData: TaggingViewModel;
  if ( !isOP ) {
    !this.itmName ? newTrackData = this.notItmName(trackData) : newTrackData = this.isItmName(trackData);
    switch (this.typeReco) {
      case (TryAndBuy.confirmable):
      case (TryAndBuy.pruebaActiva):
        newTrackData.journey_process = tagging.tryAndBuy.newExtraData.pruebaActiva;
        break;
      case (TryAndBuy.contratable):
      case (TryAndBuy.estadoInicial):
        newTrackData.journey_process = tagging.tryAndBuy.newExtraData.inicial;
        break;
      case ( TryAndBuy.pruebaTerminada ):
          newTrackData.journey_process = tagging.tryAndBuy.newExtraData.pruebaFinalizada;
        break;
      default:
        newTrackData.journey_process = tagging.tryAndBuy.newExtraData.comprado;
    }
  }

  else {
    newTrackData = trackData;
    newTrackData.page_name = tagging.oneProfesional.newView.page_name.replace('{product}',
    tagging.oneProfesional.newExtraData.listadoServicios.toLowerCase());
    newTrackData.page_typology = tagging.oneProfesional.newExtraData.principal;
    newTrackData.navigation_level_3 = tagging.oneProfesional.newExtraData.listadoServicios;
    newTrackData.page_screen = tagging.oneProfesional.newExtraData.listadoServiciosOP;
    newTrackData.page_detail = tagging.oneProfesional.newExtraData.listadoServiciosOP;
  }

  newTrackData.journey_category = CrossVariables.client_typology;
  txtButton ? this.setClick(newTrackData, typeButton, txtButton) : this.tagging.view(null, newTrackData);

  if (this.step <= 0) {
    newTrackData.journey_status = tagging.tryAndBuy.newExtraData.start;
    newTrackData.journey_detail = tagging.tryAndBuy.newExtraData.inicial;
  }
}

setBackdropTagging(typeReco, actTabTitleDesc) {
  const trackData: TaggingViewModel = Object.assign( {}, tagging.noPermissions);
  switch (typeReco) {
    case (TryAndBuy.confirmable):
    case (TryAndBuy.pruebaActiva):
      trackData.journey_type_plan = tagging.tryAndBuy.newExtraData.pruebaActiva;
      break;
    case (TryAndBuy.contratable):
    case (TryAndBuy.estadoInicial):
      trackData.journey_type_plan = tagging.tryAndBuy.newExtraData.inicial;
      break;
    case ( TryAndBuy.pruebaTerminada ):
      trackData.journey_type_plan = tagging.tryAndBuy.newExtraData.pruebaFinalizada;
      break;
    default:
      trackData.journey_type_plan = tagging.tryAndBuy.newExtraData.comprado;
  }
  trackData.page_name = trackData.page_name.replace('{s}', actTabTitleDesc);
  this.tagging.view(null, trackData);
}

setExtraTagging(trackData: TaggingViewModel): void {
  let formPrueba: string;

  if ( this.typeReco === TryAndBuy.confirmable || this.typeReco === TryAndBuy.pruebaActiva ) {
    formPrueba = tagging.tryAndBuy.newExtraData.formCompraPrueba;
  }
  else {
    formPrueba = tagging.tryAndBuy.newExtraData.formPruebaGratis;
  };

  trackData.page_name = trackData.page_name.replace('{product}', `${this.itmName}:${formPrueba}`);
  trackData.page_subcategory_level_3 = formPrueba;
  trackData.navigation_level_4 = formPrueba;
}

setClick(trackData: TaggingViewModel, typeButton: string, txtButton: string): void {
 trackData.event_category = tagging.tryAndBuy.newExtraData.boton;
  trackData.event_context = tagging.tryAndBuy.newView.page_screen;
  let newTrackData: TaggingClickDataModel;
  switch (typeButton) {
    case ('isEnd'):
      newTrackData = this.buttonEnd(trackData, txtButton);
      break;
    case (tagging.tryAndBuy.newExtraData.isDetail):
      newTrackData = this.buttonDetail(trackData, txtButton)
      break;
    case (tagging.tryAndBuy.newExtraData.formulario):
      newTrackData = this.formulario(trackData, txtButton)
      break;
    case (tagging.tryAndBuy.newExtraData.exitAbandonar):
      newTrackData = this.exit(trackData, typeButton, txtButton)
      break;
    case (tagging.tryAndBuy.newExtraData.exitContinuar):
      newTrackData = this.exit(trackData, typeButton, txtButton)
      break;
    default:
      newTrackData = trackData;
      newTrackData['&&products'] = tagging.tryAndBuy.newExtraData['&&products']
      .replace('{product}', this.tagging.replaceSpecialCharText(this.itmName));
      if (typeButton === tagging.tryAndBuy.newExtraData.sticky && this.step < 2) {
        newTrackData.page_subcategory_level_3 = '';
        newTrackData.navigation_level_4 = '';
      }
      newTrackData.event_name = `${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}${typeButton}`;
      newTrackData.event_label = newTrackData.event_name;
      newTrackData.journey_detail = `${this.itmName}:${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}${typeButton}`;
      newTrackData.journey_step = `${tagging.tryAndBuy.newExtraData.pruebaCompra}:${newTrackData.journey_detail}`;
  }
  this.tagging.track(newTrackData.event_name, newTrackData);
}

buttonEnd(trackData: TaggingViewModel, txtButton?: string): TaggingViewModel {
  trackData.page_subcategory_level_3 = tagging.tryAndBuy.newExtraData.formPrueba;
  trackData.navigation_level_4 = tagging.tryAndBuy.newExtraData.formPrueba;

  let isOk: string;
  let typeForm: string;
  let typeBuy: string;
  this.activeButton ? isOk = tagging.tryAndBuy.newExtraData.formfin : isOk = tagging.tryAndBuy.newExtraData.ko;

  trackData.journey_detail = `${this.itmName}:${tagging.tryAndBuy.newExtraData.formulario}:${isOk}`;
  trackData.journey_step = `${tagging.tryAndBuy.newExtraData.pruebaCompra}:${trackData.journey_detail}`;
  if (this.typeReco === TryAndBuy.confirmable || this.typeReco === TryAndBuy.pruebaActiva ) {
    typeForm = tagging.tryAndBuy.newExtraData.formCompraPrueba;
    typeBuy = tagging.tryAndBuy.newExtraData.comprar;
  }
  else {
    typeForm = tagging.tryAndBuy.newExtraData.formPruebaGratis;
    typeBuy = tagging.tryAndBuy.newExtraData.probar;
  }
  trackData.page_subcategory_level_3 = typeForm;
  trackData.navigation_level_4 = typeForm;
  trackData.event_name = `${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  trackData.event_label = `${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  return trackData
}

buttonDetail(trackData: TaggingViewModel, txtButton?: string): TaggingViewModel {
 if (this.typeReco !== TryAndBuy.confirmable) {
    trackData.page_subcategory_level_3 = tagging.tryAndBuy.newExtraData.formPrueba;
    trackData.navigation_level_4 = tagging.tryAndBuy.newExtraData.formPrueba;
  }
  else {
    trackData.page_subcategory_level_3 = tagging.tryAndBuy.newExtraData.formCompraPrueba;
    trackData.navigation_level_4 = tagging.tryAndBuy.newExtraData.formCompraPrueba;
  }
  trackData.event_name = `${tagging.tryAndBuy.newExtraData.clickEn} ${tagging.tryAndBuy.newExtraData.verDetalles} ${this.itmName}`;
  trackData.event_label = `${tagging.tryAndBuy.newExtraData.clickEn} ${tagging.tryAndBuy.newExtraData.verDetalles} ${this.itmName}`;
  trackData.journey_detail = `${this.itmName}:${tagging.tryAndBuy.newExtraData.formulario}:${tagging.tryAndBuy.newExtraData.clickEn}:${txtButton}`;
  trackData.journey_step = `${tagging.tryAndBuy.newExtraData.pruebaCompra}:${this.itmName}:${tagging.tryAndBuy.newExtraData.formulario}:${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  return trackData
}

formulario(trackData: TaggingViewModel, txtButton?: string): TaggingViewModel {
  if (this.step < 2 || this.isResume) {
    trackData.page_subcategory_level_3 = '';
    trackData.navigation_level_4 = '';
    trackData.journey_detail = `${this.itmName}:${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
    trackData.journey_step = `${tagging.tryAndBuy.newExtraData.pruebaCompra}:${this.itmName}:${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  }
  else {
    trackData.journey_detail = `${this.itmName}:${tagging.tryAndBuy.newExtraData.formulario}:${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
    trackData.journey_step = `${tagging.tryAndBuy.newExtraData.pruebaCompra}:${this.itmName}:${tagging.tryAndBuy.newExtraData.formulario}:${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  }
  trackData.event_name = `${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  trackData.event_label = `${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;


  return trackData
}

exit(trackData: TaggingViewModel, typeButton?: string, txtButton?: string): TaggingViewModel {
  let typeExit: string;

  if (typeButton === tagging.tryAndBuy.newExtraData.exitAbandonar) {
    typeExit = tagging.tryAndBuy.newExtraData.abandonar;
    trackData['&&events'] = tagging.tryAndBuy.newExtraData.event105;
    trackData.journey_status = tagging.tryAndBuy.newExtraData.end;
  }
  else if (typeButton === tagging.tryAndBuy.newExtraData.exitContinuar) {
    typeExit = tagging.tryAndBuy.newExtraData.continuar;
    trackData['&&events'] = tagging.tryAndBuy.newExtraData.event106;
  }

  trackData.page_subcategory_level_3 = `${tagging.tryAndBuy.newExtraData.exit}:${typeExit}`;
  trackData.navigation_level_4 = trackData.page_subcategory_level_3;
  trackData.page_name += `:${typeExit}`;
  trackData.journey_detail += `:${typeExit}`;
  trackData.journey_step += `:${typeExit}`;
  trackData.event_name = `${tagging.tryAndBuy.newExtraData.clickEn} ${txtButton}`;
  trackData.event_label = trackData.event_name;
  return trackData;
}

sendTrackTagging(entryPoint: TryAndBuyModel, position: string): void {
  const page: TaggingViewModel = Object.assign({}, tagging.tryAndBuy.newView);
  const trackData: TaggingViewModel = Object.assign(page, tagging.tryAndBuy.clickEntryPoint.data);
  trackData.event_name = tagging.tryAndBuy.clickEntryPoint.data.event_name + entryPoint.title;
  trackData.event_label = tagging.tryAndBuy.clickEntryPoint.data.event_name + entryPoint.title;
  trackData.page_name = tagging.tryAndBuy.newExtraData.page_name_list;
  this.tagging.fillJourneyCategoryAndSubcategory(trackData);
  trackData.journey_status = 'start';
  trackData.entrypoint_position = position.toString();
  trackData.journey_detail = entryPoint.title.toLowerCase();
  trackData.journey_step += entryPoint.title.toLowerCase();
  trackData.entrypoint_title = entryPoint.title.toLowerCase();
  let typeForm: string;
  if (this.isNewForm) {

    this.typeReco !== tagging.tryAndBuy.newExtraData.confirmable ?
    typeForm = tagging.tryAndBuy.newExtraData.formPruebaGratis : typeForm = tagging.tryAndBuy.newExtraData.formCompraPrueba;

    trackData.page_subcategory_level_3 = typeForm;
    trackData.navigation_level_4 = typeForm;
  }
  this.tagging.track(tagging.promosMicro.clickEntryPoint.eventName, trackData);
}

notItmNameError (trackData: TaggingViewModel): TaggingViewModel {
  this.itmName = tagging.tryAndBuy.newExtraData.listadoServicios;
  trackData.page_subcategory_level_1 = `${trackData.page_subcategory_level_1}:${tagging.tryAndBuy.newExtraData.error}`;
  trackData.navigation_level_2 += `:${tagging.tryAndBuy.newExtraData.error}`;
  trackData.navigation_level_3 = '';
  trackData.page_screen = tagging.tryAndBuy.newExtraData.error;
  trackData.journey_name += `:${tagging.tryAndBuy.newExtraData.error}`;
  trackData.journey_status = tagging.tryAndBuy.newExtraData.end;
  trackData.journey_detail = `${trackData.journey_detail = trackData.journey_detail.replace('{journeyDetail}', this.itmName.toLowerCase())}:${tagging.tryAndBuy.newExtraData.error}`;
  trackData.journey_step = tagging.tryAndBuy.newExtraData.error;
  return trackData
}

isItmNameError (trackData: TaggingViewModel): TaggingViewModel {
  trackData.page_subcategory_level_2 = this.itmName.toLowerCase();
  trackData.navigation_level_3 = this.itmName.toLowerCase();
  trackData.journey_detail = `${trackData.journey_detail = trackData.journey_detail.replace('{journeyDetail}', this.itmName.toLowerCase())}:${tagging.tryAndBuy.newExtraData.ko}`;
  trackData.journey_step = trackData.journey_step.replace('{journeyDetail}', trackData.journey_detail );
  return trackData
}

sendTrackError(errorCode?: string, errorDescription?: string): void {
const page: TaggingViewModel = Object.assign({}, tagging.tryAndBuy.viewError);
const trackData: TaggingViewModel = Object.assign(page, tagging.tryAndBuy.showError.data);
let newTrackData: TaggingViewModel;
!this.itmName ? newTrackData = this.notItmNameError(trackData) : newTrackData = this.isItmNameError(trackData);

newTrackData.page_name = `${tagging.tryAndBuy.newExtraData.page_name_list}:${tagging.tryAndBuy.newExtraData.error}`;

newTrackData.error_list = newTrackData.error_list.replace('{error_code}', errorCode);
newTrackData.error_category = errorCode
newTrackData.error_description = errorDescription;
let pageType: string = tagging.tryAndBuy.newExtraData.detail;
if (this.isForm) {
  pageType = tagging.tryAndBuy.newExtraData.formulario;
}

if (this.step > 0) {
  newTrackData.journey_detail = newTrackData.journey_detail.replace('{journeyDetail}', this.itmName.toLowerCase()) + pageType;
  switch (this.typeReco) {
    case (TryAndBuy.confirmable):
      newTrackData.journey_process = tagging.tryAndBuy.newExtraData.pruebaActiva;
      break;
    case (TryAndBuy.contratable):
      newTrackData.journey_process = tagging.tryAndBuy.newExtraData.inicial;
      break;
    default:
      newTrackData.journey_process = tagging.tryAndBuy.newExtraData.comprado;
  }
}
else {
  newTrackData.journey_process = tagging.tryAndBuy.newExtraData.inicial;
}


newTrackData.error_page = `${newTrackData.navigation_level_1} : ${newTrackData.navigation_level_2}`
this.tagging.fillJourneyCategoryAndSubcategory(newTrackData);
this.tagging.track(tagging.promosMicro.clickEntryPoint.eventName, newTrackData);
}

requestReceivedTrack(): void {
const page: TaggingViewModel = Object.assign( {}, tagging.tryAndBuy.newView);
const trackData: TaggingViewModel = Object.assign(page, tagging.tryAndBuy.requestReceived.data);
if (!this.itmName) {
  this.itmName = tagging.tryAndBuy.newExtraData.listadoServicios;
}
trackData.page_subcategory_level_2 = this.itmName.toLowerCase();
trackData.page_name = trackData.page_name.replace('{product}', this.itmName.toLowerCase());
trackData.navigation_level_3 = this.itmName.toLowerCase();
this.tagging.fillJourneyCategoryAndSubcategory(trackData);
trackData.journey_detail = trackData.journey_detail.replace('{product}', this.itmName.toLowerCase());
trackData.journey_process = this.typeJourneyProc();
trackData.journey_step = trackData.journey_step.replace('{product}', this.itmName.toLowerCase());
trackData.journey_status = 'end';
this.tagging.view(null, trackData);
}

leaveProccessTrack(typeButton?: string, txtButton?: string): void {
const page: TaggingViewModel = Object.assign( {}, tagging.tryAndBuy.newView);
const trackData: TaggingViewModel = Object.assign(page, tagging.tryAndBuy.leaveProcess.data);
if (!this.itmName) {
  this.itmName = tagging.tryAndBuy.newExtraData.listadoServicios;
}
trackData.page_subcategory_level_2 = this.itmName.toLowerCase();
trackData.page_name = trackData.page_name.replace('{product}', this.itmName.toLowerCase());
trackData.event_name = '';
trackData.navigation_level_3 = this.itmName.toLowerCase();
trackData.page_screen = tagging.tryAndBuy.newExtraData.exit;
this.tagging.fillJourneyCategoryAndSubcategory(trackData);

this.itmName.toLowerCase() === tagging.tryAndBuy.newExtraData.listadoServicios ?
trackData.journey_process = '' : trackData.journey_process = this.typeJourneyProc();

let typeForm: string;

this.isNewForm ? (
  typeForm = tagging.tryAndBuy.newExtraData.formAcceso,
  this.setExtraTagging(trackData)
  ) : this.isResume ? (
    typeForm = tagging.tryAndBuy.newExtraData.formResumen,
    this.setExtraTagging(trackData)
  ) : typeForm = tagging.tryAndBuy.newExtraData.detail;

trackData.navigation_level_4 = tagging.tryAndBuy.newExtraData.exit;
trackData.page_subcategory_level_3 = tagging.tryAndBuy.newExtraData.exit;
trackData.journey_detail = `${this.itmName}:${typeForm}:${tagging.tryAndBuy.newExtraData.exit}`;
trackData.journey_step = `${trackData.journey_name}:${this.itmName}:${typeForm}:${tagging.tryAndBuy.newExtraData.exit}`;
txtButton ? this.setClick(trackData, typeButton, txtButton) : this.tagging.view(null, trackData);

}

typeJourneyProc(): string {
  let journey_process: string = '';
    switch (this.typeReco) {
      case (TryAndBuy.confirmable):
      case (TryAndBuy.pruebaActiva):
        journey_process = tagging.tryAndBuy.newExtraData.pruebaActiva;
        break;
      case (TryAndBuy.contratable):
      case (TryAndBuy.estadoInicial):
        journey_process = tagging.tryAndBuy.newExtraData.inicial;
        break;
      case ( TryAndBuy.pruebaTerminada):
          journey_process = tagging.tryAndBuy.newExtraData.pruebaFinalizada;
        break;
      default:
        journey_process = tagging.tryAndBuy.newExtraData.comprado;
    }
  return journey_process;
  }
}

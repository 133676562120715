import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Mva10FullModslServiceService {
  /** centered title in overlay */
  public errorTitle?: string;
  /** sub title below the title, it is any for safeHtml text */
  public errorSubtitle?: any;
  public errorImage?: string;
  /** red button */
  public errorButton1?: string;
  /** red button action */
  public errorButton1Action?: Function;
  /** white button below the red button */
  public errorButton2?: string;
  /** retry button */
  public retryBtn?: string;
  /** retry button action */
  public retryBtnAction?: Function;
  /** white button action */
  public errorButton2Action?: Function;
  /** centered image */
  public img?: string;
  /** flag to increase z-index to be 660 */
  public increase_z_index?: boolean = false;
  /** flag for hiding top right X button */
  public hideXButton?: boolean;
  /** flag for showing the skeleton */
  public showSkeleton?: boolean = false;
  /** close button Function */
  public closeAction?: any;
  /** close btn subject fire when close the overlay  */
  public closeOverlaySubject: Subject<any> = new Subject<any>();
  /** error button 1 subject fire when clicked  */
  public errorButton1Subject: Subject<void> = new Subject<void>();
  /** flag to show the overlay with grey background */
  public greyMode?: boolean;
  /** flag to edit error modal ui */
  public adjustErrorModalUi?: boolean;
  /** property to set image width if you set it , otherwise it will remain as deafult */
  public imageWidth?: number;
  /** property to set image height if you set it , otherwise it will remain as deafult */
  public imageHeight?: number;
  /* flag to adjust Content UI, removes overflow property from container and adjust padding for Content */
  public isAdjustContent?: boolean;
  constructor() { }

  resetProperties(): void {
    this.errorTitle = null;
    this.errorSubtitle = null;
    this.errorImage = null;
    this.errorButton1 = null;
    this.errorButton1Action = null;
    this.errorButton2 = null;
    this.retryBtn = null;
    this.retryBtnAction = null;
    this.errorButton2Action = null;
    this.img = null;
    this.increase_z_index = false;
    this.hideXButton = null;
    this.showSkeleton = false;
    this.closeAction = null;
    this.closeOverlaySubject = new Subject<any>();
    this.errorButton1Subject = new Subject<void>();
    this.greyMode = null;
    this.adjustErrorModalUi = null;
    this.imageWidth = null;
    this.imageHeight = null;
  }
}

//#region Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DoubleDropDownItem } from '../../../models/double-dropdown-item.model';
import { DoubleDropDownOptions } from '../../../models/double-dropdown-options.model';
import { DirectionType } from '../../enums/directions-type.enum';

declare var $;
//#endregion
//#region Configurations
/**
 * Component class to encapsulate it is behaviors and related properties
 *
 * @export
 * @class SpDoubleToggleDropdownComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'sp-double-toggle-dropdown',
  templateUrl: './sp-double-toggle-dropdown.component.html',
  styleUrls: ['./sp-double-toggle-dropdown.component.scss']
})
//#endregion
export class SpDoubleToggleDropdownComponent implements OnInit {
  //#region Declarations
  // Left (First) drop down and Right (Second) current selected item
  lDropDownSelectedItem = new DoubleDropDownItem();
  rDropDownSelectedItem = new DoubleDropDownItem();

  // used to identify which drop down is targeted
  dropDownType = DirectionType;
  //#endregion
  //#region Inputs
  // To indicate if we have two drop downs or just one occupying have the row
  @Input() isTwoPerRow = true;

  // Left (First) drop down and Right (Second) drop down rendering options
  @Input() lDropDownOptions: DoubleDropDownOptions;
  @Input() rDropDownOptions: DoubleDropDownOptions;
  //#endregion
  //#region Outputs
  @Output() showLDatePicker = new EventEmitter();
  @Output() showRDatePicker = new EventEmitter();
  @Output() onLDropDownChanged = new EventEmitter();
  @Output() onRDropDownChanged = new EventEmitter();
  //#endregion
  //#region Initialization
  constructor() {}

  ngOnInit() {
    // Set initial selected to the first element of the list
    if (this.lDropDownSelectedItem === null) {
      this.lDropDownSelectedItem.label = this.lDropDownOptions.items[0].label;
      this.lDropDownSelectedItem.value = this.lDropDownOptions.items[0].value;
    }
    if (this.rDropDownSelectedItem === null) {
      this.rDropDownSelectedItem.label = this.rDropDownOptions.items[0].label;
      this.rDropDownSelectedItem.value = this.rDropDownOptions.items[0].value;
    }
  }
  //#endregion
  //#region Behaviors
  lDropDownOpen() {
    $('.' + this.lDropDownOptions.dropDownClass).slideDown();
    this.lDropDownOptions.opened = true;
  }
  lDropDownClose() {
    $('.' + this.lDropDownOptions.dropDownClass).slideUp();
    this.lDropDownOptions.opened = false;
  }
  rDropDownOpen() {
    $('.' + this.rDropDownOptions.dropDownClass).slideDown();
    this.rDropDownOptions.opened = true;
  }
  rDropDownClose() {
    $('.' + this.rDropDownOptions.dropDownClass).slideUp();
    this.rDropDownOptions.opened = false;
  }
  /**
   * Toggle visibility of items list (close and open it)
   *
   * @param {dropDownType} type
   * @returns
   * @memberof SpDoubleToggleDropdownComponent
   */
  toggleItemsList(type: DirectionType) {
    if (type === this.dropDownType.left) {
      // First (Left) Dropdown
      if (
        !$('.' + this.lDropDownOptions.dropDownClass).is(':visible') ||
        $('.' + this.lDropDownOptions.dropDownClass).is(':hidden')
      ) {
        // open
        if (this.rDropDownOptions.opened === true) {
          this.rDropDownClose();
        }
        this.lDropDownOpen();
        return;
      } else {
        // close
        this.lDropDownClose();
      }
    } else if (type === this.dropDownType.right) {
      // Second (Right) Dropdown
      if (
        !$('.' + this.rDropDownOptions.dropDownClass).is(':visible') ||
        $('.' + this.rDropDownOptions.dropDownClass).is(':hidden')
      ) {
        // open
        if (this.lDropDownOptions.opened === true) {
          this.lDropDownClose();
        }
        this.rDropDownOpen();
        return;
      } else {
        this.rDropDownClose();
      }
    }
  }
  /**
   * Sets the user selected value from the left drop down
   *
   * @param {DoubleDropDownItem} newValue
   * @memberof SpDoubleToggleDropdownComponent
   */
  setLDropDownSelectedValue(newValue: DoubleDropDownItem) {
    this.lDropDownSelectedItem.label = newValue.label;
    this.lDropDownSelectedItem.value = newValue.value;
    this.lDropDownOptions.isSelected = true;
    this.onLDropDownChanged.emit(newValue);
  }
  /**
   * Sets the user selected value from the right drop down
   *
   * @param {DoubleDropDownItem} newValue
   * @memberof SpDoubleToggleDropdownComponent
   */
  setRDropDownSelectedValue(newValue: DoubleDropDownItem) {
    this.rDropDownSelectedItem.label = newValue.label;
    this.rDropDownSelectedItem.value = newValue.value;
    this.rDropDownOptions.isSelected = true;
    this.onRDropDownChanged.emit(newValue);
  }
/**
 * To handle broadcasting an event to notify
 * the requester to open a date picker element
 *
 * @param {DropDownType} type
 * @memberof SpDoubleToggleDropdownComponent
 */
openDatePicker(type: DirectionType) {
    if (type === this.dropDownType.left) {
      // First (Left) Dropdown
      this.showLDatePicker.emit();
    } else if (type === this.dropDownType.right) {
      // Second (Right) Dropdown
      this.showRDatePicker.emit();
    }
  }
  //#endregion
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sp-pending-install-warning',
  templateUrl: './pending-install-warning.component.html',
  styleUrls: ['./pending-install-warning.component.scss']
})
export class PendingInstallWarningComponent {
  @Input() withBox: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() linkText: string;

  @Output() linkClick: EventEmitter<void>;

  constructor(public translate: TranslateService) {
    this.linkClick = new EventEmitter();
  }

  onLinkClick(): void {
    this.linkClick.emit();
  }
}

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentFullTrayModel } from '../../../models/payment';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentTrayConfigPrePaidAnonymous } from './payment-tray-config-prePaid-anonymous';

export class PaymentTrayConfigPrePaidAnonymousThirdParty extends PaymentTrayConfigPrePaidAnonymous implements PaymentTrayConfigInterface {
    // this component is treated like an ovrelay we remove tem from history after navigating away from them
    // not to show them to the user accidentaly when he navigate back
    compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] =
        [PaymentPages.cancel, PaymentPages.loading, PaymentPages.deleteConfiramtion];
    /** variable to be used for getting a tray config method based on component name */
    trayConfigurations: any = {
        [PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfigAnonymous.bind(this),
        [PaymentPages.ok]: this.getOkCompTrayConfigAnonymous.bind(this),
        [PaymentPages.ko]: this.getKoCompTrayConfigِAnonymous.bind(this),
        [PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
        [PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
        [PaymentPages.ticket]: this.getTicketCompTrayConfigAnonymous.bind(this),
        [PaymentPages.topupScreen]: this.getTopUpLandingCompTrayConfigPrePaidAnonymous.bind(this),
    };
    constructor(public paymentNavService: PaymentNavigationService,
        public delegateService: DelegateCreationService,
        public translate: TranslateService,
        public paymentWalletService: PaymentWalletService,
        public router: Router
    ) {
        super(paymentNavService, delegateService, translate, paymentWalletService, router);
    }
    /**z
   * @param compName the page we need it's tray configuration
   * function should return PaymentFullTrayModel containing
   * the proper tray configuration
   */
    getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
        let config: PaymentFullTrayModel;
        /** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
        if (this.trayConfigurations[compName]) {
            config = this.trayConfigurations[compName]();
        } else {
            config = this.getIframeCompTrayConfig();
        }
        return config;
    }
    /**
     * helper function to set general configration for the
     * tray
     */
    getInitalConfigModel(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = new PaymentFullTrayModel();
        paymentFullTrayModel.MVA10Config.isCenterTitle = true;
        paymentFullTrayModel.MVA10Config.isFullScreen = true;
        paymentFullTrayModel.MVA10Config.showBackButton = false;
        paymentFullTrayModel.MVA10Config.isScrollableTray = false
        paymentFullTrayModel.MVA10Config.hideXButton = true;
        paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = true;
        return paymentFullTrayModel;
    }


}

export class AddressVerticalInfoModel {
    number: string;
    stair: string;
    floor: string;
    door: string;
    postalCode: string;
    additionalInfo?: string;
    city?: string;
    name?: string;
    provinceName: string;
}

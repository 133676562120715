import { Injectable } from '@angular/core';
import { BillingService } from 'src/app/billing/billing.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { SiteHandlingService } from 'src/app/shared/services/site-handling.service';
import { PaymentJourneyParams } from 'src/app/models/payment';
import { PaymentJourneyIds } from 'src/app/shared/enums/payment-journey-ids.enum';
import { PaymentLanguages } from 'src/app/shared/enums/payment-languages.enum';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { StorageService } from '../../core/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class FinancedDevicesPaymentService {

  constructor(private billingService: BillingService,
    private subscriptionService: SubscriptionService,
    private paymentNavigationService: PaymentNavigationService,
    private siteHandlingService: SiteHandlingService,
    private storage: StorageService) { }

  /*
     * Open iframe and set payment joureny params object needed to open it
     * @param amount of the money user need to pay
     * @param WCSJournyKey of the current journey
     * @param concepto wcs for concepto value in iframe screen
     */
    openPaymentModulePostPaidFinancedDevices(amount: string, WCSJournyKey: PaymentJourny, concepto: string): void {
        const paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
        // payer data
        paymentJourneyParams.payer.documentId = this.storage.userProfile.document.id;
        paymentJourneyParams.payer.siteId = this.billingService.selectedSiteId ? this.billingService.selectedSiteId
          : this.subscriptionService.customerData.customerAccountsId;
        paymentJourneyParams.payer.serviceId = this.billingService.selectedFundedDevice.msisdn;
        paymentJourneyParams.payer.documentType = this.storage.userProfile.document.type
        // buyer data
        paymentJourneyParams.buyer.documentId = this.storage.userProfile.document.id;
        paymentJourneyParams.buyer.siteId = this.billingService.selectedSiteId ? this.billingService.selectedSiteId
          : this.subscriptionService.customerData.customerAccountsId;
        paymentJourneyParams.buyer.serviceId = this.billingService.selectedFundedDevice.msisdn;
        paymentJourneyParams.amount = amount;
        paymentJourneyParams.WCSJournyKey = WCSJournyKey;
        paymentJourneyParams.isAnonymous = this.siteHandlingService.isSiteSuspended() ? true : false;
        // params related to iframe screen
        paymentJourneyParams.concepto = concepto;
        paymentJourneyParams.journeyId = PaymentJourneyIds.postPaidFinancedDevicesWithouCC;
        paymentJourneyParams.imei = this.paymentNavigationService.financedDevicesCancellation.imei;
        paymentJourneyParams.installmentQuantity =
        this.paymentNavigationService.financedDevicesCancellation.installmentQuantity;
        // handling journey
        paymentJourneyParams.isHandleWaiting = true;
        paymentJourneyParams.language = PaymentLanguages.es;
        paymentJourneyParams.paymentTaggingConfigArchetype =
          this.billingService.getPaymentFinancedDevicesJourneyTaggingArchetype(WCSJournyKey, amount);
        paymentJourneyParams.placeHolders.okScreeen.subtitle = this.billingService.getSuccessScreenPlaceHolders(amount, WCSJournyKey);
        this.paymentNavigationService.WCSJourneyKey = WCSJournyKey;
        this.paymentNavigationService.openPaymentThirdParty(paymentJourneyParams);
      }
}

export const TrafficeVariables = {
    page_section: 'vodafone',
    page_category: 'test',
    page_subcategory_level_1: '',
    page_subcategory_level_2: '',
    page_subcategory_level_3: '',
    page_screen: 'example',
    page_name: 'vodafone:test:example',
    page_previous_page: 'vodafone: test: previous_example',
    page_language: 'castellano',
}
export const ClientVariables = {
    client_bundle: 'one',
    client_crm: 'amdocs',
    client_debt: 'no',
    client_hierarchy: 'individual',
    client_id: '111111111-123',
    client_login_type: 'manual',
    client_origin: 'nuevo',
    client_privileges: 'completo',
    client_products: 'fijo,fibra,tv',
    client_status: 'activo',
    client_typology: 'particular contrato',
    client_login_id_type: 'email',
    client_sfid: '1025154',
    client_segment: 'oro',
    client_id_crm: '111111111',
    client_login_status: 'logged'
}
export const ServiceVariables = {
    service_addons: 'linea',
    service_msisdn_old: 'abC23dFb',
    service_msisdn: 'MSISDN-encrypted-with-new-encoding',
    service_plan: '12345',
    service_plan_expiration: '20171015',
    service_type: '',
    service_robinson_list: 'si'
}
export const ErrorVariables = {
    error_category: 'server error',
    error_description: 'error 500',
    error_page: 'vodafone:test:example'
}
export const OtherVariables = {
    flux_process: 'nueva linea:paso 1',
    platform: 'web',
    page_typology: 'overlay',
    page_traffic_source: 'directo',
    page_url: 'page schema',
    document_name: 'document name',
    product_name: 'product name',
    server: 'vodafone'
}
export const CrossVariables = {
    client_bundle: '',
    client_crm: '',
    client_debt: '',
    client_hierarchy: '',
    client_id: '',
    client_login_type: '',
    client_privileges: '',
    client_products: '',
    client_status: '',
    client_typology: '',
    error_category: '',
    error_descripcion: '',
    page_name: '',
    page_platform: '',
    page_section: '',
    site_section: '',
    page_subcategory_level_1: '',
    page_subcategory_level_2: '',
    page_subcategory_level_3: '',
    page_subcategory_level_4: '',
    page_subcategory_level_5: '',
    page_subcategory_level_6: '',
    page_screen: '',
    page_typology: '',
    page_detail: '',
    page_original_referring: '',
    page_url: '',
    visitor_login_status: '',
    // --- New tagging vars ---
    asset_name: '',
    navigation_level_1: '',
    navigation_level_2: '',
    navigation_level_3: '',
    navigation_level_4: '',
    navigation_level_5: '',
    // --- END: New tagging vars ---
    service_addons: '',
    service_plan: '',
    service_type: '',
    client_login_id_type: '',
    client_segment: '',
    error_page: '',
    marketing_cloud_id: '',
    client_login_status: '',
    client_id_crm: '',
    error_type: '',
    serviceAES256: '',
    prepaid_status: '',
    client_id_ev: '',
    event_name: 'mobile visitor',
    // --- New tagging vars ---
    event_category: '',
    event_context: '',
    event_label: '',
    event_action: '',
    // --- END: New tagging vars ---
    nba_availability: 'no resuelto',
    nba_show: 'no resuelto',
    error_code: '',
    prod_config: '',
    help_bubble_status: '',
    msisdn: '',
    superoffer: '',
    error_list: ''
}
export const taggingConfig = {
    pendingRequests: [],
    navigationEnd: false,
    componentFinishInit: false
}
export class EntrypointVariables {
    section?: string;
    location?: string;
    position?: string;
    title?: string;
    journeyname?: string;
    experiencename?: string;
    events?: string;
}

/**
 * Model for taggingHelper.view() function - MAY BE MISSING SOME PARAMETERS
 */
export interface TaggingViewModel extends TaggingClickDataModel {
    client_bundle?: string;
    client_crm?: string;
    client_debt?: string;
    client_hierarchy?: string;
    client_id?: string;
    client_login_type?: string;
    client_privileges?: string;
    client_products?: string;
    client_status?: string;
    client_typology?: string;
    error_category?: string;
    error_descripcion?: string;
    error_description?: string;
    page_name?: string;
    page_platform?: string;
    page_section?: string;
    page_subcategory_level_1?: string;
    page_subcategory_level_2?: string;
    page_subcategory_level_3?: string;
    page_subcategory_level_4?: string;
    // --- New tagging vars ---
    asset_name?: string;
    navigation_level_1?: string;
    navigation_level_2?: string;
    navigation_level_3?: string;
    navigation_level_4?: string;
    navigation_level_5?: string;
    // --- END: New tagging vars ---
    page_screen?: string;
    page_detail?: string;
    page_typology?: string;
    page_original_referring?: string;
    page_url?: string;
    service_addons?: string;
    service_plan?: string;
    service_type?: string;
    client_login_id_type?: string;
    client_segment?: string;
    error_page?: string;
    marketing_cloud_id?: string;
    client_login_status?: string;
    client_id_crm?: string;
    error_type?: string;
    serviceAES256?: string;
    prepaid_status?: string;
    client_id_ev?: string;
    nba_availability?: string;
    nba_show?: string;
    error_code?: string;
    prod_config?: string;
    help_bubble_status?: string;
    msisdn?: string;
    superoffer?: string;
    client_billing_id?: string;
    client_billing_type?: string;
    billing_alert?: string;
    purchase_migration_type?: string;
    purchase_product_category?: string;
    purchase_product_classification?: string;
    promotion_category?: string;
    is_main_flow?: string;
    state_flow?: string;
    error_list?: string;
    installation_type?: string;
      // Transsational
    product_connection_type?: string;
    visitor_donor_mobile?: string;
    discount_code?: string;
    new_discount_code?: string;
    discount_expiration?: string;
    discount_value_change?: string;
    toggle_active?: string;
    toggle_not_active?: string;
    eventName?: string;
    transaction_shipping?: string;
    shipping_type?: string;
    journey_card_selected?: string;
    payment_id?: string;
    data?: TaggingClickDataModel;
    purchaseID?: string;
    journey_mobile?: string;
    transaction_cart_id?: string;
    journey_principal?: string;
    experience_name_test?: string;
    lifecycle_timestamp?: number;
    aditional_line_availability?: string;
    mobile_exchange_availability?: string;
    promotion_code?: string;
    client_origin?: string;
    product_playlist_name?: string;
    journey_topup_amount?: string;
    journey_prepaid_tariff?: string;
}

/**
 * Model for taggingHelper.track() function - MAY BE MISSING SOME PARAMETERS
 */
export interface TaggingActionModel {
    eventName?: string;
    data?: TaggingClickDataModel;
}

export interface TaggingClickDataModel {
    event_name?: string;
    // --- New tagging vars ---
    event_category?: string;
    event_context?: string;
    event_label?: string;
    event_action?: string;
    // --- END: New tagging vars ---
    '&&events'?: string;
    entrypoint_list?: string;
    entrypoint_pega?: string;
    entrypoint_section?: string;
    entrypoint_location?: string;
    entrypoint_position?: string;
    entrypoint_title?: string;
    entrypoint_type?: string;
    entrypoint_name?: string;
    entrypoint_label?: string;
    entrypoint_issue?: string;
    entrypoint_groupnba?: string;
    entrypoint_code?: string;
    purchase_entrypoint?: string;
    experience_name?: string;
    page_name?: string;
    page_section?: string;
    page_subcategory_level_1?: string;
    page_subcategory_level_2?: string;
    page_subcategory_level_3?: string;
    page_subcategory_level_4?: string;
    page_screen?: string;
    promo_plan?: string;
    promo_segment?: string;
    journey_name?: string;
    journey_category?: string;
    journey_subcategory?: string;
    journey_installation_type?: string;
    journey_options?: string;
    journey_original?: string;
    journey_type?: string;
    journey_step?: string;
    journey_process?: string;
    journey_error_category?: string;
    journey_error_description?: string;
    journey_error_code?: string;
    journey_detail?: string;
    journey_environment?: string;
    journey_payment_type?: string;
    journey_status?: string;
    journey_numoffers?: number;
    journey_position?: number;
    journey_bundle?: string;
    journey_packs?: string;
    journey_product?: string;
    journey_type_plan?: string;
    journey_offer_diff?: string;
    '&&products'?: string;
    previous_pagename?: string;
    asset_name?: string;
    navigation_level_1?: string;
    navigation_level_2?: string;
    navigation_level_3?: string;
    navigation_level_4?: string;
    navigation_level_5?: string;
    payment_id?: string;
    journey_social_media?: string;
    journey_card_selected?: string;
    purchaseID?: string;
    product_catalog_offer?: string;
    product_type_offer?: string;
    page_detail?: string;
    journey_service_name?: string;
}

export interface JourneyTaggModel {
    purchase_entrypoint?: string;
    purchase_migration_type?: string;
    purchase_product_classification?: string;
    products?: string;
    promotion_name?: string;
    promotion_duration?: string;
    promotion_code?: string;
    transaction_shipping?: string;
    purchaseID?: string;
}

export interface TaggingErrorModel {
    '&&events'?: string;
    journey_type?: string;
    journey_name?: string;
    journey_category?: string;
    journey_subcategory?: string;
    journey_proccess?: string;
    journey_option?: string;
    journey_detail?: string;
    journey_step?: string;
    error_category?: string;
    error_description?: string;
    error_page?: string;
    error_type?: string;
    error_code?: string;
}

export interface TaggingSwStatesModel {
    init_state?: string;
    button_state?: string;
    acordeon_state?: string;
    cond_state?: string;
    frec_state?: string;
    error_funcional?: string;
    error_tecnico?: string;
}

export interface TaggingErrorFuncionalModel {
    error_event?: string;
    error_subcategory_level_2?: string;
    error_page_screen?: string;
    error_type?: string;
    error_code?: string;
}

export interface SPegaCrossSellLineaStates {
    state_page?: string;
    state_saveData?: string;
    state_cta_save?: string;
    state_cta_close?: string;
}

export class ProductViewTags {
    prodView?: string;
    products?: string;
    tarifSelected?: boolean;
}

export interface EntryPointsTags {
    event_name?: string;
    entrypoint_section?: string;
    entrypoint_location?: string;
    entrypoint_title?: string;
    entrypoint_position?: string;
    entrypoint_type?: string;
    entrypoint_name?: string;
    entrypoint_issue?: string;
    entrypoint_label?: string;
    entrypoint_groupnba?: string;
    entrypoint_code?: string;
    experience_name?: string;
    journey_name?: string;
    journey_options?: string;
    '&&events': string;
}
export interface DiscountRenewalModel extends TaggingViewModel {
    promotion_code?: string,
    client_origin?: string
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MicroFlowsService } from '../../shared/services/micro-flows.service';
import { CanComponentDeactivate } from '../../fault-management/guards/openNewTicketDeactivate.guard';

@Injectable({
  providedIn: 'root'
})
export class MicroFlowDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(
    private microflowsService: MicroFlowsService
  ) {}
    canDeactivate(component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.microflowsService.cleanMf();
    return true;
  }
}

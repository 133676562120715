export class PostalAddress {
  type: string;
  name: string;
  addressNumber: string;
  floor: string;
  door: string;
  zipCode: string;
  province: string;
  town: string;
  verticalId: string;
  longitud?: number;
  latitud?: number;
  notNormalizedAllowed?: boolean;
}

import { RoamingBundleDeatils } from './romaingBundleDeatils.model';
import { SafeHtml } from '@angular/platform-browser';

export class RoamingBundleCard {
    /** the card main title in the colored box */
    cardTitle: string;
    /** leftTitle inside card  */
    leftTitleText: string;
    /** flag to set classes of labeel and btns inside card */
    status: string;
    /**btn's text inside card */
    btnText: string;
    /**second center title */
    SecondTitle: string;
    /**third center title */
    thirdTitle: string;
    /**the last text inside card  */
    textBelow: string;
    /** linkText*/
    linkText: string;
    /**show title and border flag */
    hideTitleAndBorder: boolean;
    /** array for bundle deatils */
    bundleDeatils?: RoamingBundleDeatils[];
    /**show divider under linktext */
    linkTextBottomDivider?: boolean;
    /** Show More text */
    showMoreText?: SafeHtml;
    /** hint is a variable for labe that may appear above bundle deatiles title ,
     *  it's created intially for datos
     */
    hint?: string;
    /** Color of the label and border. If null, it is turquoise */
    colorLabel?: string;
    /** Show third title without treating it */
    showSimpleThirdTitle?: boolean;
    /** Hide more details button */
    hideMoreDetails?: boolean;
}

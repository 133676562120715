import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { allowedServicesDef, CommercialJourneys, commercialMobile, JSON_PATHS, entryPointsMVA10, LOCAL_STORAGE_KEYS } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { CustomerType } from '../enums/customerType.enum';
import { UserSettingToken } from '../enums/user-setting-token.enum';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EntryPointsService } from './entry-points.service';
import { AllowedServicesService } from './allowed-services.service';
import { EntryPointResponseModel } from '../../../app/models/entry-points.model';
import * as JsonQuery from 'jsonpath/jsonpath';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from '../utils/utilities.service';
import { EntryPointModel } from '../../../app/models/entry-points.model';
import { OfferDiscount } from '../../../app/models/commercial-offer.model';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { TaggingViewModel } from 'src/app/models/tagging.model';
import { tagging } from 'src/config/tagging-config';
import { Error } from 'src/app/models/error.model';
import { RecoProductPackageModel } from '../../models/reco-product-package.model';


@Injectable({
  providedIn: 'root'
})
export class MicroFlowsService {

  public dataInMf: string[] = [];
  public entrePointsRes: object;
  public allowedRes: object;
  private averia: string = '';
  public isMe: boolean;
  public webToken: string;
  public chatToken: string;
  public partialError: boolean = false;
  public screenCode: string;
  public retry: number = 0;
  public cartId: string;
  public isMainFlow: boolean = false;
  public laEntryPoint: EntryPointModel;
  public offerDiscounts: Array<OfferDiscount> = [];
  public recoProductPackage: RecoProductPackageModel;
  private postResetCookie: BehaviorSubject<boolean>;
  public postResetCookie$: Observable<boolean>;
  private unsuscribeDiscounts: string;
  private stepCommercial: string;
  private errorReco: boolean = false;
  public navigateError: HttpErrorResponse;
  public responseEntrypoints: EntryPointResponseModel;

  public productsTag: string;
  public tarifChanged: boolean = false;
  public showArrowBack: boolean = false;

  constructor(
    public subscriptionData: SubscriptionService,
    private storage: StorageService,
    public http: HttpClient,
    public entryPointService: EntryPointsService,
    private allowedServices: AllowedServicesService,
    private translateService: TranslateService,
    private readonly router: Router,
    private readonly utilities: UtilitiesService,
    private tagging: TaggingHelperService,
  ) {
     this.postResetCookie = new BehaviorSubject<boolean>(false);
      this.postResetCookie$ = this.postResetCookie.asObservable();
   }


  public setCommercialStep(step: string): void {
    this.stepCommercial = step;
  }

  public getCommercialStep(): string {
    return this.stepCommercial;
  }
  public dataPush(prop: string): void {
    this.dataInMf.push(prop);
  }
  public getTokens(type: UserSettingToken, siteId?: string, serviceId?: string, menuItemClicked = true, chatBotToken?: string
    , WCSData?: any, isdigital: boolean = null): Observable<any> {
    const url: string = API_URLS.userSettings.userSettings;
    const segment: string = this.setSegment(WCSData, isdigital);
    const body: object = this.setBody(menuItemClicked, serviceId, WCSData, type, siteId, chatBotToken, segment);
    body['attributes'][0]['params'].averia = this.averia;
    const options: object = this.headersData();

    return this.http.post(url, body, options).pipe(map(res => {
      this.webToken = JsonQuery.value(res, JSON_PATHS.UserSettings.webToken) || null;
      this.chatToken = JsonQuery.value(res, JSON_PATHS.UserSettings.chatToken) || null;
      return true
    }), catchError(error => {
      return throwError(error);
    }));
  }

  private setSegment(WCSData: any, isdigital: boolean): string {
    let segment: string;
    this.storage.userProfile && (this.storage.userProfile.customerType &&
      this.storage.userProfile.customerType.toLowerCase() === CustomerType.Employee.toLocaleLowerCase() ||
      this.storage.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLocaleLowerCase()) ?
      segment = (WCSData && WCSData.microCompanySegment) ? WCSData.microCompanySegment : '' :
      this.altSegment(WCSData, isdigital);

    return segment;
  }

  private altSegment(WCSData: any, isdigital: boolean): string {
    let segment: string;
    if (isdigital) {
      segment = WCSData ? WCSData.rsYUSegment : ''
    }
    else {
      segment = WCSData ? WCSData.rsSegment : ''
    }
    return segment;
  }

  private setBody(menuItemClicked: boolean = true,
    serviceId: string, WCSData: any, type: UserSettingToken, siteId: string, segment: string, chatBotToken?: string): object {
    const body: object = menuItemClicked ?
      { 'attributes': [{ 'name': type }] }
      :
      serviceId && WCSData ?
        {
          'attributes': [{
            'name': type,
            'params': {
              'selectedSite': siteId,
              'selectedService': serviceId,
              'chatBotToken': chatBotToken,
              'origin': WCSData.originWeb,
              'segment': segment
            }
          }]
        }
        :
        serviceId ?
          {
            'attributes': [{
              'name': type,
              'params': {
                'selectedSite': siteId,
                'selectedService': serviceId
              }
            }]
          }
          :
          {
            'attributes': [{
              'name': type,
              'params': {
                'selectedSite': siteId
              }
            }]
          };
    return body;
  }

  public reset(): void {
    this.dataInMf = [];
    this.entrePointsRes = [];
    this.allowedRes = [];
  }

  public setClientType(): void {
    const isMicroResegmented: boolean = this.utilities.isConsumerAndHasMicro()
    const customerType: string = this.storage.userProfile.customerType.toLowerCase();
    if (customerType === CustomerType.Consumer.toLowerCase() && !isMicroResegmented) {
      this.isMe = false;
    } else {
      this.isMe = true;
    }
  }

  public headersData(): object {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json')
    const options: object = {
      headers: headers,
      withCredentials: true
    };
    return options;
  }

  public getEntryPoints(
    screenCode: string,
    customerAccountId: string,
    maxNumber?: number,
    insideJourney?: boolean,
    serviceId?: string,
    version?: string,
    cartId?: string
  ): Observable<any> {
    const url: string = this.entryPointsUrl(screenCode, customerAccountId, maxNumber, insideJourney, serviceId, version, cartId);
    const options: object = this.headersData();
    if ( screenCode !== entryPointsMVA10.LAD  ||
       (!this.entryPointService.laEntryPoint || Object.keys(this.entryPointService.laEntryPoint)?.length === 0 )) {
      return this.http.get(url, options).pipe(map((res: EntryPointResponseModel) => {
        this.responseEntrypoints = res;
        if (res.entryPoints.length > 0) {
          this.entryPointService.logicEntry(res, screenCode);
          if (res.entryPoints[0].entryPoint === entryPointsMVA10.LA_PEGA) {
            this.laEntryPoint = res.entryPoints[0];
          }
        } else {
          this.partialError = true;
        }
        this.entryPointService.identifyOTorDebt(this.responseEntrypoints, screenCode);
        return res.entryPoints.length > 0 ? true : false;
      }), catchError(error => {
        return throwError(error);
      }));
    } else {
      this.entryPointService.logicEntry(this.entryPointService.laEntryPoint, screenCode);
      this.laEntryPoint = this.entryPointService.laEntryPoint.entryPoints[0];
      return of(true);
    }
 }

  public putResetCookie(postReset: boolean): void {
    this.postResetCookie.next(postReset);
  }

  public resetCookieWpega(url: string, params: object, options: object): Observable<string> {
    return this.http.post(url, params, options).pipe(map((res: any) => {
     this.cartId = res?.NTOL_TXID;
     this.postResetCookie.next(true);
     return res;
   }), catchError((err) => {
     return throwError(err);
   }));
  }

  public resetCookieWOpega(url: string, params: object, options: object): Observable<string> {
    return this.http.post(url, params, options).pipe(map((res: any) => {
      this.cartId = res?.NTOL_TXID;
      return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }
  private setTrackErrorTaggingLogic(errorCategory: string, error: Error): void {
    const page: TaggingViewModel = tagging.newLinesPurchase.page;
    const data: TaggingViewModel = tagging.newLinesPurchase.data;
    const errorData: TaggingViewModel = tagging.newLinesPurchase.error;
    const strings: { [key: string]: string } = tagging.newLinesPurchase.strings;

    page.page_name = page.page_name = page.page_section + ':' +
                                      page.page_subcategory_level_1 + ':' +
                                      strings.error;
    page.page_screen = strings.error;

    data.page_subcategory_level_3 = '';
    data.page_subcategory_level_4 = '';
    data.navigation_level_3 = strings.error;
    data['&&events'] = strings.event100;

    errorData.error_category = errorData.error_category.replace('{error_category}', errorCategory + '_' + error.error?.ecode);
    errorData.error_description = errorData.error_description.replace('{error_description}', error.error?.message);
    errorData.error_type = errorData.error_type.replace('{error_type}', strings.error_tecnico);
    errorData.error_code = errorData.error_code.replace('{error_code}', error.error?.ecode);

    const state: string = page.page_name;
    const context_data: TaggingViewModel = Object.assign(page, data, errorData);
    this.tagging.track(state, context_data);
  }

  public resetCookie(resetWithPega: boolean): Observable<any> {
    const url: string = API_URLS.Checkout.deteleReset.replace('{idClient}', this.subscriptionData.customerData.currentService.siteId)
      .replace('{clientTypeID}', this.isMe ? commercialMobile.clientTypeMicro.toString() : commercialMobile.clientType.toString())
    const options: object = this.headersData();
    let params: object = {}

    if ((this.laEntryPoint && Object.keys(this.laEntryPoint).length > 0
         && this.laEntryPoint?.entryPoint === entryPointsMVA10.LA_PEGA) || resetWithPega) {
      if (!resetWithPega) {
         const obs$: object = this.getRecommendations('onlyclientsproducts', 'DSH').pipe(
          switchMap( () => {
            params = this.getAllEntryPointData();
            if ( !this.errorReco ) {
              return this.resetCookieWpega(url, params, options);
            }

          })
         ).subscribe(
            (res) => {
              return res;
            },
            (error) => {
              if (error.ecode?.toString() === '1300' || error.error.ecode?.toString() === '1300') {
                this.setTrackErrorTaggingLogic(tagging.newLinesPurchase.strings.error_trastienda, error);
                this.router.navigate([config.dashboard.route]);
              }
            }
          )
         return of(obs$);
      } else {
        return this.resetCookieWpega(url, params, options);
      }
    } else {
       return this.resetCookieWOpega(url, params, options)
    }

  }

  public getAllEntryPointData(): EntryPointModel {
    this.laEntryPoint.unsubscribeDiscounts = this.unsuscribeDiscounts
    return this.laEntryPoint;
  }

   public getAllowedServices(journeyCode: string, customerAccountId: string, onlyAllowed: boolean): Observable<any> {
    let url: string = API_URLS.Checkout.allowedServices.replace('{journeyCode}', journeyCode)
      .replace('{customerAccountId}', customerAccountId).replace('{onlyAllowed}', onlyAllowed.toString())
      .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel);
    this.translateService.get('v10').subscribe(text => {
      url = url.concat('&version=', text.commercial.versionPortalEP);
    })
    if (this.cartId) {
      url += allowedServicesDef.query.replace('{cartid}', this.cartId);
    }

    const headers: HttpHeaders = new HttpHeaders();
    const options: object = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      if (res.allowedServices.length > 0) {
        this.allowedServices.allowedLogic(res.allowedServices)
      } else {
        this.partialError = true;
      }
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public entryPointsUrl(screenCode: string,
    customerAccountId: string,
    maxNumber?: number,
    insideJourney?: boolean,
    serviceId?: string,
    version?: string,
    cartId?: string): string {
    let url = '';
    maxNumber ?
      url = API_URLS.EntryPointManagement.entryPointsWithMaxNumber.replace('{screenCode}', screenCode)
        .replace('{customerAccountId}', customerAccountId)
        .replace('{maxNumber}', String(maxNumber))
        .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel)
      : url = API_URLS.EntryPointManagement.entryPointsWithOutMaxNumber.replace('{screenCode}', screenCode)
        .replace('{customerAccountId}', customerAccountId)
        .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel);

    if (serviceId) {
      url = url.concat('&serviceId=', serviceId);
    }
    if (cartId && cartId !== '') {
      url = url.concat('&cartId=', cartId);
    }
    this.translateService.get('v10').subscribe(text => {
      url = url.concat('&version=', text.commercial.versionPortalEP);
    })
    return url;
  }

  getRecommendations( fields?: string, screenCode?: string, offerVFDescriptor?: string): Observable<any> {
    let url: string = API_URLS.Recommendation.getRecommendation.replace('{customerAccountId}',
    this.subscriptionData.customerData.customerAccountsId);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN));

    if (fields) { url = url.concat('&fields=', fields); }
    if (screenCode) { url = url.concat('&screenCode=', screenCode); }
    if (offerVFDescriptor) { url = url.concat('&offerVFDescriptor=', encodeURIComponent(offerVFDescriptor)); }

    return this.http.get(url, { headers }).pipe(map((res: any) => {
      if (typeof res.recommendedOffers !== 'undefined') {
        res.recommendedOffers.forEach(offer => {
          if (typeof offer.discounts !== 'undefined') {
            offer.discounts.forEach(discount => {
              if (discount.type === 'Origin') {
                this.offerDiscounts.push({
                  code: discount.code,
                  instance: discount.instance,
                  type: discount.type,
                  dtoStart: discount.dtoStart,
                  dtoExpire: discount.dtoExpire,
                  dtoDuration: discount.dtoDuration,
                  amount: discount.amount,
                  netAmount: discount.netAmount
                });
              }
            });
          }
        });
      }
      if (res.product) {
        this.recoProductPackage = res.product;
        this.recoProductPackage.subscriptionsAmount = res.subscriptionsAmount ? res.subscriptionsAmount : res.product.subscriptionsAmount;
        this.recoProductPackage.subscriptionsNetAmount = res.subscriptionsNetAmount ? res.subscriptionsNetAmount
                                                         : res.product.subscriptionsNetAmount;

      }
      if (this.offerDiscounts.length > 0) {
        this.unsuscribeDiscounts = '';
      }
     this.errorReco = false ;
     return res
    }), catchError((err) => {
      this.errorReco = true ;
      const url: string = API_URLS.Checkout.deteleReset.replace('{idClient}', this.subscriptionData.customerData.currentService.siteId)
      .replace('{clientTypeID}', this.isMe ? commercialMobile.clientTypeMicro.toString() : commercialMobile.clientType.toString())
      const options: object = this.headersData();
      const params: object = {}
      const obs$: Observable<string> = this.resetCookieWpega(url, params, options)
      this.postResetCookie.next(false);
      return obs$;
    }));
  }

  public logicRetry(): void {
    this.retry++;
    this.partialError = this.retry >= 3;
  }

  public cleanMf(): void {
    this.screenCode = '';
  }
}

<div class='card-contanier'>
  <div class="left-side-card">
    <span class="line-one">{{firstLine}}</span>
    <span class="line-two">{{secondLine}}</span>
    <div *ngIf="isPending" class="pending-container">
      <img class="info-icon" [src]="pendingIconSource">
      <span class="status" [ngStyle]="{'color': color}">{{stautsText}}</span>
    </div>
    <span *ngIf="!isPending" class="status" [ngStyle]="{'color': color}">{{stautsText}}</span>
  </div>
  <div class="right-side-card">
    <sp-toggle-button-mva10 [disable]="disable || isPending" [check]='checkToggle' (option)="changeStatus($event)">
    </sp-toggle-button-mva10>
  </div>
</div>
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { Subscription } from 'rxjs';

import { CommonService } from 'src/app/core/services/common.service';
import { ResizeService } from 'src/app/mva10/shared/services/resize.service';
import { MarketplaceTagging, ShopTabs } from '../../Interfaces/marketplace-enums';
import { MarketplaceService } from '../../services/marketplace.service';
import { TaggingClickDataModel } from '../../../models/tagging.model';
import { marketplaceTagging } from '../../tagging/marketplaceTagging';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sp-marketplace-tabs',
  templateUrl: './marketplace-tabs.component.html',
  styleUrls: ['./marketplace-tabs.component.scss']
})
export class MarketplaceTabsComponent implements OnInit, OnDestroy {
  isResponsive: boolean = false;
  BREAKPOINTS: typeof Breakpoints = Breakpoints;
  breakpoint: Breakpoints;
  subscriptionResize: Subscription;

  tabs: typeof ShopTabs = ShopTabs;
  tabShop: boolean = true;
  tabMarketplace: boolean = false;
  tabShopLabel: string = '';
  tabMarketplaceLabel: string = '';

  @Input() pageTagData: TaggingClickDataModel;

  constructor(
    private resizeService: ResizeService,
    public commonService: CommonService,
    public marketplaceService: MarketplaceService,
    public tagging: TaggingHelperService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.breakpoint = this.resizeService.breakpoint;
    this.setResponsiveTabs();

    this.subscriptionResize = this.resizeService.onResize$.subscribe(() => {
      this.breakpoint = this.resizeService.breakpoint;
      this.setResponsiveTabs();
      this.commonService.checkMenuResponsive(this.breakpoint);
    });
    this.getLabels();
  }

  ngOnDestroy(): void {
    this.subscriptionResize.unsubscribe();
  }

  public setResponsiveTabs(): void {
    this.isResponsive = this.breakpoint !== this.BREAKPOINTS.MOBILE;
  }

  getLabels(): void {
    this.tabShopLabel = this.translate.instant('v10.flows.screen.SHOP.tab.vodafone');
    this.tabMarketplaceLabel = this.translate.instant('v10.flows.screen.SHOP.tab.otros');
  }

  onTabClick(tab: ShopTabs): void {
    switch (tab) {
      case ShopTabs.Shop:
        this.taggingTabClickEvent(this.tabShopLabel, MarketplaceTagging.marketplace);
        if (this.marketplaceService.protectedShoppingCart) {
          this.marketplaceService.instantiateMarketplaceAlertModal(ShopTabs.Shop)
        } else {
          this.marketplaceService.tabShop = true;
          this.marketplaceService.tabMarketplace = false;
        };
        break;
      case ShopTabs.Mktplace:
        this.taggingTabClickEvent(this.tabMarketplaceLabel, MarketplaceTagging.vf);
        this.marketplaceService.getRefreshTokenUrl();
        this.marketplaceService.tabShop = false;
        this.marketplaceService.tabMarketplace = true;
    }
  }

  taggingTabClickEvent(btnLabel: string, contextLabel: string): void {
    const data: TaggingClickDataModel = Object.assign({},
      this.pageTagData,
      marketplaceTagging.marketplaceTabsTab.event,
    );
    data.event_label = data.event_label.replace('{0}', btnLabel);
    data.event_context = data.event_context.replace('{0}', contextLabel);
    this.tagging.track(data.page_name + data.event_label, data);
  }

}

import { SidemenuConfigService } from './../shared/services/sidemenu-config.service';
import { DashboardService } from './../shared/services/dashboard.service';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot } from '@angular/router';
import { SubscriptionService } from '../core/services/subscription.service';
import { CompanyService } from '../shared/services/company.service';
import { CustomerAccountService } from '../shared/services/customer-account.service';
import { CustomerBillService } from '../shared/services/customer-bill.service';

import * as constants from '../shared/constants/defines';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppService } from '../app.service';

@Injectable()
export class DashboardResolve implements Resolve<any> {

  constructor(public customerAccount: CustomerAccountService,
    private appService: AppService,
    private customerBillService: CustomerBillService,
    private subscription: SubscriptionService, private company: CompanyService, private dashboard: DashboardService,
    private menuService: SidemenuConfigService,
    private tagging: TaggingHelperService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.subscription.customerData.currentService) {
      this.loadOutOfBundle(state);
    }
  }
  loadOutOfBundle(state) {
    if (!this.appService.isSMEUser()) {
      this.dashboard.getBalanceForService(constants.BundleType.outOfPlan).subscribe(() => {
        this.dashboard.errorFlag = false;
        this.dashboard.loadBundle = true;
      }, error => {
        // Set cross variable error values
        this.dashboard.errorFlag = true;
        this.dashboard.loadBundle = true;
      });
    }
    this.customerBillService.GetCustomerBill().subscribe(() => {
      this.dashboard.dateError = false;
    },
      error => {
        this.dashboard.dateError = true;
      });
  }
}

<div class="chat-pass row change-padding cont-height">
    <div class="col-xs-12">
      <!-- Without Mobility -->
        <div *ngIf="!Bundle.mobility" class="reamin-usage row change-padding">
            <div class="remain" [ngClass]="Bundle.IsUnLimited || Bundle.TotalConsumption&&Bundle.ConsumedConsumption!=null?'col-xs-7':'col-xs-12'">
                <span class="bundle-name-lines">
                    <span *ngIf="!Bundle.title">{{Bundle.Name}}&nbsp;</span>
                    <span *ngIf="Bundle.title">{{Bundle.title}}&nbsp;</span>
                    <span *ngIf="Bundle.title">{{Bundle.titleLimit}} {{Bundle.titleRate}}</span>
                </span>
                <p class="call-price active-unlimated">
                    <span *ngIf="parent.tariffService.Tariff.isPrepaidCommunity && parent.isBenifitsActive && parent.isPrePaid" class="active-package">{{'v10.productsServices.prepaidCommunity.benefits.unlimitedMinutes'|translate}}</span>
                </p>
            </div>
            <div class="col-xs-5 usage" *ngIf="!Bundle.IsUnLimited && Bundle.TotalConsumption&&Bundle.ConsumedConsumption!=null ">
                <sp-landing-percentage title="{{'productsServices.itemsList.psRemianing.body'|translate}}" unit="{{'dashboard.contentList.guage.Mins'|translate}}"
                    [remaining]="Bundle.RemainConsumptionMinutes | dot" [percentage]="Bundle.RemainingConsumptionPercentage"></sp-landing-percentage>
            </div>
            <div class="col-xs-5 usage" *ngIf="Bundle.IsUnLimited">
                <sp-landing-percentage [percentage]="100"></sp-landing-percentage>
            </div>
        </div>
        <!-- With Mobility -->
        <div *ngIf="Bundle.mobility" class="reamin-usage row change-padding">
            <div class="remain" [ngClass]="Bundle.mobility.IsUnLimited || Bundle.TotalConsumption&&Bundle.ConsumedConsumption!=null?'col-xs-7':'col-xs-12'">
                <span class="bundle-name-lines">
                    <span *ngIf="Bundle.mobility.name">{{Bundle.mobility.name}}&nbsp;</span>
                    <span *ngIf="!Bundle.mobility.name">{{Bundle.mobility.title}}&nbsp;</span>
                    <span *ngIf="!Bundle.mobility.name">{{Bundle.titleLimit}} {{Bundle.titleRate}}</span>
                </span>
                <p class="call-price active-unlimated">
                    <span *ngIf="parent.tariffService.Tariff.isPrepaidCommunity && parent.isBenifitsActive && parent.isPrePaid" class="active-package">{{'v10.productsServices.prepaidCommunity.benefits.unlimitedMinutes'|translate}}</span>
                </p>
            </div>
            <div class="col-xs-5 usage" *ngIf="!Bundle.mobility.IsUnLimited && Bundle.mobility.TotalConsumption&&Bundle.mobility.ConsumedConsumption!=null ">
                <sp-landing-percentage title="{{'productsServices.itemsList.psRemianing.body'|translate}}" unit="{{'dashboard.contentList.guage.Mins'|translate}}"
                    [remaining]="Bundle.mobility.RemainConsumptionMinutes | dot" [percentage]="Bundle.mobility.RemainingConsumptionPercentage"></sp-landing-percentage>
            </div>
            <div class="col-xs-5 usage" *ngIf="Bundle.mobility.IsUnLimited">
                <sp-landing-percentage [percentage]="100"></sp-landing-percentage>
            </div>
        </div>
        <div class="call-info" *ngIf="Bundle.PayPerUse && Bundle.CallSetup">
            <!-- pay per use b true w mintues -->
            <p class="call-price">
                <span class="package">
                        + {{'productsServices.prepaidPlan.itemsList.planEstablish.body'|translate}}
                </span>

            </p>
        </div>
    </div>
</div>

import { Injectable, NgZone } from '@angular/core';
import { PageTaggingModel } from '../../models/new-tagging-page.model';
import { InteractionTaggingModel, JsonFilesModel } from '../../models/new-tagging-Interaction.model';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorLocation, ErrorTaggingModel } from '../../models/new-tagging-error.model';
import { StorageService } from './storage.service';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { Observable, of } from 'rxjs';
import { NewTaggingJsonModel } from '../../../app/models/new-tagging-json-model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as constants from '../../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { environment } from '../../../environments/environment';
import { JourneyTaggingModel } from '../../models/journey-tagging.model';


@Injectable()
export class NewTaggingHelperService {
    jsonFilesArray: JsonFilesModel[] = new Array<JsonFilesModel>();
    taggingBaseUrl: string = environment.imageBaseUrl;
    constructor(private taggingHelper: TaggingHelperService,
        private storageService: StorageService,
        private httpClient: HttpClient,
        private _ngZone: NgZone
        ) {
          this.initHotJarLibrary();
         }

    /** new tagging interaction
     * send the data with InteractionTaggingModel specifications
     * send page variable of the interaction
     * any more variable out of InteractionTaggingModel you need to send specificVariables
     */
     public interaction(data: InteractionTaggingModel, pageVariables: PageTaggingModel, specificVariables?: any): void {
        if (data.journey) {
            const customerType: string = this.storageService.userProfile ? this.storageService.userProfile.customerType : '';
            data.journey.journey_category =
                this.taggingHelper.getUserType(customerType, ClientTypology) || ClientTypology.postpaid;
        }
        data.journey = { ...new JourneyTaggingModel(), ...(data.journey || {}) };
        // when track interaction dont send the journy object with the page Variable
        if (pageVariables && pageVariables['journey']) {
             delete pageVariables['journey']
        }
        // if page has an otherVarible sent in custom_map object then it will be dedected and send here
        if (data['custom_map']) {
            specificVariables = {...data['custom_map'], ...specificVariables }
            delete data['custom_map'];
        }
        const eventName: string = `${data.event.event_category}:${data.event.event_context}:${data.event.event_label}`;
        this.taggingHelper.track(eventName, data, pageVariables, specificVariables);
    }
    /** new tagging state send the data with PageTaggingModel specifications
    * send page variable of the view
    * any more variable out of PageTaggingModel you need to send specificVariables
    * @param data tha page object in @type PageTaggingModel
    * @param otherVariables any Variables out the @type PageTaggingModel
    */
    public state(data: PageTaggingModel): void {
       let otherVariables: object;
        if (data.journey) {
            const customerType: string = this.storageService.userProfile ? this.storageService.userProfile.customerType : '';
            data.journey.journey_category =
                this.taggingHelper.getUserType(customerType, ClientTypology).toLowerCase()
        }
        data.journey = { ...new JourneyTaggingModel(), ...(data.journey || {}) };
        // if page has an otherVarible sent in custom_map object then it will be dedected and send here
        if (data['custom_map']) {
            otherVariables = data['custom_map']
        }
        this.taggingHelper.view(data, otherVariables);
    }


    /** this function for maping the error to a concatenate it to an error
     * item then send this concatenated list to error tagging
     */
    public creatErrorItem(errorItem: HttpErrorResponse, clearPreviousErrList?: boolean): void {
        const item: ErrorTaggingModel = {
            error_type: this.taggingHelper.setErrorTypeValue(errorItem),
            error_location: ErrorLocation.back,
            error_code: errorItem.error?.ecode || 0,
            error_module: errorItem.error?.transactionId,
            error_description: errorItem.error?.description || errorItem.error?.message || errorItem.message,
            http_status: errorItem.status,
        };
        const concatenatedErrorItem: string = this.parseErrorList(item);
        if (clearPreviousErrList) {
          this.taggingHelper.error_list = '';
        }
        this.taggingHelper.error_list = this.taggingHelper.error_list === '' ? concatenatedErrorItem : `${this.taggingHelper.error_list},${concatenatedErrorItem}`;
    };

    public parseErrorList(errorData: ErrorTaggingModel): string {
        return `${constants.taggingLiterals.error_list}`
            .replace('{error_type}', errorData.error_type || undefined)
            .replace('{error_location}', errorData.error_location || undefined)
            .replace('{error_code}', errorData.error_code?.toString() || undefined)
            .replace('{error_module}', errorData.error_module || undefined)
            .replace('{http_status}', errorData.http_status.toString() || undefined)
            .replace('{error_description}', errorData.error_description || undefined);
    }

    /** get tagging json file and data
     * @param pageKey {JsonPath} key of page object ex: $.page.pageTwo.three
     * @param jsonFileName {string} name of the file json that have object of tagging
     */

    getJsonTagging(jsonFileName: string, pageKey?: string): Observable<NewTaggingJsonModel> {
        const index: number = this.jsonFilesArray.findIndex((elem: JsonFilesModel) => elem.fileName === jsonFileName);
        let selectedTaggingJson: NewTaggingJsonModel;
        if (index === -1) {
            return this.httpClient.get(`${this.taggingBaseUrl}/config/analytics/${jsonFileName}.json`).pipe(
                map((res: NewTaggingJsonModel) => {
                selectedTaggingJson = res;
                this.jsonFilesArray.push({ fileName: jsonFileName, jsonDataObject: JSON.parse(JSON.stringify(res)) });
                if (pageKey) {
                    selectedTaggingJson = JsonQuery.value(selectedTaggingJson, pageKey);
                }
                return selectedTaggingJson;
            }));
        } else {
            selectedTaggingJson = JSON.parse(JSON.stringify(this.jsonFilesArray[index].jsonDataObject));
            if (pageKey) {
                selectedTaggingJson = JsonQuery.value(selectedTaggingJson, pageKey);
            }
            return of(selectedTaggingJson);
        }
    }

    private initHotJarLibrary(): void {
      (window as any).new_hj = (event: string, eventName: string) => {
          this._ngZone.runOutsideAngular(() => {
              if ((window as any).hj) {
                  (window as any).hj(event, eventName);
              } else {
                  console.warn('HotJar lib not available yet');
              }
          });
      };
  }


}

import { Component } from '@angular/core';
import { Product } from '../../../../app/models/product.model';
import { LandingComponent } from '../../../../app/products-and-services/landing/landing.component';

@Component({
  selector: 'sp-landing-prepaid-enjoymore-bundle',
  templateUrl: './landing-prepaid-enjoymore-bundle.component.html',
  styleUrls: ['./landing-prepaid-enjoymore-bundle.component.scss']
})
export class LandingPrepaidEnjoymoreBundleComponent  {
  public parent: LandingComponent
  public enjoyMoreBundles: Product[];

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate, state} from '@angular/animations';
import { expandAnimation } from '../../animations/expand.animation';

@Component({
  selector: 'sp-expandable-chevron',
  templateUrl: './expandable-chevron.component.html',
  styleUrls: ['./expandable-chevron.component.scss'],
  animations: [
    expandAnimation,
    trigger('rotateAnimation', [
      state('0', style({ transform: 'rotate(0)' })),
      state('1', style({ transform: 'rotate(180deg)' })),
      transition('1 => 0', animate('0.3s ease')),
      transition('0 => 1', animate('0.3s ease'))
    ])
  ]
})
export class ExpandableChevronComponent implements OnInit {
  @Input() expanded: boolean;
  @Input() leftIcon: string;
  @Input() hideChevron: boolean;
  @Output() headerClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    this.expanded = false;
  }

  onHeaderClick(): void {
    this.expanded = !this.expanded;
    this.headerClick.emit(this.expanded);
  }
}

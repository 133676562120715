import { tagging } from './../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { BillingService } from '../../../billing/billing.service';
import * as constants from '../../../shared/constants/defines';

import { StorageService } from '../../../core/services/storage.service';
import { CustomerAccountService } from '../../../shared/services/customer-account.service';
import { CompanyService } from '../../../shared/services/company.service';
import {
  SelectorModel, CardImageSelectorModel
} from '@mva10/mva10-angular';
import { journeyTags, TYPEUSER } from './../../../shared/constants/defines';
import { Subscription } from '../../../models/billing.model';
import { BundleType, BundleTypeCurrentConsumptionCards as BundleTypeCurrentConsumptionCards } from '../../../shared/enums/bundleType.enum';
import { ServiceType } from '../../../shared/enums/serviceType.enum';
import { CurrentConsumptionData, TotalizedCurrentConsumption } from '../../../models/billing-current-consumption.model';
declare var $;
import { ServiceModel } from './../../../models/service.model';
import { SiteType } from '../../enums/siteType.enum';
import { AnimationOptions } from 'ngx-lottie';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { CustomerBillService } from '../../services/customer-bill.service';
import moment from 'moment';
import { CustomerBillModel } from './../../../models/customer-bill.model';
import { Router } from '@angular/router';
import { config } from '../../../../config/pages-config';
import { CrossVariables, TaggingViewModel } from '../../../models/tagging.model';

@Component({
  selector: 'sp-current-comsuption',
  templateUrl: './current-comsuption.component.html',
  styleUrls: ['./current-comsuption.component.scss']
})
export class CurrentComsuptionComponent implements OnInit {

  labelButtonCollapse: string;
  labelButtonExpanded: string;
  cardExpandableValueCalls: boolean = true;
  cardExpandableValueInternet: boolean = true;
  cardExpandableValueMessages: boolean = true;
  cardExpandableValueTv: boolean = true;
  typeComsuptionSelectCalls: string;
  typeComsuptionSelectMessages: string;
  typeComsuptionSelectInternet: string;
  serviceListSite: {
    [siteID: string]: ServiceModel[];
  } = {};
  selectorData: SelectorModel;
  serviceSelectComsuption: Subscription;
  loadComsuption = false;
  errorComsuption = false;
  listComsuption: CurrentConsumptionData[];
  listTotalComsuptionTypeCalls: CurrentConsumptionData[];
  listTotalComsuptionTypeInternet: CurrentConsumptionData[];
  listTotalComsuptionTypeMessages: CurrentConsumptionData[];
  listTotalComsuptionTypeTv: CurrentConsumptionData[];
  totalComsuptionTypeCalls: number = 0;
  totalComsuptionTypeInternet: number = 0;
  totalComsuptionTypeMessages: number = 0;
  totalComsuptionTypeTv: number = 0;
  taggingComsuption: any;
  labelCalls: string;
  labelData: string;
  labelMessages: string;
  labelTv: string;
  labelInternet: string;
  labelDescEmptyComsuption: string;
  labelcardExpandableValueCalls: string;
  labelcardExpandableValueInternet: string;
  labelcardExpandableValueMessages: string;
  labelcardExpandableValueTv: string;
  dataWCSComsuption: any;
  multipleSites: boolean;

  typeUser: number;
  listSubcriptionsItems: any;

  arrayPostpaid: CardImageSelectorModel[] = [];
  arrayLandline: CardImageSelectorModel[] = [];
  arrayTv: CardImageSelectorModel[] = [];
  arrayMbb: CardImageSelectorModel[] = [];

  lottieConfig: AnimationOptions;
  anim: any;

  selectedListConsumption: { [type: string]: CurrentConsumptionData[] } = {};
  totalizedInternetSumByType: TotalizedCurrentConsumption[];
  selectorStartAtIndex: number = -1;

  roamersBanner: {title: string, text: string, button: string};

  isNaN: Function = Number.isNaN;

  @Input() serviceId;
  @Input() siteId: string;
  @Input() detailToExpand: BundleType | BundleTypeCurrentConsumptionCards;
  @Input() badgeToSelect: string;
  @Output() serviceSelectComsuptionDashboard: EventEmitter<Subscription> = new EventEmitter<Subscription>();
  @Input() showSelector: boolean = true;
  @Input() showCycle: boolean = false;

  @ViewChild('cardVoice') cardVoice: ElementRef;
  @ViewChild('cardData') cardData: ElementRef;
  @ViewChild('cardSMS') cardSMS: ElementRef;
  @ViewChild('cardTV') cardTV: ElementRef;
  labelCycle: string;
  labelMonth: string;

  get constants() { return constants; };
  get bundleTypeEnum() { return BundleType; }


  constructor(
    public appTheme: AppService,
    public subscriptionData: SubscriptionService,
    private translate: TranslateService,
    private taggingHelper: TaggingHelperService,
    public tagging: TaggingHelperService,
    public billingService: BillingService,
    public utilitiesService: UtilitiesService,
    public storage: StorageService,
    public customerAccountService: CustomerAccountService,
    private companyService: CompanyService,
    public appService: AppService,
    private configurationService: ConfigurationService,
    private customerBillService: CustomerBillService,
    private router: Router,
  ) {
    this.appTheme.showFullAppLoader = false;
  }

  ngOnInit() {
    this.resetTextExpand();
    this.selectorData = new SelectorModel();
    this.selectorData.cardImageSelectorList = new Array<CardImageSelectorModel>();

    this.typeUser = this.appService.typeUser;
    this.listSubcriptionsItems = (this.appService.listSubcriptions) ? this.appService.listSubcriptions.items : [];

    this.initServicesList();

    this.customerAccountService.GetCustomerAccounts(this.companyService.selectedCompanyId, true).subscribe(() => {
      this.multipleSites = (this.storage.userProfile.sites && this.storage.userProfile.sites.length > 1
        && this.customerAccountService.isP1Sites(this.storage.userProfile) && this.customerAccountService.customerAccounts.length > 1) ||
        (this.storage.userProfile.companies && this.storage.userProfile.companies.length > 1);
      });

    this.getCycle();
    this.checkRoamers();
  }

  getCycle(): void {
    if (this.configurationService.configuration.billingFlag) {
      this.customerBillService.GetCustomerBill().subscribe(() => {
        const userBill: CustomerBillModel = this.customerBillService.userBill;
        this.labelCycle = `${moment(new Date(userBill.startDate)).format('DD MMM')
          .replace('.', '')} - ${moment(new Date()).format('DD MMM').replace('.', '')}`;
        this.labelMonth = userBill.endDate.getDate() >= 14 ?
          this.utilitiesService.getMonthName((userBill.endDate.getMonth() + 1).toString()) :
          this.utilitiesService.getMonthName((userBill.startDate.getMonth() + 1).toString());
      });
    }
  }

  async initServicesList(): Promise<void> {
    const customerAccountId: string =
      this.customerAccountService.customerAccount?.id ||
      this.subscriptionData.customerData?.customerAccountsId;
    this.serviceListSite[customerAccountId] = this.subscriptionData.serviceListSite;
    this.siteId = this.siteId || customerAccountId;

    if (!this.serviceListSite[this.siteId]) {
      this.serviceListSite[this.siteId] = await this.subscriptionData
        .getCachedServicesForSite(this.siteId)
        .toPromise()
        .catch(err => (this.serviceListSite[this.siteId] = []));
    }

    this.serviceListSite[this.siteId].forEach(item => {
      this.mapServiceSelector(item, item.type);
    });

    this.orderServicesArrays();

    this.getComsuption(this.serviceId);
  }

  getComsuption(serviceId) {
    this.selectorData.cardImageSelectorList.forEach(item => item.checked = false);
    let selectFirstElement = true;
    if (serviceId) {
      this.serviceSelectComsuption = this.serviceListSite[this.siteId].find(element => element.id === serviceId);
      if (!this.serviceSelectComsuption) {
        this.serviceSelectComsuption = this.serviceListSite[this.siteId].find(element =>
          element.id === this.selectorData.cardImageSelectorList[0].id)
      }
      if (this.serviceSelectComsuption && this.selectorData.cardImageSelectorList.length > 0) {
        this.selectorData.cardImageSelectorList.find(element => element.id === this.serviceSelectComsuption.id).checked = true;
        selectFirstElement = false;
      }
    }
    if (selectFirstElement && this.selectorData.cardImageSelectorList.length > 0) {
      this.serviceSelectComsuption = this.serviceListSite[this.siteId].find(element =>
        element.id === this.selectorData.cardImageSelectorList[0].id)
      this.selectorData.cardImageSelectorList[0].checked = true;
    }
    this.serviceSelectComsuptionDashboard.emit(this.serviceSelectComsuption);

    this.selectorStartAtIndex = this.selectorData.cardImageSelectorList.findIndex(el => el.checked);
    this.selectorStartAtIndex = this.selectorStartAtIndex < 0 ? 0 : this.selectorStartAtIndex;

    this.getTranslateTextComsuption();

    this.errorComsuption = false;
    this.loadComsuption = false;

    this.billingService.getCurrentConsumption(this.siteId,
      this.serviceSelectComsuption?.id).subscribe(items => {
        this.listComsuption = items;
        this.checkTotalComsuptionType();
        this.sendViewInitComsuption();

        this.loadComsuption = true;
        this.initSelection();
      }, error => {
        this.errorComsuption = true;
        this.loadComsuption = true;
        this.sendViewInitComsuptionError();
      });
  }

  /**
   * Function to expand an specific detail card (voice/data/sms/tv) and select a specific
   * segment (all/included/oob) based on detailToExpand and badgeToSelect inputs
   */
  initSelection() {
    if (this.detailToExpand) {
      setTimeout(() => {
        switch (this.detailToExpand) {
          case BundleTypeCurrentConsumptionCards.NonZero:
            this.expandNonZeroCardConsumption(this.badgeToSelect);
            break;

          case BundleTypeCurrentConsumptionCards.OneOobTypeOnly:
            this.expandIfOnlyOneOobConsumptionType(this.badgeToSelect);
            break;

          default:
            this.clickExpandCardConsumption(false, this.detailToExpand, true);
            if (this.badgeToSelect) {
              this.changeSelectComsuption(this.badgeToSelect, this.detailToExpand, true);
            }
            break;
        }
        this.detailToExpand = null;
        this.badgeToSelect = null;
      }, 500);
    }
  }

  getTranslateTextComsuption() {
    this.translate.get('v10.billing').subscribe(data => {
      this.labelButtonCollapse = data.comsuption.openExpand;
      this.labelButtonExpanded = data.comsuption.closeExpand;
      this.labelCalls = data.comsuption.labelCalls;
      this.labelData = data.comsuption.labelData;
      this.labelMessages = data.comsuption.labelSms;
      this.labelTv = data.comsuption.labelTv;
      this.labelInternet = data.comsuption.labelInternet;
      this.labelDescEmptyComsuption = data.comsuption.descEmpty;
      this.dataWCSComsuption = data.comsuption;
      this.labelcardExpandableValueCalls = this.labelButtonCollapse;
      this.labelcardExpandableValueInternet = this.labelButtonCollapse;
      this.labelcardExpandableValueMessages = this.labelButtonCollapse;
      this.labelcardExpandableValueTv = this.labelButtonCollapse;
      this.lottieConfig = {
        path: this.appTheme.getImgFullPath(data.comsuption.iconError),
        autoplay: true,
        loop: true
      };
    });
  }

  sendViewInitComsuption() {
    switch (this.serviceSelectComsuption.type.trim().toLowerCase()) {
      case ServiceType.Mobile.toLowerCase().trim():
      case ServiceType.Postpaid.toLowerCase().trim():
        tagging.current_comsuption.postpaid.view.client_billing_id = this.multipleSites ? SiteType.Multisite : SiteType.Monosite;
        this.taggingHelper.view(null, tagging.current_comsuption.postpaid.view);
        this.taggingComsuption = tagging.current_comsuption.postpaid;
        break;
      case ServiceType.MbbPostpaid.toLowerCase().trim():
        tagging.current_comsuption.mbb.view.client_billing_id = this.multipleSites ? SiteType.Multisite : SiteType.Monosite;
        this.taggingHelper.view(null, tagging.current_comsuption.mbb.view);
        this.taggingComsuption = tagging.current_comsuption.mbb;
        break;
      case ServiceType.Landline.toLowerCase().trim():
        tagging.current_comsuption.landline.view.client_billing_id = this.multipleSites ? SiteType.Multisite : SiteType.Monosite;
        this.taggingHelper.view(null, tagging.current_comsuption.landline.view);
        this.taggingComsuption = tagging.current_comsuption.landline;
        break;
      case ServiceType.VodafoneEnTuCasa.toLowerCase().trim():
        tagging.current_comsuption.homezone.view.client_billing_id = this.multipleSites ? SiteType.Multisite : SiteType.Monosite;
        this.taggingHelper.view(null, tagging.current_comsuption.homezone.view);
        this.taggingComsuption = tagging.current_comsuption.homezone;
        break;
      case ServiceType.Tv.toLowerCase().trim():
      case ServiceType.TvOnline.toLowerCase().trim():
        tagging.current_comsuption.tv.view.client_billing_id = this.multipleSites ? SiteType.Multisite : SiteType.Monosite;
        this.taggingHelper.view(null, tagging.current_comsuption.tv.view);
        this.taggingComsuption = tagging.current_comsuption.tv;
        break;
    }
  }


  sendViewInitComsuptionError() {
    let view;
    switch (this.serviceSelectComsuption.type.trim().toLowerCase()) {
      case ServiceType.Mobile.toLowerCase().trim():
      case ServiceType.Postpaid.toLowerCase().trim():
        view = Object.assign({}, tagging.current_comsuption.postpaid.view);
        break;
      case ServiceType.MbbPostpaid.toLowerCase().trim():
        view = Object.assign({}, tagging.current_comsuption.mbb.view);
        break;
      case ServiceType.Landline.toLowerCase().trim():
        view = Object.assign({}, tagging.current_comsuption.landline.view);
        break;
      case ServiceType.VodafoneEnTuCasa.toLowerCase().trim():
        view = Object.assign({}, tagging.current_comsuption.homezone.view);
        break;
      case ServiceType.Tv.toLowerCase().trim():
      case ServiceType.TvOnline.toLowerCase().trim():
        view = Object.assign({}, tagging.current_comsuption.tv.view);
        break;
    }
    view.error_category = tagging.current_comsuption.labels.server_error;
    view.error_descripcion = tagging.current_comsuption.labels.server_error_desc;
    this.taggingHelper.view(null, view);
  }

  clickExpandCardConsumption(isClosingCard: boolean, type: BundleType, skipTagging = false) {
    const page = Object.assign({}, this.taggingComsuption.view);
    const animationDelay = 300;

    // Set variables
    let expandCardId = '';
    let cardElement: ElementRef;
    let taggingData;
    switch (type) {
      case BundleType.Voice:
        expandCardId = 'cardExpandCallsId';
        cardElement = this.cardVoice;
        taggingData = this.taggingComsuption.clickCalls;
        this.cardExpandableValueCalls = isClosingCard;
        this.labelcardExpandableValueCalls = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;

      case BundleType.Data:
        expandCardId = 'cardExpandInternetId';
        cardElement = this.cardData;
        taggingData = this.taggingComsuption.clickInternet;
        this.cardExpandableValueInternet = isClosingCard;
        this.labelcardExpandableValueInternet = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;

      case BundleType.SMS:
        expandCardId = 'cardExpandMessagesId';
        cardElement = this.cardSMS;
        taggingData = this.taggingComsuption.clickMessages;
        this.cardExpandableValueMessages = isClosingCard;
        this.labelcardExpandableValueMessages = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;

      case BundleType.TV:
        expandCardId = 'cardExpandTvId';
        cardElement = this.cardTV;
        taggingData = this.taggingComsuption.clickTV;
        this.cardExpandableValueTv = isClosingCard;
        this.labelcardExpandableValueTv = isClosingCard ? this.labelButtonCollapse : this.labelButtonExpanded;
        break;
    }

    // Perform actions
    if (isClosingCard) {
      $('#' + expandCardId).slideUp(animationDelay);
    } else {
      $('#' + expandCardId).slideDown(animationDelay);
      setTimeout(() => cardElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' }), animationDelay);
      if (!skipTagging) {
        const trackData = Object.assign(page, taggingData.data);
        this.taggingHelper.track(taggingData.eventName, trackData);
      }
      // Opening: select badge to show all values
      this.changeSelectComsuption(constants.typeTabsComsuption.todo, type, true);
    }
  }

  expandNonZeroCardConsumption(badgeToSelect?: string) {
    // In reverse order (TV, Messages, Internet, Calls) to make the scrollIntoView correct
    if (this.checkCardsConsumption(BundleType.TV) && this.totalComsuptionTypeTv > 0) {
      this.clickExpandCardConsumption(false, BundleType.TV, true);
      if (badgeToSelect) {
        this.changeSelectComsuption(badgeToSelect, BundleType.TV, true);
      }
    }
    if (this.checkCardsConsumption(BundleType.SMS) && this.totalComsuptionTypeMessages > 0) {
      this.clickExpandCardConsumption(false, BundleType.SMS, true);
      if (badgeToSelect) {
        this.changeSelectComsuption(badgeToSelect, BundleType.SMS, true);
      }
    }
    if (this.checkCardsConsumption(BundleType.Data) && this.totalComsuptionTypeInternet > 0) {
      this.clickExpandCardConsumption(false, BundleType.Data, true);
      if (badgeToSelect) {
        this.changeSelectComsuption(badgeToSelect, BundleType.Data, true);
      }
    }
    if (this.checkCardsConsumption(BundleType.Voice) && this.totalComsuptionTypeCalls > 0) {
      this.clickExpandCardConsumption(false, BundleType.Voice, true);
      if (badgeToSelect) {
        this.changeSelectComsuption(badgeToSelect, BundleType.Voice, true);
      }
    }
  }

  expandIfOnlyOneOobConsumptionType(badgeToSelect?: string) {
    let total = 0;
    let type: BundleType;
    if (this.checkCardsConsumption(BundleType.Voice) && this.totalComsuptionTypeCalls > 0) {
      total++;
      type = BundleType.Voice;
    }
    if (this.checkCardsConsumption(BundleType.Data) && this.totalComsuptionTypeInternet > 0) {
      total++;
      type = BundleType.Data;
    }
    if (this.checkCardsConsumption(BundleType.SMS) && this.totalComsuptionTypeMessages > 0) {
      total++;
      type = BundleType.SMS;
    }
    if (this.checkCardsConsumption(BundleType.TV) && this.totalComsuptionTypeTv > 0) {
      total++;
      type = BundleType.TV;
    }
    if (total === 1) {
      this.clickExpandCardConsumption(false, type, true);
      if (badgeToSelect) {
        this.changeSelectComsuption(badgeToSelect, type, true);
      }
    }
  }

  changeSelectComsuption(value: string, type: BundleType, skipTagging = false) {
    if (type === BundleType.Voice) {
      this.typeComsuptionSelectCalls = value;
    } else if (type === BundleType.Data) {
      this.typeComsuptionSelectInternet = value;
    } else if (type === BundleType.SMS) {
      this.typeComsuptionSelectMessages = value;
    }

    this.listComsuptionFunction(type);

    if (!skipTagging) {
      const page = Object.assign({}, this.taggingComsuption.view);
      let trackData;
      switch (value) {
        case constants.typeTabsComsuption.todo:
          trackData = Object.assign(page, this.taggingComsuption.clickTodo.data);
          this.taggingHelper.track(this.taggingComsuption.clickIncluido.eventName, trackData);
          break;
        case constants.typeTabsComsuption.incluido:
          trackData = Object.assign(page, this.taggingComsuption.clickIncluido.data);
          this.taggingHelper.track(this.taggingComsuption.clickFuera.eventName, trackData);
          break;
        case constants.typeTabsComsuption.fuera:
          trackData = Object.assign(page, this.taggingComsuption.clickFuera.data);
          this.taggingHelper.track(this.taggingComsuption.clickTodo.eventName, trackData);
          break;
      }
    }
  }

  resetTextExpand() {
    this.cardExpandableValueCalls = true;
    this.cardExpandableValueInternet = true;
    this.cardExpandableValueMessages = true;
    this.cardExpandableValueTv = true;
    this.labelcardExpandableValueCalls = this.labelButtonCollapse;
    this.labelcardExpandableValueInternet = this.labelButtonCollapse;
    this.labelcardExpandableValueMessages = this.labelButtonCollapse;
    this.labelcardExpandableValueTv = this.labelButtonCollapse;
    this.typeComsuptionSelectCalls = constants.typeTabsComsuption.todo;
    this.typeComsuptionSelectInternet = constants.typeTabsComsuption.todo;
    this.typeComsuptionSelectMessages = constants.typeTabsComsuption.todo;
  }

  closeExpandCards() {
    $('#cardExpandCallsId').slideUp(0);
    $('#cardExpandInternetId').slideUp(0);
    $('#cardExpandMessagesId').slideUp(0);
    $('#cardExpandTvId').slideUp(0);
  }

  selectorCardServiceClicked(id, event) {
    this.serviceSelectComsuption = this.serviceListSite[this.siteId].find(element => element.id === id);
    this.serviceSelectComsuptionDashboard.emit(this.serviceSelectComsuption);
    const found: any = this.listSubcriptionsItems.find(element => element.id === id);

    this.typeUser = TYPEUSER.zero;

    if (found) {
      if (found.digital && found.parts[0].customerAccounts[0].digital) {
        this.typeUser = TYPEUSER.one;
      } else if (!found.digital && found.parts[0].customerAccounts[0].digital) {
        this.typeUser = TYPEUSER.two;
      } else if (found.digital) {
        this.typeUser = TYPEUSER.three;
      }
    }

    this.selectorData.cardImageSelectorList.forEach(item => {
      item.checked = false;
      if (item.id === id) {
        item.checked = true;
      }
    });

    this.closeExpandCards();
    this.resetTextExpand();

    this.loadComsuption = false;
    this.errorComsuption = false;

    this.billingService.getCurrentConsumption(this.siteId,
      this.serviceSelectComsuption.id).subscribe(items => {
        this.listComsuption = items;
        this.checkTotalComsuptionType();
        this.sendViewInitComsuption();

        this.loadComsuption = true;
      }, error => {
        this.errorComsuption = true;
        this.loadComsuption = true;
        this.sendViewInitComsuptionError();
      });

    this.checkRoamers();
  }

  checkCardsConsumption(type) {
    let result;
    if (this.serviceSelectComsuption) {
      switch (this.serviceSelectComsuption.type.trim().toLowerCase()) {
        case ServiceType.Mobile.toLowerCase().trim():
        case ServiceType.Postpaid.toLowerCase().trim():
          result = type !== BundleType.TV;
          break;
        case ServiceType.MbbPostpaid.toLowerCase().trim():
          result = type === BundleType.Data;
          break;
        case ServiceType.Landline.toLowerCase().trim():
          result = type === BundleType.Voice;
          break;
        case ServiceType.VodafoneEnTuCasa.toLowerCase().trim():
          result = type !== BundleType.TV;
          break;
        case ServiceType.Tv.toLowerCase().trim():
        case ServiceType.TvOnline.toLowerCase().trim():
          result = type === BundleType.TV;
          break;
        default:
          result = true;
      }
      return result;
    }
  }

  mapServiceSelector(item: ServiceModel, type: string) {
    const itemImageCard: any = this.customerAccountService.mapServiceSelector(item, type);
    switch (type.toLowerCase().trim()) {
      case ServiceType.Mobile.toLowerCase().trim():
      case ServiceType.Postpaid.toLowerCase().trim():
        this.arrayPostpaid.push(itemImageCard);
        break;
      case ServiceType.MbbPostpaid.toLowerCase().trim():
        this.arrayMbb.push(itemImageCard);
        break;
      case ServiceType.Landline.toLowerCase().trim():
      case ServiceType.VodafoneEnTuCasa.toLowerCase().trim():
        this.arrayLandline.push(itemImageCard);
        break;
      case ServiceType.Tv.toLowerCase().trim():
      case ServiceType.TvOnline.toLowerCase().trim():
        this.arrayTv.push(itemImageCard);
        break;
    }
  }

  orderServicesArrays() {
    this.selectorData.cardImageSelectorList.push(...this.arrayPostpaid.slice(), ...this.arrayLandline.slice(),
      ...this.arrayTv.slice(), ...this.arrayMbb.slice());
  }

  listComsuptionFunction(type: BundleType) {
    let list = [];
    if (type === BundleType.Voice) {
      switch (this.typeComsuptionSelectCalls) {
        case constants.typeTabsComsuption.todo:
          list = this.listTotalComsuptionTypeCalls;
          break;
        case constants.typeTabsComsuption.incluido:
          list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.Voice && !(comsuption.amount > 0));
          break;
        case constants.typeTabsComsuption.fuera:
          list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.Voice && comsuption.amount > 0);
          break;
      }
    } else if (type === BundleType.Data) {
      switch (this.typeComsuptionSelectInternet) {
        case constants.typeTabsComsuption.todo:
          list = this.listTotalComsuptionTypeInternet;
          break;
        case constants.typeTabsComsuption.incluido:
          list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.Data && !(comsuption.amount > 0));
          break;
        case constants.typeTabsComsuption.fuera:
          list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.Data && comsuption.amount > 0);
          break;
      }
      this.calculateTotalizedDataConsumption(list);
    } else if (type === BundleType.SMS) {
      switch (this.typeComsuptionSelectMessages) {
        case constants.typeTabsComsuption.todo:
          list = this.listTotalComsuptionTypeMessages;
          break;
        case constants.typeTabsComsuption.incluido:
          list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.SMS && !(comsuption.amount > 0));
          break;
        case constants.typeTabsComsuption.fuera:
          list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.SMS && comsuption.amount > 0);
          break;
      }
    } else if (type === BundleType.TV) {
      list = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.TV);
    }

    this.selectedListConsumption[type] = list;
  }

  updateTotalConsumption(type: BundleType, list?: CurrentConsumptionData[]) {
    switch (type) {
      case BundleType.Voice:
        this.totalComsuptionTypeCalls = (list || this.listTotalComsuptionTypeCalls).reduce((result, item) => result + item.amount, 0);
        break;
      case BundleType.Data:
        this.totalComsuptionTypeInternet = (list || this.listTotalComsuptionTypeInternet).reduce((result, item) => result + item.amount, 0);
        break;
      case BundleType.SMS:
        this.totalComsuptionTypeMessages = (list || this.listTotalComsuptionTypeMessages).reduce((result, item) => result + item.amount, 0);
        break;
      case BundleType.TV:
        this.totalComsuptionTypeTv = (list || this.listTotalComsuptionTypeTv).reduce((result, item) => result + item.amount, 0);
        break;
    }
  }

  checkTotalComsuptionType() {
    this.listTotalComsuptionTypeCalls = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.Voice);
    this.listTotalComsuptionTypeInternet = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.Data);
    this.listTotalComsuptionTypeMessages = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.SMS);
    this.listTotalComsuptionTypeTv = this.listComsuption.filter(comsuption => comsuption.type.toLowerCase() === BundleType.TV);

    this.updateTotalConsumption(BundleType.Voice);
    this.updateTotalConsumption(BundleType.Data);
    this.updateTotalConsumption(BundleType.SMS);
    this.updateTotalConsumption(BundleType.TV);

    this.listComsuptionFunction(BundleType.Voice);
    this.listComsuptionFunction(BundleType.Data);
    this.listComsuptionFunction(BundleType.SMS);
    this.listComsuptionFunction(BundleType.TV);
  }


  getDateComsuption(date) {
    const dateFormat = this.utilitiesService.getDateFormatted(date).split('/');
    const monthName = this.utilitiesService.getMonthName(dateFormat[1], false);

    return `${dateFormat[0]} de ${monthName}, ${this.utilitiesService.getTime(date)}`;
  }

  separatorDay(comsuption: CurrentConsumptionData, comsuptionBefore: CurrentConsumptionData) {
    let aux = false;
    if (comsuptionBefore) {
      const date = this.utilitiesService.getDateFormatted(comsuption.timePeriod.startDate).split('/');
      const dateBefore = this.utilitiesService.getDateFormatted(comsuptionBefore.timePeriod.startDate).split('/');
      if (
        date &&
        dateBefore &&
        date.length > 2 &&
        dateBefore.length > 2 &&
        (new Date(+date[2], +date[1] - 1, +date[0]) < new Date(+dateBefore[2], +dateBefore[1] - 1, +dateBefore[0]) ||
          new Date(+date[2], +date[1] - 1, +date[0]) > new Date(+dateBefore[2], +dateBefore[1] - 1, +dateBefore[0]))
      ) {
        aux = true;
      }
    } else {
      aux = true;
    }
    return aux;
  }

  separatorDayText(comsuptionDate) {
    const date = this.utilitiesService.getDateFormatted(comsuptionDate).split('/');
    const monthName = this.utilitiesService.getMonthName(date[1], false);

    return `${this.utilitiesService.getDayName(this.utilitiesService.parseDate(comsuptionDate, '-').getDay()).toUpperCase()}, ${date[0]} DE
     ${monthName.toUpperCase()}`;
  }

  getLabelsComsuptionEmpty(select) {
    const text = [];
    switch (select) {
      case constants.typeTabsComsuption.incluido:
        text.push(this.dataWCSComsuption.titleEmptyInclude);
        text.push(this.dataWCSComsuption.descEmptyInclude);
        break;
      case constants.typeTabsComsuption.fuera:
        text.push(this.dataWCSComsuption.titleEmptyExtra);
        text.push(this.dataWCSComsuption.descEmptyExtra);
        break;
    }

    return text;
  }

  getLabelEmptyComsuptionType(type) {
    let label;
    if (type === BundleType.Voice) {
      label = this.labelDescEmptyComsuption.replace('{0}', this.labelCalls);
    } else if (type === BundleType.Data) {
      label = this.labelDescEmptyComsuption.replace('{0}', this.labelData);
    } else if (type === BundleType.SMS) {
      label = this.labelDescEmptyComsuption.replace('{0}', this.labelMessages);
    } else if (type === BundleType.TV) {
      label = this.labelDescEmptyComsuption.replace('{0}', this.labelTv);
    }
    return label;
  }

  getDestination(item: CurrentConsumptionData, type: BundleType) {
    if (type === BundleType.Data) {
      if (!isNaN(item.quantity)) {
        // Consumo sin facturar
        return item.destination;
      } else {
        // Consumo facturado
        return this.utilitiesService.formatBytes(+item.destination, item.unit, ' ');
      }
    } else {
      return item.destination;
    }
  }

  getDataConsumption(item: CurrentConsumptionData): string {
    if (!isNaN(item.quantity)) {
      // Consumo sin facturar
      return this.utilitiesService.formatBytes(item.quantity, item.unit, ' ');
    } else {
      // Consumo facturado
      return '';
    }
  }

  getDuration(duration) {
    if (duration) {
      const seconds = duration;
      const munites = seconds > 60 ? (seconds / 60) : 0;
      return `${Math.trunc(munites)}m ${seconds % 60}s`;
    } else {
      return '';
    }
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  calculateTotalizedDataConsumption(list: CurrentConsumptionData[]) {
    this.totalizedInternetSumByType = (list || [])
      .reduce((result: TotalizedCurrentConsumption[], item) => {
        const destIndex = result.findIndex(e => e.description === item.destination);
        if (destIndex < 0) {
          // Destination does not exist in reduced array, push it
          result.push({
            description: item.destination,
            totalPrice: item.amount || 0,
            totalAmount: this.utilitiesService.getQuantityInKiloBytes(item.quantity, item.unit),
            amountUnits: 'KB'
          });
          return result;
        }
        // Destination exists, sum totalAmount
        result[destIndex].totalPrice += item.amount || 0;
        result[destIndex].totalAmount += this.utilitiesService.getQuantityInKiloBytes(item.quantity, item.unit);
        return result;
      }, [])
      .sort((a, b) => b.totalAmount - a.totalAmount)
      .map(res => {
        // Format total amount into MB or GB
        return {
          ...res,
          ...{
            description: res.description,
            formattedTotal: this.utilitiesService.formatBytes(res.totalAmount, res.amountUnits, ' ')
          }
        };
      });
  }

  private checkRoamers(): void {
    const roamers: BundleType[] = this.subscriptionData
      .cachedRoamers[this.subscriptionData.customerData.customerAccountsId][this.serviceSelectComsuption?.id] || [];
    if (roamers.length) {
      const sortingBundles: BundleType[] = [BundleType.Data, BundleType.Voice, BundleType.SMS];
      const wcsKey: string = roamers.sort((a, b) => sortingBundles.indexOf(a) - sortingBundles.indexOf(b)).join('');
      this.roamersBanner = {
        title: this.translate.instant(`v10.billing.roamers.${wcsKey}.title`),
        text: this.translate.instant(`v10.billing.roamers.${wcsKey}.text`),
        button: this.translate.instant(`v10.billing.roamers.${wcsKey}.button`)
      };
    } else {
      this.roamersBanner = undefined;
    }
  }

  public goToRoaming(): void {
    const page: TaggingViewModel = Object.assign({}, tagging.current_comsuption.postpaid.view);
    const journey: TaggingViewModel = {};
    journey.journey_category = CrossVariables.client_typology;
    journey.journey_subcategory = journeyTags.pospaid;
    journey.journey_type = tagging.icomingRoamingActivationJourney.firstStep.data.journey_type;
    const event: TaggingViewModel = {};
    event.event_name =  tagging.current_comsuption.postpaid.clickRoamingPrices.data.event_name;
    event.event_category =  tagging.faultVariables.button;
    event.event_label = this.translate.instant('v10.billing.roamers.button');
    const trackObject: TaggingViewModel = Object.assign(page, journey, event);
    this.tagging.trackReplace(`${trackObject.page_name}:${trackObject.event_name}`, trackObject, true);
    this.router.navigate([ config.roaming.route ]);
  }

}

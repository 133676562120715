import { JourneyTaggingModel } from './journey-tagging.model';
import { NewTaggingJsonModel } from './new-tagging-json-model';

export class InteractionTaggingModel {
    journey?: JourneyTaggingModel;
    event: EventModel;
    custom_map?: object;
}

export class JsonFilesModel {
    fileName: string;
    jsonDataObject: NewTaggingJsonModel;
}

export class EventModel {
    event_category: eventCategory;
    event_context: string;
    event_label: string;
}
export enum eventCategory {
    ctc = 'ctc',
    link = 'link',
    boton = 'boton',
    selector = 'selector',
    nudge = 'nudge',
    acordeon = 'acordeon',
    visualizacion = 'visualizacion',
    carrusel = 'carrusel',
    evento = 'evento'
}

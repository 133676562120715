import { MainCharacteristics } from './commercial-terminal-list.model';
import { PromoCommercial } from './promo-commercial.model';

export class CommercialDetail {
  public additionalLine: boolean;
  public agentOffer: boolean;
  public catalogOffer: string;
  public clientType: number;
  public contractType: number;
  public coverageTechnology: string;
  public crossedOfferId: string;
  public offerType?: string;
  public description: string;
  public idesc: string;
  public manualProvision: boolean;
  public name: string;
  public nameList: string;
  public numberLine: number;
  public offerId: number;
  public os: string;
  public permanenceDuration: string;
  public plusLine: boolean;
  public rateType: number;
  public rateTypeMapeo: number;
  public registerType: number;
  public registerTypeName: string;
  public sap: string;
  public sceneType: number;
  public serviceItemType: number;
  public serviceItemTypeName: string;
  public shopType: number;
  public symmetricSpeed: boolean;
  public promotions: PromoCommercial[];
  public brand: string;
  public canon: number;
  public canonTaxes: number;
  public capacity: string;
  public cash: number;
  public cashTaxes: number;
  public color: string;
  public manufacturedType: string;
  public months: number;
  public paymentType: string;
  public permanencePenalty: number;
  public permanencePenaltyTaxes: number;
  public terminalType: number;
  public promotedCanon?: boolean;
  public finalPaymentNumberMonths?: number;
  public packageImage?: PackageImage;
  public packageText?: PackageText;
  public dataSpeedList?: string;
  public associatedLine?: string;
  public asociatedTariff?: string;
  public tipoCliente?: string;
  public mainCharacteristics?: MainCharacteristics[];
  public categoryType?: string;
  public socialRateType?: number;
  public saleType?: string;
}

export class PackageImage {
  public altCallsIncludedIcon: string;
  public altDataIncludedIcon: string;
  public altImageIcon: string;
  public altOtherIcon: string;
  public altRoamingIcon: string;
  public desktopCallsIncludedIcon: string;
  public desktopDataIncludedIcon: string;
  public desktopOtherIcon: string;
  public desktopRoamingIcon: string;
  public mobileCallsIncludedIcon: string;
  public mobileDataIncludedIcon: string;
  public mobileOtherIcon: string;
  public mobileRoamingIcon: string;
  public rateImageIcon: string;
  public rateImageIconFicha: string;
  public tabletCallsIncludedIcon: string;
  public tabletDataIncludedIcon: string;
  public tabletOtherIcon: string;
  public tabletRoamingIcon: string;
}

export class PackageText {
  public callsIncludedDetailList: string;
  public callsIncludedFicha: string;
  public callsIncludedList: string;
  public callsIncludedSecondFicha: string;
  public callsIncludedSecondList: string;
  public callsList: string;
  public dataDetailFicha: string;
  public dataDetailList: string;
  public dataIncludedDetailList: string;
  public dataIncludedFicha: string;
  public dataIncludedList: string;
  public dataIncludedSecondFicha: string;
  public dataIncludedSecondList: string;
  public dataList: string;
  public dataSpeedFicha: string;
  public dataSpeedList: string;
  public dataSpeedCard: string;
  public htmlTextInfo: string;
  public htmlTextRateTable: string;
  public htmlTextTicketMobile: string;
  public otherDetailList: string;
  public otherList: string;
  public roamingList: string;
  public internetIncludedList: string;
  public internetIncludedFicha?: string;
  public internetIncludedSecondList?: string;
  public internetIncludedSecondFicha?: string;
  public longDescription?: string;
  public shortDescription?: string;
}

export class PaymentMethod {
  catalogOffer: string;
  fee: number;
  feeDescription: string;
  feeTaxes: number;
  paymentType: number;
  selectedByDefault: boolean;
}

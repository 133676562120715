
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as constants from '../../shared/constants/defines';
/** SERVICES*/
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
/** ENUM */
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { NewTaggingJsonModel } from '../../models/new-tagging-json-model';
import { ValidationErrors } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class MobileToPackExpediteService {


    // IUA
    public iua: string = '';
    public insertedIua: boolean = false;
    public insertedIuaPortability: boolean = false;
    public category: string = this.taggingHelperService
        .getUserType(this.storageService.userProfile.customerType, ClientTypology)?.toLowerCase();
    public subcategory: string =
        this.subscriptionService.customerData.SiteType?.toLowerCase() === constants.pay.postpaid_check?.toLowerCase()
            ? constants.pay.postpaid : constants.pay.prepaid;

    constructor(
        public taggingHelperService: TaggingHelperService,
        private readonly storageService: StorageService,
        private readonly subscriptionService: SubscriptionService,
        public translateService: TranslateService,
        public newTagging: NewTaggingHelperService
    ) {
    }

    public tagClickExpedite(btn: string, type: boolean): void {
        this.newTagging.getJsonTagging('mobile-to-pack/expedite').subscribe((data: NewTaggingJsonModel) => {
            data.eventList['click_expedite'].event.event_label = data.eventList['click_expedite'].event.event_label.replace(
                '<btn>', btn);
            data.eventList['click_expedite'].event.event_context = data.eventList['click_expedite'].event.event_context.replace(
                '<type>', type ? constants.iua.portability : constants.iua.notPortability);
            data.eventList['click_expedite'].journey.journey_category = this.category;
            data.eventList['click_expedite'].journey.journey_subcategory = this.subcategory;
            this.newTagging.interaction(data.eventList['click_expedite'], data.page);
        });
    }

    public tagExpediteBackdropState(): void {
        this.newTagging.getJsonTagging('mobile-to-pack/expedite').subscribe((data: NewTaggingJsonModel) => {
            data.page.stateList['expedite_backdrop'].journey.journey_category = this.category;
            data.page.stateList['expedite_backdrop'].journey.journey_subcategory = this.subcategory;
            this.newTagging.state(data.page.stateList['expedite_backdrop']);
        });
    }

    public tagClickInsert(btn: string): void {
        this.newTagging.getJsonTagging('mobile-to-pack/expedite').subscribe((data: NewTaggingJsonModel) => {
            data.eventList['click_insert'].event.event_label = data.eventList['click_insert'].event.event_label.replace(
                '<btn>', btn);
            data.eventList['click_insert'].journey.journey_category = this.category;
            data.eventList['click_insert'].journey.journey_subcategory = this.subcategory;
            this.newTagging.interaction(data.eventList['click_insert'], data.page.stateList['expedite_backdrop']);
        });
    }

    public tagClickClose(iua: string, notValidIua: ValidationErrors): void {
        this.newTagging.getJsonTagging('mobile-to-pack/expedite').subscribe((data: NewTaggingJsonModel) => {
            data.eventList['click_close'].event.event_context = iua ?
                (notValidIua ? constants.iua.notValid : constants.iua.valid) : constants.iua.empty;
            data.eventList['click_close'].journey.journey_category = this.category;
            data.eventList['click_close'].journey.journey_subcategory = this.subcategory;
            this.newTagging.interaction(data.eventList['click_close'], data.page.stateList['expedite_backdrop']);
        });
    }
}

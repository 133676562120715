export class TimeLineItemModel {
    title: string;
    descriptions: TimeLineItemDescription[];
    bulletType: BulletTypeEnum;
    fontType?: FontTypeEnum;
    redBorderToNextPoint?: boolean;
    redBorderToNextDescription?: boolean;
    constructor(title, descriptions: TimeLineItemDescription[], bulletType?, fontType?,
        redBorderToNextPoint?, redBorderToNextDescription?) {
        this.title = title || '';
        this.descriptions = descriptions || new Array<TimeLineItemDescription>();
        this.bulletType = bulletType || BulletTypeEnum.shallowCircle;
        this.fontType = fontType;
        this.redBorderToNextPoint = redBorderToNextPoint;
        this.redBorderToNextDescription = redBorderToNextDescription;
    }
}

export class TimeLineItemDescription {
    text: string;
    hasBullet: boolean;
    bulletType?: BulletTypeEnum;
    fontType?: FontTypeEnum;
    redBorderToNextDescription?: boolean;
    image?: string;
    constructor(
        text: string,
        hasBullet: boolean,
        bulletType: BulletTypeEnum,
        fontType: FontTypeEnum,
        redBorderToNextDescription: boolean,
        image: string,
    ) {
        this.text = text;
        this.hasBullet = hasBullet;
        this.bulletType = bulletType;
        this.fontType = fontType;
        this.redBorderToNextDescription = redBorderToNextDescription;
        this.image = image;
    }
}

export enum BulletTypeEnum {
    shallowCircle = 'first-circle',
    grayCircle = 'gray-circle',
    redCircle = 'red-circle',
    shallowCircleSmall = 'first-circle-small',
    redCircleSmall = 'red-circle-small'
}

export enum FontTypeEnum {
    bold = 'bold-text',
    gray = 'gray-text'
}

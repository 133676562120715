<div class="radio custom-radio" [ngClass]="{'vodafone-one':one}" *ngIf="dataSource.length" (click)="closeInfoOpenedInfoToggles($event)">
    <ul [ngClass]="{'ul-company':companyTab}">
      <li *ngIf="title" class="title">
        <div class="ul-title">{{title}}</div>
      </li>
        <li *ngFor="let data of dataSource; let i = index" (click)="onClick(data, $event)" class="sp-rb-advanced-item">
            <div class="item-content form-group change-padding">
                <span *ngIf="data.icon" class="icon solid" [ngClass]="data.icon"></span>
                <div *ngIf="onlineTvBox" class="online-tv-offer-desc">
                    <div *ngIf="data.name != noPromotionName">
                        <h4>{{'productsServices.noTv.itemsList.ntWithMob.body'|translate}} <span class="offer-service-id">{{data.serviceIds[0]}}</span></h4>
                        <h4 class="online-tv-offer-period">{{data.name}}</h4>
                        <span>({{'productsServices.noTv.itemsList.ntAfter.body' | translate}} {{data.fee}}{{data.feeUnit}})</span>
                    </div>
                    <div *ngIf="data.name == noPromotionName">
                        <h4 class="online-tv-no-offer-text">{{'productsServices.noTv.itemsList.ntContract.body' | translate}}</h4>
                        <h4 class="online-tv-no-offer-text">{{data.fee}}{{data.feeUnit}}</h4>
                    </div>


                </div>
                <input *ngIf="!onlineTvBox" type="radio" id="{{data.id}}" [name]="groupName" [value]="data.value" (change)="updateForm(data.value)" [checked]="checked(data.value)">
                <input *ngIf="onlineTvBox" type="radio" id="{{data.id}}" [name]="groupName" [value]="data" (change)="updateForm(data)" [checked]="checked(data)">

                <P *ngIf="data.text" [ngClass]="{'textStyle':newClass,'number-item ':!newClass,'vodfone-one-alignment':one}">{{data.text}}</P>
                <label class="block" for="{{data.id}}" *ngIf="data.label && !isChangeSecurity" [ngClass]="{'vf1-label':!data.text&&one}">
                  <span>{{data.label}} </span>
                </label>
                <label class="block"  *ngIf="data.label && isChangeSecurity" [ngClass]="{'vf1-label':!data.text&&one}">
                  <div  class="seurity-text" >
                    <b>{{data.label}}</b>
                    <span *ngIf="data.isRecommended" >{{'productsServices.MiWifi.messagesList.MiRecommandadaMsg.MiRecommandadaMsg_description' | translate}}</span>
                    <div id="info-label" (click)="toggleSlideInfo($event,i)" #infoToggle><i class="icon icon-info-circle" (click)="toggleSlideInfo($event,i)"></i>{{'productsServices.MiWifi.messagesList.MiExtraInfoMsg.MiExtraInfoMsg_description' | translate}}</div>

                  </div>
                </label>
                <div id="check-circle" #checkCircle class="check" [ngClass]='{"centered-check-box" : onlineTvBox}'></div>
            </div>
            <div class="row" #infoContainer class="landing-info-container" id="landing-info-container-{{i}}">
                <div class="caption-container">

                    <div id="icon-container" #iconContainer class="icon-container">
                        <i #infoIconClose id="info-icon-close" class="icon icon-close" (click)="toggleSlideInfo($event, i, true)"></i>
                    </div>


                    <div class="">
                        <p id="info-text" #infoText [innerHTML]="data.info" class="caption-description">
                        </p>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>

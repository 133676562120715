import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { AppService } from '../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { SpMva10OverlayService } from '../../shared/services/sp-mva10-overlay.service';
import { ProductsAndServicesService } from '../../products-and-services/products-and-services.service';
import { TopUpService } from '../top-up.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { ServiceModel } from '../../models/service.model';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../../../config/pages-config';
import { TopupPrepaidStages, Validation, VisaFirstDigit, MasterCardFirstDigit, Safari,
  isSafariScreen, contacts, LOCAL_STORAGE_KEYS, stringOfTrue, languageKey } from '../../shared/constants/defines';
import { TopupPrepaidErrorOVerlayDataService } from '../top-up-prepaid-error-overlay-data.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from '../../../config/tagging-config';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpMva10ServiceSelectorComponent } from '../../shared/sp-mva10-service-selector/sp-mva10-service-selector.component';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { PaymentCardsService } from '../../shared/services/payment-cards.service';
import { StorageService } from '../../core/services/storage.service';
import { PaymentCardModel } from '../../models/payment-card.model';
import { LanguageEnum } from '../../shared/enums/language.enum';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { ResizeService } from '../../mva10/shared/services/resize.service';
import { BannerCardModel } from 'src/app/models/banner-card.model';

import type {
  AmountsSelectorListByStep,
  AmountSelectorValue,
  GenerateAmountSelectorValuesOptions,
  GenerateAmountSelectorListByStepOptions,
  GenerateAmountSelectorValueOptions,
} from './mva10-topup-landing-with-pci.interface';

@Component({
  selector: 'sp-mva10-topup-landing-with-pci',
  templateUrl: './mva10-topup-landing-with-pci.component.html',
  styleUrls: ['./mva10-topup-landing-with-pci.component.scss']
})
export class Mva10TopupLandingWithPciComponent implements OnInit, AfterViewChecked, OnDestroy {
  selectedAmountSelectorValue = 2;
  currentSelectedAmountSelectorValue: number;
  amountSelectorValues: AmountSelectorValue[] = [];
  minAmountSelector: number = 5;
  maxAmountSelectorValue: number = 95;
  visaImage: string;
  masterCardImage: string;
  selections: ServiceModel[];
  lottieConfig: object;
  hideContent: boolean = false;
  anim: any;
  showFullLoader = false;
  form: UntypedFormGroup;
  MsisdnValidation: any;
  showeMsisdnErrorMessage: boolean;
  isFocus: boolean;
  selectedService: ServiceModel;
  errorMessage: string;
  showSafariErrorScreen: string;
  /** This is a flag
   * if false the topup journey will be displayed.
   * if true the safari not supported overlay will be displayed
   */
  showSafariErrorOverlay = false
  uiModel: { [key: string]: string } = {
    refills: '',
    rechargeNumber: '',
    introduceNewMobile: '',
    rechargeAmount: '',
    continueBtn: '',
    sslSecured: ''
  }

  /**for padlock image */
  BREAKPOINTS: typeof Breakpoints = Breakpoints;
  breakpoint: Breakpoints = Breakpoints.MOBILE;
  @ViewChild('serviceSelector') serviceSelector: SpMva10ServiceSelectorComponent;
  @ViewChild('landingContainer') landingDiv: ElementRef;
  msisdnLessThanNineDigits: string;
  msisdnNotcontains6Or7: string;
  textInputPreviousValue = '';
  scrolledView = false;
  pageHasScroller = false
  reachScrollerEnd = false;
  selectedCardImage: string;
  changingLanguage = false;
  selectedCardNumer: string;
  /**show success icon when take a number from contact list */
  showMsisdnFieldSuccessIcon: boolean = false;
  ableToOpenContactList: string;
  numberWrittenByUser: boolean;
  languageIcon: string;
  languageText: string;
  isChangeToSpanish: boolean = true
  languageBannerCard: BannerCardModel = new BannerCardModel();
  showTobiBanner: boolean;
  keyLanguage: string;
  isDesktop: boolean;
  constructor(
    public appService: AppService,
    public translateService: TranslateService,
    public trayService: SpMva10OverlayService,
    private productsAndServicesService: ProductsAndServicesService,
    public topupService: TopUpService,
    private subscriptionsService: SubscriptionService,
    private router: Router,
    private tagging: TaggingHelperService,
    private topupErrorService: TopupPrepaidErrorOVerlayDataService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private paymentCardsService: PaymentCardsService,
    private storageService: StorageService,
    private dynamicTrayService: DynamicTrayService,
    private utilities: UtilitiesService,
    private resizeService: ResizeService,
    private storage: StorageService) { }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.isChangeToSpanish = params?.lang?.toLowerCase() === LanguageEnum.english.toLowerCase();
      this.changeLanguage(true);
    });
    this.isDesktop = this.resizeService.breakpoint === Breakpoints.DESKTOP
    this.breakpoint = this.resizeService.breakpoint;
    /**check if browser supported contact list or not */
    this.topupService.contactListSupported = contacts in navigator;
    this.setPageUi();
    if (!this.topupService.isReturnFromPermissionScreen) {
    this.checkJourneyTypeAnonymousOrLoggedIn();
   }
    this.topupService.returnDataFromContactList.subscribe(() => this.checkOnPhoneNumber());
    this.translateService.get('payment.itemsList.showSafariErrorScreen').subscribe(data => {
      this.showSafariErrorScreen = data.body;
      const browserName: string = this.utilities.browserName();
      if (this.showSafariErrorScreen &&
         this.showSafariErrorScreen.toLowerCase() === isSafariScreen && (browserName.toLowerCase() === Safari.toLowerCase())) {
        this.handleSafariContingencyScreen();
      }
      else {
        this.setTopupForOthersFormInput();
        this.setUiContent(this.topupService.topupLanguage);
        this.setAmountSelectorInitalValue();
        this.serviceSelectorLogic();
        if (!this.topupService.isReturnFromPermissionScreen) {
        this.resetAmountSelectorInitialConfigurations();
        if (!this.topupService.isAnonymousProcess) {
          this.checkForSavedCreditCards();
        } else {
          this.sendPageTagging();
          this.sendNewTopupPageTagging();
        }
      }
       this.topupService.dynamicTrayData.closeButtonAction = this.closeTrayWithTagging.bind(this);
      }
    });
}

  ngAfterViewChecked(): void {
    if (!this.showSafariErrorOverlay) {
    this.pageHasScroller = this.landingDiv.nativeElement.clientHeight < this.landingDiv.nativeElement.scrollHeight;
  }
}
  serviceSelectorLogic(): void {
    const prePaidServices: ServiceModel[] =
      this.subscriptionsService.customerData.services ? this.subscriptionsService.customerData.services.filter(service => {
        return (service.type && (service.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
        || service.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()))
      }) : [];
    if (prePaidServices.length === 0 || this.topupService.isAnonymousProcess) {
      this.showFullLoader = false;
      this.topupService.showPlus = this.topupService.isAnonymousProcess ? false : true;
    }
    else {

      this.setServiceSelectorServices();
    }
  }
  returnDataAfterBackFromWallet(): void {
    this.form = this.formBuilder.group({
      msisdn: [this.topupService.msisdn]
    });
    this.geterrorMessage()
    if (this.form.controls['msisdn'].value[0] !== '6' && this.form.controls['msisdn'].value[0] !== '7') {
      this.showeMsisdnErrorMessage = true
      this.isFocus = true;
      this.errorMessage = this.msisdnNotcontains6Or7;
    }
    else if (this.form.controls['msisdn'].value.length !== 9) {
      this.showeMsisdnErrorMessage = true
      this.isFocus = true;
      this.errorMessage = this.msisdnLessThanNineDigits;
      }
  }
  setTopupForOthersFormInput(): void {
    this.MsisdnValidation = Validation.msisdn;
    if (this.topupService.returnFromWalletScreen && this.topupService.msisdn) {
      this.returnDataAfterBackFromWallet();
    }
    else if (this.topupService.isReturnFromPermissionScreen) {
      this.checkOnPhoneNumber();
    }
    else {
      this.form = this.formBuilder.group({
        msisdn: ['', Validators.required]
      });
    }
  }

  /**check if selected number from contact list to check the validation or not */
  checkOnPhoneNumber(): void {
    this.numberWrittenByUser = this.topupService.selectedPhoneNumber ? false : true;
    if (this.topupService.selectedPhoneNumber) {
      this.form = this.formBuilder.group({
        msisdn: [this.topupService.selectedPhoneNumber]
    });
    this.form.controls['msisdn'].markAsTouched();
    this.onBlur();
    this.form.controls['msisdn'].markAsDirty();
    this.isFocus = true;
    }
    else {
      this.form = this.formBuilder.group({
        msisdn: [this.topupService.msisdn]
      });
    }
  }

  checkJourneyTypeAnonymousOrLoggedIn(): void {
    this.topupService.isMobileView = this.route.snapshot.queryParams.isMobile === 'false' ?
     false : true;
    this.topupService.isAnonymousProcess = this.router.url.split('?')[0] === config.publicTopup.route ||
      this.topupService.isOpenedFromLoginTabs;
      if (this.topupService.isAnonymousProcess) {
        this.changeLanguage();
      }
  }

  setAmountSelectorInitalValue(): void {
    this.assignAmountSelectorValues();

    if (this.productsAndServicesService.initialAmount) {
      const initialAmountIndex: any = this.amountSelectorValues.findIndex((amount) => {
        return +amount.name >= this.productsAndServicesService.initialAmount
      });
      this.selectedAmountSelectorValue = initialAmountIndex !== -1 ? initialAmountIndex : this.selectedAmountSelectorValue;
      this.productsAndServicesService.initialAmount = 0;
      this.amountSelectorValues.forEach((item, index) => {
        item.selected = item.value === initialAmountIndex ? true : false;
      });
    }

    this.topupService.curruentSelectedAmountSelectorValue = this.topupService.curruentSelectedAmountSelectorValue
      ? this.topupService.curruentSelectedAmountSelectorValue
      : this.amountSelectorValues[this.selectedAmountSelectorValue].value;
  }

  public assignAmountSelectorValues(): void {
    this.amountSelectorValues = this.generateAmountSelectorValues({
      min: this.minAmountSelector,
      max: this.maxAmountSelectorValue,
      step: 5,
      selected: this.selectedAmountSelectorValue
    });
  }

  public generateAmountSelectorValues({ selected, ...rest }: GenerateAmountSelectorValuesOptions): AmountSelectorValue[] {
    const amounts: AmountsSelectorListByStep = this.generateAmountSelectorListByStep(rest);

    const selectorValues: AmountSelectorValue[] = []

    for (const amount of amounts) {
      const selectorAmountValue: AmountSelectorValue = this.generateAmountSelectorValue({ amount, amounts, selected });

      selectorValues.push(selectorAmountValue);
    }

    return selectorValues;
  }

  public generateAmountSelectorListByStep({ min, max, step }: GenerateAmountSelectorListByStepOptions): AmountsSelectorListByStep {
    const amounts: Array<number> = [];

    for (let i: number = min; i <= max; i += step) {
      amounts.push(i)
    }

    return amounts;
  }

  public generateAmountSelectorValue({ amount, amounts, selected }: GenerateAmountSelectorValueOptions): AmountSelectorValue {
    return {
      value: amounts.indexOf(amount),
      name: amount.toString(),
      selected: amounts.indexOf(amount) === selected,
    };
  }

  setPageUi(): void {
    this.topupService.dynamicTrayData.preventTrayInnerScrolling = true;
    this.trayService.fullScreenTray = true;
    this.appService.openBubbleStopScroll = true;
    this.translateService.get('payment').subscribe(data => {
      if (this.breakpoint === Breakpoints.MOBILE) {
        this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciVisa.url);
        this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciMasterCard.url);
        }
        else {
        this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciFooterVisa.url);
        this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciFooterMasterCard.url);
        }
    });
  }

  setUiContent(lan: LanguageEnum = LanguageEnum.spanish): void {
    setTimeout(() => {
      switch (lan) {
        case LanguageEnum.spanish:
          this.translateService.get('common.itemsList').subscribe(data => {
            this.uiModel.refills = data.refills.body;
            this.uiModel.rechargeNumber = data.rechargeNumber.body;
            this.uiModel.rechargeAmount = data.rechargeAmount.body;
          });
          this.translateService.get('payment').subscribe(data => {
            this.uiModel.continueBtn = data.paymentMethods.buttonsList.continueBtn.text;
            this.uiModel.sslSecured = data.mobileRecharge.fieldsList.sslSecured.body
          });
          this.translateService.get('v10.payment.messagesList.prepaidTopup').subscribe(data => {
            this.uiModel.introduceNewMobile = data.es.addNewMobile;
          });
          break;
        case LanguageEnum.english:
          this.translateService.get('common.itemsList').subscribe(data => {
            this.uiModel.refills = data.refills_en.body;
            this.uiModel.rechargeNumber = data.rechargeNumber_en.body;
            this.uiModel.rechargeAmount = data.rechargeAmount_en.body;
            this.uiModel.introduceNewMobile = data.introduceNewMobile_en.body;
          });
          this.translateService.get('payment').subscribe(data => {
            this.uiModel.continueBtn = data.paymentMethods.buttonsList.continueBtn_en.text;
            this.uiModel.sslSecured = data.mobileRecharge.fieldsList.sslSecured_en.body;
          });
          this.translateService.get('v10.payment.messagesList.prepaidTopup').subscribe(data => {
            this.uiModel.introduceNewMobile = data.en.addNewMobile;
          });
          break;
      }
    }, 300);
  }

  setServiceSelectorServices(): void {
    this.showFullLoader = true;
    if (this.topupService.backToFirstStep) {
      this.topupService.transactionCanceledSubject.subscribe(() => {
        this.getServicesLogic();
      })
    } else {
      this.getServicesLogic();
    }
  }

  getServicesLogic(): void {
    this.selections = this.filterPrepaidServices();
    this.topupService.traySelectedService = null;
    this.topupService.newPciSelectedService = this.selections[0];
    this.showFullLoader = false;
    if (this.selections.length === 0) {
      this.showFullLoader = false;
      this.topupService.showPlus = true;
    }
    if (this.topupService.msisdn) {
        this.topupService.newPciSelectedService = null;
        this.topupService.showPlus = true;
    }
  }

  filterPrepaidServices(): ServiceModel[] {
    const result: ServiceModel[] = this.topupService.getPrepaidServiceUnderSite(this.subscriptionsService.customerData.customerAccountsId,
      this.topupService.backToFirstStep ? this.topupService.newPciSelectedService : this.topupService.traySelectedService);
    this.topupService.backToFirstStep = false;
    return result;
  }

  sendPageTagging(): void {
    this.tagging.view(tagging.pciPrepaidPages.serviceSelectorPage, this.storageService.topupPrepaid);
    tagging.pciPrepaid.journys.firstStep.data.journey_subcategory = this.topupService.setTaggingJournySubCategoryValue();
    tagging.pciPrepaid.journys.firstStep.data.journey_options = this.amountSelectorValues[this.selectedAmountSelectorValue].toString();
    tagging.pciPrepaid.journys.firstStep.data.journey_type = this.topupService.setPciTaggingJourneyType();
    this.tagging.track(tagging.pciPrepaid.journys.firstStep.eventName,
       tagging.pciPrepaid.journys.firstStep.data,
       tagging.pciPrepaidPages.serviceSelectorPage, this.storageService.topupPrepaid);
  }

  sendNewTopupPageTagging(newCreditCard?: boolean): void {
    if (this.topupService.isAnonymousProcess || newCreditCard || !this.topupService.selectedCreditCard) {
      tagging.pciPrepaid.newTopupJourney.journey_card_selected = tagging.staticVariables.newCreditCard;
    }
    else {
      tagging.pciPrepaid.newTopupJourney.journey_card_selected = tagging.staticVariables.savedCreditCard;
    }
    this.tagging.viewReplace(false, {}, tagging.pciPrepaid.newTopupJourney);
  }

  checkForSavedCreditCards(): void {
    if (!this.topupService.returnFromWalletScreen) {
      this.getSavedCreditCards();
    }
    else if (!this.topupService.selectedCreditCard) {
        this.translateService.get('payment.buttonList').subscribe(data => {
            this.selectedCardNumer = data.anotherCreditCardWalletButton.text;
        })
        this.translateService.get('payment.paymentMethods.images').subscribe(data => {
            this.selectedCardImage = this.appService.getImgFullPath(data.walletOther.url);
        });
        this.sendNewTopupPageTagging(true);
      }
    else if (!this.topupService.showCardSelectionSection && this.topupService.returnFromWalletScreen) {
      this.topupService.returnFromWalletScreen = false;
      this.topupService.selectedCreditCard = null;
      this.sendNewTopupPageTagging(true);
      return;
    }
    else {
      this.selectedCardNumer = this.topupService.selectedCreditCard.cardMask;
      this.selectedCardImage = this.topupService.selectedCreditCard.cardImage;
      this.sendNewTopupPageTagging();
    }
      this.topupService.showCardSelectionSection = true;
      this.topupService.returnFromWalletScreen = false;
  }

  getSavedCreditCards(): void {
    this.showFullLoader = true;
    this.paymentCardsService.getPaymentCardsByDocumentId(this.storageService.userProfile.document.id).subscribe(() => {
      this.topupService.selectedCreditCard = this.paymentCardsService.cardsList.length > 0 ?
        this.paymentCardsService.cardsList.find(card => card.principle) : null;
      this.showFullLoader = false;
      if (this.topupService.selectedCreditCard) {
        this.selectedCardNumer = this.topupService.selectedCreditCard.cardMask
        this.setCardSelectionVisaImage();
      }
      this.topupService.showCardSelectionSection = this.topupService.selectedCreditCard && !this.topupService.isAnonymousProcess;
      this.sendPageTagging();
      this.sendNewTopupPageTagging();
    }, error => {
      this.showFullLoader = false;
      this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
      this.topupService.showCardSelectionSection = false;
      this.topupService.selectedCreditCard = null;
      this.sendPageTagging();
      this.sendNewTopupPageTagging(true);
    });
  }

  setCardSelectionVisaImage(): void {
    this.translateService.get('payment.paymentMethods.images').subscribe(data => {
      if (this.topupService.selectedCreditCard.cardMaskData && this.topupService.selectedCreditCard.cardMaskData[0] === VisaFirstDigit) {
        this.selectedCardImage = this.appService.getImgFullPath(data.walletVisa.url);
      } else if (this.topupService.selectedCreditCard.cardMaskData &&
        this.topupService.selectedCreditCard.cardMaskData[0] === MasterCardFirstDigit) {
        this.selectedCardImage = this.appService.getImgFullPath(data.walletMasterCard.url);
      } else {
        this.selectedCardImage = this.appService.getImgFullPath(data.walletOther.url);
      }
    });
  }

  selectedAmountSelectorValues(index) {
    this.currentSelectedAmountSelectorValue = index !== -1 ?
      +this.amountSelectorValues[index].name : this.currentSelectedAmountSelectorValue;
    this.topupService.curruentSelectedAmountSelectorValue = this.currentSelectedAmountSelectorValue
  }
  closeTray(): void {
    this.topupService.selectedItemIndex = 0
    this.topupService.msisdn = '';
    this.topupService.showPlus = false;
    this.trayService.close(false);
    this.dynamicTrayService.closeTraySubject.next();
    if (this.router.url.split('?')[0] === config.publicTopup.route) {
      this.router.navigate([config.login.route]);
    }
    this.topupService.isReturnFromPermissionScreen = false;
  }

  sendTaggingClickOnXInteraction(): void {
    const pageTaggingName =  tagging.pciPrepaidPages.serviceSelectorPage.page_name;
    const eventName = tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.eventName.replace('<page_name>', pageTaggingName);
    this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickOncloseCrossTopupJourney.data,
      tagging.pciPrepaidPages.serviceSelectorPage);
    }

  closeTrayWithTagging(): void {
    this.sendTaggingClickOnXInteraction();
    this.closeTray();
    }

  clickPlus(): void {
      this.topupService.showPlus = true;
      this.topupService.newPciSelectedService = null;
      this.topupService.msisdn = '';
      this.form.controls['msisdn'].setValue('');
      this.sendClickPlusTagging();

  }
  onServiceChange(service: ServiceModel): void {
    this.selectedService = service;
    const isEligableToNewTopup = this.topupService.checkNewPciEligability(service);
    if (!isEligableToNewTopup) {
      this.closeTray();
      if (this.router.url === config.TopUp.topUpPrepaidservice.route) {
        this.topupService.trayServiceChangeSubject.next(service);
      } else {
        this.topupService.traySelectedService = service;
        this.router.navigate([config.TopUp.topUpPrepaidservice.route]);
      }
    } else {
      this.topupService.newPciSelectedService = service;
      this.topupService.selectedItemIndex = this.selections.findIndex((item) => {
        return item.id === service.id;
      })
    }
    this.topupService.showPlus = false;
    this.topupService.msisdn = '';
    this.tagging.track(tagging.pciPrepaid.interactions.clickLineNumber.eventName,
      tagging.pciPrepaid.interactions.clickLineNumber.data,
          tagging.pciPrepaidPages.serviceSelectorPage, this.storageService.topupPrepaid)
  }
  handleAnimation(anim: any) {
    this.anim = anim;
  }



  navigateToIframeEnablement(): void {
    this.topupService.selectedItemIndex = 0;
    if (this.topupService.showPlus || this.topupService.isAnonymousProcess) {
      this.topupService.msisdn = this.form.controls['msisdn'].value;
    }
    else {
      this.topupService.msisdn = '';
    }
    if ((this.topupService.newPciSelectedService && !this.topupService.newPciSelectedService.hasPendingPciTransactions)
    || this.topupService.showPlus || (this.topupService.isAnonymousProcess && this.form.controls['msisdn'].valid)) {
      this.hideContent = true;
      setTimeout(() => {
        this.topupService.topupNavigationSubject.next({page: TopupPrepaidStages.secondStep});
        this.trayService.showForwardTransition = true;
        this.trayService.showBackwardTransition = false;
      }, 1500);
    }
    this.topupService.isReturnFromPermissionScreen = false;
  }

  resetAmountSelectorInitialConfigurations(): void {
    this.hideContent = false;
    this.currentSelectedAmountSelectorValue = this.topupService.curruentSelectedAmountSelectorValue && this.topupService.backToFirstStep ?
    this.topupService.curruentSelectedAmountSelectorValue : +this.amountSelectorValues[this.selectedAmountSelectorValue].name;
    if (this.topupService.curruentSelectedAmountSelectorValue && this.topupService.backToFirstStep
      || this.topupService.returnFromWalletScreen || this.topupService.isReturnFromPermissionScreen) {
      this.selectedAmountSelectorValue = this.amountSelectorValues.
      findIndex(amount => Number(amount.name) === this.topupService.curruentSelectedAmountSelectorValue);
    } else {
      this.selectedAmountSelectorValue = 2;
      this.currentSelectedAmountSelectorValue = +this.amountSelectorValues[this.selectedAmountSelectorValue].name;
      this.topupService.curruentSelectedAmountSelectorValue = this.currentSelectedAmountSelectorValue;
    }
  }

  onFocus(): void {
    this.isFocus = true;
    if (!this.form.controls['msisdn'].value) {
    this.showeMsisdnErrorMessage = false;
    }

  }
  onInputFn(event) {
    this.showMsisdnFieldSuccessIcon = false;
    this.numberWrittenByUser = true;
    if (isNaN(event.data) && event.data) {
      event.preventDefault();
      this.form.controls['msisdn'].setValue(this.textInputPreviousValue);
      event.target.value = this.textInputPreviousValue
    } else {
      this.textInputPreviousValue = event.target.value;
      this.geterrorMessage()
      this.showeMsisdnErrorMessage = false;
      if (this.form.controls['msisdn'].value &&
      !(this.form.controls['msisdn'].value[0] === '7' ||  this.form.controls['msisdn'].value[0] === '6')
      || (!this.form.controls['msisdn'].value && !(event.target.value === '7' || event.target.value === '6')))  {
        this.showeMsisdnErrorMessage = true;
        this.errorMessage = this.msisdnNotcontains6Or7;
      } else if (this.form.controls['msisdn'].value.length === 8 && event.data) {
        event.target.blur();
      }

    }
  }

  onBlur(): void {
    this.geterrorMessage()
    this.isFocus = false;
    this.showeMsisdnErrorMessage = true;
    if (this.form.controls['msisdn'].value[0] !== '6' && this.form.controls['msisdn'].value[0] !== '7') {
      this.errorMessage = this.msisdnNotcontains6Or7;
    }
    else if (this.form.controls['msisdn'].value.length !== 9) {
      this.errorMessage = this.msisdnLessThanNineDigits;
    }
    else {
      if (this.topupService.isReturnFromPermissionScreen && !this.numberWrittenByUser) {
      this.showMsisdnFieldSuccessIcon = true;
      }
    }
  }

  geterrorMessage(): void {
    switch (this.topupService.topupLanguage) {
      case LanguageEnum.spanish:
        this.translateService.get('common.itemsList').subscribe(data => {
          this.msisdnNotcontains6Or7 = data.numberNotStartingWithSixOrSeven.body;
          this.msisdnLessThanNineDigits = data.numberLessThanNightDigits.body;
        });
        break;
      case LanguageEnum.english:
        this.translateService.get('common.itemsList').subscribe(data => {
          this.msisdnNotcontains6Or7 = data.numberNotStartingWithSixOrSeven_en.body;
          this.msisdnLessThanNineDigits = data.numberLessThanNightDigits_en.body;
        });
        break;
    }
  }

  changeLanguage(skipTagging = false): void {
    this.topupService.topupLanguage = this.isChangeToSpanish ? LanguageEnum.spanish : LanguageEnum.english;
    this.changingLanguage = true;
    this.setUiContent(this.topupService.topupLanguage);
    setTimeout(() => {
      this.changingLanguage = false;
    }, 300);
    if (!skipTagging) {
       this.changeLanguageTagging(this.topupService.topupLanguage);
    }
    this.setAnonymousUIContent();
  }

  setAnonymousUIContent(): void {
    const isShowBanner: string =  this.translateService.instant('v10.payment.messagesList.anonymousPrepaidTopup.es.tobiBanner.showBanner');
    this.showTobiBanner = isShowBanner.toLowerCase() === stringOfTrue.toLowerCase();
    if (this.topupService.topupLanguage === LanguageEnum.english) {
      this.languageIcon = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/spain@2x.png');
      this.languageText = this.translateService.instant('common.buttonList.languageEs.text');
      this.isChangeToSpanish = true;
      this.keyLanguage = languageKey.en;
    }
    else {
      this.languageIcon = this.appService.getImgFullPath('https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/uk@2x.png');
      this.languageText = this.translateService.instant('common.buttonList.languageEn.text');
      this.isChangeToSpanish = false;
      this.keyLanguage = languageKey.es;
    }
    if (this.showTobiBanner) {
      this.mapBannerData();
    }
  }

  mapBannerData(): void {
    this.translateService.get('v10.payment.messagesList.anonymousPrepaidTopup.' + this.keyLanguage + '.tobiBanner').subscribe((data) => {
      this.languageBannerCard.title = data.title;
      this.languageBannerCard.description = data.description;
      this.languageBannerCard.button = data.button;
      this.languageBannerCard.buttonLink = data.buttonLink;
      this.languageBannerCard.icon = data.icon;
    })
  }

  clickOnBannerCTA(): void {
  this.clickOnBannerCTATagging();
    window.open(this.languageBannerCard.buttonLink, '_blank');
  }

  clickOnBannerCTATagging(): void {
    switch (this.topupService.topupLanguage) {
      case LanguageEnum.spanish:
        this.tagging.track(tagging.pciPrepaid.interactions.clickOnIrAMiVodafone.eventName,
           tagging.pciPrepaid.interactions.clickOnIrAMiVodafone.data, tagging.pciPrepaidPages.serviceSelectorPage);
        break;
      case  LanguageEnum.english:
        this.tagging.track(tagging.pciPrepaid.interactions.clickOnGoToMiVodafone.eventName,
          tagging.pciPrepaid.interactions.clickOnGoToMiVodafone.data, tagging.pciPrepaidPages.serviceSelectorPage);
        break;
    }
  }

  changeLanguageTagging(language) {
    switch (language) {
      case 'es':
        this.storageService.topupPrepaid.page_language = tagging.staticVariables.pciPrepaidLanguage.spanish;
        this.tagging.track(tagging.pciPrepaid.interactions.clickSpanishLanguage.eventName,
           tagging.pciPrepaid.interactions.clickSpanishLanguage.data, tagging.pciPrepaidPages.serviceSelectorPage,
           this.storageService.topupPrepaid);
        break;
      case 'en':
        this.storageService.topupPrepaid.page_language = tagging.staticVariables.pciPrepaidLanguage.english;
        this.tagging.track(tagging.pciPrepaid.interactions.clickEnglishLanguage.eventName,
          tagging.pciPrepaid.interactions.clickEnglishLanguage.data, tagging.pciPrepaidPages.serviceSelectorPage,
           this.storageService.topupPrepaid);
        break;
    }
  }

  sendClickPlusTagging(): void {
    this.tagging.track(tagging.pciPrepaid.interactions.clickAddNewLineNumber.eventName,
      tagging.pciPrepaid.interactions.clickAddNewLineNumber.data,
      tagging.pciPrepaidPages.serviceSelectorPage, this.storageService.topupPrepaid);
  }

  navigateToWalletScreen(): void {
      if (this.topupService.showPlus) {
        this.topupService.msisdn = this.form.controls['msisdn'].value;
      }
      this.topupService.topupNavigationSubject.next({page: TopupPrepaidStages.TopUpCardWalletScreen});
      this.trayService.showForwardTransition = true;
      this.trayService.showBackwardTransition = false;
      this.topupService.isReturnFromPermissionScreen = false;
      this.sendClickOnManageCreditCardTagging();
  }

  onScroll(event) {
    this.scrolledView = event.target.scrollTop !== 0;
    this.reachScrollerEnd = (Math.ceil(event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight);
    this.topupService.dynamicTrayData.trayInnerContentScrolling = this.scrolledView;
  }

  sendClickOnManageCreditCardTagging(): void {
    this.tagging.track(tagging.pciPrepaid.interactions.clickViewSavedCreditCard.eventName,
      tagging.pciPrepaid.interactions.clickViewSavedCreditCard.data,
      tagging.pciPrepaidPages.serviceSelectorPage);
  }

  ngOnDestroy(): void {
    this.appService.openBubbleStopScroll = false;
    this.topupService.dynamicTrayData.closeButtonAction = null;
    this.topupService.dynamicTrayData.trayInnerContentScrolling = false;
  }
  /** This for display safari not supported error screen instead of Topup journey. */
  handleSafariContingencyScreen(): void {
      this.showSafariErrorOverlay = true;
      this.topupErrorService.setUiDataForSafariContingencyScreen();
    }

/**to navigate to contact list screen permission */
  navigateToContactListPermission(): void {
    this.ableToOpenContactList = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ableToOpenContactList);
    this.showMsisdnFieldSuccessIcon = false;
    if (!this.ableToOpenContactList) {
    this.trayService.showForwardTransition = true;
    this.trayService.showBackwardTransition = false;
    this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.topUpContactListPermissionComponent });
    }
    else {
      this.topupService.openContactList().then(() => {
        this.checkOnPhoneNumber();
      })
    };
  }
  }

export enum MarketplaceTagging {
  'close' = 'aspa superior',
  'vf' = 'vf',
  'marketplace' = 'marketplace'
}

export enum MarketplaceUrlParams {
  'tab' = 'tab',
  'uri' = 'uri'
}

export enum ShopTabs {
  'Shop' = 'Shop',
  'Mktplace' = 'Mktplace'
}

export enum IframeMessageOrders {
  'hideNavbar' = 'hideNavbar',
  'showNavbar' = 'showNavbar',
  'hideTabs' = 'hideTabs',
  'showTabs' = 'showTabs',
  'close' = 'close',
  'fullClose' = 'fullClose',
}

export enum IframeMessageStatus {
  'buying' = 'shoppingcartFull',
  'notBuying' = 'shoppingcartEmpty',
}

export enum IframeMessageTypes {
  'order' = 'mktpOrder',
  'status' = 'mktpStatus',
  'destination' = 'mktpDestination'
}


import {finalize, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as Routes from '../../shared/constants/routes-config';
import { Configurations } from '../../models/configuration.model';
import { JSON_PATHS, CONFIG, LOCAL_STORAGE_KEYS, CSRFToken } from '../../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { StorageService } from '../../core/services/storage.service';
import { BillClarification } from '../../models/bill-clarification.model';

import { Observable, of } from 'rxjs';
import { ProductConfiguration } from '../../models/product-configuration.model';
import { TobiUserConfiguration } from '../../models/tobiUserConfiguration.model';


import { AppService } from '../../app.service';
import { SettingsResponse } from '../../models/settings-response.model';
/**
 * Configuration Service Used to load application Configuration from middleware
 * middleware URL is defined in the application enviroment settings.
 */
@Injectable()
export class ConfigurationService {

  public configuration: Configurations;
  isConfigLoaded: boolean = false;
  isConfigurationApiFailed: boolean = false;

  /**
   * constructor inject http service in to it
   */
  constructor(private http: HttpClient,
     private storage: StorageService,
     private app: AppService,
     ) { }
  /**
   * Load function responsible for load configuration from server config path based on environment
   */
  public load(): Observable<Configurations> {
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Accept', 'application/json');
      // headers = headers.append('vf-target-stub', 'false');
      headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
      const config: {headers: HttpHeaders, observe: 'body'} = {
        headers: headers,
        observe: 'response' as 'body'
      };
      this.storage.removeFromLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
      const newRefreshTokenDate: Date = new Date();
      if (this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)) {
        this.storage.setLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP, newRefreshTokenDate.setDate(newRefreshTokenDate.getDate() - 1));
      }

     return this.http.get(Routes.API_URLS.CONFIGURATIONS.CONFIG_GET.replace('{type}', 'All'), config).pipe(finalize(() => {
        this.app.settingsSubject.next(true);
        this.isConfigLoaded = true;
      })).pipe(map((response: HttpResponse<SettingsResponse>) => {
        const userCSRFToken: string = response.headers.get(CSRFToken);
        this.storage.setLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN, userCSRFToken);
        this.isConfigurationApiFailed = false;
        this.configuration = this.transformConfig(response.body);
        this.storage.setLocalStorage('settings', this.configuration);
        return this.configuration;
      }),
       catchError((error) => {
         this.isConfigurationApiFailed = true;
          if (this.storage.getLocalStorage('settings')) {
            this.configuration = this.storage.getLocalStorage('settings')
          }
          else {

            this.http.get('https://assets-es-sit3.dxlpreprod.local.vodafone.es/config/app-config.json').subscribe(res => {
              this.configuration = res as Configurations;
              this.storage.setLocalStorage('settings', this.configuration);
              return res;
            });
          }
          return of(this.configuration);
        }));
  }
  /**
   * Get Configuration variable loaded from load function
   */
  public getConfiguration(): Configurations {
    return this.configuration;
  }
  /**
   * Map the JSON object returned from the backend
   * to Configuration model
   */
   transformConfig(config: SettingsResponse): Configurations {
    const configurationModel: Configurations = new Configurations();
    /**Avilability of new anonymous journey */
    configurationModel.newAnonymousJourney = config[JSON_PATHS.Configuration.newAnonymousJourney] || false;
    configurationModel.availabilityOfRMCA = !!config[JSON_PATHS.Configuration.availabilityOfRMCA];
    configurationModel.networkLogin = config[JSON_PATHS.Configuration.networkLogin] || null;
    configurationModel.showBillsLink = config[JSON_PATHS.Configuration.showBillsLink] || null;
    configurationModel.showOneNumberSSO = config[JSON_PATHS.Configuration.showOneNumberSSO] || null;
    configurationModel.showSecureNetSSO = config[JSON_PATHS.Configuration.showSecureNetSSO] || null;
    configurationModel.chatEnabled = config[JSON_PATHS.Configuration.chatEnabled] || null;
    configurationModel.tobiBubble = config[JSON_PATHS.Configuration.tobiBubble] || null;
    configurationModel.hideBillConfigurationToggles = config[JSON_PATHS.Configuration.hideBillConfigurationToggles] || false;
    configurationModel.tobiChatUnAvailability = config[JSON_PATHS.Configuration.tobiChatUnAvailability];
    configurationModel.tobiUserConfiguration = [...this.transformTobiUserConfig(config)];
    /** Availability of some back-end points */
    configurationModel.biztalkAvailabilityFlag = config[JSON_PATHS.Configuration.biztalkAvailabilityFlag] !== false;
    configurationModel.paymentUnavailability = config[JSON_PATHS.Configuration.paymentUnavailability] === true;
    configurationModel.billingFlag = config[JSON_PATHS.Configuration.billingFlag] !== false;
    configurationModel.clarifyFlag = config[JSON_PATHS.Configuration.clarifyFlag] !== false;
    configurationModel.genevaFlag = config[JSON_PATHS.Configuration.genevaFlag] !== false;
    configurationModel.ocsFlag = config[JSON_PATHS.Configuration.ocsFlag] !== false;
    configurationModel.topupNewPCI = config[JSON_PATHS.Configuration.pciFlag] !== false;
    configurationModel.topupNewPCIMsisdnPattern = config[JSON_PATHS.Configuration.pciPattern] || '';
    configurationModel.disablemiwifi = config[JSON_PATHS.Configuration.disablemiwifi] !== false;
    configurationModel.showBillingEta = config[JSON_PATHS.Configuration.showBillingEta] !== false;
    configurationModel.rsmAvailabilityForBillReview = config[JSON_PATHS.Configuration.rsmAvailabilityForBillReview];
    configurationModel.showBillstatus = config[JSON_PATHS.Configuration.showBillstatus];
    configurationModel.showMovementsHistoryNewScreen = config[JSON_PATHS.Configuration.showMovementsHistoryNewScreen] || false;

    configurationModel.billClarifications = [...this.transformBillClarificationConfig(config)];

    // add configuration product to configuration model
    configurationModel.productConfiguration = [...this.transformProductConfig(config)];

    configurationModel.showEverythingIsOk = config[JSON_PATHS.Configuration.showEverythingIsOk] || false;
    configurationModel.smartPayMSIDNPattern = config[JSON_PATHS.Configuration.smartPayMSIDNPattern];
    configurationModel.PurchaseDefaultJourney = config[JSON_PATHS.Configuration.purchaseDefaultJourney];
    configurationModel.reviewBillingJourney.pattern =
      JsonQuery.value(config, JSON_PATHS.Configuration.billreviewpattern) || '';
    configurationModel.reviewBillingJourney.status =
      JsonQuery.value(config, JSON_PATHS.Configuration.billreviewStatus) || '';
    configurationModel.reviewBillingJourney.validUsers = JsonQuery.value(config, JSON_PATHS.Configuration.billreviewValidUsers) || [];


    /** Availability of superwifi flow */
    configurationModel.showSuperWifi = !!config[JSON_PATHS.Configuration.showSuperWifi];
    configurationModel.storiesPosition = config[JSON_PATHS.Configuration.storiesPosition];
    return configurationModel;
  }

  transformTobiUserConfig(config: SettingsResponse): TobiUserConfiguration[] {
    const configTobiModel: TobiUserConfiguration[] = [];
    const tobiUserConfiguration: TobiUserConfiguration[] = config[JSON_PATHS.Configuration.tobiUserConfiguration] || [];
    tobiUserConfiguration.forEach(tobiUser => {
      const tobiConfigurationUser: TobiUserConfiguration = new TobiUserConfiguration();
      tobiConfigurationUser.customerType = tobiUser.customerType;
      tobiConfigurationUser.profileType = tobiUser.profileType;
      configTobiModel.push(tobiConfigurationUser);
    });
    return configTobiModel;
  }

  transformBillClarificationConfig(config: SettingsResponse): BillClarification[] {
    const configBillClarificationModel: BillClarification[] = [];
    const billClarifications: BillClarification[] = config[JSON_PATHS.Configuration.billClarifications] || [];
    billClarifications.forEach(billSetting => {
      const billClarification: BillClarification = new BillClarification();
      billClarification.customerType = billSetting.customerType;
      billClarification.segments = billSetting.segments;
      billClarification.tariffCodes = billSetting.tariffCodes;
      configBillClarificationModel.push(billClarification);
    })
    return configBillClarificationModel;
  }

  transformProductConfig(config: SettingsResponse): ProductConfiguration[] {
    const configProductModel: ProductConfiguration[] = [];
    const productConfiguration: ProductConfiguration[] = config[JSON_PATHS.Configuration.productConfiguration] || [];
    productConfiguration.forEach(productConfig => {
      const productConfiguration: ProductConfiguration = new ProductConfiguration();
      productConfiguration.web = productConfig.web;
      productConfiguration.featureID = productConfig.featureID;
      productConfiguration.filterType = productConfig.filterType;
      productConfiguration.filterValue = productConfig.filterValue;
      productConfiguration.operator = productConfig.operator;
      productConfiguration.adobe = productConfig.adobe;
      configProductModel.push(productConfiguration);
    })
    return configProductModel;
  }

}

import {throwError as observableThrowError, throwError } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { tagging } from './../../../config/tagging-config';
import { BenefitStatus } from './../enums/benefit-status.enum';
import { PlanType } from './../enums/plan-type.enum';
import { Tariff, Voucher } from './../../models/tariff.model';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { StorageService } from '../../core/services/storage.service';
import * as JsonQuery from 'jsonpath/jsonpath';
import * as constants from '../constants/defines';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { JSON_PATHS, TariffSegment, subscriptionsAndTariffError,
  cachingKeys, tvPlans, TariffErrorMessage } from '../constants/defines';
import { SubscriptionService } from '../../core/services/subscription.service';
import { Address } from '../../models/Address.model';
import { TariffStatus } from '../enums/tariffStatus.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { ErrorHandlingService } from '../../core/services/errorHandling.service';
import { evict } from '../decorators/evict.decorator';
import { ErrorHadlingUsingNotificationService } from '../../core/services/error-hadling-using-notification.service';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { TranslateService } from '@ngx-translate/core';
import { DestinyService } from './destiny.service';
import { TariffHelperService } from './tariff-helper.service';
import { LOCAL_STORAGE_KEYS } from '../constants/defines';
import { JWTHelper } from '../../core/services/jwt.helper';
import { Equipment } from '../../models/equipment.model';
import { TariffOnlineTVResponse, TariffPromotionInterface, TariffListResponse, TariffItemsResponse, TariffFibreResponse } from '../../models/tariff-list-response.model';

@Injectable()
export class TariffService {
  public Tariff: Tariff;
  public inactiveTariffs: Array<Tariff> = new Array<Tariff>();
  public selectedTariffs = new Tariff();
  public upgradeTvPending: boolean;
  isError = false;
  public tvBundleName: string;
  isNewOfferTV: boolean = false;
  upgradeNewOfferTV: boolean = false;
  isTVNEG: boolean = false;
  public tvOrdered: {[key: string]: boolean} = {};
  public delightTvOrdered: {[key: string]: boolean} = {};
  public tryAnswered: {[key: string]: boolean} = {};

  tariffWs10: any;
  isPrepaidBenefit: boolean = false;
  isPrepaidNoBenefit: boolean = false;
  isPurePrepaid: boolean = false;
  userPQ: boolean = false;
  isPrepaidYu = false;

  constructor(private http: HttpClient,
    private storageService: StorageService,
    private subscriptionService: SubscriptionService,
    private tagging: TaggingHelperService,
    private errorHandling: ErrorHandlingService,
    private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
    private translate: TranslateService,
    private destinyService: DestinyService,
    private tariffHelper: TariffHelperService,
    private jwtHelper: JWTHelper
  ) {
    this.Tariff = new Tariff();
    this.destinyService.newOfferTVObservable.subscribe(state => {
      this.isNewOfferTV = state;
      this.isTVNEG = this.destinyService.isTVNEG;
    });
    this.destinyService.upgradeTVObservable.subscribe(state => {
      this.upgradeNewOfferTV = state;
    });
  }

  /**
   * Fetch all tarrifs of the current user with the site id and default service id
   */
  GetAllTariffs(subscriptionId?: string) {
    const serviceId: string = subscriptionId || this.subscriptionService.customerData.currentService.id;
    const jwt: string = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0];
    const customerAccountId: string = this.jwtHelper.getUserProfileFromJWT(jwt)?.services
      .find(sub => `${sub.id}` === `${serviceId}`)?.customerAccountId;

      if (customerAccountId && serviceId) {
    const url: string = API_URLS.Tariff.fetchAllTariffs.replace('{subscription-id}', serviceId)
      .replace('{customer-account-id}', customerAccountId);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN));
    const options: { [key: string]: HttpHeaders } = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map(
      (res: TariffListResponse) => {
        this.inactiveTariffs = new Array<Tariff>();
        this.Tariff = new Tariff();
        return this.mapTariff(res);
      }));
    } else {
      return throwError(TariffErrorMessage.siteOrServiceNotFound);
    }
  }
  setTvBundleTaggingVariable() {
    if (this.subscriptionService.customerData.currentService.type.toLowerCase() !== ServiceType.Tv.toLowerCase()) {
      this.storageService.productAndServiceVariables.tv_bundle = tagging.staticVariables.tvBundle.nontvService;

    } else {
        this.tvBundleName = tagging.staticVariables.tvBundle[this.Tariff.group.toLocaleLowerCase()];
      this.storageService.productAndServiceVariables.tv_bundle = this.Tariff.group ? this.tvBundleName
        : tagging.staticVariables.tvBundle.nontvService;
    }
  }
  @evict(cachingKeys.Tariff)
  patchActivateChannel(email, status, requestBody?) {
    const url = API_URLS.Tariff.patchProduct.replace('{tarrifId}', this.Tariff.Id);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');

    const options: { [key: string]: HttpHeaders } = { headers };

    let body: { [key: string]: { [key: string]: any } } = {
      'onlineTv': {
        'status': {
          'current': status
        },
        email
      }
    }

    if (requestBody) {
      body = requestBody;
    }

    return this.http.patch(url, body, options);
  }

  patchTariffSuperWifiStatus(status) {
    const url = API_URLS.Tariff.patchProduct.replace('{tarrifId}', this.Tariff.Id);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');

    const options: { [key: string]: HttpHeaders } = { headers };
    const body: { [key: string]: { [key: string]: any } }  = {
      'extension': {
        'es': {
          'superWifi': { status }
        }
      }
    }

    return this.http.patch(url, body, options);
  }

  getSelectedServicesStatus(id, successFun, errFun, pageType) {
    const getSelectedServices = this.subscriptionService.customerData.services.find((el) => el.id === id);
    if (getSelectedServices) {
      if ((getSelectedServices.type?.toString() === ServiceType.Prepaid)) {
        if (getSelectedServices.status?.toLowerCase() === TariffStatus.Active) {
          successFun()
        } else {
          // store old selected services if i need to return to it in case of error InActive
          // get status from the server
          this.GetAllTariffs(id).subscribe(
            (response) => {
              // update service data
              // check if new status is active for prepaid then please add it as selected servces and do normal action
              if (this.Tariff.Status === TariffStatus.Active) {
                // change the stauts of clicked service with updated one
                getSelectedServices.status = this.Tariff.Status;
                // after success update service & current services
                this.subscriptionService.customerData.services.map((el) => {
                  if (el.id.toString() === getSelectedServices.id.toString()) {
                    el = getSelectedServices;
                    this.subscriptionService.customerData.currentService = getSelectedServices;
                    this.tagging.encryptMsisdn(this.subscriptionService.customerData.currentService.id);
                    successFun()
                  }
                })
              } else {
                // Reset old services &  show Error
                errFun();
                throw observableThrowError(response);
              }

            }, (error) => {
              if (error.status.toString() === subscriptionsAndTariffError.status && error.error && error.error.ecode &&
                error.error.ecode.toString() === subscriptionsAndTariffError.ecode) {
                const errorObj = this.errorHandling.lookUpError(pageType, error);
                if (errorObj) {
                  error.error['bodyContent'] = errorObj['description'];
                  error.error['bodyTitle'] = errorObj['title'];
                  error.error['primaryButtonText'] = errorObj['dismissButtonText'] || errorObj['dismissButton'];
                  errFun(error)
                }
              }
              else {
                this.isError = true
                const errorModal =
                  this.errorHadlingUsingNotificationService.errorHandlingUsingNotification(error, constants.HttpRequestMethods.get,
                    constants.PAGES.DASHBORD, false);
                errorModal.show();
              }
            })
        }
      }
      else {
        // normal flow if not prepaid
        successFun()
      }
    }
  }


  patchChangePassword() {
    const url = API_URLS.Tariff.patchChangePassword.replace('{id}', this.subscriptionService.customerData.customerAccountsId);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');

    const options: { [key: string]: HttpHeaders } = { headers };
    const body: Object = {};

    return this.http.patch(url, body, options);
  }

  patchChangeUsername(username) {
    const url = API_URLS.Tariff.patchChangeUsername.replace('{id}', this.subscriptionService.customerData.customerAccountsId);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');

    const options: { [key: string]: HttpHeaders } = { headers };
    const body: { [key: string]: string } = { username };

    return this.http.patch(url, body, options);
  }

  @evict(cachingKeys.Tariff)
  renewBenifits() {
    const url = API_URLS.Tariff.patchProduct.replace('{tarrifId}', this.Tariff.Id);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');

    const options: { [key: string]: HttpHeaders } = { headers };
    const body: { [key: string]: { [key: string]: any } }  = {
      'extension': {
        'es': {
          'benefits': {
            'status': BenefitStatus.active
          }
        }
      }
    }

    return this.http.patch(url, body, options);

  }

  @evict(cachingKeys.Tariff)
  getTariff() {
    this.tariffWs10 = null;

    return this.GetAllTariffs().pipe(map((response: any) => {
      if (response.items) {
        this.tariffWs10 = response.items[0];
        this.checkPaidType();
      }
    }), catchError((error) => {
      return throwError(error);
    }));
  }

  checkPaidType() {
    this.isPrepaidNoBenefit = false;
    this.isPrepaidBenefit = false;
    this.isPurePrepaid = false;
    this.isPrepaidYu = false;
    if (this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()) {
      if (this.tariffWs10.extension.es.benefits.isEligible) {
        this.isPrepaidBenefit = true;
        this.isPrepaidNoBenefit = false;
        this.isPurePrepaid = false
        this.userPQ = false;
      } else if (!this.tariffWs10.extension.es.benefits.isEligible) {
        this.isPrepaidNoBenefit = true;
        this.isPrepaidBenefit = false;
        this.isPurePrepaid = false;
        this.userPQ = true;
      }
      this.translate.get('v10.dashboard.config.filter_yu_prepaid').subscribe(code => {
        this.isPrepaidYu = code.includes(this.tariffWs10.code);
      });
    } else if (this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Postpaid.toLowerCase()) {
        this.isPrepaidBenefit = true;
        this.isPrepaidNoBenefit = false;
        this.isPurePrepaid = false;
        this.userPQ = false;
    }
  }

  getProductsON19(tariff) {
    const url = API_URLS['Dashboard-WS10'].Products.
      replace('{subscription-id}', this.subscriptionService.customerData.currentService.id).
      replace('{customer-account-id}', this.subscriptionService.customerData.customerAccountsId);

    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }), catchError((error) => {
      return throwError(error);
    }));
  }

  /**
   * Returns voucher found by type
   * @param voucherType
   */
  public findVoucherByType(voucherType: string): Voucher {
    let voucher: Voucher;
    if (this.Tariff.vouchers?.length) {
      voucher = this.Tariff.vouchers.find((vch: Voucher) => vch.type.toLowerCase() === voucherType.toLowerCase());
    }
    return voucher;
  }

  public showDelightTvSurvey(): boolean {
    return this.tariffHelper.showDelightTvSurvey(this.Tariff.vouchers);
  }
  isDay28(): boolean {
    const today: Date = new Date();
    const expiryDate: Date = new Date(this.Tariff.benefitExpiryDate);
    const tomorrow: Date = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (expiryDate.getDate() - tomorrow.getDate() === 0);
  }
  mapTariff(res: TariffListResponse): TariffListResponse {
    let tariff: Tariff = new Tariff();
    res['items'].forEach(item => {
      this.mapBasicTariffData(item, tariff);
      this.mapTariffSegmentsProperty(item, tariff);
      this.checkYuTariff(item, tariff);
      this.mapAddressProperty(item, tariff);
      this.mapFibreProperty(item, tariff);
      this.mapTvProperty(item, tariff);
      this.mapPlanType(item, tariff);
      // set service_plane cross variable
      this.storageService.service_plane = tariff.Code ? tariff.Code : '';
      this.setTaggingStaticVariable(tariff);
      // CPE
      this.mapEquipmentProperty(item, tariff);
      this.storageService.productAndServiceVariables.plan_type = this.subscriptionService.customerData.currentService.name;
      // add mobility object story MVES-371
      tariff.isTotalMobility = JsonQuery.value(item, JSON_PATHS.Tariff.isTotalMobility);
      // super wifi object
      tariff.superWifi = JsonQuery.value(item, JSON_PATHS.Tariff.superWifi);
      tariff.secureNet = JsonQuery.value(item, JSON_PATHS.Tariff.secureNet);
      tariff.secureNetFamily = JsonQuery.value(item, JSON_PATHS.Tariff.secureNetFamily);
      tariff = this.checkTariffUndefinedItems(tariff);
      this.checkTariffStatus(tariff);
      tariff.mercury = JsonQuery.value(item, JSON_PATHS.Tariff.mercury);
      this.mapVoucher(item, tariff);
      tariff.isPrepaidCommunity = JsonQuery.value(item, JSON_PATHS.Tariff.isPrepaidCommunity) || false;
      const promotions: TariffPromotionInterface[] = JsonQuery.value(item, JSON_PATHS.Tariff.promotions) || [];
      tariff.promotions = this.tariffHelper.mapPromotions(promotions);
    });
    if (res['items'].length === 1) {
      this.selectedTariffs = this.Tariff.Id ? this.Tariff : this.inactiveTariffs[0];
      this.Tariff = this.selectedTariffs;
    }
    this.setTvBundleTaggingVariable();
    this.Tariff.SuperOffer = JsonQuery.value(res, JSON_PATHS.Tariff.SuperOffer) || null;
    this.destinyService.checkNewOfferTV(this.Tariff);
    this.destinyService.checkUpgradeTV(this.Tariff);
    return res;
  }
  mapBasicTariffData(item: TariffItemsResponse, tariff: Tariff): void {
    tariff.Name = JsonQuery.value(item, JSON_PATHS.Tariff.Name);
    tariff.Code = JsonQuery.value(item, JSON_PATHS.Tariff.Code);
    tariff.serviceType = JsonQuery.value(item, JSON_PATHS.Tariff.serviceType);
    tariff.statusSBA = JsonQuery.value(item, JSON_PATHS.Tariff.statusSBA);
    tariff.decoEquipment = JsonQuery.value(item, JSON_PATHS.Tariff.decoEquipment) || [];
    tariff.devices = JsonQuery.value(item, JSON_PATHS.Tariff.devices);
    tariff.Status = JsonQuery.value(item, JSON_PATHS.Tariff.Status);
    tariff.RoamingMessage = JsonQuery.value(item, JSON_PATHS.Tariff.RoamingMessage);
    tariff.RoamingMessageType = JsonQuery.value(item, JSON_PATHS.Tariff.RoamingMessageType);
    tariff.Fee = JsonQuery.value(item, JSON_PATHS.Tariff.Fee) !== undefined &&
      JsonQuery.value(item, JSON_PATHS.Tariff.Fee) !== '' ?
      JsonQuery.value(item, JSON_PATHS.Tariff.Fee)
      : null;
    tariff.Unit = JsonQuery.value(item, JSON_PATHS.Tariff.Unit);
    tariff.group = JsonQuery.value(item, JSON_PATHS.Tariff.group);
    tariff.Id = JsonQuery.value(item, JSON_PATHS.Tariff.Id);
    tariff.Email = JsonQuery.value(item, JSON_PATHS.Tariff.Email);
    tariff.benefitStatus = JsonQuery.value(item, JSON_PATHS.Tariff.benefitStatus);
    tariff.ShortDescription = JsonQuery.value(item, JSON_PATHS.Tariff.ShortDescription);
    tariff.LongDescription = JsonQuery.value(item, JSON_PATHS.Tariff.LongDescription);
    tariff.benefitDescription = JsonQuery.value(item, JSON_PATHS.Tariff.benefitDescription);
    tariff.topupPeriodicity = JsonQuery.value(item, JSON_PATHS.Tariff.topupPeriodicity);
    tariff.benefitExpiryDate = JsonQuery.value(item, JSON_PATHS.Tariff.benefitExpiryDate);
    tariff.benefitRenewalDate = JsonQuery.value(item, JSON_PATHS.Tariff.benefitRenewalDate);
    tariff.hasBenifits = JsonQuery.value(item, JSON_PATHS.Tariff.hasBenefits);
    tariff.smartPay = JsonQuery.value(item, JSON_PATHS.Tariff.smartPay);
  }
  mapTariffSegmentsProperty( item: TariffItemsResponse, tariff: Tariff): void {
    tariff.segments = JsonQuery.value(item, JSON_PATHS.Tariff.Segments) || [];
    if (tariff.segments?.length > 0) {
      this.storageService.isHoreca = tariff.segments.filter((item) => {
        return item.toLowerCase() === tvPlans.Horeca.toLowerCase();
      }).length > 0;
    }
  }
  checkYuTariff(item: TariffItemsResponse, tariff: Tariff): void {
    if (JsonQuery.value(item, JSON_PATHS.Tariff.Yu)) {
      tariff.Yu =
        (JsonQuery.value(item, JSON_PATHS.Tariff.Yu)).toLocaleLowerCase() === TariffSegment.yu.toLocaleLowerCase() || false;
    } else {
      tariff.Yu = false;
      tariff.Xs = false;
    }
  }
  mapAddressProperty(item: TariffItemsResponse, tariff: Tariff): void {
    tariff.Address = new Address();
    // **  Address Property   */
    const address: Address = JsonQuery.value(item, JSON_PATHS.Tariff.Address);
    if (address) {
      tariff.Address.buildingNo = JsonQuery.value(address, JSON_PATHS.UserAccount.buildingNo) || null;
      tariff.Address.country = JsonQuery.value(address, JSON_PATHS.UserAccount.country) || null;
      tariff.Address.door = JsonQuery.value(address, JSON_PATHS.UserAccount.door) || null;
      tariff.Address.level = JsonQuery.value(address, JSON_PATHS.UserAccount.levelNr) || null;
      tariff.Address.town = JsonQuery.value(address, JSON_PATHS.UserAccount.town) || null;
      tariff.Address.street = JsonQuery.value(address, JSON_PATHS.UserAccount.street) || null;
      tariff.Address.streetType = JsonQuery.value(address, JSON_PATHS.UserAccount.streetType) || null;
      tariff.Address.postcode = JsonQuery.value(address, JSON_PATHS.UserAccount.postcodeTariff) || null;
      tariff.Address.line1 = tariff.Address.streetType + ' ' +
      tariff.Address.street + ' ' + tariff.Address.buildingNo +
        ', ' + tariff.Address.level;
      tariff.Address.line2 = tariff.Address.postcode + ', ' + tariff.Address.town;
    }
  }
  mapFibreProperty(item: TariffItemsResponse, tariff: Tariff): void {
    // **  Fibre Property   */
    const fibre: TariffFibreResponse = JsonQuery.value(item, JSON_PATHS.Tariff.Fibre);
    if (fibre) {
      tariff.DownloadSpeed = JsonQuery.value(item, JSON_PATHS.Tariff.DownloadSpeed);
      if (tariff.DownloadSpeed) {
        const downloadSpeed: number = +(tariff.DownloadSpeed)
        tariff.DownloadSpeed = downloadSpeed.toFixed(0).toString();
      }
      tariff.UploadSpeed = JsonQuery.value(item, JSON_PATHS.Tariff.UploadSpeed);
      if (tariff.UploadSpeed) {
        const uploadSpeed: number = +(tariff.UploadSpeed)
        tariff.UploadSpeed = uploadSpeed.toFixed(0).toString();
      }
      tariff.DownloadSpeedUnit = JsonQuery.value(item, JSON_PATHS.Tariff.DownloadSpeedUnit);
      tariff.UploadSpeedUnit = JsonQuery.value(item, JSON_PATHS.Tariff.UploadSpeedUnit);
      tariff.RouterName = JsonQuery.value(item, JSON_PATHS.Tariff.RouterName);
      tariff.RouterType = JsonQuery.value(item, JSON_PATHS.Tariff.RouterType);
      tariff.RouterSerialNumber = JsonQuery.value(item, JSON_PATHS.Tariff.RouterSerialNumber);
      this.storageService.productAndServiceVariables.
      router_serial_number = tariff.RouterSerialNumber ? tariff.RouterSerialNumber : '';
    }
  }

  mapTvProperty(item: TariffItemsResponse, tariff: Tariff): void {
    const tv: TariffOnlineTVResponse = JsonQuery.value(item, JSON_PATHS.Tariff.Television);
    // **  Tv Property   */
    if (tv) {
      tariff.HasPendingChannel = JsonQuery.value(item, JSON_PATHS.Tariff.HasPendingChannel);
      tariff.NumberOfChannels = JsonQuery.value(item, JSON_PATHS.Tariff.NumberOfChannels);
      tariff.OnlineStatus = JsonQuery.value(item, JSON_PATHS.Tariff.OnlineStatus);
      tariff.lastStatus = JsonQuery.value(item, JSON_PATHS.Tariff.lastStatus);
      if (tariff.OnlineStatus?.toLowerCase() === BundleStatus.Active.toLowerCase()) {
        this.storageService.productAndServiceVariables.tv_online_status = tagging.staticVariables.tvOnlineStatus.standard;
        this.translate.get('productsServices.tv.vesTitle').subscribe((data) => {
          this.storageService.productAndServiceVariables.tv_online_bundle =
            tagging.staticVariables.onlineTvBundle.standard.replace('{0}', data);
        })
      } else {
        this.storageService.productAndServiceVariables.tv_online_status = tagging.staticVariables.tvOnlineStatus.notv;
      }
    }
  }
  mapPlanType(item: TariffItemsResponse, tariff: Tariff): void {
     /** map plan type (segments[0]) and benefit status */
     if (tariff.segments?.length > 0) {
      tariff.planType = (<PlanType>tariff.segments[0]);
      tariff.benefitStatus = JsonQuery.value(item, JSON_PATHS.Tariff.benefitStatus);
      tariff.Xs =
        (tariff.segments.find(el => el.toLocaleLowerCase() === TariffSegment.xs.toLocaleLowerCase())) ? true : false;
    }
  }
  mapEquipmentProperty( item: TariffItemsResponse, tariff: Tariff): void {
    const equipment: Equipment = JsonQuery.value(item, JSON_PATHS.Tariff.equipment)
    if (equipment) {
      tariff.equipment = this.tariffHelper.mapEquipment(equipment, item);
    }
  }
  setTaggingStaticVariable(tariff: Tariff): void {
    if (tariff.benefitStatus) {
      this.storageService.productAndServiceVariables.status_benefits =
        tagging.staticVariables.benefitsStatus[tariff.benefitStatus.toLowerCase()];
    }
  }
  checkTariffUndefinedItems(tariff: Tariff): Tariff {
    for (const tariffItem in tariff) {
      if (!Array.isArray(tariff[tariffItem]) && tariff[tariffItem] === undefined ) {
        tariff[tariffItem] = null;
      }
    }
    return tariff;
  }
  checkTariffStatus(tariff: Tariff): void {
    if (tariff.Status.toLowerCase() === TariffStatus.TvPackagePending.toLowerCase()) {
      this.upgradeTvPending = true;
    }
    if (tariff.Status.toLowerCase() === TariffStatus.Active.toLowerCase()) {
      this.Tariff = tariff;
    }
    else {
      this.inactiveTariffs.push(tariff)
    }
  }
  mapVoucher(item: TariffItemsResponse, tariff: Tariff): void {
    const vouchers: Voucher[] = JsonQuery.value(item, JSON_PATHS.Tariff.vouchers) || [];
    if (vouchers.length) {
      tariff.vouchers = this.tariffHelper.mapVouchers(vouchers);
    }
  }
}


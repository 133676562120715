import { MicroFlowsService } from './micro-flows.service';

import {catchError, map} from 'rxjs/operators';

import { LOCAL_STORAGE_KEYS, CommercialJourneys } from './../constants/defines';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../core/services/storage.service';
import { HttpHeaders } from '@angular/common/http';
import { UserSettingToken } from '../../shared/enums/user-setting-token.enum';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { EntryPointResponseModel } from '../../models/entry-points.model';
import { OnepGroupList } from '../../models/oneProfesionalData.model';
@Injectable()
export class OpProductsListService {
  public selecteElement: string;
  public selecteElementName: string;
  public version: string;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    public subscriptionService: SubscriptionService,
    private translate: TranslateService,
    public microFlowsService: MicroFlowsService
  ) {}

  actualizeAuthTicket() {
    return  this.microFlowsService.getTokens(UserSettingToken.DxlAuth,
      this.subscriptionService.customerData.customerAccountsId,
      this.subscriptionService.customerData.currentService.id, false)

  }

  getProductsList(): Observable<OnepGroupList> {
    this.version = this.translate.instant('v10.commercial.fullVersionPortalEP');
    const url: string = API_URLS.OpProductList.getOpProductListTyp.replace('{siteId}',
    this.subscriptionService.customerData.currentService.siteId).replace('{lastVersion}',
    this.version);
    const headers = new HttpHeaders();
    headers.set('auth_ticket', this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT));
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: any) => {
     return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getEntryPointsCod(cod: string): Observable<EntryPointResponseModel> {
    const url: string = API_URLS.EntryPointManagement.entryPointsWithVersion
    .replace('{screenCode}', cod)
    .replace('{customerAccountId}', this.subscriptionService.customerData.currentService.siteId)
    .replace('{serviceId}', this.subscriptionService.customerData.currentService.id)
    .replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel)
    .replace('{version}', this.translate.instant('v10.commercial.versionPortalEP'));
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('auth_ticket', this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT));
    const options: { [key: string]: HttpHeaders } = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: EntryPointResponseModel) => {
      return res;
    }), catchError(error => {
      return throwError(error);
    }));
  }

  getProduct(selectedGroup: string) {
    const url = API_URLS.OpProductList.getOpProduct.replace('{siteId}',
    this.subscriptionService.customerData.currentService.siteId).replace('{selectedGroup}',
    selectedGroup);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((res: any) => {
     return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  getTryAndBuyState(description: number, newVersion: boolean): any {
    const url: string = API_URLS.TryAndBuy.getTryAndBuyProductCode.replace('{customerAccountId}',
    this.subscriptionService.customerData.customerAccountsId).replace('{productCode}', description.toString())
    .replace('{newVersion}', newVersion.toString());

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options: Object = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((res: any) => {
     return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

}

import { Component, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AppService } from '../../app.service';
import { PaymentErrorService } from '../services/payment-error.service';
import { TaggingClickDataModel } from '../../models/tagging.model';
import { marketplaceTagging } from '../../marketplace/tagging/marketplaceTagging';
import { MarketplaceService } from '../../marketplace/services/marketplace.service';

@Component({
  selector: 'sp-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() firstBtnAction: Function;
  @Input() isDimmed: boolean = false;
  // default dimming time if not sent is 30 sec
  @Input() dimmingTime: number = 30;

  pageTagData: TaggingClickDataModel =  marketplaceTagging.marketplaceTabsTab.myBillingPage;

  constructor(public paymentErrorService: PaymentErrorService,
    public appService: AppService,
    public marketplaceService: MarketplaceService
  ) { }
  ngOnInit(): void {
    if (this.isDimmed) {
      setTimeout(() => {
        this.isDimmed = false;
      }, this.dimmingTime * 1000);
    }
  }

  ngAfterViewInit(): void {
    this.marketplaceService.init(true, true);
  }

  /**
  * the default implmentaion of the first btn in delete card confiramtion screen
  */
  onFirstBtnAction(): void {
    if (this.isDimmed) {
      return;
    } else {
      if (this.firstBtnAction) {
        this.firstBtnAction();
      } else {
        this.paymentErrorService.currnetError.firstBtnAction()
      }
    }

  }

  ngOnDestroy(): void {
    this.marketplaceService.destroy();
  }

}

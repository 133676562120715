import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AuthenticationGuard } from './guards/authentication.guard';
import { AuthorizationGuard } from './guards/authorization.guard';
import { AuthorizedUserGuard } from './guards/authorizedUser.guard';


import { DisableDeepLinksGuard } from './guards/disable-deeplinks.guard';
import { MicroFlowDeactivateGuard } from './guards/micro-flow-deactivate.guard';
import { MicroFlowsGuard } from './guards/micro-flows.guard';
import { PreLoginGuard } from './guards/preLogin.guard';
import { FederationGuard } from './guards/federation.guard';

import { PostPaidGuard } from './guards/postpaid.guard';
import { PreloadGuard } from './guards/preload.guard';
import { ReloadGuard } from './guards/Reload.guard';
import { UpgradeProfileGuard } from './guards/upgrade-profile.guard';
import { PrepaidGuard } from './guards/prepaid.guard';
import { BizTalkReplicaModeGuard } from './guards/biz-talk-replica-mode.guard';
import { CommonService } from './services/common.service';
import { ErrorHandlingService } from './services/errorHandling.service';
import { ErrorHadlingUsingNotificationService } from './services/error-hadling-using-notification.service';
import { EverythingOkService } from './services/everything-ok.service';
import { HashService } from './services/hash.service';
import { JWTHelper } from './services/jwt.helper';
import { LocationService } from './services/location.service';
import { NewTaggingHelperService } from './services/new-tagging.helper.service';
import { RoutesConfigService } from './services/routes-config.service';
import { StorageService } from './services/storage.service';
import { SubscriptionService } from './services/subscription.service';
import { AuthenticateService } from './services/authenticate.service';
import { TaggingHelperService } from './services/tagging.helper.service';
import { ConfigurationService } from './services/configuration.service';
import { configLoaderFactory } from '../shared/utils/common.factory';
import { TranslateService } from '@ngx-translate/core';
import { SmapiService } from './services/smapi.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configLoaderFactory,
      deps: [ConfigurationService, TranslateService, SmapiService],
      multi: true
    },
    AuthenticationGuard,
    AuthorizationGuard,
    AuthorizedUserGuard,
    BizTalkReplicaModeGuard,
    DisableDeepLinksGuard,
    FederationGuard,
    MicroFlowDeactivateGuard,
    MicroFlowsGuard,
    PostPaidGuard,
    PreLoginGuard,
    PreloadGuard,
    PrepaidGuard,
    ReloadGuard,
    UpgradeProfileGuard,
    CommonService,
    ErrorHandlingService,
    ErrorHadlingUsingNotificationService,
    EverythingOkService,
    HashService,
    JWTHelper,
    LocationService,
    NewTaggingHelperService,
    RoutesConfigService,
    StorageService,
    SubscriptionService,
    AuthenticateService,
    TaggingHelperService,
    ConfigurationService,
    SmapiService,
  ]
})
export class CoreMVAModule {
}

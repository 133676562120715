<div class="top-up-rows top-up-rows-responsive">
  <div class="ticket-container">
    <canvas class="ticket-canvas" #ticketCanvas></canvas>
    <img src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ticket@2x.png" class="ticket-img"
      [ngClass]="paySuccessSer.paymentTicket.ticketNote !== '' ? 'with-ticket-note' : ''">
    <div class="ticket-header" [ngClass]="paySuccessSer.paymentTicket.ticketNote !== '' ? 'ticket-header-with-note' : ''">
      <img id="TicketTckImg" [src]="topupService.paymetTicketIcon" alt="" class="payment-img">
      <p id="paymentPaymentTicketSubtitle">{{paySuccessSer.paymentTicket.subtitle}}</p>
    </div>
    <div class="ticket-details" [ngClass]="paySuccessSer.paymentTicket.ticketNote !== '' ? 'ticket-details-with-note' : ''">
      <div class="detail-item" *ngFor="let item of paySuccessSer.paymentTicket.ticketDetails, let i = index">
        <p [id]="'paymentPaymentTicketDetailItemKey'+ i" class="title">{{item.key}}</p>
        <p [id]="'paymentPaymentTicketDetailItemValue'+ i" class="text">{{item.value}}</p>
      </div>
      <div class="ticket-note" *ngIf="paySuccessSer.paymentTicket.ticketNote && paySuccessSer.paymentTicket.ticketNote !== ''">
        <p id="paymentPaymentTicketNote">{{paySuccessSer.paymentTicket.ticketNote}}</p>
      </div>
    </div>
  </div>
  <div class="ticket-buttons-container">
    <button type="button" class="continue button red two" id="paymentPaymentTicketDownloadBtn"
      (click)="downLoadTicket()">{{paySuccessSer.paymentTicket.firstBtnText}}</button>
    <button id="paymentPaymentTicketSecondBtnAction" *ngIf="paySuccessSer.paymentTicket.firstBtnText" type="button" class="cancel button white two"
      (click)="paySuccessSer.paymentTicket.secondBtnAction()">{{paySuccessSer.paymentTicket.secondBtnText}}</button>
  </div>
</div>

<div class="toast-download"
  [ngClass]="{'open-animation-toast': showDownloadedToast, 'close-animation-toast': !showDownloadedToast, 'show-download-toast': !hideDownloadedToast, 'hide-download-toast': hideDownloadedToast}">
  <div [ngClass]="{'close-animation-text-fade-out': !showDownloadedToast}">
    <canvas #tickCanvas class="tick-canvas"></canvas>
    <p id="paymentPaymentTicketToast">{{paySuccessSer.paymentTicket.toast}}</p>
  </div>
</div>
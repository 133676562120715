import { Component } from '@angular/core';
import { LandingComponent } from '../../../products-and-services/landing/landing.component';
import { Product } from '../../../models/product.model';

@Component({
  selector: 'sp-landing-fixed-to-mobile-bundle',
  templateUrl: './landing-fixed-to-mobile-bundle.component.html',
  styleUrls: ['./landing-fixed-to-mobile-bundle.component.scss']
})
export class LandingFixedToMobileBundleComponent {
  public parent: LandingComponent
  public Bundle: Product
  public isUnlimited: boolean = false;

  constructor() {}

}

<div class="cancel__container"
[ngClass]="{'fade-out-tray': billingService.fadeOutTransition,
'forward-transition-tray': billingService.forwardTransition,
'backward-transition-tray': billingService.backwardTransition}">
  <div class="cancel__cont">
    <div class="cancel__icon">
      <img [src]="imgUrl" alt="warning-img"/>
    </div>
    <p class="cancel__title" [innerHTML]="title"></p>
  </div>
  <div class="cancel__btns-cont">
    <div class="cancel__first-btn">
      <mva10-c-button [body]="firstBtnDesc" [styleClass]="'mva10-c-button--primary'" (click)="continuePayment()">
      </mva10-c-button>
    </div>
    <div class="cancel__second-btn">
      <mva10-c-button [body]="secondBtnDesc" [styleClass]="'mva10-c-button--tertiary'" (click)="cancelPayment()">
      </mva10-c-button>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Validation } from '../../../shared/constants/defines';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { Breakpoints } from '@mva10/mva10-angular';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentCompoentInputData_Keys } from '../../../shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentJourneyEnvironment } from '../../../shared/enums/payment-journey-environment.enum';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';
import { PaymentNavigationService } from '../../services/payment-navigation.service';
import { BillingService } from '../../../billing/billing.service';
import { tagging } from '../../../../config/tagging-config';
import { CrossVariables } from '../../../models/tagging.model';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';

@Component({
  selector: 'sp-payment-cash-desk-user',
  templateUrl: './payment-cash-desk-user.component.html',
  styleUrls: ['./payment-cash-desk-user.component.scss']
})
export class PaymentCashDeskUserComponent implements OnInit {
  /** amount of the selected item in bill payment page for cash desk user */
  cashDeskAmount: number;
  /** declare the form */
  partialPaymentForm: UntypedFormGroup = this.fb.group({
    payPartialField: ['', [this.amountValidator()]]
  });
  /** images variables */
  visaImage: string;
  masterCardImage: string;
  winkImage: string;
  /** bottom right text in the tray */
  securedSslText: string;
  /** WCS variables */
  totalPaymentCashDeskWcsData:
    { description: string, icon1: string, icon2: string, confirmButtonText: string, partialOption: string, payPartialButtonText: string } =
    { description: '', icon1: '', icon2: '', confirmButtonText: '', partialOption: '', payPartialButtonText: '' };

  partialPaymentCashDeskWcsData: {
    title: string, subtitle: string, description: string, confirmButtonText: string,
    textboxPlaceholder: string, paidAmountErrorMsg: string, icon: string
  } =
    { title: '', subtitle: '', description: '', confirmButtonText: '', textboxPlaceholder: '', paidAmountErrorMsg: '', icon: '' };
  /** should include the error message that should be appeared once user enters a value more than maximum */
  amountMoreThanMaxMsg: string = '';
  /** should include the error message that should be appeared once user enters an invalid value */
  invalidValueMsg: string = '';
  /** Show and hide validation error of the entered value in partial payment */
  showErrorMsg: boolean = false;
  /** Show and hide X button in the text field */
  showXIcon: boolean = true;
  /** to handle the height of tray when keyboard is opening */
  isFieldFocused: boolean = false;
  /** to change UI in case of desktop view */
  isDesktopView: boolean;
  /** to get different image in case of mobile view */
  isMobileView: boolean;
  @Input() showPayPartial: boolean = false;
  constructor(
    public billingService: BillingService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private appService: AppService,
    private taggingHelperService: TaggingHelperService,
    public paymentWalletService: PaymentWalletService,
    private paymentNavigationService: PaymentNavigationService
  ) { }

  ngOnInit() {
    /** check if the device is desktop */
    this.isDesktopView = window.innerWidth >= Breakpoints.DESKTOP;
    this.isMobileView = window.innerWidth < Breakpoints.TABLET;
    /** get the amount of the selected item in bill payment page for cash desk user */
    this.cashDeskAmount = this.billingService.cashDeskAmount;
    this.translate.get('payment').subscribe((data) => {
      /** get Data from wcs for total payment */
      this.totalPaymentCashDeskWcsData.description = data.messagesList.cashDeskPayment.description;
      this.totalPaymentCashDeskWcsData.confirmButtonText = data.messagesList.cashDeskPayment.confirmButton.text;
      this.totalPaymentCashDeskWcsData.partialOption = data.itemsList.partialPaymentOption.body.replace('{0}',
        this.billingService.roundAmount(this.cashDeskAmount));
      this.totalPaymentCashDeskWcsData.payPartialButtonText = data.buttonList.partialPaymentButton.text;
      /** get Data from wcs for partial payment */
      this.partialPaymentCashDeskWcsData.title = data.messagesList.pendingAdvancedPayment.title;
      this.partialPaymentCashDeskWcsData.subtitle = data.messagesList.pendingAdvancedPayment.subtitle;
      this.partialPaymentCashDeskWcsData.description = data.messagesList.pendingAdvancedPayment.description;
      this.partialPaymentCashDeskWcsData.confirmButtonText = data.messagesList.pendingAdvancedPayment.confirmButton.text;
      this.partialPaymentCashDeskWcsData.textboxPlaceholder = data.itemsList.partialAmountToPay.body;
      this.partialPaymentCashDeskWcsData.paidAmountErrorMsg = data.messagesList.paidAmountErrMsg.description;
      this.invalidValueMsg = data.messagesList.paidAmountErrMsg.description;
      this.amountMoreThanMaxMsg = data.itemsList.cashDeskNewErrorMsg.body;
      this.partialPaymentCashDeskWcsData.icon = this.appService.getImgFullPath(data.messagesList.paidAmountErrMsg.icon.url);
      /** get Data from wcs for total, partial footer images and text */
      this.securedSslText = data.itemsList.securedSsl.body;
      this.visaImage = this.appService.getImgFullPath(data.paymentMethods.images.pciVisa.url);
      this.masterCardImage = this.appService.getImgFullPath(data.paymentMethods.images.pciMasterCard.url);
      /** get data from wcs for mobile and responsive wink image */
      if (this.isMobileView) {
        this.winkImage = this.appService.getImgFullPath(data.messagesList.cashDeskPayment.icon.url);
      } else {
        this.winkImage = this.appService.getImgFullPath(data.images.cashDeskResponsiveIcon.url);
      }
    });
    if (this.showPayPartial) {
      tagging.cashDeskPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
      tagging.cashDeskPaymentStartPartialJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
            PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
      this.taggingHelperService.view(tagging.cashDeskPaymentStartPartialPageName, tagging.cashDeskPaymentStartPartialJourney);
    } else {
      tagging.cashDeskPaymentStartJourney.journey_category = CrossVariables.client_typology;
      tagging.cashDeskPaymentStartJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
            PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
      this.taggingHelperService.view(tagging.cashDeskPaymentStartPageName, tagging.cashDeskPaymentStartJourney);
    }
  }
  /**
  * Executed when click on pay partial button in total screen to show pay partial screen
  */
  payPartial(): void {
    this.paymentNavigationService.openPaymentComponent(PaymentPages.partialCashDesk, PaymentJourny.postcashDeskPartial ,
      [{ key: PaymentCompoentInputData_Keys.payment_debt_cashdesk_showPayPartial, value: true }]);
  }
  /**
   * Excuted when click on continuar Button to make total or partial payment.
   * Remove € sign from text field when submit value.
   * Check if value contains comma replace it with dot.
   */
  onSubmit(): void {
    let cashDeskAmount: number;
    if (this.showPayPartial) {
      if (this.partialPaymentForm.controls['payPartialField'].value.includes(',')) {
        const removeCommaFromAmount: any = this.partialPaymentForm.controls['payPartialField'].value.replace(',', '.');
        cashDeskAmount = +removeCommaFromAmount.replace('€', '');
      } else {
        cashDeskAmount = +this.partialPaymentForm.controls['payPartialField'].value.replace('€', '');
      }
    } else {
      cashDeskAmount = this.cashDeskAmount;
    }
    this.translate.get('payment.itemsList.cashDeskType.body').subscribe((concepto) => {
      this.billingService.openPaymentModule(cashDeskAmount.toString(),
        this.showPayPartial ? PaymentJourny.postcashDeskPartial : PaymentJourny.postcashDeskTotal, concepto)
    });
  }
  /**
   * @return a regex that accept only numbers and single comma in text field.
   */
  numbersAndSingleCommaOnly(): RegExp {
    const regexForNumbersAndSingleComaOnly: RegExp = new RegExp(Validation.numbersAndCommaOnly);
    return regexForNumbersAndSingleComaOnly;
  }
  /**
   * Validation Fn to validate form against it.
   * Make text field must have a value , accept only numbers , single comma , and value smaller than or equal dept amount.
   */
  amountValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        this.showErrorMsg = false;
        return { invalid: true };
      } else if (control.value && this.numbersAndSingleCommaOnly().test(control.value)
        && (+control.value.replace(',', '.') > 0 && +control.value.replace(',', '.') <= this.cashDeskAmount)) {
        if (control.value.includes('.')) {
          setTimeout(() => {
            control.setValue(control.value.replace('.', ','));
          }, 300);
        }
        this.showErrorMsg = false;
        return null;
      } else {
        /** Check if amount more than maximum value or it's invalid value */
        if ((+control.value.replace(',', '.') > this.cashDeskAmount)) {
          this.partialPaymentCashDeskWcsData.paidAmountErrorMsg = this.amountMoreThanMaxMsg;
        } else {
          this.partialPaymentCashDeskWcsData.paidAmountErrorMsg = this.invalidValueMsg;
        }
        this.showErrorMsg = true;
        return { invalid: true };
      }
    }
  }
  /**
   * Add € sign to valid text field value when blur or focus out from it.
   * Reset validators to null to make submit button not disabled when value is valid and € added to text field.
   * Hide x button inside text field when blur.
   * Revert isFieldFocused to make tray height as before
   */
  addEurSignToValidInputOnBlur(): void {
    setTimeout(() => {
      this.isFieldFocused = false;
      this.showXIcon = false;
      if (this.partialPaymentForm.valid && this.partialPaymentForm.controls['payPartialField'].value) {
        this.partialPaymentForm.controls['payPartialField'].setValidators(null);
        this.partialPaymentForm.controls['payPartialField'].setValue(this.partialPaymentForm.controls['payPartialField'].value + '€');
      }
    }, 1);
  }
  /**
   * Check if text field value contains € sign which added on blur and then reomove it on focus in text field.
   * Reset Validators again to validator fn.
   * Reset showXIcon boolean to true again after blur.
   * Set isFieldFocused boolean to true on focus.
   */
  onFocus(): void {
    this.showXIcon = true;
    this.isFieldFocused = true;
    if (this.partialPaymentForm.controls['payPartialField'].value
      && this.partialPaymentForm.controls['payPartialField'].value.includes('€')) {
      this.partialPaymentForm.controls['payPartialField'].
        setValue(this.partialPaymentForm.controls['payPartialField'].value.replace('€', ''));
      this.partialPaymentForm.controls['payPartialField'].setValidators([this.amountValidator()]);
    }
  }
  /**
   * Executed when click on x button inside text field.
   * Reset showXIcon boolean to true again after blur.
   * Reset Validators again to validator fn.
   */
  clickXButtonInTextField(): void {
    this.showXIcon = true;
    this.isFieldFocused = true;
    this.partialPaymentForm.controls['payPartialField'].setValidators([this.amountValidator()]);
  }
}


import { Address } from './Address.model';
import { AddressAccountEditDetailsViewModel } from './account-edit-details.model';
import { Permission } from './permission.model';
import { CustomerAccountSignStatus } from '../shared/enums/customer-account-sign-status.enum';

export class CustomerAccount {
  public id: string;
  public type: string;
  public firstName: string;
  public middleName: string;
  public familyName: string;
  public msisdn: string;
  public email: string;
  public address: Address;
  public companyId: string;
  public status: string;
  public statusSP: string;
  public segment: string;
  public xmasVoucher?: string;
  public parts: Array<any>;
  public debtAmount?: number;
  public chatSegment?: string;
  public emailStatus?: string;
  public newEmail?: string;
  public confirmedEmail?: string;
  clientType?: string;
  digital?: boolean;
  marketSegment?: string;
  onePlus?: boolean;
  onePlusMicro?: boolean;
  overdue?: boolean;
  signUpDate?: number;
  smartPay?: boolean;
  marketType?: string;
  public pendingSign?: boolean;
}

export interface CustomerAccountResponseModel {
  firstName?: string;
  count: number;
  offset: number;
  items: CustomerAccountResponseItem[];
  chatSegment?: string;
}

export interface CustomerAccountResponseItem {
  id: string;
  cif: string;
  type: string;
  status: string;
  address: {
    formattedAddress: string;
  };
  overdue: boolean;
  digital: boolean;
  onePlus: boolean;
  onePlusMicro: boolean;
  pdteFirma?: CustomerAccountSignStatus;
  smartPay?: boolean;
  name?: string;
  parts?: any[];
  clientType?: string;
  marketType?: string;
}

export class CustomerAccountNewModel {
  nif?: string;
  accountId?: string;
  msisdn?: string;
  fullName?: string;
  name?: string;
  firstSurname?: string;
  secondSurname?: string;
  billingAddress?: AddressAccountEditDetailsViewModel;
  billingAccountId?: string;
  contact?: {
    favouritePhone?: string;
    // Keys from here are based on MyAccountDetailsType enum
    vf?: string;
    email?: string;
    mobile?: string;
    landline?: string;
    additional?: string;
    postaladdress?: AddressAccountEditDetailsViewModel;
  };
}

export class UpdatedPaymentAddress {
  buildingName?: string;
  buildingNumber?: number | string;
  level?: string;
  street?: string;
  town?: string;
  country?: string;
  postCode?: string;
}

export class CustomerAccountAuthorizedPatch {
  individual?: {
    firstName?: string;
    middleName?: string;
    familyName?: string;
  };
  contactPoints?: ContactPoint[];
}

export class PermissionsModel {
  isOpen?: boolean;
  permissions?: Permission[];
}

// Authorized user
export interface MyAccountAuthorizedResponseModel {
  company: Company;
  contactPoints: ContactPoint[];
  extension: Extension;
  infoContact: InfoContact;
}

interface InfoContact {
  numDocument: string;
  name: string;
  lastName: string;
  secondLastName: string;
  telephone: string;
  email: string;
}

interface Extension {
  es: {
    document: Document;
    permissions: Permission[];
  };
}

interface Document {
  id: string;
  type: string;
}

export interface ContactPoint {
  fixedLine: {
    phoneNumber: string;
  };
  mobile: {
    msisdn: string;
  };
  email: {
    fullAddress: string;
  };
}

interface Company {
  id: string;
  name: string;
}

export class FoundSelector {
  code?: string;
  description?: string;
  digital?: boolean;
  id?: string;
  instance?: string;
  internetSpeed?: string;
  links?: string;
  name?: string;
  parts?: Parts [];
  type?: string;
}

interface Parts {
  customerAccounts?: CustomerAccount[]
  productOffers?: ProductOffer[]
}

interface ProductOffer {
  name?: string;
  type?: string;
}


export class CommercialCapacity {
  public capacidad: string;
  public valor: string;
  public unidad: string;

  static equal(obj1: CommercialCapacity, obj2: CommercialCapacity): boolean {
    return obj1.capacidad === obj2.capacidad && obj1.valor === obj2.valor && obj1.unidad === obj2.unidad;
  }
}

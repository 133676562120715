<div class="top-up-rows forward-transition-tray" [ngClass]="{'fade-out-tray':hideContent, 'topup-rows-responsive': topupService.useDynamicTray,'height-with-percentage': topupService.useDynamicTray, 'height-with-vh': !topupService.useDynamicTray}">
  <div class="top-up-title-with-exit-icon-container" [ngClass]="{'hide-title': topupService.useDynamicTray}">
    <img class="header-img" src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/red-small@2x.png">
    <div class="top-up-title">
      <p>{{uiModel.refills}}</p>
    </div>
    <div class="exit-logo" *ngIf="topupService.isMobileView">
      <span (click)="closeTrayWithTagging()" class="icon icon-close"></span>
    </div>
  </div>
<div class="confirmation-container" [ngClass]="{'confiramtion-container-small-margin': showSaveCardToggle}">
    <div class="lottiee-loader-container">
      <sp-lottie-loader [lottieImageUrl]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/tick.json'" [loop]="false"></sp-lottie-loader>
    </div>
    <div class="confirmation-title" [innerHTML]="uiModel.sucessTopupMsg_title">
    </div>
    <div class="confirmation-desc">
      <p *ngIf="confirmationDescLine1" [innerHTML]="confirmationDescLine1"></p>
      <p *ngIf="confirmationDescLine2"[innerHTML]="confirmationDescLine2"></p>

    </div>
    <div class="save-card-container" *ngIf="showSaveCardToggle">
      <p class="save-card-text">{{'payment.messagesList.sucessTopupMsg.subtitle'|translate}}</p>
      <sp-toggle-button [disable]="false" [check]="saveCreditCard" class="toggel" (option)="onSaveCreditCardToggleChanges($event)"></sp-toggle-button>
    </div>
  </div>
  <div class="iframe-error-retry-button" [ngClass]="{'iframe-error-retry-button-small-margin': showSaveCardToggle}">
      <button (click)="confirmationButtonClick()"  id="PciSuccess_btn_confirmation" type="button" class="button red two cancel" [ngClass]="{'anonymous-desktop': topupService.isAnonymousProcess}">{{confirmationButtonText}}</button>
      <button *ngIf="secondButtonText" (click)="secondButtonClick()"  id="PciSuccess_btn_second" type="button" class="button white two cancel" [ngClass]="{'anonymous-desktop': topupService.isAnonymousProcess}">{{secondButtonText}}</button>
  </div>
</div>

import {map} from 'rxjs/operators';
import { CompanyService } from './../shared/services/company.service';
import { Injectable } from '@angular/core';
import { API_URLS } from '../shared/constants/routes-config';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BillPaymentProcessRequest } from '../models/bill-payment-process-request.model';
import { BillPaymentConfirmationRequest } from '../models/bill-payment-confirmation-request.model';
import { NetPlusIframeRequest } from '../models/netplus-iframe-request.model';
import { TopupPatchModel } from '../models/top-up-patch.model';
import { CONFIG, billingServiceApiVersion, getPaymentCommitmentHeader } from '../shared/constants/defines';
import { BillDetailsResponseModel } from '../models/bill-details-vf10.model';
import { Observable } from 'rxjs';
import { BillingListResponse } from '../models/billing.model';
import { BillReviewTicketRequestBody } from '../models/bill-review-ticket-requestBody.model';
import { PaymentCommitmentResponse } from '../models/payment-commitment-response.model';
import { EnergyDebtResponse } from '../models/energy-debt-model';
import { NifAndCif } from './nif-and-cif.modal';

@Injectable({
  providedIn: 'root',
})
export class BillingData {
  constructor(public http: HttpClient, public companyService: CompanyService) { }

  GetBills(type?: string, status?: string, offset?: number): Observable<BillingListResponse> {
    let url: string = API_URLS.Bill.GetBills;
    if (this.companyService.selectedCompanyId) {
      url += `?holderId=${this.companyService.selectedCompanyId}`;
    }
    if (type) {
      url += `${url.indexOf('?') > -1 ? '&' : '?'}type=${type}`;
    }
    if (status) {
      url += `${url.indexOf('?') > -1 ? '&' : '?'}status=${status}`;
    }
    if (offset) {
      url += `${url.indexOf('?') > -1 ? '&' : '?'}offset=${offset}`;
    }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('version', billingServiceApiVersion);

    return this.http.get(url, { headers }).pipe(map((res: BillingListResponse) => {
      return res;
    }
    ));
  }

  /**
   * call api of customerBills and send siteId to it to get bills related to that site
   * @param siteId {string} user siteId
   */
  getUserBillsBySiteId(siteId: string): Observable<Object> {
    const url: string = `${API_URLS.Bill.GetCustomerBill.replace('{siteId}', siteId)}&type=bill`;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('version', '2.0');
    const options: any = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    })
    );
  }

  getBillsWithStatus(billingAccountID: string, status: string) {
    const url: string = `${API_URLS.Bill.GetBills}?type=bill`;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('version', billingServiceApiVersion);

    return this.http.get(url, { headers }).pipe(map((res) => {
      return res;
    }
    ));
  }
  GetNotification(notificationId: string) {
    const url: string = API_URLS.Bill.GetBillNotification.replace('{id}', notificationId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    }));
  }
  loadBillDetails(billId: string): Observable<BillDetailsResponseModel> {
    const url: string = API_URLS.Bill.GetBillDetails.replace('{id}', billId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: BillDetailsResponseModel) => {
      return res;
    }));
  }
  loadBillDetailsServiceUsages(billId: string, serviceId: string) {
    const url: string = API_URLS.Bill.GetServiceUsages
      .replace('{billId}', billId)
      .replace('{serviceId}', serviceId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    }
    ));
  }
  getBillingAccountById(SiteId: string, billingAccountId: string, fileds?: string) {
    let url = API_URLS.Bill.GetConfigurations.replace('{id}', SiteId).replace('{billingAccountId}', billingAccountId);
    if (fileds) {
      url += `?fields=${fileds}`;
    }
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    }));
  }
  patchBillConfiguration(value: any, path: string, operation: string, siteId: string, billingAccountId: string) {
    const body = {
      'op': operation,
      'path': path,
      'value': value
    };
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json-patch+json');
    const options = {
      headers: headers
    };
    const url: string = API_URLS.Bill.PatchConfiguration.replace('{id}', siteId)
      .replace('{billingAccountId}', billingAccountId);
    return this.http.patch(url, body, options).pipe(map(res => res));
  }
  sendEmail(reqBody: any) {
    const url: string = API_URLS.Bill.sendEmail;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    const body = reqBody;
    return this.http.post(url, body, options).pipe(map(res => res));
  }
  getCurrentConsumption(siteId: string, serviceId: string) {
    const url: string = API_URLS.Bill.getCurrentConsumption.
      replace('{customerAccountId}', siteId).replace('{subscriptionId}', serviceId) + '?sortType=desc&sortField=startDate';
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res) => {
      return res;
    }
    ));
  }

  // call-Options
  getCallOptions(customerAccountId, serviceId) {
    const url: string = API_URLS.Bill.callOption.replace('{customerAccountId}', customerAccountId)
      .replace('{serviceId}', serviceId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(url, options).pipe(map((res: any) => {
      return res;
    }
    ))
  }
  patchCallID(requestBody: any, customerAccountId, serviceId) {
    const url: string = API_URLS.Bill.callOption.replace('{customerAccountId}', customerAccountId)
      .replace('{serviceId}', serviceId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    const options = {
      headers: headers
    };
    return this.http.patch(url, requestBody, options).pipe(map((res) => {
      return res;
    }
    ))
  }
  // bill Payment Process
  getBillIframeProcess(requestBody: BillPaymentProcessRequest, customerAccountId: string, subscriptionId: string) {
    const url: string = API_URLS.Bill.paymentProcessURL.replace('{customerAccountId}', customerAccountId)
      .replace('{subscriptionId}', subscriptionId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.post(url, requestBody, options).pipe(map(res => { return res; }));
  }

  // requestNetplusIframeForMva10Topup
  requestNetplusIframeUrlForMva10Topup(requestBody: NetPlusIframeRequest,
    customerAccountId: string, subscriptionId: string, isAnonymous = false) {
    const url: string = API_URLS.Bill.paymentProcessURL.replace('{customerAccountId}', customerAccountId)
      .replace('{subscriptionId}', subscriptionId);
    let headers: HttpHeaders = new HttpHeaders();
    if (isAnonymous) {
      headers = headers.append('Content-Type', 'application/json').append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
    } else {
      headers = headers.append('Content-Type', 'application/json')
    }
    const options = {
      headers: headers
    };
    return this.http.post(url, requestBody, options).pipe(map(res => { return res; }));
  }


  // payment confirmation
  paymentConfirmation(requestBody: BillPaymentConfirmationRequest, customerAccountId: string) {
    const url: string = API_URLS.Bill.PaymentConfirmation.replace('{customerAccountId}', customerAccountId);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.post(url, requestBody, options).pipe(map(res => { return res; }));
  }
  // ipdate status of Payment Process
  updatePaymentStatus(paramsObj, customerAccountId: string, subscriptionId: string, isAnonymous = false) {
    const url: string = API_URLS.Bill.paymentProcessURL.replace('{customerAccountId}', customerAccountId)
      .replace('{subscriptionId}', subscriptionId);
    let headers: HttpHeaders = new HttpHeaders();
    if (isAnonymous) {
      headers = headers.append('Content-Type', 'application/json-patch+json').append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
    } else {
      headers = headers.append('Content-Type', 'application/json-patch+json')
    }
    const options = {
      headers: headers
    };
    return this.http.patch<TopupPatchModel>(url, paramsObj, options).pipe(map(res => { return res; }));
  }

  public getBillTicketNumber(data: BillReviewTicketRequestBody): Observable<{id: string, href: string}> {
    const url: string = API_URLS.billReviewTicketCreation;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<{id: string, href: string}>(url, data, { headers });
  }

  public getPaymentCommitments(siteID: string): Observable<PaymentCommitmentResponse> {
    const url: string = API_URLS.CustomerDebt.GetUserCommitments.replace('{SiteID}', siteID);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json')
    .append(getPaymentCommitmentHeader.name, getPaymentCommitmentHeader.value);
    return this.http.get(url, {headers}).pipe(map((res: PaymentCommitmentResponse) => res));
  }

  public getEnergyDebt (siteID: string): Observable<EnergyDebtResponse> {
    const url: string = API_URLS.CustomerDebt.GetUserCommitments.replace('{SiteID}', siteID);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json')
    headers = headers.append('X-VF-API-Process', 'GetEnergyDebt')
    return this.http.get(url, {headers}).pipe(map((res: EnergyDebtResponse) => res));
  }

  getStatusbyID(billNo: string, nifAndCifData: NifAndCif): Observable<Object> {
    let url: string;
    if (nifAndCifData.isCIF) {
      url = API_URLS.Bill.GetStatusCIF.replace('{billId}', billNo).replace('{docID}', nifAndCifData.docId);
    } else {
      url = API_URLS.Bill.GetStatusNIF.replace('{billId}', billNo).replace('{docID}', nifAndCifData.docId);
    }
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-VF-API-Process', 'ConsultarEstadoFacturas');
    return this.http.get(url, { headers }).pipe(map((res) => res));
  }

}

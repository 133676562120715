import { ServiceType } from './../../shared/enums/serviceType.enum';
import { Injectable } from '@angular/core';
import { StorageService } from './../../core/services/storage.service';
import { SubscriptionService } from './../../core/services/subscription.service';
import { iotProductType } from './../../shared/constants/defines';

@Injectable()
export class VProductsPurchaseService {
  /**  Type for page Vproductspurchase */
  public productType: string;
  /** Type for page Vproductsactivation */
  public typeConfiguration: string;
  /** Type for page iot-page */
  public typePage: string;
  // Variables for Vproductspurchase
  public shippingMode: string;
  public shippingDirection: string;
  public selectedDay: string;
  /** Variable for tagging resume page */
  public selectedTab: string;
  constructor(
    private subscriptionService: SubscriptionService,
    private storageService: StorageService
  ) { }

  public generateTicketBody(): any {
    let template;
    let faultyCode: string;
    let triplet: string;
    switch (this.productType) {
      case iotProductType.vHomeMini:
        faultyCode = 'MIVF-ONEPLUS-CMVHO';
        triplet = 'OP_Vhome';
        break;
      case iotProductType.connectedCar:
        faultyCode = 'MIVF-ONEPLUS-CMVAU';
        triplet = 'OP_Vauto';
        break;
    }
    template = {
      ticketType: 'Peticion',
      description: this.getTicketDescription(),
      severity: '1',
      relatedParty: [
        {
          id: this.subscriptionService.customerData.customerAccountsId
        }
      ],
      note: [
        {
          id: 'FaultyCode',
          text: faultyCode
        },
        {
          id: 'Triplet',
          text: triplet
        },
        {
          id: 'ContactMethod',
          text: '1'
        }
      ]
    };
    return template;
  }

  public getDocumentumData() {
    return {
      siteId: this.subscriptionService.customerData.customerAccountsId,
      nif: this.storageService.userProfile.document.id,
      ticket: { description: this.getTicketDescription() }
    };
  }

  private getTicketDescription() {
    let type;
    switch (this.productType) {
      case iotProductType.vHomeMini:
        type = 'ONEPLUS - VHOME MINI';
        break;
      case iotProductType.connectedCar:
        type = 'ONEPLUS - CAR CONECTED';
        break;
    }
    let msisdn = '';
    this.subscriptionService.customerData.services.forEach(service => {
      if (
        service.type.toLowerCase() === ServiceType.Postpaid.toLowerCase() &&
        service.package !== null && service.package !== undefined
      ) {
        msisdn = msisdn.concat(`${service.id};`);
      }
    });
    return `${type}\n${msisdn}\n${this.shippingMode}\n${this.shippingDirection}\n${this.selectedDay}`;
  }
}

<div class="content-wrapper">
    <div class="sub-header">
        <span id="paymentBillingSubHeader" class="sub-header-text"
            [innerHtml]="'payment.itemsList.payments.body'|translate"></span>
    </div>
    <div class="bills-data-section">
        <div *ngIf="!showDebt">
            <div class="payment__box">
                <div class="payment__text-with-image">
                    <p id="paymentBillingInAdvanceTitle" class="payment__text margin-sm">
                        {{'payment.messagesList.payInAdvanceLanding.title' | translate}}
                    </p>
                    <p id="paymentBillingInAdvanceDescription" class="payment__desc">
                        {{'payment.messagesList.payInAdvanceLanding.description' | translate}}</p>
                    <img id="paymentBillingInAdvanceIcon" class="payment__wink-image"
                        src="{{appService.getImgFullPath('payment.messagesList.payInAdvanceLanding.icon.url'|translate)}}"
                        alt="wink" />
                </div>
                <div class="payment__open-inadvance-overlay">
                    <mva10-c-button id="paymentBillingInAdvanceConfirmationBtn"
                        [body]="'payment.messagesList.payInAdvanceLanding.confirmButton.text' |translate"
                        (click)="payInAdvance()" [styleClass]="'mva10-c-button--primary'"
                        [name]="'payment.messagesList.payInAdvanceLanding.confirmButton.text' |translate"
                        [title]="'payment.messagesList.payInAdvanceLanding.confirmButton.text' |translate">
                    </mva10-c-button>
                </div>
            </div>
        </div>
        <div class="page-body" *ngIf="showDebt">
            <div class="dept">
                <div class="dept-box-header">
                    <div id="paymentBillingDebtTitle" class="header-left-title">
                        {{'payment.messagesList.debtPaymentLanding.title' |translate}}</div>
                </div>
                <div class="pending-payment-info"
                *ngIf="(showDebt && anonPayService.anonymousPaymentCustomer?.dept?.state == paymentReflectionStatus.NotEligible)">
                    <sp-alert-info [alertColor]="'#005ea5'" [iconSVG]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/info-circle.svg' |imgFullPath"
                        [message]="'v10.payment.itemsList.anonymousPayment.es.paymentNotReflected' |translate">
                    </sp-alert-info>
                </div>
                <div class="dept-box-body">
                    <div class="dept-box-header">
                        <div id="paymentBillingDebtSubTitle" class="dept-box-left">
                            {{'payment.messagesList.debtPaymentLanding.subtitle' | translate}}
                        </div>
                        <div id="paymentBillingDebtAmount" class="dept-box-right">
                            {{anonPayService.anonymousPaymentCustomer?.dept?.accountBalanceAmount
                            |currency:'EUR':'symbol'|sliceCurrency}}<span class="euro-sign">
                                {{anonPayService.anonymousPaymentCustomer?.dept?.accountBalanceAmountUnit}}
                            </span></div>
                    </div>
                    <div class="dept-box-body-cont">
                        <div id="paymentBillingDebtDescription" class="dept-box-des"
                            [innerHtml]="'payment.messagesList.debtPaymentLanding.description' |translate">
                        </div>
                        <div class="dept-box-btn">
                            <button id="paymentBillingDebtConfirmationBtn" (click)="pay()"
                                class="ternary-btn"
                                [ngClass]="{'dimmed-btn': anonPayService.anonymousPaymentCustomer?.dept?.state === paymentReflectionStatus.NotEligible}"
                                [disabled]="anonPayService.anonymousPaymentCustomer?.dept?.state == paymentReflectionStatus.NotEligible">
                                {{'payment.messagesList.debtPaymentLanding.confirmButton.text'
                                |translate}}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
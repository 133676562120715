import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDynamicTray } from '../models/dynamic-tray.model';
import { RoamingBundle } from '../models/roamingBundle.model';
import { DynamicTrayService } from '../shared/services/dynamic-tray.service';
import { SubscriptionService } from '../core/services/subscription.service';
import { RoamingService } from '../../app/shared/services/roaming.service'
import { map } from 'rxjs/operators';
import { RoamingBundleCard } from '../models/roamingBundleCard.model';
import { RestrictionsStatus } from '../shared/enums/restrictions-status.enum';
import { RoamingBundleTypes } from '../shared/enums/roamingBundleTypes';
import { RoamingBundleDeatils, romaingBundleDeailType } from '../models/romaingBundleDeatils.model';
import { RoamingCountryModel } from '../models/roamin-country.model';
import { Observable, Subject } from 'rxjs';
import { ZoneOneTrayComponent } from './zone-one-tray/zone-one-tray.component';
import { PoliticaZoneOneComponent } from './politica-zone-one/politica-zone-one.component';
import { Roaming, roamingToggles } from '../shared/constants/defines';
import { ProductDeatis, ProductItem, ProductOfferingPostObject, RoamingAction } from '../shared/models/roaming-productOrderItem';
import { roamingProductmangment } from '../shared/constants/defines';
import { LineRestrictionTypes } from '../shared/enums/line-restriction-types.enum';
import { IGenericSuccessErrorTray } from '../models/generic-success-error-tray.model';
import { AppService } from '../app.service';
import { tagging } from '../../config/tagging-config';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { TaggingJourney, TrackActionsObject, TrackActions, PaymentTaggingPageName } from '../models/payment';
import { ClientTypology } from '../shared/enums/clientTopology.enum';
import { StorageService } from '../core/services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class RoamingHelper {
    public closeTraySubject: Subject<boolean> = new Subject<boolean>();
    public selectedMsidnRoaming: string;
    public fisrtSelectedCountry: RoamingCountryModel;
    public secondSelectedCountry: RoamingCountryModel;
    public tarrifCode: string;
    public customerAccountId: string;
    public roamingBundleList: RoamingBundle[];
    public roamingBundleCards: RoamingBundleCard[];
    public roamingLoadingMode: boolean;
    public callingProductOfferingSucess: boolean;
    public callingRestrictionApiSucess: boolean;
    public callingRestrictionApifinished: boolean;
    public showOtherCardsDisabled: boolean = false;
    public showMainRoamingDisabled: boolean = false;
    public moundoCard: RoamingBundleCard = new  RoamingBundleCard();
    public worldCard: RoamingBundleCard = new  RoamingBundleCard();
    hideMainToggle: boolean;
    hideSecondryToggles: boolean;


    constructor(public translate: TranslateService,
        public dynamicTrayService: DynamicTrayService,
        public subscriptionService: SubscriptionService,
        public roamingService: RoamingService,
        private appTheme: AppService,
        private tagging: TaggingHelperService,
        private storageService: StorageService) { }

    /** will return Tray Zone one Tray data */
    drawZoneOneTray(): void {
        /** draw Tray of Zone 1 */
        let zoneOneTrayTitle: string;
        let zoneOneTrayBtnTxt: string;
        this.translate.get('v10.roaming.tray.countriesZone1').subscribe(data => {
            zoneOneTrayTitle = data.title;
            zoneOneTrayBtnTxt = data.button;
        })
        const trayZoneOneData: IDynamicTray = {
            basicTrayData: {
                title: zoneOneTrayTitle,
                secondaryButton: zoneOneTrayBtnTxt
            },
            closeButtonAction: () => { this.dynamicTrayService.close() },
            secondaryButtonAction: () => { this.dynamicTrayService.close() },
            overlayID: 'zone-one-tray'

        };
        this.dynamicTrayService.open(trayZoneOneData, ZoneOneTrayComponent);
    }
    drawPoliticaZoneOneTray(): void {
        /** draw Tray of Zone 1 politica */
        let zoneOnePoliticaTrayTitle: string;
        this.translate.get('v10.roaming.tray.fairUsePolicyZone1').subscribe(data => {
            zoneOnePoliticaTrayTitle = data.title;
        })
        const trayPoliticaZoneOneData: IDynamicTray = {
            basicTrayData: {
                title: zoneOnePoliticaTrayTitle,
                isScrollableTray: true
            },
            closeButtonAction: () => {
                this.dynamicTrayService.close();
            },
            backButtonAction: () => {
                this.dynamicTrayService.close();
            },
            showBackArrowWhenScrollToFullScreen: true
        };
        this.dynamicTrayService.open(trayPoliticaZoneOneData, PoliticaZoneOneComponent);
    }
    /** method for get roaming bundle */
    getRoamingBundles(): Observable<RoamingBundleCard[]> {
        let zoneId: string = this.fisrtSelectedCountry.zone;
        if ( this.fisrtSelectedCountry.zone === Roaming.ZoneOne && this.secondSelectedCountry.zone === Roaming.ZoneOne) {
            zoneId = Roaming.ZoneZero;
        }
        return this.roamingService.getRoamingEligibleProductOffering(
            this.customerAccountId, this.tarrifCode, zoneId,
            this.selectedMsidnRoaming).pipe(map(res => {
                this.roamingBundleList = [...res];
                this.getMissingdataFromWcs(this.roamingBundleList);
                this.mapBundelToComponentModel(this.roamingBundleList);
                this.moundoCard = this.roamingBundleCards.find(card => !card.hideTitleAndBorder);
                this.worldCard = this.roamingBundleCards.find(card => card.hideTitleAndBorder);
                return this.roamingBundleCards;
            }));
    }
    getMissingdataFromWcs(roamingBundleList: RoamingBundle[]): void {
        roamingBundleList.forEach(bundle => {
            this.getMissingbundleDeatilsFromWsc(bundle);
        });
    }
    /** method to get bunlde deatils from wcs in case it's not came from api  */
    getMissingbundleDeatilsFromWsc(tarrif: RoamingBundle): void {
        tarrif.deatils.forEach(bunlde => {
            const bundeltype: string = bunlde.type;
            const deatilsObj: RoamingBundleDeatils =  tarrif.deatils.find(element => element.type === bundeltype);

            this.translate.get('v10.roaming.landing.tarrifs').subscribe((data) => {
                if (tarrif.type === RoamingBundleTypes.World) {
                    deatilsObj.title = deatilsObj.title || data.world[bundeltype].name;
                    deatilsObj.icon = deatilsObj.icon || data.world[bunlde.type].icon;
                    deatilsObj.price = deatilsObj.price || data.world[bunlde.type].price;
                }
                else {
                    deatilsObj.title = deatilsObj.title || data.world[bundeltype].name;
                    deatilsObj.icon = deatilsObj.icon || data.world[bunlde.type].icon;
                    deatilsObj.price = deatilsObj.price || data.world[bunlde.type].price;
                }
                this.getBundledescription(tarrif, deatilsObj, data);
            });
        });
    }
    /** method to map roamingBundleList to roamingBundleCards to be used in component directly  */
    mapBundelToComponentModel(roamingBundleList: RoamingBundle[]): void {
        this.roamingBundleCards = new Array<RoamingBundleCard>(this.roamingBundleList.length);
        roamingBundleList.forEach((item: RoamingBundle, index: number) => {
            let bundleDeatils: RoamingBundleDeatils[] = new Array<RoamingBundleDeatils>(4);
            bundleDeatils = [...item.deatils];
            this.roamingBundleCards[index] = new RoamingBundleCard();
            this.roamingBundleCards[index].bundleDeatils = new Array<RoamingBundleDeatils>(4);
            this.roamingBundleCards[index].bundleDeatils = bundleDeatils;
            this.roamingBundleCards[index].status = item.status;
            this.roamingBundleCards[index].hideTitleAndBorder = (item.type === RoamingBundleTypes.World) ? true : false;
            this.translate.get('v10.roaming.landing.tarrifs').subscribe((data) => {
                this.roamingBundleCards[index].linkText = data.world.moreDetailsButton;
                if (item.type === RoamingBundleTypes.World) {
                    this.roamingBundleCards[index].textBelow = data.world.description;
                    this.roamingBundleCards[index].thirdTitle = data.world.subtitle1;
                    if (this.fisrtSelectedCountry.zone === Roaming.ZoneOne ) {
                        this.roamingBundleCards[index].hint =
                            (item['isLimitedTarrif'] === false) ? data.world.unlimitedTarrifDescription : null;
                        if (item['isLimitedTarrif']) {
                            this.getSubtitleForLimitedTarrif(index);
                        }
                       else if (this.secondSelectedCountry.zone === Roaming.ZoneOne && !item['isLimitedTarrif'] ) {
                        this.roamingBundleCards[index].thirdTitle = data.world.subtitle4;
                    }
                    else if (this.secondSelectedCountry.zone !== Roaming.ZoneOne && !item['isLimitedTarrif']) {
                        this.roamingBundleCards[index].thirdTitle = data.world.subtitle3;
                    }
                    }
                    this.roamingBundleCards[index].linkTextBottomDivider = true;
                }
                if (item.type === RoamingBundleTypes.Mundo) {
                    this.roamingBundleCards[index].cardTitle = data.mundo.header;
                    this.roamingBundleCards[index].SecondTitle = data.mundo.title;
                    this.roamingBundleCards[index].thirdTitle = data.mundo.subtitle1;
                    this.roamingBundleCards[index].thirdTitle =
                        this.roamingBundleCards[index].thirdTitle.replace('{0}', data.mundo.pricePerUse);
                    this.roamingBundleCards[index].textBelow = data.mundo.description;
                    this.roamingBundleCards[index].linkTextBottomDivider = false;
                    this.getMoundoStatusLabel(data, item, this.roamingBundleCards[index]);

                }
            });

        });
    }
    /** method to set status label
     * @param data is data from WCS
     * @param item is romaing bundle
    */
    getMoundoStatusLabel(data: any, item: RoamingBundle, bundlecard: RoamingBundleCard): void {
        switch (item.status) {
            case RestrictionsStatus.active:
                bundlecard.leftTitleText = data.activeStatus;
                bundlecard.btnText = data.buttonList.deactivateButton;
                break;
            case RestrictionsStatus.inactive:
                bundlecard.leftTitleText = data.deactiveStatus;
                bundlecard.btnText = data.buttonList.activateButton;
                break;
            case RestrictionsStatus.PendingActive:
                bundlecard.leftTitleText = data.pendingActivationStatus;
                bundlecard.btnText = data.buttonList.activateButton;

                break;
            case RestrictionsStatus.PendingInActive:
                bundlecard.leftTitleText = data.pendingDeactivationStatus;
                bundlecard.btnText = data.buttonList.deactivateButton;

                break;
        }
    }
       /**method to get bundle deatils description as it sometimes came from api and sometime else it came from wcs */
       getBundledescription(tarrif: RoamingBundle, deatilsObj: RoamingBundleDeatils, data: any): void {
        if (deatilsObj.type === romaingBundleDeailType.callssent || deatilsObj.type === romaingBundleDeailType.callsreceived ) {
          if (tarrif.type === RoamingBundleTypes.World) {
          if (deatilsObj.establishmentPrice) {
              deatilsObj.description = data.world[deatilsObj.type + 'establishment'].description;
              deatilsObj.description = deatilsObj.description.replace
              ('{0}', deatilsObj.establishmentPrice + deatilsObj.establishmentUnit );
             }
             else {
                 deatilsObj.description = data.world[deatilsObj.type].description;
             }
          }
          else {
              if (deatilsObj.establishmentPrice) {
                  deatilsObj.description = data.mundo[deatilsObj.type + 'establishment'].description;
                  deatilsObj.description = deatilsObj.description.replace
                  ('{0}', deatilsObj.establishmentPrice + deatilsObj.establishmentUnit );
                 }
                 else {
                     deatilsObj.description = data.mundo[deatilsObj.type].description;
                 }
          }
        }
        else {
          if (tarrif.type === RoamingBundleTypes.World) {
                  deatilsObj.description = deatilsObj.description || data.world[deatilsObj.type].description;
                  if ( this.fisrtSelectedCountry.zone === '1' && deatilsObj.type === romaingBundleDeailType.data) {
                          deatilsObj.description = ' ';
                  }
          }
          else {
                  deatilsObj.description = deatilsObj.description || data.mundo[deatilsObj.type].description;
               // as per reuqested to remove description if origin country is zone 2
                  if ( this.fisrtSelectedCountry.zone === '2' && deatilsObj.type === romaingBundleDeailType.data) {
                    deatilsObj.description = '';
            }
          }
        }
  }


    /** method to active/DeActivate moundo tarrif */
    toggleproductOrderingManagement(action: RoamingAction): Observable<any> {
        const reqBody: ProductOfferingPostObject = new ProductOfferingPostObject();
        reqBody.relatedParty[0].id = this.customerAccountId;
        const firstItem: ProductItem = new ProductItem();
        firstItem.id = '1';
        firstItem.action = action;
        reqBody.productOrderItem = new Array<ProductItem>();
        reqBody.productOrderItem.push(firstItem);
        this.roamingService.moundoBundleProductOffering.forEach((bundle, index) => {
            const item: ProductItem  = new ProductItem();
            item.id = '1.' + (index + 1).toString();
            item.action = action;
            item.category[0].id = roamingProductmangment.RoamingCategory.id_CatalogElement;
            item.category[0].name = roamingProductmangment.RoamingCategory.name;
            item.product = new ProductDeatis();
            item.product.productSerialNumber = this.selectedMsidnRoaming;
            item.product.isBundle = true;
            item.product.productOffering.id = this.roamingService.moundoBundleProductOffering[index].id;
            item.product.productCharacteristic[0].name = roamingProductmangment.instance;
            item.product.productCharacteristic[0].value =
            this.subscriptionService.customerData.services.find(item => item.id === this.selectedMsidnRoaming)?.instance;
            reqBody.productOrderItem.push(item);
        });
        return this.roamingService.toggleproductOrderingManagement(reqBody).pipe(map(res => res));
    }

    getSubtitleForLimitedTarrif(index: number): void {
        this.translate.get('v10.roaming.landing.tarrifs').subscribe((data) => {
            this.roamingBundleCards[index].thirdTitle = data.world.subtitle1;
            if (this.secondSelectedCountry.zone === Roaming.ZoneOne) {
                this.roamingBundleCards[index].thirdTitle = data.world.subtitle2;
            }
        })
    }

    setTrayBoldCenterTitleAndImg(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus,
        confirmationTrayData: IGenericSuccessErrorTray, roamingWcsObject: any): void {
        if (roamingType === LineRestrictionTypes.roaming) {
          this.checkStatusActiveOrNot(roaimgStatus, confirmationTrayData, roamingWcsObject.deactivationConfirmationMsg.title,
            roamingWcsObject.activationConfirmationMsg.title,
            this.appTheme.getImgFullPath(roamingWcsObject.deactivationConfirmationMsg.icon),
            this.appTheme.getImgFullPath(roamingWcsObject.activationConfirmationMsg.icon));
        } else if (roamingType === LineRestrictionTypes.incomingRoaming) {
          this.checkStatusActiveOrNot(roaimgStatus, confirmationTrayData, roamingWcsObject.ingoing.deactivation.title,
            roamingWcsObject.ingoing.activation.title,
            this.appTheme.getImgFullPath(roamingWcsObject.ingoing.icon),
            this.appTheme.getImgFullPath(roamingWcsObject.ingoing.icon));
        } else if (roamingType === LineRestrictionTypes.international) {
          this.checkStatusActiveOrNot(roaimgStatus, confirmationTrayData, roamingWcsObject.outgoing.deactivation.title,
            roamingWcsObject.outgoing.activation.title,
            this.appTheme.getImgFullPath(roamingWcsObject.outgoing.icon),
            this.appTheme.getImgFullPath(roamingWcsObject.outgoing.icon));
        } else if (roamingType === LineRestrictionTypes.data) {
          this.checkStatusActiveOrNot(roaimgStatus, confirmationTrayData, roamingWcsObject.data.deactivation.title,
            roamingWcsObject.data.activation.title,
            this.appTheme.getImgFullPath(roamingWcsObject.data.icon),
            this.appTheme.getImgFullPath(roamingWcsObject.data.icon));
        }
      }

      checkStatusActiveOrNot(roaimgStatus: RestrictionsStatus, confirmationTrayData: IGenericSuccessErrorTray,
        deactiveTitle: string, activeTitle: string, deactiveImg: string, activeImg: string): void {
        // bold center title changes according to status
        if (roaimgStatus === RestrictionsStatus.active) {
          confirmationTrayData.boldCenterTitle = deactiveTitle;
          confirmationTrayData.staticImagePath = deactiveImg;
        }
        // incase status is inactive
        else {
          confirmationTrayData.boldCenterTitle = activeTitle;
          confirmationTrayData.staticImagePath = activeImg;
        }
      }

      setConfirmationTrayTitle(roamingType: LineRestrictionTypes, newStatus: RestrictionsStatus, roamingWcsObject: any): string {
        let title: string = '';
        if (roamingType === LineRestrictionTypes.roaming) {
          title = this.checkConfirmationTrayStatus(newStatus, roamingWcsObject.successMsg.title,
          roamingWcsObject.deactivationSuccessMsg.title);
        } else if (roamingType === LineRestrictionTypes.incomingRoaming) {
          title = this.checkConfirmationTrayStatus(newStatus, roamingWcsObject.ingoing.activation.confirmationTitle,
          roamingWcsObject.ingoing.deactivation.confirmationTitle);
        } else if (roamingType === LineRestrictionTypes.international) {
          title = this.checkConfirmationTrayStatus(newStatus, roamingWcsObject.outgoing.activation.confirmationTitle,
          roamingWcsObject.outgoing.deactivation.confirmationTitle);
        } else if (roamingType === LineRestrictionTypes.data) {
          title = this.checkConfirmationTrayStatus(newStatus, roamingWcsObject.data.activation.confirmationTitle,
          roamingWcsObject.data.confirmation.title);
        }
        return title;
      }

      checkConfirmationTrayStatus(newStatus: RestrictionsStatus, successActive: string, successDeactive: string): string {
        return (newStatus === RestrictionsStatus.active) ? successActive : successDeactive;
      }

  /**
   * check if roaming toggle is inactive or pending to dim secondary toggles.
   * check if one of secondary toggles is pending and the two others are inactive to dim secondary toggles.
   */
  dimSecondaryToggles(roamingStatus: RestrictionsStatus, incomingRoamingStatus: RestrictionsStatus,
     outgoingStatus: RestrictionsStatus, dataStatus: RestrictionsStatus): void {
    this.showOtherCardsDisabled = false;
    if ((roamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      || roamingStatus?.toLowerCase().includes(RestrictionsStatus.pending))
      || this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)) {
      this.showOtherCardsDisabled = true;
      this.hideMainToggle = false;
      this.hideSecondryToggles = true;
    }
  }

  /**
   * check if all secondary toggles are inactive to dim main toggle.
   * check if main roaming toggle is pending to also dim main toggle.
   * check if one of secondary toggles is pending and the two others are inactive to dim main toggle.
   */
    dimMainRoamingToggle(roamingStatus: RestrictionsStatus, incomingRoamingStatus: RestrictionsStatus,
        outgoingStatus: RestrictionsStatus, dataStatus: RestrictionsStatus): void {
        this.showMainRoamingDisabled = false;
        if (roamingStatus?.toLowerCase() === RestrictionsStatus.PendingInActive) {
            this.hideMainToggle = false;
            this.hideSecondryToggles = true;
            this.showMainRoamingDisabled = true;
        }
        else if ((incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
            && outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
            && dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())) {
            this.hideMainToggle = true;
            this.showMainRoamingDisabled = true;
        }
        else {
            this.checkToggls(roamingStatus, incomingRoamingStatus, outgoingStatus, dataStatus);
        }
    }

    checkToggls(roamingStatus: RestrictionsStatus, incomingRoamingStatus: RestrictionsStatus,
        outgoingStatus: RestrictionsStatus, dataStatus: RestrictionsStatus): void {
        if (roamingStatus?.toLowerCase() === RestrictionsStatus.PendingActive &&
            this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)) {
            this.hideMainToggle = false;
            this.hideSecondryToggles = false;
            this.showMainRoamingDisabled = true;
        }
        else if (roamingStatus?.toLowerCase() === RestrictionsStatus.PendingActive &&
            !this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)) {
            this.hideMainToggle = false;
            this.hideSecondryToggles = false;
            this.showMainRoamingDisabled = true;
        }
        else if (roamingStatus?.toLowerCase() === RestrictionsStatus.active.toLocaleLowerCase() &&
            this.checkSecondaryTogglesStatus(incomingRoamingStatus, outgoingStatus, dataStatus)) {
            this.hideMainToggle = true;
            this.hideSecondryToggles = false;
            this.showMainRoamingDisabled = true;
        }

        else if (roamingStatus?.toLowerCase() === RestrictionsStatus.active.toLocaleLowerCase() &&
            this.checkSecondaryTogglesStatusPendingActivation(incomingRoamingStatus, outgoingStatus, dataStatus)) {
            this.hideMainToggle = false;
            this.hideSecondryToggles = false;
            this.showMainRoamingDisabled = true;
            this.showOtherCardsDisabled = true;

        }
    }
  /**
   * check if one of secondary toggles is pending and the two others are inactive.
   * to dim main toggle and secondary toggles.
   */
  checkSecondaryTogglesStatus(incomingRoamingStatus: RestrictionsStatus,
    outgoingStatus: RestrictionsStatus, dataStatus: RestrictionsStatus): boolean {
    if ((incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && dataStatus?.toLowerCase() === RestrictionsStatus.PendingInActive.toLocaleLowerCase())
      || (incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && outgoingStatus?.toLowerCase() === RestrictionsStatus.PendingInActive.toLocaleLowerCase()
      && dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())
      || (incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.PendingInActive.toLocaleLowerCase()
      && outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())) {
          return true;
    }
    return false;
  }


  checkSecondaryTogglesStatusPendingActivation(incomingRoamingStatus: RestrictionsStatus,
    outgoingStatus: RestrictionsStatus, dataStatus: RestrictionsStatus): boolean {
    if ((incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && dataStatus?.toLowerCase() === RestrictionsStatus.PendingActive.toLocaleLowerCase())
      || (incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && outgoingStatus?.toLowerCase() === RestrictionsStatus.PendingActive.toLocaleLowerCase()
      && dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())
      || (incomingRoamingStatus?.toLowerCase() === RestrictionsStatus.PendingActive.toLocaleLowerCase()
      && outgoingStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase()
      && dataStatus?.toLowerCase() === RestrictionsStatus.inactive.toLocaleLowerCase())) {
          return true;
    }
    return false;
  }

  opentrayTaggingOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus): void {
    if (roamingType === LineRestrictionTypes.international) {
      this.tagging.view(roaimgStatus === RestrictionsStatus.inactive ? tagging.outgoingActivationTray : tagging.outgoingDeActivationTray);
    }
    else if (roamingType === LineRestrictionTypes.data) {
      this.tagging.view(roaimgStatus === RestrictionsStatus.inactive ? tagging.dataActivationTray : tagging.dataDeActivationTray);
    }
  }

  clickOnBtnCardTaggingOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus): void {
    if (roamingType === LineRestrictionTypes.international) {
      roaimgStatus === RestrictionsStatus.inactive ?
        this.applyTaggingForToggleClick(tagging.RoamingActions.outgoing.activeBtnCard,
          tagging.outgoingActivationJourney.firstStep, tagging.activacionOutgoing)
        :
        this.applyTaggingForToggleClick(tagging.RoamingActions.outgoing.desActiveBtnCard,
          tagging.outgoingDeActivationJourney.firstStep, tagging.desactivacionOutgoing);
    } else if (roamingType === LineRestrictionTypes.data) {
      roaimgStatus === RestrictionsStatus.inactive ?
        this.applyTaggingForToggleClick(tagging.RoamingActions.data.activeBtnCard,
          tagging.dataActivationJourney.firstStep, tagging.activacionData)
        :
        this.applyTaggingForToggleClick(tagging.RoamingActions.data.desActiveBtnCard,
          tagging.dataDeActivationJourney.firstStep, tagging.desactivacionData);
    }
  }

  applyTaggingForToggleClick(taggingObj: TrackActionsObject, taggingObjData: TrackActions,
    tagPageName: { 'page_name': string }): void {
    this.tagging.track(taggingObj.eventName, taggingObj.data);
    // tagging start of joureny
    const tag: TaggingJourney = Object.assign({}, taggingObjData.data);
    tag.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
    this.tagging.track(taggingObjData.eventName, tag, tagPageName);
  }

  sucessErrorTrayTagingOutgoingAndData(isSuccess: boolean, roamingType: LineRestrictionTypes,
     newStatus: RestrictionsStatus, error?: HttpErrorResponse): void {
    if (roamingType === LineRestrictionTypes.international) {
      newStatus === RestrictionsStatus.active ? this.taggingOutgoingpatchingRequestActivation(isSuccess, error) :
        this.taggingOutgoingpatchingRequestDeActivation(isSuccess, error);
    }
    else if (roamingType === LineRestrictionTypes.data) {
      newStatus === RestrictionsStatus.active ? this.taggingDatapatchingRequestActivation(isSuccess, error) :
        this.taggingDatapatchingRequestDeActivation(isSuccess, error);
    }
  }

  applyTaggingFortraySuccess(taggingPageNameObject: PaymentTaggingPageName, taggingObjData: TrackActions,
     tagPageName: { 'page_name': string }): void {
    this.tagging.view(taggingPageNameObject);
    // tagging sucess of joureny
    const tag: TaggingJourney = Object.assign({}, taggingObjData.data);
    tag.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
    this.tagging.track(taggingObjData.eventName, tag, tagPageName);
  }

  applyTaggingFortrayFail(taggingPageNameObject: PaymentTaggingPageName, taggingObjData: TrackActions,
     tagPageName: { 'page_name': string }, error?: HttpErrorResponse): void {
    this.tagging.view(taggingPageNameObject);
    // tagging start of joureny
    const tag: TaggingJourney = Object.assign({}, taggingObjData.data);
    tag.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
    this.handleErrorDataForToggles(tag, error);
    this.tagging.track(taggingObjData.eventName, tag, tagPageName);
  }

  taggingOutgoingpatchingRequestActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
    (isSuccess) ? this.applyTaggingFortraySuccess(tagging.outgoingSucessActive, tagging.outgoingActivationJourney.sucess,
      tagging.activacionOutgoing) : this.applyTaggingFortrayFail(tagging.outgoingFailActive, tagging.outgoingActivationJourney.error,
        tagging.activacionOutgoing, error);
  }

  taggingOutgoingpatchingRequestDeActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
    (isSuccess) ? this.applyTaggingFortraySuccess(tagging.outgoingSucessDeActive, tagging.outgoingDeActivationJourney.sucess,
      tagging.desactivacionOutgoing) : this.applyTaggingFortrayFail(tagging.outgoingFailDeActive, tagging.outgoingDeActivationJourney.error,
        tagging.desactivacionOutgoing, error);
  }

  taggingDatapatchingRequestActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
    (isSuccess) ? this.applyTaggingFortraySuccess(tagging.dataSucessActive, tagging.dataActivationJourney.sucess,
      tagging.activacionData) : this.applyTaggingFortrayFail(tagging.dataFailActive, tagging.dataActivationJourney.error,
        tagging.activacionData, error);
  }

  taggingDatapatchingRequestDeActivation(isSuccess: boolean, error?: HttpErrorResponse): void {
    (isSuccess) ? this.applyTaggingFortraySuccess(tagging.dataSucessDeActive, tagging.dataDeActivationJourney.sucess,
      tagging.desactivacionData) : this.applyTaggingFortrayFail(tagging.dataFailDeActive, tagging.dataDeActivationJourney.error,
        tagging.desactivacionData, error);
  }

  taggingToggleClickOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus): void {
    if (roamingType === LineRestrictionTypes.international || roamingType === LineRestrictionTypes.data) {
      const type: string = roamingType === LineRestrictionTypes.international ? roamingToggles.outgoing : roamingToggles.data;
      if (roaimgStatus === RestrictionsStatus.inactive) {
        this.tagging.track(tagging.RoamingActions[type].activateBtnTray.eventName,
          tagging.RoamingActions[type].activateBtnTray.data,
          roamingType === LineRestrictionTypes.international ? tagging.outgoingActivationTray : tagging.dataActivationTray);
      } else if (roaimgStatus === RestrictionsStatus.active) {
        this.tagging.track(tagging.RoamingActions[type].desactivationBtnTray.eventName,
          tagging.RoamingActions[type].desactivationBtnTray.data,
          roamingType === LineRestrictionTypes.international ? tagging.outgoingDeActivationTray : tagging.dataDeActivationTray);
      }
    }
  }

  taggingToggleClickCancelOutgoingAndData(roamingType: LineRestrictionTypes, roaimgStatus: RestrictionsStatus, isCancel: boolean): void {
    if ((roamingType === LineRestrictionTypes.international || roamingType === LineRestrictionTypes.data) && isCancel) {
      const type: string = roamingType === LineRestrictionTypes.international ? roamingToggles.outgoing : roamingToggles.data;
      if (roaimgStatus === RestrictionsStatus.inactive) {
        this.tagging.track(tagging.RoamingActions[type].CancelBtnTray.eventName,
          tagging.RoamingActions[type].CancelBtnTray.data,
          roamingType === LineRestrictionTypes.international ? tagging.outgoingActivationTray : tagging.dataActivationTray);
      } else if (roaimgStatus === RestrictionsStatus.active) {
        this.tagging.track(tagging.RoamingActions[type].desactivationCancelBtnTray.eventName,
          tagging.RoamingActions[type].desactivationCancelBtnTray.data,
          roamingType === LineRestrictionTypes.international ? tagging.outgoingDeActivationTray : tagging.dataDeActivationTray);
      }
    }
  }

  handleErrorDataForToggles(tag: TaggingJourney, error: HttpErrorResponse): void {
    tag.journey_error_category = this.tagging.setErrorTypeValue(error);
    tag.journey_error_code = error?.error?.ecode ?? '';
    tag.journey_error_description = this.translate.instant('v10.roaming.landing.roamingPermissions.tray.failure.description');
  }
}

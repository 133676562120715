import { environment } from '../../../environments/environment';
import { cachingKeys, sessionTimeout } from './defines';

export const CACHE_CONFIG = [
    // Products and services
    {
        'key': cachingKeys.Products,
        'timeout': 10,
        'api': environment.middlewareBaseUrl +
            'v2/product/products?subscriptionId={subscription-id}&customerAccountId={customer-account-id}'

    },
    {
        'key': cachingKeys.Subscriptions,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v2/subscription/subscriptions/'
    },
    // My Account

    {
        'key': cachingKeys.GetCustomerAgreements,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v3/customer/customerAgreements/'
    },
    {
        'key': cachingKeys.FetchCustomerAccountById,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}'
    },
    {
        'key': cachingKeys.FetchBillingAccounts,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}/billingAccounts'
    },
    {
        'key': cachingKeys.getPaymentMethods,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v2/customer/customerAccounts.*?hierarchyType.*'
    },
    {
        'key': cachingKeys.CurrentSpending,
        'timeout': 10,
        'api': environment.middlewareBaseUrl +
            'v2/service/serviceBalances?customerAccountId={siteId}&serviceIdentifier={serviceId}&categories=outOfPlan'
    },
    {
        'key': cachingKeys.Tariff,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl +
            'v2/product/tariffs?subscriptionId={subscription-id}&customerAccountId={customer-account-id}',
    },
    // Billing
    {
        'key': cachingKeys.GetBills,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v3/payment/customerBills'
    },
    {
        'key': cachingKeys.GetBillDetails,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v3/payment/customerBills/{id}'
    },
    {
        'key': cachingKeys.GetServiceUsages,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v3/payment/customerBills/{billId}/serviceUsages?subscriptionId={serviceId}'
    },
    {
        'key': cachingKeys.getCurrentConsumption,
        'timeout': 10,
        'api': environment.middlewareBaseUrl
            + 'es/v1/customerAccounts/{customerAccountId}/subscriptions/{subscriptionId}/usage'
    }

    // Misc
    ,
    {
        'key': cachingKeys.permissionsAndPreferences,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'es/v1/customerAccounts/{siteId}/settings'
    },
    {
        'key': cachingKeys.getSIMProfileData,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'v2/subscription/subscriptions/{id}/simProfile.*',
    }
    // CallOptions
    ,
    {
        'key': cachingKeys.getCallOptions,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + '/es/v1/serviceAccountInfo/{serviceId}/settings/availability'
    },
    {
        'key': cachingKeys.Offers,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'es/v1/nbaRecommendation?customerAccountId={customer-account-id}'
    },
    {
        'key': cachingKeys.PriceCustomerAccount,
        'timeout': sessionTimeout,
        'api': environment.middlewareBaseUrl + 'es/v1/productsServices/product/price?customerAccountId={customerAccountId}'
    }
]

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Mva10SubHeaderService {
  /** showMva10SubHeaderSubject: should be emitted every time of showing mva10 subheader */
  public showMva10SubHeaderSubject = new Subject<any>();

  showMva10SubHeader: boolean = false;

  constructor() { }

  /**
   * This method for showing mva10 header
   * @param component should be in EntryComponents array in the module
   */
  open(component: any) {
    this.showMva10SubHeader = true;
    this.showMva10SubHeaderSubject.next(component);
  }

  /**
   * This method for showing mva10 subheader
   */
  close() {
    this.showMva10SubHeader = false;
  }

}

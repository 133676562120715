import { Permission } from './permission.model';
export class MyAccountAuthorizedInfo {
    id: string;
    type: string;
    currentMsisdn: string;
    username: string;
    salutation: string;
    firstName: string;
    familyName: string;
    birthDate: string;
    companyName: string;
    companyId: string;
    documentId: string;
    phone: string;
    vodafonePhone: string;
    email: string;
    permissions: Permission[];
    contactPoint: string;
    notificationChannel: string;
    notificationTime: string;
}

<button class="adara-close-button" (click)="showExitOverlay()" *ngIf="!appService.showVodaFullLoader && !appService.showFullAppLoader"
id="AdaraMainCloseButton">
    <svg class="adara-main__icon-close">
        <title>Close</title>
        <use xlink:href="#icon-close"/>
    </svg>
</button>
<iframe #Iframe
    id="AdaraIframe"
    class="adara-main__webview"
    [src]="urlIframe"
    (load)="onIframeLoad(Iframe)"
    >
</iframe>
<vfac-overlay
    [overlayData]="haveYouFinishedOverlay"
    [show]="showHaveYouFinishedOverlay"
    [loaded]="true"
    [greyMode]="false"
    [showButtons]="isMobile"
    (primaryBtnClicked)="exit()"
    (secondaryBtnClicked)="stay()"
    >
    <div body class="adara-modal-container">
        <p id="AdaraHaveYouFinishedModalTitle" class="adara-have-you-finished-header">
            {{haveYouFinishedOverlay.title}}
        </p>
        <span class="line" *ngIf="!isMobile"></span>
        <div class="adara-have-you-finished extra-margin">
            <div class="adara-have-you-finished-img">
                <img
                    id="AdaraHaveYouFinishedModalIcon"
                    class="adara-have-you-finished-img-file"
                    src="{{('v10.productsServices.adara.closeOverlay.image_rsp' | imgFullPath)}}"
                />
            </div>
            <p id="AdaraHaveYouFinishedModalTitle" class="adara-have-you-finished-title">
                {{adaraCloseOverlayTitle}}
            </p>
        </div>
        <div class="adara-modal-button-container" *ngIf="!isMobile">
            <mva10-c-button (mva10OnClick)="exit()">
            <p class="extra-padding" id="AdaraHaveYouFinishedButtonYes">
                {{haveYouFinishedOverlay.primaryButton}}
            </p>
            </mva10-c-button>
            <mva10-c-button (mva10OnClick)="stay()"
            [styleClass]="'mva10-c-button--tertiary'">
            <p class="extra-padding" id="AdaraHaveYouFinishedButtonNo">
                {{haveYouFinishedOverlay.secondaryButton}}
            </p>
            </mva10-c-button>
        </div>
    </div>
</vfac-overlay>

<vfac-overlay-full-screen
    [overlayFullScreenData]="exitBuyingFullScreenOverlay"
    [show]="showDoYouWantToExitBuyingFullScreenOverlay"
    (primaryBtnClicked)="stay()"
    (secundaryBtnClicked)="exit()"
    (closeBtnClicked)="stay()">
    <div body class="adara-exit-modal">
        <div class="adara-exit-modal-img">
          <img
            id="AdaraDoYouWantToExitBuyingModalIcon"
            class="adara-exit-modal-img-file"
            src="{{('v10.dashboard.onePlus.overlayError.icon' | imgFullPath)}}"
          />
        </div>
        <p id="AdaraDoYouWantToExitBuyingModalTitle" class="adara-exit-modal-title">
          {{doYouWantToLeaveTitle}}
        </p>
        <p id="AdaraDoYouWantToExitBuyingModalDescription" class="adara-exit-modal-subtible mva10-u-body">
          {{adaraCloseOverlaySubtitle}}
        </p>
    </div>
</vfac-overlay-full-screen>

<vfac-overlay-full-screen
    [overlayFullScreenData]="errorOverlay"
    [show]="showErrorOverlay"
    (primaryBtnClicked)="retry()"
    (closeBtnClicked)="exit()">
    <div body class="adara-error-modal">
        <div class="adara-error-modal-img">
          <img
            id="AdaraErrorModalIcon"
            class="adara-error-modal-img-file"
            src="{{('v10.dashboard.onePlus.overlayError.icon' | imgFullPath)}}"/>
        </div>
        <p id="AdaraErrorModalTitle" class="adara-error-modal-title">
          {{errorModalTitle}}
        </p>
        <p id="AdaraErrorModalDescription" class="adara-error-modal-subtible mva10-u-body">
          {{errorModalSubtitle}}
        </p>
    </div>
</vfac-overlay-full-screen>
export enum MyAccountSections {
  all = 'all',
  myData = 'myData',
  payments = 'payments',
  contracts = 'contracts',
  privacy = 'privacy',
  orders = 'orders',
  alerts = 'alerts',
  billing = 'billing',
  none = ''
}
export enum MyDataInputFieldId {
  name = 'name',
  firstName = 'firstName',
  secondName = 'secondName',
  IBAN = 'IBAN',
  Email = 'Email'
}


import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BuySimDataService } from './buy-sim-data.service';
import { JSON_PATHS, BUY_SIM_JOURNEY } from '../../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { SuggestedAddressModel } from '../../models/buy-sim-suggested-address.model';
import { BuySimService } from './buy-sim.service';
import { AddressVerticalInfoModel } from '../../models/buy-sim-address-vertical-info.model';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { IDynamicTray } from '../../models/dynamic-tray.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { throwError } from 'rxjs';
import { BuySimSteps } from '../../shared/enums/buy-sim-steps.enum';

@Injectable()
export class NormalizeAddressService {
    /**list of suggested address comes from api */
    suggestedAddressList: SuggestedAddressModel[] = new Array<SuggestedAddressModel>();
    /** selected suggested address by user */
    selectedAddress: SuggestedAddressModel;
    /** list of address vertical info from api */
    addressHorizontalInfoList: AddressVerticalInfoModel[] = new Array<AddressVerticalInfoModel>();
    dynamicTrayData: IDynamicTray = {
        basicTrayData: {
            isScrollableTray: false
        },
        isBoldTitle: true
      };
    /**list of address vertical info from api without mapping */
    addressHorizontalDataList = [];
    constructor(private buySimDataService: BuySimDataService,
        private buySimService: BuySimService,
        private dynamicTrayService: DynamicTrayService,
        private translateService: TranslateService,
        private router: Router) {}

    /** gets suggested address based on user search input
     * @param address {string} user search text
     */
    getNewSuggestGeocoder(address) {
        return this.buySimDataService.getNewSuggestGeocoder(address).pipe(map((data: any) => {
            this.suggestedAddressList = new Array<SuggestedAddressModel>();
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                const addressList = JsonQuery.value(data.data, JSON_PATHS.buySim.address.addressList) || [];
                addressList.forEach(address => {
                    const newAddress: SuggestedAddressModel = new SuggestedAddressModel();
                    newAddress.formattedAddress = JsonQuery.value(address, JSON_PATHS.buySim.address.formattedAddress);
                    newAddress.provinceId = this.getAddressComponent(BUY_SIM_JOURNEY.addressComponentsType.provinceId, address);
                    newAddress.cityId = this.getAddressComponent(BUY_SIM_JOURNEY.addressComponentsType.cityId, address);
                    newAddress.postalCode = this.getAddressComponent(BUY_SIM_JOURNEY.addressComponentsType.postalCode, address);
                    newAddress.streetId = this.getAddressComponent(BUY_SIM_JOURNEY.addressComponentsType.streetId, address);

                    this.suggestedAddressList.push(newAddress);
                });
                return this.suggestedAddressList.map((data) => {
                    const addressElements: string[] = data.formattedAddress.split(',');
                    addressElements.splice(-2, 2);
                    return addressElements.join(',');
                });
            } else {
                this.buySimService.handleGeneralError(data);
            }
        }, error => {
            this.buySimService.handleGeneralError(error);
        }));
    }

    /** gets address component from vertical info using address component type
     * @param addressComponentType {string}
     * @param address
    */
    getAddressComponent(addressComponentType, address) {
        const addressComponents = JsonQuery.value(address, JSON_PATHS.buySim.address.addressComponents) || [];
        const selectedAddressComponent = addressComponents.find(component => {
            const componentType = JsonQuery.value(component, JSON_PATHS.buySim.address.addressComponentType) || '';
            return componentType === addressComponentType;
        });
        return (selectedAddressComponent ?
            (JsonQuery.value(selectedAddressComponent, JSON_PATHS.buySim.address.addressComponentShortName) || '') : '');
    }

    /** gets vertical info of selected suggested address
     * @param streetNumber {string}
     * @param selectedAddressIndex {number} selected index of suggested address
     */
    getNewGeocoder(streetNumber, selectedAddressIndex) {
        this.selectedAddress = this.suggestedAddressList[selectedAddressIndex];
        return this.buySimDataService.getNewGeocoder(streetNumber, this.selectedAddress).pipe(map((data: any) => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                this.addressHorizontalInfoList = new Array<AddressVerticalInfoModel>();
                this.addressHorizontalDataList = JsonQuery.value(data.data, JSON_PATHS.buySim.address.horizontalDataList) || [];
                this.addressHorizontalDataList.forEach(horizontalDataItem => {
                    const horizontalInfoItem = this.mapVerticalInfo(horizontalDataItem);
                    this.addressHorizontalInfoList.push(horizontalInfoItem);
                });
                return (this.addressHorizontalDataList && this.addressHorizontalDataList.length > 0) ? true : false;
            } else {
                this.buySimService.handleGeneralError(data);
            }
        }), catchError((error) => {
            this.buySimService.handleGeneralError(error);
            return throwError(error);
          }));
    }

    /** maps address vertical info*/
    mapVerticalInfo(item) {
        const horizontalInfoItem = new AddressVerticalInfoModel();
        horizontalInfoItem.number = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.number);
        horizontalInfoItem.stair = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.stair);
        horizontalInfoItem.floor = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.floor);
        horizontalInfoItem.door = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.door);
        return horizontalInfoItem;
    }

    /** opens dynamic tray to show legal terms */
    openAddressTray(component) {
        this.translateService.get('v10.buySim.itemsList.addressNotFoundLabel.body').subscribe(data => {
            this.dynamicTrayData.basicTrayData.title = data;
            this.dynamicTrayService.open(this.dynamicTrayData, component);
            this.buySimService.preventScroll = true;
            this.buySimService.preventBackgroundScrolling = true;
            this.dynamicTrayData.closeButtonAction = () => {
              this.buySimService.preventScroll = false;
              this.buySimService.preventBackgroundScrolling = false;
              this.dynamicTrayService.close();
              this.dynamicTrayService.componentRefClearSubject.next();
            }
        });
      }

    /** submit save delivery info using selected address elements
     * @param selectedAddressElements {AddressVerticalInfoModel}
     */
    submitAddressInfo(selectedAddressElements: AddressVerticalInfoModel, useVerticalInfo = false) {
        this.buySimService.showFullLoader = true
        this.dynamicTrayService.close();
        this.setAddressInfoRequestBody(selectedAddressElements, useVerticalInfo).subscribe(addressInfoBodyObj => {
            this.buySimDataService.submitAddressInfo(addressInfoBodyObj).subscribe(data => {
                const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
                this.buySimService.showFullLoader = false;
                if (status === BUY_SIM_JOURNEY.statusOk) {
                    this.buySimService.preventScroll = false;
                    this.buySimService.isPaymentFullScreen = true;
                    this.buySimService.hideTicketDetails = true;
                    this.router.navigate([config.buySim.payment.route]);
                    this.buySimService.currentStep = BuySimSteps.stepThreePayment;
                } else {
                    this.buySimService.handleGeneralError(data);
                }
            }, error => {
                this.buySimService.showFullLoader = false;
                this.buySimService.handleGeneralError(error);
            });
        });
    }

    /** sets save delivery info api body object
     * @param selectedAddressElements {AddressVerticalInfoModel} selected address elements
     */
    setAddressInfoRequestBody(selectedAddressElements: AddressVerticalInfoModel, useVerticalInfo= false) {
        const body = Object.assign({}, BUY_SIM_JOURNEY.saveDeliveryDataRequestObj);
        return this.buySimDataService.getDeliveryDates(selectedAddressElements.postalCode).pipe(map((data: any) => {
            const status = JsonQuery.value(data, JSON_PATHS.buySim.status);
            if (status === BUY_SIM_JOURNEY.statusOk) {
                if (useVerticalInfo) {
                    this.setAddressBodyObjFromVerticalInfo(selectedAddressElements, body);
                } else {
                    this.setAddressBodyObjFromManualInput(selectedAddressElements, body);
                }
                this.setOrderBodyObj(data.data, body);
                return body;
            } else {
                this.dynamicTrayService.close();
                this.buySimService.handleGeneralError(data);
            }
        }), catchError((error) => {
            this.dynamicTrayService.close();
            this.buySimService.handleGeneralError(error);
            return throwError(error);
          }));
     }

     /** sets order prop in body from getDeliveryDates api */
     setOrderBodyObj(data, requestBody) {
        requestBody.xCosteEur = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xCosteEur);
        requestBody.xCanal = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xCanal);
        requestBody.xCostePromoEur = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xCostePromoEur);
        requestBody.xDestEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xDestEntrega);
        requestBody.xDsOpcEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xDsOpcEntrega);
        requestBody.xIdentificacion = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xIdentificacion);
        requestBody.xOpcEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xOpcEntrega);
        requestBody.xPerfil = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xPerfil);
        requestBody.xPrioridadEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xPrioridadEntrega);
        requestBody.xTipoCliente = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xTipoCliente);
        requestBody.xTipoProceso = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xTipoProceso);
        requestBody.cdTipoDeliveryType = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.cdTipoDeliveryType);
        requestBody.cdTipoProceso = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.cdTipoProceso);
        requestBody.dsOpcEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.dsOpcEntrega);
        requestBody.fcEntregaPedido = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.fcEntregaPedido);
        requestBody.idModalidadEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.idModalidadEntrega);
        requestBody.idTipoEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.idTipoEntrega);
        if (this.buySimService.portabilityChecked) {
            requestBody.fcPortabilidad = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.fcPortabilidad);
            requestBody.itPortabilidad = true;
            requestBody.xDiaEntrega = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xDiaEntrega)
            requestBody.xHoraLiberacion = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xHoraLiberacion)
            requestBody.xSlaFraude = JsonQuery.value(data, JSON_PATHS.buySim.deliveryDates.xSlaFraude)
        }
    }

    /**set address request obj when user choose to enter address manual
     * @param selectedAddressElements {AddressVerticalInfoModel}
     * @param requestBody
     */
    setAddressBodyObjFromManualInput(selectedAddressElements: AddressVerticalInfoModel, requestBody) {
        requestBody.address.number = selectedAddressElements.number;
        requestBody.address.name = selectedAddressElements.name;
        requestBody.address.stairway = selectedAddressElements.stair;
        requestBody.address.floor = selectedAddressElements.floor;
        requestBody.address.door = selectedAddressElements.floor;
        requestBody.address.postcode = selectedAddressElements.postalCode;
        requestBody.address.postCodeDelivery = selectedAddressElements.postalCode;
        requestBody.address.province = this.getProvinceId(selectedAddressElements.provinceName);
        requestBody.address.town = selectedAddressElements.city;
        requestBody.address.additionalInfo = selectedAddressElements.additionalInfo;
        requestBody.address.verticalId = null;
    }

    /** sets address obj in api body request using user selected address elements
     * @param selectedAddressElements {AddressVerticalInfoModel} user selection
     * @param requestBody
     */
    setAddressBodyObjFromVerticalInfo(selectedAddressElements: AddressVerticalInfoModel, requestBody) {
        const selectedVerticalInfo = this.getSelectedVerticalInfo(selectedAddressElements);
        this.mapAdressObjValues(selectedVerticalInfo, requestBody);
    }

    /** maps address obj in request body of save delivery info api
     * @param selectedVerticalInfo address selections by user
     * @param requestBody save delivery info request body
     */
    mapAdressObjValues(selectedVerticalInfo, requestBody) {
        requestBody.address.name =
         JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.name);

         requestBody.address.number =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.number);

        requestBody.address.stairway =
         JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.stairway);

         requestBody.address.floor =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.floor);

        requestBody.address.door =
         JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.door);

         requestBody.address.descriptionNumber =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.descriptionNumber);

        requestBody.address.descriptionStair =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.descriptionStair);

        requestBody.address.descriptionFloor =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.descriptionFloor);

        requestBody.address.descriptionDoor =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.descriptionDoor);

        requestBody.address.postcode =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.postalcode);

        requestBody.address.postCodeDelivery =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.postCodeDelivery);

        requestBody.address.verticalId =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.verticalId);

        requestBody.address.type =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.type);

        requestBody.address.province =
        this.getProvinceId(JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.province));

        requestBody.address.town =
        JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.town);

        const duplicate = JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.duplicate)
        requestBody.address.duplicate = duplicate ? duplicate : null;

        const block = JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.block);
        requestBody.address.block = block ? block : null;

        const identificador = JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.identificador);
        requestBody.address.identificador = identificador ? identificador : null;

        const hand1 = JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.hand1);
        requestBody.address.hand1 = hand1 ? hand1 : null;

        const hand2 = JsonQuery.value(selectedVerticalInfo, JSON_PATHS.buySim.coverageAddressList.hand2);
        requestBody.address.hand2 = hand2 ? hand2 : null;
    }

    /** gets address vertical info from api data after user selects address components
     * @param selectedAddressElements {AddressVerticalInfoModel} user selections
     */
    getSelectedVerticalInfo(selectedAddressElements: AddressVerticalInfoModel) {
       return  this.addressHorizontalDataList.find(item => {
            const number = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.number);
            const stair = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.stair);
            const floor = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.floor);
            const door = JsonQuery.value(item, JSON_PATHS.buySim.address.horizontalDataItem.door);
            return (selectedAddressElements.number === number &&
             selectedAddressElements.stair === stair &&
              selectedAddressElements.floor === floor &&
               selectedAddressElements.door === door)
        });
    }

    /**gets province id by province name
     * @param provinceName {string}
     * returns province id
    */
    getProvinceId(provinceName) {
        const province =  this.buySimService.provinceDataList.find(
            province => province.name.toLowerCase().normalize('NFD').replace(/[^(0-9a-zA-Z- :\\-_,)]/gi, '')
         === provinceName.toLowerCase().normalize('NFD').replace(/[^(0-9a-zA-Z- :\\-_,)]/gi, ''));
        return province ? province.id : 0;
    }
}

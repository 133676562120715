import { UserProfile } from './../../models/user-profile.model';
import { CLIENT_RS, customerTypes, LOCAL_STORAGE_PREFIX, SEGMENT_MICRO, SEGMENT_PARTICULAR, CLIENT_ME } from '../../shared/constants/defines';
import { Injectable } from '@angular/core';
import { tagging } from '../../../config/tagging-config';
import { Subject } from 'rxjs';
import { LOCAL_STORAGE_KEYS } from '../../shared/constants/defines';
import { PaymentTaggingPageName } from '../../models/payment';
import { CustomerAccount } from '../../models/customer-account.model';

@Injectable()
export class StorageService {
    public pendingRequests: string[] = [];
    public navigationEnd: boolean = false;
    public jwt: string;
    public accessToken;
    public refreshToken;
    public accessExpirationTime: Date = null;
    public accessIssuedTime: Date = null;
    public refreshExpirationTime: Date = null;
    public userProfile: UserProfile;
    /**
     * When true, signature process will be considered as finished and no redirections or entry points will occur
     */
    public isFirmaFinished: boolean;
    /**
     * Whether to show Dashboard entry point to access the signature process in myOrders page or not
     */
    public showFirmaEntryPoint: boolean;
    /**
     * When false, sites retrieved from /customerAccounts service will be checked and redirection
     * to myOrders will be performed if any of the sites returns a value pdteFirma: '1'
     */
    public checkedRedirectionToSignProcess: boolean = false;
    public msisdn: string; // Hack to enable testing the network login
    public welcomeMessageDisplayed = false;
    public utag_cookie = '';
    public client_login_type = '';
    public service_plane = '';
    public isCreditNote = false;
    public serviceAES256 = '';
    public onPdfDownload = new Subject<any>();
    public onErrorOccurs = new Subject<any>();
    public gdprUpdatedLocaly = false;
    public gdprDiagnosticStatus = true;
    public secureNetAlertServiceId: string;
    public secureNetAccountId: string;
    public navigateToSecureNetFromBilling = false;
    public superoffer: string = '';
    serviceStorage: Object;
    date: Date = new Date()
    guageFinishLoading = false;
    billDetailsFinishLoading = false;
    billConfigFinishLoading: boolean = false;
    billConfigAccountFinishLoading = false;
    misPagosErrortaggingObj: { error_list: string, '&&events': string };
    superWifiRegisterFinishLoading: boolean = false;
    superWifiConfigurationFinishLoading: boolean = false;
    financedDevicesPageName: PaymentTaggingPageName;
    inboxListingFinishLoad = false;
    notRestErrorVariables = false;
    isHoreca = false;
    creditBalanceAmount = null;
    public billingVariables = {
        billing_alert: '',
        billing_status: '',
        billing_ebilling: ''
    };
    public productAndServiceVariables = {
        plan_type: '',
        tv_bundle: '',
        tv_online_status: tagging.staticVariables.tvOnlineStatus.notv,
        tv_online_bundle: '',
        special_data_bundles_status: '',
        extra_status: '',
        status_benefits: '',
        entertaiment_type: '',
        plan_alert: '',
        router_serial_number: ''
    };
    public dashboardVariables = {
        consumption_type: ''
    }
    public myaccountVariables = {
        plan_commitment: ''
    }

    public needHelpBubbleVariables = {
        faqs_availability: '',
        asistente_availability: '',
    }

    public topupPrepaid = {
        page_language: tagging.staticVariables.pciPrepaidLanguage.spanish
    }

    public messegaesVariables = {
        titulo_noti_mcare: ''
    }
    public customerAccountsLoaded: boolean;
    public roamingVariables: any = {
        plan_commitment : this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear()
    }
    constructor() {
        this.serviceStorage = new Object();
    }
    /**
     * add/set item to browser localstorage
     * @param key the identifier for the localstorage item
      * @param value the value of localstorage item
     */
    public setStorage(key: string, value: any) {
        const newKey = LOCAL_STORAGE_PREFIX + '.' + key;
        // Add to localstorage
        if (typeof value === 'object') {
            this.serviceStorage[newKey] = JSON.stringify(value);
        } else {
            this.serviceStorage[newKey] = String(value);
        }

    }
    public setLocalStorage(key: string, value: any) {
        const newKey = LOCAL_STORAGE_PREFIX + '.' + key;
        // Add to localstorage
        if (typeof value === 'object') {
            localStorage.setItem(newKey, JSON.stringify(value));
        } else {
            localStorage.setItem(newKey, String(value));
        }

    }

    /**
         * read certain item from the session storage or from the cachedSession and
         * parse the item to json if the item is a stringified object.
          * @param  {key} The key of the property to be detected
          * @returns {Object} the returned object holds the value for the detected property
         */
    public getStorage(key: string) {
        try {
            const value = JSON.parse(this.serviceStorage[LOCAL_STORAGE_PREFIX + '.' + key]);
            return value;
        } catch (error) {
            return this.serviceStorage[LOCAL_STORAGE_PREFIX + '.' + key];
        }
    }

    public getLocalStorage(key: string) {
        try {
            const value = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PREFIX + '.' + key));
            return value;
        } catch (error) {
            return localStorage.getItem(LOCAL_STORAGE_PREFIX + '.' + key);
        }
    }
    /**
         * remove certain item from the localStorage and from the cachedSession
           * @param  {key} The key of the property to be removed
         */
    public remove(key: string) {
        delete this.serviceStorage[LOCAL_STORAGE_PREFIX + '.' + key];
    }
    public removeFromLocalStorage(key: string) {
        localStorage.removeItem(LOCAL_STORAGE_PREFIX + '.' + key);
    }
    /**
         * clear all the localStorage items and the cachedSession items
         */
    public empty() {
        this.userProfile = null;
        this.jwt = null;
        this.accessExpirationTime = null;
        this.accessToken = null;
        this.refreshExpirationTime = null;
        this.refreshToken = null;
        this.welcomeMessageDisplayed = false;
        this.removeFromLocalStorage(LOCAL_STORAGE_KEYS.JWT);
        this.removeFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        this.removeFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP);
        this.removeFromLocalStorage(LOCAL_STORAGE_KEYS.FLOWMIGRATION);
        this.removeFromLocalStorage(LOCAL_STORAGE_KEYS.FLOWDISCOUNTRENEWAL);
        this.serviceStorage = new Object();
    }
    public emptyLocalStorage() {
        localStorage.clear();
    }
    /**
         * list all items in the localStorage
         * @returns {Object} the returned object holds the cached Session object
         */
    public listAllItems() {
        const items = [];

        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {
                items.push(items);
            }
        }

        return items;
    }

    public checkGdprDiagnosticFlag() {
        return ((( this.userProfile && !this.userProfile.gdprDiagnostics)
        && !this.gdprUpdatedLocaly) ||
        (this.gdprUpdatedLocaly && !this.gdprDiagnosticStatus))
    }


    getIsMicroResegmented(): boolean {
        return this.userProfile?.customerType === customerTypes.CONSUMER
          ? this.userProfile?.sites?.some((site: any) => site.clientType === CLIENT_RS && site.marketSegment === SEGMENT_MICRO)
          : false;
      }
      getIsParticlurResegmented(): boolean {
        return this.userProfile?.customerType === customerTypes.CONSUMER
          ? this.userProfile?.sites?.some((site: any) => site.clientType === CLIENT_RS && site.marketSegment === SEGMENT_PARTICULAR)
          : false;
      }

    getIsME(): boolean {
    return this.userProfile?.sites?.some((site: CustomerAccount) => site.clientType === CLIENT_ME);
    }
}

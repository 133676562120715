import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { OverlayFullScreenModel } from '@mva10/mva10-angular';
import { CtcOverlayModel } from '../../../models/ctc-overlay.model';


@Component({
  selector: 'sp-mva10-ctc-overlay',
  templateUrl: './sp-mva10-ctc-overlay.component.html',
  styleUrls: ['./sp-mva10-ctc-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SpMva10CtcOverlayComponent {

  @Input() data: CtcOverlayModel;
  @Input() show: boolean;
  @Output() clickBtn = new EventEmitter<string>();
  @Output() close = new EventEmitter();

  overlayConfig: OverlayFullScreenModel;

  constructor() {
    this.overlayConfig = new OverlayFullScreenModel();
    this.overlayConfig.closeBtn = true;
    this.overlayConfig.background = 'white';
  }

  onClickButton() {
    this.clickBtn.emit(this.data.number);
  }

  onClose() {
    this.close.emit();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Inbox_Message } from '../constants/defines';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const newVal = value.sort((a: any, b: any) => {
      const date1 = +new Date(a.creationDate);
      const date2 = +new Date(b.creationDate);
      if (value === Inbox_Message.DATE_DESC) {
        return date1 - date2
      }
      else {
        return date2 - date1
      }
    });

    return newVal;
  }

}

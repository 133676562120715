import { ImgFullPathPipe } from './img-full-path.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ImgFullPathPipe],
  providers: [ImgFullPathPipe],
  exports: [ImgFullPathPipe],
  imports: [CommonModule]
})
export class ImgFullPathModule {}


import { map } from 'rxjs/operators';
import { URLConfig } from './../../models/url.model';
import { API_URLS } from '../../shared/constants/routes-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JSON_PATHS, CONFIG } from '../../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { throwError, Observable } from 'rxjs';
@Injectable()
export class RoutesConfigService {
  constructor(private http: HttpClient) { }
  getUrl(sourceUrl: string): Observable<URLConfig> {
    if (!sourceUrl) {
      return throwError(null);
    }
    const url = API_URLS.url.getUrl.replace('{sourceUrl}', sourceUrl)
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
    const config = {
      headers: headers
    };

    return this.http.get<URLConfig>(url, config).pipe(map((response) => {
      const items = JsonQuery.value(response, JSON_PATHS.URLItems.items);
      const url: URLConfig = new URLConfig();
      if (items) {
        url.id = JsonQuery.value(items[0], JSON_PATHS.URLItems.id) || '';
        url.name = JsonQuery.value(items[0], JSON_PATHS.URLItems.name) || '';
        url.source = JsonQuery.value(items[0], JSON_PATHS.URLItems.sourceLink) || '';
        url.destination = JsonQuery.value(items[0], JSON_PATHS.URLItems.destination) || '';
        url.urlType = JsonQuery.value(items[0], JSON_PATHS.URLItems.urlType) || '';
        url.messageCode = JsonQuery.value(response, JSON_PATHS.URLItems.messageCode);
      }
      return url;
    }))
  }
}

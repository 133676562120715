export class LegalTermsItemPostModel {
    checked: boolean;
    id_legalterms: number;
    internalDescription: string;
    name: string;
    type: string;
}

export interface LegalTermsSaveResponse {
  result: {
    status: number;
  };
  data: {
    orderToken: string;
    shopTypePrincipal: string;
    orderNum: string;
    completed: boolean;
    order_id: string;
    orderDateCreation: string;
  };
}

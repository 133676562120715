export const FOLDER_ADARA_MY_PRODUCTS = 'adara/adara-my-products';
export const FOLDER_ADARA_WEBVIEW_EXIT = 'adara/adara-webview-exit';
export const FOLDER_ADARA_WEBVIEW_CHECKOUT = 'adara/adara-webview-checkout';
export const FOLDER_ADARA_LOGIN = 'adara/adara-login';
export const FOLDER_ADARA_HELP_SELFCARE = 'adara/adara-help';
export const FOLDER_ADARA_HELP_PURCHASE = 'adara/adara-help-purchase';
export const FOLDER_ADARA_BAJA = 'adara/adara-baja'
export const FOLDER_ADARA_BILLS = 'adara/adara-bills';
export const ADARA_TRACKING = {
    EVENT: 'event',
    PAGE: 'page',
    JOURNEY: 'journey',
    EVENT_LABEL: 'event_label',
    ENTRYPOINTS: 'entrypoints',
    EVENTLIST: 'eventList',
    INTERACTIONS: {
        FILTER_INTERACTION: 'event_filter-interaction',
        FILTER_CLICK: 'event_filter-click-adara',
        CLICK_LEAVE: 'event_click-leave',
        CLICK_CONTINUE: 'event_click-continue',
        CLICK_YES: 'event_click-yes',
        CLICK_NO: 'event_click-no',
        CLICK_ACCESS: 'event_click-access',
        CLICK_PASSWORD: 'event_click-password-recovery',
        CLICK_CLOSE_ADARA_LOGIN: 'event_click-close-adara-login',
        CLICK_HELP: 'event_click_help',
        IMPRESSION_BILLS_BANNER: 'event_my-bills-banner-impression',
        CLICK_BILLS_BANNER: 'event_my-bills-banner-click',
        IMPRESSION_MANAGE_ENERGY_BUTTON: 'event_energy-management-button-impression',
        CLICK_MANAGE_ENERGY_BUTTON: 'event_energy-management-button-click',
    },
    PAGES: {
        PAGE_CHECKOUT: 'page_checkout',
        PAGE_EXIT: 'page_exit',
        PAGE_LOGIN: 'page_login'
    },
    ENTRYPOINTS_DIRECT: {
        IMPRESSION_BILLS_BANNER: 'entrypoint_my-bills-banner-impression',
        CLICK_BILLS_BANNER: 'entrypoint_my-bills-banner-click',
        IMPRESSION_MANAGE_ENERGY_BUTTON: 'entrypoint_energy-management-button-impression',
        CLICK_MANAGE_ENERGY_BUTTON: 'entrypoint_energy-management-button-click',
    }
}



/**
 * Model for double drop down component list item
 *
 * @export
 * @class DoubleDropDownItem
 */
export class DoubleDropDownItem {
  label?: string;
  value?: any;

  public constructor(init?: Partial<DoubleDropDownItem>) {
    Object.assign(this, init);
  }
}

import { NotificationService } from './../shared/services/notification.service';
import { Error } from './../models/error.model';
import { ErrorHandlingService } from './../core/services/errorHandling.service';
import { StorageService } from './../core/services/storage.service';
import { AuthenticateService } from './../core/services/authenticate.service';

import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { SubscriptionService } from '../core/services/subscription.service';
import { CustomerAccountService } from '../shared/services/customer-account.service';
import { RetryService } from '../shared/services/retry.service';
import { AppService } from '../app.service';
import { PAGES } from '../shared/constants/defines';
import { Notification } from './../models/notification.model';
import { CompanyService } from '../shared/services/company.service';


@Injectable()
export class ProductsAndServicesResolve implements Resolve<any> {
    errorObj: Error;
    constructor(private storageService: StorageService,
        private auth: AuthenticateService,
        private customerAccount: CustomerAccountService,
        private subscription: SubscriptionService, private retryService: RetryService,
        private appService: AppService, private errorHandlingService: ErrorHandlingService,
        private notificationService: NotificationService,
        private companyService: CompanyService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.subscription.customerData.currentService) {
            if (!this.customerAccount.customerAccounts.length) {

                return this.customerAccount.
                    GetCustomerAccounts(this.companyService.selectedCompanyId).toPromise().then(() => {
                        return true
                    }).catch(error => {
                        this.errorHandling(state, error);
                        return true;
                    })
            }
            else {
                return true;
            }
        }
        else {
            return true
        }
    }
    errorHandling(state, error) {
        this.appService.showFullAppLoader = false;
        const notificationError = new Notification();
        this.errorObj = this.errorHandlingService.lookUpError(PAGES.PRODUCT_SERVICE, error)
        if (this.errorObj) {
            notificationError.bodyContent = this.errorObj.description;
            notificationError.bodyTitle = this.errorObj.title;
            this.retryService.url = state.url;
            notificationError.showRetryButton = this.errorObj.retryButton;
            notificationError.primaryButtonText = this.errorObj.dismissButtonText || this.errorObj.confirmButtonText;
            notificationError.primaryButtonClick = () => {
                this.notificationService.notificationModal.hide();
            };
            this.notificationService.createNotification(notificationError);
        }
    }
}

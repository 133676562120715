import { BundleStatus } from '../shared/enums/bundleStatus.enum';

export class SVAs {
    public code: string;
    public name: string;
    public category: string;
    public amount: SVAAmount;
    public discount?: Discount;
    public sba: SBA[];
    public resumeIcon: string;
    public detailIcon: string;
    public info: string;
    public longDesc: string;
    public shortDesc: string;
    public type: string;
    public additionalInfo: string;
    public additionalInfoTitle?: string;
    public packagesDesc?: Array<PackagesDesc>;
    public status: string;
    public typeOnePlus: string;
    public deviceName?: string;
  }
  export class SVAAmount {
    public currency: string;
    public taxesCode: string;
    public netAmountDue: number;
    public grossAmountDue: number;
    public taxesDue?: number;
    public amountCredited?: number;
    public chargedFor?: number;
    public type: string | string[];
  }
  export class Discount {
    public amount: SVAAmount;
    public terminationDate: string;
  }
  export class SBA {
      public subscription: Subscription;
      public amount: SVAAmount;
      public discount?: Discount;
      public type: string;
  }
  export class Subscription {
    public msisdn: string;
    public type: string;
    public code?: string;
    public status?: BundleStatus;
    public rgu?: string;
  }
  export class PackagesDesc {
    public short: string;
    public long: string;
  }


import { Component, OnDestroy, OnInit } from '@angular/core';
import { BillingService } from '../billing.service';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sp-pay-debt-other-payment-methods',
  templateUrl: './pay-debt-other-payment-methods.component.html',
  styleUrls: ['./pay-debt-other-payment-methods.component.scss']
})
export class PayDebtOtherPaymentMethodsComponent implements OnInit, OnDestroy {
  firstBtnDescription: string = '';
  payThroughBankBody: string = '';
  constructor(
    public billingService: BillingService,
    public dynamicTray: DynamicTrayService,
    private utilities: UtilitiesService,
    private translate: TranslateService
  ) { }
  ngOnInit() {
    this.translate.get('payment').subscribe((data) => {
      if (this.billingService.showOtherPaymentMethodsSecondBtn) {
        this.firstBtnDescription = data.buttonList.downloadInvoiceButton.text;
      } else {
        this.firstBtnDescription = data.itemsList.otherPaymentMethods.body;
      }
    })
    this.translate.get('billing.readyPayBill.fieldsList').subscribe((data) => {
      if (this.billingService.showOtherPaymentMethodsSecondBtn) {
        this.payThroughBankBody = data.makeDeposite.body;
      } else {
        this.payThroughBankBody = data.payThroughBank.body;
      }
    })
    this.dynamicTray.trayData.closeButtonAction = this.closeTray.bind(this);
  }
  /**
  * Download bill in pdf when click on download btn in cash desk payment.
  * Close tray after PDF Download.
  */
  downloadPdf() {
    const url = this.billingService.readyLink;
    this.utilities.downloadPDF(url, this.billingService.billAccountId);
    this.closeTray();
  }
  /**
  * Executed when click on x in dynamic tray.
  * Executed when click on entendido in case of cash desk payment.
  */
  closeTray() {
    this.dynamicTray.close();
    if (this.billingService.showOtherPaymentMethodsSecondBtn) {
      setTimeout(() => {
        this.billingService.showOtherPaymentMethodsSecondBtn = false;
      }, 500);
    }
  }
  ngOnDestroy(): void {
    this.billingService.forwardTransition = false;
  }
}

import { CompanyService } from '../../shared/services/company.service';
import { StorageService } from '../../core/services/storage.service';
import { AuthenticateService } from '../services/authenticate.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../../../config/pages-config';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { SiteStatus } from '../../shared/enums/siteStatus.enum';
import * as pagesConfig from '../../../config/pages-config';
import { AppService } from '../../app.service';
import { SiteHandlingService } from '../../shared/services/site-handling.service';
import { ImpersonationGroup } from '../../shared/enums/impersonationGroup.enum';
import { ImpersonationService } from '../../shared/services/impersonation.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
@Injectable()
export class AuthorizationGuard implements CanActivate {
  url: string
  constructor(private router: Router,
    private impersonationService: ImpersonationService,
    private companyService: CompanyService,
    public appTheme: AppService,
    private authenticationService: AuthenticateService,
    private storageService: StorageService,
    private route: ActivatedRoute, private deepLinkService: DeepLinkingService,
    private configurationService: ConfigurationService,
    private utilitiesService: UtilitiesService,
    private siteHandlingService: SiteHandlingService) {
    this.route
      .queryParams
      .subscribe(params => {
        this.url = params['targetUrl'];
        if (this.url) {
          this.deepLinkService.routeDeepLink(this.url)
        }
      });
  }
  /**
   * canActivate is checking whether the user is authenticated or not
   * if the user is not authenticated, the storage will be empty and navigate to the login page
   * @param route - snapshot of current route data
   * @param state - snapshot of current state data
   * @returns {Observable<boolean>}
   */
  /** nudge change according to site status if status Desconectado_No_Pago show
   *nudge with two buttons else show nudge with one button
   */

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let authorized: boolean = false;

    const siteStatus: SiteStatus = this.siteHandlingService.getCurrentSiteStatus();

    const _checkDeepLink: () => void = () => {
      if (this.url && this.deepLinkService.isDeepLink) {
        this.router.navigateByUrl(this.url);
      }
      else if (state.url.split('=')[1] && this.deepLinkService.routeDeepLink(decodeURIComponent(state.url.split('=')[1]))) {
        this.router.navigateByUrl(decodeURIComponent(state.url.split('=')[1]));
      } else {
        /**check if site is p2 from JWT and not authorized with multiple companies
         *routing to bills and show nudge
        */
        if (this.siteHandlingService.isSiteP2() ||
          (this.impersonationService.impersonatedUser && this.impersonationService.impersonatedUser.Group &&
            this.impersonationService.impersonatedUser.Group.toLocaleLowerCase() ===
            ImpersonationGroup.PayADebt.toLocaleLowerCase())) {
              if (!this.configurationService.configuration.billingFlag) {
                this.router.navigate([pagesConfig.config.billIsDown.route]);
              } else {
                this.router.navigate([pagesConfig.config.BillingVf10.route]);
              }
        }
        // store 2396 route to manage order in case of pending installation authorized user
        else if (siteStatus && siteStatus.toLowerCase() === SiteStatus.Pend_de_Instalar.toLowerCase()) {
          this.siteHandlingService.handelPendingInstallationCase(next);
        }
        else {
          this.router.navigate([config.dashboard.name]);
        }
      }
      authorized = false;
    }

    if (this.authenticationService.hasRole('Authorized') &&
      this.storageService.userProfile &&
      this.storageService.userProfile.companies) {

      if (this.storageService.userProfile.companies.length === 1 && !this.storageService.userProfile.hasExternalCompanies) {
        this.companyService.selectedCompanyId = this.storageService.userProfile.companies[0].id;
        this.companyService.CompanyList = [];
        this.companyService.CompanyList.push(this.storageService.userProfile.companies[0]);

        if (this.utilitiesService.hasToReregister()) {
          // go to register message page
          this.router.navigate(['/login/reregister']);
        } else {
          _checkDeepLink();
        }
      }
      else {
        authorized = true;
      }
    }
    else {
      /**check if site is p2 from JWT and not authorized with multiple companies
       *routing to bills and show nudge
       */
      if (this.siteHandlingService.isSiteP2()) {
        if (!this.configurationService.configuration.billingFlag) {
          this.router.navigate([pagesConfig.config.billIsDown.route]);
        } else {
          this.router.navigate([pagesConfig.config.BillingVf10.route]);
        }
      }

       // store 2396 route to manage order in case of pending installation authorized user
       if (siteStatus && siteStatus.toLowerCase() === SiteStatus.Pend_de_Instalar.toLowerCase()) {
        this.siteHandlingService.handelPendingInstallationCase(next);
       }
      else {
        if (this.url && this.deepLinkService.isDeepLink) {
          this.router.navigateByUrl(this.url);
        } else {
          this.router.navigate([config.dashboard.name]);
        }
      }
      authorized = false;
    }

    return authorized;
  }
}


import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentError } from '../../../models/payment';
import { ERRORCODES, VERIFYEMAIL } from '../../../shared/constants/defines';
import { PaymentErrorCategoryInWCS } from '../../../shared/enums/payment-error-category-inWCS.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentErrorCategory } from '../../../shared/enums/payment-error-category.enum';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentErrorInterface } from '../paymentErrorDelegate/payment-error-interface';

export class PaymentErrorDefault implements PaymentErrorInterface {

    defaultErrorCategory: PaymentErrorCategory = PaymentErrorCategory.general;
    currentErrorTitle: string;
    displayedErrorCategory: PaymentErrorCategory;
    constructor(public paymentNavigationService: PaymentNavigationService,
        public translate: TranslateService,
        public router: Router) { }


    /**
     * @param ecode error code string
     * functon to map error code to error category
     */
    mapErrorCode(ecode: any): PaymentErrorCategory {
        let errorCategory: PaymentErrorCategory;
        switch (ecode) {
            case ERRORCODES.NOT_A_PREPAID_SERVICE:
            case ERRORCODES.PENDING_MSISDN:
            case ERRORCODES.PAYMENT_INACTIVE_MSISDN:
                errorCategory = PaymentErrorCategory.Notactive;
                break;
            case ERRORCODES.PREPAID_SERVICE_HAS_PENDING_ORDERS:
                errorCategory = PaymentErrorCategory.TopupInProgress;
                break;
            case ERRORCODES.REPLICA_MODE_ERROR:
            case VERIFYEMAIL.errorAppUnavilability:
                errorCategory = PaymentErrorCategory.ReplicaMode;
                break;
            default:
                errorCategory = this.defaultErrorCategory;
                break;
        }
        return errorCategory;
    }
    /**
    * function to fill error overlay with it's proper
    * wcs values and btn actions
    */
    fillErrorOverlay(errorCategory: string): PaymentError {
        this.displayedErrorCategory =
         Object.keys(PaymentErrorCategory).map(key => PaymentErrorCategory[key]).find(value => value === errorCategory)
        if (this.displayedErrorCategory) {
            const currentError: PaymentError = new PaymentError();
            const url = this.paymentNavigationService.getWcsPath(PaymentPages.ko) + '.' + PaymentErrorCategoryInWCS[errorCategory]
            this.translate.get(url).subscribe(data => {
                this.currentErrorTitle = data.title;
                currentError.title = data.subtitle;
                currentError.desc = data.description;
                currentError.imgUrl = data.icon;
                currentError.lottieUrl = 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json';
                currentError.firstBtnText = data.button1;
                currentError.secondBtnText = data.button2;
            });
            currentError.firstBtnAction = this.getFirstBtnAction(errorCategory);
            currentError.secondBtnAction = this.getSecondBtnAction();
            return currentError;
        } else {
            return this.fillErrorOverlay(this.defaultErrorCategory);
        }
    }

    /**
    * the default implmentaion of the first btn in error payment screen
    */
    defaultClose(): void {
        this.paymentNavigationService.defaultKoClose();

    }
    /**
     * the default implmentaion of the second btn in error payment screen
     */
    close(): void {
        this.paymentNavigationService.closePayment();
    }
    /**
     * the default implmentaion of the first btn in error payment screen
     */
    getFirstBtnAction(category?: string): Function {
        return this.defaultClose.bind(this);
    }
    /**
     * the default implmentaion of the second btn in error payment screen
     */
    getSecondBtnAction(): Function {
        return this.close.bind(this);
    }
    /**
     * @param ecode error code string
     * functon to map error code to error category
     */
    handleExceptionalEcode(ecode: string): boolean {
        return false;
    }
    /**
     * action for x btn click on overlay
     */
    closeBtnAction(): void {
        this.paymentNavigationService.defaultKoClose();
    }

}

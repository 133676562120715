export enum SharedServicesPegaReplacerError {
  InvalidTypeForCreateToken = 'Invalid type for create replacer token. Must be String.',
  InvalidValueForCreateToken = 'Invalid value for create replacer token. Must be of type `${string}+${string}`.',
  InvalidTokenForCreateToken = 'Invalid token for create replacer token. Must be equal. Example: `{+}`',
  ReplacerInvalidSource = 'Invalid source for replacer. Must be of type Map or Object',
  ReplacerInvalidTemplate = 'Invalid template for replacer. Must be of type String. Example: `Hi, {name}!`',
}
export interface SharedServicesPegaReplacerReplaceOptions {
  tokens?: SharedServicesPegaReplacerTokens;
  source: SharedServicesPegaReplacerReplaceFromSourceOptionsSource;
  template: string;
}
export type SharedServicesPegaReplacerReplaceFromSourceOptionsSource =
  | Map<string, string | number>
  | Record<string, string | number>;
export interface SharedServicesPegaReplacerReplaceFromSourceOptions {
  tokens?: SharedServicesPegaReplacerTokens;
  source: SharedServicesPegaReplacerReplaceFromSourceOptionsSource;
  template: string;
}

export type SharedServicesPegaReplacerTokens = string; // +ES2020 --> `${string}+${string}`;
export type SharedServicesPegaReplacerTokensMap = Map<'prefix' | 'suffix' | 'separator', string>;

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BuySimService } from '../services/buy-sim.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { config } from '../../../config/pages-config';
import { Router } from '@angular/router';
import { tagging } from '../../../config/tagging-config';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { BuySimSteps } from '../../shared/enums/buy-sim-steps.enum';
import { BUY_SIM_JOURNEY, CommercialJourneys } from '../../shared/constants/defines';
@Component({
  selector: 'sp-payment-ticket',
  templateUrl: './payment-ticket.component.html',
  styleUrls: ['./payment-ticket.component.scss']
})
export class PaymentTicketComponent implements OnInit, AfterViewInit, OnDestroy {
  submitted = false;
  /** show and hide terms backdrop */
  showTermsOverlay = false;
  /** holds terms data that shown in backdrop */
  termsData: OverlayModel = new OverlayModel();
  /**payment link */
  paymentLink: string;
  /**This flag for payment details section */
  public showDetails = false;
  /**chevron title */
  chevronTitle: string;
  /**cancel chevron title*/
  cancelButton: string;
  /**see more chevron title*/
  seeDetailsButton: string;
  /**for promotion more details button*/
  chevronPromotionTitle: string;
  /**flag to see promotion more details */
  showPromotionDetails: boolean = false;
  hintDescLine1 = '';
  hintDescLine2 = '';
  /**selected number type */
  numberType: string;
  /** terms and conditions checkbox ref */
  @ViewChild('generalTerms') generalTerms: ElementRef;
  /**initial balance texts */
  paymentCashOnDeliveryHint: string;
  freePayOnDeliveryHint: string;
  paymentPendingHint: string;
  constructor(public buySimService: BuySimService,
    private translate: TranslateService,
    private router: Router,
    private tagging: TaggingHelperService) { }

  ngOnInit() {
    this.getTitleFromWCS();
    this.setUIData();
    this.setTermsBackdropObj();
    if (this.router.url === config.buySim.payment.route) {
      this.buySimService.legalTerms.forEach(legalTerm => {
        legalTerm.notSavedChecked = false;
        legalTerm.checked = false
      });
      this.buySimService.sendPageTagging(tagging.buySIMJourney.payment);
      this.buySimService.findByToken();
      this.buySimService.getShoppingCart();
      this.buySimService.currentStep = BuySimSteps.stepThreePayment;
    }
  }

  /** binds value of terms checkbox from history service */
  ngAfterViewInit(): void {
    if (this.buySimService.isPaymentFullScreen) {
      this.generalTerms.nativeElement.checked = this.buySimService.paymentTermsAndConditionFlag;
    }
  }

  getTitleFromWCS(): void {
    if (this.buySimService.isPaymentFullScreen) {
    this.translate.get('v10.buySim.itemsList').subscribe(data => {
      this.buySimService.screenTitle = data.paymentDataTitle;
    });
    }
  }

  setUIData(): void {
    this.buySimService.hideTicketDetails =
      this.router.url === config.buySim.payment.route || this.router.url === config.buySim.orderConfirmation.route;
    this.buySimService.isPaymentFullScreen =
      this.router.url === config.buySim.payment.route || this.router.url === config.buySim.orderConfirmation.route;
    if (this.buySimService.isPaymentFullScreen) {
      this.buySimService.hideHintText = true;
    }
    if (this.buySimService.hideTicketDetails && this.buySimService.isPaymentFullScreen) {
      this.buySimService.showFullLoader = false;
    }
    this.translate.get('v10.buySim.itemsList.paymentTermsLink').subscribe(data => {
      this.paymentLink = data;
    });
    this.translate.get('v10.buySim.itemsList.payment.seeDetails').subscribe(data => {
      this.seeDetailsButton = data;
      this.chevronTitle = data;
      this.chevronPromotionTitle = data;
    });
    this.translate.get('v10.buySim.itemsList.payment.cancel').subscribe(data => {
      this.cancelButton = data;
    });
    this.translate.get('v10.buySim.itemsList.paymentCashOnDeliveryHint').subscribe(data => {
      this.paymentCashOnDeliveryHint = data;
    });
    this.translate.get('v10.buySim.itemsList.freePayOnDeliveryHint').subscribe(data => {
      this.freePayOnDeliveryHint = data;
    });
    this.translate.get('v10.buySim.itemsList.paymentPendingHint').subscribe(data => {
      this.paymentPendingHint = data;
    });
    this.translate.get('v10.buySim.itemsList').subscribe(data => {
      if (this.buySimService.newNumberChecked) {
        this.numberType = data.paymentNewNumber;
      }
      else {
        this.numberType = data.paymentScreen.portabilityLabel;
      }
    });
  }

  /**set initial balance text depends on total cost */
  getDeliveryHint(): string {
    if (this.buySimService.currentStep === BuySimSteps.stepZeroPersonalInfo) {
      return this.paymentPendingHint;
    } else {
      return (+this.setInitalBalanceAmount().split(CommercialJourneys.euros)[0].trim() === +BUY_SIM_JOURNEY.portabilityInitialAmount) ?
        this.freePayOnDeliveryHint : this.paymentCashOnDeliveryHint;
    }
  }

  onSubmit(): void {
    this.tagging.track(tagging.buySIMInteractions.clickOnFinishOrder.eventName,
      tagging.buySIMInteractions.clickOnFinishOrder.data, tagging.buySimPages.payment);
    this.submitted = true
    if (this.buySimService.paymentTermsAndConditionFlag) {
      this.buySimService.showFullLoader = true;
      this.router.navigate([config.buySim.orderConfirmation.route]);
    }
  }

  /** set terms backdrop title and button */
  setTermsBackdropObj(): void {
    this.translate.get('v10.buySim.itemsList.paymentOverlayTitle').subscribe(data => {
      this.termsData.title = data;
    });
  }

  /** close terms backdrop */
  closeTermsOverlay(sendTaggingFlag = false): void {
    this.showTermsOverlay = false;
    this.buySimService.preventScroll = false;
    this.buySimService.preventBackgroundScrolling = false;
    if (sendTaggingFlag) {
      this.tagging.track(tagging.buySIMInteractions.clickOnSaveCondition.eventName, tagging.buySIMInteractions.clickOnSaveCondition.data,
        tagging.buySimPages.generalConditions);
        setTimeout(() => {
          this.buySimService.legalTerms.forEach(legalTerm => legalTerm.checked = legalTerm.notSavedChecked)
          }, 500);

    }
    else {
      setTimeout(() => {
        this.buySimService.legalTerms.forEach(legalTerm => legalTerm.notSavedChecked = legalTerm.checked)
      }, 500);
    }
  }

  /** opens terms backdrop */
  onTermsClick(): void {
    this.showTermsOverlay = true;
    this.buySimService.preventScroll = true;
    this.tagging.view(tagging.buySIMJourney.paymentTermsAndCondition);
    setTimeout(() => {
      this.bindLegalTermsCheckBoxes();
    }, 0);
    this.buySimService.preventBackgroundScrolling = true;
  }

  /** binds value of legal check boxes  */
  bindLegalTermsCheckBoxes(): void {
    this.buySimService.legalTerms.forEach((term, index) => {
      const termHtmlElement: HTMLInputElement = document.getElementsByName(`terms-${index}`)[0] as HTMLInputElement;
      termHtmlElement.checked = term.checked;
    });
  }

  /** sets checked value foreach legal term item */
  onlegalTermClick(value: any, index: any): void {
    this.buySimService.legalTerms[index].notSavedChecked = value;
  }

  /** sets checked value foreach legal term item */
  onConditionClick(value: any): void {
    this.buySimService.paymentTermsAndConditionFlag = value;
    if (value) {
      this.tagging.track(tagging.buySIMInteractions.clickOnAcceptGeneralCondition.eventName,
        tagging.buySIMInteractions.clickOnAcceptGeneralCondition.data, tagging.buySimPages.payment);
    }
  }

  onToggle(): void {
    this.showDetails = !this.showDetails;
    this.chevronTitle = this.showDetails ? this.cancelButton : this.seeDetailsButton;
  }

  onChevronToggle(): void {
    this.showPromotionDetails = !this.showPromotionDetails;
    this.chevronPromotionTitle = this.showPromotionDetails ? this.cancelButton : this.seeDetailsButton;
  }

  /** resets ui flags at destroy */
  ngOnDestroy(): void {
    if (this.router.url !== config.buySim.payment.route &&
       this.router.url !== config.buySim.orderConfirmation.route &&
       this.router.url !== config.buySim.generalError.route) {
      this.buySimService.hideTicketDetails = false;
      this.buySimService.isPaymentFullScreen = false;
    }
  }
  /** sets initial balance in payment ticket */
  setInitalBalanceAmount(): string {
    return (this.buySimService.currentStep === BuySimSteps.stepZeroPersonalInfo) ? BUY_SIM_JOURNEY.buySimInitialBalance :
      this.buySimService.shoppingCart.initialBalance;
  }
}



export class BannerCardModel {
    icon?: string;
    title?: string;
    description?: string;
    button?: string;
    buttonLink?: string;
}


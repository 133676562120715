export enum BillAlarmStatus {
    newProduct = 'newproduct',
    newProductPayInAdvance = 'newproductpayinadvance',
    changeTariff = 'changetariff',
    changeTariffPayInAdvance = 'changetariffpayinadvance',
    singleCharge = 'singleCharge',
    worryFree = 'worryfree',
    reconnection = 'reconnection',
    creditNote = 'creditnote',
    extraConsumption = 'extraconsumption'
}



import { map, catchError } from 'rxjs/operators';
import { TaggingHelperService } from './../core/services/tagging.helper.service';

import { billingAccountQueryParamTypes, cachingKeys, defines, TopupPrepaidStages, ANONYMOUS } from './../shared/constants/defines';
import { CurrentConsumptionData, TimePeriod } from './../models/billing-current-consumption.model';
import { SubscriptionService } from './../core/services/subscription.service';
import { CustomerAccount } from './../models/customer-account.model';
import { BillNotificationType } from './../shared/enums/bill-notification-type.enum';

import { CreditNote } from './../models/cerdit-notes.model';
import { SpModalFullComponent } from './../shared/components/sp-modal-full/sp-modal-full.component';
import { AppService } from './../app.service';
import * as _ from 'lodash';
import { BillStatus } from './../shared/enums/billStatus.enum';
import { BillDetailsCategory, BillDetailsModel, Taxes } from './../models/bill-details.model';
import { UtilitiesService } from './../shared/utils/utilities.service';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Billing, BillingAccount, BillingCycle, BillOverview, Amount, Subscription, BillConfiguration } from './../models/billing.model';
import * as constants from '../shared/constants/defines';
import * as JsonQuery from 'jsonpath/jsonpath';
import { BillingData } from './billing-data';
import { config } from '../../config/pages-config';
import { Router } from '@angular/router';
import { BillNotification, Alert } from '../models/billing.model';
import { Subject, Observable, throwError } from 'rxjs';
import { BillAlarmStatus } from '../shared/enums/billAlarmStatus.enum';
import { TranslateService } from '@ngx-translate/core';
import { billClass, billQueryString, pathEBillConfigPath, AlertId, billReviewTicketCreation } from '../shared/constants/defines';
import { LanguageEnum } from '../shared/enums/language.enum';
import { BillingAccounts } from '../models/billing-accounts.model';
import { PaymentMethod } from '../models/payment-method.model';
import { Debt } from '../models/debt.model';
import { BillPaymentConfirmationRequest } from '../models/bill-payment-confirmation-request.model';
import { BillPaymentProcessRequest } from '../models/bill-payment-process-request.model';
import { evict } from '../shared/decorators/evict.decorator';
import { tagging } from '../../config/tagging-config';
import { StorageService } from '../core/services/storage.service';
import { BillDetailsCategoryType } from '../shared/enums/bill-details-cat-type.enum';
import { CallOptionRequest } from '../models/callId-model';
import { CrossFunctionalityService } from '../shared/services/cross-functionality.service';
import { NetPlusIframeRequest } from '../models/netplus-iframe-request.model';
import { BillingAddress } from '../models/billing-address.model';
import { IDynamicTray } from '../models/dynamic-tray.model';
import { CrossVariables } from '../models/tagging.model';
import { TopUpService } from '../top-up/top-up.service';
import { DynamicTrayService } from '../shared/services/dynamic-tray.service';
import { SpMva10OverlayService } from '../shared/services/sp-mva10-overlay.service';
import { JourneyIdEnum } from '../shared/enums/iframe-status.enum';
import { CallOptionsTypes } from '../shared/enums/call-options-types.enum';
import { FundedDevice } from '../models/funded-device.model';
import {
  PaymentCustomer, PaymentJourneyParams, PaymentTaggingJourney,
  PaymentTaggingObject, PaymentTaggingObjectArchetype, PaymentTaggingPageName, TrackActionsObject
} from '../models/payment';
import { PaymentJourneyIds } from '../shared/enums/payment-journey-ids.enum';
import {PaymentJourneyName } from '../shared/enums/payment-journey-name.enum';
import { PaymentJourneySubCategory } from '../shared/enums/payment-journey-sub-category.enum';
import {PaymentJourneyType } from '../shared/enums/payment-journey-type.enum';
import { PaymentJourneyProcess } from '../shared/enums/payment-journey-process.enum';
import { PaymentJourneyPaymentType } from '../shared/enums/payment-journey-payment-type.enum';
import { PaymentJourneyDetail } from '../shared/enums/payment-journey-detail.enum';
import { PaymentJourneyEnvironment } from '../shared/enums/payment-journey-environment.enum';
import { PaymentJourny } from '../shared/enums/payment-journy.enum';
import { PaymentJourneyUserType } from '../shared/enums/payment-journey-user-type.enum';
import { PaymentJourneyEvents } from '../shared/enums/payment-journey-events.enum';
import { PaymentLanguages } from '../shared/enums/payment-languages.enum';
import { PaymentNavigationService } from '../payment/services/payment-navigation.service';
import { PaymentWalletService } from '../shared/services/payment-wallet.service';
import { PaymentCardModel } from '../models/payment-card.model';
import { Breakpoints } from '@mva10/mva10-angular';
import { BillDetailsServiceUsage, ServiceUsagesResponseModel } from '../models/bill-details-service-usage.model';
import { SiteStatus, siteTypes } from '../shared/enums/siteStatus.enum';
import { SiteHandlingService } from '../shared/services/site-handling.service';
import { BillReviewTicketRequestBody } from '../models/bill-review-ticket-requestBody.model';
import { FOLDER_FINANCED_DEVICES, PAYMENTS_TRACKING } from '../shared/constants/archetype/paymentsTracking';
import { PaymentCommitmentResponse } from '../models/payment-commitment-response.model';
import { EnergyDebtResponse } from '../models/energy-debt-model';
import { BillStatusModel } from '../models/bill-status.model';
import { NifAndCif } from './nif-and-cif.modal';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public cameToServiceUsageFromCurrentConsumption: boolean;
  showAlarm: boolean;
  error: boolean;
  public bills: Array<Billing>;
  public billsOffset: Array<Billing>;
  public selectedBill: Billing;
  public selectedBillId: string;
  public positionBillId: string;
  public creditNotes: Array<CreditNote>;
  public deviceInvoices: Array<CreditNote>;
  public remainingDays: number;
  public isEditFlag: boolean;
  public fullModal: SpModalFullComponent;
  public fullModalAlarms: SpModalFullComponent;
  public oldAlias: string;
  public showSpinner: boolean;
  public showFullAppLoader: boolean;
  public formattedStartDate: string;
  public formattedEndDate: string;
  private dateForRemaining: string;
  public showError: boolean;
  public toDate: string;
  public selectedCategory: BillDetailsCategory;
  public selectedService: Subscription;
  public currentConsumptionSelectedService: Subscription;
  public selectedBillInfo;
  public billingSites: Array<CustomerAccount>;
  public selectedSiteId: string;
  public selectedSiteStatus: string;
  /** To be used with the new site selector */
  public selectedSiteChanged: Subject<CustomerAccount> = new Subject<CustomerAccount>();
  public billConfiguration: BillConfiguration;
  public newEBillAlias: string;
  public Castellano: string;
  public Catalan: string;
  public Euskera: string;
  public Gallego: string;
  public Ingles: string;
  statusForBillPayment;
  public successPaymentProcess: boolean;
  @Output() public aliasChanged = new EventEmitter<string>();
  public currentIndex: number;
  public updateBillsObservable = new Subject<any>();
  /** This subject will be emitted once billing sites loaded */
  public billingSitesLoaded: Subject<Array<CustomerAccount>> = new Subject<Array<CustomerAccount>>();
  /** Flag for force passing DebtTotalAmountGuard guard */
  public cameFromDebtTotalAmountOverlay: boolean;
  billShokCheckedItemList: BillDetailsCategory[] = [];
  public eBillAliasSubject = new Subject<any>();
  public pciIframSpinner: boolean = false;
  /** subject fire when success payment happend after closing success overlay */
  public successPaymentSubject = new Subject<Boolean>();
  // we start revamping by adding this values
  deptAmount: number;
  /** the selected amount in case of cash desk user */
  cashDeskAmount: number;
  billAccountId: string;
  dynamicTrayBackBtnClicked = new Subject<any>();
  forwardTransition: boolean = false;
  backwardTransition: boolean = false;
  fadeOutTransition: boolean = false;
  dynamicTrayData: IDynamicTray;
  readyLink: string = '';
  showOtherPaymentMethodsSecondBtn: boolean = false;
  // boolean to show leaving payment overlay in start screens.
  showLeavingOverlay: boolean = false;
  // subject to fire when click on x and continuar button in leaving overlay in start screen.
  resetFormSubject: Subject<any> = new Subject<any>();
  // title of start screen.
  startScreenTitle: string = '';
  // debt total or patial payment
  paymentType: string = '';
  isPayingEnergyDebt: boolean = false;
  /**
   *the billing acoount of the user
   *we store all data we need  about the user and his dept or current bill
   *
   * @type {BillingAccounts}
   * @memberof BillingService
   */
  public billingAccount: BillingAccounts;
  /** Counter for counting the number of finished APIs */
  doneAPIsCounter: number = 0;

  /**
   *
   * it indeicate that we start payment journey for post paid user
   * the most important boolean here we use it inside top up
   * journey to custom any business in topup journeys to payment buissness need
   * @type {boolean}
   * @memberof BillingService
   */
  public startBillingPayment: boolean = false;
  /**
   *
   *
   * @type {boolean}
   * @memberof BillingService
   * for checking if this is a user needs to pay his dept
   */
  _isDeptPayment: boolean = false;
  /**
   *
   *
   * @type {boolean}
   * @memberof BillingService
   * for checking if this is a user needs to pay in advance
   */
  _isInAdvancePayment: boolean = false;
  /**
   *
   *
   * @type {boolean}
   * @memberof BillingService
   * for checking if this is a cash desk user
   */
  _isCashDeskPayment: boolean = false;
  showPayPartial: boolean;
  // the devices user buy and currently pay installment for them
  public fundedDevices: FundedDevice[];
  // the devices user see it's details
  public selectedFundedDevice: FundedDevice;
  public isPendingCancellation: boolean;
  public selectedBillReviewAccID: string;

  public get isDeptPayment(): boolean {
    return this._isDeptPayment;
  }
  public set isDeptPayment(value: boolean) {
    this._isDeptPayment = value;
  }
  public get isInAdvancePayment(): boolean {
    return this._isInAdvancePayment;
  }
  public set isInAdvancePayment(value: boolean) {
    this._isInAdvancePayment = value;
  }
  public get isCashDeskPayment(): boolean {
    return this._isCashDeskPayment;
  }
  public set isCashDeskPayment(value: boolean) {
    this._isCashDeskPayment = value;
  }
  public updateBills(): void {
    this.updateBillsObservable.next();
  }
  constructor(public billingData: BillingData,
    private router: Router,
    private utilsService: UtilitiesService,
    private appService: AppService,
    public translate: TranslateService,
    private subscriptionService: SubscriptionService,
    public tagging: TaggingHelperService,
    private storage: StorageService,
    private topupService: TopUpService,
    private dynamicTrayService: DynamicTrayService,
    public trayService: SpMva10OverlayService,
    private cross: CrossFunctionalityService,
    private paymentWalletService: PaymentWalletService,
    public paymentNavigationService: PaymentNavigationService,
    private siteHandlingService: SiteHandlingService,
  ) {
  }

  /**map billing accounts data */
  mapBillingAccountsData(item: any) {
    const billing = new Billing();
    billing.id = JsonQuery.value(item, constants.JSON_PATHS.Billing.billId) || null;
    billing.billNo = JsonQuery.value(item, constants.JSON_PATHS.Billing.billNo) || null;
    billing.status = JsonQuery.value(item, constants.JSON_PATHS.Billing.billStatus) || null;
    billing.creationDate = JsonQuery.value(item, constants.JSON_PATHS.Billing.creationDate) || null;
    billing.billingAccount = new BillingAccount();
    billing.billingAccount.billingAddress = new BillingAddress();
    const billingAccount = JsonQuery.value(item, constants.JSON_PATHS.Billing.billingAccount) || null;
    if (billingAccount) {
      billing.billingAccount.id = JsonQuery.value(billingAccount, constants.JSON_PATHS.Billing.billingAccountId) || null;
      billing.billingAccount.alias = JsonQuery.value(billingAccount, constants.JSON_PATHS.Billing.billingAccountAlias) || null;
      const billingAddress = JsonQuery.value(billingAccount, constants.JSON_PATHS.Billing.billingAddress) || null;
      if (billingAddress) {
        billing.billingAccount.billingAddress.formattedAddress =
          JsonQuery.value(billingAddress, constants.JSON_PATHS.Billing.billingAccountFormattedAddress) || null;
      }
    }
    billing.billingCycle = new BillingCycle();
    const billigCycle = JsonQuery.value(item, constants.JSON_PATHS.Billing.billingCycle) || null;
    if (billigCycle) {
      billing.billingCycle.billingMonth = JsonQuery.value(billigCycle, constants.JSON_PATHS.Billing.billingMonth) || null;
      billing.billingCycle.billingYear = JsonQuery.value(billigCycle, constants.JSON_PATHS.Billing.billingYear) || null;
      billing.billingCycle.startDate = JsonQuery.value(billigCycle, constants.JSON_PATHS.Billing.startDate) || null;
      billing.billingCycle.endDate = JsonQuery.value(billigCycle, constants.JSON_PATHS.Billing.endDate) || null;

      this.dateForRemaining = billing.billingCycle.endDate;
      this.utilsService.getDateWithMonthNameAsync(billing.billingCycle.startDate, true).subscribe(date => {
        this.formattedStartDate = date;
      });
      this.utilsService.getDateWithMonthNameAsync(billing.billingCycle.endDate, true).subscribe(date => {
        this.formattedEndDate = date;
      });
    }

    billing.billOverview = new BillOverview();
    const billOverview = JsonQuery.value(item, constants.JSON_PATHS.Billing.billOverview) || null;
    if (billOverview) {
      billing.billOverview.numberOfAlarms = JsonQuery.value(billOverview, constants.JSON_PATHS.Billing.numberOfAlarms) || null;
      billing.billOverview.amounts = new Amount()
      const amount = JsonQuery.value(billOverview, constants.JSON_PATHS.Billing.billAmount) || null;
      if (amount) {
        billing.billOverview.amounts.currency = JsonQuery.value(amount, constants.JSON_PATHS.Billing.billCurrency) || null;
        billing.billOverview.amounts.grossAmountDue = JsonQuery.value(amount, constants.JSON_PATHS.Billing.grossAmountDue) || 0;
        billing.billOverview.amounts.remainingAmountDue = JsonQuery.value(amount, constants.JSON_PATHS.Billing.remainingAmountDue) || 0;
      }
    }
    const links = JsonQuery.value(item, constants.JSON_PATHS.Billing.links) || null;
    if (links) {
      billing.docHref = JsonQuery.value(links, constants.JSON_PATHS.Billing.billDocumentLink) || null;
    }
    billing.subscriptions = new Array<Subscription>();
    billing.subscriptions = JsonQuery.value(item, constants.JSON_PATHS.Billing.subscriptions) || null;
    return billing;
  }
  GetBills() {
    this.bills = new Array<Billing>();
    return this.billingData.GetBills(billQueryString.bill).pipe(map((res) => {
      const items = JsonQuery.value(res, constants.JSON_PATHS.Billing.items);
      items.map((item) => {
        this.bills.push(this.mapBillingAccountsData(item));
      });
      return this.bills;
    }), catchError((error) => {
      return throwError(error);
    }));
  }
  /**
   * call api of customerBills and send siteId to it to get bills related to that site
   * @returns {Observable<Billing[]} observable of userBills
   */
  getUserBillsBySiteId(selectedSiteId: string): Observable<Billing[]> {
    const userBills: Array<Billing> = new Array<Billing>();
    return this.billingData.getUserBillsBySiteId(selectedSiteId).pipe(map((res) => {
      const items: any = JsonQuery.value(res, constants.JSON_PATHS.Billing.items);
      items.map((item) => {
        userBills.push(this.mapBillingAccountsData(item));
      });
      return userBills;
    }
    ));
  }


  GetBillsOffset(offset?: number) {
    this.billsOffset = new Array<Billing>();
    return this.billingData.GetBills(billQueryString.bill, null, offset).pipe(map((res) => {
      const items = JsonQuery.value(res, constants.JSON_PATHS.Billing.items);
      items.map((item) => {
        this.billsOffset.push(this.mapBillingAccountsData(item));
      });
      return this.billsOffset;
    }
    ));
  }
  getBillingAccountById(id: string) {
    this.billingAccount = new BillingAccounts();
    const billingId = id || this.subscriptionService.customerData.currentService.siteId;
    return this.billingData.getBillingAccountById(billingId
      , this.selectedSiteId, billingAccountQueryParamTypes.customerDebt).pipe(map((res) => {
        this.billingAccount = this.mapBillingAccountData(res);
        return this.billingAccount;
      }));
  }

  /**map billing accounts data */
  mapBillingAccountData(item: any) {
    const billingAccount = new BillingAccounts();
    billingAccount.id = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.id) || null;
    billingAccount.paymentMethod = new PaymentMethod();
    const paymentMethod = item[constants.JSON_PATHS.BillingAccount.paymentMethod] || null;
    if (paymentMethod) {
      billingAccount.paymentMethod.bankName = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.bankName) || '';
      billingAccount.paymentMethod.accountNumber = JsonQuery.value(paymentMethod,
        constants.JSON_PATHS.BillingAccount.accountNumber) || '';
      billingAccount.paymentMethod.bic = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.bic) || '';
      billingAccount.paymentMethod.iban = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.iban) || '';
      billingAccount.paymentMethod.type = JsonQuery.value(paymentMethod, constants.JSON_PATHS.BillingAccount.type) || '';
    }
    billingAccount.debt = new Debt();
    const debt = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.debt) || null;
    if (debt) {
      billingAccount.debt.amount = JsonQuery.value(debt, constants.JSON_PATHS.BillingAccount.amount) || '';
      billingAccount.debt.dueDate = JsonQuery.value(debt, constants.JSON_PATHS.BillingAccount.dueDate) || '';
      billingAccount.debt.notReflectedPayments = JsonQuery.value(debt, constants.JSON_PATHS.BillingAccount.notReflectedPayments) || false;
    }
    billingAccount.notReflectedCachDesk = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.notReflectedCachDesk) || false;
    billingAccount.reflectedPaymentsType = JsonQuery.value(item, constants.JSON_PATHS.BillingAccount.reflectedPaymentsType);
    return billingAccount;
  }
  getBillsWithStatus() {
    this.bills = new Array<Billing>();
    return this.billingData.getBillsWithStatus(this.selectedSiteId, billQueryString.status.ready).pipe(
      map((res) => {
        const items = JsonQuery.value(res, constants.JSON_PATHS.Billing.items);
        items.map((item) => {
          this.bills.push(this.mapBillingAccountsData(item));
        });
        return this.bills;
      }
      ));
  }
  getBillsSorted(arr: Billing[]) {
    const bills = this.utilsService.group('billingCycle.billingYear', arr, 'billingCycle.billingMonth')
    bills.sort(function (a, b) {
      return a.keyOne - b.keyOne;
    })
    bills.forEach(element => {
      element.items.sort(function (a, b) {
        return a.keyTwo - b.keyTwo;
      })
    })
    const finalBills = new Array<Billing>();
    bills.forEach(element => {
      element.items.forEach((oneBill) => {
        oneBill.items.forEach((element, index) => {
          finalBills.push(element)

        });
      });
    });
    return finalBills;
  }
  getCurrentAccountBillsAndSorted() {
    const arr = this.bills.filter(bill => {
      return bill.billingAccount.id === this.selectedBill.billingAccount.id;
    });
    return this.getBillsSorted(arr);
  }

  getBillNotification(id: string) {
    return this.billingData.GetNotification(id).pipe(map((res) => {
      const notification = res as BillNotification;
      notification.alert = JsonQuery.value(res, constants.JSON_PATHS.Billing.Notification.alerts) as Alert[];
      return notification;
    }));
  }
  private mapBillingDetailsData(item: any) {
    const billDetails = new BillDetailsModel();
    billDetails.total = JsonQuery.value(item, constants.JSON_PATHS.BillDetails.total) || 0; // gross
    billDetails.subTotal = JsonQuery.value(item, constants.JSON_PATHS.BillDetails.subTotal) || 0; // net
    billDetails.taxes = new Array<Taxes>();
    billDetails.taxes = JsonQuery.value(item, constants.JSON_PATHS.BillDetails.taxes) || 0; // taxes
    billDetails.tax = JsonQuery.value(item, constants.JSON_PATHS.BillDetails.taxesDue) || 0; // taxesDue (total taxes)
    billDetails.categories = [];
    billDetails.status = (<BillStatus>this.selectedBill.status) || null;

    if (billDetails.status) {
      if (billDetails.status.toLowerCase() !== BillStatus.error.toLowerCase()
        && billDetails.status.toLowerCase() !== BillStatus.generalError.toLowerCase()
        && billDetails.status.toLowerCase() !== BillStatus.inProgress.toLowerCase()) {

        const allDetailsItems = JsonQuery.value(item, constants.JSON_PATHS.BillDetails.allDetailesItems);
        const sortedItems = _.sortBy(allDetailsItems, billQueryString.aggregationLevel)
        const groupedItems = _.groupBy(sortedItems, billQueryString.type);
        const keys = Object.keys(groupedItems);
        let oldOfferIndex = 0;
        let newOfferIndex = 0;
        let isFirtOldOffer = true;
        let isFirstNewOffer = true;
        for (let index = 0; index < keys.length; index++) {
          /** take first item of any group as a category */
          const billDetailsCategory = this.mapBillDetailsCategory(groupedItems[keys[index]][0]);
          billDetailsCategory.subItems = [];
          for (let index2 = 1; index2 < groupedItems[keys[index]].length; index2++) {
            const subItem = this.mapBillDetailsCategory(groupedItems[keys[index]][index2]);
            if (subItem) {
              if (subItem.newOffer === undefined || billDetailsCategory.categoryType !== BillDetailsCategoryType.basic) {
                billDetailsCategory.subItems.push(subItem);
              } else if (!subItem.newOffer) {
                if (isFirtOldOffer) {
                  subItem.firstIndex = true;
                  isFirtOldOffer = false;
                }
                billDetailsCategory.subItems.splice(oldOfferIndex, 0, subItem);
                oldOfferIndex++;
                newOfferIndex++;
              } else if (subItem.newOffer) {
                if (isFirstNewOffer) {
                  subItem.firstIndex = true;
                  isFirstNewOffer = false;
                }
                billDetailsCategory.subItems.splice(newOfferIndex, 0, subItem);
                newOfferIndex++;
              }
            }
          }
          // Check last index to draw New Offer Header
          if (billDetailsCategory.categoryType === BillDetailsCategoryType.basic) {
            const element = billDetailsCategory.subItems.find(element => element.newOffer === true);
            const index = billDetailsCategory.subItems.indexOf(element);
            if (index > 0) {
              billDetailsCategory.subItems[index - 1].lastIndex = true;
              const newOfferitems = billDetailsCategory.subItems.filter(element => element.newOffer === true);
              const firstItemName = newOfferitems[0].name;
              billDetailsCategory.subItems[index].nameQuotaHeader = this.getNewOfferHeader(newOfferitems, firstItemName);
            } else {
              billDetailsCategory.subItems[0].firstIndex = false;
            }
          }
          billDetails.categories.push(billDetailsCategory);
        }
      } else if (billDetails.status.toLowerCase() === BillStatus.inProgress.toLowerCase()) {
        this.remainingDays = this.getRemainingDays(this.selectedBill.billingCycle.endDate);
      }
    }

    const allSubItems: BillDetailsCategory[] = [].concat(...billDetails.categories.map(category => category.subItems));
    const realNumberOfAlarms = allSubItems.filter(item => item.notificationType &&
      item.notificationType.toLowerCase() === BillNotificationType.alarm.toLowerCase());
    billDetails.numberOfAlarms = realNumberOfAlarms.length;

    return billDetails;
  }

  private getNewOfferHeader(items, name) {
    let title = null;
    if (items.length === 1) {
      this.translate.get('billing.billDetails.fieldsList.singleNewOfferSeparator.body').subscribe(data => {
        title = data.replace('{0}', name);
      });
    }
    if (items.length > 1) {
      this.translate.get('billing.billDetails.fieldsList.multipleNewOfferSeparator.body').subscribe(data => {
        title = data;
      });
    }
    return title;
  }

  private mapBillDetailsCategory(category) {
    const result = new BillDetailsCategory();
    result.discounts = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.discounts) || null;
    if (result.discounts) {
      result.discounts.forEach(element => {
        this.mapDiscounts(element);
      });
    }
    result.amount = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.amount) || null;
    result.name = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.name) || null;
    result.categoryType = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.type) || null;
    result.serviceNumber = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.serviceNumber) || null;
    result.newOffer = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.newOffer);
    result.typeAlarm = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.typeAlarm);
    result.startDate = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.startDate) || null;
    result.endDate = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.endDate) || null;

    result.isNavigator = false;
    result.isPurple = false;
    result.showIcon = false;

    const itemLevel = category[billQueryString.aggregationLevel];
    if (itemLevel === 0) {
      /** this number will return when aggregated level is 0 only */
      const numberOfCategoryAlarms = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.numberOfNotifications) || 0;
      if (numberOfCategoryAlarms && numberOfCategoryAlarms > 0) {
        result.isNavigator = false;
        result.isPurple = true;
        result.showIcon = true;
      }
    } else {
      const notification = JsonQuery.value(category, constants.JSON_PATHS.BillDetails.notification) || null;

      if (notification) {
        result.notificationId = JsonQuery.value(notification, constants.JSON_PATHS.BillDetails.notificationId) || null;

        result.notificationType = JsonQuery.value(notification, constants.JSON_PATHS.BillDetails.notificationType) || null;
        if (result.notificationType) {
          switch (result.notificationType.toLowerCase()) {
            case BillNotificationType.alarm.toLowerCase():
              result.isNavigator = true;
              result.isPurple = true;
              result.showIcon = true;
              break;
            case BillNotificationType.help.toLowerCase():
              result.isNavigator = false;
              result.isPurple = false;
              result.showIcon = false;
              break;
          }
        }
      }
    }

    return result;
  }
  private mapDiscounts(discount) {
    discount.amount = JsonQuery.value(discount, constants.JSON_PATHS.BillDetails.amount) || null;
    discount.name = JsonQuery.value(discount, constants.JSON_PATHS.BillDetails.name) || null;
    discount.categoryType = JsonQuery.value(discount, constants.JSON_PATHS.BillDetails.type) || null;
    discount.serviceNumber = JsonQuery.value(discount, constants.JSON_PATHS.BillDetails.serviceNumber) || null;

    discount.isNavigator = false;
    discount.isPurple = false;
    discount.showIcon = false;

    const itemLevel = discount[billQueryString.aggregationLevel];
    if (itemLevel === 0) {
      /** this number will return when aggregated level is 0 only */
      const numberOfCategoryAlarms = discount.numberOfNotifications || 0;
      if (numberOfCategoryAlarms && numberOfCategoryAlarms > 0) {
        discount.isNavigator = false;
        discount.isPurple = true;
        discount.showIcon = true;
      }
    } else {
      const notification = JsonQuery.value(discount, constants.JSON_PATHS.BillDetails.notification) || null;

      if (notification) {
        discount.notificationId = JsonQuery.value(notification, constants.JSON_PATHS.BillDetails.notificationId) || null;

        discount.notificationType = JsonQuery.value(notification, constants.JSON_PATHS.BillDetails.notificationType) || null;
        if (discount.notificationType) {
          switch (discount.notificationType.toLowerCase()) {
            case BillNotificationType.alarm.toLowerCase():
              discount.isNavigator = true;
              discount.isPurple = true;
              discount.showIcon = true;
              break;
            case BillNotificationType.help.toLowerCase():
              discount.isNavigator = false;
              discount.isPurple = false;
              discount.showIcon = false;
              break;
          }
        }
      }
    }
  }
  GetBillDetails() {
    this.oldAlias = this.selectedBill ? this.selectedBill.billingAccount.alias ||
      this.selectedBill.billingAccount.id : '';
    this.changeAlias(this.oldAlias);
    return this.billingData.loadBillDetails(this.selectedBillId).pipe(map((res: any) => {
      if (res) {
        const details = this.mapBillingDetailsData(res);
        this.selectedBill.details = details;
        return this.selectedBill.details;
      }
      return null;
    }
    ));
  }
  changeAlias(alias) {
    this.aliasChanged.emit(alias);
  }
  public getRemainingDays(dateString: string) {
    let endDate: Date = this.utilsService.parseDate(dateString, '-');
    /** adding 5 days as required */
    endDate.setDate(endDate.getDate() + 5);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    /** adding 1 because this function returns number of days between 2 days without the any day of them */
    const remainingDays = this.utilsService.daydiff(today, endDate) + 1;
    return remainingDays;
  }
  @evict(cachingKeys.GetBills, cachingKeys.GetBillDetails)
  public editAlias(newAlias: string, selectedSiteId?: string) {
    let selectedBillId;
    if (this.selectedBill) {
      selectedBillId = this.selectedBill.billingAccount.id;
    }
    return this.billingData.patchBillConfiguration({ 'alias': newAlias }, pathEBillConfigPath.alias,
      null, selectedSiteId || selectedBillId, selectedSiteId || selectedBillId).pipe(
        map(res => {
          this.changeAlias(newAlias);
          if (this.bills) {
            this.bills.map(bill => {
              if (bill.billingAccount.id === this.selectedBill.billingAccount.id) {
                bill.billingAccount.alias = newAlias;
              }
            });

          }
          return res;
        }));
  }
  GetBillDetailsServiceUsages(selectedBillId: string, serviceId: string): Observable<BillDetailsServiceUsage[]> {
    return this.billingData
      .loadBillDetailsServiceUsages(selectedBillId, serviceId)
      .pipe(map((res: ServiceUsagesResponseModel) => res.items));
  }
  public getServiceById(serviceId) {
    return this.selectedBill.subscriptions.find(service => service.id === serviceId);
  }
  mapCreditNotesData(item: any): CreditNote {
    const creditNote = new CreditNote();
    creditNote.id = JsonQuery.value(item, constants.JSON_PATHS.Billing.billId) || null;
    creditNote.status = JsonQuery.value(item, constants.JSON_PATHS.Billing.billStatus) || '';
    const links = JsonQuery.value(item, constants.JSON_PATHS.Billing.links) || null;
    if (links) {
      creditNote.docHref = JsonQuery.value(links, constants.JSON_PATHS.Billing.billDocumentLink) || null;
    }
    const billOverview = JsonQuery.value(item, constants.JSON_PATHS.Billing.billOverview) || null;
    if (billOverview) {

      const amount = JsonQuery.value(billOverview, constants.JSON_PATHS.Billing.billAmount) || null;
      if (amount) {

        creditNote.amount = this.utilsService.
          converamountToPositive(JsonQuery.value(amount, constants.JSON_PATHS.Billing.grossAmountDue)) || 0;
      }
    }
    creditNote.creationDate =
    this.utilsService.getDateFormatted(JsonQuery.value(item, constants.JSON_PATHS.Billing.creationDate)) || null;
    creditNote.resolutionDate =
    this.utilsService.getDateFormatted(JsonQuery.value(item, constants.JSON_PATHS.Billing.resolutionDate)) || null;
    if (creditNote.resolutionDate) {
      creditNote.resolutionDate = this.utilsService.dateWithMonthNameString(creditNote.resolutionDate )
    }
    return creditNote;
  }
  GetCreditNotes() {
    this.creditNotes = new Array<CreditNote>();
    return this.billingData.GetBills(billQueryString.creditNotes).pipe(map((res) => {
      const items = JsonQuery.value(res, constants.JSON_PATHS.Billing.items);
      if (items.length > 0) {
        items.map((item) => {
          this.creditNotes.push(this.mapCreditNotesData(item));
        });
      }

      return res;
    }
    ));
  }
  GetDeviceInvoices() {
    this.deviceInvoices = new Array<CreditNote>();
    return this.billingData.GetBills(billQueryString.deviceInvoice).pipe(map((res) => {
      const items = JsonQuery.value(res, constants.JSON_PATHS.Billing.items);
      if (items && items.length > 0) {
        items.map((item) => {
          this.deviceInvoices.push(this.mapCreditNotesData(item));
        });
      }
      return res;
    }
    ));
  }
  public getNotification(category: BillDetailsCategory) {
    const isContract = category &&
      (category.categoryType.toLowerCase() === BillDetailsCategoryType.plan.toLowerCase()
        || category.categoryType.toLowerCase() === BillDetailsCategoryType.basic.toLowerCase());
    const hideDateText = isContract && +category.amount < 0;
    this.showSpinner = false;
    this.fullModalAlarms.totalPrice = '';
    this.fullModalAlarms.totalTitle = '';
    this.fullModalAlarms.bodyContent = '';
    this.fullModalAlarms.bodyTitle = '';
    this.fullModalAlarms.alarmClass = '';
    this.fullModalAlarms.primaryButtonText = '';
    this.fullModalAlarms.adjustStyleForSingleCharge = false;
    this.translate.get('billing.billDetails.fieldsList.tillText.body').subscribe(data => {
      this.toDate = data
    });

    // show full app loader before open overlay
    this.appService.showFullAppLoader = true;
    this.showSpinner = true;
    if (category.notificationId !== AlertId.ALRT_1) {
      this.getBillNotification(category.notificationId).subscribe(res => {
        this.appService.showFullAppLoader = false;
        this.showError = false;
        this.showSpinner = false;
        let total = 0;
        const services = new Array<any>();
        res.alert.forEach(element => {
          services.push(
            {
              title: element.name,
              lineOne: hideDateText ? '' :
                this.utilsService.getDateFormatted(element.startDate) +
                ' ' + this.toDate + ' ' +
                this.utilsService.getDateFormatted(element.endDate),
              rightText: this.utilsService.converamountToPositive(element.price),
              class: ''
            }
          )
          total += Number(element.price);
        });
        this.translate.get('billing.billDetails.fieldsList.total.body').subscribe(data => {
          this.fullModalAlarms.totalTitle = data;
        })
        this.fullModalAlarms.totalPrice = this.utilsService.converamountToPositive(total);
        this.fullModalAlarms.bodyContent = res.description;
        // alarm tagging put here
        if (res.alertType) {
          if (res.alertType.toLowerCase() === BillAlarmStatus.newProductPayInAdvance.toLowerCase()) {
            this.fullModalAlarms.show();
            this.translate.get('billing.billAlarmOverlay.fieldsList.payInAdvance.body').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data;
            })
            this.fullModalAlarms.items = services;
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.nuevoProductoYPagoPorAdelantado,
              this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.newProduct.toLowerCase()) {
            this.fullModalAlarms.show();
            this.fullModalAlarms.alarmClass = billClass.oneServiceClass;
            this.translate.get('billing.billAlarmOverlay.fieldsList.payNormal.body').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data;
            })
            this.fullModalAlarms.items = services;
            this.fullModalAlarms.totalPrice = '';
            this.fullModalAlarms.totalTitle = '';
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.nuevoProducto, this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.changeTariff.toLowerCase()) {
            this.fullModalAlarms.show();
            let plan1
            let plan2;
            this.translate.get('billing.billAlarmOverlay.fieldsList').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data.changeTariffNormal.body;
              plan1 = data.oldPlanTilte.body;
              plan2 = data.newPlan.body;
            })
            services.splice(0, 0, { title: plan1, class: billClass.titleOnly });
            services.splice(2, 0, { title: plan2, class: billClass.titleOnly });
            this.fullModalAlarms.items = services;
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.cambioDeTarifa, this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.changeTariffPayInAdvance.toLowerCase()) {
            this.fullModalAlarms.show();
            let tariffText1;
            let tariffText2;
            let tariffText3;
            let tariffText4;
            this.translate.get('billing.billAlarmOverlay.fieldsList').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data.changeTariffAdvance.body;
              tariffText1 = data.oldPlanTilte.body;
              tariffText2 = data.planReturn.body;
              tariffText3 = data.newPlan.body;
              tariffText4 = data.advancePayment.body;
            })
            services.splice(0, 0, { title: tariffText2, class: billClass.titleOnly });
            services.splice(2, 0, { title: tariffText3, class: billClass.titleOnly });
            services.splice(4, 0, { title: tariffText4, class: billClass.titleOnly });
            this.fullModalAlarms.items = services;
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.cambioDeTarifaYPagoPorAdelantado,
              this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.singleCharge.toLowerCase()) {
            this.fullModalAlarms.show();
            this.fullModalAlarms.alarmClass = billClass.oneServiceClass;
            this.fullModalAlarms.adjustStyleForSingleCharge = true;
            this.translate.get('billing.billAlarmOverlay.fieldsList').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data.discountsTitle.body;
            })
            const discountService = new Array<any>();
            res.alert.forEach(element => {
              discountService.push(
                {
                  title: element.name,
                  rightText: this.utilsService.converamountToPositive(element.price),
                  class: ''
                }
              )
            });
            this.fullModalAlarms.items = discountService;
            this.fullModalAlarms.totalPrice = '';
            this.fullModalAlarms.totalTitle = '';
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.descuento, this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.reconnection.toLowerCase()) {
            this.fullModalAlarms.show();
            this.fullModalAlarms.alarmClass = billClass.oneServiceClass;
            this.translate.get('billing.billAlarmOverlay.fieldsList').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data.connAfterSuspension.body;
            })
            const reconnectionService = new Array<any>();
            res.alert.forEach(element => {
              reconnectionService.push(
                {
                  title: element.name,
                  rightText: this.utilsService.converamountToPositive(element.price),
                  class: ''
                }
              )
            });
            this.fullModalAlarms.items = reconnectionService;
            this.fullModalAlarms.totalPrice = '';
            this.fullModalAlarms.totalTitle = '';
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.reconexionTrasCancelacion,
              this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.worryFree.toLowerCase()) {
            this.fullModalAlarms.show();
            let serviceTitle;
            let dateText;
            this.translate.get('billing.billAlarmOverlay.fieldsList').subscribe(data => {
              this.fullModalAlarms.bodyTitle = data.worryFreeTitle.body;
              serviceTitle = data.worryFreeServices.body;
              dateText = data.consupmtionDate.body;
            })
            const worryFreeservices = new Array<any>();
            let totalWorryFree = 0;
            res.alert.forEach(element => {
              worryFreeservices.push(
                {
                  title: serviceTitle,
                  lineOne: element.name,
                  lineTwo: hideDateText ? '' :
                    dateText + ' ' + this.utilsService.formatDateToDayAndMonth(element.startDate),
                  rightText: this.utilsService.converamountToPositive(element.price),
                  class: ''
                }
              )
              totalWorryFree += Number(element.price);
            });
            this.fullModalAlarms.items = worryFreeservices;
            this.fullModalAlarms.totalPrice = this.utilsService.converamountToPositive(totalWorryFree);
            if (res.alert.length === 1) {
              this.fullModalAlarms.totalPrice = null;
              this.fullModalAlarms.totalTitle = null;
            }
            this.translate.get('billing.billAlarmOverlay.buttonsList.manageMegas.text').subscribe(data => {
              this.fullModalAlarms.primaryButtonText = data;
            })
            this.fullModalAlarms.primaryButtonClick = () => {
              this.appService.showFullAppLoader = true;
              this.router.navigate([config.ProductsAndServices.manageWorryFree.route]);
            }
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.megas, this.fullModalAlarms.bodyTitle);
          }
          else if (res.alertType.toLowerCase() === BillAlarmStatus.creditNote.toLowerCase()) {
            this.storage.isCreditNote = true;
            this.router.navigate([config.billing.creditNotes.route]);

          }
          // when alarm type is extraConsumption, navigate to billingDetailsServiceUsages route
          else if (res.alertType.toLowerCase() === BillAlarmStatus.extraConsumption.toLowerCase()) {
            this.router.navigate([config.billing.billingDetailsServiceUsages.route]);
            this.sendBillAlarmViewState(tagging.staticVariables.billDetailsAlarmPage_name.consumoExtra, this.fullModalAlarms.bodyTitle);
          }
        }
      }
        ,
        (error) => {
          this.appService.showFullAppLoader = false;
          this.showSpinner = false;
          this.cross.showNudge = true;
          this.cross.showRetryButton = false;
          this.cross.showOneButton = true;
          this.translate.get('billing.errorList.500.6003').subscribe(data => {
            this.cross.buttonText = data.confirmButton.text;
            this.cross.nudgeTitle = data.title;
            this.cross.nudgeDescription = data.description;
          })
          this.translate.get('billing.billDetails.fieldsList.billLoadingOverlay.body').subscribe(data => {
            this.tagging.setErrorVariables(error, data);
          })
        }
      )
    }
    // if alert id is ALRT_1 navigate to billingDetailsServiceUsages route
    else {
      this.showSpinner = false;
      this.appService.showFullAppLoader = false;
      this.router.navigate([config.billing.billingDetailsServiceUsages.route], { queryParams: { 'serviceId': category.serviceNumber } });
    }
  }
  sendBillAlarmViewState(pageName, bodyTitle) {
    this.storage.billingVariables.billing_alert = bodyTitle;
    tagging.billDetailsAlarms.page_name = tagging.staticVariables.billDetailsAlarmsreplacedVar.pageName.replace('{0}'
      , pageName);
    tagging.billDetailsAlarms.page_subcategory_level_4 = tagging.staticVariables.billDetailsAlarmsreplacedVar.LevelAndPageScreen
      .replace('{0}'
        , pageName);
    tagging.billDetailsAlarms.page_screen = tagging.staticVariables.billDetailsAlarmsreplacedVar.LevelAndPageScreen.replace('{0}'
      , pageName);
    this.tagging.view(tagging.billDetailsAlarms, this.storage.billingVariables);
  }
  public sendTheEmail(email: string, msisdn: string, description: string) {
    const reqBody = {
      contact: {
        msisdn: msisdn,
        email: email
      },
      description: description,
      bills: [
        {
          id: this.selectedBill.id,
          details: []
        }
      ]
    }
    for (const item of this.billShokCheckedItemList) {
      const detail = {
        subscriptionId: item.serviceNumber,
        name: item.name,
        amount: item.amount
      }
      reqBody.bills[0].details.push(detail);
    }

    return this.billingData.sendEmail(reqBody).pipe(map(res => res));
  }
  getBillConfiguration() {
    return this.billingData.getBillingAccountById(this.selectedSiteId
      , this.selectedSiteId, billingAccountQueryParamTypes.billConfig).pipe(map((res) => {
        const billConfiguration = res as BillConfiguration;
        this.billConfiguration = billConfiguration;
        return billConfiguration;
      }
      ));
  }
  patchBillConfiguration(value: any, path: string, operation: string, siteId: string, billingAccountId: string) {
    return this.billingData.patchBillConfiguration(value, path, operation, siteId, billingAccountId).pipe(map((res) => { return res }))
  }
  getBillPdfLanguage(language: string): string {
    this.loadWcs();
    let pddfLanguage: string;
    switch (language.toLowerCase()) {
      case LanguageEnum.Castellano.toLowerCase():
        pddfLanguage = this.Castellano;
        break;
      case LanguageEnum.Catalan.toLowerCase():
        pddfLanguage = this.Catalan;
        break;
      case LanguageEnum.Euskera.toLowerCase():
        pddfLanguage = this.Euskera;
        break;
      case LanguageEnum.Gallego.toLowerCase():
        pddfLanguage = this.Gallego;
        break;
      case LanguageEnum.Ingles.toLowerCase():
        pddfLanguage = this.Ingles;
        break;
      default:
        break;
    }
    return pddfLanguage;
  }
  loadWcs(): void {
    this.translate.get('billing.billLanguage.fieldsList.languageList.languageList').subscribe(data => {
      this.Castellano = data.castellano.castellano
      this.Catalan = data.catalan.catalan
      this.Euskera = data.euskera.euskera
      this.Gallego = data.gallego.gallego
      this.Ingles = data.ingles.ingles

    });
  }

  getCurrentConsumption(siteId: string, serviceId: string): Observable<CurrentConsumptionData[]> {
    return this.billingData.getCurrentConsumption(siteId, serviceId).pipe(map(res => {
      const array: CurrentConsumptionData[] = [];
      if (JsonQuery.value(res, constants.JSON_PATHS.Billing.currentConsumption.items)) {
        const items = JsonQuery.value(res, constants.JSON_PATHS.Billing.currentConsumption.items);
        items.map(item => {
          array.push(this.mapCurrentConsumption(item));
        })
      }
      return array;
    }));
  }

  mapCurrentConsumption(item) {
    const mappedItem: CurrentConsumptionData = new CurrentConsumptionData();
    mappedItem.amount = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.amount) || 0;
    mappedItem.description = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.description) || null;
    mappedItem.type = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.type) || null;
    mappedItem.destination = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.destination) || null;
    mappedItem.unit = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.unit) || null;
    mappedItem.quantity = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.quantity) || 0;
    mappedItem.code = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.code) || null;


    mappedItem.timePeriod = new TimePeriod();
    const timePeriod = JsonQuery.value(item, constants.JSON_PATHS.Billing.currentConsumption.timePeriod) || null;
    if (timePeriod) {
      mappedItem.timePeriod.duration = JsonQuery.value(timePeriod, constants.JSON_PATHS.Billing.currentConsumption.duration) !== undefined ?
        JsonQuery.value(timePeriod, constants.JSON_PATHS.Billing.currentConsumption.duration) : null;
      mappedItem.timePeriod.startDate = JsonQuery.value(timePeriod, constants.JSON_PATHS.Billing.currentConsumption.startDate) || null;
      mappedItem.timePeriod.endDate = JsonQuery.value(timePeriod, constants.JSON_PATHS.Billing.currentConsumption.endDate) || null;
    }

    return mappedItem;
  }
  // bill Payment
  // @evict(cachingKeys.GetBills, cachingKeys.GetBillDetails)
  public updateStatus(paramsObj, serviceId?, isAnonymous = false) {
    const service = serviceId ? serviceId : this.subscriptionService.customerData.currentService.id;
    const customerAccountId: string = isAnonymous ? 'ANONYMOUS' :
      (this.selectedSiteId ? this.selectedSiteId : this.subscriptionService.customerData.customerAccountsId);
    if (this.startBillingPayment) {
      paramsObj.journeyId = this.topupService.selectedCreditCard ? JourneyIdEnum.postPaidWithCvv : JourneyIdEnum.postPaid;
    }
    return this.billingData.updatePaymentStatus(paramsObj,
      customerAccountId, service, isAnonymous).pipe(
        map(res => { return res; }));
  }
  // @evict(cachingKeys.GetBills, cachingKeys.GetBillDetails)
  public getBillIframeProcess(requestBody: BillPaymentProcessRequest) {
    return this.billingData.getBillIframeProcess(requestBody,
      this.selectedSiteId, this.subscriptionService.customerData.currentService.id).pipe(
        map(res => { return res; }));
  }
  public requestNetplusIframeUrlForMva10Topup(requestBody: NetPlusIframeRequest, serviceId?, isAnonymous: boolean = false) {
    const service = serviceId ? serviceId : this.subscriptionService.customerData.currentService.id
    const customerAccountId: string = isAnonymous ? 'ANONYMOUS' :
      (this.selectedSiteId ? this.selectedSiteId : this.subscriptionService.customerData.customerAccountsId);
    if (this.startBillingPayment) {
      /** Override journeyId for postpaid user */
      requestBody.journeyId = this.topupService.selectedCreditCard ? JourneyIdEnum.postPaidWithCvv : JourneyIdEnum.postPaid;
      requestBody.platform = constants.platformWeb;
    }
    return this.billingData.requestNetplusIframeUrlForMva10Topup(requestBody,
      customerAccountId, service, isAnonymous).pipe(
        map(res => { return res; }));
  }

  // payment confirmation
  public paymentConfirmation(requestBody: BillPaymentConfirmationRequest) {
    return this.billingData.paymentConfirmation(requestBody, this.subscriptionService.customerData.customerAccountsId).pipe(
      map(res => { return res; }));
  }

  calculateInProgressRemaining(): void {
    this.remainingDays = this.getRemainingDays(this.selectedBill.billingCycle.endDate);
  }
  getFullDateFormmatted(bill: Billing): string {
    let billFromDate: string;
    let billToDate: string;
    this.translate.get('billing.billOverview.fieldsList').subscribe(data => {
      billFromDate = data.from.body;
      billToDate = data.to.body;
    })
    return billFromDate + ' ' + this.utilsService.getDateFormatted(bill.billingCycle.startDate)
      + ' ' + billToDate + ' ' + this.utilsService.getDateFormatted(bill.billingCycle.endDate);
  }

  // MVES-3368 & SWAT-219
  getCallOptionsStatuses(currentServiceid) {
    return this.billingData.getCallOptions(this.subscriptionService.customerData.customerAccountsId,
      currentServiceid)
  }

  updateCallIDstatus(previousStatus, currentStatus, currentServiceid,
    callOptionType: CallOptionsTypes = CallOptionsTypes.callId, isDivertCallOption?: boolean) {

    const option: CallOptionRequest = {
      name: callOptionType, // const from DXL
      status: {
        previous: previousStatus,
        current: currentStatus
      }
    }
    const body: any = {};
    /** SWAT-372 send a request with same its format in GET (inside divertCalls object) */
    if (isDivertCallOption) {
      body.divertCalls = {};
      body.divertCalls[callOptionType] = option;
    } else {
      body[callOptionType] = option;
    }
    return this.billingData.patchCallID(body,
      this.subscriptionService.customerData.customerAccountsId,
      currentServiceid)
  }
  isSiteCancelled(site: CustomerAccount): boolean {
    return (
      (site.status.toLowerCase() === SiteStatus.Cancelled.toLowerCase() && site.type.toLowerCase() !== siteTypes.maestra)
      || (site.status.toLowerCase() === SiteStatus.Cancelado.toLowerCase() && site.type.toLowerCase() !== siteTypes.maestra)
    )
  }
  isSubCuentaNoResponsable(site: CustomerAccount): boolean {
    return (
      (site.type.toLowerCase() === siteTypes.subNoResponsable || site.type.toLowerCase() === siteTypes.subNoResponsable)
    )
  }
  isSiteWithNoPaymentFor6Month(site: CustomerAccount): boolean {
    return (
      site.status.toLowerCase() === SiteStatus.Desconectado_No_Pago_Mas_De_Seis_Meses.toLowerCase()
      || site.status.toLowerCase() === SiteStatus.Disconnected_no_payment_for_more_than_6_months.toLowerCase()
      || site.status.toLowerCase() === SiteStatus.Disconnected_for_more_than_6_months.toLowerCase()
      || site.status.toLowerCase() === SiteStatus.Desconectada_Mas_De_Seis_Meses.toLowerCase()
      || site.status.toLowerCase() === SiteStatus.Desconectado.toLowerCase()
      || site.status.toLowerCase() === SiteStatus.Disconnected.toLowerCase()
    )
  }
  isPrePaidFormattedAddress(site: CustomerAccount): boolean {
    return (
      (site.address && site.address.formattedAddress
        && site.address.formattedAddress.toLowerCase().includes(constants.prepaidFormatedAddress.address.toLowerCase()))
    )
  }

  getMonthFromBillingCycle(bill: Billing): string {
    const startDate = new Date(bill.billingCycle.startDate);
    const endDate = new Date(bill.billingCycle.endDate);
    return endDate.getDate() >= 14 ? this.utilsService.getMonthName((endDate.getMonth() + 1).toString()) :
      this.utilsService.getMonthName((startDate.getMonth() + 1).toString());
  }
  /**
   *this function is used in the error screen after payment fail
   * this return the proper function to restart the payment hourny again
   * according to which  journey we start from the begnaing
   * @returns {Function}
   * @memberof BillingService
   */
  startBillPaymentProcess(): Function {
    if (this.isDeptPayment) {
      return this.startDeptPaymentFlow.bind(this);
    } else if (this.isInAdvancePayment) {
      // to be changed after development of the next entery point
      return this.startInAdvancePaymentFlow.bind(this);
    } else if (this.isCashDeskPayment) {
      /** restart cash desk payment flow */
      return this.startCashDeskPaymentFlow.bind(this);
    }
  }
  /**
   * CIKS-555
   * Open other payment methods tray for debt with transition
   */
  openOtherPaymentMethods(): void {
    if (this.isDeptPayment) {
      this.backwardTransition = false;
      this.forwardTransition = true;
      this.dynamicTrayService.trayData.preventTrayInnerScrolling = false;
      this.dynamicTrayService.trayData.isContentBodyFullHeight = true;
      this.dynamicTrayService.trayData.removeTrayPadding = false;
      this.dynamicTrayService.trayData.isGradientBackground = false;
      this.topupService.topupNavigationSubject.next({
        page: TopupPrepaidStages.payDebtOtherPaymentMethods
      });
    }
  }

  /**
   * Show back button and change tray title.
   * Assign backButtonTransition to back button action to be execute animation when click back button.
   */
  payPartialBtnClicked(data) {
    this.dynamicTrayData.basicTrayData.showBackButton = data.showBackButton;
    this.dynamicTrayData.backButtonAction = this.backButtonTransition.bind(this);
  }
  /**
    * Execute Animation Fadeout , Forward and Backward when dynamic tray backbutton clicked.
    */
  backButtonTransition(): void {
    this.fadeOutTransition = true;
    setTimeout(() => {
      this.dynamicTrayBackBtnClicked.next();
      this.dynamicTrayData.basicTrayData.showBackButton = false;
      this.dynamicTrayService.trayData.basicTrayData.showBackButton = false;
      this.forwardTransition = false;
      this.showPayPartial = false;
      this.fadeOutTransition = false;
      this.backwardTransition = true;
    }, 1000);
  }
  /**
   * Executed when click on pay partial button in alert info
   * Execute Animation Fadeout , Forward and Backward when click payPartial button.
   * Add tagging view for partial payment screen
   * Fire subject to subscribe on it to show back button and change dynamic tray title
   * @param partialPaymentWcsData {partialPaymentWcsData} string - wcs title to send to dynamic tray
   */
  openPayPartial(partialPaymentWcsData) {
    this.fadeOutTransition = true;
    this.backwardTransition = false;
    setTimeout(() => {
      const data = {
        showBackButton: true,
        title: partialPaymentWcsData
      }
      this.payPartialBtnClicked(data);
      this.fadeOutTransition = false;
      this.forwardTransition = true;
      this.showPayPartial = true;
      if (this.isDeptPayment) {
        tagging.deptPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
        this.tagging.view(tagging.deptPaymentStartPartialPageName, tagging.deptPaymentStartPartialJourney);
      } else if (this.isCashDeskPayment) {
        tagging.cashDeskPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
        this.tagging.view(tagging.cashDeskPaymentStartPartialPageName, tagging.cashDeskPaymentStartPartialJourney);
      }
    }, 1000);
  }
  /**
   *the starting point for opening the dynamic tray
   * for the dept payment journey
   * we set prerequisite data for the tray configuration
   * and get subject to start it
   * backWordTranstion is used when we are navigation back to dept Payment
   * tagging for dept payment start screen
   * @memberof BillingService
   */
  startDeptPaymentFlow(backWordTranstion = false) {
    /** isMobileView should be true in case of mobile view */
    const isMobileView = window.innerWidth < Breakpoints.TABLET;
    this.startBillingPayment = true;
    this.topupService.useDynamicTray = true;
    this.translate.get('payment.messagesList.pendingPayment.title').subscribe((data) => {
      this.dynamicTrayData = {
        basicTrayData: {
          title: data,
          isCenterTitle: true,
          isFullScreen: true
        },
        isBoldTitle: !isMobileView,
        /** to make tray title is 18px */
        is18PxTitle: isMobileView,
        /** make full height for mobile view only */
        isContentBodyFullHeight: true
      };
    });
    this.startScreenTitle = this.dynamicTrayData.basicTrayData.title;
    this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
    // to simulate going back to the component
    if (backWordTranstion) {
      this.backwardTransition = true;
      setTimeout(() => {
        this.payPartialBtnClicked({
          showBackButton: this.showPayPartial
        });
      });
    } else {
      this.showPayPartial = false;
    }
    this.topupService.topupNavigationSubject.next({
      page: TopupPrepaidStages.billingDeptPayment,
      isFirstTimeOpen: true
    });
    this.startDeptTagging();
  }
  /**
   *this is the integration function between 3 payment journey
   * the dept , cash desk and in advance payment
   * it set some values netplus need it to open in iframe
   * we pass the screen name we need to enter TopupPrepaidStages.secondStep
   * and fire subject to start the iframe
   * and configure some values for the animation
   * @param {*} amount
   * amount of payment user needs to pay
   * @memberof BillingService
   */
  openNetPlusIframe(amount) {
    this.topupService.curruentSelectedAmountSelectorValue = amount;
    this.topupService.useDynamicTray = true;
    this.startBillingPayment = true;
    this.fadeOutTransition = true;
    setTimeout(() => {
      this.dynamicTrayService.trayData = this.topupService.dynamicTrayData;
      this.topupService.topupNavigationSubject.next({ page: TopupPrepaidStages.secondStep });
      this.trayService.showForwardTransition = true;
      this.trayService.showBackwardTransition = false;
    }, 1500);
  }
  /**
   * the starting point for opening the dynamic tray
   * for the cash desk payment journey
   * we set prerequisite data for the tray configuration
   * and get subject to start it
   * tagging for cash desk payment start screen
   * @memberof BillingService
   */
  startCashDeskPaymentFlow(backWordTranstion = false) {
    /** isMobileView should be true in case of mobile view */
    const isMobileView = window.innerWidth < Breakpoints.TABLET;
    this.startBillingPayment = true;
    this.topupService.useDynamicTray = true;
    this.translate.get('payment.messagesList.cashDeskPayment.title').subscribe((data) => {
      this.dynamicTrayData = {
        basicTrayData: {
          title: data,
          isCenterTitle: true,
          isFullScreen: true
        },
        isBoldTitle: !isMobileView,
        /** to make tray title is 18px */
        is18PxTitle: isMobileView,
        /** make full height for mobile view only */
        isContentBodyFullHeight: true
      };
    });
    this.startScreenTitle = this.dynamicTrayData.basicTrayData.title;
    this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
    // to simulate going back to the component
    if (backWordTranstion) {
      this.backwardTransition = true;
      this.payPartialBtnClicked({ showBackButton: this.showPayPartial });
    } else {
      this.showPayPartial = false;
    }
    this.topupService.topupNavigationSubject.next({
      page: TopupPrepaidStages.cashDeskPayment,
      isFirstTimeOpen: true
    });
    this.startCashDeskTagging();
  }
  /**
   * the starting point for opening the dynamic tray
   * for the in advance payment journey
   * we set prerequisite data for the tray configuration
   * and get subject to start it
   * tagging for in advance payment start screen
   * @memberof BillingService
   */
  startInAdvancePaymentFlow(backWordTranstion = false) {
    /** isMobileView should be true in case of mobile view */
    const isMobileView = window.innerWidth < Breakpoints.TABLET;
    this.startBillingPayment = true;
    this.topupService.useDynamicTray = true;
    this.translate.get('payment.messagesList.paymentInAdvance.title').subscribe((data) => {
      this.dynamicTrayData = {
        basicTrayData: {
          title: data,
          isCenterTitle: true,
          isFullScreen: true
        },
        isBoldTitle: !isMobileView,
        /** to make tray title is 18px */
        is18PxTitle: isMobileView,
        /** make full height for mobile view only */
        isContentBodyFullHeight: true
      };
    });
    this.startScreenTitle = this.dynamicTrayData.basicTrayData.title;
    this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
    // to simulate going back to the component
    if (backWordTranstion) {
      this.backwardTransition = true;
      this.payPartialBtnClicked({ showBackButton: this.showPayPartial });
    } else {
      this.showPayPartial = false;
    }
    this.topupService.topupNavigationSubject.next({
      page: TopupPrepaidStages.inAdvancePayment,
      isFirstTimeOpen: true
    });
    this.startInAdvanceTagging();
  }
  /**
   * reset all flags of user payment type
   */
  resetBillingFalgs(): void {
    this.startBillingPayment = false;
    this.isDeptPayment = false;
    this.isInAdvancePayment = false;
    this.isCashDeskPayment = false;
    this.showPayPartial = false;
    this.cashDeskAmount = null;
    this.deptAmount = null;
  }
  /**
  * Show leaving overlay when click on x in start screen with animation.
  * Send tagging for leaving overlay.
  * Bind x in leaving overlay to clickXButtonInCancelationOverlay function.
  */
  closeDynamicTray(): void {
    this.sendPostpaidPageTagging(tagging.cancelPaymentInStartScreenPageName, tagging.cancelPaymentJourney);
    this.fadeOutTransition = true;
    this.backwardTransition = false;
    setTimeout(() => {
      this.fadeOutTransition = false;
      this.forwardTransition = true;
      this.showLeavingOverlay = true;
      this.dynamicTrayData.isGradientBackground = true;
      this.dynamicTrayData.basicTrayData.showBackButton = false;
      this.dynamicTrayData.closeButtonAction = this.clickXButtonInCancelationOverlay.bind(this);
    }, 1000);
  }
  /**
   * Executed when click on x in leaving overlay.
   * Return to start screen again without animation.
   * Make tagging of start screen again.
   */
  clickXButtonInCancelationOverlay(): void {
    if (this.isDeptPayment) {
      this.startDeptTagging();
    } else if (this.isCashDeskPayment) {
      this.startCashDeskTagging();
    } else if (this.isInAdvancePayment) {
      this.startInAdvanceTagging();
    }
    this.showLeavingOverlay = false;
    this.dynamicTrayData.isGradientBackground = false;
    this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
    this.forwardTransition = false;
    this.resetFormSubject.next();
    if (this.showPayPartial) {
      this.dynamicTrayData.basicTrayData.showBackButton = true;
    }
  }
  /**
   * Tagging fun for leaving overlay and cancel button in leaving overlay.
   */
  sendPostpaidPageTagging(pageName: any, journey: any, has_journey_card_selected: boolean = true): void {
    const paymentPageName: any = Object.assign({}, pageName);
    const paymentJourney: any = Object.assign({}, journey);
    if (this.isDeptPayment) {
      paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
        tagging.staticVariables.paymentDeptPageName);
      paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
        tagging.staticVariables.paymentDeptPageName);
      paymentJourney['journey_payment_type'] =
        this.showPayPartial ? tagging.staticVariables.payPartial : tagging.staticVariables.payTotal;
      paymentJourney.journey_process = tagging.staticVariables.paymentDeptJourneyProcess;
    } else if (this.isCashDeskPayment) {
      paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
        tagging.staticVariables.paymentCashDeskPageName);
      paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
        tagging.staticVariables.paymentCashDeskPageName);
      paymentJourney['journey_payment_type'] =
        this.showPayPartial ? tagging.staticVariables.payPartial : tagging.staticVariables.payTotal;
      paymentJourney.journey_process = tagging.staticVariables.paymentCashDeskJourneyProcess;
    } else if (this.isInAdvancePayment) {
      paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>',
        tagging.staticVariables.paymentInAdvancePageName);
      paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2.replace('<payment_page_name>',
        tagging.staticVariables.paymentInAdvancePageName);
      paymentJourney.journey_process = tagging.staticVariables.paymentInAdvanceJourneyProcess;
    }
    paymentJourney.journey_category = CrossVariables.client_typology;
    if (has_journey_card_selected) {
      paymentJourney.journey_card_selected = this.topupService.selectedCreditCard ?
        tagging.staticVariables.savedCreditCard : tagging.staticVariables.newCreditCard;
    }
    this.tagging.view(paymentPageName, paymentJourney);
  }
  // make tagging for in dept payment start screen.
  startDeptTagging(): void {
    if (this.showPayPartial) {
      tagging.deptPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
      this.tagging.view(tagging.deptPaymentStartPartialPageName, tagging.deptPaymentStartPartialJourney);
    } else {
      tagging.deptPaymentStartJourney.journey_category = CrossVariables.client_typology;
      this.tagging.view(tagging.deptPaymentStartPageName, tagging.deptPaymentStartJourney);
    }
  }
  // make tagging for in advance payment start screen.
  startInAdvanceTagging(): void {
    tagging.payInAdvancePaymentStartJourney.journey_category = CrossVariables.client_typology;
    this.tagging.view(tagging.payInAdvancePaymentStartPageName, tagging.payInAdvancePaymentStartJourney);
  }
  // make tagging for in cash desk payment start screen.
  startCashDeskTagging(): void {
    if (this.showPayPartial) {
      tagging.cashDeskPaymentStartPartialJourney.journey_category = CrossVariables.client_typology;
      this.tagging.view(tagging.cashDeskPaymentStartPartialPageName, tagging.cashDeskPaymentStartPartialJourney);
    } else {
      tagging.cashDeskPaymentStartJourney.journey_category = CrossVariables.client_typology;
      this.tagging.view(tagging.cashDeskPaymentStartPageName, tagging.cashDeskPaymentStartJourney);
    }
  }
  /**
   * Executed when click on continuar button in leaving overlay.
   * Return to start screen again with animation.
   * Make tagging of previous start screen again.
   */
  continuePayment(): void {
    if (this.isDeptPayment) {
      this.startDeptTagging();
    } else if (this.isCashDeskPayment) {
      this.startCashDeskTagging();
    } else if (this.isInAdvancePayment) {
      this.startInAdvanceTagging();
    }
    this.fadeOutTransition = true;
    setTimeout(() => {
      this.fadeOutTransition = false;
      this.forwardTransition = false;
      this.backwardTransition = true;
      this.showLeavingOverlay = false;
      this.dynamicTrayData.isGradientBackground = false;
      this.resetFormSubject.next();
      if (this.showPayPartial) {
        this.dynamicTrayData.basicTrayData.showBackButton = true;
      }
      this.dynamicTrayData.closeButtonAction = this.closeDynamicTray.bind(this);
      this.dynamicTrayService.closeTraySubject.subscribe(() => {
        this.closeDynamicTray.bind(this);
      });
    }, 1000);
  }
  /**
   * Executed when click on cancel button in leaving overlay.
   * Close tray and return to mis pagos page.
   * Make tagging for cancel button.
   */
  cancelPayment(): void {
    this.sendPostpaidPageTagging(tagging.cancelPaymentBtnClickedInStartScreenPageName,
      tagging.cancelPaymentBtnClickedJourney);
    this.topupService.returnFromWalletScreen = false;
    this.dynamicTrayService.closeTraySubject.next();
    setTimeout(() => {
      this.forwardTransition = false;
      this.showLeavingOverlay = false;
      this.dynamicTrayData.isGradientBackground = false;
    }, 1000);
  }
  roundAmount(amount): string {
    return isNaN(parseFloat(amount)) ? '' : parseFloat(amount).toFixed(2).toString().replace('.', ',')
  }
  /**
  * Open dynamic tray of other payment methods in cash desk payment.
  */
  openOtherPaymentMethodsOverlay(): void {
    /** isMobileView should be true in case of mobile view */
    const isMobileView = window.innerWidth < Breakpoints.TABLET;
    this.startBillingPayment = true;
    this.topupService.useDynamicTray = true;
    this.translate.get('payment.itemsList.otherPaymentMethodstray.body').subscribe((data) => {
      this.dynamicTrayData = {
        basicTrayData: {
          title: data,
          isCenterTitle: true,
          isFullScreen: true
        },
        isBoldTitle: !isMobileView,
        /** to make tray title is 18px */
        is18PxTitle: isMobileView,
        isContentBodyFullHeight: true,
        preventTrayInnerScrolling: false
      };
    });
    this.topupService.topupNavigationSubject.next({
      page: TopupPrepaidStages.payDebtOtherPaymentMethods,
      isFirstTimeOpen: true
    });
  }
  /**
   * Set selected site and site id then navigate to bill payment details
   * @param filterdBillingSites filtered sites from resolvers
   */
  checkBillingSitesAndNavigate(filterdBillingSites: CustomerAccount[], preventNavigation?: boolean, extraParams?: {}): void {
    this.billingSites = filterdBillingSites;
    /** Check if a site of logged in service is existing in sites list or not */
    const loggedInSiteExist: CustomerAccount = filterdBillingSites.find(
      site => site.id === (this.selectedSiteId || this.subscriptionService?.customerData?.currentService?.siteId)
    );
    const selectedSite: CustomerAccount = loggedInSiteExist || filterdBillingSites[0];
    this.selectedSiteId = selectedSite.id;
    this.selectedSiteStatus = selectedSite.status;
    if (!preventNavigation) {
      this.router.navigate([config.billing.billPaymentDetails.route],
        { queryParams: { accountStatus: selectedSite.status, ...extraParams } });
    }

  }

  /**
   * Method for getting filtered billing sites
   * @param billingAccounts billing accounts list
   */
  getFilteredBillSites(billingAccounts: Array<CustomerAccount>): Array<CustomerAccount> {
    return billingAccounts.filter((site) => {
      return !(site.status && (
        this.isPrePaidFormattedAddress(site)
        || this.isSiteCancelled(site)
        || this.isSubCuentaNoResponsable(site)
        || this.isSiteWithNoPaymentFor6Month(site)
      )
      )
    })
  }

  /** Method called when selected site changed */
  newSiteSelected(newselectedSite: CustomerAccount): void {
    /** Reset doneAPIsCounter counter to start count again */
    this.doneAPIsCounter = 0;
    this.selectedSiteChanged.next(newselectedSite);
  }
  /** Method for getting selected site */
  getCurrentSite(): CustomerAccount {
    this.selectedSiteId = this.selectedSiteId === null ? this.billingSites[0].id : this.selectedSiteId;
    return this.billingSites.find(site => site.id === this.selectedSiteId);
  }
  /*
   * Open iframe and set payment joureny params object needed to open it
   * @param amount of the money user need to pay
   * @param WCSJournyKey of the current journey
   * @param concepto wcs for concepto value in iframe screen
   */
  openPaymentModule(amount: string, WCSJournyKey: PaymentJourny, concepto: string): void {
    const paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
    // payer data
    paymentJourneyParams.payer.documentId = this.storage.userProfile.document.id;
    paymentJourneyParams.payer.siteId = this.selectedSiteId ? this.selectedSiteId
      : this.subscriptionService.customerData.customerAccountsId;
      paymentJourneyParams.payer.serviceId = this.siteHandlingService.isSiteSuspended() ? ANONYMOUS :
      this.subscriptionService.customerData.currentService.id;
    paymentJourneyParams.payer.documentType = this.storage.userProfile.document.type
    // buyer data
    paymentJourneyParams.buyer.documentId = this.storage.userProfile.document.id;
    paymentJourneyParams.buyer.siteId = this.selectedSiteId ? this.selectedSiteId
      : this.subscriptionService.customerData.customerAccountsId;
    paymentJourneyParams.buyer.serviceId = this.siteHandlingService.isSiteSuspended() ? ANONYMOUS :
      this.subscriptionService.customerData.currentService.id;

    paymentJourneyParams.amount = amount;
    paymentJourneyParams.WCSJournyKey = WCSJournyKey;
    paymentJourneyParams.isAnonymous = this.siteHandlingService.isSiteSuspended() ? true : false;
    // params related to iframe screen
    paymentJourneyParams.concepto = concepto;
    paymentJourneyParams.journeyId = PaymentJourneyIds.postPaid;
    // identify total or partial payment
    paymentJourneyParams.paymentType = this.paymentType;
    // crediet card data
    const selectedCard: PaymentCardModel = this.paymentWalletService.selectedCard;
    if (selectedCard) {
      paymentJourneyParams.token = selectedCard.token;
      paymentJourneyParams.expiryDate = selectedCard.expiryDate;
      paymentJourneyParams.cardMask = selectedCard.cardMask;
      paymentJourneyParams.journeyId = PaymentJourneyIds.postPaidWithCvv;
    }
    // for handling journey

    paymentJourneyParams.isHandleWaiting = false;
    paymentJourneyParams.language = PaymentLanguages.es;
    paymentJourneyParams.paymentTaggingConfig = this.getPaymentModuleTagging(WCSJournyKey, amount);
    paymentJourneyParams.placeHolders.okScreeen.subtitle = this.getSuccessScreenPlaceHolders(amount, WCSJournyKey);
    this.paymentNavigationService.WCSJourneyKey = WCSJournyKey;
    this.paymentNavigationService.openPaymentThirdParty(paymentJourneyParams);
  }
  /**
   *
   * @param amount of the money user need to pay
   * @param WCSJournyKey of the current journey
   */
  getSuccessScreenPlaceHolders(amount: string, WCSJournyKey: PaymentJourny): string[] {
    const placeHolders: any[] = [];
    switch (WCSJournyKey) {
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.anonymousDebtPartial:
        placeHolders.push(this.roundAmount(this.deptAmount - +amount));
        break;
      case PaymentJourny.postcashDeskPartial:
        placeHolders.push(this.roundAmount(amount));
        break;
      case PaymentJourny.postInadvance:
      case PaymentJourny.anonymousPayInAdvance:
        placeHolders.push(this.roundAmount(amount + defines.euro));
        break;
    }
    return placeHolders;
  }
  /**
  * Return tagging object (pagename, journey) for postpaid tagging
  * @param WCSJournyKey of the current journey
  * @param amount {string} amount to pay
  */
 getPaymentModuleTagging(WCSJournyKey: PaymentJourny, amount: string): PaymentTaggingObject {
  const paymentTaggingObject: PaymentTaggingObject = new PaymentTaggingObject();
  paymentTaggingObject.pageName =
  this.getPageNameForPostPaidTagging(tagging.payWithCreditCardPageName, WCSJournyKey);
  paymentTaggingObject.pageName_ok =
  this.getPageNameForPostPaidTagging(tagging.paymentDonePageName, WCSJournyKey);
  paymentTaggingObject.pageName_ko =
  this.getPageNameForPostPaidTagging(tagging.paymentKOPageName, WCSJournyKey);
  paymentTaggingObject.journey = this.getJourneyForPostPaidTagging(WCSJournyKey, amount);
  return paymentTaggingObject;
}
/**
  * Return tagging object (pagename, journey) for financed devices tagging
  * @param WCSJournyKey of the current journey
  * @param amount {string} amount to pay
  */
 getPaymentFinancedDevicesJourneyTagging(WCSJournyKey: PaymentJourny, amount: string): PaymentTaggingObject {
  const paymentTaggingObject: PaymentTaggingObject = new PaymentTaggingObject();
  paymentTaggingObject.pageName = tagging.payWithCreditCardFinancedDevicesPageName;
  paymentTaggingObject.pageName_ok = tagging.paymentDoneFinancedDevicesPageName;
  paymentTaggingObject.pageName_ko = tagging.paymentFinancedDevicesKOPageName;
  paymentTaggingObject.journey = this.getJourneyForPostPaidTagging(WCSJournyKey, amount);
  return paymentTaggingObject;
}

/**
  * Return tagging object (pagename, journey) for financed devices tagging wioth new archetype
  * @param WCSJournyKey of the current journey
  * @param amount {string} amount to pay
  */
getPaymentFinancedDevicesJourneyTaggingArchetype(WCSJournyKey: PaymentJourny, amount: string): PaymentTaggingObjectArchetype {
  const paymentTaggingObject: PaymentTaggingObjectArchetype = new PaymentTaggingObjectArchetype();
  paymentTaggingObject.pageName.state_id = PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD;
  paymentTaggingObject.folder = FOLDER_FINANCED_DEVICES;
  paymentTaggingObject.pageName.custom_map = {
    product_brand: this.selectedFundedDevice?.brand,
    product_model: this.selectedFundedDevice?.model,
    journey_payment_type: PaymentJourneyPaymentType.total,
    journey_payment_method: tagging.staticVariables.paymentMethod

  }
  paymentTaggingObject.pageName_ok.state_id = PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_OK;
  paymentTaggingObject.pageName_ok.custom_map = {
    product_brand: this.selectedFundedDevice?.brand,
    product_model: this.selectedFundedDevice?.model,
    journey_payment_type: PaymentJourneyPaymentType.total,
    journey_payment_method: tagging.staticVariables.paymentMethod
  }
  paymentTaggingObject.pageName_ko.state_id = PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO;
  paymentTaggingObject.pageName_ko.custom_map = {
    product_brand: this.selectedFundedDevice?.brand,
    product_model: this.selectedFundedDevice?.model,
    journey_payment_type: PaymentJourneyPaymentType.total,
    journey_payment_method: tagging.staticVariables.paymentMethod
  }
  return paymentTaggingObject;
  }

  /**
  * Return pagename object for postpaid tagging
  * @param pageNameObject the pagename object
  */
  getPageNameForPostPaidTagging(pageNameObject: PaymentTaggingPageName, WCSJournyKey: PaymentJourny): PaymentTaggingPageName {
    const pageName: PaymentTaggingPageName = Object.assign({}, pageNameObject);
    switch (WCSJournyKey) {
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.anonymousDebtPartial:
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.anonymousDebtTotal:
      case PaymentJourny.suspendedDeptTotal:
        pageName.page_name = pageName.page_name.replace('<payment_page_name>', PaymentJourneyUserType.dept);
        pageName.page_subcategory_level_2 = pageName.page_subcategory_level_2.replace('<payment_page_name>',
          PaymentJourneyUserType.dept);
        break;
      case PaymentJourny.postcashDeskPartial:
      case PaymentJourny.postcashDeskTotal:
        pageName.page_name = pageName.page_name.replace('<payment_page_name>', PaymentJourneyUserType.cashDesk);
        pageName.page_subcategory_level_2 = pageName.page_subcategory_level_2.replace('<payment_page_name>',
          PaymentJourneyUserType.cashDesk);
        break;
      case PaymentJourny.postInadvance:
      case PaymentJourny.anonymousPayInAdvance:
        pageName.page_name = pageName.page_name.replace('<payment_page_name>', PaymentJourneyUserType.inadvance);
        pageName.page_subcategory_level_2 = pageName.page_subcategory_level_2.replace('<payment_page_name>',
          PaymentJourneyUserType.inadvance);
        break;
    }

    return pageName;
  }
  /**
   * Return journey object for postpaid tagging
   * @param WCSJournyKey of the current journey
   * @param amount {string} amount to pay
   */
  getJourneyForPostPaidTagging(WCSJournyKey: PaymentJourny, amount: string): PaymentTaggingJourney {
    let amountToPay: number = +this.roundAmount(amount).replace(',', '.') * 100;
    amountToPay = Math.round(amountToPay);
    const journey: PaymentTaggingJourney = new PaymentTaggingJourney();
    journey.journey_name = PaymentJourneyName.postpaid;
    journey.journey_category = CrossVariables.client_typology;
    journey.journey_subcategory = PaymentJourneySubCategory.postpaid;
    journey.journey_type = PaymentJourneyType.servicio;
    journey.event_success = PaymentJourneyEvents.ok.replace('<amount>', amountToPay.toString());
    journey.event_error = PaymentJourneyEvents.koEvent3;
    switch (WCSJournyKey) {
      case PaymentJourny.postDeptPartial:
      case PaymentJourny.anonymousDebtPartial:
        journey.journey_payment_type = PaymentJourneyPaymentType.partial;
        journey.journey_process = PaymentJourneyProcess.dept;
        break;
      case PaymentJourny.postDeptTotal:
      case PaymentJourny.anonymousDebtTotal:
        journey.journey_payment_type = PaymentJourneyPaymentType.total;
        journey.journey_process = PaymentJourneyProcess.dept;
        break;
      case PaymentJourny.postcashDeskPartial:
        journey.journey_payment_type = PaymentJourneyPaymentType.partial;
        journey.journey_process = PaymentJourneyProcess.cashdesk;
        break;
      case PaymentJourny.postcashDeskTotal:
        journey.journey_payment_type = PaymentJourneyPaymentType.total;
        journey.journey_process = PaymentJourneyProcess.cashdesk;
        break;
      case PaymentJourny.postInadvance:
      case PaymentJourny.anonymousPayInAdvance:
        journey.journey_process = PaymentJourneyProcess.inadvance;
        break;
      case PaymentJourny.financedDevicesCancellation:
        journey.journey_name = PaymentJourneyName.financedDevices;
        journey.journey_payment_type = PaymentJourneyPaymentType.total;
        journey['journey_payment_method'] = tagging.staticVariables.paymentMethod;
        break;
    }
    return journey;
  }

  /**
   * Send track actions for postpaid journey.
   * @param pageName {PaymentTaggingPageName} pageName object for each track action
   * @param paymentJourneyUserType {PaymentJourneyUserType} user type dept or cash desk or inadvance
   * @param trackActionsObject {TrackActionsObject} object that contains event_name and data for action
   */
  sendPostPaidTaggingTrackActions(pageName: PaymentTaggingPageName, paymentJourneyUserType: PaymentJourneyUserType,
    trackActionsObject: TrackActionsObject): void {
    const paymentPageName: PaymentTaggingPageName = Object.assign({}, pageName);
    paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>', paymentJourneyUserType);
    paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2
      .replace('<payment_page_name>', paymentJourneyUserType);
    const eventName: string = trackActionsObject.eventName.replace('<payment_page_name>', paymentJourneyUserType);
    this.tagging.track(eventName, trackActionsObject.data, paymentPageName);
  }
  /**
   * Send view for delete cc screen for postapid journey
   * @param pageName {PaymentTaggingPageName} pageName object for each track action
   * @param paymentJourneyProcess {PaymentJourneyProcess} journey process value
   * @param paymentJourneyDetail {PaymentJourneyDetail} journey detail value
   * @param paymentJourneyUserType {PaymentJourneyUserType} user type dept or cash desk or inadvance
   */
  sendViewForDeleteCCScreenPostpaid(pageName: PaymentTaggingPageName, paymentJourneyProcess: PaymentJourneyProcess,
    paymentJourneyDetail: PaymentJourneyDetail, paymentJourneyUserType: PaymentJourneyUserType): void {
    const paymentPageName: PaymentTaggingPageName = Object.assign({}, pageName);
    paymentPageName.page_name = paymentPageName.page_name.replace('<payment_page_name>', paymentJourneyUserType);
    paymentPageName.page_subcategory_level_2 = paymentPageName.page_subcategory_level_2
      .replace('<payment_page_name>', paymentJourneyUserType);
    const paymentJourney: PaymentTaggingJourney = new PaymentTaggingJourney();
    paymentJourney.journey_name = PaymentJourneyName.postpaid;
    paymentJourney.journey_category = CrossVariables.client_typology;
    paymentJourney.journey_subcategory = PaymentJourneySubCategory.postpaid;
    paymentJourney.journey_type = PaymentJourneyType.servicio;
    paymentJourney.journey_process = paymentJourneyProcess;
    if (this.isDeptPayment) {
      paymentJourney.journey_payment_type =
        this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postDeptPartial ?
          PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
    } else if (this.isCashDeskPayment) {
      paymentJourney.journey_payment_type =
        this.paymentNavigationService.WCSJourneyKey === PaymentJourny.postcashDeskPartial ?
          PaymentJourneyPaymentType.partial : PaymentJourneyPaymentType.total;
    }
    paymentJourney.journey_detail = paymentJourneyDetail;
    paymentJourney.journey_step = paymentJourney.journey_name + ':' + paymentJourney.journey_detail;
    paymentJourney.journey_environment = this.paymentNavigationService.paymentJourneyParams.isAnonymous ?
                        PaymentJourneyEnvironment.anonymous : PaymentJourneyEnvironment.private;
    this.tagging.view(paymentPageName, paymentJourney);
  }

  /*
     * Open iframe and set payment joureny params object needed to open it
     * @param amount of the money user need to pay
     * @param WCSJournyKey of the current journey
     * @param concepto wcs for concepto value in iframe screen
     */
  openPaymentModulePostPaidAnonymous(amount: string, WCSJournyKey: PaymentJourny, concepto: string,
    payer: PaymentCustomer, buyer: PaymentCustomer): void {
    const paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
    paymentJourneyParams.payer = payer;
    paymentJourneyParams.buyer = buyer;

    paymentJourneyParams.amount = amount;
    paymentJourneyParams.WCSJournyKey = WCSJournyKey;
    paymentJourneyParams.isAnonymous = true;
    // params related to iframe screen
    paymentJourneyParams.concepto = concepto;
    paymentJourneyParams.journeyId = PaymentJourneyIds.postPaid;
     // identify total or partial payment
     paymentJourneyParams.paymentType = this.paymentType;
    // for handling journey
    paymentJourneyParams.isHandleWaiting = false;
    paymentJourneyParams.language = PaymentLanguages.es;
    paymentJourneyParams.paymentTaggingConfig = this.getPaymentModuleTagging(WCSJournyKey, amount);
    paymentJourneyParams.placeHolders.okScreeen.subtitle = this.getSuccessScreenPlaceHolders(amount, WCSJournyKey);
    this.paymentNavigationService.WCSJourneyKey = WCSJournyKey;
    this.paymentNavigationService.openPaymentThirdParty(paymentJourneyParams);
  }

  getBillReviewTicketNumber(customerComment: string): Observable<{id: string, href: string}> {
    const reqBody: BillReviewTicketRequestBody = new BillReviewTicketRequestBody();
    reqBody.note = [{id: billReviewTicketCreation.NoteID,
      text:
      `${billReviewTicketCreation.Factura}${this.selectedBill.billNo}\n${billReviewTicketCreation.clientComment} ${customerComment}`}];
    reqBody.relatedParty = [{id: this.selectedBillReviewAccID}];
    return this.billingData.getBillTicketNumber(reqBody);
  }
  getPaymentCommitments(): Observable<PaymentCommitmentResponse> {
    return this.billingData.getPaymentCommitments(this.selectedSiteId);
  }
  getEnergyDebt(energySiteId: string): Observable<EnergyDebtResponse> {
    return this.billingData.getEnergyDebt(energySiteId);
  }

  setNIFandCIF(): NifAndCif {
   const nifAndCif: NifAndCif = {};
    if (this.storage.userProfile.selectedCif) {
      nifAndCif.docId = this.storage.userProfile.selectedCif;
      nifAndCif.isCIF = true;
    } else {
      nifAndCif.docId = this.storage.userProfile.document.id;
      nifAndCif.isCIF = false
    }
    return nifAndCif;
  }

  getBillStatus(billAccId: string): Observable<Billing[]> {
    let nifAndCifData: NifAndCif;
    nifAndCifData = this.setNIFandCIF();
    return this.billingData.getStatusbyID(billAccId, nifAndCifData).pipe(map((res: BillStatusModel[]) => {
      this.fillBillsWithStatus(res);
      return this.bills
    }))
  }

  fillBillsWithStatus(data: BillStatusModel[]): void {
    this.bills.forEach((bill) => {
      const billStatusId: BillStatusModel = data.find(el => el.id.value === bill.billNo);
      bill.billStatus = billStatusId?.status;
      bill.billNo = billStatusId?.id?.value ?? bill.billNo;
      bill.paymentMethod = billStatusId?.payment?.paymentItem.map(el => el.paymentMethod.toLowerCase());
      bill.dueDateTime = this.utilsService.getDateFromString(billStatusId?.details?.dueDateTime?.value);
      bill.isDateExpired = this.utilsService.checkDateHasExpired(
        this.utilsService.getDateFromString(billStatusId?.details?.dueDateTime?.value)
      );
    })
  }
}

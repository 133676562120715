import { CommercialPrice } from './commercial-price.model';
import { CommercialCartItem } from './commercial-cart-item.model';
import { CommercialShippingCosts } from './commercial-shipping-costs.model';
import { ShoppingCartPegaModel } from './shopping-cart-pega.model';
import { ShoppingCartUnsubscribeServices } from './shopping-cart-unsubscribe-services.model';

export class CommercialShoppingCart {
  public deleteItems: boolean;
  public remainingMobileLines: number;
  public remainingInternetLines: number;
  public overMaxLines: boolean;
  public overMaxInternetLines: boolean;
  public taxType: string;
  public taxValue: number;
  public price?: CommercialPrice;
  public cartItems: CommercialCartItem[];
  public siteId: string;
  public shippingCosts?: CommercialShippingCosts;
  public checkoutCriteriaDto: CommercialCheckoutCriteriaDto;
  public ecode: number;
  public pega?: ShoppingCartPegaModel;
  public unsubscribeServices?: Array<ShoppingCartUnsubscribeServices>;
}

export class CommercialCheckoutCriteriaDto {
  public deliveryType: string;
  public postalCode: string;
  public bankAccount: string;
  public paymenttypeid?: number;
}

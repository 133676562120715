import { Component, OnInit, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'sp-lottie-loader',
  templateUrl: './lottie-loader.component.html',
  styleUrls: ['./lottie-loader.component.scss']
})
export class LottieLoaderComponent implements OnInit {
  lottieConfig: AnimationOptions ;
  anim: any;
  @Input() width;
  @Input() height;
  @Input() lottieImageUrl;
  @Input() lottieImageJson;
  @Input() loop = true;
  @Input() loadingText: string;
  @Input() textWidth: number
  constructor() { }

  ngOnInit() {
    this.lottieConfig = {
      animationData: this.lottieImageJson,
      path: this.lottieImageUrl,
      autoplay: true,
      loop: this.loop
    };
  }
  handleAnimation(anim: any) {
    this.anim = anim;
  }


}

export class CommercialFinancingOption {
  public numMeses: number;
  public flagDefaultMonthly?: boolean;
  public payments: CommercialPayment[];
}

export class CommercialPayment {
  public idPrecio: number;
  public pagoAlContadoConImpuesto: number;
  public pagoAlContadoSinImpuesto: number;
  public cuotaMensualConImpuesto: number;
  public cuotaMensualSinImpuesto: number;
  public pagoInicialConImpuesto: number;
  public pagoInicialSinImpuesto: number;
  public crossedOfferOriginal: string;
  public offerType?: string;
  public canonConImpuesto: number;
  public canonSinImpuesto: number;
  public paymentType: string;
  public flagFinalPayment: boolean;
  public flagOptionsRefinancingFp: boolean;
  public flagDefaultPayment?: boolean;
  public flagPaymentOptionFp?: boolean;
  public deviceFinalPaymentTaxes?: number;
  public deviceFinalPayment?: number;
  public optionsRefinancingFp?: CommercialRefinancingOption[];
  public permanenceDuration?: number;
  public permanencePenalty?: number;
  public permanencePenaltyTaxes?: number;
  public pvpTotal: number;
  public pvpTotalTaxes: number;
  public offerOrigin?: string;
}

export class CommercialRefinancingOption {
  public numberMonths: number;
  public monthlyFeeTaxes: number;
  public monthlyFee: number;
}

export class CommercialPermanence {
  public permanenceDuration?: number;
  public permanencePenalty?: number;
  public permanencePenaltyTaxes?: number;
}

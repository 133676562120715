<main class="finanaced-device-wraper">
    <section class="financed-device-pay-details">
        <div class="financed-device-pay-details__text-image">
            <p id="paymentFinancedDevicesSubtitle" class="financed-device-pay-details__subtitle">{{'v10.payment.itemsList.financedDevices.es.totalScreen.subTitle'|translate}}</p>
            <img id="paymentFinancedDevicesImg" class="financed-device-pay-details__wink-image"
                [src]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/Wink@xhdpi.png' | imgFullPath"
                alt="wink" />
        </div>
    <p id="paymentFinancedDevicesAmount"class="financed-device-pay-details__amount">{{amountToPay |currency:'EUR':'symbol' |sliceCurrencyRoundZero}}{{coin}}</p>
    </section>
    <section class="financed-device-pay-details-sec">
        <p id="paymentFinancedDevicesDescription" class="financed-device-pay-details__description">
            {{'v10.payment.itemsList.financedDevices.es.totalScreen.description'|translate}}</p>
        <div class="financed-device-pay-details-sec__newCard-box">
            <p id="paymentFinancedDevicesNewCardTxt">{{'v10.payment.itemsList.financedDevices.es.totalScreen.newCardText'|translate}}</p>
        </div>
    </section>
    <div class="finanaced-device-wraper__submit-btn">
        <mva10-c-button
        id="paymentFinancedDevicesSubmitBtn" 
        (click) ="openPaymentModule()"
        [styleClass]="'mva10-c-button--primary'"
        [body]="'v10.payment.itemsList.financedDevices.es.totalScreen.button'|translate"
        [title]="'v10.payment.itemsList.financedDevices.es.totalScreen.button'|translate">
       </mva10-c-button>
    </div>
    <div *ngIf="isTabletView" class="finanaced-device-wraper__footer">
        <div class="finanaced-device-wraper__footer-images">
            <div class="visa-image">
                <img [src]="'v10.payment.itemsList.financedDevices.es.paymentThirdParty.visa'|translate" alt="visa" />
              </div>
            <div class="mastercard-image">
                <img [src]="'v10.payment.itemsList.financedDevices.es.paymentThirdParty.masterCard'|translate" alt="mastercard" />
              </div>
            </div>
            <div class="finanaced-device-wraper__footer-securedssl">
              <p id="paymentFinancedDevicesSecuredSSL">{{'v10.payment.itemsList.financedDevices.es.paymentThirdParty.securedSSL'|translate}}</p>
            </div>
    </div>
</main>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../../models/product.model';
import { ProductCategory } from '../../enums/productCategory.enum';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import { UtilitiesService } from '../../utils/utilities.service';
import { AppService } from '../../../app.service';

@Component({
  selector: 'sp-enjoy-more-navigator',
  templateUrl: './sp-enjoy-more-navigator.component.html',
  styleUrls: ['./sp-enjoy-more-navigator.component.scss']
})
export class SpEnjoyMoreNavigatorComponent implements OnInit {

  @Input() public title1: string;
  @Input() public title2: string;
  @Input() public iconName: string;
  @Input() public isLightGray: boolean;
  @Input() public isDarkGray: boolean;
  @Input() public active: boolean;
  @Input() public deactivate: boolean;
  @Input() public changeChevronColor: boolean;
  @Input() public promoIcon: boolean;
  @Input() public enjoymoreItem: Product;
  @Output() public navigateByCode: EventEmitter<any> = new EventEmitter<any>();
  touristLastWord: string

  @Input() isPromotedProduct: boolean;
  @Input() expiryDate: string;

  /**url */
  @Input() public jumbotronUrl: string;
  /**showLock */
  @Input() public showLock: boolean;
  constructor(
    private productsAndServicesService: ProductsAndServicesService,
    private utilitiesService: UtilitiesService,
    public appService: AppService
  ) { }

  ngOnInit() {
    if (this.enjoymoreItem) {
      if (this.enjoymoreItem.EnjoyMoreRenewalDate) {
        this.enjoymoreItem.EnjoyMoreRenewalDate = new Date(this.enjoymoreItem.EnjoyMoreRenewalDate).toString();
      }
    }
    if (this.enjoymoreItem && this.isAdhockProduct()) {
      const titleTwoCopy = this.title2;
      this.productsAndServicesService.showTouristLastDayFlag =
        this.utilitiesService.checkTodayEqualityWithDayBeforeEndDate(this.enjoymoreItem.EnjoyMoreRenewalDate);
      this.title2 = ' ' + titleTwoCopy.split(' ')[0];
      this.touristLastWord = titleTwoCopy.split(' ')[2];
    }
  }

  isAdhockProduct() {
    return this.enjoymoreItem && this.enjoymoreItem.ProductCategory.toLowerCase() === ProductCategory.Adhoc.toLowerCase();
  }
  /**
   * get the navigation step if item is locked then it has to upgrade otherwise it will navigate to
   * url
   */
  // navigate(url) {
  //   if (this.showLock) {
  //     // upgrade scenario or another action
  //   } else {
  //     this.router.navigate([this.jumbotronUrl]);
  //   }
  // }
  navigateTo() {
    this.navigateByCode.emit();
  }

}


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CrossFunctionalityService } from './cross-functionality.service';
import { ProductService } from './product.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHadlingUsingNotificationService } from '../../core/services/error-hadling-using-notification.service';
import { ProductsAndServicesService } from '../../products-and-services/products-and-services.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ErrorHandlingService } from '../../core/services/errorHandling.service';
import { AppService } from '../../app.service';
import { ServiceType } from '../enums/serviceType.enum';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { BuyOrRemove } from '../enums/buyOrRemove.enum';
import { VERIFYEMAIL, BIZTALKERROR, PAGES, HttpRequestMethods } from '../constants/defines';
import { tagging } from '../../../config/tagging-config';
import { ProductType } from '../enums/productType.enum';
import { BundleType } from '../enums/bundleType.enum';

/**
 * PurchasingService is created for handling all purchasing process from now
 * PurchasingService is refactoring of purchasing process code.
 */
@Injectable()
export class PurchasingService {
  /**
   *
   * @constructor
   */
  constructor(
    public cross: CrossFunctionalityService,
    private subscriptionData: SubscriptionService,
    private translate: TranslateService,
    private product: ProductService,
    private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
    private productsAndServicesService: ProductsAndServicesService,
    private tagging: TaggingHelperService,
    private errorHandling: ErrorHandlingService,
    public appService: AppService
  ) { }


  /**
   * purchasingPrepaidProduct is used for purcasing only prepaid product
   * @constructor
   * @param {activeBundle} boolen - is bundle status active or not
   * @param {inActiveBundle} boolen - bundle status inActive or not
   * @param {EnjoyedBundle} boolen - is user is currently enjoed bundle or not this flag comes from (this.cross.withBenefit)
   * inside cross functionalty service
   * @param {isRenew} boolen - is user will renew a bundle or not
   */
  purchasingPrepaidProduct(activeBundle: boolean, inActiveBundle: boolean, EnjoyedBundle: boolean, isRenew: boolean) {
    this.cross.showToast = false;
    this.cross.showLoading = true;

    const id = this.cross.productID;
    this.productsAndServicesService.extraProduct = this.product.products.find(prd => prd.Code === id);
    return this.product.BuyOrRemoveBundle(id, this.cross.status, this.cross.promotionCode, this.cross.email,
      this.cross.renew).pipe(map(() => {
        this.sendTaggingSuccessJourny();
        this.cross.renewed = false;
        this.cross.productSubject.next();
        // in case prepaid user activate enjoymore
        if (activeBundle) {
          this.purchasingPrepaidActiveBundle();
        }// in case prepaid user renew extras or enjoy more
        else if (isRenew) {
          this.renewExtraOrEnjoy();
        }// in case prepaid user deactive bundle active enjoyed
        else if (inActiveBundle && EnjoyedBundle) {
          this.deactivateActiveBundleEnjoyed()
        }// in case prepaid user deactive bundle active not enjoyed
        else if (inActiveBundle && !EnjoyedBundle) {
          this.deactivateActiveBundleNotEnjoyed();
        }
        this.cancel();

        if (this.cross.customNudge) {
          this.translate.get('productsServices.tv.messagesList.OTVConfirmationOK').
            subscribe(data => {
              this.cross.nudgeTitle = data.OTVConfirmationOK_title;
              this.cross.nudgeDescription = data.OTVConfirmationOK_description;
            });
          this.cross.showToast = false;
          this.cross.showNudge = true;
        } else {
          this.cross.showToast = true;
          this.cross.showNudge = false;
        }

        this.cross.productActivationEmmiter.next();
      }));
  }

  /**
   * purchasingPrepaidActiveBundle method is used for handling purchasing Prepaid Active Bundle
   */
  private purchasingPrepaidActiveBundle() {
    this.cross.isProductInActive = false;
    this.cross.isProductActive = true;
    this.cross.alert = false;
    // General confirmation message for all toasts
    this.translate.get('productsServices.contentList.actAndDeAct.messagesList.preActivation.preActivation_description').
      subscribe(data => {
        this.cross.toastMessage = data;
      });
  }

  /**
   * deactivateActiveBundleEnjoyed method is used for handling deactivation Prepaid Active enjoyed Bundle
   */
  private deactivateActiveBundleEnjoyed() {
    // if we are going to deactivate a bundle active enjoyed it will be pending and still active
    this.cross.isProductInActive = false;
    this.cross.isProductActive = true;
    this.cross.pending = true;
    this.cross.isDimmedButton = true;
    this.cross.isPendingDeActivation = true;
    this.cross.alert = false;
    this.translate.get('productsServices.contentList.actAndDeAct.messagesList.preEnjoyDeAct.preEnjoyDeAct_description').
      subscribe(data => {
        this.cross.toastMessage = data;
      });
  }

  /**
   * deactivateActiveBundleEnjoyed method is used for handling deactivation Prepaid Active Not enjoyed Bundle
   */
  private deactivateActiveBundleNotEnjoyed() {
    // if we are going to deactivate a bundle active not enjoyed it will be deactivated immediately
    this.cross.isProductInActive = true;
    this.cross.isProductActive = false;
    this.cross.alert = false;
    this.translate.get('productsServices.contentList.actAndDeAct.messagesList.preNoEnjoyDeAct.preNoEnjoyDeAct_description').
      subscribe(data => {
        this.cross.toastMessage = data;
      });
  }

  /**
   * deactivateActiveBundleEnjoyed method is used for handling renewing Bundle
   */
  private renewExtraOrEnjoy() {
    // success renew
    this.cross.alert = false;
    this.cross.renewed = true;
    this.cross.renew = false;
    if (this.productsAndServicesService.isAccumulate) {
      this.translate.get('productsServices.contentList.actAndDeAct.messagesList.preActivation.preActivation_description').
        subscribe(data => {
          this.cross.toastMessage = data;
        });

    } else {
      this.translate.get('productsServices.contentList.actAndDeAct.messagesList.preRenewal.preRenewal_description').
        subscribe(data => {
          this.cross.toastMessage = data;
        });

    }
  }

  /**
 * sendTaggingSuccessJourny method is used for handling tagging in case of sucess
 */
  sendTaggingSuccessJourny() {
    if (this.productsAndServicesService.changeWorryFreeStatus) {
      this.sendTaggingWorryFreeSuccessJourney();
      this.productsAndServicesService.changeWorryFreeStatus = false;
    }
    else if (this.productsAndServicesService.selectedTvExtra) {
      this.sendTaggingTvSuccessJourny();
    }
    else if (this.productsAndServicesService.changeEnjoyMoreStatus) {
      this.sendTaggingEnjoyMoreSuccessJourney();
      this.productsAndServicesService.changeEnjoyMoreStatus = false;
    }
    else if (this.productsAndServicesService.extraProduct &&
      this.productsAndServicesService.extraProduct.Type.toLowerCase() === BundleType.Data.toLowerCase()) {
      this.sendTaggingEnjoyMoreSuccessJourney();
    }
    else if (this.productsAndServicesService.promtionMiniXs &&
      this.cross.productID === this.productsAndServicesService.promtionMiniXs.Code) {
      this.promotionTaggingSuccess()
    }
  }

  /**
   *
   * sendTaggingSuccessJourny method is used for handling tagging
   */
  sendTaggingWorryFreeSuccessJourney() {
    if (this.cross.status === BuyOrRemove.Active) {
      tagging.productAndServices.manageDataBundle.success.data.journey_subcategory =
        tagging.productAndServices.manageDataBundle.subCategory.activate;
    }
    if (this.cross.status === BuyOrRemove.Inactive) {
      tagging.productAndServices.manageDataBundle.success.data.journey_subcategory =
        tagging.productAndServices.manageDataBundle.subCategory.deactivate;
    }
    this.tagging.track(tagging.productAndServices.manageDataBundle.success.eventName,
      tagging.productAndServices.manageDataBundle.success.data);
  }
  /**
   *
   * sendTaggingSuccessJourny method is used for handling tagging in case of error
   */
  sendTaggingErrorJourny(error, errorDescription?: string) {
    if (this.productsAndServicesService.changeWorryFreeStatus) {
      this.sendTaggingWorryFreeErrorJourney(error, errorDescription);
      this.productsAndServicesService.changeWorryFreeStatus = false;
    }
    else if (this.productsAndServicesService.selectedTvExtra) {
      this.sendTaggingTvErrorJourny(error, errorDescription);
    }
    else if (this.productsAndServicesService.changeEnjoyMoreStatus) {
      this.sendTaggingEnjoyMoreErrorJourney(error, errorDescription);
      this.productsAndServicesService.changeEnjoyMoreStatus = false;
    }
    else if (this.productsAndServicesService.extraProduct &&
      this.productsAndServicesService.extraProduct.Type.toLowerCase() === BundleType.Data.toLowerCase()) {
      this.sendTaggingEnjoyMoreErrorJourney(errorDescription);
    }
    else if (this.productsAndServicesService.promtionMiniXs &&
      this.cross.productID === this.productsAndServicesService.promtionMiniXs.Code) {
      this.promotionTaggingError();
    }
  }
  /**
   *
   * sendTaggingSuccessJourny method is used for handling tagging
   */
  sendTaggingWorryFreeErrorJourney(error, errorDescription?: string) {
    if (this.cross.status === BuyOrRemove.Active) {
      tagging.productAndServices.manageDataBundle.error.data.journey_subcategory =
        tagging.productAndServices.manageDataBundle.subCategory.activate;
    }
    if (this.cross.status === BuyOrRemove.Inactive) {
      tagging.productAndServices.manageDataBundle.error.data.journey_subcategory =
        tagging.productAndServices.manageDataBundle.subCategory.deactivate;
    }
    tagging.productAndServices.manageDataBundle.error.data.journey_error_description =
      errorDescription ? errorDescription : tagging.productAndServices.manageDataBundle.error.errorDescription
    tagging.productAndServices.manageDataBundle.error.data.journey_error_category = this.tagging.setErrorTypeValue(error);
    this.tagging.track(tagging.productAndServices.manageDataBundle.error.eventName,
      tagging.productAndServices.manageDataBundle.error.data)
  }
  /**
   *
   * sendTaggingSuccessJourny method is used for handling tagging in case of error
   */
  sendTaggingTvErrorJourny(error, errorDescription?: string) {
    if (this.productsAndServicesService.selectedTvExtra.ProductType.toLowerCase() === ProductType.OnlineTvPackage.toLowerCase()
      && this.productsAndServicesService.selectedTvExtra.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase()) {
      tagging.productAndServices.activeStandAloneOnlineTv.error.data.journey_options =
        this.productsAndServicesService.selectedTvExtra.Name;
      tagging.productAndServices.activeStandAloneOnlineTv.error.data.journey_error_description =
        errorDescription ? errorDescription : tagging.productAndServices.activeStandAloneOnlineTv.errorDescription
      tagging.productAndServices.activeStandAloneOnlineTv.error.data.journey_error_category = this.tagging.setErrorTypeValue(error);
      this.tagging.track(tagging.productAndServices.activeStandAloneOnlineTv.error.eventName,
        tagging.productAndServices.activeStandAloneOnlineTv.error.data);
    }
    else if (this.productsAndServicesService.selectedTvExtra.ProductType.toLowerCase() === ProductType.OnlineTvPackage.toLowerCase()
      && this.productsAndServicesService.selectedTvExtra.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()) {
      tagging.productAndServices.deactivateStandAloneOnlineTv.error.data.journey_options =
        this.productsAndServicesService.selectedTvExtra.Name;
      tagging.productAndServices.deactivateStandAloneOnlineTv.error.data.journey_error_description =
        errorDescription ? errorDescription : tagging.productAndServices.deactivateStandAloneOnlineTv.error.errorDescription
      tagging.productAndServices.deactivateStandAloneOnlineTv.error.data.journey_error_category = this.tagging.setErrorTypeValue(error);
      this.tagging.track(tagging.productAndServices.deactivateStandAloneOnlineTv.error.eventName,
        tagging.productAndServices.deactivateStandAloneOnlineTv.error.data)
    }
    else if (this.productsAndServicesService.selectedTvExtra.ProductType.toLowerCase() === ProductType.Channel.toLocaleLowerCase()
      && this.productsAndServicesService.selectedTvExtra.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase()) {
      tagging.productAndServices.upgradeChannel.error.data.journey_options = this.productsAndServicesService.selectedTvExtra.Name;
      tagging.productAndServices.upgradeChannel.error.data.journey_error_category = this.tagging.setErrorTypeValue(error);
      this.tagging.track(tagging.productAndServices.upgradeChannel.error.eventName,
        tagging.productAndServices.upgradeChannel.error.data);

    }
  }
  /**
  *
  * sendTaggingSuccessJourny method is used for handling tagging
  */
  sendTaggingEnjoyMoreErrorJourney(error, errorDescription?: string) {
    tagging.productAndServices.manageDataBundle.error.data.journey_error_description =
      errorDescription ? errorDescription : tagging.productAndServices.manageDataBundle.error.errorDescription
    tagging.productAndServices.manageDataBundle.error.data.journey_error_category = this.tagging.setErrorTypeValue(error);
    this.tagging.track(tagging.productAndServices.manageDataBundle.error.eventName,
      tagging.productAndServices.manageDataBundle.error.data)
  }
  /**
    *
    * sendTaggingSuccessJourny method is used for handling tagging
    */
  sendTaggingTvSuccessJourny() {
    if (this.productsAndServicesService.selectedTvExtra.ProductType.toLowerCase() === ProductType.OnlineTvPackage.toLocaleLowerCase() &&
      this.productsAndServicesService.selectedTvExtra.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase()) {
      tagging.productAndServices.activeStandAloneOnlineTv.success.data.journey_options =
        this.productsAndServicesService.selectedTvExtra.Name;
      this.tagging.track(tagging.productAndServices.activeStandAloneOnlineTv.success.eventName,
        tagging.productAndServices.activeStandAloneOnlineTv.success.data);
    }
    else if (this.productsAndServicesService.selectedTvExtra.ProductType.toLowerCase() === ProductType.OnlineTvPackage.toLocaleLowerCase()
      && this.productsAndServicesService.selectedTvExtra.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()) {
      tagging.productAndServices.deactivateStandAloneOnlineTv.success.data.journey_options =
        this.productsAndServicesService.selectedTvExtra.Name;
      this.tagging.track(tagging.productAndServices.deactivateStandAloneOnlineTv.success.eventName,
        tagging.productAndServices.deactivateStandAloneOnlineTv.success.data)
    }
    else if (this.productsAndServicesService.selectedTvExtra.ProductType.toLowerCase() === ProductType.Channel.toLocaleLowerCase()
      && this.productsAndServicesService.selectedTvExtra.currentStatus.toLowerCase() === BundleStatus.Inactive.toLowerCase()) {
      tagging.productAndServices.upgradeChannel.success.data.journey_options = this.productsAndServicesService.selectedTvExtra.Name;
      this.tagging.track(tagging.productAndServices.upgradeChannel.success.eventName,
        tagging.productAndServices.upgradeChannel.success.data);

    }
  }
  /**
    *
    * sendTaggingSuccessJourny method is used for handling tagging
    */
  promotionTaggingError() {
    tagging.productAndServices.activatePromotionJourney.error.data.journey_options = this.appService.appHeaderTitle
    this.tagging.track(tagging.productAndServices.activatePromotionJourney.error.eventName,
      tagging.productAndServices.activatePromotionJourney.error.data)
  }
  /**
  *
  * sendTaggingSuccessJourny method is used for handling tagging
  */
  sendTaggingEnjoyMoreSuccessJourney() {
    this.tagging.track(tagging.productAndServices.manageDataBundle.success.eventName,
      tagging.productAndServices.manageDataBundle.success.data);
  }
  /**
  *
  * sendTaggingSuccessJourny method is used for handling tagging
  */
  promotionTaggingSuccess() {
    tagging.productAndServices.activatePromotionJourney.success.data.journey_options = this.appService.appHeaderTitle
    this.tagging.track(tagging.productAndServices.activatePromotionJourney.success.eventName,
      tagging.productAndServices.activatePromotionJourney.success.data)
  }
  cancel(error?, errorDescription?) {
    this.cross.productActivationCancelOrFailedEmmiter.next({ error: error, description: errorDescription });
    this.cross.hide();
    this.tagging.sendViewState();
  }

  handlingError(error) {
    const errorObj = JSON.parse(error.error);
    error.error = errorObj
    this.sendTaggingErrorJourny(error);
    if (errorObj && errorObj.ecode) {
      if (errorObj.ecode.toString() === BIZTALKERROR) {
        this.appService.NudgeBiztalkError();
        this.tagging.taggingBiztalkNudgeError()
      }
      else {
        if (this.subscriptionData.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()) {
          if (errorObj.ecode.toString() === '1007') {
            if (this.cross.status && this.cross.status.toLowerCase() === BundleStatus.Active.toLowerCase()) {
              this.cross.showNudge = true;
              // General confirmation message for all toasts
              this.translate.get('productsServices.messagesList.psNudgeActErr').subscribe(data => {
                this.cross.nudgeTitle = data.title;
                this.cross.nudgeDescription = data.description + ' ' + this.cross.productName;
              })

            } // in case prepaid user deactive bundle active enjoyed
            else if (this.cross.status && this.cross.status.toLowerCase() === BundleStatus.Inactive.toLowerCase()) {
              // if we are going to deactivate a bundle active enjoyed it will be pending and still active
              this.translate.get('productsServices.messagesList.psNudgeDeActErr').subscribe(data => {
                this.cross.nudgeTitle = data.title;
                this.cross.nudgeDescription = data.description + ' ' + this.cross.productName;
              })
            }
          } else {
            const errorMessageObj = this.errorHandling.lookUpError(PAGES.PRODUCT_SERVICE, error);
            this.cross.nudgeTitle = errorMessageObj.title;
            this.cross.nudgeDescription = errorMessageObj.description;
          }
          this.cancel(error, this.cross.nudgeDescription);
          this.cross.showNudge = true;
        }
        const errorMessageObj = this.errorHandling.lookUpError(PAGES.PRODUCT_SERVICE, error);
        if (errorObj.ecode.toString() === VERIFYEMAIL.ErrorUserNameExist) {
          this.cross.isShowActiveForm = true
          this.cross.removeData = false
          this.cross.showNudge = false;
          this.cross.showToast = false;
          this.cross.isDimmedButton = true
          this.cross.showErrorBackendMessage = true;
          this.cancel(error);
        }
        else if (errorObj.ecode.toString() === VERIFYEMAIL.ErrorActivateExtra) {
          if (!this.cross.navigation && this.cross.status) {
            if (this.cross.status.toLowerCase() === BuyOrRemove.Active.toLowerCase()) {
              this.translate.get('productsServices.messagesList.psNudgeActErr').subscribe(data => {
                // UAT CR: Remove The Product Name from the nudge as it gets to multiple lines
                this.cross.nudgeTitle = data.title;
                this.cross.nudgeDescription = data.description;
              })
            }
            else if (this.cross.status.toLowerCase() === BuyOrRemove.Inactive.toLowerCase()) {
              this.translate.get('productsServices.messagesList.psNudgeDeActErr')
                .subscribe(data => {
                  // UAT CR: Remove The Product Name from the nudge as it gets to multiple lines
                  this.cross.nudgeTitle = data.title
                  this.cross.nudgeDescription = data.description + ' ' + this.cross.productName
                })
            }
          }
          this.cross.removeData = false;
          this.cross.showToast = false;
          this.cross.showOneButton = false;
          this.cross.showRetryButton = false;
          this.cancel(error, this.cross.nudgeTitle);
          this.cross.showNudge = true;
          this.sendTaggingTvErrorJourny(error.code, this.cross.nudgeDescription);
        }
        else {
          this.cross.showOneButton = true;
          this.cross.showNudge = true;
          this.cross.nudgeTitle = errorMessageObj.title;
          this.cross.nudgeDescription = errorMessageObj.description;
          this.cross.buttonText = errorMessageObj.dismissButtonText;
          this.cancel(error, this.cross.nudgeDescription);
        }
      }

    }
    else {
      this.cross.removeData = true;
      this.cross.showToast = false;
      this.cross.showNudge = false;
      const notificationModel = this.errorHadlingUsingNotificationService.errorHandlingUsingNotification(error,
        HttpRequestMethods.post,
        PAGES.PRODUCT_SERVICE, true);
      notificationModel.show()
      this.cancel(error, notificationModel.bodyContent.toString());

    }
  }
}


import {finalize} from 'rxjs/operators';
import { ServiceModel } from './../../../models/service.model';
import { LoginService } from './../../../login/login.service';
import { SubscriptionService } from './../../../core/services/subscription.service';
import { CompanyService } from './../../../shared/services/company.service';
import { ProfileType } from './../../../shared/enums/profileType.enum';
import { StorageService } from './../../../core/services/storage.service';
import { CustomerAccountService } from './../../../shared/services/customer-account.service';
import { Router } from '@angular/router';
import { SidemenuCategory } from './../../../shared/enums/sidemenu-category.enum';
import { MENU_ACTION_TYPE, SidemenuQuery, LeftMenuIconsName, adaraValues } from './../../../shared/constants/defines';
import { NavigationType } from './../../../shared/enums/navigation-type.enum';
import { SidemenuConfigService } from './../../../shared/services/sidemenu-config.service';
import { Subject ,  Observable } from 'rxjs';
import { ResizeService } from './resize.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Breakpoints, Mva10SelectFieldModel, CardImageSelectorModel, SelectorModel } from '@mva10/mva10-angular';
import { config } from '../../../../config/pages-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { tagging } from '../../../../config/tagging-config';
import { SideMenuItemModel } from '../../../models/side-menu-item.model';
import { SideMenuItemType } from '../../../shared/enums/side-menu-item-type.enum';
import { SideMenuModel } from '../../../models/side-menu.model';
import { CardSelectorExtendedModel } from '../../../models/card-selector-extended.model';
import { ProductManagementEnum } from '../../../shared/enums/product-management.enum';
import { ProductManagementModel } from '../../../models/product-management.model';
import { UtilitiesService } from '../../../shared/utils/utilities.service';
import { TaggingClickDataModel } from '../../../models/tagging.model';
import { NotificationBadgeService } from '../../../shared/services/notification-badge.service';
import { NotificationType } from '../../../shared/enums/notification-type.enum';
import { ServiceType } from '../../../shared/enums/serviceType.enum';
import { TrayConfigurationService } from './../../dashboard/services/tray-configuration.service';
import { FOLDER_TRAY_FOR_YOU, FOLDER_TRAY_SHOP_SELECTOR, TRAY_CONFIG_TRACKING,
  FOLDER_TRAY_KIT_DIGITAL, FOLDER_TRAY_ONE_PROFESSIONAL } from '../../../shared/constants/archetype/trayConfigTracking';
import { SiteHandlingService } from '../../../shared/services/site-handling.service';

@Injectable()
export class LeftMenuWs10Service {

  showLeftMenu = false;
  leftMenuData = new SideMenuModel();
  expanded: boolean;
  selectedOption: SideMenuItemType;
  showSubmenu = false;
  clientsPQ = false;
  currentCif: any;
  currentAddress: any;
  addressSelector: Mva10SelectFieldModel[];
  addressSelectorDefault: string;
  addressSelectorLoaded = false;
  cifTabIdx = 0;
  productSelectorLoad = false;
  selectorDataButtonText: string;
  selectorDataDescription: string;
  errorCif = false;
  loaded = false;
  public fullViewPage: boolean = true;
  public itemsTablet: SideMenuItemModel[];
  public itemsDesktop: SideMenuItemModel[];
  private productSelectedSubject = new Subject<any>();
  private clickedButtonSubject = new Subject();
  private cifSelectorSubject: Subject<number> = new Subject<number>();
  private siteSelectorSubject = new Subject();
  startIndexSelectorCard: number;
  isSitePendingInstallation: boolean;
  isOpProducts: boolean = false;
  isProductDetail: boolean = false;
  shopSelectorButton: SideMenuItemModel;
  topUpButton: SideMenuItemModel;
  placeHolderButton: SideMenuItemModel;
  secondButton: SideMenuItemModel;
  isPrepaid: boolean;
  hasMktpEntrypoint: boolean;

  constructor(
    private menuService: SidemenuConfigService,
    private router: Router,
    private resizeService: ResizeService,
    private translateService: TranslateService,
    private tagging: TaggingHelperService,
    private customerAccountService: CustomerAccountService,
    private storageService: StorageService,
    private companyService: CompanyService,
    private subscriptionService: SubscriptionService,
    private utils: UtilitiesService,
    private notificationBadge: NotificationBadgeService,
    private loginService: LoginService,
    private siteHandlingService: SiteHandlingService,
    private trayConfigurationService: TrayConfigurationService) {
      this.notificationBadge.getRealTimeCounter().subscribe((count): void => {
        this.itemsTablet = this.itemsTablet?.map((item) => {
          if (item.type === SideMenuItemType.MENU) {
            return { ...item, badgeText: count[NotificationType.INBOX] || '' };
          }
          if (item.type === SideMenuItemType.ACCOUNT) {
            return { ...item, badgeText: count[NotificationType.TOTAL] > 0 ? ' ' : '' };
          }
          return item;
        });
        this.itemsDesktop = this.itemsTablet?.filter((item) => item.type !== SideMenuItemType.PRODUCTS);
        this.updateItems();
      });
  }

  get productSelected$(): Observable<any> {
    return this.productSelectedSubject.asObservable();
  }

  get clickedButton$(): Observable<any> {
    return this.clickedButtonSubject.asObservable();
  }

  get cifSelectorClicked$(): Observable<number> {
    return this.cifSelectorSubject.asObservable();
  }

  get siteSelectorClicked$(): Observable<any> {
    return this.siteSelectorSubject.asObservable();
  }

  initLeftMenu() {
    if (!this.loaded) {
      this.enableMenu();

      this.resizeService.onResize$.subscribe((size: Breakpoints) => {
        this.updateItems();
      });

      this.customerAccountService.productsUpdated$.subscribe((selectorData: any) => {
        this.initSelectorData();
      });

      this.translateService.get('v10.common.tray').subscribe(text => {
          const menuButton = new SideMenuItemModel();
          menuButton.iconClassName = LeftMenuIconsName.menu;
          menuButton.text = text.lnk_menu;
          menuButton.type = SideMenuItemType.MENU;

          this.setSecondButton();

          this.shopSelectorButton = new SideMenuItemModel();
          this.shopSelectorButton.iconClassName = LeftMenuIconsName.shop;
          this.shopSelectorButton.text = text.lnk_shop;
          this.shopSelectorButton.route = config.TiendaV10.route;
          this.shopSelectorButton.type = SideMenuItemType.SHOP;

          this.topUpButton = new SideMenuItemModel();
          this.topUpButton.iconClassName = LeftMenuIconsName.topUps;
          this.topUpButton.text = text.lnk_top_up;
          this.topUpButton.route = config.TopUp.topUpPrepaidservice.route;
          this.topUpButton.type = SideMenuItemType.TOPUP;

          const productsButton = new SideMenuItemModel();
          productsButton.iconClassName = LeftMenuIconsName.products;
          productsButton.text = text.lnk_products;
          productsButton.type = SideMenuItemType.PRODUCTS;

          const accountButton = new SideMenuItemModel();
          accountButton.iconClassName = LeftMenuIconsName.myAccount;
          accountButton.text = text.lnk_account;
          accountButton.type = SideMenuItemType.ACCOUNT;

          this.placeHolderButton = new SideMenuItemModel();
          this.placeHolderButton.type = SideMenuItemType.PLACEHOLDER;

           const items: Array<SideMenuItemModel> = !this.siteHandlingService.isSiteP2() ?
           [menuButton, this.secondButton, this.placeHolderButton, productsButton, accountButton] :
           [menuButton, productsButton, accountButton];

          this.itemsTablet = items;
          this.itemsDesktop = items.filter(item => {
            return item.type !== SideMenuItemType.PRODUCTS;
          });
          this.setLeftMenuButton(this.hasMktpEntrypoint)
          this.updateItems();
      });

      if (this.showLeftMenu) {
        this.initServiceSelector();
      }
      this.loaded = true;
    }
  }

  private enableMenu() {
    if (this.router.url !== config.login.route && this.router.url !== config.login.companyChooser.route) {
      this.showLeftMenu = true;
    } else {
      this.showLeftMenu = false;
    }
  }

  setCifSelectorClicked(cifTabIdx) {
    this.cifTabIdx = cifTabIdx;
    this.companyService.selectedCompanyId = this.companyService.CompanyList[cifTabIdx].id;

    if (this.router.url.split('?')[0] === config.dashboard.route) {
      this.cifSelectorSubject.next(cifTabIdx);
    } else {
      this.updateCifSelector(cifTabIdx);
      if (this.router.url.split('?')[0] !== config.ProductsAndServices.MainScreen.route) {
        this.router.navigate([config.dashboard.route]);
      }
    }
  }

  private updateCifSelector(tabIdx) {
    this.cifTabIdx = tabIdx;
    this.companyService.selectedCompanyId = this.companyService.CompanyList[tabIdx].id;
    if (tabIdx === undefined) {
      this.tagging.track(tagging.dashboard.retryCif.eventName, tagging.dashboard.retryCif.data);
    }

    if (this.companyService.selectedCompanyId !== this.customerAccountService.otherCIFs) {
      this.productSelectorLoad = false;
      this.addressSelectorLoaded = false;
      this.errorCif = false;

      this.subscriptionService.GetCompanyServices(this.companyService.selectedCompanyId).subscribe(result => {
        this.initServiceSelector();
        this.siteSelectorSubject.next(this.subscriptionService.customerData.currentService);
        this.tagging.encryptApi(this.subscriptionService.customerData.currentService.id).pipe(finalize(() => {
          this.tagging.track(tagging.dashboard.selectCif.eventName, tagging.dashboard.selectCif.data);
        })).subscribe();
      }, error => {
        this.productSelectorLoad = true
        this.setAddressSelector();
        this.errorCif = true;
      });
    } else {
      this.loginService.siebleRedirection(this.subscriptionService.customerData.customerAccountsId,
        this.subscriptionService.customerData.currentService.id);
    }
  }

  private initServiceSelector() {
    this.addressSelectorLoaded = false;
    this.productSelectorLoad = false;

      if (!this.subscriptionService?.serviceListSite?.length && this.companyService.selectedCompanyId) {
        const companyId: string = this.companyService.selectedCompanyId;
        this.subscriptionService.GetDefaultServices(companyId && companyId.length ? companyId : null).subscribe(() => {
          this.initProducts();
        }, () => {
          this.errorCif = true;
          this.addressSelectorLoaded = true;
          this.productSelectorLoad = true;
        });
      } else {
        this.initProducts();
      }
  }

  private initProducts(): void {
    this.customerAccountService.getCustomerAccountsWS10(null, true).subscribe(() => {
      this.customerAccountService.initSelector(null, true);
    }, () => {
      this.errorCif = true;
      this.addressSelectorLoaded = true;
      this.productSelectorLoad = true;
    });
  }

  initSelectorData() {
    this.setSelectorDataText();
    this.setAddressSelector();
    this.initCifSelector();
    this.clientsPQ = this.storageService.userProfile.customerType.toLowerCase() !== ProfileType.EMPLOYEE.toLowerCase() &&
      this.storageService.userProfile.customerType.toLowerCase() !== ProfileType.SME.toLowerCase();
    this.productSelectorLoad = true;
    this.addressSelectorLoaded = true;
    this.errorCif = false;
  }

  updateAddress() {
    this.setSelectorDataText();
    this.currentAddress = this.addressSelector.find(field => field.checked).name;
    this.productSelectorLoad = true;
    this.addressSelectorLoaded = true;
    this.errorCif = false;
  }

  private setSelectorDataText() {
    this.translateService.get('v10.common.tray.overlay_myproducts').subscribe(text => {
      this.selectorDataDescription = text.selectaddress;
      this.selectorDataButtonText = this.customerAccountService.isSitePrepaid ?
        text.products_services_button : text.mysubscriptions;
    });
  }

  private setAddressSelector(): void {
      this.addressSelector = [
        ...this.customerAccountService.customerAccounts,
        ...this.customerAccountService.customerAccountsPendingInstall
      ].filter(item => item.marketType !== adaraValues.CLIENT_ADARA).map(item => {
        const field: Mva10SelectFieldModel = new Mva10SelectFieldModel();
        field.name = item.address.formattedAddress ? item.address.formattedAddress : item.address.toString();
        field.value = item.id;
        field.checked = false;
        if (item.id === this.subscriptionService.customerData.customerAccountsId) {
          field.checked = true;
          this.addressSelectorDefault = field.value;
          this.currentAddress = field.name;
        }
        return field;
       });
  }

  private initCifSelector() {
    this.currentCif = this.companyService.selectedCompanyId;
    if (this.customerAccountService.selectorCIFData) {
      this.cifTabIdx = this.customerAccountService.selectorCIFData.findIndex(item => item === this.currentCif);
    }
  }

  setSiteSelectorClicked(): void {
    if (this.router.url.split('?')[0] === config.dashboard.route) {
      this.updateDashboardSite();
    } else {

      const id: string = this.addressSelector.find(selectField => {
        return selectField.checked;
      }).value;

      if (this.customerAccountService.selectDefault !== id || !id) {

        const idSelected: string = id ? id : this.customerAccountService.selectDefault;

        this.productSelectorLoad = false;

        const _found: CardSelectorExtendedModel = this.customerAccountService.primaryList.find(element => element.id === idSelected);
        this.customerAccountService.selectDefault = (_found) ? _found.id : null;
        this.subscriptionService.customerData.customerAccountsId = this.customerAccountService.selectDefault;

        this.isSitePendingInstallation = this.customerAccountService.isPendingInstallationSiteStatus();

        this.subscriptionService.customerData.services = [];

        if (this.isSitePendingInstallation) {
          this.productSelectorLoad = true;
          this.closeSubmenu();
        } else {
          this.subscriptionService.GetCustomerServices(idSelected).subscribe(result => {
            this.subscriptionService.customerData.currentService = result[0];
            this.subscriptionService.customerSubject.next(this.subscriptionService.customerData.currentService);
            this.siteSelectorSubject.next(this.subscriptionService.customerData.currentService);
            this.customerAccountService.selectorData = new SelectorModel();
            this.customerAccountService.selectorData.cardImageSelectorList = [];
            this.customerAccountService.isSitePrepaid = true;
            this.subscriptionService.serviceListSite.forEach(item => {
              const type: any = item.type;
              this.customerAccountService.checkSitePrepaid(type);
              this.customerAccountService.selectorData.
                cardImageSelectorList.push(this.customerAccountService.mapServiceSelector(item, type));
            });
            this.customerAccountService.selectorDataWithSubscriptions = this.customerAccountService.getServicesListWithSubscriptions();
            this.startIndexSelectorCard =
              this.customerAccountService.selectorData.cardImageSelectorList.findIndex(select => select.checked === true);
            this.setSelectorDataText();
            this.updateAddress();
          }, error => {
            this.productSelectorLoad = true;
          });
        }
      }

      if (this.router.url.split('?')[0] !== config.ProductsAndServices.MainScreen.route) {
        this.router.navigate([config.dashboard.route]);
      }
    }
  }

  updateDashboardSite() {
    const selectedIdx = this.addressSelector.findIndex(selectField => {
      return selectField.checked;
    });
    this.currentAddress = this.addressSelector[selectedIdx].name;
    const siteClicked = this.customerAccountService.primaryList[selectedIdx];
    this.siteSelectorSubject.next(siteClicked.id);
  }

  productsButtonClicked() {
    this.closeSubmenu();
    this.clickedButtonSubject.next();
  }

  productSelected(productId: string): void {
    this.closeSubmenu();
    this.isOpProducts = false;
    this.resetTrayButtonsWhenProductSelect(this.subscriptionService.customerData.currentService,
      this.subscriptionService.customerData.services.find(srv => srv.id === productId))
    if (productId === ProductManagementEnum.online_subscriptions) {
      const product: ProductManagementModel = this.translateService.instant('v10.dashboard.gestion.list.online_subscriptions');

      const eventName: string = tagging.dashboard.subscriptionOnlineTrayClick.eventName;
      const data: TaggingClickDataModel = Object.assign({}, tagging.dashboard.subscriptionOnlineTrayClick.data);
      this.tagging.track(eventName, data);

      this.router.navigate([product.url_pm.split('?')[0]], { queryParams: this.utils.getRouteParams(product.url_pm) });
    } else if (productId === ProductManagementEnum.business) {
      this.isOpProducts = true;
      let data: TaggingClickDataModel;
      const title: CardImageSelectorModel = this.customerAccountService.selectorData.cardImageSelectorList
      .find(element => element.id === ProductManagementEnum.business)
      const eventName: string = tagging.dashboard.selectorCardBusinessClicked.eventName.replace('product', `${title.title} ${title.description}`);
        data = {...tagging.dashboard.selectorCardBusinessClicked.data};
        data.event_label = `${title.title} ${title.description}`;
        this.tagging.track(eventName, data);
      this.router.navigate([config.ProductsAndServices.MainScreen.route]);
    }
    else if (this.router.url.split('?')[0] !== config.dashboard.route) {
      const currentService: ServiceModel = this.subscriptionService.serviceListSite.find(service => service.id === productId);
      if (currentService) {
        this.subscriptionService.customerData.currentService = currentService;
        this.changeProduct(productId);
        this.router.navigate([config.ProductsAndServices.MainScreen.route]);
      }
    } else {
      const service: ServiceModel = this.subscriptionService.customerData.services.find(srv => srv.id === productId);
      if (this.subscriptionService.customerData.currentService.id !== service.id) {
        this.subscriptionService.customerData.currentService = service;
      }
      this.fullViewPage = false;
      this.router.navigate([config.ProductsAndServices.MainScreen.route]);
    }
  }

  changeProduct(product: string): void {
    if (this.customerAccountService.selectorData) {
      this.customerAccountService.selectorData.cardImageSelectorList.forEach(item => {
        item.checked = false;
        if (item.id === product) {
          item.checked = true;
        }
      });
    }
  }

  logoutClick() {
    this.closeSubmenu();
    this.menuService.leftMenuItemClick({
      navigationType: NavigationType.Internal,
      itemText: '',
      locked: false,
      iconClassName: '',
      actionType: MENU_ACTION_TYPE.Command,
      command: SidemenuQuery.logout,
      category: SidemenuCategory.General
    });
  }

  leftMenuItemClick(item) {
    this.selectedOption = item.type;
    if (item.type === SideMenuItemType.MENU || item.type === SideMenuItemType.PRODUCTS || item.type === SideMenuItemType.ACCOUNT) {
      this.showSubmenu = true;
      this.expanded = true;
    } else if (item.type === SideMenuItemType.SHOP) {
      this.buttonShopClick();
    } else {
      this.closeSubmenu();
      this.buttonClicked(item);
      this.router.navigate([item.route]);
    }
  }

  onClickSubmenuItem(item) {
    this.closeSubmenu();
    this.fullViewPage = !item.redirectTo?.includes(config.ProductsAndServices.landing.route);
    this.menuService.leftMenuItemClick(item);
  }

  closeSubmenu() {
    this.showSubmenu = false;
    this.expanded = false;
  }

  public updateItems(): void {
    this.leftMenuData.items = this.resizeService.breakpoint === Breakpoints.DESKTOP ? this.itemsDesktop : this.itemsTablet;
  }

  buttonClicked(button: SideMenuItemModel): void {
    if (button.type === SideMenuItemType.FORYOU) {
      this.trayConfigurationService.trayInteractionTagging(FOLDER_TRAY_FOR_YOU, TRAY_CONFIG_TRACKING.EVENTS.CLICK_FOR_YOU);
    } else if (button.type === SideMenuItemType.KITDIGITAL) {
      this.trayConfigurationService.trayInteractionTagging(FOLDER_TRAY_KIT_DIGITAL, TRAY_CONFIG_TRACKING.EVENTS.CLICK_KIT_DIGITAL);
    } else if (button.type === SideMenuItemType.ONEPROFESSIONAL) {
      this.trayConfigurationService.trayInteractionTagging(FOLDER_TRAY_ONE_PROFESSIONAL,
        TRAY_CONFIG_TRACKING.EVENTS.CLICK_ONE_PROFESSIONAL);
    } else if (button.route === config.PurchasedProducts.route) {
      const eventName = tagging.dashboard.leftMenuMyServicesClick.eventName;
      const data = tagging.dashboard.leftMenuMyServicesClick.data;
      this.tagging.track(eventName, data);
    } else if (button.route === config.myAccount.route) {
      const eventName = tagging.dashboard.leftMenuMyAccountClick.eventName;
      const data = tagging.dashboard.leftMenuMyAccountClick.data;
      this.tagging.track(eventName, data);
    }
  }

  clearMenu() {
    this.loaded = false;
    this.showLeftMenu = false;
    this.closeSubmenu();
    this.hasMktpEntrypoint = null;
    this.isPrepaid = null;
  }

  public setLeftMenuButton(hasMktpEntrypoint: boolean, selectedService?: ServiceModel): void {
    this.hasMktpEntrypoint = hasMktpEntrypoint;
    this.isPrepaid = this.subscriptionService.customerData.currentService.type === ServiceType.Prepaid;
    let shopOrTopUpButton: SideMenuItemModel
    if (selectedService) {
      shopOrTopUpButton = selectedService?.type === ServiceType.Prepaid ? this.topUpButton : this.shopSelectorButton;
    } else {
      shopOrTopUpButton = this.isPrepaid ? this.topUpButton : this.shopSelectorButton;
    }
    this.itemsDesktop.splice(2, 1, shopOrTopUpButton);
    this.itemsTablet.splice(2, 1, shopOrTopUpButton);
  }

  buttonShopClick(): void {
    if (this.hasMktpEntrypoint) {
      this.showSubmenu = true;
      this.expanded = true;
      this.trayConfigurationService.trayInteractionTagging(FOLDER_TRAY_SHOP_SELECTOR,
        TRAY_CONFIG_TRACKING.EVENTS.SHOW_SHOP_SELECTOR);
      setTimeout(() => {
        this.trayConfigurationService.trayInteractionTagging(FOLDER_TRAY_SHOP_SELECTOR);
      }, 2000);
    } else if (!this.hasMktpEntrypoint) {
      this.closeSubmenu();
      this.trayConfigurationService.navigateToShop();
    }
  }

  resetTrayButtonsWhenProductSelect(previousService: ServiceModel, selectedService?: ServiceModel): void {
    if ((previousService?.type === ServiceType.Prepaid && selectedService?.type === ServiceType.Postpaid) ||
        (previousService?.type === ServiceType.Postpaid && selectedService?.type === ServiceType.Prepaid)) {
          this.setLeftMenuButton(this.hasMktpEntrypoint, selectedService)
    }
  }

  setSecondButton(): void {
    this.secondButton = new SideMenuItemModel();
    if (this.storageService.getIsMicroResegmented() || this.storageService.getIsME()) {
      this.secondButton.iconClassName = LeftMenuIconsName.oneProfessional;
      this.secondButton.text = this.translateService.instant(`v10.common.tray.${this.storageService.getIsME() ? 'lnk_kitdigital' : 'lnk_sp_mobile'}`);
      this.secondButton.route = this.storageService.getIsME() ? config.europeanFundsStep2.route : config.opProducts.route;
      this.secondButton.type = this.storageService.getIsME() ? SideMenuItemType.KITDIGITAL : SideMenuItemType.ONEPROFESSIONAL;
    } else {
      this.secondButton.iconClassName = LeftMenuIconsName.forYou;
      this.secondButton.text = this.translateService.instant('v10.common.tray.lnk_for_you');
      this.secondButton.route = config.specialForYou.route;
      this.secondButton.type = SideMenuItemType.FORYOU;
    }
  }
}

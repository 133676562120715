<div *ngIf="show" class="margin-bottom">
  <mva10-c-card>
    <div class="card-header">
      <div class="card-header-first">
        <img id="sharedPriceHikeIcon" [src]="icon | imgFullPath" />
        <span id="sharedPriceHikeTitle" class="card-header-first-title" [innerHTML]="title"></span>
      </div>
      <div (click)="show=false">
        <span class="icon icon-close card-header-second-icon"></span>
      </div>
    </div>
    <div class="card-desc">
      <div id="sharedPriceHikeSubtitle" class="card-desc-subtitle" [innerHTML]="subtitle"></div>
      <div id="sharedPriceHikeBody" class="card-desc-body" [innerHTML]="body"></div>
    </div>
  </mva10-c-card>
</div>

import { Component, OnInit } from '@angular/core';
import { tagging } from '../../../config/tagging-config';
import { AppService } from '../../app.service';
import { CustomerAccount } from '../../models/customer-account.model';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { BillingService } from '../billing.service';

@Component({
  selector: 'sp-new-site-selector',
  templateUrl: './new-site-selector.component.html',
  styleUrls: ['./new-site-selector.component.scss']
})
export class NewSiteSelectorComponent implements OnInit {
  selectedSite: CustomerAccount;
  otherSitesList: Array<CustomerAccount>;

  constructor(
    private billingService: BillingService,
    private taggingService: TaggingHelperService,
    public dynamicTrayService: DynamicTrayService,
    public appService: AppService
  ) { }

  ngOnInit() {
    /** Send state tagging */
    this.sendStateTagging();

    /** Set selected site data */
    this.selectedSite = this.billingService.billingSites
      .find(site => site.id === this.billingService.selectedSiteId);

    /** Set other sites only (all except selected site) */
    this.otherSitesList = this.billingService.billingSites
      .filter(site => site.id !== this.billingService.selectedSiteId);
  }

  /**
   * Method for closing the tray and emitting selectedSiteChanged subject to reload bill payment landing page
   * @param site The choosen one of the sites list
   */
  changeSite(site: CustomerAccount): void {
    this.sendTrackTagging();
    this.billingService.newSiteSelected(site);
    this.closeTray();
  }

  /**
   * close site selector list tray
   */
  closeTray(): void {
    this.dynamicTrayService.closeTraySubject.next();
  }
  /**
   * Method for sending state tagging when show this screen
   */
  sendStateTagging(): void {
    this.taggingService.view(tagging.newSiteSelectorTray.stateScreen);
  }

  /**
   * Method for sending track tagging after choosing one of the addresses
   */
  sendTrackTagging(): void {
    this.taggingService.track(tagging.newSiteSelectorTray.track.eventName, tagging.newSiteSelectorTray.track.data,
    tagging.newSiteSelectorTray.stateScreen);
  }
}

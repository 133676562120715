import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlToTextPipe'
})
export class HtmlToTextPipe implements PipeTransform {

  transform(text: string, args?: any): string {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

}

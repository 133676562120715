import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { DropDownItem } from '../../../models/dropdown.model';
declare var $;
@Component({
  selector: 'sp-toggle-dropdown',
  templateUrl: './sp-toggle-dropdown.component.html',
  styleUrls: ['./sp-toggle-dropdown.component.scss']
})
export class SpToggleDropdownComponent implements OnInit, OnChanges {
  historyValue
  @Input() dataSet: Array<DropDownItem>;
  @Output() clickevent = new EventEmitter();
  @Input() datePickerText: string;
  @Input() title: string;
  @Input() prefix: string;
  @Input() showDatePickerFlage: boolean;
  @Input() selectDatePicker: boolean;
  @Output() showDatePicker = new EventEmitter();
  @Output() expanded = new EventEmitter();
  @Input() isSelected: boolean;
  @Input() addPrefix: boolean;
  @Input() disableDropdown: boolean;
  @Input() selectPpeDdStatus: boolean;
  @Output() dropdownInitValue = new EventEmitter();
  @Input() close = false;
  isExpanded: boolean;
  @Input() selectedLanguage: DropDownItem
  selectedDuration: any;
  initDropDown: boolean;
  constructor() { }

  ngOnInit() {
    if (!this.historyValue || !this.selectedDuration) {
      this.historyValue = this.dataSet[0].label;
      this.selectedDuration = this.dataSet[0].duration;
      this.dropdownInitValue.emit({
        label: this.historyValue,
        duration: this.selectedDuration
      })
      if (this.selectPpeDdStatus) {
        this.initDropDown = true;
      }
    }
  }
  ngOnChanges(changes) {
    if (changes.selectedLanguage && changes.selectedLanguage.currentValue) {
      this.historyValue = changes.selectedLanguage.currentValue.label.replace(/\s/g, '');
      this.selectedDuration = changes.selectedLanguage.currentValue.duration;
    }
    if (changes.close) {
      this.closeDropdown();
      this.close = false;
    }
  }
  toggleHistory() {
    if (!this.disableDropdown) {
      if (!$('.history-dropdown').is(':visible') || $('.history-dropdown').is(':hidden')) {
      this.openDropdown();
        return;
      } else {
        this.closeDropdown();
      }
    }
  }
  setHistoryValue(newValue: DropDownItem) {
    this.historyValue = newValue.label;
    this.selectedDuration = newValue.duration;
    this.clickevent.emit(newValue);
  }
  openDatepicker() {
    this.showDatePicker.emit();

  }
  openDropdown() {
    $('.history-dropdown').slideDown();
    $('.icon.icon-chevron-down').hide();
    $('.icon.icon-chevron-up').show();
    if (this.selectPpeDdStatus) {
      this.isExpanded = true;
      this.expanded.emit(true)
    }
  }
  closeDropdown() {
    $('.history-dropdown').slideUp();
    $('.icon.icon-chevron-up').hide();
    $('.icon.icon-chevron-down').show();
    this.isExpanded = false;
    this.expanded.emit(false);
  }
}

import { Component, Input } from '@angular/core';
import { TimeLineItemModel } from '../../models/time-line-item.model';

@Component({
  selector: 'sp-mva10-time-line',
  templateUrl: './mva10-time-line.component.html',
  styleUrls: ['./mva10-time-line.component.scss']
})
export class Mva10TimeLineComponent {
  @Input() itemList = new Array<TimeLineItemModel>();
}

<div class="vfac-submenu-account vfes-2/3">
  <p class="vfac-submenu-account__title mva10-u--body">{{ 'v10.common.tray.overlay_myaccount.myaccount' | translate }}</p>
  <p class="vfac-submenu-account__subtitle mva10-u-heading--4-lt">{{ 'v10.common.tray.overlay_myaccount.subtitle' | translate }}</p>

  <div class="vfac-submenu-account__cards-container" *ngIf="menuService.myAccountCarouselData">
    <vfac-card-product
      *ngFor="let item of menuService.myAccountCarouselData.cardImageSelectorList"
      (click)="myAccountSelectorCardClicked(item)"
      [image]="item.image"
      [title]="item.title"
      [description]="item.description"
      extraClass="mva10-u--mb2x"
      [showBadge] = "item.showBadge"
      [badgeText] = "item.badgeText"
    ></vfac-card-product>
  </div>
</div>

import { Injectable, EventEmitter } from '@angular/core';
import { TopupPrepaidError } from '../shared/enums/top-up-prepaid-error.enum';
import { TranslateService } from '@ngx-translate/core';
import { SpMva10OverlayService } from '../shared/services/sp-mva10-overlay.service';
import { TopUpService } from './top-up.service';
import { TopupPrepaidStages, nativeAppVariables, nativeAppURL } from '../shared/constants/defines';
import { AppService } from '../app.service';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { tagging } from '../../config/tagging-config';
import { config } from '../../config/pages-config';
import { Router } from '@angular/router';
import { StorageService } from '../core/services/storage.service';
import { LanguageEnum } from '../shared/enums/language.enum';
import { DynamicTrayService } from '../shared/services/dynamic-tray.service';
import { BillingService } from '../billing/billing.service';
import { NewTaggingHelperService } from '../core/services/new-tagging.helper.service';
import { FOLDER_FINANCED_DEVICES, PAYMENTS_TRACKING } from '../shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from '../models/new-tagging-json-model';
import { NewTaggingStateListModel } from '../models/new-tagging-state-list.model';

@Injectable()
export class TopupPrepaidErrorOVerlayDataService {
    title: string;
    descriptionLine1: string;
    descriptionLine2: string;
    iconUrl: string;
    lottieFile: string;
    showLottie: boolean = false;
    showTwoCtas: boolean = false;
    confirmationButtonText: string;
    cancelationButttonText: string;
    showXButton: boolean = false;
    cancelationButtonFn: Function;
    activateXbuttonSubject: boolean = false
    confirmationButtonFn: Function;
    taggingViewObj = {};
    errorDesc: string = '';
    errorCode: string = '';
    hideCancelOverlay: boolean = false;
    xButtonSubject: EventEmitter<void> = new EventEmitter();
    trayTitle: string = '';
    isIframeCanceled: boolean = false;
    pageTaggingName: string = '';
    sendTaggingClickOnXInteractionFlag: boolean;
    isKoTaggingJourney: boolean = true;
    constructor(private translate: TranslateService,
        private trayService: SpMva10OverlayService,
        private topupService: TopUpService,
        private appservice: AppService,
        private tagging: TaggingHelperService,
        private storageService: StorageService,
        private router: Router,
        public billService: BillingService,
        private dynamicTrayService: DynamicTrayService,
        private newTagging: NewTaggingHelperService) { }

    setErrorUiData(errorCategory: TopupPrepaidError): void {
        this.setErrorTrayConfig(errorCategory);
        this.handleErrorsByCategory(errorCategory);
        this.setErrorUIDataByCategory(errorCategory);
        switch (errorCategory) {
            case TopupPrepaidError.refundFailed:
                this.setErrorOverlayExtraData(false, false, 'payment.messagesList.failureRefundMsg',
                'payment.messagesList.failureRefundMsg_en', 'payment.messagesList.failureRefundMsg', this.topupService.isMobileView);
                this.trayService.showBackwardTransition = false;
                this.trayService.showForwardTransition = false;
                this.confirmationButtonFn = () => {
                    this.sendTaggingStateArchetype(
                        PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO_REFUND_FAILED
                    );
                    this.closeTray();
                };
                break;
            case TopupPrepaidError.TimeOut:
                this.setErrorOverlayExtraData(true, false, 'payment.messagesList.timeoutMsg',
                'payment.messagesList.timeoutMsg_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json', this.topupService.isMobileView);
                this.confirmationButtonFn = () => {
                    this.sendTaggingInteraction(tagging.pciPrepaid.interactions.clickContinueErrorOverlay);
                    this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
                };
                break;
            case TopupPrepaidError.operationNotExist:
                this.setErrorOverlayExtraData(false, false, 'payment.messagesList.operationNotExistMsg',
                'payment.messagesList.operationNotExistMsg_en', 'payment.messagesList.operationNotExistMsg',
                this.topupService.isMobileView);
                this.trayService.showBackwardTransition = false;
                this.trayService.showForwardTransition = false;
                this.confirmationButtonFn = () => {
                    this.closeTray();
                };
                break;
            case TopupPrepaidError.mortiroloKO108:
                this.setErrorOverlayExtraData(true, false, 'payment.messagesList.failureMortiroloMsg',
                'payment.messagesList.failureMortiroloMsg_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json', this.topupService.isMobileView);
                this.trayService.showBackwardTransition = false;
                this.trayService.showForwardTransition = false;
                this.confirmationButtonFn = () => {
                    this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
                };
                break;
        }
    }

    setErrorOverlayExtraData(showLottie: boolean, showTwoCtas: boolean, spanishKeyPath: string,
        englishKeyPath: string, imgPath?: string, showXButton: boolean = false,
        hasCancelationButton: boolean = false): void {
            this.showLottie = showLottie;
            this.showTwoCtas = showTwoCtas;
            this.showXButton = showXButton;
            if (showLottie) {
                this.lottieFile = imgPath;
            }
            else {
                this.translate.get(imgPath).subscribe(data => {
                    this.iconUrl = this.appservice.getImgFullPath(data.icon.url);
                });
            }
            this.setOverlayUiContent(spanishKeyPath, englishKeyPath, hasCancelationButton);
        }

    setErrorTrayConfig(errorCategory: TopupPrepaidError): void {
        this.resetConfigurations();
        this.confirmationButtonFn = () => {
            this.sendTaggingInteraction(tagging.pciPrepaid.interactions.clickContinueErrorOverlay);
            if (!this.topupService.isMobileView) {
                this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
            } else {
                this.returnToTopupIframePage(TopupPrepaidStages.secondStep);
            }
        };
        this.cancelationButtonFn = this.closeTray;
        this.pageTaggingName = tagging.pciPrepaidPages.koPage.page_name;
        this.sendTaggingClickOnXInteractionFlag = true;
        this.trayService.showForwardTransition = true;
        this.trayService.showBackwardTransition = false;
        this.showXButton = this.topupService.isMobileView;
        this.setTrayTitle();
        if (this.isKoTaggingJourney) {
            this.taggingViewObj = tagging.pciPrepaidPages.koPage;
            this.sendKoTaggingJourney(errorCategory);
        }
        this.tagging.view(this.taggingViewObj);
    }
    handleErrorsByCategory(errorCategory: TopupPrepaidError): void {
        switch (errorCategory) {
        case TopupPrepaidError.PaymentApiFails:
        case TopupPrepaidError.general:
            this.setErrorOverlayExtraData(true, false, 'payment.messagesList.allGeneralErrorHandlingMsg',
            'payment.messagesList.allGeneralErrorHandlingMsg_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json');
            this.confirmationButtonFn = () => {
                 this.sendTaggingInteraction(tagging.pciPrepaid.interactions.clickEmpezaErrorOverlay);
                this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
            };

         break;
        case TopupPrepaidError.invalidCreditCard:
          this.setErrorOverlayExtraData(false, false, 'payment.messagesList.cardRejectedMsg',
           'payment.messagesList.cardRejectedMsg_en', 'payment.messagesList.cardRejectedMsg');
            this.confirmationButtonFn = () => {
                this.sendTaggingAndNavigateBack();
            };
            break;

        case TopupPrepaidError.invalidCreditCardInfo:
            this.setErrorOverlayExtraData(false, false, 'payment.messagesList.incorrectCardDateMsg',
            'payment.messagesList.incorrectCardDateMsg_en', 'payment.messagesList.incorrectCardDateMsg');
            this.confirmationButtonFn = () => {
               this.sendTaggingAndNavigateBack()
            };

            break;

        case TopupPrepaidError.CreditCardTypeNotSupported:
        this.setErrorOverlayExtraData(false, false, 'payment.messagesList.cardNotSupportedMsg',
        'payment.messagesList.cardNotSupportedMsg_en', 'payment.messagesList.cardNotSupportedMsg', false, true);
            this.confirmationButtonFn = () => {
                this.sendTaggingAndNavigateBack();
            };
            break;

        case TopupPrepaidError.SystemNotAvailable:
            this.setErrorOverlayExtraData(true, false, 'payment.messagesList.systemNotAvailableMsg',
            'payment.messagesList.systemNotAvailableMsg_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json', false, true);
            this.confirmationButtonFn = this.closeTray;
            break;
        }
    }

    setErrorUIDataByCategory(errorCategory: TopupPrepaidError): void {
        switch (errorCategory) {
        case TopupPrepaidError.Notactive:
        case TopupPrepaidError.Notprepaid:
        case TopupPrepaidError.NotvodafoneNumber:
            this.setErrorOverlayExtraData(true, false, 'payment.messagesList.invalidMobileNumber',
            'payment.messagesList.invalidMobileNumber_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json');
            this.confirmationButtonFn = () => {
                this.sendTaggingInteraction(tagging.pciPrepaid.interactions.clickEmpezaErrorOverlay);
                this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
            };
            break;
        case TopupPrepaidError.TopupInProgress:
            this.setErrorOverlayExtraData(true, false, 'payment.messagesList.RechargeInProgress',
            'payment.messagesList.rechargeInProgress_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json');
            this.confirmationButtonFn = () => {
                this.sendTaggingInteraction(tagging.pciPrepaid.interactions.clickEmpezaErrorOverlay);
                this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
            };
            break;
        case TopupPrepaidError.ReplicaMode:
            this.setErrorOverlayExtraData(true, false, 'payment.messagesList.replicamodeMsg',
            'payment.messagesList.replicamodeMsg_en', 'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/warning.json');
            this.confirmationButtonFn = this.closeTray;
            this.setReplicaModeButtonText();
            break;

        case TopupPrepaidError.refundSuccess:
            this.setErrorOverlayExtraData(false, false, 'payment.messagesList.sucessRefundMsg',
            'payment.messagesList.sucessRefundMsg_en', 'payment.messagesList.sucessRefundMsg', this.topupService.isMobileView);
            this.trayService.showBackwardTransition = false;
            this.trayService.showForwardTransition = false;
            this.confirmationButtonFn = () => {
                this.sendTaggingStateArchetype(
                    PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_KO_REFUND_SUCCESS
                );
                this.closeTray();
            };
            break;
        }
    }

    sendTaggingAndNavigateBack(): void {
        this.sendTaggingInteraction(tagging.pciPrepaid.interactions.clickEmpezaErrorOverlay);
        if (!this.topupService.isMobileView) {
            this.returnToTopupIframePage(TopupPrepaidStages.firstStep);
        }
        else {
            this.returnToTopupIframePage(TopupPrepaidStages.secondStep);
        }
    }
    /** This is for handling safari error overlay screen UI */
    setUiDataForSafariContingencyScreen(): void {
        this.sendSafariNotSupportedScreenTagging();
        this.showLottie = false;
        this.showTwoCtas = false;
        this.showXButton = this.topupService.isMobileView;
        this.trayService.showBackwardTransition = false;
        this.trayService.showForwardTransition = false;
        this.translate.get('payment.messagesList.safariNotSupportedMsg').subscribe(data => {
            this.title = data.safariNotSupportedMsg_title;
            this.setErrorDescription(data.safariNotSupportedMsg_description);
            this.confirmationButtonText = data.safariNotSupportedMsg_button1.text;
            this.iconUrl = this.appservice.getImgFullPath(data.safariNotSupportedMsg_icon.url);
        });
        this.confirmationButtonFn = () => {
            this.sendSafariNotSupportedInteraction();
            const completeAppLink: string = nativeAppURL
                .replace('{afl}', nativeAppVariables.androidAppStore)
                .replace('{ifl}', nativeAppVariables.iosAppleStore)
                .replace('{ofl}', nativeAppVariables.webAppLink)
                .replace('{domain}', nativeAppVariables.domainProduction)
                .replace('{apn}', nativeAppVariables.nativeAppProductionBundleId)
                .replace('{ibi}', nativeAppVariables.nativeAppProductionBundleId);
            window.location.href = completeAppLink;
        };
    }
    /** This is for send view Safari not supported screen tagging */
    sendSafariNotSupportedScreenTagging(): void {
        this.tagging.view(tagging.pciPrepaidPages.safariNotSupportedPage);
    }

    /** This is for CTA Interaction tagging in safari not supported screen */
    sendSafariNotSupportedInteraction(): void {
        const eventName: string = tagging.pciPrepaid.interactions.clickDownLoadIOSApp.eventName.replace('<page_name>',
            tagging.pciPrepaidPages.safariNotSupportedPage.page_name);
        this.tagging.track(eventName, tagging.pciPrepaid.interactions.clickDownLoadIOSApp.data,
            tagging.pciPrepaidPages.safariNotSupportedPage);
    }

    setTrayTitle(): void {
        switch (this.topupService.topupLanguage) {
            case LanguageEnum.spanish:
                this.translate.get('common.itemsList.refills.body').subscribe(data => {
                    this.trayTitle = data;
                });
                break;
            case LanguageEnum.english:
                this.translate.get('common.itemsList.refills_en.body').subscribe(data => {
                    this.trayTitle = data;
                });
                break;
        }
    }

    setOverlayUiContent(spanishKey: string, englishKey: string, hasCancelationButton: boolean = false,
        changeTitlePath: boolean = false): void {
        switch (this.topupService.topupLanguage) {
            case LanguageEnum.spanish:
                this.getOverlayWcsContent(spanishKey, hasCancelationButton, changeTitlePath)
                break;
            case LanguageEnum.english:
                this.getOverlayWcsContent(englishKey, hasCancelationButton, changeTitlePath)
                break;
        }
    }

    setReplicaModeButtonText(): void {
        switch (this.topupService.topupLanguage) {
            case LanguageEnum.spanish:
                this.translate.get('payment.buttonList.closeButton.text').subscribe(data => {
                    this.confirmationButtonText = data;
                });
                break;
            case LanguageEnum.english:
                this.translate.get('payment.buttonList.closeButton_en.text').subscribe(data => {
                    this.confirmationButtonText = data;
                });
                break;
        }
    }

    getOverlayWcsContent(key: string, hasCancelationButton: boolean = false, changeTitlePath: boolean = false): void {
        this.translate.get(key).subscribe(data => {
            let titleKey: string = '';
            let descriptionKey: string = '';
            let confirmationBtnKey: string = '';
            let cancelationBtnKey: string = '';
            Object.keys(data).forEach(key => {
                if (key.indexOf('title') !== -1) {
                    titleKey = key;
                }
                if (changeTitlePath && key.indexOf('subtitle') !== -1) {
                    titleKey = key;
                }
                if (key.indexOf('description') !== -1) {
                    descriptionKey = key;
                }
                if (key.indexOf('confirmButton') !== -1 || key.indexOf('button1') !== -1) {
                    confirmationBtnKey = key;
                }
                if (key.indexOf('cancelButton') !== -1 || key.indexOf('button2') !== -1) {
                    cancelationBtnKey = key;
                }
            })
            if (data[descriptionKey]) {
                this.setErrorDescription(data[descriptionKey]);
            }
            this.title = data[titleKey];
            this.confirmationButtonText = confirmationBtnKey ? data[confirmationBtnKey].text : '';
            this.cancelationButttonText = hasCancelationButton ? (cancelationBtnKey ? data[cancelationBtnKey].text : '') : '';
            if (changeTitlePath) {
                this.trayTitle = data.title;
            }
        });
    }

    resetConfigurations(): void {
        this.showLottie = false;
        this.showTwoCtas = false;
        this.showXButton = false;
        this.descriptionLine1 = '';
        this.descriptionLine2 = '';
        this.confirmationButtonText = '';
        this.cancelationButttonText = '';
        this.title = '';
        this.iconUrl = '';
        this.lottieFile = '';
        this.trayTitle = '';

    }

    returnToTopupIframePage(page: TopupPrepaidStages): void {
        this.closeTray(true);
        setTimeout(() => {
            this.topupService.topupNavigationSubject.next({ page: page, isFirstTimeOpen: true });
            this.trayService.showBackwardTransition = false;
            this.trayService.showForwardTransition = false;
        }, 1000);
    }
    /**
     *function is called from error overlay to restart the payment module
     *
     * @param {*} startBillPaymentJourny
     * @memberof TopupPrepaidErrorOVerlayDataService
     */
    restartBillPaymentFlow(startBillPaymentJourny): void {
        this.closeTray(true);
        setTimeout(() => {
            startBillPaymentJourny();
        }, 1000);
    }

    closeTray(isColseAndReopen: boolean = false): void {
        this.isIframeCanceled = false;
        this.trayService.close();
        this.dynamicTrayService.closeTraySubject.next();
        setTimeout(() => {
            this.topupService.dynamicTrayData.isGradientBackground = false;
        }, 1000);
        this.topupService.msisdn = '';
        if (this.topupService.isAnonymousProcess && !isColseAndReopen) {
            this.router.navigate([config.login.route]);
        }
    }

    setErrorDescription(description: string): void {
        if (description.indexOf('<br') !== -1) {
            const descSplittedLine1: string[] = description.split('<');
            const descSplittedLine2: string[] = description.split('>');
            this.descriptionLine1 = descSplittedLine1[0];
            this.descriptionLine2 = descSplittedLine2[descSplittedLine2.length - 1];
        } else {
            this.descriptionLine1 = description;
        }
    }
    // pci tagging journies
    sendTaggingInteraction(taggingObj): void {
        this.tagging.track(taggingObj.eventName,
            taggingObj.data,
            tagging.pciPrepaidPages.koPage, this.storageService.topupPrepaid)
    }
    sendTaggingStateArchetype(stateList: string): void {
        this.newTagging.getJsonTagging(FOLDER_FINANCED_DEVICES).subscribe((data: NewTaggingJsonModel) => {
            const state: NewTaggingStateListModel = data.page.stateList[stateList];
            state.custom_map['product_brand'] = this.billService.selectedFundedDevice?.brand;
            state.custom_map['product_model'] = this.billService.selectedFundedDevice?.model;
            this.newTagging.state(state);
        });
    }

    sendKoTaggingJourney(category): void {
        const service: string = this.topupService.msisdn ? this.topupService.msisdn :
            (this.topupService.newPciSelectedService ? this.topupService.newPciSelectedService.id : '');

        tagging.pciPrepaid.journys.error.data.journey_options = this.topupService.curruentSelectedAmountSelectorValue.toString();
        this.setTaggingErrorParams(category);
        tagging.pciPrepaid.journys.error.data.journey_subcategory = this.topupService.setTaggingJournySubCategoryValue();

        if (service) {
            this.tagging.encryptApi(service).subscribe(data => {
                tagging.pciPrepaid.journys.error.data.mobile_recharge = data;
                this.tagging.track(tagging.pciPrepaid.journys.error.eventName,
                    tagging.pciPrepaid.journys.error.data,
                    tagging.pciPrepaidPages.koPage, this.storageService.topupPrepaid);
            });
        } else {
            this.tagging.track(tagging.pciPrepaid.journys.error.eventName,
                tagging.pciPrepaid.journys.error.data,
                tagging.pciPrepaidPages.koPage, this.storageService.topupPrepaid);
        }
        this.resetTaggingParams();

    }

    resetTaggingParams(): void {
        this.errorDesc = '';
        this.errorCode = '';
    }
    /**
     * check for empty values for ecode and edesc to replace them with static values
     * @param staticECode {string} static value for ecode
     * @param staticEDesc {string} static value for edesc
     */
    checkEmptyValuesForECodeOrEDesc(staticECode: string, staticEDesc: string): void {
        if (!this.errorCode) {
            this.errorCode = staticECode;
        }
        if (!this.errorDesc) {
            this.errorDesc = staticEDesc;
        }
    }
    setTaggingErrorParams(category: string): void {
        const taggingObjKey: string = Object.keys(TopupPrepaidError).find(value => TopupPrepaidError[value] === category);
        tagging.pciPrepaid.journys.error.data.journey_error_category = tagging.staticVariables.pciPrepaidErrorCategories[taggingObjKey];
        tagging.pciPrepaid.journys.error.data.journey_error_code = this.errorCode;
        tagging.pciPrepaid.journys.error.data.journey_error_description = tagging.staticVariables.pciPrepaidErrorDescription[taggingObjKey];
    }

}

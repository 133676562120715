import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdaraMainComponent } from './adara-main/adara-main.component';

import { AdaraRoutingModule } from './adara-routing.module';

import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { CoreModule, GenericModule, OverlayFullScreenModule, OverlayModule } from '@mva10/mva10-angular';


@NgModule({
  declarations: [AdaraMainComponent],
  imports: [
    CommonModule,
    AdaraRoutingModule,
    TranslateModule,
    SharedModule,
    GenericModule,
    CoreModule,
    OverlayFullScreenModule,
    OverlayModule
  ]
})
export class AdaraModule { }

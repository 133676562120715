<div class="campaign">
  <div class="modal-dialog">
    <div class="modal-header">
      <div class="close-icon">
        <button id="SummaryComp_btn_close" type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
          <span class='icon icon-close modal-full-close'></span>

        </button>

      </div>
    </div>

    <div class="modal-body">

      <div class="head-title">
        <p>{{campaign?.title}}</p>
      </div>
      <div class="text-center">
        <img [src]="appService.getImgFullPath(WCS?.icon?.url)" alt="" class="notifications-hi">
      </div>
      <div class="description" [innerHtml]='campaign?.description'>
      </div>
      <div *ngFor="let permission of campaign?.modules?.permissions" class="card">
        <div class="card-header">
          <p> {{permission?.status ? WCS?.accept : WCS?.noAccept }} </p>
          <sp-toggle-button (option)="toggle($event,permission)" [disable]="false" [check]="permission?.status" class="toggel toggle"></sp-toggle-button>
        </div>
        <div class="card-body" [innerHTML]='permission?.description'></div>
      </div>
      <div class="choose-btn row">
        <div class="col-xs-12 center">
          <button id="SummaryComp_btn_submitBtn1" [disabled]="!changeCTAStyle()" type="button" class="btn btn-default continue" (click)="onGrantPermissions()">{{ campaign?.blocker ? WCS?.continue?.text : WCS?.confirmButton?.text }}</button>
          <button id="SummaryComp_btn_gray" type="button" *ngIf="!campaign?.blocker" (click)="continue()" class="button gray two fix-margin">{{WCS.cancelButton.text}}</button>
        </div>
      </div>
    </div>
  </div>
  <sp-modal #campaignModal></sp-modal>
</div>
import { TranslateModule } from '@ngx-translate/core';
import { SpRadioButtonAdvancedComponent } from './sp-radio-button-advanced.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SpRadioButtonAdvancedComponent],
  exports: [SpRadioButtonAdvancedComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class SpRadioButtonAdvancedModule {}

import { Injectable } from '@angular/core';
import * as JsonQuery from 'jsonpath/jsonpath';
import { CtcOverlayModel } from '../../models/ctc-overlay.model';
import { JSON_PATHS, lightMode, darkMode, SidemenuQuery } from '../constants/defines';
import { UtilitiesService } from '../utils/utilities.service';
import { DmpModel, NexusDmpModel, NexusRecoDmpModel } from './../../models/dmp.model';
@Injectable()
export class DmpService {

  private window: any = window;

  constructor(
    private utilsService: UtilitiesService
  ) {
    this.window._personalizacion = {};
    this.window._personalizacion = {
      dashboardBanner1: {
        resolve: null,
        reject: null,
        promise: null
      },
      dashboardBanner2: {
        resolve: null,
        reject: null,
        promise: null
      },
      tiendaBanner1: {
        resolve: null,
        reject: null,
        promise: null
      },
      tiendaBanner2: {
        resolve: null,
        reject: null,
        promise: null
      },
      tiendaBanner3: {
        resolve: null,
        reject: null,
        promise: null
      },
      nexusBanner: {
        resolve: null,
        reject: null,
        promise: null
      },
      newLinesPurchaseSticky: {
        resolve: null,
        reject: null,
        promise: null
      },
      dashboardDescubre: {
        resolve: null,
        reject: null,
        promise: null
      },
      s4yBanner1: {
        resolve: null,
        reject: null,
        promise: null
      },
      s4yCarrusel1: {
        resolve: null,
        reject: null,
        promise: null
      },
      s4yListado1: {
        resolve: null,
        reject: null,
        promise: null
      },
      s4ylistadoBeneficios: {
        resolve: null,
        reject: null,
        promise: null
      },
      lineasAdicionalesHome: {
        resolve: null,
        reject: null,
        promise: null
      },
      flujoConvergencia: {
        resolve: null,
        reject: null,
        promise: null
      },
    };
    this.initPromiseDmp();
  }

  get personalizacion() {
    return this.window._personalizacion;
  }

  initPromiseDmp() {
    this.window._personalizacion.dashboardBanner1.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.dashboardBanner1.resolve = resolve;
      this.window._personalizacion.dashboardBanner1.reject = reject;
    });
    this.window._personalizacion.dashboardBanner2.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.dashboardBanner2.resolve = resolve;
      this.window._personalizacion.dashboardBanner2.reject = reject;
    });
  }

  public initNewLinesPurchaseSticky(): void {
    this.window._personalizacion.newLinesPurchaseSticky.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.newLinesPurchaseSticky.resolve = resolve;
      this.window._personalizacion.newLinesPurchaseSticky.reject = reject;
    });
  }

  public initDashboardDiscovery(): void {
    this.window._personalizacion.dashboardDescubre.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.dashboardDescubre.resolve = resolve;
      this.window._personalizacion.dashboardDescubre.reject = reject;
    });
  }

  public initLineasAdicionalesHome(): void {
    this.window._personalizacion.lineasAdicionalesHome.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.lineasAdicionalesHome.resolve = resolve;
      this.window._personalizacion.lineasAdicionalesHome.reject = reject;
    });
  }

  initPromiseShopBanner() {
    this.window._personalizacion.tiendaBanner1.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.tiendaBanner1.resolve = resolve;
      this.window._personalizacion.tiendaBanner1.reject = reject;
    });
    this.window._personalizacion.tiendaBanner2.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.tiendaBanner2.resolve = resolve;
      this.window._personalizacion.tiendaBanner2.reject = reject;
    });
    this.window._personalizacion.tiendaBanner3.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.tiendaBanner3.resolve = resolve;
      this.window._personalizacion.tiendaBanner3.reject = reject;
    });
  }

  initPromisesS4Y(): void {
    this.window._personalizacion.s4yBanner1.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.s4yBanner1.resolve = resolve;
      this.window._personalizacion.s4yBanner1.reject = reject;
    });
    this.window._personalizacion.s4yCarrusel1.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.s4yCarrusel1.resolve = resolve;
      this.window._personalizacion.s4yCarrusel1.reject = reject;
    });
    this.window._personalizacion.s4yListado1.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.s4yListado1.resolve = resolve;
      this.window._personalizacion.s4yListado1.reject = reject;
    });
    this.window._personalizacion.s4ylistadoBeneficios.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.s4ylistadoBeneficios.resolve = resolve;
      this.window._personalizacion.s4ylistadoBeneficios.reject = reject;
    });
  }

  public initPromisesBundle(): void {
    this.window._personalizacion.flujoConvergencia.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.flujoConvergencia.resolve = resolve;
      this.window._personalizacion.flujoConvergencia.reject = reject;
    });
  }

  /**
   * @param res JSON from DMP
   */
  public dmpContent(res: any): DmpModel {
    const model = new DmpModel();
    const animation = JsonQuery.value(res, JSON_PATHS.Dmp.animation);
    model.animation = animation ? JSON.parse(animation) : '';
    model.bgColor = JsonQuery.value(res, JSON_PATHS.Dmp.bgColor) || '';
    model.buttonType = JsonQuery.value(res, JSON_PATHS.Dmp.buttonType) || '';
    const controlGroup: string = JsonQuery.value(res, JSON_PATHS.Dmp.controlGroup);
    model.controlGroup = controlGroup ? JSON.parse(controlGroup) : false;
    model.CTAbgColor = JsonQuery.value(res, JSON_PATHS.Dmp.CTAbgColor) || '';
    model.CTAtextColor = JsonQuery.value(res, JSON_PATHS.Dmp.CTAtextColor) || '';
    model.deeplink = JsonQuery.value(res, JSON_PATHS.Dmp.deeplink);
    const design = JsonQuery.value(res, JSON_PATHS.Dmp.design) || '';
    model.design = design.toLowerCase();
    model.entryPointTitle = JsonQuery.value(res, JSON_PATHS.Dmp.entryPointTitle) || '';
    model.entryPointLocation = JsonQuery.value(res, JSON_PATHS.Dmp.entryPointLocation) || '';
    model.experienceName = JsonQuery.value(res, JSON_PATHS.Dmp.experienceName) || '';
    model.gradient = JsonQuery.value(res, JSON_PATHS.Dmp.gradient) || '';
    model.image = JsonQuery.value(res, JSON_PATHS.Dmp.image);
    model.image_rsp = JsonQuery.value(res, JSON_PATHS.Dmp.image_rsp);
    model.imageTitle = JsonQuery.value(res, JSON_PATHS.Dmp.imageTitle);
    model.imageTitle_rsp = JsonQuery.value(res, JSON_PATHS.Dmp.imageTitle_rsp);
    model.journeyName = JsonQuery.value(res, JSON_PATHS.Dmp.journeyName) || '';
    model.nameCTA = JsonQuery.value(res, JSON_PATHS.Dmp.nameCTA) || '';
    const padding: string = JsonQuery.value(res, JSON_PATHS.Dmp.padding);
    model.padding = padding ? JSON.parse(padding) : false;
    model.promo = JsonQuery.value(res, JSON_PATHS.Dmp.promo) || '';
    model.text = JsonQuery.value(res, JSON_PATHS.Dmp.text) || '';
    model.textCTA = JsonQuery.value(res, JSON_PATHS.Dmp.textCTA) || '';
    model.title = JsonQuery.value(res, JSON_PATHS.Dmp.title) || '';
    model.webviewlink = JsonQuery.value(res, JSON_PATHS.Dmp.webviewlink);
    model.imgRight = JsonQuery.value(res, JSON_PATHS.Dmp.image_right) || '';
    model.imgRightRsp = JsonQuery.value(res, JSON_PATHS.Dmp.image_right_rsp) || '';
    model.paddingImg = JsonQuery.value(res, JSON_PATHS.Dmp.padding_image_right) || false;
    model.darkTextFlag = (JsonQuery.value(res, JSON_PATHS.Dmp.mode) || darkMode) === lightMode;

    if (JsonQuery.value(res, JSON_PATHS.Dmp.ctcNum)) {
      model.ctc = new CtcOverlayModel();
      model.ctc.number = JsonQuery.value(res, JSON_PATHS.Dmp.ctcNum);
      model.ctc.text = JsonQuery.value(res, JSON_PATHS.Dmp.ctcText);
      model.ctc.buttonText = JsonQuery.value(res, JSON_PATHS.Dmp.ctcTextCTA);
      model.ctc.title = JsonQuery.value(res, JSON_PATHS.Dmp.ctcTitle);
    }

    return model;
  }

  /**
   * @param res Array JSON from DMP
   */
  public arrayDmpContent(res: Array<{ [key: string]: string }>): Array<DmpModel> {

    const arrayDmpModel: DmpModel[] = [];
    res.forEach(element => {
      arrayDmpModel.push(this.dmpContent(element));
    });

    return arrayDmpModel;
  }

  public initPromisesNexusBanner(): void {
    this.window._personalizacion.nexusBanner.promise = new Promise((resolve, reject) => {
      this.window._personalizacion.nexusBanner.resolve = resolve;
      this.window._personalizacion.nexusBanner.reject = reject;
    });
  }

  public arrayNexusDmpContent(res: Array<NexusRecoDmpModel>, experienceName: string): Array<NexusDmpModel> {

    const arrayNexusDmpModel: NexusDmpModel[] = [];
    res.forEach(element => {
      if (element.recomendations.length > 1) {
        this.utilsService.sortByKeyReverse(element.recomendations, SidemenuQuery.order)
      }
      arrayNexusDmpModel.push(this.DmpNexusContent(element.code, element.recomendations[0], experienceName));
    });

    return arrayNexusDmpModel;
  }

  public DmpNexusContent(code: string, res: NexusDmpModel, experienceName: string): NexusDmpModel {
    const model: NexusDmpModel = new NexusDmpModel();
    model.code = code;
    model.cardTitle = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardTitle);
    model.cardDesc = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardDesc);
    model.cardImgDesktop = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardImgDesktop);
    model.cardImgMobile = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardImgMobile);
    model.cardBackgroundImgDesktop = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardBackgroundImgDesktop);
    model.cardBackgroundImgMobile = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardBackgroundImgMobile);
    model.cardBackgroundColour = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardBackgroundColour);
    model.cardBtnText = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardBtnText);
    model.cardBtnColour = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardBtnColour);
    model.cardBtnBackground = JsonQuery.value(res, JSON_PATHS.nexusDmp.cardBtnBackground);
    model.deeplink = JsonQuery.value(res, JSON_PATHS.nexusDmp.deeplink);
    model.order = JsonQuery.value(res, JSON_PATHS.nexusDmp.order);
    model.controlGroup = JsonQuery.value(res, JSON_PATHS.nexusDmp.controlGroup);
    model.journeyName = JsonQuery.value(res, JSON_PATHS.nexusDmp.journeyName);
    model.experienceName = experienceName;
    return model;
  }

}

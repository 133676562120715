import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartpayLandingComponent } from './smartpay-landing.component';
import { SmartpayLandingRoutingModule } from './smartpay-landing-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule, GenericModule,  } from '@mva10/mva10-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [ SmartpayLandingComponent ],
  imports: [
    CommonModule,
    SmartpayLandingRoutingModule,
    TranslateModule,
    CoreModule,
    GenericModule,
    AngularSvgIconModule,
    SharedModule
  ]
})
export class SmartpayLandingModule { }

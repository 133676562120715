import { ServiceType } from '../../enums/serviceType.enum';


export class Slide {
    public index: number;
    public active: boolean;
    public checked = false;
    public selected = '';
    public radioDisabled = true;
    public icon: string;
    public type?: ServiceType;
    public text?: string;
    id?: string;
    public addPlusClass?: boolean;
    public svgPath?: string;
}

import {map, catchError,  mergeMap } from 'rxjs/operators';
import { CheckoutCommercialService } from './checkout-commercial.service';
import { Injectable } from '@angular/core';
import { API_URLS } from '../constants/routes-config';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { StorageService } from '../../core/services/storage.service';
import {
  JSON_PATHS, CommercialJourneys, PAGES, commercialMobile, LOCAL_STORAGE_KEYS, serviceabilityListResult
} from '../constants/defines';
import { CommercialOffer, CommercialOfferPega, PegaModel, CommercialTagg } from '../../models/commercial-offer.model';
import { RequestConfig } from '../../models/request-config.model';
import * as JsonQuery from 'jsonpath/jsonpath';
import { PromoCommercial } from '../../models/promo-commercial.model';
import { CustomerAccountService } from './customer-account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../../config/pages-config';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerType } from '../enums/customerType.enum';
import { Serviciability } from '../../models/serviciability.model';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { ContractedProducts } from '../../models/contractedProducts.model';
import { FederationErrorService } from './federation-error.service';
import { CommercialPersonalData } from '../../models/commercial-personal-data.model';
import { CustomerAccount } from '../../models/customer-account.model';
import { EntryPointModel } from '../../models/entry-points.model';
import { InfoPromoPega } from '../../models/info-promo-pega.model';
import { ServiceType } from '../enums/serviceType.enum';
import { UtilitiesService } from '../utils/utilities.service';
import { ClientInfoOffers } from '../../models/client-info-offers.model';
import { environment } from '../../../environments/environment';
import { HeadersDataOptions } from '../../shared/models/dxl-header-data-options.model';
import { CommercialOptions } from '../../models/commercial-list-terminals.model';
import { JourneyTaggModel } from '../../models/tagging.model';
import { ResponseOffer, PostServiciability, GetValidatePortability } from '../../mobile-to-pack/shared/models/services-response.model';

@Injectable()
export class CommercialOffersService {
  public offers: CommercialOffer[];
  private offersSubject: BehaviorSubject<CommercialOffer[]> = new BehaviorSubject([]);
  public offers$: Observable<CommercialOffer[]> = this.offersSubject.asObservable();

  public promos: PromoCommercial[];
  private promosSubject: BehaviorSubject<PromoCommercial[]> = new BehaviorSubject([]);
  public promos$: Observable<PromoCommercial[]> = this.promosSubject.asObservable();

  public serviciability: Serviciability;
  private serviciabilitySubject: BehaviorSubject<{}> = new BehaviorSubject({});
  public serviciability$: Observable<{}> = this.serviciabilitySubject.asObservable();

  public contractedProducts: ContractedProducts;
  private contractedProductsSubject: BehaviorSubject<{}> = new BehaviorSubject({});
  public contractedProducts$: Observable<{}> = this.contractedProductsSubject.asObservable();

  public clientTypeParam: string = '';
  private clientTypeSubject: BehaviorSubject<string> = new BehaviorSubject(this.clientTypeParam);
  public clientType$: Observable<string> = this.clientTypeSubject.asObservable();

  public shopTypeParam: string = '';
  private shopTypeSubject: BehaviorSubject<string> = new BehaviorSubject(this.shopTypeParam);
  public shopType$: Observable<string> = this.shopTypeSubject.asObservable();

  public sceneTypeParam: string = '';
  private sceneTypeSubject: BehaviorSubject<string> = new BehaviorSubject(this.sceneTypeParam);
  public sceneType$: Observable<string> = this.sceneTypeSubject.asObservable();

  public infoPromoPega: InfoPromoPega;
  private infoPromoPegaSubject: BehaviorSubject<{}> = new BehaviorSubject([]);
  public infoPromoPega$: Observable<{}> = this.infoPromoPegaSubject.asObservable();

  public clientInfo: ClientInfoOffers;
  private clientInfoSubject: BehaviorSubject<{}> = new BehaviorSubject([]);
  public clientInfo$: Observable<{}> = this.clientInfoSubject.asObservable();

  public errorPreScoring: boolean = false;

  private mainFlow: string;
  public currentOfferIsNotMainFlow: boolean = false;
  public clientType: string;
  public offerId: string;

  statusError: string;
  name: string = null;
  familyName: string = null;
  journeyTagg: any = null;
  history: CommercialTagg[] = [];
  backFromStep: boolean[] = new Array<boolean>();
  origin: string = PAGES.DASHBORD;
  stepTitles: string[];
  origen: string = PAGES.DASHBORD;
  isM2P: boolean;
  isME: boolean;
  federror: HttpErrorResponse;
  isUniqueOffer: boolean;
  uniqueOfferId: string;
  isLimitedTarif: boolean;
  /* offerId value to shopping cart item. */
  opOfferIds: {[key: string]: {[key: string]: number}} = {};
  public dataUserLine: CommercialPersonalData;
  public products: string;
  public isMicroResegmented: boolean;

  public temporalPromotions: PromoCommercial[];
  public offerPega: CommercialOfferPega;
  public offerPegaExist: boolean = false;
  public resPega: PegaModel;

  public ntolTxID: string;

  constructor(
    public http: HttpClient,
    public storageService: StorageService,
    private customerAccountService: CustomerAccountService,
    private subscriptionData: SubscriptionService,
    private translate: TranslateService,
    private router: Router,
    private cookieService: CookieService,
    private fedErrorService: FederationErrorService,
    private checkoutCommercialService: CheckoutCommercialService,
    private readonly utilities: UtilitiesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isMicroResegmented = this.utilities.isConsumerAndHasMicro();
  }

  public setJourney(journey: string): void {
    const wcsM2PLink: string[] = this.translate.instant('v10.flows.customization.MPON19.link_web').split('=');
    this.isM2P = wcsM2PLink.length > 1 ? journey === wcsM2PLink[1] : journey === CommercialJourneys.journey.M2P;
    if (!this.mainFlow) {
      this.mainFlow = journey;
    }
    this.currentOfferIsNotMainFlow = this.mainFlow !== undefined && this.mainFlow !== journey;
  }

  public setME(clientType: string): void {
    const config: RequestConfig = this.translate.instant('v10.commercial.request_config');
    if (clientType === CustomerType.Consumer.toLowerCase()) {
      this.clientTypeParam = config.clientType_Partis;
      this.shopTypeParam = config.shopType_Partis;
      this.clientTypeSubject.next(this.clientTypeParam);
      this.shopTypeSubject.next(this.shopTypeParam);
      this.sceneTypeParam = config.sceneType_Fide;
      this.sceneTypeSubject.next(this.sceneTypeParam);
      this.isME = false;
    } else {
      this.clientTypeParam = config.clientType_Micro;
      this.shopTypeParam = config.shopType_Micro;
      this.clientTypeSubject.next(this.clientTypeParam);
      this.shopTypeSubject.next(this.shopTypeParam);
      this.sceneTypeParam = config.sceneType_Fide;
      this.sceneTypeSubject.next(this.sceneTypeParam);
      this.isME = true;
    }
  }

  public getOffers(journey?: string, isPega: boolean = false): Observable<ResponseOffer> {

    const url: string = this.getOffersURI(journey, isPega);
    const options: HeadersDataOptions = this.headersData();
    return this.http.get(url, options).pipe(map((res: ResponseOffer) => {
      if (typeof res.infoPromoPega !== 'undefined') {
        this.resPega = {
          infoPromoPega : res.infoPromoPega,
          offers: res.offer

        } ;
         this.offerPegaLogic(this.resPega);
      }
      this.setErrorEcodes(res);
      if (!this.errorPreScoring) {
        this.promosLogic(res);
        this.offersLogic(res);
        this.infoPromoPegaLogic(res);
        this.getClientInfoOffers(res);
        return res;
      }
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public setErrorEcodes(res: ResponseOffer): void {
    if (res.offer) {
      this.errorPreScoring = false;
      this.offersLogic(res);
      this.promosLogic(res);
      this.infoPromoPegaLogic(res);
      this.getClientInfoOffers(res);
      this.offersSubject.next(this.offers);
      this.promosSubject.next(this.promos);
      this.infoPromoPegaSubject.next(this.infoPromoPega);
      this.clientInfoSubject.next(this.clientInfo);
    } else {
      this.errorPreScoring = this.errorPreScoringCompareRes(res);
    }
  }

  public errorPreScoringCompareRes(res: ResponseOffer): boolean {
    return res && res.ecode &&
      ((res.ecode === commercialMobile.errorCodes.preScoring) || (res.ecode === commercialMobile.errorCodes.preScoring4));
  }

  public addQueryParams( url: string): string {
    url = this.compareUniqueOffer(url);
    url = this.compareQueryParamMapIsPega(url);
    return url;
  }

  public compareUniqueOffer(url: string): string {
    if ((this.isUniqueOffer) || (this.activatedRoute.snapshot.queryParamMap.get('rateCode'))) {
      url = this.activatedRoute?.snapshot?.queryParamMap.get('rateCode') ?  `${url}&rateCode=${this.activatedRoute.snapshot.queryParamMap.get('rateCode') }` : `${url}&rateCode=${this.uniqueOfferId}` ;
    }
    return url;
  }

  public compareQueryParamMapIsPega(url: string): string {
    if (this.activatedRoute?.snapshot?.queryParamMap.get('isPega')) {
      url = `${url}&isPega=${this.activatedRoute?.snapshot?.queryParamMap.get('isPega')}`;
    }
    return url;
  }



  public compareCommercialjourneysM2P(url: string, configRequest: RequestConfig): string {
    url += serviceabilityListResult.param;
    return url
      .replace('{siteId}', this.subscriptionData.customerData.currentService.siteId)
      .replace('{coverage}', this.serviciability?.serviceabilityListResult[0].technology)
      .replace('{internet}', this.serviciability?.serviceabilityListResult[0].maxSpeed)
      .replace('{registerType}', configRequest.registerType_MovilToPack)
      .replace('{speed5g}', this.serviciability?.serviceabilityListResult[0].speed5g ?
        this.serviciability?.serviceabilityListResult[0].speed5g.toString() : '0')
      .replace('{coverage5g}', this.serviciability?.serviceabilityListResult[0].coverage5g ?
        this.serviciability?.serviceabilityListResult[0].coverage5g.toString() : false.toString());
  }

  public urlReplaceSap(url: string): string {
    const sapVal: string = this.isME || this.isMicroResegmented
                            ? CommercialJourneys.sapME
                            : CommercialJourneys.sap;
    let urlRep: string = url;
    urlRep = urlRep.replace('{sap}', sapVal);
    return urlRep;
  }
  public typeCommercialJourneys(journey: string, config: RequestConfig, isPega: boolean): string {
    let url: string = '';
    if (journey === CommercialJourneys.journey.AL) {
      url += CommercialJourneys.offersRequest.AL.params;
      url = this.urlReplaceSap(url)
        .replace('{onlyAdditionalLine}', CommercialJourneys.onlyAdditionalLine)
        .replace('{siteId}', this.subscriptionData.customerData.currentService.siteId);
       url = this.addQueryParams(url);
    }
    else if ((journey === CommercialJourneys.journey.M2P) || (journey === CommercialJourneys.journey.BUNDLELUPSELL) ||
      (journey === CommercialJourneys.oneConect )) {
      url += CommercialJourneys.offersRequest.M2P.params;
      url = this.compareCommercialjourneysM2P(url, config);
      url = (isPega) ? url + '&isPega=true' : url;
    }
    else if (journey === CommercialJourneys.journey.sndResidences) {
      url += CommercialJourneys.offersRequest.sndResidences.params;
      url = this.urlReplaceSap(url)
        .replace('{registerType}', CommercialJourneys.registerType)
        .replace('{siteId}', this.subscriptionData.customerData.currentService.siteId)
        .replace('{rateCode}', this.translate.instant('v10.commercial.secondResidences.rate_code'));
    }
    else if (journey === CommercialJourneys.journey.OPADD) {
      url += CommercialJourneys.offersRequest.OPADD.params;
      url = this.urlReplaceSap(url);
      url = url  .replace('{siteId}', this.subscriptionData.customerData.currentService.siteId);
    }
    else if (journey === CommercialJourneys.journey.OPUPG) {
      url += CommercialJourneys.offersRequest.OPUPG.params;
      url = this.urlReplaceSap(url)
        .replace('{siteId}', this.subscriptionData.customerData.currentService.siteId)
        .replace('{oneplusPackage}', this.subscriptionData.customerData.currentService.package.code);
    }
    return url;
  }

  public getOffersURI(journey: string, isPega: boolean): string {
    let url: string = API_URLS.Offers.getOffers + CommercialJourneys.offersRequest.common.params;
    const customerType: string = this.storageService.userProfile.customerType.toLowerCase();
    const config: RequestConfig = this.translate.instant('v10.commercial.request_config');
    url = url
        .replace('{clientType}', this.isMicroResegmented ? config.clientType_Micro :
          customerType === CustomerType.Consumer.toLowerCase() ? config.clientType_Partis : config.clientType_Micro)
        .replace('{shopType}', this.isMicroResegmented ? config.shopType_Micro :
          customerType === CustomerType.Consumer.toLowerCase() ? config.shopType_Partis : config.shopType_Micro)
        .replace('{sceneType}', config.sceneType_Fide)
        .replace('{contractType}', config.contractType_Postpaid);
    url = this.replaceRateType(url, journey, config);
    this.setME(customerType);

    return url + this.typeCommercialJourneys(journey, this.translate.instant('v10.commercial.request_config'), isPega);
  }

  public replaceRateType(UrlReplace: string, journey: string, configRequest: RequestConfig): string {
    if (journey === CommercialJourneys.journey.AL
      || journey === CommercialJourneys.journey.OPADD
      || journey === CommercialJourneys.journey.sndResidences) {
        UrlReplace = UrlReplace.replace('{rateType}', configRequest.rateType_Mobile);
    } else if (journey === CommercialJourneys.journey.M2P) {
      UrlReplace = UrlReplace.replace('{rateType}', configRequest.rateType_One);
    } else if (journey === CommercialJourneys.journey.BUNDLELUPSELL || journey === CommercialJourneys.journey.OPUPG) {
      UrlReplace = UrlReplace.replace('{rateType}', configRequest.rateType_Upgrade);
    } else if (journey === CommercialJourneys.oneConect) {
      UrlReplace = UrlReplace.replace('{rateType}', configRequest.rateType_One_Conecta);
    }
    UrlReplace = UrlReplace.replace('{rateType}', null);
    return UrlReplace;
  }

  public offersLogic(offer: ResponseOffer): void {
    this.offers = new Array<CommercialOffer>();
    const offs: CommercialOffer[] = JsonQuery.value(offer, JSON_PATHS.CommercialOffers.offs) || null;
    offs?.forEach(off => {
      const offerCommercial: CommercialOffer = new CommercialOffer();
      this.offersLogicMapPrincipal(off, offerCommercial);
      this.offersLogicMapinstallment(off, offerCommercial);
      this.offersLogicMapDatas(off, offerCommercial);
      this.offersLogicMapCalls(off, offerCommercial);
      this.offersLogicMapInternet(off, offerCommercial);
      this.offersLogicMapShow(off, offerCommercial);
      this.offersLogicMapconfig(off, offerCommercial);
      this.offers.push(offerCommercial);
    });
  }

  public offersLogicMapconfig(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.configurable = JsonQuery.value(off, JSON_PATHS.CommercialOffers.configurable) || false;
    offerCommercial.device = JsonQuery.value(off, JSON_PATHS.CommercialOffers.device) || null;
    offerCommercial.deviceUpfrontTaxesPromo = JsonQuery.value(off, JSON_PATHS.CommercialOffers.deviceUpfrontTaxesPromo) || 0;
    offerCommercial.otherList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.otherList) || null;
    offerCommercial.otherDetailList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.otherDetailList) || null;
    offerCommercial.image = JsonQuery.value(off, JSON_PATHS.CommercialOffers.image) || null;
    offerCommercial.permanenceDuration = JsonQuery.value(off, JSON_PATHS.CommercialOffers.permanenceDuration) || null;
    offerCommercial.promotions = JsonQuery.value(off, JSON_PATHS.CommercialOffers.promotions) as string[] || null;
    offerCommercial.nameList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.nameList) || null;
    offerCommercial.additionalLine = JsonQuery.value(off, JSON_PATHS.CommercialOffers.additionalLine) || null;
    offerCommercial.recommendedUses = JsonQuery.value(off, JSON_PATHS.CommercialOffers.recommendedList) as string[] || null;
    offerCommercial.transaccional = JsonQuery.value(off, JSON_PATHS.CommercialOffers.transaccional);
    offerCommercial.unsubscribeDiscounts = JsonQuery.value(off, JSON_PATHS.CommercialOffers.unsubscribeDiscounts);
  }

  public offersLogicMapinstallment(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.installment = this.formatPrices(JsonQuery.value(off, JSON_PATHS.CommercialOffers.installment));
    offerCommercial.installmentTaxes = this.formatPrices(JsonQuery.value(off, JSON_PATHS.CommercialOffers.installmentTaxes));
    offerCommercial.installmentPromo = this.formatPrices(JsonQuery.value(off, JSON_PATHS.CommercialOffers.installmentPromo));
    offerCommercial.installmentPromoTaxes = this.formatPrices(JsonQuery.value(off, JSON_PATHS.CommercialOffers.installmentPromoTaxes));
    if (offerCommercial && offerCommercial.installmentPromoTaxes && offerCommercial.installmentPromoTaxes.slice(-2) === '00') {
      offerCommercial.installmentPromoTaxes = offerCommercial.installmentPromoTaxes.replace(',00', '');
    }
    if (offerCommercial && offerCommercial.installmentTaxes && offerCommercial.installmentTaxes.slice(-2) === '00') {
      offerCommercial.installmentTaxes = offerCommercial.installmentTaxes.replace(',00', '');
    }
  }

  public offersLogicMapPrincipal(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.offerId = (off.offerId) ? off.offerId : null;
    offerCommercial.packageId = (off.packageId) ? off.packageId : null;
    offerCommercial.disabled = JsonQuery.value(off, JSON_PATHS.CommercialOffers.disabled);
    offerCommercial.registerType = off.registerType;
    offerCommercial.code = JsonQuery.value(off, JSON_PATHS.CommercialOffers.code) || null;
    offerCommercial.name = JsonQuery.value(off, JSON_PATHS.CommercialOffers.name) || null;
    offerCommercial.rate = JsonQuery.value(off, JSON_PATHS.CommercialOffers.rate) || null;
  }

  public offersLogicMapDatas(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.dataIncludedList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataIncludedList) || null;
    offerCommercial.dataList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataList) || null;
    offerCommercial.dataIncludedDetailList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataIncludedDetailList) || null;
    offerCommercial.dataIncludedSecondList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataIncludedSecondList) || null;
    offerCommercial.dataSpeedList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataSpeedList) || null;
    offerCommercial.dataDetailList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataDetailList) || null;
    offerCommercial.dataAddLineDefNewRegi = JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataAddLineDefNewRegi) as string || null;
    offerCommercial.dataAddLineDefNewRegiOne =
      JsonQuery.value(off, JSON_PATHS.CommercialOffers.dataAddLineDefNewRegiOne) as string || null;
  }

  public offersLogicMapCalls(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.callsIncludedList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.callsIncludedList) || null;
    offerCommercial.callsIncludedDetailList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.callsIncludedDetailList) || null;
    offerCommercial.callsIncludedSecondList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.callsIncludedSecondList) || null;
    offerCommercial.callsList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.callsList) || null;
  }

  public offersLogicMapInternet(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.internetIncludedFicha = JsonQuery.value(off, JSON_PATHS.CommercialOffers.internetIncludedFicha) as string || null;
    offerCommercial.internetIncludedList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.internetIncludedList) as string || null;
    offerCommercial.internetIncludedSecondList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.internetIncludedSecondList) as string
      || null;
    offerCommercial.internetIncludedSecondFicha = JsonQuery.value(off,
      JSON_PATHS.CommercialOffers.internetIncludedSecondFicha) as string || null;
  }

  public offersLogicMapShow(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.showDetailList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.showDetailList) || false;
    offerCommercial.showDetailFicha = JsonQuery.value(off, JSON_PATHS.CommercialOffers.showDetailFicha) || false;
    offerCommercial.showOpenList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.showOpenList) || false;
  }

  public offersLogicMapRoaming(off: CommercialOffer, offerCommercial: CommercialOffer): void {
    offerCommercial.roamingList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.roamingList) || null;
    offerCommercial.roamingDetailList = JsonQuery.value(off, JSON_PATHS.CommercialOffers.roamingDetailList) || null;
  }


  public offerPegaLogic(offerResponse: PegaModel): void {
    offerResponse.offers.forEach(offer => {
      if ((offer?.rate?.flagPromoPega !== undefined && offer?.rate?.flagPromoPega)) {
        this.offerPega = new CommercialOfferPega();
        this.offerPega.flagPromoPega = offer.rate.flagPromoPega;
        this.offerPega.cardTitle = offer.rate.name;
        this.offerPega.cardSubtitle = offer.rate.text?.dataIncludedList;
        this.offerPega.cardVelocity = offer.rate.text?.dataIncludedSecondList;
        this.offerPega.thunderAltImageIcon = offer.rate.image?.altImageIcon;
        this.offerPega.thunderImageIcon = offer.rate.image?.rateImageIcon;
        this.offerPega.installmentTaxes = offer.rate.price?.installmentTaxes;
        this.offerPega.netFeeIncrease = offerResponse.infoPromoPega.netFeeIncrease;
        this.offerPega.duracionDescAdHoc = offerResponse.infoPromoPega.duracionDescAdHoc;
        this.offerPega.originNetFee = offerResponse.infoPromoPega.originNetFee;
        this.offerPega.bindingLife = offerResponse.infoPromoPega.bindingLife;
        this.offerPega.bindingPenalty = offerResponse.infoPromoPega.bindingPenalty;
        this.offerPega.precioDescAdHoc = Math.abs(Number(offerResponse.infoPromoPega.precioDescAdHoc)).toString();
        this.offerPega.secondLinesDiscountPrice = Math.abs(Number(offerResponse.infoPromoPega.secondLinesDiscountPrice)).toString();
        this.offerPega.exclusiveOffer = Number(this.offerPega.originNetFee) + Number(this.offerPega.netFeeIncrease);
        this.offerPega.priceOfferEnds = this.offerPega.exclusiveOffer + Math.abs(Number(this.offerPega.precioDescAdHoc));
        this.offerPegaExist = offer.rate.flagPromoPega;
      }
    });
  }

  public formatPrices(resPrice?: number): string {
    return resPrice || resPrice === 0 ? Number(resPrice).toFixed(2).toString().replace('.', ',') : null;
  }

  public emptyOffers(): void {
    this.offers = undefined;
  }

  public promosLogic(promo: ResponseOffer): void {
    this.promos = new Array<PromoCommercial>();
    const proms: PromoCommercial[] = JsonQuery.value(promo, JSON_PATHS.PromoCommercial.proms) || null;
    if (proms) {
      proms.forEach(prom => {
        const commercialPromo: PromoCommercial = new PromoCommercial();
        this.promosLogicMapPrincipal(commercialPromo, prom);
        this.promosLogicMapDiscount(commercialPromo, prom);
        this.promosLogicMapUrl(commercialPromo, prom);
        this.promosLogicMapFlag(commercialPromo, prom);
        this.promosLogicMapDetail(commercialPromo, prom);
        this.promosLogicMapText(commercialPromo, prom);
        this.promos.push(commercialPromo);
        return this.promos;
      });
    }
  }

  public promosLogicMapPrincipal(commercialPromo: PromoCommercial, prom: PromoCommercial): void {
    commercialPromo.id = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.id);
    commercialPromo.endDate = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.endDate);
    commercialPromo.keep = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.keep);
    commercialPromo.applyTo = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.applyTo);
    commercialPromo.descriptionCRM = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.descriptionCRM);
    commercialPromo.evaluationOrder = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.evaluationOrder);
    commercialPromo.internalName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.internalName);
    commercialPromo.linkedToCatalog = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.linkedToCatalog);
    commercialPromo.publicName = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.publicName);
  }

  public promosLogicMapDiscount(commercialPromo: PromoCommercial, prom: PromoCommercial): void {
    commercialPromo.discountAmount = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountAmount);
    commercialPromo.discountClassType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountClassType);
    commercialPromo.discountDuration = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountDuration);
    commercialPromo.discountDurationUndefined = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountDurationUndefined);
    commercialPromo.discountType = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.discountType);
  }

  public promosLogicMapUrl(commercialPromo: PromoCommercial, prom: PromoCommercial): void {
    commercialPromo.urlMobilePromotionImage = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImage);
    commercialPromo.urlMobilePromotionImageFicha = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlMobilePromotionImageFicha);
    commercialPromo.urlTabletPromotionImageFicha = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.urlTabletPromotionImageFicha);
  }

  public promosLogicMapFlag(commercialPromo: PromoCommercial, prom: PromoCommercial): void {
    commercialPromo.flagIgnoreCRM = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagIgnoreCRM);
    commercialPromo.flagRegional = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.flagRegional);
  }

  public promosLogicMapDetail(commercialPromo: PromoCommercial, prom: PromoCommercial): void {
    commercialPromo.detailTextList = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailTextList);
    commercialPromo.detailTextFicha = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.detailTextFicha);
  }

  public promosLogicMapText(commercialPromo: PromoCommercial, prom: PromoCommercial): void {
    commercialPromo.stayPromotionText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.stayPromotionText);
    commercialPromo.shortText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.shortText);
    commercialPromo.starText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.starText);
    commercialPromo.longTextFicha = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.longTextFicha);
    commercialPromo.headbandOpenText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.headbandOpenText);
    commercialPromo.longText = JsonQuery.value(prom, JSON_PATHS.PromoCommercial.longText);
  }

  public infoPromoPegaLogic(offer: ResponseOffer): void {
    const objInfoPromoPega: InfoPromoPega = JsonQuery.value(offer, JSON_PATHS.bundellfibra.infoPromoPega) || null;
    if (objInfoPromoPega) {
        this.infoPromoPega = Object.assign({}, objInfoPromoPega);
    }
  }

  public getClientInfoOffers(offer: ResponseOffer): void {
    const objClientInfoOffers: ClientInfoOffers = JsonQuery.value(offer, JSON_PATHS.bundellfibra.clientInfo) || null;
    if (objClientInfoOffers) {
        this.clientInfo = Object.assign({}, objClientInfoOffers);
    }
  }

  public postServiciability(clientype: string, isMTP: boolean): Observable<Serviciability> {
    let url: string = API_URLS.Checkout.deteleReset
      .replace('{idClient}', this.subscriptionData.customerData.customerAccountsId)
      .replace('{clientTypeID}', this.isMicroResegmented
        ? commercialMobile.clientTypeMicro.toString()
        : clientype
      );

    if (isMTP) {
      url += `?idTypeJourney=${CommercialJourneys.mobileToPack}`;
    }
    const options: HeadersDataOptions = this.headersData();
    return this.http.post(url, options).pipe(mergeMap((resReset: PostServiciability) => {
      return this.getServiciability(resReset.NTOL_TXID);
    })).pipe(catchError((err) => {
      this.federror = err;
      this.router.navigate([config.FedError.route]);
      this.federror = this.fedErrorService.fedError;
      return throwError(err);
    }));
  }

  public getValidatePortability(siteId: string, msisdn: string[]): Observable<GetValidatePortability> {
    const queryParams: HttpParams = new HttpParams().set('siteId', siteId);
    const url: string = API_URLS.validatePortability.getValidatePortability + '?' + queryParams;
    const headers: HttpHeaders = new HttpHeaders();
    const params: string[] = msisdn;
    const options: CommercialOptions = {
      headers: headers,
      auth_ticket: this.storageService.getStorage(LOCAL_STORAGE_KEYS.JWT),
      withCredentials: true,
   }
   return this.http.post(url, params, options).pipe(map((res: GetValidatePortability) => {
       return res;
    }), catchError((err) => {
      return throwError(err);
    }));
  }

  public getServiciability(resetNTOL: string): Observable<Serviciability> {
    const customerType: string = this.storageService.userProfile.customerType.toLowerCase();
    let url: string = API_URLS.Serviciability.getServiciability;
    const conf: RequestConfig = this.translate.instant('v10.commercial.request_config');
    url = url
        .replace('{siteId}', this.subscriptionData.customerData.customerAccountsId)
        .replace('{shopType}', customerType === CustomerType.Consumer.toLowerCase() ? conf.shopType_Partis : conf.shopType_Micro)
    const options: HeadersDataOptions = this.headersData();
    this.cookieService.put(CommercialJourneys.ntol, resetNTOL);

    return this.http.get(url, options).pipe(map((res: Serviciability) => {
      this.serviciability = res;
      this.serviciabilitySubject.next(this.serviciability);
      return res;
    }), catchError((err) => {
      this.federror = err;
      this.router.navigate([config.FedError.route]);
      this.federror = this.fedErrorService.fedError;
      return throwError(err);
    }));
  }

  public postServiciabilityBundleFibra(clientype: string, journey: string, _data: EntryPointModel): Observable<Serviciability> {
    let url: string = API_URLS.Checkout.deteleReset
      .replace('{idClient}', this.subscriptionData.customerData.customerAccountsId)
      .replace('{clientTypeID}', this.isMicroResegmented
        ? commercialMobile.clientTypeMicro.toString()
        : clientype
      );

    url += `?idTypeJourney=${journey}`;

    const options: HeadersDataOptions = this.headersData();
    const data: EntryPointModel = (_data.offerVFDescriptor) ? _data : null;
    return this.http.post(url, data, options).pipe(mergeMap((resReset: PostServiciability) => {
      this.ntolTxID = resReset.NTOL_TXID;
      return this.getServiciabilityBundleFibra(resReset.NTOL_TXID);
    }));
  }


  public getServiciabilityBundleFibra(resetNTOL: string): Observable<Serviciability> {
    let url: string = API_URLS.Serviciability.getServiciability;
    const customerType: string = this.storageService.userProfile.customerType.toLowerCase();
    const conf: RequestConfig = this.translate.instant('v10.commercial.request_config');
    url = this.replaceUrlSiteIdShopType(url, customerType, conf)
    const options: HeadersDataOptions = this.headersData();
    const optionsReset: CookieOptions = {
        path: '/',
        domain: environment.imageBaseUrl
    }
    this.cookieService.put(CommercialJourneys.ntol, resetNTOL, optionsReset);
    return this.http.get(url, options).pipe(map((res: Serviciability) => {
        this.serviciability = res;
        this.serviciabilitySubject.next(this.serviciability);
        return res;
    }));
  }

  public replaceUrlSiteIdShopType(url: string, customerType: string, conf: RequestConfig): string {
    return url
      .replace('{siteId}', this.subscriptionData.customerData.customerAccountsId)
      .replace('{shopType}', this.isMicroResegmented ? conf.shopType_Micro :
        customerType === CustomerType.Consumer.toLowerCase()
          ? conf.shopType_Partis
          : conf.shopType_Micro
      );
  }
  getContractedProducts(): Observable<ContractedProducts> {
    let url: string = API_URLS.ContractedProducts.getContratedProducts;
    url = url.replace('{siteId}', this.subscriptionData.customerData.customerAccountsId);
    const options: HeadersDataOptions = this.headersData();
    return this.http.get(url, options).pipe(map((res: ContractedProducts) => {
      this.contractedProducts = res;
      this.contractedProductsSubject.next(this.contractedProducts);
      return res;
    }));
  }

  public headersData(): HeadersDataOptions {
    const headers: HttpHeaders = new HttpHeaders();
    const options: HeadersDataOptions = {
      headers: headers,
      withCredentials: true
    };
    return options;
  }

  public userData(): void {
    const result: CustomerAccount = this.customerAccountService.customerAccount;
    if (result.firstName) {
      this.name = result.firstName;
    }
    if (result.familyName) {
      this.familyName = result.familyName;
    }
  }

  public offersLoaded(): boolean {
    return this.offers ? true : false;
  }

  public saveJourneyTagg(tagg: JourneyTaggModel): void {
    this.journeyTagg = tagg;
  }

  public setKeyJourneyTagg(key: string, value: string): void {
    this.journeyTagg[key] = value;
  }

  public getKeyJourneyTagg(key: string): string {
    return this.journeyTagg[key];
  }

  public getJourneyTagg() {
    return this.journeyTagg;
  }

  public setHistoryStep(state: CommercialTagg, step: number): void {
    this.history[step] = state;
  }

  public getHistoryStep(step: number): CommercialTagg {
    return this.history[step];
  }

  public deleteFuturesHistoryStep(step: number): void {
    this.history = this.history.slice(0, step);
  }

  public clearHistory(): void {
    this.history = new Array<CommercialTagg>();
  }

  public setOrigin(origin: string): void {
    this.origin = origin;
  }

  public saveStepper(stepTitles: string[]): void {
    this.stepTitles = stepTitles;
  }

  public getStepper(): string[] {
    return this.stepTitles;
  }

  public setLineUser(_dataUserLine: CommercialPersonalData): void {
    this.dataUserLine = _dataUserLine;
  }

  public concatPromos(): PromoCommercial[] {
    const numLines: number = this.subscriptionData.customerData.services.filter(el =>
      (el.type.toLowerCase() === ServiceType.Postpaid.toLowerCase())).length;
    const liteCodes: string[] = this.translate.instant('v10.dashboard.onePlus.litePackages').split(',');
    const packageCode: string = this.subscriptionData.customerData.currentService.package.code;
    if (!this.promos) {
      this.promos = [];
    } else if (numLines < 4 && !liteCodes.includes(packageCode)) {
      this.promos = this.promos.concat(this.temporalPromotions);
    }
    return this.promos;
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class FederationErrorService {
  public fedError: any;
  public pageToRetry: string;

  constructor() { }

}

<div class="card-selection-container">
  <div class="cardLabel" *ngIf='isPrinciple'>{{label}}</div>

  <div class="card-selection--with-dots" [ngClass]="{'extra-sparkle': isPrinciple}" (click)='onCardClicked()'>
    <img [src]="cardImg" alt="" class="card-img">
    <p class="card-mask">{{cardNumber}}</p>
    <div *ngIf="isDotsShow" class="dots-img dots-container" (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="menu">
      <img src='https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/3Dots.png' alt="">
      <div class="after-dots-empty"></div>
    </div>
  </div>

  <div>
    <mat-menu #menu="matMenu" overlapTrigger="false" xPosition="before">
      <div *ngFor="let item of menuItems;let i = index">
        <button mat-menu-item (click)='onMenuActionClicked(item)' [disabled]="!i && isPrinciple"> <!-- disable first item when i === 0 -->
          {{item}}
        </button>
        <hr class="divider" *ngIf="(menuItems.length-1)>i ">
      </div>
    </mat-menu>
  </div>
</div>
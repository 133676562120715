import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmartPayManagementComponent } from './smart-pay-management/smart-pay-management.component';
import { config } from '../../config/pages-config';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: config.ProductsAndServices.Smartpay.route,
        pathMatch: 'full',
      },
      {
        path: config.SmartPay.smartPayManagement.name,
        component: SmartPayManagementComponent
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmartPayRoutingModule { }

import { BillingAddress } from './billing-address.model';
import { PaymentMethod } from './payment-method.model';
import { BillingAccountSubscription } from './billing-accounts-subscription';
import { Debt } from './debt.model';
import { ReflectedPaymentType } from '../shared/enums/reflected-payment-type.enum';
export class BillingAccounts {
    public id: string;
    public billingAddress?: BillingAddress;
    public paymentMethod: PaymentMethod;
    public debt: Debt;
    public subscriptions?: BillingAccountSubscription[];
    public notReflectedCachDesk?: boolean;
    public reflectedPaymentsType?: ReflectedPaymentType;
}



export class BubbleHelpConfiguration {
    public name: string;
    public taggingName: string;
    public chatEnabled: boolean;
    public faqsEnabled: boolean;
    public module: string;
    public tobiBubble: string;
    public pageName?: string;
}

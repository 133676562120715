import { MyAccountSections } from '../shared/enums/my-account-sections.enum';

export class AccountOverviewData {
    public title: string;
    public description?: string;
    public url: string;
    public locked?: boolean;
    public display?: boolean;
    public section?: MyAccountSections;
}

export class AccountOverview {
    public accountOverviewData: AccountOverviewData [];
}

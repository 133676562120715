import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../models/payment';
import { overlayTitleSelector, overlayCenterTitleStyle, overlayRemoveBoldTitleStyle } from '../../../shared/constants/defines';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentTrayConfigPostPaid } from './payment-tray-config-postPaid';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';

export class PaymentTrayConfigFinancedDevicesCancellation extends PaymentTrayConfigPostPaid implements PaymentTrayConfigInterface {
    // this component is treated like an ovrelay we remove tem from history after navigating away from them
    // not to show them to the user accidentaly when he navigate back
    compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] =
        [PaymentPages.cancel, PaymentPages.loading, PaymentPages.deleteConfiramtion];
    /** variable to be used for getting a tray config method based on component name */
    trayConfigurations: any = {
        [PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
        [PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
        [PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
        [PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
        [PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
        [PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
        [PaymentPages.ticket]: this.getTicketCompTrayConfig.bind(this),
        [PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
        [PaymentPages.financedDevicesTotal]: this.getFinancedDevicesCancellationCompTrayConfig.bind(this)

    };
    constructor(public paymentNavService: PaymentNavigationService,
        public delegateService: DelegateCreationService,
        public translate: TranslateService,
        public paymentWalletService: PaymentWalletService
    ) {
        super(paymentNavService, delegateService, translate, paymentWalletService);
    }
    /**
   * @param compName the page we need it's tray configuration
   * function should return PaymentFullTrayModel containing
   * the proper tray configuration
   */
    getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
        let config: PaymentFullTrayModel;
        /** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
        if (this.trayConfigurations[compName]) {
            config = this.trayConfigurations[compName]();
        } else {
            config = this.getIframeCompTrayConfig();
        }
        return config;
    }


    /**
    * function return PaymentFullTrayModel
    * the configration of the ok screen configration
    */
    getFinancedDevicesCancellationCompTrayConfig(): PaymentFullTrayModel {
        const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
        this.translate.get('v10.payment.itemsList.financedDevices.es.paymentThirdParty').subscribe(data => {
            paymentFullTrayModel.MVA10Config.title = data.title;
        })
        paymentFullTrayModel.paymentCustomConfig.closeBtnAction
            = () => {
                this.delegateService.paymentSuccessConfig.closeBtnAction()
            }
        paymentFullTrayModel.paymentCustomConfig.customStyles =
            paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
                new CustomStyle(overlayTitleSelector, overlayCenterTitleStyle),
                new CustomStyle(overlayTitleSelector, overlayRemoveBoldTitleStyle)
            );
        return paymentFullTrayModel;
    }
}

import { config } from './../../../config/pages-config';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RetryService {
  public url: string;

  constructor(private router: Router) {}

  public retry(): void {
    this.router.navigate([config.retry.route]);
  }
}

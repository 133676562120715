import { commercialMobile } from './../../shared/constants/defines';
import { CommercialCheckoutCartService } from './../../models/commercial-checkout-shopping-cart.model';
import { Component, Input } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'sp-nexus-op',
  templateUrl: './nexus-op.component.html',
  styleUrls: ['./nexus-op.component.scss']
})
export class NexusOpComponent {
  @Input() services: Array<CommercialCheckoutCartService>;
  public coinMonth: string = commercialMobile.coinMonth;

  constructor(
    public appService: AppService
  ) { }

}

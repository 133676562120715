
import {map} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class GrifoTimeService {

  constructor(private translate: TranslateService,
    private http: HttpClient) {}
  getChatGrifo() {
    let url: string;
    this.translate.get('v10').subscribe(v10 => {
      url = v10.common.settings.assessor.tap_schedule_service_url;
    })
    let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };

    return this.http.get(url, options).pipe(map((response: any) => {
        return response;
    }));


  }
}



import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URLS } from '../shared/constants/routes-config';
import { SendInteractionsBody } from '../models/send-interactions-body.model';
import { SendInteractionsBodySLA } from '../models/send-interactions-body-sla.model';
import { Observable } from 'rxjs';

@Injectable()
export class FaultManagementData {
    constructor(private http: HttpClient) { }
    getTicketTemplate(customerAccountId: string, body: any) {
        const url = API_URLS.faultManagement.getTicketTemplate.replace('{CustomerAccountId}', customerAccountId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.post(url, body, options).pipe(map((res) => {
            return res;
        }));
    }
    sendInteractions(customerAccountId: string, interactionId: any, serviceType: string, backend: string): Observable<String> {
        const url: string = API_URLS.faultManagement.SetInteraction.replace('{CustomerAccountId}', customerAccountId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json')
        const options = {
            headers: headers,
        };
        const body: SendInteractionsBody = { interactionId, 'platform': 'web', serviceType, backend };
        return this.http.post(url, body, { ...options, responseType: 'text' });
    }
    sendInteractionsSLA(
        customerAccountId: string,
        interactionId: any,
        ticketId: string,
        ticketName: string,
        creationDate: string,
        sendCustomerAccountId: boolean): Observable<string> {
        const url: string = API_URLS.faultManagement.SetInteraction.replace('{CustomerAccountId}', customerAccountId);
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json')
        const options: Object = {
            headers: headers,
        };
        const body: SendInteractionsBodySLA = {
            interactionId,
            'platform': 'web',
            ticketId,
            ticketName,
            creationDate,
            ...(sendCustomerAccountId && {'selectedSite': customerAccountId})
        };
        return this.http.post(url, body, { ...options, responseType: 'text' });
    }

    getTicketsData(siteId: string) {
        const url = API_URLS.faultManagement.getTickets.replace('{CustomerAccountId}', siteId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(url, options).pipe(map((res) => {
            return res;
        }));

    }

    getServiceAvailability(siteId: string) {
        const url = API_URLS.faultManagement.getServiceAvailability.
            replace('{CustomerAccountId}', siteId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(url, options).pipe(map((res) => {
            return res;
        }));

    }


    postServiceAvailability(siteId: string, msisdn, serviceType, massiveTicketId) {
        const url = API_URLS.faultManagement.getServiceAvailability.
            replace('{CustomerAccountId}', siteId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        const body = {
            'serviceId': msisdn,
            'massiveTicketId': massiveTicketId,
            'serviceType': serviceType
        };


        return this.http.post(url, body, options).pipe(map((res) => {
            return res;
        }));

    }


    getCurrentTicket(siteId, ticketId: string) {
        const url = API_URLS.faultManagement.getTicketById.replace('{CustomerAccountId}', siteId).
            replace('{ticketId}', ticketId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(url, options).pipe(map((res) => {
            return res;
        }));

    }
    updateTicketStatus(siteId, ticketId, status) {
        const url = API_URLS.faultManagement.updateStatusUrl.replace('{CustomerAccountId}', siteId);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/merge-patch+json');
        const options = {
            headers: headers
        };
        const body = {
            'ticketId': ticketId,
            'status': status
        }
        return this.http.patch(url, body, options).pipe(map(response => {
            return response;
        }));
    }
    deleteAppointment(siteId, templeteId) {
        const url = API_URLS.faultManagement.cancelAppointmentUrl.replace('{CustomerAccountId}', siteId)
            .replace('{templeteId}', templeteId);
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options: Object = {
            headers: headers
        };
        return this.http.delete(url, options).pipe(map(response => {
            return response;
        }));
    }
}

import { Component, Input} from '@angular/core';
import { Product } from '../../../../app/models/product.model';
import { LandingComponent } from '../../../../app/products-and-services/landing/landing.component';
import { defines, miTarrifaIcons } from '../../constants/defines';
import { CallType } from '../../enums/callType.enum';

@Component({
  selector: 'sp-landing-prepaid-fixed-to-mobile-bundle',
  templateUrl: './landing-prepaid-fixed-to-mobile-bundle.component.html',
  styleUrls: ['./landing-prepaid-fixed-to-mobile-bundle.component.scss']
})
export class LandingPrepaidFixedToMobileBundleComponent {
  @Input() Bundle: Product
  public parent: LandingComponent
  public isActiveTarrif: boolean = false;
  internationalType: CallType.International;
  Icons: Record<string, string> = miTarrifaIcons;
  defines: Record<string, string> = defines ;
}

import { Component, Input } from '@angular/core';
import { Product } from '../../../../app/models/product.model';
import { LandingComponent } from '../../../../app/products-and-services/landing/landing.component';
import { miTarrifaIcons } from '../../constants/defines';

@Component({
  selector: 'sp-landing-prepaid-sms-bundle',
  templateUrl: './landing-prepaid-sms-bundle.component.html',
  styleUrls: ['./landing-prepaid-sms-bundle.component.scss']
})
export class LandingPrepaidSmsBundleComponent {
  public parent: LandingComponent
  @Input() Bundle: Product;
  Icons: Record<string, string> = miTarrifaIcons;


}

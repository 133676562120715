import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceModel } from '../../models/service.model';

@Component({
  selector: 'sp-mva10-service-selector',
  templateUrl: './sp-mva10-service-selector.component.html',
  styleUrls: ['./sp-mva10-service-selector.component.scss']
})
export class SpMva10ServiceSelectorComponent implements OnInit {

  @Input() selectedIndex: number = 0;
  @Input() selections: ServiceModel[];
  @Output() onSelectionChange = new EventEmitter<ServiceModel>();
  @Output() plusClicked = new EventEmitter<ServiceModel>();
  @Input() showPlus: boolean;
  @Input()
  plusSelected: boolean;
  @Input() selectedItem: number;
  constructor() { }

  ngOnInit() {
    this.selectedIndex = this.plusSelected ? null : this.selectedItem ? this.selectedItem : 0;
  }
  onSelectionClick(selectionIndex) {
    this.selectedIndex = selectionIndex;
    this.plusSelected = false;
    this.onSelectionChange.emit(this.selections[selectionIndex]);
  }
  clickPlus() {
    this.plusSelected = true;
    this.selectedIndex = null;
    this.plusClicked.emit();
  }
}

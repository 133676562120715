import { config } from './../../../config/pages-config';
import { screenCode, entryPointsMVA10, CaptureResponseActions, groupsS4U, carouselPEGA } from './../constants/defines';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CaptureResponseModel } from '../../models/capture-response.model';
import { EntryPointModel, EntryPointResponseModel } from '../../models/entry-points.model';
import { SubscriptionService } from '../../core/services/subscription.service';
import { Rechazos, EntryPointModel as EpModel } from '../../models/entry-point.model';

@Injectable({
  providedIn: 'root'
})
export class CacheEntrypointsService {

  private screenCode: string;
  private _listEntryPoints: EntryPointResponseModel;
  private entryPointsByServiceID: Array<{serviceID: string, entryPoint: EntryPointResponseModel}> = [];
  public dataResponse: CaptureResponseModel;

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {
    this.setScreenCodeByUrl(this.router.url);
  }

  public setScreenCodeByUrl(url: string): void {
    const _starURL: string = url;
    if (url.indexOf('?') !== -1) {
        const _auxSplitURL: string = url.split('?')[0];
        url = ((_auxSplitURL === config.DiscountRenewal.MainScreen.route) ||
               (_auxSplitURL === config.Recommender.route || _auxSplitURL === config.Recommender.Comparator.route) ||
               (_auxSplitURL === config.Bundleupsell.Mainscreen.route) ) ? _auxSplitURL : url;
    }
    switch (url) {
      case config.DiscountRenewal.MainScreen.route:
        this.screenCode = entryPointsMVA10.renewDiscounts;
        break;
      case config.Recommender.route:
      case config.Recommender.Comparator.route:
        this.screenCode = entryPointsMVA10.MGN;
        break;
      case config.Recommender.OnePlusComparator.route:
        this.screenCode = screenCode.HIU;
        break;
      case config.Bundleupsell.Mainscreen.route:
        this.screenCode = entryPointsMVA10.UPSELL;
        break;
    }
    url = _starURL;
  }

  /** Set Cache for EntryPoints*/
  public setEntryPoints(_list: EntryPointResponseModel): void {
      this._listEntryPoints = _list;
  }
  /** Get Cache for EntryPoints*/
  public getEntryPoints(): EntryPointResponseModel {
      return this._listEntryPoints;
  }

  /** Set Cache for ScreenCode by EntryPoint*/
  public setScreenCode(_screenCode: string): void {
    this.screenCode = _screenCode;
  }
  /** Get Cache for ScreenCode by EntryPoint*/
  public getScreenCode(): string {
    return this.screenCode;
  }

  /** Function Set Capture response : PEGA */
  public setCaptureResponse(_objEntryPoint: EntryPointModel, _actions: string): CaptureResponseModel {
    const _data: CaptureResponseModel = {
      outcome: (_actions === CaptureResponseActions.click) ? CaptureResponseActions.click : CaptureResponseActions.accepted,
      containerName: _objEntryPoint.containerName,
      originNetFee: _objEntryPoint.originNetFee,
      name: _objEntryPoint.name,
      label: _objEntryPoint.label,
      issue: _objEntryPoint.issue,
      groupNBA: _objEntryPoint.groupNBA,
      interactionID: _objEntryPoint.interactionID,
      rank: _objEntryPoint.rank,
      bundleParent: _objEntryPoint.bundleParent,
      siteID: this.subscriptionService.customerData.customerAccountsId,
      paqueteDescAdHoc: _objEntryPoint.paqueteDescAdHoc,
      precioDescAdHoc: _objEntryPoint.precioDescAdHoc,
      duracionDescAdHoc: _objEntryPoint.duracionDescAdHoc,
      codigoDescAdHoc: _objEntryPoint.codigoDescAdHoc,
      cuotaDescAdHoc: _objEntryPoint.cuotaDescAdHoc,
      offerVFDescriptor: _objEntryPoint.offerVFDescriptor,
      netFeeIncrease: _objEntryPoint.netFeeIncrease,
      pegaToken: _objEntryPoint.pegaToken
    };
    return _data;
  }
  /** Function Set Capture response S4U: PEGA */
  public setCaptureResponseS4U(item: EpModel, group: string, reject?: Rechazos): CaptureResponseModel {
    this.dataResponse = {
      containerName: item.containerName,
      originNetFee: item.originNetFee,
      name: item.name,
      label: item.label,
      issue: item.issue,
      groupNBA: item.groupNBA,
      interactionID: item.interactionID,
      rank: item.rank,
      bundleParent: item.bundleParent,
      paqueteDescAdHoc: item.paqueteDescAdHoc,
      precioDescAdHoc: item.precioDescAdHoc,
      duracionDescAdHoc: item.duracionDescAdHoc,
      codigoDescAdHoc: item.codigoDescAdHoc,
      cuotaDescAdHoc: item.cuotaDescAdHoc,
      offerVFDescriptor: item.offerVFDescriptor,
      outcome: carouselPEGA.impression,
      code: item.code,
      codigoNBA: item.codeNBA,
      id: item.id,
      siteID: this.subscriptionService.customerData.customerAccountsId,
    }
    if (group === groupsS4U.S4U1 || group === groupsS4U.S4U2) {
      this.dataResponse = {
        ...this.dataResponse,
        colorNBA: item.colorNBA,
        flow: item.flow,
        group: item.group,
        netFeeIncrease: item.netFeeIncrease,
        order: item.order,
        space: item.space,
        umbralesNBA: item.umbralesNBA,
        codigoRechazo: reject.codigoRechazo,
        motivoRechazo: reject.motivoRechazo,
        outcome: CaptureResponseActions.rejected,
      };
    } else {
        this.dataResponse = {...this.dataResponse, outcome: carouselPEGA.impression}
    }
    return this.dataResponse;
  }

  /** Set Cache for EntryPoints*/
  public setEntryPointsByServiceID(_list: {serviceID: string, entryPoint: EntryPointResponseModel}): void {
    const entryPoint: {serviceID: string, entryPoint: EntryPointResponseModel}
    = this.entryPointsByServiceID.find(entry => entry.serviceID === _list.serviceID);
    if (!entryPoint) {
      this.entryPointsByServiceID.push(_list);
    }
  }
  /** Get Cache for EntryPoints*/
  public getEntryPointsByServiceID(): Array<{serviceID: string, entryPoint: EntryPointResponseModel}> {
    return this.entryPointsByServiceID;
  }

}

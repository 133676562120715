<div class="jumbotron-container">
    <a id="SpEnjoyMoreNavigatorComp_a_navigateTo" (click)="navigateTo()">

        <div class="jumbotronContent">
            <div class="col-xs-11 style-position" [ngClass]="{'style-position-gray':isDarkGray && !isAdhockProduct()}">
                <div [ngClass]="{'dark-gray':isDarkGray && !isAdhockProduct(), 'grayShape':isLightGray && !isAdhockProduct(),'red':!isLightGray && !isDarkGray || isAdhockProduct(), 'enjoymore-container-with-date':enjoymoreItem && (enjoymoreItem.EnjoyMoreRenewalDate||!enjoymoreItem.benefitsFlag) || isPromotedProduct ,'new-red-shape': isPromotedProduct}">
                    <span class="title"> {{title1}}
                        <strong>{{title2}}</strong>
                        <span class="small-tourist-font" *ngIf="touristLastWord">{{touristLastWord}}</span>
                    </span>

                    <div *ngIf="enjoymoreItem && enjoymoreItem.EnjoyMoreRenewalDate && enjoymoreItem.benefitsFlag && !isAdhockProduct() &&!isPromotedProduct"
                        class="enjoymore-date">
                        {{'productsServices.enjoyMore.itemList.renewalTxt.body'|translate}}
                        {{enjoymoreItem.EnjoyMoreRenewalDate | date:'dd/MM/yy'}}
                    </div>
                    <div *ngIf="isPromotedProduct"
                        class="enjoymore-date">
                        {{'productsServices.enjoyMore.itemList.offerTxt.body'|translate}}
                        {{expiryDate | date:'dd/MM/yy'}}
                    </div>
                    <div *ngIf="enjoymoreItem  && !enjoymoreItem.benefitsFlag && !isAdhockProduct()" class="enjoymore-date not-active">
                        {{'productsServices.enjoyMore.itemList.noEnjoyTxt.body'|translate}}
                    </div>
                    <div *ngIf="isAdhockProduct()" class="enjoymore-date with-hasta">
                        <span class="first-letter-upper">{{'billing.billAlarmOverlay.fieldsList.tillText.body'|translate}}</span> {{enjoymoreItem.EnjoyMoreRenewalDate | date:'dd/MM/yy'}}
                    </div>
                    
                </div>

                <img class="image-position" [ngClass]="{'promo':promoIcon}" *ngIf="iconName" [src]="appService.getImgFullPath(iconName)" />
            </div>

            <div class="col-xs-1">
                <div class="jumbotronIcons">
                    <span class="icon icon-security" *ngIf="showLock"></span>
                    <span class="icon-chevron-right" [ngClass]="{'grayChevron':changeChevronColor,'dark-gray-chevron':!changeChevronColor && isDarkGray}"></span>
                </div>
            </div>
            <!-- This line is removed as per Defect#6417 local ALM and 8362 on spain alm -->
            <!-- <hr class="pass-line"> -->
        </div>
    </a>
</div>
<div class="expandable-chevron-header" (click)="onHeaderClick()">
  <div class="expandable-chevron-header--icon" *ngIf="leftIcon">
    <svg class="icon icon__custom">
      <title>{{ leftIcon }}</title>
      <use [attr.xlink:href]="leftIcon" />
    </svg>
  </div>
  <div class="expandable-chevron-header--content">
    <ng-content select="[expandable-header]"></ng-content>
  </div>
  <div class="expandable-chevron-header--arrow" *ngIf="!hideChevron">
    <svg class="icon icon__chevron-down" [@rotateAnimation]="expanded">
      <title>Chrevon</title>
      <use xlink:href="#icon-chevron-down" />
    </svg>
  </div>
</div>
<div class="expandable-chevron-content" *ngIf="!hideChevron && expanded" [@expandAnimation]>
  <div>
    <ng-content select="[expandable-body]"></ng-content>
  </div>
</div>

import { BillAlarmType } from '../../../shared/enums/bill-alarm-type.enum';

export const ALARMS_TYPE_CONFIG = {
  'newsTypesAlarm': [
    BillAlarmType.newProduct,
    BillAlarmType.newProductPaymentInAdvance,
    BillAlarmType.changeTariff,
    BillAlarmType.changeTariffWithPaymentInAdvance
  ],
  'newsMonthlyFee': [
    BillAlarmType.newProduct,
    BillAlarmType.changeTariff
  ],
  'newsPaymentAdvance': [
    BillAlarmType.newProductPaymentInAdvance,
    BillAlarmType.changeTariffWithPaymentInAdvance
  ],
  'tariffChange': [
    BillAlarmType.changeTariff,
    BillAlarmType.changeTariffWithPaymentInAdvance
  ],
  'newProduct': [
    BillAlarmType.newProduct,
    BillAlarmType.newProductPaymentInAdvance
  ],
  'tariffUpdate': [
    BillAlarmType.m4m,
    BillAlarmType.migration
  ],
};

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-content" *ngIf="item">
        <p class="card-content-status" [ngClass]="{'blue-text': item.status && (item.status.toLowerCase() == bundleStatus.PendingActivation.toLowerCase() || item.status.toLowerCase() == bundleStatus.PendingDeactivation.toLowerCase()), 'orange-text':  item.status && item.status.toLowerCase() == bundleStatus.Inactive.toLowerCase()}">
          {{item.greenText}}
        </p>
        <div class="card-content-image">
          <img [src]="item.serviceImage" />
          <p class="card-content-name">{{'v10.productsServices.secureNetConvergent.cardService.title'|translate}}</p>        </div>
        <hr />
        <p class="card-content-number-title">
          {{'v10.productsServices.secureNetConvergent.cardService.subtitle'|translate}}:
        </p>
        <div class="card-content-number-content">
          <span>{{item.serviceId}}</span>
          <span>{{item.serviceType}}</span>
        </div>
        <hr />
        <p class="card-content-price">
          {{item.secondTitle}}
        </p>
        <div class="flex-div">
          <div class="flex-div-1">
            <p class="card-content-free">
              {{item.price}}
            </p>
            <p *ngIf="item.tariffPlanText == monthlyText" class="monthly-text">
              {{item.tariffPlanText}}
            </p>
          </div>
          <sp-toggle-button *ngIf="item.status.toLowerCase() != bundleStatus.PendingActivation.toLowerCase() && item.status.toLowerCase() != bundleStatus.PendingDeactivation.toLowerCase()"
            [check]="item.status.toLowerCase() === bundleStatus.Active.toLowerCase() || item.status.toLowerCase() == bundleStatus.PendingDeactivation.toLowerCase()"
            [disable]="item.status.toLowerCase() == bundleStatus.PendingActivation.toLowerCase() || item.status.toLowerCase() == bundleStatus.PendingDeactivation.toLowerCase()"
            (option)="clickToggle.emit(item)">
          </sp-toggle-button>
        </div>
        <div [ngClass]="{'min-height': !isOnePlus}">
          <p *ngIf="item.tariffPlanText != monthlyText">{{item.tariffPlanText}}</p>
          <p class="aditional-info" [innerHTML]="item.additionalInfo"></p>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit} from '@angular/core';
import { GenereicAccordionModel } from '../../shared/models/generic-accordion.model';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
@Component({
  selector: 'sp-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {
  terms: GenereicAccordionModel[] = [];
  showTopDiv: boolean;
  constructor(
    private translateService: TranslateService,
    public appService: AppService,
    public utilitiesService: UtilitiesService,
  ) { }

  ngOnInit() {
    this.translateService.get('v10.legal.termsConditions.itemList').subscribe(data => {
      data.forEach(element => {
        this.terms.push({ accordionTitle: element.title, htmlContent: element.description })
      });
    })
  }
}

import { SpErrorMessageComponent } from './sp-error-message.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule, GenericModule } from '@mva10/mva10-angular';

@NgModule({
  declarations: [SpErrorMessageComponent],
  imports: [CommonModule, CoreModule, GenericModule],
  exports: [SpErrorMessageComponent]
})
export class SpErrorMessageModule {}

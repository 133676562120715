export enum TicketStatus {
    Open = 'open',
    Closed = 'closed'
}

export enum TicketTemplateCode {
    MRI = 'MRI',
    DIAGNOSTICO = 'DIAGNOSTICO',
    DEFAULT = 'DEFAULT',
    BARBECHO = 'BARBECHO',
    GENERAL_BREAKDOWN_WITH_DATE = 'GENERAL_BREAKDOWN_WITH_DATE',
    GENERAL_BREAKDOWN_WITHOUT_DATE = 'GENERAL_BREAKDOWN_WITHOUT_DATE',
    FRONT_OFFICE = 'FRONT_OFFICE',
    CONTRATA_APPOINTMENT_WITHOUT_DATE = 'CONTRATA_APPOINTMENT_WITHOUT_DATE',
    CONTRATA_APPOINTMENT_WITH_CTA = 'CONTRATA_APPOINTMENT_WITH_CTA',
    CONTRATA_HOME_VISIT_ALL_DAY = 'CONTRATA_HOME_VISIT_ALL_DAY',
    CONTRATA_HOME_VISIT = 'CONTRATA_HOME_VISIT',
    CONTRATA_WITH_APPOINTMENT = 'CONTRATA_WITH_APPOINTMENT',
    MOVIL_SOLUCIONADOYSLA = 'MOVIL_SOLUCIONADOYSLA',
    MOVIL_ILOC_FALTANDATOS = 'MOVIL_ILOC.FALTANDATOS',
    GEOLOCALIZACION = 'GEOLOCALIZACION',
    GEOLOCALIZACION2 = 'GEOLOCALIZACION2',
}

export enum TicketImageName {
    generic = 'generic',
    mobile = 'mobile-mid copy',
    landLine = 'landline_or_call',
    musicAndMovies = 'music_and_movies',
    router = 'router',
    tv = 'tv'
}

export enum TicketJourney {
    Reiterado_abandono = 'Reiterado_abandono',
    Reiterado_finalizado_flujo = 'Reiterado_finalizado_flujo',
    Notificar_averia = 'Notificar_averia',
    Reabrir_averia = 'Reabrir_averia ',
    Retomar_chat_abierto = 'Retomar_chat_abierto',
    Modificar_cita_averia_campo = 'Modificar_cita_averia_campo',
    Notificar_averia_solucionada = 'Notificar_averia_solucionada',
    Averia_fuera_de_SLA = 'Averia_fuera_de_SLA',
    Averia_ilocalizable = 'Averia_ilocalizable',
    Autoinstalacion = 'Autoinstalacion'
}

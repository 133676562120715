import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, NgModel } from '@angular/forms';
/**
 * custome validateors to call every time changes happens
 * @param pattern {string} value to vaildate formComtrol against this pattern
 * @param required {boolean} value to vaildate formComtrol that it is required
 * returns null if FormControl is valid
 * returns object if FormControl is not valid
 */
export function createTextBoxValidator(pattern: string, required: boolean) {
  const _pattern = new RegExp(pattern);
  return (c: UntypedFormControl) => {
    if (_pattern && required) {
      return (c.value && _pattern.test(c.value)) ? null : { mismatch: true };
    }
    else if (_pattern && c.value) {
      return (_pattern.test(c.value)) ? null : { mismatch: true };
    } else {
      return null;
    }
  };
}
const fn = () => { };
@Component({
  selector: 'sp-text-area',
  templateUrl: './sp-text-area.component.html',
  styleUrls: ['./sp-text-area.component.scss']
  , providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SPTextAreaComponent),
      multi: true
    }
    , {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SPTextAreaComponent),
      multi: true
    }
  ]
})

export class SPTextAreaComponent implements OnInit, ControlValueAccessor {

  // boolean to show or hide popup
  isShow = false;
  /**this will appear if pattern not equal empty string and the innervalue doesn't match the pattern   */
  @Input()
  patternErrorMessage;
  /**this will appear if isRequired not equal false and the innervalue equal empty string   */
  @Input()
  requiredErrorMessage = '';
  /** flag to show or hide password icon */
  @Input()
  showInfoIcon = false;
  /**flag to hash value in text box if ispassword true or not hashed */
  hashed = false;
  @Input()
  placeholder = '';
  /** The text box label. */
  @Input()
  label = '';
  /**  to show information for the text box  */
  @Input()
  infoText = '';
  /** The flag which indicates if the textbox required or not. */
  @Input()
  isRequired: boolean;
  /** The flag which indicates if the textbox with pattern or not. */
  @Input()
  pattern: string;
  /** The infoIconDetails carries info message*/
  @Input()
  infoIconDetails: string;
  @Input()
  isUpperCase = false;
  /** The inner value which holds the textbox text. */
  innerValue = '';
  /**textbox id */
  @Input()
  id = '';
  @Input()
  textType: string;
  type: string;
  /** The textTypeStatus whether it is password or normal text. */
  private TextboxType = {
    text: 'text',
    password: 'password',
    number: 'number'
  };
  /**the max length of the input text  */
  @Input()
  max: number;
  @Input()
  enforceValidation = false;
  @Output() onFocusFn = new EventEmitter();
  @Input() showError: boolean;
  @Output() onBlurFn = new EventEmitter();
  @Input() mva10Textbox: boolean;
  @Input() mva10TextboxRemovePadding: boolean; /* boolean to remove paddings around the textBox*/
  @Input() focused: boolean;
  @Input() dirtyValidationCheck: boolean = false;
  @Input() areaRowsNo = 4;
  @ViewChild('variable', { static: true }) inputRef: NgModel
  /** The call back which will be called if the user focus on the textbox */
  onTouched: () => void = fn;
  /** The call back which will be called if the user change any text on the textbox */
  onChanged: (_: any) => void = fn;

  /**this function used to be eq */
  validateFn: any = () => { };
  /**
    * refresh the inner value in case of it is not like the old inner value and the new value not equal undefined.
    * @param  {any} The text value.
  */
  writeValue(value: any): void {
    if (value !== undefined) {
      this.innerValue = value;
    }
  }
  /**
  * call the onChanged to refresh the value for the parent component.
   * @param  {any} The callback value.
   */
  // tslint:disable-next-line:no-shadowed-variable
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  /**
  * call the onTouched to refresh the value for the parent component.
   * @param  {any} The callback value.
     */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /**
   * called when value changed to validate th value
   * @param {c} Form Control to be validated
   */
  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
  /**
   * call the blur when component not foucsed
   */
  onBlur() {
    if (this.inputRef.valid || !this.inputRef.value) {
      this.focused = false;
    }
    this.onChanged(this.innerValue);
    this.onBlurFn.emit();
  }
  /**
   * call change when value changed
   */
  Change() {
    this.onChanged(this.innerValue);
  }
  /**empty constructor */
  constructor() { }
  /**
   * check if ispassword true if true type will be password otherwise will be text
   * validateFn function to be equal custom validator createTextBoxValidator
   */
  ngOnInit() {
    this.validateFn = createTextBoxValidator(this.pattern, this.isRequired);
  }
  /**
   * call toggleIcon when click on passwodIcon to hashed value or not
   */
  toggleIcon() {
    if (this.type === this.TextboxType.text) {
      this.type = this.TextboxType.password;
      this.hashed = true;
    } else {
      this.type = this.TextboxType.text;
      this.hashed = false;
    }
  }
  /* function to open the popup  */
  public openPopup() {
    if (!this.isShow) {
      this.isShow = true;
    }
  }
  /* function to close the popup  */
  public closePopup() {
    if (this.isShow) {
      this.isShow = false;
    }
  }
  onFocusEvent() {
    if (this.mva10Textbox) {
      this.focused = true;
    }
    this.onFocusFn.emit();
  }
}


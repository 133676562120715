<mva10-c-card [modifier]="'shadow'" class='tariff-info-card-container'>
    <div class="card-row first-row">
        <img id='ProductAndServiceTariffInfoCardfirstImage' src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/ic-mobile@2x.png">
        <p id='ProductAndServiceTariffInfoCardfirstTitle' class='text' [innerHtml]='infoCard?.titles[0]'></p>
    </div>
    <div class="card-row second-row">
        <img id='ProductAndServiceTariffInfoCardSecondImage' src="https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/images/voice@2.png" />
        <p id='ProductAndServiceTariffInfoCardSecondtitle' class='text' [innerHtml]='infoCard?.titles[1]'></p>
    </div>
    <div class="card-row last-row">
        <div [innerHtml]='dataUsage' #dataUsageRef></div>
        <button type="button" *ngIf='infoCard?.btnText' [ngClass]="{'btn-Inactive':!infoCard?.btnActive}"
            [disabled]='!infoCard?.btnActive' class='btn' id='ProductAndServiceTariffInfoCardbtn'
            (click)='navigateToTariffDetailsPage()'>{{infoCard?.btnText}}</button>
    </div>

</mva10-c-card>

import { Injectable, } from '@angular/core';
import { Subject, fromEvent, Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { PaymentCompoentInputData, PaymentJourneyParams, PaymentNavigation, PaymentOutPutParams, ThirdPartyURLParam } from '../../models/payment';
import { codiogo, mensaj, paymentOutPutParams, paymentWCSKey } from '../../shared/constants/defines';
import { IframeStatus } from '../../shared/enums/iframe-status.enum';
import { PaymentLanguages } from '../../shared/enums/payment-languages.enum';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { PaymentTrayAction } from '../../shared/enums/payment-tray-action.enum';
import { PaymentJourny } from '../../shared/enums/payment-journy.enum';
import { FinancedDevicesCancellation } from 'src/app/models/financed-device-cancellation';
import { callToTel } from './../../shared/constants/defines';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentNavigationService {
  /* is payment started  */
  isPaymentStarted = false;
  /* journey key used in wcs and delegate service */
  WCSJourneyKey: PaymentJourny;
  /* onject containing all parameter needed from the caller of the module */
  paymentJourneyParams: PaymentJourneyParams = new PaymentJourneyParams();
  /* values recorded from iframe needed for the tagging & saving credit card */
  thirdPartyURLParams: ThirdPartyURLParam = new ThirdPartyURLParam();
  /* subject to handle by the tray service to handle the diffrenet action needed by user  */
  public changePaymentCompSubject: Subject<PaymentNavigation> = new Subject<PaymentNavigation>();
  customCancelOverlaySecondBtnAction: Function;
  finalStatus: IframeStatus;
  paymentOutPutParams: PaymentOutPutParams = new PaymentOutPutParams();
  // financed devices extra paramas
  financedDevicesCancellation: FinancedDevicesCancellation = new FinancedDevicesCancellation();
  accountNumberValue: string;
  isAnonymousPayment: boolean;
  otpCode: string;
  otpTechError: boolean = false;
  productTagg: string;
  subscription: Subscription;
  supportedPhoneNumber: string;
  isPayingEnergyDebt: boolean = false;
  isRestartingPayment: boolean = false;
  isCommitment: boolean = false;
  commitmentId: string;
  public isExitingPaymentEnergyDebt: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public isExitingPaymentEnergyDebtObservable: Observable<Boolean> = this.isExitingPaymentEnergyDebt.asObservable();
  public partialEnergyDebtPayment: boolean = false;
  public hasPayedEnergyDebt: boolean = false;
  constructor(public appService: AppService) {
    /* subscribe to clean up the service after closing the payment tray */
    this.changePaymentCompSubject.subscribe(({ actionNeeded }) => {
      if (actionNeeded === PaymentTrayAction.forceClose) {
        if (this.isPaymentStarted) {
          this.isPaymentStarted = false;
          this.subscription?.unsubscribe();
          (this.isPayingEnergyDebt && !this.isRestartingPayment) ||
          (this.isPayingEnergyDebt && this.finalStatus === IframeStatus.complete && this.partialEnergyDebtPayment) ?
          this.isExitingPaymentEnergyDebt.next(true) : history.back();
        }
        this.customCancelOverlaySecondBtnAction = null;
      }
    });
    this.subscribeForLogOutEvent();
  }
  /**
   * @param paymentJourneyParams onject containing all parameter needed from the caller of the module
   * it keep ref from the object for other component to use
   * and fire subect to open the iframe page inside our tray
   * used to epen iframe only
   */
  openPaymentThirdParty(paymentJourneyParams: PaymentJourneyParams): void {
    if (!this.WCSJourneyKey && !this.isPaymentStarted) {
      this.isPaymentStarted = true;
      this.handleBackArrowNavigation();
      this.WCSJourneyKey = paymentJourneyParams.WCSJournyKey;
    }
    this.paymentJourneyParams = paymentJourneyParams;
    this.openPaymentComponent(PaymentPages.thirdPartyContainer);
  }
  /**
   * @param paymentJourneyParams journey key used in wcs and delegate service
   * it keep ref from the journey key for other component to use
   * it is used to push any compnent (entery point ,loading ,etc ) inside our tray
   */

  openPaymentComponent(compname: PaymentPages, WCSJourneyKey?: PaymentJourny, componentData?: PaymentCompoentInputData[]): void {
    if (WCSJourneyKey) {
      this.WCSJourneyKey = WCSJourneyKey;
      this.paymentJourneyParams.WCSJournyKey = WCSJourneyKey;
      if (!this.isPaymentStarted) {
        this.isPaymentStarted = true;
        this.handleBackArrowNavigation();
        this.paymentJourneyParams = new PaymentJourneyParams();
        this.finalStatus = null;
        this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.startPayment });
      }
    }
    this.changePaymentCompSubject.next({ compname, actionNeeded: PaymentTrayAction.changeComp, compData: componentData });
  }
  /**
   * function used to go back for the previous page
   */
  back(backSteps?: number): void {
    this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.pop, backSteps });
  }
  /**
   * function used to go back for the previous page WithoutAnimation
   */
  backWithoutAnimation(): void {
    this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.popWithoutAnimation });
  }
  /**
   * function used to close payment module
   */
  closePayment(): void {
    this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.forceClose, finalStatus: this.finalStatus });
  }
  /**
   * function used to show cancel payment screen
   */
  showCancelPayment(): void {
    this.openPaymentComponent(PaymentPages.cancel);
  }
  /**
   * function used to restart payment journy and navigate to the first entery point
   * (first component was pushed to the tray)
   */
  restartPaymentJourney(compname?: PaymentPages): void {
    this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.restart , compname });
  }
  /**
   * function used to restart payment journy and navigate to the first entery point
   * (iframe comp)
   */
  restartFromIframePaymentJourney(): void {
    this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.restartFromIframe });
  }
  /**
   * function used to show full app vodafone loader
   */
  showVodafoneLoader(): void {
    this.appService.showVodaFullLoader = true;
  }
  /**
   * function used to show full app vodafone loader
   */
  hideVodafoneLoader(): void {
      this.appService.showVodaFullLoader = false;
  }
  /**
   * function used to call a number
   */
  callSupportedNumber(): void {
    document.location.href = `${callToTel}${this.supportedPhoneNumber}`;
    this.closePayment();
  }
  /**
   * @param page page name
   * used to return string containing the path in wcs of the
   * page
   */
  getWcsPath(page: PaymentPages): string {
    let path: string = paymentWCSKey.mainSection
    switch (page) {
      case PaymentPages.thirdPartyContainer:
        path += paymentWCSKey.itemList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.thirdPartyPageKey
        break;
      case PaymentPages.ok:
        path += paymentWCSKey.messsageList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.paymentSuccessful
        break;
      case PaymentPages.cancel:
        path += paymentWCSKey.messsageList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.paymentCancel
        break;
      case PaymentPages.ko:
        path += paymentWCSKey.messsageList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language
        break;
      case PaymentPages.wallet:
        path += paymentWCSKey.itemList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.paymentWallet
        break;
      case PaymentPages.ticket:
        path += paymentWCSKey.itemList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.paymentTicket
        break;
      case PaymentPages.deleteConfiramtion:
        path += paymentWCSKey.messsageList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.deleteWalletConfirmation
        break;
      case PaymentPages.contactListPermission:
        path += paymentWCSKey.messsageList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.openContactListPermission
        break;
      case PaymentPages.cardPaymentCommercial:
        path += paymentWCSKey.itemList +
          this.WCSJourneyKey + '.' +
          this.paymentJourneyParams.language +
          paymentWCSKey.eShopMiVF
        break;
    }
    return path;
  }
  /**
   * @param amount string contains number
   * we need to add comma then any numbers after
   * for example 15  will be 15,00
   * 15,55 will be 15,55
   * 15.22 will be 15,22
   */
  roundAmountToString(amount: any): string {
    return isNaN(parseFloat(amount)) ? '' : parseFloat(amount.replace(',', '.')).toFixed(2).toString().replace('.', ',')
  }
  convertAmountToCent(amount: any): string {
    return isNaN(parseFloat(amount)) ? '' : Math.round(+ (parseFloat(amount.replace(',', '.')).toFixed(2)) * 100).toString();
  }
  startCancelPayment(): void {
    if (this.thirdPartyURLParams.transactionId) {
      this.showCancelPayment();
    } else {
      if (this.paymentJourneyParams.koUrlExternal) {
        window.open(this.paymentJourneyParams.koUrlExternal + this.getpaymentFeedBackQuary(), '_self');
      } else {
        this.closePayment();
      }
    }
  }
  defaultKoClose(): void {
    if (this.paymentJourneyParams.koUrlExternal) {
      // commented for future use
      // this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.fullAppRedirection });
      // setTimeout(() => {
      window.open(this.paymentJourneyParams.koUrlExternal + this.getpaymentFeedBackQuary(), '_self');
      // }, 350);
    } else {
      this.closePayment();
    }
  }
  defaultOkClose(): void {
    if (this.paymentJourneyParams.okUrlExternal) {
      // commented for future use
      // this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.fullAppRedirection });
      // setTimeout(() => {
      window.open(this.paymentJourneyParams.okUrlExternal + this.getpaymentFeedBackQuary(), '_self');
      // }, 350);
    } else {
      this.closePayment();
    }
  }
  changeLanguage(language: PaymentLanguages): void {
    this.paymentJourneyParams.language = language;
    this.changePaymentCompSubject.next({ actionNeeded: PaymentTrayAction.changeLanguage });
  }

  getpaymentFeedBackQuary(): string {
    this.getDefaultParams();
    let feedBack = ''
    feedBack = (this.paymentOutPutParams.codigo ? paymentOutPutParams.codigo + this.paymentOutPutParams.codigo : '')
      + (this.paymentOutPutParams.numOperacionOrigen ?
        paymentOutPutParams.numOperacionOrigen + this.paymentOutPutParams.numOperacionOrigen : '')
      + (this.paymentOutPutParams.fechaOpOrigen ? paymentOutPutParams.FechaOpOrigen + this.paymentOutPutParams.fechaOpOrigen : '')
      + (this.paymentOutPutParams.importe ? paymentOutPutParams.importe + this.paymentOutPutParams.importe : '')
      + (this.paymentOutPutParams.mensaje ? paymentOutPutParams.mensaje + this.paymentOutPutParams.mensaje : '')
      + (this.paymentOutPutParams.systemReferenceId ? paymentOutPutParams.systemReferenceId + this.paymentOutPutParams.systemReferenceId : '')
      + (this.paymentOutPutParams.refundCodigo ? paymentOutPutParams.refundCodigo + this.paymentOutPutParams.refundCodigo : '')
      + (this.paymentOutPutParams.refundId ? paymentOutPutParams.refundId + this.paymentOutPutParams.refundId : '')
      + (this.paymentOutPutParams.refundFecha ? paymentOutPutParams.refundFecha + this.paymentOutPutParams.refundFecha : '')
      + (this.paymentOutPutParams.refundMensaje ? paymentOutPutParams.refundMensaje + this.paymentOutPutParams.refundMensaje : '')
    return feedBack ? '?' + feedBack : '';
  }
  getDefaultParams(): void {
    this.paymentOutPutParams.systemReferenceId = this.paymentOutPutParams.systemReferenceId ?
      this.paymentOutPutParams.systemReferenceId : this.paymentJourneyParams.systemReferenceId;
    this.paymentOutPutParams.numOperacionOrigen = this.paymentOutPutParams.numOperacionOrigen ?
      this.paymentOutPutParams.numOperacionOrigen : this.thirdPartyURLParams.transactionId;
    this.paymentOutPutParams.importe = this.paymentOutPutParams.importe ?
      this.paymentOutPutParams.importe : this.paymentJourneyParams.amountInCent;
    if (!this.paymentOutPutParams.codigo) {
      this.getCodigoMensaj(this.finalStatus);
    }
  }
  getCodigoMensaj(status: IframeStatus): void {
    switch (status) {
      case IframeStatus.complete:
        this.paymentOutPutParams.codigo = codiogo.complete;
        this.paymentOutPutParams.mensaje = mensaj.OKMessage;
        break;
      case IframeStatus.failed:
        this.paymentOutPutParams.codigo = codiogo.fail;
        this.paymentOutPutParams.mensaje = mensaj.KoMessage;
        break;
      case IframeStatus.canceled:
        this.paymentOutPutParams.codigo = codiogo.cancel;
        this.paymentOutPutParams.mensaje = mensaj.cancelMessage;
        break;
      case IframeStatus.timeout:
        this.paymentOutPutParams.codigo = codiogo.timeout;
        this.paymentOutPutParams.mensaje = mensaj.timeoutMessage;
        break;

    }
  }
  // commented for future use
  // getpaymentFeedBack(): PaymentOutPutParams {
  //   const feedBack: PaymentOutPutParams = new PaymentOutPutParams();
  //   feedBack.codigo = this.paymentOutPutParams.codigo
  //   feedBack.numOperacionOrigen = this.paymentOutPutParams.numOperacionOrigen
  //   feedBack.fechaOpOrigen = this.paymentOutPutParams.fechaOpOrigen
  //   feedBack.importe = this.paymentOutPutParams.importe;
  //   feedBack.mensaje = this.paymentOutPutParams.mensaje;
  //   feedBack.systemReferenceId = this.paymentOutPutParams.systemReferenceId;
  //   feedBack.refundCodigo = this.paymentOutPutParams.refundCodigo;
  //   feedBack.refundId = this.paymentOutPutParams.refundId;
  //   feedBack.refundFecha = this.paymentOutPutParams.refundFecha;
  //   feedBack.refundMensaje = this.paymentOutPutParams.refundMensaje;
  //   return feedBack;
  // }
  subscribeForLogOutEvent(): void {

    this.appService.applogingOut.subscribe(() => {
      this.closePayment();
    })
  }

  handleBackArrowNavigation(): void {
    history.pushState(null, null, location.href);
    this.subscription = fromEvent(window, 'popstate').subscribe(() => {
      history.pushState(null, null, location.href);
    });
  }
  openExternalVFWebLink(VFExternalBuisnessWeb: string): void {
    this.showVodafoneLoader()
    setTimeout(() => {
      window.open(VFExternalBuisnessWeb, '_self');
    }, 100);
  }

}

import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UpgradeProfileComponent } from './upgrade-profile.component';
import { StepOneComponent } from './step-one/step-one.component';
import { StepZeroComponent } from './step-zero/step-zero.component';
import { StepTwoComponent } from './step-two/step-two.component';
import { UpgradeProfileService } from './upgrade-profile.service';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        TranslateModule,
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        SharedModule
    ],
    declarations: [UpgradeProfileComponent, StepOneComponent, StepZeroComponent, StepTwoComponent],
    exports: [UpgradeProfileComponent, StepOneComponent, StepZeroComponent, StepTwoComponent],
    providers: [UpgradeProfileService]
})
export class UpgradeProfileModule { }

import { BillClarification } from './bill-clarification.model';
import { ProductConfiguration } from './product-configuration.model';
import { TobiUserConfiguration } from './tobiUserConfiguration.model';

export class Configurations {
    public networkLogin: boolean;
    public showBillsLink: boolean;
    public showOneNumberSSO: boolean;
    public showSecureNetSSO: boolean;
    public chatEnabled: boolean;
    public productConfiguration: ProductConfiguration[];
    public billClarifications: BillClarification[];

    public billingFlag: boolean;
    public biztalkAvailabilityFlag: boolean;
    public paymentUnavailability: boolean;
    public clarifyFlag: boolean;
    public genevaFlag: boolean;
    public ocsFlag: boolean;
    public topupNewPCI: boolean;
    public topupNewPCIMsisdnPattern: string;
    public disablemiwifi: boolean;
    public hideBillConfigurationToggles: boolean;
    public showBillingEta: boolean;
    public tobiChatUnAvailability: boolean;
    public tobiUserConfiguration: TobiUserConfiguration[];
    public showEverythingIsOk: boolean;
    public smartPayMSIDNPattern: string;
    public newAnonymousJourney: boolean;
    public availabilityOfRMCA: boolean;
    public PurchaseDefaultJourney?: string;
    public reviewBillingJourney: ReviewBillingJourney = new ReviewBillingJourney();
    public showSuperWifi: boolean;
    public storiesPosition: number;
    public rsmAvailabilityForBillReview: boolean;
    public showMovementsHistoryNewScreen: boolean;
    public tobiBubble: string;
    public showBillstatus: boolean;
}

export class ReviewBillingJourney {
    pattern: string;
    status: string;
    validUsers: string[];
}

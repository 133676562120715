import { LOCAL_STORAGE_KEYS, UPGRAD_SCINARIO_ERROR_4006, BIZTALKERROR } from './../../shared/constants/defines';
import { SidemenuConfigService } from './../../shared/services/sidemenu-config.service';
import { SubscriptionService } from './../../core/services/subscription.service';
import { tagging } from './../../../config/tagging-config';
import { Component, OnInit, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { UpgradeProfileService } from '../upgrade-profile.service';
import { UpgradeProfile } from '../UpgradeProfile.enum';
import { SpModalComponent } from '../../shared/components/sp-modal/sp-modal.component';
import { TranslateService } from '@ngx-translate/core';
import * as Constants from '../../shared/constants/defines';
import { Validation, PAGES } from '../../shared/constants/defines';
import { StorageService } from '../../core/services/storage.service';
import { Router } from '@angular/router';
import { Error } from '../../models/error.model';
import { MyAccountService } from '../../my-account/my-account.service';
import { CustomerAccountService } from '../../shared/services/customer-account.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ErrorHadlingUsingNotificationService } from '../../core/services/error-hadling-using-notification.service';
import { AppService } from '../../app.service';
import { LoginService } from '../../login/login.service';
import { Sanitizer } from '@angular/core';
import { ErrorHandlingService } from '../../core/services/errorHandling.service';

@Component({
  selector: 'sp-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  @ViewChild('overlay', { static: true }) overlay: ElementRef;
  public message = false;
  form: UntypedFormGroup;
  public showInput = false;
  defultOption = '';
  dniplaceholder: String = '';
  value: String = '';
  maxLength = 0;
  showValidation = false;
  pattern: String = '';
  isRequired = true;
  upgradeError: Error;
  showBackEndValidation = false;
  hideRedBorder = false;
  backEndValidationText = ''
  @ViewChild('modal') modal: SpModalComponent;
  documentTypes = [{ text: '', value: '' }, { text: '', value: '' }, { text: '', value: '' }];
  backEndErrorHandlers = [
    {
      type: 'E',
      handler: (richText, error) => {
        this.backEndValidationText = richText;
        this.showBackEndValidation = true;
        this.hideRedBorder = false;
        if (error.error && error.error.ecode === UPGRAD_SCINARIO_ERROR_4006) {
          this.hideRedBorder = true;
        }
      }
    }
  ]
  constructor(private fb: UntypedFormBuilder,
    private translate: TranslateService,
    public upgrade: UpgradeProfileService,
    private storageService: StorageService,
    private router: Router,
    private myAccountService: MyAccountService,
    private customerAccountService: CustomerAccountService,
    private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
    private taggingHelperService: TaggingHelperService,
    private subscriptionService: SubscriptionService,
    private menuService: SidemenuConfigService,
    private authenticateService: AuthenticateService,
    private appService: AppService,
    private loginService: LoginService,
    private sanitizer: Sanitizer,
    private errorHandling: ErrorHandlingService
  ) {
    this.accept = this.accept.bind(this);
  }

  ngOnInit() {
    this.form = this.fb.group({
      dniType: ['', [Validators.required]],
      dni: ['', [Validators.required]]
    });

    this.translate.
      get('login.upgradeProfile.fieldsList.docType.dropdownValues')
      .subscribe(data => {
        this.defultOption = data[0];
        this.documentTypes[0].text = data[1];
        this.documentTypes[0].value = '1';
        this.documentTypes[1].text = data[2];
        this.documentTypes[1].value = '2';
        this.documentTypes[2].text = data[3];
        this.documentTypes[2].value = '3';
      });
    this.taggingHelperService.view(tagging.upgradeStepTwo);
  }
  /**
   * dismiss the modal
   */
  dismiss() {
    this.overlay.nativeElement.style.display = 'none';
    this.upgrade.startScenario = false;
    this.upgrade.step = UpgradeProfile.stepZero;
  }
  /**
   * @param newSelect get the value selected in drop down
   * on change it get the new selected value and set it's label as place holder
   * and set it's validation pattern to validate based on it.
   */
  onChange(newSelect) {
    this.form.controls.dni.setValue('');
    const newValue = newSelect.value;
    const newText = newSelect.value !== '' ? newSelect[newSelect.value].label : '';

    if (newValue === '1') {
      this.showInput = true;
      this.dniplaceholder = newText;
      this.maxLength = 9;
    } else if (newValue === '2') {
      this.showInput = true;
      this.maxLength = 9;
      this.dniplaceholder = newText;
    } else if (newValue === '3') {
      this.showInput = true;
      this.maxLength = 255;
      this.isRequired = false;
      this.dniplaceholder = newText;
    } else {
      this.showInput = false;
    }
    if (this.showInput) {
      this.pattern = Validation[newText];
    }

  }
  /**
   * submit form sending NIE or NIF or Passport and it's type to validate them if success the upgrade scenario is done
   */
  submit() {
    const form = this;
    if (this.form.valid) {
      this.taggingHelperService.track(tagging.upgrade.start.eventName, tagging.upgrade.start.data);
      this.upgrade.upgradeProfile(this.documentTypes[this.form.controls.dniType.value - 1].text,
        this.form.controls.dni.value.toUpperCase()).subscribe((response) => {
          this.taggingHelperService.track(tagging.upgrade.success.eventName, tagging.upgrade.success.data);

          if (response) {
            if (this.customerAccountService.customerAccount) {
              this.myAccountService.loadheader(this.customerAccountService.customerAccount);
            }

            this.subscriptionService.GetDefaultServices().subscribe(() => {
              this.customerAccountService.customerAccounts = [];
              this.storageService.userProfile = this.authenticateService.getUserProfile
                (this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT));
              this.menuService.menuItemsIsLoadedInResolver = true;
              this.loadMenu();
            });
            this.message = true;
            setTimeout(function () {
              form.modal.show();
            });
            this.overlay.nativeElement.style.display = 'none';
          }
        }, (error) => {
          this.showBackEndValidation = false;
          this.hideRedBorder = false;
          if (error.error && error.error.ecode) {
            if (error.error.ecode.toString() === BIZTALKERROR) {
              this.appService.NudgeBiztalkError();
              this.taggingHelperService.taggingBiztalkNudgeError()
            }
          } else {
            const isBackEndValidation = this.checkBackEndValidations(error);
            if (isBackEndValidation) {
              return;
            }
            this.taggingHelperService.track(tagging.upgrade.error.eventName, tagging.upgrade.error.data);
            const notificationModal =
              this.errorHadlingUsingNotificationService
                .errorHandlingUsingNotification(error, Constants.HttpRequestMethods.post, PAGES.LOGIN, true);
            notificationModal.show();
            notificationModal.primaryButtonStyle = 'btn registration';
          }
        });
    } else {
      this.showValidation = true;
    }
  }
  /**
     * accept function delegated to accept button in the modal and check to which step shall redirect the user
     */
  public accept = () => {
    this.upgrade.startScenario = false;
    this.upgrade.step = UpgradeProfile.stepZero;
    if (this.upgrade.targetRoute) {
      this.router.navigate([this.upgrade.targetRoute]);
      if (this.upgrade.showModal) {
        this.upgrade.showModal()
      }
    }
  };
  /**
   * destroy function delegated to close button in the modal
   */
  destroy = function () {
    this.upgrade.startScenario = false;
  };
  loadMenu() {
    this.menuService.getUserMenuItems().subscribe();
  }
  checkBackEndValidations(error) {
    const errorType = this.loginService.getLoginErrorHandlingType(error);
    const errorHandler = this.backEndErrorHandlers.find(handler => handler.type === errorType);
    const errorWcsObj = this.errorHandling.lookUpError(PAGES.LOGIN, error);

    if (!errorHandler || !errorWcsObj || !errorWcsObj.descriptionRichText) {
      return false;
    }
    errorHandler.handler(this.sanitizer.sanitize(SecurityContext.NONE, errorWcsObj.descriptionRichText), error);
    return true;

  }
  resetFormValidation() {
    this.showBackEndValidation = false;

  }
}

<div class="payment-page-main-container">
  <!-- upper msidn  amount part -->
  <div class="account-block" *ngIf="wcsObj">
    <div class="toggle-description">
      <div class="recharge-container">
        <div id="service-id-logo-mobile">
          <img class="logo-mobile" [src]='appService.getImgFullPath(wcsObj.conceptImg)'>
        </div>
        <div id="service-id-recharge-text">
          {{wcsObj.concept}}
        </div>
        <div id="service-id-recharge-price">
          {{wcsObj.concepto}}
        </div>
      </div>
      <div class="recharge-container">
        <div id="service-id-logo-trolley">
          <img class="trolly-img" [src]='appService.getImgFullPath(wcsObj.amountImg)'>
        </div>
        <div id="service-id-amount-text">
          {{wcsObj.amount}}
        </div>
        <div id="service-id-amount">
          {{wcsObj.amountValue}} €
        </div>
      </div>
    </div>
  </div>
  <!-- upper msidn  amount part -->

  <!-- iframe section  -->
  <div #iframeContainer id="iframeContainer" *ngIf="netPlusURL" class="iframe-container"
    [ngStyle]="{display:showLottieLoader?'none':'flex' , height: pageHeight ? pageHeight:'393px'}">
    <iframe #Iframe id="Iframe" (load)="loadURL(Iframe)" name="framename" [src]="netPlusURL" scrolling="auto"></iframe>
  </div>
  <!-- iframe section end -->

  <!-- internal loading -->
  <div class="lottiee-loader-container" *ngIf="showLottieLoader">
    <sp-lottie-loader [lottieImageUrl]="'https://assets-es-sit3.dxlpreprod.local.vodafone.es/assets/loader.json'"></sp-lottie-loader>
  </div>
  <!-- internal loading end -->

  <!-- footer img section  -->
  <div class="icons-with-security-text" [ngClass]="{'add-padding-bottom': !showLottieLoader}" *ngIf="wcsObj">
    <div class="icons-container">
      <div class="visa-icon">
        <img [src]='appService.getImgFullPath(wcsObj.footerVisaImg)' alt="" class="img-responsive">
      </div>
      <div class="master-card-icon">
        <img [src]='appService.getImgFullPath(wcsObj.footerMastercardImg)' alt="" class="img-responsive">
      </div>
    </div>
    <div class="security-text-container-having-text">
      <p>{{wcsObj.footerText}}</p>
    </div>
  </div>
  <!-- footer img section end -->

</div>

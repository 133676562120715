import { NotificationService } from './../shared/services/notification.service';
import { Error } from './../models/error.model';
import { ErrorHandlingService } from './../core/services/errorHandling.service';
import { StorageService } from './../core/services/storage.service';
import { AuthenticateService } from './../core/services/authenticate.service';
import { CustomerType } from './../shared/enums/customerType.enum';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { SubscriptionService } from '../core/services/subscription.service';
import { CustomerAccountService } from '../shared/services/customer-account.service';
import { RetryService } from '../shared/services/retry.service';
import { AppService } from '../app.service';
import { PAGES, HttpRequestMethods, LOCAL_STORAGE_KEYS, cachingKeys } from '../shared/constants/defines';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { ErrorHadlingUsingNotificationService } from '../core/services/error-hadling-using-notification.service';
import { CommercialOffersService } from '../shared/services/commercial-offers.service';
import { ConfigurationService } from '../core/services/configuration.service';

import { CacheService } from '../shared/utils/cache.service';
import { UserProfile } from '../models/user-profile.model';

@Injectable()
export class MyAccountResolve implements Resolve<any> {
    errorObj: Error;
    constructor(private storageService: StorageService,
        private auth: AuthenticateService,
        private customerAccount: CustomerAccountService,
        private subscription: SubscriptionService, private retryService: RetryService,
        private appService: AppService, private errorHandlingService: ErrorHandlingService,
        private notificationService: NotificationService,
        private configurationService: ConfigurationService,
        private tagging: TaggingHelperService,
        private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService,
        private commercialOffersService: CommercialOffersService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.checkAutorizedAndReplicaMode();

        if (this.subscription.customerData.currentService?.siteId) {
            CacheService.evict(cachingKeys.FetchCustomerAccountById);
            return this.customerAccount.GetCustomerAccountById(this.subscription.customerData.currentService.siteId).toPromise()
                .then((result) => {
                    this.commercialOffersService.userData();
                    if (result && result.firstName) {
                        this.appService.initGretting(result.firstName);
                        sessionStorage.setItem('mva10-LocalStore.key.firstName', result.firstName);
                    }
                    if (this.auth.hasRole(CustomerType.Authorized.toLowerCase())) {
                        return this.customerAccount.
                            getCustomerPartiesById(this.storageService.userProfile.document.id).toPromise().then(() => {
                                return true
                            }).catch(error => {
                                this.errorHandling(state, error, HttpRequestMethods.get);
                                return true;
                            });
                    }
                    else {
                        return true;
                    }
                }).catch(error => {
                    this.errorHandling(state, error, HttpRequestMethods.get);
                    return true;
                });
        }
        else {
            return true
        }
    }
    errorHandling(state, error, RequestMethod: string) {
        // set tagging error variables
        this.appService.showFullAppLoader = false;
        const notificationModal =
            this.errorHadlingUsingNotificationService.errorHandlingUsingNotification(error, RequestMethod, PAGES.COMMON, false);
        notificationModal.show()
    }

    checkAutorizedAndReplicaMode() {
        /** to handle biztalk replica mode in overview and permissions&preferences pages */
        const userProfile: UserProfile = this.auth.getUserProfile(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0]);
        this.appService.isAuthorizedAndBiztalkReplicaMode = userProfile && userProfile.customerType &&
            userProfile.customerType.toString().toLowerCase() === CustomerType.Authorized.toLowerCase()
            && !this.configurationService.configuration.biztalkAvailabilityFlag;
    }
}

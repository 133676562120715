
import { Injectable } from '@angular/core';
import { SelectBadgeModel } from '@mva10/mva10-angular';
import { ServiceType } from '../enums/serviceType.enum';
import { SubscriptionService } from '../../core/services/subscription.service';
import { ShopV10OrderTabsObject } from '../../models/shopv10-order-tabs-object.model';

@Injectable()
export class TiendaV10Service {

  public origin;

  constructor(private subscription: SubscriptionService) { }

  public setOrigen(origin: string, tienda?: string) {
    this.origin = origin;
    return this.origin;
  }

  public getSubscriptionServiceList (): SelectBadgeModel[] {

    const arrayServiceList: SelectBadgeModel[] = [];
    this.subscription.customerData.services.forEach((service, index) => {
      const element: SelectBadgeModel = new SelectBadgeModel();
      switch (service.type.toUpperCase()) {
        case ServiceType.Postpaid.toUpperCase():
        case ServiceType.MbbPostpaid.toUpperCase():
        case ServiceType.MbbHoliday.toUpperCase():
        case ServiceType.Prepaid.toUpperCase():
          element.id = service.id;
          element.checked = index === 0;
          element.text = service.id;
          arrayServiceList.push(element);
          break;
      }
    });

    return arrayServiceList;
  }

  public sortTabs(mapped: ShopV10OrderTabsObject[]): ShopV10OrderTabsObject[] {
    mapped.sort((firstInd: ShopV10OrderTabsObject, lastInd: ShopV10OrderTabsObject) => {
      if (firstInd.newNum > lastInd.newNum) {
        return 1;
      }
      if (firstInd.newNum < lastInd.newNum) {
        return -1;
      }
      return 0;
    });
    return mapped;
  }
}

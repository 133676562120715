import { Component, Input } from '@angular/core';
import { Savings } from '../../../models/savings.model';

@Component({
  selector: 'sp-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss']
})
export class SavingsComponent {
  @Input() savings: Savings;

  constructor() { }

}
